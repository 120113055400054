import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Row, Col, Modal} from 'react-bootstrap'
import { required } from '../../util/validationFunctions'
import InputNumerical from '../comun/form/InputNumerical'
import Button from '../comun/button/Button'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputRadioButton from '../comun/form/InputRadioButton'
import InputDatePicker from '../comun/form/InputDatePicker'
import InputSelect from '../comun/form/InputSelect'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import { Print, Launch, Warning } from '@material-ui/icons'
import './Planing.scss'
import ModalVerracoPlaningPage from '../../containers/planing/ModalVerracoPlaningPage'

class ModalExportarImprimirPlaning extends Component {
   constructor () {
    super()
    this.state = {
      imputSelected: false,
      exportarTodoFecha: 'todo',
      errorExportar: false
    }
  }

  componentDidMount () {
    this.props.actions.comboVerraco()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.datosExportarPlaning !== prevProps.datosExportarPlaning) {
      if (this.props.datosExportarPlaning.excel && this.props.datosExportarPlaning.excel !== null){
        let pdf = this.props.datosExportarPlaning.excel
        let element = document.createElement('a')
        // element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('href', 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('PLANING.TITLE') + ".xlsx")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }

    if (this.props.datosImprimirPlaning !== prevProps.datosImprimirPlaning) {
      if (this.props.datosImprimirPlaning.pdf && this.props.datosImprimirPlaning.pdf !== null){
        let pdf = this.props.datosImprimirPlaning.pdf
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('PLANING.TITLE') + ".pdf")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }

    if (this.props.showModalExportarImprimirPlaning !== prevProps.showModalExportarImprimirPlaning) {
      this.props.change('export', 'todo')
      this.setState({exportarTodoFecha: 'todo'})
    }
  }

  cerrarModalExportarImprimirPlaning () {
    this.props.actions.cerrarModalExportarImprimirPlaning()
  }

  exportarVerracoPlaning (fechaExportacion) {
    const filtros = this.props.filtros
    filtros.fechaExportacion = fechaExportacion
    filtros.completo = false
    this.props.actions.exportarPlaning(filtros)
    this.setState({exportarTodoFecha: ''})
  }

  imprimirVerracoPlaning (fechaExportacion) {
    const filtros = this.props.filtros
    filtros.fechaExportacion = fechaExportacion
    filtros.completo = false
    this.props.actions.imprimirPlaning(filtros)
    this.setState({exportarTodoFecha: ''})
  }

  exportarPlaning () {
    const filtros = this.props.filtros
    filtros.fechaExportacion = null
    filtros.completo = true
    this.props.actions.exportarPlaning(filtros)
    this.setState({exportarTodoFecha: ''})
  }

  imprimirPlaning () {
    const filtros = this.props.filtros
    filtros.fechaExportacion = null
    filtros.completo = true
    this.props.actions.imprimirPlaning(filtros)
    this.setState({exportarTodoFecha: ''})
  }

  elegirTipoExportacion (value) {
    this.setState({exportarTodoFecha: value})
    this.setState({errorExportar: false})
  }

  aceptarExportarPalning (values) {
    if (this.state.exportarTodoFecha === 'fecha') {
      this.exportarVerracoPlaning(values.fechaExportacion)
      this.setState({errorExportar: false})
    } else if (this.state.exportarTodoFecha === 'todo') {
      this.exportarPlaning()
      this.setState({errorExportar: false})
    } else {
      this.setState({errorExportar: true})
    }
  }

  aceptarImprimirPalning (values) {
    if (this.state.exportarTodoFecha === 'fecha') {
      this.imprimirVerracoPlaning(values.fechaExportacion)
      this.setState({errorExportar: false})
    } else if (this.state.exportarTodoFecha === 'todo') {
      this.imprimirPlaning()
      this.setState({errorExportar: false})
    } else {
      this.setState({errorExportar: true})
    }
  }

  render () {
    const {
      t, handleSubmit, showModalExportarImprimirPlaning, exportarImprimir,
      actions:{onDeleteVerracoPlaning},
      combos: {comboVerraco}
    } = this.props

  return (
      <Modal show={showModalExportarImprimirPlaning} onHide={() => this.cerrarModalExportarImprimirPlaning()} bsSize="large" dialogClassName="sm" backdrop="static" aria-labelledby="contained-modal-title-lg"  className="modal-exportar-imprimir-planing">
        <form className="form-modal-planing">
          <Modal.Header closeButton>
            <Modal.Title>
            {
              exportarImprimir === 'exportar' ? (
                t('PLANING.MODAL.EXPORTAR')
              ) : (
                exportarImprimir === 'imprimir' ? (
                  t('PLANING.MODAL.IMPRIMIR')
                ) : null
              )
            }
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {
              exportarImprimir === 'exportar' ? (
                <div>
                <Field name="export" component={InputRadioButton} props={{ value: "todo" }} onChange={(e, value) => this.elegirTipoExportacion(value)}>{t('PLANING.EXPORTAR_TODO')}</Field>
                <Field name="export" component={InputRadioButton} props={{ value: "fecha" }} onChange={(e, value) => this.elegirTipoExportacion(value)}>{t('PLANING.FECHA')}</Field>
                {
                  this.state.exportarTodoFecha === 'fecha' ? (
                    <Field
                      colSm={12}
                      id="fechaExportacion"
                      name="fechaExportacion"
                      controlLabel={t('PLANING.FECHA')}
                      component={InputDatePicker}
                      validate={required}
                    />
                  ) : null
                }
                <div className="clearfix"></div>
                {
                  this.state.errorExportar === true ? (
                    <p className="error-eleccion-exportar-imprimir"><Warning /> {t('PLANING.ERROR_EXPORTAR')}</p>
                  ) : null
                }
                <div className="botones-footer-planing">
                  <Button type="button" className="btn btn-primary" onClick={handleSubmit(this.aceptarExportarPalning.bind(this))}>{t('PLANING.MODAL.ACEPTAR')}</Button>
                  <Button type="button" className="btn btn-white" onClick={() => this.cerrarModalExportarImprimirPlaning()}>{t('PLANING.MODAL.CANCELAR')}</Button>
                </div>
                </div>
              ) : (
                exportarImprimir === 'imprimir' ? (
                  <div>
                    <Field name="export" component={InputRadioButton} props={{ value: "todo" }} onChange={(e, value) => this.elegirTipoExportacion(value)}>{t('PLANING.IMPRIMIR_TODO')}</Field>
                    <Field name="export" component={InputRadioButton} props={{ value: "fecha" }} onChange={(e, value) => this.elegirTipoExportacion(value)}>{t('PLANING.FECHA')}</Field>
                    {
                      this.state.exportarTodoFecha === 'fecha' ? (
                        <Field
                          colSm={12}
                          id="fechaExportacion"
                          name="fechaExportacion"
                          controlLabel={t('PLANING.FECHA')}
                          component={InputDatePicker}
                          validate={required}
                        />
                     ) : null
                  }
                  <div className="clearfix"></div>
                {
                  this.state.errorExportar === true ? (
                    <p className="error-eleccion-exportar-imprimir"><Warning /> {t('PLANING.ERROR_EXPORTAR')}</p>
                  ) : null
                }
                <div className="botones-footer-planing">
                  <Button type="button" className="btn btn-primary" onClick={handleSubmit(this.aceptarImprimirPalning.bind(this))}>{t('PLANING.MODAL.ACEPTAR')}</Button>
                  <Button type="button" className="btn btn-white" onClick={() => this.cerrarModalExportarImprimirPlaning()}>{t('PLANING.MODAL.CANCELAR')}</Button>
                </div>
                  </div>
                ) : null
              )
            }
            </div>
            <div className="clearfix"></div>
          </Modal.Body>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalExportarImprimirPlaning',
})(ModalExportarImprimirPlaning))
