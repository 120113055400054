import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize} from 'redux-form'
import i18n from '../../i18n'
import actionTypes from '../../constants/actions/producto/producto'
import { closeModalProducto, filtrarProductoSuccess, filtrosProductoSuccess, recuperarDatosModalProductoSuccess, duplicarDatosModalProductoSuccess, deSeleccionarTodosProducto,
 crearCsvProductoSuccess, imprimirPdfProductoSuccess} from '../../actions/producto/producto'
import {openSimpleModal, openMensajeAlerta} from '../../actions/common'
import { yesNoModal } from '../modal/yesNoModal'
import addProducto from '../../services/producto/addProducto'
import filtrosProducto from '../../services/producto/filtrosProducto'
import getProductos from '../../services/producto/getProductos'
import editProducto from '../../services/producto/editProducto'
import getProductoCambioEstado from '../../services/producto/getProductoCambioEstado'
import deleteProducto from '../../services/producto/deleteProducto'
import getCambiarEstadoProductoSeleccion from '../../services/producto/getCambiarEstadoProductoSeleccion'
import eliminarProductoSeleccion from '../../services/producto/eliminarProductoSeleccion'
import getCrearCsv from '../../services/producto/getCrearCsv'
import getImprimirPdfProducto from '../../services/producto/getImprimirPdfProducto'
import {comboNombreProductos, comboReferenciaProductos} from '../../actions/combos/combos'
import { obtenerNotificaconesUsuarioSuccess } from '../../actions/notificaciones/notificaciones'
import getNotificaciones from '../../services/notificaciones/getNotificaciones'

export function * filtrarProducto ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const list = yield call(filtrosProducto, values, auth.token)
    yield put(filtrarProductoSuccess(list))
    yield put(filtrosProductoSuccess(values))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchFiltrarProducto () {
  yield takeLatest(actionTypes.FILTRAR_PRODUCTO, filtrarProducto)
}

export function * abrirModalProducto ({values}) {
  try {
    const valores = {activo: true, avisoStockMinimo: false}
    yield put(initialize('ModalProducto', valores))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchAbrirProducto () {
  yield takeLatest(actionTypes.ABRIR_MODAL_PRODUCTO, abrirModalProducto)
}

export function * submitNuevoProducto ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const list = yield call(addProducto, values, auth.token)
    if (list.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-insercion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    yield put(closeModalProducto())
    yield put(comboNombreProductos())
    yield put(comboReferenciaProductos())
    let filtros = yield select(state => state.producto.filtros)
    const lista = yield call(filtrosProducto, filtros, auth.token)
    yield put(filtrarProductoSuccess(lista))
    yield put(filtrosProductoSuccess(filtros))

    const listNotificaciones = yield call(getNotificaciones, null)
    yield put(obtenerNotificaconesUsuarioSuccess(listNotificaciones))
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchSubmitNuevoProducto () {
  yield takeLatest(actionTypes.SUBMIT_NUEVO_PRODUCTO, submitNuevoProducto)
}

export function * recuperarDatosModalProducto ({idProducto}) {
  try {
    let auth = yield select(state => state.auth)
    const registroModificar = yield call(getProductos, idProducto, null)
    yield put(recuperarDatosModalProductoSuccess(registroModificar))
    if (registroModificar.producto.usuarioAvisoStockMinimo !== null) {
      const comboUsuarioCentro = yield select(state => state.combos.comboUsuariosCentro)
      const usuarioCentro = comboUsuarioCentro.filter((usuario) => {
        if (usuario.value === registroModificar.producto.usuarioAvisoStockMinimo){
          return usuario
        }
      })
      registroModificar.producto.usuarioAvisoStockMinimo = usuarioCentro[0]
    }

    if (registroModificar.producto.unidadMedida === 1){
      registroModificar.producto.unidadMedida = {value: 1, label: i18n.t('COMUN.COMBOS.UNIDAD_MEDIDA.UNIDADES')}
    } else if (registroModificar.producto.unidadMedida === 2){
      registroModificar.producto.unidadMedida = {value: 2, label: i18n.t('COMUN.COMBOS.UNIDAD_MEDIDA.LITROS')}
    }
    yield put(initialize('ModalProducto', registroModificar.producto))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchRecuperarDatosModalProducto () {
  yield takeLatest(actionTypes.RECUPERAR_DATOS_MODAL_PRODUCTO, recuperarDatosModalProducto)
}

export function * editarProducto ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const edicionProducto = yield call(editProducto, values, auth.token)
    if (edicionProducto.actualizado === true) {
      yield put(openMensajeAlerta('modificacion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-modificacion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    yield put(closeModalProducto())
    yield put(comboNombreProductos())
    yield put(comboReferenciaProductos())
    let filtros = yield select(state => state.producto.filtros)
    if (filtros.referencia && values.referencia){
      filtros.referencia = {value: values.referencia, label: values.referencia}
    }
    if (filtros.nombre && values.nombre){
      filtros.nombre = {value: filtros.nombre.value, label: values.nombre}
    }
    const list = yield call(filtrosProducto, filtros, auth.token)
    yield put(filtrarProductoSuccess(list))
    yield put(filtrosProductoSuccess(filtros))

    const listNotificaciones = yield call(getNotificaciones, null)
    yield put(obtenerNotificaconesUsuarioSuccess(listNotificaciones))
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchEditarProducto () {
  yield takeLatest(actionTypes.EDITAR_PRODUCTO, editarProducto)
}

export function * duplicarDatosModalProducto ({idProducto}) {
  try {
    let auth = yield select(state => state.auth)
    const registroDuplicar = yield call(getProductos, idProducto, null)
    yield put(duplicarDatosModalProductoSuccess(registroDuplicar))
    if (registroDuplicar.producto.usuarioAvisoStockMinimo !== null) {
      const comboUsuarioCentro = yield select(state => state.combos.comboUsuariosCentro)
      const usuarioCentro = comboUsuarioCentro.filter((usuario) => {
        if (usuario.value === registroDuplicar.producto.usuarioAvisoStockMinimo){
          return usuario
        }
      })
      registroDuplicar.producto.usuarioAvisoStockMinimo = usuarioCentro[0]
    }

    if (registroDuplicar.producto.unidadMedida === 1){
      registroDuplicar.producto.unidadMedida = {value: 1, label: i18n.t('COMUN.COMBOS.UNIDAD_MEDIDA.UNIDADES')}
    } else if (registroDuplicar.producto.unidadMedida === 2){
      registroDuplicar.producto.unidadMedida = {value: 2, label: i18n.t('COMUN.COMBOS.UNIDAD_MEDIDA.LITROS')}
    }
    yield put(initialize('ModalProducto', registroDuplicar.producto))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchDuplicarDatosModalProducto () {
  yield takeLatest(actionTypes.DUPLICAR_DATOS_MODAL_PRODUCTO, duplicarDatosModalProducto)
}

export function * cambiarEstadoProducto ({idProducto}) {
  try {
    yield put(openSimpleModal('cambiarEstado'))
    const confirmed = yield call(yesNoModal, {modalType: 'cambiarEstado'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const cambioEstado = yield call(getProductoCambioEstado, idProducto, null)
      if (cambioEstado.actualizado) {
        yield put(openMensajeAlerta('cambio-estado'))
      } else {
        yield put(openMensajeAlerta('no-cambio-estado'))
      }
      let filtros = yield select(state => state.producto.filtros)
      const list = yield call(filtrosProducto, filtros, auth.token)
      yield put(filtrarProductoSuccess(list))
      yield put(filtrosProductoSuccess(filtros))

      const listNotificaciones = yield call(getNotificaciones, null)
      yield put(obtenerNotificaconesUsuarioSuccess(listNotificaciones))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchCambiarEstadoProducto () {
  yield takeLatest(actionTypes.CAMBIAR_ESTADO_PRODUCTO, cambiarEstadoProducto)
}

export function * onDeleteProducto ({idProducto, referencia}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const eliminar = yield call(deleteProducto, idProducto, null)
      if (eliminar.asociado === false && eliminar.eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(comboNombreProductos())
      yield put(comboReferenciaProductos())
      let filtros = yield select(state => state.producto.filtros)
      if (filtros.referencia && filtros.referencia.value === referencia){
        filtros.referencia = null
      }
      if (filtros.nombre && filtros.nombre.value === idProducto){
        filtros.nombre = null
      }
      const list = yield call(filtrosProducto, filtros, auth.token)
      yield put(filtrarProductoSuccess(list))
      yield put(filtrosProductoSuccess(filtros))

      const listNotificaciones = yield call(getNotificaciones, null)
      yield put(obtenerNotificaconesUsuarioSuccess(listNotificaciones))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchOnDeleteProducto () {
  yield takeLatest(actionTypes.ON_DELETE_PRODUCTO, onDeleteProducto)
}

export function * cambiarEstadoSeleccionProducto ({list}) {
  try {
    yield put(openSimpleModal('cambiarEstado'))
    const confirmed = yield call(yesNoModal, {modalType: 'cambiarEstado'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      let cambioEstadoSeleccion = yield call(getCambiarEstadoProductoSeleccion, {list: list}, auth.token)
      if (cambioEstadoSeleccion.actualizado) {
        yield put(openMensajeAlerta('cambio-estado'))
      } else {
        yield put(openMensajeAlerta('no-cambio-estado'))
      }
      yield put(deSeleccionarTodosProducto())
      yield put(initialize('simpleTable'))

      let filtros = yield select(state => state.producto.filtros)
      const lista = yield call(filtrosProducto, filtros, auth.token)
      yield put(filtrarProductoSuccess(lista))
      yield put(filtrosProductoSuccess(filtros))

      const listNotificaciones = yield call(getNotificaciones, null)
      yield put(obtenerNotificaconesUsuarioSuccess(listNotificaciones))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchCambiarEstadoSeleccionProducto () {
  yield takeLatest(actionTypes.CAMBIAR_ESTADO_SELECCION_PRODUCTO, cambiarEstadoSeleccionProducto)
}

export function * deleteSeleccionProducto ({list}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      let eliminarSeleccion = yield call(eliminarProductoSeleccion, {list: list}, auth.token)
      if (eliminarSeleccion.asociado === false && eliminarSeleccion.eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(deSeleccionarTodosProducto())
      yield put(initialize('simpleTable'))
      yield put(comboNombreProductos())
      yield put(comboReferenciaProductos())
      let filtros = yield select(state => state.producto.filtros)
      list.forEach((li) => {
        if (filtros.referencia && filtros.referencia.value === li.referencia){
          filtros.referencia = null
        }
        if (filtros.nombre && filtros.nombre.value === li.idProducto){
          filtros.nombre = null
        }
      })
      const lista = yield call(filtrosProducto, filtros, auth.token)
      yield put(filtrarProductoSuccess(lista))
      yield put(filtrosProductoSuccess(filtros))
      yield put(initialize('filtrosProducto', filtros))

      const listNotificaciones = yield call(getNotificaciones, null)
      yield put(obtenerNotificaconesUsuarioSuccess(listNotificaciones))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchDeleteSeleccionProducto () {
  yield takeLatest(actionTypes.DELETE_SELECCION_PRODUCTO, deleteSeleccionProducto)
}

export function * crearCsvProducto ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosExcelProducto = yield call(getCrearCsv, list, auth.token)
    yield put(crearCsvProductoSuccess(datosExcelProducto))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchCrearCsvProducto () {
  yield takeLatest(actionTypes.CREATE_CSV_PRODUCTO, crearCsvProducto)
}
export function * imprimirPdfProducto ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfProducto = yield call(getImprimirPdfProducto, list, auth.token)
    yield put(imprimirPdfProductoSuccess(datosPdfProducto))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirPdfProducto () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_PRODUCTO, imprimirPdfProducto)
}
