import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize} from 'redux-form'
import i18n from '../../i18n'
import actionTypes from '../../constants/actions/conectividad/conectividad'
import {closeModalConectividad, submitNuevaConectividadSuccess, fetchConectividadSuccess, fetchFiltrarConectividadSuccess, recuperarDatosModalConectividadSuccess,
  editarConectividadSuccess, duplicarDatosModalConectividadSuccess, cambiarEstadoConectividadSuccess, deSeleccionarTodosConectividad, crearCsvConectividadSuccess,
  imprimirPdfConectividadSuccess, actualizarConectividadVpnSuccess, probarConexionVpnSuccess} from '../../actions/conectividad/conectividad'
import {openModal, closeModal, openSimpleModal, openMensajeAlerta} from '../../actions/common'
import { yesNoModal } from '../modal/yesNoModal'
import getFiltrarConectividad from '../../services/conectividad/getFiltrarConectividad'
import comprobarDisponibilidadIPService from '../../services/conectividad/comprobarDisponibilidadIP'
import comprobarDisponibilidadVPNService from '../../services/conectividad/comprobarDisponibilidadVPN'
import getAddConectividad from '../../services/conectividad/getAddConectividad'
import getConectividad from '../../services/conectividad/getConectividad'
import editMaquina from '../../services/conectividad/editMaquina'
import getConectividadCambioEstado from '../../services/conectividad/getConectividadCambioEstado'
import deleteConectividad from '../../services/conectividad/deleteConectividad'
import getCambiarEstadoSeleccionConectividad from '../../services/conectividad/getCambiarEstadoSeleccionConectividad'
import getDeleteSeleccionConectividad from '../../services/conectividad/getDeleteSeleccionConectividad'
import getCrearCsvConectividad from '../../services/conectividad/getCrearCsvConectividad'
import getImprimirPdfConectividad from '../../services/conectividad/getImprimirPdfConectividad'
import getActualizarConcectividadVpn from '../../services/conectividad/getActualizarConcectividadVpn'
import getObtenerConcectividadVpn from '../../services/conectividad/getObtenerConcectividadVpn'
import comprobarDatosVPN from '../../services/conectividad/comprobarDatosVPN'

// Sagas
import {yesNoModal as yesNoModalSaga} from '../modal/yesNoModal'

export function * fetchFiltrarConectividad ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const list = yield call(getFiltrarConectividad, values, auth.token)
    const comboTipoPerifericoConectividad = yield select(state => state.combos.comboTipoPerifericoConectividad)
    let combo1
    let combo2
    const listConectividad = list.map((li, i) => {
      combo1 = comboTipoPerifericoConectividad.filter((tipoPeriferico) => {
        if (li.idTipoMaquina === tipoPeriferico.value){
          return tipoPeriferico
        }
      })
      li.maquina = combo1[0] !== undefined ? i18n.t(combo1[0].label) : null
      return li
    })
    yield put(fetchConectividadSuccess(listConectividad))
    yield put(fetchFiltrarConectividadSuccess(values))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchFetchFiltrarConectividad () {
  yield takeLatest(actionTypes.FETCH_FILTRAR_CONECTIVIDAD, fetchFiltrarConectividad)
}

export function * submitNuevaConectividad ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    let isIPDisponible = true
    isIPDisponible = yield call(comprobarDisponibilidadIPService, {IP: values.IP}, auth.token)
    if(!isIPDisponible) {
      yield call(yesNoModalSaga, {
        modalType: 'ipNoDisponible'
      })
      if (onErrorCallback) yield onErrorCallback()
    } else {
      const list = yield call(getAddConectividad, values, auth.token)
      yield put(submitNuevaConectividadSuccess(list))
      if (list.insertado === true) {
        yield put(openMensajeAlerta('insercion'))
        if (onSuccessCallback) yield onSuccessCallback()
      } else {
        yield put(openMensajeAlerta('no-insercion'))
        if (onErrorCallback) yield onErrorCallback()
      }

      yield put(initialize('ModalConectividad', {activo: true}))
      yield put(closeModalConectividad())
      let val = yield select(state => state.conectividad.filtros)
      const listado = yield call(getFiltrarConectividad, val, auth.token)
      const comboTipoPerifericoConectividad = yield select(state => state.combos.comboTipoPerifericoConectividad)
      let combo1
      let combo2
      const listConectividad = listado.map((li, i) => {
        combo1 = comboTipoPerifericoConectividad.filter((tipoPeriferico) => {
          if (li.idTipoMaquina === tipoPeriferico.value){
            return tipoPeriferico
          }
        })
        li.maquina = combo1[0] !== undefined ? i18n.t(combo1[0].label) : null
        return li
      })
      yield put(fetchConectividadSuccess(listConectividad))
      yield put(fetchFiltrarConectividadSuccess(val))
    }
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchFetchNuevaConectividad () {
  yield takeLatest(actionTypes.SUBMIT_NUEVA_CONECTIVIDAD, submitNuevaConectividad)
}

export function * recuperarDatosModalConectividad ({idConectividad}) {
  try {
    let auth = yield select(state => state.auth)
    const registroModificar = yield call(getConectividad, idConectividad)
    let dividirIp
    if (registroModificar.IP && registroModificar.IP !== null){
      dividirIp = registroModificar.IP.split('.')
      registroModificar.ipParte1 = dividirIp[0]
      registroModificar.ipParte2 = dividirIp[1]
      registroModificar.ipParte3 = dividirIp[2]
      registroModificar.ipParte4 = dividirIp[3]
    }
    let combo1
    const comboTipoPerifericoConectividad = yield select(state => state.combos.comboTipoPerifericoConectividad)
      combo1 = comboTipoPerifericoConectividad.filter((tipoPeriferico) => {
        if (tipoPeriferico.value === registroModificar.idTipoMaquina){
          return tipoPeriferico
        }
      })
    if (Object.keys(combo1).length > 0) {
      registroModificar.idTipoMaquina = {value: combo1[0].value, label: i18n.t(combo1[0].label)}
    }
    yield put(recuperarDatosModalConectividadSuccess(registroModificar))
    yield put(initialize('ModalConectividad', registroModificar))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchRecuperarDatosModalConectividad () {
  yield takeLatest(actionTypes.RECUPERAR_DATOS_MODAL_CONECTIVIDAD, recuperarDatosModalConectividad)
}

export function * editarConectividad ({values, onSuccessCallback, onErrorCallback}) {
  try {
    const auth = yield select(state => state.auth)
    let isIPDisponible = true
    isIPDisponible = yield call(comprobarDisponibilidadIPService, {IP: values.IP}, auth.token)
    if(!isIPDisponible) {
      yield call(yesNoModalSaga, {
        modalType: 'ipNoDisponible'
      })
      if (onErrorCallback) yield onErrorCallback()
    } else {
      const list = yield call(editMaquina, values, auth.token)
      yield put(editarConectividadSuccess(list))
      if (list.actualizado === true) {
        yield put(openMensajeAlerta('modificacion'))
        if (onSuccessCallback) yield onSuccessCallback()
      } else {
        yield put(openMensajeAlerta('no-modificacion'))
        if (onErrorCallback) yield onErrorCallback()
      }

      yield put(initialize('ModalConectividad', {activo: true}))
      yield put(closeModalConectividad())
      let val = yield select(state => state.conectividad.filtros)
      const listado = yield call(getFiltrarConectividad, val, auth.token)
      const comboTipoPerifericoConectividad = yield select(state => state.combos.comboTipoPerifericoConectividad)
      let combo1
      let combo2
      const listConectividad = listado.map((li, i) => {
        combo1 = comboTipoPerifericoConectividad.filter((tipoPeriferico) => {
          if (li.idTipoMaquina === tipoPeriferico.value) {
            return tipoPeriferico
          }
        })
        li.maquina = combo1[0] !== undefined ? i18n.t(combo1[0].label) : null
        return li
      })
      yield put(fetchConectividadSuccess(listConectividad))
      yield put(fetchFiltrarConectividadSuccess(val))
    }
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchEditarConectividad () {
  yield takeLatest(actionTypes.EDITAR_CONECTIVIDAD, editarConectividad)
}

export function * duplicarDatosModalConectividad ({idConectividad, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const registroDuplicar = yield call(getConectividad, idConectividad)
    yield put(duplicarDatosModalConectividadSuccess(registroDuplicar))
    if (registroDuplicar.IP && registroDuplicar.IP !== null){
      const dividirIp = registroDuplicar.IP.split('.')
      registroDuplicar.ipParte1 = dividirIp[0]
      registroDuplicar.ipParte2 = dividirIp[1]
      registroDuplicar.ipParte3 = dividirIp[2]
      registroDuplicar.ipParte4 = dividirIp[3]
    }
    let combo1
    const comboTipoPerifericoConectividad = yield select(state => state.combos.comboTipoPerifericoConectividad)
      combo1 = comboTipoPerifericoConectividad.filter((tipoPeriferico) => {
        if (tipoPeriferico.value === registroDuplicar.idTipoMaquina){
          return tipoPeriferico
        }
      })
    if (Object.keys(combo1).length > 0) {
      registroDuplicar.idTipoMaquina = {value: combo1[0].value, label: i18n.t(combo1[0].label)}
    }
    yield put(initialize('ModalConectividad', registroDuplicar))
    if (onSuccessCallback) yield onSuccessCallback()
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchDuplicarDatosModalConectividad () {
  yield takeLatest(actionTypes.DUPLICAR_DATOS_MODAL_CONECTIVIDAD, duplicarDatosModalConectividad)
}

export function * cambiarEstadoConectividad ({idConectividad}) {
  try {
    yield put(openSimpleModal('cambiarEstado'))
    const confirmed = yield call(yesNoModal, {modalType: 'cambiarEstado'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const dataEstado = yield call(getConectividadCambioEstado, idConectividad, null)
      yield put(cambiarEstadoConectividadSuccess(dataEstado))
      if (dataEstado.actualizado === true) {
        yield put(openMensajeAlerta('cambio-estado'))
      } else {
        yield put(openMensajeAlerta('no-cambio-estado'))
      }
      let val = yield select(state => state.conectividad.filtros)
      const listado = yield call(getFiltrarConectividad, val, auth.token)
      const comboTipoPerifericoConectividad = yield select(state => state.combos.comboTipoPerifericoConectividad)
      let combo1
      let combo2
      const listConectividad = listado.map((li, i) => {
        combo1 = comboTipoPerifericoConectividad.filter((tipoPeriferico) => {
          if (li.idTipoMaquina === tipoPeriferico.value){
            return tipoPeriferico
          }
        })
        li.maquina = combo1[0] !== undefined ? i18n.t(combo1[0].label) : null
        return li
      })
      yield put(fetchConectividadSuccess(listConectividad))
      yield put(fetchFiltrarConectividadSuccess(val))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchCambiarEstadoConectividad () {
  yield takeLatest(actionTypes.CAMBIAR_ESTADO_CONECTIVIDAD, cambiarEstadoConectividad)
}

export function * onDeleteConectividad ({idConectividad}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const dataDelete = yield call(deleteConectividad, idConectividad, null)
      if (dataDelete.eliminado === true && dataDelete.asociado === false) {
        yield put(openMensajeAlerta('eliminacion'))
      } else if (dataDelete.eliminado === false && dataDelete.asociado === true) {
        yield put(openMensajeAlerta('no-eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      let val = yield select(state => state.conectividad.filtros)
      const listado = yield call(getFiltrarConectividad, val, auth.token)
      const comboTipoPerifericoConectividad = yield select(state => state.combos.comboTipoPerifericoConectividad)
      let combo1
      let combo2
      const listConectividad = listado.map((li, i) => {
        combo1 = comboTipoPerifericoConectividad.filter((tipoPeriferico) => {
          if (li.idTipoMaquina === tipoPeriferico.value){
            return tipoPeriferico
          }
        })
        li.maquina = combo1[0] !== undefined ? i18n.t(combo1[0].label) : null
        return li
      })
      yield put(fetchConectividadSuccess(listConectividad))
      yield put(fetchFiltrarConectividadSuccess(val))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchOnDeleteConectividad () {
  yield takeLatest(actionTypes.ON_DELETE_CONECTIVIDAD, onDeleteConectividad)
}

export function * cambiarEstadoSeleccionConectividad ({list}) {
  try {
    yield put(openSimpleModal('cambiarEstado'))
    const confirmed = yield call(yesNoModal, {modalType: 'cambiarEstado'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      let cambioEstadoSeleccion = yield call(getCambiarEstadoSeleccionConectividad, {list}, auth.token)
      if (cambioEstadoSeleccion.actualizado) {
        yield put(openMensajeAlerta('cambio-estado'))
      } else {
        yield put(openMensajeAlerta('no-cambio-estado'))
      }
      yield put(deSeleccionarTodosConectividad())
      yield put(initialize('simpleTable'))
      let val = yield select(state => state.conectividad.filtros)
      const listado = yield call(getFiltrarConectividad, val, auth.token)
      const comboTipoPerifericoConectividad = yield select(state => state.combos.comboTipoPerifericoConectividad)
      let combo1
      let combo2
      const listConectividad = listado.map((li, i) => {
        combo1 = comboTipoPerifericoConectividad.filter((tipoPeriferico) => {
          if (li.idTipoMaquina === tipoPeriferico.value){
            return tipoPeriferico
          }
        })
        li.maquina = combo1[0] !== undefined ? i18n.t(combo1[0].label) : null
        return li
      })
      yield put(fetchConectividadSuccess(listConectividad))
      yield put(fetchFiltrarConectividadSuccess(val))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchCambiarEstadoSeleccionConectividad () {
  yield takeLatest(actionTypes.CAMBIAR_ESTADO_SELECCION_CONECTIVIDAD, cambiarEstadoSeleccionConectividad)
}

export function * deleteSeleccionConectividad ({list}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const deleteSelection = yield call(getDeleteSeleccionConectividad, {list}, auth.token)
      if (deleteSelection.eliminado === true && deleteSelection.asociado === false) {
        yield put(openMensajeAlerta('eliminacion'))
      } else if (deleteSelection.eliminado === false && deleteSelection.asociado === true) {
        yield put(openMensajeAlerta('no-eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(deSeleccionarTodosConectividad())
      yield put(initialize('simpleTable'))
      let val = yield select(state => state.conectividad.filtros)
      const listado = yield call(getFiltrarConectividad, val, auth.token)
      const comboTipoPerifericoConectividad = yield select(state => state.combos.comboTipoPerifericoConectividad)
      let combo1
      let combo2
      const listConectividad = listado.map((li, i) => {
        combo1 = comboTipoPerifericoConectividad.filter((tipoPeriferico) => {
          if (li.idTipoMaquina === tipoPeriferico.value){
            return tipoPeriferico
          }
        })
        li.maquina = combo1[0] !== undefined ? i18n.t(combo1[0].label) : null
        return li
      })
      yield put(fetchConectividadSuccess(listConectividad))
      yield put(fetchFiltrarConectividadSuccess(val))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchDeleteSeleccionConectividad () {
  yield takeLatest(actionTypes.DELETE_SELECCION_CONECTIVIDAD, deleteSeleccionConectividad)
}

export function * crearCsvConectividad ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosExcelConectividad = yield call(getCrearCsvConectividad, list, auth.token)
    yield put(crearCsvConectividadSuccess(datosExcelConectividad))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchCrearCsvConectividad () {
  yield takeLatest(actionTypes.CREATE_CSV_CONECTIVIDAD, crearCsvConectividad)
}

export function * imprimirPdfConectividad ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosExcelConectividad = yield call(getImprimirPdfConectividad, list, auth.token)
    yield put(imprimirPdfConectividadSuccess(datosExcelConectividad))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirPdfConectividad () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_CONECTIVIDAD, imprimirPdfConectividad)
}

export function * guardarYnuevoConectividad ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    let isIPDisponible = true
    isIPDisponible = yield call(comprobarDisponibilidadIPService, {IP: values.IP}, auth.token)
    if(!isIPDisponible) {
      yield call(yesNoModalSaga, {
        modalType: 'ipNoDisponible'
      })
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      const list = yield call(getAddConectividad, values, auth.token)
      if (list.insertado === true) {
        yield put(openMensajeAlerta('insercion'))
        if (onSuccessCallback) yield onSuccessCallback()
      } else {
        yield put(openMensajeAlerta('no-insercion'))
        if (onErrorCallback) yield onErrorCallback()
      }

      yield put(initialize('ModalConectividad', {activo: true}))
      let val = yield select(state => state.conectividad.filtros)
      const listado = yield call(getFiltrarConectividad, val, auth.token)
      const comboTipoPerifericoConectividad = yield select(state => state.combos.comboTipoPerifericoConectividad)
      let combo1
      let combo2
      const listConectividad = listado.map((li, i) => {
        combo1 = comboTipoPerifericoConectividad.filter((tipoPeriferico) => {
          if (li.idTipoMaquina === tipoPeriferico.value) {
            return tipoPeriferico
          }
        })
        li.maquina = combo1[0] !== undefined ? i18n.t(combo1[0].label) : null
        return li
      })
      yield put(fetchConectividadSuccess(listConectividad))
      yield put(fetchFiltrarConectividadSuccess(val))
    }
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchGuardarYnuevoConectividad () {
  yield takeLatest(actionTypes.GUARDAR_Y_NUEVO_CONECTIVIDAD, guardarYnuevoConectividad)
}

export function * actualizarConectividadVpn ({values}) {
  try {
    let auth = yield select(state => state.auth)

    let isVPNDisponible = true
    isVPNDisponible = yield call(comprobarDisponibilidadVPNService, {IP: values.IP}, auth.token)
    if(!isVPNDisponible.disponible) {
      let ipsNoSisponibles = isVPNDisponible.maquinas.reduce((acc, curr) => (acc + curr.IP)  + "\n", "")
      yield call(yesNoModalSaga, {
        modalType: 'vpnNoDisponible',
        subMessage: i18n.t('SIMPLE_MODAL.VPN_NO_DISPONIBLE_SUBMENSAJE', {listadoDireccionesIP: ipsNoSisponibles})
      })
    } else {
      const list = yield call(getActualizarConcectividadVpn, values, auth.token)
      if (list.actualizado === true) {
        yield put(openMensajeAlerta('modificacion'))
      } else {
        yield put(openMensajeAlerta('no-modificacion'))
      }

      //actualizar form vpn
      const dataVPN = yield call(getObtenerConcectividadVpn, null)
      let dividirIp
      if (dataVPN.IP !== null) {
        dividirIp = dataVPN.IP.split('.')
        dataVPN.ipParte1 = dividirIp[0]
        dataVPN.ipParte2 = dividirIp[1]
        dataVPN.ipParte3 = dividirIp[2]
        dataVPN.ipParte4 = dividirIp[3]
      }
      yield put(actualizarConectividadVpnSuccess(dataVPN))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchActualizarConectividadVpn () {
  yield takeLatest(actionTypes.ACTUALIZAR_CONECTIVIDAD_VPN, actualizarConectividadVpn)
}

export function * actualizarConectividadVpnVacio ({values}) {
  try {
    let auth = yield select(state => state.auth)
    values.conexionVPN = false
    const list = yield call(getActualizarConcectividadVpn, values, auth.token)
    /*if (list.actualizado === true) {
      yield put(openMensajeAlerta('modificacion'))
    } else {
      yield put(openMensajeAlerta('no-modificacion'))
    }*/

    //actualizar form vpn
    const dataVPN = yield call(getObtenerConcectividadVpn, null)
    let dividirIp
    if (dataVPN.IP !== null){
      dividirIp = dataVPN.IP.split('.')
      dataVPN.ipParte1 = dividirIp[0]
      dataVPN.ipParte2 = dividirIp[1]
      dataVPN.ipParte3 = dividirIp[2]
      dataVPN.ipParte4 = dividirIp[3]
    }
    yield put(actualizarConectividadVpnSuccess(dataVPN))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchActualizarConectividadVpnVacio () {
  yield takeLatest(actionTypes.ACTUALIZAR_CONECTIVIDAD_VPN_VACIO, actualizarConectividadVpnVacio)
}

export function * obternerConectividadVpn ({values}) {
  try {
    let dataVPN = yield call(getObtenerConcectividadVpn, null)
    let dividirIp
    if (!dataVPN) {
      dataVPN = {
        conexionVPN: false
      }
    }
    else if (dataVPN.IP !== null){
      dividirIp = dataVPN.IP.split('.')
      dataVPN.ipParte1 = dividirIp[0]
      dataVPN.ipParte2 = dividirIp[1]
      dataVPN.ipParte3 = dividirIp[2]
      dataVPN.ipParte4 = dividirIp[3]
    }
    yield put(initialize('formConectividad', dataVPN))
    yield put(actualizarConectividadVpnSuccess(dataVPN))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchObternebternerConectividadVpn () {
  yield takeLatest(actionTypes.OBTENER_CONECTIVIDAD_VPN, obternerConectividadVpn)
}

export function * probarConexionVpn ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const purebaConexionVpn = yield call(comprobarDatosVPN, values, auth.token)
    if (purebaConexionVpn.conectado === true) {
      yield put(openMensajeAlerta('conectado'))
    } else {
      yield put(openMensajeAlerta('no-conectado'))
    }
    yield put(probarConexionVpnSuccess(purebaConexionVpn))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchProbarConexionVpn () {
  yield takeLatest(actionTypes.PROBAR_CONEXION_VPN, probarConexionVpn)
}
