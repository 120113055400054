import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import { Warning } from '@material-ui/icons'
import Button from '../comun/button/Button'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import FiltrosTrazabilidadMaterialPage from '../../containers/trazabilidadMaterial/FiltrosTrazabilidadMaterialPage'
import ModalMaterialDisponiblePage from '../../containers/trazabilidadMaterial/ModalMaterialDisponiblePage'
import ModalEditarTrazabilidadMaterialPage from '../../containers/trazabilidadMaterial/ModalEditarTrazabilidadMaterialPage'
import {estadoLote, cantidadNegativa} from '../comun/table/Stilyzers'
import {permisos as permisosConstant} from '../../constants/permisos'

class ModalTrazabilidadMaterial extends Component {
  render () {
    const {
      t, showModal, idCategoria, lotesEscandallo, checkedFiltrosModalTrazabilidadMaterial, disabled, numeroRegistrosPorPagina, paginaActual, showStockColumns = false,
      actions: {closeModalTrazabilidadMaterial, guardarModalTrazabilidadMaterial, openModalEditarTrazabilidadMaterial, deleteTrazabilidadMaterial}
    } = this.props
    let stockInsuficiente = this.props.stockInsuficiente[idCategoria]

    const tKey = 'ANALISIS_EXTRACCIONES.TRAZABILIDAD_MATERIAL.'
    const idElemento = 'idTrazabilidadFront'
    const idForm = 'listadoTrazabilidadMaterialTable'

    const tableTrazabilidadMaterial = {
      id: 'listadoTrazabilidadMaterialTable',
      form: idForm,
      idElemento,
      multiHeader: false,
      hasCheckbox: !disabled && (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ANALISIS] > 1),
      hasOptions: !disabled && (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ANALISIS] > 1),
      mostrarCambioEstado: false,
      mostrarModificar: !disabled && (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ANALISIS] > 1),
      onEdit: (row) => !disabled && openModalEditarTrazabilidadMaterial(row),
      mostrarDuplicar: false,
      mostrarEliminar: !disabled,
      onDelete: (row) =>  !disabled && deleteTrazabilidadMaterial(row),
      mostrarIndicadorColor: false,
      columns: [
        {id: 'nombre', name: t(tKey + 'COLUMNS.PRODUCTO')},
        {id: 'lote', name: t(tKey + 'COLUMNS.LOTE'), stilyzer: estadoLote},
        showStockColumns && {id: 'stock', name: t(tKey + 'COLUMNS.STOCK'), type: 'numero'},
        {id: 'consumido', name: t(tKey + 'COLUMNS.' + (showStockColumns ? 'A_CONSUMIR' : 'CONSUMIDO')), type: 'numero'},
        showStockColumns && {id: 'stockFinal', name: t(tKey + 'COLUMNS.STOCK_FINAL'), type: 'numero', stilyzer: cantidadNegativa},
        {id: 'caducidad', name: t(tKey + 'COLUMNS.FECHA_CADUCIDAD'), type: 'fecha'},
        {id: 'abierto', name: t(tKey + 'COLUMNS.ABIERTO')}
      ],
      rows: lotesEscandallo,
      filtros: !disabled ? <FiltrosTrazabilidadMaterialPage idElemento={idElemento} idForm={idForm} /> : null,
      initialValues: {},
      tablaModal: true,
      idBlockOptions: 1,
      colorearRow: (row) => {
        let classRow = ''
        if (row.recomendado){
          classRow = 'lote-recomendado'
        }
        return classRow
      }
    }
    tableTrazabilidadMaterial.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableTrazabilidadMaterial.initialValues['check' + idForm + row[idElemento]] = checkedFiltrosModalTrazabilidadMaterial
        }
      }
    )
    return (
      <Modal show={showModal} onHide={closeModalTrazabilidadMaterial} bsSize="large" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{t(tKey + 'TITLE')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalMaterialDisponiblePage />
          <ModalEditarTrazabilidadMaterialPage showStockColumns={showStockColumns} />
          <SimpleTablePage {...tableTrazabilidadMaterial} />
          {stockInsuficiente && (
            <p className="error-verracos"><Warning/>{t(tKey + 'MENSAJES.STOCK_INSUFICIENTE')}</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" className="btn btn-primary" onClick={guardarModalTrazabilidadMaterial}>{t(tKey + 'BUTTONS.ACEPTAR')}</Button>
          <Button type="button" className="btn btn-white" onClick={closeModalTrazabilidadMaterial}>{t(tKey + 'BUTTONS.CANCELAR')}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default ModalTrazabilidadMaterial