import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize, change } from 'redux-form'
import i18n from '../../i18n'
import {permisos as permisosConstant} from '../../constants/permisos'
import actionTypes from '../../constants/actions/fichaVerraco/fichaVerraco'
import {fetchFichaVerracoSuccess, asignarCodRazaSuccess, submitNuevaEvolucionSuccess,
  editarEvolucionSuccess, deleteEvolucionSuccess, submitNuevaAlimentacionSucces,
  editarAlimentacionSuccess, deleteAlimentacionSuccess, openModalAlimentacion, closeModalAlimentacion, fetchFiltrarFichaVerracoSuccess, fetchDuplicarVerracoSuccess,
  editarIndexSuccess, deleteIndexSuccess, openModalIndex, closeModalIndex, deSeleccionarTodosFichaVerraco, obtenerPresenciaVerracoSuccess, abrirModalEvolucion, recuperarDatosModalEvolucionSuccess,
  duplicarDatosModalEvolucionSuccess, recuperarDatosModalIndexSuccess, abrirModalIndex, duplicarDatosModalIndexSuccess, crearCsvFichaVerracoSuccess, imprimirPdfFichaVerracoSuccess,
  guardarYnuevoVerracoSuccess, recuperarDatosModalAlimentacionSuccess, duplicarDatosModalAlimentacionSuccess, imprimirDetalleVerracoSuccess} from '../../actions/fichaVerraco/fichaVerraco'
import {submitEditarIndexSuccess, submitEditarEvolucionSuccess} from '../../actions/fichaVerraco/editarVerraco/editarVerraco'
import {openModal, closeModal, openSimpleModal, openMensajeAlerta} from '../../actions/common'
import { yesNoModal } from '../modal/yesNoModal'
import getFichaVerracoList from '../../services/fichaVerraco/getFichaVerracoList'
import addNuevoVerraco from '../../services/fichaVerraco/addNuevoVerraco'
import getAsignarCodRaza from '../../services/fichaVerraco/getAsignarCodRaza'
import addNuevaEvolucion from '../../services/fichaVerraco/addNuevaEvolucion'
import addNuevaAlimentacion from '../../services/fichaVerraco/addNuevaAlimentacion'
import addNuevaIndex from '../../services/fichaVerraco/addNuevaIndex'
import putCambiarVigilado from '../../services/fichaVerraco/putCambiarVigilado'
import deleteVerraco from '../../services/fichaVerraco/deleteVerraco'
import putCambiarVigiladoSeleccion from '../../services/fichaVerraco/putCambiarVigiladoSeleccion'
import deleteSeleccionVerraco from '../../services/fichaVerraco/deleteSeleccionVerraco'
import getFiltrarVerraco from '../../services/fichaVerraco/getFiltrarVerraco'
import getPresenciasVerraco from '../../services/fichaVerraco/getPresenciasVerraco'
import duplicarVerraco from '../../services/fichaVerraco/duplicarVerraco'
import getCrearCsvFichaVerraco from '../../services/fichaVerraco/getCrearCsvFichaVerraco'
import getImprimirPdfFichaVerraco from '../../services/fichaVerraco/getImprimirPdfFichaVerraco'
import {date_formatter, dateTimeZones} from '../../util/formatFunctions'
import getImprimirDetalleVerraco from '../../services/fichaVerraco/getImprimirDetalleVerraco'
import obtenerCodigoRegistroAlimentacion from '../../services/planSanitario/registroAlimentacion/obtenerCodigoRegistroAlimentacion'
import editVerraco from '../../services/fichaVerraco/editarVerraco/editVerraco'

export function * fetchFichaVerraco () {
  try {
    const list = yield call(getFichaVerracoList, null)
    yield put(fetchFichaVerracoSuccess(list))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchFetchFichaVerraco () {
  yield takeLatest(actionTypes.FETCH_FICHA_VERRACO, fetchFichaVerraco)
}

export function * submitNuevoVerraco ({values, history, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const insercionVerraco = yield call(addNuevoVerraco, values, auth.token)
    if (insercionVerraco.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-insercion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    yield put(initialize('nuevoVerraco'))
    history.push({pathname: '/FichaVerraco', state: { from: history.location.pathname }})
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchSubmitNuevoVerraco () {
  yield takeLatest(actionTypes.SUBMIT_NUEVO_VERRACO, submitNuevoVerraco)
}

export function * asignarCodRaza ({codRaza}) {
  try {
    let auth = yield select(state => state.auth)
    const cod = yield call(getAsignarCodRaza, codRaza, auth.token)
    yield put(asignarCodRazaSuccess(cod))
    yield put(change('nuevoVerraco', 'codVerraco', cod))
    yield put(change('editarVerraco', 'codVerraco', cod))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchAsignarCodRaza () {
  yield takeLatest(actionTypes.ASIGNAR_COD_RAZA, asignarCodRaza)
}

export function * abrirModalVacioEvolucion () {
  try {
    // yield put(recuperarDatosModalInstalacionSuccess(null))
    yield put(initialize('ModalEvolucion', {}))
    yield put(abrirModalEvolucion())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchAbrirModalVacioEvolucion () {
  yield takeLatest(actionTypes.ABRIR_MODAL_VACIO_EVOLUCION, abrirModalVacioEvolucion)
}

/*export function * submitNuevaEvolucion ({values}) {
  try {
    let auth = yield select(state => state.auth)
    let numFila = yield select(state => state.fichaVerraco.nuevaEvolucion)
    values.idElemento = numFila.length + 1
    let nuevaEvolucion = yield call(addNuevaEvolucion, values, auth.token)
    yield put(submitNuevaEvolucionSuccess(nuevaEvolucion))
    // yield put(submitEditarEvolucionSuccess(nuevaEvolucion))
    yield put(closeModal())
    yield put(initialize('ModalEvolucion'))
  } catch (error) {

  } finally {

  }
}
export function * watchSubmitNuevaEvolucion () {
  yield takeLatest(actionTypes.SUBMIT_NUEVA_EVOLUCION, submitNuevaEvolucion)
}*/

export function * recuperarDatosModalEvolucion ({idEvolucion}) {
  try {
    const evolucionesAnadidas = yield select(state => state.fichaVerraco.evolucionesAnadidas)
    const evolucion = evolucionesAnadidas.filter((evol) => {
      if (evol.idElemento === idEvolucion){
        return evol
      }
    })
    yield put(initialize('ModalEvolucion', evolucion[0]))
    yield put (recuperarDatosModalEvolucionSuccess())
    yield put(abrirModalEvolucion())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchRecuperarDatosModalEvolucion () {
  yield takeLatest(actionTypes.RECUPERAR_DATOS_MODAL_EVOLUCION, recuperarDatosModalEvolucion)
}

export function * editarEvolucion ({values}) {
  try {
    const evolucionState = yield select(state => state.fichaVerraco.evolucionesAnadidas)
    const evolucionRecuperar = evolucionState.filter((evolucion) => {
      if (evolucion.idElemento === values.idElemento){
        evolucion.fechaEvolucion = values.fechaEvolucion
        evolucion.condicionCorporalVerraco = values.condicionCorporalVerraco
        evolucion.pesoVerraco = values.pesoVerraco
        evolucion.kgPiensoDiaVerraco = values.kgPiensoDiaVerraco
        evolucion.gmdVerraco = values.gmdVerraco
        evolucion.idElemento = values.idElemento
      }
      return evolucion
    })
    yield put(editarEvolucionSuccess(evolucionRecuperar))
  } catch (error) {
    console.log(error)
  } finally {
    /**/
  }
}
export function * watchEditarEvolucion () {
  yield takeLatest(actionTypes.EDITAR_EVOLUCION, editarEvolucion)
}

export function * deleteEvolucion ({idEvolucion}) {
  try {
    const evolucionesAnadidas = yield select(state => state.fichaVerraco.evolucionesAnadidas)
    const evoluciones = evolucionesAnadidas.filter((evolucion) => {
      if (evolucion.idElemento !== idEvolucion){
        return evolucion
      }
    })
    yield put(deleteEvolucionSuccess(evoluciones))
    yield put(closeModal())
  } catch (error) {
    console.log(error)
  } finally {
    /**/
  }
}
export function * watchDeleteEvolucion () {
  yield takeLatest(actionTypes.DELETE_EVOLUCION, deleteEvolucion)
}

export function * duplicarDatosModalEvolucion ({idEvolucion}) {
  try {
    const evolucionesAnadidas = yield select(state => state.fichaVerraco.evolucionesAnadidas)
    const evolucion = evolucionesAnadidas.filter((evol) => {
      if (evol.idElemento === idEvolucion){
        return evol
      }
    })

    const evolucionRecuperar = evolucion.map((evol) => {
      const evolu = {fechaEvolucion: evol.fechaEvolucion, condicionCorporalVerraco: evol.condicionCorporalVerraco, pesoVerraco: evol.pesoVerraco, kgPiensoDiaVerraco: evol.kgPiensoDiaVerraco,
      gmdVerraco: evol.gmdVerraco}
      return evolu
    })

    yield put(initialize('ModalEvolucion', evolucionRecuperar[0]))
    yield put (duplicarDatosModalEvolucionSuccess())
    yield put(abrirModalEvolucion())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchDuplicarDatosModalEvolucion () {
  yield takeLatest(actionTypes.DUPLICAR_DATOS_MODAL_EVOLUCION, duplicarDatosModalEvolucion)
}

export function * deleteSeleccionEvolucion ({lista}) {
  try {
    let filas = yield select(state => state.fichaVerraco.evolucionesAnadidas)
    lista.map((list) => {
      filas = filas.filter((fila) => {
        if (fila.idElemento !== list.idElemento){
          return fila
        }
      })
    })
    yield put(deleteEvolucionSuccess(filas))
    yield put(closeModal())
  } catch (error) {
    console.log(error)
  } finally {
    /**/
  }
}
export function * watchDeleteSeleccionEvolucion () {
  yield takeLatest(actionTypes.DELETE_SELECCION_EVOLUCION, deleteSeleccionEvolucion)
}

export function * abrirModalVacioAlimentacion () {
  try {
    const codigoRegistroAlimentacion = yield call(obtenerCodigoRegistroAlimentacion, null)
    yield put(initialize('ModalAlimentacion', codigoRegistroAlimentacion))
    yield put(openModalAlimentacion())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchAbrirModalVacioAlimentacion () {
  yield takeLatest(actionTypes.ABRIR_MODAL_VACIO_ALIMENTACION, abrirModalVacioAlimentacion)
}

export function * getAlimentacion () {
  try {
    /*const listAlimentacion = yield call(getAlimentacionList, null)
    yield put(getAlimentacionSuccess(listAlimentacion))*/
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchGetAlimentacion () {
  yield takeLatest(actionTypes.GET_ALIMENTACION, getAlimentacion)
}

export function * submitNuevaAlimentacion ({values}) {
  try {
    yield put(submitNuevaAlimentacionSucces(values))
    yield put(closeModalAlimentacion())
    yield put(initialize('ModalAlimentacion'))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchSubmitNuevaAlimentacion () {
  yield takeLatest(actionTypes.SUBMIT_NUEVA_ALIMENTACION, submitNuevaAlimentacion)
}

export function * recuperarDatosModalAlimentacion ({idElemento}) {
  try {
    let auth = yield select(state => state.auth)
    const alimentacionState = yield select(state => state.fichaVerraco.alimentacionAnadidas)
    const alimentacion = alimentacionState.filter((datos) => {
      if (datos.idElemento === idElemento) {
        datos.tipoAlimentacion = {value: datos.idTipoAlimentacion, label: datos.nombreTipoAlimentacion}
        datos.operario = {value: datos.idOperario, label: datos.nombreOperario}
        const diaHoy = new Date()
        if (diaHoy >= new Date(datos.fechaFinal)) {
          datos.terminado = i18n.t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.ALIMENTACION.MODAL.SI')
        } else {
          datos.terminado = i18n.t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.ALIMENTACION.MODAL.NO')
        }
        return datos
      }
    })
    yield put(recuperarDatosModalAlimentacionSuccess(alimentacion[0]))
    yield put(openModalAlimentacion())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchRecuperarDatosModalAlimentacion () {
  yield takeLatest(actionTypes.RECUPERAR_DATOS_MODAL_ALIMENTACION, recuperarDatosModalAlimentacion)
}

export function * editarAlimentacion ({values}) {
  try {
    const alimentacionState = yield select(state => state.fichaVerraco.alimentacionAnadidas)
    const alimentacion = alimentacionState.map((datos) => {
      if (datos.idElemento === values.idElemento){
        datos = values
      }
      return datos
    })
    yield put(editarAlimentacionSuccess(alimentacion))
    yield put(closeModalAlimentacion())
  } catch (error) {
    console.log(error)
  } finally {
    /**/
  }
}
export function * watchEditarAlimentacion () {
  yield takeLatest(actionTypes.EDITAR_ALIMENTACION, editarAlimentacion)
}

export function * deleteAlimentacion ({idElemento}) {
  try {
    const alimentacionState = yield select(state => state.fichaVerraco.alimentacionAnadidas)
    const alimentacion = alimentacionState.filter((datos) => {
      if (datos.idElemento !== idElemento){
        return datos
      }
    })
    yield put(deleteAlimentacionSuccess(alimentacion))
    yield put(closeModalAlimentacion())
  } catch (error) {
    console.log(error)
  } finally {
    /**/
  }
}
export function * watchDeleteAlimentacion () {
  yield takeLatest(actionTypes.DELETE_ALIMENTACION, deleteAlimentacion)
}

export function * duplicarDatosModalAlimentacion ({idElemento}) {
  try {
    const alimentacionState = yield select(state => state.fichaVerraco.alimentacionAnadidas)
    const alimentacion = alimentacionState.filter((datos) => {
      if (datos.idElemento === idElemento) {
        return datos
      }
    })
    yield put(duplicarDatosModalAlimentacionSuccess(alimentacion[0]))
    yield put(initialize('ModalAlimentacion', alimentacion[0]))
    yield put(openModalAlimentacion())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchDuplicarDatosModalAlimentacion () {
  yield takeLatest(actionTypes.DUPLICAR_DATOS_MODAL_ALIMENTACION, duplicarDatosModalAlimentacion)
}

export function * deleteSeleccionAlimentacion ({lista}) {
  try {
    let filas = yield select(state => state.fichaVerraco.alimentacionAnadidas)
    lista.map((list) => {
      filas = filas.filter((fila) => {
        if (fila.idElemento !== list.idElemento){
          return fila
        }
      })
    })
    yield put(deleteAlimentacionSuccess(filas))
    yield put(closeModalAlimentacion())
  } catch (error) {
    console.log(error)
  } finally {
    /**/
  }
}
export function * watchDeleteSeleccionAlimentacion () {
  yield takeLatest(actionTypes.DELETE_SELECCION_ALIMENTACION, deleteSeleccionAlimentacion)
}

export function * guardarYnuevoAlimentacion ({values}) {
  console.log(values)
  try {
    let auth = yield select(state => state.auth)
    let numFila = yield select(state => state.fichaVerraco.alimentacionAnadidas)
    values.idElemento = numFila.length + 1
    let alimentacionAnadidas = yield call(addNuevaAlimentacion, values, auth.token)
    yield put(submitNuevaAlimentacionSucces(alimentacionAnadidas))
    yield put(initialize('ModalAlimentacion'))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchGuardarYnuevoAlimentacion () {
  yield takeLatest(actionTypes.GUARDAR_Y_NUEVO_ALIMENTACION, guardarYnuevoAlimentacion)
}

export function * abrirModalVacioIndex () {
  try {
    // yield put(recuperarDatosModalInstalacionSuccess(null))
    yield put(initialize('ModalIndex', {}))
    yield put(openModalIndex())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchAbrirModalVacioIndex () {
  yield takeLatest(actionTypes.ABRIR_MODAL_VACIO_INDEX, abrirModalVacioIndex)
}

export function * getIndex () {
  try {
    /*const listIndex = yield call(getIndexList, null)
    yield put(getIndexSuccess(listIndex))*/
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchGetIndex () {
  yield takeLatest(actionTypes.GET_INDEX, getIndex)
}

export function * recuperarDatosModalIndex ({idIndex}) {
  try {
    const indexAnadidos = yield select(state => state.fichaVerraco.indexAnadidos)
    const index = indexAnadidos.filter((ind) => {
      if (ind.idElemento === idIndex){
        return ind
      }
    })
    yield put(initialize('ModalIndex', index[0]))
    yield put (recuperarDatosModalIndexSuccess())
    yield put(abrirModalIndex())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchRecuperarDatosModalIndex () {
  yield takeLatest(actionTypes.RECUPERAR_DATOS_MODAL_INDEX, recuperarDatosModalIndex)
}

export function * editarIndex ({values}) {
  try {
    const indexState = yield select(state => state.fichaVerraco.indexAnadidos)
    const indexRecuperar = indexState.filter((index) => {
      if (index.idElemento === values.idElemento){
        index.fechaIndex = values.fechaIndex
        index.index = values.index
        index.idElemento = values.idElemento
      }
      return index
    })
    yield put(editarIndexSuccess(indexRecuperar))
  } catch (error) {
    console.log(error)
  } finally {
    /**/
  }
}
export function * watchEditarIndex () {
  yield takeLatest(actionTypes.EDITAR_INDEX, editarIndex)
}

export function * deleteIndex ({idIndex}) {
  try {
    const indexState = yield select(state => state.fichaVerraco.indexAnadidos)
    const evoluciones = indexState.filter((index) => {
      if (index.idElemento !== idIndex){
        return index
      }
    })
    yield put(deleteIndexSuccess(evoluciones))
    yield put(closeModalIndex())
  } catch (error) {
    console.log(error)
  } finally {
    /**/
  }
}
export function * watchDeleteIndex () {
  yield takeLatest(actionTypes.DELETE_INDEX, deleteIndex)
}

export function * duplicarDatosModalIndex ({idIndex}) {
  try {
    const indexAnadidos = yield select(state => state.fichaVerraco.indexAnadidos)
    const index = indexAnadidos.filter((ind) => {
      if (ind.idElemento === idIndex){
        return ind
      }
    })
    const idexRecuperar = index.map((ind) => {
      const idex = {fechaIndex: ind.fechaIndex, index: ind.index}
      return idex
    })
    yield put(initialize('ModalIndex', idexRecuperar[0]))
    yield put (duplicarDatosModalIndexSuccess())
    yield put(abrirModalIndex())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchDuplicarDatosModalIndex () {
  yield takeLatest(actionTypes.DUPLICAR_DATOS_MODAL_INDEX, duplicarDatosModalIndex)
}

export function * deleteSeleccionIndex ({lista}) {
  try {
    let filas = yield select(state => state.fichaVerraco.indexAnadidos)
    lista.map((list) => {
      filas = filas.filter((fila) => {
        if (fila.idElemento !== list.idElemento){
          return fila
        }
      })
    })
    yield put(deleteIndexSuccess(filas))
    yield put(closeModalIndex())
    yield put(initialize('simpleTable', {}))
  } catch (error) {
    console.log(error)
  } finally {
    /**/
  }
}
export function * watchDeleteSeleccionIndex () {
  yield takeLatest(actionTypes.DELETE_SELECCION_INDEX, deleteSeleccionIndex)
}

export function * cambiarVigilado ({idVerraco}) {
  try {
    yield put(openSimpleModal('cambiarVigilado'))
    const confirmed = yield call(yesNoModal, {modalType: 'cambiarVigilado'})
    if (confirmed) {
      const cambioVigilado = yield call(putCambiarVigilado, idVerraco, null)
      yield put(initialize('FiltrosFichaVerraco'))

      let auth = yield select(state => state.auth)
      let filtros = yield select(state => state.fichaVerraco.filtros)
      const lista = yield call(getFiltrarVerraco, filtros, auth.token)
      yield put(fetchFichaVerracoSuccess(lista))
      yield put(fetchFiltrarFichaVerracoSuccess(filtros))

      if (cambioVigilado.actualizado === true) {
        yield put(openMensajeAlerta('cambio-vigilado'))
      } else {
        yield put(openMensajeAlerta('no-cambio-vigilado'))
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchCambiarVigilado () {
  yield takeLatest(actionTypes.CAMBIAR_VIGILADO, cambiarVigilado)
}

export function * onDeleteVerraco ({idVerraco}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      const eliminarVerraco = yield call(deleteVerraco, idVerraco, null)
      let auth = yield select(state => state.auth)
      let filtros = yield select(state => state.fichaVerraco.filtros)
      const lista = yield call(getFiltrarVerraco, filtros, auth.token)
      yield put(fetchFichaVerracoSuccess(lista))
      yield put(fetchFiltrarFichaVerracoSuccess(filtros))
      if (eliminarVerraco.eliminado === true && eliminarVerraco.asociado === false) {
        yield put(openMensajeAlerta('eliminacion'))
      } else if (eliminarVerraco.eliminado === false && eliminarVerraco.asociado === true){
        yield put(openMensajeAlerta('no-eliminacion'))
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchOnDeleteVerraco () {
  yield takeLatest(actionTypes.ON_DELETE_VERRACO, onDeleteVerraco)
}

export function * cambiarVigiladoSeleccionFichaVerraco ({list}) {
  try {
    yield put(openSimpleModal('cambiarVigilado'))
    const confirmed = yield call(yesNoModal, {modalType: 'cambiarVigilado'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      let cambioVigiladoSeleccion = yield call(putCambiarVigiladoSeleccion, list, auth.token)
      if (cambioVigiladoSeleccion.actualizado === true) {
        yield put(openMensajeAlerta('cambio-vigilado'))
      } else {
        yield put(openMensajeAlerta('no-cambio-vigilado'))
      }
      yield put(deSeleccionarTodosFichaVerraco())
      yield put(initialize('FiltrosFichaVerraco'))
      yield put(initialize('simpleTable'))

      let filtros = yield select(state => state.fichaVerraco.filtros)
      const lista = yield call(getFiltrarVerraco, filtros, auth.token)
      yield put(fetchFichaVerracoSuccess(lista))
      yield put(fetchFiltrarFichaVerracoSuccess(filtros))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchCambiarVigiladoSeleccionFichaVerraco () {
  yield takeLatest(actionTypes.CAMBIAR_VIGILADO_SELECCION_FICHA_VERRACO, cambiarVigiladoSeleccionFichaVerraco)
}

export function * deleteSeleccionFichaVerraco ({list}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const eliminarVerraco = yield call(deleteSeleccionVerraco, list, auth.token)
      if (eliminarVerraco.eliminado === true && eliminarVerraco.asociado === false) {
        yield put(openMensajeAlerta('eliminacion'))
      } else if (eliminarVerraco.eliminado === false && eliminarVerraco.asociado === true){
        yield put(openMensajeAlerta('no-eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(initialize('simpleTable'))
      yield put(deSeleccionarTodosFichaVerraco())
      yield put(initialize('FiltrosFichaVerraco'))
      let filtros = yield select(state => state.fichaVerraco.filtros)
      const lista = yield call(getFiltrarVerraco, filtros, auth.token)
      yield put(fetchFichaVerracoSuccess(lista))
      yield put(fetchFiltrarFichaVerracoSuccess(filtros))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchDeleteSeleccionFichaVerraco () {
  yield takeLatest(actionTypes.DELETE_SELECCION_FICHA_VERRACO, deleteSeleccionFichaVerraco)
}

export function * cerrarModalCambioEstadoVerraco () {
  try {
      yield put(initialize('ModalCambioEstadoVerraco'))
      /*let cambioEstadoVerraco = yield select(state => state.cambioEstadoVerraco)
      cambioEstadoVerraco = []*/
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchCerrarModalCambioEstadoVerraco () {
  yield takeLatest(actionTypes.CERRAR_MODAL_CAMBIO_ESTADO_VERRACO, cerrarModalCambioEstadoVerraco)
}

export function * guardarYnuevoVerraco ({values, duplicar, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const insercionVerraco = yield call(addNuevoVerraco, values, auth.token)
    if (insercionVerraco.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-insercion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    yield put(initialize('nuevoVerraco', {
      propiedad: {value: true, label: i18n.t('FICHA_VERRACO.NUEVO_VERRACO.COMBOS.PROPIO')}
    }))

    let valoresModalCambioEstado = {fechaCambioEstado: new Date()}
    yield put(initialize('ModalVerracoCambioEstado', valoresModalCambioEstado))
    yield put(guardarYnuevoVerracoSuccess())
    if (duplicar === true){
      window.location.href="/#/NuevoVerraco"
    }
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchGuardarYnuevoVerraco () {
  yield takeLatest(actionTypes.GUARDAR_Y_NUEVO_VERRACO, guardarYnuevoVerraco)
}

export function * fetchFiltrarFichaVerraco ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const list = yield call(getFiltrarVerraco, values, auth.token)
    yield put(fetchFichaVerracoSuccess(list))
    yield put(fetchFiltrarFichaVerracoSuccess(values))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchFetchFiltrarFichaVerraco () {
  yield takeLatest(actionTypes.FETCH_FILTRAR_FICHA_VERRACO, fetchFiltrarFichaVerraco)
}

export function * obtenerPresenciaVerraco ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const totalesPresencia = yield call(getPresenciasVerraco, values, auth.token)
    yield put(obtenerPresenciaVerracoSuccess(totalesPresencia))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchObtenerPresenciaVerraco () {
  yield takeLatest(actionTypes.OBTENER_PRESENCIA_VERRACO, obtenerPresenciaVerraco)
}

export function * fetchDuplicarVerraco ({idVerraco}) {
  try {
    let auth = yield select(state => state.auth)
    const listVerraco = yield call(duplicarVerraco, idVerraco.idVerraco, null)
    yield put(fetchDuplicarVerracoSuccess(listVerraco))
    if (listVerraco.verraco.idRaza !== null) {
      listVerraco.verraco.idRaza = {value: listVerraco.verraco.idRaza, label: listVerraco.verraco.nombreRaza}
    } else {
      listVerraco.verraco.idRaza = ''
    }
    if (listVerraco.verraco.idLinea !== null) {
      listVerraco.verraco.idLinea = {value: listVerraco.verraco.idLinea, label: listVerraco.verraco.nombreLinea}
    } else {
      listVerraco.verraco.idLinea = ''
    }
    if (listVerraco.verraco.propiedad === true){
      listVerraco.verraco.propiedad = {value: true, label: 'Propio' }
    } else if(listVerraco.verraco.propiedad === false){
        listVerraco.verraco.propiedad = {value: false, label: 'Alquilado'}
    }
    let comboOperario = yield select(state => state.combos.comboOperarioActivo)
    comboOperario.map((operario) => {
      if (operario.value === listVerraco.verraco.idOperarioColecta){
        listVerraco.verraco.idOperarioColecta = operario
      }
      return listVerraco.verraco.idOperarioColecta
    })
    if (listVerraco.verraco.idTipoDosis !== null) {
      listVerraco.verraco.idTipoDosis = {value: listVerraco.verraco.idTipoDosis, label: listVerraco.verraco.nombreTipoDosis}
    } else {
      listVerraco.verraco.idTipoDosis = ''
    }
    let comboSalaExtraccion = yield select(state => state.combos.comboSalaExtraccionActivo)
    comboSalaExtraccion.map((salaExtraccion) => {
      if (salaExtraccion.value === listVerraco.verraco.idSalaExtraccion){
        listVerraco.verraco.idSalaExtraccion = salaExtraccion
      }
      return listVerraco.verraco.idSalaExtraccion
    })
    let comboTiempoMonta = yield select(state => state.combos.comboTiempoMonta)
    comboTiempoMonta.map((monta) => {
      if (monta.value === listVerraco.verraco.tiempoMonta){
        listVerraco.verraco.tiempoMonta = {value: monta.value, label: i18n.t(monta.label)}
      }
      return listVerraco.verraco.tiempoMonta
    })
    let comboConservacion = yield select(state => state.combos.comboConservacion)
    comboConservacion.map((con) => {
      if (con.value === listVerraco.verraco.conservacion){
        listVerraco.verraco.conservacion = con
      }
      return listVerraco.verraco.conservacion
    })
    let comboCliente = yield select(state => state.combos.comboCliente)
    comboCliente.map((cliente) => {
      if (cliente.value === listVerraco.verraco.idCliente){
        listVerraco.verraco.idCliente = cliente
      }
      return listVerraco.verraco.idCliente
    })
    if (listVerraco.verraco.genHalotano === 'NN'){
      listVerraco.verraco.genHalotano = {value: 'NN', label: 'NN' }
    } else if(listVerraco.verraco.genHalotano === 'NP') {
        listVerraco.verraco.genHalotano = {value: 'NP', label: 'NP'}
    } else if(listVerraco.verraco.genHalotano === 'PP') {
        listVerraco.verraco.genHalotano = {value: 'PP', label: 'PP'}
    }
    if (listVerraco.verraco.fechaNacimiento !== null ){ listVerraco.verraco.fechaNacimiento = listVerraco.verraco.fechaNacimiento}
    if (listVerraco.verraco.fechaCuarentena !== null ){ listVerraco.verraco.fechaCuarentena = listVerraco.verraco.fechaCuarentena}
    if (listVerraco.verraco.fechaEntradaGranja !== null ){ listVerraco.verraco.fechaEntradaGranja = listVerraco.verraco.fechaEntradaGranja}
    yield put(initialize('editarVerraco', listVerraco.verraco))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchFetchDuplicarVerraco () {
  yield takeLatest(actionTypes.FETCH_DUPLICAR_VERRACO, fetchDuplicarVerraco)
}

export function * crearCsvFichaVerraco ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosExcelFichaVerraco = yield call(getCrearCsvFichaVerraco, list, auth.token)
    yield put(crearCsvFichaVerracoSuccess(datosExcelFichaVerraco))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchCrearCsvFichaVerraco () {
  yield takeLatest(actionTypes.CREATE_CSV_FICHA_VERRACO, crearCsvFichaVerraco)
}

export function * imprimirPdfFichaVerraco ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfFichaVerraco = yield call(getImprimirPdfFichaVerraco, list, auth.token)
    yield put(imprimirPdfFichaVerracoSuccess(datosPdfFichaVerraco))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirPdfFichaVerraco () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_FICHA_VERRACO, imprimirPdfFichaVerraco)
}

export function * imprimirDetalleVerraco ({values, idVerraco, onSuccessCallback, onErrorCallback}) {
  try {
    let state = yield select(state => state)
    let soloLectura = (state.auth.permisos !== '-1' && state.auth.permisos[permisosConstant.FICHA_VERRACOS] === 1)

    let confirmed = true
    if (!soloLectura) {
      confirmed = yield call(yesNoModal, {modalType: 'imprimirGuardar'})
    }

    if (confirmed) {
      if (!soloLectura) {
        // guardar modal antes de imprimirlo
        let auth = yield select(state => state.auth)
        yield call(editVerraco, values, auth.token)
        yield put(initialize('nuevoVerraco'))
        // Reset formulario
        yield put(initialize('editarVerraco', values))
      }

      // Imprimir
      const datosDetalleVerraco = yield call(getImprimirDetalleVerraco, idVerraco, null)
      yield put(imprimirDetalleVerracoSuccess(datosDetalleVerraco))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      if (onErrorCallback) yield onErrorCallback()
    }
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirDetalleVerraco () {
  yield takeLatest(actionTypes.IMPRIMIR_DETALLE_VERRACO, imprimirDetalleVerraco)
}