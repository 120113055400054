import React, { Component } from 'react'
import {reduxForm, Field, Fields} from 'redux-form'
import { Row, Col} from 'react-bootstrap'
import InputSelect from '../comun/form/InputSelect'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputText from '../comun/form/InputText'
import Button from '../comun/button/Button'
import InputRangeDatePickerDynamicNames from '../comun/form/InputRangeDatePickerDynamicNames'
import { DoneAll, Refresh, Delete, Launch, Print, AddCircleOutline, FilterList } from '@material-ui/icons'
import {date_formatter, dateTimeZones, date_parser} from '../../util/formatFunctions'
import {permisos as permisosConstant} from '../../constants/permisos'

class FiltrosPedidosVenta extends Component {
   constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelected: false,
      fitrosSeleccionados: []
    }
  }

  UNSAFE_componentWillMount () {
    this.props.actions.comboTipoDosis()
    this.props.actions.comboRuta()
    this.props.initialize({
      fechaEntrada: date_parser(dateTimeZones(this.props.auth.zonaHorariaString), this.props.auth.formaFechaHora)
    })
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.paginaActual !== this.props.paginaActual) {
      this.props.change('seleccionar-todo', false)
      this.props.actions.deseleccionarTodosPedidosVenta()
    }
    if (this.props.datosExcelPedidoVenta !== prevProps.datosExcelPedidoVenta) {
      if (this.props.datosExcelPedidoVenta.excel && this.props.datosExcelPedidoVenta.excel !== null){
        let pdf = this.props.datosExcelPedidoVenta.excel
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.PEDIDO.PEDIDOS_VENTA') + ".xlsx")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
    if (this.props.datosPdfPedidoVenta !== prevProps.datosPdfPedidoVenta) {
      if (this.props.datosPdfPedidoVenta.pdf && this.props.datosPdfPedidoVenta.pdf !== null){
        let pdf = this.props.datosPdfPedidoVenta.pdf
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.PEDIDO.PEDIDOS_VENTA') + ".pdf")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable !== prevProps.formSimpleTable) {
        let contActivos = 0
        if (this.props.formSimpleTable.values) {
          Object.values(this.props.formSimpleTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todo', false)
            this.setState({ imputSelected: false })
            this.props.actions.deseleccionarTodosPedidosVenta()
          }
        }
      }
    }
  }

  fetchFiltrarPedidosVenta (values) {
    this.props.actions.fetchFiltrarPedidosVenta(values)
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  exportarPedidosVenta () {
    this.props.actions.exportarPedidosVenta(this.props.filtros)
  }

  imprimirPedidosVenta () {
    this.props.actions.imprimirPedidosVenta(this.props.filtros)
  }

  intercambiarCheck (value) {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosPedidosVenta()
    } else {
      this.props.actions.deseleccionarTodosPedidosVenta()
    }
  }

  deleteSeleccionPedidosVenta () {
    const itemsSeleccion = this.props.listPedidosVenta.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idElemento': row.idElemento,
          'idPedido': row.idPedido
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true
    });
    this.props.actions.deleteSeleccionPedidosVenta(list)
  }

  quitarFiltros (value) {
    let values = this.props.filtros
    values[value] = null
    this.props.actions.fetchFiltrarPedidosVenta(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }

  render () {
  const {
    t, handleSubmit, filtros,
    actions: {abrirModalPedidosVenta},
    combos: {comboTipoDosis, comboRuta}
  } = this.props

  let comboEstadoPedido = this.props.combos.comboEstadoPedido
  comboEstadoPedido = comboEstadoPedido.map((comboEstado) => {
    return {...comboEstado, label: t(comboEstado.label)}
  })

  return (
      <div>
        <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <form className="custom-form">
            <Field
              colSm={2}
              id="numeroPedido"
              name="numeroPedido"
              controlLabel={t('PEDIDOS.PEDIDOS_VENTA.FILTROS.NUM_PEDIDO')}
              component={InputText}
              onInputChange={handleSubmit(this.fetchFiltrarPedidosVenta.bind(this))}
            />
            <Field
              colSm={2}
              id="cliente"
              name="cliente"
              controlLabel={t('PEDIDOS.PEDIDOS_VENTA.FILTROS.CLIENTE')}
              component={InputText}
              onInputChange={handleSubmit(this.fetchFiltrarPedidosVenta.bind(this))}
            />
            <Field
              colSm={2}
              id="nombreDireccion"
              name="nombreDireccion"
              controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.GRANJA')}
              component={InputText}
              onInputChange={handleSubmit(this.fetchFiltrarPedidosVenta.bind(this))}
            />
            <Field
              colSm={2}
              id="estado"
              name="estado"
              controlLabel={t('PEDIDOS.PEDIDOS_VENTA.FILTROS.ESTADO')}
              component={InputSelect}
              options={comboEstadoPedido}
              valueKey="value"
              labelKey="label"
              customClass='nombre-pedidos-venta'
              onInputChange={handleSubmit(this.fetchFiltrarPedidosVenta.bind(this))}
            />
            <Field
              colSm={2}
              id="tipoDosis"
              name="tipoDosis"
              controlLabel={t('PEDIDOS.PEDIDOS_VENTA.FILTROS.TIPO_DOSIS')}
              component={InputSelect}
              options={comboTipoDosis}
              valueKey="value"
              labelKey="label"
              customClass='nombre-pedidos-venta'
              noPredeterminado={false}
              onInputChange={handleSubmit(this.fetchFiltrarPedidosVenta.bind(this))}
            />
            <Field
              colSm={2}
              id="ruta"
              name="ruta"
              controlLabel={t('PEDIDOS.PEDIDOS_VENTA.FILTROS.RUTA')}
              component={InputSelect}
              options={comboRuta}
              valueKey="value"
              labelKey="label"
              customClass='nombre-pedidos-venta'
              onInputChange={handleSubmit(this.fetchFiltrarPedidosVenta.bind(this))}
            />
            <Fields
              names={['fechaEntrada', 'fechaSalida']}
              component={InputRangeDatePickerDynamicNames}
              colSm={4}
              nameFrom="fechaEntrada"
              nameTo="fechaSalida"
              controlLabel={t('PEDIDOS.PEDIDOS_VENTA.FILTROS.PERIODO')}
              onInputChange={handleSubmit(this.fetchFiltrarPedidosVenta.bind(this))}
            />
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.numeroPedido && filtros.numeroPedido !== null ? (
                      <span className="badge-dark">{t('PEDIDOS.PEDIDOS_VENTA.FILTROS.NUM_PEDIDO') + ': ' + filtros.numeroPedido}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('numeroPedido')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.cliente && filtros.cliente !== null ? (
                      <span className="badge-dark">{t('PEDIDOS.PEDIDOS_VENTA.FILTROS.CLIENTE') + ': ' + filtros.cliente}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('cliente')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.nombreDireccion && filtros.nombreDireccion !== null ? (
                      <span className="badge-dark">{t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.GRANJA') + ': ' + filtros.nombreDireccion}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('nombreDireccion')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.estado && filtros.estado !== null ? (
                      <span className="badge-dark">{t('PEDIDOS.PEDIDOS_VENTA.FILTROS.ESTADO') + ': ' + filtros.estado.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('estado')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.tipoDosis && filtros.tipoDosis !== null ? (
                      <span className="badge-dark">{t('PEDIDOS.PEDIDOS_VENTA.FILTROS.TIPO_DOSIS') + ': ' + filtros.tipoDosis.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('tipoDosis')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.ruta && filtros.ruta !== null ? (
                      <span className="badge-dark">{t('PEDIDOS.PEDIDOS_VENTA.FILTROS.RUTA') + ': ' + filtros.ruta.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('ruta')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    (filtros.fechaEntrada && filtros.fechaSalida && filtros.fechaEntrada !== null && filtros.fechaSalida !== null) ? (
                      <span className="badge-dark">{t('PEDIDOS.PEDIDOS_VENTA.FILTROS.PERIODO') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtros.fechaEntrada, this.props.auth.formaFechaHora, false) + ' - ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtros.fechaSalida, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaSalida')}> x </button></span>
                    ) : (filtros.fechaEntrada && filtros.fechaEntrada !== null) ? (
                      <span className="badge-dark">{t('PEDIDOS.PEDIDOS_VENTA.FILTROS.PERIODO') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtros.fechaEntrada, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaEntrada')}> x </button></span>
                    ) : (filtros.fechaSalida && filtros.fechaSalida !== null) ? (
                      <span className="badge-dark">{t('PEDIDOS.PEDIDOS_VENTA.FILTROS.PERIODO') + ' ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtros.fechaSalida, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaSalida')}> x </button></span>
                    ) : null
                  )
                }
              </div>
            </div>
          </form>
        </div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.PEDIDO] > 1) && [
                <div className="custom-check-w" key="custom-check-w">
                  <Field
                    id="seleccionar-todo"
                    name="seleccionar-todo"
                    colSm={12}
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheck(value)}
                  />
                </div>,
                <div id="block-options" key="block-options" className={this.state.imputSelected === true ? 'button-group buttons-min block-options' : 'button-group buttons-min block-options oculta-action'}>
                  <button type="button" data-toggle="modal" data-target="#delete-modal" className="btn btn-icono" onClick={(e) => this.deleteSeleccionPedidosVenta()}>
                    <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                  </button>
                  <div className="barra-buttons-min"></div>
                </div>
              ]}
              <div className="button-group buttons-min">
                <button type="button" className="btn btn-icono" onClick={(e) => this.exportarPedidosVenta()}>
                  <Launch></Launch> {t('TITULOS_BOTONES.EXPORTAR')}
                </button>
                <button type="button" className="btn btn-icono" onClick={(e) => this.imprimirPedidosVenta()}>
                  <Print></Print> {t('TITULOS_BOTONES.IMPRIMIR')}
                </button>
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#new-edit-modal"
                  className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.PEDIDO] < 2) ? ' disabled' : '')}
                  onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.PEDIDO] > 1) ? abrirModalPedidosVenta : undefined}
                >
                  <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                </button>
              </div>
            </div>
          </Col>
          <Col sm={2} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'} onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t('TITULOS_BOTONES.FILTROS')}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosPedidosVenta',
  initialValues: {
    fechaEntrada: new Date()
  }
})(FiltrosPedidosVenta))