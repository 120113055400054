import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosPedidosVenta from '../../components/pedidosVenta/FiltrosPedidosVenta'
import { abrirModalPedidosVenta, seleccionarTodosPedidosVenta, deseleccionarTodosPedidosVenta, deleteSeleccionPedidosVenta, fetchFiltrarPedidosVenta, exportarPedidosVenta,
  imprimirPedidosVenta} from '../../actions/pedidosVenta/pedidosVenta'
import {comboNumeroPedido, comboCliente, comboTipoDosis, comboRuta} from '../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.pedidosVenta,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formSimpleTable: state.form.simpleTable,
    auth: state.auth,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      abrirModalPedidosVenta,
      seleccionarTodosPedidosVenta,
      deseleccionarTodosPedidosVenta,
      deleteSeleccionPedidosVenta,
      comboNumeroPedido,
      comboCliente,
      comboTipoDosis,
      comboRuta,
      fetchFiltrarPedidosVenta,
      exportarPedidosVenta,
      imprimirPedidosVenta
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosPedidosVenta))