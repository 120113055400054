import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosFoso from '../../components/foso/FiltrosFoso'
import { fetchFiltrarFoso, crearCsvFoso, imprimirPdfFoso } from '../../actions/foso/foso'
import {comboVerraco, comboRaza, comboLineaGenetica, comboUbicacionPadre, comboNombreGrupoVerraco, comboIndex} from '../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.foso,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formSimpleTable: state.form.simpleTable,
    auth: state.auth,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      comboVerraco,
      fetchFiltrarFoso,
      crearCsvFoso,
      imprimirPdfFoso,
      comboRaza,
      comboLineaGenetica,
      comboUbicacionPadre,
      comboNombreGrupoVerraco,
      comboIndex
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosFoso))