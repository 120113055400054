import actionTypes from '../../../constants/actions/planSanitario/registroIntervencionSanitaria/registroIntervencionSanitaria'

export function fetchRegistroIntervencionSanitaria () {
  return {
    type: actionTypes.FETCH_REGISTRO_INTERVENCION_SANITARIA
  }
}

export function fetchRegistroIntervencionSanitariaSuccess (list) {
  return {
    type: actionTypes.FETCH_REGISTRO_INTERVENCION_SANITARIA_SUCCESS,
    list
  }
}

export function abrirModalVacioRegistroIntervencionSanitaria () {
  return {
    type: actionTypes.ABRIR_MODAL_VACIO_REGISTRO_INTERVENCION_SANITARIA
  }
}

export function closeRegistroIntervencion () {
  return {
    type: actionTypes.CLOSE_REGISTRO_INTERVENCION
  }
}

export function seleccionarTodosRegistroIntervencionSanitaria(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_REGISTRO_INTERVENCION_SANITARIA
  }
}

export function deSeleccionarTodosRegistroIntervencionSanitaria(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_REGISTRO_INTERVENCION_SANITARIA
  }
}

export function submitNuevoRegistroIntervencionSanitaria(values, onSuccessCallback, onErrorCallback) {
  return{
    type: actionTypes.SUBMIT_NUEVO_REGISTRO_INTERVENCION_SANITARIA,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function submitNuevoRegistroIntervencionSanitariaSuccess(idIntervencion){
  return{
    type: actionTypes.SUBMIT_NUEVO_REGISTRO_INTERVENCION_SANITARIA_SUCCESS,
    idIntervencion
  }
}

export function recuperarDatosModalRegistroIntervencionSanitaria (idIntervencion) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_REGISTRO_INTERVENCION_SANITARIA,
    idIntervencion
  }
}

export function recuperarDatosModalRegistroIntervencionSanitariaSuccess (data) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_REGISTRO_INTERVENCION_SANITARIA_SUCCESS,
    data
  }
}

export function editarRegistroIntervencionSanitaria (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.EDITAR_REGISTRO_INTERVENCION_SANITARIA,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function duplicarDatosModalRegistroIntervencionSanitaria (idIntervencion, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_REGISTRO_INTERVENCION_SANITARIA,
    idIntervencion,
    onSuccessCallback,
    onErrorCallback
  }
}

export function duplicarDatosModalRegistroIntervencionSanitariaSuccess (dataDuplicar) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_REGISTRO_INTERVENCION_SANITARIA_SUCCESS,
    dataDuplicar
  }
}

export function vaciarDatosModalRegistroIntervencionSanitariaSuccess () {
  return {
    type: actionTypes.VACIAR_DATOS_MODAL_REGISTRO_INTERVENCION_SANITARIA_SUCCESS
  }
}

export function cambiarEstadoRegistroIntervencionSanitaria (idIntervencion) {
  return {
    type: actionTypes.CAMBIAR_ESTADO_REGISTRO_INTERVENCION_SANITARIA,
    idIntervencion
  }
}

export function onDeleteRegistroIntervencionSanitaria (idIntervencion) {
  return {
    type: actionTypes.ON_DELETE_REGISTRO_INTERVENCION_SANITARIA,
    idIntervencion
  }
}

export function cambiarEstadoSeleccionRegistroIntervencionSanitaria(list){
  return {
    type: actionTypes.CAMBIAR_ESTADO_SELECCION_REGISTRO_INTERVENCION_SANITARIA,
    list
  }
}

export function deleteSeleccionRegistroIntervencionSanitaria(list){
  return {
    type: actionTypes.DELETE_SELECCION_REGISTRO_INTERVENCION_SANITARIA,
    list
  }
}

export function guardarYnuevoRegistroIntervencionSanitaria (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.GUARDAR_Y_NUEVO_REGISTRO_INTERVENCION_SANITARIA,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function fetchFiltrarRegistroIntervencionSanitaria (values) {
  return {
    type: actionTypes.FETCH_FILTRAR_REGISTRO_INTERVENCION_SANITARIA,
    values
  }
}

export function fetchFiltrarRegistroIntervencionSanitariaSuccess (filtros) {
  return {
    type: actionTypes.FETCH_FILTRAR_REGISTRO_INTERVENCION_SANITARIA_SUCCESS,
    filtros
  }
}

export function abrirModalVerracoRegistroIntervencionSanitaria () {
  return {
    type: actionTypes.ABRIR_MODAL_VERRACO_REGISTRO_INTERVENCION_SANITARIA
  }
}

export function cerrarModalVerracoRegistroIntervencionSanitaria () {
  return {
    type: actionTypes.CERRAR_MODAL_VERRACO_REGISTRO_INTERVENCION_SANITARIA
  }
}

export function fetchVerracosRegistroIntervencionSanitaria (verracosAnadidos){
  return {
    type: actionTypes.FETCH_VERRACOS_REGISTRO_INTERVENCION_SANITARIA,
    verracosAnadidos
  }
}

export function fetchVerracosRegistroIntervencionSanitariaSuccess (listVerracosEnRegistroIntervencionSanitaria){
  return {
    type: actionTypes.FETCH_VERRACOS_REGISTRO_INTERVENCION_SANITARIA_SUCCESS,
    listVerracosEnRegistroIntervencionSanitaria
  }
}

export function seleccionarTodosModalVerracoRegistroIntervencionSanitaria(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_MODAL_VERRACO_REGISTRO_INTERVENCION_SANITARIA
  }
}

export function deSeleccionarTodosModalVerracoRegistroIntervencionSanitaria(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_MODAL_VERRACO_REGISTRO_INTERVENCION_SANITARIA
  }
}

export function anadirVerracoModalRegistroIntervencionSanitaria (listadoAnadir) {
  return {
    type: actionTypes.ANADIR_VERRACO_MODAL_REGISTRO_INTERVENCION_SANITARIA,
    listadoAnadir
  }
}

export function anadirVerracoModalRegistroIntervencionSanitariaSuccess (verracosAnadidos) {
  return {
    type: actionTypes.ANADIR_VERRACO_MODAL_REGISTRO_INTERVENCION_SANITARIA_SUCCESS,
    verracosAnadidos
  }
}

export function fetchFiltrarVerracoModalRegistroIntervencionSanitaria (values) {
  return {
    type: actionTypes.FETCH_FILTRAR_VERRACO_MODAL_REGISTRO_INTERVENCION_SANITARIA,
    values
  }
}

export function fetchFiltrarVerracoModalRegistroIntervencionSanitariaSuccess (filtrosModalVerracoRegistroIntervencionSanitaria) {
  return {
    type: actionTypes.FETCH_FILTRAR_VERRACO_MODAL_REGISTRO_INTERVENCION_SANITARIA_SUCCESS,
    filtrosModalVerracoRegistroIntervencionSanitaria
  }
}


export function seleccionarTodosModalRegistroIntervencionSanitaria(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_MODAL_REGISTRO_INTERVENCION_SANITARIA
  }
}

export function deSeleccionarTodosModalRegistroIntervencionSanitaria(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_MODAL_REGISTRO_INTERVENCION_SANITARIA
  }
}

export function deleteSeleccionVerracosRegistroIntervencionSanitaria (listVerracosQuitarRegistroIntervencionSanitaria) {
  return {
    type: actionTypes.DELETE_SELECCION_VERRACOS_REGISTRO_INTERVENCION_SANITARIA,
    listVerracosQuitarRegistroIntervencionSanitaria
  }
}

export function onDeleteVerracoAnadidoRegistroIntervencionSanitaria (idVerraco) {
  return {
    type: actionTypes.ON_DELETE_VERRACO_ANADIDO_REGISTRO_INTERVENCION_SANITARIA,
    idVerraco
  }
}

export function onDeleteVerracoAnadidoRegistroIntervencionSanitariaSuccess (verracosAnadidos) {
  return {
    type: actionTypes.ON_DELETE_VERRACO_ANADIDO_REGISTRO_INTERVENCION_SANITARIA_SUCCESS,
    verracosAnadidos
  }
}

export function inicializarVerraco(){
  return {
    type: actionTypes.INICIALIZAR_VERRACO
  }
}

export function crearCsvRegistroIntervencion(list){
  return {
    type: actionTypes.CREATE_CSV_REGISTRO_INTERVENCION,
    list
  }
}

export function crearCsvRegistroIntervencionSuccess(datosExcelRegistroIntervencion){
  return {
    type: actionTypes.CREATE_CSV_REGISTRO_INTERVENCION_SUCCESS,
    datosExcelRegistroIntervencion
  }
}
export function imprimirPdfRegistroIntervencion(list){
  return {
    type: actionTypes.IMPRIMIR_PDF_REGISTRO_INTERVENCION,
    list
  }
}

export function imprimirPdfRegistroIntervencionSuccess(datosPdfRegistroIntervencion){
  return {
    type: actionTypes.IMPRIMIR_PDF_REGISTRO_INTERVENCION_SUCCESS,
    datosPdfRegistroIntervencion
  }
}

export function subirArchivosIntervencion (archivos) {
  return {
    type: actionTypes.SUBIR_ARCHIVOS_INTERVENCION,
    archivos
  }
}

export function obtenerArchivoContentIntervencion(archivo){
  return {
    type: actionTypes.OBTENER_ARCHIVO_CONTENT_INTERVENCION,
    archivo
  }
}

export function eliminarArchvioIntervencion(archivo){
  return {
    type: actionTypes.ELIMINAR_ARCHIVO_INTERVENCION,
    archivo
  }
}

export function obtenerArchivoIntervencionSuccess(archivoDescarga){
  return {
    type: actionTypes.OBTENER_ARCHIVO_INTERVENCION_SUCCESS,
    archivoDescarga
  }
}

export function abrirModalAgendaIntervencionSanitaria(){
  return {
    type: actionTypes.ABRIR_MODAL_AGENDA_INTERVENCION_SANITARIA,
  }
}

export function abrirModalAgendaIntervencionSanitariaSuccess(){
  return {
    type: actionTypes.ABRIR_MODAL_AGENDA_INTERVENCION_SANITARIA_SUCCESS,
  }
}

export function guardarModalAgendaIntervencionSanitaria(values){
  return {
    type: actionTypes.GUARDAR_MODAL_AGENDA_INTERVENCION_SANITARIA,
    values
  }
}

export function guardarModalAgendaIntervencionSanitariaSuccess(){
  return {
    type: actionTypes.GUARDAR_MODAL_AGENDA_INTERVENCION_SANITARIA_SUCCESS,
  }
}

export function cerrarModalAgendaIntervencionSanitaria(){
  return {
    type: actionTypes.CERRAR_MODAL_AGENDA_INTERVENCION_SANITARIA,
  }
}

export function cerrarModalAgendaIntervencionSanitariaSuccess(){
  return {
    type: actionTypes.CERRAR_MODAL_AGENDA_INTERVENCION_SANITARIA_SUCCESS,
  }
}

export function imprimirInformeRegistroIntervencion(values, idIntervencion, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.IMPRIMIR_INFORME_REGISTRO_INTERVENCION,
    values,
    idIntervencion,
    onSuccessCallback,
    onErrorCallback
  }
}

export function fetchRegistroIntervencionSanitariaImprimirGuardarSuccess(list){
  return {
    type: actionTypes.FETCH_REGISTRO_INTERVENCION_SANITARIA_IMPRIMIR_GUARDAR_SUCCESS,
    list
  }
}

export default {
  fetchRegistroIntervencionSanitaria,
  fetchRegistroIntervencionSanitariaSuccess,
  abrirModalVacioRegistroIntervencionSanitaria,
  closeRegistroIntervencion,
  seleccionarTodosRegistroIntervencionSanitaria,
  deSeleccionarTodosRegistroIntervencionSanitaria,
  submitNuevoRegistroIntervencionSanitaria,
  submitNuevoRegistroIntervencionSanitariaSuccess,
  recuperarDatosModalRegistroIntervencionSanitaria,
  recuperarDatosModalRegistroIntervencionSanitariaSuccess,
  editarRegistroIntervencionSanitaria,
  duplicarDatosModalRegistroIntervencionSanitaria,
  duplicarDatosModalRegistroIntervencionSanitariaSuccess,
  vaciarDatosModalRegistroIntervencionSanitariaSuccess,
  cambiarEstadoRegistroIntervencionSanitaria,
  onDeleteRegistroIntervencionSanitaria,
  cambiarEstadoSeleccionRegistroIntervencionSanitaria,
  deleteSeleccionRegistroIntervencionSanitaria,
  guardarYnuevoRegistroIntervencionSanitaria,
  fetchFiltrarRegistroIntervencionSanitaria,
  fetchFiltrarRegistroIntervencionSanitariaSuccess,
  abrirModalVerracoRegistroIntervencionSanitaria,
  cerrarModalVerracoRegistroIntervencionSanitaria,
  fetchVerracosRegistroIntervencionSanitaria,
  fetchVerracosRegistroIntervencionSanitariaSuccess,
  seleccionarTodosModalVerracoRegistroIntervencionSanitaria,
  deSeleccionarTodosModalVerracoRegistroIntervencionSanitaria,
  anadirVerracoModalRegistroIntervencionSanitaria,
  anadirVerracoModalRegistroIntervencionSanitariaSuccess,
  fetchFiltrarVerracoModalRegistroIntervencionSanitaria,
  fetchFiltrarVerracoModalRegistroIntervencionSanitariaSuccess,
  seleccionarTodosModalRegistroIntervencionSanitaria,
  deSeleccionarTodosModalRegistroIntervencionSanitaria,
  deleteSeleccionVerracosRegistroIntervencionSanitaria,
  onDeleteVerracoAnadidoRegistroIntervencionSanitaria,
  onDeleteVerracoAnadidoRegistroIntervencionSanitariaSuccess,
  inicializarVerraco,
  crearCsvRegistroIntervencion,
  crearCsvRegistroIntervencionSuccess,
  imprimirPdfRegistroIntervencion,
  imprimirPdfRegistroIntervencionSuccess,
  subirArchivosIntervencion,
  obtenerArchivoContentIntervencion,
  eliminarArchvioIntervencion,
  obtenerArchivoIntervencionSuccess,
  abrirModalAgendaIntervencionSanitaria,
  abrirModalAgendaIntervencionSanitariaSuccess,
  guardarModalAgendaIntervencionSanitaria,
  guardarModalAgendaIntervencionSanitariaSuccess,
  cerrarModalAgendaIntervencionSanitaria,
  cerrarModalAgendaIntervencionSanitariaSuccess,
  imprimirInformeRegistroIntervencion,
  fetchRegistroIntervencionSanitariaImprimirGuardarSuccess
}
