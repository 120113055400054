import React, { Component } from 'react'
import { Modal, Row } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { required } from '../../../util/validationFunctions'
import { onSubmitFail } from '../../../util/util'
import Button from '../../comun/button/Button'
import ButtonChangeRecord from '../../comun/button/ButtonChangeRecord'
import ButtonLockable from '../../comun/button/ButtonLockable'
import InputText from '../../comun/form/InputText'
import InputDatePicker from '../../comun/form/InputDatePicker'
import InputNumerical from '../../comun/form/InputNumerical'
import './Visita.scss'
import {permisos as permisosConstant} from '../../../constants/permisos'
import {Print} from '@material-ui/icons'

class ModalVisita extends Component {
  submitNuevoVisita (values) {
    return new Promise((resolve, reject) => {
      values.diasUltimaVisita = parseInt(values.diasVisita, 10)
      values.imprimir = false
      if (Object.keys(this.props.data).length > 0){
        this.props.actions.editarVisita(values, resolve, reject)
      } else {
        this.props.actions.submitNuevoVisita(values, resolve, reject)
      }
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }
  imprimirInformeVisita (values) {
    return new Promise((resolve, reject) => {
      if (Object.keys(this.props.data).length > 0){
        this.props.actions.imprimirInformeVisita(values, this.props.data.idVisita, resolve, reject)
      } else {
        reject()
      }
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }
  guardarYnuevoVisita(values){
    return new Promise((resolve, reject) => {
      this.props.actions.guardarYnuevoVisita(values, resolve, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }
  componentDidUpdate (prevProps) {
    if (this.props.ultimaVisita !== prevProps.ultimaVisita) {
      if (this.props.ultimaVisita.pdf && this.props.ultimaVisita.pdf !== null){
        let pdf = this.props.ultimaVisita.pdf
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.PLAN_SANITARIO.VISITAS')+".pdf")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
  }
  cerrarModal () {
    this.props.actions.restartVisitas()
    this.props.actions.cerrarModalVisitas()
  }
  render () {
    const {
      t, handleSubmit, submitting, list, showModalVisitas, data, duplicar,
      actions: {recuperarDatosModalVisita, cerrarModalVisitas}
    } = this.props

    return (
      <Modal show={showModalVisitas} onHide={() => this.cerrarModal()} aria-labelledby="contained-modal-title-lg" className="modal-visitas" backdrop="static">
      <form className="form-Visita">
        <Modal.Header closeButton={!submitting}>
          <Modal.Title>
          {
            Object.keys(data).length > 0 ? (
              t('PLAN_SANITARIO.VISITA.MODAL.TITULO_EDITAR')
            ) : (
              t('PLAN_SANITARIO.VISITA.MODAL.TITULO_NUEVO')
            )
          }
          </Modal.Title>
          {!submitting && data && data.idVisita && (
            <ButtonChangeRecord list={list} idElemento="idVisita" currentId={data.idVisita} getNextRecord={recuperarDatosModalVisita} />
          )}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Field
              id="fechaVisita"
              name="fechaVisita"
              colSm={3}
              component={InputDatePicker}
              controlLabel={t('PLAN_SANITARIO.VISITA.MODAL.FECHA_VISITA') + '*'}
              claseActivo="fecha-visita"
              validate={required}
            />
            <Field
              id="nombreVisita"
              name="nombreVisita"
              colSm={3}
              component={InputText}
              controlLabel={t('PLAN_SANITARIO.VISITA.MODAL.NOMBRE_CONTACTO') + '*'}
              claseActivo="nombre-visita"
              validate={required}
              maxLength={true}
              valorMaxLength={70}
            />
            <Field
              id="dniVisita"
              name="dniVisita"
              colSm={3}
              component={InputText}
              controlLabel={t('PLAN_SANITARIO.VISITA.MODAL.DNI')}
              claseActivo="dni-visita"
              maxLength={true}
              valorMaxLength={20}
            />
            <Field
              id="matriculaVisita"
              name="matriculaVisita"
              colSm={3}
              component={InputText}
              controlLabel={t('PLAN_SANITARIO.VISITA.MODAL.MATRICULA')}
              claseActivo="matricula-visita"
              maxLength={true}
              valorMaxLength={20}
            />
            <Field
              id="empresaVisita"
              name="empresaVisita"
              colSm={3}
              component={InputText}
              controlLabel={t('PLAN_SANITARIO.VISITA.MODAL.EMPRESA') + '*'}
              claseActivo="empresa-visita"
              validate={required}
              maxLength={true}
              valorMaxLength={100}
            />
            <Field
              id="diasVisita"
              name="diasVisita"
              colSm={3}
              component={InputNumerical}
              numDecimales={0}
              controlLabel={t('PLAN_SANITARIO.VISITA.MODAL.DIAS_ULTIMA_VISITA') + '*'}
              claseActivo="dias-ultima-visita"
              validate={required}
            />
            <Field
              id="lugarUltimaVisita"
              name="lugarUltimaVisita"
              colSm={3}
              component={InputText}
              controlLabel={t('PLAN_SANITARIO.VISITA.MODAL.ULTIMO_LUGAR_VISITADO')}
              claseActivo="lugar-ultima-visita"
              maxLength={true}
              valorMaxLength={100}
            />
            <Field
              id="motivoVisita"
              name="motivoVisita"
              colSm={12}
              component={InputText}
              controlLabel={t('PLAN_SANITARIO.VISITA.MODAL.MOTIVO_VISITA')}
              claseActivo="motivo-visita"
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {
            Object.keys(this.props.data).length > 0 && (
              <ButtonLockable
                processing={submitting}
                hasIcon={true}
                showLoaderOnClick={true}
                type="button"
                className="imprimir-visita-modal btn btn-primary btn-sm"
                disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.VISITAS] < 1) || submitting}
                onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.VISITAS] > 0) && !submitting) ? handleSubmit(this.imprimirInformeVisita.bind(this)) : () => {}}
              >
                <Print />{t('PLANING.MODAL.IMPRIMIR')}
              </ButtonLockable>
            )
          }
          {
            !this.props.data && (
              <ButtonLockable
                processing={submitting}
                hasIcon={true}
                showLoaderOnClick={true}
                type="button"
                className="btn btn-primary btn-sm"
                disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.VISITAS] < 2) || submitting}
                onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.VISITAS] > 1) && !submitting) ? handleSubmit(this.guardarYnuevoVisita.bind(this)) : () => {}}
              >
                {t('PLAN_SANITARIO.VISITA.MODAL.GUARDAR_Y_NUEVO')}
              </ButtonLockable>
            )
          }
          <ButtonLockable
            processing={submitting}
            hasIcon={true}
            showLoaderOnClick={true}
            type="button"
            className="btn btn-primary btn-sm"
            disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.VISITAS] < 2) || submitting}
            onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.VISITAS] > 1) && !submitting) ? handleSubmit(this.submitNuevoVisita.bind(this)) : () => {}}
          >
            {t('PLAN_SANITARIO.VISITA.MODAL.GUARDAR')}
          </ButtonLockable>
          <Button
            type="button"
            className={"btn btn-white btn-sm" + (submitting ? ' disabled' : '')}
            onClick={() => !submitting && this.cerrarModal()}
          >
            {t('PLAN_SANITARIO.VISITA.MODAL.CANCELAR')}
          </Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalVisita',
  onSubmitFail
})(ModalVisita))