import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalPlaning from '../../components/planing/ModalPlaning'
import {closeModalPlaning, abrirModalSeleccionarVerracoPlaning, totalesPlaning, asignarVerracos, anadirPlaning, onDeleteVerracoPlaning, seleccionarTodosModalPlaning,
  cargarVerracosPlanificados, deleteSeleccionVerracoPlaning, onDeleteVerracoPlaningSuccess, cerrarModalSeleccionarVerracoPlaning, anadirVerracosPlaning,
  deSeleccionarTodosModalPlaning, obtenerDatosVerracoPlaning, seleccionarTodosSugerenciasPlanning, deSeleccionarTodosSugerenciasPlanning, seleccionarTodosQuitarSugerenciasPlanning, deSeleccionarTodosQuitarSugerenciasPlanning} from '../../actions/planing/planing'
import {comboOperario} from '../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.planing,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formTableVerracoPlaning: state.form.tableVerracoPlaning,
    formTableListadoVerracoPlaning: state.form.tableListadoVerracoPlaning,
    auth: state.auth,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeModalPlaning,
      abrirModalSeleccionarVerracoPlaning,
      totalesPlaning,
      asignarVerracos,
      anadirPlaning,
      onDeleteVerracoPlaning,
      seleccionarTodosModalPlaning,
      deSeleccionarTodosModalPlaning,
      cargarVerracosPlanificados,
      deleteSeleccionVerracoPlaning,
      onDeleteVerracoPlaningSuccess,
      cerrarModalSeleccionarVerracoPlaning,
      anadirVerracosPlaning,
      obtenerDatosVerracoPlaning,
      comboOperario,
      seleccionarTodosSugerenciasPlanning,
      deSeleccionarTodosSugerenciasPlanning,
      seleccionarTodosQuitarSugerenciasPlanning,
      deSeleccionarTodosQuitarSugerenciasPlanning
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalPlaning))