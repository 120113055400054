import actionTypes from '../../constants/actions/perfil/perfil'

export function initialState () {
  return {
    showModalPerfil: false,
    dataPerfil: [],
    showModalCambiarPassPerfil: false,
    dataCambiarPass: []
  }
}

export function abrirModalPerfil (state, {onSuccessCallback, onErrorCallback}) {
  if (onSuccessCallback) onSuccessCallback()
  return {
    ...state,
    showModalPerfil: true
  }
}

export function cerrarModalPerfil (state) {
  return {
    ...state,
    showModalPerfil: false
  }
}

export function fetchPerfilSuccess (state, {dataPerfil}) {
  return {
    ...state,
    dataPerfil: dataPerfil
  }
}

export function abrirCambiarPassPerfil (state) {
  return {
    ...state,
    showModalCambiarPassPerfil: true
  }
}

export function cerrarCambiarPassPerfil (state) {
  return {
    ...state,
    showModalCambiarPassPerfil: false
  }
}

export function cambiarPasswordPerfilSuccess (state, {dataCambiarPass}) {
  return {
    ...state,
    dataCambiarPass: dataCambiarPass
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.ABRIR_MODAL_PERFIL:
      return abrirModalPerfil(state, action)
    case actionTypes.CERRAR_MODAL_PERFIL:
      return cerrarModalPerfil(state, action)
    case actionTypes.FETCH_PERFIL_SUCCESS:
      return fetchPerfilSuccess(state, action)
    case actionTypes.ABRIR_CAMBIAR_PASS_PERFIL:
      return abrirCambiarPassPerfil(state, action)
    case actionTypes.CERRAR_CAMBIAR_PASS_PERFIL:
      return cerrarCambiarPassPerfil(state, action)
    case actionTypes.CAMBIAR_PASSWORD_PERFIL_SUCCESS:
      return cambiarPasswordPerfilSuccess(state, action)
    default:
      return state
  }
}