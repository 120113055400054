import React, { Component } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { required } from '../../util/validationFunctions'
import Button from '../comun/button/Button'
import ButtonLockable from '../comun/button/ButtonLockable'
import InputText from '../comun/form/InputText'
import InputTextArea from '../comun/form/InputTextArea'
import InputCheckBox from '../comun/form/InputCheckBox'
import './PanelControl.scss'
import {permisos as permisosConstant} from '../../constants/permisos'

class ModalTeclasContajePanelControl extends Component {
  constructor () {
    super()
    this.valoresPredefinidosTeclasContaje = this.valoresPredefinidosTeclasContaje.bind(this)
    this.obtenerValoresTeclasContaje = this.obtenerValoresTeclasContaje.bind(this)
    this.guardarTeclasContaje = this.guardarTeclasContaje.bind(this)
  }

  componentDidMount() {
    this.props.actions.obtenerValoresTeclasContaje()
  }
  valoresPredefinidosTeclasContaje () {
    return new Promise((resolve, reject) => {
      this.props.actions.valoresPredefinidosTeclasContaje(resolve, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }
  obtenerValoresTeclasContaje () {
    return new Promise((resolve, reject) => {
      this.props.actions.obtenerValoresTeclasContaje(resolve, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }
  guardarTeclasContaje (values) {
    return new Promise((resolve, reject) => {
      this.props.actions.guardarTeclasContaje(values, resolve, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }
  render () {
    const {
      t, handleSubmit, submitting, showModalTeclasContaje, actions: {cerrarModalTeclasContaje}
    } = this.props
    return (
      <Modal show={showModalTeclasContaje} onHide={cerrarModalTeclasContaje} aria-labelledby="contained-modal-title-lg" className="modal-teclas-contaje" backdrop="static">
      <form className="form-teclas-contaje">
        <Modal.Header closeButton={!submitting}>
          <Modal.Title>
          {
            t('PANEL_CONTROL.MODAL_TECLAS_CONTAJE.TITLE')
          }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={4}></Col>
            <Col sm={4}><span className="teclaContajeMas">+</span></Col>
            <Col sm={4}><span className="teclaContajeMenos">-</span></Col>
            <Col sm={4}>
              <span>{t('PANEL_CONTROL.MODAL_TECLAS_CONTAJE.CABEZAS')}</span>
            </Col>
            <Field
              colSm={4}
              id="sumarCabezas"
              name="sumarCabezas"
              component={InputText}
              customClass='cabeza-mas'
              maxLength={true}
              valorMaxLength={1}
            />
            <Field
              colSm={4}
              id="restarCabezas"
              name="restarCabezas"
              component={InputText}
              customClass='cabeza-menos'
              maxLength={true}
              valorMaxLength={1}
            />
            <Col sm={4}>
              <span>{t('PANEL_CONTROL.MODAL_TECLAS_CONTAJE.COLAS')}</span>
            </Col>
            <Field
              colSm={4}
              id="sumarColas"
              name="sumarColas"
              component={InputText}
              customClass='colas-mas'
              maxLength={true}
              valorMaxLength={1}
            />
            <Field
              colSm={4}
              id="restarColas"
              name="restarColas"
              component={InputText}
              customClass='colas-menos'
              maxLength={true}
              valorMaxLength={1}
            />
            <Col sm={4}>
              <span>{t('PANEL_CONTROL.MODAL_TECLAS_CONTAJE.G_DISTAL')}</span>
            </Col>
            <Field
              colSm={4}
              id="sumarGotaDistal"
              name="sumarGotaDistal"
              component={InputText}
              customClass='g-distal-mas'
              maxLength={true}
              valorMaxLength={1}
            />
            <Field
              colSm={4}
              id="restarGotaDistal"
              name="restarGotaDistal"
              component={InputText}
              customClass='g-distal-menos'
              maxLength={true}
              valorMaxLength={1}
            />
            <Col sm={4}>
              <span>{t('PANEL_CONTROL.MODAL_TECLAS_CONTAJE.G_PROXIMAL')}</span>
            </Col>
            <Field
              colSm={4}
              id="sumarGotaProximal"
              name="sumarGotaProximal"
              component={InputText}
              customClass='g-proximal-mas'
              maxLength={true}
              valorMaxLength={1}
            />
            <Field
              colSm={4}
              id="restarGotaProximal"
              name="restarGotaProximal"
              component={InputText}
              customClass='g-proximal-menos'
              maxLength={true}
              valorMaxLength={1}
            />
            <Col sm={4}>
              <span>{t('PANEL_CONTROL.MODAL_TECLAS_CONTAJE.BUENOS')}</span>
            </Col>
            <Field
              colSm={4}
              id="sumarBuenos"
              name="sumarBuenos"
              component={InputText}
              customClass='buenos-mas'
              maxLength={true}
              valorMaxLength={1}
            />
            <Field
              colSm={4}
              id="restarBuenos"
              name="restarBuenos"
              component={InputText}
              customClass='buenos-menos'
              maxLength={true}
              valorMaxLength={1}
            />
            <Col sm={4}>
              <span>{t('PANEL_CONTROL.MODAL_TECLAS_CONTAJE.CUADROS')}</span>
            </Col>
            <Field
              colSm={4}
              id="sumarCuadros"
              name="sumarCuadros"
              component={InputText}
              customClass='cuadros-mas'
              maxLength={true}
              valorMaxLength={1}
            />
            <Field
              colSm={4}
              id="restarCuadros"
              name="restarCuadros"
              component={InputText}
              customClass='cuadros-menos'
              maxLength={true}
              valorMaxLength={1}
            />
            <Col sm={4}>
              <span>{t('PANEL_CONTROL.MODAL_TECLAS_CONTAJE.ACR_ANORMALES')}</span>
            </Col>
            <Field
              colSm={4}
              id="sumarAcrAnormales"
              name="sumarAcrAnormales"
              component={InputText}
              customClass='acr-anormales-mas'
              maxLength={true}
              valorMaxLength={1}
            />
            <Field
              colSm={4}
              id="restarAcrAnormales"
              name="restarAcrAnormales"
              component={InputText}
              customClass='acr-anormales-menos'
              maxLength={true}
              valorMaxLength={1}
            />
            <Col sm={4}>
              <span>{t('PANEL_CONTROL.MODAL_TECLAS_CONTAJE.VIDEOS')}</span>
            </Col>
            <Field
              colSm={4}
              id="sumarVideo"
              name="sumarVideo"
              component={InputText}
              customClass='video-mas'
              maxLength={true}
              valorMaxLength={1}
            />
            <Field
              colSm={4}
              id="restarVideo"
              name="restarVideo"
              component={InputText}
              customClass='video-menos'
              maxLength={true}
              valorMaxLength={1}
            />
            <Col sm={4}>
              <span>{t('PANEL_CONTROL.MODAL_TECLAS_CONTAJE.CONTADO')}</span>
            </Col>
            <Field
              colSm={4}
              id="sumarContado"
              name="sumarContado"
              component={InputText}
              customClass='contado-mas'
              maxLength={true}
              valorMaxLength={1}
            />
            <Field
              colSm={4}
              id="restarContado"
              name="restarContado"
              component={InputText}
              customClass='contado-menos'
              maxLength={true}
              valorMaxLength={1}
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <ButtonLockable
            processing={submitting}
            hasIcon={true}
            showLoaderOnClick={true}
            type="button"
            className="btn btn-white btn-sm"
            disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.TECLAS_CONTAJE] < 2) || submitting}
            onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TECLAS_CONTAJE] > 1) && !submitting) ? handleSubmit(this.valoresPredefinidosTeclasContaje) : () => {}}
          >
            {t('PANEL_CONTROL.MODAL_TECLAS_CONTAJE.VALORES_PREDEFINIDOS')}
          </ButtonLockable>
          <ButtonLockable
            processing={submitting}
            hasIcon={true}
            showLoaderOnClick={true}
            type="button"
            className="btn btn-white btn-sm"
            disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.TECLAS_CONTAJE] < 2) || submitting}
            onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TECLAS_CONTAJE] > 1) && !submitting) ? handleSubmit(this.obtenerValoresTeclasContaje) : () => {}}
          >
            {t('PANEL_CONTROL.MODAL_TECLAS_CONTAJE.VALORES_ANTERIORES')}
          </ButtonLockable>
          <ButtonLockable
            processing={submitting}
            hasIcon={true}
            showLoaderOnClick={true}
            type="button"
            className="btn btn-primary btn-sm"
            disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.TECLAS_CONTAJE] < 2) || submitting}
            onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TECLAS_CONTAJE] > 1) && !submitting) ? handleSubmit(this.guardarTeclasContaje) : () => {}}
          >
            {t('PANEL_CONTROL.MODAL_TECLAS_CONTAJE.ACEPTAR')}
          </ButtonLockable>
          <Button
            type="button"
            className={"btn btn-white btn-sm" + (submitting ? ' disabled' : '')}
            onClick={() => !submitting && cerrarModalTeclasContaje()}
          >
            {t('PANEL_CONTROL.MODAL_TECLAS_CONTAJE.CERRAR')}
          </Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalTeclasContajePanelControl',
})(ModalTeclasContajePanelControl))