import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ArrowForward } from '@material-ui/icons'
import { required } from '../../util/validationFunctions'
import ButtonLockable from '../comun/button/ButtonLockable'
import InputText from '../comun/form/InputText'
import InputPassword from '../comun/form/InputPassword'
import AlertDanger from '../comun/alert/AlertDanger'
import SimplePage from '../../containers/page/SimplePage'
import './login.scss'

class Login extends Component {
  constructor() {
    super()
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(values) {
    return new Promise((resolve, reject) => {
      this.props.actions.login(values, this.props.adminLogin, resolve, reject)
    })
    .then(() => {})
    .catch(() => {})
  }

  componentDidMount () {
    this.props.actions.comprobarEntorno()
  }

  render () {
    const { t, handleSubmit, submitting, auth: {desautorizado, expiredSession, licenciaExpirada, isCloud}, adminLogin } = this.props
    const t_key = 'LOGIN.'

    return (
      <SimplePage title={t(`${t_key}TITLE`) + (expiredSession ? ' - ' +  t(`${t_key}SESSION_CADUCADA.TITLE`) : '')} className="login-container">
        {expiredSession && (
          <p>
            {t(`${t_key}SESSION_CADUCADA.DESCRIPCION`)}
          </p>
        )}
        {
          licenciaExpirada === true ? (
            <AlertDanger>{t(`${t_key}FORM.LICENCIA_EXPIRADA`)}</AlertDanger>
          ) : (
          desautorizado === true ? ( 
            <AlertDanger>{t(`${t_key}FORM.CREDENCIALES_INCORRECTOS`)}</AlertDanger>
            ) : null
          )
        }
        <div>
          <form onSubmit={handleSubmit(this.onSubmit)}>
            <Row>
              <Field
                id="nombreUsuario"
                name="nombreUsuario"
                colSm={12}
                controlLabel={t(`${t_key}FORM.USUARIO`) + '*'}
                placeholder={t(`${t_key}FORM.USUARIO`)}
                component={InputText}
                validate={required}
              />
            </Row>
            <Row>
              <Field
                id="pswUsuario"
                name="pswUsuario"
                colSm={12}
                controlLabel={t(`${t_key}FORM.PASSWORD`) + '*'}
                placeholder={t(`${t_key}FORM.PASSWORD`)}
                component={InputPassword}
                validate={required}
              />
            </Row>
            {!adminLogin && (
              <Row>
                <Field
                  id="codlicencia"
                  name="codlicencia"
                  colSm={12}
                  controlLabel={t(`${t_key}FORM.COD_LICENCIA`) + '*'}
                  placeholder={t(`${t_key}FORM.COD_LICENCIA`)}
                  component={InputText}
                  validate={required}
                />
              </Row>
            )}
            <Row>
              {!adminLogin && (
                <Col sm={9}>
                  {isCloud ? (
                    <Link to="/RequestResetPassword">{t(`${t_key}FORM.OLVIDAR_PASSWORD`)}</Link>
                  ) : [
                    <span key="ActualizarAplicacion"> | <Link to="/ActualizarAplicacion">{t(`${t_key}FORM.ACTUALIZAR_APLICACION`)}</Link></span>,
                    <span key="RenovarLicencia"> | <Link to="/RenovarLicencia">{t(`${t_key}FORM.RENOVAR_LICENCIA`)}</Link></span>
                  ]}
                </Col>
              )}
              <Col sm={!adminLogin ? 3 : 12}>
                <ButtonLockable processing={submitting} type="submit" className="btn-primary pull-right" replaceChild="ArrowForward" hasIcon={true}>
                  <ArrowForward name="ArrowForward" />
                  {t(`${t_key}FORM.LOGIN`)}
                </ButtonLockable>
              </Col>
            </Row>
          </form>
        </div>
      </SimplePage>
    )
  }
}

export default reduxForm({
  form: 'login'
})(Login)
