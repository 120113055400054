export default {
  FETCH_ALBARANES: 'FETCH_ALBARANES',
  FETCH_ALBARANES_SUCCESS: 'FETCH_ALBARANES_SUCCESS',
  SELECCIONAR_TODOS_ALBARANES: 'SELECCIONAR_TODOS_ALBARANES',
  DESELECCIONAR_TODOS_ALBARANES: 'DESELECCIONAR_TODOS_ALBARANES',
  DUPLICAR_DATOS_MODAL_ALBARAN: 'DUPLICAR_DATOS_MODAL_ALBARAN',
  DUPLICAR_DATOS_MODAL_ALBARAN_SUCCESS: 'DUPLICAR_DATOS_MODAL_ALBARAN_SUCCESS',
  ON_DELETE_ALBARAN: 'ON_DELETE_ALBARAN',
  DELETE_SELECCION_ALBARANES: 'DELETE_SELECCION_ALBARANES',
  CREATE_CSV_ALBARANES: 'CREATE_CSV_ALBARANES',
  IMPRIMIR_PDF_ALBARANES: 'IMPRIMIR_PDF_ALBARANES',
  ABRIR_MODAL_VACIO_ALBARAN: "ABRIR_MODAL_VACIO_ALBARAN",
  ABRIR_MODAL_VACIO_ALBARAN_SUCCESS: "ABRIR_MODAL_VACIO_ALBARAN_SUCCESS",
  ABRIR_DETALLES_ALBARAN: "ABRIR_DETALLES_ALBARAN",
  ABRIR_DETALLES_ALBARAN_SUCCESS: "ABRIR_DETALLES_ALBARAN_SUCCESS",
  CERRAR_DETALLES_ALBARAN: "CERRAR_DETALLES_ALBARAN",
  CERRAR_DETALLES_ALBARAN_SUCCESS: "CERRAR_DETALLES_ALBARAN_SUCCESS",
  EDITAR_ALBARAN: "EDITAR_ALBARAN",
  SELECCIONAR_TODOS_MODAL_ALBARAN_DOSIS: "SELECCIONAR_TODOS_MODAL_ALBARAN_DOSIS",
  DESELECCIONAR_TODOS_MODAL_ALBARAN_DOSIS: "DESELECCIONAR_TODOS_MODAL_ALBARAN_DOSIS",
  DELETE_MODAL_ALBARAN_DOSIS: "DELETE_MODAL_ALBARAN_DOSIS",
  DELETE_SELECCION_MODAL_ALBARAN_DOSIS: "DELETE_SELECCION_MODAL_ALBARAN_DOSIS",
  ABRIR_MODAL_VACIO_ALBARAN_DOSIS: "ABRIR_MODAL_VACIO_ALBARAN_DOSIS",
  ABRIR_MODAL_VACIO_ALBARAN_DOSIS_SUCCESS: "ABRIR_MODAL_VACIO_ALBARAN_DOSIS_SUCCESS",
  ABRIR_DETALLES_ALBARAN_DOSIS: "ABRIR_DETALLES_ALBARAN_DOSIS",
  ABRIR_DETALLES_ALBARAN_DOSIS_SUCCESS: "ABRIR_DETALLES_ALBARAN_DOSIS_SUCCESS",
  CERRAR_DETALLES_ALBARAN_DOSIS: "CERRAR_DETALLES_ALBARAN_DOSIS",
  CERRAR_DETALLES_ALBARAN_DOSIS_SUCCESS: "CERRAR_DETALLES_ALBARAN_DOSIS_SUCCESS",
  EDITAR_ALBARAN_DOSIS: "EDITAR_ALBARAN_DOSIS",
  EDITAR_ALBARAN_DOSIS_SUCCESS: "EDITAR_ALBARAN_DOSIS_SUCCESS",
  CALC_TOTALES_ALBARAN_DOSIS: "CALC_TOTALES_ALBARAN_DOSIS",
  CALC_TOTALES_ALBARAN_DOSIS_SUCCESS: "CALC_TOTALES_ALBARAN_DOSIS_SUCCESS",
  CREAR_ALBARANES_DESDE_PEDIDO_SUCCESS: 'CREAR_ALBARANES_DESDE_PEDIDO_SUCCESS',
  EDITAR_ALBARAN_E_IMPRIMIR_DATOS_ALBARAN: "EDITAR_ALBARAN_E_IMPRIMIR_DATOS_ALBARAN",
  IMPRIMIR_DATOS_ALBARAN: "IMPRIMIR_DATOS_ALBARAN",
  IMPRIMIR_DATOS_ALBARAN_CLIENTE: "IMPRIMIR_DATOS_ALBARAN_CLIENTE"
}