import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Image, Popover, OverlayTrigger } from 'react-bootstrap'
import {AccountCircle, PowerSettingsNew} from '@material-ui/icons'
import ButtonLockable from '../comun/button/ButtonLockable'
import InputSelect from '../comun/form/InputSelect'
import ModalPerfilPage from '../../containers/perfil/ModalPerfilPage'
import NotificacionesPage from '../../containers/notificaciones/NotificacionesPage'
import avatar from '../../assets/images/avatar.png'

class PopoverUser extends Component {
  constructor() {
    super()
    this.state = {
      processingVerPerfil: false,
      processingCerrarSession: false
    }
    this.abrirModalPerfil = this.abrirModalPerfil.bind(this);
    this.logout = this.logout.bind(this);
  }

  abrirModalPerfil() {
    if (!this.state.processingVerPerfil) {
      this.setState({processingVerPerfil: true}, () =>{
        new Promise((resolve, reject) => {
          this.props.actions.abrirModalPerfil(resolve, reject)
        })
        .then(() => {})
        .catch(() => {})
        .finally(() => { if (this._mounted) this.setState({processingVerPerfil: false}) })
      })
    }
  }

  logout() {
    if (!this.state.processingCerrarSession) {
      this.setState({processingCerrarSession: true}, () => {
        new Promise((resolve, reject) => {
          this.props.actions.logout(false, false, resolve, reject)
        })
        .then(() => {})
        .catch(() => {})
        .finally(() => { if (this._mounted) this.setState({processingCerrarSession: false}) })
      })
    }
  }

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  render () {
    const {
      t, auth: {nombreUsuario, pertenece_grupo_admin}
    } = this.props

    // Eliminada debido al aviso de propiedad no reconocida.
    let cleanedProps = {...this.props}
    delete cleanedProps.t

    return (
      <Popover {...cleanedProps} id="popover-trigger-click-root-close" className="content-popover-user" onClick={() => document.body.click()}>
        <div className="user-popover">
          <div className="data-cargo">{pertenece_grupo_admin && t('HEADER.USUARIO')}</div>
          <div className="data-name">{nombreUsuario}</div>
        </div>
        <div className="mi-cuenta-popover">
          <ul>
            <li onClick={this.abrirModalPerfil}>
              <ButtonLockable processing={this.state.processingVerPerfil} className="no-border no-bg full-size text-left" replaceChild="AccountCircle" hasIcon={true}>
                <AccountCircle name="AccountCircle"/>
                <span className="acciones-mi-cuenta-popover">{t('HEADER.MI_CUENTA')}</span>
              </ButtonLockable>
            </li>
            <hr style={{marginBottom: '0px', marginTop: '0px'}} />
            <li onClick={this.logout} >
              <ButtonLockable processing={this.state.processingCerrarSession} className="no-border no-bg full-size text-left" replaceChild="PowerSettingsNew" hasIcon={true}>
                <PowerSettingsNew name="PowerSettingsNew"/>
                <span className="acciones-mi-cuenta-popover">{t('HEADER.CERRAR_SESION')}</span>
              </ButtonLockable>
            </li>
          </ul>
        </div>
      </Popover>
    )
  }
}

class UserSubHeader extends Component {

  componentDidMount () {
    this.props.actions.comboObtenerCentroUsuario()
  }

  componentDidUpdate (prevProps) {
    if (this.props.auth.centro !== prevProps.auth.centro) {
      this.props.actions.obtenerNotificaconesUsuario()
    }
  }

  handleChangeCentro(idCentro){
    if (idCentro && idCentro.value) this.props.actions.cambiarCentro(idCentro.value, this.props.history)
  }

  render () {
    const { t, auth, showModalPerfil, actions, combos:{comboObtenerCentroUsuario} } = this.props
    return (
      <div className="loged">
        {showModalPerfil && <ModalPerfilPage />}
        <form>
          <Field
            colSm={0}
            id="centro"
            name="centro"
            placeholder={t('HEADER.CENTRO_INSEMINACION')}
            component={InputSelect}
            options={comboObtenerCentroUsuario}
            valueKey="value"
            labelKey="label"
            customClass='select-centro-inseminacion'
            onInputChange={(value) => this.handleChangeCentro(value)}
            isClearable={false}
          />
        </form>
        <OverlayTrigger trigger="click" placement="bottom" rootClose overlay={(<PopoverUser t={t} auth={auth} actions={actions} />)}>
          <div className="nav-link dropdown-toggle">
            {
              auth.imagenUsuario !== null ? (
                <Image src={auth.imagenUsuario} className='avatar' responsive />
              ) : (
                <Image src={avatar} className='avatar' responsive />
              )
            }
            <div className="user-data">
              {auth.pertenece_grupo_admin && (<div className="data-cargo">{t('HEADER.USUARIO')}: </div>)}
              <div className="data-name">{auth.nombreUsuario}</div>
            </div>
          </div>
        </OverlayTrigger>
        <NotificacionesPage />
      </div>
    )
  }
}

export default (reduxForm({
  form: 'centro',
})(UserSubHeader))
