import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize, getFormValues} from 'redux-form'
import i18n from '../../../i18n'
import {downloadDocument} from '../../../util/util'
import {date_parser, dateTimeZones} from '../../../util/formatFunctions'
import {permisos as permisosConstant} from '../../../constants/permisos'
import actionTypes from '../../../constants/actions/planSanitario/bioseguridadHigiene/bioseguridadHigiene'
import { fetchBioseguridadHigieneSuccess, fetchLimpiezaSilosSuccess, onDeleteSiloAnadidoSuccess, fetchLimpiezaCorralSuccess, onDeleteCorralAnadidoSuccess, fetchUbicacionSuccess,
  onDeleteUbicacionAnadidoSuccess, fetchVerracoSuccess, onDeleteVerracoAnadidoBioseguridadSuccess, fetchGrupoVerracoBioseguridadSuccess, onDeleteGrupoVerracoAnadidoSuccess,
  recuperarDatosModalBioseguridadHigieneSuccess, duplicarDatosModalBioseguridadHigieneSuccess, vaciarTablas, fetchFiltrarBioseguridadHigieneSuccess, submitAnaliticaAguaSuccess, submitLimpiezaSilosSuccess, submitLimpiezaCorralesSuccess, submitOtrosSuccess, crearCsvBioseguridadSuccess, imprimirPdfBioseguridadSuccess, abrirModalAgendaBioseguridadSuccess, guardarModalAgendaBioseguridadSuccess, cerrarModalAgendaBioseguridadSuccess, closeModalBioseguridadHigiene, openModalBioseguridadHigiene, filtrosUbicacionSuccess, filtrosVerracosSuccess, subirArchivosBioseguridad, obtenerArchivoBioseguridadSuccess} from '../../../actions/planSanitario/bioseguridadHigiene/bioseguridadHigiene'
import {abrirModalAgendaSuccess, fetchAgenda, fetchTareasDia} from '../../../actions/agenda/agenda'
import {openModal, closeModal, openSimpleModal, openMensajeAlerta} from '../../../actions/common'
import getBioseguridadHigieneList from '../../../services/planSanitario/bioseguridadHigiene/getBioseguridadHigieneList'
import getSilos from '../../../services/planSanitario/bioseguridadHigiene/getSilos'
import getCorral from '../../../services/planSanitario/bioseguridadHigiene/getCorral'
import getUbicacion from '../../../services/planSanitario/bioseguridadHigiene/getUbicacion'
import getVerraco from '../../../services/planSanitario/bioseguridadHigiene/getVerraco'
import getGrupoVerraco from '../../../services/planSanitario/bioseguridadHigiene/getGrupoVerraco'
import addBioseguridad from '../../../services/planSanitario/bioseguridadHigiene/addBioseguridad'
import getBioseguridad from '../../../services/planSanitario/bioseguridadHigiene/getBioseguridad'
import editBioseguridad from '../../../services/planSanitario/bioseguridadHigiene/editBioseguridad'
import deleteBioseguridad from '../../../services/planSanitario/bioseguridadHigiene/deleteBioseguridad'
import deleteSeleccionBioSeguridad from '../../../services/planSanitario/bioseguridadHigiene/deleteSeleccionBioSeguridad'
import getfiltrarBioSeguridad from '../../../services/planSanitario/bioseguridadHigiene/getfiltrarBioSeguridad'
import getCrearCsvBioseguridad from '../../../services/planSanitario/bioseguridadHigiene/getCrearCsvBioseguridad'
import getImprimirPdfBioseguridad from '../../../services/planSanitario/bioseguridadHigiene/getImprimirPdfBioseguridad'
import addBioseguridadAgenda from '../../../services/planSanitario/bioseguridadHigiene/addBioseguridadAgenda'
import obtenerArchivoBioseguridad from '../../../services/planSanitario/bioseguridadHigiene/obtenerArchivoBioseguridad'
import imprimirDetalleBioseguridad from '../../../services/planSanitario/bioseguridadHigiene/imprimirDetalleBioseguridad'

// Sagas
import {yesNoModal as yesNoModalSaga} from '../../modal/yesNoModal'

import { obtenerNotificaconesUsuarioSuccess } from '../../../actions/notificaciones/notificaciones'
import getNotificaciones from '../../../services/notificaciones/getNotificaciones'

let getInitialValuesModalBioseguridadHigiene = (state) => {
  return {
    fechaEntrada: dateTimeZones(state.auth.zonaHorariaString),
    fechaSalida: dateTimeZones(state.auth.zonaHorariaString),
    tipoAccion: {value: 1, label: i18n.t('COMUN.COMBOS.TIPO_ACCION.ANALITICA_AGUA')},
    analitica: {
      fecha: dateTimeZones(state.auth.zonaHorariaString)
    },
    limpiezaSilos: {
      fecha: dateTimeZones(state.auth.zonaHorariaString)
    },
    limpiezaCorrales: {
      fecha: dateTimeZones(state.auth.zonaHorariaString),
      fechaCaducidad: dateTimeZones(state.auth.zonaHorariaString)
    },
    tipoOtros: {
      tipo: {value: 1, label: i18n.t('COMUN.COMBOS.' + state.combos.comboTipoOtros[0].label)},
      fechaCaducidad: dateTimeZones(state.auth.zonaHorariaString)
    }
  }
}

export function * abrirModalVacioBioseguridadHigiene () {
  try {
    yield put(initialize('ModalBioseguridadHigiene', {}))
    yield put(openModal())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchAbrirModalVacioBioseguridadHigiene () {
  yield takeLatest(actionTypes.ABRIR_MODAL_VACIO_BIOSEGURIDAD_HIGIENE, abrirModalVacioBioseguridadHigiene)
}

export function * fetchBioseguridadHigiene () {
  try {
  const list = yield call(getBioseguridadHigieneList, null)
    yield put(fetchBioseguridadHigieneSuccess(list))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchFetchBioseguridadHigiene () {
  yield takeLatest(actionTypes.FETCH_BIOSEGURIDAD_HIGIENE, fetchBioseguridadHigiene)
}

export function * fetchLimpiezaSilos (limpiezaSilosAnadidas) {
  try {
  let auth = yield select(state => state.auth)
  const listLimpiezaSilos = yield call(getSilos, limpiezaSilosAnadidas, auth.token)
    yield put(fetchLimpiezaSilosSuccess(listLimpiezaSilos))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchFetchLimpiezaSilos () {
  yield takeLatest(actionTypes.FETCH_LIMPIEZA_SILOS, fetchLimpiezaSilos)
}

export function * abrirModalLimpiezaSilos ({listSiloAnadidos}) {
  try {
    yield put(initialize('ModalLimpiezaSilos', {}))
    let auth = yield select(state => state.auth)
    const listLimpiezaSilos = yield call(getSilos, listSiloAnadidos, auth.token)
    yield put(fetchLimpiezaSilosSuccess(listLimpiezaSilos))
    yield put(openModal())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchAbrirModalLimpiezaSilos () {
  yield takeLatest(actionTypes.ABRIR_MODAL_LIMPIEZA_SILOS, abrirModalLimpiezaSilos)
}

export function * onDeleteSiloAnadido ({idInstalacion}) {
  try {
    const silosNoMostrar = yield select(state => state.bioseguridadHigiene.listSiloAnadidos)
    const silosParaMostrar = silosNoMostrar.filter((silo) => {
      if (silo.idInstalacion !== idInstalacion){
        return silo
      }
    })
    // const silosParaBorrar =yield call(getSilos, silosParaMostrar, auth.token)
    yield put(onDeleteSiloAnadidoSuccess(silosParaMostrar))

    let auth = yield select(state => state.auth)
    if (Object.keys(silosParaMostrar).length > 0) {
      const silosAnadidos =yield call(getSilos, silosParaMostrar, auth.token)
      yield put(fetchLimpiezaSilosSuccess(silosAnadidos))
    } else {
      const silosAnadidos = []
      yield put(fetchLimpiezaSilosSuccess(silosAnadidos))
    }

    /*const listaMostrar = yield select(state => state.bioseguridadHigiene.silosAnadidos)
    const listVerracosEnGrupo = yield call(getSilos, listaMostrar, auth.token)
    yield put(fetchLimpiezaSilosSuccess(listVerracosEnGrupo))*/

  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchOnDeleteSiloAnadido () {
  yield takeLatest(actionTypes.ON_DELETE_SILO_ANADIDO, onDeleteSiloAnadido)
}

export function * deleteSeleccionModalSilos ({list}) {
  try {
    yield put(onDeleteSiloAnadidoSuccess(list))

    let auth = yield select(state => state.auth)
    if (Object.keys(list).length > 0) {
      yield put(fetchLimpiezaSilosSuccess(list))
    } else {
      const listAnadidos = []
      yield put(fetchLimpiezaSilosSuccess(listAnadidos))
    }
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchDeleteSeleccionModalSilos () {
  yield takeLatest(actionTypes.DELETE_SELECCION_MODAL_SILOS, deleteSeleccionModalSilos)
}

export function * fetchLimpiezaCorral ({list, instalacionPadre}) {
  try {
  let auth = yield select(state => state.auth)
  const listLimpiezaCorral = yield call(getCorral, {list: list, instalacionPadre: instalacionPadre}, auth.token)
    yield put(fetchLimpiezaCorralSuccess(listLimpiezaCorral))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchFetchLimpiezaCorral () {
  yield takeLatest(actionTypes.FETCH_LIMPIEZA_CORRAL, fetchLimpiezaCorral)
}

export function * abrirModalLimpiezaCorral ({listCorralAnadidos}) {
  try {
    yield put(initialize('ModalLimpiezaCorral', {}))
    let auth = yield select(state => state.auth)
    const listLimpiezaCorral = yield call(getCorral, {list: listCorralAnadidos}, auth.token)
    yield put(fetchLimpiezaCorralSuccess(listLimpiezaCorral))
    yield put(openModal())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchAbrirModalLimpiezaCorral () {
  yield takeLatest(actionTypes.ABRIR_MODAL_LIMPIEZA_CORRAL, abrirModalLimpiezaCorral)
}

export function * onDeleteCorralAnadido ({idInstalacion}) {
  try {
    const corralNoMostrar = yield select(state => state.bioseguridadHigiene.listCorralAnadidos)
    const corralParaMostrar = corralNoMostrar.filter((corral) => {
      if (corral.idInstalacion !== idInstalacion){
        return corral
      }
    })
    yield put(onDeleteCorralAnadidoSuccess(corralParaMostrar))

    let auth = yield select(state => state.auth)
    if (Object.keys(corralParaMostrar).length > 0) {
      const corralAnadidos =yield call(getCorral, {list: corralParaMostrar}, auth.token)
      yield put(fetchLimpiezaCorralSuccess(corralAnadidos))
    } else {
      const corralAnadidos = []
      yield put(fetchLimpiezaCorralSuccess(corralAnadidos))
    }
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchOnDeleteCorralAnadido () {
  yield takeLatest(actionTypes.ON_DELETE_CORRAL_ANADIDO, onDeleteCorralAnadido)
}

export function * deleteSeleccionModalCorral ({list}) {
  try {
    yield put(onDeleteCorralAnadidoSuccess(list))

    let auth = yield select(state => state.auth)
    if (Object.keys(list).length > 0) {
      yield put(fetchLimpiezaCorralSuccess(list))
    } else {
      const listAnadidos = []
      yield put(fetchLimpiezaCorralSuccess(listAnadidos))
    }
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchDeleteSeleccionModalCorral () {
  yield takeLatest(actionTypes.DELETE_SELECCION_MODAL_CORRAL, deleteSeleccionModalCorral)
}

export function * fetchUbicacion ({ubicacionAnadidas}) {
  try {
    let auth = yield select(state => state.auth)
    const listUbicacion = yield call(getUbicacion, ubicacionAnadidas, auth.token)
    yield put(filtrosUbicacionSuccess(ubicacionAnadidas))
    yield put(fetchUbicacionSuccess(listUbicacion))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchFetchUbicacion () {
  yield takeLatest(actionTypes.FETCH_UBICACION, fetchUbicacion)
}

export function * abrirModalUbicacion ({listUbicacionAnadidos}) {
  try {
    yield put(initialize('ModalUbicacion', {}))
    let auth = yield select(state => state.auth)
    const listUbicacion = yield call(getUbicacion, {list: listUbicacionAnadidos}, auth.token)
    yield put(fetchUbicacionSuccess(listUbicacion))
    // yield put(openModal())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchAbrirModalUbicacion () {
  yield takeLatest(actionTypes.ABRIR_MODAL_UBICACION, abrirModalUbicacion)
}

export function * onDeleteUbicacionAnadido ({idInstalacion}) {
  try {
    const ubicacionNoMostrar = yield select(state => state.bioseguridadHigiene.listUbicacionAnadidos)
    const ubicacionParaMostrar = ubicacionNoMostrar.filter((ubicacion) => {
      if (ubicacion.idInstalacion !== idInstalacion){
        return ubicacion
      }
    })
    yield put(onDeleteUbicacionAnadidoSuccess(ubicacionParaMostrar))

    let auth = yield select(state => state.auth)
    if (Object.keys(ubicacionParaMostrar).length > 0) {
      const ubicacionAnadidos =yield call(getUbicacion, {list: ubicacionParaMostrar}, auth.token)
      yield put(fetchUbicacionSuccess(ubicacionAnadidos))
    } else {
      const ubicacionAnadidos = []
      yield put(fetchUbicacionSuccess(ubicacionAnadidos))
    }
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchOnDeleteUbicacionAnadido () {
  yield takeLatest(actionTypes.ON_DELETE_UBICACION_ANADIDO, onDeleteUbicacionAnadido)
}

export function * deleteSeleccionModalUbicacion ({list}) {
  try {
    yield put(onDeleteUbicacionAnadidoSuccess(list))

    let auth = yield select(state => state.auth)
    if (Object.keys(list).length > 0) {
      yield put(fetchUbicacionSuccess(list))
    } else {
      const listAnadidos = []
      yield put(fetchUbicacionSuccess(listAnadidos))
    }
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchDeleteSeleccionModalUbicacion () {
  yield takeLatest(actionTypes.DELETE_SELECCION_MODAL_UBICACION, deleteSeleccionModalUbicacion)
}

export function * fetchVerraco ({verracoAnadidas}) {
  try {
    let auth = yield select(state => state.auth)
    const listVerraco = yield call(getVerraco, verracoAnadidas, auth.token)
    yield put(fetchVerracoSuccess(listVerraco))
    yield put(filtrosVerracosSuccess(verracoAnadidas))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchFetchVerraco () {
  yield takeLatest(actionTypes.FETCH_VERRACO, fetchVerraco)
}

export function * abrirModalVerraco ({listVerracoAnadidos}) {
  try {
    yield put(initialize('ModalVerraco', {}))
    let auth = yield select(state => state.auth)
    const listVerraco = yield call(getVerraco, {list: listVerracoAnadidos}, auth.token)
    yield put(fetchVerracoSuccess(listVerraco))
    yield put(openModal())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchAbrirModalVerraco () {
  yield takeLatest(actionTypes.ABRIR_MODAL_VERRACO, abrirModalVerraco)
}

export function * onDeleteVerracoAnadidoBioseguridad ({idVerraco}) {
  try {
    const verracoNoMostrar = yield select(state => state.bioseguridadHigiene.listVerracoAnadidos)
    const verracoParaMostrar = verracoNoMostrar.filter((verraco) => {
      if (verraco.idVerraco !== idVerraco){
        return verraco
      }
    })
    yield put(onDeleteVerracoAnadidoBioseguridadSuccess(verracoParaMostrar))

    let auth = yield select(state => state.auth)
    if (Object.keys(verracoParaMostrar).length > 0) {
      const verracoAnadidos =yield call(getVerraco, {list: verracoParaMostrar}, auth.token)
      yield put(fetchVerracoSuccess(verracoAnadidos))
    } else {
      const verracoAnadidos = []
      yield put(fetchVerracoSuccess(verracoAnadidos))
    }
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchOnDeleteVerracoAnadidoBioseguridad () {
  yield takeLatest(actionTypes.ON_DELETE_VERRACO_ANADIDO_BIOSEGURIDAD, onDeleteVerracoAnadidoBioseguridad)
}

export function * deleteSeleccionModalVerraco ({list}) {
  try {
    yield put(onDeleteVerracoAnadidoBioseguridadSuccess(list))

    let auth = yield select(state => state.auth)
    if (Object.keys(list).length > 0) {
      yield put(fetchVerracoSuccess(list))
    } else {
      const listAnadidos = []
      yield put(fetchVerracoSuccess(listAnadidos))
    }
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchDeleteSeleccionModalVerraco () {
  yield takeLatest(actionTypes.DELETE_SELECCION_MODAL_VERRACO, deleteSeleccionModalVerraco)
}

export function * fetchGrupoVerracoBioseguridad (grupoVerracoAnadidas) {
  try {
  let auth = yield select(state => state.auth)
  const listGrupoVerraco = yield call(getGrupoVerraco, grupoVerracoAnadidas, auth.token)
    yield put(fetchGrupoVerracoBioseguridadSuccess(listGrupoVerraco))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchFetchGrupoVerraco () {
  yield takeLatest(actionTypes.FETCH_GRUPO_VERRACO_BIOSEGURIDAD, fetchGrupoVerracoBioseguridad)
}

export function * abrirModalGrupoVerraco ({listGrupoVerracoAnadidos}) {
  try {
    yield put(initialize('ModalGrupoVerraco', {}))
    let auth = yield select(state => state.auth)
    const listGrupoVerraco = yield call(getGrupoVerraco, listGrupoVerracoAnadidos, auth.token)
    yield put(fetchGrupoVerracoBioseguridadSuccess(listGrupoVerraco))
    yield put(openModal())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchAbrirModalGrupoVerraco () {
  yield takeLatest(actionTypes.ABRIR_MODAL_GRUPO_VERRACO, abrirModalGrupoVerraco)
}

export function * onDeleteGrupoVerracoAnadido ({idGrupoVerraco}) {
  try {
    const grupoVerracoNoMostrar = yield select(state => state.bioseguridadHigiene.listGrupoVerracoAnadidos)
    const listGrupoVerracoAnadidos = grupoVerracoNoMostrar.filter((grupoVerraco) => {
      if (grupoVerraco.idGrupoVerraco !== idGrupoVerraco){
        return grupoVerraco
      }
    })
    yield put(onDeleteGrupoVerracoAnadidoSuccess(listGrupoVerracoAnadidos))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchOnDeleteGrupoVerracoAnadidoBioseguridad () {
  yield takeLatest(actionTypes.ON_DELETE_GRUPO_VERRACO_ANADIDO_BIOSEGURIDAD, onDeleteGrupoVerracoAnadido)
}

export function * deleteSeleccionModalGrupoVerraco ({list}) {
  try {
    yield put(onDeleteGrupoVerracoAnadidoSuccess(list))

    let auth = yield select(state => state.auth)
    if (Object.keys(list).length > 0) {
      yield put(fetchGrupoVerracoBioseguridadSuccess(list))
    } else {
      const listAnadidos = []
      yield put(fetchGrupoVerracoBioseguridadSuccess(listAnadidos))
    }
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchDeleteSeleccionModalGrupoVerraco () {
  yield takeLatest(actionTypes.DELETE_SELECCION_MODAL_GRUPO_VERRACO, deleteSeleccionModalGrupoVerraco)
}

export function * submitAnaliticaAgua ({values, onSuccessCallback, onErrorCallback}) {
  try {
    const state = yield select(state => state)
    const escrituraAgenda = (state.auth.permisos === '-1' || state.auth.permisos[permisosConstant.AGENDA] > 1)

    const insercionBioseguridad = yield call(addBioseguridad, values, state.auth.token)
    yield put(submitAnaliticaAguaSuccess(insercionBioseguridad))
    if (insercionBioseguridad.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-insercion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    let filtros = yield select(state => state.bioseguridadHigiene.filtros)
    const list = yield call(getfiltrarBioSeguridad, filtros, state.auth.token)
    yield put(fetchBioseguridadHigieneSuccess(list))
    yield put(fetchFiltrarBioseguridadHigieneSuccess(filtros))

    if (insercionBioseguridad.insertado === true && escrituraAgenda) {
      const confirmed = yield call(yesNoModalSaga, {modalType: 'crearAvisoAgenda'})
      if (confirmed) {
        // yield call(abrirModalAgendaBioseguridad, {tipoAccion: values.tipoAccion})
        yield put(abrirModalAgendaSuccess(values))
        values.fecha = date_parser(values.analitica.fecha, state.auth.formaFechaHora)
        let fechaGuiones = values.fecha.split('-')
        if (fechaGuiones[0] === "" && fechaGuiones[1] === ""){
          values.fecha = fechaGuiones[2] + '-' + fechaGuiones[3] + '-' + fechaGuiones[4]
        }
        values.idBioSeguridadAnalitica = insercionBioseguridad.idBioSeguridad
        yield put(initialize('ModalAgenda', values))
      }
    }

    yield put(closeModalBioseguridadHigiene())
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchSubmitAnaliticaAgua () {
  yield takeLatest(actionTypes.SUBMIT_ANALITICA_AGUA, submitAnaliticaAgua)
}

export function * submitLimpiezaSilos ({values, onSuccessCallback, onErrorCallback}) {
  try {
    const state = yield select(state => state)
    const escrituraAgenda = (state.auth.permisos === '-1' || state.auth.permisos[permisosConstant.AGENDA] > 1)

    const insercionBioseguridad = yield call(addBioseguridad, values, state.auth.token)
    yield put(submitLimpiezaSilosSuccess(insercionBioseguridad))
    if (insercionBioseguridad.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-insercion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    let filtros = yield select(state => state.bioseguridadHigiene.filtros)
    const list = yield call(getfiltrarBioSeguridad, filtros, state.auth.token)
    yield put(fetchBioseguridadHigieneSuccess(list))
    yield put(fetchFiltrarBioseguridadHigieneSuccess(filtros))

    if (insercionBioseguridad.insertado === true && escrituraAgenda) {
      const confirmed = yield call(yesNoModalSaga, {modalType: 'crearAvisoAgenda'})
      if (confirmed) {
        // yield call(abrirModalAgendaBioseguridad, {tipoAccion: values.tipoAccion})
        yield put(abrirModalAgendaSuccess(values))
        values.fecha = date_parser(values.limpiezaSilos.fecha, state.auth.formaFechaHora)
        let fechaGuiones = values.fecha.split('-')
        if (fechaGuiones[0] === "" && fechaGuiones[1] === ""){
          values.fecha = fechaGuiones[2] + '-' + fechaGuiones[3] + '-' + fechaGuiones[4]
        }
        values.idBioSeguridadLimpiezaSilo = insercionBioseguridad.idBioSeguridad
        yield put(initialize('ModalAgenda', values))
      }
    }

    yield put(closeModalBioseguridadHigiene())
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchSubmitLimpiezaSilos () {
  yield takeLatest(actionTypes.SUBMIT_LIMPIEZA_SILOS, submitLimpiezaSilos)
}

export function * submitLimpiezaCorrales ({values, onSuccessCallback, onErrorCallback}) {
  try {
    const state = yield select(state => state)
    const escrituraAgenda = (state.auth.permisos === '-1' || state.auth.permisos[permisosConstant.AGENDA] > 1)

    const insercionBioseguridad = yield call(addBioseguridad, values, state.auth.token)
    yield put(submitLimpiezaCorralesSuccess(insercionBioseguridad))
    let filtros = yield select(state => state.bioseguridadHigiene.filtros)
    const list = yield call(getfiltrarBioSeguridad, filtros, state.auth.token)
    yield put(fetchBioseguridadHigieneSuccess(list))
    yield put(fetchFiltrarBioseguridadHigieneSuccess(filtros))
    if (insercionBioseguridad.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-insercion'))
      if (onErrorCallback) yield onErrorCallback()
    }

    if (insercionBioseguridad.insertado === true && escrituraAgenda) {
      const confirmed = yield call(yesNoModalSaga, {modalType: 'crearAvisoAgenda'})
      if (confirmed) {
        // yield call(abrirModalAgendaBioseguridad, {tipoAccion: values.tipoAccion})
        yield put(abrirModalAgendaSuccess(values))
        values.fecha = values.limpiezaCorrales.fecha
        values.idBioSeguridadLimpiezaCorral = insercionBioseguridad.idBioSeguridad
        yield put(initialize('ModalAgenda', values))
      }
    }

    yield put(closeModalBioseguridadHigiene())
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchSubmitLimpiezaCorrales () {
  yield takeLatest(actionTypes.SUBMIT_LIMPIEZA_CORRALES, submitLimpiezaCorrales)
}

export function * submitOtros ({values, onSuccessCallback, onErrorCallback}) {
  try {
    const state = yield select(state => state)
    const escrituraAgenda = (state.auth.permisos === '-1' || state.auth.permisos[permisosConstant.AGENDA] > 1)

    const insercionBioseguridad = yield call(addBioseguridad, values, state.auth.token)
    yield put(submitOtrosSuccess(insercionBioseguridad))
    let filtros = yield select(state => state.bioseguridadHigiene.filtros)
    const list = yield call(getfiltrarBioSeguridad, filtros, state.auth.token)
    yield put(fetchBioseguridadHigieneSuccess(list))
    yield put(fetchFiltrarBioseguridadHigieneSuccess(filtros))
    if (insercionBioseguridad.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-insercion'))
      if (onErrorCallback) yield onErrorCallback()
    }

    if (insercionBioseguridad.insertado === true && escrituraAgenda) {
      const confirmed = yield call(yesNoModalSaga, {modalType: 'crearAvisoAgenda'})
      if (confirmed) {
        // yield call(abrirModalAgendaBioseguridad, {tipoAccion: values.tipoAccion, identificacion: values.tipoOtros.identificacion})
        yield put(abrirModalAgendaSuccess(values))
        values.fecha = values.tipoOtros.fechaCaducidad
        values.idBioSeguridadOtro = insercionBioseguridad.idBioSeguridad
        yield put(initialize('ModalAgenda', values))
      }
    }

    yield put(closeModalBioseguridadHigiene())
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchSubmitOtros () {
  yield takeLatest(actionTypes.SUBMIT_OTROS, submitOtros)
}

export function * recuperarDatosModalBioseguridadHigiene ({idBioseguridad, idTipoAccion}) {
  try {
    let auth = yield select(state => state.auth)
    const registroModificar = yield call(getBioseguridad, idBioseguridad, idTipoAccion, null)
    if (idTipoAccion === "1") {
      yield put(subirArchivosBioseguridad(registroModificar.archivos))
    }
    yield put(recuperarDatosModalBioseguridadHigieneSuccess(registroModificar))
    yield put(initialize('ModalBioseguridadHigiene', registroModificar))
    yield put(openModalBioseguridadHigiene())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchRecuperarDatosModalBioseguridadHigiene () {
  yield takeLatest(actionTypes.RECUPERAR_DATOS_MODAL_BIOSEGURIDAD_HIGIENE, recuperarDatosModalBioseguridadHigiene)
}

export function * modificarAnaliticaAgua ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const edicionBioseguridad = yield call(editBioseguridad, values, auth.token)
    if (edicionBioseguridad.actualizado === true) {
      yield put(openMensajeAlerta('modificacion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-modificacion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    let filtros = yield select(state => state.bioseguridadHigiene.filtros)
    const list = yield call(getfiltrarBioSeguridad, filtros, auth.token)
    yield put(fetchBioseguridadHigieneSuccess(list))
    yield put(fetchFiltrarBioseguridadHigieneSuccess(filtros))
    yield put(closeModalBioseguridadHigiene())
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchModificarAnaliticaAgua () {
  yield takeLatest(actionTypes.MODIFICAR_ANALITICA_AGUA, modificarAnaliticaAgua)
}

export function * modificarLimpiezaSilos ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const edicionBioseguridad = yield call(editBioseguridad, values, auth.token)
    if (edicionBioseguridad.actualizado === true) {
      yield put(openMensajeAlerta('modificacion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-modificacion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    let filtros = yield select(state => state.bioseguridadHigiene.filtros)
    const list = yield call(getfiltrarBioSeguridad, filtros, auth.token)
    yield put(fetchBioseguridadHigieneSuccess(list))
    yield put(fetchFiltrarBioseguridadHigieneSuccess(filtros))
    yield put(closeModalBioseguridadHigiene())
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchModificarLimpiezaSilos () {
  yield takeLatest(actionTypes.MODIFICAR_LIMPIEZA_SILOS, modificarLimpiezaSilos)
}

export function * modificarLimpiezaCorral ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const edicionBioseguridad = yield call(editBioseguridad, values, auth.token)
    let filtros = yield select(state => state.bioseguridadHigiene.filtros)
    const list = yield call(getfiltrarBioSeguridad, filtros, auth.token)
    yield put(fetchBioseguridadHigieneSuccess(list))
    yield put(fetchFiltrarBioseguridadHigieneSuccess(filtros))
    if (edicionBioseguridad.actualizado === true) {
      yield put(openMensajeAlerta('modificacion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-modificacion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    yield put(closeModalBioseguridadHigiene())
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchModificarLimpiezaCorral () {
  yield takeLatest(actionTypes.MODIFICAR_LIMPIEZA_CORRAL, modificarLimpiezaCorral)
}

export function * modificarOtros ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const edicionBioseguridad = yield call(editBioseguridad, values, auth.token)
    let filtros = yield select(state => state.bioseguridadHigiene.filtros)
    const list = yield call(getfiltrarBioSeguridad, filtros, auth.token)
    yield put(fetchBioseguridadHigieneSuccess(list))
    yield put(fetchFiltrarBioseguridadHigieneSuccess(filtros))
    if (edicionBioseguridad.actualizado === true) {
      yield put(openMensajeAlerta('modificacion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-modificacion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    yield put(closeModalBioseguridadHigiene())
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchModificarOtros () {
  yield takeLatest(actionTypes.MODIFICAR_OTROS, modificarOtros)
}

export function * duplicarDatosModalBioseguridadHigiene ({idBioseguridad, idTipoAccion}) {
  try {
    let auth = yield select(state => state.auth)
    const registroModificar = yield call(getBioseguridad, idBioseguridad, idTipoAccion, null)
    if (registroModificar.tipoOtros) {
      registroModificar.tipoOtros.identificacion = registroModificar.tipoOtros.identificacion + ' - Copia'
    }
    if (idTipoAccion === "1") {
      yield put(subirArchivosBioseguridad(registroModificar.archivos))
    }
    yield put(duplicarDatosModalBioseguridadHigieneSuccess())
    yield put(recuperarDatosModalBioseguridadHigieneSuccess(registroModificar))
    yield put(initialize('ModalBioseguridadHigiene', registroModificar))
    yield put(openModalBioseguridadHigiene())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchDuplicarDatosModalBioseguridadHigiene () {
  yield takeLatest(actionTypes.DUPLICAR_DATOS_MODAL_BIOSEGURIDAD_HIGIENE, duplicarDatosModalBioseguridadHigiene)
}

export function * onDeleteBioseguridadHigiene ({idBioseguridad, idTipoAccion}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModalSaga, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const values = {idBioSeguridad: idBioseguridad, tipoAccion: idTipoAccion}
      const dataDelete = yield call(deleteBioseguridad , values, auth.token)
      if (dataDelete.eliminado === true && dataDelete.asociado === false) {
        yield put(openMensajeAlerta('eliminacion'))
      } else if (dataDelete.eliminado === false && dataDelete.asociado === true) {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      let filtros = yield select(state => state.bioseguridadHigiene.filtros)
      const list = yield call(getfiltrarBioSeguridad, filtros, auth.token)
      yield put(fetchBioseguridadHigieneSuccess(list))
      yield put(fetchFiltrarBioseguridadHigieneSuccess(filtros))
      yield put(fetchAgenda())
      yield put(fetchTareasDia())
      const listNotificaciones = yield call(getNotificaciones, null)
      yield put(obtenerNotificaconesUsuarioSuccess(listNotificaciones))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchOnDeleteBioseguridadHigiene () {
  yield takeLatest(actionTypes.ON_DELETE_BIOSEGURIDAD_HIGIENE, onDeleteBioseguridadHigiene)
}

export function * guardarNuevoAnaliticaAgua ({values, onSuccessCallback, onErrorCallback}) {
  try {
    const state = yield select(state => state)
    const escrituraAgenda = (state.auth.permisos === '-1' || state.auth.permisos[permisosConstant.AGENDA] > 1)

    const insercionBioseguridad = yield call(addBioseguridad, values, state.auth.token)
    let filtros = yield select(state => state.bioseguridadHigiene.filtros)
    const list = yield call(getfiltrarBioSeguridad, filtros, state.auth.token)
    yield put(fetchBioseguridadHigieneSuccess(list))
    yield put(fetchFiltrarBioseguridadHigieneSuccess(filtros))
    if (insercionBioseguridad.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
      if (onSuccessCallback) yield onSuccessCallback()
      let initialValues = getInitialValuesModalBioseguridadHigiene(state)
      yield put(initialize('ModalBioseguridadHigiene', initialValues))
      yield put(vaciarTablas())
    } else {
      yield put(openMensajeAlerta('no-insercion'))
      if (onErrorCallback) yield onErrorCallback()
    }

    if (insercionBioseguridad.insertado === true && escrituraAgenda) {
      yield put(subirArchivosBioseguridad([]))
      const confirmed = yield call(yesNoModalSaga, {modalType: 'crearAvisoAgenda'})
      if (confirmed) {
        yield put(abrirModalAgendaSuccess(values))
        values.fecha = date_parser(values.analitica.fecha, state.auth.formaFechaHora)
        let fechaGuiones = values.fecha.split('-')
        if (fechaGuiones[0] === "" && fechaGuiones[1] === ""){
          values.fecha = fechaGuiones[2] + '-' + fechaGuiones[3] + '-' + fechaGuiones[4]
        }
        values.idBioSeguridadAnalitica = insercionBioseguridad.idBioSeguridad
        yield put(initialize('ModalAgenda', values))
      }
    }
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchGuardarNuevoAnaliticaAgua () {
  yield takeLatest(actionTypes.GUARDAR_NUEVO_ANALITICA_AGUA, guardarNuevoAnaliticaAgua)
}

export function * guardarNuevoLimpiezaSilos ({values, onSuccessCallback, onErrorCallback}) {
  try {
    const state = yield select(state => state)
    const escrituraAgenda = (state.auth.permisos === '-1' || state.auth.permisos[permisosConstant.AGENDA] > 1)

    const insercionBioseguridad = yield call(addBioseguridad, values, state.auth.token)
    let filtros = yield select(state => state.bioseguridadHigiene.filtros)
    const list = yield call(getfiltrarBioSeguridad, filtros, state.auth.token)
    yield put(fetchBioseguridadHigieneSuccess(list))
    yield put(fetchFiltrarBioseguridadHigieneSuccess(filtros))
    if (insercionBioseguridad.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
      if (onSuccessCallback) yield onSuccessCallback()
      let initialValues = getInitialValuesModalBioseguridadHigiene(state)
      yield put(initialize('ModalBioseguridadHigiene', initialValues))
      yield put(vaciarTablas())
    } else {
      yield put(openMensajeAlerta('no-insercion'))
      if (onErrorCallback) yield onErrorCallback()
    }

    if (insercionBioseguridad.insertado === true && escrituraAgenda) {
      const confirmed = yield call(yesNoModalSaga, {modalType: 'crearAvisoAgenda'})
      if (confirmed) {
        yield put(abrirModalAgendaSuccess(values))
        values.fecha = date_parser(values.limpiezaSilos.fecha, state.auth.formaFechaHora) //values.limpiezaSilos.fecha
        let fechaGuiones = values.fecha.split('-')
        if (fechaGuiones[0] === "" && fechaGuiones[1] === ""){
          values.fecha = fechaGuiones[2] + '-' + fechaGuiones[3] + '-' + fechaGuiones[4]
        }
        values.idBioSeguridadLimpiezaSilo = insercionBioseguridad.idBioSeguridad
        yield put(initialize('ModalAgenda', values))
      }
    }
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchGuardarNuevoLimpiezaSilos () {
  yield takeLatest(actionTypes.GUARDAR_NUEVO_LIMPIEZA_SILOS, guardarNuevoLimpiezaSilos)
}

export function * guardarNuevoLimpiezaCorrales ({values, onSuccessCallback, onErrorCallback}) {
  try {
    const state = yield select(state => state)
    const escrituraAgenda = (state.auth.permisos === '-1' || state.auth.permisos[permisosConstant.AGENDA] > 1)

    const insercionBioseguridad = yield call(addBioseguridad, values, state.auth.token)
    let filtros = yield select(state => state.bioseguridadHigiene.filtros)
    const list = yield call(getfiltrarBioSeguridad, filtros, state.auth.token)
    yield put(fetchBioseguridadHigieneSuccess(list))
    yield put(fetchFiltrarBioseguridadHigieneSuccess(filtros))
    if (insercionBioseguridad.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
      if (onSuccessCallback) yield onSuccessCallback()
      let initialValues = getInitialValuesModalBioseguridadHigiene(state)
      yield put(initialize('ModalBioseguridadHigiene', initialValues))
      yield put(vaciarTablas())
    } else {
      yield put(openMensajeAlerta('no-insercion'))
      if (onErrorCallback) yield onErrorCallback()
    }

    if (insercionBioseguridad.insertado === true && escrituraAgenda) {
      const confirmed = yield call(yesNoModalSaga, {modalType: 'crearAvisoAgenda'})
      if (confirmed) {
        yield put(abrirModalAgendaSuccess(values))
        values.fecha = values.limpiezaCorrales.fecha
        values.idBioSeguridadLimpiezaCorral = insercionBioseguridad.idBioSeguridad
        yield put(initialize('ModalAgenda', values))
      }
    }
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchGuardarNuevoLimpiezaCorrales () {
  yield takeLatest(actionTypes.GUARDAR_NUEVO_LIMPIEZA_CORRALES, guardarNuevoLimpiezaCorrales)
}

export function * guardarNuevoOtros ({values, onSuccessCallback, onErrorCallback}) {
  try {
    const state = yield select(state => state)
    const escrituraAgenda = (state.auth.permisos === '-1' || state.auth.permisos[permisosConstant.AGENDA] > 1)

    const insercionBioseguridad = yield call(addBioseguridad, values, state.auth.token)
    let filtros = yield select(state => state.bioseguridadHigiene.filtros)
    const list = yield call(getfiltrarBioSeguridad, filtros, state.auth.token)
    yield put(fetchBioseguridadHigieneSuccess(list))
    yield put(fetchFiltrarBioseguridadHigieneSuccess(filtros))
    if (insercionBioseguridad.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
      if (onSuccessCallback) yield onSuccessCallback()
      let initialValues = getInitialValuesModalBioseguridadHigiene(state)
      yield put(initialize('ModalBioseguridadHigiene', initialValues))
      yield put(vaciarTablas())
    } else {
      yield put(openMensajeAlerta('no-insercion'))
      if (onErrorCallback) yield onErrorCallback()
    }

    if (insercionBioseguridad.insertado === true && escrituraAgenda) {
      const confirmed = yield call(yesNoModalSaga, {modalType: 'crearAvisoAgenda'})
      if (confirmed) {
        yield put(abrirModalAgendaSuccess(values))
        values.fecha = values.tipoOtros.fechaCaducidad
        values.idBioSeguridadOtro = insercionBioseguridad.idBioSeguridad
        yield put(initialize('ModalAgenda', values))
      }
    }
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchGuardarNuevoOtros () {
  yield takeLatest(actionTypes.GUARDAR_NUEVO_OTROS, guardarNuevoOtros)
}

export function * deleteSeleccionBioseguridadHigiene ({list}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModalSaga, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      list = {list: list}
      let dataDeleteSeleccion = yield call(deleteSeleccionBioSeguridad, list, auth.token)
      if (dataDeleteSeleccion.eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(initialize('simpleTable'))
      let filtros = yield select(state => state.bioseguridadHigiene.filtros)
      const lista = yield call(getfiltrarBioSeguridad, filtros, auth.token)
      yield put(fetchBioseguridadHigieneSuccess(lista))
      yield put(fetchFiltrarBioseguridadHigieneSuccess(filtros))
      yield put(vaciarTablas())
      yield put(fetchAgenda())
      yield put(fetchTareasDia())
      const listNotificaciones = yield call(getNotificaciones, null)
      yield put(obtenerNotificaconesUsuarioSuccess(listNotificaciones))
    }
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchDeleteSeleccionBioseguridadHigiene () {
  yield takeLatest(actionTypes.DELETE_SELECCION_BIOSEGURIDAD_HIGIENE, deleteSeleccionBioseguridadHigiene)
}

export function * fetchFiltrarBioseguridadHigiene ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const list = yield call(getfiltrarBioSeguridad, values, auth.token)
    yield put(fetchBioseguridadHigieneSuccess(list))
    yield put(fetchFiltrarBioseguridadHigieneSuccess(values))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchFetchFiltrarBioseguridadHigiene () {
  yield takeLatest(actionTypes.FETCH_FILTRAR_BIOSEGURIDAD_HIGIENE, fetchFiltrarBioseguridadHigiene)
}

export function * crearCsvBioseguridad ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosExcelBioseguridad = yield call(getCrearCsvBioseguridad, list, auth.token)
    yield put(crearCsvBioseguridadSuccess(datosExcelBioseguridad))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchCrearCsvBioseguridad () {
  yield takeLatest(actionTypes.CREATE_CSV_BIOSEGURIDAD, crearCsvBioseguridad)
}
export function * imprimirPdfBioseguridad ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfBioseguridad = yield call(getImprimirPdfBioseguridad, list, auth.token)
    yield put(imprimirPdfBioseguridadSuccess(datosPdfBioseguridad))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirPdfBioseguridad () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_BIOSEGURIDAD, imprimirPdfBioseguridad)
}

export function * abrirModalAgendaBioseguridad ({tipoAccion, identificacion}) {
  try {
    // Abrir el modal y rellenar los campos accion e identificacion. Este último unicamente cuando el accion sea otros.
    yield put(initialize('ModalAgendaBioseguridad', {accion: (tipoAccion ? tipoAccion.label : ''), identificacion}))
    yield put(abrirModalAgendaBioseguridadSuccess({tipoAccion: (tipoAccion ? tipoAccion.value : null)}))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchAbrirModalAgendaBioseguridad () {
  yield takeLatest(actionTypes.ABRIR_MODAL_AGENDA_BIOSEGURIDAD, abrirModalAgendaBioseguridad)
}

export function * guardarModalAgendaBioseguridad ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const insercionBioseguridadAgenda = yield call(addBioseguridadAgenda, values, auth.token)
    if (insercionBioseguridadAgenda.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
    } else {
      yield put(openMensajeAlerta('no-insercion'))
    }
    yield put(guardarModalAgendaBioseguridadSuccess())
    const listNotificaciones = yield call(getNotificaciones, null)
    yield put(obtenerNotificaconesUsuarioSuccess(listNotificaciones))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchGuardarModalAgendaBioseguridad () {
  yield takeLatest(actionTypes.GUARDAR_MODAL_AGENDA_BIOSEGURIDAD, guardarModalAgendaBioseguridad)
}

export function * cerrarModalAgendaBioseguridad () {
  try {
    yield put(cerrarModalAgendaBioseguridadSuccess())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchCerrarModalAgendaBioseguridad () {
  yield takeLatest(actionTypes.CERRAR_MODAL_AGENDA_BIOSEGURIDAD, cerrarModalAgendaBioseguridad)
}

export function * obtenerArchivoContentBioseguridad ({archivo}) {
  try {
    let auth = yield select(state => state.auth)
    const archivoDescarga = yield call(obtenerArchivoBioseguridad, {archivo}, auth.token)
    yield put(obtenerArchivoBioseguridadSuccess(archivoDescarga))
    let href = 'data:application/pdf;base64,' + encodeURIComponent(archivoDescarga.archivo)
    let name = archivoDescarga.nombreArchivo
    downloadDocument(href, name)
  } catch (error) {

  } finally {
    
  }
}
export function * watchObtenerArchivoContentBioseguridad () {
  yield takeLatest(actionTypes.OBTENER_ARCHIVO_CONTENT_BIOSEGURIDAD, obtenerArchivoContentBioseguridad)
}

export function * imprimirInformeBioseguridad ({values, id, tipoAccion, onSuccessCallback, onErrorCallback}) {
  try {
    let state = yield select(state => state)
    let soloLectura = (state.auth.permisos !== '-1' && state.auth.permisos[permisosConstant.BIOSEGURIDAD_HIGIENE] === 1)

    let confirmed = true
    if (!soloLectura) {
      confirmed = yield call(yesNoModalSaga, {modalType: 'imprimirGuardar'})
    }

    if (confirmed) {
      if (!soloLectura) {
        // guardar modal antes de imprimirlo
        yield call(editBioseguridad, values, state.auth.token)

        let filtros = yield select(state => state.bioseguridadHigiene.filtros)
        const list = yield call(getfiltrarBioSeguridad, filtros, state.auth.token)
        yield put(fetchBioseguridadHigieneSuccess(list))
        yield put(fetchFiltrarBioseguridadHigieneSuccess(filtros))

        // Reset formulario
        yield put(initialize('ModalBioseguridadHigiene', values))
      }

      // imprimir
      const archivoDescarga = yield call(imprimirDetalleBioseguridad, id, tipoAccion, null)
      let name = ''
      let href = 'data:application/pdf;base64,' + encodeURIComponent(archivoDescarga.pdf)
      if (tipoAccion === 1) {
        name = i18n.t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.ANALITICA_AGUA.ANALITICA_AGUA') + ".pdf"
      }
      else if (tipoAccion === 2) {
        name = i18n.t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.LIMPIEZA_SILOS_DEPOSITOS.LIMPIEZA_SILOS_DEPOSITOS') + ".pdf"
      }
      else if (tipoAccion === 3) {
        name = i18n.t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.LIMPIEZA_CORRALES.LIMPIEZA_CORRALES') + ".pdf"
      }
      else if (tipoAccion === 4) {
        name = i18n.t('PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE.MODAL.OTROS.OTROS') + ".pdf"
      }
      downloadDocument(href, name)
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(initialize('ModalBioseguridadHigiene', values))
      if (onErrorCallback) yield onErrorCallback()
    }
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
  } finally {
    
  }
}
export function * watchImprimirInformeBioseguridad () {
  yield takeLatest(actionTypes.IMPRIMIR_INFORME_BIOSEGURIDAD, imprimirInformeBioseguridad)
}
