import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize} from 'redux-form'
import { Warning } from '@material-ui/icons'
import i18n from '../../../i18n'
import {downloadDocument} from '../../../util/util'
import {permisos as permisosConstant} from '../../../constants/permisos'
import actionTypes from '../../../constants/actions/planSanitario/registroAlimentacion/registroAlimentacion'
import {fetchRegistroAlimentacionSuccess, recuperarDatosModalRegistroAlimentacionSuccess, duplicarDatosModalRegistroAlimentacionSuccess, deSeleccionarTodosRegistroAlimentacion, 
  fetchFiltrarRegistroAlimentacionSuccess, fetchVerracosRegistroAlimentacionSuccess, cerrarModalVerracoRegistroAlimentacion, anadirVerracoModalRegistroAlimentacionSuccess,
  fetchFiltrarVerracoModalRegistroAlimentacionSuccess, onDeleteVerracoAnadidoRegistroAlimentacionSuccess, resetearVerracos, crearCsvRegistroAlimentacionSuccess, imprimirPdfRegistroAlimentacionSuccess, closeModalRegistroAlimentacion, openModalRegistroAlimentacion
} from '../../../actions/planSanitario/registroAlimentacion/registroAlimentacion'
import {openSimpleModal, openMensajeAlerta} from '../../../actions/common'
import { yesNoModal } from '../../modal/yesNoModal'
import {dateTimeZones} from '../../../util/formatFunctions'
import getRegistroAlimentacionList from '../../../services/planSanitario/registroAlimentacion/getRegistroAlimentacionList'
import addRegistroAlimentacion from '../../../services/planSanitario/registroAlimentacion/addRegistroAlimentacion'
import getRegistroAlimentacion from '../../../services/planSanitario/registroAlimentacion/getRegistroAlimentacion'
import editRegistroAlimentacion from '../../../services/planSanitario/registroAlimentacion/editRegistroAlimentacion'
import getRegistroAlimentacionParaDuplicar from '../../../services/planSanitario/registroAlimentacion/getRegistroAlimentacionParaDuplicar'
import deleteRegistroAlimentacion from '../../../services/planSanitario/registroAlimentacion/deleteRegistroAlimentacion'
import getDeleteSeleccionRegistroAlimentacion from '../../../services/planSanitario/registroAlimentacion/getDeleteSeleccionRegistroAlimentacion'
import getFiltrarRegistroAlimentacion from '../../../services/planSanitario/registroAlimentacion/getFiltrarRegistroAlimentacion'
import getVerracoRegistroAlimentacionList from '../../../services/planSanitario/registroAlimentacion/getVerracoRegistroAlimentacionList'
import getAnadirVerracosRegistroAlimentacion from '../../../services/planSanitario/registroAlimentacion/getAnadirVerracosRegistroAlimentacion'
import getFiltrarRegistroAlimentacionSinAsignar from '../../../services/planSanitario/registroAlimentacion/getFiltrarRegistroAlimentacionSinAsignar'
import getCrearCsvRegistroAlimentacion from '../../../services/planSanitario/registroAlimentacion/getCrearCsvRegistroAlimentacion'
import getImprimirPdfRegistroAlimentacion from '../../../services/planSanitario/registroAlimentacion/getImprimirPdfRegistroAlimentacion'
import obtenerCodigoRegistroAlimentacion from '../../../services/planSanitario/registroAlimentacion/obtenerCodigoRegistroAlimentacion'
import imprimirDetalleRegistroAlimentacion from '../../../services/planSanitario/registroAlimentacion/imprimirDetalleRegistroAlimentacion'

export function * fetchRegistroAlimentacion () {
  try {
    const list = yield call(getRegistroAlimentacionList, null)
    yield put(fetchRegistroAlimentacionSuccess(list))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchFetchRegistroAlimentacion () {
  yield takeLatest(actionTypes.FETCH_REGISTRO_ALIMENTACION, fetchRegistroAlimentacion)
}

export function * abrirModalVacioRegistroAlimentacion () {
  try {
    yield put(resetearVerracos())
    const codigoRegistroAlimentacion = yield call(obtenerCodigoRegistroAlimentacion, null)
    yield put(initialize('ModalRegistroAlimentacion', codigoRegistroAlimentacion))
    yield put(openModalRegistroAlimentacion())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchAbrirModalVacioRegistroAlimentacion () {
  yield takeLatest(actionTypes.ABRIR_MODAL_VACIO_REGISTRO_ALIMENTACION, abrirModalVacioRegistroAlimentacion)
}

export function * submitNuevoRegistroAlimentacion ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const insercionRegistroAlimentacion = yield call(addRegistroAlimentacion, values, auth.token)
    let filtros = yield select(state => state.registroAlimentacion.filtros)
    const list = yield call(getFiltrarRegistroAlimentacion, filtros, auth.token)
    yield put(fetchRegistroAlimentacionSuccess(list))
    yield put(fetchFiltrarRegistroAlimentacionSuccess(filtros))
    if (insercionRegistroAlimentacion.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-insercion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    yield put(closeModalRegistroAlimentacion())
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchSubmitNuevoRegistroAlimentacion () {
  yield takeLatest(actionTypes.SUBMIT_NUEVO_REGISTRO_ALIMENTACION, submitNuevoRegistroAlimentacion)
}

export function * recuperarDatosModalRegistroAlimentacion ({idAlimentacion}) {
  try {
    let auth = yield select(state => state.auth)
    const registroModificar = yield call(getRegistroAlimentacion, idAlimentacion)
    yield put(recuperarDatosModalRegistroAlimentacionSuccess(registroModificar))
    registroModificar.alimentacion.tipoAlimentacion = {label: registroModificar.alimentacion.tipoAlimentacion, value: registroModificar.alimentacion.idTipoAlimentacion}
    registroModificar.alimentacion.idOperario !== null ?
    registroModificar.alimentacion.operario = {label: registroModificar.alimentacion.nombreOperario, value: registroModificar.alimentacion.idOperario} : registroModificar.alimentacion.operario = null
    registroModificar.alimentacion.fechaInicio = registroModificar.alimentacion.fechaInicio
    registroModificar.alimentacion.fechaFinal = registroModificar.alimentacion.fechaFinal
    const diaHoy = new Date()
    if (diaHoy >= new Date(registroModificar.alimentacion.fechaFinal)) {
      registroModificar.alimentacion.terminado = 'SI'
    } else {
      registroModificar.alimentacion.terminado = 'NO'
    }
    yield put(initialize('ModalRegistroAlimentacion', registroModificar.alimentacion))
    yield put(openModalRegistroAlimentacion())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchRecuperarDatosModalRegistroAlimentacion () {
  yield takeLatest(actionTypes.RECUPERAR_DATOS_MODAL_REGISTRO_ALIMENTACION, recuperarDatosModalRegistroAlimentacion)
}

export function * editarRegistroAlimentacion ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const edicionRegistroAlimentacion = yield call(editRegistroAlimentacion, values, auth.token)
    let filtros = yield select(state => state.registroAlimentacion.filtros)
    const list = yield call(getFiltrarRegistroAlimentacion, filtros, auth.token)
    yield put(fetchRegistroAlimentacionSuccess(list))
    yield put(fetchFiltrarRegistroAlimentacionSuccess(filtros))
    if (edicionRegistroAlimentacion.actualizado === true) {
      yield put(openMensajeAlerta('modificacion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-modificacion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    yield put(closeModalRegistroAlimentacion())
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /**/
  }
}
export function * watchEditarRegistroAlimentacion () {
  yield takeLatest(actionTypes.EDITAR_REGISTRO_ALIMENTACION, editarRegistroAlimentacion)
}

export function * duplicarDatosModalRegistroAlimentacion ({idAlimentacion, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const duplicar = yield call(getRegistroAlimentacionParaDuplicar, idAlimentacion)
    const codigoRegistroAlimentacion = yield call(obtenerCodigoRegistroAlimentacion, null)
    duplicar.alimentacion.numeroRegistroAlimentacion = codigoRegistroAlimentacion.numeroRegistroAlimentacion
    yield put(duplicarDatosModalRegistroAlimentacionSuccess(duplicar))
    duplicar.alimentacion.tipoAlimentacion = {label: duplicar.alimentacion.tipoAlimentacion, value: duplicar.alimentacion.idTipoAlimentacion}
    duplicar.alimentacion.operario = {label: duplicar.alimentacion.nombreOperario, value: duplicar.alimentacion.idOperario}
    duplicar.alimentacion.fechaInicio = duplicar.alimentacion.fechaInicio
    duplicar.alimentacion.fechaFinal = duplicar.alimentacion.fechaFinal
    const diaHoy = new Date()
    if (diaHoy >= new Date(duplicar.alimentacion.fechaFinal)) {
      duplicar.alimentacion.terminado = 'SI'
    } else {
      duplicar.alimentacion.terminado = 'NO'
    }
    yield put(initialize('ModalRegistroAlimentacion', duplicar.alimentacion))
    yield put(openModalRegistroAlimentacion())
    if (onSuccessCallback) yield onSuccessCallback()
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchDuplicarDatosModalRegistroAlimentacion () {
  yield takeLatest(actionTypes.DUPLICAR_DATOS_MODAL_REGISTRO_ALIMENTACION, duplicarDatosModalRegistroAlimentacion)
}

export function * onDeleteRegistroAlimentacion ({idAlimentacion}) {
  try {
    let auth = yield select(state => state.auth)
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      const dataDelete = yield call(deleteRegistroAlimentacion , idAlimentacion, null)
      let filtros = yield select(state => state.registroAlimentacion.filtros)
      const list = yield call(getFiltrarRegistroAlimentacion, filtros, auth.token)
      yield put(fetchRegistroAlimentacionSuccess(list))
      yield put(fetchFiltrarRegistroAlimentacionSuccess(filtros))
      if (dataDelete.eliminado === true && dataDelete.asociado === false) {
        yield put(openMensajeAlerta('eliminacion'))
      } else if (dataDelete.insertado === false && dataDelete.asociado === true) {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchOnDeleteRegistroAlimentacion () {
  yield takeLatest(actionTypes.ON_DELETE_REGISTRO_ALIMENTACION, onDeleteRegistroAlimentacion)
}

export function * deleteSeleccionRegistroAlimentacion ({list}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      let dataDeleteSeleccion = yield call(getDeleteSeleccionRegistroAlimentacion, list, auth.token)
      if (dataDeleteSeleccion.eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(initialize('simpleTable'))
      yield put(deSeleccionarTodosRegistroAlimentacion())
      yield put(initialize('filtrosRegistroAlimentacion'))
      let filtros = yield select(state => state.registroAlimentacion.filtros)
      const lista = yield call(getFiltrarRegistroAlimentacion, filtros, auth.token)
      yield put(fetchRegistroAlimentacionSuccess(lista))
      yield put(fetchFiltrarRegistroAlimentacionSuccess(filtros))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchDeleteSeleccionRegistroAlimentacion () {
  yield takeLatest(actionTypes.DELETE_SELECCION_REGISTRO_ALIMENTACION, deleteSeleccionRegistroAlimentacion)
}

export function * guardarYnuevoRegistroAlimentacion ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    let verracosAnadidos = yield select(state => state.registroAlimentacion.verracosAnadidos)
    values.verraco = verracosAnadidos
    let estadoPeticion = yield call(addRegistroAlimentacion, values, auth.token)
    estadoPeticion = estadoPeticion && estadoPeticion.insertado
    if (estadoPeticion === true) {
      yield put(openMensajeAlerta('insercion'))
      yield put(initialize('ModalRegistroAlimentacion', ''))
      let filtros = yield select(state => state.registroAlimentacion.filtros)
      const list = yield call(getFiltrarRegistroAlimentacion, filtros, auth.token)
      yield put(fetchRegistroAlimentacionSuccess(list))
      yield put(fetchFiltrarRegistroAlimentacionSuccess(filtros))
      yield put(resetearVerracos())
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-insercion'))
      if (onErrorCallback) yield onErrorCallback()
    }
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchGuardarYnuevoRegistroAlimentacion () {
  yield takeLatest(actionTypes.GUARDAR_Y_NUEVO_REGISTRO_ALIMENTACION, guardarYnuevoRegistroAlimentacion)
}

export function * fetchFiltrarRegistroAlimentacion ({values}) {
  try {
    let auth = yield select(state => state.auth)
    values.fechaRegistroAlimentacionDesde = values.fechaEntrada
    values.fechaRegistroAlimentacionHasta = values.fechaSalida
    const list = yield call(getFiltrarRegistroAlimentacion, values, auth.token)
    yield put(fetchRegistroAlimentacionSuccess(list))
    yield put(fetchFiltrarRegistroAlimentacionSuccess(values))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchFetchFiltrarRegistroAlimentacion () {
  yield takeLatest(actionTypes.FETCH_FILTRAR_REGISTRO_ALIMENTACION, fetchFiltrarRegistroAlimentacion)
}

export function * fetchVerracosRegistroAlimentacion ({verracosAnadidos}) {
  try {
    let auth = yield select(state => state.auth)
    const listVerracosEnRegistroAlimentacion = yield call(getVerracoRegistroAlimentacionList, verracosAnadidos, auth.token)
    yield put(fetchVerracosRegistroAlimentacionSuccess(listVerracosEnRegistroAlimentacion))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchFetchVerracosRegistroAlimentacion () {
  yield takeLatest(actionTypes.FETCH_VERRACOS_REGISTRO_ALIMENTACION, fetchVerracosRegistroAlimentacion)
}

export function * anadirVerracoModalRegistroAlimentacion ({listadoAnadir}) {
  try {
    let auth = yield select(state => state.auth)
    const verracosAnadidos = yield call(getAnadirVerracosRegistroAlimentacion, listadoAnadir, auth.token)
    yield put(anadirVerracoModalRegistroAlimentacionSuccess(verracosAnadidos))
    yield put(cerrarModalVerracoRegistroAlimentacion())
    /*const verracosNoMostrar = yield select(state => state.registroAlimentacion.verracosAnadidos)
    const listVerracosEnRegistroAlimentacion = yield call(getVerracoRegistroAlimentacionList, verracosNoMostrar, auth.token)
    yield put(fetchVerracosRegistroAlimentacionSuccess(listVerracosEnRegistroAlimentacion))*/
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchAnadirVerracoModalRegistroAlimentacion () {
  yield takeLatest(actionTypes.ANADIR_VERRACO_MODAL_REGISTRO_ALIMENTACION, anadirVerracoModalRegistroAlimentacion)
}

export function * fetchFiltrarVerracoModalRegistroAlimentacion ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const list = yield call(getFiltrarRegistroAlimentacionSinAsignar, values, auth.token)
    yield put(fetchVerracosRegistroAlimentacionSuccess(list))
    yield put(fetchFiltrarVerracoModalRegistroAlimentacionSuccess(values))
    // yield put(initialize('ModalVerracoRegistroAlimentacion'))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchFetchFiltrarVerracoModalRegistroAlimentacion () {
  yield takeLatest(actionTypes.FETCH_FILTRAR_VERRACO_MODAL_REGISTRO_ALIMENTACION, fetchFiltrarVerracoModalRegistroAlimentacion)
}

export function * onDeleteVerracoAnadidoRegistroAlimentacion ({idVerraco}) {
  try {
    const verracosNoMostrar = yield select(state => state.registroAlimentacion.verracosAnadidos)
    const verracosParaMostrar = verracosNoMostrar.filter((verraco) => {
      if (verraco.idElemento !== idVerraco){
        return verraco
      }
    })
    let auth = yield select(state => state.auth)
    if (Object.keys(verracosParaMostrar).length > 0) {
      const verracosAnadidos = yield call(getAnadirVerracosRegistroAlimentacion, verracosParaMostrar, auth.token)
      yield put(onDeleteVerracoAnadidoRegistroAlimentacionSuccess(verracosAnadidos))
    } else {
      const verracosAnadidos = []
      yield put(onDeleteVerracoAnadidoRegistroAlimentacionSuccess(verracosAnadidos))
    }
    /*const listaMostrar = yield select(state => state.registroAlimentacion.verracosAnadidos)
    const listVerracosEnGrupo = yield call(getVerracoRegistroAlimentacionList, listaMostrar, auth.token)
    yield put(fetchVerracosRegistroAlimentacionSuccess(listVerracosEnGrupo))*/

  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchOnDeleteVerracoAnadidoRegistroAlimentacion () {
  yield takeLatest(actionTypes.ON_DELETE_VERRACO_ANADIDO_REGISTRO_ALIMENTACION, onDeleteVerracoAnadidoRegistroAlimentacion)
}

export function * deleteSeleccionVerracosRegistroAlimentacion ({listVerracosQuitarRegistroAlimentacion}) {
  try {
    let auth = yield select(state => state.auth)
    if (Object.keys(listVerracosQuitarRegistroAlimentacion).length > 0) {
      const verracosAnadidos = yield call(getAnadirVerracosRegistroAlimentacion, listVerracosQuitarRegistroAlimentacion, auth.token)
      yield put(onDeleteVerracoAnadidoRegistroAlimentacionSuccess(verracosAnadidos))
    } else {
      const verracosAnadidos = []
      yield put(onDeleteVerracoAnadidoRegistroAlimentacionSuccess(verracosAnadidos))
    }

    /*const listaMostrar = yield select(state => state.registroAlimentacion.verracosAnadidos)
    const listVerracosEnGrupo = yield call(getVerracoRegistroAlimentacionList, listaMostrar, auth.token)
    yield put(fetchVerracosRegistroAlimentacionSuccess(listVerracosEnGrupo))*/

  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchDeleteSeleccionVerracosRegistroAlimentacion () {
  yield takeLatest(actionTypes.DELETE_SELECCION_VERRACOS_REGISTRO_ALIMENTACION, deleteSeleccionVerracosRegistroAlimentacion)
}

export function * crearCsvRegistroAlimentacion ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosExcelRegistroAlimentacion = yield call(getCrearCsvRegistroAlimentacion, list, auth.token)
    yield put(crearCsvRegistroAlimentacionSuccess(datosExcelRegistroAlimentacion))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchCrearCsvRegistroAlimentacion () {
  yield takeLatest(actionTypes.CREATE_CSV_REGISTRO_ALIMENTACION, crearCsvRegistroAlimentacion)
}
export function * imprimirPdfRegistroAlimentacion ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfRegistroAlimentacion = yield call(getImprimirPdfRegistroAlimentacion, list, auth.token)
    yield put(imprimirPdfRegistroAlimentacionSuccess(datosPdfRegistroAlimentacion))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirPdfRegistroAlimentacion () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_REGISTRO_ALIMENTACION, imprimirPdfRegistroAlimentacion)
}

export function * imprimirInformeRegistroAlimentacion ({values, idAlimentacion, onSuccessCallback, onErrorCallback}) {
  try {
    let state = yield select(state => state)
    let soloLectura = (state.auth.permisos !== '-1' && state.auth.permisos[permisosConstant.REGISTRO_ALIMENTACIONES] === 1) || values === null

    let confirmed = true
    if (!soloLectura) {
      confirmed = yield call(yesNoModal, {modalType: 'imprimirGuardar'})
    }

    if (confirmed) {
      if (!soloLectura) {
        // guardar modal antes de imprimirlo
        let auth = yield select(state => state.auth)
        const edicionRegistroAlimentacion = yield call(editRegistroAlimentacion, values, auth.token)
        if (edicionRegistroAlimentacion.actualizado === true) {
          yield put(openMensajeAlerta('modificacion'))
          // Reset formulario
          yield put(initialize('ModalRegistroAlimentacion', values))
          let filtros = yield select(state => state.registroAlimentacion.filtros)
          const list = yield call(getFiltrarRegistroAlimentacion, filtros, auth.token)
          yield put(fetchRegistroAlimentacionSuccess(list))
          yield put(fetchFiltrarRegistroAlimentacionSuccess(filtros))
        } else {
          yield put(openMensajeAlerta('no-modificacion'))
        }
      }

      // IMPRIMIR
      const datosPdfRegistroAlimentacion = yield call(imprimirDetalleRegistroAlimentacion, idAlimentacion, null)
      let href = 'data:application/pdf;base64,' + encodeURIComponent(datosPdfRegistroAlimentacion.pdf)
      let name = i18n.t('MENU.PLAN_SANITARIO.ALIMENTACION.REGISTRO_ALIMENTACIONES') + ".pdf"
      downloadDocument(href, name)
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      if (onErrorCallback) yield onErrorCallback()
    }
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirInformeRegistroAlimentacion () {
  yield takeLatest(actionTypes.IMPRIMIR_INFORME_REGISTRO_ALIMENTACION, imprimirInformeRegistroAlimentacion)
}
