import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize} from 'redux-form'
import i18n from '../../i18n';
import actionTypes from '../../constants/actions/dashboard/dashboard'
import { fetchDashboardSuccess } from '../../actions/dashboard/dashboard'
import {openSimpleModal, openMensajeAlerta} from '../../actions/common'
import { yesNoModal } from '../modal/yesNoModal'
import {yesNoModal as yesNoModalSaga} from '../modal/yesNoModal'
import getDashboard from '../../services/dashboard/getDashboard'

export function * fetchDashboard () {
  try {
    const datosDashboard = yield call(getDashboard, null)
    yield put(fetchDashboardSuccess(datosDashboard))
  } catch (error) {}
}
export function * watchFetchDashboard() {
  yield takeLatest(actionTypes.FETCH_DASHBOARD, fetchDashboard)
}
