import {call, put, takeLatest, select} from 'redux-saga/effects'
import combosActionTypes from '../../constants/actions/combos/combos'
import {
  comboCodLineaGeneticaSuccess, comboLineaGeneticaSuccess, comboCodRazaSuccess, comboRazaSuccess, comboNombreGrupoVerracoSuccess,
  comboMaquinaSuccess, comboRutaSuccess, comboRutasActivasSuccess, comboVerracoSuccess, comboTipoInstalacionPadreSuccess,
  comboTipoInstalacionSuccess, comboInstalacionPadreSuccess, comboTipoIntervencionSanitariaSuccess, comboCodInstalacionSuccess,
  comboNombreInstalacionSuccess, comboOperarioSuccess, comboSalaExtraccionSuccess, comboClienteSuccess, comboDireccionClienteSuccess,
  comboVerracoSinUbicacionSuccess, comboDireccionesClienteSinPredeterminadoSuccess, comboTipoDosisSuccess, comboObtenerCentroUsuarioSuccess,
  comboListadoVerracosSuccess, fetchComboPoolSuccess, fetchComboTipoDiluyenteSuccess, comboTipoAlimentacionSuccess, comboCifClienteSuccess,
  comboLocalidadClienteSuccess, comboInstalacionesPadreSuccess, comboNombresOperariosSuccess, comboApellidosOperariosSuccess,
  comboLocalidadOperariosSuccess, comboEmpresaVisitasSuccess, comboContactoVistasSuccess, comboLineaGeneticaActivaSuccess,
  comboRazaActivaSuccess, comboOperarioActivoSuccess, comboSalaExtraccionActivoSuccess, fetchComboRectaCalibradoSuccess,
  comboEmpresaTransportistaSuccess, comboConductoresTransportistaSuccess, comboVehiculosTransportistaSuccess, comboUsuariosCentroSuccess,
  comboGrupoUsuariosCentroSuccess, comboNombreProductosSuccess, comboReferenciaProductosSuccess, comboNombreEscandalloSuccess,
  comboProductoEscandalloSuccess, comboProveedoresEntradaMercanciaSuccess, comboAlbaranesEntradaMercanciaSuccess,
  comboOrigenesEntradaMercanciaSuccess, comboLotesSuccess, comboProductosActivosSuccess, comboProductosActivosTrazabilidadSuccess,
  comboLotesProductoEspecificoActivoSuccess, comboLotesProductoEspecificoActivoTrazabilidadSuccess, comboRazonSocialClientesActivosSuccess,
  comboAliasClientesActivosSuccess, comboLotesActivosSuccess, comboIndexSuccess, comboUbicacionPadreSuccess, comboUbicacionSuccess,
  comboVerracosPresentesActivosSuccess, comboVerracosExtraccionAlbaranSuccess, comboPoolsExtraccionAlbaranSuccess,
  comboClienteConVerracosEnPropiedadSuccess, comboClienteActivoSuccess, comboNumeroPedidoSuccess, comboTransportistasClienteSuccess,
  comboRutaTransportistaClienteSuccess, comboVehiculosTransportistaDeterminadoSuccess, comboConductoresTransportistaDeterminadoSuccess,
  comboNumerosAlbaranSuccess, comboEmpresaTransportistaActivosSuccess, comboInstalacionPadreActivaSuccess, comboLocalidadClientesSuccess,
  comboProvinciasClientesSuccess, comboUsuariosNoAsignadosOperarioSuccess, comboOperarioPredeterminadoSuccess, comboProductosValidacionActivosSuccess,
  comboNombreGrupoVerracoActivaSuccess, comboTodasUbicacionesSuccess, comboInstalacionesPadreOSinHijosSuccess, comboVerracoConSeguimientoSuccess,
  comboZonaHorariaSuccess
} from '../../actions/combos/combos'
import {ubicacionenesVaciasSuccess} from '../../actions/movimientoVerraco/movimientoVerraco'
import getComboCodLineaGenetica from '../../services/combos/getComboCodLineaGenetica'
import getComboLineaGenetica from '../../services/combos/getComboLineaGenetica'
import getComboCodRaza from '../../services/combos/getComboCodRaza'
import getComboRaza from '../../services/combos/getComboRaza'
import getComboNombreGrupoVerraco from '../../services/combos/getComboNombreGrupoVerraco'
import getComboVerraco from '../../services/combos/getComboVerraco'
import getComboVerracoNoEliminados from '../../services/combos/getComboVerracoNoEliminados'
import getComboTipoInstalacionPadre from '../../services/combos/getComboTipoInstalacionPadre'
import getComboTipoInstalacion from '../../services/combos/getComboTipoInstalacion'
import getComboInstalacionPadre from '../../services/combos/getComboInstalacionPadre'
import getComboCodInstalacion from '../../services/combos/getComboCodInstalacion'
import getComboNombreInstalacion from '../../services/combos/getComboNombreInstalacion'
import getComboOperario from '../../services/combos/getComboOperario'
import getComboSalaExtraccion from '../../services/combos/getComboSalaExtraccion'
import getComboCliente from '../../services/combos/getComboCliente'
import getComboDireccionCliente from '../../services/combos/getComboDireccionCliente'
import getDireccionesClienteSinPredeterminado from '../../services/combos/getDireccionesClienteSinPredeterminado'
import getComboUbicacion from '../../services/combos/getComboUbicacion'
import getComboTipoDosis from '../../services/combos/getComboTipoDosis'
import getcomboObtenerCentroUsuario from '../../services/combos/getcomboObtenerCentroUsuario'
import getComboPool from '../../services/combos/getComboPool'
import getComboTipoDiluyente from '../../services/combos/getComboTipoDiluyente'
import getComboTipoAlimentacionActiva from '../../services/combos/getComboTipoAlimentacionActiva'
import getComboTipoIntervencionSanitariaActiva from '../../services/combos/getComboTipoIntervencionSanitariaActiva'
import getComboVerracosSinUbicacion from '../../services/combos/getComboVerracosSinUbicacion'
import getComboMaquina from '../../services/combos/getComboMaquina'
import getComboRuta from '../../services/combos/getComboRuta'
import getComboCifCliente from '../../services/combos/getComboCifCliente'
import getComboLocalidadCliente from '../../services/combos/getComboLocalidadCliente'
import getInstalacionesPadre from '../../services/combos/getInstalacionesPadre'
import getNombresOperarios from '../../services/combos/getNombresOperarios'
import getApellidosOperarios from '../../services/combos/getApellidosOperarios'
import getLocalidadesOperarios from '../../services/combos/getLocalidadesOperarios'
import getEmpresaVisitas from '../../services/combos/getEmpresaVisitas'
import getContactoVisitas from '../../services/combos/getContactoVisitas'
import getComboLineaGeneticaActiva from '../../services/combos/getComboLineaGeneticaActiva'
import getComboRazaActiva from '../../services/combos/getComboRazaActiva'
import getcomboOperarioActivo from '../../services/combos/getcomboOperarioActivo'
import getcomboSalaExtraccionActivo from '../../services/combos/getcomboSalaExtraccionActivo'
import getComboRectaCalibradoService from '../../services/combos/getComboRectaCalibrado'
import getcomboEmpresaTransportista from '../../services/combos/getcomboEmpresaTransportista'
import getcomboConductoresTransportista from '../../services/combos/getcomboConductoresTransportista'
import getcomboVehiculosTransportista from '../../services/combos/getcomboVehiculosTransportista'
import getcomboUsuariosCentro from '../../services/combos/getcomboUsuariosCentro'
import getcomboGrupoUsuariosCentro from '../../services/combos/getcomboGrupoUsuariosCentro'
import getcomboNombreProductos from '../../services/combos/getcomboNombreProductos'
import getcomboReferenciaProductos from '../../services/combos/getcomboReferenciaProductos'
import getcomboNombreEscandallo from '../../services/combos/getcomboNombreEscandallo'
import getcomboProductoEscandallo from '../../services/combos/getcomboProductoEscandallo'
import getcomboProveedoresEntradaMercancia from '../../services/combos/getcomboProveedoresEntradaMercancia'
import getcomboAlbaranesEntradaMercancia from '../../services/combos/getcomboAlbaranesEntradaMercancia'
import getcomboOrigenesEntradaMercancia from '../../services/combos/getcomboOrigenesEntradaMercancia'
import getcomboLotes from '../../services/combos/getcomboLotes'
import getcomboLotesActivos from '../../services/combos/getComboLotesActivos'
import getcomboProductosActivos from '../../services/combos/getcomboProductosActivos'
import getcomboProductosActivosTrazabilidad from '../../services/combos/getcomboProductosActivosTrazabilidad'
import getcomboLotesProductoEspecificoActivo from '../../services/combos/getcomboLotesProductoEspecificoActivo'
import getcomboLotesProductoEspecificoActivoTrazabilidad from '../../services/combos/getcomboLotesProductoEspecificoActivoTrazabilidad'
import getcomboRazonSocialClientesActivos from '../../services/combos/getcomboRazonSocialClientesActivos'
import getcomboAliasClientesActivos from '../../services/combos/getcomboAliasClientesActivos'
import getcomboIndex from '../../services/combos/getcomboIndex'
import getcomboUbicacionPadre from '../../services/combos/getcomboUbicacionPadre'
import getcomboVerracosPresentesActivos from '../../services/combos/getcomboVerracosPresentesActivos'
import getComboVerracosExtraccionAlbaran from '../../services/combos/getComboVerracosExtraccionAlbaran'
import getComboPoolsExtraccionAlbaran from '../../services/combos/getComboPoolsExtraccionAlbaran'
import getcomboClienteActivo from '../../services/combos/getcomboClienteActivo'
import getComboClienteConVerracosEnPropiedad from '../../services/combos/getComboClienteConVerracosEnPropiedad'
import getComboNumeroPedido from '../../services/combos/getComboNumeroPedido'
import getComboTransportistasClienteService from '../../services/combos/getComboTransportistasCliente'
import getComboRutaTransportistaClienteService from '../../services/combos/getComboRutaTransportistaCliente'
import getComboVehiculosTransportistaDeterminadoService from '../../services/combos/getComboVehiculosTransportistaDeterminado'
import getComboConductoresTransportistaDeterminadoService from '../../services/combos/getComboConductoresTransportistaDeterminado'
import getComboNumerosAlbaranService from '../../services/combos/getComboNumerosAlbaran'
import getEmpresaTransportistaActivos from '../../services/combos/getEmpresaTransportistaActivos'
import getComboInstalacionPadreActiva from '../../services/combos/getComboInstalacionPadreActiva'
import getComboLocalidadClientes from '../../services/combos/getComboLocalidadClientes'
import getComboProvinciasClientes from '../../services/combos/getComboProvinciasClientes'
import getComboUsuariosNoAsignadosOperario from '../../services/combos/getComboUsuariosNoAsignadosOperario'
import getComboOperarioPredeterminado from '../../services/combos/getComboOperarioPredeterminado'
import getProductosValidacionActivos from '../../services/combos/getProductosValidacionActivos'
import getUbicacionesVacias from '../../services/movimientoVerraco/getUbicacionesVacias'
import getComboNombreGrupoVerracoActiva from '../../services/combos/getComboNombreGrupoVerracoActiva'
import getComboTodasUbicaciones from '../../services/combos/getComboTodasUbicaciones'
import getComboRutasActivas from '../../services/combos/getComboRutasActivas'
import getInstalacionesPadreOSinHijos from '../../services/combos/getInstalacionesPadreOSinHijos'
import getVerracosConSeguimiento from '../../services/combos/getVerracosConSeguimiento'
import getZonaHoraria from '../../services/combos/getZonaHoraria'
// Sagas
import {yesNoModal as yesNoModalSaga} from '../modal/yesNoModal'

export function * comboCodLineaGenetica () {
  try {
    const data = yield call(getComboCodLineaGenetica, null)
    yield put(comboCodLineaGeneticaSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboCodLineaGenetica () {
  yield takeLatest(combosActionTypes.COMBO_COD_LINEA_GENETICA, comboCodLineaGenetica)
}

export function * comboLineaGenetica () {
  try {
    const data = yield call(getComboLineaGenetica, null)
    yield put(comboLineaGeneticaSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboLineaGenetica () {
  yield takeLatest(combosActionTypes.COMBO_LINEA_GENETICA, comboLineaGenetica)
}

export function * comboCodRaza () {
  try {
    const data = yield call(getComboCodRaza, null)
    yield put(comboCodRazaSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboCodRaza () {
  yield takeLatest(combosActionTypes.COMBO_COD_RAZA, comboCodRaza)
}

export function * comboRaza () {
  try {
    const data = yield call(getComboRaza, null)
    yield put(comboRazaSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboRaza () {
  yield takeLatest(combosActionTypes.COMBO_RAZA, comboRaza)
}

export function * comboNombreGrupoVerraco () {
  try {
    const data = yield call(getComboNombreGrupoVerraco, null)
    yield put(comboNombreGrupoVerracoSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboNombreGrupoVerraco () {
  yield takeLatest(combosActionTypes.COMBO_NOMBRE_GRUPO_VERRACO, comboNombreGrupoVerraco)
}

export function * comboVerraco ({values = {}, noEliminado = false}) {
  try {
    const data = yield call((noEliminado ? getComboVerracoNoEliminados : getComboVerraco), values)
    yield put(comboVerracoSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboVerraco () {
  yield takeLatest(combosActionTypes.COMBO_VERRACO, comboVerraco)
}

export function * comboTipoInstalacionPadre () {
  try {
    const data = yield call(getComboTipoInstalacionPadre, null)
    yield put(comboTipoInstalacionPadreSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboTipoInstalacionPadre () {
  yield takeLatest(combosActionTypes.COMBO_TIPO_INSTALACION_PADRE, comboTipoInstalacionPadre)
}

export function * comboTipoInstalacion () {
  try {
    const data = yield call(getComboTipoInstalacion, null)
    yield put(comboTipoInstalacionSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboTipoInstalacion () {
  yield takeLatest(combosActionTypes.COMBO_TIPO_INSTALACION, comboTipoInstalacion)
}

export function * comboInstalacionPadre ({idTipoInstalacionPadre}) {
  try {
    let auth = yield select(state => state.auth)
    const data = yield call(getComboInstalacionPadre, idTipoInstalacionPadre, auth.token)
    yield put(comboInstalacionPadreSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboInstalacionPadre () {
  yield takeLatest(combosActionTypes.COMBO_INSTALACION_PADRE, comboInstalacionPadre)
}

export function * comboCodInstalacion () {
  try {
    const data = yield call(getComboCodInstalacion, null)
    yield put(comboCodInstalacionSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboCodInstalacion () {
  yield takeLatest(combosActionTypes.COMBO_COD_INSTALACION, comboCodInstalacion)
}

export function * comboNombreInstalacion () {
  try {
    const data = yield call(getComboNombreInstalacion, null)
    yield put(comboNombreInstalacionSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboNombreInstalacion () {
  yield takeLatest(combosActionTypes.COMBO_NOMBRE_INSTALACION, comboNombreInstalacion)
}

export function * comboOperario () {
  try {
    const data = yield call(getComboOperario, null)
    yield put(comboOperarioSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboOperario () {
  yield takeLatest(combosActionTypes.COMBO_OPERARIO, comboOperario)
}

export function * comboSalaExtraccion () {
  try {
    const data = yield call(getComboSalaExtraccion, null)
    yield put(comboSalaExtraccionSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboSalaExtraccion () {
  yield takeLatest(combosActionTypes.COMBO_SALA_EXTRACCION, comboSalaExtraccion)
}

export function * comboCliente () {
  try {
    const data = yield call(getComboCliente, null)
    yield put(comboClienteSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboCliente () {
  yield takeLatest(combosActionTypes.COMBO_CLIENTE, comboCliente)
}

export function * comboDireccionCliente ({idCliente, mostrarAvisoClienteSinDirecciones, callback}) {
  try {
    let data = []
    if (idCliente) {
      data = yield call(getComboDireccionCliente, idCliente)

      if (mostrarAvisoClienteSinDirecciones && data.length === 0) {
        yield call(yesNoModalSaga, {modalType: 'clienteSinDirecciones'})
      }
    }
    yield put(comboDireccionClienteSuccess(data))

    if (callback) yield call(callback, data)
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboDireccionCliente () {
  yield takeLatest(combosActionTypes.COMBO_DIRECCION_CLIENTE, comboDireccionCliente)
}

export function * comboDireccionesClienteSinPredeterminado ({idCliente}) {
  try {
    let data = []
    if (idCliente) {
      data = yield call(getDireccionesClienteSinPredeterminado, idCliente)
      yield put(comboDireccionesClienteSinPredeterminadoSuccess(data))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboDireccionClienteSinPredeterminado () {
  yield takeLatest(combosActionTypes.COMBO_DIRECCION_CLIENTE_SIN_PERSONALIZADO, comboDireccionesClienteSinPredeterminado)
}

export function * comboUbicacion () {
  try {
    const data = yield call(getComboUbicacion, null)
    yield put(comboUbicacionSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboUbicacion () {
  yield takeLatest(combosActionTypes.COMBO_UBICACION, comboUbicacion)
}

export function * comboTipoDosis () {
  try {
    const data = yield call(getComboTipoDosis, null)
    yield put(comboTipoDosisSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboTipoDosis () {
  yield takeLatest(combosActionTypes.COMBO_TIPO_DOSIS, comboTipoDosis)
}

export function * comboObtenerCentroUsuario () {
  try{
    const data = yield call(getcomboObtenerCentroUsuario, null)
    yield put(comboObtenerCentroUsuarioSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboObtenerCentroUsuario () {
  yield takeLatest(combosActionTypes.COMBO_OBTENER_CENTRO_USUARIO, comboObtenerCentroUsuario)
}

export function * comboListadoVerracos () {
  try {
    const data = yield call(getComboVerraco, null)
    yield put(comboListadoVerracosSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboListadoVerracos () {
  yield takeLatest(combosActionTypes.COMBO_LISTADO_VERRACO, comboVerraco)
}

export function * fetchComboPool () {
  try {
    const data = yield call(getComboPool)
    yield put(fetchComboPoolSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchFetchComboPool () {
  yield takeLatest(combosActionTypes.FETCH_COMBO_POOL, fetchComboPool)
}

export function * fetchComboTipoDiluyente () {
  try {
    const data = yield call(getComboTipoDiluyente)
    yield put(fetchComboTipoDiluyenteSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}

export function * watchFetchComboTipoDiluyente () {
  yield takeLatest(combosActionTypes.FETCH_COMBO_TIPO_DILUYENTE, fetchComboTipoDiluyente)
}

export function * comboTipoAlimentacion () {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getComboTipoAlimentacionActiva, null)
    yield put(comboTipoAlimentacionSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboTipoAlimentacion () {
  yield takeLatest(combosActionTypes.COMBO_TIPO_ALIMENTACION, comboTipoAlimentacion)
}

export function * comboTipoIntervencionSanitaria () {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getComboTipoIntervencionSanitariaActiva, null)
    yield put(comboTipoIntervencionSanitariaSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboTipoIntervencionSanitaria () {
  yield takeLatest(combosActionTypes.COMBO_TIPO_INTERVENCION_SANITARIA, comboTipoIntervencionSanitaria)
}

export function * comboVerracoSinUbicacion ({valor}) {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getComboVerracosSinUbicacion, valor, null)
    yield put(comboVerracoSinUbicacionSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboVerracoSinUbicacion () {
  yield takeLatest(combosActionTypes.COMBO_VERRACO_SIN_UBICACION, comboVerracoSinUbicacion)
}

export function * comboMaquina ({values}) {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getComboMaquina, values, null)
    yield put(comboMaquinaSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboMaquina () {
  yield takeLatest(combosActionTypes.COMBO_MAQUINA, comboMaquina)
}

export function * comboRuta () {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getComboRuta, null)
    yield put(comboRutaSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboRuta () {
  yield takeLatest(combosActionTypes.COMBO_RUTA, comboRuta)
}

export function * comboCifCliente () {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getComboCifCliente, null)
    yield put(comboCifClienteSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboCifCliente () {
  yield takeLatest(combosActionTypes.COMBO_CIF_CLIENTE, comboCifCliente)
}

export function * comboLocalidadCliente () {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getComboLocalidadCliente, null)
    yield put(comboLocalidadClienteSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboLocalidadCliente () {
  yield takeLatest(combosActionTypes.COMBO_LOCALIDAD_CLIENTE, comboLocalidadCliente)
}

export function * comboInstalacionesPadre () {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getInstalacionesPadre, null)
    yield put(comboInstalacionesPadreSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboInstalacionesPadre () {
  yield takeLatest(combosActionTypes.COMBO_INSTALACIONES_PADRE, comboInstalacionesPadre)
}

export function * comboNombresOperarios () {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getNombresOperarios, null)
    yield put(comboNombresOperariosSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboNombresOperarios () {
  yield takeLatest(combosActionTypes.COMBO_NOMBRES_OPERARIOS, comboNombresOperarios)
}

export function * comboApellidosOperarios () {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getApellidosOperarios, null)
    yield put(comboApellidosOperariosSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboApellidosOperarios () {
  yield takeLatest(combosActionTypes.COMBO_APELLIDOS_OPERARIOS, comboApellidosOperarios)
}

export function * comboLocalidadOperarios () {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getLocalidadesOperarios, null)
    yield put(comboLocalidadOperariosSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboLocalidadOperarios () {
  yield takeLatest(combosActionTypes.COMBO_LOCALIDAD_OPERARIOS, comboLocalidadOperarios)
}

export function * comboEmpresaVisitas () {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getEmpresaVisitas, null)
    yield put(comboEmpresaVisitasSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboEmpresaVisitas () {
  yield takeLatest(combosActionTypes.COMBO_EMPRESA_VISITAS, comboEmpresaVisitas)
}

export function * comboContactoVistas () {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getContactoVisitas, null)
    yield put(comboContactoVistasSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboContactoVistas () {
  yield takeLatest(combosActionTypes.COMBO_CONTACTO_VISITAS, comboContactoVistas)
}

export function * comboLineaGeneticaActiva () {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getComboLineaGeneticaActiva, null)
    yield put(comboLineaGeneticaActivaSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboLineaGeneticaActiva () {
  yield takeLatest(combosActionTypes.COMBO_LINEA_ACTIVA_GENETICA, comboLineaGeneticaActiva)
}

export function * comboRazaActiva () {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getComboRazaActiva, null)
    yield put(comboRazaActivaSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboRazaActiva () {
  yield takeLatest(combosActionTypes.COMBO_ACTIVA_RAZA, comboRazaActiva)
}

export function * comboOperarioActivo () {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getcomboOperarioActivo, null)
    yield put(comboOperarioActivoSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboOperarioActivo () {
  yield takeLatest(combosActionTypes.COMBO_OPERARIO_ACTIVO, comboOperarioActivo)
}

export function * comboSalaExtraccionActivo () {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getcomboSalaExtraccionActivo, null)
    yield put(comboSalaExtraccionActivoSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboSalaExtraccionActivo () {
  yield takeLatest(combosActionTypes.COMBO_SALA_EXTRACCION_ACTIVO, comboSalaExtraccionActivo)
}

export function * fetchComboRectaCalibrado () {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getComboRectaCalibradoService, null)
    yield put(fetchComboRectaCalibradoSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchFetchComboRectaCalibrado () {
  yield takeLatest(combosActionTypes.FETCH_COMBO_RECTA_CALIBRADO, fetchComboRectaCalibrado)
}

export function * comboEmpresaTransportista () {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getcomboEmpresaTransportista, null)
    yield put(comboEmpresaTransportistaSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboEmpresaTransportista () {
  yield takeLatest(combosActionTypes.COMBO_EMPRESA_TRANSPORTISTA, comboEmpresaTransportista)
}

export function * comboConductoresTransportista () {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getcomboConductoresTransportista, null)
    yield put(comboConductoresTransportistaSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboConductoresTransportista () {
  yield takeLatest(combosActionTypes.COMBO_CONDUCTORES_TRANSPORTISTA, comboConductoresTransportista)
}

export function * comboVehiculosTransportista () {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getcomboVehiculosTransportista, null)
    yield put(comboVehiculosTransportistaSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboVehiculosTransportista () {
  yield takeLatest(combosActionTypes.COMBO_VEHICULO_TRANSPORTISTA, comboVehiculosTransportista)
}

export function * comboUsuariosCentro () {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getcomboUsuariosCentro, null)
    yield put(comboUsuariosCentroSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboUsuariosCentro () {
  yield takeLatest(combosActionTypes.COMBO_USUARIOS_CENTRO, comboUsuariosCentro)
}

export function * comboGrupoUsuariosCentro () {
  try {
    const data = yield call(getcomboGrupoUsuariosCentro)
    yield put(comboGrupoUsuariosCentroSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboGrupoUsuariosCentro () {
  yield takeLatest(combosActionTypes.COMBO_GRUPO_USUARIOS_CENTRO, comboGrupoUsuariosCentro)
}

export function * comboNombreProductos () {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getcomboNombreProductos, null)
    yield put(comboNombreProductosSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboNombreProductos () {
  yield takeLatest(combosActionTypes.COMBO_NOMBRE_PRODUCTO, comboNombreProductos)
}

export function * comboReferenciaProductos () {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getcomboReferenciaProductos, null)
    yield put(comboReferenciaProductosSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboReferenciaProductos () {
  yield takeLatest(combosActionTypes.COMBO_REFERENCIA_PRODUCTO, comboReferenciaProductos)
}

export function * comboNombreEscandallo () {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getcomboNombreEscandallo, null)
    yield put(comboNombreEscandalloSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboNombreEscandallo () {
  yield takeLatest(combosActionTypes.COMBO_NOMBRE_ESCANDALLO, comboNombreEscandallo)
}

export function * comboProductoEscandallo () {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getcomboProductoEscandallo, null)
    yield put(comboProductoEscandalloSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboProductoEscandallo () {
  yield takeLatest(combosActionTypes.COMBO_PRODUCTO_ESCANDALLO, comboProductoEscandallo)
}

export function * comboProveedoresEntradaMercancia () {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getcomboProveedoresEntradaMercancia, null)
    yield put(comboProveedoresEntradaMercanciaSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboProveedoresEntradaMercancia () {
  yield takeLatest(combosActionTypes.COMBO_PROVEEDORES_ENTRADA_MERCANCIA, comboProveedoresEntradaMercancia)
}

export function * comboAlbaranesEntradaMercancia () {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getcomboAlbaranesEntradaMercancia, null)
    yield put(comboAlbaranesEntradaMercanciaSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboAlbaranesEntradaMercancia () {
  yield takeLatest(combosActionTypes.COMBO_ALBARANES_ENTRADA_MERCANCIA, comboAlbaranesEntradaMercancia)
}

export function * comboOrigenesEntradaMercancia () {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getcomboOrigenesEntradaMercancia, null)
    yield put(comboOrigenesEntradaMercanciaSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboOrigenesEntradaMercancia () {
  yield takeLatest(combosActionTypes.COMBO_ORIGENES_ENTRADA_MERCANCIA, comboOrigenesEntradaMercancia)
}

export function * comboLotes ({producto}) {
  try {
    const data = yield call(getcomboLotes, (producto && producto.value ? producto.value : ''))
    yield put(comboLotesSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboLotes () {
  yield takeLatest(combosActionTypes.COMBO_LOTES, comboLotes)
}

export function * comboLotesActivos () {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getcomboLotesActivos, null)
    yield put(comboLotesActivosSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboLotesActivos () {
  yield takeLatest(combosActionTypes.COMBO_LOTES_ACTIVOS, comboLotesActivos)
}

export function * comboProductosActivos () {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getcomboProductosActivos, null)
    yield put(comboProductosActivosSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboProductosActivos () {
  yield takeLatest(combosActionTypes.COMBO_PRODUCTOS_ACTIVOS, comboProductosActivos)
}

export function * comboProductosActivosTrazabilidad () {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getcomboProductosActivosTrazabilidad, null)
    yield put(comboProductosActivosTrazabilidadSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboProductosActivosTrazabilidad () {
  yield takeLatest(combosActionTypes.COMBO_PRODUCTOS_ACTIVOS_TRAZABILIDAD, comboProductosActivosTrazabilidad)
}

export function * comboLotesProductoEspecificoActivo ({idProducto, idLote}) {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getcomboLotesProductoEspecificoActivo, idProducto, idLote, null)
    yield put(comboLotesProductoEspecificoActivoSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboLotesProductoEspecificoActivo () {
  yield takeLatest(combosActionTypes.COMBO_LOTES_PRODUCTO_ESPECIFICO_ACTIVO, comboLotesProductoEspecificoActivo)
}

export function * comboLotesProductoEspecificoActivoTrazabilidad ({idProducto}) {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getcomboLotesProductoEspecificoActivoTrazabilidad, idProducto, null)
    yield put(comboLotesProductoEspecificoActivoTrazabilidadSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboLotesProductoEspecificoActivoTrazabilidad () {
  yield takeLatest(combosActionTypes.COMBO_LOTES_PRODUCTO_ESPECIFICO_ACTIVO_TRAZABILIDAD, comboLotesProductoEspecificoActivoTrazabilidad)
}

export function * comboRazonSocialClientesActivos ({idProducto}) {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getcomboRazonSocialClientesActivos, idProducto, null)
    yield put(comboRazonSocialClientesActivosSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboRazonSocialClientesActivos () {
  yield takeLatest(combosActionTypes.COMBO_RAZON_SOCIAL_CLIENTES_ACTIVOS, comboRazonSocialClientesActivos)
}

export function * comboAliasClientesActivos ({idProducto}) {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getcomboAliasClientesActivos, idProducto, null)
    yield put(comboAliasClientesActivosSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboAliasClientesActivos () {
  yield takeLatest(combosActionTypes.COMBO_ALIAS_CLIENTES_ACTIVOS, comboAliasClientesActivos)
}

export function * comboIndex () {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getcomboIndex, null)
    yield put(comboIndexSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboIndex () {
  yield takeLatest(combosActionTypes.COMBO_INDEX, comboIndex)
}

export function * comboUbicacionPadre () {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getcomboUbicacionPadre, null)
    yield put(comboUbicacionPadreSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboUbicacionPadre () {
  yield takeLatest(combosActionTypes.COMBO_UBICACION_PADRE, comboUbicacionPadre)
}

export function * comboVerracosPresentesActivos () {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getcomboVerracosPresentesActivos, null)
    yield put(comboVerracosPresentesActivosSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboVerracosPresentesActivos () {
  yield takeLatest(combosActionTypes.COMBO_VERRACOS_PRESENTES_ACTIVOS, comboVerracosPresentesActivos)
}

export function * comboVerracosExtraccionAlbaran ({values}) {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getComboVerracosExtraccionAlbaran, auth.token, values)
    yield put(comboVerracosExtraccionAlbaranSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboVerracosExtraccionAlbaran () {
  yield takeLatest(combosActionTypes.COMBO_VERRACOS_EXTRACCION_ALBARAN, comboVerracosExtraccionAlbaran)
}

export function * comboClienteActivo () {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getcomboClienteActivo, null)
    yield put(comboClienteActivoSuccess(data))
} catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboClienteActivo () {
  yield takeLatest(combosActionTypes.COMBO_CLIENTE_ACTIVO, comboClienteActivo)
}

export function * comboClienteConVerracosEnPropiedad () {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getComboClienteConVerracosEnPropiedad, null)
    yield put(comboClienteConVerracosEnPropiedadSuccess(data))
} catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboClienteConVerracosEnPropiedad () {
  yield takeLatest(combosActionTypes.COMBO_CLIENTE_CON_VERRACOS_EN_PROPIEDAD, comboClienteConVerracosEnPropiedad)
}

export function * comboPoolsExtraccionAlbaran ({values}) {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getComboPoolsExtraccionAlbaran, auth.token, values)
    yield put(comboPoolsExtraccionAlbaranSuccess(data))
 } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboPoolsExtraccionAlbaran () {
  yield takeLatest(combosActionTypes.COMBO_POOLS_EXTRACCION_ALBARAN, comboPoolsExtraccionAlbaran)
}

export function * comboNumeroPedido () {
  try {
    const data = yield call(getComboNumeroPedido, null)
    yield put(comboNumeroPedidoSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboNumeroPedido () {
  yield takeLatest(combosActionTypes.COMBO_NUMERO_PEDIDO, comboNumeroPedido)
}

export function * comboTransportistasCliente ({idDireccionCliente}) {
  try {
    let data = []
    if (idDireccionCliente) {
      data = yield call(getComboTransportistasClienteService, idDireccionCliente)
    }
    yield put(comboTransportistasClienteSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboTransportistasCliente () {
  yield takeLatest(combosActionTypes.COMBO_TRANSPORTISTAS_CLIENTE, comboTransportistasCliente)
}

export function * comboRutaTransportistaCliente ({idDireccionCliente, idTransportista}) {
  try {
    const auth = yield select(state => state.auth)
    let data = []
    if (idDireccionCliente && idTransportista) {
      data = yield call(getComboRutaTransportistaClienteService, auth.token, {idDireccionCliente, idTransportista})
    }
    yield put(comboRutaTransportistaClienteSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboRutaTransportistaCliente () {
  yield takeLatest(combosActionTypes.COMBO_RUTA_TRANSPORTISTA_CLIENTE, comboRutaTransportistaCliente)
}

export function * comboVehiculosTransportistaDeterminado ({idTransportista}) {
  try {
    let data = []
    if (idTransportista) {
      data = yield call(getComboVehiculosTransportistaDeterminadoService, idTransportista)
    }
    yield put(comboVehiculosTransportistaDeterminadoSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboVehiculosTransportistaDeterminado () {
  yield takeLatest(combosActionTypes.COMBO_VEHICULOS_TRANSPORTISTA_DETERMINADO, comboVehiculosTransportistaDeterminado)
}

export function * comboConductoresTransportistaDeterminado ({idTransportista}) {
  try {
    let data = []
    if (idTransportista) {
      data = yield call(getComboConductoresTransportistaDeterminadoService, idTransportista)
    }
    yield put(comboConductoresTransportistaDeterminadoSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboConductoresTransportistaDeterminado () {
  yield takeLatest(combosActionTypes.COMBO_CONDUCTORES_TRANSPORTISTA_DETERMINADO, comboConductoresTransportistaDeterminado)
}

export function * comboNumerosAlbaran () {
  try {
    let data = yield call(getComboNumerosAlbaranService)
    yield put(comboNumerosAlbaranSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboNumerosAlbaran () {
  yield takeLatest(combosActionTypes.COMBO_NUMEROS_ALBARAN, comboNumerosAlbaran)
}

export function * comboEmpresaTransportistaActivos () {
  try {
    let data = yield call(getEmpresaTransportistaActivos)
    yield put(comboEmpresaTransportistaActivosSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboEmpresaTransportistaActivos () {
  yield takeLatest(combosActionTypes.COMBO_EMPRESA_TRANSPORTISTA_ACTIVOS, comboEmpresaTransportistaActivos)
}

export function * comboInstalacionPadreActiva () {
  try {
    let data = yield call(getComboInstalacionPadreActiva)
    yield put(comboInstalacionPadreActivaSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboInstalacionPadreActiva () {
  yield takeLatest(combosActionTypes.COMBO_INSTALACION_PADRE_ACTIVA, comboInstalacionPadreActiva)
}

export function * comboLocalidadClientes () {
  try {
    let data = yield call(getComboLocalidadClientes)
    yield put(comboLocalidadClientesSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboLocalidadClientes () {
  yield takeLatest(combosActionTypes.COMBO_LOCALIDAD_CLIENTES, comboLocalidadClientes)
}

export function * comboProvinciasClientes () {
  try {
    let data = yield call(getComboProvinciasClientes)
    yield put(comboProvinciasClientesSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboProvinciasClientes () {
  yield takeLatest(combosActionTypes.COMBO_PROVINCIAS_CLIENTES, comboProvinciasClientes)
}

export function * comboUsuariosNoAsignadosOperario () {
  try {
    let data = yield call(getComboUsuariosNoAsignadosOperario)
    yield put(comboUsuariosNoAsignadosOperarioSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboUsuariosNoAsignadosOperario () {
  yield takeLatest(combosActionTypes.COMBO_USUARIOS_NO_ASIGNADOS_OPERARIO, comboUsuariosNoAsignadosOperario)
}

export function * comboOperarioPredeterminado () {
  try {
    let data = yield call(getComboOperarioPredeterminado)
    yield put(comboOperarioPredeterminadoSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboOperarioPredeterminado () {
  yield takeLatest(combosActionTypes.COMBO_OPERARIO_PREDETERMINADO, comboOperarioPredeterminado)
}

export function * comboProductosValidacionActivos () {
  try {
    let data = yield call(getProductosValidacionActivos)
    yield put(comboProductosValidacionActivosSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboProductosValidacionActivos () {
  yield takeLatest(combosActionTypes.COMBO_PRODUCTOS_VALIDACION_ACTIVOS, comboProductosValidacionActivos)
}

export function * comboNombreGrupoVerracoActiva () {
  try {
    let data = yield call(getComboNombreGrupoVerracoActiva)
    yield put(comboNombreGrupoVerracoActivaSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboNombreGrupoVerracoActiva () {
  yield takeLatest(combosActionTypes.COMBO_NOMBRE_GRUPO_VERRACO_ACTIVA, comboNombreGrupoVerracoActiva)
}

export function * comboTodasUbicaciones () {
  try {
    let data = yield call(getComboTodasUbicaciones)
    yield put(comboTodasUbicacionesSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboTodasUbicaciones () {
  yield takeLatest(combosActionTypes.COMBO_TODAS_UBICACIONES, comboTodasUbicaciones)
}

export function * comboRutasActivas () {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getComboRutasActivas, null)
    yield put(comboRutasActivasSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboRutasActivas () {
  yield takeLatest(combosActionTypes.COMBO_RUTAS_ACTIVAS, comboRutasActivas)
}

export function * comboInstalacionesPadreOSinHijos () {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getInstalacionesPadreOSinHijos, null)
    yield put(comboInstalacionesPadreOSinHijosSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboInstalacionesPadreOSinHijos () {
  yield takeLatest(combosActionTypes.COMBO_INSTALACIONES_PADRE_O_SIN_HIJOS, comboInstalacionesPadreOSinHijos)
}

export function * comboVerracoConSeguimiento () {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getVerracosConSeguimiento, null)
    yield put(comboVerracoConSeguimientoSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboVerracoConSeguimiento () {
  yield takeLatest(combosActionTypes.COMBO_VERRACOS_CON_SEGUIMIENTO, comboVerracoConSeguimiento)
}

export function * comboZonaHoraria () {
  try {
    const auth = yield select(state => state.auth)
    const data = yield call(getZonaHoraria, null)
    yield put(comboZonaHorariaSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchComboZonaHoraria () {
  yield takeLatest(combosActionTypes.COMBO_ZONA_HORARIA, comboZonaHoraria)
}
