import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ArrowBack, Refresh } from '@material-ui/icons'
import { required } from '../../util/validationFunctions'
import ButtonLockable from '../comun/button/ButtonLockable'
import InputText from '../comun/form/InputText'
import AlertSuccess from '../comun/alert/AlertSuccess'
import SimplePage from '../../containers/page/SimplePage'
import './login.scss'

class RequestResetPassword extends Component {
  constructor() {
    super()
    this.state = {
      status: null
    }
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(values) {
    this.setState(() => {
      return {status: false}
    })
    return new Promise((resolve, reject) => {
      this.props.actions.requestResetPassword(values, resolve, reject)
    }).then(() => {
      this.setState(() => {
        return {status: true}
      })
    }).catch(() => {})
  }

  render () {
    const { t, handleSubmit, submitting } = this.props
    const t_key = 'REQUEST_RESET_PASSWORD.'
    return (
      <SimplePage title={t(`${t_key}TITLE`)} className="reset-password-container">
        <p>
          {t(`${t_key}DESCRIPCION`)}
        </p>
        {this.state.status && <AlertSuccess>{t(`${t_key}FORM.SUCCESS`)}</AlertSuccess>}
        <div>
          <form onSubmit={handleSubmit(this.onSubmit)}>
            <Row>
              <Field
                id="emailUsuario"
                name="emailUsuario"
                colSm={12}
                controlLabel={t(`${t_key}FORM.EMAIL`) + '*'}
                placeholder={t(`${t_key}FORM.EMAIL`)}
                component={InputText}
                validate={required}
              />
              <Field
                id="codlicencia"
                name="codlicencia"
                colSm={12}
                controlLabel={t(`${t_key}FORM.COD_LICENCIA`) + '*'}
                placeholder={t(`${t_key}FORM.COD_LICENCIA`)}
                component={InputText}
                validate={required}
              />
              <Col sm={12}>
                <ButtonLockable processing={submitting} type="submit" className="btn-primary pull-right" replaceChild="Refresh" hasIcon={true}>
                  <Refresh name="Refresh" />
                  {t(`${t_key}FORM.RESET_PASSWORD`)}
                </ButtonLockable>
                <Link to="/Login" className="btn btn-link pull-right"><ArrowBack/>{t(`${t_key}FORM.LOGIN`)}</Link>
              </Col>
            </Row>
          </form>
        </div>
      </SimplePage>
    )
  }
}

export default reduxForm({
  form: 'requestResetPassword'
})(RequestResetPassword)
