import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize} from 'redux-form'
import i18n from '../../i18n'
import actionTypes from '../../constants/actions/importarDatos/importarDatos'
import {submitImportarDatosSuccess} from '../../actions/importarDatos/importarDatos'
import {openModal, closeModal, openSimpleModal, openMensajeAlerta} from '../../actions/common'
import { yesNoModal } from '../modal/yesNoModal'
import getImportarDatos from '../../services/importarDatos/getImportarDatos'

export function * submitImportarDatos ({value, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const listCargaIndex = yield call(getImportarDatos, value, auth.token)
    yield put(submitImportarDatosSuccess(listCargaIndex))
    if (onSuccessCallback) yield onSuccessCallback()
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchSubmitImportarDatos () {
  yield takeLatest(actionTypes.SUBMIT_IMPORTAR_DATOS, submitImportarDatos)
}
