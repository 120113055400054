import combosActionTypes from '../../constants/actions/combos/combos'
import {estado} from '../../constants/estado'
import {estadoVerraco} from '../../constants/estadoVerraco'
import movimientoVerracoActionTypes from '../../constants/actions/movimientoVerraco/movimientoVerraco'
import {causaEliminado} from '../../constants/causasEliminado'
import {analisisExtraccion, translateAnalisisExtraccion, tipoAnalisisExtraccion, translateTipoAnalisisExtraccion, observacionesEyaculadoEliminado, translateObservacionesEyaculadoEliminado} from '../../constants/analisis'
import {tipoAccionBioseguridadHigiene, translateTipoAccionBioseguridadHigiene, tipoOtros, translateTipoOtros, estadoAccion, translateEstadoAccion} from '../../constants/tipoAccion'
import {estadoDilucion, translateEstadoDilucion} from '../../constants/estadoDilucion'
import {estadoEnvasado, translateEstadoEnvasado} from '../../constants/estadoEnvasado'
import {tratamiento, translatetratamiento} from '../../constants/tratamiento'
import {verracoSiNo, translateVerracoSiNo, tipoControlSeguimiento, translateTipoControlSeguimiento} from '../../constants/seguimiento'
import {estadoAlbaran, translateEstadoAlbaran} from '../../constants/albaran'
import {modulos, translateModulos} from '../../constants/modulos'
import {tipoTareasAgenda, translateTipoTareasAgenda, colorTipoTareasAgenda} from '../../constants/agenda'

export function initialState () {
  return {
    comboCodLineaGenetica: [],
    comboLineaGenetica: [],
    comboEstado: [
      {value: estado.ACTIVO, label: 'COMUN.COMBOS.COMBO_ESTADO.ACTIVO'},
      {value: estado.INACTIVO, label: 'COMUN.COMBOS.COMBO_ESTADO.INACTIVO'}
    ],
    comboCodRaza: [],
    comboRaza: [],
    comboNombreGrupoVerraco: [],
    comboVerraco: [],
    comboTipoInstalacionPadre: [],
    comboTipoInstalacion: [],
    comboInstalacionPadre: [],
    comboCodInstalacion: [],
    comboNombreInstalacion: [],
    comboOperario: [],
    comboSalaExtraccion: [],
    comboEstadoVerraco: [
      {value: estadoVerraco.CUARENTENA, label: 'COMUN.COMBOS.COMBO_ESTADO_VERRACO.CUARENTENA'},
      {value: estadoVerraco.PRESENTADO, label: 'COMUN.COMBOS.COMBO_ESTADO_VERRACO.PRESENTE'},
      {value: estadoVerraco.ACTIVO, label: 'COMUN.COMBOS.COMBO_ESTADO_VERRACO.ACTIVO'},
      {value: estadoVerraco.BAJA, label: 'COMUN.COMBOS.COMBO_ESTADO_VERRACO.BAJA'},
      {value: estadoVerraco.ELIMINADO, label: 'COMUN.COMBOS.COMBO_ESTADO_VERRACO.ELIMINADO'}
    ],
    comboEstadoVerracoNoEliminado: [
      {value: estadoVerraco.CUARENTENA, label: 'COMUN.COMBOS.COMBO_ESTADO_VERRACO.CUARENTENA'},
      {value: estadoVerraco.PRESENTADO, label: 'COMUN.COMBOS.COMBO_ESTADO_VERRACO.PRESENTE'},
      {value: estadoVerraco.ACTIVO, label: 'COMUN.COMBOS.COMBO_ESTADO_VERRACO.ACTIVO'},
      {value: estadoVerraco.BAJA, label: 'COMUN.COMBOS.COMBO_ESTADO_VERRACO.BAJA'}
    ],
    comboTipoDosis: [],
    comboUbicacion: [],
    comboUbicacionVacia: [],
    comboObtenerCentroUsuario: [],
    comboTiempoMonta: [
      { value: 1, label: 'COMUN.COMBOS.TIEMPO_MONTA.MUY_LENTO' },
      { value: 2, label: 'COMUN.COMBOS.TIEMPO_MONTA.LENTO' },
      { value: 3, label: 'COMUN.COMBOS.TIEMPO_MONTA.NORMAL' },
      { value: 4, label: 'COMUN.COMBOS.TIEMPO_MONTA.RAPIDO' },
      { value: 5, label: 'COMUN.COMBOS.TIEMPO_MONTA.MUY_RAPIDO' }
    ],
    comboConservacion: [
      { value: 1, label: '*' },
      { value: 2, label: '**' },
      { value: 3, label: '***' },
      { value: 4, label: '****' },
      { value: 5, label: '*****' }
    ],
    comboCausaEliminacion: [
      {value: causaEliminado.GENETICA, label: 'COMUN.COMBOS.CAUSA_ELIMINACION.GENETICA'},
      {value: causaEliminado.PRODUCTIVIDAD, label: 'COMUN.COMBOS.CAUSA_ELIMINACION.PRODUCTIVIDAD'},
      {value: causaEliminado.CALIDAD_SEMINAL, label: 'COMUN.COMBOS.CAUSA_ELIMINACION.CALIDAD_SEMINAL'},
      {value: causaEliminado.COJERAS, label: 'COMUN.COMBOS.CAUSA_ELIMINACION.COJERAS'},
      {value: causaEliminado.MUERTE_SUBITA, label: 'COMUN.COMBOS.CAUSA_ELIMINACION.MUERTE_SUBITA'},
      {value: causaEliminado.ENFERMEDAD, label: 'COMUN.COMBOS.CAUSA_ELIMINACION.ENFERMEDAD'},
      {value: causaEliminado.OTRAS, label: 'COMUN.COMBOS.CAUSA_ELIMINACION.OTRAS'}
    ],
    comboListadoVerracos: [],
    comboPool: [],
    comboAnalisisExtraccion: [
      {value: analisisExtraccion.TODOS, label: translateAnalisisExtraccion[analisisExtraccion.TODOS], predeterminado: true},
      {value: analisisExtraccion.PREVISTO, label: translateAnalisisExtraccion[analisisExtraccion.PREVISTO]}
    ],
    comboTipoAnalisisExtraccion: [
      {value: tipoAnalisisExtraccion.COLORIMETRO, label: translateTipoAnalisisExtraccion[tipoAnalisisExtraccion.COLORIMETRO]},
      {value: tipoAnalisisExtraccion.CAMARA_BURKER, label: translateTipoAnalisisExtraccion[tipoAnalisisExtraccion.CAMARA_BURKER]},
      {value: tipoAnalisisExtraccion.MAGAVISION_I, label: translateTipoAnalisisExtraccion[tipoAnalisisExtraccion.MAGAVISION_I]},
      {value: tipoAnalisisExtraccion.MAGAVISION_M, label: translateTipoAnalisisExtraccion[tipoAnalisisExtraccion.MAGAVISION_M]},
      {value: tipoAnalisisExtraccion.NUEVO_MAGAVISION, label: translateTipoAnalisisExtraccion[tipoAnalisisExtraccion.NUEVO_MAGAVISION]}
    ],
    comboObservacionesEyaculadoEliminado: [
      {value: observacionesEyaculadoEliminado.MUERTO, label: translateObservacionesEyaculadoEliminado[observacionesEyaculadoEliminado.MUERTO]},
      {value: observacionesEyaculadoEliminado.MOTILIDAD, label: translateObservacionesEyaculadoEliminado[observacionesEyaculadoEliminado.MOTILIDAD]},
      {value: observacionesEyaculadoEliminado.FA, label: translateObservacionesEyaculadoEliminado[observacionesEyaculadoEliminado.FA]},
      {value: observacionesEyaculadoEliminado.CONCENTRACION, label: translateObservacionesEyaculadoEliminado[observacionesEyaculadoEliminado.CONCENTRACION]},
      {value: observacionesEyaculadoEliminado.AGLUTINACION, label: translateObservacionesEyaculadoEliminado[observacionesEyaculadoEliminado.AGLUTINACION]},
      {value: observacionesEyaculadoEliminado.CONTAMINACION_U_OTROS, label: translateObservacionesEyaculadoEliminado[observacionesEyaculadoEliminado.CONTAMINACION_U_OTROS]}
    ],
    comboTipoAnalisisPanelControl: [
      {value: tipoAnalisisExtraccion.COLORIMETRO, label: translateTipoAnalisisExtraccion[tipoAnalisisExtraccion.COLORIMETRO]},
      {value: tipoAnalisisExtraccion.CAMARA_BURKER, label: translateTipoAnalisisExtraccion[tipoAnalisisExtraccion.CAMARA_BURKER]},
      {value: tipoAnalisisExtraccion.MAGAVISION_I, label: translateTipoAnalisisExtraccion[tipoAnalisisExtraccion.MAGAVISION_I]},
      {value: tipoAnalisisExtraccion.MAGAVISION_M, label: translateTipoAnalisisExtraccion[tipoAnalisisExtraccion.MAGAVISION_M]},
      {value: tipoAnalisisExtraccion.NUEVO_MAGAVISION, label: translateTipoAnalisisExtraccion[tipoAnalisisExtraccion.NUEVO_MAGAVISION]}
    ],
    comboRectaCalibrado: [],
    comboTipoDiluyente: [],
    comboDispositivosVideo: [],
    comboProducto: [],
    comboTipoAlimentacion: [],
    comboTipoIntervencionSanitaria: [],
    comboVerracoSinUbicacion: [],
    comboTipoAccion: [
      {value: tipoAccionBioseguridadHigiene.ANALITICA_AGUA, label: translateTipoAccionBioseguridadHigiene[tipoAccionBioseguridadHigiene.ANALITICA_AGUA]},
      {value: tipoAccionBioseguridadHigiene.LIMPIEZA_SILO_DEPOSITO, label: translateTipoAccionBioseguridadHigiene[tipoAccionBioseguridadHigiene.LIMPIEZA_SILO_DEPOSITO]},
      {value: tipoAccionBioseguridadHigiene.LIMPIEZA_CORRALES, label: translateTipoAccionBioseguridadHigiene[tipoAccionBioseguridadHigiene.LIMPIEZA_CORRALES]},
      {value: tipoAccionBioseguridadHigiene.OTROS, label: translateTipoAccionBioseguridadHigiene[tipoAccionBioseguridadHigiene.OTROS]}
    ],
    comboTipoOtros: [
      {value: tipoOtros.UBICACION, label: translateTipoOtros[tipoOtros.UBICACION]},
      {value: tipoOtros.VERRACO, label: translateTipoOtros[tipoOtros.VERRACO]},
      {value: tipoOtros.GRUPO_VERRACO, label: translateTipoOtros[tipoOtros.GRUPO_VERRACO]}
    ],
    comboEstadoAccion: [
      {value: estadoAccion.NO, label: translateEstadoAccion[estadoAccion.NO]},
      {value: estadoAccion.SI, label: translateEstadoAccion[estadoAccion.SI]},
      {value: estadoAccion.PENDIENTE, label: translateEstadoAccion[estadoAccion.PENDIENTE]},
      {value: estadoAccion.DEBE_MEJORAR, label: translateEstadoAccion[estadoAccion.DEBE_MEJORAR]}
    ],
    comboMaquina: [],
    comboDiluido: [
      {value: estadoDilucion.DILUIDO, label: translateEstadoDilucion[estadoDilucion.DILUIDO]},
      {value: estadoDilucion.NO_DILUIDO, label: translateEstadoDilucion[estadoDilucion.NO_DILUIDO]}
    ],
    comboEnvasado: [
      {value: estadoEnvasado.ENVASADO, label: translateEstadoEnvasado[estadoEnvasado.ENVASADO]},
      {value: estadoEnvasado.NO_ENVASADO, label: translateEstadoEnvasado[estadoEnvasado.NO_ENVASADO]}
    ],
    comboRuta: [],
    comboCliente: [],
    comboCifCliente: [],
    comboDireccionCliente: [],
    comboLocalidadCliente: [],
    comboInstalacionesPadre: [],
    comboNombresOperarios: [],
    comboApellidosOperarios: [],
    comboLocalidadOperarios: [],
    comboEmpresaVisitas: [],
    comboContactoVistas: [],
    comboLineaGeneticaActiva: [],
    comboRazaActiva: [],
    comboOperarioActivo: [],
    comboSalaExtraccionActivo: [],
    comboTratamiento:[
      {value: tratamiento.SI, label: translatetratamiento[tratamiento.SI]},
      {value: tratamiento.NO, label: translatetratamiento[tratamiento.NO]}
    ],
    comboEmpresaTransportista: [],
    comboConductoresTransportista: [],
    comboVehiculosTransportista: [],
    comboGenHalotano: [
      {value: 'NN', label: 'NN'},
      {value: 'NP', label: 'NP'},
      {value: 'PP', label: 'PP'}
    ],
    comboUsuariosCentro: [],
    comboGrupoUsuariosCentro: [],
    comboNombreProductos: [],
    comboReferenciaProductos: [],
    comboProductoEscandallo: [],
    comboProveedoresEntradaMercancia: [],
    comboAlbaranesEntradaMercancia: [],
    comboOrigenesEntradaMercancia: [],
    comboLotes: [],
    comboLotesActivos: [],
    comboProductosActivos: [],
    comboProductosActivosTrazabilidad: [],
    comboLotesProductoEspecificoActivo: [],
    comboLotesProductoEspecificoActivoTrazabilidad: [],
    comboVerracoSiNo: [
      {value: verracoSiNo.VERRACO, label: translateVerracoSiNo[verracoSiNo.VERRACO]},
      {value: verracoSiNo.POOL, label: translateVerracoSiNo[verracoSiNo.POOL]}
    ],
    comboTipoControlSeguimiento: [
      {value: tipoControlSeguimiento.INTERNO, label: translateTipoControlSeguimiento[tipoControlSeguimiento.INTERNO], predeterminado: true},
      {value: tipoControlSeguimiento.EXTERNO, label: translateTipoControlSeguimiento[tipoControlSeguimiento.EXTERNO]}
    ],
    comboRazonSocialClientesActivos: [],
    comboAliasClientesActivos: [],
    comboIndex: [],
    comboUbicacionPadre: [],
    comboEstadoPlaning: [
      {value: 1, label: 'COMUN.COMBOS.COMBO_ESTADO_VERRACO.CUARENTENA'},
      {value: 2, label: 'COMUN.COMBOS.COMBO_ESTADO_VERRACO.PRESENTE'},
      {value: 3, label: 'COMUN.COMBOS.COMBO_ESTADO_VERRACO.ACTIVO'},
      {value: 4, label: 'COMUN.COMBOS.COMBO_ESTADO_VERRACO.BAJA'}
    ],
    comboEstadoModalSugerenciasPlaning: [
      {value: 2, label: 'COMUN.COMBOS.COMBO_ESTADO_VERRACO.PRESENTE'},
      {value: 3, label: 'COMUN.COMBOS.COMBO_ESTADO_VERRACO.ACTIVO'}
    ],
    comboVerracosPresentesActivos: [],
    comboEstadoAlbaran: [
      {value: estadoAlbaran.BORRADOR, label: translateEstadoAlbaran[estadoAlbaran.BORRADOR]},
      {value: estadoAlbaran.CANCELADO, label: translateEstadoAlbaran[estadoAlbaran.CANCELADO]},
      {value: estadoAlbaran.REALIZADO, label: translateEstadoAlbaran[estadoAlbaran.REALIZADO]}
    ],
    comboVerracosExtraccionAlbaran: [],
    comboPoolsExtraccionAlbaran: [],
    comboClienteActivo: [],
    comboClienteConVerracosEnPropiedad: [],
    comboEstadoPedido: [
      {value: 1, label: 'COMUN.COMBOS.COMBO_ESTADO_PEDIDO.BORRADOR'},
      {value: 2, label: 'COMUN.COMBOS.COMBO_ESTADO_PEDIDO.CANCELADO'},
      {value: 3, label: 'COMUN.COMBOS.COMBO_ESTADO_PEDIDO.CONFIRMADO'},
      {value: 4, label: 'COMUN.COMBOS.COMBO_ESTADO_PEDIDO.REALIZADO'}
    ],
    comboNumeroPedido: [],
    comboTransportistasCliente: [],
    comboRutaTransportistaCliente: [],
    comboVehiculosTransportistaDeterminado: [],
    comboConductoresTransportistaDeterminado: [],
    comboNumerosAlbaran: [],
    comboPropiedad: [
      { value: true, label: 'FICHA_VERRACO.NUEVO_VERRACO.COMBOS.PROPIO'},
      { value: false, label: 'FICHA_VERRACO.NUEVO_VERRACO.COMBOS.ALQUILADO'}
    ],
    comboEmpresaTransportistaActivos: [],
    comboInstalacionPadreActiva: [],
    comboLocalidadClientes: [],
    comboProvinciasClientes: [],
    comboUsuariosNoAsignadosOperario: [],
    comboOperarioPredeterminado: [],
    comboProductosValidacionActivos: [],
    comboTipoPerifericoConectividad: [
      {value: 1, label: 'COMUN.COMBOS.TIPO_PERIFERICO.MAQUINA_ENVASAR'},
      {value: 2, label: 'COMUN.COMBOS.TIPO_PERIFERICO.MAQUINA_DILUIR'},
      {value: 3, label: 'COMUN.COMBOS.TIPO_PERIFERICO.IMPRESORA'}
    ],
    comboTipoTareasAgenda: [
      {value: tipoTareasAgenda.CUARENTENA, label: translateTipoTareasAgenda[tipoTareasAgenda.CUARENTENA], ...colorTipoTareasAgenda[tipoTareasAgenda.CUARENTENA]},
      {value: tipoTareasAgenda.BIOSEGURIDAD_HIGIENE, label: translateTipoTareasAgenda[tipoTareasAgenda.BIOSEGURIDAD_HIGIENE], ...colorTipoTareasAgenda[tipoTareasAgenda.BIOSEGURIDAD_HIGIENE]},
      {value: tipoTareasAgenda.INTERVENCIONES_SANITARIAS, label: translateTipoTareasAgenda[tipoTareasAgenda.INTERVENCIONES_SANITARIAS], ...colorTipoTareasAgenda[tipoTareasAgenda.INTERVENCIONES_SANITARIAS]},
      {value: tipoTareasAgenda.COPIA_SEGURIDAD, label: translateTipoTareasAgenda[tipoTareasAgenda.COPIA_SEGURIDAD], ...colorTipoTareasAgenda[tipoTareasAgenda.COPIA_SEGURIDAD]},
      {value: tipoTareasAgenda.VIGILANCIA, label: translateTipoTareasAgenda[tipoTareasAgenda.VIGILANCIA], ...colorTipoTareasAgenda[tipoTareasAgenda.VIGILANCIA]}
    ],
    comboDiasAntes: [
      {value: 1, label: 1}, {value: 2, label: 2}, {value: 3, label: 3},
      {value: 4, label: 4}, {value: 5, label: 5}, {value: 6, label: 6},
      {value: 7, label: 7}, {value: 8, label: 8}, {value: 9, label: 9},
      {value: 10, label: 10}/*, {value: 11, label: 11}, {value: 12, label: 12},
      {value: 13, label: 13}, {value: 14, label: 14}, {value: 15, label: 15},
      {value: 16, label: 16}, {value: 17, label: 17}, {value: 18, label: 18},
      {value: 19, label: 19}, {value: 20, label: 20}, {value: 21, label: 21},
      {value: 22, label: 22}, {value: 23, label: 23}, {value: 24, label: 24},
      {value: 25, label: 25}, {value: 26, label: 26}, {value: 27, label: 27},
      {value: 28, label: 28}, {value: 29, label: 29}, {value: 30, label: 30},
      {value: 31, label: 31}*/
    ],
    comboMeses: [
      {value: 1, label: 'COMUN.DATE.MONTHS.ENERO'},
      {value: 2, label: 'COMUN.DATE.MONTHS.FEBRERO'},
      {value: 3, label: 'COMUN.DATE.MONTHS.MARZO'},
      {value: 4, label: 'COMUN.DATE.MONTHS.ABRIL'},
      {value: 5, label: 'COMUN.DATE.MONTHS.MAYO'},
      {value: 6, label: 'COMUN.DATE.MONTHS.JUNIO'},
      {value: 7, label: 'COMUN.DATE.MONTHS.JULIO'},
      {value: 8, label: 'COMUN.DATE.MONTHS.AGOSTO'},
      {value: 9, label: 'COMUN.DATE.MONTHS.SEPTIEMBRE'},
      {value: 10, label: 'COMUN.DATE.MONTHS.OCTUBRE'},
      {value: 11, label: 'COMUN.DATE.MONTHS.NOVIEMBRE'},
      {value: 12, label: 'COMUN.DATE.MONTHS.DICIEMBRE'}
    ],
    comboZonaHoraria: [],
    comboModulos: [
      {value: modulos.BASICO, label: translateModulos[modulos.BASICO]},
      {value: modulos.INFORMES, label: translateModulos[modulos.INFORMES]},
      {value: modulos.PEDIDOS_CLIENTES, label: translateModulos[modulos.PEDIDOS_CLIENTES]},
      {value: modulos.TRAZABILIDAD_BIOSEGURIDAD, label: translateModulos[modulos.TRAZABILIDAD_BIOSEGURIDAD]}
    ],
    comboAgrupacion: [
      {value: 1, label: 'COMUN.COMBOS.AGRUPACION.VERRACO'},
      {value: 2, label: 'COMUN.COMBOS.AGRUPACION.RAZA'},
      {value: 3, label: 'COMUN.COMBOS.AGRUPACION.LINEA_GENETICA'}
    ],
    comboNombreGrupoVerracoActiva: [],
    comboTodasUbicaciones: [],
    comboRutasActivas: [],
    comboVigenteActual: [
      {value: 1, label: 'COMUN.COMBOS.VIGENTE_ACTUAL.TODOS'},
      {value: 2, label: 'COMUN.COMBOS.VIGENTE_ACTUAL.VIGENTE_ACTUAL'}
    ],
    comboInstalacionesPadreOSinHijos: [],
    comboPendienteEncursoTerminado: [
      {value: 1, label: 'COMUN.COMBOS.COMBO_PENDIENTE_ENCURSO_TERMINADO.PENDIENTES'},
      {value: 2, label: 'COMUN.COMBOS.COMBO_PENDIENTE_ENCURSO_TERMINADO.EN_CURSO'},
      {value: 3, label: 'COMUN.COMBOS.COMBO_PENDIENTE_ENCURSO_TERMINADO.TERMINADO'}
    ],
    comboVerracoPrevisto: [
      {value: 1, label: 'COMUN.COMBOS.VERRACOS_PREVISTOS.VERRACOS_PLANING'},
      {value: 2, label: 'COMUN.COMBOS.VERRACOS_PREVISTOS.TODOS'}
    ],
    comboVerracoConSeguimiento: [],
    comboDireccionesClienteSinPredeterminado: [],
    comboIdioma: [
      {value: 'es', label: 'COMUN.COMBOS.IDIOMA.ESPANOL'},
      {value: 'en', label: 'COMUN.COMBOS.IDIOMA.INGLES'},
      {value: 'ch', label: 'COMUN.COMBOS.IDIOMA.CHINO'}
    ],
    comboFormatoFecha: [
      {value: 'day/month/year', label: 'day/month/year'},
      {value: 'month/day/year', label: 'month/day/year'}
    ],
    comboFormatoHora: [
      {value: '/12', label: '12h'},
      {value: '/24', label: '24h'}
    ],
    comboDecimalMiles: [
      {value: ',', label: ','},
      {value: '.', label: '.'}
    ]
  }
}

function comboCodLineaGeneticaSuccess (state, {data}) {
  return {
    ...state,
    comboCodLineaGenetica: data
  }
}

function comboLineaGeneticaSuccess (state, {data}) {
  return {
    ...state,
    comboLineaGenetica: data
  }
}

function comboCodRazaSuccess (state, {data}) {
  return {
    ...state,
    comboCodRaza: data
  }
}

function comboRazaSuccess (state, {data}) {
  return {
    ...state,
    comboRaza: data
  }
}

function comboNombreGrupoVerracoSuccess (state, {data}) {
  return {
    ...state,
    comboNombreGrupoVerraco: data
  }
}

function comboVerracoSuccess (state, {data}) {
  return {
    ...state,
    comboVerraco: data
  }
}

function comboTipoInstalacionPadreSuccess (state, {data}) {
  return {
    ...state,
    comboTipoInstalacionPadre: data
  }
}

function comboTipoInstalacionSuccess (state, {data}) {
  return {
    ...state,
    comboTipoInstalacion: data
  }
}

function comboInstalacionPadreSuccess (state, {data}) {
  return {
    ...state,
    comboInstalacionPadre: data
  }
}

function comboCodInstalacionSuccess (state, {data}) {
  return {
    ...state,
    comboCodInstalacion: data
  }
}

function comboNombreInstalacionSuccess (state, {data}) {
  return {
    ...state,
    comboNombreInstalacion: data
  }
}

function comboOperarioSuccess (state, {data}) {
  return {
    ...state,
    comboOperario: data
  }
}

function comboSalaExtraccionSuccess (state, {data}) {
  return {
    ...state,
    comboSalaExtraccion: data
  }
}

function comboClienteSuccess (state, {data}) {
  return {
    ...state,
    comboCliente: data
  }
}

function comboDireccionClienteSuccess (state, {data}) {
  return {
    ...state,
    comboDireccionCliente: data
  }
}

function ubicacionenesVaciasSuccess (state, {data}) {
  return {
    ...state,
    comboUbicacionVacia: data
  }
}

function comboUbicacionSuccess (state, {data}) {
  return {
    ...state,
    comboUbicacion: data
  }
}

function comboTipoDosisSuccess (state, {data}) {
  return {
    ...state,
    comboTipoDosis: data
  }
}

function comboObtenerCentroUsuarioSuccess (state, {data}) {
  return {
    ...state,
    comboObtenerCentroUsuario: data
  }
}

function comboListadoVerracosSuccess (state, {data}) {
  return {
    ...state,
    comboListadoVerracos: data
  }
}

function fetchComboPoolSuccess (state, {data}) {
  return {
    ...state,
    comboPool: data
  }
}

function fetchComboTipoDiluyenteSuccess (state, {data}) {
  return {
    ...state,
    comboTipoDiluyente: data
  }
}

function fetchComboProductoSuccess (state, {data}) {
  return {
    ...state,
    comboProducto: data
  }
}

function comboTipoAlimentacionSuccess (state, {data}) {
  return {
    ...state,
    comboTipoAlimentacion: data
  }
}

function comboTipoIntervencionSanitariaSuccess (state, {data}) {
  return {
    ...state,
    comboTipoIntervencionSanitaria: data
  }
}

function comboVerracoSinUbicacionSuccess (state, {data}){
  return{
    ...state,
    comboVerracoSinUbicacion: data
  }
}

function comboMaquinaSuccess (state, {data}){
  return{
    ...state,
    comboMaquina: data
  }
}

function comboRutaSuccess (state, {data}){
  return{
    ...state,
    comboRuta: data
  }
}

function comboRutasActivasSuccess (state, {data}){
  return{
    ...state,
    comboRuta: data
  }
}

function comboCifClienteSuccess (state, {data}){
  return{
    ...state,
    comboCifCliente: data
  }
}

function comboLocalidadClienteSuccess (state, {data}){
  return{
    ...state,
    comboLocalidadCliente: data
  }
}

function comboInstalacionesPadreSuccess (state, {data}){
  return{
    ...state,
    comboInstalacionesPadre: data
  }
}

function comboNombresOperariosSuccess (state, {data}){
  return{
    ...state,
    comboNombresOperarios: data
  }
}

function comboApellidosOperariosSuccess (state, {data}){
  return{
    ...state,
    comboApellidosOperarios: data
  }
}

function comboLocalidadOperariosSuccess (state, {data}){
  return{
    ...state,
    comboLocalidadOperarios: data
  }
}

function comboEmpresaVisitasSuccess (state, {data}){
  return{
    ...state,
    comboEmpresaVisitas: data
  }
}

function comboContactoVistasSuccess (state, {data}){
  return{
    ...state,
    comboContactoVistas: data
  }
}

function comboLineaGeneticaActivaSuccess (state, {data}){
  return{
    ...state,
    comboLineaGeneticaActiva: data
  }
}

function comboRazaActivaSuccess (state, {data}){
  return{
    ...state,
    comboRazaActiva: data
  }
}

function comboOperarioActivoSuccess (state, {data}){
  return{
    ...state,
    comboOperarioActivo: data
  }
}

function comboSalaExtraccionActivoSuccess (state, {data}){
  return{
    ...state,
    comboSalaExtraccionActivo: data
  }
}

function fetchComboRectaCalibradoSuccess (state, {data}){
  return{
    ...state,
    comboRectaCalibrado: data
  }
}

function comboEmpresaTransportistaSuccess (state, {data}){
  return{
    ...state,
    comboEmpresaTransportista: data
  }
}
function comboConductoresTransportistaSuccess (state, {data}){
  return{
    ...state,
    comboConductoresTransportista: data
  }
}
function comboVehiculosTransportistaSuccess (state, {data}){
  return{
    ...state,
    comboVehiculosTransportista: data
  }
}
function comboUsuariosCentroSuccess (state, {data}){
  return{
    ...state,
    comboUsuariosCentro: data
  }
}
function comboGrupoUsuariosCentroSuccess (state, {data}){
  return{
    ...state,
    comboGrupoUsuariosCentro: data
  }
}

function comboNombreProductosSuccess (state, {data}){
  return{
    ...state,
    comboNombreProductos: data
  }
}

function comboReferenciaProductosSuccess (state, {data}){
  return{
    ...state,
    comboReferenciaProductos: data
  }
}

function comboNombreEscandalloSuccess (state, {data}){
  return{
    ...state,
    comboNombreEscandallo: data
  }
}

function comboProductoEscandalloSuccess (state, {data}){
  return{
    ...state,
    comboProductoEscandallo: data
  }
}

function comboProveedoresEntradaMercanciaSuccess (state, {data}){
  return{
    ...state,
    comboProveedoresEntradaMercancia: data
  }
}

function comboAlbaranesEntradaMercanciaSuccess (state, {data}){
  return{
    ...state,
    comboAlbaranesEntradaMercancia: data
  }
}

function comboOrigenesEntradaMercanciaSuccess (state, {data}){
  return{
    ...state,
    comboOrigenesEntradaMercancia: data
  }
}

function comboLotesSuccess (state, {data}){
  return{
    ...state,
    comboLotes: data
  }
}

function comboLotesActivosSuccess (state, {data}){
  return{
    ...state,
    comboLotesActivos: data
  }
}

function comboProductosActivosSuccess (state, {data}){
  return{
    ...state,
    comboProductosActivos: data
  }
}

function comboProductosActivosTrazabilidadSuccess (state, {data}){
  return{
    ...state,
    comboProductosActivosTrazabilidad: data
  }
}

function comboLotesProductoEspecificoActivoSuccess (state, {data}){
  return{
    ...state,
    comboLotesProductoEspecificoActivo: data
  }
}

function comboLotesProductoEspecificoActivoTrazabilidadSuccess (state, {data}){
  return{
    ...state,
    comboLotesProductoEspecificoActivoTrazabilidad: data
  }
}

function comboRazonSocialClientesActivosSuccess (state, {data}){
  return{
    ...state,
    comboRazonSocialClientesActivos: data
  }
}

function comboAliasClientesActivosSuccess (state, {data}){
  return{
    ...state,
    comboAliasClientesActivos: data
  }
}

function comboIndexSuccess (state, {data}){
  return{
    ...state,
    comboIndex: data
  }
}

function comboUbicacionPadreSuccess (state, {data}){
  return{
    ...state,
    comboUbicacionPadre: data
  }
}

function comboVerracosPresentesActivosSuccess (state, {data}){
  return{
    ...state,
    comboVerracosPresentesActivos: data
  }
}

function comboVerracosExtraccionAlbaranSuccess (state, {data}){
  return{
    ...state,
    comboVerracosExtraccionAlbaran: data
  }
}

function comboPoolsExtraccionAlbaranSuccess (state, {data}){
  return{
    ...state,
    comboPoolsExtraccionAlbaran: data
  }
}
function comboClienteActivoSuccess (state, {data}){
  return{
    ...state,
    comboClienteActivo: data
  }
}
function comboClienteConVerracosEnPropiedadSuccess (state, {data}){
  return{
    ...state,
    comboClienteConVerracosEnPropiedad: data
  }
}

function comboNumeroPedidoSuccess (state, {data}){
  return{
    ...state,
    comboNumeroPedido: data
  }
}

function comboTransportistasClienteSuccess (state, {data}){
  return{
    ...state,
    comboTransportistasCliente: data
  }
}

function comboRutaTransportistaClienteSuccess (state, {data}){
  return{
    ...state,
    comboRutaTransportistaCliente: data
  }
}

function comboVehiculosTransportistaDeterminadoSuccess (state, {data}){
  return{
    ...state,
    comboVehiculosTransportistaDeterminado: data
  }
}

function comboConductoresTransportistaDeterminadoSuccess (state, {data}){
  return{
    ...state,
    comboConductoresTransportistaDeterminado: data
  }
}

function comboNumerosAlbaranSuccess (state, {data}){
  return{
    ...state,
    comboNumerosAlbaran: data
  }
}

function comboEmpresaTransportistaActivosSuccess (state, {data}){
  return{
    ...state,
    comboEmpresaTransportistaActivos: data
  }
}

function comboInstalacionPadreActivaSuccess (state, {data}) {
  return {
    ...state,
    comboInstalacionPadreActiva: data
  }
}

function comboLocalidadClientesSuccess (state, {data}) {
  return {
    ...state,
    comboLocalidadClientes: data
  }
}

function comboProvinciasClientesSuccess (state, {data}) {
  return {
    ...state,
    comboProvinciasClientes: data
  }
}

function comboUsuariosNoAsignadosOperarioSuccess (state, {data}) {
  return {
    ...state,
    comboUsuariosNoAsignadosOperario: data
  }
}

function comboOperarioPredeterminadoSuccess (state, {data}) {
  return {
    ...state,
    comboOperarioPredeterminado: data
  }
}

function comboProductosValidacionActivosSuccess (state, {data}) {
  return {
    ...state,
    comboProductosValidacionActivos: data
  }
}

function comboNombreGrupoVerracoActivaSuccess (state, {data}) {
  return {
    ...state,
    comboNombreGrupoVerracoActiva: data
  }
}

function comboTodasUbicacionesSuccess (state, {data}) {
  return {
    ...state,
    comboTodasUbicaciones: data
  }
}

function comboInstalacionesPadreOSinHijosSuccess (state, {data}) {
  return {
    ...state,
    comboInstalacionesPadreOSinHijos: data
  }
}

function comboVerracoConSeguimientoSuccess (state, {data}) {
  return {
    ...state,
    comboVerracoConSeguimiento: data
  }
}

function comboDireccionesClienteSinPredeterminadoSuccess (state, {data}) {
  return {
    ...state,
    comboDireccionesClienteSinPredeterminado: data
  }
}

function comboZonaHorariaSuccess (state, {data}) {
  return {
    ...state,
    comboZonaHoraria: data
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case combosActionTypes.COMBO_TODAS_UBICACIONES_SUCCESS:
      return comboTodasUbicacionesSuccess(state, action)
    case combosActionTypes.COMBO_COD_LINEA_GENETICA_SUCCESS:
      return comboCodLineaGeneticaSuccess(state, action)
    case combosActionTypes.COMBO_LINEA_GENETICA_SUCCESS:
      return comboLineaGeneticaSuccess(state, action)
    case combosActionTypes.COMBO_COD_RAZA_SUCCESS:
      return comboCodRazaSuccess(state, action)
    case combosActionTypes.COMBO_RAZA_SUCCESS:
      return comboRazaSuccess(state, action)
    case combosActionTypes.COMBO_NOMBRE_GRUPO_VERRACO_SUCCESS:
      return comboNombreGrupoVerracoSuccess(state, action)
    case combosActionTypes.COMBO_VERRACO_SUCCESS:
      return comboVerracoSuccess(state, action)
    case combosActionTypes.COMBO_TIPO_INSTALACION_PADRE_SUCCESS:
      return comboTipoInstalacionPadreSuccess(state, action)
    case combosActionTypes.COMBO_TIPO_INSTALACION_SUCCESS:
      return comboTipoInstalacionSuccess(state, action)
    case combosActionTypes.COMBO_INSTALACION_PADRE_SUCCESS:
      return comboInstalacionPadreSuccess(state, action)
    case combosActionTypes.COMBO_COD_INSTALACION_SUCCESS:
      return comboCodInstalacionSuccess(state, action)
    case combosActionTypes.COMBO_NOMBRE_INSTALACION_SUCCESS:
      return comboNombreInstalacionSuccess(state, action)
    case combosActionTypes.COMBO_OPERARIO_SUCCESS:
      return comboOperarioSuccess(state, action)
    case combosActionTypes.COMBO_SALA_EXTRACCION_SUCCESS:
      return comboSalaExtraccionSuccess(state, action)
    case combosActionTypes.COMBO_CLIENTE_SUCCESS:
      return comboClienteSuccess(state, action)
    case combosActionTypes.COMBO_DIRECCION_CLIENTE_SUCCESS:
      return comboDireccionClienteSuccess(state, action)
    case movimientoVerracoActionTypes.UBICACIONES_VACIAS_SUCCESS:
      return ubicacionenesVaciasSuccess(state, action)
    case combosActionTypes.COMBO_TIPO_DOSIS_SUCCESS:
      return comboTipoDosisSuccess(state, action)
    case combosActionTypes.COMBO_OBTENER_CENTRO_USUARIO_SUCCESS:
      return comboObtenerCentroUsuarioSuccess(state, action)
    case combosActionTypes.COMBO_LISTADO_VERRACO:
      return comboListadoVerracosSuccess(state, action)
    case combosActionTypes.FETCH_COMBO_POOL_SUCCESS:
      return fetchComboPoolSuccess(state, action)
    case combosActionTypes.FETCH_COMBO_TIPO_DILUYENTE_SUCCESS:
      return fetchComboTipoDiluyenteSuccess(state, action)
    case combosActionTypes.FETCH_COMBO_PRODUCTO_SUCCESS:
      return fetchComboProductoSuccess(state, action)
    case combosActionTypes.COMBO_TIPO_ALIMENTACION_SUCCESS:
      return comboTipoAlimentacionSuccess(state, action)
    case combosActionTypes.COMBO_TIPO_INTERVENCION_SANITARIA_SUCCESS:
      return comboTipoIntervencionSanitariaSuccess(state, action)
    case combosActionTypes.COMBO_VERRACO_SIN_UBICACION_SUCCESS:
      return comboVerracoSinUbicacionSuccess(state, action)
    case combosActionTypes.COMBO_MAQUINA_SUCCESS:
      return comboMaquinaSuccess(state, action)
    case combosActionTypes.COMBO_RUTA_SUCCESS:
      return comboRutaSuccess(state, action)
    case combosActionTypes.COMBO_RUTAS_ACTIVAS_SUCCESS:
      return comboRutasActivasSuccess(state, action)
    case combosActionTypes.COMBO_CIF_CLIENTE_SUCCESS:
      return comboCifClienteSuccess(state, action)
    case combosActionTypes.COMBO_LOCALIDAD_CLIENTE_SUCCESS:
      return comboLocalidadClienteSuccess(state, action)
    case combosActionTypes.COMBO_INSTALACIONES_PADRE_SUCCESS:
      return comboInstalacionesPadreSuccess(state, action)
    case combosActionTypes.COMBO_NOMBRES_OPERARIOS_SUCCESS:
      return comboNombresOperariosSuccess(state, action)
    case combosActionTypes.COMBO_APELLIDOS_OPERARIOS_SUCCESS:
      return comboApellidosOperariosSuccess(state, action)
    case combosActionTypes.COMBO_LOCALIDAD_OPERARIOS_SUCCESS:
      return comboLocalidadOperariosSuccess(state, action)
    case combosActionTypes.COMBO_EMPRESA_VISITAS_SUCCESS:
      return comboEmpresaVisitasSuccess(state, action)
    case combosActionTypes.COMBO_CONTACTO_VISITAS_SUCCESS:
      return comboContactoVistasSuccess(state, action)
    case combosActionTypes.COMBO_LINEA_GENETICA_ACTIVA_SUCCESS:
      return comboLineaGeneticaActivaSuccess(state, action)
    case combosActionTypes.COMBO_RAZA_ACTIVA_SUCCESS:
      return comboRazaActivaSuccess(state, action)
    case combosActionTypes.COMBO_OPERARIO_ACTIVO_SUCCESS:
      return comboOperarioActivoSuccess(state, action)
    case combosActionTypes.COMBO_SALA_EXTRACCION_ACTIVO_SUCCESS:
      return comboSalaExtraccionActivoSuccess(state, action)
    case combosActionTypes.FETCH_COMBO_RECTA_CALIBRADO_SUCCESS:
      return fetchComboRectaCalibradoSuccess(state, action)
    case combosActionTypes.COMBO_EMPRESA_TRANSPORTISTA_SUCCESS:
      return comboEmpresaTransportistaSuccess(state, action)
    case combosActionTypes.COMBO_CONDUCTORES_TRANSPORTISTA_SUCCESS:
      return comboConductoresTransportistaSuccess(state, action)
    case combosActionTypes.COMBO_VEHICULO_TRANSPORTISTA_SUCCESS:
      return comboVehiculosTransportistaSuccess(state, action)
    case combosActionTypes.COMBO_USUARIOS_CENTRO_SUCCESS:
      return comboUsuariosCentroSuccess(state, action)
    case combosActionTypes.COMBO_GRUPO_USUARIOS_CENTRO_SUCCESS:
      return comboGrupoUsuariosCentroSuccess(state, action)
    case combosActionTypes.COMBO_NOMBRE_PRODUCTO_SUCCESS:
      return comboNombreProductosSuccess(state, action)
    case combosActionTypes.COMBO_REFERENCIA_PRODUCTO_SUCCESS:
      return comboReferenciaProductosSuccess(state, action)
    case combosActionTypes.COMBO_NOMBRE_ESCANDALLO_SUCCESS:
      return comboNombreEscandalloSuccess(state, action)
    case combosActionTypes.COMBO_PRODUCTO_ESCANDALLO_SUCCESS:
      return comboProductoEscandalloSuccess(state, action)
    case combosActionTypes.COMBO_PROVEEDORES_ENTRADA_MERCANCIA_SUCCESS:
      return comboProveedoresEntradaMercanciaSuccess(state, action)
    case combosActionTypes.COMBO_ORIGENES_ENTRADA_MERCANCIA_SUCCESS:
      return comboOrigenesEntradaMercanciaSuccess(state, action)
    case combosActionTypes.COMBO_ALBARANES_ENTRADA_MERCANCIA_SUCCESS:
      return comboAlbaranesEntradaMercanciaSuccess(state, action)
    case combosActionTypes.COMBO_LOTES_SUCCESS:
      return comboLotesSuccess(state, action)
    case combosActionTypes.COMBO_LOTES_ACTIVOS_SUCCESS:
      return comboLotesActivosSuccess(state, action)
    case combosActionTypes.COMBO_PRODUCTOS_ACTIVOS_SUCCESS:
      return comboProductosActivosSuccess(state, action)
    case combosActionTypes.COMBO_PRODUCTOS_ACTIVOS_TRAZABILIDAD_SUCCESS:
      return comboProductosActivosTrazabilidadSuccess(state, action)
    case combosActionTypes.COMBO_LOTES_PRODUCTO_ESPECIFICO_ACTIVO_SUCCESS:
      return comboLotesProductoEspecificoActivoSuccess(state, action)
    case combosActionTypes.COMBO_LOTES_PRODUCTO_ESPECIFICO_ACTIVO_TRAZABILIDAD_SUCCESS:
      return comboLotesProductoEspecificoActivoTrazabilidadSuccess(state, action)
    case combosActionTypes.COMBO_RAZON_SOCIAL_CLIENTES_ACTIVOS_SUCCESS:
      return comboRazonSocialClientesActivosSuccess(state, action)
    case combosActionTypes.COMBO_ALIAS_CLIENTES_ACTIVOS_SUCCESS:
      return comboAliasClientesActivosSuccess(state, action)
    case combosActionTypes.COMBO_INDEX_SUCCESS:
      return comboIndexSuccess(state, action)
    case combosActionTypes.COMBO_UBICACION_PADRE_SUCCESS:
      return comboUbicacionPadreSuccess(state, action)
    case combosActionTypes.COMBO_UBICACION_SUCCESS:
      return comboUbicacionSuccess(state, action)
    case combosActionTypes.COMBO_VERRACOS_PRESENTES_ACTIVOS_SUCCESS:
      return comboVerracosPresentesActivosSuccess(state, action)
    case combosActionTypes.COMBO_VERRACOS_EXTRACCION_ALBARAN_SUCCESS:
      return comboVerracosExtraccionAlbaranSuccess(state, action)
    case combosActionTypes.COMBO_POOLS_EXTRACCION_ALBARAN_SUCCESS:
      return comboPoolsExtraccionAlbaranSuccess(state, action)
    case combosActionTypes.COMBO_CLIENTE_ACTIVO_SUCCESS:
      return comboClienteActivoSuccess(state, action)
    case combosActionTypes.COMBO_CLIENTE_CON_VERRACOS_EN_PROPIEDAD_SUCCESS:
      return comboClienteConVerracosEnPropiedadSuccess(state, action)
    case combosActionTypes.COMBO_NUMERO_PEDIDO_SUCCESS:
      return comboNumeroPedidoSuccess(state, action)
    case combosActionTypes.COMBO_TRANSPORTISTAS_CLIENTE_SUCCESS:
      return comboTransportistasClienteSuccess(state, action)
    case combosActionTypes.COMBO_RUTA_TRANSPORTISTA_CLIENTE_SUCCESS:
      return comboRutaTransportistaClienteSuccess(state, action)
    case combosActionTypes.COMBO_VEHICULOS_TRANSPORTISTA_DETERMINADO_SUCCESS:
      return comboVehiculosTransportistaDeterminadoSuccess(state, action)
    case combosActionTypes.COMBO_CONDUCTORES_TRANSPORTISTA_DETERMINADO_SUCCESS:
      return comboConductoresTransportistaDeterminadoSuccess(state, action)
    case combosActionTypes.COMBO_NUMEROS_ALBARAN_SUCCESS:
      return comboNumerosAlbaranSuccess(state, action)
    case combosActionTypes.COMBO_EMPRESA_TRANSPORTISTA_ACTIVOS_SUCCESS:
      return comboEmpresaTransportistaActivosSuccess(state, action)
    case combosActionTypes.COMBO_INSTALACION_PADRE_ACTIVA_SUCCESS:
      return comboInstalacionPadreActivaSuccess(state, action)
    case combosActionTypes.COMBO_LOCALIDAD_CLIENTES_SUCCESS:
      return comboLocalidadClientesSuccess(state, action)
    case combosActionTypes.COMBO_PROVINCIAS_CLIENTES_SUCCESS:
      return comboProvinciasClientesSuccess(state, action)
    case combosActionTypes.COMBO_USUARIOS_NO_ASIGNADOS_OPERARIO_SUCCESS:
      return comboUsuariosNoAsignadosOperarioSuccess(state, action)
    case combosActionTypes.COMBO_OPERARIO_PREDETERMINADO_SUCCESS:
      return comboOperarioPredeterminadoSuccess(state, action)
    case combosActionTypes.COMBO_PRODUCTOS_VALIDACION_ACTIVOS_SUCCESS:
      return comboProductosValidacionActivosSuccess(state, action)
    case combosActionTypes.COMBO_NOMBRE_GRUPO_VERRACO_ACTIVA_SUCCESS:
      return comboNombreGrupoVerracoActivaSuccess(state, action)
    case combosActionTypes.COMBO_INSTALACIONES_PADRE_O_SIN_HIJOS_SUCCESS:
      return comboInstalacionesPadreOSinHijosSuccess(state, action)
    case combosActionTypes.COMBO_VERRACOS_CON_SEGUIMIENTO_SUCCESS:
      return comboVerracoConSeguimientoSuccess(state, action)
    case combosActionTypes.COMBO_DIRECCION_CLIENTE_SIN_PERSONALIZADO_SUCCESS:
      return comboDireccionesClienteSinPredeterminadoSuccess(state, action)
    case combosActionTypes.COMBO_ZONA_HORARIA_SUCCESS:
      return comboZonaHorariaSuccess(state, action)
    default:
      return state
  }
}
