import React, { Component } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import {reduxForm, Field, setSubmitFailed} from 'redux-form'
import { required } from '../../../util/validationFunctions'
import { Delete, AddCircleOutline, Warning, Photo, PictureAsPdf, FileCopy, Print, Notifications } from '@material-ui/icons'
import './ModalRegistroIntervencionSanitaria.scss'
import Button from '../../comun/button/Button'
import ButtonChangeRecord from '../../comun/button/ButtonChangeRecord'
import ButtonLockable from '../../comun/button/ButtonLockable'
import FormSubtitle from '../../comun/form/FormSubtitle'
import InputText from '../../comun/form/InputText'
import InputCheckBox from '../../comun/form/InputCheckBox'
import InputTextArea from '../../comun/form/InputTextArea'
import InputDatePicker from '../../comun/form/InputDatePicker'
import InputNumerical from '../../comun/form/InputNumerical'
import InputSelect from '../../comun/form/InputSelect'
import ListadoPage from '../../../containers/page/ListadoPage'
import ModalVerracoRegistroIntervencionSanitariaPage from '../../../containers/planSanitario/registroIntervencionSanitaria/ModalVerracoRegistroIntervencionSanitariaPage'
import {estadoVerraco as estadoVerracoConstants} from '../../../constants/estadoVerraco'
import {estadoVerraco as estadoVerracoStilyzer} from '../../comun/table/Stilyzers'
import Dropzone from 'react-dropzone'
import {permisos as permisosConstant} from '../../../constants/permisos'
import ModalAgendaPage from '../../../containers/agenda/ModalAgendaPage'
import {dateTimeZones} from '../../../util/formatFunctions'

class ModalRegistroIntervencionSanitaria extends Component {
  constructor (props) {
    super(props)
    this.state = {
      imputSelected: false,
      mensajeErrorVerracos: '',
      classErrorVerracos: false,
      archivos: [],
      mensajeSubirArchivos: false,
      medicarMayor: false,
      fechaInicio: dateTimeZones(this.props.auth.zonaHorariaString)
    }
  }

  closeRegistroIntervencion(){
    this.setState({classErrorVerracos: false})
    this.setState({mensajeErrorVerracos: ''})
    this.props.actions.closeRegistroIntervencion()
    this.props.actions.vaciarDatosModalRegistroIntervencionSanitariaSuccess()
  }

  componentDidMount(){
    this.props.actions.comboTipoIntervencionSanitaria()
    this.props.actions.comboOperarioPredeterminado()
  }

  componentDidUpdate(prevProps, prevState){
    if (Object.keys(this.props.verracosAnadidos).length > 0) {
      if (this.props.verracosAnadidos !== prevProps.verracosAnadidos) {
        this.setState({classErrorVerracos: false})
        this.setState({mensajeErrorVerracos: ''})
      }
    } else {
      if (this.props.verracosAnadidos !== prevProps.verracosAnadidos) {
        this.setState({mensajeErrorVerracos: this.props.t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.ERROR_VERRACO_REQUERIDO')})
      }
    }

    if (this.props.archivo !== prevProps.archivo){
      this.setState({archivos: this.props.archivo})
    }

    if (this.props.showModalRegistroIntervencion !== prevProps.showModalRegistroIntervencion) {
      if (this.props.showModalRegistroIntervencion === true && Object.keys(this.props.data).length === 0) {
        this.props.change('fechaInicio', dateTimeZones(this.props.auth.zonaHorariaString))
      }
    }

    if (this.props.formModalRegistroIntervencionSanitaria !== prevProps.formModalRegistroIntervencionSanitaria){
      if (this.props.showModalRegistroIntervencion === true && Object.keys(this.props.data).length > 0) {
        if (Object.keys(this.props.formModalRegistroIntervencionSanitaria).length > 0 && this.props.formModalRegistroIntervencionSanitaria.values.idIntervencion){
          if (this.props.formModalRegistroIntervencionSanitaria.values.intervalo === 0) {
            this.props.change('intervalo', "0")
          }
          if (this.props.formModalRegistroIntervencionSanitaria.values.periodoSupresion === 0) {
            this.props.change('periodoSupresion', "0")
          }
          if (this.props.formModalRegistroIntervencionSanitaria.values.diasMedicar === 0) {
            this.props.change('diasMedicar', "0")
          }
          if (this.props.formModalRegistroIntervencionSanitaria.values.numeroIntervenciones === 0) {
            this.props.change('numeroIntervenciones', "0")
          }
        }
      }
    }

    if (this.props.submitSucceeded !== prevProps.submitSucceeded && (this.state.classErrorVerracos === true || this.state.medicarMayor === true)) {
      this.props.dispatch(setSubmitFailed('ModalRegistroIntervencionSanitaria'))
    }

    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable !== prevProps.formSimpleTable) {
        let contActivos = 0
        if (this.props.formSimpleTable.values) {
          Object.values(this.props.formSimpleTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todo-modal-registro-intervencion-sanitaria', false)
            this.setState({ imputSelected: false })
            this.props.actions.deSeleccionarTodosModalRegistroIntervencionSanitaria()
          }
        }
      }
    }
  }

  submitNuevoRegistroIntervencionSanitaria (values) {
    return new Promise((resolve, reject) => {
      if (this.state.medicarMayor === false) {
        this.setState({fechaInicio: values.fechaInicio})
        values.verraco = this.props.verracosAnadidos
        values.archivos = this.props.archivo
        if (Object.keys(this.props.verracosAnadidos).length > 0) {
          this.setState({classErrorVerracos: false})
          if (Object.keys(this.props.data).length > 0) {
            this.props.actions.editarRegistroIntervencionSanitaria(values, resolve, reject)
          } else {
            this.props.actions.submitNuevoRegistroIntervencionSanitaria(values, resolve, reject)
          }
        } else {
          this.setState({classErrorVerracos: true})
          this.setState({mensajeErrorVerracos: this.props.t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.ERROR_VERRACO_REQUERIDO')})
          reject()
        }
      } else {
        reject()
      }
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  guardarYnuevoRegistroIntervencionSanitaria(values){
    return new Promise((resolve, reject) => {
      if (this.state.medicarMayor === false) {
        this.setState({fechaInicio: values.fechaInicio})
        values.verraco = this.props.verracosAnadidos
        values.archivos = this.props.archivo
        if (Object.keys(this.props.verracosAnadidos).length > 0) {
          this.setState({classErrorVerracos: false})
          this.props.actions.guardarYnuevoRegistroIntervencionSanitaria(values, resolve, reject)
        } else {
          this.setState({classErrorVerracos: true})
          this.setState({mensajeErrorVerracos: this.props.t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.ERROR_VERRACO_REQUERIDO')})
          reject()
        }
      } else {
        reject()
      }
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }
  intercambiarCheckModalRegistroIntervencionSanitaria (value) {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosModalRegistroIntervencionSanitaria()
    } else {
      this.props.actions.deSeleccionarTodosModalRegistroIntervencionSanitaria()
    }
  }

  deleteSeleccionVerracosRegistroIntervencionSanitaria () {
    const itemsSeleccion = this.props.verracosAnadidos.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] !== true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idVerraco': row.idVerraco
        }
      )
    })
    let list = itemsSeleccion.filter(function (item) {
      if (item !== false){
        return item
      }
    })
    this.props.actions.deleteSeleccionVerracosRegistroIntervencionSanitaria(list)
  }

  onDropArchivos(archivos) {
    let extension = this.getFileExtension(archivos[0].name)
    if (extension === 'pdf') {
      this.setState({mensajeSubirArchivos: false})
      var numFiles=archivos.length
      archivos.map((archivo, i) => {
        var reader = new FileReader();
        reader.onloadend = () => {
          archivo.content=reader.result
          const archivoVerraco = {archivo: archivo.name, content: reader.result}
          this.setState({archivos: this.state.archivos.concat(archivoVerraco)})
          this.props.actions.subirArchivosIntervencion(this.state.archivos)
        }
        reader.readAsDataURL(archivo)
      })
    } else {
      this.setState({mensajeSubirArchivos: true})
    }
  }

  calcularTerminado (value) {
    let diaHoy = dateTimeZones(this.props.auth.zonaHorariaString)
    if (new Date(value).getTime() <= diaHoy.getTime()) {
      this.props.change('terminado', this.props.t('COMUN.COMBOS.TRATAMIENTO.SI'))
    } else {
      this.props.change('terminado', this.props.t('COMUN.COMBOS.TRATAMIENTO.NO'))
    }
  }

  getFileExtension(filename) {
    return filename.split('.').pop()
  }

  eliminaArchivo(archivo){
    this.props.actions.eliminarArchvioIntervencion(archivo)
  }

  calculoFechaFinal () {
    let formatoFechaHora = this.props.auth.formaFechaHora
    let numeroIntervenciones = document.getElementById("numeroIntervenciones").value
    let intervalo = document.getElementById("intervalo").value
    let diasMedicar = document.getElementById("diasMedicar").value
    let fechaPrimeraAplicacion = document.getElementsByClassName('form-RegistroIntervencionSanitaria')[0].getElementsByClassName('datepicker-indv')[1].getElementsByTagName('input')[0].value;
    var separadorFecha = fechaPrimeraAplicacion.split('/')
    let formatoFecha
    if(intervalo===""){
      intervalo="0"
    }
    if(diasMedicar===""){
      diasMedicar=0
    }
    if (formatoFechaHora === 'day/month/year/12' || formatoFechaHora === 'day/month/year/24'){
      formatoFecha = new Date(separadorFecha[2], separadorFecha[1] - 1, separadorFecha[0])
    } else if (formatoFechaHora === 'month/day/year/12' || formatoFechaHora === 'month/day/year/24'){
      formatoFecha = new Date(separadorFecha[2], separadorFecha[0] - 1, separadorFecha[1])
    } else if (formatoFechaHora === 'year/month/day/12' || formatoFechaHora === 'year/month/day/24'){
      formatoFecha = new Date(separadorFecha[0], separadorFecha[1] - 1, separadorFecha[2])
    } else if (formatoFechaHora === 'year/day/month/12' || formatoFechaHora === 'year/day/month/24'){
      formatoFecha = new Date(separadorFecha[0], separadorFecha[2] - 1, separadorFecha[1])
    }
    else{
      formatoFecha = dateTimeZones(this.props.auth.zonaHorariaString)
    }
    if (intervalo === "0") {
      this.setState({medicarMayor: false})
      let calculo
      calculo = (parseFloat(numeroIntervenciones) * parseFloat(intervalo)) - (parseFloat(intervalo) - parseFloat(diasMedicar))
      let fecha = new Date(dateTimeZones(this.props.auth.zonaHorariaString).setDate(formatoFecha.getDate() + (calculo - 1)))
      if(fecha<formatoFecha){
        fecha=formatoFecha
      }
      if (!isNaN(fecha) && fecha >= dateTimeZones(this.props.auth.zonaHorariaString)) {
        this.props.change('fechaFin', fecha)
      }
    } else {
      if ((parseFloat(diasMedicar) > parseFloat(intervalo))) {
        this.setState({medicarMayor: true})
        this.props.change('fechaFin', null)
      } else {
        this.setState({medicarMayor: false})
        let calculo
        calculo = (parseFloat(numeroIntervenciones) * parseFloat(intervalo)) - (parseFloat(intervalo) - parseFloat(diasMedicar))
        let fecha = new Date(dateTimeZones(this.props.auth.zonaHorariaString).setDate(formatoFecha.getDate() + (calculo - 1)))
        if(fecha<formatoFecha){
          fecha=formatoFecha
        }
        if (!isNaN(fecha) && fecha >= dateTimeZones(this.props.auth.zonaHorariaString)) {
          this.props.change('fechaFin', fecha)
        }
      }
    }
  }

  imprimirInformeRegistroIntervencion (values) {
    return new Promise((resolve, reject) => {
      if (this.state.medicarMayor === false) {
        this.setState({fechaInicio: values.fechaInicio})
        values.verraco = this.props.verracosAnadidos
        values.archivos = this.props.archivo
        if (Object.keys(this.props.verracosAnadidos).length > 0) {
          this.setState({classErrorVerracos: false})
          this.props.actions.imprimirInformeRegistroIntervencion(values, this.props.data.intervencion.idIntervencion, resolve, reject)
        } else {
          this.setState({classErrorVerracos: true})
          this.setState({mensajeErrorVerracos: this.props.t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.ERROR_VERRACO_REQUERIDO')})
          reject()
        }
      } else {
        reject()
      }
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  obtenerArchivo (archivo) {
    if(archivo.content){
      let element = document.createElement('a')
      element.setAttribute('href', encodeURIComponent(archivo))
      element.setAttribute('href', archivo.content)
      element.setAttribute('download', archivo.archivo)
      element.style.display = 'none'
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
    }
    else{
      this.props.actions.obtenerArchivoContentIntervencion(archivo)
    }
  }

  abrirModalAgenda (idAgenda) {
    this.props.actions.abrirModalAgenda(idAgenda)
  }

  duplicar(values){
    return new Promise((resolve, reject) => {
      this.props.duplicar(values, resolve, reject)
    })
      .then(() => { console.log('completado') })
      .catch(() => { console.log('error') })
  }

  render () {
    const {
      t, handleSubmit, submitting, list, showModalRegistroIntervencion, data, verracosAnadidos, checkedModalRegistroIntervencionSanitaria, numeroRegistrosPorPagina, paginaActual,
      actions: {recuperarDatosModalRegistroIntervencionSanitaria, abrirModalVerracoRegistroIntervencionSanitaria, onDeleteVerracoAnadidoRegistroIntervencionSanitaria},
      combos: {comboTipoIntervencionSanitaria, comboOperarioPredeterminado}
    } = this.props

    verracosAnadidos.forEach(
      (row) => row.idElemento = row.idVerraco
    )

    const tableVerracosAnadidos = {
      id: 'verracoAnadidoTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.REGISTRO_INTERVENCION_SANITARIA] > 1) && !submitting,
      hasOptions: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.REGISTRO_INTERVENCION_SANITARIA] > 1) && !submitting,
      columns: [
        {id: 'codVerraco', name: t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.TABLA.NOMBRE')},
        {id: 'fechaAsignacion', name: t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.TABLA.FECHA_INICIO'), type: 'fecha'},
        {id: 'nombreRaza', name: t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.TABLA.RAZA')},
        {id: 'nombreLinea', name: t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.TABLA.LINEA_GENETICA')},
        {id: 'estadoVerraco', name: t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.TABLA.ESTADO'), stilyzer: estadoVerracoStilyzer},
        {id: 'ubicacionverraco', name: t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.TABLA.UBICACION')},
        {id: 'index', name: t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.TABLA.INDEX')}
      ],
      rows: verracosAnadidos,
      rowsVerraco: true,
      onDelete: (row) => onDeleteVerracoAnadidoRegistroIntervencionSanitaria(row.idVerraco),
      mostrarCambioEstado: false,
      mostrarModificar: false,
      mostrarDuplicar: false,
      initialValues: {},
      tablaModal: true,
      idBlockOptions: 1,
      showModal: this.props.showModalVerracoRegistroIntervencionSanitaria,
      colorearRow: (row) => {
        switch(row.estadoverraco) {
          case estadoVerracoConstants.CUARENTENA:
            return 'verraco-en-cuarentena';
          case estadoVerracoConstants.PRESENTADO:
            return 'verraco-en-presente';
          case estadoVerracoConstants.ACTIVO:
            return 'verraco-en-activo';
          case estadoVerracoConstants.BAJA:
            return 'verraco-en-baja';
          case estadoVerracoConstants.ELIMINADO:
            return 'verraco-en-eliminado';
          default:
            return 'verraco-en-ningun-estado';
        }
      }
    }

    tableVerracosAnadidos.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableVerracosAnadidos.initialValues['check' + row.idElemento] = checkedModalRegistroIntervencionSanitaria
        }
      }
    )

    return (
      <Modal show={showModalRegistroIntervencion} onHide={() => this.closeRegistroIntervencion()} aria-labelledby="contained-modal-title-lg" className="modal-registro-intervencion-sanitaria" backdrop="static">
      <form className="form-RegistroIntervencionSanitaria">
        <Modal.Header closeButton={!submitting}>
          <Modal.Title>
          {
            Object.keys(data).length > 0 ? (
              t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.TITULO_EDITAR')
            ) : (
              t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.TITULO_NUEVO')
            )
          }
          </Modal.Title>
          {!submitting && data && data.intervencion && data.intervencion.idIntervencion && (
            <ButtonChangeRecord list={list} idElemento="idIntervencion" currentId={data.intervencion.idIntervencion} getNextRecord={recuperarDatosModalRegistroIntervencionSanitaria} />
          )}
        </Modal.Header>
        <Modal.Body>
        {/*<ModalAgendaPage
          idIntervencion={Object.keys(data).length > 0 ? data.intervencion.idIntervencion : null}
          tipoTarea={3}
         />*/}
          <Row>
            <Field
              colSm={3}
              id="numeroRegistroIntervencionSanitaria"
              name="numeroRegistroIntervencionSanitaria"
              controlLabel={t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.CODIGO_ALIMENTACION')}
              component={InputText}
              disabled={true}
              maxLength={true}
              valorMaxLength={150}
            />
            <Field
              colSm={3}
              id="idTipoIntervencion"
              name="idTipoIntervencion"
              controlLabel={t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.TIPO_INTERVENCION_SANITARIA') + '*'}
              component={InputSelect}
              options={comboTipoIntervencionSanitaria}
              valueKey="value"
              labelKey="label"
              customClass='select-tipo-intervencion-sanitaria'
              validate={required}
            />
            <Field
              colSm={3}
              id="producto"
              name="producto"
              controlLabel={t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.PRODUCTO')}
              component={InputText}
              maxLength={true}
              valorMaxLength={120}
            />
            <Field
              id="fechaCaducidad"
              name="fechaCaducidad"
              component={InputDatePicker}
              colSm={3}
              controlLabel={t(`PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.FECHA_CADUCIDAD`)}
            />
            <Field
              id="lote"
              name="lote"
              colSm={3}
              component={InputText}
              controlLabel={t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.LOTE')}
              claseActivo="nombre-lote"
              maxLength={true}
              valorMaxLength={100}
            />
            {/*
              Object.keys(this.props.data).length > 0 ? (
                <Field
                  id="cantidad"
                  name="cantidad"
                  colSm={3}
                  component={InputNumerical}
                  numDecimales={2}
                  controlLabel={t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.CANTIDAD')}
                  claseActivo="nombre-cantidad"
                />
              ) : null
            */}
            <Field
              colSm={3}
              id="idOperario"
              name="idOperario"
              controlLabel={t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.OPERARIO')}
              component={InputSelect}
              options={comboOperarioPredeterminado}
              valueKey="value"
              labelKey="label"
              customClass='select-operario'
            />
            <Field
              id="numeroRecetaProducto"
              name="numeroRecetaProducto"
              colSm={3}
              component={InputText}
              numDecimales={0}
              controlLabel={t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.NUM_RECETA_ASIGNADA_PRODUCTO')}
              claseActivo="numero-receta"
              maxLength={true}
              valorMaxLength={20}
            />
            <Field
              id="dosificacion"
              name="dosificacion"
              colSm={3}
              component={InputText}
              controlLabel={t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.DOSIFICACION')}
              claseActivo="dosificacion"
              maxLength={true}
              valorMaxLength={40}
            />
            <div className="clearfix"></div>
            <div className="tabla-verracos-en-grupo-verracos">
              <FormSubtitle>{t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.VERRACOS_ASIGNADOS')}</FormSubtitle>
              <div id="block-options1" className={"filtros-tabla-modal-registro-intervencion-sanitaria col-sm-12" + (this.state.imputSelected === false ? ' oculta-action' : '')}>
                <div className="custom-check-w">
                  {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.REGISTRO_INTERVENCION_SANITARIA] > 1) && [
                    <Field
                      id="seleccionar-todo-modal-registro-intervencion-sanitaria"
                      name="seleccionar-todo-modal-registro-intervencion-sanitaria"
                      key="seleccionar-todo-modal-registro-intervencion-sanitaria"
                      inline
                      component={InputCheckBox}
                      onInputChange={(value) => this.intercambiarCheckModalRegistroIntervencionSanitaria(value)}
                    />,
                    <button type="button" key="button" data-toggle="modal" data-target="#delete-modal" className="btn-icono" onClick={(e) => this.deleteSeleccionVerracosRegistroIntervencionSanitaria()}>
                      <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                    </button>,
                    <ModalVerracoRegistroIntervencionSanitariaPage key="ModalVerracoRegistroIntervencionSanitariaPage" />
                  ]}
                  <button
                    type="button"
                    data-toggle="modal"
                    data-target="#new-edit-modal"
                    className="btn btn-primary nuevo btn-sm"
                    disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.REGISTRO_INTERVENCION_SANITARIA] < 2) || submitting}
                    onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.REGISTRO_INTERVENCION_SANITARIA] > 1) && !submitting ? () => abrirModalVerracoRegistroIntervencionSanitaria() : undefined}
                  >
                    <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                  </button>
                </div>
              </div>
              <ListadoPage t_key="GRUPOS_VERRACO." noTitle={true} table={tableVerracosAnadidos}>
              </ListadoPage>
              <p className={this.state.classErrorVerracos === true ? 'error-verracos' : 'noerror-verracos'}><Warning/>{this.state.mensajeErrorVerracos}</p>
            </div>
            <div className="clearfix"></div>
            <Field
              id="fechaInicio"
              name="fechaInicio"
              component={InputDatePicker}
              colSm={2}
              controlLabel={t(`PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.FECHA_1_APLICACION`)}
              onInputChange={() => this.calculoFechaFinal()}
            />
            <Field
              id="fechaFin"
              name="fechaFin"
              component={InputDatePicker}
              colSm={2}
              controlLabel={t(`PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.FECHA_FINAL`)}
              onInputChange={(value) => this.calcularTerminado(value)}
              disabled={true}
            />
            <Field
              colSm={2}
              id="terminado"
              name="terminado"
              controlLabel={t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.TERMINADO')}
              component={InputText}
              disabled={true}
            />
            <div className="clearfix"></div>
            <Field
              id="numeroIntervenciones"
              name="numeroIntervenciones"
              colSm={3}
              component={InputNumerical}
              numDecimales={0}
              controlLabel={t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.N_INTERVENCIONES') + '*'}
              claseActivo="numero-receta"
              validate={required}
              onInputChange={() => this.calculoFechaFinal()}
            />
            <Field
              id="intervalo"
              name="intervalo"
              colSm={3}
              component={InputNumerical}
              numDecimales={0}
              controlLabel={t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.INTERVALO') + '*'}
              claseActivo="numero-receta"
              validate={required}
              onInputChange={() => this.calculoFechaFinal()}
            />
            <Field
              id="diasMedicar"
              name="diasMedicar"
              colSm={3}
              component={InputNumerical}
              numDecimales={0}
              controlLabel={t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.MEDICAR_DURANTE') + '*'}
              claseActivo="numero-receta"
              validate={required}
              onInputChange={() => this.calculoFechaFinal()}
              diasMedicarMayorQueIntervalo={this.state.medicarMayor}
              t={t}
            />
            <Field
              id="periodoSupresion"
              name="periodoSupresion"
              colSm={3}
              component={InputNumerical}
              numDecimales={0}
              controlLabel={t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.PERIODO_SUPRESION') + '*'}
              claseActivo="numero-receta"
              validate={required}
            />
            <div className="clearfix"></div>
            <Field
              id="observaciones"
              name="observaciones"
              colSm={6}
              component={InputTextArea}
              componentClass="textarea"
              controlLabel={t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.OBSERVACIONES')}
              claseActivo="observaciones"
            />
            <Col sm={6}>
              <div className="archivosIntervencion">
                {t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.ADJUNTAR_ARCHIVO')}
                <Dropzone
                  // accept="image/jpeg, image/png"
                  onDrop={this.onDropArchivos.bind(this)}
                  uploadMultiple={true}
                  maxFiles={null}
                  // maxSize={200000}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}  className="custom-file-label">
                      <input {...getInputProps()} />
                      <label><span className="explorar-input-file">{t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.EXPLORAR')}</span></label>
                    </div>
                  )}
                </Dropzone>
                {
                  this.state.mensajeSubirArchivos === true ? (
                    <p className='error-verracos'><Warning/>{t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.NO_PODER_ADJUNTAR')}</p>
                  ) : null
                }
                <ul>
                  {
                    Object.keys(this.state.archivos).length > 0 && (
                      this.state.archivos.map((item, idx) =>{
                        return (
                          <li
                            key={idx}
                          >
                            {
                              this.getFileExtension(item.archivo) === 'jpg' || this.getFileExtension(item.archivo) === 'gif' || this.getFileExtension(item.archivo) === 'png' ||
                              this.getFileExtension(item.archivo) === 'JPG' || this.getFileExtension(item.archivo) === 'GIF' || this.getFileExtension(item.archivo) === 'PNG' ? (
                                <Photo/>
                              ) : (
                                    this.getFileExtension(item.archivo) === 'pdf' ? (
                                      <PictureAsPdf/>
                                    ) : (<FileCopy/>)
                                  )
                            }
                            <p style={{cursor: 'pointer'}} onClick={() => this.obtenerArchivo(item)}>{item.archivo}</p>
                            <span className="borrar" onClick={() => this.eliminaArchivo(item.archivo)}><Delete/></span>
                          </li>
                        )
                      })
                    )
                  }
                </ul>
              </div>
            </Col>
            <div className="clearfix"></div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {
            Object.keys(data).length > 0 &&
            (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.AGENDA] > 0) &&
            (
              data.intervencion.idAgenda !== null ? (
                <Button
                  type="button"
                  className="imprimir-registro-alimentacion-modal btn btn-primary btn-sm"
                  disabled={submitting}
                  onClick={() =>  !submitting && this.abrirModalAgenda(data.intervencion.idAgenda)}
                >
                  <Notifications/>{t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.EDITAR_AVISO_AGENDA')}
                </Button>
              ) : (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.AGENDA] > 1) && (
                <Button
                  type="button"
                  disabled={submitting}
                  className="imprimir-registro-alimentacion-modal btn btn-primary btn-sm"
                  onClick={() => !submitting && this.abrirModalAgenda()}
                >
                  <Notifications/>{t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.CREAR_AVISO_AGENDA')}
                </Button>
              )
            )
          }
          {
            Object.keys(data).length > 0 && (
              <ButtonLockable
                processing={submitting}
                hasIcon={true}
                showLoaderOnClick={true}
                type="button"
                className="imprimir-registro-alimentacion-modal btn btn-primary btn-sm"
                disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.REGISTRO_INTERVENCION_SANITARIA] < 1) || submitting}
                onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.REGISTRO_INTERVENCION_SANITARIA] > 0) && !submitting ? handleSubmit(this.imprimirInformeRegistroIntervencion.bind(this)) : () => {}}
              >
                <Print />{t('PLANING.MODAL.IMPRIMIR')}
              </ButtonLockable>
            )
          }
          {
            Object.keys(data).length > 0 && (
              <Button
                type="button"
                className="btn btn-white btn-sm"
                disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.REGISTRO_INTERVENCION_SANITARIA] < 2) || submitting}
                onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.REGISTRO_INTERVENCION_SANITARIA] > 1) && !submitting ? handleSubmit(this.duplicar.bind(this)) : undefined}
              >
                {t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.DUPLICAR')}
              </Button>
            )
          }
          {
            Object.keys(data).length === 0 && (
              <ButtonLockable
                processing={submitting}
                hasIcon={true}
                showLoaderOnClick={true}
                type="button"
                className="btn btn-primary btn-sm"
                disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.REGISTRO_INTERVENCION_SANITARIA] < 2) || submitting}
                onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.REGISTRO_INTERVENCION_SANITARIA] > 1) && !submitting) ? handleSubmit(this.guardarYnuevoRegistroIntervencionSanitaria.bind(this)) : () => {}}
              >
                {t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.GUARDAR_Y_NUEVO')}
              </ButtonLockable>
            )
          }
          <ButtonLockable
            processing={submitting}
            hasIcon={true}
            showLoaderOnClick={true}
            type="button"
            className="btn btn-primary btn-sm"
            disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.REGISTRO_INTERVENCION_SANITARIA] < 2) || submitting}
            onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.REGISTRO_INTERVENCION_SANITARIA] > 1) && !submitting) ? handleSubmit(this.submitNuevoRegistroIntervencionSanitaria.bind(this)) : () => {}}
          >
            {t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.GUARDAR')}
          </ButtonLockable>
          <Button
            type="button"
            className={"btn btn-white btn-sm" + (submitting ? ' disabled' : '')}
            onClick={() => !submitting && this.closeRegistroIntervencion()}
          >
            {t('PLAN_SANITARIO.REGISTRO_INTERVENCION_SANITARIA.MODAL.CANCELAR')}
          </Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalRegistroIntervencionSanitaria',
})(ModalRegistroIntervencionSanitaria))
