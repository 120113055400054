import actionTypes from '../../constants/actions/usuarios/usuarios'

export function fetchUsuarios (values) {
  return {
    type: actionTypes.FETCH_USUARIOS,
    values
  }
}

export function fetchUsuariosSuccess (listUsuarios) {
  return {
    type: actionTypes.FETCH_USUARIOS_SUCCESS,
    listUsuarios
  }
}

export function abrirModalUsuarios () {
  return {
    type: actionTypes.ABRIR_MODAL_USUARIOS
  }
}

export function cerrarModalUsuarios () {
  return {
    type: actionTypes.CERRAR_MODAL_USUARIOS
  }
}

export function filtrarUsuariosSuccess (filtros) {
  return {
    type: actionTypes.FILTRAR_USUARIOS_SUCCESS,
    filtros
  }
}

export function uploadFileUsuarios (file) {
  const imagen = file !== null ? {name: file.name, file} : null
  return {
    type: actionTypes.UPLOAD_FILE_USUARIOS,
    imagen
  }
}

export function fetchCentrosUsuarios (values) {
  return {
    type: actionTypes.FETCH_CENTROS_USUARIOS,
    values
  }
}

export function fetchCentrosUsuariosSuccess (listCentros) {
  return {
    type: actionTypes.FETCH_CENTROS_USUARIOS_SUCCESS,
    listCentros
  }
}

export function fetchUsuarioGrupoUsuarios (values) {
  return {
    type: actionTypes.FETCH_USUARIO_GRUPO_USUARIOS,
    values
  }
}

export function fetchUsuarioGrupoUsuariosSuccess (listGruposUsuarios) {
  return {
    type: actionTypes.FETCH_USUARIO_GRUPO_USUARIOS_SUCCESS,
    listGruposUsuarios
  }
}

export function abrirModalListCentro () {
  return {
    type: actionTypes.ABRIR_MODAL_LIST_CENTRO
  }
}

export function cerrarModalListCentro () {
  return {
    type: actionTypes.CERRAR_MODAL_LIST_CENTRO
  }
}

export function abrirModalGruposUsuarios () {
  return {
    type: actionTypes.ABRIR_MODAL_GRUPOS_USUARIOS
  }
}

export function cerrarModalGruposUsuarios () {
  return {
    type: actionTypes.CERRAR_MODAL_GRUPOS_USUARIOS
  }
}

export function seleccionarTodosModalGruposUsuario () {
  return {
    type: actionTypes.SELECCIONAR_TODOS_MODAL_GRUPOS_USUARIO
  }
}

export function deSeleccionarTodosModalGruposUsuario () {
  return {
    type: actionTypes.DESELECCIONAR_TODOS_MODAL_GRUPOS_USUARIO
  }
}

export function anadirGruposUsuario (listGruposUsuariosAnadidos) {
  return {
    type: actionTypes.ANADIR_GRUPO_USUARIO,
    listGruposUsuariosAnadidos
  }
}

export function eliminarGrupoUsuariosAnadidos (listGruposUsuariosAnadidos) {
  return {
    type: actionTypes.ELIMINAR_GRUPO_USUARIOS_ANADIDOS,
    listGruposUsuariosAnadidos
  }
}

export function seleccionarTodosTablaGruposUsuario () {
  return {
    type: actionTypes.SELECCIONAR_TODOS_TABLA_GRUPOS_USUARIO
  }
}

export function deSeleccionarTodosTablaGruposUsuario () {
  return {
    type: actionTypes.DESELECCIONAR_TODOS_TABLA_GRUPOS_USUARIO
  }
}

export function seleccionarTodosModalListCentro () {
  return {
    type: actionTypes.SELECCIONAR_TODOS_MODAL_LIST_CENTROS
  }
}

export function deSeleccionarTodosModalListCentro () {
  return {
    type: actionTypes.DESELECCIONAR_TODOS_MODAL_LIST_CENTROS
  }
}

export function anadirListCentro (listCentrosAnadidos) {
  return {
    type: actionTypes.ANADIR_LIST_CENTROS,
    listCentrosAnadidos
  }
}

export function eliminarCentrosAnadidos (listCentrosAnadidos) {
  return {
    type: actionTypes.ELIMINAR_CENTROS_ANADIDOS,
    listCentrosAnadidos
  }
}

export function seleccionarTodosTablaCentros () {
  return {
    type: actionTypes.SELECCIONAR_TODOS_TABLA_CENTROS
  }
}

export function deSeleccionarTodosTablaCentros () {
  return {
    type: actionTypes.DESELECCIONAR_TODOS_TABLA_CENTROS
  }
}

export function submitNuevoUsuario (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.SUBMIT_NUEVO_USUARIO,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function submitNuevoUsuarioSuccess (dataNuevoUsuario) {
  return {
    type: actionTypes.SUBMIT_NUEVO_USUARIO_SUCCESS,
    dataNuevoUsuario
  }
}

export function recuperarDatosUsuario (idUsuario) {
  return {
    type: actionTypes.RECUPERAR_DATOS_USUARIO,
    idUsuario
  }
}

export function recuperarDatosUsuarioSuccess (dataUsuario) {
  return {
    type: actionTypes.RECUPERAR_DATOS_USUARIO_SUCCESS,
    dataUsuario
  }
}

export function editarUsuarios (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.EDITAR_USUARIOS,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function duplicarUsuario (idUsuario) {
  return {
    type: actionTypes.DUPLICAR_USUARIO,
    idUsuario
  }
}

export function duplicarUsuarioSuccess (dataUsuarioDuplicar) {
  return {
    type: actionTypes.DUPLICAR_USUARIO_SUCCESS,
    dataUsuarioDuplicar
  }
}

export function cambiarEstadoUsuario (idUsuario) {
  return {
    type: actionTypes.CAMBIAR_ESTADO_USUARIO,
    idUsuario
  }
}

export function eliminarUsuario (idUsuario) {
  return {
    type: actionTypes.ELIMINAR_USUARIO,
    idUsuario
  }
}

export function seleccionarTodosUsuarios () {
  return {
    type: actionTypes.SELECCIONAR_TODOS_USUARIOS
  }
}

export function deSeleccionarTodosUsuarios () {
  return {
    type: actionTypes.DESELECCIONAR_TODOS_USUARIOS
  }
}

export function deleteSeleccionUsuario (list) {
  return {
    type: actionTypes.DELETE_SELECCION_USUARIO,
    list
  }
}

export function cambioEstadoSeleccionUsuario (list) {
  return {
    type: actionTypes.CAMBIO_ESTADO_SELECCION_USUARIO,
    list
  }
}

export function crearCsvUsuarios (list) {
  return {
    type: actionTypes.CREAR_CSV_USUARIOS,
    list
  }
}
export function crearCsvUsuariosSuccess (datosExcelUsuarios) {
  return {
    type: actionTypes.CREAR_CSV_USUARIOS_SUCCESS,
    datosExcelUsuarios
  }
}

export function imprimirPdfUsuarios (list) {
  return {
    type: actionTypes.IMPRIMIR_PDF_USUARIOS,
    list
  }
}

export function imprimirPdfUsuariosSuccess (datosPdfUsuarios) {
  return {
    type: actionTypes.IMPRIMIR_PDF_USUARIOS_SUCCESS,
    datosPdfUsuarios
  }
}

export function cambiarPasswordUsuario (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.CAMBIAR_PASSWORD_USUARIO,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function cambiarPasswordUsuarioSuccess (dataCambiarPass) {
  return {
    type: actionTypes.CAMBIAR_PASSWORD_USUARIO_SUCCESS,
    dataCambiarPass
  }
}

export function abrirModalCambiarPass () {
  return {
    type: actionTypes.ABRIR_MODAL_CAMBIAS_PASS
  }
}

export function cerrarModalCambiarPass () {
  return {
    type: actionTypes.CERRAR_MODAL_CAMBIAS_PASS
  }
}

export function abrirModalCambiarTokenMagavision () {
  return {
    type: actionTypes.ABRIR_MODAL_CAMBIAR_TOKEN_MAGAVISION
  }
}

export function cerrarModalCambiarTokenMagavision () {
  return {
    type: actionTypes.CERRAR_MODAL_CAMBIAR_TOKEN_MAGAVISION
  }
}

export function cambiarTokenMagavision (idElemento) {
  return {
    type: actionTypes.CAMBIAR_TOKEN_MAGAVISION,
    idElemento
  }
}

export function submitCambiarTokenMagavision (values) {
  return {
    type: actionTypes.SUBMIT_CAMBIAR_TOKEN_MAGAVISION,
    values
  }
}

export default {
  fetchUsuarios,
  fetchUsuariosSuccess,
  abrirModalUsuarios,
  cerrarModalUsuarios,
  filtrarUsuariosSuccess,
  uploadFileUsuarios,
  fetchCentrosUsuarios,
  fetchCentrosUsuariosSuccess,
  fetchUsuarioGrupoUsuarios,
  fetchUsuarioGrupoUsuariosSuccess,
  abrirModalListCentro,
  abrirModalGruposUsuarios,
  cerrarModalListCentro,
  cerrarModalGruposUsuarios,
  seleccionarTodosModalGruposUsuario,
  deSeleccionarTodosModalGruposUsuario,
  anadirGruposUsuario,
  eliminarGrupoUsuariosAnadidos,
  seleccionarTodosTablaGruposUsuario,
  deSeleccionarTodosTablaGruposUsuario,
  seleccionarTodosModalListCentro,
  deSeleccionarTodosModalListCentro,
  anadirListCentro,
  eliminarCentrosAnadidos,
  seleccionarTodosTablaCentros,
  deSeleccionarTodosTablaCentros,
  submitNuevoUsuario,
  submitNuevoUsuarioSuccess,
  recuperarDatosUsuario,
  recuperarDatosUsuarioSuccess,
  editarUsuarios,
  duplicarUsuario,
  duplicarUsuarioSuccess,
  cambiarEstadoUsuario,
  eliminarUsuario,
  seleccionarTodosUsuarios,
  deSeleccionarTodosUsuarios,
  deleteSeleccionUsuario,
  cambioEstadoSeleccionUsuario,
  crearCsvUsuarios,
  crearCsvUsuariosSuccess,
  imprimirPdfUsuarios,
  imprimirPdfUsuariosSuccess,
  cambiarPasswordUsuario,
  cambiarPasswordUsuarioSuccess,
  abrirModalCambiarPass,
  cerrarModalCambiarPass,
  abrirModalCambiarTokenMagavision,
  cerrarModalCambiarTokenMagavision,
  cambiarTokenMagavision,
  submitCambiarTokenMagavision
}
