import actionTypes from '../../constants/actions/poolExtracciones/poolExtracciones'

export function fetchPoolExtracciones(filtros){
  return {
    type: actionTypes.FETCH_POOL_EXTRACCIONES,
    filtros
  }
}

export function fetchPoolExtraccionesSuccess(list, totales, filtros){
  return {
    type: actionTypes.FETCH_POOL_EXTRACCIONES_SUCCESS,
    list,
    totales,
    filtros
  }
}

export function seleccionarTodosPoolExtracciones(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_POOL_EXTRACCIONES
  }
}

export function deseleccionarTodosPoolExtracciones(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_POOL_EXTRACCIONES
  }
}

export function onDeletePoolExtraccion (idPoolExtraccion) {
  return {
    type: actionTypes.ON_DELETE_POOL_EXTRACCION,
    idPoolExtraccion
  }
}

export function deleteSeleccionPoolExtracciones(list){
  return {
    type: actionTypes.DELETE_SELECCION_POOL_EXTRACCIONES,
    list
  }
}

export function crearCsvPoolExtracciones(list){
  return {
    type: actionTypes.CREATE_CSV_POOL_EXTRACCIONES,
    list
  }
}

export function imprimirPdfPoolExtracciones(list){
  return {
    type: actionTypes.IMPRIMIR_PDF_POOL_EXTRACCIONES,
    list
  }
}

export function submitFormPoolExtraccion (values, idPoolExtraccion, history, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.SUBMIT_FORM_POOL_EXTRACCION,
    values,
    idPoolExtraccion,
    history,
    onSuccessCallback,
    onErrorCallback
  }
}

export function recuperarDatosFormPoolExtraccion(idPoolExtraccion){
  return{
    type: actionTypes.RECUPERAR_DATOS_FORM_POOL_EXTRACCION,
    idPoolExtraccion
  }
}

export function recuperarDatosFormPoolExtraccionSuccess (data, analisis, totalDosisMililitros) {
  return {
    type: actionTypes.RECUPERAR_DATOS_FORM_POOL_EXTRACCION_SUCCESS,
    data,
    analisis,
    totalDosisMililitros
  }
}

export function fetchCodigoPoolExtracciones(){
  return{
    type: actionTypes.FETCH_CODIGO_POOL_EXTRACCIONES
  }
}

export function fetchCodigoPoolExtraccionesSuccess(codigoPool){
  return{
    type: actionTypes.FETCH_CODIGO_POOL_EXTRACCIONES_SUCCESS,
    codigoPool
  }
}

export function handlePoolCalcFunctionsOrden(calcFunctionList, caller) {
  return{
    type: actionTypes.HANDLE_POOL_CALC_FUNCTIONS_ORDEN,
    calcFunctionList,
    caller
  }
}

export function calcConcentracionXDosisPool() {
  return{
    type: actionTypes.CALC_CONCENTRACION_X_DOSIS_POOL
  }
}

export function calcNumeroDosisPool() {
  return{
    type: actionTypes.CALC_NUMERO_DOSIS_POOL
  }
}

export function reCalcConcentracionXDosisPool() {
  return{
    type: actionTypes.RE_CALC_CONCENTRACION_X_DOSIS_POOL
  }
}

export function initializeFormPoolExtraccion() {
  return{
    type: actionTypes.INITIALIZE_FORM_POOL_EXTRACCION
  }
}

export function initializeFormPoolExtraccionSuccess() {
  return{
    type: actionTypes.INITIALIZE_FORM_POOL_EXTRACCION_SUCCESS
  }
}

export function seleccionarTodosAsignacionAnalisisPool(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_ASIGNACION_ANALISIS_POOL
  }
}

export function deseleccionarTodosAsignacionAnalisisPool(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_ASIGNACION_ANALISIS_POOL
  }
}

export function onDeleteAsignacionAnalisisPool(idAnalisis){
  return {
    type: actionTypes.ON_DELETE_ASIGNACION_ANALISIS_POOL,
    idAnalisis
  }
}

export function deleteSeleccionAsignacionAnalisisPool(list){
  return {
    type: actionTypes.DELETE_SELECCION_ASIGNACION_ANALISIS_POOL,
    list
  }
}

export function setAsignacionAnalisisPool(list){
  return {
    type: actionTypes.SET_ASIGNACION_ANALISIS_POOL,
    list
  }
}

export function abrirModalAsignacionAnalisisPool() {
  return{
    type: actionTypes.ABRIR_MODAL_ASIGNACION_ANALISIS_POOL
  }
}

export function abrirModalAsignacionAnalisisPoolSuccess() {
  return{
    type: actionTypes.ABRIR_MODAL_ASIGNACION_ANALISIS_POOL_SUCCESS
  }
}

export function cerrarModalAsignacionAnalisisPool() {
  return{
    type: actionTypes.CERRAR_MODAL_ASIGNACION_ANALISIS_POOL
  }
}

export function cerrarModalAsignacionAnalisisPoolSuccess() {
  return{
    type: actionTypes.CERRAR_MODAL_ASIGNACION_ANALISIS_POOL_SUCCESS
  }
}

export function guardarModalAsignacionAnalisisPool(seleccionadosAnalisisList, mantenerModal) {
  return{
    type: actionTypes.GUARDAR_MODAL_ASIGNACION_ANALISIS_POOL,
    seleccionadosAnalisisList,
    mantenerModal
  }
}

export function guardarModalAsignacionAnalisisPoolSuccess(seleccionadosAnalisisList, totalDosisMililitros, mantenerModal) {
  return{
    type: actionTypes.GUARDAR_MODAL_ASIGNACION_ANALISIS_POOL_SUCCESS,
    seleccionadosAnalisisList,
    totalDosisMililitros,
    mantenerModal
  }
}

export function seleccionarTodosDisponiblesExtracciones(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_DISPONIBLES_EXTRACCIONES
  }
}

export function deseleccionarTodosDisponiblesExtracciones(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_DISPONIBLES_EXTRACCIONES
  }
}

export function deleteDisponiblesAnalisisPool(idAnalisis){
  return {
    type: actionTypes.DELETE_DISPONIBLES_ANALISIS_POOL,
    idAnalisis
  }
}

export function deleteSeleccionDisponiblesAnalisisPool(list){
  return {
    type: actionTypes.DELETE_SELECCION_DISPONIBLES_ANALISIS_POOL,
    list
  }
}

export function fetchAnalisisDisponibles(filtros, callback){
  return {
    type: actionTypes.FETCH_ANALISIS_DISPONIBLES,
    filtros,
    callback
  }
}

export function fetchAnalisisDisponiblesSuccess(list, filtros){
  return {
    type: actionTypes.FETCH_ANALISIS_DISPONIBLES_SUCCESS,
    list,
    filtros
  }
}

export function descargarEtiquetaPdf(idPool){
  return {
    type: actionTypes.DESCARGAR_ETIQUETA_PDF,
    idPool
  }
}

export function fetchTokenMagavisionPool({idPool}){
  return {
    type: actionTypes.FETCH_TOKEN_MAGAVISION_POOL,
    idPool
  }
}

export function fetchTokenMagavisionPoolSuccess(tokenUsuarioMagavision, t_anl, t_dtv){
  return {
    type: actionTypes.FETCH_TOKEN_MAGAVISION_POOL_SUCCESS,
    tokenUsuarioMagavision,
    t_anl,
    t_dtv
  }
}

export function updateTokenMagavisionPoolSuccess(t_dtv_main, isPrimerSeguimientoDelPool, t_dtv){
  return{
    type: actionTypes.UPDATE_TOKEN_MAGAVISION_POOL_SUCCESS,
    t_dtv_main,
    isPrimerSeguimientoDelPool,
    t_dtv
  }
}

export function imprimirEtiquetaPDFPool(idPool){
  return{
    type: actionTypes.IMPRIMIR_ETIQUETA_PDF_POOL,
    idPool
  }
}

export default {
  fetchPoolExtracciones,
  fetchPoolExtraccionesSuccess,
  seleccionarTodosPoolExtracciones,
  deseleccionarTodosPoolExtracciones,
  onDeletePoolExtraccion,
  deleteSeleccionPoolExtracciones,
  crearCsvPoolExtracciones,
  imprimirPdfPoolExtracciones,
  recuperarDatosFormPoolExtraccion,
  recuperarDatosFormPoolExtraccionSuccess,
  fetchCodigoPoolExtracciones,
  fetchCodigoPoolExtraccionesSuccess,
  handlePoolCalcFunctionsOrden,
  calcConcentracionXDosisPool,
  calcNumeroDosisPool,
  reCalcConcentracionXDosisPool,
  initializeFormPoolExtraccion,
  initializeFormPoolExtraccionSuccess,
  seleccionarTodosAsignacionAnalisisPool,
  deseleccionarTodosAsignacionAnalisisPool,
  onDeleteAsignacionAnalisisPool,
  deleteSeleccionAsignacionAnalisisPool,
  setAsignacionAnalisisPool,
  abrirModalAsignacionAnalisisPool,
  abrirModalAsignacionAnalisisPoolSuccess,
  cerrarModalAsignacionAnalisisPool,
  cerrarModalAsignacionAnalisisPoolSuccess,
  guardarModalAsignacionAnalisisPool,
  guardarModalAsignacionAnalisisPoolSuccess,
  seleccionarTodosDisponiblesExtracciones,
  deseleccionarTodosDisponiblesExtracciones,
  deleteDisponiblesAnalisisPool,
  deleteSeleccionDisponiblesAnalisisPool,
  fetchAnalisisDisponibles,
  fetchAnalisisDisponiblesSuccess,
  descargarEtiquetaPdf,
  fetchTokenMagavisionPool,
  fetchTokenMagavisionPoolSuccess,
  updateTokenMagavisionPoolSuccess,
  imprimirEtiquetaPDFPool
}
