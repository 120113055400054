import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import Button from '../comun/button/Button'
import ButtonLockable from '../comun/button/ButtonLockable'
import InputSelect from '../comun/form/InputSelect'
import InputDatePicker from '../comun/form/InputDatePicker'
import InputNumerical from '../comun/form/InputNumerical'
import InputTime from '../comun/form/InputTime'
import InputTextArea from '../comun/form/InputTextArea'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import FiltrosFosoPage from '../../containers/foso/FiltrosFosoPage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import {date_formatter, date_formatter_tabla, number_formatter, dateTimeZones} from '../../util/formatFunctions'
import './foso.scss'
import { required } from '../../util/validationFunctions'
import {permisos as permisosConstant} from '../../constants/permisos'

class Foso extends Component {
  constructor () {
    super()
    this.state = {
      analizado: false,
      seleccionarOperario: true,
      horaIncio: '',
      horaFinExtraccion: '',
      fecha: '',
      verraco: ''
    }
    this.eliminarFoso = this.eliminarFoso.bind(this)
    this.guardarFoso = this.guardarFoso.bind(this)
    this.modificarFoso = this.modificarFoso.bind(this)
  }

  UNSAFE_componentWillMount () {
    document.title = this.props.t('MENU.EXTRACCIONES.FOSO') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    this.props.actions.fetchFiltrarFoso({})
    this.props.actions.comboOperarioActivo()
    this.props.actions.comboVerraco()
    let fechaHora = dateTimeZones(this.props.auth.zonaHorariaString)
    let hour = fechaHora.getHours()
    let minute = fechaHora.getMinutes()
    let second = fechaHora.getSeconds()
    if (hour <= 9) hour = '0' + hour
    if (minute <= 9) minute = '0' + minute
    if (second <= 9) second = '0' + second
    let horaFinExtraccion = hour + ':' + minute
    this.props.change('horaExtraccion', horaFinExtraccion)
    this.props.change('horaInioExtraccion', null)
    this.props.change('fechaExtraccion', dateTimeZones(this.props.auth.zonaHorariaString))
    let date = new Date()
    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    if (this.props.auth.formaFechaHora === 'day/month/year/12' || this.props.auth.formaFechaHora === 'day/month/year/24'){
      this.setState({fecha: day + '/' + month + '/' + year})
    } else if (this.props.auth.formaFechaHora === 'month/day/year/12' || this.props.auth.formaFechaHora === 'month/day/year/24'){
      this.setState({fecha: month + '/' + day + '/' + year})
    } else if (this.props.auth.formaFechaHora === 'year/month/day/12' || this.props.auth.formaFechaHora === 'year/month/day/24'){
      this.setState({fecha: year + '/' + month + '/' + day})
    } else if (this.props.auth.formaFechaHora === 'year/day/month/12' || this.props.auth.formaFechaHora === 'year/day/month/24'){
      this.setState({fecha: year + '/' + day + '/' + month})
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.dataExtraccion !== prevProps.dataExtraccion){
      if (Object.keys(this.props.dataExtraccion).length > 0){
        this.setState({analizado: this.props.dataExtraccion.analizado})
        if (this.props.dataExtraccion.nombreOperarioColecta !== null){
          this.setState({seleccionarOperario: false})
        } else {
          this.setState({seleccionarOperario: true})
        }
      }
    }

    if (this.props.dataDatosVerraco !== prevProps.dataDatosVerraco){
      if (Object.keys(this.props.dataDatosVerraco).length > 0){
        this.setState({analizado: false})
        if (this.props.dataDatosVerraco.nombreOperarioColecta !== null){
          this.setState({seleccionarOperario: false})
        } else {
          this.setState({seleccionarOperario: true})
        }
      }
    }

    if (Object.keys(this.props.formFoso).length > 0) {
      if (Object.keys(this.props.formFoso.values).length > 0) {
        if (this.props.formFoso.values.horaInicioExtraccion && this.props.formFoso.values.horaExtraccion && this.props.formFoso.values.horaInicioExtraccion !== prevProps.formFoso.values.horaInicioExtraccion) {
          let fecha1 = this.props.formFoso.values.horaInicioExtraccion.split(':')
          let fecha1Minutos = (parseInt(fecha1[0], 10) * 60) + parseInt(fecha1[1], 10)
          let fecha2 = this.props.formFoso.values.horaExtraccion.split(':')
          let fecha2Minutos = (parseInt(fecha2[0], 10) * 60) + parseInt(fecha2[1], 10)
          let diferenciaHoras = fecha2Minutos - fecha1Minutos
          if (!isNaN(diferenciaHoras)){
            this.props.change('tiempoMonta', diferenciaHoras)
          } else {
            this.props.change('tiempoMonta', null)
          }
        }
        if (this.props.formFoso.values.horaExtraccion && this.props.formFoso.values.horaInicioExtraccion && this.props.formFoso.values.horaExtraccion !== prevProps.formFoso.values.horaExtraccion) {
          let fecha1 = this.props.formFoso.values.horaInicioExtraccion.split(':')
          let fecha1Minutos = (parseInt(fecha1[0], 10) * 60) + parseInt(fecha1[1], 10)
          let fecha2 = this.props.formFoso.values.horaExtraccion.split(':')
          let fecha2Minutos = (parseInt(fecha2[0], 10) * 60) + parseInt(fecha2[1], 10)
          let diferenciaHoras = fecha2Minutos - fecha1Minutos
          if (!isNaN(diferenciaHoras)){
            this.props.change('tiempoMonta', diferenciaHoras)
          } else {
            this.props.change('tiempoMonta', null)
          }
        }
      }
    }
  }

  guardarFoso (values) {
    return new Promise((resolve, reject) => {
      values.orden= this.props.dataDatosVerraco.orden
      values.idVerraco= this.props.dataDatosVerraco.verraco.idVerraco
      values.idOperarioColecta = this.props.dataDatosVerraco.operarioColecta !== null ? this.props.dataDatosVerraco.operarioColecta : values.operarioColecta.value
      values.codigoAnalisis = this.props.codigoAnalisis.codigoAnalisis
      this.props.actions.guardarFoso(values, resolve, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  modificarFoso (values) {
    return new Promise((resolve, reject) => {
      values.idOperarioColecta = values.operarioColecta.value
      this.props.actions.modificarFoso(values, resolve, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  eliminarFoso (values) {
    values.idAnalisis = this.props.dataExtraccion.idAnalisis
    this.props.actions.eliminarFoso(values)
  }

  quitarCeldaSeleccionada (id) {
    if (id.value !== undefined) {
      var elems = document.getElementById('fosoTable1').getElementsByTagName('tbody')[0].getElementsByTagName('tr');
      [].forEach.call(elems, function(el) {
        var elemstd = el.getElementsByTagName('td');
        [].forEach.call(elemstd, function(eltd) {
          eltd.classList.remove("fila-seleccionada-foso");
       })
      })
      this.setState({verraco: id})
      if (this.props.formFoso.values.fechaExtraccion) {
        let date = new Date(this.props.formFoso.values.fechaExtraccion)
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        if (this.props.auth.formaFechaHora === 'day/month/year/12' || this.props.auth.formaFechaHora === 'day/month/year/24'){
          this.setState({fecha: day + '/' + month + '/' + year})
        } else if (this.props.auth.formaFechaHora === 'month/day/year/12' || this.props.auth.formaFechaHora === 'month/day/year/24'){
          this.setState({fecha: month + '/' + day + '/' + year})
        } else if (this.props.auth.formaFechaHora === 'year/month/day/12' || this.props.auth.formaFechaHora === 'year/month/day/24'){
          this.setState({fecha: year + '/' + month + '/' + day})
        } else if (this.props.auth.formaFechaHora === 'year/day/month/12' || this.props.auth.formaFechaHora === 'year/day/month/24'){
          this.setState({fecha: year + '/' + day + '/' + month})
        }
      }
      this.props.actions.obtenerDatosVerraco(id.value, this.state.fecha)
    }
  }

  seleccionarFecha (fecha) {
    var elems = document.getElementById('fosoTable1').getElementsByTagName('tbody')[0].getElementsByTagName('tr');
      [].forEach.call(elems, function(el) {
        var elemstd = el.getElementsByTagName('td');
        [].forEach.call(elemstd, function(eltd) {
          eltd.classList.remove("fila-seleccionada-foso");
       })
      })
    this.setState({fecha: fecha})
    if (this.state.verraco !== '') {
      this.props.actions.obtenerDatosVerraco(this.state.verraco.value, fecha)
    }
  }

  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual, checkedFiltrosFoso, listFoso, handleSubmit, submitting, dataExtraccion,
      combos: {comboOperarioActivo, comboVerraco}
    } = this.props

    let fecha = dateTimeZones(this.props.auth.zonaHorariaString)
    let arrayAntes = []
    let arrayDespues = []
    for (var i=1; i<7 / 2; i++) {
      // dias antes de hoy
      let HaceUnaSemana=new Date(fecha.getTime() - (24*60*60*1000) * i)
      let fechaMontadaDespues = HaceUnaSemana.getFullYear()+"-"+parseInt(HaceUnaSemana.getMonth() + 1, 10) +"-"+HaceUnaSemana.getDate()
      arrayAntes.push(date_formatter_tabla(fechaMontadaDespues, this.props.auth.formaFechaHora, false))

      // dias despues de hoy
      let MasUnaSemana = new Date(fecha.getTime() + (24*60*60*1000) * i)
      let fechaMontadaAntes = MasUnaSemana.getFullYear()+"-"+parseInt(MasUnaSemana.getMonth() + 1, 10) +"-"+MasUnaSemana.getDate()
      arrayDespues.push(date_formatter_tabla(fechaMontadaAntes, this.props.auth.formaFechaHora, false))
    }
    let diasCalendario = arrayAntes.reverse() + ',' + date_formatter_tabla(fecha, this.props.auth.formaFechaHora, false) + ',' + arrayDespues
    let calendario = diasCalendario.split(',')
    const columVerraco = [{id: 'verraco', name: t('PLANING.TABLA.VERRACO')}]
    const childrenCalendario = calendario.map((dia) => {
      return {id: dia, name: dia}
    })

    const listadoFoso = listFoso.map((foso) => {
      foso.fechas.verraco = foso.verraco
      foso.fechas.idVerraco = foso.idVerraco
      return foso.fechas
    })

    // TABLA VERRACO
    const table = {
      id: 'fosoTable1',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      hasPagination: true,
      esFoso: true,
      columns: columVerraco.concat(childrenCalendario),
      rows: listadoFoso,
      leyenda: [
        {name: t('PLANING.EXTRACCIONES_FUTURAS'), color: '#1c8cdc'},
        {name: t('PLANING.EXTRACCIONES_PASADAS'), color: '#dc1c1c'},
        {name: t('PLANING.EXTRACCIONES_REGISTRADAS'), color: '#95cc64'},
        {name: t('PLANING.DIA_HOY'), color: '#b5b5b5'}
      ],
      initialValues: {}
    }

    return (
      <div>
        <SimpleModalPage/>
        <MensajeAlertaPage/>
        <h2 className="page-title">{t('FOSO.TITLE')}</h2>
        <FiltrosFosoPage/>
        <div className="clearfix"></div>
        <Row>
          <Col sm={8}>
            <SimpleTablePage {...table}></SimpleTablePage>
          </Col>
          <Col sm={4} style={{borderLeft: '1px solid #eee', padding: 0}}>
            <Col sm={12}>
              <h3 style={{fontWeight: 'bold'}}>{t('FOSO.FORM.TITLE')}</h3>
              {
                Object.keys(dataExtraccion).length > 0 && (
                  <p className="codigo-extraccion"><b>{t('FOSO.CODIGO_EXTRACCION')}: </b>{dataExtraccion.codigo}</p>
                )
              }
            </Col>
            <Field
              colSm={6}
              id="verraco"
              name="verraco"
              controlLabel={t('FOSO.FORM.VERRACO') + '*'}
              component={InputSelect}
              options={comboVerraco}
              valueKey="value"
              labelKey="label"
              customClass='verraco-foso'
              isClearable={false}
              disabled={this.state.analizado === true ? true : false}
              onInputChange={(id) => this.quitarCeldaSeleccionada(id)}
              validate={required}
            />
            <Field
              colSm={6}
              id="operarioColecta"
              name="operarioColecta"
              controlLabel={t('FOSO.FORM.OPERARIO') + '*'}
              component={InputSelect}
              options={comboOperarioActivo}
              valueKey="value"
              labelKey="label"
              isClearable={false}
              disabled={this.state.analizado === true ? true : false}
              validate={required}
            />
            <Field
              colSm={6}
              id="fechaExtraccion"
              name="fechaExtraccion"
              controlLabel={t('FOSO.FORM.FECHA_EXTRACCION') + '*'}
              component={InputDatePicker}
              disabled={this.state.analizado === true ? true : false}
              validate={required}
              onInputChange={(value) => this.seleccionarFecha(value)}
            />
            <Field
              colSm={6}
              id="volumen"
              name="volumen"
              controlLabel={t('FOSO.FORM.VOLUMEN')}
              component={InputNumerical}
              disabled={this.state.analizado === true ? true : false}
            />
            <Field
              colSm={6}
              id="horaInicioExtraccion"
              name="horaInicioExtraccion"
              controlLabel={t('FOSO.FORM.HORA')}
              component={InputTime}
              disabled={this.state.analizado === true ? true : false}
            />
            <Field
              colSm={6}
              id="horaExtraccion"
              name="horaExtraccion"
              controlLabel={t('FOSO.FORM.HORA_FIN') + '*'}
              component={InputTime}
              disabled={this.state.analizado === true ? true : false}
              validate={required}
            />
            <Field
              colSm={6}
              id="tiempoMonta"
              name="tiempoMonta"
              controlLabel={t('FOSO.FORM.TIEMPO_MONTA')}
              component={InputNumerical}
              numDecimales={0}
              disabled={this.state.analizado === true ? true : false}
            />
            <Field
              colSm={12}
              id="observaciones"
              name="observaciones"
              controlLabel={t('FOSO.FORM.OBSERVACIONES')}
              component={InputTextArea}
              disabled={this.state.analizado === true ? true : false}
            />
            <div className="clearfix"></div>
            <Col sm={12} style={{textAlign: 'right'}}>
              {
                Object.keys(this.props.dataExtraccion).length > 0 && this.state.analizado === false ? (
                  <Button
                    type="button"
                    onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.FOSO] > 1) && !submitting) ? handleSubmit(this.eliminarFoso.bind(this)) : () => {}}
                    className={"btn btn-primary" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.FOSO] < 2) || submitting ? ' disabled' : '')}
                    disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.FOSO] < 2) || submitting}
                  >{t('FOSO.FORM.ELIMINAR')}</Button>
                ) : null
              }
              {
                this.state.analizado === false ? (
                  Object.keys(this.props.dataDatosVerraco).length > 0 ? (
                    <ButtonLockable
                      processing={submitting}
                      hasIcon={true}
                      type="button"
                      className="btn btn-primary btn-sm"
                      disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.FOSO] < 2) || submitting}
                      onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.FOSO] > 1) && !submitting) ? handleSubmit(this.guardarFoso.bind(this)) : () => {}}
                    >
                      {t('FOSO.FORM.GUARDAR')}
                    </ButtonLockable>
                  ) : (
                    Object.keys(this.props.dataExtraccion).length > 0 ? (
                      <ButtonLockable
                        processing={submitting}
                        hasIcon={true}
                        type="button"
                        className="btn btn-primary btn-sm"
                        disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.FOSO] < 2) || submitting}
                        onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.FOSO] > 1) && !submitting) ? handleSubmit(this.modificarFoso.bind(this)) : () => {}}
                      >{t('FOSO.FORM.GUARDAR')}</ButtonLockable>
                    ) : null
                  )
                ) : null
              }
            </Col>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'formFoso',
})(Foso))