export default {
  FETCH_TOKEN: 'FETCH_TOKEN',
  FETCH_TOKEN_SUCCESS: 'FETCH_TOKEN_SUCCESS',
  UPDATE_TOKEN: 'UPDATE_TOKEN',
  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  LOGOUT: 'LOGOUT',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  REQUEST_RESET_PASSWORD: 'REQUEST_RESET_PASSWORD',
  RESET_PASSWORD: 'RESET_PASSWORD',
  REFRESH_AUTH: 'REFRESH_AUTH',
  REFRESH_AUTH_SUCCES: 'REFRESH_AUTH_SUCCES',
  TEST_AUTH: 'TEST_AUTH',
  CAMBIAR_IMAGEN_AUTH: 'CAMBIAR_IMAGEN_AUTH',
  CAMBIAR_NOMBRE_USUARIO_AUTH: 'CAMBIAR_NOMBRE_USUARIO_AUTH',
  LICENCIA_EXPIRADA_SUCCESS: 'LICENCIA_EXPIRADA_SUCCESS',
  SUBMIT_RENOVAR_TOKEN: 'SUBMIT_RENOVAR_TOKEN',
  COMPROBAR_ENTORNO: 'COMPROBAR_ENTORNO',
  COMPROBAR_ENTORNO_SUCCESS: 'COMPROBAR_ENTORNO_SUCCESS',
  CAMBIAR_IDIOMA_AUTH: 'CAMBIAR_IDIOMA_AUTH',
  OBTENER_NUMERO_SERIE: 'OBTENER_NUMERO_SERIE',
  OBTENER_NUMERO_SERIE_SUCCESS: 'OBTENER_NUMERO_SERIE_SUCCESS',
  SUBIR_ARCHIVOS_ACTUALIZAR_APLICACION: 'SUBIR_ARCHIVOS_ACTUALIZAR_APLICACION',
  SUBMIT_ACTUALIZAR_APLICACION: 'SUBMIT_ACTUALIZAR_APLICACION',
  MOSTRAR_INFORMACION_LICENCIA: 'MOSTRAR_INFORMACION_LICENCIA',
  MOSTRAR_INFORMACION_LICENCIA_SUCCESS: 'MOSTRAR_INFORMACION_LICENCIA_SUCCESS'
}
