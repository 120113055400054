
export const estadoAlbaran = {
  BORRADOR: 1,
  CANCELADO: 2,
  REALIZADO: 3
}

export const translateEstadoAlbaran = {
  1: 'ALBARAN.BORRADOR',
  2: 'ALBARAN.CANCELADO',
  3: 'ALBARAN.REALIZADO'
}