import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import InputCheckBox from '../comun/form/InputCheckBox'
import './PanelControl.scss'
import ListadoPage from '../../containers/page/ListadoPage'
import { Delete, AddCircleOutline } from '@material-ui/icons'
import ModalAnadirColorimetroPage from '../../containers/panelControl/ModalAnadirColorimetroPage'
import {permisos as permisosConstant} from '../../constants/permisos'

class ModalRectaColorimetro extends Component {
  constructor () {
    super()
    this.state = {
      checkTodosSelected: false
    }
  }
  componentDidMount() {
    this.props.actions.fetchRectaColorimetro()
  }

  componentDidUpdate (prevProps) {
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable !== prevProps.formSimpleTable) {
        let contActivos = 0
        if (this.props.formSimpleTable.values) {
          Object.values(this.props.formSimpleTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todo-recta-colorimetro', false)
            this.setState({ checkTodosSelected: false })
            this.props.actions.deSeleccionarTodosColorimetro()
          }
        }
      }
    }
  }

  intercambiarCheckRectaColorimetro (value) {
    this.setState({ checkTodosSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosColorimetro()
    } else {
      this.props.actions.deSeleccionarTodosColorimetro()
    }
  }

  abrirModalAnadirColorimetro () {
    this.props.actions.abrirModalAnadirColorimetro()
  }

  deleteSeleccionRectaColorimetro(){
    const itemsSeleccion = this.props.listColorimetro.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idRecta': row.value,
          'R': row.R,
          'X': row.X,
          'Y': row.Y,
          'label': row.label,
          'predeterminado': row.predeterminado,
          'value': row.value
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.deleteSeleccionRectaColorimetro(list)
  }

  render () {
    const {
      t, showModalRectaColorimetro, checkedColorimetro, listColorimetro, numeroRegistrosPorPagina, paginaActual, dataRectaColorimetro, dataDuplicarRectaColorimetro,
      actions: {cerrarModalRectaColorimetro, recuperarDatosRectaColorimetro, duplicarDatosModalRectaColorimetro, cambiaPredeterminadoRectaColorimetro, onDeleteRectaColorimetro}
    } = this.props

    listColorimetro.forEach(
      (row) => row.idElemento = row.value
    )

    const tableColorimetro = {
      id: 'rectaColorimetroTable',
      multiHeader: false,
      hasCheckbox: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.RECTA_COLORIMETRO] > 1,
      hasOptions: true,
      mostrarCambioEstado: false,
      columns: [
        {id: 'label', name: t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.TABLA.NOMBRE')},
        {id: 'predeterminado', name: t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.TABLA.PREDETERMINADO')},
        {id: 'formula', name: t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.TABLA.FORMULA')},
        {id: 'R', name: t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.TABLA.R')}
      ],
      rows: listColorimetro,
      data: dataRectaColorimetro,
      mostrarModificar: true,
      onEdit: (row) => recuperarDatosRectaColorimetro(row.value),
      dataDuplicar: dataDuplicarRectaColorimetro,
      mostrarDuplicar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.RECTA_COLORIMETRO] > 1,
      duplicar: (row) =>duplicarDatosModalRectaColorimetro(row.value),
      mostrarEliminar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.RECTA_COLORIMETRO] > 1,
      onDelete: (row) => onDeleteRectaColorimetro(row.value),
      mostrarPredeterminado: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.RECTA_COLORIMETRO] > 1,
      cambiarPorDefecto: (row) => cambiaPredeterminadoRectaColorimetro(row.value),
      initialValues: {}
    }
    tableColorimetro.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableColorimetro.initialValues['check' + row.idElemento] = checkedColorimetro
        }
      }
    )

    return (
      <Modal show={showModalRectaColorimetro} onHide={cerrarModalRectaColorimetro} aria-labelledby="contained-modal-title-lg" className="modal-recta-colorimetro" backdrop="static">
      <form className="form-recta-colorimetro">
        <Modal.Header closeButton>
          <Modal.Title>
          {
            t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.TITLE')
          }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12} className="container-tabla-recta-colorimetro">
              {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.RECTA_COLORIMETRO] > 1) && (
                <div id="block-options" className={"filtros-tabla-recta-colorimetro col-sm-12" + (this.state.checkTodosSelected === true ? '' : ' oculta-action')}>
                  <div className="custom-check-w">
                    <Field
                      id="seleccionar-todo-recta-colorimetro"
                      name="seleccionar-todo-recta-colorimetro"
                      inline
                      component={InputCheckBox}
                      onInputChange={(value) => this.intercambiarCheckRectaColorimetro(value)}
                      colSm={1}
                    />
                    <button type="button" data-toggle="modal" data-target="#delete-modal" className="btn-icono" onClick={() => this.deleteSeleccionRectaColorimetro()}>
                      <Delete /> {t('TITULOS_BOTONES.ELIMINAR')}
                    </button>
                    <button type="button" data-toggle="modal" data-target="#new-edit-modal" className="btn btn-primary nuevo" style={{marginLeft: "16px"}} onClick={() => this.abrirModalAnadirColorimetro()}>
                      <AddCircleOutline /> {t('TITULOS_BOTONES.NUEVO')}
                    </button>
                  </div>
                </div>
              )}
              <ModalAnadirColorimetroPage />
              <div className="clearfix" />
              <ListadoPage noTitle={true} t_key="PANEL_CONTROL.MODAL_RECTA_COLORIMETRO." table={tableColorimetro}>
              </ListadoPage>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" className="btn btn-white" onClick={cerrarModalRectaColorimetro}>{t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.CERRAR')}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalRectaColorimetro',
})(ModalRectaColorimetro))