import React, { Component } from 'react'
import { Modal, Button, Col, Tabs, Tab } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Warning, Delete, AddCircleOutline } from '@material-ui/icons'
import { Row } from 'react-bootstrap'
import { required } from '../../util/validationFunctions'
import FormSubtitle from '../comun/form/FormSubtitle'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import InputText from '../comun/form/InputText'
import InputSelect from '../comun/form/InputSelect'
import InputTextArea from '../comun/form/InputTextArea'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputNumerical from '../comun/form/InputNumerical'
import ModalAnadirRutaPage from '../../containers/cliente/ModalAnadirRutaPage'
import FiltrosModalDireccionesRutaPage from '../../containers/cliente/FiltrosModalDireccionesRutaPage'
import './Cliente.scss'

class ModalDireccionesCliente extends Component {
  constructor () {
    super()
    this.state = {
      hayPorDefecto: false,
      tipoDireccion: 2
    }
  }

  componentDidMount () {
    this.props.change('activo', true)
    this.props.change('tipo', {value: 2, label: this.props.t('COMUN.COMBOS.TIPO_DIRECCION.ENVIO')})
  }

  componentDidUpdate (prevProps) {
    let newState = {}

    if (this.props.dataDirecciones !== prevProps.dataDirecciones) {
      if (Object.keys(this.props.dataDirecciones).length > 0) {
        this.props.change('tipo', {value: this.props.dataDirecciones.idTipo, label: this.props.dataDirecciones.tipo})
        newState.tipoDireccion = this.props.dataDirecciones.idTipo
      }
    } else if (this.props.dataDuplicarDirecciones !== prevProps.dataDuplicarDirecciones) {
      if (Object.keys(this.props.dataDuplicarDirecciones).length > 0) {
        this.props.change('tipo', {value: this.props.dataDuplicarDirecciones.idTipo, label: this.props.dataDuplicarDirecciones.tipo})
        newState.tipoDireccion = this.props.dataDuplicarDirecciones.idTipo
      }
    } else if (!prevProps.showModalDireccionesCliente && this.props.showModalDireccionesCliente) {
      this.props.change('tipo', {value: 2, label: this.props.t('COMUN.COMBOS.TIPO_DIRECCION.ENVIO')})
      newState.tipoDireccion = 2
      newState.hayPorDefecto = false
    }

    if (this.props.listDireccionesCliente !== prevProps.listDireccionesCliente) {
      if (Object.keys(this.props.listDireccionesCliente).length > 0) {
        let hayPorDefecto = this.props.listDireccionesCliente.filter((direccion) => {
          if (direccion.idTipo === 1) {
            return direccion
          }
        })
        newState.hayPorDefecto = Object.keys(hayPorDefecto).length > 0
      }
    }

    if (Object.keys(newState).length > 0) {
      this.setState(newState)
    }
  }

  closeModalDireccionesCliente () {
    this.props.actions.closeModalDireccionesCliente()
    this.props.initialize()
  }

  submitDireccionCliente (values) {
    values.idTipo = values.tipo.value
    values.tipo = values.tipo.label
    values.rutas = this.props.listRutas
    if (Object.keys(this.props.dataDirecciones).length > 0){
      this.props.actions.editarDireccionCliente(values)
    } else {
      this.props.actions.submitDireccionCliente(values)
    }
    this.closeModalDireccionesCliente()
  }

  guardarYnuevoCliente (values) {
    this.props.actions.guardarYnuevoCliente(values)
    this.closeModalDireccionesCliente('ModalDireccionesCliente')
  }

  seleccionTipoEnvio (value) {
    this.setState({tipoDireccion: value.value})
  }

  render () {
    const {
      t, handleSubmit, showModalDireccionesCliente, dataDirecciones, clienteAnalisis, numeroRegistrosPorPagina, paginaActual, checkedFiltrosClienteAnalisis, checkedFiltrosClienteDilucion,
      clienteDilucion, checkedFiltrosClienteEnvasado, clienteEnvasado, dataCliente, duplicar, listDireccionesCliente = [], checkedFiltrosdDireccionesRuta, listRutas,
      actions: {onDeleteRutaCliente}
    } = this.props

    listRutas.forEach(
      (row, idRow) => row.idElemento = idRow + 1 + 'direccionRuta'
    )
    const tablaRutas = {
      id: 'rutasTable',
      multiHeader: false,
      hasCheckbox: true,
      hasOptions: true,
      mostrarCambioEstado: false,
      mostrarModificar: false,
      mostrarDuplicar: false,
      columns: [
        {id: 'transportista', name: t('PEDIDOS.CLIENTE.MODAL.DATOS_CLIENTE.TRANSPORTISTA')},
        {id: 'nombreRuta', name: t('PEDIDOS.CLIENTE.MODAL.DATOS_CLIENTE.RUTA')}
      ],
      rows: listRutas,
      onDelete: (row) => onDeleteRutaCliente(row.idElemento),
      filtros: <FiltrosModalDireccionesRutaPage />,
      showModal: this.props.showModalAnadirRuta,
      tablaModal: true,
      idBlockOptions: 2,
      initialValues: {}
    }
    tablaRutas.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tablaRutas.initialValues['check' + row.idElemento] = checkedFiltrosdDireccionesRuta
        }
      }
    )

    const comboTipoDireccion = [
      {value: 1, label: this.props.t('COMUN.COMBOS.TIPO_DIRECCION.POR_DEFECTO')},
      {value: 2, label: this.props.t('COMUN.COMBOS.TIPO_DIRECCION.ENVIO')},
      {value: 3, label: this.props.t('COMUN.COMBOS.TIPO_DIRECCION.CONTACTO')},
      {value: 4, label: this.props.t('COMUN.COMBOS.TIPO_DIRECCION.PARTICULAR')}
    ]

    const comboTipoDireccionSinPorDefecto = [
      {value: 2, label: this.props.t('COMUN.COMBOS.TIPO_DIRECCION.ENVIO')},
      {value: 3, label: this.props.t('COMUN.COMBOS.TIPO_DIRECCION.CONTACTO')},
      {value: 4, label: this.props.t('COMUN.COMBOS.TIPO_DIRECCION.PARTICULAR')}
    ]

    const comboTipoDireccionNuevo = [
      {value: 2, label: this.props.t('COMUN.COMBOS.TIPO_DIRECCION.ENVIO')},
      {value: 3, label: this.props.t('COMUN.COMBOS.TIPO_DIRECCION.CONTACTO')}
    ]

    return (
      <div>
        <ModalAnadirRutaPage />
        <Modal show={showModalDireccionesCliente} onHide={() => this.closeModalDireccionesCliente()} bsSize="large" dialogClassName="xl" backdrop="static" aria-labelledby="contained-modal-title-lg">
        <form className="form-cliente">
          <Modal.Header closeButton>
            <Modal.Title>
            {
              Object.keys(this.props.dataDirecciones).length > 0 ? (
                t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.TITLE_EDITAR')
              ) : (
                Object.keys(this.props.dataDuplicar).length > 0 ? (
                  t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.TITLE_DUPLICAR')
                ) : (
                  t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.TITLE_NUEVO')
                )
              )
            }
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Field
                colSm={3}
                id="tipo"
                name="tipo"
                controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.TIPO') + '*'}
                component={InputSelect}
                // options={this.state.hayPorDefecto === true ? comboTipoDireccionSinPorDefecto : comboTipoDireccion}
                options={comboTipoDireccionNuevo}
                valueKey="value"
                labelKey="label"
                customClass='select-tipo'
                validate={required}
                isClearable={false}
                onInputChange={(value) => this.seleccionTipoEnvio(value)}
              />
              {
                this.state.tipoDireccion === 2 ? (
                  <div key="form1">
                    <Field
                      colSm={3}
                      id="nombreDireccion"
                      name="nombreDireccion"
                      component={InputText}
                      controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.GRANJA') + '*'}
                      customClass="nombre"
                      validate={required}
                      maxLength={true}
                      valorMaxLength={60}
                    />
                    <Field
                      colSm={3}
                      id="direccion"
                      name="direccion"
                      component={InputText}
                      controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.DIRECCION') + '*'}
                      customClass="direccion"
                      validate={required}
                      maxLength={true}
                      valorMaxLength={60}
                    />
                    <Field
                      colSm={3}
                      id="localidad"
                      name="localidad"
                      component={InputText}
                      controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.LOCALIDAD') + '*'}
                      customClass="localidad"
                      validate={required}
                      maxLength={true}
                      valorMaxLength={35}
                    />
                    <Field
                      colSm={3}
                      id="provincia"
                      name="provincia"
                      component={InputText}
                      controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.PROVINCIA')}
                      customClass="provincia"
                      maxLength={true}
                      valorMaxLength={35}
                    />
                    <Field
                      colSm={3}
                      id="codigoPostal"
                      name="codigoPostal"
                      component={InputText}
                      controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.CP')}
                      customClass="codigoPostal"
                    />
                    <Field
                      colSm={3}
                      id="dni"
                      name="dni"
                      component={InputText}
                      controlLabel={t('PEDIDOS.CLIENTE.MODAL.CIF')}
                      customClass="cif"
                      maxLength={true}
                      valorMaxLength={15}
                    />
                    <Field
                      colSm={3}
                      id="codigoRega"
                      name="codigoRega"
                      component={InputText}
                      controlLabel={t('PEDIDOS.CLIENTE.MODAL.CODIGO_REGA')}
                      customClass="codigo-rega"
                      maxLength={true}
                      valorMaxLength={20}
                    />
                    <Field
                      colSm={3}
                      id="movil"
                      name="movil"
                      component={InputText}
                      controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.MOVIL')}
                      customClass="movil"
                      maxLength={true}
                      valorMaxLength={20}
                    />
                    <Field
                      colSm={3}
                      id="telefono_uno"
                      name="telefono_uno"
                      component={InputText}
                      controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.TELEFONO')}
                      customClass="telefono"
                      maxLength={true}
                      valorMaxLength={20}
                    />
                    <Field
                      colSm={3}
                      id="email"
                      name="email"
                      component={InputText}
                      controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.EMAIL')}
                      customClass="email"
                      maxLength={true}
                      valorMaxLength={50}
                    />
                  </div>
                ) : null
              }
              {
                this.state.tipoDireccion === 3 ? (
                  <div key="form2">
                    <Field
                      colSm={3}
                      id="nombreDireccion"
                      name="nombreDireccion"
                      component={InputText}
                      controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.NOMBRE') + '*'}
                      customClass="nombre"
                      validate={required}
                      maxLength={true}
                      valorMaxLength={60}
                    />
                    <Field
                      colSm={3}
                      id="funcion"
                      name="funcion"
                      component={InputText}
                      controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.FUNCION')}
                      customClass="funcion"
                      maxLength={true}
                      valorMaxLength={60}
                    />
                    <Field
                      colSm={3}
                      id="contacto_telefono_uno"
                      name="contacto_telefono_uno"
                      component={InputText}
                      controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.CONTACTO_TELEFONO')}
                      customClass="telefono"
                      maxLength={true}
                      valorMaxLength={30}
                    />
                    <Field
                      colSm={3}
                      id="telefono_dos"
                      name="telefono_dos"
                      component={InputText}
                      controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.TELEFONO2')}
                      customClass="telefono"
                      maxLength={true}
                      valorMaxLength={20}
                    />
                  </div>
                ) : null
              }
            </Row>
            {
              this.state.tipoDireccion === 2 ? (
              <SimpleTablePage {...tablaRutas}></SimpleTablePage>
              ) : null
            }
            <div className="clearfix"></div>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" className="btn btn-primary" onClick={handleSubmit(this.submitDireccionCliente.bind(this))}>{t('PEDIDOS.CLIENTE.MODAL.ACEPTAR')}</Button>
            <Button type="button" className="btn btn-white" onClick={() => this.closeModalDireccionesCliente()}>{t('PEDIDOS.CLIENTE.MODAL.CANCELAR')}</Button>
          </Modal.Footer>
          </form>
        </Modal>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'ModalDireccionesCliente',
})(ModalDireccionesCliente))