import React, { Component } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import Button from '../comun/button/Button'
import FormGroupSubtitle from '../comun/form/FormGroupSubtitle'
import InputRadioButton from '../comun/form/InputRadioButton'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputNumerical from '../comun/form/InputNumerical'
import InputSelect from '../comun/form/InputSelect'
import { required } from '../../util/validationFunctions'
import {verracoSiNo as verracoSiNoConstants, translateVerracoSiNo as translateVerracoSiNoConstants} from '../../constants/seguimiento'

class ModalAlbaranDosis extends Component {
  constructor () {
    super()
    this.handleChangeDias = this.handleChangeDias.bind(this)
    this.handleChangeTipoDosis = this.handleChangeTipoDosis.bind(this)
    this.handleChangeDosisEnviadas = this.handleChangeDosisEnviadas.bind(this)
    this.handleChangeRaza = this.handleChangeRaza.bind(this)
    this.handleChangePoolVerracoRadio = this.handleChangePoolVerracoRadio.bind(this)
    this.handleChangeEnvasadoExtraccionAlbaran = this.handleChangeEnvasadoExtraccionAlbaran.bind(this)
    this.handleChangeIsVerracoPropio = this.handleChangeIsVerracoPropio.bind(this)
    this.getComboPoolsVerracosExtraccionAlbaran = this.getComboPoolsVerracosExtraccionAlbaran.bind(this)
    this.guardarYnuevo = this.guardarYnuevo.bind(this)
    this.editarAlbaranDosis = this.editarAlbaranDosis.bind(this)
  }

  editarAlbaranDosis (values) {
    return new Promise((resolve, reject) => {
      this.props.actions.editarAlbaranDosis(values, false, resolve, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  guardarYnuevo (values) {
    return new Promise((resolve, reject) => {
      this.props.actions.editarAlbaranDosis(values, true, resolve, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  handleChangeDias (dias) {
    let values = {
      dias: dias || '0'
    }
    this.getComboPoolsVerracosExtraccionAlbaran(values, this.props.formModalAlbaranDosis.poolVerracoRadio)
  }

  handleChangeDosisEnviadas (val) {
    let numeroDosisRestantes = 0
    if (this.props.formModalAlbaranDosis && this.props.formModalAlbaranDosis.numeroDosisRestantesInicial) {
      numeroDosisRestantes = parseInt(this.props.formModalAlbaranDosis.numeroDosisRestantesInicial, 10)
    } else if (this.props.formModalAlbaranDosis && this.props.formModalAlbaranDosis.poolVerracoRadio === '' + verracoSiNoConstants.POOL) {
      numeroDosisRestantes = this.props.formModalAlbaranDosis.pool ? this.props.formModalAlbaranDosis.pool.numeroDosisRestantes : 0
    } else {
      numeroDosisRestantes = this.props.formModalAlbaranDosis.verraco ? this.props.formModalAlbaranDosis.verraco.numeroDosisRestantes : 0
    }
    numeroDosisRestantes -= val
    this.props.change('numeroDosisRestantes', numeroDosisRestantes)
  }

  handleChangeTipoDosis (tipoDosis) {
    let values = {
      idTipoDosis: tipoDosis ? tipoDosis.value : ''
    }
    this.getComboPoolsVerracosExtraccionAlbaran(values, this.props.formModalAlbaranDosis.poolVerracoRadio)
  }

  handleChangeRaza (raza) {
    let values = {
      idRaza: raza ? raza.value : ''
    }
    this.getComboPoolsVerracosExtraccionAlbaran(values, this.props.formModalAlbaranDosis.poolVerracoRadio)
  }

  handleChangeLineaGenetica (lineaGenetica) {
    let values = {
      idLinea: lineaGenetica ? lineaGenetica.value : ''
    }
    this.getComboPoolsVerracosExtraccionAlbaran(values, this.props.formModalAlbaranDosis.poolVerracoRadio)
  }

  handleChangePoolVerracoRadio (val) {
    this.getComboPoolsVerracosExtraccionAlbaran({}, val)
  }

  handleChangeEnvasadoExtraccionAlbaran (val) {
    let numeroDosisRestantesInicial = val ? parseInt(val.numeroDosisRestantes, 10) : 0
    let numeroDosisRestantes = numeroDosisRestantesInicial
    numeroDosisRestantes -= this.props.formModalAlbaranDosis && this.props.formModalAlbaranDosis.dosisEnviadas ? parseInt(this.props.formModalAlbaranDosis.dosisEnviadas, 10) : 0

    this.props.change('numeroDosisRestantesInicial', numeroDosisRestantesInicial)
    this.props.change('numeroDosisRestantes', numeroDosisRestantes)
    this.props.change('numeroDosisDisponibles', val ? parseInt(val.numeroDosisDisponibles, 10) : 0)
    this.props.change('numeroDosisReservadas', val ? parseInt(val.numeroDosisReservadas, 10) : 0)
  }

  getComboPoolsVerracosExtraccionAlbaran (values, tipo) {
    let valuesCompleto = {
      fecha: this.props.formModalAlbaran.fechaCreacion,
      diasHasta: (values.dias || values.dias === '0') ? values.dias : this.props.formModalAlbaranDosis.dias || '0',
      idRaza: values.idRaza || (this.props.formModalAlbaranDosis.raza ? this.props.formModalAlbaranDosis.raza.value : null),
      idLinea: values.idLinea || (this.props.formModalAlbaranDosis.linea ? this.props.formModalAlbaranDosis.linea.value : null),
      idTipoDosis: values.idTipoDosis || (this.props.formModalAlbaranDosis.tipoDosis ? this.props.formModalAlbaranDosis.tipoDosis.value : null),
      idEnvasadoMultiDosis: !/^new/.test(this.props.formModalAlbaranDosis.idAlbaranDosis) ? this.props.formModalAlbaranDosis.idEnvasadoMultiDosis : null,
      idAlbaran: this.props.idAlbaran ? this.props.idAlbaran : null
    }

    // Si hay cliente seleccionado y esta marcado el checkbox de isVerracoPropio, se enviaran dichos datos para que se devuelvan los verracos propios de dicho cliente.
    if (tipo === '' + verracoSiNoConstants.VERRACO && (values.isVerracoPropio ||
      (this.props.formModalAlbaran.cliente && this.props.formModalAlbaran.cliente.value &&
      this.props.formModalAlbaranDosis.isVerracoPropio && this.props.formModalAlbaranDosis.isVerracoPropio))
    ) {
      valuesCompleto.idCliente = this.props.formModalAlbaran.cliente.value
      valuesCompleto.isVerracoPropio = this.props.formModalAlbaranDosis.isVerracoPropio
    }

    if (tipo === '' + verracoSiNoConstants.POOL) {
      this.props.actions.comboPoolsExtraccionAlbaran(valuesCompleto)
      this.props.change('pool', '')
    } else {
      this.props.actions.comboVerracosExtraccionAlbaran(valuesCompleto)
      this.props.change('verraco', '')
    }
    this.handleChangeEnvasadoExtraccionAlbaran()
  }

  handleChangeIsVerracoPropio (isVerracoPropio) {
    let values = {
      isVerracoPropio: isVerracoPropio
    }
    this.getComboPoolsVerracosExtraccionAlbaran(values, this.props.formModalAlbaranDosis.poolVerracoRadio)
  }

  render () {
    const {
      t, handleSubmit, showModalAlbaranDosis, formModalAlbaranDosis, formModalAlbaran, submitting, dosisAlbaran,
      actions: {cerrarDetallesAlbaranDosis}, combos: {comboTipoDosis, comboRaza, comboLineaGenetica}
    } = this.props
    const tKey = 'ALBARANES.MODAL.MODAL_MULTIDOSIS.'

    // Filtrar los combos de dosis con el fin de evitar que se introduzcan dos registros del mismo envasado.
    let comboVerracosExtraccionAlbaran = this.props.combos.comboVerracosExtraccionAlbaran.filter(verracoExtraccionAlbaran => {
      return (
        (formModalAlbaranDosis && formModalAlbaranDosis.idEnvasadoMultiDosis === verracoExtraccionAlbaran.idEnvasadoMultiDosis) ||
        ((dosisAlbaran && dosisAlbaran.length) ? !dosisAlbaran.some(dosis => {
          return dosis.idEnvasadoMultiDosis === verracoExtraccionAlbaran.idEnvasadoMultiDosis
        }) : true)
      )
    })
    let comboPoolsExtraccionAlbaran = this.props.combos.comboPoolsExtraccionAlbaran.filter(poolExtraccionAlbaran => {
      return (
        (formModalAlbaranDosis && formModalAlbaranDosis.idEnvasadoMultiDosis === poolExtraccionAlbaran.idEnvasadoMultiDosis) ||
        ((dosisAlbaran && dosisAlbaran.length) ? !dosisAlbaran.some(dosis => {
          return dosis.idEnvasadoMultiDosis === poolExtraccionAlbaran.idEnvasadoMultiDosis
        }) : true)
      )
    })

    return (
      <Modal show={showModalAlbaranDosis} onHide={cerrarDetallesAlbaranDosis} backdrop="static" className="modal-albaran-venta">
      <form className="form-albaran">
        <Modal.Header closeButton>
          <Modal.Title>
            {t(tKey + (formModalAlbaranDosis && formModalAlbaranDosis.idAlbaranDosis ? 'TITLE_MODIFICAR' : 'TITLE_NUEVO'))}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={6}>
              <Row>
                <FormGroupSubtitle colSm={12} small={true}>&nbsp;</FormGroupSubtitle>
                <Field
                  colSm={6}
                  id="dias"
                  name="dias"
                  controlLabel={t(tKey + 'FORM.DIAS_HASTA')}
                  numDecimales={0}
                  component={InputNumerical}
                  onInputChange={(event, val) => this.handleChangeDias(val)}
                  onInputChangeDelay={true}
                />
                <Field
                  colSm={6}
                  id="tipoDosis"
                  name="tipoDosis"
                  controlLabel={t(tKey + 'FORM.TIPO_DOSIS') + '*'}
                  component={InputSelect}
                  options={comboTipoDosis}
                  valueKey="value"
                  labelKey="label"
                  isClearable={false}
                  validate={required}
                  onInputChange={(tipoDosis) => this.handleChangeTipoDosis(tipoDosis)}
                  disabled={formModalAlbaranDosis && formModalAlbaranDosis.idPedido && formModalAlbaranDosis.idPedido !== null ? true : false}
                />
                <div className="clearfix"></div>
                {formModalAlbaranDosis && formModalAlbaranDosis.dosisPedidas && (
                  <Field
                    colSm={6}
                    id="dosisPedidas"
                    name="dosisPedidas"
                    controlLabel={t(tKey + 'FORM.DOSIS_PEDIDAS')}
                    numDecimales={0}
                    component={InputNumerical}
                    disabled={true}
                  />
                )}
                <Field
                  colSm={6}
                  id="dosisEnviadas"
                  name="dosisEnviadas"
                  controlLabel={t(tKey + 'FORM.DOSIS_ENVIADAS')}
                  numDecimales={0}
                  component={InputNumerical}
                  onInputChange={(event, val) => this.handleChangeDosisEnviadas(val)}
                  onInputChangeDelay={true}
                />
              </Row>
            </Col>
            <Col sm={6} className="border-left">
              <Row>
                <FormGroupSubtitle colSm={6} small={true}>{t(tKey + 'FORM.TIPO_EXTRACCION')}</FormGroupSubtitle>
                <Col sm={6}>
                  <Row className="form-group m-b-0">
                    <Col sm={6}>
                      <Field
                        name="poolVerracoRadio"
                        component={InputRadioButton}
                        props={{ value: ''+verracoSiNoConstants.POOL }}
                        className="btn-input-style m-t-0"
                        onChange={(e, value) => this.handleChangePoolVerracoRadio(value)}
                      >
                        {t(tKey + 'FORM.POOL')}
                      </Field>
                    </Col>
                    <Col sm={6}>
                      <Field
                        name="poolVerracoRadio"
                        component={InputRadioButton}
                        props={{ value: ''+verracoSiNoConstants.VERRACO }}
                        className="btn-input-style m-t-0"
                        onChange={(e, value) => this.handleChangePoolVerracoRadio(value)}
                      >
                        {t(tKey + 'FORM.VERRACO')}
                      </Field>
                    </Col>
                  </Row>
                </Col>
                <div className="clearfix"></div>
                <Field
                  colSm={6}
                  id="raza"
                  name="raza"
                  controlLabel={t(tKey + 'FORM.RAZA')}
                  component={InputSelect}
                  options={comboRaza}
                  valueKey="value"
                  labelKey="label"
                  onInputChange={(raza) => this.handleChangeRaza(raza)}
                  disabled={formModalAlbaranDosis && formModalAlbaranDosis.idPedido && formModalAlbaranDosis.idPedido !== null ? true : false}
                />
                <Field
                  colSm={6}
                  id="linea"
                  name="linea"
                  controlLabel={t(tKey + 'FORM.LINEA_GENETICA')}
                  component={InputSelect}
                  options={comboLineaGenetica}
                  valueKey="value"
                  labelKey="label"
                  onInputChange={(lineaGenetica) => this.handleChangeLineaGenetica(lineaGenetica)}
                  disabled={formModalAlbaranDosis && formModalAlbaranDosis.idLineaGeneticaPedido && formModalAlbaranDosis.idLineaGeneticaPedido !== null ? true : false}
                />
                <div className="clearfix"></div>
                {formModalAlbaran && formModalAlbaran.cliente && formModalAlbaran.cliente.value && formModalAlbaran.cliente.verracosPropiedad && this.props.formModalAlbaranDosis && this.props.formModalAlbaranDosis.poolVerracoRadio === '' + verracoSiNoConstants.VERRACO && [
                  <Field
                    colSm={12}
                    id="isVerracoPropio"
                    name="isVerracoPropio"
                    key="isVerracoPropio"
                    controlLabel={t(tKey + 'FORM.VERRACOS_PROPIOS')}
                    component={InputCheckBox}
                    inline
                    claseActivo="onoffswitch-label"
                    onInputChange={(value) => this.handleChangeIsVerracoPropio(value)}
                  />,
                  <div className="clearfix" key="verracosPropios_clearfix"></div>
                ]}
                {formModalAlbaranDosis && formModalAlbaranDosis.poolVerracoRadio === '' + verracoSiNoConstants.POOL ? (
                  <Field
                    colSm={6}
                    id="pool"
                    name="pool"
                    controlLabel={t(tKey + 'FORM.DOSIS_POOL') + ((formModalAlbaranDosis && formModalAlbaranDosis.dosisEnviadas && formModalAlbaranDosis.dosisEnviadas > 0) ? '*' : '')}
                    component={InputSelect}
                    options={comboPoolsExtraccionAlbaran}
                    valueKey="idEnvasadoMultiDosis"
                    labelKey="concat"
                    validate={(formModalAlbaranDosis && formModalAlbaranDosis.dosisEnviadas && formModalAlbaranDosis.dosisEnviadas > 0) ? required : null}
                    onInputChange={(envasadoExtraccionAlbaran) => this.handleChangeEnvasadoExtraccionAlbaran(envasadoExtraccionAlbaran)}
                  />
                ) : (
                  <Field
                    colSm={6}
                    id="verraco"
                    name="verraco"
                    controlLabel={t(tKey + 'FORM.DOSIS_VERRACO') + ((formModalAlbaranDosis && formModalAlbaranDosis.dosisEnviadas && formModalAlbaranDosis.dosisEnviadas > 0) ? '*' : '')}
                    component={InputSelect}
                    options={comboVerracosExtraccionAlbaran}
                    valueKey="idEnvasadoMultiDosis"
                    labelKey="concat"
                    validate={(formModalAlbaranDosis && formModalAlbaranDosis.dosisEnviadas && formModalAlbaranDosis.dosisEnviadas > 0) ? required : null}
                    onInputChange={(envasadoExtraccionAlbaran) => this.handleChangeEnvasadoExtraccionAlbaran(envasadoExtraccionAlbaran)}
                    helpBlockText={formModalAlbaranDosis && formModalAlbaranDosis.nombreVerracoPedido && t(tKey + 'FORM.PEDIDO_VERRACO') + ' ' + formModalAlbaranDosis.nombreVerracoPedido}
                  />
                )}
                <Field
                  colSm={6}
                  id="numeroDosisRestantes"
                  name="numeroDosisRestantes"
                  customClass={(formModalAlbaranDosis && !isNaN(formModalAlbaranDosis.numeroDosisRestantes) && parseInt(formModalAlbaranDosis.numeroDosisRestantes, 10) < 0) ? 'negative-input' : ''}
                  controlLabel={t(tKey + 'FORM.DOSIS_RESTANTES')}
                  numDecimales={0}
                  component={InputNumerical}
                  disabled={true}
                />
                <div className="clearfix"></div>
                <Field
                  colSm={6}
                  id="numeroDosisDisponibles"
                  name="numeroDosisDisponibles"
                  controlLabel={t(tKey + 'FORM.DOSIS_DISPONIBLES')}
                  numDecimales={0}
                  component={InputNumerical}
                  disabled={true}
                />
                <Field
                  colSm={6}
                  id="numeroDosisReservadas"
                  name="numeroDosisReservadas"
                  controlLabel={t(tKey + 'FORM.DOSIS_RESERVADAS')}
                  numDecimales={0}
                  component={InputNumerical}
                  disabled={true}
                />
                <Col sm={6} smOffset={6}>
                  <p>{t(tKey + 'FORM.NO_INCLUIDAS_DOSIS_ALBARAN_ACTUAL')}</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            disabled={submitting}
            onClick={!submitting ? handleSubmit(this.guardarYnuevo) : () => {}}
            className="btn btn-primary"
          >
            {t(tKey + 'BUTTONS.GUARDAR_Y_NUEVO')}
          </Button>
          <Button
            type="button"
            disabled={submitting}
            onClick={!submitting ? handleSubmit(this.editarAlbaranDosis) : () => {}}
            className="btn btn-primary"
          >
            {t(tKey + 'BUTTONS.GUARDAR')}
          </Button>
          <Button
            type="button"
            className={"btn btn-white" + (submitting ? ' disabled' : '')}
            disabled={submitting}
            onClick={!submitting ? cerrarDetallesAlbaranDosis : null}
          >
            {t(tKey + 'BUTTONS.CANCELAR')}
          </Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalAlbaranDosis',
})(ModalAlbaranDosis))