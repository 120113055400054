import React, { Component } from 'react'
import { Modal, FormGroup, Checkbox } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import { required } from '../../util/validationFunctions'
import Button from '../comun/button/Button'
import ButtonChangeRecord from '../comun/button/ButtonChangeRecord'
import ButtonLockable from '../comun/button/ButtonLockable'
import InputText from '../comun/form/InputText'
import InputCheckBox from '../comun/form/InputCheckBox'
import {permisos as permisosConstant} from '../../constants/permisos'
import {modulos as modulosConstant} from '../../constants/modulos'

const itemsPermisos = (t) => {
  return [
    {id: permisosConstant.EXTRACCIONES, name: t('MENU.EXTRACCIONES.EXTRACCIONES'), modulos: [modulosConstant.BASICO], children: [
      {id: permisosConstant.ANALISIS, name: t('MENU.EXTRACCIONES.ANALISIS'), modulos: [modulosConstant.BASICO]},
      {id: permisosConstant.DILUCION, name: t('MENU.EXTRACCIONES.DILUCION'), modulos: [modulosConstant.BASICO]},
      {id: permisosConstant.POOL, name: t('MENU.EXTRACCIONES.POOL'), modulos: [modulosConstant.BASICO]},
      {id: permisosConstant.ENVASADO, name: t('MENU.EXTRACCIONES.ENVASADO'), modulos: [modulosConstant.BASICO]},
      {id: permisosConstant.SEGUIMIENTO, name: t('MENU.EXTRACCIONES.SEGUIMIENTO'), modulos: [modulosConstant.BASICO]},
      {id: permisosConstant.FOSO, name: t('MENU.EXTRACCIONES.FOSO'), modulos: [modulosConstant.BASICO]}
    ]},
    {id: permisosConstant.PLANNING, name: t('MENU.PLANING'), modulos: [modulosConstant.BASICO]},
    {id: permisosConstant.PEDIDOS, name: t('MENU.PEDIDO.PEDIDOS'), modulos: [modulosConstant.PEDIDOS_CLIENTES], children: [
      {id: permisosConstant.CLIENTES, name: t('MENU.PEDIDO.CLIENTES'), modulos: [modulosConstant.PEDIDOS_CLIENTES]},
      {id: permisosConstant.PEDIDO, name: t('MENU.PEDIDO.PEDIDOS_VENTA'), modulos: [modulosConstant.PEDIDOS_CLIENTES]},
      {id: permisosConstant.ALBARANES, name: t('MENU.PEDIDO.ALBARANES_VENTA'), modulos: [modulosConstant.PEDIDOS_CLIENTES]}
    ]},
    {id: permisosConstant.FICHA_VERRACOS, name: t('MENU.VERRACOS.FICHA_VERRACOS'), modulos: [modulosConstant.BASICO]},
    {id: permisosConstant.PLAN_SANITARIO, name: t('MENU.PLAN_SANITARIO.PLAN_SANITARIO'), modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD], children: [
      {id: permisosConstant.BIOSEGURIDAD_HIGIENE, name: t('MENU.PLAN_SANITARIO.BIOSEGURIDAD_HIGIENE'), modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD]},
      {id: permisosConstant.VISITAS, name: t('MENU.PLAN_SANITARIO.VISITAS'), modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD]},
      {id: permisosConstant.PS_ALIMENTACION, name: t('MENU.PLAN_SANITARIO.ALIMENTACION.ALIMENTACION'), modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD], children: [
        {id: permisosConstant.TIPOS_ALIMENTACION, name: t('MENU.PLAN_SANITARIO.ALIMENTACION.TIPOS_ALIMENTACION'), modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD]},
        {id: permisosConstant.REGISTRO_ALIMENTACIONES, name: t('MENU.PLAN_SANITARIO.ALIMENTACION.REGISTRO_ALIMENTACIONES'), modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD]}
      ]},
      {id: permisosConstant.INTERVENCIONES_SANITARIAS, name: t('MENU.PLAN_SANITARIO.INTERVENCIONES_SANITARIAS.INTERVENCIONES_SANITARIAS'), modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD], children: [
        {id: permisosConstant.TIPOS_INTERVENCION_SANITARIA, name: t('MENU.PLAN_SANITARIO.INTERVENCIONES_SANITARIAS.TIPOS_INTERVENCION'), modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD]},
        {id: permisosConstant.REGISTRO_INTERVENCION_SANITARIA, name: t('MENU.PLAN_SANITARIO.INTERVENCIONES_SANITARIAS.REGISTRO_INTERVENCION_SANITARIA'), modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD]}
      ]}
    ]},
    {id: permisosConstant.MANTENIMIENTO_DATOS, name: t('MENU.MANTENIMIENTO_DATOS.MANTENIMIENTO_DATOS'), modulos: [modulosConstant.BASICO, modulosConstant.PEDIDOS_CLIENTES, modulosConstant.TRAZABILIDAD_BIOSEGURIDAD], children: [
      {id: permisosConstant.RAZAS, name: t('MENU.MANTENIMIENTO_DATOS.RAZAS'), modulos: [modulosConstant.BASICO]},
      {id: permisosConstant.LINEAS_GENETICAS, name: t('MENU.MANTENIMIENTO_DATOS.LINEAS_GENETICAS'), modulos: [modulosConstant.BASICO]},
      {id: permisosConstant.GRUPOS_VERRACOS, name: t('MENU.MANTENIMIENTO_DATOS.GRUPOS_DE_VERRACOS'), modulos: [modulosConstant.BASICO]},
      {id: permisosConstant.OPERARIOS, name: t('MENU.MANTENIMIENTO_DATOS.OPERARIOS'), modulos: [modulosConstant.BASICO]},
      {id: permisosConstant.TRANSPORTE, name: t('MENU.MANTENIMIENTO_DATOS.TRANSPORTE.TRANSPORTE'), modulos: [modulosConstant.PEDIDOS_CLIENTES], children: [
        {id: permisosConstant.RUTAS, name: t('MENU.MANTENIMIENTO_DATOS.TRANSPORTE.RUTAS'), modulos: [modulosConstant.PEDIDOS_CLIENTES]},
        {id: permisosConstant.TRANSPORTISTAS, name: t('MENU.MANTENIMIENTO_DATOS.TRANSPORTE.TRANSPORTISTAS'), modulos: [modulosConstant.PEDIDOS_CLIENTES]},
      ]},
      {id: permisosConstant.PLANO, name: t('MENU.MANTENIMIENTO_DATOS.PLANO.PLANO'), modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD], children: [
        {id: permisosConstant.TIPOS, name: t('MENU.MANTENIMIENTO_DATOS.PLANO.TIPOS'), modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD]},
        {id: permisosConstant.INSTALACIONES, name: t('MENU.MANTENIMIENTO_DATOS.PLANO.INSTALACIONES'), modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD]},
        {id: permisosConstant.VER_MAPA, name: t('MENU.MANTENIMIENTO_DATOS.PLANO.VER_MAPA'), modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD]},
      ]},
      {id: permisosConstant.VERRACOS, name: t('MENU.VERRACOS.VERRACOS'), modulos: [modulosConstant.BASICO, modulosConstant.TRAZABILIDAD_BIOSEGURIDAD], children: [
        {id: permisosConstant.MOVIMIENTO_VERRACOS, name: t('MENU.VERRACOS.MOVIMIENTO_VERRACOS'), modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD]},
        {id: permisosConstant.CAMBIOS_ESTADO_VERRACOS, name: t('MENU.VERRACOS.CAMBIOS_ESTADO_VERRACOS'), modulos: [modulosConstant.BASICO]},
      ]}
    ]},
    {id: permisosConstant.ALMACEN, name: t('MENU.ALMACEN.ALMACEN'), modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD], children: [
      {id: permisosConstant.PRODUCTOS, name: t('MENU.ALMACEN.PRODUCTOS'), modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD]},
      {id: permisosConstant.ESCANDALLOS, name: t('MENU.ALMACEN.ESCANDALLOS'), modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD]},
      {id: permisosConstant.ENTRADAS_MERCANCIAS, name: t('MENU.ALMACEN.ENTRADAS_MERCANCIAS'), modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD]},
      {id: permisosConstant.VALIDACION_MATERIAS_PRIMAS, name: t('MENU.ALMACEN.VALIDACION_MATERIAS_PRIMAS'), modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD]},
      {id: permisosConstant.AJUSTE_STOCK, name: t('MENU.ALMACEN.AJUSTE_STOCK'), modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD]},
    ]},
    {id: permisosConstant.INFORMES, name: t('MENU.INFORMES.INFORMES'), modulos: [modulosConstant.INFORMES, modulosConstant.PEDIDOS_CLIENTES, modulosConstant.TRAZABILIDAD_BIOSEGURIDAD]},
    {id: permisosConstant.HERRAMIENTAS, name: t('MENU.HERRAMIENTAS.HERRAMIENTAS'), modulos: [modulosConstant.BASICO, modulosConstant.TRAZABILIDAD_BIOSEGURIDAD], children: [
      {id: permisosConstant.PANEL_CONTROL, name: t('MENU.HERRAMIENTAS.PANEL_CONTROL'), modulos: [modulosConstant.BASICO], children: [
        {id: permisosConstant.TIPO_DOSIS, name: t('PANEL_CONTROL.TIPOS_DOSIS'), modulos: [modulosConstant.BASICO]},
        {id: permisosConstant.TIPO_DILUYENTE, name: t('PANEL_CONTROL.TIPO_DILUYENTE'), modulos: [modulosConstant.BASICO]},
        {id: permisosConstant.TECLAS_CONTAJE, name: t('PANEL_CONTROL.TECLAS_CONTAJE'), modulos: [modulosConstant.BASICO]},
        {id: permisosConstant.RECTA_COLORIMETRO, name: t('PANEL_CONTROL.RECTA_COLORIMETRO'), modulos: [modulosConstant.BASICO]},
        {id: permisosConstant.SECUENCIA, name: t('PANEL_CONTROL.TABLA_SECUENCIA_NUMERACIONES.TITULO'), modulos: [modulosConstant.BASICO]}
      ]},
      {id: permisosConstant.CONECTIVIDAD, name: t('MENU.HERRAMIENTAS.CONECTIVIDAD'), modulos: [modulosConstant.BASICO]},
      {id: permisosConstant.UTILIDADES, name: t('MENU.UTILIDADES.UTILIDADES'), modulos: [modulosConstant.BASICO, modulosConstant.TRAZABILIDAD_BIOSEGURIDAD], children: [
        {id: permisosConstant.COPIA_SEGURIDAD, name: t('MENU.UTILIDADES.COPIA_SEGURIDAD'), modulos: [modulosConstant.BASICO]},
        {id: permisosConstant.CARGA_INDEX, name: t('MENU.UTILIDADES.CARGA_INDEX'), modulos: [modulosConstant.BASICO]},
        {id: permisosConstant.IMPORTAR_DATOS_GESIPOR_2, name: t('MENU.SISTEMA.IMPORTAR_DATOS_GESIPOR'), modulos: [modulosConstant.BASICO]},
      ]},
      {id: permisosConstant.SISTEMA, name: t('MENU.SISTEMA.SISTEMA'), modulos: [modulosConstant.BASICO], children: [
        {id: permisosConstant.EMPRESA_CENTROS, name: t('MENU.SISTEMA.EMPRESA_CENTROS'), modulos: [modulosConstant.BASICO]},
        {id: permisosConstant.USUARIOS, name: t('MENU.SISTEMA.USUARIOS'), modulos: [modulosConstant.BASICO]},
        {id: permisosConstant.GRUPOS_USUARIOS, name: t('MENU.SISTEMA.GRUPOS_USUARIOS'), modulos: [modulosConstant.BASICO]},
      ]}
    ]},
    {id: permisosConstant.AGENDA, name: t('MENU.UTILIDADES.AGENDA'), modulos: [modulosConstant.TRAZABILIDAD_BIOSEGURIDAD]},
  ]
}

/*
  Este componente se llama a si mismo recursivamente cuando tiene hijos.
*/
class ItemPermiso extends Component {
  constructor (props) {
    super()
    this.state = {
      checkLecturaValue: false,
      checkEscrituraValue: false,
      userAction: false
    }

    this.setStateCallback = this.setStateCallback.bind(this)
    this.updateCheckStatus = this.updateCheckStatus.bind(this)
  }

  setStateCallback () {
    this.props.onInputChange(this.props.id, (this.state.checkEscrituraValue ? 2 : this.state.checkLecturaValue ? 1 : 0))
  }

  updateCheckStatus () {
    let permisoItem
    if (this.props.permisosItems) {
      permisoItem = this.props.permisosItems.find((permisoItem) => {
        return permisoItem.idItem === this.props.id
      })
    }

    if (!permisoItem) permisoItem = {permiso: 0}
    this.setState({checkLecturaValue: permisoItem.permiso > 0, checkEscrituraValue: permisoItem.permiso > 1}, this.setStateCallback)
  }

  componentDidMount () {
    if (this.props.permisosItems) { // TODO Asegurarse/comprobar: ¿Unicamente ejecutar la primera vez que se obtiene el objecto?
      this.updateCheckStatus()
    }
  }

  componentDidUpdate (prevProps) {
    if (JSON.stringify(this.props.permisosItems) !== JSON.stringify(prevProps.permisosItems)) {
      this.updateCheckStatus()
    } else if (this.props.parentValue) {
      if (this.props.parentValue.userAction && this.props.parentValue.lectura !== prevProps.parentValue.lectura) {
        this.setState((state) => {
          return {
            ...state,
            checkLecturaValue: this.props.parentValue.lectura,
            checkEscrituraValue: !this.props.parentValue.lectura ? false : state.checkEscrituraValue,
            userAction: this.props.parentValue.userAction
          }
        }, this.setStateCallback)
      }
      if (this.props.parentValue.userAction && this.props.parentValue.escritura !== prevProps.parentValue.escritura) {
        this.setState((state) => {
          return {
            ...state,
            checkEscrituraValue: this.props.parentValue.escritura,
            userAction: this.props.parentValue.userAction
          }
        }, this.setStateCallback)
      }
    }
  }

  render () {
    const {id, name, children = [], permisosItems, modulos, t, onInputChange} = this.props
    const tKey = 'GRUPO_USUARIOS.MODAL.FORM.'

    const filteredChildren = children.filter((itemPermiso) => {
      return itemPermiso.modulos.some((modulo) => {
        return modulos.indexOf(modulo) !== -1
      })
    })

    return (
      <li>
        <Row>
          <Col sm={12}>
            <ul>
              <li>
                <Row>
                  <Col sm={8}><span>{name}</span></Col>
                  <Col sm={2}>
                    <FormGroup>
                      <Checkbox
                        id={"lectura" + id}
                        name={"lectura" + id}
                        title={t(tKey + "LECTURA")}
                        inline={true}
                        disabled={false}
                        checked={this.state.checkLecturaValue}
                        onChange={(e) => {
                          let checked = e.target.checked
                          this.setState((state) => {
                            return {
                              checkLecturaValue: checked,
                              checkEscrituraValue: !checked ? false : state.checkEscrituraValue,
                              userAction: true
                            }
                          }, this.setStateCallback)
                        }}
                        className="styled-checkbox select-all checkbox-inline-activo"
                      >
                        <label className="onoffswitch-label" htmlFor={"lectura" + id} />
                      </Checkbox>
                    </FormGroup>
                  </Col>
                  <Col sm={2}>
                    <FormGroup>
                      <Checkbox
                        id={"escritura" + id}
                        name={"escritura" + id}
                        title={t(tKey + "ESCRITURA")}
                        inline={true}
                        disabled={false}
                        checked={this.state.checkEscrituraValue}
                        onChange={(e) => {
                          let checked = e.target.checked
                          this.setState(() => {
                            return {
                              checkLecturaValue: checked,
                              checkEscrituraValue: checked,
                              userAction: true
                            }
                          }, this.setStateCallback)
                        }}
                        className="styled-checkbox select-all checkbox-inline-activo"
                      >
                        <label className="onoffswitch-label" htmlFor={"escritura" + id} />
                      </Checkbox>
                    </FormGroup>
                  </Col>
                </Row>
              </li>
            </ul>
          </Col>
          {filteredChildren && filteredChildren.length > 0 && (
            <Col sm={12}>
              <ul>
                {
                  filteredChildren.map((subSeccion) =>
                    <ItemPermiso
                      key={"subSeccion" + subSeccion.id}
                      id={subSeccion.id}
                      name={subSeccion.name}
                      children={subSeccion.children}
                      parentValue={{
                        lectura: this.state.checkLecturaValue,
                        escritura: this.state.checkEscrituraValue,
                        userAction: this.state.userAction,
                      }}
                      permisosItems={permisosItems}
                      modulos={modulos}
                      t={t}
                      onInputChange={onInputChange}
                    />
                  )
                }
              </ul>
            </Col>
          )}
        </Row>
      </li>
    )
  }
}

class ItemsPermisos extends Component {
  constructor (props) {
    super()
    this.itemsPermisos = []
  }

  componentDidMount () {
    this.itemsPermisos = itemsPermisos(this.props.t)
  }

  render () {
    // --- No es necesario ---
    // AYUDA: 63,

    // NUEVO_VERRACO: 64,
    // NUEVO_TRANSPORTISTA: 65,
    // SECUENCIA: 67

    const {
      permisosItems, modulos, onChangePermisos, t
    } = this.props

    return (
      this.itemsPermisos ? this.itemsPermisos.filter((itemPermiso) => {
        return itemPermiso.modulos.some((modulo) => {
          return modulos.indexOf(modulo) !== -1
        })
      }).map((itemPermiso) =>
        <ItemPermiso
          key={"seccion" + itemPermiso.id}
          id={itemPermiso.id}
          name={itemPermiso.name}
          children={itemPermiso.children}
          permisosItems={permisosItems}
          modulos={modulos}
          t={t}
          onInputChange={onChangePermisos}
        />
      ) : null
    )
  }
}

let FormModalGrupoUsuarios = (reduxForm({
  form: 'ModalGrupoUsuarios'
})(
  class FormModalGrupoUsuarios extends Component {
    componentDidUpdate(prevProps) {
      const enabled = (this.props.valid && !this.props.submitting) || !this.props.anyTouched;
      const wasEnabled = (prevProps.valid && !prevProps.submitting) || !prevProps.anyTouched;

      if (enabled !== wasEnabled) {
        // Signal to the parent component that the form can be submitted
        this.props.enabledCallback(enabled);
      }

      if (this.props.submitting !== prevProps.submitting) {
        this.props.onSubmitChange(this.props.submitting)
      }
    }

    render () {
      const {t, handleSubmit, onSubmit} = this.props
      const tKey = 'GRUPO_USUARIOS.MODAL.FORM.'

      return (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Field
              colSm={4}
              id="nombreGrupo"
              name="nombreGrupo"
              component={InputText}
              controlLabel={t(tKey + 'NOMBRE') + '*'}
              customClass='campo-modal-usuario'
              validate={required}
              maxLength={true}
              valorMaxLength={50}
            />
            <Field
              id="estaActivo"
              name="estaActivo"
              colSm={4}
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t(tKey + 'ACTIVO')}
              inline={false}
            />
          </Row>
        </form>
      )
    }
  }
))

class ModalGrupoUsuarios extends Component {
  constructor (props) {
    super()
    this.state = {
      permisos: {},
      isValid: true,
      submitButtonEnabled: true,
      submitYNuevo: false,
      submitting: false
    }

    this.submitGrupoUsuarios = this.submitGrupoUsuarios.bind(this)
    this.guardarYnuevoGrupoUsuarios = this.guardarYnuevoGrupoUsuarios.bind(this)
    this.cerrarModalGrupoUsuarios = this.cerrarModalGrupoUsuarios.bind(this)
  }

  onSubmitClick = () => {
    this.setState({submitYNuevo: false}, () => {
      this.formReference.submit()
    })
  }

  onSubmitYNuevoClick = () => {
    this.setState({submitYNuevo: true}, () => {
      this.formReference.submit()
    })
  }

  enabledCallback = (enabled) => this.setState(prevState => ({
    ...prevState,
    submitButtonEnabled: enabled
  }))

  submitGrupoUsuarios () {
    return new Promise((resolve, reject) => {
      let idGrupoUsuario = (this.props.data.gruposUsuario && this.props.data.gruposUsuario[0].idGrupoUsuario) || null
      this.props.actions.submitGrupoUsuarios({...this.props.formModalGrupoUsuarios, permisos: this.state.permisos}, idGrupoUsuario, this.state.submitYNuevo, resolve, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  guardarYnuevoGrupoUsuarios () {
    return new Promise((resolve, reject) => {
      let idGrupoUsuario = (this.props.data.gruposUsuario && this.props.data.gruposUsuario[0].idGrupoUsuario) || null
      this.props.actions.submitGrupoUsuarios({...this.props.formModalGrupoUsuarios, permisos: this.state.permisos}, idGrupoUsuario, this.state.submitYNuevo, resolve, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  cerrarModalGrupoUsuarios () {
    this.props.actions.cerrarModalGrupoUsuarios()
  }

  render () {
    const {
      t, listGrupoUsuarios, showModalGrupoUsuarios, auth: {modulos}, data, actions: {abrirModalGrupoUsuarios}
    } = this.props
    const tKey = 'GRUPO_USUARIOS.MODAL.'

    return (
      <Modal show={showModalGrupoUsuarios} onHide={this.cerrarModalGrupoUsuarios} backdrop="static">
        <Modal.Header closeButton={!this.state.submitting}>
          <Modal.Title>
          { Object.keys(data).length > 0 ? t(tKey + 'TITULO_EDITAR') : t(tKey + 'TITULO_NUEVO') }
          </Modal.Title>
          {!this.state.submitting && data && data.gruposUsuario && data.gruposUsuario[0].idGrupoUsuario && (
            <ButtonChangeRecord list={listGrupoUsuarios} idElemento="idGrupoUsuario" currentId={data.gruposUsuario[0].idGrupoUsuario} getNextRecord={abrirModalGrupoUsuarios} />
          )}
        </Modal.Header>
        <Modal.Body>
          <FormModalGrupoUsuarios
            t={t}
            onSubmit={this.submitGrupoUsuarios}
            ref={form => this.formReference = form}
            enabledCallback={this.enabledCallback}
            onSubmitChange={(submitting) => { this.setState({submitting})}}
          />
          <Row>
            <Col sm={12}>
              <div className="treeview">
                <Row>
                  <Col sm={12} className="treeview-header">
                    <Row>
                      <Col sm={8}>{t(tKey + 'FORM.APARTADO')}</Col>
                      <Col sm={2}>{t(tKey + 'FORM.LECTURA')}</Col>
                      <Col sm={2}>{t(tKey + 'FORM.ESCRITURA')}</Col>
                    </Row>
                  </Col>
                  <Col sm={12} className="treeview-body">
                    <div>
                      <ul>
                        <ItemsPermisos
                          t={t}
                          permisosItems={data ? data.permisosGrupoUsuario : null}
                          modulos={modulos}
                          onChangePermisos={(id, value) => {
                            this.setState((state) => {
                              return {...state, permisos: {...state.permisos, [id]: value}}
                            })
                          }}
                        />
                      </ul>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {
            (Object.keys(data).length === 0) && (
              <ButtonLockable
                processing={this.state.submitting}
                hasIcon={true}
                showLoaderOnClick={true}
                type="button"
                className="btn btn-primary btn-sm"
                disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.GRUPOS_USUARIOS] < 2) || this.state.submitting}
                onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.GRUPOS_USUARIOS] > 1) && !this.state.submitting) ? this.onSubmitYNuevoClick : () => {}}
              >
                {t(tKey + 'BUTTONS.GUARDAR_Y_NUEVO')}
              </ButtonLockable>
            )
          }
          <ButtonLockable
            processing={this.state.submitting}
            hasIcon={true}
            showLoaderOnClick={true}
            type="button"
            className="btn btn-primary btn-sm"
            disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.GRUPOS_USUARIOS] < 2) || this.state.submitting}
            onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.GRUPOS_USUARIOS] > 1) && !this.state.submitting) ? this.onSubmitClick : () => {}}
          >
            {t(tKey + 'BUTTONS.GUARDAR')}
          </ButtonLockable>
          <Button
            type="button"
            className={"btn btn-white btn-sm" + (this.state.submitting ? ' disabled' : '')}
            onClick={() => !this.state.submitting && this.cerrarModalGrupoUsuarios()}
          >
            {t(tKey + 'BUTTONS.CANCELAR')}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default ModalGrupoUsuarios