import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import Button from '../../comun/button/Button'
import InputText from '../../comun/form/InputText'
import InputSelect from '../../comun/form/InputSelect'
import InputDatePicker from '../../comun/form/InputDatePicker'
import InputCheckBox from '../../comun/form/InputCheckBox'
import InputTextArea from '../../comun/form/InputTextArea'
import {date_parser, date_formatter, number_formatter} from '../../../util/formatFunctions'
import { required } from '../../../util/validationFunctions'

class ModalLicencias extends Component {
  constructor () {
    super()
    this.state = {
      baja: false,
      botonRenovarLicencia: false
    }
    this.submitLicencia = this.submitLicencia.bind(this)
  }

  submitLicencia (values) {
    values.tokenExp = this.props.data.token
    
    let versionesMagavision = []
    if (values.magavisionI === true) {
      versionesMagavision.push('3')
    }
    if (values.magavisionM === true) {
      versionesMagavision.push('4')
    }
    if (values.magavision === true) {
      versionesMagavision.push('5')
    }
    values.versionesMagavision = versionesMagavision.toString()
    this.props.actions.submitLicencia(values, this.props.data.idLicencia)
    this.setState({botonRenovarLicencia: false})
  }

  componentDidMount () {
    this.props.actions.comprobarEntorno()
  }

  componentDidUpdate (prevProps) {
    if (this.props.data.baja !== prevProps.data.baja) {
      this.setState({baja: this.props.data.baja})
    }
  }

  renovarLicencia () {
    this.setState({botonRenovarLicencia: true})
  }

  cerrarRenovarLicencia () {
    this.setState({botonRenovarLicencia: false})
  }

  cerrarModalLicencias () {
    this.props.actions.cerrarModalLicencias()
    this.setState({botonRenovarLicencia: false})
  }

  render () {
    const {
      t, handleSubmit, change, showModalLicencias, data,
      auth: {formaFechaHora, isCloud}
    } = this.props
    const tKey = 'LICENCIAS.MODAL.'

    let comboModulos = this.props.combos.comboModulos
    comboModulos = comboModulos.map((licenciaItem) => {
      return {...licenciaItem, label: t(licenciaItem.label)}
    })

    return (
      <Modal show={showModalLicencias} onHide={() => this.cerrarModalLicencias()} dialogClassName="xl" backdrop="static">
        <form className="form-albaran" onSubmit={handleSubmit(this.submitLicencia.bind(this))}>
          <Modal.Header closeButton>
            <Modal.Title>
              {t(tKey + (data && data.idLicencia ? 'TITLE_MODIFICAR' : 'TITLE_NUEVO'))}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Field
                colSm={3}
                id="nombre"
                name="nombre"
                controlLabel={t(tKey + 'FORM.NOMBRE') + '*'}
                component={InputText}
                validate={required}
                maxLength={true}
                valorMaxLength={100}
              />
              <Field
                colSm={3}
                id="codLicencia"
                name="codLicencia"
                controlLabel={t(tKey + 'FORM.COD_LICENCIA') + '*'}
                component={InputText}
                validate={required}
                maxLength={true}
                valorMaxLength={20}
              />
              {
                Object.keys(data).length === 0 ? [
                  <Field
                    colSm={3}
                    id="fechaExpiracion"
                    name="fechaExpiracion"
                    key="fechaExpiracion"
                    controlLabel={t(tKey + 'FORM.FECHA_EXPIRACION') + '*'}
                    component={InputDatePicker}
                    validate={required}
                  />,
                  <Field
                    colSm={3}
                    id="identificador"
                    name="identificador"
                    key="identificador"
                    controlLabel={t(tKey + 'FORM.NUMERO_SERIE')}
                    component={InputText}
                    maxLength={true}
                    valorMaxLength={100}
                  />
                ] : (
                  <Field
                    colSm={3}
                    id="fechaAlta"
                    name="fechaAlta"
                    controlLabel={t(tKey + 'FORM.FECHA_ALTA')}
                    component={InputDatePicker}
                    disabled={true}
                  />
                )
              }
              {
                data && data.idLicencia ? (
                  this.state.botonRenovarLicencia === false ? (
                    <div>
                      <Col sm={12} style={{marginBottom: '15px'}}>
                        <p style={{wordWrap: 'break-word'}}><b>{t(tKey + 'FORM.FECHA_EXPIRACION')}</b>: {date_formatter(data.fechaExpiracion, formaFechaHora, false)}<b> | {t(tKey + 'FORM.TOKEN')}</b>: {data.token}</p>
                        <Button type="button" className="btn btn-primary" onClick={() => this.renovarLicencia()}>{t(tKey + 'FORM.RENOVAR_LICENCIA')}</Button>
                      </Col>
                    </div>
                  ) : (
                    <div>
                      <Col sm={12} style={{padding: '0px'}}>
                        <Field
                          colSm={3}
                          id="fechaExpiracion"
                          name="fechaExpiracion"
                          controlLabel={t(tKey + 'FORM.FECHA_EXPIRACION')}
                          component={InputDatePicker}
                        />
                        <Field
                          colSm={3}
                          id="identificador"
                          name="identificador"
                          controlLabel={t(tKey + 'FORM.NUMERO_SERIE')}
                          component={InputText}
                          maxLength={true}
                          valorMaxLength={100}
                        />
                        <Button type="button" style={{marginTop: '22px', padding: '5px'}} className="btn btn-primary" onClick={() => this.cerrarRenovarLicencia()}>{t(tKey + 'FORM.CERRAR')}</Button>
                      </Col>
                    </div>
                  )
                ) : null
              }
            </Row>
            <Row>
              {comboModulos.map((currentValue) =>
                <Field
                  id={"modalidad"+currentValue.value}
                  name={"modalidad"+currentValue.value}
                  key={"modalidad"+currentValue.value}
                  colSm={3}
                  component={InputCheckBox}
                  claseActivo="onoffswitch-label"
                  controlLabel={currentValue.label}
                />
              )}
            </Row>
            <hr/>
              <Row>
                <Col sm={6}><p><b>{t(tKey + 'FORM.VERSIONES_MAGAVISION')}</b></p></Col>
                <div className="clearfix"></div>
                <Field
                  colSm={2}
                  id="magavisionM"
                  name="magavisionM"
                  component={InputCheckBox}
                  claseActivo="onoffswitch-label"
                  controlLabel={t(tKey + 'FORM.MAGAVISION_M')}
                />
                <Field
                  colSm={2}
                  id="magavisionI"
                  name="magavisionI"
                  component={InputCheckBox}
                  claseActivo="onoffswitch-label"
                  controlLabel={t(tKey + 'FORM.MAGAVISION_I')}
                />
                <Field
                  colSm={2}
                  id="magavision"
                  name="magavision"
                  component={InputCheckBox}
                  claseActivo="onoffswitch-label"
                  controlLabel={t(tKey + 'FORM.MAGAVISION')}
                />
                <Field
                  colSm={2}
                  id="limiteUsuarios"
                  name="limiteUsuarios"
                  controlLabel={t(tKey + 'FORM.LIMITE_USUARIOS') + '*'}
                  component={InputText}
                  validate={required}
                />
                {!isCloud && !data.idLicencia && (
                  <Field
                    colSm={2}
                    id="idCentroCloud"
                    name="idCentroCloud"
                    controlLabel={t(tKey + 'FORM.CENTRO_CLOUD') + '*'}
                    component={InputText}
                    validate={required}
                  />
                )}
              </Row>
            <hr/>
            <Row>
              <Field
                colSm={12}
                id="observaciones"
                name="observaciones"
                controlLabel={t(tKey + 'FORM.OBSERVACIONES')}
                component={InputTextArea}
              />
            </Row>
            <hr/>
            <Row>
              <Field
                colSm={2}
                id="baja"
                name="baja"
                component={InputCheckBox}
                claseActivo="onoffswitch-label"
                controlLabel={t(tKey + 'FORM.BAJA')}
                onInputChange={(values) => {
                  if (values && !data.baja) {
                    let currentDate = new Date()
                    currentDate = date_parser(currentDate, this.props.auth.formaFechaHora)
                    change('fechaBaja', currentDate)
                  }
                  this.setState({baja: values})
                }}
              />
              {this.state.baja && (
                <Field
                  colSm={3}
                  id="fechaBaja"
                  name="fechaBaja"
                  controlLabel={t(tKey + 'FORM.FECHA_BAJA')}
                  component={InputDatePicker}
                  disabled={true}
                />
              )}
              <Field
                colSm={this.state.baja ? 7 : 10}
                id="motivoBaja"
                name="motivoBaja"
                controlLabel={t(tKey + 'FORM.MOTIVO_BAJA')}
                component={InputTextArea}
              />
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" className="btn btn-primary">{t(tKey + 'BUTTONS.GUARDAR')}</Button>
            <Button type="button" className="btn btn-white" onClick={() => this.cerrarModalLicencias()}>{t(tKey + 'BUTTONS.CANCELAR')}</Button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalLicencias',
})(ModalLicencias))