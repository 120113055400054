import React, { Component } from 'react'
import { Modal, Row } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { required } from '../../../util/validationFunctions'
import Button from '../../comun/button/Button'
import ButtonChangeRecord from '../../comun/button/ButtonChangeRecord'
import ButtonLockable from '../../comun/button/ButtonLockable'
import InputText from '../../comun/form/InputText'
import InputCheckBox from '../../comun/form/InputCheckBox'
import InputTextArea from '../../comun/form/InputTextArea'
import './TipoAlimentacion.scss'
import {permisos as permisosConstant} from '../../../constants/permisos'

class ModalTipoAlimentacion extends Component {
  submitNuevoTipoAlimentacion (values) {
    return new Promise((resolve, reject) => {
      if (this.props.data){
        this.props.actions.editarTipoAlimentacion(values, resolve, reject)
      } else {
        this.props.actions.submitNuevoTipoAlimentacion(values, resolve, reject)
      }
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }
  guardarYnuevoTipoAlimentacion(values){
    return new Promise((resolve, reject) => {
      this.props.actions.guardarYnuevoTipoAlimentacion(values, resolve, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }
  duplicar(values){
    return new Promise((resolve, reject) => {
      this.props.duplicar(values, resolve, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }
  render () {
    const {
      t, handleSubmit, submitting, list, showModal, data,
      actions: {closeModal, recuperarDatosModalTipoAlimentacion}
    } = this.props
    return (
      <Modal show={showModal} onHide={closeModal} aria-labelledby="contained-modal-title-lg" className="modal-tipo-alimentacion" backdrop="static">
      <form className="form-TipoAlimentacion">
        <Modal.Header closeButton={!submitting}>
          <Modal.Title>
          {
            data ? (
              t('PLAN_SANITARIO.TIPO_ALIMENTACION.MODAL.TITULO_EDITAR')
            ) : (
              t('PLAN_SANITARIO.TIPO_ALIMENTACION.MODAL.TITULO_NUEVO')
            )
          }
          </Modal.Title>
          {!submitting && data && data.idTipoAlimentacion && (
            <ButtonChangeRecord list={list} idElemento="idTipoAlimentacion" currentId={data.idTipoAlimentacion} getNextRecord={recuperarDatosModalTipoAlimentacion} />
          )}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Field
              id="tipoAlimentacion"
              name="tipoAlimentacion"
              colSm={12}
              component={InputText}
              controlLabel={t('PLAN_SANITARIO.TIPO_ALIMENTACION.MODAL.NOMBRE_TIPO_ALIMENTACION') + '*'}
              claseActivo="nombre-tipo-alimentacion"
              validate={required}
              maxLength={true}
              valorMaxLength={50}
            />
            <Field
              id="observacionesTipoAlimentacion"
              name="observacionesTipoAlimentacion"
              colSm={12}
              component={InputTextArea}
              componentClass="textarea"
              controlLabel={t('PLAN_SANITARIO.TIPO_ALIMENTACION.MODAL.OBSERVACIONES')}
              claseActivo="observaciones-tipo-alimentacion"
            />
            <Field
              id="activo"
              name="activo"
              colSm={12}
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('PLAN_SANITARIO.TIPO_ALIMENTACION.MODAL.ACTIVO')}
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {
            this.props.data && (
              <Button
                type="button"
                className="btn btn-white btn-sm"
                disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.TIPOS_ALIMENTACION] < 2) || submitting}
                onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPOS_ALIMENTACION] > 1) && !submitting ? handleSubmit(this.duplicar.bind(this)) : undefined}
              >
                {t('PLAN_SANITARIO.TIPO_ALIMENTACION.MODAL.DUPLICAR')}
              </Button>
            )
          }
          {
            !this.props.data && (
              <ButtonLockable
                processing={submitting}
                hasIcon={true}
                showLoaderOnClick={true}
                type="button"
                className="btn btn-primary btn-sm"
                disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.TIPOS_ALIMENTACION] < 2) || submitting}
                onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPOS_ALIMENTACION] > 1) && !submitting) ? handleSubmit(this.guardarYnuevoTipoAlimentacion.bind(this)) : () => {}}
              >
                {t('PLAN_SANITARIO.TIPO_ALIMENTACION.MODAL.GUARDAR_Y_NUEVO')}
              </ButtonLockable>
            )
          }
          <ButtonLockable
            processing={submitting}
            hasIcon={true}
            showLoaderOnClick={true}
            type="button"
            className="btn btn-primary btn-sm"
            disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.TIPOS_ALIMENTACION] < 2) || submitting}
            onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPOS_ALIMENTACION] > 1) && !submitting) ? handleSubmit(this.submitNuevoTipoAlimentacion.bind(this)) : () => {}}
          >
            {t('PLAN_SANITARIO.TIPO_ALIMENTACION.MODAL.GUARDAR')}
          </ButtonLockable>
          <Button
            type="button"
            className={"btn btn-white btn-sm" + (submitting ? ' disabled' : '')}
            onClick={() => !submitting && closeModal()}
          >
            {t('PLAN_SANITARIO.TIPO_ALIMENTACION.MODAL.CANCELAR')}
          </Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalTipoAlimentacion',
})(ModalTipoAlimentacion))