import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { withTranslation } from 'react-i18next'
import { abrirMagavision } from '../../actions/analisisExtracciones/analisisExtracciones'
import { openModalFormSeguimiento, guardarModalFormSeguimiento, closeModalFormSeguimiento, obtenerYGuardarResultadoSistemaAnalisisSeguimiento } from '../../actions/seguimiento/seguimiento'
import {openModalContajeMaquinas} from '../../actions/analisisExtracciones/contajeMaquinas'
import ModalFormSeguimiento from '../../components/seguimiento/ModalFormSeguimiento'

const mapStateToProps = (state) => {
  return {
    ...state.seguimiento.modalFormSeguimiento,
    mainList: state.seguimiento.mainList,
    idAnalisis: state.seguimiento.idAnalisis,
    idPool: state.seguimiento.idPool,
    tipoAnalisisPredeterminado: state.seguimiento.modalFormSeguimiento.data.idTipoAnalisis || state.panelControl.tipoAnalisisPredeterminado,
    auth: state.auth,
    combos: {
      comboConservacion: state.combos.comboConservacion,
      comboTipoControlSeguimiento: state.combos.comboTipoControlSeguimiento,
      comboTipoAnalisisExtraccion: state.combos.comboTipoAnalisisExtraccion
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openModalFormSeguimiento,
      guardarModalFormSeguimiento,
      closeModalFormSeguimiento,
      openModalContajeMaquinas,
      obtenerYGuardarResultadoSistemaAnalisisSeguimiento,
      abrirMagavision
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(ModalFormSeguimiento)))