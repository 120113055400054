import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { getFormValues } from 'redux-form'
import {categorias as categoriasEscandallos} from '../../constants/escandallo'
import { yesNoModal } from '../../actions/common'
import {abrirDetalles, editarDilucion, cerrarDetalles, prepararMaquinaDilucion, cancelarMaquinaDilucion} from '../../actions/dilucion/dilucion'
import {openModalTrazabilidadMaterial} from '../../actions/trazabilidadMaterial/trazabilidadMaterial'
import { comboMaquina, comboOperarioPredeterminado, fetchComboTipoDiluyente } from '../../actions/combos/combos'
import ModalDilucion from '../../components/dilucion/ModalDilucion'

export function mapStateToProps (state) {
  return {
    ...state.dilucion,
    utilizarLotesPreferidosDelUsuario: state.trazabilidadMaterial.utilizarLotesPreferidosDelUsuario,
    stockInsuficiente: state.trazabilidadMaterial.stockInsuficiente[categoriasEscandallos.DILUCION],
    combos: state.combos,
    auth: state.auth,
    formModalDilucion: getFormValues('ModalDilucion')(state)
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      cerrarDetalles,
      abrirDetalles,
      editarDilucion,
      prepararMaquinaDilucion,
      cancelarMaquinaDilucion,
      openModalTrazabilidadMaterial,
      comboMaquina,
      comboOperarioPredeterminado,
      fetchComboTipoDiluyente,
      yesNoModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalDilucion))