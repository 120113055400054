import React, { Component } from 'react'
import { Modal, Row } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { required } from '../../../util/validationFunctions'
import Button from '../../comun/button/Button'
import ButtonChangeRecord from '../../comun/button/ButtonChangeRecord'
import ButtonLockable from '../../comun/button/ButtonLockable'
import InputText from '../../comun/form/InputText'
import InputCheckBox from '../../comun/form/InputCheckBox'
import InputTextArea from '../../comun/form/InputTextArea'
import './TipoIntervencionSanitaria.scss'
import {permisos as permisosConstant} from '../../../constants/permisos'

class ModalTipoIntervencionSanitaria extends Component {
  submitNuevoTipoIntervencionSanitaria (values) {
    return new Promise((resolve, reject) => {
      if (this.props.data){
        this.props.actions.editarTipoIntervencionSanitaria(values, resolve, reject)
      } else {
        this.props.actions.submitNuevoTipoIntervencionSanitaria(values, resolve, reject)
      }
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  guardarYnuevoTipoIntervencionSanitaria(values){
    return new Promise((resolve, reject) => {
      this.props.actions.guardarYnuevoTipoIntervencionSanitaria(values, resolve, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  duplicar(values){
    return new Promise((resolve, reject) => {
      this.props.duplicar(values, resolve, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  render () {
    const {
      t, handleSubmit, submitting, list, showModal, data, duplicar,
      actions: {closeModal, recuperarDatosModalTipoIntervencionSanitaria}
    } = this.props
    return (
      <Modal show={showModal} onHide={closeModal} aria-labelledby="contained-modal-title-lg" className="modal-tipo-intervencion" backdrop="static">
      <form className="form-TipoIntervencionSanitaria">
        <Modal.Header closeButton={!submitting}>
          <Modal.Title>
          {
            data ? (
              t('PLAN_SANITARIO.TIPO_INTERVENCION_SANITARIA.MODAL.TITULO_EDITAR')
            ) : (
              t('PLAN_SANITARIO.TIPO_INTERVENCION_SANITARIA.MODAL.TITULO_NUEVO')
            )
          }
          </Modal.Title>
          {!submitting && data && data.idTipoIntervencion && (
            <ButtonChangeRecord list={list} idElemento="idTipoIntervencion" currentId={data.idTipoIntervencion} getNextRecord={recuperarDatosModalTipoIntervencionSanitaria} />
          )}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Field
              id="tipoIntervencion"
              name="tipoIntervencion"
              colSm={12}
              component={InputText}
              controlLabel={t('PLAN_SANITARIO.TIPO_INTERVENCION_SANITARIA.MODAL.NOMBRE_TIPO_INTERVENCION_SANITARIA') + '*'}
              claseActivo="nombre-tipo-intervencion-sanitaria"
              validate={required}
              maxLength={true}
              valorMaxLength={100}
            />
            <Field
              id="observaciones"
              name="observaciones"
              colSm={12}
              component={InputTextArea}
              componentClass="textarea"
              controlLabel={t('PLAN_SANITARIO.TIPO_INTERVENCION_SANITARIA.MODAL.OBSERVACIONES')}
              claseActivo="observaciones-tipo-intervencion-sanitaria"
            />
            <Field
              id="activo"
              name="activo"
              colSm={12}
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('PLAN_SANITARIO.TIPO_INTERVENCION_SANITARIA.MODAL.ACTIVO')}
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {
            this.props.data && (
              <Button
                type="button"
                className="btn btn-white btn-sm"
                disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.TIPOS_INTERVENCION_SANITARIA] < 2) || submitting}
                onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPOS_INTERVENCION_SANITARIA] > 1) && !submitting ? handleSubmit(this.duplicar.bind(this)) : undefined}
              >
                {t('PLAN_SANITARIO.TIPO_INTERVENCION_SANITARIA.MODAL.DUPLICAR')}
              </Button>
            )
          }
          {
            !this.props.data && (
              <ButtonLockable
                processing={submitting}
                hasIcon={true}
                showLoaderOnClick={true}
                type="button"
                className="btn btn-primary btn-sm"
                disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.TIPOS_INTERVENCION_SANITARIA] < 2) || submitting}
                onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPOS_INTERVENCION_SANITARIA] > 1) && !submitting) ? handleSubmit(this.guardarYnuevoTipoIntervencionSanitaria.bind(this)) : () => {}}
              >
                {t('PLAN_SANITARIO.TIPO_INTERVENCION_SANITARIA.MODAL.GUARDAR_Y_NUEVO')}
              </ButtonLockable>
            )
          }
          <ButtonLockable
            processing={submitting}
            hasIcon={true}
            showLoaderOnClick={true}
            type="button"
            className="btn btn-primary btn-sm"
            disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.TIPOS_INTERVENCION_SANITARIA] < 2) || submitting}
            onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPOS_INTERVENCION_SANITARIA] > 1) && !submitting) ? handleSubmit(this.submitNuevoTipoIntervencionSanitaria.bind(this)) : () => {}}
          >
            {t('PLAN_SANITARIO.TIPO_INTERVENCION_SANITARIA.MODAL.GUARDAR')}
          </ButtonLockable>
          <Button
            type="button"
            className={"btn btn-white btn-sm" + (submitting ? ' disabled' : '')}
            onClick={() => !submitting && closeModal()}
          >
            {t('PLAN_SANITARIO.TIPO_INTERVENCION_SANITARIA.MODAL.CANCELAR')}
          </Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalTipoIntervencionSanitaria',
})(ModalTipoIntervencionSanitaria))