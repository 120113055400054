import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import {fetchPoolExtracciones, onDeletePoolExtraccion} from '../../actions/poolExtracciones/poolExtracciones'
import PoolExtracciones from '../../components/poolExtracciones/PoolExtracciones'

export function mapStateToProps (state) {
  return {
    ...state.poolExtracciones,
    auth: state.auth,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchPoolExtracciones,
      onDeletePoolExtraccion
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PoolExtracciones))