import actionTypes from '../../constants/actions/cliente/cliente'

export function abrirModalCliente () {
  return {
    type: actionTypes.ABRIR_MODAL_CLIENTE
  }
}

export function closeModalCliente () {
  return {
    type: actionTypes.CLOSE_MODAL_CLIENTE
  }
}

export function submitNuevoCliente (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.SUBMIT_NUEVO_CLIENTE,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function submitNuevoClienteSuccess (listCliente) {
  return {
    type: actionTypes.SUBMIT_NUEVO_CLIENTE_SUCCESS,
    listCliente
  }
}

export function obtenerClienteAlmacen () {
  return {
    type: actionTypes.OBTENER_CLIENTES_ALMACEN
  }
}

export function filtrarCliente (values) {
  return {
    type: actionTypes.FILTRAR_CLIENTE,
    values
  }
}

export function filtrarClienteSuccess (listCliente) {
  return {
    type: actionTypes.FILTRAR_CLIENTE_SUCCESS,
    listCliente
  }
}

export function filtrosClienteSuccess (filtros) {
  return {
    type: actionTypes.FILTROS_CLIENTE_SUCCESS,
    filtros
  }
}

export function recuperarDatosModalCliente (idCliente) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_CLIENTE,
    idCliente
  }
}

export function recuperarDatosModalClienteSuccess (data) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_CLIENTE_SUCCESS,
    data
  }
}

export function editarCliente (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.EDITAR_CLIENTE,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function duplicarDatosModalCliente (idCliente) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_CLIENTE,
    idCliente
  }
}

export function duplicarDatosModalClienteSuccess (dataDuplicar) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_CLIENTE_SUCCESS,
    dataDuplicar
  }
}

export function cambiarEstadoCliente (idCliente) {
  return {
    type: actionTypes.CAMBIAR_ESTADO_CLIENTE,
    idCliente
  }
}

export function onDeleteCliente (idCliente, razonSocialCliente) {
  return {
    type: actionTypes.ON_DELETE_CLIENTE,
    idCliente,
    razonSocialCliente
  }
}

export function seleccionarTodosCliente () {
  return {
    type: actionTypes.SELECCIONAR_TODOS_CLIENTE
  }
}

export function deSeleccionarTodosCliente () {
  return {
    type: actionTypes.DESELECCIONAR_TODOS_CLIENTE
  }
}

export function cambiarEstadoSeleccionCliente (list) {
  return {
    type: actionTypes.CAMBIAR_ESTADO_SELECCION_CLIENTE,
    list
  }
}

export function deleteSeleccionCliente (list) {
  return {
    type: actionTypes.DELETE_SELECCION_CLIENTE,
    list
  }
}

export function crearCsvCliente(list){
  return {
    type: actionTypes.CREATE_CSV_CLIENTE,
    list
  }
}

export function crearCsvClienteSuccess(datosExcelCliente){
  return {
    type: actionTypes.CREATE_CSV_CLIENTE_SUCCESS,
    datosExcelCliente
  }
}

export function imprimirPdfCliente(list){
  return {
    type: actionTypes.IMPRIMIR_PDF_CLIENTE,
    list
  }
}

export function imprimirPdfClienteSuccess(datosPdfCliente){
  return {
    type: actionTypes.IMPRIMIR_PDF_CLIENTE_SUCCESS,
    datosPdfCliente
  }
}

export function andadirClienteDilucion (clienteDilucion) {
  return {
    type: actionTypes.ANADIR_CLIENTE_DILUCION,
    clienteDilucion
  }
}

export function andadirClienteEnvasado (clienteEnvasado) {
  return {
    type: actionTypes.ANADIR_CLIENTE_ENVASADO,
    clienteEnvasado
  }
}

export function abrirModalDireccionesCliente () {
  return {
    type: actionTypes.ABRIR_MODAL_DIRECCIONES_CLIENTE,
  }
}

export function closeModallDireccionesCliente () {
  return {
    type: actionTypes.CLOSE_MODAL_DIRECCIONES_CLIENTE
  }
}

export function guardarYnuevoCliente (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.GUARDAR_Y_NUEVO_CLIENTE,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function guardarYnuevoClienteSuccess () {
  return {
    type: actionTypes.GUARDAR_Y_NUEVO_CLIENTE_SUCCESS
  }
}

export function closeModalDireccionesCliente () {
  return {
    type: actionTypes.CLOSE_MODAL_DIRECCIONES_CLIENTE
  }
}

export function editarDireccionCliente (values) {
  return {
    type: actionTypes.EDITAR_DIRECCION_CLIENTE,
    values
  }
}

export function editarDireccionClienteSuccess (listDireccionesCliente) {
  return {
    type: actionTypes.EDITAR_DIRECCION_CLIENTE_SUCCESS,
    listDireccionesCliente
  }
}

export function submitDireccionCliente (values) {
  return {
    type: actionTypes.SUBMIT_DIRECCION_CLIENTE,
    values
  }
}

export function recuperarDatosModalDireccionCliente (idElemento) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_DIRECCION_CLIENTE,
    idElemento
  }
}

export function recuperarDatosModalDireccionClienteSuccess (dataDirecciones) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_DIRECCION_CLIENTE_SUCCESS,
    dataDirecciones
  }
}

export function duplicarDatosModalDireccionCliente (idElemento) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_DIRECCION_CLIENTE,
    idElemento
  }
}

export function duplicarDatosModalDireccionClienteSuccess (dataDuplicarDirecciones) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_DIRECCION_CLIENTE_SUCCESS,
    dataDuplicarDirecciones
  }
}

export function onDeleteDireccionCliente (idElemento) {
  return {
    type: actionTypes.ON_DELETE_DIRECCION_CLIENTE,
    idElemento
  }
}

export function seleccionarTodosDireccionesCliente () {
  return {
    type: actionTypes.SELECCIONAR_TODOS_DIRECCIONES_CLIENTE
  }
}

export function deSeleccionarTodosDireccionesCliente () {
  return {
    type: actionTypes.DESELECCIONAR_TODOS_DIRECCIONES_CLIENTE
  }
}

export function deleteSeleccionDireccionCliente (listDireccionesCliente) {
  return {
    type: actionTypes.DELETE_SELECCION_DIRECCION_CLIENTE,
    listDireccionesCliente
  }
}

export function cargarPedidosCliente (listPedidosCliente) {
  return {
    type: actionTypes.CARGAR_PEDIDOS_CLIENTE,
    listPedidosCliente
  }
}

export function abrirModalDireccionesRuta () {
  return {
    type: actionTypes.ABRIR_MODAL_DIRECCONES_RUTA
  }
}

export function cerrarModalDireccionesRuta () {
  return {
    type: actionTypes.CERRAR_MODAL_DIRECCONES_RUTA
  }
}

export function anadirDatosRuta (idRuta) {
  return {
    type: actionTypes.ANADIR_DATOS_RUTA,
    idRuta
  }
}

export function anadirDatosRutaSuccess (listRutas) {
  return {
    type: actionTypes.ANADIR_DATOS_RUTA_SUCCESS,
    listRutas
  }
}

export function onDeleteRutaCliente (idElemento) {
  return {
    type: actionTypes.ON_DELETE_RUTA_CLIENTE,
    idElemento
  }
}

export function actualizarDatosRuta (listRutas) {
  return {
    type: actionTypes.ACTUALIZAR_DATOS_RUTA,
    listRutas
  }
}

export function comboRutasNoDireccionCliente (list) {
  return {
    type: actionTypes.COMBO_RUTAS_NO_DIRECCIONES_CLIENTE,
    list
  }
}

export function comboRutasNoDireccionClienteSuccess (data) {
  return {
    type: actionTypes.COMBO_RUTAS_NO_DIRECCIONES_CLIENTE_SUCCESS,
    data
  }
}

export function cargarAlbaranCliente (listAlbaranCliente) {
  return {
    type: actionTypes.CARGAR_ALBARAN_CLIENTE,
    listAlbaranCliente
  }
}

export function cargarDosisEnviadasCliente (listDosisEnviadasCliente) {
  return {
    type: actionTypes.CARGAR_DOSIS_ENVIADAS_CLIENTE,
    listDosisEnviadasCliente
  }
}

export function seleccionarTodosModalDireccionesRuta () {
  return {
    type: actionTypes.SELECCIONAR_TODOS_MODAL_DIRECCIONES_RUTA
  }
}

export function deSeleccionarTodosModalDireccionesRuta () {
  return {
    type: actionTypes.DESELECCIONAR_TODOS_MODAL_DIRECCIONES_RUTA
  }
}

export default {
  closeModalCliente,
  abrirModalCliente,
  submitNuevoCliente,
  submitNuevoClienteSuccess,
  obtenerClienteAlmacen,
  filtrarCliente,
  filtrarClienteSuccess,
  filtrosClienteSuccess,
  recuperarDatosModalCliente,
  recuperarDatosModalClienteSuccess,
  editarCliente,
  duplicarDatosModalCliente,
  duplicarDatosModalClienteSuccess,
  cambiarEstadoCliente,
  onDeleteCliente,
  seleccionarTodosCliente,
  deSeleccionarTodosCliente,
  cambiarEstadoSeleccionCliente,
  deleteSeleccionCliente,
  crearCsvCliente,
  imprimirPdfCliente,
  crearCsvCliente,
  crearCsvClienteSuccess,
  imprimirPdfCliente,
  imprimirPdfClienteSuccess,
  andadirClienteDilucion,
  andadirClienteEnvasado,
  abrirModalDireccionesCliente,
  closeModalDireccionesCliente,
  guardarYnuevoCliente,
  guardarYnuevoClienteSuccess,
  closeModalDireccionesCliente,
  editarDireccionCliente,
  submitDireccionCliente,
  recuperarDatosModalDireccionCliente,
  recuperarDatosModalDireccionClienteSuccess,
  editarDireccionClienteSuccess,
  duplicarDatosModalDireccionCliente,
  duplicarDatosModalDireccionClienteSuccess,
  onDeleteDireccionCliente,
  seleccionarTodosDireccionesCliente,
  deSeleccionarTodosDireccionesCliente,
  deleteSeleccionDireccionCliente,
  cargarPedidosCliente,
  cerrarModalDireccionesRuta,
  abrirModalDireccionesRuta,
  cerrarModalDireccionesRuta,
  anadirDatosRuta,
  anadirDatosRutaSuccess,
  onDeleteRutaCliente,
  actualizarDatosRuta,
  comboRutasNoDireccionCliente,
  comboRutasNoDireccionClienteSuccess,
  cargarAlbaranCliente,
  cargarDosisEnviadasCliente,
  seleccionarTodosModalDireccionesRuta,
  deSeleccionarTodosModalDireccionesRuta
}