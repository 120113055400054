import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Modal, Row, Col } from 'react-bootstrap'
import { Warning } from '@material-ui/icons'
import Button from '../comun/button/Button'
import ButtonLockable from '../comun/button/ButtonLockable'
import ButtonChangeRecord from '../comun/button/ButtonChangeRecord'
import InputText from '../comun/form/InputText'
import InputDatePicker from '../comun/form/InputDatePicker'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import FiltrosEnvasadoMultiDosisTablePage from '../../containers/envasado/FiltrosEnvasadoMultiDosisTablePage'
import ModalEnvasadoMultiDosisPage from '../../containers/envasado/ModalEnvasadoMultiDosisPage'
import {number_formatter} from '../../util/formatFunctions'
import {permisos as permisosConstant} from '../../constants/permisos'

class ModalEnvasado extends Component {
  constructor () {
    super()
    this.state = {
      totalDisponibleDispar: false
    }
    this.editarEnvasado = this.editarEnvasado.bind(this)
  }

  editarEnvasado (values) {
    return new Promise((resolve, reject) => {
      this.props.actions.editarEnvasado(values, resolve, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  componentDidUpdate (prevProps) {
    if (this.props.data.totalDisponible !== this.props.data.totalUtilizado && !this.state.totalDisponibleDispar) {
      this.setState({ totalDisponibleDispar: true })
    } else if (this.props.data.totalDisponible === this.props.data.totalUtilizado && this.state.totalDisponibleDispar) {
      this.setState({ totalDisponibleDispar: false })
    }
  }

  render () {
    const {
      t, paginaActual, numeroRegistrosPorPagina, handleSubmit, submitting, showModalEnvasado, checkedFiltrosModalEnvasado,
      auth: {separadorMil, separadorDec}, list, data: {idEnvasado, multiDosis = [], totalDisponible, totalUtilizado},
      actions: {abrirDetallesEnvasado, cerrarDetallesEnvasadoSuccess, deleteEnvasadoMultiDosis, abrirModalEnvasadoMultiDosis}
    } = this.props
    const tKey = 'EXTRACCIONES.ENVASADO.MODAL.'

    const idElemento = 'idEnvasadoMultiDosis'
    const idForm = 'envasadoMultiDosisTable'

    const tableEnvasadoMultiDosis = {
      id: 'envasadoMultiDosisTable',
      form: idForm,
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ENVASADO] > 1) && !submitting,
      idElemento,
      hasOptions: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ENVASADO] > 1) && !submitting,
      noBlockOptions: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ENVASADO] > 1),
      hasPagination: true,
      mostrarModificar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ENVASADO] > 1),
      onEdit: (row) => { abrirModalEnvasadoMultiDosis(row) },
      mostrarDuplicar: false,
      mostrarEliminar: true,
      onDelete: (row) => { deleteEnvasadoMultiDosis(row) },
      mostrarCambioEstado: false,
      columns: [
        {id: 'tipoDosis', name: t(tKey + 'COLUMNS.TIPO_DOSIS')},
        {id: 'numeroDosis', name: t(tKey + 'COLUMNS.NUMERO_DOSIS'), type: 'numero', numDecimales: 0},
        {id: 'volumen', name: t(tKey + 'COLUMNS.VOLUMEN'), type: 'numero'},
        {id: 'nombreMaquina', name: t(tKey + 'COLUMNS.MAQUINA')}
      ],
      rows: multiDosis,
      filtros: !submitting ? <FiltrosEnvasadoMultiDosisTablePage idElemento={idElemento} idForm={idForm} /> : null,
      leyenda: [
        {name: t('EXTRACCIONES.ENVASADO.LEYENDA.ENVASADO_ELIMINADO'), color: '#c4415d'},
        {name: t('EXTRACCIONES.ENVASADO.LEYENDA.ENVASADO'), color: '#aacfdc'}
      ],
      initialValues: {},
      colorearRow: (row) => {
        let classRow = ''
        if (row.envasadoEliminado){
          classRow = 'envasado-eliminado'
        } else if (row.envasado) {
          classRow = 'envasado'
        }
        return classRow
      }
    }
    tableEnvasadoMultiDosis.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableEnvasadoMultiDosis.initialValues['check' + idForm + row[idElemento]] = checkedFiltrosModalEnvasado
        }
      }
    )


    // TODO Optimizar, quitando este codigo del render. Quizas almacenando el array filtrado al obtener el propio listado.
    let listadoFiltrado = []
    list.forEach((row) => {
      let estaAlmacenado = listadoFiltrado.findIndex((rowFiltrado) => {
        return rowFiltrado.idEnvasado === row.idEnvasado
      })
      if (estaAlmacenado === -1) {
        listadoFiltrado.push(row)
      }
    })

    return (
      <Modal show={showModalEnvasado} onHide={cerrarDetallesEnvasadoSuccess} aria-labelledby="contained-modal-title-lg" backdrop="static" bsSize="large">
      <form className="form-envasado" onSubmit={handleSubmit(this.editarEnvasado)}>
        <Modal.Header closeButton={!submitting}>
          <Modal.Title>
              {t(tKey + 'TITLE')}
          </Modal.Title>
          {!submitting && idEnvasado && (
            <ButtonChangeRecord list={listadoFiltrado} idElemento="idEnvasado" currentId={idEnvasado} getNextRecord={(idEnvasado) => {abrirDetallesEnvasado(idEnvasado)}} />
          )}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Field
              colSm={4}
              id="codVerracoPool"
              name="codVerracoPool"
              controlLabel={t(tKey + 'FORM.VERRACO_POOL')}
              component={InputText}
              disabled={true}
            />
            <Field
              colSm={4}
              id="nombreRaza"
              name="nombreRaza"
              controlLabel={t(tKey + 'FORM.RAZA')}
              component={InputText}
              disabled={true}
            />
            <Field
              colSm={4}
              id="nombreLinea"
              name="nombreLinea"
              controlLabel={t(tKey + 'FORM.LINEA_GENETICA')}
              component={InputText}
              disabled={true}
            />
          </Row>
          <Row>
            <Field
              colSm={4}
              id="fechaCaducidad"
              name="fechaCaducidad"
              controlLabel={t(tKey + 'FORM.FECHA_CADUCIDAD')}
              component={InputDatePicker}
              disabled={true}
            />
            <Field
              colSm={4}
              id="fechaExtraccion"
              name="fechaExtraccion"
              controlLabel={t(tKey + 'FORM.FECHA_EXTRACCION')}
              component={InputDatePicker}
              disabled={true}
            />
          </Row>
          <hr />
          <Row className="tablaTotales" style={{ margin: '0 0 10px 0'}}>
            <Col sm={6} className="estados-total-verraco">
              <span>{t(tKey + 'TOTALES.DISPONIBLE')}: {number_formatter(totalDisponible, 2, separadorDec, separadorMil)} ml</span>
            </Col>
            <Col sm={6} className={"estados-total-verraco" + (this.state.totalDisponibleDispar ? ' has-error' : '')}>
              {this.state.totalDisponibleDispar && (<span className='help-block'><Warning /></span>)}
              <span>{t(tKey + 'TOTALES.UTILIZADO')}: {number_formatter(totalUtilizado, 2, separadorDec, separadorMil)} ml</span>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <ModalEnvasadoMultiDosisPage />
              <SimpleTablePage {...tableEnvasadoMultiDosis}></SimpleTablePage>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <ButtonLockable
            processing={submitting}
            hasIcon={true}
            type="submit"
            className="btn btn-primary"
            disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.ENVASADO] < 2) || submitting}
          >
            {t(tKey + 'BUTTONS.GUARDAR')}
          </ButtonLockable>
          <Button type="button" className="btn btn-white" disabled={submitting} onClick={cerrarDetallesEnvasadoSuccess}>{t(tKey + 'BUTTONS.CANCELAR')}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalEnvasado',
})(ModalEnvasado))