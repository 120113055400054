import actionTypes from '../../constants/actions/cliente/cliente'

export function initialState () {
  return {
    listCliente: [],
    checkedFiltrosCliente: false,
    showModalCliente: false,
    filtros: {},
    dataCliente: [],
    dataDuplicarCliente: [],
    datosExcelCliente: [],
    datosPdfCliente: [],
    showModalAnadirCliente: false,
    selectorProceso: '',
    data: [],
    dataDuplicar: [],
    listDireccionesCliente: [],
    showModalDireccionesCliente: false,
    dataDirecciones: [],
    dataDuplicarDirecciones: [],
    checkedFiltrosClienteAnadidos: false,
    listPedidosCliente: [],
    listAlbaranCliente: [],
    listDosisEnviadasCliente: [],
    showModalAnadirRuta: false,
    listRutas: [],
    comboRutasNoDireccionCliente: [],
    checkedFiltrosdDireccionesRuta: false
  }
}

export function abrirModalCliente (state) {
  return {
    ...state,
    showModalCliente: true,
    data: [],
    dataDuplicar: []
  }
}

export function closeModalCliente (state) {
  return {
    ...state,
    showModalCliente: false,
    dataCliente: [],
    dataDuplicarCliente: [],
    data: [],
    dataDuplicar: [],
    listDireccionesCliente: [],
    listPedidosCliente: [],
    listAlbaranCliente: [],
    listDosisEnviadasCliente: []
  }
}

export function filtrarClienteSuccess (state, {listCliente}) {
  return {
    ...state,
    listCliente: listCliente
  }
}

export function filtrosClienteSuccess (state, {filtros}) {
  return {
    ...state,
    filtros: filtros
  }
}

export function recuperarDatosModalCliente (state, {data}) {
  if (data.direcciones && data.direcciones.rutas) {
    let rutas = data.direcciones.rutas
    let textoRutas = ''
    for (let i = 0; i < Object.keys(rutas).length; i++) {
      textoRutas = textoRutas + rutas[i].nombreRuta + ", "
    }
    data.direcciones.rutasDireccionCliente = textoRutas.slice(0, -2)
  }
  return {
    ...state,
    data: data,
    showModalCliente: true,
    listDireccionesCliente: data.direcciones,
    dataDuplicar: []
  }
}

export function duplicarDatosModalClienteSuccess (state, {dataDuplicar}) {
  return {
    ...state,
    dataDuplicar: dataDuplicar,
    showModalCliente: true,
    data: [],
    listDireccionesCliente: []
  }
}

export function seleccionarTodosCliente (state) {
  return {
    ...state,
    checkedFiltrosCliente: true
  }
}

export function deSeleccionarTodosCliente (state) {
  return {
    ...state,
    checkedFiltrosCliente: false
  }
}

export function crearCsvClienteSuccess (state, {datosExcelCliente}) {
  return {
    ...state,
    datosExcelCliente
  }
}

export function imprimirPdfClienteSuccess (state, {datosPdfCliente}) {
  return {
    ...state,
    datosPdfCliente
  }
}

export function abrirModalDireccionesCliente (state) {
  return {
    ...state,
    showModalDireccionesCliente: true
  }
}

export function closeModalDireccionesCliente (state) {
  return {
    ...state,
    showModalDireccionesCliente: false,
    dataDirecciones: [],
    dataDuplicarDirecciones: [],
    listRutas: []
  }
}

export function guardarYnuevoClienteSuccess (state) {
  return {
    ...state,
    dataCliente: [],
    dataDuplicarCliente: [],
    data: [],
    dataDuplicar: []
  }
}

export function submitDireccionCliente (state, {values}) {
  if (values.rutas) {
    let textoRutas = ''
    let textoTransportistas = ''
    let idRuta
    for (let i = 0; i < Object.keys(values.rutas).length; i++) {
      if (idRuta !== values.rutas[i].idRuta) {
        idRuta = values.rutas[i].idRuta
        textoRutas = textoRutas + values.rutas[i].nombreRuta + ", "
      }
      textoTransportistas = textoTransportistas + values.rutas[i].transportista + ", "
    }
    values.rutasDireccionCliente = textoRutas.slice(0, -2)
    values.transportista = textoTransportistas.slice(0, -2)
  }
  return {
    ...state,
    listDireccionesCliente: state.listDireccionesCliente.concat(values),
    showModalDireccionesCliente: false,
    dataDirecciones: [],
    dataDuplicarDirecciones: []
  }
}

export function recuperarDatosModalDireccionClienteSuccess (state, {dataDirecciones}) {
  return {
    ...state,
    dataDirecciones: dataDirecciones,
    dataDuplicarDirecciones: []
  }
}

export function editarDireccionClienteSuccess (state, {listDireccionesCliente}) {
  return {
    ...state,
    listDireccionesCliente: listDireccionesCliente,
    dataDirecciones: [],
    dataDuplicarDirecciones: []
  }
}

export function duplicarDatosModalDireccionClienteSuccess (state, {dataDuplicarDirecciones}) {
  return {
    ...state,
    dataDuplicarDirecciones: dataDuplicarDirecciones,
    dataDirecciones: []
  }
}

export function seleccionarTodosDireccionesCliente (state) {
  return {
    ...state,
    checkedFiltrosClienteAnadidos: true
  }
}

export function deSeleccionarTodosDireccionesCliente (state) {
  return {
    ...state,
    checkedFiltrosClienteAnadidos: false
  }
}

export function deleteSeleccionDireccionCliente (state, {listDireccionesCliente}) {
  const direccionesCliente = listDireccionesCliente.map((direccion) => {
        let textoRutas = ''
        let textoTransportistas = ''
        let idRuta
        for (let i = 0; i < Object.keys(direccion.rutas).length; i++) {
          if (idRuta !== direccion.rutas[i].idRuta) {
            idRuta = direccion.rutas[i].idRuta
            textoRutas = textoRutas + direccion.rutas[i].nombreRuta + ", "
          }
          textoTransportistas = textoTransportistas + direccion.rutas[i].transportista + ", "
        }
        direccion.transportista = textoTransportistas.slice(0, -2)
        direccion.rutasDireccionCliente = textoRutas.slice(0, -2)
      return direccion
    })
  return {
    ...state,
    listDireccionesCliente: direccionesCliente,
    checkedFiltrosClienteAnadidos: false
  }
}

export function cargarPedidosCliente (state, {listPedidosCliente}) {
  return {
    ...state,
    listPedidosCliente: listPedidosCliente
  }
}

export function cargarAlbaranCliente (state, {listAlbaranCliente}) {
  return {
    ...state,
    listAlbaranCliente: listAlbaranCliente
  }
}

export function cargarDosisEnviadasCliente (state, {listDosisEnviadasCliente}) {
  return {
    ...state,
    listDosisEnviadasCliente: listDosisEnviadasCliente
  }
}

export function abrirModalDireccionesRuta (state) {
  return {
    ...state,
    showModalAnadirRuta: true
  }
}

export function cerrarModalDireccionesRuta (state) {
  return {
    ...state,
    showModalAnadirRuta: false
  }
}

export function anadirDatosRutaSuccess (state, {listRutas}) {
  return {
    ...state,
    listRutas: state.listRutas.concat(listRutas),
    showModalAnadirRuta: false
  }
}

export function actualizarDatosRuta (state, {listRutas}) {
  return {
    ...state,
    listRutas: listRutas
  }
}

export function comboRutasNoDireccionClienteSuccess (state, {data}) {
  return {
    ...state,
    comboRutasNoDireccionCliente: data
  }
}

export function seleccionarTodosModalDireccionesRuta (state, {data}) {
  return {
    ...state,
    checkedFiltrosdDireccionesRuta: true
  }
}

export function deSeleccionarTodosModalDireccionesRuta (state, {data}) {
  return {
    ...state,
    checkedFiltrosdDireccionesRuta: false
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.ABRIR_MODAL_CLIENTE:
      return abrirModalCliente(state, action)
    case actionTypes.CLOSE_MODAL_CLIENTE:
      return closeModalCliente(state, action)
    case actionTypes.FILTRAR_CLIENTE_SUCCESS:
      return filtrarClienteSuccess(state, action)
    case actionTypes.FILTROS_CLIENTE_SUCCESS:
      return filtrosClienteSuccess(state, action)
    case actionTypes.RECUPERAR_DATOS_MODAL_CLIENTE_SUCCESS:
      return recuperarDatosModalCliente(state, action)
    case actionTypes.DUPLICAR_DATOS_MODAL_CLIENTE_SUCCESS:
      return duplicarDatosModalClienteSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_CLIENTE:
      return seleccionarTodosCliente(state, action)
    case actionTypes.DESELECCIONAR_TODOS_CLIENTE:
      return deSeleccionarTodosCliente(state, action)
    case actionTypes.CREATE_CSV_CLIENTE_SUCCESS:
      return crearCsvClienteSuccess(state, action)
    case actionTypes.IMPRIMIR_PDF_CLIENTE_SUCCESS:
      return imprimirPdfClienteSuccess(state, action)
    case actionTypes.ABRIR_MODAL_DIRECCIONES_CLIENTE:
      return abrirModalDireccionesCliente(state, action)
    case actionTypes.CLOSE_MODAL_DIRECCIONES_CLIENTE:
      return closeModalDireccionesCliente(state, action)
    case actionTypes.GUARDAR_Y_NUEVO_CLIENTE_SUCCESS:
      return guardarYnuevoClienteSuccess(state, action)
    case actionTypes.SUBMIT_DIRECCION_CLIENTE:
      return submitDireccionCliente(state, action)
    case actionTypes.RECUPERAR_DATOS_MODAL_DIRECCION_CLIENTE_SUCCESS:
      return recuperarDatosModalDireccionClienteSuccess(state, action)
    case actionTypes.EDITAR_DIRECCION_CLIENTE_SUCCESS:
      return editarDireccionClienteSuccess(state, action)
    case actionTypes.DUPLICAR_DATOS_MODAL_DIRECCION_CLIENTE_SUCCESS:
      return duplicarDatosModalDireccionClienteSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_DIRECCIONES_CLIENTE:
      return seleccionarTodosDireccionesCliente(state, action)
    case actionTypes.DESELECCIONAR_TODOS_DIRECCIONES_CLIENTE:
      return deSeleccionarTodosDireccionesCliente(state, action)
    case actionTypes.DELETE_SELECCION_DIRECCION_CLIENTE:
      return deleteSeleccionDireccionCliente(state, action)
    case actionTypes.CARGAR_PEDIDOS_CLIENTE:
      return cargarPedidosCliente(state, action)
    case actionTypes.CARGAR_ALBARAN_CLIENTE:
      return cargarAlbaranCliente(state, action)
    case actionTypes.CARGAR_DOSIS_ENVIADAS_CLIENTE:
      return cargarDosisEnviadasCliente(state, action)
    case actionTypes.ABRIR_MODAL_DIRECCONES_RUTA:
      return abrirModalDireccionesRuta(state, action)
    case actionTypes.CERRAR_MODAL_DIRECCONES_RUTA:
      return cerrarModalDireccionesRuta(state, action)
    case actionTypes.ANADIR_DATOS_RUTA_SUCCESS:
      return anadirDatosRutaSuccess(state, action)
    case actionTypes.ACTUALIZAR_DATOS_RUTA:
      return actualizarDatosRuta(state, action)
    case actionTypes.COMBO_RUTAS_NO_DIRECCIONES_CLIENTE_SUCCESS:
      return comboRutasNoDireccionClienteSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_MODAL_DIRECCIONES_RUTA:
      return seleccionarTodosModalDireccionesRuta(state, action)
    case actionTypes.DESELECCIONAR_TODOS_MODAL_DIRECCIONES_RUTA:
      return deSeleccionarTodosModalDireccionesRuta(state, action)
    default:
      return state
  }
}