import actionTypes from '../../constants/actions/copiaSeguridad/copiaSeguridad'

export function fetchCopiasSeguridad () {
  return {
    type: actionTypes.FETCH_COPIAS_SEGURIDAD
  }
}

export function fetchCopiasSeguridadSuccess (listCopiaSeguridad) {
  return {
    type: actionTypes.FETCH_COPIAS_SEGURIDAD_SUCCESS,
    listCopiaSeguridad
  }
}

export function abrirModalCopiaSeguridad () {
  return {
    type: actionTypes.ABRIR_MODAL_COPIA_SEGURIDAD
  }
}

export function cerrarModalCopiaSeguridad () {
  return {
    type: actionTypes.CERRAR_MODAL_COPIA_SEGURIDAD
  }
}

export function submitNuevaCopiaSeguridad (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.SUBMIT_NUEVA_COPIA_SEGURIDAD,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function descargarCopiaSeguridad (idCopiaSeguridad) {
  return {
    type: actionTypes.DESCARGAR_COPIA_SEGURIDAD,
    idCopiaSeguridad
  }
}

export function descargarCopiaSeguridadSuccess (archivoCopiaSeguridad) {
  return {
    type: actionTypes.DESCARGAR_COPIA_SEGURIDAD_SUCCESS,
    archivoCopiaSeguridad
  }
}

export function onDeleteCopiaSeguridad (idCopiaSeguridad) {
  return {
    type: actionTypes.ON_DELETE_COPIA_SEGURIDAD,
    idCopiaSeguridad
  }
}

export function seleccionarTodosCopiaSeguridad () {
  return {
    type: actionTypes.SELECCIONAR_TODOS_COPIA_SEGURIDAD
  }
}

export function deSeleccionarTodosCopiaSeguridad () {
  return {
    type: actionTypes.DESELECCIONAR_TODOS_COPIA_SEGURIDAD
  }
}

export function deleteSeleccionCopiaSeguridad (list) {
  return {
    type: actionTypes.DELETE_SELECCION_COPIA_SEGURIDAD,
    list
  }
}

export function restaurarCopiaSeguridad (idCopiaSeguridad) {
  return {
    type: actionTypes.RESTAURAR_COPIA_SEGURIDAD,
    idCopiaSeguridad
  }
}

export function abrirModalCopiaSeguridadProgramada () {
  return {
    type: actionTypes.ABRIR_MODAL_COPIA_SEGURIDAD_PROGRAMADA
  }
}

export function cerrarModalCopiaSeguridadProgramada () {
  return {
    type: actionTypes.CERRAR_MODAL_COPIA_SEGURIDAD_PROGRAMADA
  }
}

export function obtenerProgramacionCopiaSeguridad () {
  return {
    type: actionTypes.OBTENER_PROGRAMACION_COPIA_SEGURIDAD
  }
}

export function obtenerProgramacionCopiaSeguridadSuccess (copiaProgramada) {
  return {
    type: actionTypes.OBTENER_PROGRAMACION_COPIA_SEGURIDAD_SUCCESS,
    copiaProgramada
  }
}

export function submitNuevaCopiaSeguridadProgramada (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.SUBMIT_NUEVA_COPIA_SEGURIDAD_PROGRAMADA,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function abrirModalRestaurarConArchivo () {
  return {
    type: actionTypes.ABRIR_MODAL_RESTAURAR_CON_ARCHIVO
  }
}

export function cerrarModalRestaurarConArchivo () {
  return {
    type: actionTypes.CERRAR_MODAL_RESTAURAR_CON_ARCHIVO
  }
}

export function subirArchivosCopiaSeguridad (archivos) {
  return {
    type: actionTypes.SUBIR_ARCHIVOS_COPIA_SEGURIDAD,
    archivos
  }
}

export function submitCopiaSeguridadDesdeArchivo (archivos) {
  return {
    type: actionTypes.SUBMIT_COPIA_SEGURIDAD_DESDE_ARCHIVO,
    archivos
  }
}

export function abrirModalRestaurarMigracion () {
  return {
    type: actionTypes.ABRIR_MODAL_RESTAURAR_MIGRACION
  }
}

export function cerrarModalRestaurarMigracion () {
  return {
    type: actionTypes.CERRAR_MODAL_RESTAURAR_MIGRACION
  }
}

export function restaurarMigracion (archivos) {
  return {
    type: actionTypes.RESTAURAR_MIGRACION,
    archivos
  }
}

export function submitRestaurarMigracion (archivos) {
  return {
    type: actionTypes.SUBMIT_RESTAURAR_MIGRACION,
    archivos
  }
}


export default {
  fetchCopiasSeguridad,
  fetchCopiasSeguridadSuccess,
  abrirModalCopiaSeguridad,
  cerrarModalCopiaSeguridad,
  submitNuevaCopiaSeguridad,
  descargarCopiaSeguridad,
  descargarCopiaSeguridadSuccess,
  onDeleteCopiaSeguridad,
  seleccionarTodosCopiaSeguridad,
  deSeleccionarTodosCopiaSeguridad,
  deleteSeleccionCopiaSeguridad,
  restaurarCopiaSeguridad,
  abrirModalCopiaSeguridadProgramada,
  cerrarModalCopiaSeguridadProgramada,
  obtenerProgramacionCopiaSeguridad,
  obtenerProgramacionCopiaSeguridadSuccess,
  submitNuevaCopiaSeguridadProgramada,
  abrirModalRestaurarConArchivo,
  cerrarModalRestaurarConArchivo,
  subirArchivosCopiaSeguridad,
  submitCopiaSeguridadDesdeArchivo,
  submitRestaurarMigracion,
  restaurarMigracion,
  cerrarModalRestaurarMigracion,
  abrirModalRestaurarMigracion
}