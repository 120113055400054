import React from 'react'
import {estadoVerraco as estadoVerracoConstants} from '../../../constants/estadoVerraco'
import i18n from '../../../i18n'

export const estadoVerraco = ({estadoverraco}) => {
  switch(estadoverraco) {
    case estadoVerracoConstants.CUARENTENA:
      return <span className='estado-cuarentena'>{i18n.t('FICHA_VERRACO.INICIAL_ESTADO_VERRACO.C')}</span>;
    case estadoVerracoConstants.PRESENTADO:
      return <span className='estado-presentado'>{i18n.t('FICHA_VERRACO.INICIAL_ESTADO_VERRACO.P')}</span>;
    case estadoVerracoConstants.ACTIVO:
      return <span className='estado-activo'>{i18n.t('FICHA_VERRACO.INICIAL_ESTADO_VERRACO.A')}</span>;
    case estadoVerracoConstants.BAJA:
      return <span className='estado-baja'>{i18n.t('FICHA_VERRACO.INICIAL_ESTADO_VERRACO.B')}</span>;
    case estadoVerracoConstants.ELIMINADO:
      return <span className='estado-eliminado'>{i18n.t('FICHA_VERRACO.INICIAL_ESTADO_VERRACO.E')}</span>;
    default:
      return ;
  }
}

export const estadoVerracoInicioPlaning = ({estadoVerraco}) => {
  switch(estadoVerraco) {
    case estadoVerracoConstants.CUARENTENA:
      return <span className='estado-cuarentena'>{i18n.t('FICHA_VERRACO.INICIAL_ESTADO_VERRACO.C')}</span>;
    case estadoVerracoConstants.PRESENTADO:
      return <span className='estado-presentado'>{i18n.t('FICHA_VERRACO.INICIAL_ESTADO_VERRACO.P')}</span>;
    case estadoVerracoConstants.ACTIVO:
      return <span className='estado-activo'>{i18n.t('FICHA_VERRACO.INICIAL_ESTADO_VERRACO.A')}</span>;
    case estadoVerracoConstants.BAJA:
      return <span className='estado-baja'>{i18n.t('FICHA_VERRACO.INICIAL_ESTADO_VERRACO.B')}</span>;
    case estadoVerracoConstants.ELIMINADO:
      return <span className='estado-eliminado'>{i18n.t('FICHA_VERRACO.INICIAL_ESTADO_VERRACO.E')}</span>;
    default:
      return ;
  }
}

export const estadoVerracoInformeExtracciones = ({idEstadoVerraco}) => {
  switch(idEstadoVerraco) {
    case estadoVerracoConstants.CUARENTENA:
      return <span className='estado-cuarentena'>{i18n.t('FICHA_VERRACO.INICIAL_ESTADO_VERRACO.C')}</span>;
    case estadoVerracoConstants.PRESENTADO:
      return <span className='estado-presentado'>{i18n.t('FICHA_VERRACO.INICIAL_ESTADO_VERRACO.P')}</span>;
    case estadoVerracoConstants.ACTIVO:
      return <span className='estado-activo'>{i18n.t('FICHA_VERRACO.INICIAL_ESTADO_VERRACO.A')}</span>;
    case estadoVerracoConstants.BAJA:
      return <span className='estado-baja'>{i18n.t('FICHA_VERRACO.INICIAL_ESTADO_VERRACO.B')}</span>;
    case estadoVerracoConstants.ELIMINADO:
      return <span className='estado-eliminado'>{i18n.t('FICHA_VERRACO.INICIAL_ESTADO_VERRACO.E')}</span>;
    default:
      return ;
  }
}

export const estadoLote = ({lote, recomendado}) => {
  if (recomendado) {
    return <span className='lote-recomendado'>{lote}</span>;
  }
  return lote
}

export const cantidadNegativa = ({stockFinal, valorFormateado}) => {
  if (parseInt(stockFinal, 10) <= 0) {
    return <span className='cantidad-negativa'>{valorFormateado}</span>;
  }
  return valorFormateado
}

export const colorTipoTareasAgenda = ({backgroundColor}) => {
  return <div style={{backgroundColor: backgroundColor, width: '10px', height: '10px', margin: '0 auto'}} />
}