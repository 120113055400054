import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalAlimentacion from '../../components/fichaVerraco/ModalAlimentacion'
import {submitNuevaAlimentacion, editarAlimentacion, guardarYnuevoAlimentacion, closeModalAlimentacion} from '../../actions/fichaVerraco/fichaVerraco'
import {comboTipoAlimentacion, comboOperarioActivo} from '../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.fichaVerraco,
    combos: state.combos,
    auth: state.auth,
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeModalAlimentacion,
      submitNuevaAlimentacion,
      editarAlimentacion,
      guardarYnuevoAlimentacion,
      comboTipoAlimentacion,
      comboOperarioActivo
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalAlimentacion))