import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import ListadoPage from '../../containers/page/ListadoPage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import SimpleTableNewChildrenPage from '../../containers/comun/table/SimpleTableNewChildrenPage'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import './informes.scss'
import FiltrosInformesEstadoCentroPage from '../../containers/informes/FiltrosInformesEstadoCentroPage'
import {number_formatter} from '../../util/formatFunctions'

class InformesEstadoCentro extends Component {
  constructor () {
    super()
    this.state = {
      agrupacion: 1,
      numeroVerracos: []
    }
  }

  componentDidMount () {
    document.title = this.props.t('MENU.INFORMES.INFORMES') + ' - ' + this.props.t('MENU.INFORMES.ESTADO_CENTRO') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
  }

  componentDidUpdate (prevProps) {
    if (Object.keys(this.props.filtrosEstadoCentro).length > 0) {
      if (this.props.filtrosEstadoCentro !== prevProps.filtrosEstadoCentro) {
        this.setState({agrupacion: this.props.filtrosEstadoCentro.agrupacion.value})
      }
    }
  }

  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual, censo, totalesEstadoCentro, altasVerraco, porAltasVerraco, verracosActivos, porVerracosActivos, edad, productividadVerraco, ritmoExtraccion, eyaculadoEliminado, productividadCentro, eliminacionVerracos, clientes, ebv,
      auth: {separadorDec, separadorMil}
    } = this.props

    // TABLA - CENSO - NUMERO VERRRACOS
    let fechas = []
    let cabecera1 = []
    let filas = []
    if (Object.keys(censo).length > 0) {
      if (Object.keys(this.props.censo.numeroVerracos).length > 0) {
        fechas = Object.values(this.props.censo.numeroVerracos).map((data) => {
          return data.fecha
        })
        cabecera1 = fechas[0].map((data) => {
          return {id: data.fecha, name: data.fecha, borderDobleHeadeClass: true, children: [
              {id: 'numero', name: 'N', borderDobleHeadeClass: true, type: 'numero'},
              {id: 'porcentaje', name: '%', borderDobleHeadeClass: true, type: 'numero'}
            ]
          }
        })
        filas = Object.values(this.props.censo.numeroVerracos).map(function(data, index){
          if (data.estado === 1) {
            data.estado = t('COMUN.COMBOS.COMBO_ESTADO_VERRACO.CUARENTENA')
          } else if (data.estado === 2) {
            data.estado = t('COMUN.COMBOS.COMBO_ESTADO_VERRACO.PRESENTE')
          } else if (data.estado === 3) {
            data.estado = t('COMUN.COMBOS.COMBO_ESTADO_VERRACO.ACTIVO')
          } else if (data.estado === 4) {
            data.estado = t('COMUN.COMBOS.COMBO_ESTADO_VERRACO.BAJA')
          } else if (data.estado === 5) {
            data.estado = t('COMUN.COMBOS.COMBO_ESTADO_VERRACO.ELIMINADO')
          }
          let date = data.fecha.forEach(function(f, index){
            data[f.fecha] = {porcentaje: f.porcentaje, numero: f.numero}
          })
          return data
        })
      }
    }
    if (this.state.agrupacion === 2) {
      cabecera1.unshift({id: 'nombreRaza', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_RAZA')})
    }
    if (this.state.agrupacion === 3) {
      cabecera1.unshift({id: 'nombreLinea', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_LINEA')})
    }
    cabecera1.unshift({id: 'estado', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.ESTADO')})

    const tableInformeEstadoCentro = {
      id: 'informeStocDosisTable',
      multiHeader: true,
      multiHeaderChild: false ,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      columns:cabecera1,
      rows: filas,
      initialValues: {}
    }

    // ALTAS VERRACO
    let verracosAlta = 0
    if (this.state.agrupacion === 1) {
      if (!isNaN(altasVerraco)) {
        verracosAlta = altasVerraco
      }
    } else {
      let verracosAltaObject = Object.entries(altasVerraco).map(function(alta, index){
        return alta
      })
      verracosAlta = verracosAltaObject.map(function(data, index){
        return <p className="p-raza-linea" key={index}>{data[0] + ': ' + data[1]}</p>
      })
      if (Object.keys(verracosAlta).length === 0) {
        verracosAlta = 0
      }
    }

    // PORCENTAJE ALTAS VERRACO
    let verracosAltaPor = 0
    if (this.state.agrupacion === 1) {
      if (!isNaN(porAltasVerraco)) {
        verracosAltaPor = number_formatter(porAltasVerraco, 2, separadorDec, separadorMil) + '%'
      }
    } else {
      let verracosAltaPorObject = Object.entries(porAltasVerraco).map(function(alta, index){
        return alta
      })
      verracosAltaPor = verracosAltaPorObject.map(function(data, index){
        return <p className="p-raza-linea" key={index}>{data[0] + ': ' + number_formatter(data[1], 2, separadorDec, separadorMil) + '%'}</p>
      })
      if (Object.keys(verracosAltaPor).length === 0) {
        verracosAltaPor = 0
      }
    }

    // VERRACOS ACTIVOS
    let activosVerracos = 0
    if (this.state.agrupacion === 1) {
      if (!isNaN(verracosActivos)) {
        activosVerracos = verracosActivos
      }
    } else {
      let activosVerracosObject = Object.entries(verracosActivos).map(function(alta, index){
        return alta
      })
      activosVerracos = activosVerracosObject.map(function(data, index){
        return <p className="p-raza-linea" key={index}>{data[0] + ': ' + data[1]}</p>
      })
      if (Object.keys(activosVerracos).length === 0) {
        activosVerracos = 0
      }
    }

    // PORCENTAJE VERRACOS ACTIVOS
    let activosVerracosPor = 0
    if (this.state.agrupacion === 1) {
      if (!isNaN(porVerracosActivos)) {
        activosVerracosPor = number_formatter(porVerracosActivos, 2, separadorDec, separadorMil) + '%'
      }
    } else {
      let poractivosVerracosObject = Object.entries(porVerracosActivos).map(function(alta, index){
        return alta
      })
      activosVerracosPor = poractivosVerracosObject.map(function(data, index){
        return <p className="p-raza-linea" key={index}>{data[0] + ': ' + number_formatter(data[1], 2, separadorDec, separadorMil) + '%'}</p>
      })
      if (Object.keys(activosVerracosPor).length === 0) {
        activosVerracosPor = 0
      }
    }

    // EDAD CUARENTENA
    let cuarentenaEdad = 0
    if (this.state.agrupacion === 1) {
      if (!isNaN(edad.cuarentena)) {
        cuarentenaEdad = number_formatter(edad.cuarentena, 2, separadorDec, separadorMil)
      }
    } else {
      let cuarentenaEdadObject = Object.entries(edad.cuarentena).map(function(alta, index){
        return alta
      })
      cuarentenaEdad = cuarentenaEdadObject.map(function(data, index){
        return <p className="p-raza-linea" key={index}>{data[0] + ': ' + number_formatter(data[1], 2, separadorDec, separadorMil)}</p>
      })
      if (Object.keys(cuarentenaEdad).length === 0) {
        cuarentenaEdad = 0
      }
    }

    // EDAD ENTRADA CENTRO
    let entradaCentroEdad = 0
    if (this.state.agrupacion === 1) {
      if (!isNaN(edad.entradaCentro)) {
        entradaCentroEdad = number_formatter(edad.entradaCentro, 2, separadorDec, separadorMil)
      }
    } else {
      let edadEntradaCentroObject = Object.entries(edad.entradaCentro).map(function(alta, index){
        return alta
      })
      entradaCentroEdad = edadEntradaCentroObject.map(function(data, index){
        return <p className="p-raza-linea" key={index}>{data[0] + ': ' + number_formatter(data[1], 2, separadorDec, separadorMil)}</p>
      })
      if (Object.keys(entradaCentroEdad).length === 0) {
        entradaCentroEdad = 0
      }
    }

    // EDAD PRIMERA EXTRACCION
    let primeraExtraccionEdad = 0
    if (this.state.agrupacion === 1) {
      if (!isNaN(edad.primeraExtraccion)) {
        primeraExtraccionEdad = number_formatter(edad.primeraExtraccion, 2, separadorDec, separadorMil)
      }
    } else {
      let edadPrimeraExtraccionObject = Object.entries(edad.primeraExtraccion).map(function(alta, index){
        return alta
      })
      primeraExtraccionEdad = edadPrimeraExtraccionObject.map(function(data, index){
        return <p className="p-raza-linea" key={index}>{data[0] + ': ' + number_formatter(data[1], 2, separadorDec, separadorMil)}</p>
      })
      if (Object.keys(primeraExtraccionEdad).length === 0) {
        primeraExtraccionEdad = 0
      }
    }

    // EDAD PROMEDIO EDAD
    let promedioEdad = 0
    if (edad.promedioEdad) {
      if (this.state.agrupacion === 1) {
        if (!isNaN(edad.primeraExtraccion)) {
          promedioEdad = <Row className="medias-edad-verracos-padre">
          <Col sm={3} className="cont-medias-edad-verracos">
            <Col sm={4} className="medias-edad-verracos">
              {edad.promedioEdad.menor12 ? number_formatter(edad.promedioEdad.menor12.porcentaje, 2, separadorDec, separadorMil) : null}
            </Col>
            <Col sm={4} className="medias-edad-verracos">
              {edad.promedioEdad.menor12 ? number_formatter(edad.promedioEdad.menor12.porcentaje, 2, separadorDec, separadorMil) : null}
            </Col>
            <Col sm={4} className="medias-edad-verracos">
              {edad.promedioEdad.menor12 ? number_formatter(edad.promedioEdad.menor12.mediaEdad, 2, separadorDec, separadorMil) : null}
            </Col>
          </Col>
          <Col sm={3} className="cont-medias-edad-verracos">
            <Col sm={4} className="medias-edad-verracos">
              {edad.promedioEdad.entre1218 ? number_formatter(edad.promedioEdad.entre1218.total, 2, separadorDec, separadorMil) : null}
            </Col>
            <Col sm={4} className="medias-edad-verracos">
              {edad.promedioEdad.entre1218 ? number_formatter(edad.promedioEdad.entre1218.porcentaje, 2, separadorDec, separadorMil) : null}
            </Col>
            <Col sm={4} className="medias-edad-verracos">
              {edad.promedioEdad.entre1218 ? number_formatter(edad.promedioEdad.entre1218.mediaEdad, 2, separadorDec, separadorMil) : null}
            </Col>
          </Col>
          <Col sm={3} className="cont-medias-edad-verracos">
            <Col sm={4} className="medias-edad-verracos">
              {edad.promedioEdad.entre1824 ? number_formatter(edad.promedioEdad.entre1824.total, 2, separadorDec, separadorMil) : null}
            </Col>
            <Col sm={4} className="medias-edad-verracos">
              {edad.promedioEdad.entre1824 ? number_formatter(edad.promedioEdad.entre1824.porcentaje, 2, separadorDec, separadorMil) : null}
            </Col>
            <Col sm={4} className="medias-edad-verracos">
              {edad.promedioEdad.entre1824 ? number_formatter(edad.promedioEdad.entre1824.mediaEdad, 2, separadorDec, separadorMil) : null}
            </Col>
          </Col>
          <Col sm={3} className="cont-medias-edad-verracos">
            <Col sm={4} className="medias-edad-verracos">
              {edad.promedioEdad.mayor24 ? number_formatter(edad.promedioEdad.mayor24.total, 2, separadorDec, separadorMil) : null}
            </Col>
            <Col sm={4} className="medias-edad-verracos">
              {edad.promedioEdad.mayor24 ? number_formatter(edad.promedioEdad.mayor24.porcentaje, 2, separadorDec, separadorMil) : null}
            </Col>
            <Col sm={4} className="medias-edad-verracos" style={{border: 'none'}}>
              {edad.promedioEdad.mayor24 ? number_formatter(edad.promedioEdad.mayor24.mediaEdad, 2, separadorDec, separadorMil) : null}
            </Col>
          </Col>
        </Row>
        }
      } else {
        promedioEdad = Object.entries(edad.promedioEdad).map(function(data, index){
          if (data[0] !== "menor12" && data[0] !== "entre1218" && data[0] !== "entre1824" && data[0] !== "mayor24") {
            return <Row key={index + 201} className="medias-edad-verracos-padre promedio-edad-raza-linea">
            <Col sm={3} key={index + 202} className="cont-medias-edad-verracos">
              <Col sm={12} className="medias-edad-verracos">{data[0]}</Col>
            </Col>
            <Col sm={3} key={index + 203} className="cont-medias-edad-verracos">
              <Col sm={4} key={index + 3} className="medias-edad-verracos">
                {number_formatter(data[1].menor12.porcentaje, 2, separadorDec, separadorMil)}
              </Col>
              <Col sm={4} key={index + 4} className="medias-edad-verracos">
                {number_formatter(data[1].menor12.porcentaje, 2, separadorDec, separadorMil)}
              </Col>
              <Col sm={4} key={index + 5} className="medias-edad-verracos">
                {number_formatter(data[1].menor12.mediaEdad, 2, separadorDec, separadorMil)}
              </Col>
            </Col>
            <Col sm={3} key={index + 6} className="cont-medias-edad-verracos">
              <Col sm={4} key={index + 7} className="medias-edad-verracos">
                {number_formatter(data[1].entre1218.total, 2, separadorDec, separadorMil)}
              </Col>
              <Col sm={4} key={index + 8} className="medias-edad-verracos">
                {number_formatter(data[1].entre1218.porcentaje, 2, separadorDec, separadorMil)}
              </Col>
              <Col sm={4} key={index + 9} className="medias-edad-verracos">
                {number_formatter(data[1].entre1218.mediaEdad, 2, separadorDec, separadorMil)}
              </Col>
            </Col>
            <Col sm={3} key={index + 10} className="cont-medias-edad-verracos">
              <Col sm={4} key={index + 11} className="medias-edad-verracos">
                {number_formatter(data[1].entre1824.total, 2, separadorDec, separadorMil)}
              </Col>
              <Col sm={4} key={index + 12} className="medias-edad-verracos">
                {number_formatter(data[1].entre1824.porcentaje, 2, separadorDec, separadorMil)}
              </Col>
              <Col sm={4} key={index + 13} className="medias-edad-verracos">
                {number_formatter(data[1].entre1824.mediaEdad, 2, separadorDec, separadorMil)}
              </Col>
            </Col>
            <Col sm={3} key={index + 14} className="cont-medias-edad-verracos">
              <Col sm={4} key={index + 15} className="medias-edad-verracos">
                {number_formatter(data[1].mayor24.total, 2, separadorDec, separadorMil)}
              </Col>
              <Col sm={4} key={index + 16} className="medias-edad-verracos">
                {number_formatter(data[1].mayor24.porcentaje, 2, separadorDec, separadorMil)}
              </Col>
              <Col sm={4} key={index + 17} className="medias-edad-verracos" style={{border: 'none'}}>
                {number_formatter(data[1].mayor24.mediaEdad, 2, separadorDec, separadorMil)}
              </Col>
            </Col>
          </Row>
          } else {
            return undefined
          }
        })
      }
    }

    // EDAD ELIMINACION MESES VERRACO
    let verracoMeseseliminacion = 0
    if (edad.promedioEdad) {
      if (this.state.agrupacion === 1) {
        if (!isNaN(edad.edadEliminacion.edadMesesVerraco) && edad.edadEliminacion.edadMesesVerraco !== null) {
          verracoMeseseliminacion = number_formatter(edad.edadEliminacion.edadMesesVerraco, 2, separadorDec, separadorMil)
        }
      } else {
        let verracoMeseseliminacionObject = Object.entries(edad.edadEliminacion).map(function(alta, index){
          return alta
        })
        if (Object.keys(verracoMeseseliminacionObject).length > 0) {
          verracoMeseseliminacion = verracoMeseseliminacionObject.map(function(data, index){
            return <p className="p-raza-linea" key={index}>{data[1] && data[1].edadMesesVerraco !== null ? data[0] + ': ' + number_formatter(data[1].edadMesesVerraco, 2, separadorDec, separadorMil) : data[0] + ': ' + 0}</p>
          })
        }
        if (Object.keys(verracoMeseseliminacion).length === 0) {
          verracoMeseseliminacion = 0
        }
      }
    }

    // EDAD ELIMINACION MESES CIAP
    let verracoMesesCiap = 0
    if (edad.promedioEdad) {
      if (this.state.agrupacion === 1) {
        if (!isNaN(edad.edadEliminacion.edadMesesCiap) && edad.edadEliminacion.edadMesesCiap) {
          verracoMesesCiap = number_formatter(edad.edadEliminacion.edadMesesCiap, 2, separadorDec, separadorMil)
        }
      } else {
        let verracoMesesCiapObject = Object.entries(edad.edadEliminacion).map(function(alta, index){
          return alta
        })
        verracoMesesCiap = verracoMesesCiapObject.map(function(data, index){
          return <p className="p-raza-linea" key={index}>{data[1] && data[1].edadMesesCiap !== null ? data[0] + ': ' + number_formatter(data[1].edadMesesVerraco, 2, separadorDec, separadorMil) : data[0] + ': ' + 0}</p>
        })
        if (Object.keys(verracoMesesCiap).length === 0) {
          verracoMesesCiap = 0
        }
      }
    }

    // PRODUCTIVIDAD VERRACOS - NUMERO EXTRACCIONES
    let numeroExtracciones = 0
    if (productividadVerraco.numeroExtracciones) {
      if (this.state.agrupacion === 1) {
        if (!isNaN(productividadVerraco.numeroExtracciones.total)) {
          numeroExtracciones = <Row className="tabla-numero-extracciones-body">
          <Col sm={2} className="tabla-numero-extracciones-padre-body">
            <span className="numero-extracciones-body">{productividadVerraco.numeroExtracciones ? number_formatter(productividadVerraco.numeroExtracciones.total, 2, separadorDec, separadorMil) : 0}</span>
          </Col>
          <Col sm={2} className="tabla-numero-extracciones-padre-body">
            <span className="numero-extracciones-body">{productividadVerraco.numeroExtracciones ? number_formatter(productividadVerraco.numeroExtracciones.cuarentena, 2, separadorDec, separadorMil) : 0}</span>
          </Col>
          <Col sm={2} className="tabla-numero-extracciones-padre-body">
            <span className="numero-extracciones-body">{productividadVerraco.numeroExtracciones ? number_formatter(productividadVerraco.numeroExtracciones.enCentro, 2, separadorDec, separadorMil) : 0}</span>
          </Col>
          <Col sm={2} className="tabla-numero-extracciones-padre-body">
            <span className="numero-extracciones-body">{productividadVerraco.numeroExtracciones ? number_formatter(productividadVerraco.numeroExtracciones.index, 2, separadorDec, separadorMil) : 0}</span>
          </Col>
          <Col sm={2} className="tabla-numero-extracciones-padre-body">
            <span className="numero-extracciones-body">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}: {productividadVerraco.numeroExtracciones ? number_formatter(productividadVerraco.numeroExtracciones.activo.total, 2, separadorDec, separadorMil) : 0}</span>
          </Col>
          <Col sm={2} className="tabla-numero-extracciones-padre-body" style={{border: 'none'}}>
            <span className="numero-extracciones-body">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MEDIA')}: {productividadVerraco.numeroExtracciones ? number_formatter(productividadVerraco.numeroExtracciones.activo.media, 2, separadorDec, separadorMil) : 0}</span>
          </Col>
        </Row>
        }
      } else {
        numeroExtracciones = Object.entries(productividadVerraco.numeroExtracciones).map(function(data, index){
          if (data[0] !== "total" && data[0] !== "activo" && data[0] !== "cuarentena" && data[0] !== "enCentro" && data[0] !== "edad" && data[0] !== "index") {
            return <Row key={index + 204} className="tabla-numero-extracciones-body numero-extraciones-raza-linea">
          <Col key={index + 205} sm={2} className="tabla-numero-extracciones-padre-body">
            <span className="numero-extracciones-body">{data[0]}</span>
          </Col>
          <Col key={index + 19} sm={2} className="tabla-numero-extracciones-padre-body">
            <span className="numero-extracciones-body">{data[1].total ? number_formatter(data[1].total, 2, separadorDec, separadorMil) : 0}</span>
          </Col>
          <Col key={index + 20} sm={2} className="tabla-numero-extracciones-padre-body">
            <span className="numero-extracciones-body">{data[1].cuarentena ? number_formatter(data[1].cuarentena, 2, separadorDec, separadorMil) : 0}</span>
          </Col>
          <Col key={index + 21} sm={2} className="tabla-numero-extracciones-padre-body">
            <span className="numero-extracciones-body">{data[1].enCentro ? number_formatter(data[1].enCentro, 2, separadorDec, separadorMil) : 0}</span>
          </Col>
          <Col key={index + 22} sm={2} className="tabla-numero-extracciones-padre-body">
            <span className="numero-extracciones-body">{data[1].index ? number_formatter(data[1].index, 2, separadorDec, separadorMil) : 0}</span>
          </Col>
          <Col key={index + 23} sm={2} className="tabla-numero-extracciones-padre-body">
            <span className="numero-extracciones-body">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}: {data[1].activo ? number_formatter(data[1].activo.total, 2, separadorDec, separadorMil) : 0}</span>
          </Col>
          <Col key={index + 24} sm={2} className="tabla-numero-extracciones-padre-body" style={{border: 'none'}}>
            <span className="numero-extracciones-body">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MEDIA')}: {data[1].activo ? number_formatter(data[1].activo.media, 2, separadorDec, separadorMil) : 0}</span>
          </Col>
        </Row>
          } else {
            return undefined
          }
        })
      }
    }

    // PRODUCTIVIDAD VERRACOS - NUMERO EXTRACCIONES -edad
    let numeroExtraccionesEdad = 0
    if (productividadVerraco.numeroExtracciones) {
      if (this.state.agrupacion === 1) {
        if (!isNaN(productividadVerraco.numeroExtracciones.total)) {
          numeroExtraccionesEdad = <Row className="medias-edad-verracos-padre">
          <Col sm={3} className="cont-medias-edad-verracos">
            <Col sm={4} className="medias-edad-verracos">
              {productividadVerraco.numeroExtracciones ? number_formatter(productividadVerraco.numeroExtracciones.edad.menor12.total, 2, separadorDec, separadorMil) : 0}
            </Col>
            <Col sm={4} className="medias-edad-verracos">
              {productividadVerraco.numeroExtracciones ? number_formatter(productividadVerraco.numeroExtracciones.edad.menor12.porcentaje, 2, separadorDec, separadorMil) : 0}
            </Col>
            <Col sm={4} className="medias-edad-verracos">
              {productividadVerraco.numeroExtracciones ? number_formatter(productividadVerraco.numeroExtracciones.edad.menor12.media, 2, separadorDec, separadorMil) : 0}
            </Col>
          </Col>
          <Col sm={3} className="cont-medias-edad-verracos">
            <Col sm={4} className="medias-edad-verracos">
              {productividadVerraco.numeroExtracciones ? number_formatter(productividadVerraco.numeroExtracciones.edad.entre1218.total, 2, separadorDec, separadorMil) : 0}
            </Col>
            <Col sm={4} className="medias-edad-verracos">
              {productividadVerraco.numeroExtracciones ? number_formatter(productividadVerraco.numeroExtracciones.edad.entre1218.porcentaje, 2, separadorDec, separadorMil) : 0}
            </Col>
            <Col sm={4} className="medias-edad-verracos">
              {productividadVerraco.numeroExtracciones ? number_formatter(productividadVerraco.numeroExtracciones.edad.entre1218.media, 2, separadorDec, separadorMil) : 0}
            </Col>
          </Col>
          <Col sm={3} className="cont-medias-edad-verracos">
            <Col sm={4} className="medias-edad-verracos">
              {productividadVerraco.numeroExtracciones ? number_formatter(productividadVerraco.numeroExtracciones.edad.entre1824.total, 2, separadorDec, separadorMil) : 0}
            </Col>
            <Col sm={4} className="medias-edad-verracos">
              {productividadVerraco.numeroExtracciones ? number_formatter(productividadVerraco.numeroExtracciones.edad.entre1824.porcentaje, 2, separadorDec, separadorMil) : 0}
            </Col>
            <Col sm={4} className="medias-edad-verracos">
              {productividadVerraco.numeroExtracciones ? number_formatter(productividadVerraco.numeroExtracciones.edad.entre1824.media, 2, separadorDec, separadorMil) : 0}
            </Col>
          </Col>
          <Col sm={3} className="cont-medias-edad-verracos">
            <Col sm={4} className="medias-edad-verracos">
              {productividadVerraco.numeroExtracciones ? number_formatter(productividadVerraco.numeroExtracciones.edad.mayor24.total, 2, separadorDec, separadorMil) : 0}
            </Col>
            <Col sm={4} className="medias-edad-verracos">
              {productividadVerraco.numeroExtracciones ? number_formatter(productividadVerraco.numeroExtracciones.edad.mayor24.porcentaje, 2, separadorDec, separadorMil) : 0}
            </Col>
            <Col sm={4} className="medias-edad-verracos" style={{border: 'none'}}>
              {productividadVerraco.numeroExtracciones ? number_formatter(productividadVerraco.numeroExtracciones.edad.mayor24.media, 2, separadorDec, separadorMil) : 0}
            </Col>
          </Col>
        </Row>
        }
      } else {
        numeroExtraccionesEdad = Object.entries(productividadVerraco.numeroExtracciones).map(function(data, index){
          if (data[0] !== "total" && data[0] !== "activo" && data[0] !== "cuarentena" && data[0] !== "enCentro" && data[0] !== "edad" && data[0] !== "index") {
            return <Row key={index + 25} className="tabla-numero-extracciones-body edad-estado-centro-raza-linea">
            <Col sm={3} key={index + 2} className="tabla-numero-extracciones-padre-body">
              <Col sm={12} className="numero-extracciones-body">{data[0]}</Col>
            </Col>
            <Col sm={3} key={index + 26} className="tabla-numero-extracciones-padre-body">
              <Col sm={4} key={index + 27} className="numero-extracciones-body">
                {data[1].edad ? number_formatter(data[1].edad.menor12.total, 2, separadorDec, separadorMil) : 0}
              </Col>
              <Col sm={4} key={index + 28} className="numero-extracciones-body">
                {data[1].edad ? number_formatter(data[1].edad.menor12.porcentaje, 2, separadorDec, separadorMil) : 0}
              </Col>
              <Col sm={4} key={index + 29} className="numero-extracciones-body">
                {data[1].edad ? number_formatter(data[1].edad.menor12.media, 2, separadorDec, separadorMil) : 0}
              </Col>
            </Col>
            <Col sm={3} key={index + 30} className="tabla-numero-extracciones-padre-body">
              <Col sm={4} key={index + 31} className="numero-extracciones-body">
                {data[1].edad ? number_formatter(data[1].edad.entre1218.total, 2, separadorDec, separadorMil) : 0}
              </Col>
              <Col sm={4} key={index + 32} className="numero-extracciones-body">
                {data[1].edad ? number_formatter(data[1].edad.entre1218.porcentaje, 2, separadorDec, separadorMil) : 0}
              </Col>
              <Col sm={4} key={index + 33} className="numero-extracciones-body">
                {data[1].edad ? number_formatter(data[1].edad.entre1218.media, 2, separadorDec, separadorMil) : 0}
              </Col>
            </Col>
            <Col sm={3} key={index + 34} className="tabla-numero-extracciones-padre-body">
              <Col sm={4} key={index + 35} className="numero-extracciones-body">
                {data[1].edad ? number_formatter(data[1].edad.entre1824.total, 2, separadorDec, separadorMil) : 0}
              </Col>
              <Col sm={4} key={index + 36} className="numero-extracciones-body">
                {data[1].edad ? number_formatter(data[1].edad.entre1824.porcentaje, 2, separadorDec, separadorMil) : 0}
              </Col>
              <Col sm={4} key={index + 37} className="numero-extracciones-body">
                {data[1].edad ? number_formatter(data[1].edad.entre1824.media, 2, separadorDec, separadorMil) : 0}
              </Col>
            </Col>
            <Col sm={3} key={index + 38} className="tabla-numero-extracciones-padre-body">
              <Col sm={4} key={index + 39} className="numero-extracciones-body">
                {data[1].edad ? number_formatter(data[1].edad.mayor24.total, 2, separadorDec, separadorMil) : 0}
              </Col>
              <Col sm={4} key={index + 40} className="numero-extracciones-body">
                {data[1].edad ? number_formatter(data[1].edad.mayor24.porcentaje, 2, separadorDec, separadorMil) : 0}
              </Col>
              <Col sm={4} key={index + 41} className="numero-extracciones-body" style={{border: 'none'}}>
                {data[1].edad ? number_formatter(data[1].edad.mayor24.media, 2, separadorDec, separadorMil) : 0}
              </Col>
            </Col>
        </Row>
          } else {
            return undefined
          }
        })
      }
    }

    // PRODUCTIVIDAD VERRACOS - NUMERO DOSIS
    let numeroDosisPrdVerr = 0
    if (productividadVerraco.numeroDosis) {
      if (this.state.agrupacion === 1) {
        if (productividadVerraco.numeroDosis.enCentro) {
          numeroDosisPrdVerr = <Row className="tabla-numero-extracciones-body">
          <Col sm={2} className="tabla-numero-extracciones-padre-body">
            <span className="numero-extracciones-body">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}: {productividadVerraco.numeroDosis ? number_formatter(productividadVerraco.numeroDosis.activo.total, 2, separadorDec, separadorMil) : 0}</span>
          </Col>
          <Col sm={2} className="tabla-numero-extracciones-padre-body">
            <span className="numero-extracciones-body">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MEDIA')}: {productividadVerraco.numeroDosis ? number_formatter(productividadVerraco.numeroDosis.activo.media, 2, separadorDec, separadorMil) : 0}</span>
          </Col>
          <Col sm={2} className="tabla-numero-extracciones-padre-body">
            <span className="numero-extracciones-body">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}: {productividadVerraco.numeroDosis ? number_formatter(productividadVerraco.numeroDosis.enCentro.total, 2, separadorDec, separadorMil) : 0}</span>
          </Col>
          <Col sm={2} className="tabla-numero-extracciones-padre-body">
            <span className="numero-extracciones-body">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MEDIA')}: {productividadVerraco.numeroDosis ? number_formatter(productividadVerraco.numeroDosis.enCentro.media, 2, separadorDec, separadorMil) : 0}</span>
          </Col>
          <Col sm={2} className="tabla-numero-extracciones-padre-body">
            <span className="numero-extracciones-body">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}: {productividadVerraco.numeroDosis ? number_formatter(productividadVerraco.numeroDosis.cuarentena.total, 2, separadorDec, separadorMil) : 0}</span>
          </Col>
          <Col sm={2} className="tabla-numero-extracciones-padre-body">
            <span className="numero-extracciones-body">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MEDIA')}: {productividadVerraco.numeroDosis ? number_formatter(productividadVerraco.numeroDosis.cuarentena.media, 2, separadorDec, separadorMil) : 0}</span>
          </Col>
        </Row>
        }
      } else {
        numeroDosisPrdVerr = Object.entries(productividadVerraco.numeroDosis).map(function(data, index){
          if (data[0] !== "activo" && data[0] !== "cuarentena" && data[0] !== "enCentro" && data[0] !== "edad" && data[0] !== "calidadEyaculado") {
            return <Row key={index + 42} className="tabla-numero-extracciones-body">
            <Col sm={3}className="tabla-numero-extracciones-padre-body">
              <Col sm={12} className="tabla-numero-extracciones-padre-body">
                <span key={index + 44} className="numero-extracciones-body">{data[0]}</span>
              </Col>
            </Col>
            <Col sm={9} style={{paddingRight: 0, paddingLeft: 0}}>
              <Col sm={2} key={index + 43} className="tabla-numero-extracciones-padre-body">
                <span key={index + 44} className="numero-extracciones-body">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}: {data[1].activo ? number_formatter(data[1].activo.total, 2, separadorDec, separadorMil) : 0}</span>
              </Col>
              <Col key={index + 45} sm={2} className="tabla-numero-extracciones-padre-body">
                <span key={index + 5} className="numero-extracciones-body">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MEDIA')}: {data[1].activo ? number_formatter(data[1].activo.media, 2, separadorDec, separadorMil) : 0}</span>
              </Col>
              <Col key={index + 46} sm={2} className="tabla-numero-extracciones-padre-body">
                <span key={index + 47} className="numero-extracciones-body">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}: {data[1].enCentro ? number_formatter(data[1].enCentro.total, 2, separadorDec, separadorMil) : 0}</span>
              </Col>
              <Col key={index + 48} sm={2} className="tabla-numero-extracciones-padre-body">
                <span key={index + 49} className="numero-extracciones-body">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MEDIA')}: {data[1].enCentro ? number_formatter(data[1].enCentro.media, 2, separadorDec, separadorMil) : 0}</span>
              </Col>
              <Col key={index + 50} sm={2} className="tabla-numero-extracciones-padre-body">
                <span key={index + 51} className="numero-extracciones-body">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}: {data[1].cuarentena ? number_formatter(data[1].cuarentena.total, 2, separadorDec, separadorMil) : 0}</span>
              </Col>
              <Col key={index + 52} sm={2} className="tabla-numero-extracciones-padre-body">
                <span key={index + 53} className="numero-extracciones-body">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MEDIA')}: {data[1].cuarentena ? number_formatter(data[1].cuarentena.media, 2, separadorDec, separadorMil) : 0}</span>
              </Col>
          </Col>
        </Row>
          } else {
            return undefined
          }
        })
      }
    }

    // PRODUCTIVIDAD VERRACOS - NUMERO DOSIS -edad
    let numeroDosisPrdVerrEdad = 0
    if (productividadVerraco.numeroDosis) {
      if (this.state.agrupacion === 1) {
        if (productividadVerraco.numeroDosis.edad) {
          numeroDosisPrdVerrEdad = <Row className="medias-edad-verracos-padre">
          <Col sm={3} className="cont-medias-edad-verracos">
            <Col sm={4} className="medias-edad-verracos">
              {productividadVerraco.numeroDosis ? number_formatter(productividadVerraco.numeroDosis.edad.menor12.total, 2, separadorDec, separadorMil) : 0}
            </Col>
            <Col sm={4} className="medias-edad-verracos">
              {productividadVerraco.numeroDosis ? number_formatter(productividadVerraco.numeroDosis.edad.menor12.porcentaje, 2, separadorDec, separadorMil) : 0}
            </Col>
            <Col sm={4} className="medias-edad-verracos">
              {productividadVerraco.numeroDosis ? number_formatter(productividadVerraco.numeroDosis.edad.menor12.media, 2, separadorDec, separadorMil) : 0}
            </Col>
          </Col>
          <Col sm={3} className="cont-medias-edad-verracos">
            <Col sm={4} className="medias-edad-verracos">
              {productividadVerraco.numeroDosis ? number_formatter(productividadVerraco.numeroDosis.edad.entre1218.total, 2, separadorDec, separadorMil) : 0}
            </Col>
            <Col sm={4} className="medias-edad-verracos">
              {productividadVerraco.numeroDosis ? number_formatter(productividadVerraco.numeroDosis.edad.entre1218.porcentaje, 2, separadorDec, separadorMil) : 0}
            </Col>
            <Col sm={4} className="medias-edad-verracos">
              {productividadVerraco.numeroDosis ? number_formatter(productividadVerraco.numeroDosis.edad.entre1218.media, 2, separadorDec, separadorMil) : 0}
            </Col>
          </Col>
          <Col sm={3} className="cont-medias-edad-verracos">
            <Col sm={4} className="medias-edad-verracos">
              {productividadVerraco.numeroDosis ? number_formatter(productividadVerraco.numeroDosis.edad.entre1824.total, 2, separadorDec, separadorMil) : 0}
            </Col>
            <Col sm={4} className="medias-edad-verracos">
              {productividadVerraco.numeroDosis ? number_formatter(productividadVerraco.numeroDosis.edad.entre1824.porcentaje, 2, separadorDec, separadorMil) : 0}
            </Col>
            <Col sm={4} className="medias-edad-verracos">
              {productividadVerraco.numeroDosis ? number_formatter(productividadVerraco.numeroDosis.edad.entre1824.media, 2, separadorDec, separadorMil) : 0}
            </Col>
          </Col>
          <Col sm={3} className="cont-medias-edad-verracos">
            <Col sm={4} className="medias-edad-verracos">
              {productividadVerraco.numeroDosis ? number_formatter(productividadVerraco.numeroDosis.edad.mayor24.total, 2, separadorDec, separadorMil) : 0}
            </Col>
            <Col sm={4} className="medias-edad-verracos">
              {productividadVerraco.numeroDosis ? number_formatter(productividadVerraco.numeroDosis.edad.mayor24.porcentaje, 2, separadorDec, separadorMil) : 0}
            </Col>
            <Col sm={4} className="medias-edad-verracos" style={{border: 'none'}}>
              {productividadVerraco.numeroDosis ? number_formatter(productividadVerraco.numeroDosis.edad.mayor24.media, 2, separadorDec, separadorMil) : 0}
            </Col>
          </Col>
        </Row>
        }
      } else {
        numeroDosisPrdVerrEdad = Object.entries(productividadVerraco.numeroDosis).map(function(data, index){
          if (data[0] !== "menor12" && data[0] !== "entre1218" && data[0] !== "entre1824" && data[0] !== "mayor24") {
            return <Row key={index + 54} className="medias-edad-verracos-padre edad-estado-centro-raza-linea">
              <Col sm={3} className="cont-medias-edad-verracos">
                <Col sm={12} className="medias-edad-verracos">
                  {data[0]}
                </Col>
              </Col>
              <Col key={index + 55} sm={3} className="cont-medias-edad-verracos">
                <Col key={index + 56} sm={4} className="medias-edad-verracos">
                  {data[1].edad ? ( data[1].edad.menor12.total ? number_formatter(data[1].edad.menor12.total, 2, separadorDec, separadorMil) : 0) : 0}
                </Col>
                <Col key={index + 57} sm={4} className="medias-edad-verracos">
                  {data[1].edad ? ( data[1].edad.menor12.porcentaje ? number_formatter(data[1].edad.menor12.porcentaje, 2, separadorDec, separadorMil) : 0) : 0}
                </Col>
                <Col key={index + 58} sm={4} className="medias-edad-verracos">
                  {data[1].edad ? ( data[1].edad.menor12.media ? number_formatter(data[1].edad.menor12.media, 2, separadorDec, separadorMil) : 0) : 0}
                </Col>
              </Col>
              <Col key={index + 59} sm={3} className="cont-medias-edad-verracos">
                <Col key={index + 60} sm={4} className="medias-edad-verracos">
                  {data[1].edad ? data[1].edad.entre1218.total ? number_formatter(data[1].edad.entre1218.total, 2, separadorDec, separadorMil) : 0 : 0}
                </Col>
                <Col key={index + 61} sm={4} className="medias-edad-verracos">
                  {data[1].edad ? data[1].edad.entre1218.porcentaje ? number_formatter(data[1].edad.entre1218.porcentaje, 2, separadorDec, separadorMil) : 0 : 0}
                </Col>
                <Col key={index + 62} sm={4} className="medias-edad-verracos">
                  {data[1].edad ? data[1].edad.entre1218.media ? number_formatter(data[1].edad.entre1218.media, 2, separadorDec, separadorMil) : 0 : 0}
                </Col>
              </Col>
              <Col key={index + 63} sm={3} className="cont-medias-edad-verracos">
                <Col key={index + 64} sm={4} className="medias-edad-verracos">
                  {data[1].edad ? data[1].edad.entre1824.total ? number_formatter(data[1].edad.entre1824.total, 2, separadorDec, separadorMil) : 0 : 0}
                </Col>
                <Col key={index + 65} sm={4} className="medias-edad-verracos">
                  {data[1].edad ? data[1].edad.entre1824.porcentaje ? number_formatter(data[1].edad.entre1824.porcentaje, 2, separadorDec, separadorMil) : 0 : 0}
                </Col>
                <Col key={index + 6} sm={4} className="medias-edad-verracos">
                  {data[1].edad ? data[1].edad.entre1824.media ? number_formatter(data[1].edad.entre1824.media, 2, separadorDec, separadorMil) : 0 : 0}
                </Col>
              </Col>
              <Col key={index + 67} sm={3} className="cont-medias-edad-verracos">
                <Col key={index + 68} sm={4} className="medias-edad-verracos">
                  {data[1].edad ? data[1].edad.mayor24.total ? number_formatter(data[1].edad.mayor24.total, 2, separadorDec, separadorMil) : 0 : 0}
                </Col>
                <Col key={index + 69} sm={4} className="medias-edad-verracos">
                  {data[1].edad ? data[1].edad.mayor24.porcentaje ? number_formatter(data[1].edad.mayor24.porcentaje, 2, separadorDec, separadorMil) : 0 : 0}
                </Col>
                <Col key={index + 70} sm={4} className="medias-edad-verracos" style={{border: 'none'}}>
                  {data[1].edad ? data[1].edad.mayor24.media ? number_formatter(data[1].edad.mayor24.media, 2, separadorDec, separadorMil) : 0 : 0}
                </Col>
              </Col>
            </Row>
          } else {
            return undefined
          }
        })
      }
    }

    // PRODUCTIVIDAD VERRACOS - NUMERO DOSIS -calidadEyaculado
    let numeroDosisPrdVerrCalidadEyaculado = 0
    if (productividadVerraco.numeroDosis) {
      if (this.state.agrupacion === 1) {
        if (productividadVerraco.numeroDosis.calidadEyaculado) {
          numeroDosisPrdVerrCalidadEyaculado = <Row className="tabla-numero-extracciones">
          <Col sm={2} className="tabla-numero-extracciones-padre">
            {
              productividadVerraco.numeroDosis ? (
                Object.keys(productividadVerraco.numeroDosis.calidadEyaculado.volumen).map((data, i) => {
                  return <span key={i + 71} className="calidad-eyaculado-result" style={{background: '#a3a09d', color: '#fff'}}><b>{data}</b></span>
                })
              ) : null
            }
          </Col>
          <Col sm={2} className="tabla-numero-extracciones-padre">
            {
              productividadVerraco.numeroDosis ? (
                Object.values(productividadVerraco.numeroDosis.calidadEyaculado.volumen).map((data, i) => {
                  return <span key={i +72} className="calidad-eyaculado-result">{number_formatter(data, 2, separadorDec, separadorMil)}</span>
                })
              ) : null
            }
          </Col>
          <Col sm={3} className="tabla-numero-extracciones-padre">
            {
              productividadVerraco.numeroDosis ? (
                Object.values(productividadVerraco.numeroDosis.calidadEyaculado.concentracion).map((data, i) => {
                  return <span key={i + 73} className="calidad-eyaculado-result">{number_formatter(data, 2, separadorDec, separadorMil)}</span>
                })
              ) : null
            }
          </Col>
          <Col sm={2} className="tabla-numero-extracciones-padre">
            {
              productividadVerraco.numeroDosis ? (
                Object.values(productividadVerraco.numeroDosis.calidadEyaculado.motilidad).map((data, i) => {
                  return <span key={i + 74} className="calidad-eyaculado-result">{number_formatter(data, 2, separadorDec, separadorMil)}</span>
                })
              ) : null
            }
          </Col>
          <Col sm={3} className="tabla-numero-extracciones-padre">
            {
              productividadVerraco.numeroDosis ? (
                Object.values(productividadVerraco.numeroDosis.calidadEyaculado.porFA).map((data, i) => {
                  return <span key={i + 75} className="calidad-eyaculado-result">{number_formatter(data, 2, separadorDec, separadorMil)}</span>
                })
              ) : null
            }
          </Col>
        </Row>
        }
      } else {
        numeroDosisPrdVerrCalidadEyaculado = Object.entries(productividadVerraco.numeroDosis).map(function(datos, index){
          if (datos[0] !== "activo" && datos[0] !== "enCentro" && datos[0] !== "cuarentena" && datos[0] !== "edad"  && datos[0] !== "calidadEyaculado") {
            return <Row key={index + 76} className="tabla-numero-extracciones">
            <Col key={index + 77} sm={2} className="tabla-numero-extracciones-padre">
              {
                datos[1] ? (
                  Object.keys(datos[1].calidadEyaculado.volumen).map((data, i) => {
                    return <span key={i + 78} className="calidad-eyaculado-result" style={{background: '#a3a09d', color: '#fff'}}><b>{data}</b></span>
                  })
                ) : null
              }
            </Col>
            <Col sm={2} className="tabla-numero-extracciones-padre">
              <span className="calidad-eyaculado-result" style={{background: '#a3a09d', color: '#fff'}}><b>{datos[0]}</b></span>
            </Col>
            <Col key={index + 79} sm={2} className="tabla-numero-extracciones-padre">
              {
                datos[1] ? (
                  Object.values(datos[1].calidadEyaculado.volumen).map((data, i) => {
                    return <span key={i+ 80} className="calidad-eyaculado-result">{number_formatter(data, 2, separadorDec, separadorMil)}</span>
                  })
                ) : null
              }
            </Col>
            <Col key={index + 81} sm={2} className="tabla-numero-extracciones-padre">
              {
                datos[1] ? (
                  Object.values(datos[1].calidadEyaculado.concentracion).map((data, i) => {
                    return <span key={i+82} className="calidad-eyaculado-result">{number_formatter(data, 2, separadorDec, separadorMil)}</span>
                  })
                ) : null
              }
            </Col>
            <Col key={index + 83} sm={2} className="tabla-numero-extracciones-padre">
              {
                datos[1] ? (
                  Object.values(datos[1].calidadEyaculado.motilidad).map((data, i) => {
                    return <span key={i+84} className="calidad-eyaculado-result">{number_formatter(data, 2, separadorDec, separadorMil)}</span>
                  })
                ) : null
              }
            </Col>
            <Col key={index + 85} sm={2} className="tabla-numero-extracciones-padre">
              {
                datos[1] ? (
                  Object.values(datos[1].calidadEyaculado.porFA).map((data, i) => {
                    return <span key={i+86} className="calidad-eyaculado-result">{number_formatter(data, 2, separadorDec, separadorMil)}</span>
                  })
                ) : null
              }
            </Col>
          </Row>
          } else {
            return undefined
          }
        })
      }
    }

    // PRODUCTIVIDAD VERRACOS - RITMO EXTRACCION -INTERVALO
    let intervaloRitmoExtraccion = 0
    if (ritmoExtraccion.intervalo) {
      if (this.state.agrupacion === 1) {
        if (ritmoExtraccion.intervalo['9']) {
          intervaloRitmoExtraccion = <Row className="tabla-ritmo-extraccion-body">
          <Col sm={2} className="tabla-ritmo-extraccion-padre-body col-5">
            <Col sm={4} className="tabla-ritmo-extraccion-padre-body">
              <span className="ritmo-extraccion-body">{ritmoExtraccion.intervalo ? number_formatter(ritmoExtraccion.intervalo['12'].total, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
            <Col sm={4} className="tabla-ritmo-extraccion-padre-body">
              <span className="ritmo-extraccion-body">{ritmoExtraccion.intervalo ? number_formatter(ritmoExtraccion.intervalo['12'].porcentaje, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
            <Col sm={4} className="tabla-ritmo-extraccion-padre-body">
              <span className="ritmo-extraccion-body">{ritmoExtraccion.intervalo ? number_formatter(ritmoExtraccion.intervalo['12'].media, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
          </Col>
          <Col sm={2} className="tabla-ritmo-extraccion-padre-body col-5">
            <Col sm={4} className="tabla-ritmo-extraccion-padre-body">
              <span className="ritmo-extraccion-body">{ritmoExtraccion.intervalo ? number_formatter(ritmoExtraccion.intervalo['34'].total, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
            <Col sm={4} className="tabla-ritmo-extraccion-padre-body">
              <span className="ritmo-extraccion-body">{ritmoExtraccion.intervalo ? number_formatter(ritmoExtraccion.intervalo['34'].porcentaje, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
            <Col sm={4} className="tabla-ritmo-extraccion-padre-body">
              <span className="ritmo-extraccion-body">{ritmoExtraccion.intervalo ? number_formatter(ritmoExtraccion.intervalo['34'].media, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
          </Col>
          <Col sm={2} className="tabla-ritmo-extraccion-padre-body col-5">
            <Col sm={4} className="tabla-ritmo-extraccion-padre-body">
              <span className="ritmo-extraccion-body">{ritmoExtraccion.intervalo ? number_formatter(ritmoExtraccion.intervalo['56'].total, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
            <Col sm={4} className="tabla-ritmo-extraccion-padre-body">
              <span className="ritmo-extraccion-body">{ritmoExtraccion.intervalo ? number_formatter(ritmoExtraccion.intervalo['56'].porcentaje, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
            <Col sm={4} className="tabla-ritmo-extraccion-padre-body">
              <span className="ritmo-extraccion-body">{ritmoExtraccion.intervalo ? number_formatter(ritmoExtraccion.intervalo['56'].media, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
          </Col>
          <Col sm={2} className="tabla-ritmo-extraccion-padre-body col-5">
            <Col sm={4} className="tabla-ritmo-extraccion-padre-body">
              <span className="ritmo-extraccion-body">{ritmoExtraccion.intervalo ? number_formatter(ritmoExtraccion.intervalo['78'].total, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
            <Col sm={4} className="tabla-ritmo-extraccion-padre-body">
              <span className="ritmo-extraccion-body">{ritmoExtraccion.intervalo ? number_formatter(ritmoExtraccion.intervalo['78'].porcentaje, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
            <Col sm={4} className="tabla-ritmo-extraccion-padre-body">
              <span className="ritmo-extraccion-body">{ritmoExtraccion.intervalo ? number_formatter(ritmoExtraccion.intervalo['78'].media, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
          </Col>
          <Col sm={2} className="tabla-ritmo-extraccion-padre-body col-5">
            <Col sm={4} className="tabla-ritmo-extraccion-padre-body">
              <span className="ritmo-extraccion-body">{ritmoExtraccion.intervalo ? number_formatter(ritmoExtraccion.intervalo['9'].total, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
            <Col sm={4} className="tabla-ritmo-extraccion-padre-body">
              <span className="ritmo-extraccion-body">{ritmoExtraccion.intervalo ? number_formatter(ritmoExtraccion.intervalo['9'].porcentaje, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
            <Col sm={4} className="tabla-ritmo-extraccion-padre-body">
              <span className="ritmo-extraccion-body">{ritmoExtraccion.intervalo ? number_formatter(ritmoExtraccion.intervalo['9'].media, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
          </Col>
        </Row>
        }
      } else {
        intervaloRitmoExtraccion = Object.entries(ritmoExtraccion.intervalo).map(function(data, index){
          if (data[0] !== "9" && data[0] !== "12" && data[0] !== "34" && data[0] !== "56" && data[0] !== "78") {
            return <Row key={index + 87} className="tabla-ritmo-extraccion-body edad-estado-centro-raza-linea">
          <Col sm={2} className="tabla-ritmo-extraccion-padre-body">
            <Col sm={12} className="tabla-ritmo-extraccion-padre-body">
              <span className="ritmo-extraccion-body">{data[0]}</span>
            </Col>
          </Col>
          <Col key={index + 88} sm={2} className="tabla-ritmo-extraccion-padre-body">
            <Col key={index + 89} sm={4} className="tabla-ritmo-extraccion-padre-body">
              <span className="ritmo-extraccion-body">{data[1]['12'] ? number_formatter(data[1]['12'].total, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
            <Col key={index + 90} sm={4} className="tabla-ritmo-extraccion-padre-body">
              <span className="ritmo-extraccion-body">{data[1]['12'] ? number_formatter(data[1]['12'].porcentaje, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
            <Col key={index + 91} sm={4} className="tabla-ritmo-extraccion-padre-body">
              <span className="ritmo-extraccion-body">{data[1]['12'] ? number_formatter(data[1]['12'].media, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
          </Col>
          <Col key={index + 92} sm={2} className="tabla-ritmo-extraccion-padre-body">
            <Col key={index + 93} sm={4} className="tabla-ritmo-extraccion-padre-body">
              <span className="ritmo-extraccion-body">{data[1]['34'] ? number_formatter(data[1]['34'].total, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
            <Col key={index + 94} sm={4} className="tabla-ritmo-extraccion-padre-body">
              <span className="ritmo-extraccion-body">{data[1]['34'] ? number_formatter(data[1]['34'].porcentaje, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
            <Col key={index + 95} sm={4} className="tabla-ritmo-extraccion-padre-body">
              <span className="ritmo-extraccion-body">{data[1]['34'] ? number_formatter(data[1]['34'].media, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
          </Col>
          <Col key={index + 96} sm={2} className="tabla-ritmo-extraccion-padre-body">
            <Col key={index + 97} sm={4} className="tabla-ritmo-extraccion-padre-body">
              <span className="ritmo-extraccion-body">{data[1]['56'] ? number_formatter(data[1]['56'].total, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
            <Col key={index + 98} sm={4} className="tabla-ritmo-extraccion-padre-body">
              <span className="ritmo-extraccion-body">{data[1]['56'] ? number_formatter(data[1]['56'].porcentaje, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
            <Col key={index + 99} sm={4} className="tabla-ritmo-extraccion-padre-body">
              <span className="ritmo-extraccion-body">{data[1]['56'] ? number_formatter(data[1]['56'].media, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
          </Col>
          <Col key={index + 100} sm={2} className="tabla-ritmo-extraccion-padre-body">
            <Col sm={4} className="tabla-ritmo-extraccion-padre-body">
              <span className="ritmo-extraccion-body">{data[1]['78'] ? number_formatter(data[1]['78'].total, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
            <Col key={index + 101} sm={4} className="tabla-ritmo-extraccion-padre-body">
              <span className="ritmo-extraccion-body">{data[1]['78'] ? number_formatter(data[1]['78'].porcentaje, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
            <Col key={index + 102} sm={4} className="tabla-ritmo-extraccion-padre-body">
              <span className="ritmo-extraccion-body">{data[1]['78'] ? number_formatter(data[1]['78'].media, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
          </Col>
          <Col key={index + 103} sm={2} className="tabla-ritmo-extraccion-padre-body">
            <Col key={index + 104} sm={4} className="tabla-ritmo-extraccion-padre-body">
              <span className="ritmo-extraccion-body">{data[1]['9'] ? number_formatter(data[1]['9'].total, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
            <Col key={index + 105} sm={4} className="tabla-ritmo-extraccion-padre-body">
              <span className="ritmo-extraccion-body">{data[1]['9'] ? number_formatter(data[1]['9'].porcentaje, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
            <Col key={index + 106} sm={4} className="tabla-ritmo-extraccion-padre-body">
              <span className="ritmo-extraccion-body">{data[1]['9'] ? number_formatter(data[1]['9'].media, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
          </Col>
        </Row>
          } else {
            return undefined
          }
        })
      }
    }

    // PRODUCTIVIDAD VERRACOS - RITMO EXTRACCION -EDAD
    let edadRitmoExtraccion = 0
    if (ritmoExtraccion.edad) {
      if (this.state.agrupacion === 1) {
        if (ritmoExtraccion.edad.menor12) {
          edadRitmoExtraccion = <Row className="medias-edad-verracos-padre">
          <Col sm={6} className="cont-medias-edad-verracos">
            <Col sm={4} className="medias-edad-verracos">
              {Object.keys(ritmoExtraccion).length > 0 ? number_formatter(ritmoExtraccion.edad.menor12.numero, 2, separadorDec, separadorMil) : 0}
            </Col>
            <Col sm={4} className="medias-edad-verracos">
              {Object.keys(ritmoExtraccion).length > 0 ? number_formatter(ritmoExtraccion.edad.menor12.porcentaje, 2, separadorDec, separadorMil) : 0}
            </Col>
            <Col sm={4} className="medias-edad-verracos">
              {Object.keys(ritmoExtraccion).length > 0 ? number_formatter(ritmoExtraccion.edad.menor12.media, 2, separadorDec, separadorMil) : 0}
            </Col>
          </Col>
          <Col sm={6} className="cont-medias-edad-verracos">
            <Col sm={4} className="medias-edad-verracos">
              {Object.keys(ritmoExtraccion).length > 0 ? number_formatter(ritmoExtraccion.edad.mayor12.numero, 2, separadorDec, separadorMil) : 0}
            </Col>
            <Col sm={4} className="medias-edad-verracos">
              {Object.keys(ritmoExtraccion).length > 0 ? number_formatter(ritmoExtraccion.edad.mayor12.porcentaje, 2, separadorDec, separadorMil) : 0}
            </Col>
            <Col sm={4} className="medias-edad-verracos">
              {Object.keys(ritmoExtraccion).length > 0 ? number_formatter(ritmoExtraccion.edad.mayor12.media, 2, separadorDec, separadorMil) : 0}
            </Col>
          </Col>
        </Row>
        }
      } else {
        edadRitmoExtraccion = Object.entries(ritmoExtraccion.edad).map(function(data, index){
          if (data[0] !== "menor12" && data[0] !== "mayor12") {
            return <Row key={index + 206} className="medias-edad-verracos-padre">
          <Col key={index + 207} sm={2} className="cont-medias-edad-verracos">
            <Col key={index + 208} sm={12} className="medias-edad-verracos">
              {data[0]}
            </Col>
          </Col>

          <Col key={index + 108} sm={5} className="cont-medias-edad-verracos">
            <Col key={index + 109} sm={4} className="medias-edad-verracos">
              {Object.keys(ritmoExtraccion).length > 0 ? number_formatter(data[1].menor12.numero, 2, separadorDec, separadorMil) : 0}
            </Col>
            <Col key={index + 110} sm={4} className="medias-edad-verracos">
              {Object.keys(ritmoExtraccion).length > 0 ? number_formatter(data[1].menor12.porcentaje, 2, separadorDec, separadorMil) : 0}
            </Col>
            <Col key={index + 111} sm={4} className="medias-edad-verracos">
              {Object.keys(ritmoExtraccion).length > 0 ? number_formatter(data[1].menor12.media, 2, separadorDec, separadorMil) : 0}
            </Col>
          </Col>
          <Col key={index + 112} sm={5} className="cont-medias-edad-verracos">
            <Col key={index + 113} sm={4} className="medias-edad-verracos">
              {Object.keys(ritmoExtraccion).length > 0 ? number_formatter(data[1].mayor12.numero, 2, separadorDec, separadorMil) : 0}
            </Col>
            <Col key={index + 114} sm={4} className="medias-edad-verracos">
              {Object.keys(ritmoExtraccion).length > 0 ? number_formatter(data[1].mayor12.porcentaje, 2, separadorDec, separadorMil) : 0}
            </Col>
            <Col key={index + 115} sm={4} className="medias-edad-verracos">
              {Object.keys(ritmoExtraccion).length > 0 ? number_formatter(data[1].mayor12.media, 2, separadorDec, separadorMil) : 0}
            </Col>
          </Col>
        </Row>
          } else {
            return undefined
          }
        })
      }
    }

    // PRODUCTIVIDAD VERRACOS - RITMO EXTRACCION -MESES
    let arrayTableMesesIntervalo = []
    let filasMesesIntervalo
    if (ritmoExtraccion.meses) {
      if (this.state.agrupacion === 1) {
        arrayTableMesesIntervalo = ritmoExtraccion.meses
      } else {
        if (!ritmoExtraccion.meses[0]){
          filasMesesIntervalo = Object.entries(ritmoExtraccion.meses).map(function(data, index){
            Object.entries(data[1]).map(function(dat, index){
              return arrayTableMesesIntervalo.push({razaLinea: data[0], mes: dat[1].mes, intervalo: dat[1].intervalo})
            })
          })
        }
      }
    }
    const tableMesesIntervalo = {
      id: 'mesesIntervaloTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      columns: this.state.agrupacion === 1 ? ([
        {id: 'mes', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MES'), type: 'fecha'},
        {id: 'intervalo', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.INTERVALO'), type: 'numero'}
      ]) : ([
        {id: 'razaLinea', name: this.state.agrupacion === 2 ? t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_RAZA') : t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_LINEA')},
        {id: 'mes', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MES'), type: 'fecha'},
        {id: 'intervalo', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.INTERVALO'), type: 'numero'}
      ]),
      rows: Object.values(arrayTableMesesIntervalo),
      initialValues: {}
    }

    // EYACULADOS ELIMINADOS - CAUSA ELMINACION
    let causaEliminacionEyaculadoEliminado = 0
    if (eyaculadoEliminado.causaEliminacion) {
      if (this.state.agrupacion === 1) {
        if (eyaculadoEliminado.causaEliminacion[1]) {
          causaEliminacionEyaculadoEliminado = <Row className="tabla-ritmo-extraccion-body">
          <Col sm={2} className="tabla-ritmo-extraccion-padre-body">
            <Col sm={6} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{Object.keys(eyaculadoEliminado).length > 0 ? number_formatter(eyaculadoEliminado.causaEliminacion[1].total, 2, separadorDec, separadorMil) : 0}</span></Col>
            <Col sm={6} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{Object.keys(eyaculadoEliminado).length > 0 ? number_formatter(eyaculadoEliminado.causaEliminacion[1].media, 2, separadorDec, separadorMil) : 0}</span></Col>
          </Col>
          <Col sm={2} className="tabla-ritmo-extraccion-padre-body">
            <Col sm={6} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{Object.keys(eyaculadoEliminado).length > 0 ? number_formatter(eyaculadoEliminado.causaEliminacion[2].total, 2, separadorDec, separadorMil) : 0}</span></Col>
            <Col sm={6} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{Object.keys(eyaculadoEliminado).length > 0 ? number_formatter(eyaculadoEliminado.causaEliminacion[2].media, 2, separadorDec, separadorMil) : 0}</span></Col>
          </Col>
          <Col sm={2} className="tabla-ritmo-extraccion-padre-body">
            <Col sm={6} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{Object.keys(eyaculadoEliminado).length > 0 ? number_formatter(eyaculadoEliminado.causaEliminacion[3].total, 2, separadorDec, separadorMil) : 0}</span></Col>
            <Col sm={6} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{Object.keys(eyaculadoEliminado).length > 0 ? number_formatter(eyaculadoEliminado.causaEliminacion[3].media, 2, separadorDec, separadorMil) : 0}</span></Col>
          </Col>
          <Col sm={2} className="tabla-ritmo-extraccion-padre-body">
            <Col sm={6} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{Object.keys(eyaculadoEliminado).length > 0 ? number_formatter(eyaculadoEliminado.causaEliminacion[4].total, 2, separadorDec, separadorMil) : 0}</span></Col>
            <Col sm={6} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{Object.keys(eyaculadoEliminado).length > 0 ? number_formatter(eyaculadoEliminado.causaEliminacion[4].media, 2, separadorDec, separadorMil) : 0}</span></Col>
          </Col>
          <Col sm={2} className="tabla-ritmo-extraccion-padre-body">
            <Col sm={6} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{Object.keys(eyaculadoEliminado).length > 0 ? number_formatter(eyaculadoEliminado.causaEliminacion[5].total, 2, separadorDec, separadorMil) : 0}</span></Col>
            <Col sm={6} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{Object.keys(eyaculadoEliminado).length > 0 ? number_formatter(eyaculadoEliminado.causaEliminacion[5].media, 2, separadorDec, separadorMil) : 0}</span></Col>
          </Col>
          <Col sm={2} className="tabla-ritmo-extraccion-padre-body">
            <Col sm={6} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{Object.keys(eyaculadoEliminado).length > 0 ? number_formatter(eyaculadoEliminado.causaEliminacion[6].total, 2, separadorDec, separadorMil) : 0}</span></Col>
            <Col sm={6} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{Object.keys(eyaculadoEliminado).length > 0 ? number_formatter(eyaculadoEliminado.causaEliminacion[6].media, 2, separadorDec, separadorMil) : 0}</span></Col>
          </Col>
        </Row>
        }
      } else {
        causaEliminacionEyaculadoEliminado = Object.entries(eyaculadoEliminado.causaEliminacion).map(function(data, index){
          if (data[0] !== "1" && data[0] !== "2" && data[0] !== "3" && data[0] !== "4" && data[0] !== "5" && data[0] !== "6") {
            return <Row key={index + 116} className="tabla-ritmo-extraccion-body">
          <Col sm={2} style={{padding: 0}}>
            <Col sm={12} className="tabla-ritmo-extraccion-padre-body">
              <Col sm={12} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[0]}</span></Col>
            </Col>
          </Col>
          <Col sm={10} style={{padding: 0}}>
            <Col sm={2} key={index + 117} className="tabla-ritmo-extraccion-padre-body">
              <Col sm={6} key={index + 118} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[1][1] ? number_formatter(data[1][1].total, 2, separadorDec, separadorMil) : 0}</span></Col>
              <Col sm={6} key={index + 119} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[1][1] ? number_formatter(data[1][1].media, 2, separadorDec, separadorMil) : 0}</span></Col>
            </Col>
            <Col sm={2} key={index + 120} className="tabla-ritmo-extraccion-padre-body">
              <Col sm={6} key={index + 121} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[1][2] ? number_formatter(data[1][2].total, 2, separadorDec, separadorMil) : 0}</span></Col>
              <Col sm={6} key={index + 122} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[1][2] ? number_formatter(data[1][2].media, 2, separadorDec, separadorMil) : 0}</span></Col>
            </Col>
            <Col sm={2} key={index + 123} className="tabla-ritmo-extraccion-padre-body">
              <Col sm={6} key={index + 124} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[1][3] ? number_formatter(data[1][3].total, 2, separadorDec, separadorMil) : 0}</span></Col>
              <Col sm={6} key={index + 125} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[1][3] ? number_formatter(data[1][3].media, 2, separadorDec, separadorMil) : 0}</span></Col>
            </Col>
            <Col sm={2} key={index + 126} className="tabla-ritmo-extraccion-padre-body">
              <Col sm={6} key={index + 127} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[1][4] ? number_formatter(data[1][4].total, 2, separadorDec, separadorMil) : 0}</span></Col>
              <Col sm={6} key={index + 128} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[1][4] ? number_formatter(data[1][4].media, 2, separadorDec, separadorMil) : 0}</span></Col>
            </Col>
            <Col sm={2} key={index + 129} className="tabla-ritmo-extraccion-padre-body">
              <Col sm={6} key={index + 130} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[1][5] ? number_formatter(data[1][5].total, 2, separadorDec, separadorMil) : 0}</span></Col>
              <Col sm={6} key={index + 131} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[1][5] ? number_formatter(data[1][5].media, 2, separadorDec, separadorMil) : 0}</span></Col>
            </Col>
            <Col sm={2} key={index + 132} className="tabla-ritmo-extraccion-padre-body">
              <Col sm={6} key={index + 133} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[1][6] ? number_formatter(data[1][6].total, 2, separadorDec, separadorMil) : 0}</span></Col>
              <Col sm={6} key={index + 134} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[1][6] ? number_formatter(data[1][6].media, 2, separadorDec, separadorMil) : 0}</span></Col>
            </Col>
          </Col>
        </Row>
          } else {
            return undefined
          }
        })
      }
    }

    // EYACULADOS ELIMINADOS - MOMENTO ELMINACION
    let momentoEliminacionEyaculadoEliminado = 0
    if (eyaculadoEliminado.momentoEliminacion) {
      if (this.state.agrupacion === 1) {
        if (eyaculadoEliminado.momentoEliminacion.Analisis || eyaculadoEliminado.momentoEliminacion.Dilucion || eyaculadoEliminado.momentoEliminacion.Envasado) {
          momentoEliminacionEyaculadoEliminado = <Row className="tabla-ritmo-extraccion-body">
          <Col sm={4} className="tabla-ritmo-extraccion-padre-body">
            <Col sm={4} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{Object.keys(eyaculadoEliminado).length > 0 ? number_formatter(eyaculadoEliminado.momentoEliminacion.Analisis.total, 2, separadorDec, separadorMil) : 0}</span></Col>
            <Col sm={4} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{Object.keys(eyaculadoEliminado).length > 0 ? number_formatter(eyaculadoEliminado.momentoEliminacion.Analisis.eliminados, 2, separadorDec, separadorMil) : 0}</span></Col>
            <Col sm={4} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{Object.keys(eyaculadoEliminado).length > 0 ? number_formatter(eyaculadoEliminado.momentoEliminacion.Analisis.porcentaje, 2, separadorDec, separadorMil) : 0}</span></Col>
          </Col>
          <Col sm={4} className="tabla-ritmo-extraccion-padre-body">
            <Col sm={4} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{Object.keys(eyaculadoEliminado).length > 0 ? number_formatter(eyaculadoEliminado.momentoEliminacion.Dilucion.total, 2, separadorDec, separadorMil) : 0}</span></Col>
            <Col sm={4} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{Object.keys(eyaculadoEliminado).length > 0 ? number_formatter(eyaculadoEliminado.momentoEliminacion.Dilucion.eliminados, 2, separadorDec, separadorMil) : 0}</span></Col>
            <Col sm={4} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{Object.keys(eyaculadoEliminado).length > 0 ? number_formatter(eyaculadoEliminado.momentoEliminacion.Dilucion.porcentaje, 2, separadorDec, separadorMil) : 0}</span></Col>
          </Col>
          <Col sm={4} className="tabla-ritmo-extraccion-padre-body">
            <Col sm={4} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{Object.keys(eyaculadoEliminado).length > 0 ? number_formatter(eyaculadoEliminado.momentoEliminacion.Envasado.total, 2, separadorDec, separadorMil) : 0}</span></Col>
            <Col sm={4} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{Object.keys(eyaculadoEliminado).length > 0 ? number_formatter(eyaculadoEliminado.momentoEliminacion.Envasado.eliminados, 2, separadorDec, separadorMil) : 0}</span></Col>
            <Col sm={4} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{Object.keys(eyaculadoEliminado).length > 0 ? number_formatter(eyaculadoEliminado.momentoEliminacion.Envasado.porcentaje, 2, separadorDec, separadorMil) : 0}</span></Col>
          </Col>
        </Row>
        }
      } else {
        momentoEliminacionEyaculadoEliminado = Object.entries(eyaculadoEliminado.momentoEliminacion).map(function(data, index){
          if (data[0] !== "Analisis" && data[0] !== "Dilucion" && data[0] !== "Envasado") {
            return <Row key={index + 135} className="tabla-ritmo-extraccion-body">
          <Col sm={2} style={{padding: 0}}>
            <Col sm={12} className="tabla-ritmo-extraccion-padre-body">
              <Col sm={12} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[0]}</span></Col>
            </Col>
          </Col>
          <Col sm={10} style={{padding: 0}}>
            <Col sm={4} key={index + 136} className="tabla-ritmo-extraccion-padre-body">
              <Col sm={4} key={index + 137} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[1].Analisis ? number_formatter(data[1].Analisis.total, 2, separadorDec, separadorMil) : 0}</span></Col>
              <Col sm={4} key={index + 138} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[1].Analisis ? number_formatter(data[1].Analisis.eliminados, 2, separadorDec, separadorMil) : 0}</span></Col>
              <Col sm={4} key={index + 139} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[1].Analisis ? number_formatter(data[1].Analisis.porcentaje, 2, separadorDec, separadorMil) : 0}</span></Col>
            </Col>
            <Col sm={4} key={index + 140} className="tabla-ritmo-extraccion-padre-body">
              <Col sm={4} key={index + 141} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[1].Dilucion ? number_formatter(data[1].Dilucion.total, 2, separadorDec, separadorMil) : 0}</span></Col>
              <Col sm={4} key={index + 142} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[1].Dilucion ? number_formatter(data[1].Dilucion.eliminados, 2, separadorDec, separadorMil) : 0}</span></Col>
              <Col sm={4} key={index + 143} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[1].Dilucion ? number_formatter(data[1].Dilucion.porcentaje, 2, separadorDec, separadorMil) : 0}</span></Col>
            </Col>
            <Col sm={4} key={index + 144} className="tabla-ritmo-extraccion-padre-body">
              <Col sm={4} key={index + 145} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[1].Envasado ? number_formatter(data[1].Envasado.total, 2, separadorDec, separadorMil) : 0}</span></Col>
              <Col sm={4} key={index + 146} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[1].Envasado ? number_formatter(data[1].Envasado.eliminados, 2, separadorDec, separadorMil) : 0}</span></Col>
              <Col sm={4} key={index + 147} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[1].Envasado ? number_formatter(data[1].Envasado.porcentaje, 2, separadorDec, separadorMil) : 0}</span></Col>
            </Col>
          </Col>
        </Row>
          } else {
            return undefined
          }
        })
      }
    }

    // EYACULADOS ELIMINADOS - ELIMINADOS MES
    let arrayTableEliminadosMes = []
    let filasEliminadosMes
    if (eyaculadoEliminado.eliminadosMes) {
      if (this.state.agrupacion === 1) {
        arrayTableEliminadosMes = eyaculadoEliminado.eliminadosMes
      } else {
        if (!eyaculadoEliminado.eliminadosMes[0]){
          filasEliminadosMes = Object.entries(eyaculadoEliminado.eliminadosMes).map(function(data, index){
            Object.entries(data[1]).map(function(dat, index){
              return arrayTableEliminadosMes.push({razaLinea: data[0], fecha: dat[0], total: dat[1].total, media: dat[1].media})
            })
          })
        }
      }
    }
    const tableEliminadosMes = {
      id: 'eliminadosMesTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      columns: this.state.agrupacion === 1 ? ([
        {id: 'fecha', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.FECHAS'), type: 'fecha'},
        {id: 'total', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL'), type: 'numero'},
        {id: 'media', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE'), type: 'numero'}
      ]) : ([
        {id: 'razaLinea', name: this.state.agrupacion === 2 ? t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_RAZA') : t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_LINEA')},
        {id: 'fecha', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.FECHAS'), type: 'fecha'},
        {id: 'total', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL'), type: 'numero'},
        {id: 'media', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE'), type: 'numero'}
      ]),
      rows: Object.values(arrayTableEliminadosMes),
      initialValues: {},
    }

    // EBV - INDEX
    let ebvIndex = 0
    if (Object.keys(ebv).length > 0) {
      if (this.state.agrupacion === 1) {
        if (ebv.entre0100 || ebv.entre100110 || ebv.entre110120 || ebv.entre120130 || ebv.entre130140 || ebv.entre140150 || ebv.entre150160 || ebv.entre160170 || ebv.entre170180) {
          ebvIndex = <Row className="tabla-ritmo-extraccion-body">
          <Col sm={1} className="tabla-ritmo-extraccion-padre-body productividad-verraco-index">
            <Col sm={6} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{Object.keys(ebv).length > 0 ? number_formatter(ebv.entre0100.numero, 2, separadorDec, separadorMil) : 0}</span></Col>
            <Col sm={6} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{Object.keys(ebv).length > 0 ? number_formatter(ebv.entre0100.porcentaje, 2, separadorDec, separadorMil) : 0}</span></Col>
          </Col>
          <Col sm={1} className="tabla-ritmo-extraccion-padre-body productividad-verraco-index">
            <Col sm={6} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{Object.keys(ebv).length > 0 ? number_formatter(ebv.entre100110.numero, 2, separadorDec, separadorMil) : 0}</span></Col>
            <Col sm={6} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{Object.keys(ebv).length > 0 ? number_formatter(ebv.entre100110.porcentaje, 2, separadorDec, separadorMil) : 0}</span></Col>
          </Col>
          <Col sm={1} className="tabla-ritmo-extraccion-padre-body productividad-verraco-index">
            <Col sm={6} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{Object.keys(ebv).length > 0 ? number_formatter(ebv.entre110120.numero, 2, separadorDec, separadorMil) : 0}</span></Col>
            <Col sm={6} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{Object.keys(ebv).length > 0 ? number_formatter(ebv.entre110120.porcentaje, 2, separadorDec, separadorMil) : 0}</span></Col>
          </Col>
          <Col sm={1} className="tabla-ritmo-extraccion-padre-body productividad-verraco-index">
            <Col sm={6} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{Object.keys(ebv).length > 0 ? number_formatter(ebv.entre120130.numero, 2, separadorDec, separadorMil) : 0}</span></Col>
            <Col sm={6} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{Object.keys(ebv).length > 0 ? number_formatter(ebv.entre120130.porcentaje, 2, separadorDec, separadorMil) : 0}</span></Col>
          </Col>
          <Col sm={1} className="tabla-ritmo-extraccion-padre-body productividad-verraco-index">
            <Col sm={6} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{Object.keys(ebv).length > 0 ? number_formatter(ebv.entre130140.numero, 2, separadorDec, separadorMil) : 0}</span></Col>
            <Col sm={6} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{Object.keys(ebv).length > 0 ? number_formatter(ebv.entre130140.porcentaje, 2, separadorDec, separadorMil) : 0}</span></Col>
          </Col>
          <Col sm={1} className="tabla-ritmo-extraccion-padre-body productividad-verraco-index">
            <Col sm={6} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{Object.keys(ebv).length > 0 ? number_formatter(ebv.entre140150.numero, 2, separadorDec, separadorMil) : 0}</span></Col>
            <Col sm={6} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{Object.keys(ebv).length > 0 ? number_formatter(ebv.entre140150.porcentaje, 2, separadorDec, separadorMil) : 0}</span></Col>
          </Col>
          <Col sm={1} className="tabla-ritmo-extraccion-padre-body productividad-verraco-index">
            <Col sm={6} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{Object.keys(ebv).length > 0 ? number_formatter(ebv.entre150160.numero, 2, separadorDec, separadorMil) : 0}</span></Col>
            <Col sm={6} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{Object.keys(ebv).length > 0 ? number_formatter(ebv.entre150160.porcentaje, 2, separadorDec, separadorMil) : 0}</span></Col>
          </Col>
          <Col sm={1} className="tabla-ritmo-extraccion-padre-body productividad-verraco-index">
            <Col sm={6} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{Object.keys(ebv).length > 0 ? number_formatter(ebv.entre160170.numero, 2, separadorDec, separadorMil) : 0}</span></Col>
            <Col sm={6} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{Object.keys(ebv).length > 0 ? number_formatter(ebv.entre160170.porcentaje, 2, separadorDec, separadorMil) : 0}</span></Col>
          </Col>
          <Col sm={1} className="tabla-ritmo-extraccion-padre-body productividad-verraco-index">
            <Col sm={6} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{Object.keys(ebv).length > 0 ? number_formatter(ebv.entre170180.numero, 2, separadorDec, separadorMil) : 0}</span></Col>
            <Col sm={6} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{Object.keys(ebv).length > 0 ? number_formatter(ebv.entre170180.porcentaje, 2, separadorDec, separadorMil) : 0}</span></Col>
          </Col>
          <Col sm={1} className="tabla-ritmo-extraccion-padre-body productividad-verraco-index">
            <Col sm={6} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{Object.keys(ebv).length > 0 ? number_formatter(ebv.mayor180.numero, 2, separadorDec, separadorMil) : 0}</span></Col>
            <Col sm={6} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{Object.keys(ebv).length > 0 ? number_formatter(ebv.mayor180.porcentaje, 2, separadorDec, separadorMil) : 0}</span></Col>
          </Col>
        </Row>
        }
      } else {
        ebvIndex = Object.entries(ebv).map(function(data, index){
          if (data[0] !== "entre0100" && data[0] !== "entre100110" && data[0] !== "entre110120" && data[0] !== "entre120130" && data[0] !== "entre130140" && data[0] !== "entre140150" && data[0] !== "entre150160" && data[0] !== "entre160170" && data[0] !== "entre170180") {
            return <Row key={index + 41} className="tabla-ritmo-extraccion-body">
            <Col sm={2} style={{padding: 0}}>
              <Col sm={12} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[0]}</span></Col>
            </Col>
            <Col sm={10} style={{padding: 0}}>
            <Col sm={1} key={index + 2} className="tabla-ritmo-extraccion-padre-body productividad-verraco-index">
              <Col sm={6} key={index + 3} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[1].entre0100 ? number_formatter(data[1].entre0100.numero, 2, separadorDec, separadorMil) : 0}</span></Col>
              <Col sm={6} key={index + 4} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[1].entre0100 ? number_formatter(data[1].entre0100.porcentaje, 2, separadorDec, separadorMil) : 0}</span></Col>
            </Col>
            <Col sm={1} key={index + 5} className="tabla-ritmo-extraccion-padre-body productividad-verraco-index">
              <Col sm={6} key={index + 6} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[1].entre100110 ? number_formatter(data[1].entre100110.numero, 2, separadorDec, separadorMil) : 0}</span></Col>
              <Col sm={6} key={index + 7} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[1].entre100110 ? number_formatter(data[1].entre100110.porcentaje, 2, separadorDec, separadorMil) : 0}</span></Col>
            </Col>
            <Col sm={1} key={index + 8} className="tabla-ritmo-extraccion-padre-body productividad-verraco-index">
              <Col sm={6} key={index + 9} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[1].entre110120 ? number_formatter(data[1].entre110120.numero, 2, separadorDec, separadorMil) : 0}</span></Col>
              <Col sm={6} key={index + 10} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[1].entre110120 ? number_formatter(data[1].entre110120.porcentaje, 2, separadorDec, separadorMil) : 0}</span></Col>
            </Col>
            <Col sm={1} key={index + 11} className="tabla-ritmo-extraccion-padre-body productividad-verraco-index">
              <Col sm={6} key={index + 12} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[1].entre120130 ? number_formatter(data[1].entre120130.numero, 2, separadorDec, separadorMil) : 0}</span></Col>
              <Col sm={6} key={index + 13} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[1].entre120130 ? number_formatter(data[1].entre120130.porcentaje, 2, separadorDec, separadorMil) : 0}</span></Col>
            </Col>
            <Col sm={1} key={index + 14} className="tabla-ritmo-extraccion-padre-body productividad-verraco-index">
              <Col sm={6} key={index + 15} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[1].entre130140 ? number_formatter(data[1].entre130140.numero, 2, separadorDec, separadorMil) : 0}</span></Col>
              <Col sm={6} key={index + 16} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[1].entre130140 ? number_formatter(data[1].entre130140.porcentaje, 2, separadorDec, separadorMil) : 0}</span></Col>
            </Col>
            <Col sm={1} key={index + 17} className="tabla-ritmo-extraccion-padre-body productividad-verraco-index">
              <Col sm={6} key={index + 18} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[1].entre140150 ? number_formatter(data[1].entre140150.numero, 2, separadorDec, separadorMil) : 0}</span></Col>
              <Col sm={6} key={index + 19} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[1].entre140150 ? number_formatter(data[1].entre140150.porcentaje, 2, separadorDec, separadorMil) : 0}</span></Col>
            </Col>
            <Col sm={1} key={index + 20} className="tabla-ritmo-extraccion-padre-body productividad-verraco-index">
              <Col sm={6} key={index + 21} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[1].entre150160 ? number_formatter(data[1].entre150160.numero, 2, separadorDec, separadorMil) : 0}</span></Col>
              <Col sm={6} key={index + 22} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[1].entre150160 ? number_formatter(data[1].entre150160.porcentaje, 2, separadorDec, separadorMil) : 0}</span></Col>
            </Col>
            <Col sm={1} key={index + 23} className="tabla-ritmo-extraccion-padre-body productividad-verraco-index">
              <Col sm={6} key={index + 24} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[1].entre160170 ? number_formatter(data[1].entre160170.numero, 2, separadorDec, separadorMil) : 0}</span></Col>
              <Col sm={6} key={index + 25} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[1].entre160170 ? number_formatter(data[1].entre160170.porcentaje, 2, separadorDec, separadorMil) : 0}</span></Col>
            </Col>
            <Col sm={1} key={index + 26} className="tabla-ritmo-extraccion-padre-body productividad-verraco-index">
              <Col sm={6} key={index + 27} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[1].entre170180 ? number_formatter(data[1].entre170180.numero, 2, separadorDec, separadorMil) : 0}</span></Col>
              <Col sm={6} key={index + 28} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[1].entre170180 ? number_formatter(data[1].entre170180.porcentaje, 2, separadorDec, separadorMil) : 0}</span></Col>
            </Col>
            <Col sm={1} key={index + 29} className="tabla-ritmo-extraccion-padre-body productividad-verraco-index">
              <Col sm={6} key={index + 30} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[1].mayor180 ? number_formatter(data[1].mayor180.numero, 2, separadorDec, separadorMil) : 0}</span></Col>
              <Col sm={6} key={index + 31} className="tabla-ritmo-extraccion-padre-body"><span className="ritmo-extraccion-body">{data[1].mayor180 ? number_formatter(data[1].mayor180.porcentaje, 2, separadorDec, separadorMil) : 0}</span></Col>
            </Col>
          </Col>
        </Row>
          } else {
            return undefined
          }
        })
      }
    }

    // PRODUCTIVIDAD DE CENTRO - NUMERO DE EXTRACCIONES - DIAS SEMANA
    let prodCentNumExtraccionesDiasSemana = 0
    if (productividadCentro.numeroExtracciones) {
      if (this.state.agrupacion === 1) {
        if (productividadCentro.numeroExtracciones.diaSemana[0] || productividadCentro.numeroExtracciones.diaSemana[1] || productividadCentro.numeroExtracciones.diaSemana[2] || productividadCentro.numeroExtracciones.diaSemana[3] || productividadCentro.numeroExtracciones.diaSemana[4] || productividadCentro.numeroExtracciones.diaSemana[5] || productividadCentro.numeroExtracciones.diaSemana[6]) {
          prodCentNumExtraccionesDiasSemana = <Row className="tabla-productividad-centro-body">
          <Col sm={2} className="tabla-productividad-centro-padre-body col-7">
            <Col sm={4} className="tabla-productividad-centro-padre-body">
              <span className="productividad-centro-body">{productividadCentro.numeroExtracciones ? number_formatter(productividadCentro.numeroExtracciones.diaSemana[0].total, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
            <Col sm={4} className="tabla-productividad-centro-padre-body">
              <span className="productividad-centro-body">{productividadCentro.numeroExtracciones ? number_formatter(productividadCentro.numeroExtracciones.diaSemana[0].porcentaje, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
            <Col sm={4} className="tabla-productividad-centro-padre-body">
              <span className="productividad-centro-body">{productividadCentro.numeroExtracciones ? number_formatter(productividadCentro.numeroExtracciones.diaSemana[0].media, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
          </Col>
          <Col sm={2} className="tabla-productividad-centro-padre-body col-7">
            <Col sm={4} className="tabla-productividad-centro-padre-body">
              <span className="productividad-centro-body">{productividadCentro.numeroExtracciones ? number_formatter(productividadCentro.numeroExtracciones.diaSemana[1].total, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
            <Col sm={4} className="tabla-productividad-centro-padre-body">
              <span className="productividad-centro-body">{productividadCentro.numeroExtracciones ? number_formatter(productividadCentro.numeroExtracciones.diaSemana[1].porcentaje, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
            <Col sm={4} className="tabla-productividad-centro-padre-body">
              <span className="productividad-centro-body">{productividadCentro.numeroExtracciones ? number_formatter(productividadCentro.numeroExtracciones.diaSemana[1].media, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
          </Col>
          <Col sm={2} className="tabla-productividad-centro-padre-body col-7">
            <Col sm={4} className="tabla-productividad-centro-padre-body">
              <span className="productividad-centro-body">{productividadCentro.numeroExtracciones ? number_formatter(productividadCentro.numeroExtracciones.diaSemana[2].total, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
            <Col sm={4} className="tabla-productividad-centro-padre-body">
              <span className="productividad-centro-body">{productividadCentro.numeroExtracciones ? number_formatter(productividadCentro.numeroExtracciones.diaSemana[2].porcentaje, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
            <Col sm={4} className="tabla-productividad-centro-padre-body">
              <span className="productividad-centro-body">{productividadCentro.numeroExtracciones ? number_formatter(productividadCentro.numeroExtracciones.diaSemana[2].media, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
          </Col>
          <Col sm={2} className="tabla-productividad-centro-padre-body col-7">
            <Col sm={4} className="tabla-productividad-centro-padre-body">
              <span className="productividad-centro-body">{productividadCentro.numeroExtracciones ? number_formatter(productividadCentro.numeroExtracciones.diaSemana[3].total, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
            <Col sm={4} className="tabla-productividad-centro-padre-body">
              <span className="productividad-centro-body">{productividadCentro.numeroExtracciones ? number_formatter(productividadCentro.numeroExtracciones.diaSemana[3].porcentaje, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
            <Col sm={4} className="tabla-productividad-centro-padre-body">
              <span className="productividad-centro-body">{productividadCentro.numeroExtracciones ? number_formatter(productividadCentro.numeroExtracciones.diaSemana[3].media, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
          </Col>
          <Col sm={2} className="tabla-productividad-centro-padre-body col-7">
            <Col sm={4} className="tabla-productividad-centro-padre-body">
              <span className="productividad-centro-body">{productividadCentro.numeroExtracciones ? number_formatter(productividadCentro.numeroExtracciones.diaSemana[4].total, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
            <Col sm={4} className="tabla-productividad-centro-padre-body">
              <span className="productividad-centro-body">{productividadCentro.numeroExtracciones ? number_formatter(productividadCentro.numeroExtracciones.diaSemana[4].porcentaje, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
            <Col sm={4} className="tabla-productividad-centro-padre-body">
              <span className="productividad-centro-body">{productividadCentro.numeroExtracciones ? number_formatter(productividadCentro.numeroExtracciones.diaSemana[4].media, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
          </Col>
          <Col sm={2} className="tabla-productividad-centro-padre-body col-7">
            <Col sm={4} className="tabla-productividad-centro-padre-body">
              <span className="productividad-centro-body">{productividadCentro.numeroExtracciones ? number_formatter(productividadCentro.numeroExtracciones.diaSemana[5].total, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
            <Col sm={4} className="tabla-productividad-centro-padre-body">
              <span className="productividad-centro-body">{productividadCentro.numeroExtracciones ? number_formatter(productividadCentro.numeroExtracciones.diaSemana[5].porcentaje, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
            <Col sm={4} className="tabla-productividad-centro-padre-body">
              <span className="productividad-centro-body">{productividadCentro.numeroExtracciones ? number_formatter(productividadCentro.numeroExtracciones.diaSemana[5].media, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
          </Col>
          <Col sm={2} className="tabla-productividad-centro-padre-body col-7">
            <Col sm={4} className="tabla-productividad-centro-padre-body">
              <span className="productividad-centro-body">{productividadCentro.numeroExtracciones ? number_formatter(productividadCentro.numeroExtracciones.diaSemana[6].total, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
            <Col sm={4} className="tabla-productividad-centro-padre-body">
              <span className="productividad-centro-body">{productividadCentro.numeroExtracciones ? number_formatter(productividadCentro.numeroExtracciones.diaSemana[6].porcentaje, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
            <Col sm={4} className="tabla-productividad-centro-padre-body">
              <span className="productividad-centro-body">{productividadCentro.numeroExtracciones ? number_formatter(productividadCentro.numeroExtracciones.diaSemana[6].media, 2, separadorDec, separadorMil) : 0}</span>
            </Col>
          </Col>
        </Row>
        }
      } else {
        prodCentNumExtraccionesDiasSemana = Object.entries(productividadCentro.numeroExtracciones.diaSemana).map(function(data, index){
          if (data[0] !== '0' && data[0] !== '1' && data[0] !== '2' && data[0] !== '3' && data[0] !== '4' && data[0] !== '5' && data[0] !== '6') {
            return <Row key={index + 147} className="tabla-productividad-centro-body">
          <Col sm={2} style={{padding: 0}}>
            <Col sm={12} className="tabla-productividad-centro-padre-body"><span className="productividad-centro-body">{data[0]}</span></Col>
          </Col>
          <Col sm={10} style={{padding: 0}}>
            <Col key={index + 148} sm={2} className="tabla-productividad-centro-padre-body col-7">
              <Col key={index + 149} sm={4} className="tabla-productividad-centro-padre-body">
                <span className="productividad-centro-body">{data[1][0] ? number_formatter(data[1][0].total, 2, separadorDec, separadorMil) : 0}</span>
              </Col>
              <Col key={index + 150} sm={4} className="tabla-productividad-centro-padre-body">
                <span className="productividad-centro-body">{data[1][0] ? number_formatter(data[1][0].porcentaje, 2, separadorDec, separadorMil) : 0}</span>
              </Col>
              <Col key={index + 151} sm={4} className="tabla-productividad-centro-padre-body">
                <span className="productividad-centro-body">{data[1][0] ? number_formatter(data[1][0].media, 2, separadorDec, separadorMil) : 0}</span>
              </Col>
            </Col>
            <Col key={index + 152} sm={2} className="tabla-productividad-centro-padre-body col-7">
              <Col key={index + 153} sm={4} className="tabla-productividad-centro-padre-body">
                <span className="productividad-centro-body">{data[1][1] ? number_formatter(data[1][1].total, 2, separadorDec, separadorMil) : 0}</span>
              </Col>
              <Col key={index + 154} sm={4} className="tabla-productividad-centro-padre-body">
                <span className="productividad-centro-body">{data[1][1] ? number_formatter(data[1][1].porcentaje, 2, separadorDec, separadorMil) : 0}</span>
              </Col>
              <Col key={index + 155} sm={4} className="tabla-productividad-centro-padre-body">
                <span className="productividad-centro-body">{data[1][1] ? number_formatter(data[1][1].media, 2, separadorDec, separadorMil) : 0}</span>
              </Col>
            </Col>
            <Col key={index + 156} sm={2} className="tabla-productividad-centro-padre-body col-7">
              <Col key={index + 157} sm={4} className="tabla-productividad-centro-padre-body">
                <span className="productividad-centro-body">{data[1][2] ? number_formatter(data[1][2].total, 2, separadorDec, separadorMil) : 0}</span>
              </Col>
              <Col key={index + 158} sm={4} className="tabla-productividad-centro-padre-body">
                <span className="productividad-centro-body">{data[1][2] ? number_formatter(data[1][2].porcentaje, 2, separadorDec, separadorMil) : 0}</span>
              </Col>
              <Col key={index + 150} sm={4} className="tabla-productividad-centro-padre-body">
                <span className="productividad-centro-body">{data[1][2] ? number_formatter(data[1][2].media, 2, separadorDec, separadorMil) : 0}</span>
              </Col>
            </Col>
            <Col key={index + 160} sm={2} className="tabla-productividad-centro-padre-body col-7">
              <Col key={index + 161} sm={4} className="tabla-productividad-centro-padre-body">
                <span className="productividad-centro-body">{data[1][3] ? number_formatter(data[1][3].total, 2, separadorDec, separadorMil) : 0}</span>
              </Col>
              <Col key={index + 162} sm={4} className="tabla-productividad-centro-padre-body">
                <span className="productividad-centro-body">{data[1][3] ? number_formatter(data[1][3].porcentaje, 2, separadorDec, separadorMil) : 0}</span>
              </Col>
              <Col key={index + 163} sm={4} className="tabla-productividad-centro-padre-body">
                <span className="productividad-centro-body">{data[1][3] ? number_formatter(data[1][3].media, 2, separadorDec, separadorMil) : 0}</span>
              </Col>
            </Col>
            <Col key={index + 164} sm={2} className="tabla-productividad-centro-padre-body col-7">
              <Col key={index + 165} sm={4} className="tabla-productividad-centro-padre-body">
                <span className="productividad-centro-body">{data[1][4] ? number_formatter(data[1][4].total, 2, separadorDec, separadorMil) : 0}</span>
              </Col>
              <Col key={index + 166} sm={4} className="tabla-productividad-centro-padre-body">
                <span className="productividad-centro-body">{data[1][4] ? number_formatter(data[1][4].porcentaje, 2, separadorDec, separadorMil) : 0}</span>
              </Col>
              <Col key={index + 167} sm={4} className="tabla-productividad-centro-padre-body">
                <span className="productividad-centro-body">{data[1][4] ? number_formatter(data[1][4].media, 2, separadorDec, separadorMil) : 0}</span>
              </Col>
            </Col>
            <Col key={index + 168} sm={2} className="tabla-productividad-centro-padre-body col-7">
              <Col key={index + 23} sm={4} className="tabla-productividad-centro-padre-body">
                <span className="productividad-centro-body">{data[1][5] ? number_formatter(data[1][5].total, 2, separadorDec, separadorMil) : 0}</span>
              </Col>
              <Col key={index + 169} sm={4} className="tabla-productividad-centro-padre-body">
                <span className="productividad-centro-body">{data[1][5] ? number_formatter(data[1][5].porcentaje, 2, separadorDec, separadorMil) : 0}</span>
              </Col>
              <Col key={index + 170} sm={4} className="tabla-productividad-centro-padre-body">
                <span className="productividad-centro-body">{data[1][5] ? number_formatter(data[1][5].media, 2, separadorDec, separadorMil) : 0}</span>
              </Col>
            </Col>
            <Col key={index + 171} sm={2} className="tabla-productividad-centro-padre-body col-7">
              <Col key={index + 172} sm={4} className="tabla-productividad-centro-padre-body">
                <span className="productividad-centro-body">{data[1][6] ? number_formatter(data[1][6].total, 2, separadorDec, separadorMil) : 0}</span>
              </Col>
              <Col key={index + 173} sm={4} className="tabla-productividad-centro-padre-body">
                <span className="productividad-centro-body">{data[1][6] ? number_formatter(data[1][6].porcentaje, 2, separadorDec, separadorMil) : 0}</span>
              </Col>
              <Col key={index + 174} sm={4} className="tabla-productividad-centro-padre-body">
                <span className="productividad-centro-body">{data[1][6] ? number_formatter(data[1][6].media, 2, separadorDec, separadorMil) : 0}</span>
              </Col>
            </Col>
          </Col>
        </Row>
          } else {
            return undefined
          }
        })
      }
    }

    // PRODUCTIVIDAD DE CENTRO - NUMERO DE EXTRACCIONES - PERIODO TIEMPO
    let arrayTablePeriodoTiempo = []
    let filasPeriodoTiempo
    if (productividadCentro.numeroExtracciones) {
      if (this.state.agrupacion === 1) {
        arrayTablePeriodoTiempo = productividadCentro.numeroExtracciones.periodoTiempo
      } else {
        if (!productividadCentro.numeroExtracciones.periodoTiempo[0]){
          filasPeriodoTiempo = Object.entries(productividadCentro.numeroExtracciones.periodoTiempo).map(function(data, index){
            Object.entries(data[1]).map(function(dat, index){
              return arrayTablePeriodoTiempo.push({razaLinea: data[0], annoMes: dat[0], total: dat[1].total, porcentaje: dat[1].porcentaje})
            })
          })
        }
      }
    }
    const tablePeriodoTiempo = {
      id: 'periodoTiempoTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      columns: this.state.agrupacion === 1 ? ([
        {id: 'annoMes', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.FECHAS'), type: 'fecha'},
        {id: 'total', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL'), type: 'numero'},
        {id: 'porcentaje', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE'), type: 'numero'}
      ]) : ([
        {id: 'razaLinea', name: this.state.agrupacion === 2 ? t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_RAZA') : t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_LINEA')},
        {id: 'annoMes', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.FECHAS'), type: 'fecha'},
        {id: 'total', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL'), type: 'numero'},
        {id: 'porcentaje', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE'), type: 'numero'}
      ]),
      rows: Object.values(arrayTablePeriodoTiempo),
      initialValues: {},
    }

    // PRODUCTIVIDAD DE CENTRO - NUMERO DE DOSIS - EXTRACCIONES
    let arrayTablemesesExtracciones = []
    let filasmesesExtracciones
    if (productividadCentro.numeroDosis) {
      if (this.state.agrupacion === 1) {
        arrayTablemesesExtracciones = productividadCentro.numeroDosis.extracciones
      } else {
        if (!productividadCentro.numeroDosis.extracciones[0]){
          filasmesesExtracciones = Object.entries(productividadCentro.numeroDosis.extracciones).map(function(data, index){
            Object.entries(data[1]).map(function(dat, index){
              return arrayTablemesesExtracciones.push({razaLinea: data[0], mesAnno: dat[0], dosis: dat[1].dosis})
            })
          })
        }
      }
    }
    const tablemesesExtracciones = {
      id: 'mesesExtraccionesTable',
      multiHeader: Object.keys(productividadCentro).length > 0 ? true : false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      columns: this.state.agrupacion === 1 ? ([
        {id: 'mesAnno', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.FECHA'), type: 'fecha'},
        {id: 'dosis', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.DOSIS'), borderDobleHeadeClass: true, children: [
          {id: 'totales', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL'), borderDobleHeadeClass: true},
          {id: 'porcentaje', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE'), type:'numero', borderDobleHeadeClass: true}
        ]}
      ]) : ([
        {id: 'razaLinea', name: this.state.agrupacion === 2 ? t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_RAZA') : t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_LINEA')},
        {id: 'mesAnno', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.FECHA'), type: 'fecha'},
        {id: 'dosis', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.DOSIS'), borderDobleHeadeClass: true, children: [
          {id: 'totales', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL'), borderDobleHeadeClass: true},
          {id: 'porcentaje', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE'), type:'numero', borderDobleHeadeClass: true}
        ]}
      ]),
      rows: Object.values(arrayTablemesesExtracciones),
      initialValues: {}
    }

    // PRODUCTIVIDAD DE CENTRO - NUMERO DE DOSIS - POOL
    let arrayTablemesesPool = []
    let filasmesesPool
    if (productividadCentro.numeroDosis) {
      if (this.state.agrupacion === 1) {
        arrayTablemesesPool = productividadCentro.numeroDosis.pool
      } else {
        if (!productividadCentro.numeroDosis.pool[0]){
          filasmesesPool = Object.entries(productividadCentro.numeroDosis.pool).map(function(data, index){
            Object.entries(data[1]).map(function(dat, index){
              return arrayTablemesesPool.push({razaLinea: data[0], mesAnno: dat[0], dosis: dat[1].dosis})
            })
          })
        }
      }
    }
    const tablemesesPool = {
      id: 'mesesPoolTable',
      multiHeader: Object.keys(productividadCentro).length > 0 ? true : false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      columns: this.state.agrupacion === 1 ? ([
        {id: 'mesAnno', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.FECHA'), type: 'fecha'},
        {id: 'dosis', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.DOSIS'), borderDobleHeadeClass: true, children: [
          {id: 'totales', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL'), borderDobleHeadeClass: true},
          {id: 'porcentaje', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE'), type:'numero', borderDobleHeadeClass: true}
        ]}
      ]) : ([
        {id: 'razaLinea', name: this.state.agrupacion === 2 ? t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_RAZA') : t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_LINEA')},
        {id: 'mesAnno', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.FECHA'), type: 'fecha'},
        {id: 'dosis', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.DOSIS'), borderDobleHeadeClass: true, children: [
          {id: 'totales', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL'), borderDobleHeadeClass: true},
          {id: 'porcentaje', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE'), type:'numero', borderDobleHeadeClass: true}
        ]}
      ]),
      rows: Object.values(arrayTablemesesPool),
      initialValues: {}
    }

    // PRODUCTIVIDAD DE CENTRO - NUMERO DE DOSIS - TIPO DOSIS
    let fechasmesesTipoDosis = []
    let cabeceramesesTipoDosis = []
    let filasmesesTipoDosis = []
    if (Object.keys(productividadCentro).length > 0) {
      if (Object.keys(productividadCentro.numeroDosis.tipoDosis).length > 0) {
        fechasmesesTipoDosis = Object.values(productividadCentro.numeroDosis.tipoDosis).map((data) => {
          return data.fechas
        })
        cabeceramesesTipoDosis = fechasmesesTipoDosis[0].map((data) => {
          return {id: data.fecha, name: data.fecha, borderDobleHeadeClass: true, children: [
              {id: 'total', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL'), borderDobleHeadeClass: true, type: 'numero'},
              {id: 'porcentaje', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE'), borderDobleHeadeClass: true, type: 'numero'}
            ]
          }
        })

        filasmesesTipoDosis = Object.values(productividadCentro.numeroDosis.tipoDosis).map((data) => {
          let date = data.fechas.forEach(function(f, index){
            data[f.fecha] = {porcentaje: f.porcentaje, total: f.total}
          })
          return data
        })
      }
    }

    if (this.state.agrupacion === 2) {
      cabeceramesesTipoDosis.unshift({id: 'nombreRaza', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_RAZA')})
    }
    if (this.state.agrupacion === 3) {
      cabeceramesesTipoDosis.unshift({id: 'nombreLinea', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_LINEA')})
    }
    cabeceramesesTipoDosis.unshift({id: 'tipoDosis', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TIPO_DOSIS')})

    const tablemesesTipoDosis = {
      id: 'mesesTipoDosisTable',
      multiHeader: true,
      multiHeaderChild: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      columns: cabeceramesesTipoDosis,
      rows: filasmesesTipoDosis,
      initialValues: {}
    }

    // PRODUCTIVIDAD DE CENTRO - NUMERO DE DOSIS - DIAS SEMANA
    let fechasmesesDiasSemana = []
    let cabeceramesesDiasSemana = []
    let filasmesesDiasSemana = []
    if (Object.keys(productividadCentro).length > 0) {
      if (Object.keys(productividadCentro.numeroDosis.diaSemana).length > 0) {
        fechasmesesDiasSemana = Object.values(productividadCentro.numeroDosis.diaSemana).map((data) => {
          return data.fechas
        })
        cabeceramesesDiasSemana = fechasmesesDiasSemana[0].map((data) => {
          return {id: data.fecha, name: data.fecha, borderDobleHeadeClass: true, children: [
              {id: 'total', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL'), borderDobleHeadeClass: true, type: 'numero'},
              {id: 'porcentaje', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE'), borderDobleHeadeClass: true, type: 'numero'}
            ]
          }
        })

        filasmesesDiasSemana = Object.values(productividadCentro.numeroDosis.diaSemana).map((data) => {
          if (data.diaSemana === 0) {
            data.diaSemana = t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.LUNES')
          } else if (data.diaSemana === 1) {
            data.diaSemana = t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MARTES')
          } else if (data.diaSemana === 2) {
            data.diaSemana = t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MIERCOLES')
          } else if (data.diaSemana === 3) {
            data.diaSemana = t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.JUEVES')
          } else if (data.diaSemana === 4) {
            data.diaSemana = t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.VIERNES')
          } else if (data.diaSemana === 5) {
            data.diaSemana = t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.SABADO')
          } else if (data.diaSemana === 6) {
            data.diaSemana = t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.DOMINGO')
          }
          let date = data.fechas.forEach(function(f, index){
            data[f.fecha] = {porcentaje: f.porcentaje, total: f.total}
          })
          return data
        })
      }
    }

    if (this.state.agrupacion === 2) {
      cabeceramesesDiasSemana.unshift({id: 'nombreRaza', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_RAZA')})
    }
    if (this.state.agrupacion === 3) {
      cabeceramesesDiasSemana.unshift({id: 'nombreLinea', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_LINEA')})
    }
    cabeceramesesDiasSemana.unshift({id: 'diaSemana', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.DIAS_SEMANA')})

    const tablemesesDiasSemana = {
      id: 'mesesTipoDosisTable',
      multiHeader: true,
      multiHeaderChild: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      columns: cabeceramesesDiasSemana,
      rows: filasmesesDiasSemana,
      initialValues: {}
    }

    // PRODUCTIVIDAD DE CENTRO - NUMERO DE DOSIS - RELACION DOSIS TEORICAS REALES
    let dosisTeoricasRealesRelacion = 0
    if (this.state.agrupacion === 1) {
      if (!isNaN(productividadCentro.relacionDosisTeoricasReales)) {
        dosisTeoricasRealesRelacion = number_formatter(productividadCentro.relacionDosisTeoricasReales, 2, separadorDec, separadorMil)

      }
    } else {
      let relacionDosisTeoricasRealesObject = Object.entries(productividadCentro.relacionDosisTeoricasReales).map(function(alta, index){
        return alta
      })
      dosisTeoricasRealesRelacion = relacionDosisTeoricasRealesObject.map(function(data, index){
        return <p className="p-raza-linea" key={index}>{data[0] + ': ' + number_formatter(data[1], 2, separadorDec, separadorMil)}</p>
      })
      if (Object.keys(dosisTeoricasRealesRelacion).length === 0) {
        dosisTeoricasRealesRelacion = 0
      }
    }

    // PRODUCTIVIDAD DE CENTRO - CALIDAD DOSIS - CONCENTRACION TOTAL
    let fechasmesesConcentracionDosis = []
    let cabeceramesesConcentracionDosis = []
    let filasmesesConcentracionDosis = []
    if (Object.keys(productividadCentro).length > 0) {
      if (Object.keys(productividadCentro.calidadDosis).length > 0) {
        fechasmesesConcentracionDosis = Object.values(productividadCentro.calidadDosis.concentracionTotal).map((data) => {
          return data.fechas
        })
        if (Object.keys(fechasmesesConcentracionDosis).length > 0) {
          cabeceramesesConcentracionDosis = fechasmesesConcentracionDosis[0].map((data) => {
            return {id: data.fecha, name: data.fecha, borderDobleHeadeClass: true, children: [
                {id: 'mediaConcentracionTotal', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MEDIA'), borderDobleHeadeClass: true, type: 'numero'}
              ]
            }
          })
        }

        filasmesesConcentracionDosis = Object.values(productividadCentro.calidadDosis.concentracionTotal).map((data) => {
          let date = data.fechas.forEach(function(f, index){
            data[f.fecha] = {mediaConcentracionTotal: f.mediaConcentracionTotal}
          })
          return data
        })
      }
    }

    if (this.state.agrupacion === 2) {
      cabeceramesesConcentracionDosis.unshift({id: 'nombreRaza', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_RAZA')})
    }
    if (this.state.agrupacion === 3) {
      cabeceramesesConcentracionDosis.unshift({id: 'nombreLinea', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_LINEA')})
    }
    cabeceramesesConcentracionDosis.unshift({id: 'tipoDosis', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TIPO_DOSIS')})

    const tableConcentracionDosis = {
      id: 'concentracionDosisTable',
      multiHeader: Object.keys(filasmesesConcentracionDosis).length > 0 ? true : false,
      multiHeaderChild: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      columns: cabeceramesesConcentracionDosis,
      rows: Object.values(filasmesesConcentracionDosis),
      initialValues: {}
    }

    // PRODUCTIVIDAD DE CENTRO - CALIDAD DOSIS - CONCENTRACION UTIL
    let fechasmesesConcentracionUtil = []
    let cabeceramesesConcentracionUtil = []
    let filasmesesConcentracionUtil = []
    if (Object.keys(productividadCentro).length > 0) {
      if (Object.keys(productividadCentro.calidadDosis).length > 0) {
        fechasmesesConcentracionUtil = Object.values(productividadCentro.calidadDosis.concentracionUtil).map((data) => {
          return data.fechas
        })
        if (Object.keys(fechasmesesConcentracionUtil).length > 0) {
          cabeceramesesConcentracionUtil = fechasmesesConcentracionUtil[0].map((data) => {
            return {id: data.fecha, name: data.fecha, borderDobleHeadeClass: true, children: [
                {id: 'mediaConcentracionTotal', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MEDIA'), borderDobleHeadeClass: true, type: 'numero'}
              ]
            }
          })
        }

        filasmesesConcentracionUtil = Object.values(productividadCentro.calidadDosis.concentracionUtil).map((data) => {
          let date = data.fechas.forEach(function(f, index){
            data[f.fecha] = {mediaConcentracionTotal: f.mediaConcentracionTotal}
          })
          return data
        })
      }
    }

    if (this.state.agrupacion === 2) {
      cabeceramesesConcentracionUtil.unshift({id: 'nombreRaza', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_RAZA')})
    }
    if (this.state.agrupacion === 3) {
      cabeceramesesConcentracionUtil.unshift({id: 'nombreLinea', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_LINEA')})
    }
    cabeceramesesConcentracionUtil.unshift({id: 'tipoDosis', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TIPO_DOSIS')})

    const tableconcentracionUtil = {
      id: 'concentracionUtilTable',
      multiHeader: Object.keys(filasmesesConcentracionUtil).length > 0 ? true : false,
      multiHeaderChild: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      columns: cabeceramesesConcentracionUtil,
      rows: Object.values(filasmesesConcentracionUtil),
      initialValues: {}
    }

    // PRODUCTIVIDAD DE CENTRO - CALIDAD DOSIS - MOTILIDAD
    let fechasmesesMotilidad = []
    let cabeceramesesMotilidad = []
    let filasmesesMotilidad = []
    if (Object.keys(productividadCentro).length > 0) {
      if (Object.keys(productividadCentro.calidadDosis).length > 0) {
        fechasmesesMotilidad = Object.values(productividadCentro.calidadDosis.motilidad).map((data) => {
          return data.fechas
        })
        if (Object.keys(fechasmesesMotilidad).length > 0) {
          cabeceramesesMotilidad = fechasmesesMotilidad[0].map((data) => {
            return {id: data.fecha, name: data.fecha, borderDobleHeadeClass: true, children: [
                {id: 'mediaMotilidad', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MEDIA'), borderDobleHeadeClass: true, type: 'numero'}
              ]
            }
          })
        }

        filasmesesMotilidad = Object.values(productividadCentro.calidadDosis.motilidad).map((data) => {
          let date = data.fechas.forEach(function(f, index){
            data[f.fecha] = {mediaMotilidad: f.mediaMotilidad}
          })
          return data
        })
      }
    }

    if (this.state.agrupacion === 2) {
      cabeceramesesMotilidad.unshift({id: 'nombreRaza', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_RAZA')})
    }
    if (this.state.agrupacion === 3) {
      cabeceramesesMotilidad.unshift({id: 'nombreLinea', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_LINEA')})
    }
    cabeceramesesMotilidad.unshift({id: 'tipoDosis', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TIPO_DOSIS')})

    const tablemotilidad = {
      id: 'motilidadTable',
      multiHeader: Object.keys(filasmesesMotilidad).length > 0 ? true : false,
      multiHeaderChild: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      columns: cabeceramesesMotilidad,
      rows: Object.values(filasmesesMotilidad),
      initialValues: {}
    }

    // PRODUCTIVIDAD DE CENTRO - CALIDAD DOSIS - FUERA VALORES - TOTAL
    let filastotal = []
    if (Object.keys(productividadCentro).length > 0) {
      if (Object.keys(productividadCentro.calidadDosis.fueraValores.total).length > 0) {
        filastotal = Object.values(productividadCentro.calidadDosis.fueraValores.total).map((data) => {
          data.porDosis = number_formatter(data.porDosis, 2, separadorDec, separadorMil)
          return data
        })
      }
    }

    const tabletotal = {
      id: 'albaranesTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      columns: this.state.agrupacion === 1 ? ([
        {id: 'tipoDosis', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TIPO_DOSIS')},
        {id: 'porDosis', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.POR_DOSIS')}
      ]) : (this.state.agrupacion === 2 ? ([
        {id: 'nombreRaza', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_RAZA')},
        {id: 'tipoDosis', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TIPO_DOSIS')},
        {id: 'porDosis', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.POR_DOSIS')}
      ]) : this.state.agrupacion === 3 ? ([
      {id: 'nombreLinea', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_LINEA')},
        {id: 'tipoDosis', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TIPO_DOSIS')},
        {id: 'porDosis', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.POR_DOSIS')}
      ]) :null
      ),
      rows: Object.keys(productividadCentro).length > 0 ? filastotal : [],
      initialValues: {},
    }

    // PRODUCTIVIDAD DE CENTRO - CALIDAD DOSIS - FUERA VALORES - MESES
    let fechasmesesCalidadFueraVoles = []
    let cabeceramesesCalidadFueraVoles = []
    let filasmesesCalidadFueraVoles = []
    if (Object.keys(productividadCentro).length > 0) {
      if (Object.keys(productividadCentro.calidadDosis).length > 0) {
        fechasmesesCalidadFueraVoles = Object.values(productividadCentro.calidadDosis.fueraValores.mes).map((data) => {
          return data.fechas
        })
        if (Object.keys(fechasmesesCalidadFueraVoles).length > 0) {
          cabeceramesesCalidadFueraVoles = fechasmesesCalidadFueraVoles[0].map((data) => {
            return {id: data.fecha, name: data.fecha, borderDobleHeadeClass: true, children: [
                {id: 'porDosis', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MEDIA'), borderDobleHeadeClass: true, type: 'numero'}
              ]
            }
          })
        }

        filasmesesCalidadFueraVoles = Object.values(productividadCentro.calidadDosis.fueraValores.mes).map((data) => {
          let date = data.fechas.forEach(function(f, index){
            data[f.fecha] = {porDosis: f.porDosis}
          })
          return data
        })
      }
    }

    if (this.state.agrupacion === 2) {
      cabeceramesesCalidadFueraVoles.unshift({id: 'nombreRaza', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_RAZA')})
    }
    if (this.state.agrupacion === 3) {
      cabeceramesesCalidadFueraVoles.unshift({id: 'nombreLinea', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_LINEA')})
    }
    cabeceramesesCalidadFueraVoles.unshift({id: 'tipoDosis', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TIPO_DOSIS')})

    const tablemeses = {
      id: 'mesesTable',
      multiHeader: Object.keys(filasmesesCalidadFueraVoles).length > 0 ? true : false,
      multiHeaderChild: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      columns: cabeceramesesCalidadFueraVoles,
      rows: Object.values(filasmesesCalidadFueraVoles),
      initialValues: {}
    }

   // ELIMINACION VERRACOS - BAJAS TOTALES MES
   let filasBasjasTotalesMes = []
   if (Object.keys(eliminacionVerracos).length > 0) {
      if (this.state.agrupacion === 1) {
        if (Object.keys(eliminacionVerracos).length > 0) {
          filasBasjasTotalesMes = eliminacionVerracos.bajasTotalesMes
        }
      } else {
        if (!eliminacionVerracos.bajasTotalesMes[0]){
          filasBasjasTotalesMes = Object.entries(eliminacionVerracos.bajasTotalesMes).map((data, index) => {
            return {razaLinea: data[0], mes: data[1][index].mes, numero: data[1][index].numero, porcentaje: data[1][index].porcentaje}
          })
        }
      }
    }
    const tableBajasTotalesMes = {
      id: 'bajasTotalesMesTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      columns: this.state.agrupacion === 1 ? ([
        {id: 'mes', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MES'), type: 'fecha'},
        {id: 'numero', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO'), type: 'numero'},
        {id: 'porcentaje', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE'), type: 'numero'}
      ]) : (this.state.agrupacion === 2 ? ([
        {id: 'razaLinea', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_RAZA')},
        {id: 'mes', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MES'), type: 'fecha'},
        {id: 'numero', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO'), type: 'numero'},
        {id: 'porcentaje', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE'), type: 'numero'}
      ]) : this.state.agrupacion === 3 ? ([
        {id: 'razaLinea', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_LINEA')},
        {id: 'mes', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MES'), type: 'fecha'},
        {id: 'numero', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO'), type: 'numero'},
        {id: 'porcentaje', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE'), type: 'numero'}
      ]) :null
      ),
      rows: Object.values(filasBasjasTotalesMes),
      initialValues: {},
    }

    // ELIMINACION VERRACOS - CAUSAS ELIMINACION
    let eliminacionVerracosCausasEliminacion = 0
    if (Object.keys(eliminacionVerracos).length > 0) {
      if (this.state.agrupacion === 1) {
        if (eliminacionVerracos.causaEliminacion[0] || eliminacionVerracos.causaEliminacion[1] || eliminacionVerracos.causaEliminacion[2] || eliminacionVerracos.causaEliminacion[3] || eliminacionVerracos.causaEliminacion[4] || eliminacionVerracos.causaEliminacion[5] || eliminacionVerracos.causaEliminacion[6]) {
          eliminacionVerracosCausasEliminacion = <Row className="tabla-eliminacion-verracos-body">
          <Col sm={2} className="tabla-eliminacion-verracos-padre-body col-7">
            <Col sm={6} className="tabla-eliminacion-verracos-padre-body"><span className="eliminacion-verracos-body">
              {eliminacionVerracos.causaEliminacion[0] ? eliminacionVerracos.causaEliminacion[0].numeroVerracos : 0}
            </span></Col>
            <Col sm={6} className="tabla-eliminacion-verracos-padre-body"><span className="eliminacion-verracos-body">
              {eliminacionVerracos.causaEliminacion[0] ? eliminacionVerracos.causaEliminacion[0].porcentajeVerracos : 0}
            </span></Col>
          </Col>
          <Col sm={2} className="tabla-eliminacion-verracos-padre-body col-7">
            <Col sm={6} className="tabla-eliminacion-verracos-padre-body"><span className="eliminacion-verracos-body">
              {eliminacionVerracos.causaEliminacion[1] ? eliminacionVerracos.causaEliminacion[1].numeroVerracos : 0}
            </span></Col>
            <Col sm={6} className="tabla-eliminacion-verracos-padre-body"><span className="eliminacion-verracos-body">
              {eliminacionVerracos.causaEliminacion[1] ? eliminacionVerracos.causaEliminacion[1].porcentajeVerracos : 0}
            </span></Col>
          </Col>
          <Col sm={2} className="tabla-eliminacion-verracos-padre-body col-7">
            <Col sm={6} className="tabla-eliminacion-verracos-padre-body"><span className="eliminacion-verracos-body">
              {eliminacionVerracos.causaEliminacion[2] ? eliminacionVerracos.causaEliminacion[2].numeroVerracos : 0}
            </span></Col>
            <Col sm={6} className="tabla-eliminacion-verracos-padre-body"><span className="eliminacion-verracos-body">
              {eliminacionVerracos.causaEliminacion[2] ? eliminacionVerracos.causaEliminacion[2].porcentajeVerracos : 0}
            </span></Col>
          </Col>
          <Col sm={2} className="tabla-eliminacion-verracos-padre-body col-7">
            <Col sm={6} className="tabla-eliminacion-verracos-padre-body"><span className="eliminacion-verracos-body">
              {eliminacionVerracos.causaEliminacion[3] ? eliminacionVerracos.causaEliminacion[3].numeroVerracos : 0}
            </span></Col>
            <Col sm={6} className="tabla-eliminacion-verracos-padre-body"><span className="eliminacion-verracos-body">
              {eliminacionVerracos.causaEliminacion[3] ? eliminacionVerracos.causaEliminacion[3].porcentajeVerracos : 0}
            </span></Col>
          </Col>
          <Col sm={2} className="tabla-eliminacion-verracos-padre-body col-7">
            <Col sm={6} className="tabla-eliminacion-verracos-padre-body"><span className="eliminacion-verracos-body">
              {eliminacionVerracos.causaEliminacion[4] ? eliminacionVerracos.causaEliminacion[4].numeroVerracos : 0}
            </span></Col>
            <Col sm={6} className="tabla-eliminacion-verracos-padre-body"><span className="eliminacion-verracos-body">
              {eliminacionVerracos.causaEliminacion[4] ? eliminacionVerracos.causaEliminacion[4].porcentajeVerracos : 0}
            </span></Col>
          </Col>
          <Col sm={2} className="tabla-eliminacion-verracos-padre-body col-7">
            <Col sm={6} className="tabla-eliminacion-verracos-padre-body"><span className="eliminacion-verracos-body">
              {eliminacionVerracos.causaEliminacion[5] ? eliminacionVerracos.causaEliminacion[5].numeroVerracos : 0}
            </span></Col>
            <Col sm={6} className="tabla-eliminacion-verracos-padre-body"><span className="eliminacion-verracos-body">
              {eliminacionVerracos.causaEliminacion[5] ? eliminacionVerracos.causaEliminacion[5].porcentajeVerracos : 0}
            </span></Col>
          </Col>
          <Col sm={2} className="tabla-eliminacion-verracos-padre-body col-7">
            <Col sm={6} className="tabla-eliminacion-verracos-padre-body"><span className="eliminacion-verracos-body">
              {eliminacionVerracos.causaEliminacion[6] ? eliminacionVerracos.causaEliminacion[6].numeroVerracos : 0}
            </span></Col>
            <Col sm={6} className="tabla-eliminacion-verracos-padre-body"><span className="eliminacion-verracos-body">
              {eliminacionVerracos.causaEliminacion[6] ? eliminacionVerracos.causaEliminacion[6].porcentajeVerracos : 0}
            </span></Col>
          </Col>
        </Row>
        }
      } else {
        eliminacionVerracosCausasEliminacion = Object.entries(eliminacionVerracos.causaEliminacion).map(function(data, index){
          if (data[0] !== '0' && data[0] !== '1' && data[0] !== '2' && data[0] !== '3' && data[0] !== '4' && data[0] !== '5' && data[0] !== '6') {
            return <Row key={index + 175} className="tabla-eliminacion-verracos-body">
          <Col sm={2} style={{padding: 0}}>
            <Col sm={12} className="tabla-eliminacion-verracos-padre-body"><span className="eliminacion-verracos-body">{data[0]}</span></Col>
          </Col>
          <Col sm={10} style={{padding: 0}}>
            <Col key={index + 176} sm={2} className="tabla-eliminacion-verracos-padre-body col-7">
              <Col key={index + 177} sm={6} className="tabla-eliminacion-verracos-padre-body"><span className="eliminacion-verracos-body">
                {data[1][0] ? data[1][0].numeroVerracos : 0}
              </span></Col>
              <Col key={index + 178} sm={6} className="tabla-eliminacion-verracos-padre-body"><span className="eliminacion-verracos-body">
                {data[1][0] ? data[1][0].porcentajeVerracos : 0}
              </span></Col>
            </Col>
            <Col key={index + 179} sm={2} className="tabla-eliminacion-verracos-padre-body col-7">
              <Col key={index + 180} sm={6} className="tabla-eliminacion-verracos-padre-body"><span className="eliminacion-verracos-body">
                {data[1][1] ? data[1][1].numeroVerracos : 0}
              </span></Col>
              <Col key={index + 181} sm={6} className="tabla-eliminacion-verracos-padre-body"><span className="eliminacion-verracos-body">
                {data[1][1] ? data[1][1].porcentajeVerracos : 0}
              </span></Col>
            </Col>
            <Col key={index + 182} sm={2} className="tabla-eliminacion-verracos-padre-body col-7">
              <Col key={index + 183} sm={6} className="tabla-eliminacion-verracos-padre-body"><span className="eliminacion-verracos-body">
                {data[1][2] ? data[1][2].numeroVerracos : 0}
              </span></Col>
              <Col key={index + 184} sm={6} className="tabla-eliminacion-verracos-padre-body"><span className="eliminacion-verracos-body">
                {data[1][2] ? data[1][2].porcentajeVerracos : 0}
              </span></Col>
            </Col>
            <Col key={index + 185} sm={2} className="tabla-eliminacion-verracos-padre-body col-7">
              <Col key={index + 186} sm={6} className="tabla-eliminacion-verracos-padre-body"><span className="eliminacion-verracos-body">
                {data[1][3] ? data[1][3].numeroVerracos : 0}
              </span></Col>
              <Col key={index + 187} sm={6} className="tabla-eliminacion-verracos-padre-body"><span className="eliminacion-verracos-body">
                {data[1][3] ? data[1][3].porcentajeVerracos : 0}
              </span></Col>
            </Col>
            <Col key={index + 189} sm={2} className="tabla-eliminacion-verracos-padre-body col-7">
              <Col key={index + 190} sm={6} className="tabla-eliminacion-verracos-padre-body"><span className="eliminacion-verracos-body">
                {data[1][4] ? data[1][4].numeroVerracos : 0}
              </span></Col>
              <Col key={index + 191} sm={6} className="tabla-eliminacion-verracos-padre-body"><span className="eliminacion-verracos-body">
                {data[1][4] ? data[1][4].porcentajeVerracos : 0}
              </span></Col>
            </Col>
            <Col key={index + 192} sm={2} className="tabla-eliminacion-verracos-padre-body col-7">
              <Col sm={6} className="tabla-eliminacion-verracos-padre-body"><span className="eliminacion-verracos-body">
                {data[1][5] ? data[1][5].numeroVerracos : 0}
              </span></Col>
              <Col key={index + 193} sm={6} className="tabla-eliminacion-verracos-padre-body"><span className="eliminacion-verracos-body">
                {data[1][5] ? data[1][5].porcentajeVerracos : 0}
              </span></Col>
            </Col>
            <Col key={index + 194} sm={2} className="tabla-eliminacion-verracos-padre-body col-7">
              <Col key={index + 195} sm={6} className="tabla-eliminacion-verracos-padre-body"><span className="eliminacion-verracos-body">
                {data[1][6] ? data[1][6].numeroVerracos : 0}
              </span></Col>
              <Col key={index + 196} sm={6} className="tabla-eliminacion-verracos-padre-body"><span className="eliminacion-verracos-body">
                {data[1][6] ? data[1][6].porcentajeVerracos : 0}
              </span></Col>
            </Col>
          </Col>
        </Row>
          } else {
            return undefined
          }
        })
      }
    }

    // CLIENTES - ALBARANES
    let filasClientesAlbaranes = []
    let arrayClientesAlbaranes = []
    if (Object.keys(clientes).length > 0) {
      if (this.state.agrupacion === 1) {
        if (Object.keys(clientes).length > 0) {
          if (clientes.albaranes[0]){
            arrayClientesAlbaranes = clientes.albaranes
          }
        }
      } else {
        if (!clientes.albaranes[0]){
          filasClientesAlbaranes = Object.entries(clientes.albaranes).map(function(data, index) {
            Object.entries(data[1]).map(function(dat, index){
              return arrayClientesAlbaranes.push({razaLinea: data[0], razonSocialCliente: dat[1].razonSocialCliente, albaranes: dat[1].albaranes})
            })
          })
        }
      }
    }
    const tableAlbaranes = {
      id: 'albaranesTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      columns: this.state.agrupacion === 1 ? ([
        {id: 'razonSocialCliente', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.RAZON_SOCIAL_CLIENTE')},
        {id: 'albaranes', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.INTERVALO')}
      ]) : (this.state.agrupacion === 2 ? ([
        {id: 'razaLinea', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_RAZA')},
        {id: 'razonSocialCliente', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.RAZON_SOCIAL_CLIENTE')},
        {id: 'albaranes', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.INTERVALO')}
      ]) : this.state.agrupacion === 3 ? ([
        {id: 'razaLinea', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_LINEA')},
        {id: 'razonSocialCliente', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.RAZON_SOCIAL_CLIENTE')},
        {id: 'albaranes', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.INTERVALO')}
      ]) :null
      ),
      rows: Object.values(arrayClientesAlbaranes),
      initialValues: {},
    }

    // DOSIS ENVIADAS
    let filasClientesDosisEnviadas = []
    let arrayClientesDosisEnviadas = []
    if (Object.keys(clientes).length > 0) {
      if (this.state.agrupacion === 1) {
        if (Object.keys(clientes).length > 0) {
          if (clientes.dosisEnviadas[0]) {
            arrayClientesDosisEnviadas = clientes.dosisEnviadas
          }
        }
      } else {
        if (!clientes.dosisEnviadas[0]) {
          filasClientesDosisEnviadas = Object.entries(clientes.dosisEnviadas).map((data, index) => {
             Object.entries(data[1]).map(function(dat, index){
              return arrayClientesDosisEnviadas.push({razaLinea: data[0], dosisEnviadas: dat[1].dosisEnviadas, porDosisEnviadas: dat[1].porDosisEnviadas})
            })
          })
        }
      }
    }
    const tableDosisEnviadas = {
      id: 'dosisEnviadasTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      columns: this.state.agrupacion === 1 ? ([
        {id: 'dosisEnviadas', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.DOSIS_ENVIADAS'), type: 'numero'},
        {id: 'porDosisEnviadas', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.POR_DOSIS_ENVIADAS'), type: 'numero'}
      ]) : (this.state.agrupacion === 2 ? ([
        {id: 'razaLinea', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_RAZA')},
        {id: 'dosisEnviadas', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.DOSIS_ENVIADAS'), type: 'numero'},
        {id: 'porDosisEnviadas', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.POR_DOSIS_ENVIADAS'), type: 'numero'}
      ]) : this.state.agrupacion === 3 ? ([
        {id: 'razaLinea', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_LINEA')},
        {id: 'dosisEnviadas', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.DOSIS_ENVIADAS'), type: 'numero'},
        {id: 'porDosisEnviadas', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.POR_DOSIS_ENVIADAS'), type: 'numero'}
      ]) :null
      ),
      rows: Object.values(arrayClientesDosisEnviadas),
      initialValues: {},
    }

    // CLIENTES - MEDIA ALBARANES
    let clientesMediaAlbaranes = 0
    if (this.state.agrupacion === 1) {
      if (!isNaN(clientes.mediaAlbaranes)) {
        clientesMediaAlbaranes = number_formatter(clientes.mediaAlbaranes, 2, separadorDec, separadorMil) + '%'
      }
    } else {
      let clientesMediaAlbaranesObject = Object.entries(clientes.mediaAlbaranes).map(function(alta, index){
        return alta
      })
      clientesMediaAlbaranes = clientesMediaAlbaranesObject.map(function(data, index){
        return <p className="p-raza-linea" key={index}>{data[0] + ': ' + number_formatter(data[1], 2, separadorDec, separadorMil) + '%'}</p>
      })
      if (Object.keys(clientesMediaAlbaranes).length === 0) {
        clientesMediaAlbaranes = 0
      }
    }
    // CLIENTES - MEDIA DOSIS ENVIADAS
    let clientesMediaDosisEnviadas = 0
    if (this.state.agrupacion === 1) {
      if (!isNaN(clientes.mediaDosisEnviadas)) {
        clientesMediaDosisEnviadas = number_formatter(clientes.mediaDosisEnviadas, 2, separadorDec, separadorMil) + '%'
      }
    } else {
      let clientesMediaDosisEnviadasObject = Object.entries(clientes.mediaDosisEnviadas).map(function(alta, index){
        return alta
      })
      clientesMediaDosisEnviadas = clientesMediaDosisEnviadasObject.map(function(data, index){
        return <p className="p-raza-linea" key={index}>{data[0] + ': ' + number_formatter(data[1], 2, separadorDec, separadorMil) + '%'}</p>
      })
      if (Object.keys(clientesMediaDosisEnviadas).length === 0) {
        clientesMediaDosisEnviadas = 0
      }
    }

    // CLIENTES DOSIS MESES
    let arrayTableClientesDosisMes = []
    let filasClientesDosisMes
    if (Object.keys(clientes).length > 0) {
      if (clientes.dosis.albaranesMeses) {
        if (this.state.agrupacion === 1) {
          arrayTableClientesDosisMes = clientes.dosis.albaranesMeses
        } else {
          if (!clientes.dosis.albaranesMeses[0]){
            filasClientesDosisMes = Object.entries(clientes.dosis.albaranesMeses).map(function(data, index){
              Object.entries(data[1]).map(function(dat, index){
                return arrayTableClientesDosisMes.push({razaLinea: data[0], fecha: dat[0], total: dat[1].total, media: dat[1].media})
              })
            })
          }
        }
      }
    }
    const tableClientesDosisMes = {
      id: 'clientesDosisMesTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      columns: this.state.agrupacion === 1 ? ([
        {id: 'cliente', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.CLIENTES')},
        {id: 'fecha', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.FECHAS'), type: 'fecha'},
        {id: 'numero', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL'), type: 'numero'},
        {id: 'porcentaje', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE'), type: 'numero'}
      ]) : ([
        {id: 'razaLinea', name: this.state.agrupacion === 2 ? t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_RAZA') : t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_LINEA')},
        {id: 'cliente', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.CLIENTES')},
        {id: 'fecha', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.FECHAS'), type: 'fecha'},
        {id: 'numero', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL'), type: 'numero'},
        {id: 'porcentaje', name: t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE'), type: 'numero'}
      ]),
      rows: Object.values(arrayTableClientesDosisMes),
      initialValues: {},
    }

    return (
      <div className="content-informe-estado-centro">
        <h2 className="page-title">{t('INFORMES.ESTADO_CENTRO.TITLE')}</h2>
        <FiltrosInformesEstadoCentroPage />
        <h3>{t('INFORMES.ESTADO_CENTRO.TABLA1.CENSO')}</h3>
        <p><b>{t('INFORMES.ESTADO_CENTRO.TABLA1.NUMERO_VERRACOS')}</b></p>
        <SimpleTablePage {...tableInformeEstadoCentro}>
        </SimpleTablePage>
        <Col sm={3}>
          <span className="edad-estado-centro"><b>{t('INFORMES.ESTADO_CENTRO.TABLA1.ALTAS_VERRACO')}: </b>{verracosAlta !== undefined && verracosAlta}</span>
        </Col>
        <Col sm={3}>
          <span className="edad-estado-centro"><b>{t('INFORMES.ESTADO_CENTRO.TABLA1.POR_ALTAS_VERRACO')}: </b>{verracosAltaPor !== undefined && verracosAltaPor}</span>
        </Col>
        <Col sm={3}>
          <span className="edad-estado-centro"><b>{t('INFORMES.ESTADO_CENTRO.TABLA1.VERRACOS_ACTIVOS')}:</b> {activosVerracos !== undefined && activosVerracos}</span>
        </Col>
        <Col sm={3}>
          <span className="edad-estado-centro"><b>{t('INFORMES.ESTADO_CENTRO.TABLA1.POR_VERRACOS_ACTIVOS')}:</b> {activosVerracosPor !== undefined && activosVerracosPor}</span>
        </Col>
        <div className="clearfix"></div>
        <hr/>
        <h3 style={{marginTop: '20px'}}>{t('INFORMES.ESTADO_CENTRO.TABLA2.EDAD')}</h3>
        <Col sm={4}>
          <span className="edad-estado-centro"><b>{t('INFORMES.ESTADO_CENTRO.TABLA2.CUARENTENA')}:</b> {cuarentenaEdad !== undefined && cuarentenaEdad}</span>
        </Col>
        <Col sm={4}>
          <span className="edad-estado-centro"><b>{t('INFORMES.ESTADO_CENTRO.TABLA2.ENTRADA_CENTRO')}:</b> {entradaCentroEdad !== undefined && entradaCentroEdad}</span>
        </Col>
        <Col sm={4}>
          <span className="edad-estado-centro"><b>{t('INFORMES.ESTADO_CENTRO.TABLA2.PRIMERA_EXTRACCION')}:</b> {primeraExtraccionEdad !== undefined && primeraExtraccionEdad}</span>
        </Col>
        <div className="clearfix"></div>
        <p style={{marginTop: '15px', marginBottom: '0'}}><b>{t('INFORMES.ESTADO_CENTRO.TABLA2.PROMEDIO_EDAD')}</b></p>
        <Row className={this.state.agrupacion !== 1 ? "promedio-edad-raza-linea tablaTotales tabla-edad-estado-centro" : "tablaTotales tabla-edad-estado-centro"}>
          {
            this.state.agrupacion === 2 && (
              <Col sm={3} className="total-verracos">
                <span><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_RAZA')}</b></span>
              </Col>
            )
          }
          {
            this.state.agrupacion === 3 && (
              <Col sm={3} className="total-verracos">
                <span><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_LINEA')}</b></span>
              </Col>
            )
          }
          <Col sm={3} className="total-verracos">
            <span>{t('INFORMES.ESTADO_CENTRO.TABLA2.MENOR_12')}</span>
          </Col>
          <Col sm={3} className="total-verracos">
            <span>{t('INFORMES.ESTADO_CENTRO.TABLA2.ENTRE_12_18')}</span>
          </Col>
          <Col sm={3} className="total-verracos">
            <span>{t('INFORMES.ESTADO_CENTRO.TABLA2.ENTRE_18_24')}</span>
          </Col>
          <Col sm={3} className="total-verracos">
            <span>{t('INFORMES.ESTADO_CENTRO.TABLA2.MAYOR_24')}</span>
          </Col>
        </Row>
        <Row className={this.state.agrupacion !== 1 ? "promedio-edad-raza-linea tablaTotales tabla-edad-estado-centro-medias" : "tablaTotales tabla-edad-estado-centro-medias"}>
          {
            this.state.agrupacion === 2 && (
              <Col sm={3} className="total-verracos-padre">
                <Col sm={12} className="total-verracos" style={{padding: '15.5px'}}></Col>
              </Col>
            )
          }
          {
            this.state.agrupacion === 3 && (
              <Col sm={3} className="total-verracos-padre">
                <Col sm={12} className="total-verracos" style={{padding: '15.5px'}}></Col>
              </Col>
            )
          }
          <Col sm={3} className="total-verracos-padre">
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.TOTAL')}</b>
            </Col>
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.PORCENTAJE')}</b>
            </Col>
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.M_EDAD')}</b>
            </Col>
          </Col>
          <Col sm={3} className="total-verracos-padre">
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.TOTAL')}</b>
            </Col>
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.PORCENTAJE')}</b>
            </Col>
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.M_EDAD')}</b>
            </Col>
          </Col>
          <Col sm={3} className="total-verracos-padre">
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.TOTAL')}</b>
            </Col>
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.PORCENTAJE')}</b>
            </Col>
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.M_EDAD')}</b>
            </Col>
          </Col>
          <Col sm={3} className="total-verracos-padre">
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.TOTAL')}</b>
            </Col>
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.PORCENTAJE')}</b>
            </Col>
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.M_EDAD')}</b>
            </Col>
          </Col>
        </Row>
        {promedioEdad !== undefined && promedioEdad}
        <p style={{marginTop: '15px', marginBottom: '0'}}><b>{t('INFORMES.ESTADO_CENTRO.TABLA2.ELIMINACION_VERRACOS')}</b></p>
        <Col sm={4}>
          <span className="edad-estado-centro"><b>{t('INFORMES.ESTADO_CENTRO.TABLA2.EDAD_MESES_VERRACO')}: </b>{verracoMeseseliminacion !== undefined && verracoMeseseliminacion}</span>
        </Col>
        <Col sm={4}>
          <span className="edad-estado-centro"><b>{t('INFORMES.ESTADO_CENTRO.TABLA2.EDAD_MESES_CIAP')}:</b> {verracoMesesCiap !== undefined && verracoMesesCiap}</span>
        </Col>
        <div className="clearfix"></div>
        <hr/>
        <h3>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TITLE')}</h3>
        <p style={{marginTop: '15px', marginBottom: '0px'}}><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO_EXTRACCIONES')}</b></p>
        <Row className={this.state.agrupacion !== 1 ? "tabla-numero-extracciones numero-extraciones-raza-linea" : "tabla-numero-extracciones"}>
          {
            this.state.agrupacion === 2 && (
              <Col sm={2} className="tabla-numero-extracciones-padre">
                <span className="numero-extracciones"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_RAZA')}</b></span>
              </Col>
            )
          }
          {
            this.state.agrupacion === 3 && (
              <Col sm={2} className="tabla-numero-extracciones-padre">
                <span className="numero-extracciones"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_LINEA')}</b></span>
              </Col>
            )
          }
          <Col sm={2} className="tabla-numero-extracciones-padre">
            <span className="numero-extracciones"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}</b></span>
          </Col>
          <Col sm={2} className="tabla-numero-extracciones-padre">
            <span className="numero-extracciones"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.CUARENTENA')}</b></span>
          </Col>
          <Col sm={2} className="tabla-numero-extracciones-padre">
            <span className="numero-extracciones"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.EN_CENTRO')}</b></span>
          </Col>
          <Col sm={2} className="tabla-numero-extracciones-padre">
            <span className="numero-extracciones"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.INDEX')}</b></span>
          </Col>
          <Col sm={4} className="tabla-numero-extracciones-padre">
            <span className="numero-extracciones"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.ACTIVO')}</b></span>
          </Col>
        </Row>
        {numeroExtracciones !== undefined && numeroExtracciones}
        <p style={{margin: '10px 0 0 0'}}><b>{t('INFORMES.ESTADO_CENTRO.TABLA2.EDAD')}</b></p>
        <Row className={this.state.agrupacion !== 1 ? "tablaTotales tabla-edad-estado-centro edad-estado-centro-raza-linea" : "tablaTotales tabla-edad-estado-centro"}>
          {
            this.state.agrupacion === 2 && (
              <Col sm={3} className="total-verracos">
                <span className="numero-extracciones"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_RAZA')}</b></span>
              </Col>
            )
          }
          {
            this.state.agrupacion === 3 && (
              <Col sm={3} className="total-verracos">
                <span className="numero-extracciones"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_LINEA')}</b></span>
              </Col>
            )
          }
          <Col sm={3} className="total-verracos">
            <span>{t('INFORMES.ESTADO_CENTRO.TABLA2.MENOR_12')}</span>
          </Col>
          <Col sm={3} className="total-verracos">
            <span>{t('INFORMES.ESTADO_CENTRO.TABLA2.ENTRE_12_18')}</span>
          </Col>
          <Col sm={3} className="total-verracos">
            <span>{t('INFORMES.ESTADO_CENTRO.TABLA2.ENTRE_18_24')}</span>
          </Col>
          <Col sm={3} className="total-verracos">
            <span>{t('INFORMES.ESTADO_CENTRO.TABLA2.MAYOR_24')}</span>
          </Col>
        </Row>
        <Row className={this.state.agrupacion !== 1 ? "tablaTotales tabla-edad-estado-centro-medias edad-estado-centro-raza-linea" : "tablaTotales tabla-edad-estado-centro-medias"}>
          {
            this.state.agrupacion === 2 && (
              <Col sm={3} className="total-verracos-padre">
                <Col sm={12} className="total-verracos" style={{padding: '15.5px'}}></Col>
              </Col>
            )
          }
          {
            this.state.agrupacion === 3 && (
              <Col sm={3} className="total-verracos-padre">
                <Col sm={12} className="total-verracos" style={{padding: '15.5px'}}></Col>
              </Col>
            )
          }
          <Col sm={3} className="total-verracos-padre">
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.TOTAL')}</b>
            </Col>
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.PORCENTAJE')}</b>
            </Col>
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.M_EDAD')}</b>
            </Col>
          </Col>
          <Col sm={3} className="total-verracos-padre">
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.TOTAL')}</b>
            </Col>
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.PORCENTAJE')}</b>
            </Col>
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.M_EDAD')}</b>
            </Col>
          </Col>
          <Col sm={3} className="total-verracos-padre">
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.TOTAL')}</b>
            </Col>
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.PORCENTAJE')}</b>
            </Col>
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.M_EDAD')}</b>
            </Col>
          </Col>
          <Col sm={3} className="total-verracos-padre">
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.TOTAL')}</b>
            </Col>
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.PORCENTAJE')}</b>
            </Col>
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.M_EDAD')}</b>
            </Col>
          </Col>
        </Row>
        {numeroExtraccionesEdad !== undefined && numeroExtraccionesEdad}
        <p style={{marginTop: '15px', marginBottom: '0px'}}><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO_DOSIS')}</b></p>
        <Row className="tabla-numero-extracciones">
          {
            this.state.agrupacion === 2 && (
              <Col sm={this.state.agrupacion !== 1 ? 3 : 4} className="tabla-numero-extracciones-padre">
                <span className="numero-extracciones"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_RAZA')}</b></span>
              </Col>
            )
          }
          {
            this.state.agrupacion === 3 && (
              <Col sm={this.state.agrupacion !== 1 ? 3 : 4} className="tabla-numero-extracciones-padre">
                <span className="numero-extracciones"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_LINEA')}</b></span>
              </Col>
            )
          }
          <Col sm={this.state.agrupacion !== 1 ? 3 : 4} className="tabla-numero-extracciones-padre">
            <span className="numero-extracciones"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.ACTIVO')}</b></span>
          </Col>
          <Col sm={this.state.agrupacion !== 1 ? 3 : 4} className="tabla-numero-extracciones-padre">
            <span className="numero-extracciones"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.EN_CENTRO')}</b></span>
          </Col>
          <Col sm={this.state.agrupacion !== 1 ? 3 : 4} className="tabla-numero-extracciones-padre">
            <span className="numero-extracciones"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.CUARENTENA')}</b></span>
          </Col>
        </Row>
        {numeroDosisPrdVerr !== undefined && numeroDosisPrdVerr}
        <p style={{margin: '10px 0 0 0'}}><b>{t('INFORMES.ESTADO_CENTRO.TABLA2.EDAD')}</b></p>
        <Row className={this.state.agrupacion !== 1 ? "tablaTotales tabla-edad-estado-centro edad-estado-centro-raza-linea" : "tablaTotales tabla-edad-estado-centro"}>
          {
            this.state.agrupacion === 2 && (
              <Col sm={3} className="total-verracos">
                <span className="numero-extracciones"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_RAZA')}</b></span>
              </Col>
            )
          }
          {
            this.state.agrupacion === 3 && (
              <Col sm={3} className="total-verracos">
                <span className="numero-extracciones"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_LINEA')}</b></span>
              </Col>
            )
          }
          <Col sm={3} className="total-verracos">
            <span>{t('INFORMES.ESTADO_CENTRO.TABLA2.MENOR_12')}</span>
          </Col>
          <Col sm={3} className="total-verracos">
            <span>{t('INFORMES.ESTADO_CENTRO.TABLA2.ENTRE_12_18')}</span>
          </Col>
          <Col sm={3} className="total-verracos">
            <span>{t('INFORMES.ESTADO_CENTRO.TABLA2.ENTRE_18_24')}</span>
          </Col>
          <Col sm={3} className="total-verracos">
            <span>{t('INFORMES.ESTADO_CENTRO.TABLA2.MAYOR_24')}</span>
          </Col>
        </Row>
        <Row className={this.state.agrupacion !== 1 ? "tablaTotales tabla-edad-estado-centro-medias promedio-edad-raza-linea" : "tablaTotales tabla-edad-estado-centro-medias"}>
          {
            this.state.agrupacion === 2 && (
              <Col sm={3} className="total-verracos-padre">
                <Col sm={12} className="total-verracos" style={{padding: '15.5px'}}></Col>
              </Col>
            )
          }
          {
            this.state.agrupacion === 3 && (
              <Col sm={3} className="total-verracos-padre">
                <Col sm={12} className="total-verracos" style={{padding: '15.5px'}}></Col>
              </Col>
            )
          }
          <Col sm={3} className="total-verracos-padre">
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.TOTAL')}</b>
            </Col>
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.PORCENTAJE')}</b>
            </Col>
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.M_EDAD')}</b>
            </Col>
          </Col>
          <Col sm={3} className="total-verracos-padre">
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.TOTAL')}</b>
            </Col>
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.PORCENTAJE')}</b>
            </Col>
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.M_EDAD')}</b>
            </Col>
          </Col>
          <Col sm={3} className="total-verracos-padre">
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.TOTAL')}</b>
            </Col>
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.PORCENTAJE')}</b>
            </Col>
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.M_EDAD')}</b>
            </Col>
          </Col>
          <Col sm={3} className="total-verracos-padre">
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.TOTAL')}</b>
            </Col>
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.PORCENTAJE')}</b>
            </Col>
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.M_EDAD')}</b>
            </Col>
          </Col>
        </Row>
        {numeroDosisPrdVerrEdad !== undefined && numeroDosisPrdVerrEdad}
        <p style={{marginTop: '15px', marginBottom: '0px'}}><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.CALIDAD_EYACULADO')}</b></p>
        <Row className="tabla-numero-extracciones">
          <Col sm={12} className="tabla-numero-extracciones-padre">
            <span className="numero-extracciones"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.CALIDAD_EYACULADO')}</b></span>
          </Col>
        </Row>
        <Row className="tabla-numero-extracciones">
          <Col sm={2} className="tabla-numero-extracciones-padre">
            <span className="numero-extracciones"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.FECHA')}</b></span>
          </Col>
          {
            this.state.agrupacion === 2 && (
              <Col sm={2} className="tabla-numero-extracciones-padre">
                <span className="numero-extracciones"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_RAZA')}</b></span>
              </Col>
            )
          }
          {
            this.state.agrupacion === 3 && (
              <Col sm={2} className="tabla-numero-extracciones-padre">
                <span className="numero-extracciones"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_LINEA')}</b></span>
              </Col>
            )
          }
          <Col sm={2} className="tabla-numero-extracciones-padre">
            <span className="numero-extracciones"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.VOLUMEN')}</b></span>
          </Col>
          <Col sm={this.state.agrupacion !== 1 ? 2 : 3} className="tabla-numero-extracciones-padre">
            <span className="numero-extracciones"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.CONCENTRACION')}</b></span>
          </Col>
          <Col sm={2} className="tabla-numero-extracciones-padre">
            <span className="numero-extracciones"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MOTILIDAD')}</b></span>
          </Col>
          <Col sm={this.state.agrupacion !== 1 ? 2 : 3} className="tabla-numero-extracciones-padre">
            <span className="numero-extracciones"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.POR_FA')}</b></span>
          </Col>
        </Row>
        {numeroDosisPrdVerrCalidadEyaculado !== undefined && numeroDosisPrdVerrCalidadEyaculado}
        <p style={{marginTop: '15px', marginBottom: '0px'}}><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.RITMO_EXTRACCION')}</b></p>
        <Row className="tabla-ritmo-extraccion">
          {
            this.state.agrupacion === 2 && (
              <Col sm={2} className="tabla-ritmo-extraccion-padre">
                <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_RAZA')}</b></span>
              </Col>
            )
          }
          {
            this.state.agrupacion === 3 && (
              <Col sm={2} className="tabla-ritmo-extraccion-padre">
                <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_LINEA')}</b></span>
              </Col>
            )
          }
          <Col sm={2} className={this.state.agrupacion !== 1 ? "tabla-ritmo-extraccion-padre" : "tabla-ritmo-extraccion-padre col-5"}>
            <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.1_2_DIAS')}</b></span>
          </Col>
          <Col sm={2} className={this.state.agrupacion !== 1 ? "tabla-ritmo-extraccion-padre" : "tabla-ritmo-extraccion-padre col-5"}>
            <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.3_4_DIAS')}</b></span>
          </Col>
          <Col sm={2} className={this.state.agrupacion !== 1 ? "tabla-ritmo-extraccion-padre" : "tabla-ritmo-extraccion-padre col-5"}>
            <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.5_6_DIAS')}</b></span>
          </Col>
          <Col sm={2} className={this.state.agrupacion !== 1 ? "tabla-ritmo-extraccion-padre" : "tabla-ritmo-extraccion-padre col-5"}>
            <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.7_8_DIAS')}</b></span>
          </Col>
          <Col sm={2} className={this.state.agrupacion !== 1 ? "tabla-ritmo-extraccion-padre" : "tabla-ritmo-extraccion-padre col-5"}>
            <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MAS_9_DIAS')}</b></span>
          </Col>
        </Row>
        <Row className="tabla-ritmo-extraccion">
          {
            this.state.agrupacion === 2 && (
              <Col sm={2} className="tabla-ritmo-extraccion-padre">
                <Col sm={12} className="ritmo-extraccion" style={{padding: '15.5px'}}></Col>
              </Col>
            )
          }
          {
            this.state.agrupacion === 3 && (
              <Col sm={2} className="tabla-ritmo-extraccion-padre">
                <Col sm={12} className="ritmo-extraccion" style={{padding: '15.5px'}}></Col>
              </Col>
            )
          }
          <Col sm={2} className={this.state.agrupacion !== 1 ? "tabla-ritmo-extraccion-padre" : "tabla-ritmo-extraccion-padre col-5"}>
            <Col sm={4} className="tabla-ritmo-extraccion-padre">
              <span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}</span>
            </Col>
            <Col sm={4} className="tabla-ritmo-extraccion-padre">
              <span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span>
            </Col>
            <Col sm={4} className="tabla-ritmo-extraccion-padre">
              <span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MEDIA')}</span>
            </Col>
          </Col>
          <Col sm={2} className={this.state.agrupacion !== 1 ? "tabla-ritmo-extraccion-padre" : "tabla-ritmo-extraccion-padre col-5"}>
            <Col sm={4} className="tabla-ritmo-extraccion-padre">
              <span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}</span>
            </Col>
            <Col sm={4} className="tabla-ritmo-extraccion-padre">
              <span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span>
            </Col>
            <Col sm={4} className="tabla-ritmo-extraccion-padre">
              <span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MEDIA')}</span>
            </Col>
          </Col>
          <Col sm={2} className={this.state.agrupacion !== 1 ? "tabla-ritmo-extraccion-padre" : "tabla-ritmo-extraccion-padre col-5"}>
            <Col sm={4} className="tabla-ritmo-extraccion-padre">
              <span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}</span>
            </Col>
            <Col sm={4} className="tabla-ritmo-extraccion-padre">
              <span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span>
            </Col>
            <Col sm={4} className="tabla-ritmo-extraccion-padre">
              <span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MEDIA')}</span>
            </Col>
          </Col>
          <Col sm={2} className={this.state.agrupacion !== 1 ? "tabla-ritmo-extraccion-padre" : "tabla-ritmo-extraccion-padre col-5"}>
            <Col sm={4} className="tabla-ritmo-extraccion-padre">
              <span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}</span>
            </Col>
            <Col sm={4} className="tabla-ritmo-extraccion-padre">
              <span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span>
            </Col>
            <Col sm={4} className="tabla-ritmo-extraccion-padre">
              <span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MEDIA')}</span>
            </Col>
          </Col>
          <Col sm={2} className={this.state.agrupacion !== 1 ? "tabla-ritmo-extraccion-padre" : "tabla-ritmo-extraccion-padre col-5"}>
            <Col sm={4} className="tabla-ritmo-extraccion-padre">
              <span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}</span>
            </Col>
            <Col sm={4} className="tabla-ritmo-extraccion-padre">
              <span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span>
            </Col>
            <Col sm={4} className="tabla-ritmo-extraccion-padre">
              <span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MEDIA')}</span>
            </Col>
          </Col>
        </Row>
        {intervaloRitmoExtraccion !== undefined && intervaloRitmoExtraccion}
        <p style={{margin: '10px 0 0 0'}}><b>{t('INFORMES.ESTADO_CENTRO.TABLA2.EDAD')}</b></p>
        <Row className="tablaTotales tabla-edad-estado-centro">
          {
            this.state.agrupacion === 2 && (
              <Col sm={2} className="total-verracos">
                <span><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_RAZA')}</b></span>
              </Col>
            )
          }
          {
            this.state.agrupacion === 3 && (
              <Col sm={2} className="total-verracos">
                <span><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_LINEA')}</b></span>
              </Col>
            )
          }
          <Col sm={this.state.agrupacion !== 1 ? 5 : 6} className="total-verracos">
            <span>{t('INFORMES.ESTADO_CENTRO.TABLA2.MENOR_12')}</span>
          </Col>
          <Col sm={this.state.agrupacion !== 1 ? 5 : 6} className="total-verracos">
            <span>{t('INFORMES.ESTADO_CENTRO.TABLA2.MAYOR_12')}</span>
          </Col>
        </Row>
        <Row className="tablaTotales tabla-edad-estado-centro-medias">
          {
            this.state.agrupacion === 2 && (
              <Col sm={2} className="total-verracos-padre">
                <Col sm={12} className="total-verracos" style={{padding: '15.5px'}}><span></span></Col>
              </Col>
            )
          }
          {
            this.state.agrupacion === 3 && (
              <Col sm={2} className="total-verracos-padre">
                <Col sm={12} className="total-verracos" style={{padding: '15.5px'}}><span></span></Col>
              </Col>
            )
          }
          <Col sm={this.state.agrupacion !== 1 ? 5 : 6} className="total-verracos-padre">
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.NUMERO')}</b>
            </Col>
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.PORCENTAJE')}</b>
            </Col>
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.M_EDAD')}</b>
            </Col>
          </Col>
          <Col sm={this.state.agrupacion !== 1 ? 5 : 6} className="total-verracos-padre">
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.NUMERO')}</b>
            </Col>
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.PORCENTAJE')}</b>
            </Col>
            <Col sm={4} className="total-verracos">
              <b>{t('INFORMES.ESTADO_CENTRO.TABLA2.M_EDAD')}</b>
            </Col>
          </Col>
        </Row>
        {edadRitmoExtraccion !== undefined && edadRitmoExtraccion}
        <p style={{marginTop: '15px', marginBottom: '0px'}}><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.EPOCA_ANO_MESES')}</b></p>
        <SimpleTablePage {...tableMesesIntervalo}>
        </SimpleTablePage>
        <p style={{marginTop: '15px', marginBottom: '0px'}}><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.EYACULADOS_ELIMINADOS')}</b></p>
        <p style={{marginTop: '15px', marginBottom: '0px'}}><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.CAUSA_ELIMINACION')}</b></p>
        {
          this.state.agrupacion !== 1 ? (
            <Row className="tabla-ritmo-extraccion">
              <Col sm={2} style={{padding: 0}}>
                  {
                    this.state.agrupacion === 2 && (
                      <Col sm={12} className="tabla-ritmo-extraccion-padre">
                        <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_RAZA')}</b></span>
                      </Col>
                    )
                  }
                  {
                    this.state.agrupacion === 3 && (
                      <Col sm={12} className="tabla-ritmo-extraccion-padre">
                        <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_LINEA')}</b></span>
                      </Col>
                    )
                  }
              </Col>
              <Col sm={10} style={{padding: 0}}>
                <Col sm={2} className="tabla-ritmo-extraccion-padre">
                  <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.CAUSA_ELIMINACION_1')}</b></span>
                </Col>
                <Col sm={2} className="tabla-ritmo-extraccion-padre">
                  <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.CAUSA_ELIMINACION_2')}</b></span>
                </Col>
                <Col sm={2} className="tabla-ritmo-extraccion-padre">
                  <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.CAUSA_ELIMINACION_3')}</b></span>
                </Col>
                <Col sm={2} className="tabla-ritmo-extraccion-padre">
                  <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.CAUSA_ELIMINACION_4')}</b></span>
                </Col>
                <Col sm={2} className="tabla-ritmo-extraccion-padre">
                  <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.CAUSA_ELIMINACION_5')}</b></span>
                </Col>
                <Col sm={2} className="tabla-ritmo-extraccion-padre">
                  <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.CAUSA_ELIMINACION_6')}</b></span>
                </Col>
              </Col>
            </Row>
          ) : (
            <Row className="tabla-ritmo-extraccion">
              <Col sm={2} className="tabla-ritmo-extraccion-padre">
                <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.CAUSA_ELIMINACION_1')}</b></span>
              </Col>
              <Col sm={2} className="tabla-ritmo-extraccion-padre">
                <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.CAUSA_ELIMINACION_2')}</b></span>
              </Col>
              <Col sm={2} className="tabla-ritmo-extraccion-padre">
                <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.CAUSA_ELIMINACION_3')}</b></span>
              </Col>
              <Col sm={2} className="tabla-ritmo-extraccion-padre">
                <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.CAUSA_ELIMINACION_4')}</b></span>
              </Col>
              <Col sm={2} className="tabla-ritmo-extraccion-padre">
                <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.CAUSA_ELIMINACION_5')}</b></span>
              </Col>
              <Col sm={2} className="tabla-ritmo-extraccion-padre">
                <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.CAUSA_ELIMINACION_6')}</b></span>
              </Col>
            </Row>
          )
        }
        {
          this.state.agrupacion !== 1 ? (
            <Row className="tabla-ritmo-extraccion">
              <Col sm={2} style={{padding: 0}}>
                <Col sm={12}className="tabla-ritmo-extraccion-padre"><span style={{padding: '15.5px'}} className="ritmo-extraccion"></span></Col>
              </Col>
              <Col sm={10} style={{padding: 0}}>
                <Col sm={2} className="tabla-ritmo-extraccion-padre">
                  <Col sm={6} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}</span></Col>
                  <Col sm={6} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span></Col>
                </Col>
                <Col sm={2} className="tabla-ritmo-extraccion-padre">
                  <Col sm={6} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}</span></Col>
                  <Col sm={6} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span></Col>
                </Col>
                <Col sm={2} className="tabla-ritmo-extraccion-padre">
                  <Col sm={6} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}</span></Col>
                  <Col sm={6} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span></Col>
                </Col>
                <Col sm={2} className="tabla-ritmo-extraccion-padre">
                  <Col sm={6} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}</span></Col>
                  <Col sm={6} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span></Col>
                </Col>
                <Col sm={2} className="tabla-ritmo-extraccion-padre">
                  <Col sm={6} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}</span></Col>
                  <Col sm={6} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span></Col>
                </Col>
                <Col sm={2} className="tabla-ritmo-extraccion-padre">
                  <Col sm={6} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}</span></Col>
                  <Col sm={6} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span></Col>
                </Col>
              </Col>
            </Row>
          ) : (
            <Row className="tabla-ritmo-extraccion">
              <Col sm={2} className="tabla-ritmo-extraccion-padre">
                <Col sm={6} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}</span></Col>
                <Col sm={6} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span></Col>
              </Col>
              <Col sm={2} className="tabla-ritmo-extraccion-padre">
                <Col sm={6} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}</span></Col>
                <Col sm={6} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span></Col>
              </Col>
              <Col sm={2} className="tabla-ritmo-extraccion-padre">
                <Col sm={6} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}</span></Col>
                <Col sm={6} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span></Col>
              </Col>
              <Col sm={2} className="tabla-ritmo-extraccion-padre">
                <Col sm={6} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}</span></Col>
                <Col sm={6} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span></Col>
              </Col>
              <Col sm={2} className="tabla-ritmo-extraccion-padre">
                <Col sm={6} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}</span></Col>
                <Col sm={6} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span></Col>
              </Col>
              <Col sm={2} className="tabla-ritmo-extraccion-padre">
                <Col sm={6} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}</span></Col>
                <Col sm={6} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span></Col>
              </Col>
            </Row>
          )
        }
        {causaEliminacionEyaculadoEliminado !== undefined && causaEliminacionEyaculadoEliminado}
        <p style={{marginTop: '15px', marginBottom: '0px'}}><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MOMENTO_ELIMINACION')}</b></p>
          {
            this.state.agrupacion !== 1 ? (
            <Row className="tabla-ritmo-extraccion">
              <Col sm={2} style={{padding: 0}}>
                {
                  this.state.agrupacion === 2 && (
                    <Col sm={12} className="tabla-ritmo-extraccion-padre">
                      <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_RAZA')}</b></span>
                    </Col>
                  )
                }
                {
                  this.state.agrupacion === 3 && (
                    <Col sm={12} className="tabla-ritmo-extraccion-padre">
                      <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_LINEA')}</b></span>
                    </Col>
                  )
                }
              </Col>
              <Col sm={10} style={{padding: 0}}>
                <Col sm={4} className="tabla-ritmo-extraccion-padre">
                  <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.ANALISIS')}</b></span>
                </Col>
                <Col sm={4} className="tabla-ritmo-extraccion-padre">
                  <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.DILUCION')}</b></span>
                </Col>
                <Col sm={4} className="tabla-ritmo-extraccion-padre">
                  <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.ENVASADO')}</b></span>
                </Col>
              </Col>
            </Row>
            ) : (
            <Row className="tabla-ritmo-extraccion">
              <Col sm={4} className="tabla-ritmo-extraccion-padre">
                <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.ANALISIS')}</b></span>
              </Col>
              <Col sm={4} className="tabla-ritmo-extraccion-padre">
                <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.DILUCION')}</b></span>
              </Col>
              <Col sm={4} className="tabla-ritmo-extraccion-padre">
                <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.ENVASADO')}</b></span>
              </Col>
            </Row>
            )
          }
        {
            this.state.agrupacion !== 1 ? (
              <Row className="tabla-ritmo-extraccion">
                <Col sm={2} style={{padding: 0}}>
                  <Col sm={12}className="tabla-ritmo-extraccion-padre"><span style={{padding: '15.5px'}} className="ritmo-extraccion"></span></Col>
                </Col>
                <Col sm={10} style={{padding: 0}}>
                  <Col sm={4} className="tabla-ritmo-extraccion-padre">
                    <Col sm={4} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}</span></Col>
                    <Col sm={4} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.ELIMINADO')}</span></Col>
                    <Col sm={4} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span></Col>
                  </Col>
                  <Col sm={4} className="tabla-ritmo-extraccion-padre">
                    <Col sm={4} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}</span></Col>
                    <Col sm={4} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.ELIMINADO')}</span></Col>
                    <Col sm={4} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span></Col>
                  </Col>
                  <Col sm={4} className="tabla-ritmo-extraccion-padre">
                    <Col sm={4} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}</span></Col>
                    <Col sm={4} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.ELIMINADO')}</span></Col>
                    <Col sm={4} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span></Col>
                  </Col>
                </Col>
              </Row>
            ) : (
              <Row className="tabla-ritmo-extraccion">
                <Col sm={4} className="tabla-ritmo-extraccion-padre">
                  <Col sm={4} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}</span></Col>
                  <Col sm={4} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.ELIMINADO')}</span></Col>
                  <Col sm={4} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span></Col>
                </Col>
                <Col sm={4} className="tabla-ritmo-extraccion-padre">
                  <Col sm={4} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}</span></Col>
                  <Col sm={4} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.ELIMINADO')}</span></Col>
                  <Col sm={4} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span></Col>
                </Col>
                <Col sm={4} className="tabla-ritmo-extraccion-padre">
                  <Col sm={4} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}</span></Col>
                  <Col sm={4} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.ELIMINADO')}</span></Col>
                  <Col sm={4} className="tabla-ritmo-extraccion-padre"><span className="ritmo-extraccion">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span></Col>
                </Col>
              </Row>
            )
          }
        {momentoEliminacionEyaculadoEliminado !== undefined && momentoEliminacionEyaculadoEliminado}
        <br/>
        <span><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.ELIMINADOS_POR_MES')}</b></span>
        <SimpleTablePage {...tableEliminadosMes}>
        </SimpleTablePage>
        <span><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.INDEX')}</b></span>
        {
          this.state.agrupacion !== 1 ? (
            <Row className="tabla-ritmo-extraccion">
              <Col sm={2} style={{padding: 0}}>
                {
                  this.state.agrupacion === 2 && (
                    <Col sm={12} className="tabla-ritmo-extraccion-padre">
                      <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_RAZA')}</b></span>
                    </Col>
                  )
                }
                {
                  this.state.agrupacion === 3 && (
                    <Col sm={12} className="tabla-ritmo-extraccion-padre">
                      <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_LINEA')}</b></span>
                    </Col>
                  )
                }
              </Col>
              <Col sm={10} style={{padding: 0}}>
                <Col sm={1} className="tabla-ritmo-extraccion-padre productividad-verraco-index">
                  <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.ENTRE_0_100')}</b></span>
                </Col>
                <Col sm={1} className="tabla-ritmo-extraccion-padre productividad-verraco-index">
                  <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.ENTRE_100_110')}</b></span>
                </Col>
                <Col sm={1} className="tabla-ritmo-extraccion-padre productividad-verraco-index">
                  <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.ENTRE_100_120')}</b></span>
                </Col>
                <Col sm={1} className="tabla-ritmo-extraccion-padre productividad-verraco-index">
                  <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.ENTRE_120_130')}</b></span>
                </Col>
                <Col sm={1} className="tabla-ritmo-extraccion-padre productividad-verraco-index">
                  <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.ENTRE_130_140')}</b></span>
                </Col>
                <Col sm={1} className="tabla-ritmo-extraccion-padre productividad-verraco-index">
                  <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.ENTRE_140_150')}</b></span>
                </Col>
                <Col sm={1} className="tabla-ritmo-extraccion-padre productividad-verraco-index">
                  <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.ENTRE_150_160')}</b></span>
                </Col>
                <Col sm={1} className="tabla-ritmo-extraccion-padre productividad-verraco-index">
                  <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.ENTRE_160_170')}</b></span>
                </Col>
                <Col sm={1} className="tabla-ritmo-extraccion-padre productividad-verraco-index">
                  <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.ENTRE_170_180')}</b></span>
                </Col>
                <Col sm={1} className="tabla-ritmo-extraccion-padre productividad-verraco-index">
                  <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MAS_180')}</b></span>
                </Col>
              </Col>
            </Row>
          ) : (
            <Row className="tabla-ritmo-extraccion">
              <Col sm={1} className="tabla-ritmo-extraccion-padre productividad-verraco-index">
                <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.ENTRE_0_100')}</b></span>
              </Col>
              <Col sm={1} className="tabla-ritmo-extraccion-padre productividad-verraco-index">
                <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.ENTRE_100_110')}</b></span>
              </Col>
              <Col sm={1} className="tabla-ritmo-extraccion-padre productividad-verraco-index">
                <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.ENTRE_100_120')}</b></span>
              </Col>
              <Col sm={1} className="tabla-ritmo-extraccion-padre productividad-verraco-index">
                <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.ENTRE_120_130')}</b></span>
              </Col>
              <Col sm={1} className="tabla-ritmo-extraccion-padre productividad-verraco-index">
                <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.ENTRE_130_140')}</b></span>
              </Col>
              <Col sm={1} className="tabla-ritmo-extraccion-padre productividad-verraco-index">
                <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.ENTRE_140_150')}</b></span>
              </Col>
              <Col sm={1} className="tabla-ritmo-extraccion-padre productividad-verraco-index">
                <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.ENTRE_150_160')}</b></span>
              </Col>
              <Col sm={1} className="tabla-ritmo-extraccion-padre productividad-verraco-index">
                <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.ENTRE_160_170')}</b></span>
              </Col>
              <Col sm={1} className="tabla-ritmo-extraccion-padre productividad-verraco-index">
                <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.ENTRE_170_180')}</b></span>
              </Col>
              <Col sm={1} className="tabla-ritmo-extraccion-padre productividad-verraco-index">
                <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MAS_180')}</b></span>
              </Col>
            </Row>
          )
        }
        {
          this.state.agrupacion !== 1 ? (
            <Row className="tabla-ritmo-extraccion">
              <Col sm={2} style={{padding: 0}}>
                <Col sm={12} className="tabla-ritmo-extraccion-padre"><span style={{padding: '15.5px'}} className="ritmo-extraccion"></span></Col>
              </Col>
              <Col sm={10} style={{padding: 0}}>
                <Col sm={1} className="tabla-ritmo-extraccion-padre productividad-verraco-index">
                  <Col sm={6} className="tabla-ritmo-extraccion-padre">
                    <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO')}</b></span>
                  </Col>
                  <Col sm={6} className="tabla-ritmo-extraccion-padre">
                    <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</b></span>
                  </Col>
                </Col>
                <Col sm={1} className="tabla-ritmo-extraccion-padre productividad-verraco-index">
                  <Col sm={6} className="tabla-ritmo-extraccion-padre">
                    <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO')}</b></span>
                  </Col>
                  <Col sm={6} className="tabla-ritmo-extraccion-padre">
                    <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</b></span>
                  </Col>
                </Col>
                <Col sm={1} className="tabla-ritmo-extraccion-padre productividad-verraco-index">
                  <Col sm={6} className="tabla-ritmo-extraccion-padre">
                    <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO')}</b></span>
                  </Col>
                  <Col sm={6} className="tabla-ritmo-extraccion-padre">
                    <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</b></span>
                  </Col>
                </Col>
                <Col sm={1} className="tabla-ritmo-extraccion-padre productividad-verraco-index">
                  <Col sm={6} className="tabla-ritmo-extraccion-padre">
                    <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO')}</b></span>
                  </Col>
                  <Col sm={6} className="tabla-ritmo-extraccion-padre">
                    <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</b></span>
                  </Col>
                </Col>
                <Col sm={1} className="tabla-ritmo-extraccion-padre productividad-verraco-index">
                  <Col sm={6} className="tabla-ritmo-extraccion-padre">
                    <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO')}</b></span>
                  </Col>
                  <Col sm={6} className="tabla-ritmo-extraccion-padre">
                    <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</b></span>
                  </Col>
                </Col>
                <Col sm={1} className="tabla-ritmo-extraccion-padre productividad-verraco-index">
                  <Col sm={6} className="tabla-ritmo-extraccion-padre">
                    <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO')}</b></span>
                  </Col>
                  <Col sm={6} className="tabla-ritmo-extraccion-padre">
                    <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</b></span>
                  </Col>
                </Col>
                <Col sm={1} className="tabla-ritmo-extraccion-padre productividad-verraco-index">
                  <Col sm={6} className="tabla-ritmo-extraccion-padre">
                    <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO')}</b></span>
                  </Col>
                  <Col sm={6} className="tabla-ritmo-extraccion-padre">
                    <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</b></span>
                  </Col>
                </Col>
                <Col sm={1} className="tabla-ritmo-extraccion-padre productividad-verraco-index">
                  <Col sm={6} className="tabla-ritmo-extraccion-padre">
                    <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO')}</b></span>
                  </Col>
                  <Col sm={6} className="tabla-ritmo-extraccion-padre">
                    <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</b></span>
                  </Col>
                </Col>
                <Col sm={1} className="tabla-ritmo-extraccion-padre productividad-verraco-index">
                  <Col sm={6} className="tabla-ritmo-extraccion-padre">
                    <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO')}</b></span>
                  </Col>
                  <Col sm={6} className="tabla-ritmo-extraccion-padre">
                    <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</b></span>
                  </Col>
                </Col>
                <Col sm={1} className="tabla-ritmo-extraccion-padre productividad-verraco-index">
                  <Col sm={6} className="tabla-ritmo-extraccion-padre">
                    <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO')}</b></span>
                  </Col>
                  <Col sm={6} className="tabla-ritmo-extraccion-padre">
                    <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</b></span>
                  </Col>
                </Col>
              </Col>
            </Row>
          ) : (
            <Row className="tabla-ritmo-extraccion">
              <Col sm={1} className="tabla-ritmo-extraccion-padre productividad-verraco-index">
                <Col sm={6} className="tabla-ritmo-extraccion-padre">
                  <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO')}</b></span>
                </Col>
                <Col sm={6} className="tabla-ritmo-extraccion-padre">
                  <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</b></span>
                </Col>
              </Col>
              <Col sm={1} className="tabla-ritmo-extraccion-padre productividad-verraco-index">
                <Col sm={6} className="tabla-ritmo-extraccion-padre">
                  <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO')}</b></span>
                </Col>
                <Col sm={6} className="tabla-ritmo-extraccion-padre">
                  <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</b></span>
                </Col>
              </Col>
              <Col sm={1} className="tabla-ritmo-extraccion-padre productividad-verraco-index">
                <Col sm={6} className="tabla-ritmo-extraccion-padre">
                  <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO')}</b></span>
                </Col>
                <Col sm={6} className="tabla-ritmo-extraccion-padre">
                  <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</b></span>
                </Col>
              </Col>
              <Col sm={1} className="tabla-ritmo-extraccion-padre productividad-verraco-index">
                <Col sm={6} className="tabla-ritmo-extraccion-padre">
                  <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO')}</b></span>
                </Col>
                <Col sm={6} className="tabla-ritmo-extraccion-padre">
                  <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</b></span>
                </Col>
              </Col>
              <Col sm={1} className="tabla-ritmo-extraccion-padre productividad-verraco-index">
                <Col sm={6} className="tabla-ritmo-extraccion-padre">
                  <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO')}</b></span>
                </Col>
                <Col sm={6} className="tabla-ritmo-extraccion-padre">
                  <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</b></span>
                </Col>
              </Col>
              <Col sm={1} className="tabla-ritmo-extraccion-padre productividad-verraco-index">
                <Col sm={6} className="tabla-ritmo-extraccion-padre">
                  <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO')}</b></span>
                </Col>
                <Col sm={6} className="tabla-ritmo-extraccion-padre">
                  <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</b></span>
                </Col>
              </Col>
              <Col sm={1} className="tabla-ritmo-extraccion-padre productividad-verraco-index">
                <Col sm={6} className="tabla-ritmo-extraccion-padre">
                  <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO')}</b></span>
                </Col>
                <Col sm={6} className="tabla-ritmo-extraccion-padre">
                  <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</b></span>
                </Col>
              </Col>
              <Col sm={1} className="tabla-ritmo-extraccion-padre productividad-verraco-index">
                <Col sm={6} className="tabla-ritmo-extraccion-padre">
                  <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO')}</b></span>
                </Col>
                <Col sm={6} className="tabla-ritmo-extraccion-padre">
                  <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</b></span>
                </Col>
              </Col>
              <Col sm={1} className="tabla-ritmo-extraccion-padre productividad-verraco-index">
                <Col sm={6} className="tabla-ritmo-extraccion-padre">
                  <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO')}</b></span>
                </Col>
                <Col sm={6} className="tabla-ritmo-extraccion-padre">
                  <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</b></span>
                </Col>
              </Col>
              <Col sm={1} className="tabla-ritmo-extraccion-padre productividad-verraco-index">
                <Col sm={6} className="tabla-ritmo-extraccion-padre">
                  <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO')}</b></span>
                </Col>
                <Col sm={6} className="tabla-ritmo-extraccion-padre">
                  <span className="ritmo-extraccion"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</b></span>
                </Col>
              </Col>
            </Row>
          )
        }
        {ebvIndex !== undefined && ebvIndex}
        <hr/>
        <h3>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PRODUCTIVIDAD_CENTRO')}</h3>
        <p><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO_EXTRACCIONES')}</b></p>
        <p style={{marginTop: '15px', marginBottom: '0px'}}><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.DIAS_SEMANA')}</b></p>
        {
          this.state.agrupacion !== 1 ? (
            <Row className="tabla-productividad-centro">
              <Col sm={2} style={{padding: 0}}>
                {
                  this.state.agrupacion === 2 && (
                    <Col sm={12} className="tabla-productividad-centro-padre">
                      <span className="productividad-centro"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_RAZA')}</b></span>
                    </Col>
                  )
                }
                {
                  this.state.agrupacion === 3 && (
                    <Col sm={12} className="tabla-productividad-centro-padre">
                      <span className="productividad-centro"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_LINEA')}</b></span>
                    </Col>
                  )
                }
              </Col>
              <Col sm={10} style={{padding: 0}}>
                <Col sm={2} className="tabla-productividad-centro-padre col-7">
                  <span className="productividad-centro"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.LUNES')}</b></span>
                </Col>
                <Col sm={2} className="tabla-productividad-centro-padre col-7">
                  <span className="productividad-centro"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MARTES')}</b></span>
                </Col>
                <Col sm={2} className="tabla-productividad-centro-padre col-7">
                  <span className="productividad-centro"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MIERCOLES')}</b></span>
                </Col>
                <Col sm={2} className="tabla-productividad-centro-padre col-7">
                  <span className="productividad-centro"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.JUEVES')}</b></span>
                </Col>
                <Col sm={2} className="tabla-productividad-centro-padre col-7">
                  <span className="productividad-centro"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.VIERNES')}</b></span>
                </Col>
                <Col sm={2} className="tabla-productividad-centro-padre col-7">
                  <span className="productividad-centro"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.SABADO')}</b></span>
                </Col>
                <Col sm={2} className="tabla-productividad-centro-padre col-7">
                  <span className="productividad-centro"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.DOMINGO')}</b></span>
                </Col>
              </Col>
            </Row>
          ) : (
            <Row className="tabla-productividad-centro">
              <Col sm={2} className="tabla-productividad-centro-padre col-7">
                <span className="productividad-centro"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.LUNES')}</b></span>
              </Col>
              <Col sm={2} className="tabla-productividad-centro-padre col-7">
                <span className="productividad-centro"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MARTES')}</b></span>
              </Col>
              <Col sm={2} className="tabla-productividad-centro-padre col-7">
                <span className="productividad-centro"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MIERCOLES')}</b></span>
              </Col>
              <Col sm={2} className="tabla-productividad-centro-padre col-7">
                <span className="productividad-centro"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.JUEVES')}</b></span>
              </Col>
              <Col sm={2} className="tabla-productividad-centro-padre col-7">
                <span className="productividad-centro"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.VIERNES')}</b></span>
              </Col>
              <Col sm={2} className="tabla-productividad-centro-padre col-7">
                <span className="productividad-centro"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.SABADO')}</b></span>
              </Col>
              <Col sm={2} className="tabla-productividad-centro-padre col-7">
                <span className="productividad-centro"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.DOMINGO')}</b></span>
              </Col>
            </Row>
          )
        }
        {
          this.state.agrupacion !== 1 ? (
            <Row className="tabla-productividad-centro">
              <Col sm={2} style={{padding: 0}}>
                <Col sm={12} className="tabla-productividad-centro-padre"><span style={{padding: '15.5px'}} className="productividad-centro"></span></Col>
              </Col>
              <Col sm={10} style={{padding: 0}}>
                <Col sm={2} className="tabla-productividad-centro-padre col-7">
                  <Col sm={4} className="tabla-productividad-centro-padre">
                    <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}</span>
                  </Col>
                  <Col sm={4} className="tabla-productividad-centro-padre">
                    <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span>
                  </Col>
                  <Col sm={4} className="tabla-productividad-centro-padre">
                    <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MEDIA')}</span>
                  </Col>
                </Col>
                <Col sm={2} className="tabla-productividad-centro-padre col-7">
                  <Col sm={4} className="tabla-productividad-centro-padre">
                    <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}</span>
                  </Col>
                  <Col sm={4} className="tabla-productividad-centro-padre">
                    <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span>
                  </Col>
                  <Col sm={4} className="tabla-productividad-centro-padre">
                    <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MEDIA')}</span>
                  </Col>
                </Col>
                <Col sm={2} className="tabla-productividad-centro-padre col-7">
                  <Col sm={4} className="tabla-productividad-centro-padre">
                    <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}</span>
                  </Col>
                  <Col sm={4} className="tabla-productividad-centro-padre">
                    <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span>
                  </Col>
                  <Col sm={4} className="tabla-productividad-centro-padre">
                    <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MEDIA')}</span>
                  </Col>
                </Col>
                <Col sm={2} className="tabla-productividad-centro-padre col-7">
                  <Col sm={4} className="tabla-productividad-centro-padre">
                    <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}</span>
                  </Col>
                  <Col sm={4} className="tabla-productividad-centro-padre">
                    <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span>
                  </Col>
                  <Col sm={4} className="tabla-productividad-centro-padre">
                    <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MEDIA')}</span>
                  </Col>
                </Col>
                <Col sm={2} className="tabla-productividad-centro-padre col-7">
                  <Col sm={4} className="tabla-productividad-centro-padre">
                    <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}</span>
                  </Col>
                  <Col sm={4} className="tabla-productividad-centro-padre">
                    <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span>
                  </Col>
                  <Col sm={4} className="tabla-productividad-centro-padre">
                    <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MEDIA')}</span>
                  </Col>
                </Col>
                <Col sm={2} className="tabla-productividad-centro-padre col-7">
                  <Col sm={4} className="tabla-productividad-centro-padre">
                    <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}</span>
                  </Col>
                  <Col sm={4} className="tabla-productividad-centro-padre">
                    <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span>
                  </Col>
                  <Col sm={4} className="tabla-productividad-centro-padre">
                    <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MEDIA')}</span>
                  </Col>
                </Col>
                <Col sm={2} className="tabla-productividad-centro-padre col-7">
                  <Col sm={4} className="tabla-productividad-centro-padre">
                    <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}</span>
                  </Col>
                  <Col sm={4} className="tabla-productividad-centro-padre">
                    <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span>
                  </Col>
                  <Col sm={4} className="tabla-productividad-centro-padre">
                    <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MEDIA')}</span>
                  </Col>
                </Col>
              </Col>
            </Row>
          ) : (
            <Row className="tabla-productividad-centro">
              <Col sm={2} className="tabla-productividad-centro-padre col-7">
                <Col sm={4} className="tabla-productividad-centro-padre">
                  <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}</span>
                </Col>
                <Col sm={4} className="tabla-productividad-centro-padre">
                  <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span>
                </Col>
                <Col sm={4} className="tabla-productividad-centro-padre">
                  <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MEDIA')}</span>
                </Col>
              </Col>
              <Col sm={2} className="tabla-productividad-centro-padre col-7">
                <Col sm={4} className="tabla-productividad-centro-padre">
                  <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}</span>
                </Col>
                <Col sm={4} className="tabla-productividad-centro-padre">
                  <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span>
                </Col>
                <Col sm={4} className="tabla-productividad-centro-padre">
                  <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MEDIA')}</span>
                </Col>
              </Col>
              <Col sm={2} className="tabla-productividad-centro-padre col-7">
                <Col sm={4} className="tabla-productividad-centro-padre">
                  <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}</span>
                </Col>
                <Col sm={4} className="tabla-productividad-centro-padre">
                  <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span>
                </Col>
                <Col sm={4} className="tabla-productividad-centro-padre">
                  <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MEDIA')}</span>
                </Col>
              </Col>
              <Col sm={2} className="tabla-productividad-centro-padre col-7">
                <Col sm={4} className="tabla-productividad-centro-padre">
                  <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}</span>
                </Col>
                <Col sm={4} className="tabla-productividad-centro-padre">
                  <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span>
                </Col>
                <Col sm={4} className="tabla-productividad-centro-padre">
                  <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MEDIA')}</span>
                </Col>
              </Col>
              <Col sm={2} className="tabla-productividad-centro-padre col-7">
                <Col sm={4} className="tabla-productividad-centro-padre">
                  <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}</span>
                </Col>
                <Col sm={4} className="tabla-productividad-centro-padre">
                  <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span>
                </Col>
                <Col sm={4} className="tabla-productividad-centro-padre">
                  <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MEDIA')}</span>
                </Col>
              </Col>
              <Col sm={2} className="tabla-productividad-centro-padre col-7">
                <Col sm={4} className="tabla-productividad-centro-padre">
                  <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}</span>
                </Col>
                <Col sm={4} className="tabla-productividad-centro-padre">
                  <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span>
                </Col>
                <Col sm={4} className="tabla-productividad-centro-padre">
                  <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MEDIA')}</span>
                </Col>
              </Col>
              <Col sm={2} className="tabla-productividad-centro-padre col-7">
                <Col sm={4} className="tabla-productividad-centro-padre">
                  <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}</span>
                </Col>
                <Col sm={4} className="tabla-productividad-centro-padre">
                  <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span>
                </Col>
                <Col sm={4} className="tabla-productividad-centro-padre">
                  <span className="productividad-centro">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MEDIA')}</span>
                </Col>
              </Col>
            </Row>
          )
        }
        {prodCentNumExtraccionesDiasSemana !== undefined && prodCentNumExtraccionesDiasSemana}
        <p style={{marginTop: '15px', marginBottom: '0px'}}><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PERIODO_TIEMPO')}</b></p>
        <SimpleTablePage {...tablePeriodoTiempo}>
        </SimpleTablePage>
        <p style={{marginTop: '20px'}}><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO_DOSIS')}</b></p>
        <p><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.EXTRACCIONES')}</b></p>
        <SimpleTablePage {...tablemesesExtracciones}>
        </SimpleTablePage>
        <p style={{marginTop: '20px'}}><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.POOL')}</b></p>
        <SimpleTableNewChildrenPage {...tablemesesPool}>
        </SimpleTableNewChildrenPage>
        <p style={{marginTop: '20px'}}><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TIPO_DOSIS')}</b></p>
        <SimpleTableNewChildrenPage {...tablemesesTipoDosis}>
        </SimpleTableNewChildrenPage>
        <p style={{marginTop: '20px'}}><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.DIAS_SEMANA')}</b></p>
        <SimpleTableNewChildrenPage {...tablemesesDiasSemana}>
        </SimpleTableNewChildrenPage>
        <Col sm={4}>
          <span className="edad-estado-centro"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.RELACION_DOSIS_TEORICAS_REALES')}:</b> {dosisTeoricasRealesRelacion !== undefined && dosisTeoricasRealesRelacion}</span>
        </Col>
        <div className="clearfix"></div>
        <p style={{marginTop: '15px', marginBottom: '0px'}}><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.CALIDAD_DOSIS')}</b></p>
        <p style={{marginTop: '15px', marginBottom: '0px'}}><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.CONCENTRACION_TOTAL')}</b></p>
        <SimpleTableNewChildrenPage {...tableConcentracionDosis}>
        </SimpleTableNewChildrenPage>
        <p style={{marginTop: '15px', marginBottom: '0px'}}><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.CONCENTRACION_UTIL')}</b></p>
        <SimpleTableNewChildrenPage {...tableconcentracionUtil}>
        </SimpleTableNewChildrenPage>
        <p style={{marginTop: '15px', marginBottom: '0px'}}><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MOTILIDAD')}</b></p>
        <SimpleTableNewChildrenPage {...tablemotilidad}>
        </SimpleTableNewChildrenPage>
        <p style={{marginTop: '15px', marginBottom: '0px'}}><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.FUERA_VALORES')}</b></p>
        <p style={{marginTop: '15px', marginBottom: '0px'}}><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.TOTAL')}</b></p>
        <SimpleTableNewChildrenPage {...tabletotal}>
        </SimpleTableNewChildrenPage>
        <p style={{marginTop: '15px', marginBottom: '0px'}}><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MES')}</b></p>
        <SimpleTableNewChildrenPage {...tablemeses}>
        </SimpleTableNewChildrenPage>
        <hr/>
        <h3>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.ELIMINACION_VERRACOS')}</h3>
        <p style={{marginTop: '15px', marginBottom: '0px'}}><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.BAJAS_VERRACOS_TOTALES')}</b></p>
        <SimpleTablePage {...tableBajasTotalesMes}>
        </SimpleTablePage>
        <p style={{marginTop: '15px', marginBottom: '0px'}}><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.DISTRIBUCION_CAUSA_ELIMINACION')}</b></p>
        {
          this.state.agrupacion !== 1 ? (
            <Row className="tabla-eliminacion-verracos">
              <Col sm={2} style={{padding: 0}}>
                {
                  this.state.agrupacion === 2 && (
                    <Col sm={12} className="tabla-eliminacion-verracos-padre">
                      <span className="eliminacion-verracos"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_RAZA')}</b></span>
                    </Col>
                  )
                }
                {
                  this.state.agrupacion === 3 && (
                    <Col sm={12} className="tabla-eliminacion-verracos-padre">
                      <span className="eliminacion-verracos"><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NOMBRE_LINEA')}</b></span>
                    </Col>
                  )
                }
              </Col>
              <Col sm={10} style={{padding: 0}}>
                <Col sm={2} className="tabla-eliminacion-verracos-padre col-7">
                  <span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.GENETICA')}</span>
                </Col>
                <Col sm={2} className="tabla-eliminacion-verracos-padre col-7">
                  <span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PRODUCTIVIDAD')}</span>
                </Col>
                <Col sm={2} className="tabla-eliminacion-verracos-padre col-7">
                  <span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.CALIDAD_SEMINAL')}</span>
                </Col>
                <Col sm={2} className="tabla-eliminacion-verracos-padre col-7">
                  <span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.COJERAS')}</span>
                </Col>
                <Col sm={2} className="tabla-eliminacion-verracos-padre col-7">
                  <span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MUERTE_SUBITA')}</span>
                </Col>
                <Col sm={2} className="tabla-eliminacion-verracos-padre col-7">
                  <span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.ENFERMEDAD')}</span>
                </Col>
                <Col sm={2} className="tabla-eliminacion-verracos-padre col-7">
                  <span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.OTRAS')}</span>
                </Col>
              </Col>
            </Row>
          ) : (
            <Row className="tabla-eliminacion-verracos">
              <Col sm={2} className="tabla-eliminacion-verracos-padre col-7">
                <span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.GENETICA')}</span>
              </Col>
              <Col sm={2} className="tabla-eliminacion-verracos-padre col-7">
                <span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PRODUCTIVIDAD')}</span>
              </Col>
              <Col sm={2} className="tabla-eliminacion-verracos-padre col-7">
                <span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.CALIDAD_SEMINAL')}</span>
              </Col>
              <Col sm={2} className="tabla-eliminacion-verracos-padre col-7">
                <span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.COJERAS')}</span>
              </Col>
              <Col sm={2} className="tabla-eliminacion-verracos-padre col-7">
                <span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MUERTE_SUBITA')}</span>
              </Col>
              <Col sm={2} className="tabla-eliminacion-verracos-padre col-7">
                <span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.ENFERMEDAD')}</span>
              </Col>
              <Col sm={2} className="tabla-eliminacion-verracos-padre col-7">
                <span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.OTRAS')}</span>
              </Col>
            </Row>
          )
        }
        {
          this.state.agrupacion !== 1 ? (
            <Row className="tabla-eliminacion-verracos">
              <Col sm={2} style={{padding: 0}}>
              {
                this.state.agrupacion === 2 && (
                  <Col sm={12} className="tabla-eliminacion-verracos-padre">
                    <Col sm={12} className="tabla-eliminacion-verracos-padre"><span style={{padding: '15.5px'}} className="eliminacion-verracos"></span></Col>
                  </Col>
                )
              }
              {
                this.state.agrupacion === 3 && (
                  <Col sm={12} className="tabla-eliminacion-verracos-padre">
                    <Col sm={12} className="tabla-eliminacion-verracos-padre"><span style={{padding: '15.5px'}} className="eliminacion-verracos"></span></Col>
                  </Col>
                )
              }
              </Col>
              <Col sm={10} style={{padding: 0}}>
                <Col sm={2} className="tabla-eliminacion-verracos-padre col-7">
                  <Col sm={6} className="tabla-eliminacion-verracos-padre"><span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO_VERRACOS')}</span></Col>
                  <Col sm={6} className="tabla-eliminacion-verracos-padre"><span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span></Col>
                </Col>
                <Col sm={2} className="tabla-eliminacion-verracos-padre col-7">
                  <Col sm={6} className="tabla-eliminacion-verracos-padre"><span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO_VERRACOS')}</span></Col>
                  <Col sm={6} className="tabla-eliminacion-verracos-padre"><span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span></Col>
                </Col>
                <Col sm={2} className="tabla-eliminacion-verracos-padre col-7">
                  <Col sm={6} className="tabla-eliminacion-verracos-padre"><span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO_VERRACOS')}</span></Col>
                  <Col sm={6} className="tabla-eliminacion-verracos-padre"><span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span></Col>
                </Col>
                <Col sm={2} className="tabla-eliminacion-verracos-padre col-7">
                  <Col sm={6} className="tabla-eliminacion-verracos-padre"><span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO_VERRACOS')}</span></Col>
                  <Col sm={6} className="tabla-eliminacion-verracos-padre"><span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span></Col>
                </Col>
                <Col sm={2} className="tabla-eliminacion-verracos-padre col-7">
                  <Col sm={6} className="tabla-eliminacion-verracos-padre"><span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO_VERRACOS')}</span></Col>
                  <Col sm={6} className="tabla-eliminacion-verracos-padre"><span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span></Col>
                </Col>
                <Col sm={2} className="tabla-eliminacion-verracos-padre col-7">
                  <Col sm={6} className="tabla-eliminacion-verracos-padre"><span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO_VERRACOS')}</span></Col>
                  <Col sm={6} className="tabla-eliminacion-verracos-padre"><span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span></Col>
                </Col>
                <Col sm={2} className="tabla-eliminacion-verracos-padre col-7">
                  <Col sm={6} className="tabla-eliminacion-verracos-padre"><span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO_VERRACOS')}</span></Col>
                  <Col sm={6} className="tabla-eliminacion-verracos-padre"><span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span></Col>
                </Col>
            </Col>
          </Row>
          ) : (
            <Row className="tabla-eliminacion-verracos">
              <Col sm={2} className="tabla-eliminacion-verracos-padre col-7">
                <Col sm={6} className="tabla-eliminacion-verracos-padre"><span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO_VERRACOS')}</span></Col>
                <Col sm={6} className="tabla-eliminacion-verracos-padre"><span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span></Col>
              </Col>
              <Col sm={2} className="tabla-eliminacion-verracos-padre col-7">
                <Col sm={6} className="tabla-eliminacion-verracos-padre"><span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO_VERRACOS')}</span></Col>
                <Col sm={6} className="tabla-eliminacion-verracos-padre"><span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span></Col>
              </Col>
              <Col sm={2} className="tabla-eliminacion-verracos-padre col-7">
                <Col sm={6} className="tabla-eliminacion-verracos-padre"><span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO_VERRACOS')}</span></Col>
                <Col sm={6} className="tabla-eliminacion-verracos-padre"><span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span></Col>
              </Col>
              <Col sm={2} className="tabla-eliminacion-verracos-padre col-7">
                <Col sm={6} className="tabla-eliminacion-verracos-padre"><span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO_VERRACOS')}</span></Col>
                <Col sm={6} className="tabla-eliminacion-verracos-padre"><span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span></Col>
              </Col>
              <Col sm={2} className="tabla-eliminacion-verracos-padre col-7">
                <Col sm={6} className="tabla-eliminacion-verracos-padre"><span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO_VERRACOS')}</span></Col>
                <Col sm={6} className="tabla-eliminacion-verracos-padre"><span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span></Col>
              </Col>
              <Col sm={2} className="tabla-eliminacion-verracos-padre col-7">
                <Col sm={6} className="tabla-eliminacion-verracos-padre"><span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO_VERRACOS')}</span></Col>
                <Col sm={6} className="tabla-eliminacion-verracos-padre"><span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span></Col>
              </Col>
              <Col sm={2} className="tabla-eliminacion-verracos-padre col-7">
                <Col sm={6} className="tabla-eliminacion-verracos-padre"><span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO_VERRACOS')}</span></Col>
                <Col sm={6} className="tabla-eliminacion-verracos-padre"><span className="eliminacion-verracos">{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.PORCENTAJE')}</span></Col>
              </Col>
            </Row>
          )
        }
        {eliminacionVerracosCausasEliminacion !== undefined && eliminacionVerracosCausasEliminacion}
        <hr/>
        <h3>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.CLIENTES')}</h3>
        <Col sm={4}>
          <span className="edad-estado-centro" style={{marginBottom: '10px'}}><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.NUMERO_CLIENTES')}:</b> {clientes.numeroClientes}</span>
        </Col>
        <div className="clearfix"></div>
        <p style={{marginTop: '15px', marginBottom: '0px'}}><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.ALBARANES')}</b></p>
        <SimpleTablePage {...tableAlbaranes}>
        </SimpleTablePage>
        <Col sm={4}>
          <span className="edad-estado-centro" style={{marginBottom: '10px'}}><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MEDIA_ALBARANES')}:</b> {clientesMediaAlbaranes !== undefined && clientesMediaAlbaranes}</span>
        </Col>
        <div className="clearfix"></div>
        <p style={{marginTop: '15px', marginBottom: '0px'}}><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.DOSIS_ENVIADAS')}</b></p>
        <SimpleTablePage {...tableDosisEnviadas}>
        </SimpleTablePage>
        <Col sm={4}>
          <span className="edad-estado-centro" style={{marginBottom: '10px'}}><b>{t('INFORMES.ESTADO_CENTRO.PRODUCTIVIDAD_VERRACO.MEDIA_DOSIS_ENVIADAS')}:</b> {clientesMediaDosisEnviadas !== undefined && clientesMediaDosisEnviadas}</span>
        </Col>

        <SimpleTablePage {...tableClientesDosisMes}>
        </SimpleTablePage>
        <div className="clearfix"></div>
      </div>
    )
  }
}

export default InformesEstadoCentro