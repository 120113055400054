export default {
  FETCH_OPERARIO: 'FETCH_OPERARIO',
  FETCH_OPERARIO_SUCCESS: 'FETCH_OPERARIO_SUCCESS',
  SUBMIT_NUEVA_OPERARIO: 'SUBMIT_NUEVA_OPERARIO',
  SUBMIT_NUEVA_OPERARIO_SUCCESS: 'SUBMIT_NUEVA_OPERARIO_SUCCESS',
  RECUPERAR_DATOS_MODAL_OPERARIO: 'RECUPERAR_DATOS_MODAL_OPERARIO',
  RECUPERAR_DATOS_MODAL_OPERARIO_SUCCESS: 'RECUPERAR_DATOS_MODAL_OPERARIO_SUCCESS',
  ABRIR_MODAL_VACIO_OPERARIO: 'ABRIR_MODAL_VACIO_OPERARIO',
  EDITAR_OPERARIO: 'EDITAR_OPERARIO',
  EDITAR_OPERARIO_SUCCESS: 'EDITAR_OPERARIO_SUCCESS',
  DUPLICAR_DATOS_MODAL_OPERARIO: 'DUPLICAR_DATOS_MODAL_OPERARIO',
  DUPLICAR_DATOS_MODAL_OPERARIO_SUCCESS: 'DUPLICAR_DATOS_MODAL_OPERARIO_SUCCESS',
  CAMBIAR_ESTADO_OPERARIO: 'CAMBIAR_ESTADO_OPERARIO',
  CAMBIAR_ESTADO_OPERARIO_SUCCESS: 'CAMBIAR_ESTADO_OPERARIO_SUCCESS',
  ON_DELETE_OPERARIO: 'ON_DELETE_OPERARIO',
  ON_DELETE_OPERARIO_SUCCESS: 'ON_DELETE_OPERARIO_SUCCESS',
  FETCH_FILTRAR_OPERARIO: 'FETCH_FILTRAR_OPERARIO',
  FETCH_FILTRAR_OPERARIO_SUCCESS: 'FETCH_FILTRAR_OPERARIO_SUCCESS',
  SELECCIONAR_TODOS_OPERARIO: 'SELECCIONAR_TODOS_OPERARIO',
  DESELECCIONAR_TODOS_OPERARIO: 'DESELECCIONAR_TODOS_OPERARIO',
  CAMBIAR_ESTADO_SELECCION_OPERARIO: 'CAMBIAR_ESTADO_SELECCION_OPERARIO',
  DELETE_SELECCION_OPERARIO: 'DELETE_SELECCION_OPERARIO',
  CREATE_CSV_OPERARIO: 'CREATE_CSV_OPERARIO',
  CREATE_CSV_OPERARIO_SUCCESS: 'CREATE_CSV_OPERARIO_SUCCESS',
  GUARDAR_Y_NUEVO_OPERARIO: 'GUARDAR_Y_NUEVO_OPERARIO',
  CAMBIAR_OPERARIO_PROCESADO: 'CAMBIAR_OPERARIO_PROCESADO',
  CAMBIAR_OPERARIO_ALBARAN: 'CAMBIAR_OPERARIO_ALBARAN',
  IMPRIMIR_PDF_OPERARIO: 'IMPRIMIR_PDF_OPERARIO',
  IMPRIMIR_PDF_OPERARIO_SUCCESS: 'IMPRIMIR_PDF_OPERARIO_SUCCESS'
}
