import React, { Component } from 'react'
import ListadoPage from '../../../containers/page/ListadoPage'
import SimpleModalPage from '../../../containers/comun/modal/SimpleModalPage'
import FiltrosVisitaPage from '../../../containers/planSanitario/visita/FiltrosVisitaPage'
import ModalVisitaPage from '../../../containers/planSanitario/visita/ModalVisitaPage'
import MensajeAlertaPage from '../../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import {permisos as permisosConstant} from '../../../constants/permisos'

class Visita extends Component {

  componentDidMount () {
    document.title = this.props.t('MENU.PLAN_SANITARIO.VISITAS') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    this.props.actions.fetchFiltrarVisita({})
  }

  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual,
      visita:{list, checkedFiltrosVisita, data, dataDuplicar},
      actions: {recuperarDatosModalVisita, duplicarDatosModalVisita, cambiarEstadoVisita, onDeleteVisita, imprimirInformeVisita}
    } = this.props

    list.forEach(
      (row) => row.idElemento = row.idVisita
    )

    const tableVisita = {
      id: 'VisitaTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.VISITAS] > 1),
      hasOptions: true,
      columns: [
        {id: 'nombreVisita', name: t('PLAN_SANITARIO.VISITA.CONTACTO')},
        {id: 'dniVisita', name: t('PLAN_SANITARIO.VISITA.DNI')},
        {id: 'matriculaVisita', name: t('PLAN_SANITARIO.VISITA.MATRICULA')},
        {id: 'empresaVisita', name: t('PLAN_SANITARIO.VISITA.EMPRESA')},
        {id: 'motivoVisita', name: t('PLAN_SANITARIO.VISITA.MOTIVO_VISITA'), textAlignLeft: true},
        {id: 'diasUltimaVisita', name: t('PLAN_SANITARIO.VISITA.DIAS_ULTIMA_VISITA')},
        {id: 'lugarUltimaVisita', name: t('PLAN_SANITARIO.VISITA.ULTIMO_LUGAR_VISITADO')},
        {id: 'fechaVisita', name: t('PLAN_SANITARIO.VISITA.FECHA'), type: 'fecha'},
      ],
      mostrarCambioEstado: false,
      mostrarDuplicar: false,
      mostrarImprimirInforme: true,
      imprimirInforme: (row) => imprimirInformeVisita(null, row.idVisita),
      rows: list,
      filtros: <FiltrosVisitaPage/>,
      onEdit: (row) => recuperarDatosModalVisita(row.idVisita),
      data: data,
      cambiarEstado: (row) => cambiarEstadoVisita(row.idVisita),
      mostrarEliminar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.VISITAS] > 1,
      onDelete: (row) => onDeleteVisita(row.idVisita, row.nombreVisita, row.empresaVisita),
      initialValues: {}
    }
    tableVisita.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableVisita.initialValues['check' + row.idElemento] = checkedFiltrosVisita
        }
      }
    )
    return (
      <div>
        <SimpleModalPage/>
        <MensajeAlertaPage/>
        <ModalVisitaPage 
          duplicar={(row) =>duplicarDatosModalVisita(row.idVisita)}
        />
        <ListadoPage t_key="PLAN_SANITARIO.VISITA." table={tableVisita}>
        </ListadoPage>
      </div>
    )
  }
}

export default Visita