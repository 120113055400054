export default {
  FETCH_POOL_EXTRACCIONES: 'FETCH_POOL_EXTRACCIONES',
  FETCH_POOL_EXTRACCIONES_SUCCESS: 'FETCH_POOL_EXTRACCIONES_SUCCESS',
  SELECCIONAR_TODOS_POOL_EXTRACCIONES: 'SELECCIONAR_TODOS_POOL_EXTRACCIONES',
  DESELECCIONAR_TODOS_POOL_EXTRACCIONES: 'DESELECCIONAR_TODOS_POOL_EXTRACCIONES',
  ON_DELETE_POOL_EXTRACCION: 'ON_DELETE_POOL_EXTRACCION',
  DELETE_SELECCION_POOL_EXTRACCIONES: 'DELETE_SELECCION_POOL_EXTRACCIONES',
  CREATE_CSV_POOL_EXTRACCIONES: 'CREATE_CSV_POOL_EXTRACCIONES',
  IMPRIMIR_PDF_POOL_EXTRACCIONES: 'IMPRIMIR_PDF_POOL_EXTRACCIONES',
  SUBMIT_FORM_POOL_EXTRACCION: 'SUBMIT_FORM_POOL_EXTRACCION',
  RECUPERAR_DATOS_FORM_POOL_EXTRACCION: 'RECUPERAR_DATOS_FORM_POOL_EXTRACCION',
  RECUPERAR_DATOS_FORM_POOL_EXTRACCION_SUCCESS: 'RECUPERAR_DATOS_FORM_POOL_EXTRACCION_SUCCESS',
  FETCH_CODIGO_POOL_EXTRACCIONES: 'FETCH_CODIGO_POOL_EXTRACCIONES',
  FETCH_CODIGO_POOL_EXTRACCIONES_SUCCESS: 'FETCH_CODIGO_POOL_EXTRACCIONES_SUCCESS',
  HANDLE_POOL_CALC_FUNCTIONS_ORDEN: 'HANDLE_POOL_CALC_FUNCTIONS_ORDEN',
  CALC_CONCENTRACION_X_DOSIS_POOL: 'CALC_CONCENTRACION_X_DOSIS_POOL',
  CALC_NUMERO_DOSIS_POOL: 'CALC_NUMERO_DOSIS_POOL',
  RE_CALC_CONCENTRACION_X_DOSIS_POOL: 'RE_CALC_CONCENTRACION_X_DOSIS_POOL',
  SELECCIONAR_TODOS_FORM_POOL_EXTRACCIONES: 'SELECCIONAR_TODOS_FORM_POOL_EXTRACCIONES',
  DESELECCIONAR_TODOS_FORM_POOL_EXTRACCIONES: 'DESELECCIONAR_TODOS_FORM_POOL_EXTRACCIONES',
  INITIALIZE_FORM_POOL_EXTRACCION: 'INITIALIZE_FORM_POOL_EXTRACCION',
  INITIALIZE_FORM_POOL_EXTRACCION_SUCCESS: 'INITIALIZE_FORM_POOL_EXTRACCION_SUCCESS',
  SELECCIONAR_TODOS_ASIGNACION_ANALISIS_POOL: 'SELECCIONAR_TODOS_ASIGNACION_ANALISIS_POOL',
  DESELECCIONAR_TODOS_ASIGNACION_ANALISIS_POOL: 'DESELECCIONAR_TODOS_ASIGNACION_ANALISIS_POOL',
  ON_DELETE_ASIGNACION_ANALISIS_POOL: 'ON_DELETE_ASIGNACION_ANALISIS_POOL',
  DELETE_SELECCION_ASIGNACION_ANALISIS_POOL: 'DELETE_SELECCION_ASIGNACION_ANALISIS_POOL',
  SET_ASIGNACION_ANALISIS_POOL: 'SET_ASIGNACION_ANALISIS_POOL',
  ABRIR_MODAL_ASIGNACION_ANALISIS_POOL: 'ABRIR_MODAL_ASIGNACION_ANALISIS_POOL',
  ABRIR_MODAL_ASIGNACION_ANALISIS_POOL_SUCCESS: 'ABRIR_MODAL_ASIGNACION_ANALISIS_POOL_SUCCESS',
  CERRAR_MODAL_ASIGNACION_ANALISIS_POOL: 'CERRAR_MODAL_ASIGNACION_ANALISIS_POOL',
  CERRAR_MODAL_ASIGNACION_ANALISIS_POOL_SUCCESS: 'CERRAR_MODAL_ASIGNACION_ANALISIS_POOL_SUCCESS',
  GUARDAR_MODAL_ASIGNACION_ANALISIS_POOL: 'GUARDAR_MODAL_ASIGNACION_ANALISIS_POOL',
  GUARDAR_MODAL_ASIGNACION_ANALISIS_POOL_SUCCESS: 'GUARDAR_MODAL_ASIGNACION_ANALISIS_POOL_SUCCESS',
  SELECCIONAR_TODOS_DISPONIBLES_EXTRACCIONES: 'SELECCIONAR_TODOS_DISPONIBLES_EXTRACCIONES',
  DESELECCIONAR_TODOS_DISPONIBLES_EXTRACCIONES: 'DESELECCIONAR_TODOS_DISPONIBLES_EXTRACCIONES',
  DELETE_DISPONIBLES_ANALISIS_POOL: 'DELETE_DISPONIBLES_ANALISIS_POOL',
  DELETE_SELECCION_DISPONIBLES_ANALISIS_POOL: 'DELETE_SELECCION_DISPONIBLES_ANALISIS_POOL',
  FETCH_ANALISIS_DISPONIBLES: 'FETCH_ANALISIS_DISPONIBLES',
  FETCH_ANALISIS_DISPONIBLES_SUCCESS: 'FETCH_ANALISIS_DISPONIBLES_SUCCESS',
  DESCARGAR_ETIQUETA_PDF: 'DESCARGAR_ETIQUETA_PDF',
  FETCH_TOKEN_MAGAVISION_POOL: 'FETCH_TOKEN_MAGAVISION_POOL',
  FETCH_TOKEN_MAGAVISION_POOL_SUCCESS: 'FETCH_TOKEN_MAGAVISION_POOL_SUCCESS',
  UPDATE_TOKEN_MAGAVISION_POOL_SUCCESS: 'UPDATE_TOKEN_MAGAVISION_POOL_SUCCESS',
  IMPRIMIR_ETIQUETA_PDF_POOL: 'IMPRIMIR_ETIQUETA_PDF_POOL'
}