import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize} from 'redux-form'
import actionTypes from '../../constants/actions/ruta/ruta'
import {fetchRutaSuccess, onDeleteClienteAnadidoSuccess, fetchClienteRutaSuccess, recuperarDatosModalRutaSuccess, duplicarDatosModalRutaSuccess, limpiar, fetchFiltrarRutaSuccess,
  fetchFiltrarClienteRutaSuccess, crearCsvRutaSuccess, imprimirPdfRutaSuccess, submitNuevaRutaSuccess, editarRutaSuccess, cambiarEstadoRutaSuccess, onDeleteRutaSuccess, abrirModalVacioRutaSuccess
} from '../../actions/ruta/ruta'
import {openModal, closeModal, openSimpleModal, openMensajeAlerta} from '../../actions/common'
import { yesNoModal } from '../modal/yesNoModal'
import getRutaList from '../../services/ruta/getRutaList'
import addRuta from '../../services/ruta/addRuta'
import getClientes from '../../services/ruta/getClientes'
import getRuta from '../../services/ruta/getRuta'
import editRuta from '../../services/ruta/editRuta'
import getcambioEstadoRuta from '../../services/ruta/getcambioEstadoRuta'
import deleteRuta from '../../services/ruta/deleteRuta'
import deleteRutaSeleccion from '../../services/ruta/deleteRutaSeleccion'
import cambioEstadoRutaSeleccion from '../../services/ruta/cambioEstadoRutaSeleccion'
import filtrarRuta from '../../services/ruta/filtrarRuta'
import getClientesFiltrados from '../../services/ruta/getClientesFiltrados'
import getCrearCsvRuta from '../../services/ruta/getCrearCsvRuta'
import getImprimirPdfRuta from '../../services/ruta/getImprimirPdfRuta'
import {comboRuta} from '../../actions/combos/combos'

export function * fetchRuta () {
  try {
    const list = yield call(getRutaList, null)
    yield put(fetchRutaSuccess(list))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchfetchRuta () {
  yield takeLatest(actionTypes.FETCH_RUTA, fetchRuta)
}

export function * abrirModalVacioRuta () {
  try {
    const valores = {activo: true}
    yield put(initialize('ModalRuta', valores))
    yield put(abrirModalVacioRutaSuccess())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchAbrirModalVacioRuta() {
  yield takeLatest(actionTypes.ABRIR_MODAL_VACIO_RUTA, abrirModalVacioRuta)
}

export function * onDeleteClienteAnadido ({idDireccionCliente}) {
  try {
    const clientesNoMostrar = yield select(state => state.ruta.clientesAnadidos)
    const clientesParaMostrar = clientesNoMostrar.filter((cliente) => {
      if (cliente.idDireccionCliente !== idDireccionCliente){
        return cliente
      }
    })
    let auth = yield select(state => state.auth)
    yield put(onDeleteClienteAnadidoSuccess(clientesParaMostrar))
    const val = yield select(state => state.ruta.filtros)
    const lista = yield call(filtrarRuta, val, auth.token)
    yield put(fetchRutaSuccess(lista))
    yield put(fetchFiltrarRutaSuccess(val))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchOnDeleteClienteAnadido () {
  yield takeLatest(actionTypes.ON_DELETE_CLIENTE_ANADIDO, onDeleteClienteAnadido)
}

export function * submitNuevoRuta ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const insercionRuta = yield call(addRuta, values, auth.token)
    yield put(submitNuevaRutaSuccess(insercionRuta))
    if (insercionRuta.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-insercion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    yield put(closeModal())
    yield put(limpiar())
    const val = yield select(state => state.ruta.filtros)
    const lista = yield call(filtrarRuta, val, auth.token)
    yield put(fetchRutaSuccess(lista))
    yield put(fetchFiltrarRutaSuccess(val))
    yield put(comboRuta())
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchSubmitNuevoRuta () {
  yield takeLatest(actionTypes.SUBMIT_NUEVO_RUTA, submitNuevoRuta)
}

export function * fetchClienteRuta ({clienteAnadidos}) {
  try {
    let auth = yield select(state => state.auth)
    const listClientes = yield call(getClientes, clienteAnadidos, auth.token)
    yield put(fetchClienteRutaSuccess(listClientes))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchFetchClienteRuta () {
  yield takeLatest(actionTypes.FETCH_CLIENTE_RUTA, fetchClienteRuta)
}

export function * deleteSeleccionClientesRuta ({listClientes}) {
  try {
    yield put(onDeleteClienteAnadidoSuccess(listClientes))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchDeleteSeleccionClientesRuta () {
  yield takeLatest(actionTypes.DELETE_SELECCION_CLIENTE_RUTA, deleteSeleccionClientesRuta)
}

export function * recuperarDatosModalRuta ({idRuta}) {
  try {
    const data = yield call(getRuta, idRuta)
    yield put(recuperarDatosModalRutaSuccess(data))
    yield put(initialize('ModalRuta', data))
    yield put(abrirModalVacioRutaSuccess())
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchRecuperarDatosModalRuta () {
  yield takeLatest(actionTypes.RECUPERAR_DATOS_MODAL_RUTA, recuperarDatosModalRuta)
}

export function * editarRuta ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const edicionRuta = yield call(editRuta, values, auth.token)
    yield put(editarRutaSuccess(edicionRuta))
    if (edicionRuta.actualizado === true) {
      yield put(openMensajeAlerta('modificacion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-modificacion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    yield put(closeModal())
    yield put(limpiar())
    yield put(comboRuta())
    const val = yield select(state => state.ruta.filtros)
    if (val.nombreRuta && values.nombreRuta){
      val.nombreRuta = {value: val.nombreRuta.value, label: values.nombreRuta}
    }
    const lista = yield call(filtrarRuta, val, auth.token)
    yield put(fetchRutaSuccess(lista))
    yield put(fetchFiltrarRutaSuccess(val))
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchEditarRuta () {
  yield takeLatest(actionTypes.EDITAR_RUTA, editarRuta)
}

export function * duplicarDatosModalRuta ({idRuta, onSuccessCallback, onErrorCallback}) {
  try {
    const dataDupliacar = yield call(getRuta, idRuta)
    dataDupliacar.nombreRuta = dataDupliacar.nombreRuta + ' - Copia'
    yield put(duplicarDatosModalRutaSuccess(dataDupliacar))
    yield put(initialize('ModalRuta', dataDupliacar))
    yield put(abrirModalVacioRutaSuccess())
    if (onSuccessCallback) yield onSuccessCallback()
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchDuplicarDatosModalRuta () {
  yield takeLatest(actionTypes.DUPLICAR_DATOS_MODAL_RUTA, duplicarDatosModalRuta)
}

export function * guardarYnuevoRuta ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
     const insercionRuta = yield call(addRuta, values, auth.token)
     yield put(submitNuevaRutaSuccess(insercionRuta))
     if (insercionRuta.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
       if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-insercion'))
       if (onErrorCallback) yield onErrorCallback()
    }
    const val = yield select(state => state.ruta.filtros)
    const lista = yield call(filtrarRuta, val, auth.token)
    yield put(fetchRutaSuccess(lista))
    yield put(fetchFiltrarRutaSuccess(val))
    const valores = {activo: true, predefinido: false}
    yield put(initialize('ModalRuta', valores))
    yield put(limpiar())
    yield put(comboRuta())
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchGuardarYnuevoRuta () {
  yield takeLatest(actionTypes.GUARDAR_Y_NUEVO_RUTA, guardarYnuevoRuta)
}

export function * cambiarEstadoRuta ({idRuta}) {
  try {
    yield put(openSimpleModal('cambiarEstado'))
    const confirmed = yield call(yesNoModal, {modalType: 'cambiarEstado'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const cambioEstado = yield call(getcambioEstadoRuta, idRuta, auth.token)
      yield put(cambiarEstadoRutaSuccess(cambioEstado))
      if (cambioEstado.actualizado) {
        yield put(openMensajeAlerta('cambio-estado'))
      } else {
        yield put(openMensajeAlerta('no-cambio-estado'))
      }
      const val = yield select(state => state.ruta.filtros)
      const lista = yield call(filtrarRuta, val, auth.token)
      yield put(fetchRutaSuccess(lista))
      yield put(fetchFiltrarRutaSuccess(val))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchCambiarEstadoRuta () {
  yield takeLatest(actionTypes.CAMBIAR_ESTADO_RUTA, cambiarEstadoRuta)
}

export function * onDeleteRuta ({idRuta}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const onDelete = yield call(deleteRuta, idRuta, auth.token)
      yield put(onDeleteRutaSuccess(onDelete))
      if (onDelete.eliminado === true && onDelete.asociado === false) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(comboRuta())
      const val = yield select(state => state.ruta.filtros)
      if (val.nombreRuta && val.nombreRuta.value === idRuta){
        val.nombreRuta = null
      }
      const lista = yield call(filtrarRuta, val, auth.token)
      yield put(fetchRutaSuccess(lista))
      yield put(fetchFiltrarRutaSuccess(val))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchOnDeleteRuta () {
  yield takeLatest(actionTypes.ON_DELETE_RUTA, onDeleteRuta)
}

export function * deleteSeleccionRuta ({list}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      let onDeleteSeleccion = yield call(deleteRutaSeleccion, list, auth.token)
      if (onDeleteSeleccion.eliminado === true && onDeleteSeleccion.asociado === false) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(comboRuta())
      yield put(initialize('simpleTable'))
      const val = yield select(state => state.ruta.filtros)
      list.forEach((li) => {
        if (val.nombreRuta && val.nombreRuta.value === li.idRuta){
          val.nombreRuta = null
        }
      })
      const lista = yield call(filtrarRuta, val, auth.token)
      yield put(fetchRutaSuccess(lista))
      yield put(fetchFiltrarRutaSuccess(val))
      yield put(initialize('filtrosRuta', val))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchDeleteSeleccionRuta () {
  yield takeLatest(actionTypes.DELETE_SELECCION_RUTA, deleteSeleccionRuta)
}

export function * cambioEstadoSeleccionRuta ({list}) {
  try {
    yield put(openSimpleModal('cambiarEstado'))
    const confirmed = yield call(yesNoModal, {modalType: 'cambiarEstado'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      let cambioEstadoSeleccion = yield call(cambioEstadoRutaSeleccion, list, auth.token)
      if (cambioEstadoSeleccion.actualizado) {
        yield put(openMensajeAlerta('cambio-estado'))
      } else {
        yield put(openMensajeAlerta('no-cambio-estado'))
      }
      yield put(initialize('simpleTable'))
      const val = yield select(state => state.ruta.filtros)
      const lista = yield call(filtrarRuta, val, auth.token)
      yield put(fetchRutaSuccess(lista))
      yield put(fetchFiltrarRutaSuccess(val))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchCambioEstadoSeleccionRuta () {
  yield takeLatest(actionTypes.CAMBIO_ESTADO_SELECCION_RUTA, cambioEstadoSeleccionRuta)
}

export function * fetchFiltrarRuta ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const list = yield call(filtrarRuta, values, auth.token)
    yield put(fetchRutaSuccess(list))
    yield put(fetchFiltrarRutaSuccess(values))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchFetchFiltrarRuta () {
  yield takeLatest(actionTypes.FETCH_FILTRAR_RUTA, fetchFiltrarRuta)
}

export function * fetchFiltrarClienteRuta ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const listClientes = yield call(getClientesFiltrados, values, auth.token)
    yield put(fetchClienteRutaSuccess(listClientes))
    yield put(fetchFiltrarClienteRutaSuccess(values))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchFetchFiltrarClienteRuta () {
  yield takeLatest(actionTypes.FETCH_FILTRAR_CLIENTE_RUTA, fetchFiltrarClienteRuta)
}

export function * crearCsvRuta ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosExcelRuta = yield call(getCrearCsvRuta, list, auth.token)
    yield put(crearCsvRutaSuccess(datosExcelRuta))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchCrearCsvRuta () {
  yield takeLatest(actionTypes.CREATE_CSV_RUTA, crearCsvRuta)
}
export function * imprimirPdfRuta ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfRuta = yield call(getImprimirPdfRuta, list, auth.token)
    yield put(imprimirPdfRutaSuccess(datosPdfRuta))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirPdfRuta () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_RUTA, imprimirPdfRuta)
}
