import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import setting from '../../../setting'
import { withTranslation } from 'react-i18next'
import EditarVerraco from '../../../components/fichaVerraco/editarVerraco/EditarVerraco'
import { comboLineaGeneticaActiva,comboRaza, comboRazaActiva, comboOperarioPredeterminado, comboSalaExtraccionActivo, comboCliente, comboUbicacion, comboTipoDosis, comboDireccionesClienteSinPredeterminado } from '../../../actions/combos/combos'
import { fetchEditarVerraco, submitEditarVerraco, fetchHistoricoEstadosVerraco, fetchHistoricoGrupoVerraco, fetchHistoricoExtracciones, fetchHistoricoIntervenciones, abrirModalHistoricoGrupos, abrirModalHistoricoExtracciones, abrirModalHistoricoEstado, abrirModalHistoricoIntervenciones } from '../../../actions/fichaVerraco/editarVerraco/editarVerraco'
import { fetchFichaVerraco, fetchFiltrarFichaVerraco, asignarCodRaza, recuperarDatosModalEvolucion, deleteEvolucion, duplicarDatosModalEvolucion, getAlimentacion,
      recuperarDatosModalAlimentacion, deleteAlimentacion, duplicarDatosModalAlimentacion, getIndex, recuperarDatosModalIndex, deleteIndex, cargarEvolucion,
      duplicarDatosModalIndex, abrirModalUbicacion, abrirModalCambioEstadoVerraco, abrirModalVerracoCambioEstado, editarEvolucion, cargarIndex, cargarAlimentacion, imprimirDetalleVerraco, restartVerraco, resetUbicacion } from '../../../actions/fichaVerraco/fichaVerraco'
import { uploadFile, quitarDirectorioTemporal, eliminaImagen, subirArchivos, eliminarArchvio, obtenerArchivoContent } from '../../../actions/comun/comun'
import {ubicacionenesVacias} from '../../../actions/movimientoVerraco/movimientoVerraco'
import { enviarFechaCambioEstado } from '../../../actions/cambioEstadoVerraco/cambioEstadoVerraco'
import {obtenerDatosPanelControl} from '../../../actions/panelControl/panelControl'

export function mapStateToProps (state) {
  return {
    auth: {...state.auth},
    combos: state.combos,
    fichaVerraco: {...state.fichaVerraco},
    movimientoVerraco: {...state.movimientoVerraco},
    formEditarVerraco: state.form.editarVerraco,
    cambioEstadoVerraco: state.cambioEstadoVerraco,
    urlRecursos: setting.default.urlRecursos,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    panelControl: state.panelControl
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      comboLineaGeneticaActiva,
      comboRazaActiva,
      comboOperarioPredeterminado,
      comboSalaExtraccionActivo,
      comboCliente,
      comboUbicacion,
      comboTipoDosis,
      fetchFichaVerraco,
      fetchFiltrarFichaVerraco,
      asignarCodRaza,
      recuperarDatosModalEvolucion,
      deleteEvolucion,
      duplicarDatosModalEvolucion,
      getAlimentacion,
      recuperarDatosModalAlimentacion,
      deleteAlimentacion,
      duplicarDatosModalAlimentacion,
      getIndex,
      recuperarDatosModalIndex,
      deleteIndex,
      duplicarDatosModalIndex,
      uploadFile,
      quitarDirectorioTemporal,
      eliminaImagen,
      subirArchivos,
      eliminarArchvio,
      obtenerArchivoContent,
      fetchEditarVerraco,
      submitEditarVerraco,
      abrirModalUbicacion,
      ubicacionenesVacias,
      abrirModalCambioEstadoVerraco,
      fetchHistoricoEstadosVerraco,
      fetchHistoricoGrupoVerraco,
      abrirModalVerracoCambioEstado,
      editarEvolucion,
      cargarEvolucion,
      cargarIndex,
      comboRaza,
      fetchHistoricoExtracciones,
      fetchHistoricoIntervenciones,
      cargarAlimentacion,
      imprimirDetalleVerraco,
      enviarFechaCambioEstado,
      restartVerraco,
      resetUbicacion,
      obtenerDatosPanelControl,
      abrirModalHistoricoGrupos,
      abrirModalHistoricoExtracciones,
      abrirModalHistoricoEstado,
      abrirModalHistoricoIntervenciones,
      comboDireccionesClienteSinPredeterminado
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditarVerraco))