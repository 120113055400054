import actionTypes from '../../constants/actions/transporte/transporte'

export function fetchTransportista () {
  return {
    type: actionTypes.FETCH_TRANSPORTISTA
  }
}

export function fetchTransportistaSuccess (list) {
  return {
    type: actionTypes.FETCH_TRANSPORTISTA_SUCCESS,
    list
  }
}

export function filtrarTransporte (values) {
  return {
    type: actionTypes.FILTRAR_TRANSPORTE,
    values
  }
}

export function filtrarTrasnporteSuccess (filtros) {
  return {
    type: actionTypes.FILTRAR_TRANSPORTE_SUCCESS,
    filtros
  }
}

export function abrirModalConductor () {
  return {
    type: actionTypes.ABRIR_MODAL_CONDUCTOR
  }
}

export function closeModalConductor () {
  return {
    type: actionTypes.CLOSE_MODAL_CONDUCTOR
  }
}

export function submitNuevoConductor (values) {
  return {
    type: actionTypes.SUBMIT_NUEVO_CONDUCTOR,
    values
  }
}

export function recuperarModalConductor (idConductor) {
  return {
    type: actionTypes.RECUPERAR_MODAL_CONDUCTOR,
    idConductor
  }
}

export function recuperarModalConductorSuccess (conductorRecuperar) {
  return {
    type: actionTypes.RECUPERAR_MODAL_CONDUCTOR_SUCCESS,
    conductorRecuperar
  }
}

export function editConductorAnadido (values) {
  return {
    type: actionTypes.EDIT_CONDUCTOR_ANADIDO,
    values
  }
}

export function editConductorAnadidoSuccess (conductoresAnadidos) {
  return {
    type: actionTypes.EDIT_CONDUCTOR_ANADIDO_SUCCESS,
    conductoresAnadidos
  }
}

export function cambiarEstadoConductor (idConductor) {
  return {
    type: actionTypes.CAMBIAR_ESTADO_CONDUCTOR,
    idConductor
  }
}

export function cambiarEstadoConductorSuccess (conductor) {
  return {
    type: actionTypes.CAMBIAR_ESTADO_CONDUCTOR_SUCCESS,
    conductor
  }
}

export function onDeleteConductorAnadido (idConductor, idProducto) {
  return {
    type: actionTypes.ON_DELETE_CONDUCTOR_ANADADIDO,
    idConductor,
    idProducto
  }
}

export function duplicarConductor (idConductor) {
  return {
    type: actionTypes.DUPLICAR_CONDUCTOR,
    idConductor
  }
}

export function duplicarConductorSucccess (duplicarConductor) {
  return {
    type: actionTypes.DUPLICAR_CONDUCTOR_SUCCESS,
    duplicarConductor
  }
}

export function conductorPorDefecto (idConductor) {
  return {
    type: actionTypes.CONDUCTOR_POR_DEFECTO,
    idConductor
  }
}

export function conductorPorDefectoSuccess (conductor) {
  return {
    type: actionTypes.CONDUCTOR_POR_DEFECTO_SUCCESS,
    conductor
  }
}

export function reiniciarConductoresVehiculosRutas () {
  return {
    type: actionTypes.REINICIAR_CONDUCTORES_VEHICULOS_RUTAS
  }
}

export function abrirModalVehiculo () {
  return {
    type: actionTypes.ABRIR_MODAL_VEHICULO
  }
}

export function closeModalVehiculo () {
  return {
    type: actionTypes.CLOSE_MODAL_VEHICULO
  }
}

export function submitNuevoVehiculo (values) {
  return {
    type: actionTypes.SUBMIT_NUEVO_VEHICULO,
    values
  }
}

export function recuperarModalVehiculo (idVehiculo) {
  return {
    type: actionTypes.RECUPERAR_MODAL_VEHICULO,
    idVehiculo
  }
}

export function recuperarModalVehiculoSuccess (vehiculoRecuperar) {
  return {
    type: actionTypes.RECUPERAR_MODAL_VEHICULO_SUCCESS,
    vehiculoRecuperar
  }
}

export function editVehiculoAnadido (values) {
  return {
    type: actionTypes.EDIT_VEHICULO_ANADIDO,
    values
  }
}

export function editVehiculoAnadidoSuccess (vehiculosAnadidos) {
  return {
    type: actionTypes.EDIT_VEHICULO_ANADIDO_SUCCESS,
    vehiculosAnadidos
  }
}

export function cambiarEstadoVehiculo (idVehiculo) {
  return {
    type: actionTypes.CAMBIAR_ESTADO_VEHICULO,
    idVehiculo
  }
}

export function cambiarEstadoVehiculoSuccess (vehiculo) {
  return {
    type: actionTypes.CAMBIAR_ESTADO_VEHICULO_SUCCESS,
    vehiculo
  }
}

export function onDeleteVehiculoAnadido (idVehiculo, id) {
  return {
    type: actionTypes.ON_DELETE_VEHICULO_ANADADIDO,
    idVehiculo,
    id
  }
}

export function duplicarVehiculo (idVehiculo) {
  return {
    type: actionTypes.DUPLICAR_VEHICULO,
    idVehiculo
  }
}

export function duplicarVehiculoSucccess (duplicarVehiculo) {
  return {
    type: actionTypes.DUPLICAR_VEHICULO_SUCCESS,
    duplicarVehiculo
  }
}

export function vehiculoPorDefecto (idVehiculo) {
  return {
    type: actionTypes.VEHICULO_POR_DEFECTO,
    idVehiculo
  }
}

export function vehiculoPorDefectoSuccess (vehiculo) {
  return {
    type: actionTypes.VEHICULO_POR_DEFECTO_SUCCESS,
    vehiculo
  }
}

export function abrirModalRuta (values) {
  return {
    type: actionTypes.ABRIR_MODAL_RUTA,
    values
  }
}

export function closeModalRuta () {
  return {
    type: actionTypes.CLOSE_MODAL_RUTA
  }
}

export function filtrarRutasTransporte (rutasAnadidas) {
  return {
    type: actionTypes.FILTRAR_RUTAS_TRANSPORTE,
    rutasAnadidas
  }
}

export function filtrarRutasTransporteSuccess (listRutas) {
  return {
    type: actionTypes.FILTRAR_RUTAS_TRANSPORTE_SUCCESS,
    listRutas
  }
}

export function seleccionarTodosModalRutaTransportista () {
  return {
    type: actionTypes.SELECCIONAR_TODOS_MODAL_RUTA_TRANSPORTISTA
  }
}

export function deSeleccionarTodosModalRutaTransportista () {
  return {
    type: actionTypes.DESELECCIONAR_TODOS_MODAL_RUTA_TRANSPORTISTA
  }
}

export function anadirRutasTransportista (list) {
  return {
    type: actionTypes.ANADIR_RUTAS_TRANPORTISTA,
    list
  }
}

export function onDeleteRutaAnadida (idRuta) {
  return {
    type: actionTypes.ON_DELETE_RUTA_ANADADIDA,
    idRuta
  }
}

export function onDeleteRutaAnadidaSuccess (rutasAnadidas) {
  return {
    type: actionTypes.ON_DELETE_RUTA_ANADADIDA_SUCCESS,
    rutasAnadidas
  }
}

export function rutaPorDefecto (idRuta) {
  return {
    type: actionTypes.RUTA_POR_DEFECTO,
    idRuta
  }
}

export function rutaPorDefectoSuccess (ruta) {
  return {
    type: actionTypes.RUTA_POR_DEFECTO_SUCCESS,
    ruta
  }
}

export function filtrosAplicados (filtrosModalRuta) {
  return {
    type: actionTypes.FILTROS_APLICADOS,
    filtrosModalRuta
  }
}

export function submitNuevoTransportista (values, history, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.SUBMIT_NUEVO_TRANSPORTISTA,
    values,
    history,
    onSuccessCallback,
    onErrorCallback
  }
}

export function guardarYnuevoTransporte (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.GUARDAR_Y_NUEVO_TRANSPORTE,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function guardarYNuevoConductor (conductoresAnadidos) {
  return {
    type: actionTypes.GUARDAR_Y_NUEVO_CONDUCTOR,
    conductoresAnadidos
  }
}

export function guardarYNuevoVehiculo (vehiculosAnadidos) {
  return {
    type: actionTypes.GUARDAR_Y_NUEVO_VEHICULO,
    vehiculosAnadidos
  }
}

export function seleccionarTodosConductores () {
  return {
    type: actionTypes.SELECCIONAR_TODOS_CONDUCTORES
  }
}

export function deSeleccionarTodosConductores () {
  return {
    type: actionTypes.DESELECCIONAR_TODOS_CONDUCTORES
  }
}

export function seleccionarTodosVehiculos () {
  return {
    type: actionTypes.SELECCIONAR_TODOS_VEHICULOS
  }
}

export function deSeleccionarTodosVehiculos () {
  return {
    type: actionTypes.DESELECCIONAR_TODOS_VEHICULOS
  }
}

export function seleccionarTodosRutas () {
  return {
    type: actionTypes.SELECCIONAR_TODOS_RUTAS
  }
}

export function deSeleccionarTodosRutas () {
  return {
    type: actionTypes.DESELECCIONAR_TODOS_RUTAS
  }
}

export function deleteSeleccionConductor (conductoresAnadidos) {
  return {
    type: actionTypes.DELETE_SELECCION_CONDUCTOR,
    conductoresAnadidos
  }
}

export function deleteSeleccionVehiculo (vehiculosAnadidos) {
  return {
    type: actionTypes.DELETE_SELECCION_VEHICULO,
    vehiculosAnadidos
  }
}

export function deleteSeleccionRutaTransportista (rutasAnadidas) {
  return {
    type: actionTypes.DELETE_SELECCION_RUTA_TRANSPORTISTA,
    rutasAnadidas
  }
}

export function fetchEditarTransportista (idTransportista) {
  return {
    type: actionTypes.FETCH_EDITAR_TRANSPORTISTA,
    idTransportista
  }
}

export function submitEditarTransportista (values, history) {
  return {
    type: actionTypes.SUBMIT_EDITAR_TRANSPORTISTA,
    values,
    history
  }
}

export function cambiarEstadoTransporte (idTransportista) {
  return {
    type: actionTypes.CAMBIAR_ESTADO_TRANSPORTE,
    idTransportista
  }
}

export function cambiarEstadoTransporteSuccess (cambioEstado) {
  return {
    type: actionTypes.CAMBIAR_ESTADO_TRANSPORTE_SUCCESS,
    cambioEstado
  }
}

export function onDeleteTransporte (idTransportista) {
  return {
    type: actionTypes.ON_DELETE_TRANSPORTE,
    idTransportista
  }
}

export function onDeleteTransporteSuccess (onDelete) {
  return {
    type: actionTypes.ON_DELETE_TRANSPORTE_SUCCESS,
    onDelete
  }
}

export function seleccionarTodosTransporte () {
  return {
    type: actionTypes.SELECCIONAR_TODOS_TRANSPORTE
  }
}

export function deSeleccionarTodosTransporte () {
  return {
    type: actionTypes.DESELECCIONAR_TODOS_TRANSPORTE
  }
}

export function cambioEstadoSeleccionTransporte (list) {
  return {
    type: actionTypes.CAMBIO_ESTADO_SELECCION_TRANSPORTE,
    list
  }
}

export function deleteSeleccionTransporte (list) {
  return {
    type: actionTypes.DELETE_SELECCION_TRANSPORTE,
    list
  }
}

export function crearCsvTransporte(list){
  return {
    type: actionTypes.CREATE_CSV_TRANSPORTE,
    list
  }
}

export function crearCsvTransporteSuccess(datosExcelTransporte){
  return {
    type: actionTypes.CREATE_CSV_TRANSPORTE_SUCCESS,
    datosExcelTransporte
  }
}
export function imprimirPdfTransporte(list){
  return {
    type: actionTypes.IMPRIMIR_PDF_TRANSPORTE,
    list
  }
}

export function imprimirPdfTransporteSuccess(datosPdfTransporte){
  return {
    type: actionTypes.IMPRIMIR_PDF_TRANSPORTE_SUCCESS,
    datosPdfTransporte
  }
}

export default {
  fetchTransportista,
  fetchTransportistaSuccess,
  filtrarTransporte,
  filtrarTrasnporteSuccess,
  abrirModalConductor,
  closeModalConductor,
  submitNuevoConductor,
  editConductorAnadido,
  editConductorAnadidoSuccess,
  recuperarModalConductor,
  cambiarEstadoConductor,
  cambiarEstadoConductorSuccess,
  onDeleteConductorAnadido,
  duplicarConductor,
  duplicarConductorSucccess,
  conductorPorDefecto,
  conductorPorDefectoSuccess,
  reiniciarConductoresVehiculosRutas,
  abrirModalVehiculo,
  closeModalVehiculo,
  submitNuevoVehiculo,
  recuperarModalVehiculo,
  recuperarModalVehiculoSuccess,
  editVehiculoAnadido,
  editVehiculoAnadidoSuccess,
  cambiarEstadoVehiculo,
  cambiarEstadoVehiculoSuccess,
  onDeleteVehiculoAnadido,
  duplicarVehiculo,
  duplicarVehiculoSucccess,
  vehiculoPorDefecto,
  vehiculoPorDefectoSuccess,
  abrirModalRuta,
  closeModalRuta,
  filtrarRutasTransporte,
  filtrarRutasTransporteSuccess,
  seleccionarTodosModalRutaTransportista,
  deSeleccionarTodosModalRutaTransportista,
  anadirRutasTransportista,
  onDeleteRutaAnadida,
  rutaPorDefecto,
  onDeleteRutaAnadidaSuccess,
  rutaPorDefectoSuccess,
  filtrosAplicados,
  submitNuevoTransportista,
  guardarYnuevoTransporte,
  guardarYNuevoConductor,
  seleccionarTodosConductores,
  deSeleccionarTodosConductores,
  seleccionarTodosVehiculos,
  deSeleccionarTodosVehiculos,
  seleccionarTodosRutas,
  deSeleccionarTodosRutas,
  deleteSeleccionConductor,
  deleteSeleccionVehiculo,
  deleteSeleccionRutaTransportista,
  fetchEditarTransportista,
  submitEditarTransportista,
  cambiarEstadoTransporte,
  cambiarEstadoTransporteSuccess,
  onDeleteTransporte,
  onDeleteTransporteSuccess,
  seleccionarTodosTransporte,
  deSeleccionarTodosTransporte,
  cambioEstadoSeleccionTransporte,
  deleteSeleccionTransporte,
  crearCsvTransporte,
  crearCsvTransporteSuccess,
  imprimirPdfTransporte,
  imprimirPdfTransporteSuccess
}
