import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize} from 'redux-form'
import razaActionTypes from '../../constants/actions/raza/raza'
import {fetchRazasSuccess, recuperarDatosModalRazaSuccess, duplicarDatosModalRazaSuccess, cambiarEstadoRazaSuccess, onDeleteRazaSuccess, deSeleccionarTodosRaza, crearCsvRazaSuccess, imprimirPdfRazaSuccess, closeModalRaza, openModalRaza,
fetchFiltrarRazaSuccess, submitNuevaRazaSuccess, editRazaSuccess} from '../../actions/raza/raza'
import {openModal, closeModal, openSimpleModal, openMensajeAlerta} from '../../actions/common'
import { yesNoModal } from '../modal/yesNoModal'
import getRazaList from '../../services/raza/getRazaList'
import addRaza from '../../services/raza/addRaza'
import getRaza from '../../services/raza/getRaza'
import editRaza from '../../services/raza/editRaza'
import getRazaParaDuplicar from '../../services/raza/getRazaParaDuplicar'
import getRazaCambioEstado from '../../services/raza/getRazaCambioEstado'
import deleteRaza from '../../services/raza/deleteRaza'
import getFiltrarRaza from '../../services/raza/getFiltrarRaza'
import getCambiarEstadoSeleccion from '../../services/raza/getCambiarEstadoSeleccion'
import getDeleteSeleccion from '../../services/raza/getDeleteSeleccion'
import getCrearCsv from '../../services/raza/getCrearCsv'
import getImprimirPdfRaza from '../../services/raza/getImprimirPdfRaza'
import {comboCodRaza, comboRaza} from '../../actions/combos/combos'

export function * fetchRazas () {
  try {
    const list = yield call(getRazaList, null)
    yield put(fetchRazasSuccess(list))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchFetchRazas () {
  yield takeLatest(razaActionTypes.FETCH_RAZAS, fetchRazas)
}

export function * submitNuevaRaza ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const insercionRaza = yield call(addRaza, values, auth.token)
    yield put(submitNuevaRazaSuccess(insercionRaza))
    if (insercionRaza.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-insercion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    yield put(closeModalRaza())
    const val = yield select(state => state.raza.filtros)
    const list = yield call(getFiltrarRaza, val, auth.token)
    yield put(fetchRazasSuccess(list))
    yield put(fetchFiltrarRazaSuccess(val))
    yield put(comboCodRaza())
    yield put(comboRaza())
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /**/
  }
}
export function * watchSubmitNuevaRaza () {
  yield takeLatest(razaActionTypes.SUBMIT_NUEVA_RAZA, submitNuevaRaza)
}

export function * recuperarDatosModalRaza ({idRaza}) {
  try {
    // let auth = yield select(state => state.auth)
    const registroModificar = yield call(getRaza, idRaza)
    yield put(recuperarDatosModalRazaSuccess(registroModificar))
    yield put(initialize('ModalRaza', registroModificar))
    yield put(openModalRaza())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchRecuperarDatosModalRaza () {
  yield takeLatest(razaActionTypes.RECUPERAR_DATOS_MODAL_RAZA, recuperarDatosModalRaza)
}

export function * abrirModalVacioRaza () {
  try {
    yield put(recuperarDatosModalRazaSuccess(null))
    const valores = {activo: true}
    yield put(initialize('ModalRaza', valores))
    yield put(openModalRaza())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchAbrirModalVacioRaza () {
  yield takeLatest(razaActionTypes.ABRIR_MODAL_VACIO_RAZA, abrirModalVacioRaza)
}

export function * editarRaza ({values, onSuccessCallback, onErrorCallback}) {
  try {
    if (values.cambiarEstado === false && values.activo === false) {
      yield put(openSimpleModal('cambiarEstadoVerracosVivos'))
      const confirmedVerracosVivos = yield call(yesNoModal, {modalType: 'cambiarEstadoVerracosVivos'})
      if (confirmedVerracosVivos) {
        let auth = yield select(state => state.auth)
        const edicionRaza = yield call(editRaza, values, auth.token)
        yield put(editRazaSuccess(edicionRaza))
        if (edicionRaza.actualizado === true) {
          yield put(openMensajeAlerta('modificacion'))
          if (onSuccessCallback) yield onSuccessCallback()
        } else {
          yield put(openMensajeAlerta('no-modificacion'))
          if (onErrorCallback) yield onErrorCallback()
        }
        yield put(closeModalRaza())
        yield put(comboCodRaza())
        yield put(comboRaza())
        const val = yield select(state => state.raza.filtros)
        if (val.nombreRaza && values.nombreRaza){
          val.nombreRaza = {value: val.nombreRaza.value, label: values.nombreRaza}
        }
        if (val.codRaza && values.codRaza){
          val.codRaza = {value: val.codRaza.value, label: values.codRaza}
        }
        const list = yield call(getFiltrarRaza, val, auth.token)
        yield put(fetchRazasSuccess(list))
        yield put(fetchFiltrarRazaSuccess(val))
        yield put(initialize('filtrosRaza', val))
      } else {
        if (onErrorCallback) yield onErrorCallback()
      }
    } else {
      let auth = yield select(state => state.auth)
      const edicionRaza = yield call(editRaza, values, auth.token)
      yield put(editRazaSuccess(edicionRaza))
      if (edicionRaza.actualizado === true) {
        yield put(openMensajeAlerta('modificacion'))
        if (onSuccessCallback) yield onSuccessCallback()
      } else {
        yield put(openMensajeAlerta('no-modificacion'))
        if (onErrorCallback) yield onErrorCallback()
      }
      yield put(closeModalRaza())
      yield put(comboCodRaza())
      yield put(comboRaza())
      let val = yield select(state => state.raza.filtros)
      if (val.nombreRaza && values.nombreRaza){
        val.nombreRaza = {value: val.nombreRaza.value, label: values.nombreRaza}
      }
      if (val.codRaza && values.codRaza){
        val.codRaza = {value: val.codRaza.value, label: values.codRaza}
      }
      const list = yield call(getFiltrarRaza, val, auth.token)
      yield put(fetchRazasSuccess(list))
      yield put(fetchFiltrarRazaSuccess(val))
      yield put(initialize('filtrosRaza', val))
    }
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /**/
  }
}
export function * watchEditarRaza () {
  yield takeLatest(razaActionTypes.EDITAR_RAZA, editarRaza)
}

export function * duplicarDatosModalRaza ({idRaza, onSuccessCallback, onErrorCallback}) {
  try {
    // let auth = yield select(state => state.auth)
    yield put(recuperarDatosModalRazaSuccess(null))
    const duplicar = yield call(getRazaParaDuplicar, idRaza)
    yield put(duplicarDatosModalRazaSuccess(duplicar))
    yield put(initialize('ModalRaza', duplicar))
    yield put(openModalRaza())
    if (onSuccessCallback) yield onSuccessCallback()
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchDuplicarDatosModalRaza () {
  yield takeLatest(razaActionTypes.DUPLICAR_DATOS_MODAL_RAZA, duplicarDatosModalRaza)
}

export function * cambiarEstadoRaza ({idRaza, cambiarEstado}) {
  try {
    if (cambiarEstado === false) {
      yield put(openSimpleModal('cambiarEstadoVerracosVivos'))
      const confirmedVerracosVivos = yield call(yesNoModal, {modalType: 'cambiarEstadoVerracosVivos'})
      if (confirmedVerracosVivos) {
        let auth = yield select(state => state.auth)
        const cambioEstado = yield call(getRazaCambioEstado, idRaza, auth.token)
        yield put(cambiarEstadoRazaSuccess(cambioEstado))
        if (cambioEstado.actualizado) {
          yield put(openMensajeAlerta('cambio-estado'))
        } else {
          yield put(openMensajeAlerta('no-cambio-estado'))
        }
        const values = yield select(state => state.raza.filtros)
        const list = yield call(getFiltrarRaza, values, auth.token)
        yield put(fetchRazasSuccess(list))
        yield put(fetchFiltrarRazaSuccess(values))
      }
    } else {
      yield put(openSimpleModal('cambiarEstado'))
      const confirmed = yield call(yesNoModal, {modalType: 'cambiarEstado'})
      if (confirmed) {
        let auth = yield select(state => state.auth)
        const cambioEstado = yield call(getRazaCambioEstado, idRaza, auth.token)
        yield put(cambiarEstadoRazaSuccess(cambioEstado))
        if (cambioEstado.actualizado) {
          yield put(openMensajeAlerta('cambio-estado'))
        } else {
          yield put(openMensajeAlerta('no-cambio-estado'))
        }
        const values = yield select(state => state.raza.filtros)
        const list = yield call(getFiltrarRaza, values, auth.token)
        yield put(fetchRazasSuccess(list))
        yield put(fetchFiltrarRazaSuccess(values))
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchCambiarEstadoRaza () {
  yield takeLatest(razaActionTypes.CAMBIAR_ESTADO_RAZA, cambiarEstadoRaza)
}

export function * onDeleteRaza ({idRaza}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const onDelete = yield call(deleteRaza, idRaza, auth.token)
      yield put(onDeleteRazaSuccess(onDelete))
      if (onDelete.eliminado === true && onDelete.asociado === false) {
        yield put(openMensajeAlerta('eliminacion'))
      } else if (onDelete.eliminado === false && onDelete.asociado === true) {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(comboCodRaza())
      yield put(comboRaza())
      const values = yield select(state => state.raza.filtros)
      if (values.nombreRaza && values.nombreRaza.value === idRaza) {
        values.nombreRaza = null
      }
      if (values.codRaza && values.codRaza.value === idRaza) {
        values.codRaza = null
      }
      const list = yield call(getFiltrarRaza, values, auth.token)
      yield put(fetchRazasSuccess(list))
      yield put(fetchFiltrarRazaSuccess(values))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchOnDeleteRaza () {
  yield takeLatest(razaActionTypes.ON_DELETE_RAZA, onDeleteRaza)
}

export function * fetchFiltrarRaza ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const list = yield call(getFiltrarRaza, values, auth.token)
    yield put(fetchRazasSuccess(list))
    yield put(fetchFiltrarRazaSuccess(values))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchFetchFiltrarRaza () {
  yield takeLatest(razaActionTypes.FETCH_FILTRAR_RAZA, fetchFiltrarRaza)
}

export function * cambiarEstadoSeleccionRaza ({list}) {
  try {
    let tieneVerracosVivos = true
    list.forEach(function(li){
      if (li.cambiarEstado === false){
        tieneVerracosVivos = false
      }
    })
    if (tieneVerracosVivos === false) {
      yield put(openSimpleModal('cambiarEstadoVerracosVivos'))
      const confirmedVerracosVivos = yield call(yesNoModal, {modalType: 'cambiarEstadoVerracosVivos'})
      if (confirmedVerracosVivos) {
        let auth = yield select(state => state.auth)
        let cambioEstadoSeleccion = yield call(getCambiarEstadoSeleccion, list, auth.token)
        if (cambioEstadoSeleccion.actualizado) {
          yield put(openMensajeAlerta('cambio-estado'))
        } else {
          yield put(openMensajeAlerta('no-cambio-estado'))
        }
        yield put(deSeleccionarTodosRaza())
        yield put(initialize('FiltrosRaza'))
        yield put(initialize('simpleTable'))
        const val = yield select(state => state.raza.filtros)
        const lista = yield call(getFiltrarRaza, val, auth.token)
        yield put(fetchRazasSuccess(lista))
        yield put(fetchFiltrarRazaSuccess(val))
      }
    } else {
      yield put(openSimpleModal('cambiarEstado'))
      const confirmed = yield call(yesNoModal, {modalType: 'cambiarEstado'})
      if (confirmed) {
        let auth = yield select(state => state.auth)
        let cambioEstadoSeleccion = yield call(getCambiarEstadoSeleccion, list, auth.token)
        if (cambioEstadoSeleccion.actualizado) {
          yield put(openMensajeAlerta('cambio-estado'))
        } else {
          yield put(openMensajeAlerta('no-cambio-estado'))
        }
        yield put(deSeleccionarTodosRaza())
        yield put(initialize('FiltrosRaza'))
        yield put(initialize('simpleTable'))
        const val = yield select(state => state.raza.filtros)
        const lista = yield call(getFiltrarRaza, val, auth.token)
        yield put(fetchRazasSuccess(lista))
        yield put(fetchFiltrarRazaSuccess(val))
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchCambiarEstadoSeleccionRaza () {
  yield takeLatest(razaActionTypes.CAMBIAR_ESTADO_SELECCION_RAZA, cambiarEstadoSeleccionRaza)
}

export function * deleteSeleccionRaza ({list}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const onDelete = yield call(getDeleteSeleccion, list, auth.token)
      yield put(deSeleccionarTodosRaza())
      if (onDelete.eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(initialize('simpleTable'))
      yield put(comboCodRaza())
      yield put(comboRaza())
      const val = yield select(state => state.raza.filtros)
      list.forEach((li) => {
        if (val.nombreRaza && val.nombreRaza.value === li.idRaza) {
          val.nombreRaza = null
        }
        if (val.codRaza && val.codRaza.value === li.idRaza) {
          val.codRaza = null
        }
      })
      const lista = yield call(getFiltrarRaza, val, auth.token)
      yield put(fetchRazasSuccess(lista))
      yield put(fetchFiltrarRazaSuccess(val))
      yield put(initialize('filtrosRaza', val))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchDeleteSeleccionRaza () {
  yield takeLatest(razaActionTypes.DELETE_SELECCION_RAZA, deleteSeleccionRaza)
}

export function * crearCsvRaza ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosExcelRaza = yield call(getCrearCsv, list, auth.token)
    yield put(crearCsvRazaSuccess(datosExcelRaza))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchCrearCsvRaza () {
  yield takeLatest(razaActionTypes.CREATE_CSV_RAZA, crearCsvRaza)
}

export function * guardarYnuevoRaza ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const insercionRaza = yield call(addRaza, values, auth.token)
    yield put(submitNuevaRazaSuccess(insercionRaza))
    if (insercionRaza.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-insercion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    const valores = {activo: true}
    yield put(initialize('ModalRaza', valores))
    const val = yield select(state => state.raza.filtros)
    const list = yield call(getFiltrarRaza, val, auth.token)
    yield put(fetchRazasSuccess(list))
    yield put(fetchFiltrarRazaSuccess(val))
    yield put(comboCodRaza())
      yield put(comboRaza())
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchGuardarYnuevoRaza () {
  yield takeLatest(razaActionTypes.GUARDAR_Y_NUEVO_RAZA, guardarYnuevoRaza)
}

export function * imprimirPdfRaza ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfRaza = yield call(getImprimirPdfRaza, list, auth.token)
    yield put(imprimirPdfRazaSuccess(datosPdfRaza))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirPdfRaza () {
  yield takeLatest(razaActionTypes.IMPRIMIR_PDF_RAZA, imprimirPdfRaza)
}
