import {select, put, call, takeLatest} from 'redux-saga/effects'
import {initialize} from 'redux-form'
import i18n from '../../i18n'
import {openMensajeAlerta, openSimpleModal} from '../../actions/common'
import { yesNoModal } from '../modal/yesNoModal'
import actionTypes from '../../constants/actions/panelControl/panelControl'
import {secuencia} from '../../constants/secuenciaNumerica'
import {modulos as modulosConstant} from '../../constants/modulos'

import {cerrarModalTeclasContaje, fetchPanelControlSuccess, obtenerDatosPanelControlSuccess, obtenerValoresTeclasContajeSuccess, obtenerTipoDiluyenteSuccess,
  recuperarDatosTipoDiluyenteSuccess, duplicarDatosModalTipoDiluyenteSuccess, cambiaPredeterminadoDiluyenteSuccess, deSeleccionarTodosTipoDiluyente, duplicarDatosModalMuestrasSuccess,
  obtenerTipoDosisSuccess, recuperarDatosTipoDosisSuccess, duplicarDatosModalTipoDosisSuccess, cambiaPredeterminadoDosisSuccess, deSeleccionarTodosTipoDosis, editMuestrasAnadidasSuccess,
  cerrarModalAnadirColorimetro, fetchRectaColorimetroSuccess, recuperarDatosRectaColorimetroSuccess, duplicarDatosModalRectaColorimetroSuccess, recuperarDatosMuestrasSuccess,
  deSeleccionarTodosColorimetro, fetchListadoVerracosSuccess, reiniciarMuestraVerraco, onDeleteVerracosSuccess, verFormula, verFormulaR, calculoRecta,recuperarSecuenciaNumeracionesSuccess,
  abrirModalSecuenciasNumeracion, fecthSecuenciasNumeracionSuccess, cerrarModalSecuenciasNumeracion} from '../../actions/panelControl/panelControl'
import getPanelControlService from '../../services/panelControl/getPanelControl'
import savePanelControl from '../../services/panelControl/savePanelControl'
import obtenerTeclasContaje from '../../services/panelControl/obtenerTeclasContaje'
import saveTeclaContaje from '../../services/panelControl/saveTeclaContaje'
import insertarTipoDiluyente from '../../services/panelControl/insertarTipoDiluyente'
import obtenerTipoDiluyente from '../../services/panelControl/obtenerTipoDiluyente'
import getTipoDiluyente from '../../services/panelControl/getTipoDiluyente'
import modificarTipoDiluyente from '../../services/panelControl/modificarTipoDiluyente'
import getTipoDiluyentePredeterminado from '../../services/panelControl/getTipoDiluyentePredeterminado'
import deleteTipoDiluyente from '../../services/panelControl/deleteTipoDiluyente'
import getDeleteSeleccionTipoDiluyente from '../../services/panelControl/getDeleteSeleccionTipoDiluyente'
import insertarTipoDosis from '../../services/panelControl/insertarTipoDosis'
import obtenerTipoDosis from '../../services/panelControl/obtenerTipoDosis'
import getTipoDosis from '../../services/panelControl/getTipoDosis'
import modificarTipoDosis from '../../services/panelControl/modificarTipoDosis'
import getTipoDosisPredeterminado from '../../services/panelControl/getTipoDosisPredeterminado'
import deleteTipoDosis from '../../services/panelControl/deleteTipoDosis'
import getDeleteSeleccionTipoDosis from '../../services/panelControl/getDeleteSeleccionTipoDosis'
import anadirRectaColorimetro from '../../services/panelControl/anadirRectaColorimetro'
import getRectas from '../../services/panelControl/getRectas'
import getRectaColorimetro from '../../services/panelControl/getRectaColorimetro'
import editarRectaColorimetro from '../../services/panelControl/editarRectaColorimetro'
import getRectaPredeterminado from '../../services/panelControl/getRectaPredeterminado'
import deleteRecta from '../../services/panelControl/deleteRecta'
import getDeleteSeleccionRectaColorimetro from '../../services/panelControl/getDeleteSeleccionRectaColorimetro'
import getVerracosList from '../../services/panelControl/getVerracosList'
import calcularRecta from '../../services/panelControl/calcularRecta'
import {number_formatter} from '../../util/formatFunctions'
import getSecuenciasNumeracion from '../../services/panelControl/getSecuenciasNumeracion'
import getSecuencia from '../../services/panelControl/getSecuencia'
import postEditSecuencia from '../../services/panelControl/postEditSecuencia'

export function * fetchPanelControl () {
  try {
    let panelControl = yield call(getPanelControlService)
    yield put(fetchPanelControlSuccess(panelControl))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchFetchPanelControl () {
  yield takeLatest(actionTypes.FETCH_PANEL_CONTROL, fetchPanelControl)
}

export function * obtenerDatosPanelControl ({value}) {
  try {
    let auth = yield select(state => state.auth)
    let panelControl = yield call(getPanelControlService)
    yield put(obtenerDatosPanelControlSuccess(panelControl))

    let valoresForm = {}
    valoresForm.AlertaConcentracionTotalMaximo = panelControl.AlertaConcentracionTotalMaximo
    valoresForm.AlertaConcentracionTotalMinimo = panelControl.AlertaConcentracionTotalMinimo
    valoresForm.AlertaFACabezaMaxima = panelControl.AlertaFACabezaMaxima
    valoresForm.AlertaFAGDistalMaxima = panelControl.AlertaFAGDistalMaxima
    valoresForm.AlertaFAGProximalMaxima = panelControl.AlertaFAGProximalMaxima
    valoresForm.AlertaFAGTotalMaxima = panelControl.AlertaFAGTotalMaxima
    valoresForm.AlertaFaColaMaxima = panelControl.AlertaFaColaMaxima
    valoresForm.AlertaGradoDilucionMaximo = null // panelControl.AlertaGradoDilucionMaximo
    valoresForm.AlertaGradoDilucionMinimo = null // panelControl.AlertaGradoDilucionMinimo
    valoresForm.AlertaMotilidadMasalMaxima = panelControl.AlertaMotilidadMasalMaxima
    valoresForm.AlertaMotilidadMasalMinima = panelControl.AlertaMotilidadMasalMinima
    valoresForm.AlertaMotilidadMaxima = panelControl.AlertaMotilidadMaxima
    valoresForm.AlertaMotilidadMinima = panelControl.AlertaMotilidadMinima
    valoresForm.AlertaMotilidadProgresivaMaximo = panelControl.AlertaMotilidadProgresivaMaximo
    valoresForm.AlertaMotilidadProgresivaMinimo = panelControl.AlertaMotilidadProgresivaMinimo
    valoresForm.AlertaMotilidadTotalMaximo = panelControl.AlertaMotilidadTotalMaximo
    valoresForm.AlertaMotilidadTotalMinimo = panelControl.AlertaMotilidadTotalMinimo
    valoresForm.AlertaVolumenMaximo = panelControl.AlertaVolumenMaximo
    valoresForm.AlertaVolumenMinimo = panelControl.AlertaVolumenMinimo
    valoresForm.AutoDilucion = panelControl.AutoDilucion
    valoresForm.AutoEnvasado = panelControl.AutoEnvasado
    valoresForm.ConcentracionDosisFormasAnormales = panelControl.ConcentracionDosisFormasAnormales
    valoresForm.ConcentracionDosisMotilesProgresivos = panelControl.ConcentracionDosisMotilesProgresivos
    valoresForm.ConcentracionDosisMotilesTotales = panelControl.ConcentracionDosisMotilesTotales
    valoresForm.ConcentracionDosisMotilidaMasal = panelControl.ConcentracionDosisMotilidaMasal
    valoresForm.ConcentracionDosisMotilidad = panelControl.ConcentracionDosisMotilidad
    valoresForm.Firma = panelControl.Firma
    valoresForm.TextoAlbaranVentasA = panelControl.TextoAlbaranVentasA
    valoresForm.TextoAlbaranVentasB = panelControl.TextoAlbaranVentasB
    valoresForm.TextoInformeVisitas = panelControl.TextoInformeVisitas
    valoresForm.concentracionUtilizada = panelControl.concentracionUtilizada
    valoresForm.tipoAnalisisPredeterminado = panelControl.tipoAnalisisPredeterminado
    valoresForm.urlMagavision = panelControl.urlMagavision
    valoresForm.espermatozoidesContar = panelControl.espermatozoidesContar

    let comboTipoAnalisisPanelControl = yield select(state => state.combos.comboTipoAnalisisPanelControl)
    comboTipoAnalisisPanelControl = comboTipoAnalisisPanelControl.filter((tipoAnalisis) => {
      if (tipoAnalisis.value === ((value && value.value) || panelControl.metodoEvaluacion)){
        valoresForm.metodoEvaluacion = {value: tipoAnalisis.value, label: i18n.t('COMUN.COMBOS.' + tipoAnalisis.label)}
      }
    })

    if (panelControl.ConcentracionDosisFormasAnormales === true || panelControl.ConcentracionDosisMotilesProgresivos === true || 
      panelControl.ConcentracionDosisMotilesTotales === true || panelControl.ConcentracionDosisMotilidad === true) {
      valoresForm.ConcentracionSinCorrecciones = false
    } else {
      valoresForm.ConcentracionSinCorrecciones = true
    }
    if (panelControl.ConcentracionAplicada !== null) {
      if (panelControl.ConcentracionAplicada === true) {
        valoresForm.ConcentracionAplicada = {value: true, label: i18n.t('PANEL_CONTROL.CONCENTRACION_APLICADA')}
      } else {
        valoresForm.ConcentracionAplicada = {value: false, label: i18n.t('PANEL_CONTROL.CONCENTRACION_UTIL')}
      }
    }

    if (value !== null && value !== undefined) {
      if (value.value !== panelControl.metodoEvaluacion) {
        if (panelControl.metodoEvaluacion === 1 || panelControl.metodoEvaluacion === 2){
        valoresForm.AlertaMotilidadMasalMinima = ''
        valoresForm.AlertaMotilidadMasalMaxima = ''
        valoresForm.AlertaMotilidadTotalMinimo = ''
        valoresForm.AlertaMotilidadTotalMaximo = ''
        valoresForm.AlertaMotilidadProgresivaMinimo = ''
        valoresForm.AlertaMotilidadProgresivaMaximo = ''
        valoresForm.ConcentracionDosisMotilesProgresivos = false
        valoresForm.ConcentracionDosisMotilesTotales = false
      }
      if (panelControl.metodoEvaluacion === 3){
        valoresForm.AlertaMotilidadMinima = ''
        valoresForm.AlertaMotilidadMaxima = ''
        valoresForm.AlertaMotilidadMasalMinima = ''
        valoresForm.AlertaMotilidadMasalMaxima = ''
        valoresForm.AlertaFACabezaMaxima = ''
        valoresForm.AlertaFaColaMaxima = ''
        valoresForm.AlertaFAGProximalMaxima = ''
        valoresForm.AlertaFAGDistalMaxima = ''
        valoresForm.ConcentracionDosisMotilidad = false
      }
      if (panelControl.metodoEvaluacion === 4){
        valoresForm.AlertaMotilidadMinima = ''
        valoresForm.AlertaMotilidadMaxima = ''
        valoresForm.AlertaMotilidadTotalMinimo = ''
        valoresForm.AlertaMotilidadTotalMaximo = ''
        valoresForm.AlertaMotilidadProgresivaMinimo = ''
        valoresForm.AlertaMotilidadProgresivaMaximo = ''
        valoresForm.AlertaFACabezaMaxima = ''
        valoresForm.AlertaFaColaMaxima = ''
        valoresForm.AlertaFAGProximalMaxima = ''
        valoresForm.AlertaFAGDistalMaxima = ''
        valoresForm.ConcentracionDosisMotilidad = false
        valoresForm.ConcentracionDosisMotilesProgresivos = false
        valoresForm.ConcentracionDosisMotilesTotales = false
      }
      if (panelControl.metodoEvaluacion === 5){
        valoresForm.AlertaMotilidadMinima = ''
        valoresForm.AlertaMotilidadMaxima = ''
        valoresForm.AlertaMotilidadMasalMinima = ''
        valoresForm.AlertaMotilidadMasalMaxima = ''
        valoresForm.AlertaFACabezaMaxima = ''
        valoresForm.AlertaMotilidadTotalMaximo = ''
        valoresForm.AlertaMotilidadProgresivaMaximo = ''
        valoresForm.ConcentracionDosisMotilidad = false
      }
      }
    } else {
      if (panelControl.metodoEvaluacion === 1 || panelControl.metodoEvaluacion === 2){
        // valoresForm.espermatozoidesContar = 100
        valoresForm.AlertaMotilidadMasalMinima = ''
        valoresForm.AlertaMotilidadMasalMaxima = ''
        valoresForm.AlertaMotilidadTotalMinimo = ''
        valoresForm.AlertaMotilidadTotalMaximo = ''
        valoresForm.AlertaMotilidadProgresivaMinimo = ''
        valoresForm.AlertaMotilidadProgresivaMaximo = ''
        valoresForm.ConcentracionDosisMotilesProgresivos = false
        valoresForm.ConcentracionDosisMotilesTotales = false
      }
      if (panelControl.metodoEvaluacion === 3){
        // valoresForm.espermatozoidesContar = 500
        valoresForm.AlertaMotilidadMinima = ''
        valoresForm.AlertaMotilidadMaxima = ''
        valoresForm.AlertaMotilidadMasalMinima = ''
        valoresForm.AlertaMotilidadMasalMaxima = ''
        valoresForm.AlertaFACabezaMaxima = ''
        valoresForm.AlertaFaColaMaxima = ''
        valoresForm.AlertaFAGProximalMaxima = ''
        valoresForm.AlertaFAGDistalMaxima = ''
        valoresForm.ConcentracionDosisMotilidad = false
      }
      if (panelControl.metodoEvaluacion === 4){
        // valoresForm.espermatozoidesContar = 300
        valoresForm.AlertaMotilidadMinima = ''
        valoresForm.AlertaMotilidadMaxima = ''
        valoresForm.AlertaMotilidadTotalMinimo = ''
        valoresForm.AlertaMotilidadTotalMaximo = ''
        valoresForm.AlertaMotilidadProgresivaMinimo = ''
        valoresForm.AlertaMotilidadProgresivaMaximo = ''
        valoresForm.AlertaFACabezaMaxima = ''
        valoresForm.AlertaFaColaMaxima = ''
        valoresForm.AlertaFAGProximalMaxima = ''
        valoresForm.AlertaFAGDistalMaxima = ''
        valoresForm.ConcentracionDosisMotilidad = false
        valoresForm.ConcentracionDosisMotilesProgresivos = false
        valoresForm.ConcentracionDosisMotilesTotales = false
      }
      if (panelControl.metodoEvaluacion === 5){
        // valoresForm.espermatozoidesContar = 500
        valoresForm.AlertaMotilidadMinima = ''
        valoresForm.AlertaMotilidadMaxima = ''
        valoresForm.AlertaMotilidadMasalMinima = ''
        valoresForm.AlertaMotilidadMasalMaxima = ''
        valoresForm.AlertaFACabezaMaxima = ''
        valoresForm.AlertaMotilidadTotalMaximo = ''
        valoresForm.AlertaMotilidadProgresivaMaximo = ''
        valoresForm.ConcentracionDosisMotilidad = false
      }
    }
    valoresForm.eyaculados_utilizados_eliminados_razon_uso = false
    valoresForm.verracos_por_raza = false
    valoresForm.verracos_por_estado = false
    valoresForm.verracos_por_rango_edad = false
    valoresForm.tipo_dosis_producidas = false
    valoresForm.informacion_general_centro = false
    valoresForm.produccion_del_dia = false
    valoresForm.tareas_para_hoy = false
    valoresForm.stock_materiales = false
    valoresForm.numero_dosis_producidas_descartadas_edad = false
    if (panelControl.graficosMostrar !== null) {
      panelControl.graficosMostrar.filter((grafico) => {
        if (grafico === "1"){
          valoresForm.eyaculados_utilizados_eliminados_razon_uso = true
        }
        if (grafico === "2"){
          valoresForm.verracos_por_raza = true
        }
        if (grafico === "3"){
          valoresForm.verracos_por_estado = true
        }
        if (grafico === "4"){
          valoresForm.verracos_por_rango_edad = true
        }
        if (grafico === "5"){
          valoresForm.tipo_dosis_producidas = true
        }
        if (grafico === "6"){
          valoresForm.informacion_general_centro = true
        }
        if (grafico === "7"){
          valoresForm.produccion_del_dia = true
        }
        if (grafico === "8"){
          valoresForm.tareas_para_hoy = true
        }
        if (grafico === "9"){
          valoresForm.stock_materiales = true
        }
        if (grafico === "10"){
          valoresForm.numero_dosis_producidas_descartadas_edad = true
        }
      })
    }
    if (value !== null && value !== undefined) {
      if (value.value === 1 || value.value === 2){
        // valoresForm.espermatozoidesContar = 100
        valoresForm.AlertaMotilidadMasalMinima = ''
        valoresForm.AlertaMotilidadMasalMaxima = ''
        valoresForm.AlertaMotilidadTotalMinimo = ''
        valoresForm.AlertaMotilidadTotalMaximo = ''
        valoresForm.AlertaMotilidadProgresivaMinimo = ''
        valoresForm.AlertaMotilidadProgresivaMaximo = ''
        valoresForm.ConcentracionDosisMotilesProgresivos = false
        valoresForm.ConcentracionDosisMotilesTotales = false
      }
      if (value.value === 3){
        // valoresForm.espermatozoidesContar = 500
        valoresForm.AlertaMotilidadMinima = ''
        valoresForm.AlertaMotilidadMaxima = ''
        valoresForm.AlertaMotilidadMasalMinima = ''
        valoresForm.AlertaMotilidadMasalMaxima = ''
        valoresForm.AlertaFACabezaMaxima = ''
        valoresForm.AlertaFaColaMaxima = ''
        valoresForm.AlertaFAGProximalMaxima = ''
        valoresForm.AlertaFAGDistalMaxima = ''
        valoresForm.ConcentracionDosisMotilidad = false
      }
      if (value.value === 4){
        // valoresForm.espermatozoidesContar = 300
        valoresForm.AlertaMotilidadMinima = ''
        valoresForm.AlertaMotilidadMaxima = ''
        valoresForm.AlertaMotilidadTotalMinimo = ''
        valoresForm.AlertaMotilidadTotalMaximo = ''
        valoresForm.AlertaMotilidadProgresivaMinimo = ''
        valoresForm.AlertaMotilidadProgresivaMaximo = ''
        valoresForm.AlertaFACabezaMaxima = ''
        valoresForm.AlertaFaColaMaxima = ''
        valoresForm.AlertaFAGProximalMaxima = ''
        valoresForm.AlertaFAGDistalMaxima = ''
        valoresForm.ConcentracionDosisMotilesProgresivos = false
        valoresForm.ConcentracionDosisMotilesTotales = false
      }
      if (value.value === 5){
        // valoresForm.espermatozoidesContar = 500
        valoresForm.AlertaMotilidadMinima = ''
        valoresForm.AlertaMotilidadMaxima = ''
        valoresForm.AlertaMotilidadMasalMinima = ''
        valoresForm.AlertaMotilidadMasalMaxima = ''
        valoresForm.AlertaFACabezaMaxima = ''
        valoresForm.AlertaMotilidadTotalMaximo = ''
        valoresForm.AlertaMotilidadProgresivaMaximo = ''
        valoresForm.ConcentracionDosisMotilidad = false
      }
    }
    yield put(initialize('panelControl', valoresForm))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchObtenerDatosPanelControl () {
  yield takeLatest(actionTypes.OBTENER_DATOS_PANEL_CONTROL, obtenerDatosPanelControl)
}

export function * guardarPanelControl ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const actualizacionPanel = yield call(savePanelControl, values, auth.token)
    if (actualizacionPanel.guardado === true) {
      window.scrollTo(0, 0)
      yield put(openMensajeAlerta('actualizacion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-actualizacion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    yield call(obtenerDatosPanelControl, {value: values.metodoEvaluacion})
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
  }
}
export function * watchGuardarPanelControl () {
  yield takeLatest(actionTypes.GUARDAR_PANEL_CONTROL, guardarPanelControl)
}

export function * abrirModalTeclasContaje () {
  try {
    const teclasContaje = yield call(obtenerTeclasContaje)
    yield put(obtenerValoresTeclasContajeSuccess(teclasContaje))
    yield put(initialize('ModalTeclasContajePanelControl', teclasContaje))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchAbrirModalTeclasContaje () {
  yield takeLatest(actionTypes.ABRIR_MODAL_TECLAS_CONTAJE, abrirModalTeclasContaje)
}


export function * obtenerValoresTeclasContaje ({onSuccessCallback, onErrorCallback}) {
  try {
    const teclasContaje = yield call(obtenerTeclasContaje)
    yield put(obtenerValoresTeclasContajeSuccess(teclasContaje))
    yield put(initialize('ModalTeclasContajePanelControl', teclasContaje))
    if (onSuccessCallback) yield onSuccessCallback()
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
  }
}
export function * watchObtenerValoresTeclasContaje () {
  yield takeLatest(actionTypes.OBTENER_VALORES_TECLA_CONTAJE, obtenerValoresTeclasContaje)
}

export function * valoresPredefinidosTeclasContaje ({onSuccessCallback, onErrorCallback}) {
  try {
    let teclasContaje = yield select(state => state.panelControl.teclasContaje)
    yield put(initialize('ModalTeclasContajePanelControl', teclasContaje))
    if (onSuccessCallback) yield onSuccessCallback()
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
  }
}
export function * watchValoresPredefinidosTeclasContaje () {
  yield takeLatest(actionTypes.VALORES_PREDEFINIDOS_TECLAS_CONTAJE, valoresPredefinidosTeclasContaje)
}

export function * guardarTeclasContaje ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const actualizacionTeclaContaje = yield call(saveTeclaContaje, values, auth.token)
    if (actualizacionTeclaContaje.guardado === true) {
      yield put(openMensajeAlerta('actualizacion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-actualizacion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    yield put(cerrarModalTeclasContaje())
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
  }
}
export function * watchGuardarTeclasContaje () {
  yield takeLatest(actionTypes.GUARDAR_TECLAS_CONTAJE, guardarTeclasContaje)
}

export function * anadirDiluyentePanelControl ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const insercionTipoDiluyente = yield call(insertarTipoDiluyente, values, auth.token)
    if (insercionTipoDiluyente.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-insercion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    yield put(initialize('ModalTipoDiluyentePanelControl', {}))
    const tipoDiluyente = yield call(obtenerTipoDiluyente)
    yield put(obtenerTipoDiluyenteSuccess(tipoDiluyente))
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
  }
}
export function * watchAnadirDiluyentePanelControl () {
  yield takeLatest(actionTypes.ANADIR_DILUYENTE_PANEL_CONTROL, anadirDiluyentePanelControl)
}

export function * abrirModalTipoDiluyente () {
  try {
    const tipoDiluyente = yield call(obtenerTipoDiluyente)
    yield put(obtenerTipoDiluyenteSuccess(tipoDiluyente))
    yield put(initialize('ModalTipoDiluyentePanelControl', {}))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchAbrirModalTipoDiluyente () {
  yield takeLatest(actionTypes.ABRIR_MODAL_TIPO_DILUYENTE, abrirModalTipoDiluyente)
}

export function * recuperarDatosTipoDiluyente ({idTipoDiluyente}) {
  try {
    const dataTipoDiluyente = yield call(getTipoDiluyente, idTipoDiluyente)
    yield put(recuperarDatosTipoDiluyenteSuccess(dataTipoDiluyente))
    dataTipoDiluyente.codigo = dataTipoDiluyente.idTipoDiluyente
    yield put(initialize('ModalTipoDiluyentePanelControl', dataTipoDiluyente))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchRecuperarDatosTipoDiluyente () {
  yield takeLatest(actionTypes.RECUPERAR_DATOS_TIPO_DILUYENTE, recuperarDatosTipoDiluyente)
}

export function * editarDiluyentePanelControl ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const edicionTipoDiluyente = yield call(modificarTipoDiluyente, values, auth.token)
    if (edicionTipoDiluyente.actualizado === true) {
      yield put(openMensajeAlerta('modificacion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-modificacion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    yield put(initialize('ModalTipoDiluyentePanelControl', {}))
    const tipoDiluyente = yield call(obtenerTipoDiluyente)
    yield put(obtenerTipoDiluyenteSuccess(tipoDiluyente))
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
  }
}
export function * watchEditarDiluyentePanelControl () {
  yield takeLatest(actionTypes.EDITAR_DILUYENTE_PANEL_CONTROL, editarDiluyentePanelControl)
}

export function * duplicarDatosModalTipoDiluyente ({idTipoDiluyente}) {
  try {
    const dataDuplicarTipoDiluyente = yield call(getTipoDiluyente, idTipoDiluyente)
    yield put(duplicarDatosModalTipoDiluyenteSuccess(dataDuplicarTipoDiluyente))
    // dataDuplicarTipoDiluyente.codigo = dataDuplicarTipoDiluyente.idTipoDiluyente
    yield put(initialize('ModalTipoDiluyentePanelControl', dataDuplicarTipoDiluyente))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchDuplicarDatosModalTipoDiluyente () {
  yield takeLatest(actionTypes.DUPLICAR_DATOS_TIPO_DILUYENTE, duplicarDatosModalTipoDiluyente)
}

export function * cambiaPredeterminadoDiluyente ({idTipoDiluyente}) {
  try {
    let auth = yield select(state => state.auth)
    const tipoDiluyentePredeterminado = yield call(getTipoDiluyentePredeterminado, idTipoDiluyente, auth.token)
    if (tipoDiluyentePredeterminado.actualizado === true) {
      yield put(openMensajeAlerta('actualizacion'))
    } else {
      yield put(openMensajeAlerta('no-actualizacion'))
    }
    yield put(initialize('ModalTipoDiluyentePanelControl', {}))
    const tipoDiluyente = yield call(obtenerTipoDiluyente)
    yield put(obtenerTipoDiluyenteSuccess(tipoDiluyente))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchCambiaPredeterminadoDiluyente () {
  yield takeLatest(actionTypes.CAMBIA_PREDETERMINADO_DILUYENTE, cambiaPredeterminadoDiluyente)
}

export function * onDeleteDiluyente ({idTipoDiluyente}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const tipoDiluyentePredeterminado = yield call(deleteTipoDiluyente, idTipoDiluyente, auth.token)
      if (tipoDiluyentePredeterminado.eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(initialize('ModalTipoDiluyentePanelControl', {}))
      const tipoDiluyente = yield call(obtenerTipoDiluyente)
      yield put(obtenerTipoDiluyenteSuccess(tipoDiluyente))
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchOnDeleteDiluyente () {
  yield takeLatest(actionTypes.ON_DELETE_DILUYENTE, onDeleteDiluyente)
}

export function * deleteSeleccionTipoDiluyente ({listTipoDiluyente}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      let onDelete = yield call(getDeleteSeleccionTipoDiluyente, {list: listTipoDiluyente}, auth.token)
      if (onDelete.eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(deSeleccionarTodosTipoDiluyente())
      yield put(initialize('ModalTipoDiluyentePanelControl', {}))
      const tipoDiluyente = yield call(obtenerTipoDiluyente)
      yield put(obtenerTipoDiluyenteSuccess(tipoDiluyente))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchDeleteSeleccionTipoDiluyente () {
  yield takeLatest(actionTypes.DELETE_SELECCION_TIPO_DILUYENTE, deleteSeleccionTipoDiluyente)
}

export function * anadirDosisPanelControl ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const insercionTipoDosis = yield call(insertarTipoDosis, values, auth.token)
    if (insercionTipoDosis.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-insercion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    yield put(initialize('ModalTipoDosisPanelControl', {}))
    const tipoDosis = yield call(obtenerTipoDosis)
    yield put(obtenerTipoDosisSuccess(tipoDosis))
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
  }
}
export function * watchAnadirDosisPanelControl () {
  yield takeLatest(actionTypes.ANADIR_DOSIS_PANEL_CONTROL, anadirDosisPanelControl)
}

export function * abrirModalTipoDosis () {
  try {
    const tipoDosis = yield call(obtenerTipoDosis)
    yield put(obtenerTipoDosisSuccess(tipoDosis))
    yield put(initialize('ModalTipoDosisPanelControl', {}))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchAbrirModalTipoDosis () {
  yield takeLatest(actionTypes.ABRIR_MODAL_TIPO_DOSIS, abrirModalTipoDosis)
}

export function * recuperarDatosTipoDosis ({idTipoDosis}) {
  try {
    const dataTipoDosis = yield call(getTipoDosis, idTipoDosis)
    yield put(recuperarDatosTipoDosisSuccess(dataTipoDosis))
    dataTipoDosis.codigo = dataTipoDosis.idTipoDosis
    yield put(initialize('ModalTipoDosisPanelControl', dataTipoDosis))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchRecuperarDatosTipoDosis () {
  yield takeLatest(actionTypes.RECUPERAR_DATOS_TIPO_DOSIS, recuperarDatosTipoDosis)
}

export function * editarDosisPanelControl ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const edicionTipoDosis = yield call(modificarTipoDosis, values, auth.token)
    if (edicionTipoDosis.actualizado === true) {
      yield put(openMensajeAlerta('modificacion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-modificacion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    yield put(initialize('ModalTipoDosisPanelControl', {}))
    const tipoDosis = yield call(obtenerTipoDosis)
    yield put(obtenerTipoDosisSuccess(tipoDosis))
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
  }
}
export function * watchEditarDosisPanelControl () {
  yield takeLatest(actionTypes.EDITAR_DOSIS_PANEL_CONTROL, editarDosisPanelControl)
}

export function * duplicarDatosModalTipoDosis ({idTipoDosis}) {
  try {
    const dataDuplicarTipoDosis = yield call(getTipoDosis, idTipoDosis)
    yield put(duplicarDatosModalTipoDosisSuccess(dataDuplicarTipoDosis))
    // dataDuplicarTipoDosis.codigo = dataDuplicarTipoDosis.idTipoDosis
    yield put(initialize('ModalTipoDosisPanelControl', dataDuplicarTipoDosis))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchDuplicarDatosModalTipoDosis () {
  yield takeLatest(actionTypes.DUPLICAR_DATOS_TIPO_DOSIS, duplicarDatosModalTipoDosis)
}

export function * cambiaPredeterminadoDosis ({idTipoDosis}) {
  try {
    let auth = yield select(state => state.auth)
    const tipoDosisPredeterminado = yield call(getTipoDosisPredeterminado, idTipoDosis, auth.token)
    if (tipoDosisPredeterminado.actualizado === true) {
      yield put(openMensajeAlerta('actualizacion'))
    } else {
      yield put(openMensajeAlerta('no-actualizacion'))
    }
    yield put(initialize('ModalTipoDosisPanelControl', {}))
    const tipoDosis = yield call(obtenerTipoDosis)
    yield put(obtenerTipoDosisSuccess(tipoDosis))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchCambiaPredeterminadoDosis () {
  yield takeLatest(actionTypes.CAMBIA_PREDETERMINADO_DOSIS, cambiaPredeterminadoDosis)
}

export function * onDeleteDosis ({idTipoDosis}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const tipoDosisPredeterminado = yield call(deleteTipoDosis, idTipoDosis, auth.token)
      if (tipoDosisPredeterminado.eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(initialize('ModalTipoDosisPanelControl', {}))
      const tipoDosis = yield call(obtenerTipoDosis)
      yield put(obtenerTipoDosisSuccess(tipoDosis))
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchOnDeleteDosis () {
  yield takeLatest(actionTypes.ON_DELETE_DOSIS, onDeleteDosis)
}

export function * deleteSeleccionTipoDosis ({listTipoDosis}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      let onDelete = yield call(getDeleteSeleccionTipoDosis, {list: listTipoDosis}, auth.token)
      if (onDelete.eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(deSeleccionarTodosTipoDosis())
      yield put(initialize('ModalTipoDosisPanelControl', {}))
      const tipoDosis = yield call(obtenerTipoDosis)
      yield put(obtenerTipoDosisSuccess(tipoDosis))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchDeleteSeleccionTipoDosis () {
  yield takeLatest(actionTypes.DELETE_SELECCION_TIPO_DOSIS, deleteSeleccionTipoDosis)
}

export function * submitAnadirColorimetro ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    yield call(anadirRectaColorimetro, values, auth.token)

    const listColorimetro = yield call(getRectas, values, auth.token)
    yield put(fetchRectaColorimetroSuccess(listColorimetro))
    let valores = {colomitroOmuestras: {label: i18n.t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.INSERTAR_VALORES_COLORIMETRO'), value: 1}}
    yield put(initialize('ModalRectaColorimetro', valores))
    yield put(reiniciarMuestraVerraco())

    if (onSuccessCallback) yield onSuccessCallback()
    yield put(cerrarModalAnadirColorimetro())
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchSubmitAnadirColorimetro () {
  yield takeLatest(actionTypes.SUBMIT_ANADIR_COLORIMETRO, submitAnadirColorimetro)
}

export function * fetchRectaColorimetro ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const listColorimetro = yield call(getRectas, values, auth.token)
    yield put(fetchRectaColorimetroSuccess(listColorimetro))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchFetchRectaColorimetro () {
  yield takeLatest(actionTypes.FETCH_RECTA_COLORIMETRO, fetchRectaColorimetro)
}

export function * recuperarDatosRectaColorimetro ({value}) {
  try {
    const dataRectaColorimetro = yield call(getRectaColorimetro, value)
    yield put(recuperarDatosRectaColorimetroSuccess(dataRectaColorimetro))
    dataRectaColorimetro.nombre = dataRectaColorimetro.recta.nombre
    dataRectaColorimetro.predeterminado = dataRectaColorimetro.recta.predeterminado
    let auth = yield select(state => state.auth)
    if (Object.keys(dataRectaColorimetro.muestras).length > 0 ) {
      dataRectaColorimetro.colomitroOmuestras = {label: i18n.t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.INSERTAR_VALORES_MUESTRAS'), value: 2}
      // const formula = dataRectaColorimetro.formula = 'Y= ax+(b)'
    } else {
      dataRectaColorimetro.colomitroOmuestras = {label: i18n.t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.INSERTAR_VALORES_COLORIMETRO'), value: 1}
      dataRectaColorimetro.r = dataRectaColorimetro.recta.R
      dataRectaColorimetro.x = dataRectaColorimetro.recta.X
      dataRectaColorimetro.y = dataRectaColorimetro.recta.Y
    }
    /*const formula = 'Y= ' + number_formatter(dataRectaColorimetro.recta.Y) +'x + ('+ number_formatter(dataRectaColorimetro.recta.X) +')'
    yield put(verFormula(formula))
    const formulaR = 'R² = ' + number_formatter(dataRectaColorimetro.recta.R)
    yield put(verFormulaR(formulaR))*/
    yield put(initialize('ModalRectaColorimetro', dataRectaColorimetro))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchRecuperarDatosRectaColorimetro () {
  yield takeLatest(actionTypes.RECUPERAR_DATOS_RECTA_COLORIMETRO, recuperarDatosRectaColorimetro)
}

export function * editarColorimetro ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const edicionColorimetro = yield call(editarRectaColorimetro, values, auth.token)
    if (edicionColorimetro.actualizado === true) {
      yield put(openMensajeAlerta('modificacion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-modificacion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    yield put(cerrarModalAnadirColorimetro())
    yield put(initialize('ModalRectaColorimetro', {}))
    const listColorimetro = yield call(getRectas, values, auth.token)
    yield put(fetchRectaColorimetroSuccess(listColorimetro))
    yield put(reiniciarMuestraVerraco())
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchEditarColorimetro () {
  yield takeLatest(actionTypes.EDITAR_COLORIMETRO, editarColorimetro)
}

export function * duplicarDatosModalRectaColorimetro ({value}) {
  try {

    const dataDuplicarRectaColorimetro = yield call(getRectaColorimetro, value)
    yield put(duplicarDatosModalRectaColorimetroSuccess(dataDuplicarRectaColorimetro))
    dataDuplicarRectaColorimetro.nombre = dataDuplicarRectaColorimetro.recta.nombre
    dataDuplicarRectaColorimetro.predeterminado = dataDuplicarRectaColorimetro.recta.predeterminado
    let auth = yield select(state => state.auth)
    /*const formula = 'Y= ' + number_formatter(dataDuplicarRectaColorimetro.recta.Y) +'x + ('+ number_formatter(dataDuplicarRectaColorimetro.recta.X)
    yield put(verFormula(formula))
    const formulaR = 'R² = ' + number_formatter(dataDuplicarRectaColorimetro.recta.R)
    yield put(verFormulaR(formulaR))*/
    if (Object.keys(dataDuplicarRectaColorimetro.muestras).length > 0 ) {
      dataDuplicarRectaColorimetro.colomitroOmuestras = {label: i18n.t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.INSERTAR_VALORES_MUESTRAS'), value: 2}
    } else {
      dataDuplicarRectaColorimetro.colomitroOmuestras = {label: i18n.t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.INSERTAR_VALORES_COLORIMETRO'), value: 1}
      dataDuplicarRectaColorimetro.r = dataDuplicarRectaColorimetro.recta.R
      dataDuplicarRectaColorimetro.x = dataDuplicarRectaColorimetro.recta.X
      dataDuplicarRectaColorimetro.y = dataDuplicarRectaColorimetro.recta.Y
    }
    yield put(initialize('ModalRectaColorimetro', dataDuplicarRectaColorimetro))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchDuplicarDatosModalRectaColorimetro () {
  yield takeLatest(actionTypes.DUPLICAR_COLORIMETRO, duplicarDatosModalRectaColorimetro)
}

export function * cambiaPredeterminadoRectaColorimetro ({value}) {
  try {
    let auth = yield select(state => state.auth)
    const rectaPredeterminado = yield call(getRectaPredeterminado, value)
    if (rectaPredeterminado.actualizado === true) {
      yield put(openMensajeAlerta('actualizacion'))
    } else {
      yield put(openMensajeAlerta('no-actualizacion'))
    }
    yield put(initialize('ModalRectaColorimetro', {}))
    const listColorimetro = yield call(getRectas, value, auth.token)
    yield put(fetchRectaColorimetroSuccess(listColorimetro))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchCambiaPredeterminadoRectaColorimetro () {
  yield takeLatest(actionTypes.CAMBIA_PREDETERMINADO_RECTA_COLORIMETRO, cambiaPredeterminadoRectaColorimetro)
}

export function * onDeleteRectaColorimetro ({value}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const rectaEliminado = yield call(deleteRecta, value)
      if (rectaEliminado.eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(initialize('ModalRectaColorimetro', {}))
      const listColorimetro = yield call(getRectas, value, auth.token)
      yield put(fetchRectaColorimetroSuccess(listColorimetro))
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchOnDeleteRectaColorimetro () {
  yield takeLatest(actionTypes.ON_DELETE_RECTA_COLORIMETRO, onDeleteRectaColorimetro)
}

export function * abrirModalAnadirMuestras ({value}) {
  try {
    yield put(initialize('ModalAnadirMuestras', {}))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchAbrirModalAnadirMuestras () {
  yield takeLatest(actionTypes.ABRIR_MODAL_ANADIR_MUESTRAS, abrirModalAnadirMuestras)
}

export function * recuperarModalMuestras ({idElemento}) {
  try {
    const muestrasState = yield select(state => state.panelControl.muestrasAnadidas)
    const muestrasRecuperar = muestrasState.filter((muestras) => {
      if (muestras.idElemento === idElemento){
        return muestras
      }
    })
    yield put(recuperarDatosMuestrasSuccess(muestrasRecuperar[0]))
    yield put(initialize('ModalAnadirMuestras', muestrasRecuperar[0]))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchRecuperarModalMuestras() {
  yield takeLatest(actionTypes.RECUPERAR_DATOS_MUESTRAS, recuperarModalMuestras)
}

export function * editMuestrasAnadidas ({values, onSuccessCallback, onErrorCallback}) {
  try {
    const muestrasState = yield select(state => state.panelControl.muestrasAnadidas)
    const muestrasRecuperar = muestrasState.filter((muestras) => {
      if (muestras.idElemento === values.idElemento){
        muestras.concentracionEyaculado = values.concentracionEyaculado
        muestras.absorbancia = values.absorbancia
      }
      return muestras
    })
    yield put(editMuestrasAnadidasSuccess(muestrasRecuperar))
    let auth = yield select(state => state.auth)
    let muestras = yield select(state => state.panelControl.muestrasAnadidas)
    const calculo = yield call(calcularRecta, {list: muestras}, auth.token)
    if (calculo.Y !== null && calculo.X !== null && calculo.R !== null && calculo.Y && calculo.X && calculo.R){
      const formula = 'Y = ' + number_formatter(calculo.Y) +'x + ('+ number_formatter(calculo.X) + ')'
      const formulaR = 'R² = ' + number_formatter(calculo.R)
      const mostrarFormulas = {formula: formula, formulaR: formulaR}
      yield put(calculoRecta(mostrarFormulas))
    }
    if (onSuccessCallback) yield onSuccessCallback()
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
  }
}
export function * watchEditMuestrasAnadidas() {
  yield takeLatest(actionTypes.EDIT_MUESTAS_ANADIDAS, editMuestrasAnadidas)
}

export function * duplicarModalMuestras ({idElemento}) {
  try {
    const muestrasState = yield select(state => state.panelControl.muestrasAnadidas)
    const muestrasDuplicar = muestrasState.filter((muestras) => {
      if (muestras.idElemento === idElemento){
        return muestras
      }
    })
    yield put(duplicarDatosModalMuestrasSuccess(muestrasDuplicar[0]))
    yield put(initialize('ModalAnadirMuestras', muestrasDuplicar[0]))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchDuplicarModalMuestras() {
  yield takeLatest(actionTypes.DUPLICAR_DATOS_MUESTRAS, duplicarModalMuestras)
}

export function * onDeleteMuestras ({idElemento}) {
  try {
   yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      const muestrasState = yield select(state => state.panelControl.muestrasAnadidas)
      const muestrasAnadidas = muestrasState.filter((muestras) => {
        if (muestras.idElemento !== idElemento){
          return muestras
        }
      })
      yield put(editMuestrasAnadidasSuccess(muestrasAnadidas))
    }

    let auth = yield select(state => state.auth)
    let muestras = yield select(state => state.panelControl.muestrasAnadidas)
    const calculo = yield call(calcularRecta, {list: muestras}, auth.token)
    if (calculo.Y !== null && calculo.X !== null && calculo.R !== null){
      const formula = 'Y = ' + number_formatter(calculo.Y) +'x + ('+ number_formatter(calculo.X) + ')'
      const formulaR = 'R² = ' + number_formatter(calculo.R)
      const mostrarFormulas = {formula: formula, formulaR: formulaR}
      yield put(calculoRecta(mostrarFormulas))
    } else {
      const formula = 'Y= ax + (b)'
      const mostrarFormulas = {formula: formula, formulaR: ''}
      yield put(calculoRecta(mostrarFormulas))
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchOnDeleteMuestras() {
  yield takeLatest(actionTypes.ON_DELETE_MUESTRAS, onDeleteMuestras)
}

export function * deleteSeleccionRectaColorimetro ({listColorimetro}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      let onDelete = yield call(getDeleteSeleccionRectaColorimetro, {list: listColorimetro}, auth.token)
      if (onDelete.eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(deSeleccionarTodosColorimetro())
      yield put(initialize('ModalRectaColorimetroPanelControl', {}))
      const lista = yield call(getRectas, listColorimetro, auth.token)
      yield put(fetchRectaColorimetroSuccess(lista))
      yield put(initialize('simpleTable', {}))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchDeleteSeleccionRectaColorimetro () {
  yield takeLatest(actionTypes.DELETE_SELECCION_RECTA_COLORIMETRO, deleteSeleccionRectaColorimetro)
}

export function * fetchListadoVerracos ({verracosAnadidos}) {
  try {
    let auth = yield select(state => state.auth)
    let listVerracos = yield call(getVerracosList, verracosAnadidos, auth.token)
    yield put(fetchListadoVerracosSuccess(listVerracos))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchFetchListadoVerracos () {
  yield takeLatest(actionTypes.FETCH_LISTADO_VERRACOS, fetchListadoVerracos)
}

export function * anadirVerraco ({list}) {
  try {
    let auth = yield select(state => state.auth)
    let verracosAnadidos = yield select(state => state.panelControl.verracosAnadidos)
    let listVerracos = yield call(getVerracosList, verracosAnadidos, auth.token)
    yield put(fetchListadoVerracosSuccess(listVerracos))
  } catch (error) {

  } finally {

  }
}
export function * watchAnadirVerracoRecta () {
  yield takeLatest(actionTypes.ANADIR_VERRACOS, anadirVerraco)
}

export function * editarVerraco ({list}) {
  try {
    let auth = yield select(state => state.auth)
    let verracosAnadidos = yield select(state => state.panelControl.verracosAnadidos)
    let listVerracos = yield call(getVerracosList, verracosAnadidos, auth.token)
    yield put(fetchListadoVerracosSuccess(listVerracos))
  } catch (error) {

  } finally {

  }
}
export function * watchEditarVerracoRecta () {
  yield takeLatest(actionTypes.EDITAR_VERRACOS, editarVerraco)
}

export function * valoresPredefinidosPanelControl ({metodo, onSuccessCallback, onErrorCallback}) {
  try {
    let panelControl = yield select(state => state.panelControl)
    let auth = yield select(state => state.auth)
    let predefinido = {}
    predefinido.AlertaConcentracionTotalMaximo = panelControl.AlertaConcentracionTotalMaximo
    predefinido.AlertaConcentracionTotalMinimo = panelControl.AlertaConcentracionTotalMinimo
    // predefinido.espermatozoidesContar = panelControl.espermatozoidesContar
    predefinido.AlertaFACabezaMaxima = panelControl.AlertaFACabezaMaxima
    predefinido.AlertaFAGDistalMaxima = panelControl.AlertaFAGDistalMaxima
    predefinido.AlertaFAGProximalMaxima = panelControl.AlertaFAGProximalMaxima
    predefinido.AlertaFAGTotalMaxima = panelControl.AlertaFAGTotalMaxima
    predefinido.AlertaFaColaMaxima = panelControl.AlertaFaColaMaxima
    predefinido.AlertaGradoDilucionMaximo = null // panelControl.AlertaGradoDilucionMaximo
    predefinido.AlertaGradoDilucionMinimo = null // panelControl.AlertaGradoDilucionMinimo
    predefinido.AlertaMotilidadMasalMaxima = panelControl.AlertaMotilidadMasalMaxima
    predefinido.AlertaMotilidadMasalMinima = panelControl.AlertaMotilidadMasalMinima
    predefinido.AlertaMotilidadMaxima = panelControl.AlertaMotilidadMaxima
    predefinido.AlertaMotilidadMinima = panelControl.AlertaMotilidadMinima
    predefinido.AlertaMotilidadProgresivaMaximo = panelControl.AlertaMotilidadProgresivaMaximo
    predefinido.AlertaMotilidadProgresivaMinimo = panelControl.AlertaMotilidadProgresivaMinimo
    predefinido.AlertaMotilidadTotalMaximo = panelControl.AlertaMotilidadTotalMaximo
    predefinido.AlertaMotilidadTotalMinimo = panelControl.AlertaMotilidadTotalMinimo
    predefinido.AlertaVolumenMaximo = panelControl.AlertaVolumenMaximo
    predefinido.AlertaVolumenMinimo = panelControl.AlertaVolumenMinimo
    predefinido.AutoDilucion = false
    predefinido.AutoEnvasado = false
    predefinido.ConcentracionDosisFormasAnormales = panelControl.ConcentracionDosisFormasAnormales
    predefinido.ConcentracionDosisMotilesProgresivos = panelControl.ConcentracionDosisMotilesProgresivos
    predefinido.ConcentracionDosisMotilesTotales = panelControl.ConcentracionDosisMotilesTotales
    predefinido.ConcentracionDosisMotilidaMasal = panelControl.ConcentracionDosisMotilidaMasal
    predefinido.ConcentracionDosisMotilidad = panelControl.ConcentracionDosisMotilidad
    predefinido.ConcentracionDosisSinCorrecciones = panelControl.ConcentracionDosisSinCorrecciones
    predefinido.ConcentracionSinCorrecciones = true
    predefinido.Firma = panelControl.Firma
    predefinido.TextoAlbaranVentasA = panelControl.TextoAlbaranVentasA
    predefinido.TextoAlbaranVentasB = panelControl.TextoAlbaranVentasB
    predefinido.TextoInformeVisitas = panelControl.TextoInformeVisitas
    predefinido.concentracionUtilizada = panelControl.concentracionUtilizada
    // predefinido.metodoEvaluacion = {value: 1, label: i18n.t('COMUN.COMBOS.TIPO_ANALISIS_EXTRACCION.COLORIMETRO')}
    predefinido.tipoAnalisisPredeterminado = panelControl.tipoAnalisisPredeterminado
    predefinido.AutoEnvasado = false
    predefinido.AutoDilucion = false
    predefinido.urlMagavision = ''
    predefinido.ConcentracionAplicada = {value: false, label: i18n.t('PANEL_CONTROL.CONCENTRACION_UTIL')}
    predefinido.eyaculados_utilizados_eliminados_razon_uso = false
    predefinido.verracos_por_raza = true
    predefinido.verracos_por_estado = true
    predefinido.verracos_por_rango_edad = true
    predefinido.tipo_dosis_producidas = false
    predefinido.informacion_general_centro = false
    predefinido.produccion_del_dia = false
    predefinido.tareas_para_hoy = true
    predefinido.stock_materiales = false
    predefinido.numero_dosis_producidas_descartadas_edad = false

    let comboTipoAnalisisPanelControl = yield select(state => state.combos.comboTipoAnalisisPanelControl)
    comboTipoAnalisisPanelControl = comboTipoAnalisisPanelControl.filter((tipoAnalisis) => {
      if (tipoAnalisis.value === metodo){
        predefinido.metodoEvaluacion = {value: tipoAnalisis.value, label: i18n.t('COMUN.COMBOS.' + tipoAnalisis.label)}
      }
    })
    if (metodo !== null) {
      if (metodo === 1 || metodo === 2){
        predefinido.espermatozoidesContar = 100
        predefinido.AlertaMotilidadMasalMinima = ''
        predefinido.AlertaMotilidadMasalMaxima = ''
        predefinido.AlertaMotilidadTotalMinimo = ''
        predefinido.AlertaMotilidadTotalMaximo = ''
        predefinido.AlertaMotilidadProgresivaMinimo = ''
        predefinido.AlertaMotilidadProgresivaMaximo = ''
        predefinido.AlertaGradoDilucionMinimo = ''
        predefinido.AlertaGradoDilucionMaximo = ''
      }
      if (metodo === 3){
        predefinido.espermatozoidesContar = 500
        predefinido.AlertaMotilidadMinima = ''
        predefinido.AlertaMotilidadMaxima = ''
        predefinido.AlertaMotilidadMasalMinima = ''
        predefinido.AlertaMotilidadMasalMaxima = ''
        predefinido.AlertaGradoDilucionMinimo = ''
        predefinido.AlertaGradoDilucionMaximo = ''
        predefinido.AlertaFACabezaMaxima = ''
        predefinido.AlertaFaColaMaxima = ''
        predefinido.AlertaFAGProximalMaxima = ''
        predefinido.AlertaFAGDistalMaxima = ''
      }
      if (metodo === 4){
        predefinido.espermatozoidesContar = 300
        predefinido.AlertaMotilidadMinima = ''
        predefinido.AlertaMotilidadMaxima = ''
        predefinido.AlertaMotilidadTotalMinimo = ''
        predefinido.AlertaMotilidadTotalMaximo = ''
        predefinido.AlertaMotilidadProgresivaMinimo = ''
        predefinido.AlertaMotilidadProgresivaMaximo = ''
        predefinido.AlertaGradoDilucionMinimo = ''
        predefinido.AlertaGradoDilucionMaximo = ''
        predefinido.AlertaFACabezaMaxima = ''
        predefinido.AlertaFaColaMaxima = ''
        predefinido.AlertaFAGProximalMaxima = ''
        predefinido.AlertaFAGDistalMaxima = ''
      }
      if (metodo === 5){
        predefinido.espermatozoidesContar = 500
        predefinido.AlertaMotilidadMinima = ''
        predefinido.AlertaMotilidadMaxima = ''
        predefinido.AlertaMotilidadMasalMinima = ''
        predefinido.AlertaMotilidadMasalMaxima = ''
        predefinido.AlertaFACabezaMaxima = ''
        predefinido.AlertaMotilidadTotalMaximo = ''
        predefinido.AlertaMotilidadProgresivaMaximo = ''
      }
    }
    yield put(initialize('panelControl', predefinido))
    if (onSuccessCallback) yield onSuccessCallback()
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
  }
}
export function * watchValoresPredefinidosPanelControl () {
  yield takeLatest(actionTypes.VALORES_PREDEFINIDOS_PANEL_CONTROL, valoresPredefinidosPanelControl)
}

export function * onDeleteVerracos ({idElemento}) {
  try {
   yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      const verracosState = yield select(state => state.panelControl.verracosAnadidos)
      const verracosAnadidos = verracosState.filter((verracos) => {
        if (verracos.idElemento !== idElemento){
          return verracos
        }
      })
      yield put(onDeleteVerracosSuccess(verracosAnadidos))
      let auth = yield select(state => state.auth)
      let listVerracos = yield call(getVerracosList, verracosAnadidos, auth.token)
      yield put(fetchListadoVerracosSuccess(listVerracos))
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchOnDeleteVerracos() {
  yield takeLatest(actionTypes.ON_DELETE_VERRACOS, onDeleteVerracos)
}

export function * anadirMuestra ({muestrasAnadidas, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    let muestras = yield select(state => state.panelControl.muestrasAnadidas)
    const calculo = yield call(calcularRecta, {list: muestras}, auth.token)
    if (calculo.Y !== null && calculo.X !== null && calculo.R !== null){
      const formula = 'Y = ' + number_formatter(calculo.Y) +'x + ('+ number_formatter(calculo.X) + ')'
      const formulaR = 'R² = ' + number_formatter(calculo.R)
      const mostrarFormulas = {formula: formula, formulaR: formulaR}
      yield put(calculoRecta(mostrarFormulas))
    } else {
      const formula = 'Y= ax + (b)'
      const mostrarFormulas = {formula: formula, formulaR: ''}
      yield put(calculoRecta(mostrarFormulas))
    }
    if (onSuccessCallback) yield onSuccessCallback()
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
  }
}
export function * watchAnadirMuestra () {
  yield takeLatest(actionTypes.ANADIR_MUESTRA, anadirMuestra)
}

export function * recuperarSecuenciaNumeraciones ({idSecuencia}) {
  try {
    const dataSecuenciaNumeraciones = yield call(getSecuencia, idSecuencia, null)
    yield put(recuperarSecuenciaNumeracionesSuccess(dataSecuenciaNumeraciones))
    yield put(initialize('ModalSecuenciasNumeracion', dataSecuenciaNumeraciones))
    yield put(abrirModalSecuenciasNumeracion())
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchRecuperarSecuenciaNumeraciones () {
  yield takeLatest(actionTypes.RECUPERAR_SECUENCIA_NUMERACIONES, recuperarSecuenciaNumeraciones)
}

export function * fecthSecuenciasNumeracion () {
  try {
    let auth = yield select(state => state.auth)
    let secuenciaNumeracion = yield call(getSecuenciasNumeracion)
    let constSecuencia
    const listSecuencias = secuenciaNumeracion.filter((list) => {
      if (!auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD)) {
        if (list.idTipo === 1 || list.idTipo === 2) {
          return false
        }
      }
      if (!auth.modulos.includes(modulosConstant.PEDIDOS_CLIENTES)) {
        if (list.idTipo === 3 || list.idTipo === 4) {
          return false
        }
      }
      return true
    }).map((list) => {
      constSecuencia = secuencia.filter((sec) => {
        if (list.idTipo === sec.value){
          return sec
        }
      })
      list.tipo = i18n.t('PANEL_CONTROL.CONST_SECUENCIAS.' + constSecuencia[0].label)
      return list
    })
    yield put(fecthSecuenciasNumeracionSuccess(listSecuencias))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchFetchSecuenciasNumeracion () {
  yield takeLatest(actionTypes.FETCH_SECUENCIAS_NUMERACION, fecthSecuenciasNumeracion)
}

export function * editarSecuencia ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    let edicionSecuencia = yield call(postEditSecuencia, values, auth.token)
    if (edicionSecuencia.actualizado === true) {
      yield put(openMensajeAlerta('modificacion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-modificacion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    yield put(cerrarModalSecuenciasNumeracion())
    // cargar secuencias
    let secuenciaNumeracion = yield call(getSecuenciasNumeracion)
    let constSecuencia
    const listSecuencias = secuenciaNumeracion.map((list) => {
      constSecuencia = secuencia.filter((sec) => {
        if (list.idTipo === sec.value){
          return sec
        }
      })
      list.tipo = i18n.t('PANEL_CONTROL.CONST_SECUENCIAS.' + constSecuencia[0].label)
      return list
    })
    yield put(fecthSecuenciasNumeracionSuccess(listSecuencias))
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
  }
}
export function * watchEditarSecuencia () {
  yield takeLatest(actionTypes.EDITAR_SECUENCIA, editarSecuencia)
}
