import {select, put, call, all, takeLatest} from 'redux-saga/effects'
import { delay } from 'redux-saga/effects'
import { initialize, getFormValues } from 'redux-form';
import i18n from '../../i18n';
import {date_parser, dateTimeZones} from '../../util/formatFunctions'
import {downloadDocument} from '../../util/util'

// Constants
import {permisos as permisosConstant} from '../../constants/permisos'
import {verracoSiNo as verracoSiNoConstants, translateVerracoSiNo as translateVerracoSiNoConstants} from '../../constants/seguimiento'
import {estadoAlbaran as estadoAlbaranConstants, translateEstadoAlbaran as translateEstadoAlbaranConstants} from '../../constants/albaran'
import actionTypes from '../../constants/actions/albaran/albaran'

// Actions
import {openSimpleModal, openMensajeAlerta} from '../../actions/common'
import {
  fetchAlbaranesSuccess, deseleccionarTodosAlbaranes, abrirModalVacioAlbaranSuccess, abrirDetallesAlbaranSuccess,
  cerrarDetallesAlbaranSuccess, abrirModalVacioAlbaranDosisSuccess,
  abrirDetallesAlbaranDosisSuccess, cerrarDetallesAlbaranDosisSuccess, editarAlbaranDosisSuccess,
  deseleccionarTodosModalAlbaranDosis, calcTotalesAlbaranDosisSuccess
} from '../../actions/albaran/albaran'
import {
  recuperarDatosModalPedidosVenta
} from '../../actions/pedidosVenta/pedidosVenta'

// Services
import getAlbaranesListService from '../../services/albaran/getAlbaranesList'
import getFiltrarAlbaranesListService from '../../services/albaran/getFiltrarAlbaranesList'
import deleteAlbaranService from '../../services/albaran/deleteAlbaran'
import deleteSeleccionAlbaranesService from '../../services/albaran/deleteSeleccionAlbaranes'
import getCrearCsvAlbaranService from '../../services/albaran/getCrearCsvAlbaran'
import getImprimirPdfAlbaranService from '../../services/albaran/getImprimirPdfAlbaran'
import getAlbaranService from '../../services/albaran/getAlbaran'
import fetchNumeroAlbaranService from '../../services/albaran/fetchNumeroAlbaran'
import submitEditarAlbaranService from '../../services/albaran/submitEditarAlbaran'
import submitNuevoAlbaranService from '../../services/albaran/submitNuevoAlbaran'
import getImprimirDatosAlbaranService from '../../services/albaran/getImprimirDatosAlbaran'
import getImprimirDatosAlbaranClienteService from '../../services/albaran/getImprimirDatosAlbaranCliente'

// Sagas
import {yesNoModal as yesNoModalSaga} from '../modal/yesNoModal'
import {
  comboCliente as comboClienteSaga, comboDireccionCliente as comboDireccionClienteSaga,
  comboTransportistasCliente as comboTransportistasClienteSaga, comboRutaTransportistaCliente as comboRutaTransportistaClienteSaga,
  comboConductoresTransportistaDeterminado as comboConductoresTransportistaDeterminadoSaga,
  comboVehiculosTransportistaDeterminado  as comboVehiculosTransportistaDeterminadoSaga,
  comboTipoDosis as comboTipoDosisSaga, comboRaza as comboRazaSaga, comboLineaGenetica as comboLineaGeneticaSaga,
  comboVerracosExtraccionAlbaran as comboVerracosExtraccionAlbaranSaga, comboPoolsExtraccionAlbaran as comboPoolsExtraccionAlbaranSaga
} from '../combos/combos'

export function * fetchAlbaranes ({filtros}) {
  try {
    let auth = yield select(state => state.auth)
    let albaranesList = []
    if (filtros && Object.keys(filtros).filter(key => key !== 'seleccionar-todo').length > 0) {
      albaranesList = yield call(getFiltrarAlbaranesListService, filtros, auth.token)
    } else {
      albaranesList = yield call(getAlbaranesListService)
    }

    yield put(fetchAlbaranesSuccess(albaranesList, filtros))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchFetchAlbaranes () {
  yield takeLatest(actionTypes.FETCH_ALBARANES, fetchAlbaranes)
}

export function * onDeleteAlbaran ({idAlbaran}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModalSaga, {modalType: 'onDelete'})
    if (confirmed) {
      const {eliminado} = yield call(deleteAlbaranService, idAlbaran)
      let filtros = yield select(state => state.albaran.filtros)
      yield call(fetchAlbaranes, {filtros})
      if (eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchOnDeleteAlbaran () {
  yield takeLatest(actionTypes.ON_DELETE_ALBARAN, onDeleteAlbaran)
}

export function * deleteSeleccionAlbaranes ({list}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModalSaga, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const {eliminado} = yield call(deleteSeleccionAlbaranesService, list, auth.token)
      if (eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(deseleccionarTodosAlbaranes())
      yield put(initialize('FiltrosAlbaran'))
      let filtros = yield select(state => state.albaran.filtros)
      const inicializaTabla = yield call(fetchAlbaranes, {filtros})
      yield put(initialize('simpleTable', {}))
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchDeleteSeleccionAlbaranes () {
  yield takeLatest(actionTypes.DELETE_SELECCION_ALBARANES, deleteSeleccionAlbaranes)
}

export function * crearCsvAlbaranes ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosExcelAlbaran = yield call(getCrearCsvAlbaranService, list, auth.token)
    let href = 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(datosExcelAlbaran.excel)
    let name = i18n.t('MENU.PEDIDO.ALBARANES_VENTA') + ".xlsx"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchCrearCsvAlbaranes () {
  yield takeLatest(actionTypes.CREATE_CSV_ALBARANES, crearCsvAlbaranes)
}

export function * imprimirPdfAlbaranes ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfAlbaran = yield call(getImprimirPdfAlbaranService, list, auth.token)
    let href = 'data:application/pdf;base64,' + encodeURIComponent(datosPdfAlbaran.pdf)
    let name = i18n.t('MENU.PEDIDO.ALBARANES_VENTA') + ".pdf"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchImprimirPdfAlbaranes () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_ALBARANES, imprimirPdfAlbaranes)
}

export function * abrirModalVacioAlbaran () {
  try {
    let auth = yield select(state => state.auth)
    let [initializeResult, {numeroAlbaran}] = yield all([
      call(comboClienteSaga),
      call(fetchNumeroAlbaranService)
    ])

    let currentDate = dateTimeZones(auth.zonaHorariaString)
    yield put(initialize('ModalAlbaran', {
      numeroAlbaran,
      fechaCreacion: currentDate,
      estado: i18n.t('COMUN.COMBOS.' + translateEstadoAlbaranConstants[estadoAlbaranConstants["BORRADOR"]]),
      idEstado: estadoAlbaranConstants["BORRADOR"]
    }))

    yield put(abrirModalVacioAlbaranSuccess())
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchAbrirModalVacioAlbaran () {
  yield takeLatest(actionTypes.ABRIR_MODAL_VACIO_ALBARAN, abrirModalVacioAlbaran)
}

export function * abrirDetallesAlbaran ({idAlbaran}) {
  try {
    yield call(comboClienteSaga)
    let registroModificar = yield call(getAlbaranService, idAlbaran)
    let registrosFront = {
      idAlbaran,
      numeroAlbaran: registroModificar.numeroAlbaran,
      fechaCreacion: registroModificar.fechaCreacion,
      fechaRealizado: registroModificar.fechaRealizacion,
      origen: registroModificar.origen,
      dosisAlbaran: registroModificar.lineasAlbaran,
      observaciones: registroModificar.observaciones,
      observacionesCliente: registroModificar.observacionesCliente,
      idEstado: registroModificar.idEstado,
      estado: registroModificar.estado,
      idPedido: registroModificar.idPedido
    }

    // Recrear las propiedades necesarias.
    registrosFront.dosisAlbaran = registrosFront.dosisAlbaran.map((dosis) => {
      return {...dosis, dosisPedidas: dosis.dosisPedidas || 0, dias: dosis.diasHastaExtraccion}
    })

    // Obtener informacion extra de los combos.
    let state = yield select(state => state)
    if (registroModificar.idCliente) {
      registrosFront.cliente = state.combos.comboCliente.find((element) => {
        return element.value === registroModificar.idCliente
      })

      yield call(comboDireccionClienteSaga, {idCliente: registroModificar.idCliente})
      if (registroModificar.idDireccionCliente) {
        state = yield select(state => state)
        registrosFront.direccionEnvio = state.combos.comboDireccionCliente.find((element) => {
          return element.value === registroModificar.idDireccionCliente
        })

        yield call(comboTransportistasClienteSaga, {idDireccionCliente: registroModificar.idDireccionCliente})
        if (registroModificar.idTransportista) {
          state = yield select(state => state)
          registrosFront.transporte = state.combos.comboTransportistasCliente.find((element) => {
            return element.value === registroModificar.idTransportista
          })

          yield call(comboVehiculosTransportistaDeterminadoSaga, {idTransportista: registroModificar.idTransportista})
          yield call(comboConductoresTransportistaDeterminadoSaga, {idTransportista: registroModificar.idTransportista})
          yield call(comboRutaTransportistaClienteSaga, {
            idDireccionCliente: registroModificar.idDireccionCliente, idTransportista: registroModificar.idTransportista
          })
          if (registroModificar.idVehiculo) {
            state = yield select(state => state)
            registrosFront.vehiculo = state.combos.comboVehiculosTransportistaDeterminado.find((element) => {
              return element.value === registroModificar.idVehiculo
            })
          }
          if (registroModificar.idConductor) {
            state = yield select(state => state)
            registrosFront.conductor = state.combos.comboConductoresTransportistaDeterminado.find((element) => {
              return element.value === registroModificar.idConductor
            })
          }
          if (registroModificar.idRuta) {
            state = yield select(state => state)
            registrosFront.ruta = state.combos.comboRutaTransportistaCliente.find((element) => {
              return element.value === registroModificar.idRuta
            })
          }
        }
      }
    }
    if (registroModificar.idOperario) {
      registrosFront.operario = state.combos.comboOperarioPredeterminado.find((element) => {
        return element.value === registroModificar.idOperario
      })
    }

    yield put(initialize('ModalAlbaran', registrosFront))
    yield put(initialize('ModalAlbaranCliente', registrosFront)) /* MODAL SOLO LECTURA DE LOS ALBARANES DEL INFORME DE CLIENTES */
    yield put(abrirDetallesAlbaranSuccess(registrosFront))
    yield call(calcTotalesAlbaranDosis)
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchAbrirDetallesAlbaran () {
  yield takeLatest(actionTypes.ABRIR_DETALLES_ALBARAN, abrirDetallesAlbaran)
}

export function * duplicarDatosModalAlbaran ({idAlbaran}) {
  try {
    let auth = yield select(state => state.auth)
    let [initializeResult, {numeroAlbaran}] = yield all([
      call(comboClienteSaga),
      call(fetchNumeroAlbaranService)
    ])
    let registroModificar = yield call(getAlbaranService, idAlbaran)

    let registrosFront = {
      idAlbaranDuplicado: idAlbaran,
      numeroAlbaran: numeroAlbaran,
      fechaCreacion: registroModificar.fechaCreacion,
      fechaRealizado: registroModificar.fechaRealizacion,
      origen: registroModificar.origen,
      idPedido: registroModificar.idPedido,
      dosisAlbaran: registroModificar.lineasAlbaran,
      observaciones: registroModificar.observaciones,
      observacionesCliente: registroModificar.observacionesCliente,
      estado: i18n.t('COMUN.COMBOS.' + translateEstadoAlbaranConstants[estadoAlbaranConstants["BORRADOR"]]),
      idEstado: estadoAlbaranConstants["BORRADOR"]
    }

    // Recrear las propiedades necesarias.
    registrosFront.dosisAlbaran = registrosFront.dosisAlbaran.map((dosis) => {
      return {...dosis, dias: dosis.diasHastaExtraccion}
    })

    // Obtener informacion extra de los combos.
    let state = yield select(state => state)
    if (registroModificar.idCliente) {
      registrosFront.cliente = state.combos.comboCliente.find((element) => {
        return element.value === registroModificar.idCliente
      })

      yield call(comboDireccionClienteSaga, {idCliente: registroModificar.idCliente})
      if (registroModificar.idDireccionCliente) {
        state = yield select(state => state)
        registrosFront.direccionEnvio = state.combos.comboDireccionCliente.find((element) => {
          return element.value === registroModificar.idDireccionCliente
        })

        yield call(comboTransportistasClienteSaga, {idDireccionCliente: registroModificar.idDireccionCliente})
        if (registroModificar.idTransportista) {
          state = yield select(state => state)
          registrosFront.transporte = state.combos.comboTransportistasCliente.find((element) => {
            return element.value === registroModificar.idTransportista
          })

          yield call(comboVehiculosTransportistaDeterminadoSaga, {idTransportista: registroModificar.idTransportista})
          yield call(comboConductoresTransportistaDeterminadoSaga, {idTransportista: registroModificar.idTransportista})
          yield call(comboRutaTransportistaClienteSaga, {
            idDireccionCliente: registroModificar.idDireccionCliente, idTransportista: registroModificar.idTransportista
          })
          if (registroModificar.idVehiculo) {
            state = yield select(state => state)
            registrosFront.vehiculo = state.combos.comboVehiculosTransportistaDeterminado.find((element) => {
              return element.value === registroModificar.idVehiculo
            })
          }
          if (registroModificar.idConductor) {
            state = yield select(state => state)
            registrosFront.conductor = state.combos.comboConductoresTransportistaDeterminado.find((element) => {
              return element.value === registroModificar.idConductor
            })
          }
          if (registroModificar.idRuta) {
            state = yield select(state => state)
            registrosFront.ruta = state.combos.comboRutaTransportistaCliente.find((element) => {
              return element.value === registroModificar.idRuta
            })
          }
        }
      }
    }

    yield put(initialize('ModalAlbaran', registrosFront))
    yield put(abrirDetallesAlbaranSuccess(registrosFront))
    yield call(calcTotalesAlbaranDosis)
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchDuplicarDatosModalAlbaran () {
  yield takeLatest(actionTypes.DUPLICAR_DATOS_MODAL_ALBARAN, duplicarDatosModalAlbaran)
}

export function * cerrarDetallesAlbaran () {
  try {
    yield all([
      call(comboDireccionClienteSaga, {idCliente: null}),
      call(comboTransportistasClienteSaga, {idDireccionCliente: null}),
      call(comboVehiculosTransportistaDeterminadoSaga, {idTransportista: null}),
      call(comboConductoresTransportistaDeterminadoSaga, {idTransportista: null}),
      call(comboRutaTransportistaClienteSaga, {idDireccionCliente: null, idTransportista: null}),
      put(cerrarDetallesAlbaranSuccess())
    ])

    // Si esta abierto el modal de pedidos (y por tanto se ha abierto el modal de albaran a traves de su pedido), recargamos los datos.
    let pedidosVenta = yield select(state => state.pedidosVenta)
    if (pedidosVenta.showModalPedidosVenta && pedidosVenta.data && pedidosVenta.data.pedido) {
      yield put(recuperarDatosModalPedidosVenta(pedidosVenta.data.pedido.idPedido))
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchCerrarDetallesAlbaran () {
  yield takeLatest(actionTypes.CERRAR_DETALLES_ALBARAN, cerrarDetallesAlbaran)
}

export function * editarAlbaran ({values, mostrarMensaje, callback, onSuccessCallback, onErrorCallback}) {
  try {
    if (mostrarMensaje === true) {
      //yield put(openSimpleModal('pedidoIncompleto'))
      //const confirmedMensaje = yield call(yesNoModalSaga, {modalType: 'pedidoIncompleto'})
      let confirmedMensaje = true
      if (confirmedMensaje) {
        let state = yield select(state => state)
        let confirmed = true

        if (state.albaran.data.totalesPorDosis.total.dosisPedidas !== 0 && state.albaran.data.totalesPorDosis.total.dosisPedidas !== state.albaran.data.totalesPorDosis.total.dosisEnviadas && values.idEstado !== estadoAlbaranConstants["CANCELADO"] && values.idEstado !== estadoAlbaranConstants["BORRADOR"]) {
          confirmed = yield call(yesNoModalSaga, {modalType: 'dosisPedidasDifiereDosisEnviadas'})
        }

        if (confirmed) {
          let preparedValues = {
            ...values
          }

          // Eliminar la propiedad idAlbaranDosis cuando esta contenga la palabra new.
          preparedValues.dosisAlbaran = state.albaran.data.dosisAlbaran.map((dosis) => {
            return {
              ...dosis,
              idAlbaranDosis: (/^new/.test(dosis.idAlbaranDosis) ? null : dosis.idAlbaranDosis)
            }
          })

          // Si se recibe el idAlbaran se hara una actualización de dicho albarán, sino se creara uno nuevo.
          let estadoPeticion
          if (values.idAlbaran) {
            estadoPeticion = yield call(submitEditarAlbaranService, preparedValues, state.auth.token)
          } else {
            estadoPeticion = yield call(submitNuevoAlbaranService, preparedValues, state.auth.token)
          }

          if (estadoPeticion && (estadoPeticion.insertado || estadoPeticion.actualizado)) {
            yield put(openMensajeAlerta(values.idAlbaran ? 'modificacion' : 'insercion'))
            yield call(cerrarDetallesAlbaran)
            yield call(fetchAlbaranes, {filtros: state.albaran.filtros})
            if (callback) yield call(callback, (values.idAlbaran ? values.idAlbaran : estadoPeticion.idAlbaran))
            if (onSuccessCallback) yield onSuccessCallback()
          } else {
            yield put(openMensajeAlerta(values.idAlbaran ? 'no-modificacion' : 'no-insercion'))
            if (onErrorCallback) yield onErrorCallback()
          }
        } else if (onErrorCallback) yield onErrorCallback()
      }
    } else {
      let state = yield select(state => state)
      let confirmed = true

      if (state.albaran.data.totalesPorDosis.total.dosisPedidas !== 0 && state.albaran.data.totalesPorDosis.total.dosisPedidas !== state.albaran.data.totalesPorDosis.total.dosisEnviadas && values.idEstado !== estadoAlbaranConstants["CANCELADO"] && values.idEstado !== estadoAlbaranConstants["BORRADOR"]) {
        confirmed = yield call(yesNoModalSaga, {modalType: 'dosisPedidasDifiereDosisEnviadas'})
      }

      if (confirmed) {
        let preparedValues = {
          ...values
        }

        // Eliminar la propiedad idAlbaranDosis cuando esta contenga la palabra new.
        preparedValues.dosisAlbaran = state.albaran.data.dosisAlbaran.map((dosis) => {
          return {
            ...dosis,
            idAlbaranDosis: (/^new/.test(dosis.idAlbaranDosis) ? null : dosis.idAlbaranDosis)
          }
        })

        // Si se recibe el idAlbaran se hara una actualización de dicho albarán, sino se creara uno nuevo.
        let estadoPeticion
        if (values.idAlbaran) {
          estadoPeticion = yield call(submitEditarAlbaranService, preparedValues, state.auth.token)
        } else {
          estadoPeticion = yield call(submitNuevoAlbaranService, preparedValues, state.auth.token)
        }

        if (estadoPeticion && (estadoPeticion.insertado || estadoPeticion.actualizado)) {
          yield put(openMensajeAlerta(values.idAlbaran ? 'modificacion' : 'insercion'))
          yield call(cerrarDetallesAlbaran)
          yield call(fetchAlbaranes, {filtros: state.albaran.filtros})
          if (callback) yield call(callback, (values.idAlbaran ? values.idAlbaran : estadoPeticion.idAlbaran))
          if (onSuccessCallback) yield onSuccessCallback()
        } else {
          yield put(openMensajeAlerta(values.idAlbaran ? 'no-modificacion' : 'no-insercion'))
          if (onErrorCallback) yield onErrorCallback()
        }
      } else if (onErrorCallback) yield onErrorCallback()
    }
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
  }
}

export function * watchEditarAlbaran () {
  yield takeLatest(actionTypes.EDITAR_ALBARAN, editarAlbaran)
}

export function * abrirModalVacioAlbaranDosis () {
  try {
    yield all([
      comboTipoDosisSaga(),
      comboRazaSaga(),
      comboLineaGeneticaSaga(),
    ])

    let state = yield select(state => state)
    let {
      cliente
    } = yield getFormValues('ModalAlbaran')(state)

    yield put(initialize('ModalAlbaranDosis', {
      dias: 1,
      poolVerracoRadio: '' + verracoSiNoConstants.VERRACO,
      isVerracoPropio: cliente && cliente.verracosPropiedad ? true : false
    }))
    yield put(abrirModalVacioAlbaranDosisSuccess())
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchAbrirModalVacioAlbaranDosis () {
  yield takeLatest(actionTypes.ABRIR_MODAL_VACIO_ALBARAN_DOSIS, abrirModalVacioAlbaranDosis)
}

export function * abrirDetallesAlbaranDosis ({row}) {
  try {
    let state = yield select(state => state)
    let {
      fechaCreacion
    } = yield getFormValues('ModalAlbaran')(state)
    let filtros = {
      diasHasta: row.diasHastaExtraccion || 0,
      fecha: fechaCreacion,
      idRaza: row.idRaza,
      idLinea: row.idLinea,
      idTipoDosis: row.idTipoDosis
    }

    let numeroDosisRestantesInicial = row && row.numeroDosisRestantesInicial ? parseInt(row.numeroDosisRestantesInicial, 10) : 0
    let numeroDosisRestantes = row && row.numeroDosisRestantes ? parseInt(row.numeroDosisRestantes, 10) : 0
    let preparedValues = {
      idAlbaranDosis: row.idAlbaranDosis,
      idEnvasadoMultiDosis: row.idEnvasadoMultiDosis,
      dias: row.diasHastaExtraccion || 0,
      numeroDosisRestantesInicial: numeroDosisRestantesInicial,
      numeroDosisRestantes: numeroDosisRestantes - (row.dosisEnviadas || 0),
      dosisEnviadasInicial: row.dosisEnviadas || 0,
      dosisEnviadas: row.dosisEnviadas || 0,
      numeroDosisDisponibles: row.numeroDosisDisponibles || 0,
      numeroDosisReservadas: row.numeroDosisReservadas || 0,
      poolVerracoRadio: '' + row.tipoVerracoPool,
      datosOriginales: row,
      idPedido: row.idPedido,
      isVerracoPropio: row.isVerracoPropio ? true : false,
      idLineaGeneticaPedido: row.idLineaGeneticaPedido || null
    }

    // Si viene de un pedido se agregara a las llamadas el dato verraco. Además se mostrara en el formulario las dosis pedidas.
    if (row.dosisPedidas) {
      filtros.idVerraco = row.idVerracoPedido
      preparedValues.nombreVerracoPedido = row.nombreVerracoPedido
      preparedValues.dosisPedidas = row.dosisPedidas
    }

    if (!/^new/.test(row.idAlbaranDosis) && row.idEnvasadoMultiDosis) filtros.idEnvasadoMultiDosis = row.idEnvasadoMultiDosis

    if (state.albaran.data.idAlbaran) {
      filtros.idAlbaran = state.albaran.data.idAlbaran
    }

    yield all([
      comboTipoDosisSaga(),
      comboRazaSaga(),
      comboLineaGeneticaSaga(),
      ('' + row.tipoVerracoPool) === ('' + verracoSiNoConstants["VERRACO"]) ? comboVerracosExtraccionAlbaranSaga({values: filtros}) : comboPoolsExtraccionAlbaranSaga({values: filtros})
    ])

    // Obtener informacion extra de los combos.
    state = yield select(state => state)
    if (row.idTipoDosis) {
      preparedValues.tipoDosis = state.combos.comboTipoDosis.find((element) => {
        return element.value === row.idTipoDosis
      })
    }
    if (row.idRaza) {
      preparedValues.raza = state.combos.comboRaza.find((element) => {
        return element.value === row.idRaza
      })
    }
    if (row.idLinea) {
      preparedValues.linea = state.combos.comboLineaGenetica.find((element) => {
        return element.value === row.idLinea
      })
    }

    // Filtrar los combos de dosis con el fin de evitar que se introduzcan dos registros del mismo envasado.
    let comboVerracosExtraccionAlbaran = state.combos.comboVerracosExtraccionAlbaran.filter(verracoExtraccionAlbaran => {
      return (
        (row.idEnvasadoMultiDosis === verracoExtraccionAlbaran.idEnvasadoMultiDosis) ||
        ((state.albaran.data.dosisAlbaran && state.albaran.data.dosisAlbaran.length) ? !state.albaran.data.dosisAlbaran.some(dosis => {
          return dosis.idEnvasadoMultiDosis === verracoExtraccionAlbaran.idEnvasadoMultiDosis
        }) : true)
      )
    })
    let comboPoolsExtraccionAlbaran = state.combos.comboPoolsExtraccionAlbaran.filter(poolExtraccionAlbaran => {
      return (
        (row.idEnvasadoMultiDosis === poolExtraccionAlbaran.idEnvasadoMultiDosis) ||
        ((state.albaran.data.dosisAlbaran && state.albaran.data.dosisAlbaran.length) ? !state.albaran.data.dosisAlbaran.some(dosis => {
          return dosis.idEnvasadoMultiDosis === poolExtraccionAlbaran.idEnvasadoMultiDosis
        }) : true)
      )
    })

    if (('' + row.tipoVerracoPool) === ('' + verracoSiNoConstants["VERRACO"])) {
      if (row.idEnvasadoMultiDosis) {
        preparedValues.verraco = comboVerracosExtraccionAlbaran.find((element) => {
          return element.idEnvasadoMultiDosis === row.idEnvasadoMultiDosis
        })
      } else if (row.idVerraco) {
        preparedValues.verraco = comboVerracosExtraccionAlbaran.find((element) => {
          return element.idVerraco === row.idVerraco
        })
      }
      if (preparedValues.verraco) {
        preparedValues.numeroDosisRestantes = preparedValues.verraco.numeroDosisRestantes - (row.dosisEnviadas || 0)
        preparedValues.numeroDosisDisponibles = preparedValues.verraco.numeroDosisDisponibles
        preparedValues.numeroDosisReservadas = preparedValues.verraco.numeroDosisReservadas
      }
    } else if (('' + row.tipoVerracoPool) === ('' + verracoSiNoConstants["POOL"])) {
      preparedValues.pool = comboPoolsExtraccionAlbaran.find((element) => {
        return element.idEnvasadoMultiDosis === row.idEnvasadoMultiDosis
      })
      if (preparedValues.pool) {
        preparedValues.numeroDosisRestantes = preparedValues.pool.numeroDosisRestantes - (row.dosisEnviadas || 0)
        preparedValues.numeroDosisDisponibles = preparedValues.pool.numeroDosisDisponibles
        preparedValues.numeroDosisReservadas = preparedValues.pool.numeroDosisReservadas
      }
    }

    yield put(initialize('ModalAlbaranDosis', {...preparedValues}))
    yield put(abrirDetallesAlbaranDosisSuccess())
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchAbrirDetallesAlbaranDosis () {
  yield takeLatest(actionTypes.ABRIR_DETALLES_ALBARAN_DOSIS, abrirDetallesAlbaranDosis)
}

export function * cerrarDetallesAlbaranDosis () {
  try {
    yield put(cerrarDetallesAlbaranDosisSuccess())
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchCerrarDetallesAlbaranDosis () {
  yield takeLatest(actionTypes.CERRAR_DETALLES_ALBARAN_DOSIS, cerrarDetallesAlbaranDosis)
}

export function * editarAlbaranDosis ({values, guardarYnuevo, onSuccessCallback, onErrorCallback}) {
  try {
    let albaran = yield select(state => state.albaran)
    let updatedDosisAlbaranList = albaran.data.dosisAlbaran.slice()
    let verracoPool = (('' + values.poolVerracoRadio) === ('' + verracoSiNoConstants.VERRACO)) ? values.verraco : values.pool
    let dosisPedidas = values.dosisPedidas ? parseInt(values.dosisPedidas, 10) : 0
    let dosisEnviadas = values.dosisEnviadas ? parseInt(values.dosisEnviadas, 10) : 0
    let numeroDosisRestantes = values.numeroDosisRestantes ? parseInt(values.numeroDosisRestantes, 10) : 0
    let numeroDosisDisponibles = values.numeroDosisDisponibles ? parseInt(values.numeroDosisDisponibles, 10) : 0

    let confirmed = true
    if (dosisEnviadas > numeroDosisDisponibles) confirmed = yield call(yesNoModalSaga, {modalType: 'dosisEnviadasExcedeStockDisponible'})
    if (confirmed && dosisEnviadas < 0) confirmed = yield call(yesNoModalSaga, {modalType: 'dosisEnviadasNegativo'})

    if (confirmed) {
      // Sumatorio por tipo de dosis.
      let newDosis = {
        idAlbaranDosis: values.idAlbaranDosis || 'new_' + updatedDosisAlbaranList.length,
        idEnvasadoMultiDosis: verracoPool ? verracoPool.idEnvasadoMultiDosis : null,
        idRaza: values.raza ? values.raza.value : null,
        idLinea: values.linea ? values.linea.value : null,
        idTipoDosis: values.tipoDosis ? values.tipoDosis.value : null,
        nombreTipoDosis: values.tipoDosis ? values.tipoDosis.label : '',
        dias: values.dias,
        diasHastaExtraccion: values.dias, // Unicamente para mostrarlo en la tabla
        numeroDosisRestantesInicial: values.numeroDosisRestantesInicial || 0,
        numeroDosisRestantes: numeroDosisRestantes,
        dosisPedidas: dosisPedidas,
        dosisEnviadasInicial: dosisEnviadas,
        dosisEnviadas: dosisEnviadas,
        poolVerracoRadio: verracoPool ? ('' + values.poolVerracoRadio) : (''+verracoSiNoConstants.VERRACO),
        tipoVerracoPool: verracoPool ? ('' + values.poolVerracoRadio) : (''+verracoSiNoConstants.VERRACO),
        idVerracoPool: verracoPool ? verracoPool.idVerracoPool : null,
        verracoPool: verracoPool ? verracoPool.verracoPool : values.datosOriginales ? values.datosOriginales.nombreVerracoPedido : null,
        verraco: ('' + values.poolVerracoRadio) === ('' + verracoSiNoConstants.VERRACO) ? values.verraco : '',
        pool: ('' + values.poolVerracoRadio) === ('' + verracoSiNoConstants.POOL) ? values.pool : '',
        nombreRaza: verracoPool ? verracoPool.nombreRaza : values.raza ? values.raza.label : null,
        nombreLinea: verracoPool ? verracoPool.nombreLinea : values.datosOriginales ? values.datosOriginales.nombreLinea : null,
        isVerracoPropio: values.isVerracoPropio,
        idVerracoPedido: values.datosOriginales ? values.datosOriginales.idVerracoPedido : null,
        nombreVerracoPedido: values.datosOriginales ? values.datosOriginales.nombreVerracoPedido : '',
        idLineaGeneticaPedido: values.datosOriginales ? values.datosOriginales.idLineaGeneticaPedido : null,
        idPedido: values.datosOriginales ? values.datosOriginales.idPedido : null
      }

      // Si al añadir / modificar la línea faltan dosis se creara un registro identico con las dosis pedidas faltantes y el actual se pondra dosisPedidas = dosisEnviadas.
      let hayDosisEnviadasInsuficientes = dosisPedidas > 0 && dosisEnviadas > 0 && (dosisPedidas - dosisEnviadas) > 0
      if (hayDosisEnviadasInsuficientes) {
        newDosis.dosisPedidas = dosisEnviadas
      }

      if (values.idAlbaranDosis) {
        let indexDosisExistente = updatedDosisAlbaranList.findIndex((element) => {
          return element.idAlbaranDosis === values.idAlbaranDosis
        })
        updatedDosisAlbaranList[indexDosisExistente] = newDosis
      } else {
        updatedDosisAlbaranList.push(newDosis)
      }

      if (hayDosisEnviadasInsuficientes) {
        updatedDosisAlbaranList.push({
          ...values.datosOriginales,
          idAlbaranDosis: 'new_' + updatedDosisAlbaranList.length,
          dosisPedidas: dosisPedidas - dosisEnviadas,
          dosisEnviadas: 0
        })
      }
      yield put(editarAlbaranDosisSuccess(updatedDosisAlbaranList))

      if (guardarYnuevo === false) {
        yield put(cerrarDetallesAlbaranDosisSuccess())
      } else {
        yield put(initialize('ModalAlbaranDosis', {dias: 1, poolVerracoRadio: '' + verracoSiNoConstants.VERRACO}))
      }
      yield call(calcTotalesAlbaranDosis)
      yield delay(1000)
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      if (onErrorCallback) yield onErrorCallback()
    }
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
  }
}

export function * watchEditarAlbaranDosis () {
  yield takeLatest(actionTypes.EDITAR_ALBARAN_DOSIS, editarAlbaranDosis)
}

export function * deleteModalAlbaranDosis ({idAlbaranDosis}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModalSaga, {modalType: 'onDelete'})
    if (confirmed) {

      const state = yield select(state => state)
      let list = state.albaran.data.dosisAlbaran.filter((row) => {
        return row.idAlbaranDosis !== idAlbaranDosis
      })
      yield put(openMensajeAlerta('eliminacion'))

      yield put(editarAlbaranDosisSuccess(list))
      yield call(calcTotalesAlbaranDosis)
    }
  } catch (error) {}
}
export function * watchDeleteModalAlbaranDosis () {
  yield takeLatest(actionTypes.DELETE_MODAL_ALBARAN_DOSIS, deleteModalAlbaranDosis)
}

export function * deleteSeleccionModalAlbaranDosis ({list}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModalSaga, {modalType: 'onDelete'})
    if (confirmed) {
      let state = yield select(state => state)
      let newList = state.albaran.data.dosisAlbaran.filter((row) => {
        return !list.some((deletedRow) => {
          return deletedRow.idAlbaranDosis === row.idAlbaranDosis
        })
      })
      yield put(initialize('simpleTable', {}))
      yield put(editarAlbaranDosisSuccess(newList))
      yield call(calcTotalesAlbaranDosis)
      yield put(deseleccionarTodosModalAlbaranDosis())
      yield put(initialize('FiltrosModalAlbaran'))
      // if (eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      // } else {
      //   yield put(openMensajeAlerta('no-eliminacion'))
      // }
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchDeleteSeleccionModalAlbaranDosis () {
  yield takeLatest(actionTypes.DELETE_SELECCION_MODAL_ALBARAN_DOSIS, deleteSeleccionModalAlbaranDosis)
}

export function * calcTotalesAlbaranDosis () {
  try {
    let albaran = yield select(state => state.albaran)
    let newDosisAlbaranList = albaran.data.dosisAlbaran.slice()
    let totalesPorDosis = {}
    let totalesConjunto = {tipoDosis: 'total', dosisPedidas: 0, dosisEnviadas: 0, dosisPorServir: 0}
    let auxTotalDosisPorServir = 0

    // Sumatorio total.
    newDosisAlbaranList.forEach((dosisAlbaran) => {
      if (totalesPorDosis[dosisAlbaran.idTipoDosis]) {
        totalesPorDosis[dosisAlbaran.idTipoDosis].dosisPedidas += dosisAlbaran.dosisPedidas ? parseInt(dosisAlbaran.dosisPedidas, 10) : 0
        totalesPorDosis[dosisAlbaran.idTipoDosis].dosisEnviadas += dosisAlbaran.dosisEnviadas ? parseInt(dosisAlbaran.dosisEnviadas, 10) : 0
        totalesPorDosis[dosisAlbaran.idTipoDosis].dosisPorServir = (totalesPorDosis[dosisAlbaran.idTipoDosis].dosisPedidas > totalesPorDosis[dosisAlbaran.idTipoDosis].dosisEnviadas) ? totalesPorDosis[dosisAlbaran.idTipoDosis].dosisPedidas - totalesPorDosis[dosisAlbaran.idTipoDosis].dosisEnviadas : 0
      } else {
        totalesPorDosis[dosisAlbaran.idTipoDosis] = {
          tipoDosis: dosisAlbaran.nombreTipoDosis,
          dosisPedidas: parseInt(dosisAlbaran.dosisPedidas, 10) || 0,
          dosisEnviadas: parseInt(dosisAlbaran.dosisEnviadas, 10) || 0,
          dosisPorServir: dosisAlbaran.dosisPedidas && (parseInt(dosisAlbaran.dosisPedidas, 10) || 0) > (parseInt(dosisAlbaran.dosisEnviadas, 10) || 0) ? (parseInt(dosisAlbaran.dosisPedidas, 10) || 0) - (parseInt(dosisAlbaran.dosisEnviadas, 10) || 0) : 0
        }
      }
    })

    for (const idTipoDosis in totalesPorDosis) {
      if (totalesPorDosis.hasOwnProperty(idTipoDosis)) {
        totalesConjunto.dosisPedidas += parseInt(totalesPorDosis[idTipoDosis].dosisPedidas, 10) || 0
        totalesConjunto.dosisEnviadas += parseInt(totalesPorDosis[idTipoDosis].dosisEnviadas, 10) || 0
        auxTotalDosisPorServir = (parseInt(totalesPorDosis[idTipoDosis].dosisPorServir, 10) || 0)
        totalesConjunto.dosisPorServir += (auxTotalDosisPorServir > 0 ? auxTotalDosisPorServir : 0)
      }
    }
    totalesPorDosis.total = totalesConjunto

    yield put(calcTotalesAlbaranDosisSuccess(totalesPorDosis))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchCalcTotalesAlbaranDosis () {
  yield takeLatest(actionTypes.CALC_TOTALES_ALBARAN_DOSIS, calcTotalesAlbaranDosis)
}

export function * editarAlbaranEImprimirDatosAlbaran ({values, numeroAlbaran, mostrarMensaje, onSuccessCallback, onErrorCallback}) {
  try {
    if (mostrarMensaje === true) {
      //yield put(openSimpleModal('pedidoIncompleto'))
      //const confirmedMensaje = yield call(yesNoModalSaga, {modalType: 'pedidoIncompleto'})
      let confirmedMensaje = true
      if (confirmedMensaje) {
        yield call(editarAlbaran, {values, callback: function * (idAlbaran) {
          const confirmed = yield call(yesNoModalSaga, {modalType: 'imprimirAlbaran'})
          if (confirmed) {
            let auth = yield select(state => state.auth)
            const datosPdfAlbaran = yield call(getImprimirDatosAlbaranService, idAlbaran)
            let href = 'data:application/pdf;base64,' + encodeURIComponent(datosPdfAlbaran.pdf)
            let name = i18n.t('ALBARANES.MODAL.IMPRESOS.DATOS_ALBARAN') + numeroAlbaran + ".pdf"
            downloadDocument(href, name)
          }
        }, onSuccessCallback, onErrorCallback})
      }
    } else {
      yield call(editarAlbaran, {values, callback: function * (idAlbaran) {
        const confirmed = yield call(yesNoModalSaga, {modalType: 'imprimirAlbaran'})
        if (confirmed) {
          let auth = yield select(state => state.auth)
          const datosPdfAlbaran = yield call(getImprimirDatosAlbaranService, idAlbaran)
          let href = 'data:application/pdf;base64,' + encodeURIComponent(datosPdfAlbaran.pdf)
          let name = i18n.t('ALBARANES.MODAL.IMPRESOS.DATOS_ALBARAN') + numeroAlbaran + ".pdf"
          downloadDocument(href, name)
        }
      }, onSuccessCallback, onErrorCallback})
    }
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
  }
}

export function * watchEditarAlbaranEImprimirDatosAlbaran () {
  yield takeLatest(actionTypes.EDITAR_ALBARAN_E_IMPRIMIR_DATOS_ALBARAN, editarAlbaranEImprimirDatosAlbaran)
}

export function * imprimirDatosAlbaran ({values, idAlbaran, numeroAlbaran, onSuccessCallback, onErrorCallback}) {
  try {
    let state = yield select(state => state)
    let soloLectura = (state.auth.permisos !== '-1' && state.auth.permisos[permisosConstant.ALBARANES] === 1)

    let confirmed = true
    if (!soloLectura) {
      confirmed = yield call(yesNoModalSaga, {modalType: 'imprimirGuardar'})
    }
    if (confirmed) {
      if (!soloLectura) {
        // guardar modal antes de imprimir
        let state = yield select(state => state)
        let preparedValues = {
          ...values
        }
        // Eliminar la propiedad idAlbaranDosis cuando esta contenga la palabra new.
        preparedValues.dosisAlbaran = state.albaran.data.dosisAlbaran.map((dosis) => {
          return {
            ...dosis,
            idAlbaranDosis: (/^new/.test(dosis.idAlbaranDosis) ? null : dosis.idAlbaranDosis)
          }
        })
        yield call(submitEditarAlbaranService, preparedValues, state.auth.token)
        yield call(fetchAlbaranes, {filtros: state.albaran.filtros})
      }

      // imprimir
      const datosPdfAlbaran = yield call(getImprimirDatosAlbaranService, idAlbaran)
      let href = 'data:application/pdf;base64,' + encodeURIComponent(datosPdfAlbaran.pdf)
      let name = i18n.t('ALBARANES.MODAL.IMPRESOS.DATOS_ALBARAN') + numeroAlbaran + ".pdf"
      downloadDocument(href, name)
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      if (onErrorCallback) yield onErrorCallback()
    }
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
  }
}

export function * watchImprimirDatosAlbaran () {
  yield takeLatest(actionTypes.IMPRIMIR_DATOS_ALBARAN, imprimirDatosAlbaran)
}

export function * imprimirDatosAlbaranCliente ({values, idAlbaran, numeroAlbaran, onSuccessCallback, onErrorCallback}) {
  try {
    let state = yield select(state => state)
    let soloLectura = (state.auth.permisos !== '-1' && state.auth.permisos[permisosConstant.ALBARANES] === 1)

    let confirmed = true
    if (!soloLectura) {
      confirmed = yield call(yesNoModalSaga, {modalType: 'imprimirGuardar'})
    }
    if (confirmed) {
      if (!soloLectura) {
        // guardar modal antes de imprimir
        let state = yield select(state => state)
        let preparedValues = {
          ...values
        }
        // Eliminar la propiedad idAlbaranDosis cuando esta contenga la palabra new.
        preparedValues.dosisAlbaran = state.albaran.data.dosisAlbaran.map((dosis) => {
          return {
            ...dosis,
            idAlbaranDosis: (/^new/.test(dosis.idAlbaranDosis) ? null : dosis.idAlbaranDosis)
          }
        })
        yield call(submitEditarAlbaranService, preparedValues, state.auth.token)
        yield call(fetchAlbaranes, {filtros: state.albaran.filtros})
      }
      // imprimir
      const datosPdfClienteAlbaran = yield call(getImprimirDatosAlbaranClienteService, idAlbaran)
      let href = 'data:application/pdf;base64,' + encodeURIComponent(datosPdfClienteAlbaran.pdf)
      let name = i18n.t('ALBARANES.MODAL.IMPRESOS.DATOS_ALBARAN_CLIENTE') + numeroAlbaran + ".pdf"
      downloadDocument(href, name)
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      if (onErrorCallback) yield onErrorCallback()
    }
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
  }
}

export function * watchImprimirDatosAlbaranCliente () {
  yield takeLatest(actionTypes.IMPRIMIR_DATOS_ALBARAN_CLIENTE, imprimirDatosAlbaranCliente)
}