
export const analisisExtraccion = {
  TODOS: 1,
  PREVISTO: 2
}

export const translateAnalisisExtraccion = {
  1: 'ANALISIS_EXTRACCION.TODOS',
  2: 'ANALISIS_EXTRACCION.PREVISTO'
}

export const tipoAnalisisExtraccion = {
  COLORIMETRO: 1,
  CAMARA_BURKER: 2,
  MAGAVISION_I: 3,
  MAGAVISION_M: 4,
  NUEVO_MAGAVISION: 5
}

export const translateTipoAnalisisExtraccion = {
  1: 'TIPO_ANALISIS_EXTRACCION.COLORIMETRO',
  2: 'TIPO_ANALISIS_EXTRACCION.CAMARA_BURKER',
  3: 'TIPO_ANALISIS_EXTRACCION.MAGAVISION_I',
  4: 'TIPO_ANALISIS_EXTRACCION.MAGAVISION_M',
  5: 'TIPO_ANALISIS_EXTRACCION.NUEVO_MAGAVISION'
}

export const observacionesEyaculadoEliminado = {
  MUERTO: 1,
  MOTILIDAD: 2,
  FA: 3,
  CONCENTRACION: 4,
  AGLUTINACION: 5,
  CONTAMINACION_U_OTROS: 6
}

export const translateObservacionesEyaculadoEliminado = {
  1: 'OBSERVACIONES_EYACULADO_ELIMINADO.MUERTO',
  2: 'OBSERVACIONES_EYACULADO_ELIMINADO.MOTILIDAD',
  3: 'OBSERVACIONES_EYACULADO_ELIMINADO.FA',
  4: 'OBSERVACIONES_EYACULADO_ELIMINADO.CONCENTRACION',
  5: 'OBSERVACIONES_EYACULADO_ELIMINADO.AGLUTINACION',
  6: 'OBSERVACIONES_EYACULADO_ELIMINADO.CONTAMINACION_U_OTROS'
}

export const concentracionDisponible = {
  TOTAL: 1, // También llamada 'concentración aplicada'.
  UTIL: 2,
}