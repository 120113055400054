import React, { Component } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { required } from '../../util/validationFunctions'
import Button from '../comun/button/Button'
import ButtonLockable from '../comun/button/ButtonLockable'
import InputText from '../comun/form/InputText'
import InputNumerical from '../comun/form/InputNumerical'
import './PanelControl.scss'
import {permisos as permisosConstant} from '../../constants/permisos'

class ModalSecuenciasNumeracion extends Component {

  editarSecuencia (values) {
    return new Promise((resolve, reject) => {
      this.props.actions.editarSecuencia(values, resolve, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  render () {
    const {
      t, handleSubmit, submitting, showModalSecuenciasNumeracion, dataSecuenciaNumeraciones,
      actions: {cerrarModalSecuenciasNumeracion}
    } = this.props

    return (
      <Modal show={showModalSecuenciasNumeracion} onHide={cerrarModalSecuenciasNumeracion} aria-labelledby="contained-modal-title-lg" className="modal-secuencias-numeracion" backdrop="static">
      <form className="form-recta-colorimetro">
        <Modal.Header closeButton={!submitting}>
          <Modal.Title>
          {
            dataSecuenciaNumeraciones.idTipo === 1 ? (
              t('PANEL_CONTROL.TABLA_SECUENCIA_NUMERACIONES.SEC_REGISTRO_ALIMENTACION')
            ) : dataSecuenciaNumeraciones.idTipo === 2 ? (
              t('PANEL_CONTROL.TABLA_SECUENCIA_NUMERACIONES.SEC_REGISTRO_INTERVENCION')
            ) : dataSecuenciaNumeraciones.idTipo === 4 ? (
              t('PANEL_CONTROL.TABLA_SECUENCIA_NUMERACIONES.SEC_ALBARANES')
            ) : dataSecuenciaNumeraciones.idTipo === 3 ? (
              t('PANEL_CONTROL.TABLA_SECUENCIA_NUMERACIONES.SEC_PEDIDOS')
            ) : null
          }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12} className="container-tabla-recta-colorimetro">
              <Field
                colSm={4}
                id="prefijo"
                name="prefijo"
                component={InputText}
                controlLabel={t('PANEL_CONTROL.TABLA_SECUENCIA_NUMERACIONES.MODAL.PEFIJO')}
                maxLength={true}
                valorMaxLength={30}
              />
              <Field
                colSm={4}
                id="sufijo"
                name="sufijo"
                component={InputText}
                controlLabel={t('PANEL_CONTROL.TABLA_SECUENCIA_NUMERACIONES.MODAL.SUFIJO')}
                maxLength={true}
                valorMaxLength={30}
              />
              <Field
                colSm={4}
                id="longitudCentral"
                name="longitudCentral"
                component={InputNumerical}
                numDecimales={0}
                controlLabel={t('PANEL_CONTROL.TABLA_SECUENCIA_NUMERACIONES.MODAL.LONGITUD') + '*'}
                validate={required}
              />
              <Field
                colSm={4}
                id="incremento"
                name="incremento"
                component={InputNumerical}
                numDecimales={0}
                controlLabel={t('PANEL_CONTROL.TABLA_SECUENCIA_NUMERACIONES.MODAL.INCREMENTO_NUMERO') + '*'}
                validate={required}
              />
              <Field
                colSm={4}
                id="numSiguiente"
                name="numSiguiente"
                component={InputNumerical}
                numDecimales={0}
                controlLabel={t('PANEL_CONTROL.TABLA_SECUENCIA_NUMERACIONES.MODAL.NUMERO_SIGUIENTE') + '*'}
                validate={required}
              />
              <div className="clearfix" />
              <div className="leyenda-secuencias-numericas">
                <Col sm={12}><h3>{t('PANEL_CONTROL.TABLA_SECUENCIA_NUMERACIONES.MODAL.LEYENDA.TITULO')}</h3></Col>
                <Col sm={4}>
                  <p>{t('PANEL_CONTROL.TABLA_SECUENCIA_NUMERACIONES.MODAL.LEYENDA.ANO_CON_CENTURIA')}</p>
                  <p>{t('PANEL_CONTROL.TABLA_SECUENCIA_NUMERACIONES.MODAL.LEYENDA.ANO_SIN_CENTURIA')}</p>
                  <p>{t('PANEL_CONTROL.TABLA_SECUENCIA_NUMERACIONES.MODAL.LEYENDA.MES')}</p>
                  <p>{t('PANEL_CONTROL.TABLA_SECUENCIA_NUMERACIONES.MODAL.LEYENDA.DIA')}</p>
                </Col>
                <Col sm={4}>
                  <p>{t('PANEL_CONTROL.TABLA_SECUENCIA_NUMERACIONES.MODAL.LEYENDA.DIA_DEL_ANO')}</p>
                  <p>{t('PANEL_CONTROL.TABLA_SECUENCIA_NUMERACIONES.MODAL.LEYENDA.SEMANA_DEL_ANO')}</p>
                  <p>{t('PANEL_CONTROL.TABLA_SECUENCIA_NUMERACIONES.MODAL.LEYENDA.DIA_SEMANA')}</p>
                </Col>
                <Col sm={4}>
                  <p>{t('PANEL_CONTROL.TABLA_SECUENCIA_NUMERACIONES.MODAL.LEYENDA.HORA_24')}</p>
                  <p>{t('PANEL_CONTROL.TABLA_SECUENCIA_NUMERACIONES.MODAL.LEYENDA.HORA_12')}</p>
                  <p>{t('PANEL_CONTROL.TABLA_SECUENCIA_NUMERACIONES.MODAL.LEYENDA.MINUTO')}</p>
                  <p>{t('PANEL_CONTROL.TABLA_SECUENCIA_NUMERACIONES.MODAL.LEYENDA.SEGUNDO')}</p>
                </Col>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <ButtonLockable
            processing={submitting}
            hasIcon={true}
            showLoaderOnClick={true}
            type="button"
            className="btn btn-primary btn-sm"
            disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.SECUENCIA] < 2) || submitting}
            onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.SECUENCIA] > 1) && !submitting) ? handleSubmit(this.editarSecuencia.bind(this)) : () => {}}
          >
            {t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.GUARDAR')}
          </ButtonLockable>
          <Button
            type="button"
            className={"btn btn-white btn-sm" + (submitting ? ' disabled' : '')}
            onClick={() => !submitting && cerrarModalSecuenciasNumeracion()}
          >
            {t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.CERRAR')}
          </Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalSecuenciasNumeracion',
})(ModalSecuenciasNumeracion))