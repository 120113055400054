import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize} from 'redux-form'
import i18n from '../../i18n'
import actionTypes from '../../constants/actions/validacionMateriaPrima/validacionMateriaPrima'
import { closeModalValidacionMateriaPrima, filtrarValidacionMateriaPrimaSuccess, filtrosValidacionMateriaPrimaSuccess, recuperarDatosModalValidacionMateriaPrimaSuccess,
  duplicarDatosModalValidacionMateriaPrimaSuccess, deSeleccionarTodosValidacionMateriaPrima, crearCsvValidacionMateriaPrimaSuccess, imprimirPdfValidacionMateriaPrimaSuccess,
  obtenerVerracosMateriaPrimaSuccess, obtenerPoolMateriaPrimaSucccess, recuperarDatosModalVerracoPoolSuccess, editarVerracoPoolSuccess, duplicarDatosModalVerracoPoolSuccess, resetValidacionMateriaPrima,
  editarVerracoMateriaPrimaSuccess, editarPoolMateriaPrimaSuccess } from '../../actions/validacionMateriaPrima/validacionMateriaPrima'
import {openSimpleModal, openMensajeAlerta} from '../../actions/common'
import { yesNoModal } from '../modal/yesNoModal'
import filtrosValidacionMateriaPrima from '../../services/validacionMateriaPrima/filtrosValidacionMateriaPrima'
import getVerracosMateriaPrima from '../../services/validacionMateriaPrima/getVerracosMateriaPrima'
import getPoolMateriaPrima from '../../services/validacionMateriaPrima/getPoolMateriaPrima'
import addValidacionMateriaPrima from '../../services/validacionMateriaPrima/addValidacionMateriaPrima'
import getValidacionMateriaPrimas from '../../services/validacionMateriaPrima/getValidacionMateriaPrimas'
import editValidacionMateriaPrima from '../../services/validacionMateriaPrima/editValidacionMateriaPrima'
import deleteValidacionMateriaPrima from '../../services/validacionMateriaPrima/deleteValidacionMateriaPrima'
import eliminarValidacionMateriaPrimaSeleccion from '../../services/validacionMateriaPrima/eliminarValidacionMateriaPrimaSeleccion'
import getCrearCsv from '../../services/validacionMateriaPrima/getCrearCsv'
import getImprimirPdfValidacionMateriaPrima from '../../services/validacionMateriaPrima/getImprimirPdfValidacionMateriaPrima'

export function * filtrarValidacionMateriaPrima ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const list = yield call(filtrosValidacionMateriaPrima, values, auth.token)
    yield put(filtrarValidacionMateriaPrimaSuccess(list))
    yield put(filtrosValidacionMateriaPrimaSuccess(values))
  } catch (error) {

  } finally {
  }
}
export function * watchFiltrarValidacionMateriaPrima () {
  yield takeLatest(actionTypes.FILTRAR_VALIDACION_MATERIA_PRIMA, filtrarValidacionMateriaPrima)
}

export function * abrirModalValidacionMateriaPrima ({values}) {
  try {
    yield put(initialize('ModalValidacionMateriaPrima', {}))
  } catch (error) {

  } finally {
  }
}
export function * watchAbrirValidacionMateriaPrima () {
  yield takeLatest(actionTypes.ABRIR_MODAL_VALIDACION_MATERIA_PRIMA, abrirModalValidacionMateriaPrima)
}

export function * obtenerVerracosMateriaPrima ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const comboVerracosMateriaPrima = yield call(getVerracosMateriaPrima, list, auth.token)
    yield put(obtenerVerracosMateriaPrimaSuccess(comboVerracosMateriaPrima))
  } catch (error) {

  } finally {
  }
}
export function * watchObtenerVerracosMateriaPrima () {
  yield takeLatest(actionTypes.OBTENER_VERRACOS_MATERIA_PRIMA, obtenerVerracosMateriaPrima)
}

export function * obtenerPoolMateriaPrima ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const comboPoolMateriaPrima = yield call(getPoolMateriaPrima, list, auth.token)
    yield put(obtenerPoolMateriaPrimaSucccess(comboPoolMateriaPrima))
  } catch (error) {

  } finally {
  }
}
export function * watchObtenerPoolMateriaPrima () {
  yield takeLatest(actionTypes.OBTENER_POOL_MATERIA_PRIMA, obtenerPoolMateriaPrima)
}

export function * recuperarDatosModalVerracoPool ({idElemento}) {
  try {
    let verracoPool = yield select(state => state.validacionMateriaPrima.listVerracoPool)
    const dataVerracoPool = verracoPool.filter((verPool) => {
      if (verPool.idElemento === idElemento){
        return verPool
      }
    })
    if (dataVerracoPool[0].pool === null) {
      dataVerracoPool[0].verracoPool = {value: 1, label: i18n.t('COMUN.COMBOS.VERRACO_POOL.VERRACO')}
    } else {
      dataVerracoPool[0].verracoPool = {value: 2, label: i18n.t('COMUN.COMBOS.VERRACO_POOL.POOL')}
    }
    yield put(recuperarDatosModalVerracoPoolSuccess(dataVerracoPool[0]))
    yield put(initialize('ModalAnadirVerracoPool', dataVerracoPool[0]))
  } catch (error) {

  } finally {
  }
}
export function * watchRecuperarDatosModalVerracoPool () {
  yield takeLatest(actionTypes.RECUPERAR_DATOS_MODAL_VERRACO_POOL, recuperarDatosModalVerracoPool)
}

export function * editarVerracoPool ({values}) {
  try {
    let verracoPoolState = yield select(state => state.validacionMateriaPrima.listVerracoPool)
    const verracoPoolRecuperar = verracoPoolState.filter((VerracoPool) => {
      if (VerracoPool.idElemento === values.idElemento){
        VerracoPool.verracoPool = values.verracoPool
        VerracoPool.motilidad = values.motilidad
        VerracoPool.aglutinacion = values.aglutinacion
        VerracoPool.motilidadControl = values.motilidadControl
        VerracoPool.aglutinacionControl = values.aglutinacionControl
        VerracoPool.fecha = values.fecha
        VerracoPool.idElemento = values.idElemento
        if (values.verracoPool.value === 1) {
          VerracoPool.verraco = values.verraco.label
          VerracoPool.idAnalisis = values.idAnalisis
        } else if (values.verracoPool.value === 2) {
          VerracoPool.pool = values.pool.label
          VerracoPool.idPool = values.idPool
        }
      }
      return VerracoPool
    })
    yield put(editarVerracoPoolSuccess(verracoPoolRecuperar))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchEditarVerracoPool () {
  yield takeLatest(actionTypes.EDITAR_VERRACO_POOL, editarVerracoPool )
}

export function * duplicarDatosModalVerracoPool ({idElemento}) {
  try {
    let verracoPool = yield select(state => state.validacionMateriaPrima.listVerracoPool)
    const dataDuplicarVerracoPool = verracoPool.filter((verPool) => {
      if (verPool.idElemento === idElemento){
        return verPool
      }
    })
    if (dataDuplicarVerracoPool[0].idAnalisis && dataDuplicarVerracoPool[0].idAnalisis !== null) {
      dataDuplicarVerracoPool[0].verracoPool = {value: 1, label: i18n.t('COMUN.COMBOS.VERRACO_POOL.VERRACO')}
    } else {
      dataDuplicarVerracoPool[0].verracoPool = {value: 2, label: i18n.t('COMUN.COMBOS.VERRACO_POOL.POOL')}
    }
    yield put(duplicarDatosModalVerracoPoolSuccess(dataDuplicarVerracoPool[0]))
    yield put(initialize('ModalAnadirVerracoPool', dataDuplicarVerracoPool[0]))
  } catch (error) {

  } finally {
  }
}
export function * watchDuplicarDatosModalVerracoPool () {
  yield takeLatest(actionTypes.DUPLICAR_DATOS_MODAL_VERRACO_POOL, duplicarDatosModalVerracoPool)
}

export function * onDeleteVerracoPool ({idElemento}) {
  try {
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let verracoPoolState = yield select(state => state.validacionMateriaPrima.listVerracoPool)
      const verracoPoolRecuperar = verracoPoolState.filter((VerracoPool) => {
        if (VerracoPool.idElemento !== idElemento){
          return VerracoPool
        }
      })
      yield put(editarVerracoPoolSuccess(verracoPoolRecuperar))

      // verraco
      let verracoState = yield select(state => state.validacionMateriaPrima.listVerracoPool)
      const verracoRecuperar = verracoState.filter((verraco) => {
        if (verraco.verraco !== null) {
          if (verraco.idElemento !== idElemento){
            return verraco
          }
        }
      })
      yield put(editarVerracoMateriaPrimaSuccess(verracoRecuperar))

      // pool
      let poolState = yield select(state => state.validacionMateriaPrima.listVerracoPool)
      const poolRecuperar = poolState.filter((pool) => {
        if (pool.pool !== null) {
          if (pool.idElemento !== idElemento){
            return pool
          }
        }
      })
      yield put(editarPoolMateriaPrimaSuccess(poolRecuperar))
    }
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchOnDeleteVerracoPool () {
  yield takeLatest(actionTypes.ON_DELETE_VERRACO_POOL, onDeleteVerracoPool )
}

export function * submitNuevoValidacionMateriaPrima ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const list = yield call(addValidacionMateriaPrima, values, auth.token)
    if (list.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-insercion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    yield put(closeModalValidacionMateriaPrima())
    let filtros = yield select(state => state.validacionMateriaPrima.filtros)
    const lista = yield call(filtrosValidacionMateriaPrima, filtros, auth.token)
    yield put(filtrarValidacionMateriaPrimaSuccess(lista))
    yield put(filtrosValidacionMateriaPrimaSuccess(filtros))
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
  }
}
export function * watchSubmitNuevoValidacionMateriaPrima () {
  yield takeLatest(actionTypes.SUBMIT_NUEVO_VALIDACION_MATERIA_PRIMA, submitNuevoValidacionMateriaPrima)
}

export function * recuperarDatosModalValidacionMateriaPrima ({idValidacionMateriaPrima}) {
  try {
    let auth = yield select(state => state.auth)
    const registroModificar = yield call(getValidacionMateriaPrimas, idValidacionMateriaPrima, null)
    yield put(recuperarDatosModalValidacionMateriaPrimaSuccess(registroModificar))
    registroModificar.materiaPrima.fechaEntrada = registroModificar.materiaPrima.fechaInicio
    registroModificar.materiaPrima.fechaSalida = registroModificar.materiaPrima.fechaFin
    registroModificar.materiaPrima.producto = {value: registroModificar.materiaPrima.idProducto, label: registroModificar.materiaPrima.nombre}
    registroModificar.materiaPrima.lote = {value: registroModificar.materiaPrima.idEntradaAlmacenProducto, label: registroModificar.materiaPrima.lote}
    if (registroModificar.materiaPrima.estadoValidacion === true){
      registroModificar.materiaPrima.apto = {value: registroModificar.materiaPrima.estadoValidacion, label: i18n.t('COMUN.COMBOS.APTO.SI')}
    } else  if (registroModificar.materiaPrima.estadoValidacion === false) {
      registroModificar.materiaPrima.apto = {value: registroModificar.materiaPrima.estadoValidacion, label: i18n.t('COMUN.COMBOS.APTO.NO')}
    }
    yield put(initialize('ModalValidacionMateriaPrima', registroModificar.materiaPrima))
    yield put(editarVerracoPoolSuccess(registroModificar.pools_verracos))
    yield put(editarVerracoMateriaPrimaSuccess(registroModificar.verracos))
    yield put(editarPoolMateriaPrimaSuccess(registroModificar.pools))
  } catch (error) {

  } finally {
  }
}
export function * watchRecuperarDatosModalValidacionMateriaPrima () {
  yield takeLatest(actionTypes.RECUPERAR_DATOS_MODAL_VALIDACION_MATERIA_PRIMA, recuperarDatosModalValidacionMateriaPrima)
}

export function * editarValidacionMateriaPrima ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const edicionValidacionMateriaPrima = yield call(editValidacionMateriaPrima, values, auth.token)
    yield put(closeModalValidacionMateriaPrima())
    if (edicionValidacionMateriaPrima.actualizado === true) {
      yield put(openMensajeAlerta('modificacion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-modificacion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    let filtros = yield select(state => state.validacionMateriaPrima.filtros)
    const list = yield call(filtrosValidacionMateriaPrima, filtros, auth.token)
    yield put(filtrarValidacionMateriaPrimaSuccess(list))
    yield put(filtrosValidacionMateriaPrimaSuccess(filtros))
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
  }
}
export function * watchEditarValidacionMateriaPrima () {
  yield takeLatest(actionTypes.EDITAR_VALIDACION_MATERIA_PRIMA, editarValidacionMateriaPrima)
}

export function * duplicarDatosModalValidacionMateriaPrima ({idValidacionMateriaPrima, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const registroDuplicar = yield call(getValidacionMateriaPrimas, idValidacionMateriaPrima, null)
    yield put(duplicarDatosModalValidacionMateriaPrimaSuccess(registroDuplicar))
    registroDuplicar.materiaPrima.fechaEntrada = registroDuplicar.materiaPrima.fechaInicio
    registroDuplicar.materiaPrima.fechaSalida = registroDuplicar.materiaPrima.fechaFin
    registroDuplicar.materiaPrima.producto = {value: registroDuplicar.materiaPrima.idProducto, label: registroDuplicar.materiaPrima.nombre}
    registroDuplicar.materiaPrima.lote = ''
    registroDuplicar.materiaPrima.cantidad = ''
    if (registroDuplicar.materiaPrima.estadoValidacion === true){
      registroDuplicar.materiaPrima.apto = {value: registroDuplicar.materiaPrima.estadoValidacion, label: i18n.t('COMUN.COMBOS.APTO.SI')}
    } else  if (registroDuplicar.materiaPrima.estadoValidacion === false) {
      registroDuplicar.materiaPrima.apto = {value: registroDuplicar.materiaPrima.estadoValidacion, label: i18n.t('COMUN.COMBOS.APTO.NO')}
    }
    yield put(initialize('ModalValidacionMateriaPrima', registroDuplicar.materiaPrima))
    yield put(editarVerracoPoolSuccess(registroDuplicar.pools_verracos))
    yield put(editarVerracoMateriaPrimaSuccess(registroDuplicar.verracos))
    yield put(editarPoolMateriaPrimaSuccess(registroDuplicar.pools))
    if (onSuccessCallback) yield onSuccessCallback()
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
  }
}
export function * watchDuplicarDatosModalValidacionMateriaPrima () {
  yield takeLatest(actionTypes.DUPLICAR_DATOS_MODAL_VALIDACION_MATERIA_PRIMA, duplicarDatosModalValidacionMateriaPrima)
}


export function * onDeleteValidacionMateriaPrima ({idValidacionMateriaPrima}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const eliminar = yield call(deleteValidacionMateriaPrima, idValidacionMateriaPrima, null)
      if (eliminar.asociado === false && eliminar.eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      let filtros = yield select(state => state.validacionMateriaPrima.filtros)
      const list = yield call(filtrosValidacionMateriaPrima, filtros, auth.token)
      yield put(filtrarValidacionMateriaPrimaSuccess(list))
      yield put(filtrosValidacionMateriaPrimaSuccess(filtros))
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchOnDeleteValidacionMateriaPrima () {
  yield takeLatest(actionTypes.ON_DELETE_VALIDACION_MATERIA_PRIMA, onDeleteValidacionMateriaPrima)
}

export function * deleteSeleccionValidacionMateriaPrima ({list}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      let eliminarSeleccion = yield call(eliminarValidacionMateriaPrimaSeleccion, {list: list}, auth.token)
      if (eliminarSeleccion.eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(deSeleccionarTodosValidacionMateriaPrima())
      yield put(initialize('simpleTable'))
      let filtros = yield select(state => state.validacionMateriaPrima.filtros)
      const lista = yield call(filtrosValidacionMateriaPrima, filtros, auth.token)
      yield put(filtrarValidacionMateriaPrimaSuccess(lista))
      yield put(filtrosValidacionMateriaPrimaSuccess(filtros))
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchDeleteSeleccionValidacionMateriaPrima () {
  yield takeLatest(actionTypes.DELETE_SELECCION_VALIDACION_MATERIA_PRIMA, deleteSeleccionValidacionMateriaPrima)
}

export function * crearCsvValidacionMateriaPrima ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosExcelValidacionMateriaPrima = yield call(getCrearCsv, list, auth.token)
    yield put(crearCsvValidacionMateriaPrimaSuccess(datosExcelValidacionMateriaPrima))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchCrearCsvValidacionMateriaPrima () {
  yield takeLatest(actionTypes.CREATE_CSV_VALIDACION_MATERIA_PRIMA, crearCsvValidacionMateriaPrima)
}
export function * imprimirPdfValidacionMateriaPrima ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfValidacionMateriaPrima = yield call(getImprimirPdfValidacionMateriaPrima, list, auth.token)
    yield put(imprimirPdfValidacionMateriaPrimaSuccess(datosPdfValidacionMateriaPrima))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchImprimirPdfValidacionMateriaPrima () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_VALIDACION_MATERIA_PRIMA, imprimirPdfValidacionMateriaPrima)
}

export function * guardarYnuevoValidacionMateriaPrima ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const list = yield call(addValidacionMateriaPrima, values, auth.token)
    if (list.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-insercion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    yield put(initialize('ModalValidacionMateriaPrima', {}))
    yield put(resetValidacionMateriaPrima())
    let filtros = yield select(state => state.validacionMateriaPrima.filtros)
    const lista = yield call(filtrosValidacionMateriaPrima, filtros, auth.token)
    yield put(filtrarValidacionMateriaPrimaSuccess(lista))
    yield put(filtrosValidacionMateriaPrimaSuccess(filtros))
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
  }
}
export function * watchGuardarYnuevoValidacionMateriaPrima () {
  yield takeLatest(actionTypes.GUARDAR_Y_NUEVO_VALIDACION_MATERIA_PRIMA, guardarYnuevoValidacionMateriaPrima)
}

export function * editarVerracoMateriaPrima ({values}) {
  try {
    let verracoPoolState = yield select(state => state.validacionMateriaPrima.listVerracoPool)
    const verracoRecuperar = verracoPoolState.filter((verraco) => {
      if (verraco.verraco !== null) {
        if (verraco.idElemento === values.idElemento){
          if (values.verracoPool.value === 1) {
            verraco.verracoPool = values.verracoPool
            verraco.motilidad = values.motilidad
            verraco.aglutinacion = values.aglutinacion
            verraco.motilidadControl = values.motilidadControl
            verraco.aglutinacionControl = values.aglutinacionControl
            verraco.fecha = values.fecha
            verraco.idElemento = values.idElemento
            verraco.verraco = values.verraco.label
            verraco.idAnalisis = values.idAnalisis
          }
        }
        return verraco
      }
    })
    yield put(editarVerracoMateriaPrimaSuccess(verracoRecuperar))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchEditarVerracoMateriaPrima () {
  yield takeLatest(actionTypes.EDITAR_VERRACO_MATERIA_PRIMA, editarVerracoMateriaPrima )
}

export function * editarPoolMateriaPrima ({values}) {
  try {
    let verracoPoolState = yield select(state => state.validacionMateriaPrima.listVerracoPool)
    const poolRecuperar = verracoPoolState.filter((pool) => {
      if (pool.pool !== null) {
        if (pool.idElemento === values.idElemento){
          if (values.verracoPool.value === 2) {
            pool.verracoPool = values.verracoPool
            pool.motilidad = values.motilidad
            pool.aglutinacion = values.aglutinacion
            pool.motilidadControl = values.motilidadControl
            pool.aglutinacionControl = values.aglutinacionControl
            pool.fecha = values.fecha
            pool.idElemento = values.idElemento
            pool.pool = values.pool.label
            pool.idPool = values.idPool
          }
        }
        return pool
      }
    })
    yield put(editarPoolMateriaPrimaSuccess(poolRecuperar))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchEditarPoolMateriaPrima () {
  yield takeLatest(actionTypes.EDITAR_POOL_MATERIA_PRIMA, editarPoolMateriaPrima )
}
