import React, { Component } from 'react'
import { Button as BootstrapButton } from 'react-bootstrap'
import Loader from '../loader/Loader'
import './Button.scss'
import './ButtonPrimary.scss'
import './ButtonWhite.scss'

class ButtonLockable extends Component {
  constructor() {
    super()
    this.state = {
      clicked: false
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.processing && !this.props.processing && this.state.clicked) {
      this.setState({clicked: false})
    }
  }

  render () {
    const {
      forwardRef, processing = false, disabled = false, hasLoader = true, hasIcon = false, replaceChild = false,
      showLoaderOnClick = false, onClick = null
    } = this.props
    const cleanedProps = {...this.props}
    let children = this.props.children

    if (processing) {
      cleanedProps.disabled = true
      cleanedProps.className += ' disabled' + (hasIcon ? ' btn-icono' : '')

      if (replaceChild) {
        children = children.filter((child) => {
          return !(child && child.props && child.props.name === replaceChild)
        })
      }
    } else if (disabled) {
      cleanedProps.className += ' disabled' + (hasIcon ? ' btn-icono' : '')
    }

    if (showLoaderOnClick) {
      cleanedProps.onClick = () => {
        onClick()
        this.setState({clicked: true})
      }
    }

    // Eliminada debido al aviso de propiedad no reconocida.
    delete cleanedProps.forwardRef
    delete cleanedProps.processing
    delete cleanedProps.hasLoader
    delete cleanedProps.hasIcon
    delete cleanedProps.replaceChild
    delete cleanedProps.showLoaderOnClick

    return (
      <BootstrapButton {...cleanedProps} ref={ref => { forwardRef && forwardRef(ref) }} >
        {processing && hasLoader && (!showLoaderOnClick || this.state.clicked) && (
          <Loader />
        )}
        {children}
      </BootstrapButton>
    )
  }
}
export default ButtonLockable
