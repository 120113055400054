import React, { Component } from 'react'
import {reduxForm, Field, Fields} from 'redux-form'
import { Row, Col} from 'react-bootstrap'
import InputSelect from '../comun/form/InputSelect'
import InputNumerical from '../comun/form/InputNumerical'
import InputCheckBox from '../comun/form/InputCheckBox'
import Button from '../comun/button/Button'
import { DoneAll, Refresh, Delete, Launch, Print, AddCircleOutline, FilterList } from '@material-ui/icons'
import {date_formatter} from '../../util/formatFunctions'

class FiltrosFoso extends Component {
   constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelected: false,
      fitrosSeleccionados: []
    }
  }

  UNSAFE_componentWillMount () {
    this.props.actions.comboVerraco({}, true)
    this.props.actions.comboRaza()
    this.props.actions.comboLineaGenetica()
    this.props.actions.comboUbicacionPadre()
    this.props.actions.comboNombreGrupoVerraco()
    this.props.actions.comboIndex()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.datosExcelFoso !== prevProps.datosExcelFoso) {
      if (this.props.datosExcelFoso.excel && this.props.datosExcelFoso.excel !== null){
        let pdf = this.props.datosExcelFoso.excel
        let element = document.createElement('a')
        // element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('href', 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.EXTRACCIONES.FOSO') + ".xlsx")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
    if (this.props.datosPdfFoso !== prevProps.datosPdfFoso) {
      if (this.props.datosPdfFoso.pdf && this.props.datosPdfFoso.pdf !== null){
        let pdf = this.props.datosPdfFoso.pdf
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.EXTRACCIONES.FOSO') + ".pdf")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
  }

  fetchFiltrarFoso (values) {
    this.props.actions.fetchFiltrarFoso(values)
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  crearCsvFoso () {
    this.props.actions.crearCsvFoso(this.props.filtros)
  }

  imprimirPdfFoso () {
    this.props.actions.imprimirPdfFoso(this.props.filtros)
  }

  intercambiarCheck (value) {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosFoso()
    } else {
      this.props.actions.deseleccionarTodosFoso()
    }
  }

  deleteSeleccionFoso () {
    /*const itemsSeleccion = this.props.listFoso.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idElemento': row.idElemento,
          'idPedido': row.idPedido
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true
    });
    this.props.actions.deleteSeleccionFoso(list)
    this.props.change('seleccionar-todo', false)*/
  }

  quitarFiltros (value) {
    let values = this.props.filtros
    values[value] = null
    this.props.actions.fetchFiltrarFoso(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }

  render () {
  const {
    t, handleSubmit, filtros,
    actions: {abrirModalFoso},
    combos: {comboVerraco, comboRaza, comboUbicacionPadre, comboLineaGenetica, comboNombreGrupoVerraco}
  } = this.props

  let comboEstadoPlaning = this.props.combos.comboEstadoPlaning
  comboEstadoPlaning = comboEstadoPlaning.map((comboEstado) => {
    return {...comboEstado, label: t(comboEstado.label)}
  })

  let comboVerracoPrevisto = this.props.combos.comboVerracoPrevisto
  comboVerracoPrevisto = comboVerracoPrevisto.map((comboPrevisto) => {
    return {...comboPrevisto, label: t(comboPrevisto.label)}
  })

  const comboNumeroDiasSinExtraer = [
    {value: 1, label: 1},{value: 2, label: 2},{value: 3, label: 3},{value: 4, label: 4},{value: 5, label: 5},{value: 6, label: 6},{value: 7, label: 7},{value: 8, label: 8},
    {value: 9, label: 9},{value: 10, label: 10},
    {value: 11, label: 11},{value: 12, label: 12},{value: 13, label: 13},{value: 14, label: 14},{value: 15, label: 15},{value: 16, label: 16},{value: 17, label: 17},{value: 18, label: 18},
    {value: 19, label: 19},{value: 20, label: 20},
    {value: 21, label: 21},{value: 22, label: 22},{value: 23, label: 23},{value: 24, label: 24},{value: 25, label: 25},{value: 26, label: 26},{value: 27, label: 27},{value: 28, label: 28},
    {value: 29, label: 29},{value: 30, label: 30}
  ]

  return (
      <div>
        <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <form className="custom-form">
            <Field
              colSm={2}
              id="verraco"
              name="verraco"
              controlLabel={t('FOSO.FILTROS.VERRACO')}
              component={InputSelect}
              options={comboVerraco}
              valueKey="value"
              labelKey="label"
              customClass='verraco-foso'
              onInputChange={handleSubmit(this.fetchFiltrarFoso.bind(this))}
            />
            <Field
              colSm={2}
              id="raza"
              name="raza"
              controlLabel={t('PLANING.FILTROS.RAZA')}
              component={InputSelect}
              options={comboRaza}
              valueKey="value"
              labelKey="label"
              customClass='nombre-operario'
              onInputChange={handleSubmit(this.fetchFiltrarFoso.bind(this))}
            />
            <Field
              colSm={2}
              id="ubicacion"
              name="ubicacion"
              controlLabel={t('PLANING.FILTROS.UBICACION')}
              component={InputSelect}
              options={comboUbicacionPadre}
              valueKey="value"
              labelKey="label"
              customClass='nombre-operario'
              onInputChange={handleSubmit(this.fetchFiltrarFoso.bind(this))}
            />
            <Field
              colSm={2}
              id="lineaGenetica"
              name="lineaGenetica"
              controlLabel={t('PLANING.FILTROS.LIN_GENETICA')}
              component={InputSelect}
              options={comboLineaGenetica}
              valueKey="value"
              labelKey="label"
              customClass='nombre-operario'
              onInputChange={handleSubmit(this.fetchFiltrarFoso.bind(this))}
            />
            <Field
              colSm={2}
              id="grupoVerraco"
              name="grupoVerraco"
              controlLabel={t('PLANING.FILTROS.GRUPO_VERRACO')}
              component={InputSelect}
              options={comboNombreGrupoVerraco}
              valueKey="value"
              labelKey="label"
              customClass='nombre-operario'
              onInputChange={handleSubmit(this.fetchFiltrarFoso.bind(this))}
            />
            <Field
              colSm={2}
              id="estado"
              name="estado"
              controlLabel={t('PLANING.FILTROS.ESTADO')}
              component={InputSelect}
              options={comboEstadoPlaning}
              valueKey="value"
              labelKey="label"
              customClass='nombre-operario'
              onInputChange={handleSubmit(this.fetchFiltrarFoso.bind(this))}
            />
            <Field
              colSm={2}
              id="numDiasSinExtraer"
              name="numDiasSinExtraer"
              controlLabel={t('PLANING.FILTROS.NUM_DIAS_SIN_EXTRAER')}
              component={InputSelect}
              options={comboNumeroDiasSinExtraer}
              valueKey="value"
              labelKey="label"
              customClass='nombre-operario'
              onInputChange={handleSubmit(this.fetchFiltrarFoso.bind(this))}
            />
            <Field
              colSm={2}
              id="ebv"
              name="ebv"
              controlLabel={t('PLANING.FILTROS.EBV')}
              component={InputNumerical}
              customClass='nombre-operario'
              numDecimales={0}
              onInputChange={handleSubmit(this.fetchFiltrarFoso.bind(this))}
            />
            <Field
              colSm={2}
              id="previsto"
              name="previsto"
              controlLabel={t('FOSO.FILTROS.PREVISTO')}
              component={InputSelect}
              options={comboVerracoPrevisto}
              valueKey="value"
              labelKey="label"
              customClass='nombre-operario'
              onInputChange={handleSubmit(this.fetchFiltrarFoso.bind(this))}
            />
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.verraco && filtros.verraco !== null ? (
                      <span className="badge-dark">{t('FOSO.FILTROS.VERRACO') + ': ' + filtros.verraco.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('verraco')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.raza && filtros.raza !== null ? (
                      <span className="badge-dark">{t('PLANING.FILTROS.RAZA') + ': ' + filtros.raza.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('raza')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.ubicacion && filtros.ubicacion !== null ? (
                      <span className="badge-dark">{t('PLANING.FILTROS.UBICACION') + ': ' + filtros.ubicacion.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('ubicacion')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.lineaGenetica && filtros.lineaGenetica !== null ? (
                      <span className="badge-dark">{t('PLANING.FILTROS.LIN_GENETICA') + ': ' + filtros.lineaGenetica.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('lineaGenetica')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.grupoVerraco && filtros.grupoVerraco !== null ? (
                      <span className="badge-dark">{t('PLANING.FILTROS.GRUPO_VERRACO') + ': ' + filtros.grupoVerraco.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('grupoVerraco')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.estado && filtros.estado !== null ? (
                      <span className="badge-dark">{t('PLANING.FILTROS.ESTADO') + ': ' + filtros.estado.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('estado')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.numDiasSinExtraer && filtros.numDiasSinExtraer !== null ? (
                      <span className="badge-dark">{t('PLANING.FILTROS.NUM_DIAS_SIN_EXTRAER') + ': ' + filtros.numDiasSinExtraer.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('numDiasSinExtraer')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.ebv && filtros.ebv !== null ? (
                      <span className="badge-dark">{t('PLANING.FILTROS.EBV') + ': ' + filtros.ebv}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('ebv')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.previsto && filtros.previsto !== null ? (
                      <span className="badge-dark">{t('FOSO.FILTROS.PREVISTO') + ': ' + filtros.previsto.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('previsto')}> x </button></span>
                    ) : null
                  )
                }
              </div>
            </div>
          </form>
        </div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              {/*<div className="custom-check-w">
                <Field
                  id="seleccionar-todo"
                  name="seleccionar-todo"
                  colSm={12}
                  inline
                  component={InputCheckBox}
                  onInputChange={(value) => this.intercambiarCheck(value)}
                />
              </div>
              <div id="block-options" className={this.state.imputSelected === true ? 'button-group buttons-min block-options' : 'button-group buttons-min block-options oculta-action'}>
                <button type="button" data-toggle="modal" data-target="#delete-modal" className="btn btn-icono" onClick={(e) => this.deleteSeleccionFoso()}>
                  <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                </button>
                <div className="barra-buttons-min"></div>
              </div>*/}
              {/*<div className="button-group buttons-min">
                <button type="button" className="btn btn-icono" onClick={(e) => this.crearCsvFoso()}>
                  <Launch></Launch> {t('TITULOS_BOTONES.EXPORTAR')}
                </button>
                <button type="button" className="btn btn-icono" onClick={(e) => this.imprimirPdfFoso()}>
                  <Print></Print> {t('TITULOS_BOTONES.IMPRIMIR')}
                </button>
                <button type="button" data-toggle="modal" data-target="#new-edit-modal" className="btn btn-primary nuevo" onClick={abrirModalFoso}>
                  <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                </button>
              </div>*/}
            </div>
          </Col>
          <Col sm={2} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'} onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t('TITULOS_BOTONES.FILTROS')}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosFoso',
})(FiltrosFoso))
