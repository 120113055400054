import React, { Component } from 'react'
import {reduxForm, Field, Fields} from 'redux-form'
import { Row, Col, Modal} from 'react-bootstrap'
import { required } from '../../util/validationFunctions'
import InputNumerical from '../comun/form/InputNumerical'
import Button from '../comun/button/Button'
import ButtonLockable from '../comun/button/ButtonLockable'
import ButtonChangeRecord from '../comun/button/ButtonChangeRecord'
import InputTextArea from '../comun/form/InputTextArea'
import InputText from '../comun/form/InputText'
import InputSelect from '../comun/form/InputSelect'
import InputDatePicker from '../comun/form/InputDatePicker'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputRangeDatePickerDynamicNames from '../comun/form/InputRangeDatePickerDynamicNames'
import { DoneAll, Refresh, Delete, Launch, Print, AddCircleOutline, FilterList, Warning } from '@material-ui/icons'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import './PedidosVenta.scss'
import FiltrosModalPedidosPage from '../../containers/pedidosVenta/FiltrosModalPedidosPage'
import ModalDatosDosisPage from '../../containers/pedidosVenta/ModalDatosDosisPage'
import ModalAlbaranPage from '../../containers/albaran/ModalAlbaranPage'
import {permisos as permisosConstant} from '../../constants/permisos'
import {dateTimeZones} from '../../util/formatFunctions'

class ModalPedidosVenta extends Component {
   constructor () {
    super()
    this.state = {
      classErrorVerracos: false,
      imputSelected: false,
      textoValorTotalDosis: '',
      errorLineaPedido: false,
      estadoPedido: 1,
      numeroAlbaran: null,
      estadoAlbaran: null,
      idEstadoAlbaran: null,
      idAlbaran: null,
      hayCliente: null,
      alertaDuplicar: '',
      alertaPeriodo: '',
      alertaDias: ''
    }
  }

  componentDidMount () {
    this.props.actions.comboClienteActivo()
    this.props.actions.comboOperarioPredeterminado()
    this.props.change('fecha', dateTimeZones(this.props.auth.zonaHorariaString))
    this.props.change('estado', this.props.t('COMUN.COMBOS.COMBO_ESTADO_PEDIDO.BORRADOR'))
    this.props.actions.obtenerNumeroPedido()
  }

  componentDidUpdate (prevProps) {
    if (this.props.listPedidosAnadidos !== prevProps.listPedidosAnadidos){
      if (Object.keys(this.props.listPedidosAnadidos).length > 0){
        this.setState({errorLineaPedido: false})
        let totalDosis = 0
        this.props.listPedidosAnadidos.forEach( function(row){
          if (row.numeroDosis){
            totalDosis += parseFloat(row.numeroDosis)
          }
        })
        this.props.change('numeroDosis', totalDosis)
        if (totalDosis === 0) {
          this.setState({textoValorTotalDosis: true})
        } else {
          this.setState({textoValorTotalDosis: false})
        }
      }
    }
    if (this.props.data !== prevProps.data) {
      if (Object.keys(this.props.data).length > 0) {
        if (Object.keys(this.props.data.pedido).length > 0) {
          this.setState({estadoPedido: this.props.data.pedido.idEstado})
          this.props.change('idDireccionCliente', this.props.data.idDireccionCliente)
          this.setState({numeroAlbaran: this.props.data.pedido.numeroAlbaran})
          this.setState({estadoAlbaran: this.props.data.pedido.estadoAlbaran})
          this.setState({idEstadoAlbaran: this.props.data.pedido.idEstadoAlbaran})
          this.setState({idAlbaran: this.props.data.pedido.idAlbaran ? this.props.data.pedido.idAlbaran : null})
          this.setState({hayCliente: this.props.data.pedido.idCliente ? this.props.data.pedido.idCliente : null})
        }
      }
    }
   if (this.props.dataDuplicar !== prevProps.dataDuplicar) {
      if (Object.keys(this.props.dataDuplicar).length > 0) {
        if (Object.keys(this.props.dataDuplicar.pedido).length > 0) {
          this.props.change('estado', this.props.t('COMUN.COMBOS.COMBO_ESTADO_PEDIDO.BORRADOR'))
          this.props.actions.obtenerNumeroPedido()
        }
      }
    }

    if (Object.keys(this.props.dataDuplicar).length > 0) {
      if (this.props.formModalPedidosVenta !== prevProps.formModalPedidosVenta){
        if (this.props.formModalPedidosVenta.submitFailed === true && !this.props.formModalPedidosVenta.values.fechaEntrada && !this.props.formModalPedidosVenta.values.fechaSalida) {
          this.setState({alertaPeriodo: true})
        } else {
          this.setState({alertaPeriodo: false})
        }

        if (this.props.formModalPedidosVenta.submitFailed === true && !this.props.formModalPedidosVenta.values.fechaEntrada) {
          this.setState({alertaPeriodo: true})
        } else {
          this.setState({alertaPeriodo: false})
        }

        if (this.props.formModalPedidosVenta.submitFailed === true && !this.props.formModalPedidosVenta.values.fechaSalida) {
          this.setState({alertaPeriodo: true})
        } else {
          this.setState({alertaPeriodo: false})
        }

        if (this.state.alertaDuplicar === true && (this.props.formModalPedidosVenta.values.lunes === false && this.props.formModalPedidosVenta.values.martes === false && this.props.formModalPedidosVenta.values.miercoles === false && this.props.formModalPedidosVenta.values.jueves === false && this.props.formModalPedidosVenta.values.viernes === false && this.props.formModalPedidosVenta.values.sabado === false && this.props.formModalPedidosVenta.values.domingo === false)) {
          this.setState({alertaDias: true})
        } else {
          this.setState({alertaDias: false})
        }
      }
    }

    if (this.props.comboDireccionesCliente !== prevProps.comboDireccionesCliente) {
      if (Object.keys(this.props.comboDireccionesCliente).length > 0) {
        let direccionPredeterminada = this.props.comboDireccionesCliente.filter((direccion) => {
          if (direccion.predeterminada === true) {
            return direccion
          }
        })
        if (Object.keys(direccionPredeterminada).length > 0) {
          this.props.change('idDireccionCliente', direccionPredeterminada)
        } else {
          this.props.change('idDireccionCliente', null)
        }
      } else {
        this.props.change('idDireccionCliente', null)
      }
    }

    if (this.props.comboRutaEnPedido !== prevProps.comboRutaEnPedido) {
      if (Object.keys(this.props.comboRutaEnPedido).length > 0) {
        this.props.change('ruta', this.props.comboRutaEnPedido[0])
      }
    }

    if (this.props.showModalPedidosVenta !== prevProps.showModalPedidosVenta && Object.keys(this.props.data).length === 0  && Object.keys(this.props.dataDuplicar).length === 0) {
      this.props.actions.obtenerNumeroPedido()
      this.props.change('numeroPedido', this.props.numeroPedido)
      this.props.change('estado', this.props.t('COMUN.COMBOS.COMBO_ESTADO_PEDIDO.BORRADOR'))
    }
  }

  cerrarModalPedidosVenta () {
    this.props.actions.cerrarModalPedidosVenta()
    let fecha = dateTimeZones(this.props.auth.zonaHorariaString)
    this.props.initialize({fecha: fecha})
    this.setState({textoValorTotalDosis: false})
    this.setState({errorLineaPedido: false})
    this.setState({estadoPedido: 1})
    this.setState({numeroAlbaran: null})
    this.setState({estadoAlbaran: null})
    this.setState({idEstadoAlbaran: null})
    this.setState({idAlbaran: null})
    this.setState({hayCliente: null})
  }

  anadirPedidosVenta (values) {
    return new Promise((resolve, reject) => {
      values.idEstado = 1
      values.lineaPedido = this.props.listPedidosAnadidos
      let fecha = dateTimeZones(this.props.auth.zonaHorariaString)
      if (Object.keys(this.props.listPedidosAnadidos).length > 0){
        if (this.state.textoValorTotalDosis === false) {
          if (Object.keys(this.props.data).length > 0){
            this.props.actions.editarPedidosVenta(values, resolve, reject)
            this.props.initialize({fecha: fecha})
            this.setState({textoValorTotalDosis: false})
          } else if (Object.keys(this.props.dataDuplicar).length > 0){
            // if (this.state.alertaDuplicar === false && this.state.alertaPeriodo === false && this.state.alertaDias === false) {
            if (values.fechaEntrada && values.fechaSalida && (values.lunes === true || values.martes === true || values.miercoles === true || values.jueves === true || values.viernes === true || values.sabado === true || values.domingo === true)){
              this.setState({alertaDuplicar: false})
              this.props.actions.duplicarPedidoVenta(values, resolve, reject)
              this.props.initialize({fecha: fecha})
              this.setState({textoValorTotalDosis: false})
            } else {
              this.setState({alertaDuplicar: true})
              if (this.props.formModalPedidosVenta.values.lunes === false && this.props.formModalPedidosVenta.values.martes === false && this.props.formModalPedidosVenta.values.miercoles === false && this.props.formModalPedidosVenta.values.jueves === false && this.props.formModalPedidosVenta.values.viernes === false && this.props.formModalPedidosVenta.values.sabado === false && this.props.formModalPedidosVenta.values.domingo === false) {
                this.setState({alertaDias: true})
              } else {
                this.setState({alertaDias: false})
              }
              reject()
            }
          } else {
            this.props.actions.anadirPedidosVenta(values, resolve, reject)
            this.props.initialize({fecha: fecha})
            this.setState({textoValorTotalDosis: false})
          }
        } else {
          reject()
        }
        this.setState({errorLineaPedido: false})
      } else {
        this.setState({errorLineaPedido: true})
        reject()
      }
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  elegirClientePedidoVenta (values) {
    this.setState({hayCliente: values.value})
    this.props.actions.elegirClientePedidoVenta(values.value)
  }

  elegirDireccionClientePedidoVenta (values) {
    this.props.actions.elegirDireccionClientePedidoVenta(values.value)
  }

  cancelarAnadirPedidosVenta (values){
    return new Promise((resolve, reject) => {
      values.idEstado = 2
      values.lineaPedido = this.props.listPedidosAnadidos
      let fecha = dateTimeZones(this.props.auth.zonaHorariaString)
      if (Object.keys(this.props.listPedidosAnadidos).length > 0){
        if (this.state.textoValorTotalDosis === false) {
          if (Object.keys(this.props.data).length > 0){
            this.props.actions.editarPedidosVenta(values, resolve, reject)
            this.props.initialize({fecha: fecha})
            this.setState({textoValorTotalDosis: false})
          } else {
            this.props.actions.anadirPedidosVenta(values, resolve, reject)
            this.props.initialize({fecha: fecha})
            this.setState({textoValorTotalDosis: false})
          }
        } else {
          reject()
        }
        this.setState({errorLineaPedido: false})
      } else {
        this.setState({errorLineaPedido: true})
        reject()
      }
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }
  confirmarAnadirPedidosVenta (values){
    return new Promise((resolve, reject) => {
      values.idEstado = 3
      values.lineaPedido = this.props.listPedidosAnadidos
      let fecha = dateTimeZones(this.props.auth.zonaHorariaString)
      if (Object.keys(this.props.listPedidosAnadidos).length > 0){
        if (this.state.textoValorTotalDosis === false) {
          if (Object.keys(this.props.data).length > 0){
            this.props.actions.editarPedidosVenta(values, resolve, reject)
            this.props.initialize({fecha: fecha})
            this.setState({textoValorTotalDosis: false})
          } else {
            this.props.actions.anadirPedidosVenta(values, resolve, reject)
            this.props.initialize({fecha: fecha})
            this.setState({textoValorTotalDosis: false})
          }
        } else {
          reject()
        }
        this.setState({errorLineaPedido: false})
      } else {
        this.setState({errorLineaPedido: true})
        reject()
      }
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  validarCantidadDosis (value, prevVal) {
    if (parseInt(prevVal, 10) <=0 || prevVal === ''){
      this.setState({textoValorTotalDosis: true})
    } else {
      this.setState({textoValorTotalDosis: false})
    }
  }

  abrirModalAlbaran () {
    this.props.actions.abrirDetallesAlbaran(this.state.idAlbaran)
  }

  confirmarYnuevoPedidosVenta (values) {
    return new Promise((resolve, reject) => {
      values.idEstado = 3
      values.lineaPedido = this.props.listPedidosAnadidos
      let fecha = dateTimeZones(this.props.auth.zonaHorariaString)
      if (Object.keys(this.props.listPedidosAnadidos).length > 0){
        if (this.state.textoValorTotalDosis === false) {
          this.props.actions.confirmarYnuevoPedidosVenta(values, resolve, reject)
          this.props.initialize({fecha: fecha})
          this.setState({textoValorTotalDosis: false})
        } else {
          reject()
        }
        this.setState({errorLineaPedido: false})
      } else {
        this.setState({errorLineaPedido: true})
        reject()
      }
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  comprobarSeleccionDia (values) {
    if (values.fechaEntrada && values.fechaSalida && (values.lunes === true || values.martes === true || values.miercoles === true || values.jueves === true || values.viernes === true || values.sabado === true || values.domingo === true)){
      this.setState({alertaDuplicar: false})
    }
  }

  render () {
    const {
      t, handleSubmit, submitting, listPedidosVenta, showModalPedidosVenta, numeroRegistrosPorPagina, paginaActual, listPedidosAnadidos, checkedModalPedidos, dataPedidosVenta, dataPedidosVentaDuplicar,
      comboDireccionesCliente, data, dataDuplicar, comboRutaEnPedido,
      actions: {recuperarDatosModalPedidosVenta, recuperarDatosModalDatosDosis, duplicarDatosModalDatosDosis, onDeleteDatosDosis},
      combos:{comboClienteActivo, comboOperarioPredeterminado}
    } = this.props

    listPedidosAnadidos.forEach(
      (row, idRow) => row.idElemento = idRow + 1
    )
    const tablePedidos = {
      id: 'modalPedidosVenta',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.PEDIDO] > 1) && this.state.estadoPedido !== 2 && this.state.estadoPedido !== 3 && this.state.estadoPedido !== 4 && !submitting ? true : false,
      hasOptions: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.PEDIDO] > 1) && this.state.estadoPedido !== 2 && this.state.estadoPedido !== 3 && this.state.estadoPedido !== 4 && !submitting ? true : false,
      hasPagination: true,
      filtros: !submitting ? <FiltrosModalPedidosPage estadoPedido={this.state.estadoPedido}  hayCliente={this.state.hayCliente} /> : null,
      mostrarCambioEstado: false,
      mostrarModificar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.PEDIDO] > 1) && this.state.estadoPedido !== 2 && this.state.estadoPedido !== 3 && this.state.estadoPedido !== 4 && !submitting ? true : false,
      tablaModal: true,
      idBlockOptions: 1,
      columns: [
        {id: 'verraco', name: t('PEDIDOS.PEDIDOS_VENTA.MODAL.TABLA.VERRACO')},
        {id: 'raza', name: t('PEDIDOS.PEDIDOS_VENTA.MODAL.TABLA.RAZA')},
        {id: 'lineaGenetica', name: t('PEDIDOS.PEDIDOS_VENTA.MODAL.TABLA.LINEA_GENETICA')},
        {id: 'tipoDosis', name: t('PEDIDOS.PEDIDOS_VENTA.MODAL.TABLA.TIPO_DOSIS')},
        {id: 'numeroDosis', name: t('PEDIDOS.PEDIDOS_VENTA.MODAL.TABLA.CANTIDAD'), type: 'numero'},
      ],
      rows: listPedidosAnadidos,
      onEdit: (row) => !submitting && recuperarDatosModalDatosDosis(row.idElemento),
      data: dataPedidosVenta,
      mostrarDuplicar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.PEDIDO] > 1),
      duplicar: (row) =>duplicarDatosModalDatosDosis(row.idElemento),
      dataDuplicar: dataPedidosVentaDuplicar,
      mostrarEliminar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.PEDIDO] > 1),
      onDelete: (row) => onDeleteDatosDosis(row.idElemento),
      showModal: this.props.showModalDatosDosis,
      initialValues: {}
    }
    tablePedidos.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tablePedidos.initialValues['check' + row.idElemento] = checkedModalPedidos
        }
      }
    )

  return (
    <div>
      <ModalDatosDosisPage
        duplicar={(row) =>duplicarDatosModalDatosDosis(row.idElemento)}
        cliente={this.state.hayCliente}
      />
      <ModalAlbaranPage hasButtonChangeRecord={false}/>
        <Modal show={showModalPedidosVenta} onHide={() => this.cerrarModalPedidosVenta()} bsSize="large" dialogClassName="xl" backdrop="static" aria-labelledby="contained-modal-title-lg">
          <Modal.Header closeButton={!submitting}>
            <Modal.Title>
            {
              Object.keys(data).length > 0 ? (
                t('PEDIDOS.PEDIDOS_VENTA.MODAL.TITLE_MODIFICAR')
              ) : (
                Object.keys(dataDuplicar).length > 0 ? (
                  t('PEDIDOS.PEDIDOS_VENTA.MODAL.TITLE_DUPLICAR')
                ) : (
                  t('PEDIDOS.PEDIDOS_VENTA.MODAL.TITLE_NUEVO')
                )
              )
            }
            </Modal.Title>
            {!submitting && data && data.pedido && data.pedido.idPedido && (
              <ButtonChangeRecord list={listPedidosVenta} idElemento="idPedido" currentId={data.pedido.idPedido} getNextRecord={recuperarDatosModalPedidosVenta} />
            )}
          </Modal.Header>
          <Modal.Body>
            <Row>
              {
                Object.keys(dataDuplicar).length > 0 && (
                  <div className="dias-semana">
                    <Col sm={12}><p className={this.state.alertaPeriodo === true ? ('alerta-planing-sin-verracos') : null}><b>{t('PLANING.MODAL.PERIODO_FECHAS') + '*'}</b>{this.state.alertaPeriodo === true ? <Warning/> : null}</p></Col>
                    <Fields
                      names={['fechaEntrada', 'fechaSalida']}
                      component={InputRangeDatePickerDynamicNames}
                      colSm={4}
                      nameFrom="fechaEntrada"
                      nameTo="fechaSalida"
                      validate={required}
                      noIntro={true}
                    />
                    <div className="clearfix"></div>
                    <Col sm={12}><p className={this.state.alertaDias === true ? ('alerta-planing-sin-verracos') : null}><b>{t('PLANING.MODAL.DIAS') + '*'}</b>{this.state.alertaDias === true ? <Warning/> : null}</p></Col>
                    <Field
                      id="lunes"
                      name="lunes"
                      inline
                      component={InputCheckBox}
                      claseActivo="onoffswitch-label"
                      controlLabel={t('COMUN.DATE.WEEKDAYS_LONG.LUNES')}
                      colSm={2}
                      onInputChange={handleSubmit(this.comprobarSeleccionDia.bind(this))}
                    />
                    <Field
                      id="martes"
                      name="martes"
                      inline
                      component={InputCheckBox}
                      claseActivo="onoffswitch-label"
                      controlLabel={t('COMUN.DATE.WEEKDAYS_LONG.MARTES')}
                      colSm={2}
                      onInputChange={handleSubmit(this.comprobarSeleccionDia.bind(this))}
                    />
                    <Field
                      id="miercoles"
                      name="miercoles"
                      inline
                      component={InputCheckBox}
                      claseActivo="onoffswitch-label"
                      controlLabel={t('COMUN.DATE.WEEKDAYS_LONG.MIERCOLES')}
                      colSm={2}
                      onInputChange={handleSubmit(this.comprobarSeleccionDia.bind(this))}
                    />
                    <Field
                      id="jueves"
                      name="jueves"
                      inline
                      component={InputCheckBox}
                      claseActivo="onoffswitch-label"
                      controlLabel={t('COMUN.DATE.WEEKDAYS_LONG.JUEVES')}
                      colSm={2}
                      onInputChange={handleSubmit(this.comprobarSeleccionDia.bind(this))}
                    />
                    <Field
                      id="viernes"
                      name="viernes"
                      inline
                      component={InputCheckBox}
                      claseActivo="onoffswitch-label"
                      controlLabel={t('COMUN.DATE.WEEKDAYS_LONG.VIERNES')}
                      colSm={2}
                      onInputChange={handleSubmit(this.comprobarSeleccionDia.bind(this))}
                    />
                    <Field
                      id="sabado"
                      name="sabado"
                      inline
                      component={InputCheckBox}
                      claseActivo="onoffswitch-label"
                      controlLabel={t('COMUN.DATE.WEEKDAYS_LONG.SABADO')}
                      colSm={2}
                      onInputChange={handleSubmit(this.comprobarSeleccionDia.bind(this))}
                    />
                    <Field
                      id="domingo"
                      name="domingo"
                      inline
                      component={InputCheckBox}
                      claseActivo="onoffswitch-label"
                      controlLabel={t('COMUN.DATE.WEEKDAYS_LONG.DOMINGO')}
                      colSm={2}
                      onInputChange={handleSubmit(this.comprobarSeleccionDia.bind(this))}
                    />
                    <div className="clearfix"></div>
                    <hr />
                  </div>
                )
              }
              <Field
                colSm={4}
                id="numeroPedido"
                name="numeroPedido"
                controlLabel={t('PEDIDOS.PEDIDOS_VENTA.MODAL.FILTROS.NUM_PEDIDO')}
                component={InputText}
                disabled={true}
                maxLength={true}
                valorMaxLength={150}
              />
              <Field
                colSm={2}
                id="fecha"
                name="fecha"
                controlLabel={t('PEDIDOS.PEDIDOS_VENTA.MODAL.FILTROS.FECHA') + '*'}
                component={InputDatePicker}
                valueKey="value"
                labelKey="label"
                customClass='nombre-pedidos-venta'
                validate={required}
                disabled={this.state.estadoPedido === 2 || this.state.estadoPedido === 3 || this.state.estadoPedido === 4 ? true : false}
              />
              <Field
                colSm={4}
                id="operario"
                name="operario"
                controlLabel={t('PEDIDOS.PEDIDOS_VENTA.MODAL.FILTROS.OPERARIO')}
                component={InputSelect}
                options={comboOperarioPredeterminado}
                valueKey="value"
                labelKey="label"
                customClass='nombre-pedidos-venta'
                isClearable={false}
                disabled={this.state.estadoPedido === 2 || this.state.estadoPedido === 3 || this.state.estadoPedido === 4 ? true : false}
              />
              <div className="clearfix"></div>
              <Field
                colSm={4}
                id="cliente"
                name="cliente"
                controlLabel={t('PEDIDOS.PEDIDOS_VENTA.MODAL.FILTROS.CLIENTE') + '*'}
                component={InputSelect}
                options={comboClienteActivo}
                valueKey="value"
                labelKey="label"
                customClass='nombre-pedidos-venta'
                isClearable={false}
                onInputChange={(row) => this.elegirClientePedidoVenta(row)}
                validate={required}
                disabled={this.state.estadoPedido === 2 || this.state.estadoPedido === 3 || this.state.estadoPedido === 4 ? true : false}
              />
              <Field
                colSm={4}
                id="idDireccionCliente"
                name="idDireccionCliente"
                controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.GRANJA') + '*'}
                component={InputSelect}
                options={comboDireccionesCliente}
                valueKey="value"
                labelKey="label"
                disabled={this.state.estadoPedido === 2 || this.state.estadoPedido === 3 || this.state.estadoPedido === 4 ? true : false}
                customClass='nombre-pedidos-venta'
                validate={required}
                onInputChange={(row) => this.elegirDireccionClientePedidoVenta(row)}
                isClearable={false}
              />
              <Field
                colSm={2}
                id="ruta"
                name="ruta"
                controlLabel={t('PEDIDOS.PEDIDOS_VENTA.MODAL.FILTROS.RUTA')}
                component={InputSelect}
                options={comboRutaEnPedido}
                valueKey="value"
                labelKey="label"
                customClass='nombre-pedidos-venta'
                disabled={this.state.estadoPedido === 2 || this.state.estadoPedido === 3 || this.state.estadoPedido === 4 ? true : false}
                isClearable={false}
              />
              <Field
                colSm={2}
                id="numeroDosis"
                name="numeroDosis"
                component={InputNumerical}
                controlLabel={t('PEDIDOS.PEDIDOS_VENTA.MODAL.DOSIS_PEDIDAS')}
                customClass='dosisPedidas'
                textoValorTotalDosis={this.state.textoValorTotalDosis}
                onChange={(value, prevVal) => this.validarCantidadDosis(value, prevVal)}
                t={t}
                disabled={true}
                numDecimales={0}
              />
            </Row>
            <div className="clearfix"></div>
            <SimpleTablePage {...tablePedidos}></SimpleTablePage>
            {
              this.state.errorLineaPedido === true ? (
                <p className='error-linea-pedido'><Warning/>{t('PEDIDOS.PEDIDOS_VENTA.ERROR_LINEA_PEDIDO')}</p>
              ) : null
            }
            <Row>
              <Field
                colSm={12}
                id="observaciones"
                name="observaciones"
                component={InputTextArea}
                componentClass="textarea"
                controlLabel={t('PEDIDOS.PEDIDOS_VENTA.MODAL.OBSERVACIONES')}
                customClass='observaciones-pedidos-venta'
                disabled={this.state.estadoPedido === 2 || this.state.estadoPedido === 3 || this.state.estadoPedido === 4 ? true : false}
              />
              <Field
                colSm={2}
                id="estado"
                name="estado"
                component={InputText}
                controlLabel={t('PEDIDOS.PEDIDOS_VENTA.MODAL.ESTADO')}
                customClass='estado'
                disabled={true}
              />
              <div className="clearfix"></div>
              {
                this.state.estadoAlbaran !== null && (
                  <Col sm={12} className="info-albaran">
                    <span><b>{t('PEDIDOS.PEDIDOS_VENTA.MODAL.NUM_ALBARAN')}: </b>{this.state.numeroAlbaran}</span>
                    <span><b>{t('PEDIDOS.PEDIDOS_VENTA.MODAL.ESTADO_ALBARAN')}: </b>{this.state.estadoAlbaran}</span>
                  </Col>
                )
              }
            </Row>
            <div className="clearfix"></div>
          </Modal.Body>
          <Modal.Footer>
          {
            this.state.estadoPedido === 1 ? (
              <span style={{marginRight: '4px'}}>
              {
                Object.keys(dataDuplicar).length === 0 ? (
                  <span style={{marginRight: '4px'}}>
                    <ButtonLockable
                      processing={submitting}
                      hasIcon={true}
                      showLoaderOnClick={true}
                      type="button"
                      className="btn btn-primary"
                      disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.PEDIDO] < 2) || submitting}
                      onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.PEDIDO] > 1) && !submitting ? handleSubmit(this.confirmarYnuevoPedidosVenta.bind(this)) : undefined}
                    >
                      {t('PEDIDOS.PEDIDOS_VENTA.MODAL.CONFIRMAR_Y_NUEVO_PEDIDO')}
                    </ButtonLockable>
                    <ButtonLockable
                      processing={submitting}
                      hasIcon={true}
                      showLoaderOnClick={true}
                      type="button"
                      className="btn btn-primary"
                      disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.PEDIDO] < 2) || submitting}
                      onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.PEDIDO] > 1) && !submitting ? handleSubmit(this.confirmarAnadirPedidosVenta.bind(this)) : undefined}
                    >
                      {t('PEDIDOS.PEDIDOS_VENTA.MODAL.CONFIRMAR_PEDIDO')}
                    </ButtonLockable>
                  </span>
                ) : null
              }
              {
                Object.keys(this.props.data).length > 0 ? (
                  <ButtonLockable
                    processing={submitting}
                    hasIcon={true}
                    showLoaderOnClick={true}
                    type="button"
                    className="btn btn-primary"
                    disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.PEDIDO] < 2) || submitting}
                    onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.PEDIDO] > 1) && !submitting ? handleSubmit(this.cancelarAnadirPedidosVenta.bind(this)) : undefined}
                  >
                    {t('PEDIDOS.PEDIDOS_VENTA.MODAL.CANCELAR_PEDIDO')}
                  </ButtonLockable>
                ) : null
              }
              <ButtonLockable
                processing={submitting}
                hasIcon={true}
                showLoaderOnClick={true}
                type="button"
                className="btn btn-primary"
                disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.PEDIDO] < 2) || submitting}
                onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.PEDIDO] > 1) && !submitting ? handleSubmit(this.anadirPedidosVenta.bind(this)) : undefined}
              >{t('PEDIDOS.PEDIDOS_VENTA.MODAL.GUARDAR')}</ButtonLockable>
              </span>
            ) : null
          }
          {
            this.state.estadoPedido === 2 ? (
              <ButtonLockable
                processing={submitting}
                hasIcon={true}
                showLoaderOnClick={true}
                type="button"
                className="btn btn-primary"
                disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.PEDIDO] < 2) || submitting}
                onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.PEDIDO] > 1) && !submitting ? handleSubmit(this.anadirPedidosVenta.bind(this)) : undefined}
              >{t('PEDIDOS.PEDIDOS_VENTA.MODAL.BORRADOR')}</ButtonLockable>
            ) : null
          }
          {
            this.state.estadoPedido === 3 || this.state.estadoPedido === 4 ? (
              <span>
              <Button
                type="button"
                className={"btn btn-primary" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.ALBARANES] < 1) || submitting ? ' disabled' : '')}
                disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.ALBARANES] < 1) || submitting}
                onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ALBARANES] > 0) && !submitting ? () => this.abrirModalAlbaran() : undefined}
              >
                {t('PEDIDOS.PEDIDOS_VENTA.MODAL.ALBARAN')}
              </Button>
              {
                this.state.idEstadoAlbaran === null || this.state.idEstadoAlbaran === 2 ? (
                  <ButtonLockable
                    processing={submitting}
                    hasIcon={true}
                    showLoaderOnClick={true}
                    type="button"
                    className="btn btn-primary"
                    disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.PEDIDO] < 2) || submitting}
                    onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.PEDIDO] > 1) && !submitting ? handleSubmit(this.cancelarAnadirPedidosVenta.bind(this)) : undefined}
                  >{t('PEDIDOS.PEDIDOS_VENTA.MODAL.CANCELAR_PEDIDO')}</ButtonLockable>
                ) : null
              }
              </span>
            ) : null
          }
            <Button
              type="button"
              className={"btn btn-white" + (submitting ? ' disabled' : '')}
              disabled={submitting}
              style={{marginLeft: '2px'}}
              onClick={!submitting ? () => this.cerrarModalPedidosVenta() : null}
            >
              {t('PEDIDOS.PEDIDOS_VENTA.MODAL.CANCELAR')}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'ModalPedidosVenta',
})(ModalPedidosVenta))
