import {select, put, call, all, takeLatest} from 'redux-saga/effects'
import { delay } from 'redux-saga/effects'
import { initialize, reset, change, getFormValues } from 'redux-form';
import i18n from '../../i18n';
import {date_parser} from '../../util/formatFunctions'
import { obtenerNotificaconesUsuarioSuccess } from '../../actions/notificaciones/notificaciones'
import getNotificaciones from '../../services/notificaciones/getNotificaciones'

// Constants
import {
  colorTipoTareasAgenda, tipoFrecuencia as tipoFrecuenciaConstants,
  tipoFrecuenciaDiaria as tipoFrecuenciaDiariaConstants
} from '../../constants/agenda'
import actionTypes from '../../constants/actions/agenda/agenda'

// Actions
import {openSimpleModal, openMensajeAlerta} from '../../actions/common'
import {
  fetchAgendaSuccess, fetchTareasDiaSuccess, abrirModalAgendaSuccess, guardarModalAgendaSuccess, closeModalAgendaSuccess
} from '../../actions/agenda/agenda'

// Services
import getAgendaService from '../../services/agenda/getAgenda'
import getFiltrarAgendaService from '../../services/agenda/getFiltrarAgenda'
import getTareasDiaService from '../../services/agenda/getTareasDia'
import getTareaService from '../../services/agenda/getTarea'
import submitNuevaAgendaService from '../../services/agenda/submitNuevaAgenda'
import submitEditarAgendaService from '../../services/agenda/submitEditarAgenda'
import deleteTareaService from '../../services/agenda/deleteTarea'
import deleteTareaSerieService from '../../services/agenda/deleteTareaSerie'

// Sagas
import {yesNoModal as yesNoModalSaga} from '../modal/yesNoModal'

export function * fetchAgenda ({filtros}) {
  try {
    let state = yield select(state => state)
    let listTareasCalendario = []
    if (filtros && Object.keys(filtros).length > 0) {
      let valoresFiltros = {...filtros}
      if (valoresFiltros.operario && valoresFiltros.operario.value) valoresFiltros.operario = valoresFiltros.operario.value
      if (valoresFiltros.tipoTarea && valoresFiltros.tipoTarea.value) valoresFiltros.tipoTarea = valoresFiltros.tipoTarea.value
      valoresFiltros.tipoVista = 3 // 1->diaria, 2->semanal, 3->mensual
      listTareasCalendario = yield call(getFiltrarAgendaService, valoresFiltros, state.auth.token)
    } else {
      listTareasCalendario = yield call(getAgendaService)
    }

    listTareasCalendario = listTareasCalendario.map((tareaCalendario, idx) => {
      return {
        id: tareaCalendario.idAgenda,
        title: tareaCalendario.nombre,
        start: new Date(tareaCalendario.fecha + 'T' + (tareaCalendario.horaInicio ? tareaCalendario.horaInicio : '00:00')),
        end: new Date(tareaCalendario.fecha + 'T' + (tareaCalendario.horaFin ? tareaCalendario.horaFin : '23:59')),
        allDay: !tareaCalendario.horaInicio && !tareaCalendario.horaFin,
        color: colorTipoTareasAgenda[tareaCalendario.idTipoTarea].backgroundColor,
        textColor: colorTipoTareasAgenda[tareaCalendario.idTipoTarea].color,
        borderColor: colorTipoTareasAgenda[tareaCalendario.idTipoTarea].borderColor,
      }
    })

    yield put(fetchAgendaSuccess(listTareasCalendario, filtros))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchFetchAgenda () {
  yield takeLatest(actionTypes.FETCH_AGENDA, fetchAgenda)
}

export function * fetchTareasDia ({filtros}) {
  try {
    let state = yield select(state => state)
    let listTareasDiarias = []
    let valoresFiltros = {}
    if (filtros && Object.keys(filtros).length > 0) {
      valoresFiltros = {...filtros}
      if (filtros.operario && filtros.operario.value) valoresFiltros.operario = filtros.operario.value
      if (filtros.tipoTarea && filtros.tipoTarea.value) valoresFiltros.tipoTarea = filtros.tipoTarea.value
      valoresFiltros.tipoVista = 3 // 1->diaria, 2->semanal, 3->mensual
    }
    listTareasDiarias = yield call(getTareasDiaService, valoresFiltros, state.auth.token)
    yield put(fetchTareasDiaSuccess(listTareasDiarias))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchFetchTareasDia () {
  yield takeLatest(actionTypes.FETCH_TAREAS_DIA, fetchTareasDia)
}

export function * abrirModalAgenda ({idAgenda}) {
  try {
    let state = yield select(state => state)
    let preparedValues = {}
    if (idAgenda) {
      preparedValues = yield call(getTareaService, idAgenda)

      if (preparedValues.diasAnterioresAviso) {
        preparedValues.diasAviso = "1"
        preparedValues.diasAnterioresAviso = state.combos.comboDiasAntes.find((element) => {
          return element.value === preparedValues.diasAnterioresAviso
        })
      }

      if (preparedValues.diasAnterioresAviso === null) {
        preparedValues.diasAviso = "0"
      }

      if (preparedValues.idOperario) {
        preparedValues.idOperario = state.combos.comboOperarioPredeterminado.find((element) => {
          return element.value === preparedValues.idOperario
        })
      }
      if (preparedValues.idTipoTarea) {
        preparedValues.idTipoTarea = state.combos.comboTipoTareasAgenda.find((element) => {
          return element.value === preparedValues.idTipoTarea
        })
        preparedValues.idTipoTarea = {
          value: preparedValues.idTipoTarea.value,
          label: i18n.t(`COMUN.COMBOS.${preparedValues.idTipoTarea.label}`)
        }
      }

      if (preparedValues.frecuencia && preparedValues.frecuencia.frecuencia !== null) {
        preparedValues.tieneFrecuencia = true
        preparedValues.frecuenciaIntervalo = preparedValues.frecuencia.frecuencia

        // Datos intervalo repetición
        preparedValues.fechaComienzo = preparedValues.frecuencia.fechaComienzo
        preparedValues.intervaloRepeticion = "" + preparedValues.frecuencia.intervaloRepeticion
        if (preparedValues.intervaloRepeticion === "2") {
          preparedValues.intervaloRepeticiones = preparedValues.frecuencia.intervaloRepeticiones
        } else if (preparedValues.intervaloRepeticion === "3") {
          preparedValues.fechaFinalizacion = preparedValues.frecuencia.fechaFinalizacion
        }

        // Datos frecuencia
        if (preparedValues.frecuenciaIntervalo === tipoFrecuenciaConstants.DIARIA) {
          if (preparedValues.frecuenciaDiaria !== null) {
            preparedValues.frecuenciaDiariaCada = tipoFrecuenciaDiariaConstants.CADA_X_DIAS
            preparedValues.frecuenciaDiaria = preparedValues.frecuencia.frecuenciaDiaria
          } else {
            preparedValues.frecuenciaDiariaCada = tipoFrecuenciaDiariaConstants.TODOS_DIAS
          }
        } else if (preparedValues.frecuenciaIntervalo === tipoFrecuenciaConstants.SEMANAL) {
          preparedValues.frecuenciaSemanal = preparedValues.frecuencia.frecuenciaSemanal

          preparedValues.frecuencia.diasSemana.forEach((value) => {
            switch(value) {
              case 1:
                preparedValues.diasSemanaLunes = true
                break
              case 2:
                preparedValues.diasSemanaMartes = true
                break
              case 3:
                preparedValues.diasSemanaMiercoles = true
                break
              case 4:
                preparedValues.diasSemanaJueves = true
                break
              case 5:
                preparedValues.diasSemanaViernes = true
                break
              case 6:
                preparedValues.diasSemanaSabado = true
                break
              case 0:
                preparedValues.diasSemanaDomingo = true
                break
              default:
                console.log('[Agenda] Valor diasSemana inesperado', value)
            }
          })
        } else if (preparedValues.frecuenciaIntervalo === tipoFrecuenciaConstants.MENSUAL) {
          preparedValues.frecuenciaMensualDiaCadaMes = 'diaCadaMes'
          preparedValues.diaMes = preparedValues.frecuencia.diaMes
          preparedValues.mes = preparedValues.frecuencia.mes
        } else if (preparedValues.frecuenciaIntervalo === tipoFrecuenciaConstants.ANUAL) {
          preparedValues.anno = preparedValues.frecuencia.anno
          preparedValues.frecuenciaAnualDiaCadaMes = 'diaCadaAnno'
          preparedValues.diaMesAnno = preparedValues.frecuencia.diaMesAnno
          preparedValues.mesAnno = preparedValues.frecuencia.mesAnno

          if (preparedValues.mesAnno) {
            preparedValues.mesAnno = state.combos.comboMeses.find((element) => {
              return element.value === preparedValues.mesAnno
            })
            preparedValues.mesAnno = {
              value: preparedValues.mesAnno.value,
              label: i18n.t(preparedValues.mesAnno.label)
            }
          }
        }
      }
    }
    yield put(abrirModalAgendaSuccess(preparedValues))
    yield put(initialize('ModalAgenda', preparedValues))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchAbrirModalAgenda () {
  yield takeLatest(actionTypes.ABRIR_MODAL_AGENDA, abrirModalAgenda)
}

export function * guardarModalAgenda ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let state = yield select(state => state)
    let existAgenda = !!(values && values.idAgenda)
    let estadoPeticion

    let preparedValues = {
      nombre: values.nombre,
      Operario: values.idOperario ? values.idOperario.value : null,
      tipoTarea: values.idTipoTarea ? values.idTipoTarea.value : null,
      // fechaInicio: values.fecha,
      fecha: values.fecha,
      horaInicio: values.horaInicio,
      horaFin: values.horaFin,
      observaciones: values.observaciones,
      idIntervencion: values.idIntervencion,
      idBioSeguridadAnalitica: values.idBioSeguridadAnalitica,
      idBioSeguridadLimpiezaCorral: values.idBioSeguridadLimpiezaCorral,
      idBioSeguridadLimpiezaSilo: values.idBioSeguridadLimpiezaSilo,
      idBioSeguridadOtro: values.idBioSeguridadOtro,

      // Aviso
      aviso: !!values.aviso,
      horaAviso: values.horaAviso ? values.horaAviso : null,
      diasAnterioresAviso: values.diasAnterioresAviso ? values.diasAnterioresAviso.value : null,
    }

    // Frecuencia
    if (values.tieneFrecuencia) {
      preparedValues.frecuencia = values.frecuenciaIntervalo

      if (values.frecuenciaIntervalo === tipoFrecuenciaConstants.DIARIA) {
        if (values.frecuenciaDiariaCada === tipoFrecuenciaDiariaConstants.CADA_X_DIAS) {
          preparedValues.frecuenciaDiaria = values.frecuenciaDiaria
        } else if (values.frecuenciaDiariaCada === tipoFrecuenciaDiariaConstants.TODOS_DIAS) {
          preparedValues.frecuenciaDiaria = null
        }
      } else if (values.frecuenciaIntervalo === tipoFrecuenciaConstants.SEMANAL) {
        preparedValues.frecuenciaSemanal = values.frecuenciaSemanal
        preparedValues.diasSemana = []
        if (values.diasSemanaLunes) preparedValues.diasSemana.push(1)
        if (values.diasSemanaMartes) preparedValues.diasSemana.push(2)
        if (values.diasSemanaMiercoles) preparedValues.diasSemana.push(3)
        if (values.diasSemanaJueves) preparedValues.diasSemana.push(4)
        if (values.diasSemanaViernes) preparedValues.diasSemana.push(5)
        if (values.diasSemanaSabado) preparedValues.diasSemana.push(6)
        if (values.diasSemanaDomingo) preparedValues.diasSemana.push(0)
      } else if (values.frecuenciaIntervalo === tipoFrecuenciaConstants.MENSUAL) {
        preparedValues.diaMes = values.diaMes
        preparedValues.mes = values.mes
      } else if (values.frecuenciaIntervalo === tipoFrecuenciaConstants.ANUAL) {
        preparedValues.anno = values.anno
        preparedValues.diaMesAnno = values.diaMesAnno
        preparedValues.mesAnno = values.mesAnno ? values.mesAnno.value : null
      }

      // Intervalo de repetición
      preparedValues.intervaloRepeticion = values.intervaloRepeticion
      preparedValues.fechaComienzo = values.fechaComienzo
      preparedValues.intervaloRepeticiones = values.intervaloRepeticiones
      preparedValues.fechaFinalizacion = values.fechaFinalizacion
    }

    if (existAgenda) {
      preparedValues.idAgenda = values.idAgenda
      preparedValues.idAgendaPadre = values.idAgendaPadre
      estadoPeticion = yield call(submitEditarAgendaService, preparedValues, state.auth.token)
    } else {
      estadoPeticion = yield call(submitNuevaAgendaService, preparedValues, state.auth.token)
    }
    yield put(guardarModalAgendaSuccess())

    yield call(fetchAgenda, {filtros: state.agenda.filtros})
    yield call(fetchTareasDia, {filtros: state.agenda.filtros})

    estadoPeticion = estadoPeticion && (estadoPeticion.insertado || estadoPeticion.actualizado)
    if (estadoPeticion === true) {
      yield put(openMensajeAlerta(existAgenda ? 'modificacion' : 'insercion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta(existAgenda ? 'no-modificacion' : 'no-insercion'))
      if (onErrorCallback) yield onErrorCallback()
    }

    const listNotificaciones = yield call(getNotificaciones, null)
    yield put(obtenerNotificaconesUsuarioSuccess(listNotificaciones))
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
  }
}

export function * watchGuardarModalAgenda () {
  yield takeLatest(actionTypes.GUARDAR_MODAL_AGENDA, guardarModalAgenda)
}

export function * closeModalAgenda () {
  try {
    yield put(closeModalAgendaSuccess())
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchCloseModalAgenda () {
  yield takeLatest(actionTypes.CLOSE_MODAL_AGENDA, closeModalAgenda)
}

export function * onDeleteAgenda ({idAgenda, onSuccessCallback, onErrorCallback}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModalSaga, {modalType: 'onDelete'})
    if (confirmed) {
      const {eliminado} = yield call(deleteTareaService, idAgenda, null)
      yield call(closeModalAgenda)
      let filtros = yield select(state => state.agenda.filtros)
      yield call(fetchAgenda, {filtros})
      yield call(fetchTareasDia, {filtros})
      if (eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
        if (onSuccessCallback) yield onSuccessCallback()
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
        if (onErrorCallback) yield onErrorCallback()
      }
    } else if (onErrorCallback) {
      yield onErrorCallback()
    }
    const listNotificaciones = yield call(getNotificaciones, null)
    yield put(obtenerNotificaconesUsuarioSuccess(listNotificaciones))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchOnDeleteAgenda () {
  yield takeLatest(actionTypes.ON_DELETE_AGENDA, onDeleteAgenda)
}

export function * onDeleteAgendaSerie ({idAgenda, onSuccessCallback, onErrorCallback}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModalSaga, {modalType: 'onDelete'})
    if (confirmed) {
      const {eliminado} = yield call(deleteTareaSerieService, idAgenda, null)
      yield call(closeModalAgenda)
      let filtros = yield select(state => state.agenda.filtros)
      yield call(fetchAgenda, {filtros})
      yield call(fetchTareasDia, {filtros})
      if (eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
        if (onSuccessCallback) yield onSuccessCallback()
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
        if (onErrorCallback) yield onErrorCallback()
      }
    } else if (onErrorCallback) {
      yield onErrorCallback()
    }
    const listNotificaciones = yield call(getNotificaciones, null)
    yield put(obtenerNotificaconesUsuarioSuccess(listNotificaciones))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchOnDeleteAgendaSerie () {
  yield takeLatest(actionTypes.ON_DELETE_AGENDA_SERIE, onDeleteAgendaSerie)
}