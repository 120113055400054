import actionTypes from '../../constants/actions/informes/informes'

export function initialState () {
  return {
    // INFORME EXTRACCIONES
    listInformeExtracciones: [],
    totalesExtracciones: [],
    graficaExtracciones: [],
    filtrosExtracciones: [],
    graficaExtraccionesDosis: [],
    graficaExtraccionesConcentracion: [],
    datosPdfInformesExtracciones: [],

    // INFORME STOCK DOSIS
    listInformeStockDosis: [],
    totalesStockDosis: [],
    filtrosStockDosis: [],
    datosPdfInformesStockDosis: [],

     // INFORME POOL
    listInformePool: [],
    totalesPool: [],
    graficaPool: [],
    filtrosPool: [],
    datosPdfInformesPool: [],

    // INFORME PRODUCTIVIDAD EXTRACCIONES
    listInformeProductividadExtraccionesBuenas: [],
    listInformeProductividadExtraccionesMalas: [],
    totalesProductividadExtraccionesBuenas: [],
    totalesProductividadExtraccionesMalas: [],
    filtrosProductividadExtracciones: [],
    showModalProductividadExtracciones: false,
    showModalRecomendacionesMatadero: false,
    listVerracosProductividad: [],
    listRecomendacionesMatadero: [],
    datosPdfInformesProductividadExtracciones: [],

    // INFORME SEGUIMIENTO EXTRACCIONES/POOL
    listInformeSeguimientoExtraccionesPool: [],
    filtrosSeguimientoExtraccionesPool: [],
    listRegistrosSeguimientoExtraccionesPool: [],
    graficaRegistrosSeguimientoExtraccionesPool: [],
    datosPdfInformesSeguimientoExtraccionesPool: [],
    showModalExportarImprimirInformesSeguimientoExtraccionesPool: false,
    valor: '',

    // INFORME PRODUCTIVIDAD CENTRO
    listInformeProductividadCentro: [],
    totalesProductividadCentro: [],
    filtrosProductividadCentro: [],
    datosPdfInformesProductividadCentro: [],

    // INFORME TRAZABILIDAD
    listInformeTrazabilidad: [],
    totalesTrazabilidad: [],
    filtrosTrazabilidad: [],
    datosPdfInformesTrazabilidad: [],

    // INFORME INTERVENCIONES
    listInformeIntervenciones: [],
    totalesIntervenciones: [],
    filtrosIntervenciones: [],
    datosPdfInformesIntervenciones: [],

    // CLIENTES
    listInformeClientes: [],
    albaranesClientes: [],
    totalesClientes: [],
    filtrosCliente: [],
    datosPdfInformesClientes: [],

    // ESTADO_CENTRO
    agrupacionEstadoCentro: 1,
    filtrosEstadoCentro: [],
    censo: [],
    altasVerraco: 0,
    porAltasVerraco: 0,
    verracosActivos: 0,
    porVerracosActivos: 0,
    edad: [],
    productividadVerraco: [],
    ritmoExtraccion: [],
    eyaculadoEliminado: [],
    productividadCentro: [],
    eliminacionVerracos: [],
    clientes: [],
    ebv: [],

    // INFORME PERSONALIZADO
    checkedInformePersonalizado: false,
    datosTablaPersonalizado: [],
    valoresTablaPersonalizado: [],
    showModalGuardarSeleccion: false,
    camposSeleccionadosPersonalizado: [],
    comboSeleccionesInformePersonalizado: [],
    datosSeleccionInformePersonalizado: []
  }
}

// INFORME EXTRACCIONES
export function obtenerInformeExtraccionesSuccess (state, {listInformeExtracciones}) {
  return {
    ...state,
    listInformeExtracciones: listInformeExtracciones.extracciones,
    totalesExtracciones: listInformeExtracciones.totales,
    graficaExtracciones: listInformeExtracciones.grafica,
    graficaExtraccionesDosis: listInformeExtracciones.graficaExtraccionesDosis,
    graficaExtraccionesConcentracion: listInformeExtracciones.graficaExtraccionesConcentracion
  }
}

export function fetchFiltrosSuccess (state, {filtrosExtracciones}) {
  return {
    ...state,
    filtrosExtracciones: filtrosExtracciones
  }
}

export function imprimirPdfInformesExtraccionesSuccess (state, {datosPdfInformesExtracciones}) {
  return {
    ...state,
    datosPdfInformesExtracciones: datosPdfInformesExtracciones
  }
}

// INFORME STOCK DOSIS
export function obtenerInformeStockDosisSuccess (state, {listInformeStockDosis}) {
  return {
    ...state,
    listInformeStockDosis: listInformeStockDosis.stockDosis,
    totalesStockDosis: listInformeStockDosis.totales,
    listInformeExtracciones: [],
    totalesExtracciones: [],
    graficaExtracciones: [],
    graficaExtraccionesDosis: [],
    graficaExtraccionesConcentracion: []
  }
}

export function fetchFiltrosStockDosisSuccess (state, {filtrosStockDosis}) {
  return {
    ...state,
    filtrosStockDosis: filtrosStockDosis
  }
}

export function imprimirPdfInformesStockDosisSuccess (state, {datosPdfInformesStockDosis}) {
  return {
    ...state,
    datosPdfInformesStockDosis: datosPdfInformesStockDosis
  }
}

// INFORME POOL
export function obtenerInformePoolSuccess (state, {listInformePool}) {
  return {
    ...state,
    listInformePool: listInformePool.pool,
    totalesPool: listInformePool.totales,
    graficaPool: listInformePool.grafica
  }
}

export function fetchFiltrosPoolSuccess (state, {filtrosPool}) {
  return {
    ...state,
    filtrosPool: filtrosPool
  }
}

export function imprimirPdfInformesPoolSuccess (state, {datosPdfInformesPool}) {
  return {
    ...state,
    datosPdfInformesPool: datosPdfInformesPool
  }
}

// INFORME PRODUCTIVIDAD EXTRACCIONES
export function obtenerInformeProductividadExtraccionesSuccess (state, {listInformeProductividadExtracciones}) {
  return {
    ...state,
    listInformeProductividadExtraccionesBuenas: listInformeProductividadExtracciones.extracciones.buenas === null ? [] : listInformeProductividadExtracciones.extracciones.buenas,
    listInformeProductividadExtraccionesMalas: listInformeProductividadExtracciones.extracciones.malas === null ? [] : listInformeProductividadExtracciones.extracciones.malas,
    totalesProductividadExtraccionesBuenas: listInformeProductividadExtracciones.medias.buenas,
    totalesProductividadExtraccionesMalas: listInformeProductividadExtracciones.medias.malas
  }
}

export function fetchFiltrosProductividadExtraccionesSuccess (state, {filtrosProductividadExtracciones}) {
  return {
    ...state,
    filtrosProductividadExtracciones: filtrosProductividadExtracciones
  }
}

export function abrirModalProductividadVerracos (state) {
  return {
    ...state,
    showModalProductividadExtracciones: true
  }
}

export function cerrarModalProductividadVerracos (state) {
  return {
    ...state,
    showModalProductividadExtracciones: false
  }
}

export function abrirModalRecomendacionesMatadero (state) {
  return {
    ...state,
    showModalRecomendacionesMatadero: true
  }
}

export function cerrarModalRecomendacionesMatadero (state) {
  return {
    ...state,
    showModalRecomendacionesMatadero: false
  }
}

export function imprimirPdfInformesProductividadExtraccionesSuccess (state, {datosPdfInformesProductividadExtracciones}) {
  return {
    ...state,
    datosPdfInformesProductividadExtracciones: datosPdfInformesProductividadExtracciones
  }
}

// INFORME SEGUIMIENTO EXTRACCIONES/POOL
export function obtenerInformeSeguimientoExtraccionesPoolSuccess (state, {listInformeSeguimientoExtraccionesPool}) {
  return {
    ...state,
    listInformeSeguimientoExtraccionesPool: listInformeSeguimientoExtraccionesPool,
  }
}

export function fetchFiltrosSeguimientoExtraccionesPoolSuccess (state, {filtrosSeguimientoExtraccionesPool}) {
  return {
    ...state,
    filtrosSeguimientoExtraccionesPool: filtrosSeguimientoExtraccionesPool
  }
}

export function obtenerRegistrosSeguimientosExtraccionesPoolSuccess (state, {listRegistrosSeguimientoExtraccionesPool}) {
  return {
    ...state,
    listRegistrosSeguimientoExtraccionesPool: listRegistrosSeguimientoExtraccionesPool.seguimiento !== null ? listRegistrosSeguimientoExtraccionesPool.seguimiento : [],
    graficaRegistrosSeguimientoExtraccionesPool: listRegistrosSeguimientoExtraccionesPool.grafica !== null ? listRegistrosSeguimientoExtraccionesPool.grafica : []
  }
}

export function imprimirPdfInformesSeguimientoExtraccionesPoolSuccess (state, {datosPdfInformesSeguimientoExtraccionesPool}) {
  return {
    ...state,
    datosPdfInformesSeguimientoExtraccionesPool: datosPdfInformesSeguimientoExtraccionesPool
  }
}

export function abrirModalExportarImprimirInformesSeguimientoExtraccionesPool (state, {valor}) {
  return {
    ...state,
    showModalExportarImprimirInformesSeguimientoExtraccionesPool: true,
    exportarImprimir: valor
  }
}

export function cerrarModalExportarImprimirInformesSeguimientoExtraccionesPool (state) {
  return {
    ...state,
    showModalExportarImprimirInformesSeguimientoExtraccionesPool: false,
    exportarImprimir: ''
  }
}

// INFORME PRODUCTIVIDAD CENTRO
export function obtenerInformeProductividadCentroSuccess (state, {listInformeProductividadCentro}) {
  return {
    ...state,
    listInformeProductividadCentro: listInformeProductividadCentro
  }
}

export function fetchFiltrosProductividadCentroSuccess (state, {filtrosProductividadCentro}) {
  return {
    ...state,
    filtrosProductividadCentro: filtrosProductividadCentro
  }
}

export function imprimirPdfInformesProductividadCentroSuccess (state, {datosPdfInformesProductividadCentro}) {
  return {
    ...state,
    datosPdfInformesProductividadCentro: datosPdfInformesProductividadCentro
  }
}

// INFORME TRAZABILIDAD
export function obtenerInformeTrazabilidadSuccess (state, {listInformeTrazabilidad}) {
  return {
    ...state,
    listInformeTrazabilidad: listInformeTrazabilidad.trazabilidad,
    totalesTrazabilidad: listInformeTrazabilidad.totales
  }
}

export function fetchFiltrosTrazabilidadSuccess (state, {filtrosTrazabilidad}) {
  return {
    ...state,
    filtrosTrazabilidad: filtrosTrazabilidad
  }
}

export function imprimirPdfInformesTrazabilidadSuccess (state, {datosPdfInformesTrazabilidad}) {
  return {
    ...state,
    datosPdfInformesTrazabilidad: datosPdfInformesTrazabilidad
  }
}

// INFORME INTERVENCIONES
export function obtenerInformeIntervencionesSuccess (state, {listInformeIntervenciones}) {
  return {
    ...state,
    listInformeIntervenciones: listInformeIntervenciones.intervenciones,
    totalIntervenciones: listInformeIntervenciones.total
  }
}

export function fetchFiltrosIntervencionesSuccess (state, {filtrosIntervenciones}) {
  return {
    ...state,
    filtrosIntervenciones: filtrosIntervenciones
  }
}

export function imprimirPdfInformesIntervencionesSuccess (state, {datosPdfInformesIntervenciones}) {
  return {
    ...state,
    datosPdfInformesIntervenciones: datosPdfInformesIntervenciones
  }
}

// CLIENTE
export function obtenerInformeClienteSuccess (state, {listInformeCliente}) {
  return {
    ...state,
    listInformeClientes: listInformeCliente.clientes,
    albaranesClientes: listInformeCliente.albaranes,
    totalesCliente: listInformeCliente.totales
  }
}

export function fetchFiltrosClienteSuccess (state, {filtrosCliente}) {
  return {
    ...state,
    filtrosCliente: filtrosCliente
  }
}

export function imprimirPdfInformesClientesSuccess (state, {datosPdfInformesClientes}) {
  return {
    ...state,
    datosPdfInformesClientes: datosPdfInformesClientes
  }
}

// ESTADO_CENTRO
export function obtenerInformeEstadoCentroSuccess (state, {listInformeEstadoCentro}) {
  return {
    ...state,
    censo: listInformeEstadoCentro.censo,
    altasVerraco: listInformeEstadoCentro.censo.altasVerraco,
    porAltasVerraco: listInformeEstadoCentro.censo.porAltasVerraco,
    verracosActivos: listInformeEstadoCentro.censo.verracosActivos,
    porVerracosActivos: listInformeEstadoCentro.censo.porVerracosActivos,
    edad: listInformeEstadoCentro.edad,
    productividadVerraco: listInformeEstadoCentro.productividadVerracos,
    ritmoExtraccion: listInformeEstadoCentro.ritmoExtraccion,
    eyaculadoEliminado: listInformeEstadoCentro.eyaculadoEliminado,
    productividadCentro: listInformeEstadoCentro.productividadCentro,
    eliminacionVerracos: listInformeEstadoCentro.eliminacionVerracos,
    clientes: listInformeEstadoCentro.clientes,
    ebv: listInformeEstadoCentro.ebv
  }
}

export function fetchFiltrosEstadoCentroSuccess (state, {filtrosEstadoCentro}) {
  return {
    ...state,
    filtrosEstadoCentro: filtrosEstadoCentro,
    agrupacionEstadoCentro: filtrosEstadoCentro.agrupacion.value
  }
}

export function obtenerVerracosProductividadExtraccionesSuccess (state, {listVerracosProductividad}) {
  return {
    ...state,
    listVerracosProductividad: listVerracosProductividad
  }
}

export function obtenerRecomendacionesMataderoSuccess (state, {listRecomendacionesMatadero}) {
  return {
    ...state,
    listRecomendacionesMatadero: listRecomendacionesMatadero
  }
}

// INFORME PERSONALIZADO
export function seleccionarTodosInformePersonalizado (state) {
  return {
    ...state,
    checkedInformePersonalizado: true
  }
}

export function deseleccionarTodosInformePersonalizado (state) {
  return {
    ...state,
    checkedInformePersonalizado: false
  }
}

export function mostrarSeleccionInformePersonalizadoSuccess (state, {datosTablaPersonalizado}) {
  return {
    ...state,
    datosTablaPersonalizado: datosTablaPersonalizado.personalizado
  }
}

export function valoresTablaPersonalizadoSuccess (state, {valoresTablaPersonalizado}) {
  return {
    ...state,
    valoresTablaPersonalizado: valoresTablaPersonalizado
  }
}

export function abrirModalGuardarSeleccion (state) {
  return {
    ...state,
    showModalGuardarSeleccion: true
  }
}

export function cerrarModalGuardarSeleccion (state) {
  return {
    ...state,
    showModalGuardarSeleccion: false
  }
}

export function camposSeleccionadosPersonalizadoSuccess (state, {camposSeleccionadosPersonalizado}) {
  return {
    ...state,
    camposSeleccionadosPersonalizado: camposSeleccionadosPersonalizado
  }
}

export function comboSeleccionesInformePersonalizadoSuccess (state, {data}) {
  return {
    ...state,
    comboSeleccionesInformePersonalizado: data
  }
}

export function cargarSeleccionInformePersonalizadoSuccess (state, {data}) {
  return {
    ...state,
    datosSeleccionInformePersonalizado: data
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    // INFORME EXTRACCIONES
    case actionTypes.OBTENER_INFORME_EXTRACCIONES_SUCCESS:
      return obtenerInformeExtraccionesSuccess(state, action)
    case actionTypes.FETCH_FILTROS_SUCCESS:
      return fetchFiltrosSuccess(state, action)
    case actionTypes.IMPRIMIR_PDF_INFORMES_EXTRACCIONES_SUCCESS:
      return imprimirPdfInformesExtraccionesSuccess(state, action)

    // INFORME STOCK DOSIS
    case actionTypes.OBTENER_INFORME_STOCK_DOSIS_SUCCESS:
      return obtenerInformeStockDosisSuccess(state, action)
    case actionTypes.FETCH_FILTROS_STOCK_DOSIS_SUCCESS:
      return fetchFiltrosStockDosisSuccess(state, action)
    case actionTypes.IMPRIMIR_PDF_INFORMES_STOCK_DOSIS_SUCCESS:
      return imprimirPdfInformesStockDosisSuccess(state, action)

    // INFORME POOL
    case actionTypes.OBTENER_INFORME_POOL_SUCCESS:
      return obtenerInformePoolSuccess(state, action)
    case actionTypes.FETCH_FILTROS_POOL_SUCCESS:
      return fetchFiltrosPoolSuccess(state, action)
    case actionTypes.IMPRIMIR_PDF_INFORMES_POOL_SUCCESS:
      return imprimirPdfInformesPoolSuccess(state, action)

    // INFORME PRODUCTIVIDAD EXTRACCIONES
    case actionTypes.OBTENER_INFORME_PRODUCTIVIDAD_EXTRACCIONES_SUCCESS:
      return obtenerInformeProductividadExtraccionesSuccess(state, action)
    case actionTypes.FETCH_FILTROS_PRODUCTIVIDAD_EXTRACCIONES_SUCCESS:
      return fetchFiltrosProductividadExtraccionesSuccess(state, action)
    case actionTypes.ABRIR_MODAL_PRODUCTIVIDAD_VERRACOS:
      return abrirModalProductividadVerracos(state, action)
    case actionTypes.CERRAR_MODAL_PRODUCTIVIDAD_VERRACOS:
      return cerrarModalProductividadVerracos(state, action)
    case actionTypes.ABRIR_MODAL_RECOMENDACIONES_MATADERO:
      return abrirModalRecomendacionesMatadero(state, action)
    case actionTypes.CERRAR_MODAL_RECOMENDACIONES_MATADERO:
      return cerrarModalRecomendacionesMatadero(state, action)
    case actionTypes.OBTENER_VERRACOS_PRODUCTIVIDAD_EXTRACCIONES_SUCCESS:
      return obtenerVerracosProductividadExtraccionesSuccess(state, action)
    case actionTypes.OBTENER_RECOMENDACIONES_MATADERO_SUCCESS:
      return obtenerRecomendacionesMataderoSuccess(state, action)
    case actionTypes.IMPRIMIR_PDF_INFORMES_PRODUCTIVIDAD_EXTRACCIONES_SUCCESS:
      return imprimirPdfInformesProductividadExtraccionesSuccess(state, action)

    // INFORME SEGUIMIENTO EXTRACCIONES/POOL
    case actionTypes.OBTENER_INFORME_SEGUIMIENTO_EXTRACCIONES_POOL_SUCCESS:
      return obtenerInformeSeguimientoExtraccionesPoolSuccess(state, action)
    case actionTypes.FETCH_FILTROS_SEGUIMIENTO_EXTRACCIONES_POOL_SUCCESS:
      return fetchFiltrosSeguimientoExtraccionesPoolSuccess(state, action)
    case actionTypes.OBTENER_REGISTROS_SEGUIMIENTOS_EXTRACCIONES_POOL_SUCCESS:
      return obtenerRegistrosSeguimientosExtraccionesPoolSuccess(state, action)
    case actionTypes.IMPRIMIR_PDF_INFORMES_SEGUIMIENTO_EXTRACCIONES_POOL_SUCCESS:
      return imprimirPdfInformesSeguimientoExtraccionesPoolSuccess(state, action)
    case actionTypes.ABRIR_MODAL_EXPORTAR_IMPRIMIR_INFORMES_SEGUIMIENTO_EXTRACCIONES_POOL:
      return abrirModalExportarImprimirInformesSeguimientoExtraccionesPool(state, action)
    case actionTypes.CERRAR_MODAL_EXPORTAR_IMPRIMIR_INFORMES_SEGUIMIENTO_EXTRACCIONES_POOL:
      return cerrarModalExportarImprimirInformesSeguimientoExtraccionesPool(state, action)

    // INFORME PRODUCTIVIDAD CENTRO
    case actionTypes.OBTENER_INFORME_PRODUCTIVIDAD_CENTRO_SUCCESS:
      return obtenerInformeProductividadCentroSuccess(state, action)
    case actionTypes.FETCH_FILTROS_PRODUCTIVIDAD_CENTRO_SUCCESS:
      return fetchFiltrosProductividadCentroSuccess(state, action)
    case actionTypes.IMPRIMIR_PDF_INFORMES_PRODUCTIVIDAD_CENTRO_SUCCESS:
      return imprimirPdfInformesProductividadCentroSuccess(state, action)

    // INFORME TRAZABILIDAD
    case actionTypes.OBTENER_INFORME_TRAZABILIDAD_SUCCESS:
      return obtenerInformeTrazabilidadSuccess(state, action)
    case actionTypes.FETCH_FILTROS_TRAZABILIDAD_SUCCESS:
      return fetchFiltrosTrazabilidadSuccess(state, action)
    case actionTypes.IMPRIMIR_PDF_INFORMES_TRAZABILIDAD_SUCCESS:
      return imprimirPdfInformesTrazabilidadSuccess(state, action)

    // INFORME INTERVENCIONES
    case actionTypes.OBTENER_INFORME_INTERVENCIONES_SUCCESS:
      return obtenerInformeIntervencionesSuccess(state, action)
    case actionTypes.FETCH_FILTROS_INTERVENCIONES_SUCCESS:
      return fetchFiltrosIntervencionesSuccess(state, action)
    case actionTypes.IMPRIMIR_PDF_INFORMES_INTERVENCIONES_SUCCESS:
      return imprimirPdfInformesIntervencionesSuccess(state, action)

    // CLIENTE
    case actionTypes.OBTENER_INFORME_CLIENTE_SUCCESS:
      return obtenerInformeClienteSuccess(state, action)
    case actionTypes.FETCH_FILTROS_CLIENTE_SUCCESS:
      return fetchFiltrosClienteSuccess(state, action)
    case actionTypes.IMPRIMIR_PDF_INFORMES_CLIENTES_SUCCESS:
      return imprimirPdfInformesClientesSuccess(state, action)

    // ESTADO_CENTRO
    case actionTypes.OBTENER_INFORME_ESTADO_CENTRO_SUCCESS:
      return obtenerInformeEstadoCentroSuccess(state, action)
    case actionTypes.FETCH_FILTROS_ESTADO_CENTRO_SUCCESS:
      return fetchFiltrosEstadoCentroSuccess(state, action)

    // INFORME PERSONALIZADO
    case actionTypes.SELECCIONAR_TODOS_INFORME_PERSONALIZADO:
      return seleccionarTodosInformePersonalizado(state, action)
    case actionTypes.DESELECCIONAR_TODOS_INFORME_PERSONALIZADO:
      return deseleccionarTodosInformePersonalizado(state, action)
    case actionTypes.MOSTRAR_SELECCION_INFORME_PERSONALIZADO_SUCCESS:
      return mostrarSeleccionInformePersonalizadoSuccess(state, action)
    case actionTypes.VALORES_TABLA_PERSONALIZADO_SUCCESS:
      return valoresTablaPersonalizadoSuccess(state, action)
    case actionTypes.ABRIR_MODAL_GUARDAR_SELECCION:
      return abrirModalGuardarSeleccion(state, action)
    case actionTypes.CERRAR_MODAL_GUARDAR_SELECCION:
      return cerrarModalGuardarSeleccion(state, action)
    case actionTypes.CAMPOS_SELECCIONADOS_PERSONALIZADO_SUCCESS:
      return camposSeleccionadosPersonalizadoSuccess(state, action)
    case actionTypes.CAMPOS_SELECCIONADOS_PERSONALIZADO_SUCCESS:
      return camposSeleccionadosPersonalizadoSuccess(state, action)
    case actionTypes.COMBO_SELECCIONES_INFORME_PERSONALIZADO_SUCCESS:
      return comboSeleccionesInformePersonalizadoSuccess(state, action)
    case actionTypes.CARGAR_SELECCION_INFORME_PERSONALIZADO_SUCCESS:
      return cargarSeleccionInformePersonalizadoSuccess(state, action)
    default:
      return state
  }
}