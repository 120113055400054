import actionTypes from '../../constants/actions/envasado/envasado'

export function fetchEnvasado () {
  return {
    type: actionTypes.FETCH_ENVASADO
  }
}

export function fetchEnvasadoSuccess (list) {
  return {
    type: actionTypes.FETCH_ENVASADO_SUCCESS,
    list
  }
}

export function fetchFiltrarEnvasado (values) {
  return {
    type: actionTypes.FETCH_FILTRAR_ENVASADO,
    values
  }
}

export function fetchFiltrarEnvasadoSuccess (filtros) {
  return {
    type: actionTypes.FETCH_FILTRAR_ENVASADO_SUCCESS,
    filtros
  }
}

export function crearCsvEnvasado(list){
  return {
    type: actionTypes.CREATE_CSV_ENVASADO,
    list
  }
}

export function crearCsvEnvasadoSuccess(datosExcelEnvasado){
  return {
    type: actionTypes.CREATE_CSV_ENVASADO_SUCCESS,
    datosExcelEnvasado
  }
}
export function imprimirPdfEnvasado(list){
  return {
    type: actionTypes.IMPRIMIR_PDF_ENVASADO,
    list
  }
}

export function imprimirPdfEnvasadoSuccess(datosPdfEnvasado){
  return {
    type: actionTypes.IMPRIMIR_PDF_ENVASADO_SUCCESS,
    datosPdfEnvasado
  }
}

export function abrirDetallesEnvasado (idEnvasado) {
  return {
    type: actionTypes.ABRIR_DETALLES_ENVASADO,
    idEnvasado
  }
}

export function abrirDetallesEnvasadoSuccess (data) {
  return {
    type: actionTypes.ABRIR_DETALLES_ENVASADO_SUCCESS,
    data
  }
}

export function cerrarDetallesEnvasado () {
  return {
    type: actionTypes.CERRAR_DETALLES_ENVASADO
  }
}

export function cerrarDetallesEnvasadoSuccess () {
  return {
    type: actionTypes.CERRAR_DETALLES_ENVASADO_SUCCESS
  }
}

export function editarEnvasado (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.EDITAR_ENVASADO,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function seleccionarTodosEnvasadoMultiDosis(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_ENVASADO_MULTI_DOSIS
  }
}

export function deseleccionarTodosEnvasadoMultiDosis(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_ENVASADO_MULTI_DOSIS
  }
}

export function deleteEnvasadoMultiDosis(row){
  return {
    type: actionTypes.DELETE_ENVASADO_MULTI_DOSIS,
    row
  }
}

export function deleteSeleccionEnvasadoMultiDosis(list){
  return {
    type: actionTypes.DELETE_SELECCION_ENVASADO_MULTI_DOSIS,
    list
  }
}

export function deleteEnvasadoMultiDosisSuccess(list){
  return {
    type: actionTypes.DELETE_ENVASADO_MULTI_DOSIS_SUCCESS,
    list
  }
}

export function abrirModalEnvasadoMultiDosis (row) {
  return {
    type: actionTypes.ABRIR_MODAL_ENVASADO_MULTI_DOSIS,
    row
  }
}

export function abrirModalEnvasadoMultiDosisSuccess () {
  return {
    type: actionTypes.ABRIR_MODAL_ENVASADO_MULTI_DOSIS_SUCCESS
  }
}

export function abrirModalEnvasadoMultiDosisDesdeListadoPrincipal (idEnvasado, idEnvasadoMultiDosis) {
  return {
    type: actionTypes.ABRIR_MODAL_ENVASADO_MULTI_DOSIS_DESDE_LISTADO_PRINCIPAL,
    idEnvasado,
    idEnvasadoMultiDosis
  }
}

export function cerrarModalEnvasadoMultiDosis () {
  return {
    type: actionTypes.CERRAR_MODAL_ENVASADO_MULTI_DOSIS
  }
}

export function cerrarModalEnvasadoMultiDosisSuccess () {
  return {
    type: actionTypes.CERRAR_MODAL_ENVASADO_MULTI_DOSIS_SUCCESS
  }
}

export function guardarModalEnvasadoMultiDosis (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.GUARDAR_MODAL_ENVASADO_MULTI_DOSIS,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function guardarModalEnvasadoMultiDosisSuccess (list) {
  return {
    type: actionTypes.GUARDAR_MODAL_ENVASADO_MULTI_DOSIS_SUCCESS,
    list
  }
}

export function updateTotalUtilizadoDetalleEnvasado () {
  return {
    type: actionTypes.UPDATE_TOTAL_UTILIZADO_DETALLE_ENVASADO
  }
}

export function updateTotalUtilizadoDetalleEnvasadoSuccess (totalUtilizado) {
  return {
    type: actionTypes.UPDATE_TOTAL_UTILIZADO_DETALLE_ENVASADO_SUCCESS,
    totalUtilizado
  }
}

export function prepararMaquinaEnvasado (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.PREPARAR_MAQUINA_ENVASADO,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function cancelarMaquinaEnvasado () {
  return {
    type: actionTypes.CANCELAR_MAQUINA_ENVASADO
  }
}

export function actualizarEnvasadoMultiDosisSuccess (list) {
  return {
    type: actionTypes.ACTUALIZAR_ENVASADO_MULTI_DOSIS_SUCCESS,
    list
  }
}

export function actualizarEstadoMaquinaEnvasado(codigo){
  return {
    type: actionTypes.ACTUALIZAR_ESTADO_MAQUINA_ENVASADO,
    codigo
  }
}

export default {
  fetchEnvasado,
  fetchEnvasadoSuccess,
  fetchFiltrarEnvasado,
  fetchFiltrarEnvasadoSuccess,
  crearCsvEnvasado,
  crearCsvEnvasadoSuccess,
  imprimirPdfEnvasado,
  imprimirPdfEnvasadoSuccess,
  abrirDetallesEnvasado,
  abrirDetallesEnvasadoSuccess,
  cerrarDetallesEnvasado,
  cerrarDetallesEnvasadoSuccess,
  editarEnvasado,
  seleccionarTodosEnvasadoMultiDosis,
  deseleccionarTodosEnvasadoMultiDosis,
  deleteEnvasadoMultiDosis,
  deleteSeleccionEnvasadoMultiDosis,
  deleteEnvasadoMultiDosisSuccess,
  abrirModalEnvasadoMultiDosis,
  abrirModalEnvasadoMultiDosisSuccess,
  abrirModalEnvasadoMultiDosisDesdeListadoPrincipal,
  cerrarModalEnvasadoMultiDosis,
  cerrarModalEnvasadoMultiDosisSuccess,
  guardarModalEnvasadoMultiDosis,
  guardarModalEnvasadoMultiDosisSuccess,
  updateTotalUtilizadoDetalleEnvasado,
  updateTotalUtilizadoDetalleEnvasadoSuccess,
  prepararMaquinaEnvasado,
  cancelarMaquinaEnvasado,
  actualizarEstadoMaquinaEnvasado
}
