import React, { Component } from 'react'
import { Popover, OverlayTrigger, Image } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import {Notifications, Error, NotificationsActive, NotificationsOff} from '@material-ui/icons'
import './Notificaciones.scss'
import {permisos as permisosConstant} from '../../constants/permisos'

class Notificaciones extends Component {
  constructor (props) {
    super()
    this.checkNotifications = this.checkNotifications.bind(this)
    this.redirigirAvisoNotificacion = this.redirigirAvisoNotificacion.bind(this)
  }

  redireccionarAgenda (e, idAgenda) {
    let tag
    if (e.srcElement) {
      tag = e.srcElement.tagName
    } else if (e.target) {
      tag = e.target.tagName
    }
    if(tag !== 'path' && tag !== 'svg') {
      document.body.click()
      window.location.href="/#/Agenda"
      this.props.actions.abrirModalAgenda(idAgenda)
    }
  }

  redireccionarProducto (e, idProducto) {
    let tag
    if (e.srcElement) {
      tag = e.srcElement.tagName
    } else if (e.target) {
      tag = e.target.tagName
    }
    if(tag !== 'path' && tag !== 'svg') {
      document.body.click()
      window.location.href="/#/Producto"
      this.props.actions.recuperarDatosModalProducto(idProducto)
    }
  }

  redirigirAvisoNotificacion (idAgenda) {
    window.open(window.location.protocol + "//" + window.location.host + "/#/Agenda", '_blank')
    this.props.actions.abrirModalAgenda(idAgenda)
  }

  checkNotifications(){
    let notificacionesHoy = Object.keys(this.props.listNotificacionesNotificaciones).length > 0 ? this.props.listNotificacionesNotificaciones : []
    let now = new Date
    let utcDateTime=now.getUTCFullYear()+"-"+("0" + (now.getUTCMonth() + 1)).slice(-2)+"-"+("0" + (now.getUTCDate())).slice(-2)+" "+("0" + (now.getUTCHours())).slice(-2)+":"+("0" + (now.getUTCMinutes())).slice(-2)+":00"
    for (let i = 0; i < notificacionesHoy.length; i++) {
      if(notificacionesHoy[i]['FechaHoraUTC']===utcDateTime){
        let options = {
          body: notificacionesHoy[i]['nombre'],
          icon: '/recursos/logo-notificaciones.png',
        }
        let n = new Notification('Gesipor',options)
        if (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.AGENDA] > 1) {
          n.onclick = function (event) {
            event.preventDefault()
            window.open("/#/Agenda", '_blank')
            this.props.actions.abrirModalAgenda(notificacionesHoy[i]['idAgenda'])
          }
        }
      }
    }
  }

  UNSAFE_componentWillMount () {
    this.props.actions.obtenerNotificaconesUsuario()
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification")
    }
    else if (Notification.permission === "granted") {
      this.checkNotifications()
      setInterval(() => this.checkNotifications(), 60*1000)
    }
    else if (Notification.permission !== 'denied') {
      Notification.requestPermission(function(permission) {
        if (permission === "granted") {
          this.checkNotifications()
          setInterval(() => this.checkNotifications(), 60*1000)
        }
      }.bind(this))
    }
  }

  marcarComoLeidoNotificacion (idAgenda, leido) {
    let valLeido
    if (leido === true) {
      valLeido = false
    } else {
      valLeido = true
    }
    this.props.actions.marcarComoLeidoNotificacion(idAgenda, valLeido)
  }

  marcarComoLeidoNotificacionStock (idProducto, leido) {
    let valLeido
    if (leido === true) {
      valLeido = false
    } else {
      valLeido = true
    }
    this.props.actions.marcarComoLeidoNotificacionStock(idProducto, valLeido)
  }

  render () {
    const {
      t, listNotificacionesAgenda, urlRecursos, numeroNotificaciones
    } = this.props
    let lista = []
    let listaStock = []

    const popoverNotifications = ({t, listNotificacionesAgenda}) => (
      <Popover id="popover-trigger-click-root-close" className="content-popover-notification">
        <div className="notificaciones-popover">
          <span className="titulo-notificaciones"><b>{t('HEADER.NOTIFICACIONES')}</b><span className="num-notificaciones">{numeroNotificaciones}</span></span>
          {
            Object.keys(listNotificacionesAgenda).length > 0 ? (
              listNotificacionesAgenda.forEach((noti) => {
                if (noti.idTipoAviso === 1) {
                lista.push(<Row key={noti.tipoTarea + noti.nombre + noti.idAgenda} onClick={noti.idTipoAviso === 1 && (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.AGENDA] > 1) ? (e) => this.redireccionarAgenda(e, noti.idAgenda) : (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.PRODUCTOS] > 1) ? (e) => this.redireccionarProducto(e, noti.idProducto) : null} className="columns-tabla-notificaciones">
                <Col sm={3} style={{width: '10%'}}>{noti.leido === true ? <span onClick={() => this.marcarComoLeidoNotificacion(noti.idAgenda, noti.leido)} className="campana-noti-off"><NotificationsOff/></span> : <span onClick={() => this.marcarComoLeidoNotificacion(noti.idAgenda, noti.leido)} className="campana-noti-activa"><NotificationsActive/></span>}</Col>
                <Col sm={3} style={{width: '30%'}}>{noti.horaInicio}</Col>
                <Col sm={3} style={{width: '30%'}}>{noti.nombre}</Col>
                <Col sm={3} style={{width: '30%'}}>{noti.tipoTarea}</Col>
                </Row>)
                }
                 else if (noti.idTipoAviso === 2) {
                    listaStock.push(<Row key={noti.tipoTarea + noti.nombre} onClick={noti.idTipoAviso === 1 && (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.AGENDA] > 1) ? (e) => this.redireccionarAgenda(e, noti.idAgenda) : (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.PRODUCTOS] > 1) ? (e) => this.redireccionarProducto(e, noti.idProducto) : null} className="columns-avisos-stock">
                      <Col sm={12} >{noti.leido === true ? <span onClick={() => this.marcarComoLeidoNotificacionStock(noti.idProducto, noti.leido)} className="campana-noti-off"><NotificationsOff/></span> : <span onClick={() => this.marcarComoLeidoNotificacionStock(noti.idProducto, noti.leido)} className="campana-noti-activa"><NotificationsActive/></span>}{noti.nombre}</Col>
                      </Row>)
                 }
              })
            ) : null
          }
          {
            lista.length > 0 && ([
              <Row key="noti-1"><Col className="col-noti-agenda" sm={12}><p>{t('NOTIFICACIONES.NOTIFICACIONES_AGENDA')}</p></Col></Row>,
              <Row key="noti-2" className="titulo-tabla-notificaciones">
                <Col sm={3} style={{width: '10%'}}></Col>
                <Col sm={3} style={{width: '30%'}}>{t('NOTIFICACIONES.HORA_INICIO')}</Col>
                <Col sm={3} style={{width: '30%'}}>{t('NOTIFICACIONES.NOMBRE')}</Col>
                <Col sm={3} style={{width: '30%'}}>{t('NOTIFICACIONES.TIPO_TAREA')}</Col>
              </Row>
            ])
          }
          {
            Object.keys(listNotificacionesAgenda).length > 0 ? ([
              lista,
              listaStock.length > 0 && <Row key="tit-aviso"><Col className="col-noti-avisos-stok" sm={12}><p>{t('NOTIFICACIONES.AVISOS_STOCK')}</p></Col></Row>,
              listaStock
            ]) : (
              <Row className="columns-tabla-notificaciones">
                <Col sm={12}>{t('NOTIFICACIONES.SIN_TAREAS')}</Col>
              </Row>
            )
          }
        </div>
      </Popover>
    )

    return (
        <OverlayTrigger trigger="click" placement="left" rootClose overlay={popoverNotifications(this.props)}>
          <span className="notifications">
            <Notifications />
            {
              Object.keys(listNotificacionesAgenda).length > 0 && <span className="num-notificaciones-campana">{numeroNotificaciones}</span>
            }
          </span>
        </OverlayTrigger>
    )
  }
}

export default (reduxForm({
  form: 'Notificaciones',
})(Notificaciones))