import actionTypes from '../../../constants/actions/planSanitario/tipoAlimentacion/tipoAlimentacion'

export function fetchTipoAlimentacion () {
  return {
    type: actionTypes.FETCH_TIPO_ALIMENTACION
  }
}

export function fetchTipoAlimentacionSuccess (list) {
  return {
    type: actionTypes.FETCH_TIPO_ALIMENTACION_SUCCESS,
    list
  }
}

export function abrirModalVacioTipoAlimentacion () {
  return {
    type: actionTypes.ABRIR_MODAL_VACIO_TIPO_ALIMENTACION
  }
}

export function seleccionarTodosTipoAlimentacion(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_TIPO_ALIMENTACION
  }
}

export function deSeleccionarTodosTipoAlimentacion(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_TIPO_ALIMENTACION
  }
}

export function submitNuevoTipoAlimentacion(values, onSuccessCallback, onErrorCallback) {
  return{
    type: actionTypes.SUBMIT_NUEVO_TIPO_ALIMENTACION,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function recuperarDatosModalTipoAlimentacion (idTipoAlimentacion) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_TIPO_ALIMENTACION,
    idTipoAlimentacion
  }
}

export function recuperarDatosModalTipoAlimentacionSuccess (data) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_TIPO_ALIMENTACION_SUCCESS,
    data
  }
}

export function editarTipoAlimentacion (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.EDITAR_TIPO_ALIMENTACION,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function duplicarDatosModalTipoAlimentacion (idTipoAlimentacion, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_TIPO_ALIMENTACION,
    idTipoAlimentacion,
    onSuccessCallback,
    onErrorCallback
  }
}

export function duplicarDatosModalTipoAlimentacionSuccess (dataDuplicar) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_TIPO_ALIMENTACION_SUCCESS,
    dataDuplicar
  }
}

export function cambiarEstadoTipoAlimentacion (idTipoAlimentacion) {
  return {
    type: actionTypes.CAMBIAR_ESTADO_TIPO_ALIMENTACION,
    idTipoAlimentacion
  }
}

export function onDeleteTipoAlimentacion (idTipoAlimentacion) {
  return {
    type: actionTypes.ON_DELETE_TIPO_ALIMENTACION,
    idTipoAlimentacion
  }
}

export function cambiarEstadoSeleccionTipoAlimentacion(list){
  return {
    type: actionTypes.CAMBIAR_ESTADO_SELECCION_TIPO_ALIMENTACION,
    list
  }
}

export function deleteSeleccionTipoAlimentacion(list){
  return {
    type: actionTypes.DELETE_SELECCION_TIPO_ALIMENTACION,
    list
  }
}

export function guardarYnuevoTipoAlimentacion (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.GUARDAR_Y_NUEVO_TIPO_ALIMENTACION,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function crearCsvTipoAlimentacion(){
  return {
    type: actionTypes.CREATE_CSV_TIPO_ALIMENTACION
  }
}

export function crearCsvTipoAlimentacionSuccess(datosExcelTipoAlimentacion){
  return {
    type: actionTypes.CREATE_CSV_TIPO_ALIMENTACION_SUCCESS,
    datosExcelTipoAlimentacion
  }
}
export function imprimirPdfTipoAlimentacion(){
  return {
    type: actionTypes.IMPRIMIR_PDF_TIPO_ALIMENTACION
  }
}

export function imprimirPdfTipoAlimentacionSuccess(datosPdfTipoAlimentacion){
  return {
    type: actionTypes.IMPRIMIR_PDF_TIPO_ALIMENTACION_SUCCESS,
    datosPdfTipoAlimentacion
  }
}

export default {
  fetchTipoAlimentacion,
  fetchTipoAlimentacionSuccess,
  abrirModalVacioTipoAlimentacion,
  seleccionarTodosTipoAlimentacion,
  deSeleccionarTodosTipoAlimentacion,
  submitNuevoTipoAlimentacion,
  recuperarDatosModalTipoAlimentacion,
  recuperarDatosModalTipoAlimentacionSuccess,
  editarTipoAlimentacion,
  duplicarDatosModalTipoAlimentacion,
  duplicarDatosModalTipoAlimentacionSuccess,
  cambiarEstadoTipoAlimentacion,
  onDeleteTipoAlimentacion,
  cambiarEstadoSeleccionTipoAlimentacion,
  deleteSeleccionTipoAlimentacion,
  guardarYnuevoTipoAlimentacion,
  crearCsvTipoAlimentacion,
  crearCsvTipoAlimentacionSuccess,
  imprimirPdfTipoAlimentacion,
  imprimirPdfTipoAlimentacionSuccess
}
