import React, { Component } from 'react'
import { Modal, Row } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { required } from '../../util/validationFunctions'
import Button from '../comun/button/Button'
import ButtonChangeRecord from '../comun/button/ButtonChangeRecord'
import ButtonLockable from '../comun/button/ButtonLockable'
import InputText from '../comun/form/InputText'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputSelect from '../comun/form/InputSelect'
import {permisos as permisosConstant} from '../../constants/permisos'
import {modulos as modulosConstant} from '../../constants/modulos'

class ModalOperario extends Component {
  submitNuevaOperario (values) {
    return new Promise((resolve, reject) => {
      if (this.props.data){
        this.props.actions.editarOperario(values, resolve, reject)
      } else {
        this.props.actions.submitNuevaOperario(values, resolve, reject)
      }
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  guardarYnuevoOperario(values){
    return new Promise((resolve, reject) => {
      this.props.actions.guardarYnuevoOperario(values, resolve, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  duplicar(values){
    return new Promise((resolve, reject) => {
      this.props.duplicar(values, resolve, reject)
    })
      .then(() => { console.log('completado') })
      .catch(() => { console.log('error') })
  }

  UNSAFE_componentWillMount () {
    this.props.actions.comboProvinciasClientes()
    this.props.actions.comboUsuariosNoAsignadosOperario()
  }

  render () {
    const {
      t, handleSubmit, submitting, showModal, list, data, duplicar,
      actions: {recuperarDatosModalOperario, closeModal},
      combos: {comboProvinciasClientes, comboUsuariosNoAsignadosOperario}
    } = this.props
    return (
      <Modal show={showModal} onHide={closeModal} aria-labelledby="contained-modal-title-lg" backdrop="static">
      <form className="form-operario">
        <Modal.Header closeButton={!submitting}>
          <Modal.Title>
          {
            data ? (
              t('OPERARIO.MODAL.TITULO_EDITAR')
            ) : (
              t('OPERARIO.MODAL.TITULO_NUEVO')
            )
          }
          </Modal.Title>
          {!submitting && data && data.idOperario && (
            <ButtonChangeRecord list={list} idElemento="idOperario" currentId={data.idOperario} getNextRecord={recuperarDatosModalOperario} />
          )}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Field
              id="nombreOperario"
              name="nombreOperario"
              colSm={4}
              component={InputText}
              controlLabel={t('OPERARIO.MODAL.NOMBRE') + '*'}
              claseActivo="nombre-operario"
              validate={required}
              maxLength={true}
              valorMaxLength={20}
            />
            <Field
              id="apellidosOperario"
              name="apellidosOperario"
              colSm={4}
              component={InputText}
              controlLabel={t('OPERARIO.MODAL.APELLIDOS')}
              claseActivo="apellidos-operario"
              maxLength={true}
              valorMaxLength={50}
            />
            <Field
              id="dniOperario"
              name="dniOperario"
              colSm={4}
              component={InputText}
              controlLabel={t('OPERARIO.MODAL.DNI')}
              claseActivo="dni-operario"
              maxLength={true}
              valorMaxLength={20}
            />
            <Field
              id="emailOperario"
              name="emailOperario"
              colSm={4}
              component={InputText}
              controlLabel={t('OPERARIO.MODAL.EMAIL')}
              claseActivo="email-operario"
              maxLength={true}
              valorMaxLength={100}
            />
            <Field
              id="telefonoOperario"
              name="telefonoOperario"
              colSm={4}
              component={InputText}
              controlLabel={t('OPERARIO.MODAL.TELEFONO')}
              claseActivo="telefono-operario"
              maxLength={true}
              valorMaxLength={50}
            />
            <Field
              id="movilOperario"
              name="movilOperario"
              colSm={4}
              component={InputText}
              controlLabel={t('OPERARIO.MODAL.MOVIL')}
              claseActivo="movil-operario"
              maxLength={true}
              valorMaxLength={50}
            />
            <Field
              id="direccionOperario"
              name="direccionOperario"
              colSm={4}
              component={InputText}
              controlLabel={t('OPERARIO.MODAL.DIRECCION')}
              claseActivo="direccion-operario"
              maxLength={true}
              valorMaxLength={100}
            />
            <Field
              id="cpOperario"
              name="cpOperario"
              colSm={4}
              component={InputText}
              controlLabel={t('OPERARIO.MODAL.CP')}
              claseActivo="cp-operario"
              maxLength={true}
              valorMaxLength={10}
            />
            <Field
              id="localidadOperario"
              name="localidadOperario"
              colSm={4}
              component={InputText}
              controlLabel={t('OPERARIO.MODAL.LOCALIDAD')}
              claseActivo="localidad-operario"
              maxLength={true}
              valorMaxLength={50}
            />
            <Field
              id="provinciaOperario"
              name="provincia"
              colSm={4}
              component={InputText}
              controlLabel={t('OPERARIO.MODAL.PROVINCIA')}
              claseActivo="provincia-operario"
              maxLength={true}
              valorMaxLength={50}
            />
            <Field
              id="paisOperario"
              name="paisOperario"
              colSm={4}
              component={InputText}
              controlLabel={t('OPERARIO.MODAL.PAIS')}
              claseActivo="pais-operario"
              maxLength={true}
              valorMaxLength={50}
            />
            <Field
              colSm={4}
              id="usuario"
              name="usuario"
              controlLabel={t('OPERARIO.MODAL.USUARIO')}
              component={InputSelect}
              options={comboUsuariosNoAsignadosOperario}
              valueKey="value"
              labelKey="label"
              customClass='nombre-operario'
            />
            <Field
              id="activo"
              name="activo"
              colSm={12}
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('OPERARIO.MODAL.ACTIVO')}
            />
            <Field
              id="opeProcesado"
              name="opeProcesado"
              colSm={12}
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('OPERARIO.MODAL.OPERARIO_DE_PROCESADO')}
            />
            {/*this.props.auth.modulos.includes(modulosConstant.PEDIDOS_CLIENTES) && (
              <Field
                id="opeAlbaran"
                name="opeAlbaran"
                colSm={12}
                inline
                component={InputCheckBox}
                claseActivo="onoffswitch-label"
                controlLabel={t('OPERARIO.MODAL.OPERARIO_DE_ALBARAN')}
              />
            )*/}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {
            this.props.data && (
              <Button
                type="button"
                className="btn btn-white btn-sm"
                disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.OPERARIOS] < 2) || submitting}
                onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.OPERARIOS] > 1) && !submitting ? handleSubmit(this.duplicar.bind(this)) : undefined}
              >
                {t('OPERARIO.MODAL.DUPLICAR')}
              </Button>
            )
          }
          {
            !this.props.data && (
              <ButtonLockable
                processing={submitting}
                hasIcon={true}
                showLoaderOnClick={true}
                type="button"
                className="btn btn-primary btn-sm"
                disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.OPERARIOS] < 2) || submitting}
                onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.OPERARIOS] > 1) && !submitting) ? handleSubmit(this.guardarYnuevoOperario.bind(this)) : () => {}}
              >
                {t('OPERARIO.MODAL.GUARDAR_Y_NUEVO')}
              </ButtonLockable>
            )
          }
          <ButtonLockable
            processing={submitting}
            hasIcon={true}
            showLoaderOnClick={true}
            type="button"
            className="btn btn-primary btn-sm"
            disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.OPERARIOS] < 2) || submitting}
            onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.OPERARIOS] > 1) && !submitting) ? handleSubmit(this.submitNuevaOperario.bind(this)) : () => {}}
          >
            {t('OPERARIO.MODAL.GUARDAR')}
          </ButtonLockable>
          <Button
            type="button"
            className={"btn btn-white btn-sm" + (submitting ? ' disabled' : '')}
            onClick={() => !submitting && closeModal()}
          >
            {t('OPERARIO.MODAL.CANCELAR')}
          </Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalOperario',
})(ModalOperario))