import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import {Row, Col, Tabs, Tab} from 'react-bootstrap'
import {AddCircleOutline, CloudUpload, Delete} from '@material-ui/icons'
// import { CloudUpload, Delete, Photo, PictureAsPdf, FileCopy, Warning } from '@material-ui/icons'
import Dropzone from 'react-dropzone'
import './PanelControl.scss'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import Button from '../comun/button/Button'
import ButtonLockable from '../comun/button/ButtonLockable'
import InputSelect from '../comun/form/InputSelect'
import InputText from '../comun/form/InputText'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputTextArea from '../comun/form/InputTextArea'
import InputNumerical from '../comun/form/InputNumerical'
import ModalTeclasContajePanelControlPage from '../../containers/panelControl/ModalTeclasContajePanelControlPage'
import ModalTipoDiluyentePanelControlPage from '../../containers/panelControl/ModalTipoDiluyentePanelControlPage'
import ModalTipoDosisPanelControlPage from '../../containers/panelControl/ModalTipoDosisPanelControlPage'
import ModalRectaColorimetroPage from '../../containers/panelControl/ModalRectaColorimetroPage'
import ListadoPage from '../../containers/page/ListadoPage'
import ModalSecuenciasNumeracionPage from '../../containers/panelControl/ModalSecuenciasNumeracionPage'
import {permisos as permisosConstant} from '../../constants/permisos'
import {modulos as modulosConstant} from '../../constants/modulos'

class PanelControl extends Component {
  constructor () {
    super()
    this.state = {
      'desabilitarEspermasContar': false,
      'formasAnormales': false,
      'motilidad': false,
      'motilesProgresivos': false,
      'motilesTotales': false,
      'concentracionMotilidadMasal': false,
      'disabledMotilidad': false,
      'disbledMotilesProgresivos': true,
      'disabledMotilesTotales': true,
      'disabledMotilidadMasal': true,
      'accepted': '',
      'rejected': '',
      'hasInvalidFile': false,
      'motilidadMasal':false,
      'motilidadTotal':false,
      'motilidadProgresiva':false,
      'gradoDilucion':false,
      'motilidadAlerta':false,
      'faCabeza':false,
      'faCola':false,
      'faProximal':false,
      'faDistal':false,
      'mostrarUrlMagavision': false,
      'deshabilitarGraficoEyaculadosUtilizadosEliminadosRazonUso': false,
      'deshabilitarGraficoVerracosRaza': false,
      'deshabilitarGraficoVerracosEstado': false,
      'deshabilitarGraficoVerracosRangoEdad': false,
      'deshabilitarGraficoTipoDosisProducidas': false,
      'deshabilitarGraficoInformacionGeneralCentro': false,
      'deshabilitarGraficoProduccionDia': false,
      'deshabilitarGraficoTareasHoy': false,
      'deshabilitarGraficoStockMateriales': false,
      'deshabilitarGraficoNumeroDosisProducidasDescartadasEdad': false,
      'deshabilitarGrafico': false,
      'metodoEvaluacion': null
    }
  }

  componentDidMount () {
    document.title = this.props.t('MENU.HERRAMIENTAS.PANEL_CONTROL') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    this.props.actions.obtenerDatosPanelControl(this.state.metodoEvaluacion)
    this.setState({motilidadMasal: true})
    this.setState({motilidadTotal: true})
    this.setState({motilidadProgresiva: true})
    this.setState({gradoDilucion: true})

    if (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.SECUENCIA] > 0) {
      this.props.actions.fecthSecuenciasNumeracion()
    }

    // this.props.change('metodoEvaluacion', {value: 1, label: this.props.t('COMUN.COMBOS.TIPO_ANALISIS_EXTRACCION.COLORIMETRO')})
  }

  guardarPanelControl (values) {
    return new Promise((resolve, reject) => {
      if (this.state.accepted === ''){
        values.Firma = null
      } else {
        values.Firma = this.props.imagen
      }
      const graficosMostrar = []
      if (values.eyaculados_utilizados_eliminados_razon_uso === true) {
        graficosMostrar.push(1)
      }
      if (values.verracos_por_raza === true) {
        graficosMostrar.push(2)
      }
      if (values.verracos_por_estado === true) {
        graficosMostrar.push(3)
      }
      if (values.verracos_por_rango_edad === true) {
        graficosMostrar.push(4)
      }
      if (values.tipo_dosis_producidas === true) {
        graficosMostrar.push(5)
      }
      if (values.informacion_general_centro === true) {
        graficosMostrar.push(6)
      }
      if (values.produccion_del_dia === true) {
        graficosMostrar.push(7)
      }
      if (values.tareas_para_hoy === true) {
        graficosMostrar.push(8)
      }
      if (values.stock_materiales === true) {
        graficosMostrar.push(9)
      }
      if (values.numero_dosis_producidas_descartadas_edad === true) {
        graficosMostrar.push(10)
      }
      values.graficosMostrar = graficosMostrar
      values.idPanelControl = this.props.datosPanel.idPanelControl
      this.props.actions.guardarPanelControl(values, resolve, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.formasAnormales === false && prevState.formasAnormales !== this.state.formasAnormales && this.state.motilidad === false && this.state.motilesProgresivos === false &&
      this.state.motilesTotales === false) {
      this.props.change('ConcentracionSinCorrecciones', true)
    }
    if (this.state.formasAnormales === false && prevState.motilidad !== this.state.motilidad && this.state.motilidad === false && this.state.motilesProgresivos === false &&
      this.state.motilesTotales === false) {
      this.props.change('ConcentracionSinCorrecciones', true)
    }
    if (this.state.formasAnormales === false && prevState.motilesProgresivos !== this.state.motilesProgresivos && this.state.motilidad === false && this.state.motilesProgresivos === false
      && this.state.motilesTotales === false) {
      this.props.change('ConcentracionSinCorrecciones', true)
    }
    if (this.state.formasAnormales === false && prevState.motilesTotales !== this.state.motilesTotales && this.state.motilidad === false && this.state.motilesProgresivos === false &&
      this.state.motilesTotales === false) {
      this.props.change('ConcentracionSinCorrecciones', true)
    }
    /*if (this.state.formasAnormales === false && this.state.motilesTotales && this.state.motilidad === false && this.state.motilesProgresivos === false &&
      this.state.motilesTotales === false && this.state.concentracionMotilidadMasal === false  && prevState.concentracionMotilidadMasal !== this.state.concentracionMotilidadMasal) {
      this.props.change('ConcentracionSinCorrecciones', true)
    }*/

    if (Object.keys(this.props.datosPanel).length > 0) {
      if (this.props.datosPanel.Firma !== prevProps.datosPanel.Firma && this.state.accepted === '') {
        if (this.props.datosPanel.Firma !== null) {
          this.setState({accepted: this.props.datosPanel.Firma.file.imagebase64})
        }
      }
    }

    if (JSON.stringify(this.props.datosPanel) !== JSON.stringify(prevProps.datosPanel) || JSON.stringify(this.props.formPanelControl) !== JSON.stringify(prevProps.formPanelControl)) {
      this.setState({metodoEvaluacion: this.props.formPanelControl.metodoEvaluacion ? this.props.formPanelControl.metodoEvaluacion.value : null})
      if (this.state.metodoEvaluacion === null) {
        this.props.actions.obtenerDatosPanelControl({value: this.state.metodoEvaluacion})
        this.seleccionarMetodoEvaluacionClick({value: this.props.datosPanel.metodoEvaluacion})
      }
      if (Object.keys(this.props.datosPanel).length > 0){
        if (this.props.datosPanel.metodoEvaluacion !== prevProps.datosPanel.metodoEvaluacion) {
          if (this.props.datosPanel.metodoEvaluacion === 1 && prevProps.datosPanel.metodoEvaluacion !== 1) {
            this.seleccionarMetodoEvaluacionClick({value: 1})
            this.setState({metodoEvaluacion: 1})
          } else if (this.props.datosPanel.metodoEvaluacion === 2 && prevProps.datosPanel.metodoEvaluacion !== 2) {
            this.seleccionarMetodoEvaluacionClick({value: 2})
            this.setState({metodoEvaluacion: 2})
          } else if (this.props.datosPanel.metodoEvaluacion === 3 && prevProps.datosPanel.metodoEvaluacion !== 3) {
            this.seleccionarMetodoEvaluacionClick({value: 3})
            this.setState({metodoEvaluacion: 3})
          } else if (this.props.datosPanel.metodoEvaluacion === 4 && prevProps.datosPanel.metodoEvaluacion !== 4) {
            this.seleccionarMetodoEvaluacionClick({value: 4})
            this.setState({metodoEvaluacion: 4})
          } if (this.props.datosPanel.metodoEvaluacion === 5 && prevProps.datosPanel.metodoEvaluacion !== 5) {
            this.seleccionarMetodoEvaluacionClick({value: 5})
            this.setState({metodoEvaluacion: 5})
          }
        } else if (this.props.formPanelControl.metodoEvaluacion && this.props.formPanelControl.metodoEvaluacion !== prevProps.formPanelControl.metodoEvaluacion) {
          if (this.props.formPanelControl.metodoEvaluacion === 1 && prevProps.formPanelControl.metodoEvaluacion !== 1) {
            this.seleccionarMetodoEvaluacionClick({value: 1})
            this.setState({metodoEvaluacion: 1})
          } else if (this.props.formPanelControl.metodoEvaluacion === 2 && prevProps.formPanelControl.metodoEvaluacion !== 2) {
            this.seleccionarMetodoEvaluacionClick({value: 2})
            this.setState({metodoEvaluacion: 2})
          } else if (this.props.formPanelControl.metodoEvaluacion === 3 && prevProps.formPanelControl.metodoEvaluacion !== 3) {
            this.seleccionarMetodoEvaluacionClick({value: 3})
            this.setState({metodoEvaluacion: 3})
          } else if (this.props.formPanelControl.metodoEvaluacion === 4 && prevProps.formPanelControl.metodoEvaluacion !== 4) {
            this.seleccionarMetodoEvaluacionClick({value: 4})
            this.setState({metodoEvaluacion: 4})
          } if (this.props.formPanelControl.metodoEvaluacion === 5 && prevProps.formPanelControl.metodoEvaluacion !== 5) {
            this.seleccionarMetodoEvaluacionClick({value: 5})
            this.setState({metodoEvaluacion: 5})
          }
        }
      }
    }
    if (this.props.formPanelControl !== prevProps.formPanelControl) {
      let numeroGraficos=0
      if (this.props.formPanelControl.eyaculados_utilizados_eliminados_razon_uso === true) {
        numeroGraficos++
      }
      if (this.props.formPanelControl.verracos_por_raza === true) {
        numeroGraficos++
      }
      if (this.props.formPanelControl.verracos_por_estado === true) {
        numeroGraficos++
      }
      if (this.props.formPanelControl.verracos_por_rango_edad === true) {
        numeroGraficos++
      }
      if (this.props.formPanelControl.tipo_dosis_producidas === true) {
        numeroGraficos++
      }
      if (this.props.formPanelControl.informacion_general_centro === true) {
        numeroGraficos++
      }
      if (this.props.formPanelControl.produccion_del_dia === true) {
        numeroGraficos++
      }
      if (this.props.formPanelControl.tareas_para_hoy === true) {
        numeroGraficos++
      }
      if (this.props.formPanelControl.stock_materiales === true) {
        numeroGraficos++
      }
      if (this.props.formPanelControl.numero_dosis_producidas_descartadas_edad === true) {
        numeroGraficos++
      }
      if(numeroGraficos===4){
        if (this.props.formPanelControl.eyaculados_utilizados_eliminados_razon_uso === false) {
          this.setState({deshabilitarGraficoEyaculadosUtilizadosEliminadosRazonUso: true})
        }
        if (this.props.formPanelControl.verracos_por_raza === false) {
          this.setState({deshabilitarGraficoVerracosRaza: true})
        }
        if (this.props.formPanelControl.verracos_por_estado === false) {
          this.setState({deshabilitarGraficoVerracosEstado: true})
        }
        if (this.props.formPanelControl.verracos_por_rango_edad === false) {
          this.setState({deshabilitarGraficoVerracosRangoEdad: true})
        }
        if (this.props.formPanelControl.tipo_dosis_producidas === false) {
          this.setState({deshabilitarGraficoTipoDosisProducidas: true})
        }
        if (this.props.formPanelControl.informacion_general_centro === false) {
          this.setState({deshabilitarGraficoInformacionGeneralCentro: true})
        }
        if (this.props.formPanelControl.produccion_del_dia === false) {
          this.setState({deshabilitarGraficoProduccionDia: true})
        }
        if (this.props.formPanelControl.tareas_para_hoy === false) {
          this.setState({deshabilitarGraficoTareasHoy: true})
        }
        if (this.props.formPanelControl.stock_materiales === false) {
          this.setState({deshabilitarGraficoStockMateriales: true})
        }
        if (this.props.formPanelControl.numero_dosis_producidas_descartadas_edad === false) {
          this.setState({deshabilitarGraficoNumeroDosisProducidasDescartadasEdad: true})
        }
      }
      else{
        this.setState({deshabilitarGraficoEyaculadosUtilizadosEliminadosRazonUso: false})
        this.setState({deshabilitarGraficoVerracosRaza: false})
        this.setState({deshabilitarGraficoVerracosEstado: false})
        this.setState({deshabilitarGraficoVerracosRangoEdad: false})
        this.setState({deshabilitarGraficoTipoDosisProducidas: false})
        this.setState({deshabilitarGraficoInformacionGeneralCentro: false})
        this.setState({deshabilitarGraficoProduccionDia: false})
        this.setState({deshabilitarGraficoTareasHoy: false})
        this.setState({deshabilitarGraficoStockMateriales: false})
        this.setState({deshabilitarGraficoNumeroDosisProducidasDescartadasEdad: false})
      }
    }
    if (this.props.datosPanel !== prevProps.datosPanel) {
      if (Object.keys(this.props.datosPanel).length > 0) {
        if (this.props.datosPanel.Firma !== null && Object.keys(this.props.datosPanel.Firma).length > 0) {
          if (this.props.datosPanel.Firma.file !== null) {
            this.setState({accepted: this.props.datosPanel.Firma.file.imagebase64})
            let file = {
              name: this.props.datosPanel.Firma.name,
              file: {
                name: this.props.datosPanel.Firma.name,
                path: this.props.datosPanel.Firma.name,
                imagebase64: this.props.datosPanel.Firma.file.imagebase64
              }
            }
            this.props.actions.uploadFilePanelControl(file.file)
          }
        }
      }
    }
  }

  seleccionarMetodoEvaluacion (value) {
    this.setState({metodoEvaluacion: value.value})
    this.props.actions.obtenerDatosPanelControl(value)
    this.seleccionarMetodoEvaluacionClick(value)
  }

  seleccionarMetodoEvaluacionClick (value){
    if (value.value === 1){
      this.setState({formasAnormales: false})
      this.setState({motilidad: false})
      this.setState({motilesProgresivos: false})
      this.setState({motilesTotales: false})
      this.setState({concentracionMotilidadMasal: false})
      this.setState({disabledMotilidad: false})
      this.setState({disbledMotilesProgresivos: true})
      this.setState({disabledMotilesTotales: true})
      this.setState({disabledMotilidadMasal: true})
      this.setState({desabilitarEspermasContar: false})
      this.setState({motilidadMasal: true})
      this.setState({motilidadTotal: true})
      this.setState({motilidadProgresiva: true})
      this.setState({gradoDilucion: true})
      this.setState({motilidadAlerta: false})
      this.setState({faCabeza: false})
      this.setState({faCola: false})
      this.setState({faProximal: false})
      this.setState({faDistal: false})
      this.setState({mostrarUrlMagavision: false})
    }
    if (value.value === 2){
      this.setState({formasAnormales: false})
      this.setState({motilidad: false})
      this.setState({motilesProgresivos: false})
      this.setState({motilesTotales: false})
      this.setState({concentracionMotilidadMasal: false})
      this.setState({disabledMotilidad: false})
      this.setState({disbledMotilesProgresivos: true})
      this.setState({disabledMotilesTotales: true})
      this.setState({disabledMotilidadMasal: true})
      this.setState({desabilitarEspermasContar: false})
      this.setState({motilidadMasal: true})
      this.setState({motilidadTotal: true})
      this.setState({motilidadProgresiva: true})
      this.setState({gradoDilucion: true})
      this.setState({motilidadAlerta: false})
      this.setState({faCabeza: false})
      this.setState({faCola: false})
      this.setState({faProximal: false})
      this.setState({faDistal: false})
      this.setState({mostrarUrlMagavision: false})
    }
    if (value.value === 3){
      this.setState({formasAnormales: false})
      this.setState({motilidad: false})
      this.setState({motilesProgresivos: false})
      this.setState({motilesTotales: false})
      this.setState({concentracionMotilidadMasal: false})
      this.setState({disabledMotilidad: true})
      this.setState({disbledMotilesProgresivos: false})
      this.setState({disabledMotilesTotales: false})
      this.setState({disabledMotilidadMasal: false})
      this.setState({desabilitarEspermasContar: true})
      this.setState({motilidadAlerta: true})
      this.setState({motilidadMasal: true})
      this.setState({gradoDilucion: true})
      this.setState({faCabeza: true})
      this.setState({faCola: true})
      this.setState({faProximal: true})
      this.setState({faDistal: true})
      this.setState({motilidadTotal: false})
      this.setState({motilidadProgresiva: false})
      this.setState({mostrarUrlMagavision: false})
    }
    if (value.value === 4){
      this.setState({formasAnormales: false})
      this.setState({motilidad: false})
      this.setState({motilesProgresivos: false})
      this.setState({motilesTotales: false})
      this.setState({concentracionMotilidadMasal: false})
      this.setState({disabledMotilidad: true})
      this.setState({disbledMotilesProgresivos: true})
      this.setState({disabledMotilesTotales: true})
      this.setState({disabledMotilidadMasal: false})
      this.setState({desabilitarEspermasContar: true})
      this.setState({motilidadAlerta: true})
      this.setState({motilidadTotal: true})
      this.setState({motilidadProgresiva: true})
      this.setState({gradoDilucion: true})
      this.setState({faCabeza: true})
      this.setState({faCola: true})
      this.setState({faProximal: true})
      this.setState({faDistal: true})
      this.setState({motilidadMasal: false})
      this.setState({mostrarUrlMagavision: false})
    }

    if (value.value === 5){
      this.setState({formasAnormales: false})
      this.setState({motilidad: false})
      this.setState({motilesProgresivos: false})
      this.setState({motilesTotales: false})
      this.setState({concentracionMotilidadMasal: false})
      this.setState({disabledMotilidad: true})
      this.setState({disbledMotilesProgresivos: false})
      this.setState({disabledMotilesTotales: false})
      this.setState({disabledMotilidadMasal: false})
      this.setState({desabilitarEspermasContar: true})
      this.setState({motilidadAlerta: true})
      this.setState({motilidadMasal: true})
      this.setState({gradoDilucion: true})
      this.setState({faCabeza: true})
      this.setState({faCola: false})
      this.setState({faProximal: false})
      this.setState({faDistal: false})
      this.setState({motilidadTotal: false})
      this.setState({mostrarUrlMagavision: true})
      this.setState({motilidadProgresiva: false})
    }
  }

  handleChangeSinCorrecciones (value) {
  if (value === true){
      this.props.change('ConcentracionDosisFormasAnormales', false)
      this.props.change('ConcentracionDosisMotilidad', false)
      this.props.change('ConcentracionDosisMotilesProgresivos', false)
      this.props.change('ConcentracionDosisMotilesTotales', false)
      this.props.change('ConcentracionDosisMotilidaMasal', false)
      if (this.props.formPanelControl.metodoEvaluacion.value === 3 || this.props.formPanelControl.metodoEvaluacion.value === 5) {
        this.setState({disabledMotilesTotales: false})
        this.setState({disabledMotilidadMasal: false})
        this.setState({disbledMotilesProgresivos: false})
      }
    }
  }

  handleChangeFormasAnormales (value) {
    if (value === true){
      this.props.change('ConcentracionSinCorrecciones', false)
      this.setState({formasAnormales: true})
    } else {
      this.setState({formasAnormales: false})
    }
  }
  handleChangeMotilidad (value) {
    if (value === true){
      this.props.change('ConcentracionSinCorrecciones', false)
      this.setState({motilidad: true})
    } else {
      this.setState({motilidad: false})
    }
  }
  handleChangeMotilesProgresivos (value) {
    if (value === true){
      this.props.change('ConcentracionSinCorrecciones', false)
      this.setState({motilesProgresivos: true})
      if (this.props.formPanelControl.metodoEvaluacion.value === 3 || this.props.formPanelControl.metodoEvaluacion.value === 5) {
        this.setState({disabledMotilesTotales: true})
        this.setState({disabledMotilidadMasal: true})
        this.props.change('ConcentracionDosisMotilesTotales', false)
        this.props.change('ConcentracionDosisMotilidaMasal', false)
      }
    } else {
      this.setState({motilesProgresivos: false})
      if (this.props.formPanelControl.metodoEvaluacion.value === 3 || this.props.formPanelControl.metodoEvaluacion.value === 5) {
        this.setState({disabledMotilesTotales: false})
        this.setState({disabledMotilidadMasal: false})
      }
    }
  }
  handleChangeMotilesTotales (value) {
    if (value === true){
      this.props.change('ConcentracionSinCorrecciones', false)
      this.setState({motilesTotales: true})
      if (this.props.formPanelControl.metodoEvaluacion.value === 3 || this.props.formPanelControl.metodoEvaluacion.value === 5) {
        this.setState({disbledMotilesProgresivos: true})
        this.setState({disabledMotilidadMasal: true})
        this.props.change('ConcentracionDosisMotilesProgresivos', false)
        this.props.change('ConcentracionDosisMotilidaMasal', false)
      }
    } else {
      this.setState({motilesTotales: false})
      if (this.props.formPanelControl.metodoEvaluacion.value === 3 || this.props.formPanelControl.metodoEvaluacion.value === 5) {
        this.setState({disbledMotilesProgresivos: false})
        this.setState({disabledMotilidadMasal: false})
      }
    }
  }

  handleChangeMotilidadMasal (value) {
    if (value === true){
      this.props.change('ConcentracionSinCorrecciones', false)
      this.setState({concentracionMotilidadMasal: true})
      this.setState({motilesProgresivos: true})
      if (this.props.formPanelControl.metodoEvaluacion.value === 3 || this.props.formPanelControl.metodoEvaluacion.value === 5) {
        this.setState({disbledMotilesProgresivos: true})
        this.setState({disabledMotilesTotales: true})
        this.props.change('ConcentracionDosisMotilesTotales', false)
        this.props.change('ConcentracionDosisMotilesProgresivos', false)
      }
    } else {
      this.setState({concentracionMotilidadMasal: false})
      if (this.props.formPanelControl.metodoEvaluacion.value === 3 || this.props.formPanelControl.metodoEvaluacion.value === 5) {
        this.setState({disbledMotilesProgresivos: false})
        this.setState({disabledMotilesTotales: false})
      }
    }
  }

  handleChangeGraficosMostrarDashboard (value) {
    let numeroGraficos=0
    if (this.props.formPanelControl.eyaculados_utilizados_eliminados_razon_uso === true) {
      numeroGraficos++
    }
    if (this.props.formPanelControl.verracos_por_raza === true) {
      numeroGraficos++
    }
    if (this.props.formPanelControl.verracos_por_estado === true) {
      numeroGraficos++
    }
    if (this.props.formPanelControl.verracos_por_rango_edad === true) {
      numeroGraficos++
    }
    if (this.props.formPanelControl.tipo_dosis_producidas === true) {
      numeroGraficos++
    }
    if (this.props.formPanelControl.informacion_general_centro === true) {
      numeroGraficos++
    }
    if (this.props.formPanelControl.produccion_del_dia === true) {
      numeroGraficos++
    }
    if (this.props.formPanelControl.tareas_para_hoy === true) {
      numeroGraficos++
    }
    if (this.props.formPanelControl.stock_materiales === true) {
      numeroGraficos++
    }
    if (this.props.formPanelControl.numero_dosis_producidas_descartadas_edad === true) {
      numeroGraficos++
    }
    if(numeroGraficos==4){
      if (this.props.formPanelControl.eyaculados_utilizados_eliminados_razon_uso === false) {
        this.setState({deshabilitarGraficoEyaculadosUtilizadosEliminadosRazonUso: true})
      }
      if (this.props.formPanelControl.verracos_por_raza === false) {
        this.setState({deshabilitarGraficoVerracosRaza: true})
      }
      if (this.props.formPanelControl.verracos_por_estado === false) {
        this.setState({deshabilitarGraficoVerracosEstado: true})
      }
      if (this.props.formPanelControl.verracos_por_rango_edad === false) {
        this.setState({deshabilitarGraficoVerracosRangoEdad: true})
      }
      if (this.props.formPanelControl.tipo_dosis_producidas === false) {
        this.setState({deshabilitarGraficoTipoDosisProducidas: true})
      }
      if (this.props.formPanelControl.informacion_general_centro === false) {
        this.setState({deshabilitarGraficoInformacionGeneralCentro: true})
      }
      if (this.props.formPanelControl.produccion_del_dia === false) {
        this.setState({deshabilitarGraficoProduccionDia: true})
      }
      if (this.props.formPanelControl.tareas_para_hoy === false) {
        this.setState({deshabilitarGraficoTareasHoy: true})
      }
      if (this.props.formPanelControl.stock_materiales === false) {
        this.setState({deshabilitarGraficoStockMateriales: true})
      }
      if (this.props.formPanelControl.numero_dosis_producidas_descartadas_edad === false) {
        this.setState({deshabilitarGraficoNumeroDosisProducidasDescartadasEdad: true})
      }
    }
    else{
      this.setState({deshabilitarGraficoEyaculadosUtilizadosEliminadosRazonUso: false})
      this.setState({deshabilitarGraficoVerracosRaza: false})
      this.setState({deshabilitarGraficoVerracosEstado: false})
      this.setState({deshabilitarGraficoVerracosRangoEdad: false})
      this.setState({deshabilitarGraficoTipoDosisProducidas: false})
      this.setState({deshabilitarGraficoInformacionGeneralCentro: false})
      this.setState({deshabilitarGraficoProduccionDia: false})
      this.setState({deshabilitarGraficoTareasHoy: false})
      this.setState({deshabilitarGraficoStockMateriales: false})
      this.setState({deshabilitarGraficoNumeroDosisProducidasDescartadasEdad: false})
    }
  }

  abrirModalTeclasContaje () {
    this.props.actions.abrirModalTeclasContaje()
  }

  abrirModalTipoDiluyente () {
    this.props.actions.abrirModalTipoDiluyente()
  }

  abrirModalTipoDosis () {
    this.props.actions.abrirModalTipoDosis()
  }

  abrirModalRectaColorimetro () {
    this.props.actions.abrirModalRectaColorimetro()
  }

  valoresPredefinidosPanelControl (values) {
    return new Promise((resolve, reject) => {
      this.props.actions.valoresPredefinidosPanelControl(this.state.metodoEvaluacion, resolve, reject)
      this.seleccionarMetodoEvaluacionClick({value: this.state.metodoEvaluacion})
      window.scrollTo(0, 0)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }
  obtenerValoresPanelControl () {
    this.props.actions.obtenerDatosPanelControl({value: this.props.datosPanel.metodoEvaluacion})
    this.seleccionarMetodoEvaluacionClick({value: this.props.datosPanel.metodoEvaluacion})
    window.scrollTo(0, 0)
  }

  onDrop (fileToUpload, rejectedFiles) {
    if (fileToUpload.length === 0 && rejectedFiles.length > 0) {
      this.setState({hasInvalidFile: true})
    } else {
      this.setState({accepted: fileToUpload.accepted[0]})
      if (fileToUpload.accepted.length > 0) {
        var reader = new FileReader();
        reader.onloadend = () => {
          var imagebase64=reader.result
          fileToUpload.accepted[0].imagebase64 = imagebase64
          this.setState({accepted: imagebase64})
        }
        this.props.actions.uploadFilePanelControl(fileToUpload.accepted[0])
        // console.log(fileToUpload.accepted[0])
        reader.readAsDataURL(fileToUpload.accepted[0])
      }
    }
  }

  eliminaImagenFirma () {
    this.setState({accepted: ''})
  }

  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual, handleSubmit, submitting, urlRecursos, dataSecuenciaNumeraciones, listSecuenciaNumeraciones,
      actions: {recuperarSecuenciaNumeraciones},
      auth: {versionesMagavision}
    } = this.props

    let arrayVersionesMagavision = versionesMagavision !== null ? versionesMagavision.split(',') : []
    let comboTipoAnalisisPanelControl = this.props.combos.comboTipoAnalisisPanelControl
    let comboMetodoEvaluacion = [
      {value: comboTipoAnalisisPanelControl[0].value, label: t(`COMUN.COMBOS.${comboTipoAnalisisPanelControl[0].label}`)},
      {value: comboTipoAnalisisPanelControl[1].value, label: t(`COMUN.COMBOS.${comboTipoAnalisisPanelControl[1].label}`)}
    ]

    if (versionesMagavision !== null) {
      arrayVersionesMagavision.forEach((version) => {
        comboTipoAnalisisPanelControl.forEach((tipoAnalisisExtraccionItem) => {
          if (version === tipoAnalisisExtraccionItem.value.toString()){
            comboMetodoEvaluacion.push({value: tipoAnalisisExtraccionItem.value, label: t(`COMUN.COMBOS.${tipoAnalisisExtraccionItem.label}`)})
          }
        })
      })
    }

    let comboConcentracionUtilAplicada = [
      {value: false, label: t(`PANEL_CONTROL.CONCENTRACION_UTIL`)},
      {value: true, label: t(`PANEL_CONTROL.CONCENTRACION_APLICADA`)}
    ]

    // TABLA SECUENCIA NUMERACIONES
    listSecuenciaNumeraciones.forEach(
      (row) => row.idElemento = row.idSecuencia
    )
    const table = {
      id: 'secuenciaNumeracionesTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: !submitting,
      mostrarDuplicar: false,
      mostrarCambioEstado: false,
      mostrarEliminar: false,
      columns: [
        // {id: 'nombre', name: t('PANEL_CONTROL.TABLA_SECUENCIA_NUMERACIONES.NOMBRE')},
        {id: 'tipo', name: t('PANEL_CONTROL.TABLA_SECUENCIA_NUMERACIONES.TIPO')},
        {id: 'prefijo', name: t('PANEL_CONTROL.TABLA_SECUENCIA_NUMERACIONES.PREFIJO')},
        {id: 'sufijo', name: t('PANEL_CONTROL.TABLA_SECUENCIA_NUMERACIONES.SUFIJO')},
        {id: 'longitudCentral', name: t('PANEL_CONTROL.TABLA_SECUENCIA_NUMERACIONES.LONGITUD')},
        {id: 'numSiguiente', name: t('PANEL_CONTROL.TABLA_SECUENCIA_NUMERACIONES.NUM_SIGUIENTE')},
        {id: 'incremento', name: t('PANEL_CONTROL.TABLA_SECUENCIA_NUMERACIONES.INCREMENTO')}
      ],
      rows: listSecuenciaNumeraciones,
      onEdit: (row) => !submitting && recuperarSecuenciaNumeraciones(row.idSecuencia),
      data: dataSecuenciaNumeraciones,
      initialValues: {}
    }

    return (
      <div className="content-panel-control">
        <SimpleModalPage/>
        <MensajeAlertaPage/>
        {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TECLAS_CONTAJE] > 0) && (
          <ModalTeclasContajePanelControlPage />
        )}
        {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPO_DILUYENTE] > 0) && (
          <ModalTipoDiluyentePanelControlPage />
        )}
        {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPO_DOSIS] > 0) && (
          <ModalTipoDosisPanelControlPage />
        )}
        {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.RECTA_COLORIMETRO] > 0) && (
          <ModalRectaColorimetroPage />
        )}
        <h2 className="page-title">{t('PANEL_CONTROL.TITULO')}</h2>
        <form>
          <Tabs defaultActiveKey={1} id="tab-panel-control" className="tab-panel-control">
            <Tab eventKey={1} title={t('PANEL_CONTROL.TAB.METODO_ANALISIS')}>
              <Row>
                <Col sm={6}>
                  <Col sm={12}><h4>{t('PANEL_CONTROL.GENERALES')}</h4></Col>
                  <Field
                    colSm={6}
                    id="metodoEvaluacion"
                    name="metodoEvaluacion"
                    component={InputSelect}
                    options={comboMetodoEvaluacion}
                    controlLabel={t('PANEL_CONTROL.METODO_EVALUACION')}
                    customClass='metodo-evaluacion'
                    onInputChange={this.seleccionarMetodoEvaluacion.bind(this)}
                    isClearable={false}
                  />
                  <Field
                    colSm={6}
                    id="espermatozoidesContar"
                    name="espermatozoidesContar"
                    controlLabel={t('PANEL_CONTROL.ESPERMATOZOIDES_CONTAR')}
                    component={InputNumerical}
                    // disabled={this.state.desabilitarEspermasContar === true ? true : false}
                  />
                  {
                    this.state.mostrarUrlMagavision === true ? (
                      <Field
                        colSm={12}
                        id="urlMagavision"
                        name="urlMagavision"
                        controlLabel={t('PANEL_CONTROL.URL_MAGAVISION')}
                        component={InputText}
                        maxLength={true}
                        valorMaxLength={160}
                      />
                    ) : null
                  }
                  <div className="clearfix"></div>
                  <Col sm={6}>
                    <Button
                      type="button"
                      className="btn btn-primary nuevo"
                      disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.RECTA_COLORIMETRO] < 1) || submitting}
                      onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.RECTA_COLORIMETRO] > 0) && !submitting ? () => this.abrirModalRectaColorimetro() : undefined}
                    >
                      <AddCircleOutline></AddCircleOutline> {t('PANEL_CONTROL.RECTA_COLORIMETRO')}
                    </Button>
                  </Col>
                  <Col sm={6} style={{marginBottom: '10px'}}>
                    <Button
                      type="button"
                      className="btn btn-primary nuevo"
                      disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.TECLAS_CONTAJE] < 1) || submitting}
                      onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TECLAS_CONTAJE] > 0) && !submitting ? () => this.abrirModalTeclasContaje() : undefined}
                    >
                      <AddCircleOutline></AddCircleOutline> {t('PANEL_CONTROL.TECLAS_CONTAJE')}
                    </Button>
                  </Col>
                  <Col sm={6}>
                    <Button
                      type="button"
                      className="btn btn-primary nuevo"
                      disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.TIPO_DILUYENTE] < 1) || submitting}
                      onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPO_DILUYENTE] > 0) && !submitting ? () => this.abrirModalTipoDiluyente() : undefined}
                    >
                      <AddCircleOutline></AddCircleOutline> {t('PANEL_CONTROL.TIPO_DILUYENTE')}
                    </Button>
                  </Col>
                  <Col sm={6}>
                    <Button
                      type="button"
                      className="btn btn-primary nuevo"
                      disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.TIPO_DOSIS] < 1) || submitting}
                      onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPO_DOSIS] > 0) && !submitting ? () => this.abrirModalTipoDosis() : undefined}
                    >
                      <AddCircleOutline></AddCircleOutline> {t('PANEL_CONTROL.TIPOS_DOSIS')}
                    </Button>
                  </Col>
                  <div className="clearfix"></div>
                <hr/>
                <Col sm={12}><h4>{t('PANEL_CONTROL.DEFINICION_CONCENTRACION_DOSIS')}</h4></Col>
                  <Field
                    id="ConcentracionSinCorrecciones"
                    name="ConcentracionSinCorrecciones"
                    colSm={2}
                    inline
                    component={InputCheckBox}
                    claseActivo="onoffswitch-label"
                    controlLabel={t('PANEL_CONTROL.SIN_CORRECCIONES')}
                    onInputChange={this.handleChangeSinCorrecciones.bind(this)}
                  />
                  <Field
                    id="ConcentracionDosisFormasAnormales"
                    name="ConcentracionDosisFormasAnormales"
                    colSm={2}
                    inline
                    component={InputCheckBox}
                    claseActivo="onoffswitch-label"
                    controlLabel={t('PANEL_CONTROL.FORMAS_ANORMALES')}
                    onInputChange={this.handleChangeFormasAnormales.bind(this)}
                  />
                  <Field
                    id="ConcentracionDosisMotilidad"
                    name="ConcentracionDosisMotilidad"
                    colSm={2}
                    inline
                    component={InputCheckBox}
                    claseActivo="onoffswitch-label"
                    controlLabel={t('PANEL_CONTROL.MOTILIDAD')}
                    onInputChange={this.handleChangeMotilidad.bind(this)}
                    disabled={this.state.disabledMotilidad}
                  />
                  <Field
                    id="ConcentracionDosisMotilesProgresivos"
                    name="ConcentracionDosisMotilesProgresivos"
                    colSm={2}
                    inline
                    component={InputCheckBox}
                    claseActivo="onoffswitch-label"
                    controlLabel={t('PANEL_CONTROL.MOTILES_PROGRESIVOS')}
                    onInputChange={this.handleChangeMotilesProgresivos.bind(this)}
                    disabled={this.state.disbledMotilesProgresivos}
                  />
                  <Field
                    id="ConcentracionDosisMotilesTotales"
                    name="ConcentracionDosisMotilesTotales"
                    colSm={2}
                    inline
                    component={InputCheckBox}
                    claseActivo="onoffswitch-label"
                    controlLabel={t('PANEL_CONTROL.MOTILES_TOTALES')}
                    onInputChange={this.handleChangeMotilesTotales.bind(this)}
                    disabled={this.state.disabledMotilesTotales}
                  />
                  {/*<Field
                    id="ConcentracionDosisMotilidaMasal"
                    name="ConcentracionDosisMotilidaMasal"
                    colSm={2}
                    inline
                    component={InputCheckBox}
                    claseActivo="onoffswitch-label"
                    controlLabel={t('PANEL_CONTROL.MOTILILIDAD_MASAL')}
                    onInputChange={this.handleChangeMotilidadMasal.bind(this)}
                    disabled={this.state.disabledMotilidadMasal}
                  />*/}
                  <div className="clearfix"></div>
                </Col>
                <Col sm={6} style={{borderLeft: '1px solid #eee'}}>
                  <Col sm={12}><h4>{t('PANEL_CONTROL.ALERTAS')}</h4></Col>
                  <Col sm={7}>
                    <Col sm={6}></Col>
                    <Col sm={3} style={{textAlign: 'center'}}><b>{t('PANEL_CONTROL.MIN')}</b></Col>
                    <Col sm={3} style={{textAlign: 'center'}}><b>{t('PANEL_CONTROL.MAX')}</b></Col>
                    <div className="clearfix"></div>
                    <Col sm={6} style={{textAlign: 'right', paddingTop: '5px'}}><b>{t('PANEL_CONTROL.VOLUMEN')}</b></Col>
                    <Field
                      colSm={3}
                      id="AlertaVolumenMinimo"
                      name="AlertaVolumenMinimo"
                      component={InputNumerical}
                      customClass='alertas-panel'
                    />
                    <Field
                      colSm={3}
                      id="AlertaVolumenMaximo"
                      name="AlertaVolumenMaximo"
                      component={InputNumerical}
                      customClass='alertas-panel'
                    />
                    <Col sm={6} style={{textAlign: 'right', paddingTop: '5px'}}><b>{t('PANEL_CONTROL.MOTILIDAD')}</b></Col>
                    <Field
                      colSm={3}
                      id="AlertaMotilidadMinima"
                      name="AlertaMotilidadMinima"
                      component={InputNumerical}
                      customClass='alertas-panel'
                      disabled={this.state.motilidadAlerta}
                    />
                    <Field
                      colSm={3}
                      id="AlertaMotilidadMaxima"
                      name="AlertaMotilidadMaxima"
                      component={InputNumerical}
                      customClass='alertas-panel'
                      disabled={this.state.motilidadAlerta}
                    />
                    <Col sm={6} style={{textAlign: 'right', paddingTop: '5px'}}><b>{t('PANEL_CONTROL.MOTILIDAD_MASAL')}</b></Col>
                    <Field
                      colSm={3}
                      id="AlertaMotilidadMasalMinima"
                      name="AlertaMotilidadMasalMinima"
                      component={InputNumerical}
                      customClass='alertas-panel'
                      disabled={this.state.motilidadMasal}
                    />
                    <Field
                      colSm={3}
                      id="AlertaMotilidadMasalMaxima"
                      name="AlertaMotilidadMasalMaxima"
                      component={InputNumerical}
                      customClass='alertas-panel'
                      disabled={this.state.motilidadMasal}
                    />
                    <Col sm={6} style={{textAlign: 'right', paddingTop: '5px'}}><b>{t('PANEL_CONTROL.CONCENTRACION')}</b></Col>
                    <Field
                      colSm={3}
                      id="AlertaConcentracionTotalMinimo"
                      name="AlertaConcentracionTotalMinimo"
                      component={InputNumerical}
                      customClass='alertas-panel'
                    />
                    <Field
                      colSm={3}
                      id="AlertaConcentracionTotalMaximo"
                      name="AlertaConcentracionTotalMaximo"
                      component={InputNumerical}
                      customClass='alertas-panel'
                    />
                    <Col sm={6} style={{textAlign: 'right', paddingTop: '5px'}}><b>{t('PANEL_CONTROL.MOTILIDAD_TOTAL')}</b></Col>
                    <Field
                      colSm={3}
                      id="AlertaMotilidadTotalMinimo"
                      name="AlertaMotilidadTotalMinimo"
                      component={InputNumerical}
                      customClass='alertas-panel'
                      disabled={this.state.motilidadTotal}
                    />
                    <Field
                      colSm={3}
                      id="AlertaMotilidadTotalMaximo"
                      name="AlertaMotilidadTotalMaximo"
                      component={InputNumerical}
                      customClass='alertas-panel'
                      disabled={this.state.metodoEvaluacion === 5 ? true : this.state.motilidadTotal}
                    />
                    <Col sm={6} style={{textAlign: 'right', paddingTop: '5px'}}><b>{t('PANEL_CONTROL.MOTILIDAD_PROGRESIVA')}</b></Col>
                    <Field
                      colSm={3}
                      id="AlertaMotilidadProgresivaMinimo"
                      name="AlertaMotilidadProgresivaMinimo"
                      component={InputNumerical}
                      customClass='alertas-panel'
                      disabled={this.state.motilidadProgresiva}
                    />
                    <Field
                      colSm={3}
                      id="AlertaMotilidadProgresivaMaximo"
                      name="AlertaMotilidadProgresivaMaximo"
                      component={InputNumerical}
                      customClass='alertas-panel'
                      disabled={this.state.metodoEvaluacion === 5 ? true : this.state.motilidadProgresiva}
                    />
                    {/*
                    <Col sm={6} style={{textAlign: 'right', paddingTop: '5px'}}><b>{t('PANEL_CONTROL.GRADO_DILUCION')}</b></Col>
                    <Field
                      colSm={3}
                      id="AlertaGradoDilucionMinimo"
                      name="AlertaGradoDilucionMinimo"
                      component={InputNumerical}
                      customClass='alertas-panel'
                      disabled={this.state.gradoDilucion}
                    />
                    <Field
                      colSm={3}
                      id="AlertaGradoDilucionMaximo"
                      name="AlertaGradoDilucionMaximo"
                      component={InputNumerical}
                      customClass='alertas-panel'
                      disabled={this.state.gradoDilucion}
                    />*/}
                  </Col>
                  <Col sm={5}>
                    <Col sm={7}></Col>
                    <Col sm={5} style={{textAlign: 'center'}}><b>{t('PANEL_CONTROL.MAX')}</b></Col>
                    <Col sm={7} style={{textAlign: 'right', paddingTop: '5px'}}><b>{t('PANEL_CONTROL.FA_CABEZA')}</b></Col>
                    <Field
                      colSm={5}
                      id="AlertaFACabezaMaxima"
                      name="AlertaFACabezaMaxima"
                      component={InputNumerical}
                      customClass='alertas-panel'
                      disabled={this.state.faCabeza}
                    />
                    <Col sm={7} style={{textAlign: 'right', paddingTop: '5px'}}><b>{t('PANEL_CONTROL.FA_COLA')}</b></Col>
                    <Field
                      colSm={5}
                      id="AlertaFaColaMaxima"
                      name="AlertaFaColaMaxima"
                      component={InputNumerical}
                      customClass='alertas-panel'
                      disabled={this.state.faCola}
                    />
                    <Col sm={7} style={{textAlign: 'right', paddingTop: '5px'}}><b>{t('PANEL_CONTROL.FA_G_PROXIMAL')}</b></Col>
                    <Field
                      colSm={5}
                      id="AlertaFAGProximalMaxima"
                      name="AlertaFAGProximalMaxima"
                      component={InputNumerical}
                      customClass='alertas-panel'
                      disabled={this.state.faProximal}
                    />
                    <Col sm={7} style={{textAlign: 'right', paddingTop: '5px'}}><b>{t('PANEL_CONTROL.FA_G_DISTAL')}</b></Col>
                    <Field
                      colSm={5}
                      id="AlertaFAGDistalMaxima"
                      name="AlertaFAGDistalMaxima"
                      component={InputNumerical}
                      customClass='alertas-panel'
                      disabled={this.state.faDistal}
                    />
                    <Col sm={7} style={{textAlign: 'right', paddingTop: '5px'}}><b>{t('PANEL_CONTROL.FA_TOTAL')}</b></Col>
                    <Field
                      colSm={5}
                      id="AlertaFAGTotalMaxima"
                      name="AlertaFAGTotalMaxima"
                      component={InputNumerical}
                      customClass='alertas-panel'
                    />
                  </Col>
                </Col>
                <div className="clearfix"></div>
                <div style={{borderTop: '1px solid rgb(238, 238, 238)', marginTop: '20px'}}></div>
                <Col sm={6} style={{paddingTop: '20px'}}>
                  <Col sm={12}><h4>{t('PANEL_CONTROL.DEFINIR_CONCENTRACION_UTIL_TOTAL')}</h4></Col>
                  <Field
                    colSm={12}
                    id="ConcentracionAplicada"
                    name="ConcentracionAplicada"
                    component={InputSelect}
                    options={comboConcentracionUtilAplicada}
                    customClass='concentracion'
                    isClearable={false}
                  />
                </Col>
                <Col sm={6} style={{borderLeft: '1px solid rgb(238, 238, 238)', height: '120px', paddingTop: '20px'}}>
                  <Col sm={12}><h4>{t('PANEL_CONTROL.PRODUCCION')}</h4></Col>
                  <Field
                    id="AutoDilucion"
                    name="AutoDilucion"
                    colSm={3}
                    inline
                    component={InputCheckBox}
                    claseActivo="onoffswitch-label"
                    controlLabel={t('PANEL_CONTROL.AUTODILUCION')}
                  />
                  <Field
                    id="AutoEnvasado"
                    name="AutoEnvasado"
                    colSm={4}
                    inline
                    component={InputCheckBox}
                    claseActivo="onoffswitch-label"
                    controlLabel={t('PANEL_CONTROL.AUTOENVASADO')}
                  />
                  <div style={{borderRigth: '1px solid rgb(238, 238, 238)'}}></div>
                </Col>
                <div className="clearfix"></div>
              </Row>
            </Tab>
            <Tab eventKey={2} title={t('PANEL_CONTROL.TAB.GENERAL')}>
              <Row>
                {this.props.auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && (
                  <Col sm={6}>
                    <Field
                      colSm={12}
                      id="TextoInformeVisitas"
                      name="TextoInformeVisitas"
                      component={InputTextArea}
                      componentClass="textarea"
                      controlLabel={t('PANEL_CONTROL.TEXTO_INFORME_VISITAS')}
                      customClass='texto-informe-visitas'
                    />
                  </Col>
                )}
                <Col sm={6}>
                  {this.props.auth.modulos.includes(modulosConstant.PEDIDOS_CLIENTES) && (
                    <Col sm={8}>
                      <Field
                        colSm={12}
                        id="TextoAlbaranVentasA"
                        name="TextoAlbaranVentasA"
                        component={InputTextArea}
                        componentClass="textarea"
                        controlLabel={t('PANEL_CONTROL.TEXTO_INFORME_VENTAS')}
                        customClass='texto-albaran-ventas'
                      />
                      <Field
                        colSm={12}
                        id="TextoAlbaranVentasB"
                        name="TextoAlbaranVentasB"
                        component={InputTextArea}
                        componentClass="textarea"
                        customClass='texto-albaran-ventas'
                      />
                    </Col>
                  )}
                  <Col sm={4}>
                    <span><b>{t('PANEL_CONTROL.FIRMA')}</b></span>
                    <div className="imagenFirma">
                      <div className="content-dropzone">
                        <Dropzone
                          accept="image/jpeg, image/png"
                          onDrop={(accepted, rejected) => { this.onDrop({ accepted, rejected }) }}
                          maxSize={2000000}
                          onDropRejected={this.onDropRejected}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}  className="dropzone">
                              <input {...getInputProps()} />
                              <CloudUpload/>
                            </div>
                          )}
                        </Dropzone>
                        {
                          (this.state.accepted !== '' && this.state.accepted !== undefined) ? (
                            <div className="eliminar-imagen-firma" onClick={this.eliminaImagenFirma.bind(this)}>
                              <Delete/>
                            </div>
                          ) : null
                        }
                        <div className="imagen-firma">
                        {
                          this.state.accepted !== '' && this.state.accepted !== undefined ? (
                            <img src={this.state.accepted} />
                          ) : (
                            <img src={urlRecursos + 'imagen-firma.jpg'} />
                          )
                        }
                        </div>
                        <p className={this.state.rejected === '' ? 'error-upload-img-oculto' : 'error-upload-img-visible'}>{t('PANEL_CONTROL.IMAGEN_DEMASIADO_GRANDE')}</p>
                      </div>
                    </div>
                  </Col>
                </Col>
                <Col sm={12}>
                  <Col sm={12}>
                    <h4><b>{t('PANEL_CONTROL.DASHBOARD_GRAFICOS_MOSTRAR')}</b></h4>
                  </Col>
                  <Field
                    id="eyaculados_utilizados_eliminados_razon_uso"
                    name="eyaculados_utilizados_eliminados_razon_uso"
                    inline
                    component={InputCheckBox}
                    claseActivo="onoffswitch-label"
                    controlLabel={t('PANEL_CONTROL.EYACULADOS_UTILIZADOS_ELIMINADOS_RAZON_USO')}
                    onInputChange={this.handleChangeGraficosMostrarDashboard.bind(this)}
                    colSm={6}
                    disabled={this.state.deshabilitarGraficoEyaculadosUtilizadosEliminadosRazonUso}
                  />
                  <Field
                    id="verracos_por_raza"
                    name="verracos_por_raza"
                    inline
                    component={InputCheckBox}
                    claseActivo="onoffswitch-label"
                    controlLabel={t('PANEL_CONTROL.VERRACOS_POR_RAZA')}
                    onInputChange={this.handleChangeGraficosMostrarDashboard.bind(this)}
                    colSm={6}
                    disabled={this.state.deshabilitarGraficoVerracosRaza}
                  />
                  <Field
                    id="verracos_por_estado"
                    name="verracos_por_estado"
                    inline
                    component={InputCheckBox}
                    claseActivo="onoffswitch-label"
                    controlLabel={t('PANEL_CONTROL.VERRACOS_POR_ESTADO')}
                    onInputChange={this.handleChangeGraficosMostrarDashboard.bind(this)}
                    colSm={6}
                    disabled={this.state.deshabilitarGraficoVerracosEstado}
                  />
                  <Field
                    id="verracos_por_rango_edad"
                    name="verracos_por_rango_edad"
                    inline
                    component={InputCheckBox}
                    claseActivo="onoffswitch-label"
                    controlLabel={t('PANEL_CONTROL.VERRACOS_POR_RANGO_EDAD')}
                    onInputChange={this.handleChangeGraficosMostrarDashboard.bind(this)}
                    colSm={6}
                    disabled={this.state.deshabilitarGraficoVerracosRangoEdad}
                  />
                  <Field
                    id="tipo_dosis_producidas"
                    name="tipo_dosis_producidas"
                    inline
                    component={InputCheckBox}
                    claseActivo="onoffswitch-label"
                    controlLabel={t('PANEL_CONTROL.TIPO_DOSIS_PRODUCIDAS')}
                    onInputChange={this.handleChangeGraficosMostrarDashboard.bind(this)}
                    colSm={6}
                    disabled={this.state.deshabilitarGraficoTipoDosisProducidas}
                  />
                  <Field
                    id="informacion_general_centro"
                    name="informacion_general_centro"
                    inline
                    component={InputCheckBox}
                    claseActivo="onoffswitch-label"
                    controlLabel={t('PANEL_CONTROL.INFORMACION_GENERAL_CENTRO')}
                    onInputChange={this.handleChangeGraficosMostrarDashboard.bind(this)}
                    colSm={6}
                    disabled={this.state.deshabilitarGraficoInformacionGeneralCentro}
                  />
                  <Field
                    id="produccion_del_dia"
                    name="produccion_del_dia"
                    inline
                    component={InputCheckBox}
                    claseActivo="onoffswitch-label"
                    controlLabel={t('PANEL_CONTROL.PRODUCCION_DEL_DIA')}
                    onInputChange={this.handleChangeGraficosMostrarDashboard.bind(this)}
                    colSm={6}
                    disabled={this.state.deshabilitarGraficoProduccionDia}
                  />
                  <Field
                    id="tareas_para_hoy"
                    name="tareas_para_hoy"
                    inline
                    component={InputCheckBox}
                    claseActivo="onoffswitch-label"
                    controlLabel={t('PANEL_CONTROL.TAREAS_PARA_HOY')}
                    onInputChange={this.handleChangeGraficosMostrarDashboard.bind(this)}
                    colSm={6}
                    disabled={this.state.deshabilitarGraficoTareasHoy}
                  />
                  <Field
                    id="stock_materiales"
                    name="stock_materiales"
                    inline
                    component={InputCheckBox}
                    claseActivo="onoffswitch-label"
                    controlLabel={t('PANEL_CONTROL.STOCK_MATERIALES')}
                    onInputChange={this.handleChangeGraficosMostrarDashboard.bind(this)}
                    colSm={6}
                    disabled={this.state.deshabilitarGraficoStockMateriales}
                  />
                  <Field
                    id="numero_dosis_producidas_descartadas_edad"
                    name="numero_dosis_producidas_descartadas_edad"
                    inline
                    component={InputCheckBox}
                    claseActivo="onoffswitch-label"
                    controlLabel={t('PANEL_CONTROL.NUMERO_DOSIS_PRODUCIDAS_DESCARTADAS_EDAD')}
                    onInputChange={this.handleChangeGraficosMostrarDashboard.bind(this)}
                    colSm={6}
                    disabled={this.state.deshabilitarGraficoNumeroDosisProducidasDescartadasEdad}
                  />
                </Col>
                {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.SECUENCIA] > 0) && [
                  <div key="clearfix_secuencia" className="clearfix"></div>,
                  <div key="borde_secuencia" style={{borderTop: '1px solid rgb(238, 238, 238)', marginBottom: '20px'}}></div>,
                  <Col key="tabla_secuencia" sm={12}>
                    <h3>{t('PANEL_CONTROL.TABLA_SECUENCIA_NUMERACIONES.TITULO')}</h3>
                    <ListadoPage noTitle={true} table={table}>
                    </ListadoPage>
                    <ModalSecuenciasNumeracionPage />
                  </Col>
                ]}
              </Row>
            </Tab>
            <div className="clearfix"></div>
            <hr/>
            <div className="botones-acciones-panel">
              <Button
                type="button"
                className={"btn btn-white" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.PANEL_CONTROL] < 2) || submitting ? ' disabled' : '')}
                onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.PANEL_CONTROL] > 1) && !submitting) ? handleSubmit(this.valoresPredefinidosPanelControl.bind(this)) : undefined}
              >
                {t('PANEL_CONTROL.VALORES_PREDEFINIDOS')}
              </Button>
              <Button
                type="button"
                className={"btn btn-white" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.PANEL_CONTROL] < 2) || submitting ? ' disabled' : '')}
                onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.PANEL_CONTROL] > 1) && !submitting) ? () => this.obtenerValoresPanelControl() : undefined}
              >
                {t('PANEL_CONTROL.VALORES_ANTERIORES')}
              </Button>
              <ButtonLockable
                processing={submitting}
                hasIcon={true}
                showLoaderOnClick={true}
                type="button"
                className="btn btn-primary btn-sm"
                disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.PANEL_CONTROL] < 2) || submitting}
                onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.PANEL_CONTROL] > 1) && !submitting) ? handleSubmit(this.guardarPanelControl.bind(this)) : () => {}}
              >
                {t('PANEL_CONTROL.GUARDAR')}
              </ButtonLockable>
            </div>
          </Tabs>
        </form>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'panelControl',
})(PanelControl))