import React, { Component } from 'react'
import ListadoPage from '../../containers/page/ListadoPage'
import ModalDilucionPage from '../../containers/dilucion/ModalDilucionPage'
import FiltrosDilucionPage from '../../containers/dilucion/FiltrosDilucionPage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import './Dilucion.scss'
import { date_parser, dateTimeZones } from '../../util/formatFunctions'

class Dilucion extends Component {
  componentDidMount () {
    document.title = this.props.t('MENU.EXTRACCIONES.DILUCION') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    let fechaActual = date_parser(dateTimeZones(this.props.auth.zonaHorariaString), this.props.auth.formaFechaHora)
    let estadoLabel = this.props.t(`COMUN.COMBOS.ESTADO_DILUCION.${this.props.combos.comboDiluido[1].label}`)
    this.props.actions.fetchFiltrarDilucion({estado: {...this.props.combos.comboDiluido[1], label: estadoLabel}, fechaEntrada: fechaActual, desde: fechaActual})
  }

  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual,
      dilucion: { list, checkedFiltrosDilucion },
      actions: {fetchDilucionSuccess, abrirDetalles, onDeleteDilucion}
    } = this.props

    list.forEach(
      (row) => row.idElemento = row.idDilucion
    )

    const table = {
      id: 'dilucionTable',
      multiHeader: false,
      hasCheckbox: true,
      hasOptions: true,
      columns: [
        {id: 'codVerraco', name: t('EXTRACCIONES.DILUCION.TABLA.COD_VERRACO')},
        {id: 'codigo', name: t('EXTRACCIONES.DILUCION.TABLA.CODIGO'), type: 'numero', numDecimales: 0},
        {id: 'fecha', name: t('EXTRACCIONES.DILUCION.TABLA.FECHA'), type: 'fecha'},
        {id: 'volumenExtraccion', name: t('EXTRACCIONES.DILUCION.TABLA.VOLUMEN_ESTRACCION'), type: 'numero'},
        {id: 'volumenDiluyente', name: t('EXTRACCIONES.DILUCION.TABLA.VOLUMEN_DILUYENTE'), type: 'numero'},
        {id: 'nombreDiluyente', name: t('EXTRACCIONES.DILUCION.TABLA.NOMBRE_DILUYENTE')},
        {id: 'TipoDosis', name: t('EXTRACCIONES.DILUCION.TABLA.TIPO_DOSIS')},
        {id: 'numeroDosis', name: t('EXTRACCIONES.DILUCION.TABLA.NUMERO_DOSIS'), type: 'numero', numDecimales: 0},
        {id: 'observacionesDilucion', name: t('EXTRACCIONES.DILUCION.TABLA.OBSERVACIONES')}
      ],
      rows: list,
      filtros: <FiltrosDilucionPage />,
      mostrarVerDetalle: true,
      mostrarDuplicar: false,
      mostrarCambioEstado: false,
      onEdit: (row) => abrirDetalles(row.idDilucion),
      onSort: (rows) => fetchDilucionSuccess(rows),
      onDelete: (row) => onDeleteDilucion(row.idDilucion),
      initialValues: {},
      leyenda: [
        {name: t('EXTRACCIONES.DILUCION.LEYENDA.DILUIDO'), color: '#aacfdc'},
        {name: t('EXTRACCIONES.DILUCION.LEYENDA.DILUIDO_ELIMINADO'), color: '#c4415d'}
      ],
      colorearRow: (row) => {
        if (row.diluido && !row.dilucionEliminada) {
            return 'coloreado-diluido'
        } else if (row.dilucionEliminada) {
            return 'coloreado-diluido-eliminado'
        }
      }
    }
    table.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          table.initialValues['check' + row.idElemento] = checkedFiltrosDilucion
        }
      }
    )
    return (
      <div>
        <SimpleModalPage/>
        <MensajeAlertaPage/>
        <ModalDilucionPage/>
        <ListadoPage t_key="EXTRACCIONES.DILUCION." table={table}>
        </ListadoPage>
      </div>
    )
  }
}

export default Dilucion