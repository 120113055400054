import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosSeguimiento from '../../components/seguimiento/FiltrosSeguimiento'
import {
  fetchSeguimientos, seleccionarTodosSeguimiento, deSeleccionarTodosSeguimiento, deleteSeleccionSeguimiento,
  crearCsvSeguimiento, imprimirPdfSeguimiento
} from '../../actions/seguimiento/seguimiento'
import {comboVerraco, comboNombreGrupoVerraco, comboRaza, comboLineaGenetica, fetchComboPool, comboCliente} from '../../actions/combos/combos'

const mapStateToProps = (state) => {
  return {
    ...state.seguimiento,
    auth: state.auth,
    seguimientoTable: state.form.seguimientoTable,
    formSimpleTable: state.form.simpleTable,
    combos: {
      comboVerracoSiNo: state.combos.comboVerracoSiNo,
      comboVerraco: state.combos.comboVerraco,
      comboPool: state.combos.comboPool,
      comboLineaGenetica: state.combos.comboLineaGenetica,
      comboRaza: state.combos.comboRaza,
      comboTipoControlSeguimiento: state.combos.comboTipoControlSeguimiento,
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchSeguimientos,
      seleccionarTodosSeguimiento,
      deSeleccionarTodosSeguimiento,
      deleteSeleccionSeguimiento,
      comboVerraco,
      fetchComboPool,
      comboNombreGrupoVerraco,
      comboRaza,
      comboLineaGenetica,
      comboCliente,
      crearCsvSeguimiento,
      imprimirPdfSeguimiento
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosSeguimiento))