import actionTypes from '../../../constants/actions/planSanitario/registroAlimentacion/registroAlimentacion'

export function fetchRegistroAlimentacion () {
  return {
    type: actionTypes.FETCH_REGISTRO_ALIMENTACION
  }
}

export function fetchRegistroAlimentacionSuccess (list) {
  return {
    type: actionTypes.FETCH_REGISTRO_ALIMENTACION_SUCCESS,
    list
  }
}

export function abrirModalVacioRegistroAlimentacion () {
  return {
    type: actionTypes.ABRIR_MODAL_VACIO_REGISTRO_ALIMENTACION
  }
}

export function seleccionarTodosRegistroAlimentacion(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_REGISTRO_ALIMENTACION
  }
}

export function deSeleccionarTodosRegistroAlimentacion(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_REGISTRO_ALIMENTACION
  }
}

export function submitNuevoRegistroAlimentacion(values, onSuccessCallback, onErrorCallback){
  return{
    type: actionTypes.SUBMIT_NUEVO_REGISTRO_ALIMENTACION,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function recuperarDatosModalRegistroAlimentacion (idAlimentacion) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_REGISTRO_ALIMENTACION,
    idAlimentacion
  }
}

export function recuperarDatosModalRegistroAlimentacionSuccess (data) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_REGISTRO_ALIMENTACION_SUCCESS,
    data
  }
}

export function editarRegistroAlimentacion (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.EDITAR_REGISTRO_ALIMENTACION,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function duplicarDatosModalRegistroAlimentacion (idAlimentacion, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_REGISTRO_ALIMENTACION,
    idAlimentacion,
    onSuccessCallback,
    onErrorCallback
  }
}

export function duplicarDatosModalRegistroAlimentacionSuccess (dataDuplicar) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_REGISTRO_ALIMENTACION_SUCCESS,
    dataDuplicar
  }
}

export function cambiarEstadoRegistroAlimentacion (idAlimentacion) {
  return {
    type: actionTypes.CAMBIAR_ESTADO_REGISTRO_ALIMENTACION,
    idAlimentacion
  }
}

export function onDeleteRegistroAlimentacion (idAlimentacion) {
  return {
    type: actionTypes.ON_DELETE_REGISTRO_ALIMENTACION,
    idAlimentacion
  }
}

export function cambiarEstadoSeleccionRegistroAlimentacion(list){
  return {
    type: actionTypes.CAMBIAR_ESTADO_SELECCION_REGISTRO_ALIMENTACION,
    list
  }
}

export function deleteSeleccionRegistroAlimentacion(list){
  return {
    type: actionTypes.DELETE_SELECCION_REGISTRO_ALIMENTACION,
    list
  }
}

export function guardarYnuevoRegistroAlimentacion (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.GUARDAR_Y_NUEVO_REGISTRO_ALIMENTACION,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function fetchFiltrarRegistroAlimentacion (values) {
  return {
    type: actionTypes.FETCH_FILTRAR_REGISTRO_ALIMENTACION,
    values
  }
}

export function fetchFiltrarRegistroAlimentacionSuccess (filtros) {
  return {
    type: actionTypes.FETCH_FILTRAR_REGISTRO_ALIMENTACION_SUCCESS,
    filtros
  }
}

export function abrirModalVerracoRegistroAlimentacion () {
  return {
    type: actionTypes.ABRIR_MODAL_VERRACO_REGISTRO_ALIMENTACION
  }
}

export function cerrarModalVerracoRegistroAlimentacion () {
  return {
    type: actionTypes.CERRAR_MODAL_VERRACO_REGISTRO_ALIMENTACION
  }
}

export function fetchVerracosRegistroAlimentacion (verracosAnadidos){
  return {
    type: actionTypes.FETCH_VERRACOS_REGISTRO_ALIMENTACION,
    verracosAnadidos
  }
}

export function fetchVerracosRegistroAlimentacionSuccess (listVerracosEnRegistroAlimentacion){
  return {
    type: actionTypes.FETCH_VERRACOS_REGISTRO_ALIMENTACION_SUCCESS,
    listVerracosEnRegistroAlimentacion
  }
}

export function seleccionarTodosModalVerracoRegistroAlimentacion(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_MODAL_VERRACO_REGISTRO_ALIMENTACION
  }
}

export function deSeleccionarTodosModalVerracoRegistroAlimentacion(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_MODAL_VERRACO_REGISTRO_ALIMENTACION
  }
}

export function anadirVerracoModalRegistroAlimentacion (listadoAnadir) {
  return {
    type: actionTypes.ANADIR_VERRACO_MODAL_REGISTRO_ALIMENTACION,
    listadoAnadir
  }
}

export function anadirVerracoModalRegistroAlimentacionSuccess (verracosAnadidos) {
  return {
    type: actionTypes.ANADIR_VERRACO_MODAL_REGISTRO_ALIMENTACION_SUCCESS,
    verracosAnadidos
  }
}

export function fetchFiltrarVerracoModalRegistroAlimentacion (values) {
  return {
    type: actionTypes.FETCH_FILTRAR_VERRACO_MODAL_REGISTRO_ALIMENTACION,
    values
  }
}

export function fetchFiltrarVerracoModalRegistroAlimentacionSuccess (filtrosModalVerracoRegistroAlimentacion) {
  return {
    type: actionTypes.FETCH_FILTRAR_VERRACO_MODAL_REGISTRO_ALIMENTACION_SUCCESS,
    filtrosModalVerracoRegistroAlimentacion
  }
}


export function seleccionarTodosModalRegistroAlimentacion(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_MODAL_REGISTRO_ALIMENTACION
  }
}

export function deSeleccionarTodosModalRegistroAlimentacion(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_MODAL_REGISTRO_ALIMENTACION
  }
}

export function deleteSeleccionVerracosRegistroAlimentacion (listVerracosQuitarRegistroAlimentacion) {
  return {
    type: actionTypes.DELETE_SELECCION_VERRACOS_REGISTRO_ALIMENTACION,
    listVerracosQuitarRegistroAlimentacion
  }
}

export function onDeleteVerracoAnadidoRegistroAlimentacion (idVerraco) {
  return {
    type: actionTypes.ON_DELETE_VERRACO_ANADIDO_REGISTRO_ALIMENTACION,
    idVerraco
  }
}

export function onDeleteVerracoAnadidoRegistroAlimentacionSuccess (verracosAnadidos) {
  return {
    type: actionTypes.ON_DELETE_VERRACO_ANADIDO_REGISTRO_ALIMENTACION_SUCCESS,
    verracosAnadidos
  }
}

export function resetearVerracos () {
  return {
    type: actionTypes.RESETEAR_VERRACOS
  }
}

export function crearCsvRegistroAlimentacion(list){
  return {
    type: actionTypes.CREATE_CSV_REGISTRO_ALIMENTACION,
    list
  }
}

export function crearCsvRegistroAlimentacionSuccess(datosExcelRegistroAlimentacion){
  return {
    type: actionTypes.CREATE_CSV_REGISTRO_ALIMENTACION_SUCCESS,
    datosExcelRegistroAlimentacion
  }
}
export function imprimirPdfRegistroAlimentacion(list){
  return {
    type: actionTypes.IMPRIMIR_PDF_REGISTRO_ALIMENTACION,
    list
  }
}

export function imprimirPdfRegistroAlimentacionSuccess(datosPdfRegistroAlimentacion){
  return {
    type: actionTypes.IMPRIMIR_PDF_REGISTRO_ALIMENTACION_SUCCESS,
    datosPdfRegistroAlimentacion
  }
}

export function openModalRegistroAlimentacion(){
  return {
    type: actionTypes.OPEN_MODAL_REGISTRO_ALIMENTACION
  }
}

export function closeModalRegistroAlimentacion(){
  return {
    type: actionTypes.CLOSE_MODAL_REGISTRO_ALIMENTACION
  }
}

export function imprimirInformeRegistroAlimentacion(values, idAlimentacion, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.IMPRIMIR_INFORME_REGISTRO_ALIMENTACION,
    values,
    idAlimentacion,
    onSuccessCallback,
    onErrorCallback
  }
}

export default {
  fetchRegistroAlimentacion,
  fetchRegistroAlimentacionSuccess,
  abrirModalVacioRegistroAlimentacion,
  seleccionarTodosRegistroAlimentacion,
  deSeleccionarTodosRegistroAlimentacion,
  submitNuevoRegistroAlimentacion,
  recuperarDatosModalRegistroAlimentacion,
  recuperarDatosModalRegistroAlimentacionSuccess,
  editarRegistroAlimentacion,
  duplicarDatosModalRegistroAlimentacion,
  duplicarDatosModalRegistroAlimentacionSuccess,
  cambiarEstadoRegistroAlimentacion,
  onDeleteRegistroAlimentacion,
  cambiarEstadoSeleccionRegistroAlimentacion,
  deleteSeleccionRegistroAlimentacion,
  guardarYnuevoRegistroAlimentacion,
  fetchFiltrarRegistroAlimentacion,
  fetchFiltrarRegistroAlimentacionSuccess,
  abrirModalVerracoRegistroAlimentacion,
  cerrarModalVerracoRegistroAlimentacion,
  fetchVerracosRegistroAlimentacion,
  fetchVerracosRegistroAlimentacionSuccess,
  seleccionarTodosModalVerracoRegistroAlimentacion,
  deSeleccionarTodosModalVerracoRegistroAlimentacion,
  anadirVerracoModalRegistroAlimentacion,
  anadirVerracoModalRegistroAlimentacionSuccess,
  fetchFiltrarVerracoModalRegistroAlimentacion,
  fetchFiltrarVerracoModalRegistroAlimentacionSuccess,
  seleccionarTodosModalRegistroAlimentacion,
  deSeleccionarTodosModalRegistroAlimentacion,
  deleteSeleccionVerracosRegistroAlimentacion,
  onDeleteVerracoAnadidoRegistroAlimentacion,
  onDeleteVerracoAnadidoRegistroAlimentacionSuccess,
  resetearVerracos,
  crearCsvRegistroAlimentacion,
  crearCsvRegistroAlimentacionSuccess,
  imprimirPdfRegistroAlimentacion,
  imprimirPdfRegistroAlimentacionSuccess,
  openModalRegistroAlimentacion,
  closeModalRegistroAlimentacion,
  imprimirInformeRegistroAlimentacion
}
