import {call, put, takeLatest, select} from 'redux-saga/effects'
import comunActionTypes from '../../constants/actions/comun/comun'
import {obtenerArchivoSuccess, cambiarCentroSuccess} from '../../actions/comun/comun'
import {loginSuccess} from '../../actions/auth/auth'
import obtenerDataArchivo from '../../services/fichaVerraco/obtenerArchivo'
import saveSessionService from '../../services/session/saveSession'
import putCambiarCentro from '../../services/comun/putCambiarCentro'

export function * obtenerArchivoContent ({archivo}) {
  try {
    let auth = yield select(state => state.auth)
    const archivoDescarga = yield call(obtenerDataArchivo, {archivo}, auth.token)
    yield put(obtenerArchivoSuccess(archivoDescarga))
  } catch (error) {

  } finally {
    
  }
}
export function * watchObtenerArchivoContent () {
  yield takeLatest(comunActionTypes.OBTENER_ARCHIVO_CONTENT, obtenerArchivoContent)
}

export function * cambiarCentro ({idCentro, history}) {
  try {
    let response = yield call(putCambiarCentro, idCentro, null)
    if (response.idModalidad) response.modulos = response.idModalidad.split(';')
    yield put(loginSuccess(response))
    yield call(saveSessionService, response)
    yield put(cambiarCentroSuccess())
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchCambiarCentro () {
  yield takeLatest(comunActionTypes.CAMBIAR_CENTRO, cambiarCentro)
}

