import {call, put, takeLatest, all, select} from 'redux-saga/effects'
import {initialize, change} from 'redux-form'
import actionTypes from '../../constants/actions/foso/foso'
import {fetchFosoSuccess, fetchFiltrarFosoSuccess, obtenerDatosVerracoSuccess, obtenerCodigoAnalisisSuccess, obtenerExtraccionSuccess, crearCsvFosoSuccess,
  imprimirPdfFosoSuccess} from '../../actions/foso/foso'
import {openSimpleModal, openMensajeAlerta} from '../../actions/common'
import {yesNoModal} from '../modal/yesNoModal'
import getFiltrarFoso from '../../services/foso/getFiltrarFoso'
import getObtenerDatosVerraco from '../../services/foso/getObtenerDatosVerraco'
import getObtenerCodigoAnalisis from '../../services/foso/getObtenerCodigoAnalisis'
import getExtraccion from '../../services/foso/getExtraccion'
import addFoso from '../../services/foso/addFoso'
import editFoso from '../../services/foso/editFoso'
import deleteFoso from '../../services/foso/deleteFoso'
import getExportarFoso from '../../services/foso/getExportarFoso'
import getImprimirFoso from '../../services/foso/getImprimirFoso'
import {dateTimeZones} from '../../util/formatFunctions'

export function * fetchFiltrarFoso ({values}) {
  try {
    const auth = yield select(state => state.auth)
    const list = yield call(getFiltrarFoso, values, auth.token)
    yield put(fetchFosoSuccess(list))
    yield put(fetchFiltrarFosoSuccess(values))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchFetchFiltrarFoso () {
  yield takeLatest(actionTypes.FETCH_FILTRAR_FOSO, fetchFiltrarFoso)
}

export function * obtenerDatosVerraco ({idVerraco, fecha}) {
  try {
    const auth = yield select(state => state.auth)
    const dataDatosVerraco = yield call(getObtenerDatosVerraco, {idVerraco: idVerraco, fechaExtraccion: fecha}, auth.token)
    yield put(obtenerDatosVerracoSuccess(dataDatosVerraco))
    const codigoAnalisis = yield call(getObtenerCodigoAnalisis, null)
    yield put(obtenerCodigoAnalisisSuccess(codigoAnalisis))
    const comboVerraco = yield select(state => state.combos.comboVerraco)
    const verraco = comboVerraco.filter((cerdo) => {
      if (cerdo.value === idVerraco){
        return cerdo
      }
    })
    var separadorFecha = fecha.split('/')
    let formatoFecha
    if (separadorFecha[0] === undefined || separadorFecha[1] === undefined || separadorFecha[2] === undefined) {
      let date = new Date(fecha)
      let day = date.getDate()
      let month = date.getMonth() + 1
      let year = date.getFullYear()
      if (auth.formaFechaHora === 'day/month/year/12' || auth.formaFechaHora === 'day/month/year/24'){
        let nuevaFecha =  day + '/' + month + '/' + year
        separadorFecha = nuevaFecha.split('/')
      } else if (auth.formaFechaHora === 'month/day/year/12' || auth.formaFechaHora === 'month/day/year/24'){
        let nuevaFecha =  month + '/' + day + '/' + year
        separadorFecha = nuevaFecha.split('/')
      } else if (auth.formaFechaHora === 'year/month/day/12' || auth.formaFechaHora === 'year/month/day/24'){
        let nuevaFecha =  year + '/' + month + '/' + day
        separadorFecha = nuevaFecha.split('/')
      } else if (auth.formaFechaHora === 'year/day/month/12' || auth.formaFechaHora === 'year/day/month/24'){
        let nuevaFecha =  year + '/' + day + '/' + month
        separadorFecha = nuevaFecha.split('/')
      }
    }
    if (auth.formaFechaHora === 'day/month/year/12' || auth.formaFechaHora === 'day/month/year/24'){
      let dia = Math.trunc(separadorFecha[0])
      dia = dia <= 9 ? ('0' + dia) : (separadorFecha[0])
      formatoFecha = separadorFecha[2] + '-' + separadorFecha[1] + '-' + dia
    } else if (auth.formaFechaHora === 'month/day/year/12' || auth.formaFechaHora === 'month/day/year/24'){
      let dia = Math.trunc(separadorFecha[0])
      dia = dia <= 9 ? ('0' + dia) : (separadorFecha[0])
      formatoFecha = separadorFecha[2] + '-' + dia + '-' + separadorFecha[1]
    } else if (auth.formaFechaHora === 'year/month/day/12' || auth.formaFechaHora === 'year/month/day/24'){
      let dia = Math.trunc(separadorFecha[2])
      dia = dia <= 9 ? ('0' + dia) : (separadorFecha[2])
      formatoFecha = separadorFecha[0] + '-' + separadorFecha[1] + '-' + dia
    } else if (auth.formaFechaHora === 'year/day/month/12' || auth.formaFechaHora === 'year/day/month/24'){
      let dia = Math.trunc(separadorFecha[2])
      dia = dia <= 9 ? ('0' + dia) : (separadorFecha[2])
      formatoFecha = separadorFecha[0] + '-' + dia + '-' + separadorFecha[1]
    }

    //yield put(change('formFoso', 'verraco', verraco))
    let operarioColecta = dataDatosVerraco.nombreOperarioColecta !== null ? {value: dataDatosVerraco.operarioColecta, label: dataDatosVerraco.nombreOperarioColecta} : null
    // yield put(change('formFoso', 'operarioColecta', operarioColecta))
    let fechaExtraccion = formatoFecha
    //yield put(change('formFoso', 'fechaExtraccion', fechaExtraccion))
    let fechaHora = dateTimeZones(auth.zonaHorariaString)
    let hour = fechaHora.getHours()
    let minute = fechaHora.getMinutes()
    let second = fechaHora.getSeconds()
    if (hour <= 9) hour = '0' + hour
    if (minute <= 9) minute = '0' + minute
    if (second <= 9) second = '0' + second
    let horaFinExtraccion = hour + ':' + minute
    let horaExtraccion = horaFinExtraccion
    //yield put(change('formFoso', 'horaExtraccion', horaExtraccion))
    // yield put(initialize('formFoso', dataForm))

    yield put(initialize('formFoso', {
      verraco: verraco,
      fechaExtraccion: fechaExtraccion,
      horaExtraccion: horaExtraccion
    }))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchObtenerDatosVerraco () {
  yield takeLatest(actionTypes.OBTENER_DATOS_VERRACO, obtenerDatosVerraco)
}

export function * obtenerExtraccion ({idAnalisis}) {
  try {
    const dataExtraccion = yield call(getExtraccion, idAnalisis, null)
    yield put(obtenerExtraccionSuccess(dataExtraccion))
    const comboVerraco = yield select(state => state.combos.comboVerraco)
    const verraco = comboVerraco.filter((cerdo) => {
      if (cerdo.value === dataExtraccion.idVerraco){
        return cerdo
      }
    })
    dataExtraccion.verraco = verraco
    dataExtraccion.operarioColecta = dataExtraccion.nombreOperarioColecta !== null ? {value: dataExtraccion.idOperarioColecta, label: dataExtraccion.nombreOperarioColecta} : null
    yield put(initialize('formFoso', dataExtraccion))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchObtenerExtraccion () {
  yield takeLatest(actionTypes.OBTENER_EXTRACCION, obtenerExtraccion)
}

export function * guardarFoso ({values, onSuccessCallback, onErrorCallback}) {
  try {
    const auth = yield select(state => state.auth)
    const insercionFoso = yield call(addFoso, values, auth.token)
    if (insercionFoso.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-insercion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    yield put(initialize('formFoso', ''))
    const val = yield select(state => state.foso.filtros)
    const list = yield call(getFiltrarFoso, val, auth.token)
    yield put(fetchFosoSuccess(list))
    yield put(fetchFiltrarFosoSuccess(val))
  } catch (error) {
    console.log(error)
    if (onErrorCallback) yield onErrorCallback()
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchGuardarFoso () {
  yield takeLatest(actionTypes.GUARDAR_FOSO, guardarFoso)
}

export function * modificarFoso ({values, onSuccessCallback, onErrorCallback}) {
  try {
    const auth = yield select(state => state.auth)
    const edicionFoso = yield call(editFoso, values, auth.token)
    if (edicionFoso.actualizado === true) {
      yield put(openMensajeAlerta('modificacion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-modificacion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    yield put(initialize('formFoso', ''))
    const val = yield select(state => state.foso.filtros)
    const list = yield call(getFiltrarFoso, val, auth.token)
    yield put(fetchFosoSuccess(list))
    yield put(fetchFiltrarFosoSuccess(val))
  } catch (error) {
    console.log(error)
    if (onErrorCallback) yield onErrorCallback()
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchModificarFoso () {
  yield takeLatest(actionTypes.MODIFICAR_FOSO, modificarFoso)
}

export function * eliminarFoso ({values}) {
  try {
    const auth = yield select(state => state.auth)
     yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      const eliminacionFoso = yield call(deleteFoso, values.idAnalisis, null)
      if (eliminacionFoso.eliminado === true && eliminacionFoso.asociado === false) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(initialize('formFoso', ''))
      const val = yield select(state => state.foso.filtros)
      const list = yield call(getFiltrarFoso, val, auth.token)
      yield put(fetchFosoSuccess(list))
      yield put(fetchFiltrarFosoSuccess(val))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchEliminarFoso () {
  yield takeLatest(actionTypes.ELIMINAR_FOSO, eliminarFoso)
}

export function * crearCsvFoso ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosExcelFoso = yield call(getExportarFoso, list, auth.token)
    yield put(crearCsvFosoSuccess(datosExcelFoso))
  } catch (error) {

  } finally {
    
  }
}
export function * watchExportarFoso () {
  yield takeLatest(actionTypes.CREATE_CSV_FOSO, crearCsvFoso)
}

export function * imprimirPdfFoso ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfFoso = yield call(getImprimirFoso, list, auth.token)
    yield put(imprimirPdfFosoSuccess(datosPdfFoso))
  } catch (error) {

  } finally {
    
  }
}
export function * watchImprimirFoso () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_FOSO, imprimirPdfFoso)
}
