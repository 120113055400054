export const estadoVerraco = {
  CUARENTENA: 1,
  PRESENTADO: 2,
  ACTIVO: 3,
  BAJA: 4,
  ELIMINADO: 5
}

export const translateEstadoVerraco = {
  1: 'CUARENTENA',
  2: 'PRESENTADO',
  3: 'ACTIVO',
  4: 'BAJA',
  5: 'ELIMINADO'
}