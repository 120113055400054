import React, { Component } from 'react'
import { Modal, Row } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { required } from '../../util/validationFunctions'
import Button from '../comun/button/Button'
import ButtonChangeRecord from '../comun/button/ButtonChangeRecord'
import ButtonLockable from '../comun/button/ButtonLockable'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputText from '../comun/form/InputText'
import InputSelect from '../comun/form/InputSelect'
import InputTextArea from '../comun/form/InputTextArea'
import './TipoInstalacion.scss'
import {permisos as permisosConstant} from '../../constants/permisos'

class ModalTipoInstalacion extends Component {
  submitNuevaTipoInstalacion (values) {
    return new Promise((resolve, reject) => {
      if (this.props.data){
        this.props.actions.editarTipoInstalacion(values, resolve, reject)
      } else {
        this.props.actions.submitNuevaTipoInstalacion(values, resolve, reject)
      }
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  guardarYnuevoTipoInstalacion(values){
    return new Promise((resolve, reject) => {
      this.props.actions.guardarYnuevoTipoInstalacion(values, resolve, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  duplicar(values){
    return new Promise((resolve, reject) => {
      this.props.duplicar(values, resolve, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  render () {
    const {
      t, handleSubmit, submitting, showModal, list, data, duplicar, actions: {recuperarDatosModalTipoInstalacion, closeModal}, combos: {comboTipoInstalacionPadre}
    } = this.props
    return (
      <Modal show={showModal} onHide={closeModal} aria-labelledby="contained-modal-title-lg" className="modal-tipo-instalacion" backdrop="static">
      <form className="form-tipo-instalacion">
        <Modal.Header closeButton={!submitting}>
          <Modal.Title>
          {
            data ? (
              t('TIPO_INSTALACION.MODAL.TITULO_EDITAR')
            ) : (
              t('TIPO_INSTALACION.MODAL.TITULO_NUEVO')
            )
          }
          </Modal.Title>
          {!submitting && data && data.idTipoInstalacion && (
            <ButtonChangeRecord list={list} idElemento="idTipoInstalacion" currentId={data.idTipoInstalacion} getNextRecord={recuperarDatosModalTipoInstalacion} />
          )}
        </Modal.Header>
        <Modal.Body>
          <Row>
          <Field
              id="nombreTipoInstalacion"
              name="nombreTipoInstalacion"
              colSm={12}
              component={InputText}
              controlLabel={t('TIPO_INSTALACION.MODAL.NOMBRE_TIPO_INSTALACION') + '*'}
              claseActivo="nombre-operario"
              validate={required}
              maxLength={true}
              valorMaxLength={50}
            />
            <Field
              id="animales"
              name="animales"
              colSm={12}
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('TIPO_INSTALACION.MODAL.ANIMALES')}
            />
            <Field
              id="silo"
              name="silo"
              colSm={12}
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('TIPO_INSTALACION.MODAL.SILOS')}
            />
            <Field
              id="salaExtraccion"
              name="salaExtraccion"
              colSm={12}
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('TIPO_INSTALACION.MODAL.SALA_EXTRACCION')}
            />
            <Field
              colSm={12}
              id="idTipoInstalacionPadre"
              name="idTipoInstalacionPadre"
              controlLabel={t('TIPO_INSTALACION.MODAL.TIPO_PADRE')}
              component={InputSelect}
              options={comboTipoInstalacionPadre}
              valueKey="value"
              labelKey="label"
              customClass='select-tipo-padre'
              valorValue={data && data.idTipoInstalacionPadre}
              valorLabel={data && data.nombreTipoInstalacion}
            />
            <Field
              id="activo"
              name="activo"
              colSm={12}
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('TIPO_INSTALACION.MODAL.ACTIVO')}
            />
            <Field
              colSm={12}
              id="observaciones"
              name="observaciones"
              component={InputTextArea}
              componentClass="textarea"
              controlLabel={t('TIPO_INSTALACION.MODAL.OBSERVACIONES')}
              customClass='tipo-instalacion-observaciones'
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {
            this.props.data && (
              <Button
                type="button"
                className="btn btn-white btn-sm"
                disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.TIPOS] < 2) || submitting}
                onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPOS] > 1) && !submitting ? handleSubmit(this.duplicar.bind(this)) : undefined}
              >
                {t('TIPO_INSTALACION.MODAL.DUPLICAR')}
              </Button>
            )
          }
          {
            !this.props.data && (
              <ButtonLockable
                processing={submitting}
                hasIcon={true}
                showLoaderOnClick={true}
                type="button"
                className="btn btn-primary btn-sm"
                disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.TIPOS] < 2) || submitting}
                onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPOS] > 1) && !submitting) ? handleSubmit(this.guardarYnuevoTipoInstalacion.bind(this)) : () => {}}
              >
                {t('TIPO_INSTALACION.MODAL.GUARDAR_Y_NUEVO')}
              </ButtonLockable>
            )
          }
          <ButtonLockable
            processing={submitting}
            hasIcon={true}
            showLoaderOnClick={true}
            type="button"
            className="btn btn-primary btn-sm"
            disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.TIPOS] < 2) || submitting}
            onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPOS] > 1) && !submitting) ? handleSubmit(this.submitNuevaTipoInstalacion.bind(this)) : () => {}}
          >
            {t('TIPO_INSTALACION.MODAL.GUARDAR')}
          </ButtonLockable>
          <Button
            type="button"
            className={"btn btn-white btn-sm" + (submitting ? ' disabled' : '')}
            onClick={() => !submitting && closeModal()}
          >
            {t('TIPO_INSTALACION.MODAL.CANCELAR')}
          </Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalTipoInstalacion',
})(ModalTipoInstalacion))