import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Envasado from '../../components/envasado/Envasado'
import { fetchFiltrarEnvasado, abrirDetallesEnvasado, abrirModalEnvasadoMultiDosisDesdeListadoPrincipal, editarEnvasado } from '../../actions/envasado/envasado'

export function mapStateToProps (state) {
  return {
    envasado: {...state.envasado},
    combos: {
      comboEnvasado: state.combos.comboEnvasado
    },
    auth: state.auth,
    paginaActual: state.common.paginaActual,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchFiltrarEnvasado,
      abrirDetallesEnvasado,
      abrirModalEnvasadoMultiDosisDesdeListadoPrincipal,
      editarEnvasado
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Envasado))