import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize} from 'redux-form'
import i18n from '../../i18n'
import {downloadDocument} from '../../util/util'
import actionTypes from '../../constants/actions/informes/informes'
import {obtenerInformeExtraccionesSuccess, fetchFiltrosSuccess, obtenerInformeStockDosisSuccess, fetchFiltrosStockDosisSuccess, obtenerInformePoolSuccess,
  fetchFiltrosPoolSuccess, obtenerInformeProductividadExtraccionesSuccess, fetchFiltrosProductividadExtraccionesSuccess, obtenerInformeSeguimientoExtraccionesPoolSuccess,
  fetchFiltrosSeguimientoExtraccionesPoolSuccess, obtenerInformeProductividadCentroSuccess, fetchFiltrosProductividadCentroSuccess, obtenerInformeTrazabilidadSuccess,
  fetchFiltrosTrazabilidadSuccess, obtenerInformeIntervencionesSuccess, fetchFiltrosIntervencionesSuccess, obtenerInformeClienteSuccess, fetchFiltrosClienteSuccess,
  obtenerInformeEstadoCentroSuccess, fetchFiltrosEstadoCentroSuccess, obtenerRegistrosSeguimientosExtraccionesPoolSuccess, obtenerVerracosProductividadExtraccionesSuccess,
  obtenerRecomendacionesMataderoSuccess, imprimirPdfInformesExtraccionesSuccess, imprimirPdfInformesPoolSuccess, imprimirPdfInformesProductividadExtraccionesSuccess, imprimirPdfInformesSeguimientoExtraccionesPoolSuccess, imprimirPdfInformesProductividadCentroSuccess, imprimirPdfInformesTrazabilidadSuccess, imprimirPdfInformesIntervencionesSuccess, imprimirPdfInformesClientesSuccess, imprimirPdfInformesStockDosisSuccess, cerrarModalExportarImprimirInformesSeguimientoExtraccionesPool, mostrarSeleccionInformePersonalizadoSuccess, valoresTablaPersonalizadoSuccess, camposSeleccionadosPersonalizadoSuccess, cerrarModalGuardarSeleccion, deseleccionarTodosInformePersonalizado, comboSeleccionesInformePersonalizadoSuccess, cargarSeleccionInformePersonalizadoSuccess} from '../../actions/informes/informes'
import {openModal, closeModal, openSimpleModal, openMensajeAlerta} from '../../actions/common'
import { yesNoModal } from '../modal/yesNoModal'
import getInformeExtracciones from '../../services/informes/getInformeExtracciones'
import getInformeStockDosis from '../../services/informes/getInformeStockDosis'
import getInformePool from '../../services/informes/getInformePool'
import getInformeProductividadExtracciones from '../../services/informes/getInformeProductividadExtracciones'
import getInformeSeguimientoExtraccionesPool from '../../services/informes/getInformeSeguimientoExtraccionesPool'
import getInformeProductividadCentro from '../../services/informes/getInformeProductividadCentro'
import getInformeTrazabilidad from '../../services/informes/getInformeTrazabilidad'
import getInformeIntervenciones from '../../services/informes/getInformeIntervenciones'
import getInformeCliente from '../../services/informes/getInformeCliente'
import getInformeEstadoCentro from '../../services/informes/getInformeEstadoCentro'
import {translateObservacionesEyaculadoEliminado} from '../../constants/analisis'
import getRegistrosSeguimientoExtraccionesPool from '../../services/informes/getRegistrosSeguimientoExtraccionesPool'
import getObtenerVerracosProductividadExtracciones from '../../services/informes/getObtenerVerracosProductividadExtracciones'
import getObtenerRecomendacionesMatadero from '../../services/informes/getObtenerRecomendacionesMatadero'
import {round_decimal} from '../../util/formatFunctions'
import getImprimirInformeExtracciones from '../../services/informes/getImprimirInformeExtracciones'
import getImprimirInformePool from '../../services/informes/getImprimirInformePool'
import getImprimirInformeProductividadExtracciones from '../../services/informes/getImprimirInformeProductividadExtracciones'
import getImprimirInformesSeguimientoExtraccionesPool from '../../services/informes/getImprimirInformesSeguimientoExtraccionesPool'
import getImprimirInformesProductividadCentro from '../../services/informes/getImprimirInformesProductividadCentro'
import getImprimirInformesTrazabilidad from '../../services/informes/getImprimirInformesTrazabilidad'
import getImprimirInformesIntervenciones from '../../services/informes/getImprimirInformesIntervenciones'
import getImprimirInformesClientes from '../../services/informes/getImprimirInformesClientes'
import getImprimirInformesStockDosis from '../../services/informes/getImprimirInformesStockDosis'
import getCrearCsvInformeExtracciones from '../../services/informes/getCrearCsvInformeExtracciones'
import getCrearCsvInformePool from '../../services/informes/getCrearCsvInformePool'
import getCrearCsvInformeProductividadExtracciones from '../../services/informes/getCrearCsvInformeProductividadExtracciones'
import getImprimirInformeProductividadExtraccionesVerraco from '../../services/informes/getImprimirInformeProductividadExtraccionesVerraco'
import getCrearCsvInformeProductividadExtraccionesVerraco from '../../services/informes/getCrearCsvInformeProductividadExtraccionesVerraco'
import getImprimirInformeProductividadExtraccionesMatadero from '../../services/informes/getImprimirInformeProductividadExtraccionesMatadero'
import getCrearCsvInformeProductividadExtraccionesMatadero from '../../services/informes/getCrearCsvInformeProductividadExtraccionesMatadero'
import getCrearCsvInformeStockDosis from '../../services/informes/getCrearCsvInformeStockDosis'
import getCrearCsvInformeClientes from '../../services/informes/getCrearCsvInformeClientes'
import getCrearCsvInformeTrazabilidad from '../../services/informes/getCrearCsvInformeTrazabilidad'
import getCrearCsvInformeIntervenciones from '../../services/informes/getCrearCsvInformeIntervenciones'
import getCrearCsvInformeProductividadCentro from '../../services/informes/getCrearCsvInformeProductividadCentro'
import getImprimirInformeEstadoCentro from '../../services/informes/getImprimirInformeEstadoCentro'
import getCrearCsvInformesEstadoCentro from '../../services/informes/getCrearCsvInformesEstadoCentro'
import excelInformeSeguimientoExtraccionesPool from '../../services/informes/excelInformeSeguimientoExtraccionesPool'
import getMostrarSeleccionInformePersonalizadoVerraco from '../../services/informes/getMostrarSeleccionInformePersonalizadoVerraco'
import addSeleccionInformePersonalizado from '../../services/informes/addSeleccionInformePersonalizado'
import getComboSeleccionesInformePersonalizado from '../../services/informes/getComboSeleccionesInformePersonalizado'
import getCargarSeleccionInformePersonalizado from '../../services/informes/getCargarSeleccionInformePersonalizado'
import editSeleccionInformePersonalizado from '../../services/informes/editSeleccionInformePersonalizado'
import deleteSeleccionInformePersonalizado from '../../services/informes/deleteSeleccionInformePersonalizado'
import getMostrarSeleccionInformePersonalizadoExtracciones from '../../services/informes/getMostrarSeleccionInformePersonalizadoExtracciones'
import getMostrarSeleccionInformePersonalizadoPlanning from '../../services/informes/getMostrarSeleccionInformePersonalizadoPlanning'
import getMostrarSeleccionInformePersonalizadoAlbaran from '../../services/informes/getMostrarSeleccionInformePersonalizadoAlbaran'
import getMostrarSeleccionInformePersonalizadoCliente from '../../services/informes/getMostrarSeleccionInformePersonalizadoCliente'
import getImprimirInformePersonalizado from '../../services/informes/imprimirPdfInformePersonalizado'
import getCrearCsvInformesPersonalizado from '../../services/informes/crearCsvInformePersonalizado'

// INFORME EXTRACCIONES
export function * obtenerInformeExtracciones ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const listInformeExtracciones = yield call(getInformeExtracciones, values, auth.token)
    const list = listInformeExtracciones.extracciones.map((lista) => {
      if (lista.observacionesEyaculadoEliminado !== null) {
        if(translateObservacionesEyaculadoEliminado[lista.observacionesEyaculadoEliminado] !== undefined){
          lista.causaEliminacion = i18n.t('COMUN.COMBOS.' + translateObservacionesEyaculadoEliminado[lista.observacionesEyaculadoEliminado])
        }
        else{
          lista.causaEliminacion = lista.observacionesEyaculadoEliminado
        }
      }
      lista.porFA = round_decimal(lista.porFA)
      return lista
    })
    const listaInforme = {
      extracciones: listInformeExtracciones.extracciones,
      grafica: listInformeExtracciones.grafica,
      totales: listInformeExtracciones.totales,
      graficaExtraccionesDosis: listInformeExtracciones.graficaExtraccionesDosis,
      graficaExtraccionesConcentracion: listInformeExtracciones.graficaExtraccionesConcentracion
    }
    yield put(obtenerInformeExtraccionesSuccess(listaInforme))
    yield put(fetchFiltrosSuccess(values))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchObtenerInformeExtracciones () {
  yield takeLatest(actionTypes.OBTENER_INFORME_EXTRACCIONES, obtenerInformeExtracciones)
}

export function * imprimirPdfInformesExtracciones ({filtros}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfExtracciones = yield call(getImprimirInformeExtracciones, filtros, auth.token)
    yield put(imprimirPdfInformesExtraccionesSuccess(datosPdfExtracciones))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirPdfInformesExtracciones () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_INFORMES_EXTRACCIONES, imprimirPdfInformesExtracciones)
}

export function * crearCsvInformeExtracciones ({filtros}) {
  try {
    let auth = yield select(state => state.auth)
    const dataCsv = yield call(getCrearCsvInformeExtracciones, filtros, auth.token)
    let href = 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(dataCsv.excel)
    let name = i18n.t('MENU.INFORMES.EXTRACCIONES') + ".xlsx"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchCrearCsvInformeExtracciones  () {
  yield takeLatest(actionTypes.CREAR_CSV_INFORMES_EXTRACCIONES, crearCsvInformeExtracciones)
}

// INFORME STOCK DOSIS
export function * obtenerInformeStockDosis ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const listInformeStockDosis = yield call(getInformeStockDosis, values, auth.token)

    if (listInformeStockDosis.stockDosis && listInformeStockDosis.stockDosis.length > 0) {
      listInformeStockDosis.stockDosis = listInformeStockDosis.stockDosis.map((stockDosisRow) => {
        stockDosisRow.numeroDosisReservadas = '' + stockDosisRow.numeroDosisReservadas
        return stockDosisRow
      })
    }
    yield put(obtenerInformeStockDosisSuccess(listInformeStockDosis))
    yield put(fetchFiltrosStockDosisSuccess(values))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchObtenerInformeStockDosis () {
  yield takeLatest(actionTypes.OBTENER_INFORME_STOCK_DOSIS, obtenerInformeStockDosis)
}

export function * imprimirPdfInformesStockDosis ({filtros}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfInformesStockDosis = yield call(getImprimirInformesStockDosis, filtros, auth.token)
    yield put(imprimirPdfInformesStockDosisSuccess(datosPdfInformesStockDosis))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirPdfInformesStockDosis () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_INFORMES_STOCK_DOSIS, imprimirPdfInformesStockDosis)
}

export function * crearCsvInformeStockDosis ({filtros}) {
  try {
    let auth = yield select(state => state.auth)
    const dataCsv = yield call(getCrearCsvInformeStockDosis, filtros, auth.token)
    let href = 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(dataCsv.excel)
    let name = i18n.t('MENU.INFORMES.STOCK_DOSIS') + ".xlsx"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchCrearCsvInformeStockDosis () {
  yield takeLatest(actionTypes.CREAR_CSV_INFORMES_STOCK_DOSIS, crearCsvInformeStockDosis)
}

// INFORME POOL
export function * obtenerInformePool ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const listInformePool = yield call(getInformePool, values, auth.token)
    yield put(obtenerInformePoolSuccess(listInformePool))
    yield put(fetchFiltrosPoolSuccess(values))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchObtenerInformePool () {
  yield takeLatest(actionTypes.OBTENER_INFORME_POOL, obtenerInformePool)
}

export function * imprimirPdfInformesPool ({filtros}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfPool = yield call(getImprimirInformePool, filtros, auth.token)
    yield put(imprimirPdfInformesPoolSuccess(datosPdfPool))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirPdfInformesPool () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_INFORMES_POOL, imprimirPdfInformesPool)
}

export function * crearCsvInformePool ({filtros}) {
  try {
    let auth = yield select(state => state.auth)
    const dataCsv = yield call(getCrearCsvInformePool, filtros, auth.token)
    let href = 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(dataCsv.excel)
    let name = i18n.t('MENU.INFORMES.POOL') + ".xlsx"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchCrearCsvInformePool () {
  yield takeLatest(actionTypes.CREAR_CSV_INFORMES_POOL, crearCsvInformePool)
}

// INFORME PRODUCTIVIDAD EXTRACCIONES
export function * obtenerInformeProductividadExtracciones ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const listInformeProductividadExtracciones = yield call(getInformeProductividadExtracciones, values, auth.token)
    yield put(obtenerInformeProductividadExtraccionesSuccess(listInformeProductividadExtracciones))
    yield put(fetchFiltrosProductividadExtraccionesSuccess(values))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchObtenerInformeProductividadExtracciones () {
  yield takeLatest(actionTypes.OBTENER_INFORME_PRODUCTIVIDAD_EXTRACCIONES, obtenerInformeProductividadExtracciones)
}

export function * imprimirPdfInformesProductividadExtracciones ({filtros}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfInformesProductividadExtracciones = yield call(getImprimirInformeProductividadExtracciones, filtros, auth.token)
    yield put(imprimirPdfInformesProductividadExtraccionesSuccess(datosPdfInformesProductividadExtracciones))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirPdfInformesProductividadExtracciones () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_INFORMES_PRODUCTIVIDAD_EXTRACCIONES, imprimirPdfInformesProductividadExtracciones)
}

export function * crearCsvInformeProductividadExtracciones ({filtros}) {
  try {
    let auth = yield select(state => state.auth)
    const dataCsv = yield call(getCrearCsvInformeProductividadExtracciones, filtros, auth.token)
    let href = 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(dataCsv.excel)
    let name = i18n.t('MENU.INFORMES.PRODUCTIVIDAD_MACHOS') + ".xlsx"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchCrearCsvInformeProductividadExtracciones () {
  yield takeLatest(actionTypes.CREAR_CSV_INFORME_PRODUCTIVIDAD_EXTRACCIONES, crearCsvInformeProductividadExtracciones)
}

export function * imprimirPdfInformesProductividadExtraccionesVerraco ({filtros}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfInformesProductividadExtraccionesVerraco = yield call(getImprimirInformeProductividadExtraccionesVerraco, filtros, auth.token)
    let href = 'data:application/pdf;base64,' + encodeURIComponent(datosPdfInformesProductividadExtraccionesVerraco.pdf)
    let name = i18n.t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.FILTROS.PRODUCTIVIDAD_VERRACOS') + ".pdf"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirPdfInformesProductividadExtraccionesVerraco () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_INFORMES_PRODUCTIVIDAD_EXTRACCIONES_VERRACO, imprimirPdfInformesProductividadExtraccionesVerraco)
}

export function * crearCsvInformeProductividadExtraccionesVerraco ({filtros}) {
  try {
    let auth = yield select(state => state.auth)
    const dataCsv = yield call(getCrearCsvInformeProductividadExtraccionesVerraco, filtros, auth.token)
    let href = 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(dataCsv.excel)
    let name = i18n.t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.FILTROS.PRODUCTIVIDAD_VERRACOS') + ".xlsx"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchCrearCsvInformeProductividadExtraccionesVerraco () {
  yield takeLatest(actionTypes.CREAR_CSV_INFORME_PRODUCTIVIDAD_EXTRACCIONES_VERRACO, crearCsvInformeProductividadExtraccionesVerraco)
}

export function * imprimirPdfInformesProductividadExtraccionesMatadero ({filtros}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfInformesProductividadExtraccionesMatadero = yield call(getImprimirInformeProductividadExtraccionesMatadero, filtros, auth.token)
    let href = 'data:application/pdf;base64,' + encodeURIComponent(datosPdfInformesProductividadExtraccionesMatadero.pdf)
    let name = i18n.t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.MODAL.RECOMENDACIONES_MATADERO') + ".pdf"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirPdfInformesProductividadExtraccionesMatadero () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_INFORMES_PRODUCTIVIDAD_EXTRACCIONES_MATADERO, imprimirPdfInformesProductividadExtraccionesMatadero)
}

export function * crearCsvInformeProductividadExtraccionesMatadero ({filtros}) {
  try {
    let auth = yield select(state => state.auth)
    const dataCsv = yield call(getCrearCsvInformeProductividadExtraccionesMatadero, filtros, auth.token)
    let href = 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(dataCsv.excel)
    let name = i18n.t('INFORMES.PRODUCTIVIDAD_EXTRACCIONES.MODAL.RECOMENDACIONES_MATADERO') + ".xlsx"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchCrearCsvInformeProductividadExtraccionesMatadero () {
  yield takeLatest(actionTypes.CREAR_CSV_INFORME_PRODUCTIVIDAD_EXTRACCIONES_MATADERO, crearCsvInformeProductividadExtraccionesMatadero)
}

// INFORME SEGUIMIENTO EXTRACCIONES/POOL
export function * obtenerInformeSeguimientoExtraccionesPool ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const listInformeSeguimientoExtraccionesPool = yield call(getInformeSeguimientoExtraccionesPool, values, auth.token)
    yield put(obtenerInformeSeguimientoExtraccionesPoolSuccess(listInformeSeguimientoExtraccionesPool))
    yield put(fetchFiltrosSeguimientoExtraccionesPoolSuccess(values))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchObtenerInformeSeguimientoExtraccionesPool () {
  yield takeLatest(actionTypes.OBTENER_INFORME_SEGUIMIENTO_EXTRACCIONES_POOL, obtenerInformeSeguimientoExtraccionesPool)
}

export function * obtenerRegistrosSeguimientosExtraccionesPool ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const listRegistrosSeguimientoExtraccionesPool = yield call(getRegistrosSeguimientoExtraccionesPool, values, auth.token)
    if (listRegistrosSeguimientoExtraccionesPool.seguimiento !== null) {
      const list = listRegistrosSeguimientoExtraccionesPool.seguimiento.map((listInforme) => {
      if (listInforme.conservacion === 1) {
        listInforme.conservacion = '*'
      }
      if (listInforme.conservacion === 2) {
        listInforme.conservacion = '**'
      }
      if (listInforme.conservacion === 3) {
        listInforme.conservacion = '***'
      }
      if (listInforme.conservacion === 4) {
        listInforme.conservacion = '****'
      }
      if (listInforme.conservacion === 5) {
        listInforme.conservacion = '*****'
      }
      if(listInforme.tipoControl === 1) {
        listInforme.tipoControl = i18n.t('COMUN.COMBOS.SEGUIMIENTO.INTERNO')
      }
      if(listInforme.tipoControl === 2) {
        listInforme.tipoControl = i18n.t('COMUN.COMBOS.SEGUIMIENTO.EXTERNO')
      }
      return listInforme
    })
    const listExtraccionesPool = {seguimiento: list, grafica: listRegistrosSeguimientoExtraccionesPool.grafica}
    yield put(obtenerRegistrosSeguimientosExtraccionesPoolSuccess(listExtraccionesPool))
    } else 
    yield put(obtenerRegistrosSeguimientosExtraccionesPoolSuccess(listRegistrosSeguimientoExtraccionesPool))
    
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchObtenerRegistrosSeguimientosExtraccionesPool () {
  yield takeLatest(actionTypes.OBTENER_REGISTROS_SEGUIMIENTOS_EXTRACCIONES_POOL, obtenerRegistrosSeguimientosExtraccionesPool)
}

export function * imprimirPdfInformesSeguimientoExtraccionesPool ({filtros}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfInformesSeguimientoExtraccionesPool = yield call(getImprimirInformesSeguimientoExtraccionesPool, filtros, auth.token)
    yield put(imprimirPdfInformesSeguimientoExtraccionesPoolSuccess(datosPdfInformesSeguimientoExtraccionesPool))
    yield put(cerrarModalExportarImprimirInformesSeguimientoExtraccionesPool())
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirPdfInformesSeguimientoExtraccionesPool () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_INFORMES_SEGUIMIENTO_EXTRACCIONES_POOL, imprimirPdfInformesSeguimientoExtraccionesPool)
}

export function * exportarInformeSeguimientoExtraccionesPool ({filtros}) {
  try {
    let auth = yield select(state => state.auth)
    const dataCsv = yield call(excelInformeSeguimientoExtraccionesPool, filtros, auth.token)
    let href = 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(dataCsv.excel)
    let name = i18n.t('MENU.INFORMES.SEGUIMIENTO_EXTRACCIONES_POOL') + ".xlsx"
    downloadDocument(href, name)
    yield put(cerrarModalExportarImprimirInformesSeguimientoExtraccionesPool())
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchExportarInformeSeguimientoExtraccionesPool () {
  yield takeLatest(actionTypes.EXPORTAR_INFORMES_SEGUIMIENTO_EXTRACCIONES_POOL, exportarInformeSeguimientoExtraccionesPool)
}

// INFORME PRODUCTIVIDAD CENTRO
export function * obtenerInformeProductividadCentro ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const listInformeProductividadCentro = yield call(getInformeProductividadCentro, values, auth.token)
    yield put(obtenerInformeProductividadCentroSuccess(listInformeProductividadCentro))
    yield put(fetchFiltrosProductividadCentroSuccess(values))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchObtenerInformeProductividadCentro () {
  yield takeLatest(actionTypes.OBTENER_INFORME_PRODUCTIVIDAD_CENTRO, obtenerInformeProductividadCentro)
}

export function * imprimirPdfInformesProductividadCentro ({filtros}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfInformesProductividadCentro = yield call(getImprimirInformesProductividadCentro, filtros, auth.token)
    yield put(imprimirPdfInformesProductividadCentroSuccess(datosPdfInformesProductividadCentro))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirPdfInformesProductividadCentro () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_INFORMES_PRODUCTIVIDAD_CENTRO, imprimirPdfInformesProductividadCentro)
}

export function * crearCsvInformeProductividadCentro ({filtros}) {
  try {
    let auth = yield select(state => state.auth)
    const dataCsv = yield call(getCrearCsvInformeProductividadCentro, filtros, auth.token)
    let href = 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(dataCsv.excel)
    let name = i18n.t('INFORMES.PRODUCTIVIDAD_CENTRO.TITLE') + ".xlsx"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchCrearCsvInformeProductividadCentro () {
  yield takeLatest(actionTypes.CREAR_CSV_INFORME_PRODUCTIVIDAD_CENTRO, crearCsvInformeProductividadCentro)
}

// INFORME TRAZABILIDAD
export function * obtenerInformeTrazabilidad ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const listInformeTrazabilidad = yield call(getInformeTrazabilidad, values, auth.token)

    let valuesFiltrado = Object.keys(values).filter((key) => values[key] != null )
    if (valuesFiltrado.length < 1) {
      listInformeTrazabilidad.totales.numeroClientes = '-'
      listInformeTrazabilidad.totales.numeroDosis = '-'
    }

    yield put(obtenerInformeTrazabilidadSuccess(listInformeTrazabilidad))
    yield put(fetchFiltrosTrazabilidadSuccess(values))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchObtenerInformeTrazabilidad () {
  yield takeLatest(actionTypes.OBTENER_INFORME_TRAZABILIDAD, obtenerInformeTrazabilidad)
}

export function * imprimirPdfInformesTrazabilidad ({filtros}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfInformesTrazabilidad = yield call(getImprimirInformesTrazabilidad, filtros, auth.token)
    yield put(imprimirPdfInformesTrazabilidadSuccess(datosPdfInformesTrazabilidad))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirPdfInformesTrazabilidad () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_INFORMES_TRAZABILIDAD, imprimirPdfInformesTrazabilidad)
}

export function * crearCsvInformeTrazabilidad ({filtros}) {
  try {
    let auth = yield select(state => state.auth)
    const dataCsv = yield call(getCrearCsvInformeTrazabilidad, filtros, auth.token)
    let href = 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(dataCsv.excel)
    let name = i18n.t('MENU.INFORMES.TRAZABILIDAD') + ".xlsx"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchCrearCsvInformeTrazabilidad () {
  yield takeLatest(actionTypes.CREAR_CSV_INFORME_TRAZABILIDAD, crearCsvInformeTrazabilidad)
}

// INFORME INTERVENCIONES
export function * obtenerInformeIntervenciones ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const listInformeIntervenciones = yield call(getInformeIntervenciones, values, auth.token)
    yield put(obtenerInformeIntervencionesSuccess(listInformeIntervenciones))
    yield put(fetchFiltrosIntervencionesSuccess(values))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchObtenerInformeIntervenciones () {
  yield takeLatest(actionTypes.OBTENER_INFORME_INTERVENCIONES, obtenerInformeIntervenciones)
}

export function * imprimirPdfInformesIntervenciones ({filtros}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfInformesIntervenciones = yield call(getImprimirInformesIntervenciones, filtros, auth.token)
    yield put(imprimirPdfInformesIntervencionesSuccess(datosPdfInformesIntervenciones))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirPdfInformesIntervenciones () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_INFORMES_INTERVENCIONES, imprimirPdfInformesIntervenciones)
}

export function * crearCsvInformeIntervenciones ({filtros}) {
  try {
    let auth = yield select(state => state.auth)
    const dataCsv = yield call(getCrearCsvInformeIntervenciones, filtros, auth.token)
    let href = 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(dataCsv.excel)
    let name = i18n.t('MENU.INFORMES.INTERVENCIONES') + ".xlsx"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchCrearCsvInformeIntervenciones () {
  yield takeLatest(actionTypes.CREAR_CSV_INFORME_INTERVENCIONES, crearCsvInformeIntervenciones)
}

// CLIENTE
export function * obtenerInformeCliente ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const listInformeCliente = yield call(getInformeCliente, values, auth.token)
    yield put(obtenerInformeClienteSuccess(listInformeCliente))
    yield put(fetchFiltrosClienteSuccess(values))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchObtenerInformeCliente () {
  yield takeLatest(actionTypes.OBTENER_INFORME_CLIENTE, obtenerInformeCliente)
}

export function * imprimirPdfInformesClientes ({filtros}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfInformesClientes = yield call(getImprimirInformesClientes, filtros, auth.token)
    yield put(imprimirPdfInformesClientesSuccess(datosPdfInformesClientes))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirPdfInformesClientes () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_INFORMES_CLIENTES, imprimirPdfInformesClientes)
}

export function * crearCsvInformeClientes ({filtros}) {
  try {
    let auth = yield select(state => state.auth)
    const dataCsv = yield call(getCrearCsvInformeClientes, filtros, auth.token)
    let href = 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(dataCsv.excel)
    let name = i18n.t('MENU.INFORMES.CLIENTES') + ".xlsx"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchCrearCsvInformeClientes () {
  yield takeLatest(actionTypes.CREAR_CSV_INFORME_CLIENTES, crearCsvInformeClientes)
}

// ESTADO_CENTRO
export function * obtenerInformeEstadoCentro ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const listInformeEstadoCentro = yield call(getInformeEstadoCentro, values, auth.token)
    yield put(obtenerInformeEstadoCentroSuccess(listInformeEstadoCentro))
    yield put(fetchFiltrosEstadoCentroSuccess(values))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchObtenerInformeEstadoCentro () {
  yield takeLatest(actionTypes.OBTENER_INFORME_ESTADO_CENTRO, obtenerInformeEstadoCentro)
}

export function * obtenerVerracosProductividadExtracciones ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const listVerracos = yield call(getObtenerVerracosProductividadExtracciones, values, auth.token)
    yield put(obtenerVerracosProductividadExtraccionesSuccess(listVerracos))
    yield put(fetchFiltrosEstadoCentroSuccess(values))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchObtenerVerracosProductividadExtracciones () {
  yield takeLatest(actionTypes.OBTENER_VERRACOS_PRODUCTIVIDAD_EXTRACCIONES, obtenerVerracosProductividadExtracciones)
}

export function * obtenerRecomendacionesMatadero ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const listRecomendacionesMatadero = yield call(getObtenerRecomendacionesMatadero, values, auth.token)
    yield put(obtenerRecomendacionesMataderoSuccess(listRecomendacionesMatadero))
    yield put(fetchFiltrosEstadoCentroSuccess(values))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchObtenerRecomendacionesMatadero () {
  yield takeLatest(actionTypes.OBTENER_RECOMENDACIONES_MATADERO, obtenerRecomendacionesMatadero)
}

export function * imprimirPdfInformesEstadoCentro ({filtros}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdf = yield call(getImprimirInformeEstadoCentro, filtros, auth.token)
    let href = 'data:application/pdf;base64,' + encodeURIComponent(datosPdf.pdf)
    let name = i18n.t('MENU.INFORMES.ESTADO_CENTRO') + ".pdf"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirPdfInformesEstadoCentro () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_INFORMES_ESTADO_CENTRO, imprimirPdfInformesEstadoCentro)
}

export function * crearCsvInformesEstadoCentro ({filtros}) {
  try {
    let auth = yield select(state => state.auth)
    const dataCsv = yield call(getCrearCsvInformesEstadoCentro, filtros, auth.token)
    let href = 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(dataCsv.excel)
    let name = i18n.t('MENU.INFORMES.ESTADO_CENTRO') + ".xlsx"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchCrearCsvInformesEstadoCentro () {
  yield takeLatest(actionTypes.CREAR_CSV_INFORMES_ESTADO_CENTRO, crearCsvInformesEstadoCentro)
}

// INFORME PERSONALIZADO
export function * mostrarSeleccionInformePersonalizado ({list, campo}) {
  try {
    let auth = yield select(state => state.auth)
    const lista = []
    const valoresTablaPersonalizado = []
    const entidadesTablaPersonalizado = []
    list.forEach((lis) => {
      lista.push(lis.value)
      valoresTablaPersonalizado.push({id: lis.value, name: lis.nombre, type: lis.type, numDecimales: lis.numDecimales, stilyzer: lis.stilyzer})

      if (!entidadesTablaPersonalizado.includes(lis.entidad)) {
        entidadesTablaPersonalizado.push(lis.entidad)
      }
    })
    let valores = []
    valores.lista = lista
    valores.entidades = entidadesTablaPersonalizado
    if (campo === 'verraco') {
      const datosTablaPersonalizado = yield call(getMostrarSeleccionInformePersonalizadoVerraco, valores, auth.token)
      yield put(mostrarSeleccionInformePersonalizadoSuccess(datosTablaPersonalizado))
      yield put(valoresTablaPersonalizadoSuccess(valoresTablaPersonalizado))
      yield put(camposSeleccionadosPersonalizadoSuccess(valores))
    } else if (campo === 'extracciones') {
      const datosTablaPersonalizado = yield call(getMostrarSeleccionInformePersonalizadoExtracciones, valores, auth.token)
      yield put(mostrarSeleccionInformePersonalizadoSuccess(datosTablaPersonalizado))
      yield put(valoresTablaPersonalizadoSuccess(valoresTablaPersonalizado))
      yield put(camposSeleccionadosPersonalizadoSuccess(valores))
    } else if (campo === 'planning') {
      const datosTablaPersonalizado = yield call(getMostrarSeleccionInformePersonalizadoPlanning, valores, auth.token)
      yield put(mostrarSeleccionInformePersonalizadoSuccess(datosTablaPersonalizado))
      yield put(valoresTablaPersonalizadoSuccess(valoresTablaPersonalizado))
      yield put(camposSeleccionadosPersonalizadoSuccess(valores))
    } /*else if (campo === 'albaran') {
      const datosTablaPersonalizado = yield call(getMostrarSeleccionInformePersonalizadoAlbaran, valores, auth.token)
      yield put(mostrarSeleccionInformePersonalizadoSuccess(datosTablaPersonalizado))
      yield put(valoresTablaPersonalizadoSuccess(valoresTablaPersonalizado))
      yield put(camposSeleccionadosPersonalizadoSuccess(valores))
    }*/ else if (campo === 'cliente') {
      const datosTablaPersonalizado = yield call(getMostrarSeleccionInformePersonalizadoCliente, valores, auth.token)
      yield put(mostrarSeleccionInformePersonalizadoSuccess(datosTablaPersonalizado))
      yield put(valoresTablaPersonalizadoSuccess(valoresTablaPersonalizado))
      yield put(camposSeleccionadosPersonalizadoSuccess(valores))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchMostrarSeleccionInformePersonalizado () {
  yield takeLatest(actionTypes.MOSTRAR_SELECCION_INFORME_PERSONALIZADO, mostrarSeleccionInformePersonalizado)
}

export function * abrirModalGuardarSeleccion ({list, campo}) {
  try {
    let auth = yield select(state => state.auth)
    const lista = []
    list.forEach((lis) => {
      lista.push(lis.value)
    })
    let camposSeleccionadosPersonalizado = {
      camposSeleccionados: lista,
      entidad: campo
    }
    // if (campo === 'verraco') {
      yield put(camposSeleccionadosPersonalizadoSuccess(camposSeleccionadosPersonalizado))
    // }
    let stateDatosSeleccionInformePersonalizado = yield select(state => state.informes.datosSeleccionInformePersonalizado)
    if (Object.keys(stateDatosSeleccionInformePersonalizado).length > 0){
      yield put(initialize('ModalGuardarSeleccionInformePersonalizado', stateDatosSeleccionInformePersonalizado))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchAbrirModalGuardarSeleccion () {
  yield takeLatest(actionTypes.ABRIR_MODAL_GUARDAR_SELECCION, abrirModalGuardarSeleccion)
}

export function * submitGuardarSeleccionInformePersonalizado ({values}) {
  try {
    let auth = yield select(state => state.auth)
    let stateDatosSeleccionInformePersonalizado = yield select(state => state.informes.datosSeleccionInformePersonalizado)
    if (Object.keys(stateDatosSeleccionInformePersonalizado).length > 0){
      const insercionSeleccionInformePersonalizado = yield call(editSeleccionInformePersonalizado, values, auth.token)
      if (insercionSeleccionInformePersonalizado.actualizado === true) {
        yield put(openMensajeAlerta('modificacion'))
      } else {
        yield put(openMensajeAlerta('no-modificacion'))
      }
      yield put(cerrarModalGuardarSeleccion())
      yield put(initialize('ModalGuardarSeleccionInformePersonalizado', {}))
      yield put(deseleccionarTodosInformePersonalizado())
      yield put(initialize('simpleTable', {}))
      yield put(initialize('InformesPersonalizado', {}))
      const data = yield call(getComboSeleccionesInformePersonalizado, values.entidad, null)
      yield put(comboSeleccionesInformePersonalizadoSuccess(data))
      yield put(cargarSeleccionInformePersonalizadoSuccess([]))
    } else {
      const insercionSeleccionInformePersonalizado = yield call(addSeleccionInformePersonalizado, values, auth.token)
      if (insercionSeleccionInformePersonalizado.insertado === true) {
        yield put(openMensajeAlerta('insercion'))
      } else {
        yield put(openMensajeAlerta('no-insercion'))
      }
      yield put(cerrarModalGuardarSeleccion())
      yield put(initialize('ModalGuardarSeleccionInformePersonalizado', {}))
      yield put(deseleccionarTodosInformePersonalizado())
      yield put(initialize('simpleTable', {}))
      const data = yield call(getComboSeleccionesInformePersonalizado, values.entidad, null)
      yield put(comboSeleccionesInformePersonalizadoSuccess(data))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchSubmitGuardarSeleccionInformePersonalizado () {
  yield takeLatest(actionTypes.SUBMIT_GUARDAR_SELECCION_INFORME_PERSONALIZADO, submitGuardarSeleccionInformePersonalizado)
}

export function * comboSeleccionesInformePersonalizado ({entidad}) {
  try {
    let auth = yield select(state => state.auth)
    const data = yield call(getComboSeleccionesInformePersonalizado, entidad, null)
    yield put(comboSeleccionesInformePersonalizadoSuccess(data))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchComboSeleccionesInformePersonalizado () {
  yield takeLatest(actionTypes.COMBO_SELECCIONES_INFORME_PERSONALIZADO, comboSeleccionesInformePersonalizado)
}

export function * cargarSeleccionInformePersonalizado ({idInformePersonalizado}) {
  try {
    if (idInformePersonalizado !== null) {
      let auth = yield select(state => state.auth)
      const data = yield call(getCargarSeleccionInformePersonalizado, idInformePersonalizado, null)
      yield put(cargarSeleccionInformePersonalizadoSuccess(data))
      let valores = {}
      let camposSeleccionados = []
      camposSeleccionados = data.camposSeleccionados.split(',')
      if (camposSeleccionados.length > 0) {
        camposSeleccionados.forEach((campos) => {
          valores['check' + campos] = true
        })
      }
      yield put(initialize('simpleTable', valores))
    } else {
      yield put(cargarSeleccionInformePersonalizadoSuccess([]))
      yield put(initialize('simpleTable', {}))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchCargarSeleccionInformePersonalizado () {
  yield takeLatest(actionTypes.CARGAR_SELECCION_INFORME_PERSONALIZADO, cargarSeleccionInformePersonalizado)
}

export function * eliminarSeleccionInformePersonalizado ({values}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const registroEliminado = yield call(deleteSeleccionInformePersonalizado, values, auth.token)
      if (registroEliminado.eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(initialize('ModalGuardarSeleccionInformePersonalizado', {}))
      yield put(deseleccionarTodosInformePersonalizado())
      yield put(initialize('simpleTable', {}))
      yield put(initialize('InformesPersonalizado', {}))
      const data = yield call(getComboSeleccionesInformePersonalizado, values.entidad, null)
      yield put(comboSeleccionesInformePersonalizadoSuccess(data))
      yield put(cargarSeleccionInformePersonalizadoSuccess([]))
      yield call(mostrarSeleccionInformePersonalizado, {list: [], campo: values.entidad})
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchEliminarSeleccionInformePersonalizado () {
  yield takeLatest(actionTypes.ELIMINAR_SELECCION_INFORME_PERSONALIZADO, eliminarSeleccionInformePersonalizado)
}

export function * imprimirPdfInformePersonalizado ({values, campo}) {
  try {
    let auth = yield select(state => state.auth)
    const lista = []
    const entidades = []
    values.forEach((lis) => {
      lista.push(lis.value)

      if (!entidades.includes(lis.entidad)) {
        entidades.push(lis.entidad)
      }
    })
    const filtros = {lista: lista, campo: campo, entidades}
    const datosPdf = yield call(getImprimirInformePersonalizado, filtros, auth.token)
    let href = 'data:application/pdf;base64,' + encodeURIComponent(datosPdf.pdf)
    let name = i18n.t('MENU.INFORMES.PERSONALIZADO') + ".pdf"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirPdfInformePersonalizado () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_INFORME_PRESONALIZADO, imprimirPdfInformePersonalizado)
}

export function * crearCsvInformePersonalizado ({values, campo}) {
  try {
    let auth = yield select(state => state.auth)
    const lista = []
    const entidades = []
    values.forEach((lis) => {
      lista.push(lis.value)

      if (!entidades.includes(lis.entidad)) {
        entidades.push(lis.entidad)
      }
    })
    const filtros = {lista: lista, campo: campo, entidades}
    const dataCsv = yield call(getCrearCsvInformesPersonalizado, filtros, auth.token)
    let href = 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(dataCsv.excel)
    let name = i18n.t('MENU.INFORMES.PERSONALIZADO') + ".xlsx"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchCrearCsvInformePersonalizado () {
  yield takeLatest(actionTypes.CREAR_CSV_INFORME_PERSONALIZADO, crearCsvInformePersonalizado)
}
