import actionTypes from '../../constants/actions/trazabilidadMaterial/trazabilidadMaterial'
import {categorias as categoriasEscandallos} from '../../constants/escandallo'

export function initialState () {
  return {
    showModal: false,
    idCategoria: null,
    idTipoDosis: null,
    idAnalisis: null,
    idEnvasadoMultiDosis: null,
    lotesEscandallo: [],
    utilizarLotesPreferidosDelUsuario: false,
    utilizarLotesPreferidosDelUsuarioEnvasado: {},
    stockInsuficiente: {
      [categoriasEscandallos.ANALISIS]: false,
      [categoriasEscandallos.DILUCION]: false,
      [categoriasEscandallos.ENVASADO]: false,
      [categoriasEscandallos.ANALISIS_DILUCION]: false,
    },
    checkedFiltrosModalTrazabilidadMaterial: false,
    showModalEditarTrazabilidad: false,
    disabled: false,
    showStockColumns: false
  }
}

function comprobarStockTrazabilidadSuccess (state, {stockInsuficiente}) {
  return {
    ...state,
    stockInsuficiente: {
      ...state.stockInsuficiente,
      ...stockInsuficiente
    }
  }
}

function updateStockInsuficienteSuccess (state, {stockInsuficiente}) {
  return {
    ...state,
    stockInsuficiente: {
      ...state.stockInsuficiente,
      ...stockInsuficiente
    }
  }
}

function updateLotesEscandalloSuccess (state, {lotesEscandallo}) {
  return {
    ...state,
    lotesEscandallo
  }
}

function openModalTrazabilidadMaterialSuccess (state, {idCategoria, idTipoDosis, idAnalisis, idEnvasadoMultiDosis, lotesEscandallo, disabled, showStockColumns}) {
  return {
    ...state,
    showModal: true,
    idCategoria,
    idTipoDosis,
    idAnalisis,
    idEnvasadoMultiDosis,
    lotesEscandallo,
    disabled,
    showStockColumns
  }
}

function guardarModalTrazabilidadMaterialSuccess (state) {
  let newState = {
    ...state,
    showModal: false,
    idCategoria: null,
    idTipoDosis: null,
    lotesEscandallo: [],
  }

  if (state.idCategoria === categoriasEscandallos.ENVASADO) {
    newState.utilizarLotesPreferidosDelUsuarioEnvasado[state.idEnvasadoMultiDosis] = true
  } else {
    newState.utilizarLotesPreferidosDelUsuario = true
  }

  return newState
}

function closeModalTrazabilidadMaterialSuccess (state) {
  return {
    ...state,
    showModal: false,
    idCategoria: null,
    idTipoDosis: null,
    lotesEscandallo: []
  }
}

export function seleccionarTodosTrazabilidadMaterial (state) {
  return {
    ...state,
    checkedFiltrosModalTrazabilidadMaterial: true
  }
}

export function deSeleccionarTodosTrazabilidadMaterial (state) {
  return {
    ...state,
    checkedFiltrosModalTrazabilidadMaterial: false
  }
}

function openModalEditarTrazabilidadMaterialSuccess (state) {
  return {
    ...state,
    showModalEditarTrazabilidad: true,
  }
}

function guardarModalEditarTrazabilidadMaterialSuccess (state) {
  return {
    ...state,
    showModalEditarTrazabilidad: false,
  }
}

function closeModalEditarTrazabilidadMaterialSuccess (state) {
  return {
    ...state,
    showModalEditarTrazabilidad: false,
  }
}

function resetUtilizarLotesPreferidosDelUsuarioSuccess (state) {
  return {
    ...state,
    utilizarLotesPreferidosDelUsuario: false,
    utilizarLotesPreferidosDelUsuarioEnvasado: {}
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.COMPROBAR_STOCK_TRAZABILIDAD_SUCCESS:
      return comprobarStockTrazabilidadSuccess(state, action)
    case actionTypes.UPDATE_STOCK_INSUFICIENTE_SUCCESS:
      return updateStockInsuficienteSuccess(state, action)
    case actionTypes.UPDATE_LOTES_ESCANDALLO_SUCCESS:
      return updateLotesEscandalloSuccess(state, action)
    case actionTypes.OPEN_MODAL_TRAZABILIDAD_MATERIAL_SUCCESS:
      return openModalTrazabilidadMaterialSuccess(state, action)
    case actionTypes.GUARDAR_MODAL_TRAZABILIDAD_MATERIAL_SUCCESS:
      return guardarModalTrazabilidadMaterialSuccess(state, action)
    case actionTypes.CLOSE_MODAL_TRAZABILIDAD_MATERIAL_SUCCESS:
      return closeModalTrazabilidadMaterialSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_TRAZABILIDAD_MATERIAL:
      return seleccionarTodosTrazabilidadMaterial(state, action)
    case actionTypes.DESELECCIONAR_TODOS_TRAZABILIDAD_MATERIAL:
      return deSeleccionarTodosTrazabilidadMaterial(state, action)
    case actionTypes.OPEN_MODAL_EDITAR_TRAZABILIDAD_MATERIAL_SUCCESS:
      return openModalEditarTrazabilidadMaterialSuccess(state, action)
    case actionTypes.GUARDAR_MODAL_EDITAR_TRAZABILIDAD_MATERIAL_SUCCESS:
      return guardarModalEditarTrazabilidadMaterialSuccess(state, action)
    case actionTypes.CLOSE_MODAL_EDITAR_TRAZABILIDAD_MATERIAL_SUCCESS:
      return closeModalEditarTrazabilidadMaterialSuccess(state, action)
    case actionTypes.RESET_UTILIZAR_LOTES_PREFERIDOS_DEL_USUARIO_SUCCESS:
      return resetUtilizarLotesPreferidosDelUsuarioSuccess(state, action)
    default:
      return state
  }
}