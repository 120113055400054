import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Foso from '../../components/foso/Foso'
import {fetchFiltrarFoso, guardarFoso, modificarFoso, eliminarFoso, obtenerDatosVerraco} from '../../actions/foso/foso'
import {comboOperarioActivo, comboVerraco} from '../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.foso,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    auth: state.auth,
    combos: state.combos,
    formFoso: state.form.formFoso
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchFiltrarFoso,
      comboOperarioActivo,
      guardarFoso,
      modificarFoso,
      eliminarFoso,
      comboVerraco,
      obtenerDatosVerraco
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Foso))