export default {
  FETCH_PANEL_CONTROL: 'FETCH_PANEL_CONTROL',
  FETCH_PANEL_CONTROL_SUCCESS: 'FETCH_PANEL_CONTROL_SUCCESS',
  OBTENER_DATOS_PANEL_CONTROL: 'OBTENER_DATOS_PANEL_CONTROL',
  OBTENER_DATOS_PANEL_CONTROL_SUCCESS: 'OBTENER_DATOS_PANEL_CONTROL_SUCCESS',
  GUARDAR_PANEL_CONTROL: 'GUARDAR_PANEL_CONTROL',
  ABRIR_MODAL_TECLAS_CONTAJE: 'ABRIR_MODAL_TECLAS_CONTAJE',
  CERRAR_MODAL_TECLAS_CONTAJE: 'CERRAR_MODAL_TECLAS_CONTAJE',
  OBTENER_VALORES_TECLA_CONTAJE: 'OBTENER_VALORES_TECLA_CONTAJE',
  OBTENER_VALORES_TECLA_CONTAJE_SUCCESS: 'OBTENER_VALORES_TECLA_CONTAJE_SUCCESS',
  VALORES_PREDEFINIDOS_TECLAS_CONTAJE: 'VALORES_PREDEFINIDOS_TECLAS_CONTAJE',
  GUARDAR_TECLAS_CONTAJE: 'GUARDAR_TECLAS_CONTAJE',
  ABRIR_MODAL_TIPO_DILUYENTE: 'ABRIR_MODAL_TIPO_DILUYENTE',
  CERRAR_MODAL_TIPO_DILUYENTE: 'CERRAR_MODAL_TIPO_DILUYENTE',
  ANADIR_DILUYENTE_PANEL_CONTROL: 'ANADIR_DILUYENTE_PANEL_CONTROL',
  OBTENER_TIPO_DILUYENTE: 'OBTENER_TIPO_DILUYENTE',
  OBTENER_TIPO_DILUYENTE_SUCCESS: 'OBTENER_TIPO_DILUYENTE_SUCCESS',
  RECUPERAR_DATOS_TIPO_DILUYENTE: 'RECUPERAR_DATOS_TIPO_DILUYENTE',
  RECUPERAR_DATOS_TIPO_DILUYENTE_SUCCESS: 'RECUPERAR_DATOS_TIPO_DILUYENTE_SUCCESS',
  EDITAR_DILUYENTE_PANEL_CONTROL: 'EDITAR_DILUYENTE_PANEL_CONTROL',
  DUPLICAR_DATOS_TIPO_DILUYENTE: 'DUPLICAR_DATOS_TIPO_DILUYENTE',
  DUPLICAR_DATOS_TIPO_DILUYENTE_SUCCESS: 'DUPLICAR_DATOS_TIPO_DILUYENTE_SUCCESS',
  CAMBIA_PREDETERMINADO_DILUYENTE: 'CAMBIA_PREDETERMINADO_DILUYENTE',
  CAMBIA_PREDETERMINADO_DILUYENTE_SUCCESS: 'CAMBIA_PREDETERMINADO_DILUYENTE_SUCCESS',
  ON_DELETE_DILUYENTE: 'ON_DELETE_DILUYENTE',
  ON_DELETE_DILUYENTE_SUCCESS: 'ON_DELETE_DILUYENTE_SUCCESS',
  SELECCIONAR_TODOS_TIPO_DILUYENTE: 'SELECCIONAR_TODOS_TIPO_DILUYENTE',
  DESELECCIONAR_TODOS_TIPO_DILUYENTE: 'DESELECCIONAR_TODOS_TIPO_DILUYENTE',
  DELETE_SELECCION_TIPO_DILUYENTE: 'DELETE_SELECCION_TIPO_DILUYENTE',
  ABRIR_MODAL_TIPO_DOSIS: 'ABRIR_MODAL_TIPO_DOSIS',
  CERRAR_MODAL_TIPO_DOSIS: 'CERRAR_MODAL_TIPO_DOSIS',
  ANADIR_DOSIS_PANEL_CONTROL: 'ANADIR_DOSIS_PANEL_CONTROL',
  OBTENER_TIPO_DOSIS: 'OBTENER_TIPO_DOSIS',
  OBTENER_TIPO_DOSIS_SUCCESS: 'OBTENER_TIPO_DOSIS_SUCCESS',
  RECUPERAR_DATOS_TIPO_DOSIS: 'RECUPERAR_DATOS_TIPO_DOSIS',
  RECUPERAR_DATOS_TIPO_DOSIS_SUCCESS: 'RECUPERAR_DATOS_TIPO_DOSIS_SUCCESS',
  EDITAR_DOSIS_PANEL_CONTROL: 'EDITAR_DOSIS_PANEL_CONTROL',
  DUPLICAR_DATOS_TIPO_DOSIS: 'DUPLICAR_DATOS_TIPO_DOSIS',
  DUPLICAR_DATOS_TIPO_DOSIS_SUCCESS: 'DUPLICAR_DATOS_TIPO_DOSIS_SUCCESS',
  CAMBIA_PREDETERMINADO_DOSIS: 'CAMBIA_PREDETERMINADO_DOSIS',
  CAMBIA_PREDETERMINADO_DOSIS_SUCCESS: 'CAMBIA_PREDETERMINADO_DOSIS_SUCCESS',
  ON_DELETE_DOSIS: 'ON_DELETE_DOSIS',
  ON_DELETE_DOSIS_SUCCESS: 'ON_DELETE_DOSIS_SUCCESS',
  SELECCIONAR_TODOS_TIPO_DOSIS: 'SELECCIONAR_TODOS_TIPO_DOSIS',
  DESELECCIONAR_TODOS_TIPO_DOSIS: 'DESELECCIONAR_TODOS_TIPO_DOSIS',
  DELETE_SELECCION_TIPO_DOSIS: 'DELETE_SELECCION_TIPO_DOSIS',
  ABRIR_MODAL_RECTA_COLORIMETRO: 'ABRIR_MODAL_RECTA_COLORIMETRO',
  CERRAR_MODAL_RECTA_COLORIMETRO: 'CERRAR_MODAL_RECTA_COLORIMETRO',
  ABRIR_MODAL_ANADIR_COLORIMETRO: 'ABRIR_MODAL_ANADIR_COLORIMETRO',
  CERRAR_MODAL_ANADIR_COLORIMETRO: 'CERRAR_MODAL_ANADIR_COLORIMETRO',
  SUBMIT_ANADIR_COLORIMETRO: 'SUBMIT_ANADIR_COLORIMETRO',
  FETCH_RECTA_COLORIMETRO: 'FETCH_RECTA_COLORIMETRO',
  FETCH_RECTA_COLORIMETRO_SUCCESS: 'FETCH_RECTA_COLORIMETRO_SUCCESS',
  RECUPERAR_DATOS_RECTA_COLORIMETRO: 'RECUPERAR_DATOS_RECTA_COLORIMETRO',
  RECUPERAR_DATOS_RECTA_COLORIMETRO_SUCCESS: 'RECUPERAR_DATOS_RECTA_COLORIMETRO_SUCCESS',
  EDITAR_COLORIMETRO: 'EDITAR_COLORIMETRO',
  EDITAR_COLORIMETRO_SUCCESS: 'EDITAR_COLORIMETRO_SUCCESS',
  DUPLICAR_COLORIMETRO: 'DUPLICAR_COLORIMETRO',
  DUPLICAR_COLORIMETRO_SUCCESS: 'DUPLICAR_COLORIMETRO_SUCCESS',
  CAMBIA_PREDETERMINADO_RECTA_COLORIMETRO: 'CAMBIA_PREDETERMINADO_RECTA_COLORIMETRO',
  ON_DELETE_RECTA_COLORIMETRO: 'ON_DELETE_RECTA_COLORIMETRO',
  ABRIR_MODAL_ANADIR_MUESTRAS: 'ABRIR_MODAL_ANADIR_MUESTRAS',
  CERRAR_MODAL_ANADIR_MUESTRAS: 'CERRAR_MODAL_ANADIR_MUESTRAS',
  ANADIR_MUESTRA: 'ANADIR_MUESTRA',
  RECUPERAR_DATOS_MUESTRAS: 'RECUPERAR_DATOS_MUESTRAS',
  RECUPERAR_DATOS_MUESTRAS_SUCCESS: 'RECUPERAR_DATOS_MUESTRAS_SUCCESS',
  EDIT_MUESTAS_ANADIDAS: 'EDIT_MUESTAS_ANADIDAS',
  EDIT_MUESTAS_ANADIDAS_SUCCESS: 'EDIT_MUESTAS_ANADIDAS_SUCCESS',
  DUPLICAR_DATOS_MUESTRAS: 'DUPLICAR_DATOS_MUESTRAS',
  DUPLICAR_DATOS_MUESTRAS_SUCCESS: 'DUPLICAR_DATOS_MUESTRAS_SUCCESS',
  ON_DELETE_MUESTRAS: 'ON_DELETE_MUESTRAS',
  REINICIAR_MUESTRA_VERRACO: 'REINICIAR_MUESTRA_VERRACO',
  SELECCIONAR_TODOS_COLORIMETRO: 'SELECCIONAR_TODOS_COLORIMETRO',
  DESELECCIONAR_TODOS_COLORIMETRO: 'DESELECCIONAR_TODOS_COLORIMETRO',
  DELETE_SELECCION_RECTA_COLORIMETRO: 'DELETE_SELECCION_RECTA_COLORIMETRO',
  ABRIR_MODAL_ANADIR_VERRACOS: 'ABRIR_MODAL_ANADIR_VERRACOS',
  CERRAR_MODAL_ANADIR_VERRACOS: 'CERRAR_MODAL_ANADIR_VERRACOS',
  FETCH_LISTADO_VERRACOS: 'FETCH_LISTADO_VERRACOS',
  FETCH_LISTADO_VERRACOS_SUCCESS: 'FETCH_LISTADO_VERRACOS_SUCCESS',
  SELECCIONAR_TODOS_VERRACOS: 'SELECCIONAR_TODOS_VERRACOS',
  DESELECCIONAR_TODOS_VERRACOS: 'DESELECCIONAR_TODOS_VERRACOS',
  ANADIR_VERRACOS: 'ANADIR_VERRACOS',
  SELECCIONAR_VERRACOS_ANADIDOS: 'SELECCIONAR_VERRACOS_ANADIDOS',
  DESELECCIONAR_VERRACOS_ANADIDOS: 'DESELECCIONAR_VERRACOS_ANADIDOS',
  SELECCIONAR_TODOS_MUESTRAS: 'SELECCIONAR_TODOS_MUESTRAS',
  DESELECCIONAR_TODOS_MUESTRAS: 'DESELECCIONAR_TODOS_MUESTRAS',
  EDITAR_VERRACOS: 'EDITAR_VERRACOS',
  VALORES_PREDEFINIDOS_PANEL_CONTROL: 'VALORES_PREDEFINIDOS_PANEL_CONTROL',
  UPLOAD_FILE_PANEL_CONTROL: 'UPLOAD_FILE_PANEL_CONTROL',
  GUARDAR_Y_NUEVO_MUESTRAS: 'GUARDAR_Y_NUEVO_MUESTRAS',
  ON_DELETE_VERRACOS: 'ON_DELETE_VERRACOS',
  ON_DELETE_VERRACOS_SUCCESS: 'ON_DELETE_VERRACOS_SUCCESS',
  VER_FORMULA: 'VER_FORMULA',
  VER_FORMULA_R: 'VER_FORMULA_R',
  CALCULO_RECTA: 'CALCULO_RECTA',
  RECUPERAR_SECUENCIA_NUMERACIONES: 'RECUPERAR_SECUENCIA_NUMERACIONES',
  RECUPERAR_SECUENCIA_NUMERACIONES_SUCCESS: 'RECUPERAR_SECUENCIA_NUMERACIONES_SUCCESS',
  ABRIR_MODAL_SECUENCIAS_NUMERACION: 'ABRIR_MODAL_SECUENCIAS_NUMERACION',
  CERRAR_MODAL_SECUENCIAS_NUMERACION: 'CERRAR_MODAL_SECUENCIAS_NUMERACION',
  FETCH_SECUENCIAS_NUMERACION: 'FETCH_SECUENCIAS_NUMERACION',
  FETCH_SECUENCIAS_NUMERACION_SUCCESS: 'FETCH_SECUENCIAS_NUMERACION_SUCCESS',
  EDITAR_SECUENCIA: 'EDITAR_SECUENCIA'
}