import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize} from 'redux-form'
import i18n from '../../i18n'
import actionTypes from '../../constants/actions/transporte/transporte'
import {fetchTransportistaSuccess, filtrarTrasnporteSuccess, recuperarModalConductorSuccess, editConductorAnadidoSuccess, cambiarEstadoConductorSuccess, duplicarConductorSucccess,
  conductorPorDefectoSuccess, reiniciarConductoresVehiculosRutas, editVehiculoAnadidoSuccess, cambiarEstadoVehiculoSuccess, duplicarVehiculoSucccess, vehiculoPorDefectoSuccess,
  recuperarModalVehiculoSuccess, filtrarRutasTransporteSuccess, onDeleteRutaAnadidaSuccess, rutaPorDefectoSuccess, filtrosAplicados, cambiarEstadoTransporteSuccess, onDeleteTransporteSuccess,
  deSeleccionarTodosTransporte, crearCsvTransporteSuccess, imprimirPdfTransporteSuccess} from '../../actions/transporte/transporte'
import {openModal, closeModal, openSimpleModal, openMensajeAlerta} from '../../actions/common'
import { yesNoModal } from '../modal/yesNoModal'
import getFiltrarTransporte from '../../services/transporte/getFiltrarTransporte'
import getFiltrarRuta from '../../services/transporte/getFiltrarRuta'
import addTransportista from '../../services/transporte/addTransportista'
import getTransporte from '../../services/transporte/getTransporte'
import editarTransportista from '../../services/transporte/editarTransportista'
import cambioEstadoTransporte from '../../services/transporte/cambioEstadoTransporte'
import deleteTransporte from '../../services/transporte/deleteTransporte'
import cambioEstadoTransporteSeleccion from '../../services/transporte/cambioEstadoTransporteSeleccion'
import deleteTransporteSeleccion from '../../services/transporte/deleteTransporteSeleccion'
import getCrearCsvTransporte from '../../services/transporte/getCrearCsvTransporte'
import getImprimirPdfTransporte from '../../services/transporte/getImprimirPdfTransporte'
import comprobarEliminacionConductor from '../../services/transporte/comprobarEliminacionConductor'
import comprobarEliminacionVehiculo from '../../services/transporte/comprobarEliminacionVehiculo'

export function * fetchTransportista () {
  try {
    /*const list = yield call(getTransporteList, null)
    yield put(fetchTransportistaSuccess(list))*/
  } catch (error) {

  } finally {
  }
}
export function * watchFetchTransportista () {
  yield takeLatest(actionTypes.FETCH_TRANSPORTISTA, fetchTransportista)
}

export function * filtrarTransporte ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const list = yield call(getFiltrarTransporte, values, auth.token)
    yield put(fetchTransportistaSuccess(list))
    yield put(filtrarTrasnporteSuccess(values))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchFiltrarTrasporte () {
  yield takeLatest(actionTypes.FILTRAR_TRANSPORTE, filtrarTransporte)
}

export function * abrirModalConductor () {
  try {
    const valores = {activo: true}
    yield put(initialize('ModalConductor', valores))
    yield put(openModal())
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchAbrirModalConductor() {
  yield takeLatest(actionTypes.ABRIR_MODAL_CONDUCTOR, abrirModalConductor)
}

export function * recuperarModalConductor ({idConductor}) {
  try {
    const conductoresState = yield select(state => state.transporte.conductoresAnadidos)
    const conductorRecuperar = conductoresState.filter((conductor) => {
      if (conductor.idElemento === idConductor){
        return conductor
      }
    })
    yield put(recuperarModalConductorSuccess(conductorRecuperar[0]))
    yield put(initialize('ModalConductor', conductorRecuperar[0]))
    yield put(openModal())
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchRecuperarModalCondutor() {
  yield takeLatest(actionTypes.RECUPERAR_MODAL_CONDUCTOR, recuperarModalConductor)
}

export function * editConductorAnadido ({values}) {
  try {
    const conductoresState = yield select(state => state.transporte.conductoresAnadidos)
    const conductorRecuperar = conductoresState.filter((conductor) => {
      if (conductor.idElemento === values.idElemento){
        conductor.activo = values.activo
        conductor.dniConductor = values.dniConductor
        conductor.movilConductor = values.movilConductor
        conductor.nombreConductor = values.nombreConductor
        conductor.predefinido = values.predefinido
      } else {
        if (values.predefinido === true) {
          conductor.predefinido = false
        }
      }
      return conductor
    })
    yield put(editConductorAnadidoSuccess(conductorRecuperar))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchEditConductorAnadido() {
  yield takeLatest(actionTypes.EDIT_CONDUCTOR_ANADIDO, editConductorAnadido)
}

export function * cambiarEstadoConductor ({idConductor}) {
  try {
   yield put(openSimpleModal('cambiarEstado'))
    const confirmed = yield call(yesNoModal, {modalType: 'cambiarEstado'})
    if (confirmed) {
      const conductoresState = yield select(state => state.transporte.conductoresAnadidos)
      const conductoresAnadidos = conductoresState.filter((conductor) => {
        if (conductor.idElemento === idConductor){
          conductor.activo = !conductor.activo
        }
        return conductor
      })

      const cambioEstadoTrasporte = yield select(state => state.transporte.cambioEstadoTrasporte)
      let conductor = cambioEstadoTrasporte
      if (conductor === false) {
        conductor = true
      } else {
        conductor = false
      }
      yield put(cambiarEstadoConductorSuccess(conductor))
      yield put(editConductorAnadidoSuccess(conductoresAnadidos))
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchCambiarEstadoConductor() {
  yield takeLatest(actionTypes.CAMBIAR_ESTADO_CONDUCTOR, cambiarEstadoConductor)
}

export function * onDeleteConductorAnadido ({idConductor, idProducto}) {
  try {
     yield put(openSimpleModal('onDelete'))
      const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
      if (confirmed) {
        const conductoresState = yield select(state => state.transporte.conductoresAnadidos)
        const conductoresAnadidos = conductoresState.filter((conductor) => {
        if (conductor.idElemento !== idConductor){
          return conductor
        }
      })
      yield put(editConductorAnadidoSuccess(conductoresAnadidos))
      if (idProducto !== undefined) {
        const comprobacion = yield call(comprobarEliminacionConductor, idProducto, null)
        if (comprobacion.eliminar === true) {
          yield put(openMensajeAlerta('eliminacion'))
        } else {
          yield put(openMensajeAlerta('no-eliminacion'))
        }
      } else {
        yield put(openMensajeAlerta('eliminacion'))
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchOnDeleteConductorAnadido() {
  yield takeLatest(actionTypes.ON_DELETE_CONDUCTOR_ANADADIDO, onDeleteConductorAnadido)
}

export function * duplicarConductor ({idConductor}) {
  try {
    const conductoresState = yield select(state => state.transporte.conductoresAnadidos)
    const duplicarConductor = conductoresState.filter((conductor) => {
      if (conductor.idElemento === idConductor){
        return conductor
      }
    })
    yield put(duplicarConductorSucccess(duplicarConductor[0]))
    yield put(initialize('ModalConductor', duplicarConductor[0]))
    yield put(openModal())
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchDuplicarConductor() {
  yield takeLatest(actionTypes.DUPLICAR_CONDUCTOR, duplicarConductor)
}

export function * conductorPorDefecto ({idConductor}) {
  try {
   yield put(openSimpleModal('conductorPorDefecto'))
    const confirmed = yield call(yesNoModal, {modalType: 'conductorPorDefecto'})
    if (confirmed) {
      const conductoresState = yield select(state => state.transporte.conductoresAnadidos)
      const conductoresAnadidos = conductoresState.map((conductor) => {
        if (conductor.idElemento === idConductor){
          conductor.predefinido = !conductor.predefinido
        } else {
          if (conductor.predefinido === true){
            conductor.predefinido = !conductor.predefinido
          }
        }
        return conductor
      })
      const cambioPorDefecto = yield select(state => state.transporte.cambioPorDefecto)
      let conductor = cambioPorDefecto
      if (conductor === false) {
        conductor = true
      } else {
        conductor = false
      }
      yield put(conductorPorDefectoSuccess(conductor))
      yield put(editConductorAnadidoSuccess(conductoresAnadidos))
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchConductorPorDefecto() {
  yield takeLatest(actionTypes.CONDUCTOR_POR_DEFECTO, conductorPorDefecto)
}

export function * abrirModalVehiculo () {
  try {
    const valores = {activo: true}
    yield put(initialize('ModalVehiculo', valores))
    yield put(openModal())
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchAbrirModalVehiculo() {
  yield takeLatest(actionTypes.ABRIR_MODAL_VEHICULO, abrirModalVehiculo)
}

export function * recuperarModalVehiculo ({idVehiculo}) {
  try {
    const vehiculosState = yield select(state => state.transporte.vehiculosAnadidos)
    const vehiculoRecuperar = vehiculosState.filter((vehiculo) => {
      if (vehiculo.idElemento === idVehiculo){
        return vehiculo
      }
    })
    yield put(recuperarModalVehiculoSuccess(vehiculoRecuperar[0]))
    yield put(initialize('ModalVehiculo', vehiculoRecuperar[0]))
    yield put(openModal())
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchRecuperarModalVehiculo() {
  yield takeLatest(actionTypes.RECUPERAR_MODAL_VEHICULO, recuperarModalVehiculo)
}

export function * editVehiculoAnadido ({values}) {
  try {
    const vehiculosState = yield select(state => state.transporte.vehiculosAnadidos)
    const vehiculoRecuperar = vehiculosState.filter((vehiculo) => {
      if (vehiculo.idElemento === values.idElemento){
        vehiculo.activo = values.activo
        vehiculo.matricula = values.matricula
        vehiculo.modelo = values.modelo
        vehiculo.predefinido = values.predefinido
      } else {
        if (vehiculo.predefinido === true) {
          vehiculo.predefinido = false
        }
      }
      return vehiculo
    })
    yield put(editVehiculoAnadidoSuccess(vehiculoRecuperar))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchEditVehiculoAnadido() {
  yield takeLatest(actionTypes.EDIT_VEHICULO_ANADIDO, editVehiculoAnadido)
}

export function * cambiarEstadoVehiculo ({idVehiculo}) {
  try {
   yield put(openSimpleModal('cambiarEstado'))
    const confirmed = yield call(yesNoModal, {modalType: 'cambiarEstado'})
    if (confirmed) {
      const vehiculosState = yield select(state => state.transporte.vehiculosAnadidos)
      const vehiculosAnadidos = vehiculosState.filter((vehiculo) => {
        if (vehiculo.idElemento === idVehiculo){
          vehiculo.activo = !vehiculo.activo
        }
        return vehiculo
      })

      const cambioEstadoVehiculo = yield select(state => state.transporte.cambioEstadoVehiculo)
      let vehiculo = cambioEstadoVehiculo
      if (vehiculo === false) {
        vehiculo = true
      } else {
        vehiculo = false
      }
      yield put(cambiarEstadoVehiculoSuccess(vehiculo))
      yield put(editVehiculoAnadidoSuccess(vehiculosAnadidos))
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchCambiarEstadoVehiculo() {
  yield takeLatest(actionTypes.CAMBIAR_ESTADO_VEHICULO, cambiarEstadoVehiculo)
}

export function * onDeleteVehiculoAnadido ({idVehiculo, id}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      const vehiculosState = yield select(state => state.transporte.vehiculosAnadidos)
      const vehiculosAnadidos = vehiculosState.filter((vehiculo) => {
        if (vehiculo.idElemento !== idVehiculo){
          return vehiculo
        }
      })
      yield put(editVehiculoAnadidoSuccess(vehiculosAnadidos))
      if (id !== undefined) {
        const comprobacion = yield call(comprobarEliminacionVehiculo, id, null)
        if (comprobacion.eliminar === true) {
          yield put(openMensajeAlerta('eliminacion'))
        } else {
          yield put(openMensajeAlerta('no-eliminacion'))
        }
      } else {
        yield put(openMensajeAlerta('eliminacion'))
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchOnDeleteVehiculoAnadido() {
  yield takeLatest(actionTypes.ON_DELETE_VEHICULO_ANADADIDO, onDeleteVehiculoAnadido)
}

export function * duplicarVehiculo ({idVehiculo}) {
  try {
    const vehiculosState = yield select(state => state.transporte.vehiculosAnadidos)
    const duplicarVehiculo = vehiculosState.filter((vehiculo) => {
      if (vehiculo.idElemento === idVehiculo){
        return vehiculo
      }
    })
    yield put(duplicarVehiculoSucccess(duplicarVehiculo[0]))
    yield put(initialize('ModalVehiculo', duplicarVehiculo[0]))
    yield put(openModal())
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchDuplicarVehiculo() {
  yield takeLatest(actionTypes.DUPLICAR_VEHICULO, duplicarVehiculo)
}

export function * vehiculoPorDefecto ({idVehiculo}) {
  try {
   yield put(openSimpleModal('vehiculoPorDefecto'))
    const confirmed = yield call(yesNoModal, {modalType: 'vehiculoPorDefecto'})
    if (confirmed) {
      const vehiculosState = yield select(state => state.transporte.vehiculosAnadidos)
      const vehiculosAnadidos = vehiculosState.map((vehiculo) => {
        if (vehiculo.idElemento === idVehiculo){
          vehiculo.predefinido = !vehiculo.predefinido
        } else {
          if (vehiculo.predefinido === true) {
            vehiculo.predefinido = !vehiculo.predefinido
          }
        }
        return vehiculo
      })
      const cambioPorDefectoVehiculo = yield select(state => state.transporte.cambioPorDefectoVehiculo)
      let vehiculo = cambioPorDefectoVehiculo
      if (vehiculo === false) {
        vehiculo = true
      } else {
        vehiculo = false
      }
      yield put(vehiculoPorDefectoSuccess(vehiculo))
      yield put(editVehiculoAnadidoSuccess(vehiculosAnadidos))
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchVehiculoPorDefecto() {
  yield takeLatest(actionTypes.VEHICULO_POR_DEFECTO, vehiculoPorDefecto)
}

export function * filtrarRutasTransporte ({rutasAnadidas}) {
  try {
    let auth = yield select(state => state.auth)
    const listRutas = yield call(getFiltrarRuta, rutasAnadidas, auth.token)
    yield put(filtrarRutasTransporteSuccess(listRutas))
    yield put(filtrosAplicados(rutasAnadidas))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchFiltrarRutasTransporte () {
  yield takeLatest(actionTypes.FILTRAR_RUTAS_TRANSPORTE, filtrarRutasTransporte)
}

export function * abrirModalRuta ({values}) {
  try {
    let auth = yield select(state => state.auth)
    let rutasAnadidas = yield select(state => state.transporte.rutasAnadidas)
    const listRutas = yield call(getFiltrarRuta, {list: rutasAnadidas, activo: {value: true, label: i18n.t('ESTADO.ACTIVO')}}, auth.token)
    let valores = {
      activo: {value: true, label: i18n.t('ESTADO.ACTIVO')},
      ruta: null,
      cliente: null
    }
    yield put(initialize('ModalRuta', valores))
    yield put(filtrarRutasTransporteSuccess(listRutas))
    yield put(filtrosAplicados(valores))
    yield put(openModal())
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchAbrirModalRuta() {
  yield takeLatest(actionTypes.ABRIR_MODAL_RUTA, abrirModalRuta)
}

export function * onDeleteRutaAnadida ({idRuta}) {
  try {
   yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      const rutasState = yield select(state => state.transporte.rutasAnadidas)
      const rutasAnadidas = rutasState.filter((ruta) => {
        if (ruta.idElemento !== idRuta){
          return ruta
        }
      })
      yield put(onDeleteRutaAnadidaSuccess(rutasAnadidas))
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchOnDeleteRutaAnadida() {
  yield takeLatest(actionTypes.ON_DELETE_RUTA_ANADADIDA, onDeleteRutaAnadida)
}

export function * rutaPorDefecto ({idRuta}) {
  try {
   yield put(openSimpleModal('rutaPorDefecto'))
    const confirmed = yield call(yesNoModal, {modalType: 'rutaPorDefecto'})
    if (confirmed) {
      const rutasState = yield select(state => state.transporte.rutasAnadidas)
      const rutasAnadidas = rutasState.map((ruta) => {
        if (ruta.idElemento === idRuta){
          ruta.predefinido = !ruta.predefinido
        } else {
          if (ruta.predefinido === true) {
            ruta.predefinido = !ruta.predefinido
          }
        }
        return ruta
      })
      const cambioPorDefectoRuta = yield select(state => state.transporte.cambioPorDefectoRuta)
      let ruta = cambioPorDefectoRuta
      if (ruta === false) {
        ruta = true
      } else {
        ruta = false
      }
      yield put(rutaPorDefectoSuccess(ruta))
      yield put(onDeleteRutaAnadidaSuccess(rutasAnadidas))
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchRutaPorDefecto() {
  yield takeLatest(actionTypes.RUTA_POR_DEFECTO, rutaPorDefecto)
}

export function * submitNuevoTransportista ({values, history, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const insercionTranportista = yield call(addTransportista, values, auth.token)
    if (insercionTranportista.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
      yield put(initialize('ModalVehiculo', {}))
      yield put(initialize('DuplicarTransportista', {}))
      yield put(reiniciarConductoresVehiculosRutas())
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-insercion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    yield put(closeModal())
    history.push({pathname: '/Transporte', state: { from: history.location.pathname }})
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
  }
}
export function * watchSubmitNuevoTransportista() {
  yield takeLatest(actionTypes.SUBMIT_NUEVO_TRANSPORTISTA, submitNuevoTransportista)
}

export function * guardarYnuevoTransporte ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const insercionTranportista = yield call(addTransportista, values, auth.token)
    if (insercionTranportista.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
      let valores = {}
      valores.activo = true
      yield put(initialize('NuevoTransportista', valores))
      yield put(initialize('DuplicarTransportista', valores))
      yield put(reiniciarConductoresVehiculosRutas())
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-insercion'))
      if (onErrorCallback) yield onErrorCallback()
    }
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
  }
}
export function * watchGuardarYnuevoTransporte() {
  yield takeLatest(actionTypes.GUARDAR_Y_NUEVO_TRANSPORTE, guardarYnuevoTransporte)
}

export function * fetchEditarTransportista ({idTransportista}) {
  try {
    const list = yield call(getTransporte, idTransportista, null)
    // yield put(fetchTransportistaSuccess(list))
    yield put(initialize('EditarTransportista', list.transporte))
    yield put(initialize('DuplicarTransportista', list.transporte))
    if (Object.keys(list.conductores).length > 0){
      yield put(editConductorAnadidoSuccess(list.conductores))
    }
    if (Object.keys(list.vehiculos).length > 0){
      yield put(editVehiculoAnadidoSuccess(list.vehiculos))
    }
    if (Object.keys(list.rutas).length > 0){
      yield put(onDeleteRutaAnadidaSuccess(list.rutas))
    }
  } catch (error) {

  } finally {
  }
}
export function * watchFetchEditarTransportista () {
  yield takeLatest(actionTypes.FETCH_EDITAR_TRANSPORTISTA, fetchEditarTransportista)
}

export function * submitEditarTransportista ({values, history}) {
  try {
    let auth = yield select(state => state.auth)
    const insercionTranportista = yield call(editarTransportista, values, auth.token)
    if (insercionTranportista.actualizado === true) {
      yield put(openMensajeAlerta('modificacion'))
      yield put(initialize('EditarTransportista', {}))
      yield put(reiniciarConductoresVehiculosRutas())
    } else {
      yield put(openMensajeAlerta('no-modificacion'))
    }
    yield put(closeModal())
    history.push({pathname: '/Transporte', state: { from: history.location.pathname }})
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchSubmitEditarTransportista() {
  yield takeLatest(actionTypes.SUBMIT_EDITAR_TRANSPORTISTA, submitEditarTransportista)
}

export function * cambiarEstadoTransporte ({idTransportista}) {
  try {
   yield put(openSimpleModal('cambiarEstado'))
    const confirmed = yield call(yesNoModal, {modalType: 'cambiarEstado'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const cambioEstado = yield call(cambioEstadoTransporte, idTransportista, auth.token)
      yield put(cambiarEstadoTransporteSuccess(cambioEstado))
      if (cambioEstado.actualizado) {
        yield put(openMensajeAlerta('cambio-estado'))
      } else {
        yield put(openMensajeAlerta('no-cambio-estado'))
      }
      const values = yield select(state => state.transporte.filtros)
      const list = yield call(getFiltrarTransporte, values, auth.token)
      yield put(fetchTransportistaSuccess(list))
      yield put(filtrarTrasnporteSuccess(values))
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchCambiarEstadoTransporte() {
  yield takeLatest(actionTypes.CAMBIAR_ESTADO_TRANSPORTE, cambiarEstadoTransporte)
}

export function * onDeleteTransporte ({idTransportista}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const onDelete = yield call(deleteTransporte, idTransportista, auth.token)
      yield put(onDeleteTransporteSuccess(onDelete))
      if (onDelete.eliminado === true && onDelete.asociado === false) {
        yield put(openMensajeAlerta('eliminacion'))
      } else if (onDelete.eliminado === false && onDelete.asociado === true) {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      const values = yield select(state => state.transporte.filtros)
      const list = yield call(getFiltrarTransporte, values, auth.token)
      yield put(fetchTransportistaSuccess(list))
      yield put(filtrarTrasnporteSuccess(values))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchOnDeleteTransporte () {
  yield takeLatest(actionTypes.ON_DELETE_TRANSPORTE, onDeleteTransporte)
}

export function * cambioEstadoSeleccionTransporte ({list}) {
  try {
    yield put(openSimpleModal('cambiarEstado'))
    const confirmed = yield call(yesNoModal, {modalType: 'cambiarEstado'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      let cambioEstadoSeleccion = yield call(cambioEstadoTransporteSeleccion, list, auth.token)
      if (cambioEstadoSeleccion.actualizado) {
        yield put(openMensajeAlerta('cambio-estado'))
      } else {
        yield put(openMensajeAlerta('no-cambio-estado'))
      }
      yield put(deSeleccionarTodosTransporte())
      yield put(initialize('filtrosTransporte'))
      yield put(initialize('simpleTable'))
      const val = yield select(state => state.transporte.filtros)
      const lista = yield call(getFiltrarTransporte, val, auth.token)
      yield put(fetchTransportistaSuccess(lista))
      yield put(filtrarTrasnporteSuccess(val))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchCambioEstadoSeleccionTransporte () {
  yield takeLatest(actionTypes.CAMBIO_ESTADO_SELECCION_TRANSPORTE, cambioEstadoSeleccionTransporte)
}

export function * deleteSeleccionTransporte ({list}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      let onDeleteSelecion = yield call(deleteTransporteSeleccion, list, auth.token)
      if (onDeleteSelecion.eliminado === true && onDeleteSelecion.asociado === false) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(deSeleccionarTodosTransporte())
      yield put(initialize('filtrosTransporte'))
      yield put(initialize('simpleTable'))
      const val = yield select(state => state.transporte.filtros)
      const lista = yield call(getFiltrarTransporte, val, auth.token)
      yield put(fetchTransportistaSuccess(lista))
      yield put(filtrarTrasnporteSuccess(val))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchDeleteSeleccionTransporte () {
  yield takeLatest(actionTypes.DELETE_SELECCION_TRANSPORTE, deleteSeleccionTransporte)
}

export function * crearCsvTransporte ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosExcelTransporte = yield call(getCrearCsvTransporte, list, auth.token)
    yield put(crearCsvTransporteSuccess(datosExcelTransporte))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchCrearCsvTransporte () {
  yield takeLatest(actionTypes.CREATE_CSV_TRANSPORTE, crearCsvTransporte)
}

export function * imprimirPdfTransporte ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfTransporte = yield call(getImprimirPdfTransporte, list, auth.token)
    yield put(imprimirPdfTransporteSuccess(datosPdfTransporte))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirPdfTransporte () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_TRANSPORTE, imprimirPdfTransporte)
}
