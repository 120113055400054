import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import { getFormValues } from 'redux-form'
import { yesNoModal } from '../../actions/common'
import {comboPoolsExtraccionAlbaran, comboVerracosExtraccionAlbaran} from '../../actions/combos/combos'
import {cerrarDetallesAlbaranDosis, editarAlbaranDosis} from '../../actions/albaran/albaran'
import ModalAlbaranDosis from '../../components/albaran/ModalAlbaranDosis'

export function mapStateToProps (state) {
  return {
    ...state.albaran.data,
    formModalAlbaran: getFormValues('ModalAlbaran')(state),
    formModalAlbaranDosis: getFormValues('ModalAlbaranDosis')(state),
    auth: state.auth,
    combos: {
      comboTipoDosis: state.combos.comboTipoDosis,
      comboRaza: state.combos.comboRaza,
      comboLineaGenetica: state.combos.comboLineaGenetica,
      comboPoolsExtraccionAlbaran: state.combos.comboPoolsExtraccionAlbaran,
      comboVerracosExtraccionAlbaran: state.combos.comboVerracosExtraccionAlbaran
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      cerrarDetallesAlbaranDosis,
      editarAlbaranDosis,
      comboPoolsExtraccionAlbaran,
      comboVerracosExtraccionAlbaran,
      yesNoModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalAlbaranDosis))