import apiFetch from '../apiFetch'

export default function (baseUrlMagavision, tokenUsuarioMagavision, t_anl, signal) {
  return apiFetch({
    server: null,
    endPoint: `${baseUrlMagavision + (baseUrlMagavision[baseUrlMagavision.length - 1] !== '/' ? '/' : '')}api/analysis?t_usr=${tokenUsuarioMagavision}&t_anl=${t_anl}`,
    method: 'GET',
    mode: 'cors',
    credentials: 'omit',
    accept: '*/*',
    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
    signal: signal
  })
}
