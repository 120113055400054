export default {
  FETCH_SEGUIMIENTOS: "FETCH_SEGUIMIENTOS",
  FETCH_SEGUIMIENTOS_SUCCESS: "FETCH_SEGUIMIENTOS_SUCCESS",
  SELECCIONAR_TODOS_SEGUIMIENTO: "SELECCIONAR_TODOS_SEGUIMIENTO",
  DESELECCIONAR_TODOS_SEGUIMIENTO: "DESELECCIONAR_TODOS_SEGUIMIENTO",
  OPEN_MODAL_LISTADO_SEGUIMIENTO: 'OPEN_MODAL_LISTADO_SEGUIMIENTO',
  OPEN_MODAL_LISTADO_SEGUIMIENTO_SUCCESS: 'OPEN_MODAL_LISTADO_SEGUIMIENTO_SUCCESS',
  CLOSE_MODAL_LISTADO_SEGUIMIENTO: 'CLOSE_MODAL_LISTADO_SEGUIMIENTO',
  CLOSE_MODAL_LISTADO_SEGUIMIENTO_SUCCESS: 'CLOSE_MODAL_LISTADO_SEGUIMIENTO_SUCCESS',
  SELECCIONAR_TODOS_MODAL_SEGUIMIENTO: 'SELECCIONAR_TODOS_MODAL_SEGUIMIENTO',
  DESELECCIONAR_TODOS_MODAL_SEGUIMIENTO: 'DESELECCIONAR_TODOS_MODAL_SEGUIMIENTO',
  DELETE_SEGUIMIENTO: 'DELETE_SEGUIMIENTO',
  DELETE_SELECCION_SEGUIMIENTO: 'DELETE_SELECCION_SEGUIMIENTO',
  CREATE_CSV_SEGUIMIENTO: 'CREATE_CSV_SEGUIMIENTO',
  IMPRIMIR_PDF_SEGUIMIENTO: 'IMPRIMIR_PDF_SEGUIMIENTO',
  OPEN_MODAL_FORM_SEGUIMIENTO: 'OPEN_MODAL_FORM_SEGUIMIENTO',
  OPEN_MODAL_FORM_SEGUIMIENTO_SUCCESS: 'OPEN_MODAL_FORM_SEGUIMIENTO_SUCCESS',
  GUARDAR_MODAL_FORM_SEGUIMIENTO: 'GUARDAR_MODAL_FORM_SEGUIMIENTO',
  GUARDAR_MODAL_FORM_SEGUIMIENTO_SUCCESS: 'GUARDAR_MODAL_FORM_SEGUIMIENTO_SUCCESS',
  CLOSE_MODAL_FORM_SEGUIMIENTO: 'CLOSE_MODAL_FORM_SEGUIMIENTO',
  CLOSE_MODAL_FORM_SEGUIMIENTO_SUCCESS: 'CLOSE_MODAL_FORM_SEGUIMIENTO_SUCCESS',
  OBTENER_Y_GUARDAR_RESULTADO_SISTEMA_ANALISIS_SEGUIMIENTO: 'OBTENER_Y_GUARDAR_RESULTADO_SISTEMA_ANALISIS_SEGUIMIENTO'
}