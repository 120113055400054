import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosPlaning from '../../components/planing/FiltrosPlaning'
import {elegirDiasPlaning, abrirModalPlaning, fetchFiltrarPlaning, cargarVerracosPlanificados, abrirModalExportarImprimirPlaning, abrirDuplicarPlaning, seleccionarTodosPlanningPrincipal, deSeleccionarTodosPlanningPrincipal, anadirSeleccionPlaningPrincipal} from '../../actions/planing/planing'
import {comboRaza, comboLineaGenetica, comboUbicacionPadre, comboNombreGrupoVerraco, comboOperario, comboCliente, comboIndex} from '../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.planing,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formSimpleTable: state.form.simpleTable,
    auth: state.auth,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      elegirDiasPlaning,
      abrirModalPlaning,
      comboRaza,
      comboLineaGenetica,
      comboUbicacionPadre,
      comboNombreGrupoVerraco,
      fetchFiltrarPlaning,
      cargarVerracosPlanificados,
      comboOperario,
      comboCliente,
      abrirModalExportarImprimirPlaning,
      comboIndex,
      abrirDuplicarPlaning,
      seleccionarTodosPlanningPrincipal,
      deSeleccionarTodosPlanningPrincipal,
      anadirSeleccionPlaningPrincipal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosPlaning))