import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize, change} from 'redux-form'
import i18n from '../../i18n'
import {dateTimeZones} from '../../util/formatFunctions'
import actionTypes from '../../constants/actions/entradaMercancia/entradaMercancia'
import { filtrarEntradaMercanciaSuccess, filtrosEntradaMercanciaSuccess, recuperarModalEntradaMercanciaProductoAnadidoSuccess, duplicarDatosModalEntradaMercanciaAnadidoSuccess,
  editarEntradaMercanciaProductoSuccess, closeModalEntradaMercancia, recuperarDatosModalEntradaMercanciaSuccess, duplicarDatosModalEntradaMercanciaSuccess, resetDatosEntradaMercanciaProducto, deleteSeleccionEntradaMercanciaProductoAnadidoSuccess, 
  guardarYnuevoEntradaMercanciaSuccess, deSeleccionarTodosEntradaMercancia, crearCsvEntradaMercanciaSuccess, imprimirPdfEntradaMercanciaSuccess, fetchLoteExistenteSuccess
} from '../../actions/entradaMercancia/entradaMercancia'
import {openSimpleModal, openMensajeAlerta} from '../../actions/common'
import { yesNoModal } from '../modal/yesNoModal'
import addEntradaMercancia from '../../services/entradaMercancia/addEntradaMercancia'
import filtrosEntradaMercancia from '../../services/entradaMercancia/filtrosEntradaMercancia'
import getEntradaMercancia from '../../services/entradaMercancia/getEntradaMercancia'
import editEntradaMercancia from '../../services/entradaMercancia/editEntradaMercancia'
import deleteEntradaMercancia from '../../services/entradaMercancia/deleteEntradaMercancia'
import eliminarEntradaMercanciaSeleccion from '../../services/entradaMercancia/eliminarEntradaMercanciaSeleccion'
import getCrearCsv from '../../services/entradaMercancia/getCrearCsv'
import getImprimirPdfEntradaMercancia from '../../services/entradaMercancia/getImprimirPdfEntradaMercancia'
import comprobarEliminacionEntradaAlmacenProducto from '../../services/entradaMercancia/comprobarEliminacionEntradaAlmacenProducto'
import getDatosLote from '../../services/entradaMercancia/getDatosLote'
import {comboProveedoresEntradaMercancia, comboAlbaranesEntradaMercancia, comboOrigenesEntradaMercancia} from '../../actions/combos/combos'
import { obtenerNotificaconesUsuarioSuccess } from '../../actions/notificaciones/notificaciones'
import getNotificaciones from '../../services/notificaciones/getNotificaciones'
import comprobarEliminacionEntradaAlmacenProductoSeleccion from '../../services/entradaMercancia/comprobarEliminacionEntradaAlmacenProductoSeleccion'

export function * filtrarEntradaMercancia ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const list = yield call(filtrosEntradaMercancia, values, auth.token)
    yield put(filtrarEntradaMercanciaSuccess(list))
    yield put(filtrosEntradaMercanciaSuccess(values))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchFiltrarEntradaMercancia () {
  yield takeLatest(actionTypes.FILTRAR_ENTRADA_MERCANCIA, filtrarEntradaMercancia)
}

export function * abrirModalEntradaMercancia ({values}) {
  try {
    yield put(initialize('ModalEntradaMercancia', {}))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchAbrirModalEntradaMercancia () {
  yield takeLatest(actionTypes.ABRIR_MODAL_ENTRADA_MERCANCIA, abrirModalEntradaMercancia )
}

export function * recuperarModalEntradaMercanciaProductoAnadido  ({idElemento, proceso}) {
  try {
    let auth = yield select(state => state.auth)

    let productoAlmacenState = yield select(state => state.entradaMercancia.entradaMercanciaProductos)
    const productoAlmacenRecuperar = productoAlmacenState.filter((producto) => {
      if (producto.idElemento === idElemento){
        return producto
      }
    })
    yield put(initialize('ModalEntradaMercanciaAnadirProducto', productoAlmacenRecuperar[0]))
    yield put(recuperarModalEntradaMercanciaProductoAnadidoSuccess(productoAlmacenRecuperar[0]))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchRecuperarModalEntradaMercanciaProductoAnadido () {
  yield takeLatest(actionTypes.RECUPERAR_MODAL_ENTRADA_MERCANCIA_PRODUCTO_ANADIDO, recuperarModalEntradaMercanciaProductoAnadido)
}

export function * editarEntradaMercanciaProducto ({values, onSuccessCallback, onErrorCallback}) {
  try {
     let productoAlmacenState = yield select(state => state.entradaMercancia.entradaMercanciaProductos)
     const productoAlmacenRecuperar = productoAlmacenState.filter((producto) => {
      if (producto.idElemento === values.idElemento){
        producto.cantidad = values.cantidad
        producto.fechaCaducidad = values.fechaCaducidad
        producto.idElemento = values.idElemento
        producto.idProducto = values.idProducto
        producto.lote = values.lote
        producto.nombre = values.nombre
        producto.stockProduccion = values.stockProduccion
      }
      return producto
    })
    yield put(editarEntradaMercanciaProductoSuccess(productoAlmacenRecuperar))
    if (onSuccessCallback) yield onSuccessCallback()
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchEditarEntradaMercanciaProducto () {
  yield takeLatest(actionTypes.EDITAR_ENTRADA_MERCANCIA_PRODUCTO, editarEntradaMercanciaProducto )
}


export function * abrirModalEntradaMercanciaAnadirProducto ({values}) {
  try {
    let auth = yield select(state => state.auth)
    /*let date = new Date()
    let lote = 'REF' + date.getFullYear() + (date.getMonth()+1 < 10 ? '0' : '') + (date.getMonth()+1) + (date.getDate() < 10 ? '0' : '') + date.getDate() +
                (date.getHours() < 10 ? '0' : '') + date.getHours() + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() + (date.getSeconds() < 10 ? '0' : '') + date.getSeconds()*/
    yield put(initialize('ModalEntradaMercanciaAnadirProducto', {}))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchAbrirModalEntradaMercanciaAnadirProducto () {
  yield takeLatest(actionTypes.ABRIR_MODAL_ENTRADA_MERCANCIA_ANADIR_PRODUCTO, abrirModalEntradaMercanciaAnadirProducto )
}

export function * duplicarDatosModalEntradaMercanciaAnadido  ({idElemento, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    let productoAlmacenState = yield select(state => state.entradaMercancia.entradaMercanciaProductos)
    let datosProducto = []
    const productoAlmacenRecuperar = productoAlmacenState.filter((producto) => {
      if (producto.idElemento === idElemento) {
        datosProducto = {
          idEntradaAlmacenProducto: undefined,
          idProducto: producto.idProducto,
          nombre: producto.nombre,
          cantidad: producto.cantidad,
          lote: producto.lote,
          fechaCaducidad: producto.fechaCaducidad,
          editable: true
        }
        return datosProducto
      }
    })
    yield put(initialize('ModalEntradaMercanciaAnadirProducto', datosProducto))
    yield put(duplicarDatosModalEntradaMercanciaAnadidoSuccess(datosProducto))
    if (onSuccessCallback) yield onSuccessCallback()
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchDuplicarDatosModalEntradaMercanciaAnadido () {
  yield takeLatest(actionTypes.DUPLICAR_MODAL_ENTRADA_MERCANCIA_PRODUCTO_ANADIDO, duplicarDatosModalEntradaMercanciaAnadido)
}

export function * onDeleteEntradaMercanciaProducto ({idElemento, idProducto}) {
    try {
      if (idProducto !== undefined) {
        const comprobacion = yield call(comprobarEliminacionEntradaAlmacenProducto, idProducto, null)
        if (comprobacion.eliminar === true) {
          let productoAlmacenState = yield select(state => state.entradaMercancia.entradaMercanciaProductos)
          const productoRecuperar = productoAlmacenState.filter((producto) => {
            if (producto.idElemento !== idElemento){
              return producto
            }
          })
          yield put(editarEntradaMercanciaProductoSuccess(productoRecuperar))
          yield put(openMensajeAlerta('eliminacion'))
        }else{
          yield put(openMensajeAlerta('no-eliminacion'))
        }
      } else {
        let productoAlmacenState = yield select(state => state.entradaMercancia.entradaMercanciaProductos)
        const productoRecuperar = productoAlmacenState.filter((producto) => {
          if (producto.idElemento !== idElemento){
            return producto
          }
        })
        yield put(editarEntradaMercanciaProductoSuccess(productoRecuperar))
        yield put(openMensajeAlerta('eliminacion'))
      }
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchOnDeleteEntradaMercanciaProducto () {
  yield takeLatest(actionTypes.ON_DELETE_ENTRADA_MERCANCIA_PRODUCTO, onDeleteEntradaMercanciaProducto )
}

export function * guardarYnuevoEntradaMercanciaProducto ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    /*let date = new Date()
    let lote = 'REF' + date.getFullYear() + (date.getMonth()+1 < 10 ? '0' : '') + (date.getMonth()+1) + (date.getDate() < 10 ? '0' : '') + date.getDate() +
      (date.getHours() < 10 ? '0' : '') + date.getHours() + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() + (date.getSeconds() < 10 ? '0' : '') + date.getSeconds()*/
    yield put(initialize('ModalEntradaMercanciaAnadirProducto', {}))
    yield put(resetDatosEntradaMercanciaProducto())
    if (onSuccessCallback) yield onSuccessCallback()
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchGuardarYnuevoEntradaMercanciaProducto () {
  yield takeLatest(actionTypes.GUARDAR_Y_NUEVO_ENTRADA_MERCANCIA_PRODUCTO, guardarYnuevoEntradaMercanciaProducto )
}


export function * submitNuevoEntradaMercancia ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const list = yield call(addEntradaMercancia, values, auth.token)
    if (list.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-insercion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    yield put(closeModalEntradaMercancia())
    let filtros = yield select(state => state.entradaMercancia.filtros)
    const lista = yield call(filtrosEntradaMercancia, filtros, auth.token)
    yield put(filtrarEntradaMercanciaSuccess(lista))
    yield put(filtrosEntradaMercanciaSuccess(filtros))
    yield put(comboProveedoresEntradaMercancia())
    yield put(comboAlbaranesEntradaMercancia())
    yield put(comboOrigenesEntradaMercancia())

    const listNotificaciones = yield call(getNotificaciones, null)
    yield put(obtenerNotificaconesUsuarioSuccess(listNotificaciones))
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchSubmitNuevoEntradaMercancia () {
  yield takeLatest(actionTypes.SUBMIT_NUEVO_ENTRADA_MERCANCIA, submitNuevoEntradaMercancia )
}

export function * recuperarDatosModalEntradaMercancia ({idEntradaAlmacen}) {
  try {
    let auth = yield select(state => state.auth)
    const registroModificar = yield call(getEntradaMercancia, idEntradaAlmacen, null)
    if (Object.keys(registroModificar.entradaAlmacenProductos).length > 0) {
      registroModificar.entradaAlmacenProductos[0].nombreProducto = registroModificar.entradaAlmacenProductos[0].nombre
    }
    yield put(recuperarDatosModalEntradaMercanciaSuccess(registroModificar))
    yield put(initialize('ModalEntradaMercancia', registroModificar.entradaAlmacen))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchRecuperarDatosModalEntradaMercancia () {
  yield takeLatest(actionTypes.RECUPERAR_DATOS_MODAL_ENTRADA_MERCANCIA, recuperarDatosModalEntradaMercancia)
}

export function * editarEntradaMercancia ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const list = yield call(editEntradaMercancia, values, auth.token)
    if (list.actualizado === true) {
      yield put(openMensajeAlerta('modificacion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-modificacion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    yield put(closeModalEntradaMercancia())
    yield put(comboProveedoresEntradaMercancia())
    yield put(comboAlbaranesEntradaMercancia())
    yield put(comboOrigenesEntradaMercancia())
    let filtros = yield select(state => state.entradaMercancia.filtros)
    if (filtros.proveedor && values.proveedor){
      filtros.proveedor = {value: values.proveedor, label: values.proveedor}
    }
    if (filtros.nAlbaran && values.numeroAlbaran){
      filtros.nAlbaran = {value: values.numeroAlbaran, label: values.numeroAlbaran}
    }
    if (filtros.origen && values.origen){
      filtros.origen = {value: values.origen, label: values.origen}
    }
    const lista = yield call(filtrosEntradaMercancia, filtros, auth.token)
    yield put(filtrarEntradaMercanciaSuccess(lista))
    yield put(filtrosEntradaMercanciaSuccess(filtros))

    const listNotificaciones = yield call(getNotificaciones, null)
    yield put(obtenerNotificaconesUsuarioSuccess(listNotificaciones))
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchEditarEntradaMercancia () {
  yield takeLatest(actionTypes.EDITAR_ENTRADA_MERCANCIA, editarEntradaMercancia )
}

export function * duplicarDatosModalEntradaMercancia ({idEntradaAlmacen, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const registroDuplicar = yield call(getEntradaMercancia, idEntradaAlmacen, null)
    if (Object.keys(registroDuplicar.entradaAlmacenProductos).length > 0) {
      registroDuplicar.entradaAlmacenProductos[0].nombreProducto = registroDuplicar.entradaAlmacenProductos[0].nombre
    }
    yield put(duplicarDatosModalEntradaMercanciaSuccess(registroDuplicar))
    yield put(initialize('ModalEntradaMercancia', registroDuplicar.entradaAlmacen))
    if (onSuccessCallback) yield onSuccessCallback()
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchDuplicarDatosModalEntradaMercancia () {
  yield takeLatest(actionTypes.DUPLICAR_DATOS_MODAL_ENTRADA_MERCANCIA, duplicarDatosModalEntradaMercancia)
}

export function * guardarYnuevoEntradaMercancia ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const list = yield call(addEntradaMercancia, values, auth.token)
    if (list.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-insercion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    yield put(guardarYnuevoEntradaMercanciaSuccess())
    yield put(initialize('ModalEntradaMercancia', {}))

    let filtros = yield select(state => state.entradaMercancia.filtros)
    const lista = yield call(filtrosEntradaMercancia, filtros, auth.token)
    yield put(filtrarEntradaMercanciaSuccess(lista))
    yield put(filtrosEntradaMercanciaSuccess(filtros))
    yield put(comboProveedoresEntradaMercancia())
    yield put(comboAlbaranesEntradaMercancia())
    yield put(comboOrigenesEntradaMercancia())

    const listNotificaciones = yield call(getNotificaciones, null)
    yield put(obtenerNotificaconesUsuarioSuccess(listNotificaciones))
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchGuardarYnuevoEntradaMercancia () {
  yield takeLatest(actionTypes.GUARDAR_Y_NUEVO_ENTRADA_MERCANCIA, guardarYnuevoEntradaMercancia )
}

export function * onDeleteEntradaMercancia ({idEntradaAlmacen, proveedor, numeroAlbaran, origen}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const eliminar = yield call(deleteEntradaMercancia, idEntradaAlmacen, null)
      if (eliminar.eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
    yield put(comboProveedoresEntradaMercancia())
    yield put(comboAlbaranesEntradaMercancia())
    yield put(comboOrigenesEntradaMercancia())
    let filtros = yield select(state => state.entradaMercancia.filtros)
    if (filtros.proveedor && filtros.proveedor.value === proveedor){
      filtros.proveedor = null
    }
    if (filtros.nAlbaran && filtros.nAlbaran.value === numeroAlbaran){
      filtros.nAlbaran = null
    }
    if (filtros.origen && filtros.origen.value === origen){
      filtros.origen = null
    }
    const lista = yield call(filtrosEntradaMercancia, filtros, auth.token)
    yield put(filtrarEntradaMercanciaSuccess(lista))
    yield put(filtrosEntradaMercanciaSuccess(filtros))

    const listNotificaciones = yield call(getNotificaciones, null)
    yield put(obtenerNotificaconesUsuarioSuccess(listNotificaciones))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchOnDeleteEntradaMercancia () {
  yield takeLatest(actionTypes.ON_DELETE_ENTRADA_MERCANCIA, onDeleteEntradaMercancia)
}

export function * deleteSeleccionEntradaMercancia ({list}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      let eliminarSeleccion = yield call(eliminarEntradaMercanciaSeleccion, {list: list}, auth.token)
      if (eliminarSeleccion.eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(deSeleccionarTodosEntradaMercancia())
      yield put(initialize('simpleTable'))
      yield put(comboProveedoresEntradaMercancia())
      yield put(comboAlbaranesEntradaMercancia())
      yield put(comboOrigenesEntradaMercancia())
      let filtros = yield select(state => state.entradaMercancia.filtros)
      list.forEach((li) => {
        if (filtros.proveedor && filtros.proveedor.value === li.proveedor){
          filtros.proveedor = null
        }
        if (filtros.nAlbaran && filtros.nAlbaran.value === li.numeroAlbaran){
          filtros.nAlbaran = null
        }
        if (filtros.origen && filtros.origen.value === li.origen){
          filtros.origen = null
        }
      })
      const lista = yield call(filtrosEntradaMercancia, filtros, auth.token)
      yield put(filtrarEntradaMercanciaSuccess(lista))
      yield put(filtrosEntradaMercanciaSuccess(filtros))
      yield put(initialize('filtrosEntradaMercancia', filtros))

      const listNotificaciones = yield call(getNotificaciones, null)
      yield put(obtenerNotificaconesUsuarioSuccess(listNotificaciones))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchDeleteSeleccionEntradaMercancia () {
  yield takeLatest(actionTypes.DELETE_SELECCION_ENTRADA_MERCANCIA, deleteSeleccionEntradaMercancia)
}

export function * crearCsvEntradaMercancia ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosExcelEntradaMercancia = yield call(getCrearCsv, list, auth.token)
    yield put(crearCsvEntradaMercanciaSuccess(datosExcelEntradaMercancia))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchCrearCsvEntradaMercancia () {
  yield takeLatest(actionTypes.CREATE_CSV_ENTRADA_MERCANCIA, crearCsvEntradaMercancia)
}

export function * imprimirPdfEntradaMercancia ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfEntradaMercancia = yield call(getImprimirPdfEntradaMercancia, list, auth.token)
    yield put(imprimirPdfEntradaMercanciaSuccess(datosPdfEntradaMercancia))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirPdfEntradaMercancia () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_ENTRADA_MERCANCIA, imprimirPdfEntradaMercancia)
}

export function * deleteSeleccionEntradaMercanciaProductoAnadido ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const listado = list.filter((li) => {
      if (li.check === true){
        return li
      }
    })

    const listadoEliminar = listado.filter((li) => {
      if (li.idEntradaAlmacenProducto === undefined){
        return li
      }
    })

    const listadoComprobar = listado.filter((li) => {
      if (li.idEntradaAlmacenProducto !== undefined){
        return li
      }
    })

    let notificacionEliminacion = false

    const comprobacion = yield call(comprobarEliminacionEntradaAlmacenProductoSeleccion, {list: listadoComprobar}, auth.token)
    listado.forEach((li) => {
      comprobacion.eliminar.forEach((producto) => {
        if (li.idEntradaAlmacenProducto === producto.id) {
          if (producto.eliminar === true){
            listadoEliminar.push(li)
          } else {
            notificacionEliminacion = true
          }
        }
      })
    })

    const idsListadoEliminar = []
    listadoEliminar.forEach((li) => {
      idsListadoEliminar.push(li.idElemento)
    })

    let entradaMercanciaProductos = yield select(state => state.entradaMercancia.entradaMercanciaProductos)
    let listadoEliminarSeleccion = entradaMercanciaProductos.filter((producto) => {
        if (idsListadoEliminar.indexOf(producto.idElemento) === -1) {
          return producto
        }
    })
    if (notificacionEliminacion === false) {
      yield put(openMensajeAlerta('eliminacion'))
    } else {
      yield put(openMensajeAlerta('no-eliminacion'))
    }
    yield put(initialize('simpleTable'))
    yield put(initialize('filtrosEntradaMercanciaProducto'))
    yield put(deleteSeleccionEntradaMercanciaProductoAnadidoSuccess(listadoEliminarSeleccion))

  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchDeleteSeleccionEntradaMercanciaProductoAnadido () {
  yield takeLatest(actionTypes.DELETE_SELECCION_ENTRADA_MERCANCIA_PRODUCTO_ANADIDO, deleteSeleccionEntradaMercanciaProductoAnadido)
}

export function * fetchLoteExistente ({idProducto, lote}) {
  try {
    const datosLote = yield call(getDatosLote, idProducto, lote)
    if (datosLote && datosLote.lote) {
      yield put(change('ModalEntradaMercanciaAnadirProducto', 'fechaCaducidad', datosLote.lote.caducidad || ''))
    }
    yield put(fetchLoteExistenteSuccess(datosLote && datosLote.lote))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchFetchLoteExistente () {
  yield takeLatest(actionTypes.FETCH_LOTE_EXISTENTE, fetchLoteExistente)
}