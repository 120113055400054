import React, { Component } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import Button from '../comun/button/Button'
import ButtonLockable from '../comun/button/ButtonLockable'
import ButtonChangeRecord from '../comun/button/ButtonChangeRecord'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputText from '../comun/form/InputText'
import InputDatePicker from '../comun/form/InputDatePicker'
import InputTextArea from '../comun/form/InputTextArea'
import InputSelect from '../comun/form/InputSelect'
import InputNumerical from '../comun/form/InputNumerical'
import {tipoAnalisisExtraccion as tipoAnalisisExtraccionConstants} from '../../constants/analisis'
import {permisos as permisosConstant} from '../../constants/permisos'

class ModalFormSeguimiento extends Component {
  constructor () {
    super()
    this.state = {
      tipoAnalisisSeleccionado: null,
      archivoAnalisis: null,
      datosProcesar: null
    }
    this.handleChangeTipoAnalisis = this.handleChangeTipoAnalisis.bind(this)
    this.abrirMagavision = this.abrirMagavision.bind(this)
    this.obtenerYGuardarResultadoSistemaAnalisisSeguimiento = this.obtenerYGuardarResultadoSistemaAnalisisSeguimiento.bind(this)
    this.guardarSeguimiento = this.guardarSeguimiento.bind(this)
  }

  guardarSeguimiento (values) {
    return new Promise((resolve, reject) => {
      this.props.actions.guardarModalFormSeguimiento({
        ...values,
        idAnalisis: this.props.idAnalisis || this.props.data.idAnalisis || this.props.match.params.idAnalisisExtraccion || null,
        idPool: this.props.idPool || this.props.data.idPool || this.props.match.params.idPoolExtraccion || null
      }, resolve, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  handleChangeTipoAnalisis (tipoAnalisisSeleccionado) {
    this.setState({tipoAnalisisSeleccionado: tipoAnalisisSeleccionado ? tipoAnalisisSeleccionado.value : null})
  }

  abrirMagavision(tipoAnalisis) {
    this.props.actions.abrirMagavision(tipoAnalisis, true, this.props.isPool)
  }

  obtenerYGuardarResultadoSistemaAnalisisSeguimiento() {
    this.props.actions.obtenerYGuardarResultadoSistemaAnalisisSeguimiento(this.state.tipoAnalisisSeleccionado)
  }

  componentDidMount () {
    if (this.props.tipoAnalisisPredeterminado) {
      let tipoAnalisisPredeterminado = this.props.combos.comboTipoAnalisisExtraccion.find((tipoAnalisisExtraccionItem) => {
        return tipoAnalisisExtraccionItem.value === this.props.tipoAnalisisPredeterminado
      })
      tipoAnalisisPredeterminado = {value: tipoAnalisisPredeterminado.value, label: this.props.t(`COMUN.COMBOS.${tipoAnalisisPredeterminado.label}`)}
      this.setState({tipoAnalisisSeleccionado: tipoAnalisisPredeterminado.value})
      this.props.change('tipoAnalisis', tipoAnalisisPredeterminado)
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.tipoAnalisisPredeterminado !== prevProps.tipoAnalisisPredeterminado) {
      let tipoAnalisisPredeterminado = this.props.tipoAnalisisPredeterminado
      if (this.props.tipoAnalisisPredeterminado) {
        tipoAnalisisPredeterminado = this.props.combos.comboTipoAnalisisExtraccion.find((tipoAnalisisExtraccionItem) => {
          return tipoAnalisisExtraccionItem.value === this.props.tipoAnalisisPredeterminado
        })
        if (tipoAnalisisPredeterminado) {
          tipoAnalisisPredeterminado = {value: tipoAnalisisPredeterminado.value, label: this.props.t(`COMUN.COMBOS.${tipoAnalisisPredeterminado.label}`)}
          this.setState({tipoAnalisisSeleccionado: tipoAnalisisPredeterminado.value})
        }
      }
    }

    if (this.props.showModalFormSeguimiento !== prevProps.showModalFormSeguimiento && this.state.tipoAnalisisSeleccionado !== this.props.tipoAnalisisPredeterminado) {
      this.setState({tipoAnalisisSeleccionado: this.props.tipoAnalisisPredeterminado})
    }
  }

  render () {
    const {
      t, handleSubmit, submitting, showModalFormSeguimiento, data, mainList, idAnalisis,
      actions: {openModalFormSeguimiento, openModalContajeMaquinas, closeModalFormSeguimiento}
    } = this.props
    const tKey = 'SEGUIMIENTO.FORM.'

    let comboTipoAnalisisExtraccion = this.props.combos.comboTipoAnalisisExtraccion
    comboTipoAnalisisExtraccion = comboTipoAnalisisExtraccion.map((tipoAnalisisExtraccionItem) => {
      return {...tipoAnalisisExtraccionItem, label: t(`COMUN.COMBOS.${tipoAnalisisExtraccionItem.label}`)}
    })

    let comboConservacion = []
    this.props.combos.comboConservacion.forEach((comboConservacionItem) => {
      comboConservacion.push(Object.assign({}, comboConservacionItem))
    })
    comboConservacion[0].predeterminado = true

    let comboTipoControlSeguimiento = this.props.combos.comboTipoControlSeguimiento
    comboTipoControlSeguimiento = comboTipoControlSeguimiento.map((tipoControlSeguimientoItem) => {
      return {...tipoControlSeguimientoItem, label: t(`COMUN.COMBOS.${tipoControlSeguimientoItem.label}`)}
    })

    return (
      <Modal show={showModalFormSeguimiento} onHide={closeModalFormSeguimiento} bsSize="large" backdrop="static">
        <form onSubmit={handleSubmit(this.guardarSeguimiento)}>
          <Modal.Header closeButton={!submitting}>
            <Modal.Title>{t(tKey + ((data && data.duplicarSeguimiento) ? 'TITLE_DUPLICAR' : (data && data.idSeguimiento) ? 'TITLE_MODIFICAR' : 'TITLE_NUEVO'))}</Modal.Title>
            {!submitting && data && data.idSeguimiento && (
              <ButtonChangeRecord list={mainList} idElemento="idSeguimiento" currentId={data.idSeguimiento} getNextRecord={openModalFormSeguimiento} />
            )}
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Field
                colSm={3}
                id="codigo"
                name="codigo"
                controlLabel={t(tKey + 'FORM.CODIGO')}
                component={InputText}
                disabled={true}
              />
              <Field
                colSm={3}
                id="fecha"
                name="fecha"
                controlLabel={t(tKey + 'FORM.FECHA')}
                component={InputDatePicker}
              />
              <Field
                colSm={3}
                id="tipoAnalisis"
                name="tipoAnalisis"
                controlLabel={t(tKey + 'FORM.TIPO_ANALISIS')}
                component={InputSelect}
                options={comboTipoAnalisisExtraccion}
                valueKey="value"
                labelKey="label"
                onInputChange={(tipoAnalisisSeleccionado) => this.handleChangeTipoAnalisis(tipoAnalisisSeleccionado)}
                disabled={data && data.idSeguimiento}
              />
              <Col sm={3}>
                {
                  this.state.tipoAnalisisSeleccionado === tipoAnalisisExtraccionConstants.COLORIMETRO ? (
                    <Button
                      type="button"
                      onClick={() => ((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.SEGUIMIENTO] > 1) && (!data.idSeguimiento || data.duplicarSeguimiento) && !submitting) && openModalContajeMaquinas(tipoAnalisisExtraccionConstants.COLORIMETRO, true) }
                      className={"btn btn-primary btn-input-style" + (((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.SEGUIMIENTO] < 2) || (data.idSeguimiento && !data.duplicarSeguimiento) || submitting) ? ' disabled' : '')}
                    >{t(tKey + 'BUTTONS.CONTAR')}</Button>
                  ) : this.state.tipoAnalisisSeleccionado === tipoAnalisisExtraccionConstants.CAMARA_BURKER ? (
                    <Button
                      type="button"
                      onClick={() => ((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.SEGUIMIENTO] > 1) && (!data.idSeguimiento || data.duplicarSeguimiento) && !submitting) && openModalContajeMaquinas(tipoAnalisisExtraccionConstants.CAMARA_BURKER, true) }
                      className={"btn btn-primary btn-input-style" + (((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.SEGUIMIENTO] < 2) || (data.idSeguimiento && !data.duplicarSeguimiento) || submitting) ? ' disabled' : '')}
                    >{t(tKey + 'BUTTONS.CONCENTRACION_FA')}</Button>
                  ) : (
                    this.state.tipoAnalisisSeleccionado === tipoAnalisisExtraccionConstants.MAGAVISION_I ||
                    this.state.tipoAnalisisSeleccionado === tipoAnalisisExtraccionConstants.MAGAVISION_M ||
                    this.state.tipoAnalisisSeleccionado === tipoAnalisisExtraccionConstants.NUEVO_MAGAVISION
                  ) && (
                    <Button
                      type="button"
                      onClick={() => ((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.SEGUIMIENTO] > 1) && (!data.idSeguimiento || data.duplicarSeguimiento) && !submitting) && this.abrirMagavision(this.state.tipoAnalisisSeleccionado) }
                      className={"btn btn-primary btn-input-style" + (((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.SEGUIMIENTO] < 2) || (data.idSeguimiento && !data.duplicarSeguimiento) || submitting) ? ' disabled' : '')}
                    >{t(tKey + 'BUTTONS.ABRIR_MAGAVISION')}</Button>
                  )
                }
              </Col>
            </Row>
            <Row>
            {
              (
                this.state.tipoAnalisisSeleccionado === tipoAnalisisExtraccionConstants.COLORIMETRO ||
                this.state.tipoAnalisisSeleccionado === tipoAnalisisExtraccionConstants.CAMARA_BURKER ||
                this.state.tipoAnalisisSeleccionado === tipoAnalisisExtraccionConstants.MAGAVISION_M
              ) && (
                <Field
                  colSm={3}
                  id="porMotilidad"
                  name="porMotilidad"
                  controlLabel={t(tKey + 'FORM.MOTILIDAD_PERCENT')}
                  component={InputNumerical}
                />
              )
            }
            {
              (
                this.state.tipoAnalisisSeleccionado === tipoAnalisisExtraccionConstants.MAGAVISION_I ||
                this.state.tipoAnalisisSeleccionado === tipoAnalisisExtraccionConstants.NUEVO_MAGAVISION
              ) && [
                <Field
                  colSm={3}
                  id="porMotilidadTotal"
                  name="porMotilidadTotal"
                  key="porMotilidadTotal"
                  controlLabel={t(tKey + 'FORM.MOTILIDAD_TOTAL_PERCENT')}
                  component={InputNumerical}
                  disabled={true}
                />,
                <Field
                  colSm={3}
                  id="porMotilidadProgresiva"
                  name="porMotilidadProgresiva"
                  key="porMotilidadProgresiva"
                  controlLabel={t(tKey + 'FORM.MOTILIDAD_PROGRESIVA_PERCENT')}
                  component={InputNumerical}
                  disabled={true}
                />
              ]
            }
            {
              (
                this.state.tipoAnalisisSeleccionado === tipoAnalisisExtraccionConstants.MAGAVISION_M ||
                this.state.tipoAnalisisSeleccionado === tipoAnalisisExtraccionConstants.MAGAVISION_I
              ) && (
                <Col sm={3} smOffset={this.state.tipoAnalisisSeleccionado === tipoAnalisisExtraccionConstants.MAGAVISION_M ? 6 : 3} key="getOldMagavisionResultButton">
                  <Button
                    type="button"
                    onClick={() => ((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.SEGUIMIENTO] > 1) && (!data.idSeguimiento || data.duplicarSeguimiento) && !submitting) && this.obtenerYGuardarResultadoSistemaAnalisisSeguimiento() }
                    className={"btn btn-primary btn-input-style" + (((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.SEGUIMIENTO] < 2) || (data.idSeguimiento && !data.duplicarSeguimiento) || submitting) ? ' disabled' : '')}
                  >{t(tKey + 'BUTTONS.EVALUACION')}</Button>
                </Col>
              )
            }
            </Row>
            <Row>
              <Field
                colSm={3}
                id="concentracion"
                name="concentracion"
                controlLabel={t(tKey + 'FORM.CONCENTRACION')}
                component={InputNumerical}
                disabled={(this.state.tipoAnalisisSeleccionado && (
                  this.state.tipoAnalisisSeleccionado !== tipoAnalisisExtraccionConstants.COLORIMETRO && this.state.tipoAnalisisSeleccionado !== tipoAnalisisExtraccionConstants.CAMARA_BURKER
                ))}
              />
              <Field
                colSm={3}
                id="calidad"
                name="calidad"
                controlLabel={t(tKey + 'FORM.CALIDAD')}
                component={InputNumerical}
              />
              <Field
                colSm={3}
                id="aglutinacion"
                name="aglutinacion"
                controlLabel={t(tKey + 'FORM.AGLUTINACION')}
                component={InputNumerical}
              />
              <Field
                colSm={3}
                id="formasAnormales"
                name="formasAnormales"
                controlLabel={t(tKey + 'FORM.FORMAS_ANORMALES')}
                component={InputNumerical}
                disabled={(this.state.tipoAnalisisSeleccionado && (
                  this.state.tipoAnalisisSeleccionado !== tipoAnalisisExtraccionConstants.COLORIMETRO && this.state.tipoAnalisisSeleccionado !== tipoAnalisisExtraccionConstants.CAMARA_BURKER
                ))}
              />
            </Row>
            <Row>
              <Field
                colSm={3}
                id="contaminacion"
                name="contaminacion"
                component={InputCheckBox}
                claseActivo="onoffswitch-label"
                controlLabel={t(tKey + 'FORM.CONTAMINACION')}
              />
              {((data && data.idAnalisis) || idAnalisis || !!this.props.match.params.idAnalisisExtraccion) && (
                <Field
                  colSm={3}
                  id="conservacion"
                  name="conservacion"
                  controlLabel={t(tKey + 'FORM.CONSERVACION')}
                  component={InputSelect}
                  options={comboConservacion}
                  valueKey="value"
                  labelKey="label"
                  isClearable={false}
                />
              )}
              <Field
                colSm={3}
                id="tipoControl"
                name="tipoControl"
                controlLabel={t(tKey + 'FORM.TIPO_CONTROL')}
                component={InputSelect}
                options={comboTipoControlSeguimiento}
                valueKey="value"
                labelKey="label"
                isClearable={false}
              />
              <Field
                colSm={3}
                id="empresa"
                name="empresa"
                controlLabel={t(tKey + 'FORM.EMPRESA')}
                component={InputText}
                maxLength={true}
                valorMaxLength={100}
              />
            </Row>
            <Row>
              <Field
                colSm={12}
                id="observaciones"
                name="observaciones"
                controlLabel={t(tKey + 'FORM.OBSERVACIONES')}
                component={InputTextArea}
              />
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <ButtonLockable
              processing={submitting}
              hasIcon={true}
              type="submit"
              className="btn btn-primary"
              disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.SEGUIMIENTO] < 2) || submitting}
            >
              {t(tKey + 'BUTTONS.GUARDAR')}
            </ButtonLockable>
            <Button type="button" className="btn btn-white" disabled={submitting} onClick={!submitting ? closeModalFormSeguimiento : null}>{t(tKey + 'BUTTONS.CERRAR')}</Button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalFormSeguimiento'
})(ModalFormSeguimiento))