// Generar una expression regular a partir de un string escapando los caracteres especiales.
// Source: https://stackoverflow.com/questions/4029109/javascript-regex-how-to-put-a-variable-inside-a-regular-expression/14359586#14359586
export function regexEscape(str) {
  return str.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
}
export function regexGenerator(input, flags = 'g') {
  return new RegExp(regexEscape(input), flags);
}


// Dado un string formateado se parsea a número.
// Debido a la limitación de tamaño de los números (9999999999999999 === 10000000000000000),
// no se puede parsear numeros mayores de Number.MAX_SAFE_INTEGER.
// Se trataran como string para poder tratar numeros de tal magnitud.
export function number_parser (number, numDecimales = 2, separadorDec = ',', separadorMil = '.') {
  let auxNumber = ('' + number).trim();
  if (auxNumber === '') return '';
  let auxSeparadorDec = separadorDec

  // Eliminar separador de miles.
  let regexpSeparadorMil = regexGenerator(separadorMil, 'g')
  auxNumber = auxNumber.replace(regexpSeparadorMil, '');

  // Eliminar el exceso de separadores de decimales.
  let regexpSeparadorDec = new RegExp(`[.${auxSeparadorDec}]`, 'g');//regexGenerator(auxSeparadorDec, 'g')
  let firstOcurrence = auxNumber.indexOf(auxSeparadorDec)
  let firstPointOcurrence = auxNumber.indexOf('.')
  if (firstPointOcurrence !== -1 && firstPointOcurrence < firstOcurrence) {
    firstOcurrence = firstPointOcurrence
    auxSeparadorDec = '.'
  }
  if (firstOcurrence !== -1) {
    auxNumber = auxNumber.substring(0, firstOcurrence + 1) + auxNumber.substring(firstOcurrence).replace(regexpSeparadorDec, '').replace('.', '')
  }

  // Redondear y eliminar el exceso de decimales.
  let [integerPart, decimalPart] = auxNumber.split(auxSeparadorDec)
  auxNumber = integerPart || '0'
  if (numDecimales && (decimalPart && decimalPart.length || firstOcurrence !== -1)) {

    // Se añade un 1 al principio para evitar que al redondear la parte decimal y esta tenga ceros a la izquierda, se pierdan dichos digitos.
    let auxDecimal =  '1' + decimalPart.slice(0, numDecimales) + '.' + decimalPart.slice(numDecimales)
    auxDecimal = '' + Math.round(auxDecimal)

    if (auxDecimal.slice(0,1) !== '1') {
      auxNumber = parseInt(auxNumber, 10) + 1
    }

    auxNumber += '.' + auxDecimal.slice(1, numDecimales + 1)
  }
  return auxNumber;
}

// Dado un número se transforma a string formateado
export function number_formatter (number, numDecimales = 2, separadorDec = ',', separadorMil = '.') {
  let auxNumber = ('' + number).trim()
  if (auxNumber === '') return ''

  if (isNaN(auxNumber)) {
    auxNumber = number_parser(auxNumber, numDecimales, separadorDec, separadorMil)
    // console.log(`[number_formatter] No se ha recibido un número valido: ${number}. Intento de parseo: ${auxNumber}`)
  }

  // Separar las partes
  let symbol = (auxNumber === '-' || auxNumber < 0) ? '-' : ''
  auxNumber = ('' + auxNumber).replace(symbol, '')
  let [integerPart, decimalPart] = auxNumber.split('.')

  auxNumber = integerPart || '0'
  for (let i = integerPart.length - 1, j = 1; i > 0; i--, j++) {
    if (j % 3 === 0) {
      auxNumber = auxNumber.slice(0, i) + separadorMil + auxNumber.slice(i, auxNumber.length)
    }
  }

  auxNumber = symbol + auxNumber
  if (integerPart && numDecimales){
    auxNumber += separadorDec
    if (decimalPart && decimalPart.length > numDecimales) {
      decimalPart = decimalPart.slice(0, numDecimales)
    }
    else if (!decimalPart || decimalPart.length < numDecimales) {
      decimalPart = (decimalPart || '' ) + '0'.repeat(numDecimales - (decimalPart ? decimalPart.length : 0))
    }
    auxNumber += decimalPart
  }
  return auxNumber
}

// Redondear al 'número' mas cercano teniendo en cuenta la parte decimal.
export function round_decimal(number, numDecimales = 2, separadorDec = ',', separadorMil = '.') {
  let aux = ("" + number).replace('.', separadorDec)
  aux = number_parser(aux, numDecimales, separadorDec, separadorMil)
  aux = parseFloat(aux.replace(separadorDec, '.'))
  return aux
}

// Comprueba que el valor no exceda los límites definidos en las propiedades minValue y maxValue del parametro props.
export function getClosestIfOutsideOfRange (value, props) {
  let aux = value === '-' ? 0 : value
  if (props.minValue != null && aux < props.minValue) {
    return props.minValue
  } else if (props.maxValue != null && aux > props.maxValue) {
    return props.maxValue
  }
  return value
}

// Dada una fecha con un formato no estandar, la devuelve en formato estandar (year-month-day hh:mm:ss).
export function date_parser (dateFormatted, format = 'day/month/year/24') {
  if (!dateFormatted) {
    console.log('[date_parser]', 'El primer parametro es requerido y no puede estar vacio')
    return false
  }

  if (dateFormatted instanceof Date) {
    let year, month, day
    year = "" + dateFormatted.getFullYear()
    month = "" + (dateFormatted.getMonth() + 1) // Los meses empiezan desde 0. (Enero = 0, Febrero = 1, ...)
    day = "" + dateFormatted.getDate()

    if (year.length < 4) year = '0'.repeat(4 - year.length) + year
    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day
    return year + '-' + month + '-' + day
  }

  // Obtener el separador.
  let reservedWords = ['year', 'month', 'day'] // Este array también se usa como formato estandar
  let separador = format
  reservedWords.forEach((word) => {
    separador = separador.replace(word, '')
  })
  separador = separador[0]

  // Formatear la fecha.
  let splitedFormat = format.split(separador)
  let [datePart, timePart] = dateFormatted.split(' ')
  let splitedDateFormatted = datePart.split(separador)
  let splitedDateDefaultFormat = reservedWords.map((word) => {
    let index = splitedFormat.indexOf(word)
    return splitedDateFormatted[index]
  })
  let dateDefaultFormat = splitedDateDefaultFormat.join('-')
  if (timePart) dateDefaultFormat += ' ' + timePart
  return dateDefaultFormat
}

// Dada una fecha con un formato estandar (year-month-day hh:mm:ss), la devuelve en el formato del usuario.
export function date_formatter (date, format = 'day/month/year/24', showTime = true) {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }

  let year, month, day
  year = "" + date.getUTCFullYear()
  month = "" + (date.getUTCMonth() + 1) // Los meses empiezan desde 0. (Enero = 0, Febrero = 1, ...)
  day = "" + date.getUTCDate()

  if (year.length < 4) year = '0'.repeat(4 - year.length) + year
  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  let hour, minute, second
  if (showTime) {
    hour = date.getUTCHours()
    minute = date.getUTCMinutes()
    second = date.getUTCSeconds()

    if (hour <= 9) hour = '0' + hour
    if (minute <= 9) minute = '0' + minute
    if (second <= 9) second = '0' + second
  }

  let formatted = format
  if (format.indexOf('24') !== -1) {
    formatted = formatted.replace('/24', (showTime ? (' ' + hour + ':' + minute + ':' + second) : ''));
  } else if (format.indexOf('12') !== -1) {
    hour = parseInt(hour, 10);
    let period = hour > 12 ? ' pm' : ' am';
    hour = hour > 12 ? hour - 12 : hour;
    formatted = formatted.replace('/12', (showTime ? (' ' + Math.abs(hour) + ':' + minute + ':' + second + period) : ''));
  }

  formatted = formatted.replace('day', day);
  formatted = formatted.replace('month', month);
  formatted = formatted.replace('year', year);

  return formatted;
}

// Dada el formato de fechas definido por el usuario, generar el formato utilizado por la libreria Momentjs.
export function getMomentFormat(formaFechaHora) {
  return formaFechaHora.replace('/12', '').replace('/24', '').replace('day', 'DD').replace('month', 'MM').replace('year', 'YYYY')
}

// fecha-hora segun zona horaria
export function dateTimeZones (zona) {
  if (zona === null || zona === undefined){
    zona = 'Europe/Madrid';
  } 
  let date = new Date().toLocaleString('en-EU', {timeZone: zona});
  return new Date(date);
}

export function date_formatter_tabla (date, format = 'day/month/year/24', showTime = true) {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }

  let year, month, day
  year = "" + date.getFullYear()
  month = "" + (date.getMonth() + 1) // Los meses empiezan desde 0. (Enero = 0, Febrero = 1, ...)
  day = "" + date.getDate()

  if (year.length < 4) year = '0'.repeat(4 - year.length) + year
  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  let hour, minute, second
  if (showTime) {
    hour = date.getHours()
    minute = date.getMinutes()
    second = date.getSeconds()

    if (hour <= 9) hour = '0' + hour
    if (minute <= 9) minute = '0' + minute
    if (second <= 9) second = '0' + second
  }

  let formatted = format
  if (format.indexOf('24') !== -1) {
    formatted = formatted.replace('/24', (showTime ? (' ' + hour + ':' + minute + ':' + second) : ''));
  } else if (format.indexOf('12') !== -1) {
    hour = parseInt(hour, 10);
    let period = hour > 12 ? ' pm' : ' am';
    hour = hour > 12 ? hour - 12 : hour;
    formatted = formatted.replace('/12', (showTime ? (' ' + Math.abs(hour) + ':' + minute + ':' + second + period) : ''));
  }

  formatted = formatted.replace('day', day);
  formatted = formatted.replace('month', month);
  formatted = formatted.replace('year', year);

  return formatted;
}
