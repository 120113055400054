import {select, put, call, all, takeLatest, take, race} from 'redux-saga/effects'
import { delay } from 'redux-saga/effects'
import { initialize, reset, change, getFormValues } from 'redux-form';
import i18n from '../../i18n';
import {round_decimal, dateTimeZones} from '../../util/formatFunctions'
import {downloadDocument} from '../../util/util'

// Constants
import {translateEstadoVerraco} from '../../constants/estadoVerraco'
import {categorias as categoriasEscandallos} from '../../constants/escandallo'
import {estadoMaquinaDilucion, translateEstadoMaquinaDilucion} from '../../constants/maquinas'
import {analisisExtraccion as analisisExtraccionConstants, tipoAnalisisExtraccion as tipoAnalisisExtraccionConstants, translateTipoAnalisisExtraccion} from '../../constants/analisis'
import actionTypes from '../../constants/actions/analisisExtracciones/analisisExtracciones'
import {modulos as modulosConstant} from '../../constants/modulos'

// Actions
import {comboVerraco} from '../../actions/combos/combos'
import {openSimpleModal, openMensajeAlerta} from '../../actions/common'
import {
  fetchAnalisisExtraccionesSuccess, deseleccionarTodosAnalisisExtracciones, recuperarDatosFormAnalisisExtraccionSuccess,
  fetchCodigoAnalisisExtraccionesSuccess, fetchTokenMagavisionSuccess, fetchTokenVerracoMagavisionSuccess, updateTokenMagavisionSuccess,
  fetchDatosVerracoExtraccionesSuccess, prepararVerracoParaCambiarAEstadoActivoSuccess, changeTipoAnalisisExtraccionSuccess,
  abrirNuevoMagavisionSuccess, cerrarNuevoMagavisionSuccess, esperandoResultadoSistemaAnalisis, initializeFormAnalisisExtraccionSuccess, abrirModalDilucionAnalisisExtraccionSuccess,
  guardarModalDilucionAnalisisExtraccionSuccess, cerrarModalDilucionAnalisisExtraccionSuccess, actualizarEstadoMaquinaDilucionAnalisisExtraccion, imprimirEtiquetaPDFSuccess
} from '../../actions/analisisExtracciones/analisisExtracciones'
import { comprobarStockTrazabilidad, comprobarStockTrazabilidadSuccess, openModalTrazabilidadMaterial
} from '../../actions/trazabilidadMaterial/trazabilidadMaterial'

import {
  updateTokenMagavisionPoolSuccess
} from '../../actions/poolExtracciones/poolExtracciones'

// Services
import obtenerDatosVerracoService from '../../services/analisisExtracciones/obtenerDatosVerraco'
import getAnalisisExtraccionesListService from '../../services/analisisExtracciones/getAnalisisExtraccionesList'
import getFiltrarAnalisisExtraccionesListService from '../../services/analisisExtracciones/getFiltrarAnalisisExtraccionesList'
import putCambiarEyaculadoEliminadoAnalisisExtraccionesService from '../../services/analisisExtracciones/putCambiarEyaculadoEliminadoAnalisisExtracciones'
import putCambiarVigiladoAnalisisExtraccionesService from '../../services/analisisExtracciones/putCambiarVigiladoAnalisisExtracciones'
import deleteAnalisisExtraccionService from '../../services/analisisExtracciones/deleteAnalisisExtraccion'
import cambiarEyaculadoEliminadoSeleccionAnalisisExtraccionesService from '../../services/analisisExtracciones/cambiarEyaculadoEliminadoSeleccionAnalisisExtracciones'
import cambiarVigiladoSeleccionAnalisisExtraccionesService from '../../services/analisisExtracciones/cambiarVigiladoSeleccionAnalisisExtracciones'
import deleteSeleccionAnalisisExtraccionService from '../../services/analisisExtracciones/deleteSeleccionAnalisisExtraccion'
import getCrearCsvAnalisisExtraccionesService from '../../services/analisisExtracciones/getCrearCsvAnalisisExtracciones'
import getImprimirPdfAnalisisExtraccionesService from '../../services/analisisExtracciones/getImprimirPdfAnalisisExtracciones'
import getAnalisisExtraccionService from '../../services/analisisExtracciones/getAnalisisExtraccion'
import getCodigoAnalisisExtraccionesService from '../../services/analisisExtracciones/getCodigoAnalisisExtracciones'
import getTokenMagavisionService from '../../services/analisisExtracciones/getTokenMagavision'
import submitNuevoAnalisisExtraccionService from '../../services/analisisExtracciones/submitNuevoAnalisisExtraccion'
import submitEditarAnalisisExtraccionService from '../../services/analisisExtracciones/submitEditarAnalisisExtraccion'
import comprobarPrimeraDilucionDelDiaService from '../../services/dilucion/comprobarPrimeraDilucionDelDia'
import prepararMaquinaDilucionAnalisisExtraccionService from '../../services/analisisExtracciones/prepararMaquinaDilucionAnalisisExtraccion'
import comprobarEstadoMaquinaDilucionAnalisisExtraccionService from '../../services/analisisExtracciones/comprobarEstadoMaquinaDilucionAnalisisExtraccion'
import abrirOldMagavisionService from '../../services/analisisExtracciones/abrirOldMagavision'
import iniciarServidorLecturaOldMagavisionService from '../../services/analisisExtracciones/iniciarServidorLecturaOldMagavision'
import comprobarServidorLecturaOldMagavisionService from '../../services/analisisExtracciones/comprobarServidorLecturaOldMagavision'
import leerDatosServidorLecturaOldMagavisionService from '../../services/analisisExtracciones/leerDatosServidorLecturaOldMagavision'
import leerDatosServidorLecturaNewMagavisionService from '../../services/analisisExtracciones/leerDatosServidorLecturaNewMagavision'
import logoutNewMagavisionService from '../../services/analisisExtracciones/logoutNewMagavision'
import getPDFEtiqueta from '../../services/analisisExtracciones/getPDFEtiqueta'
import getImprimirEtiquetaPDF from '../../services/analisisExtracciones/getImprimirEtiquetaPDF'
import getLotesEscandalloService from '../../services/trazabilidadMaterial/getLotesEscandallo'

// Sagas
import {yesNoModal as yesNoModalSaga} from '../modal/yesNoModal'
import {fetchPanelControl as fetchPanelControlSaga} from '../panelControl/panelControl'
import {
  comboVerraco as comboVerracoSaga, comboOperarioPredeterminado as comboOperarioPredeterminadoSaga, fetchComboRectaCalibrado as fetchComboRectaCalibradoSaga,
  comboTipoDosis as comboTipoDosisSaga, fetchComboTipoDiluyente as fetchComboTipoDiluyenteSaga
} from '../combos/combos'

import { obtenerNotificaconesUsuarioSuccess } from '../../actions/notificaciones/notificaciones'
import getNotificaciones from '../../services/notificaciones/getNotificaciones'

export function * fetchAnalisisExtracciones ({filtros}) {
  try {
    let auth = yield select(state => state.auth)
    let analisisExtraccionesList = []
    if (filtros && Object.keys(filtros).filter((filtro) => {return filtro !== 'seleccionar-todo'}).length > 0) {
      analisisExtraccionesList = yield call(getFiltrarAnalisisExtraccionesListService, filtros, auth.token)
    } else {
      analisisExtraccionesList = yield call(getAnalisisExtraccionesListService)
    }

    analisisExtraccionesList.analisis.map((analisisExtraccion) => {
      analisisExtraccion.porFaTotal = analisisExtraccion.faTotalPercent
      analisisExtraccion.tipoAnalisis = (analisisExtraccion.idTipoAnalisis ? i18n.t('COMUN.COMBOS.' + translateTipoAnalisisExtraccion[analisisExtraccion.idTipoAnalisis]) : '')
      return analisisExtraccion
    })
    yield put(fetchAnalisisExtraccionesSuccess(analisisExtraccionesList.analisis, analisisExtraccionesList.totales, filtros))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchFetchAnalisisExtracciones () {
  yield takeLatest(actionTypes.FETCH_ANALISIS_EXTRACCIONES, fetchAnalisisExtracciones)
}

export function * cambiarEyaculadoEliminadoAnalisisExtracciones ({idAnalisisExtraccion}) {
  try {
    const confirmed = yield call(yesNoModalSaga, {modalType: 'cambiarEyaculadoEliminado'})
    if (confirmed) {
      let filtros = yield select(state => state.analisisExtracciones.filtros)
      const {actualizado} = yield call(putCambiarEyaculadoEliminadoAnalisisExtraccionesService, idAnalisisExtraccion)
      yield call(fetchAnalisisExtracciones, {filtros})
      if (actualizado === true) {
        yield put(openMensajeAlerta('cambio-eyaculado-eliminado'))
      } else {
        yield put(openMensajeAlerta('no-cambio-eyaculado-eliminado'))
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchCambiarEyaculadoEliminadoAnalisisExtracciones () {
  yield takeLatest(actionTypes.CAMBIAR_EYACULADO_ELIMINADO_ANALISIS_EXTRACCIONES, cambiarEyaculadoEliminadoAnalisisExtracciones)
}

export function * cambiarVigiladoAnalisisExtracciones ({idVerraco}) {
  try {
    const confirmed = yield call(yesNoModalSaga, {modalType: 'cambiarVigilado'})
    if (confirmed) {
      let filtros = yield select(state => state.analisisExtracciones.filtros)
      const {actualizado} = yield call(putCambiarVigiladoAnalisisExtraccionesService, idVerraco)
      yield call(fetchAnalisisExtracciones, {filtros})
      if (actualizado === true) {
        yield put(openMensajeAlerta('cambio-vigilado'))
      } else {
        yield put(openMensajeAlerta('no-cambio-vigilado'))
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchCambiarVigiladoAnalisisExtracciones () {
  yield takeLatest(actionTypes.CAMBIAR_VIGILADO_ANALISIS_EXTRACCIONES, cambiarVigiladoAnalisisExtracciones)
}

export function * onDeleteAnalisisExtraccion ({idAnalisisExtraccion}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModalSaga, {modalType: 'onDelete'})
    if (confirmed) {
      let filtros = yield select(state => state.analisisExtracciones.filtros)
      const {eliminado} = yield call(deleteAnalisisExtraccionService, idAnalisisExtraccion, null)
      yield call(fetchAnalisisExtracciones, {filtros})
      if (eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
    }
    const listNotificaciones = yield call(getNotificaciones, null)
    yield put(obtenerNotificaconesUsuarioSuccess(listNotificaciones))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchOnDeleteAnalisisExtraccion () {
  yield takeLatest(actionTypes.ON_DELETE_ANALISIS_EXTRACCION, onDeleteAnalisisExtraccion)
}

export function * cambiarEyaculadoEliminadoSeleccionAnalisisExtracciones ({list}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModalSaga, {modalType: 'cambiarEyaculadoEliminado'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      let filtros = yield select(state => state.analisisExtracciones.filtros)
      const {actualizado} = yield call(cambiarEyaculadoEliminadoSeleccionAnalisisExtraccionesService, list, auth.token)
      if (actualizado === true) {
        yield put(openMensajeAlerta('cambio-eyaculado-eliminado'))
      } else {
        yield put(openMensajeAlerta('no-cambio-eyaculado-eliminado'))
      }
      yield put(deseleccionarTodosAnalisisExtracciones())
      yield put(initialize('FiltrosAnalisisExtracciones'))
      const inicializaTabla = yield call(fetchAnalisisExtracciones, {filtros})
      yield put(initialize('simpleTable'))
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchCambiarEyaculadoEliminadoSeleccionAnalisisExtracciones () {
  yield takeLatest(actionTypes.CAMBIAR_EYACULADO_ELIMINADO_SELECCION_ANALISIS_EXTRACCIONES, cambiarEyaculadoEliminadoSeleccionAnalisisExtracciones)
}

export function * cambiarVigiladoSeleccionAnalisisExtracciones ({list}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModalSaga, {modalType: 'cambiarVigilado'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      let filtros = yield select(state => state.analisisExtracciones.filtros)
      const {actualizado} = yield call(cambiarVigiladoSeleccionAnalisisExtraccionesService, list, auth.token)
      if (actualizado === true) {
        yield put(openMensajeAlerta('cambio-vigilado'))
      } else {
        yield put(openMensajeAlerta('no-cambio-vigilado'))
      }
      yield put(deseleccionarTodosAnalisisExtracciones())
      yield put(initialize('FiltrosAnalisisExtracciones'))
      const inicializaTabla = yield call(fetchAnalisisExtracciones, {filtros})
      yield put(initialize('simpleTable'))
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchCambiarVigiladoSeleccionAnalisisExtracciones () {
  yield takeLatest(actionTypes.CAMBIAR_VIGILADO_ELIMINADO_SELECCION_ANALISIS_EXTRACCIONES, cambiarVigiladoSeleccionAnalisisExtracciones)
}

export function * deleteSeleccionAnalisisExtracciones ({list}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModalSaga, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      let filtros = yield select(state => state.analisisExtracciones.filtros)
      const {eliminado} = yield call(deleteSeleccionAnalisisExtraccionService, list, auth.token)
      if (eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(deseleccionarTodosAnalisisExtracciones())
      yield put(initialize('FiltrosAnalisisExtracciones'))
      const inicializaTabla = yield call(fetchAnalisisExtracciones, {filtros})
      yield put(initialize('simpleTable'))

      const listNotificaciones = yield call(getNotificaciones, null)
      yield put(obtenerNotificaconesUsuarioSuccess(listNotificaciones))
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchDeleteSeleccionAnalisisExtracciones () {
  yield takeLatest(actionTypes.DELETE_SELECCION_ANALISIS_EXTRACCIONES, deleteSeleccionAnalisisExtracciones)
}

export function * crearCsvAnalisisExtracciones ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosExcelAnalisisExtracciones = yield call(getCrearCsvAnalisisExtraccionesService, list, auth.token)
    let href = 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(datosExcelAnalisisExtracciones.excel)
    let name = i18n.t('MENU.EXTRACCIONES.EXTRACCIONES') + ".xlsx"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchCrearCsvAnalisisExtracciones () {
  yield takeLatest(actionTypes.CREATE_CSV_ANALISIS_EXTRACCIONES, crearCsvAnalisisExtracciones)
}

export function * imprimirPdfAnalisisExtracciones ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfAnalisisExtracciones = yield call(getImprimirPdfAnalisisExtraccionesService, list, auth.token)
    let href = 'data:application/pdf;base64,' + encodeURIComponent(datosPdfAnalisisExtracciones.pdf)
    let name = i18n.t('MENU.EXTRACCIONES.EXTRACCIONES') + ".pdf"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchImprimirPdfAnalisisExtracciones () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_ANALISIS_EXTRACCIONES, imprimirPdfAnalisisExtracciones)
}

export function * submitFormAnalisisExtraccion ({values, idAnalisisExtraccion, history, onSuccessCallback, onErrorCallback}) {
  try {
    let state = yield select(state => state)
    let auth = yield select(state => state.auth)

    let preparedValues = {
      codigoAnalisis: values.codigoAnalisis,
      fechaExtraccion: values.fechaExtraccion,
      idVerraco: values.verraco ? values.verraco.idVerraco : values.idVerraco ? values.idVerraco.value : null,
      idOperarioColecta: values.operarioColecta ? values.operarioColecta.value : null,
      idOperarioProcesado: values.operarioProcesado ? values.operarioProcesado.value : null,
      horaExtraccion: values.horaExtraccion,
      horaEntradaLaboratorio: values.horaEntradaLaboratorio,
      horaInicioProcesado: values.horaInicioProcesado,
      horaFinProcesado: values.horaFinProcesado,
      volumen: values.volumen,
      motilidadTotal: values.motilidadTotal,
      calidad: values.calidad ? values.calidad : null,
      aglutinacion: values.aglutinacion,
      temperaturaEyaculado: values.temperaturaEyaculado,
      idTipoAnalisis: values.tipoAnalisis ? values.tipoAnalisis.value : null,
      idRecta: values.rectaCalibrado ? values.rectaCalibrado.value : null,
      lectura: values.lectura,
      motilidadMasal: values.motilidadMasal,
      mt: values.mt,
      mp: values.mp,
      buenos: values.camposCalculados ? values.camposCalculados.buenos : null,
      totalAcrosomas: values.camposCalculados ? values.camposCalculados.totalAcrosomas : null,
      faCabezas: values.camposCalculados ? values.camposCalculados.faCabezas : null,
      faColas: values.camposCalculados ? values.camposCalculados.faColas : null,
      faGotaProximal: values.camposCalculados ? values.camposCalculados.faGotaProximal : null,
      faGotaDistal: values.camposCalculados ? values.camposCalculados.faGotaDistal : null,
      faTotal: values.camposCalculados ? values.camposCalculados.faTotal : null,
      faTotalPercent: values.camposCalculados ? values.camposCalculados.faTotalPercent : null,
      contados: values.contados ? values.contados : null,
      concentracionTotal: values.concentracionTotal,
      concentracionUtil: values.concentracionUtil,
      concentracionXDosisAplicada: values.concentracionXDosisAplicada,
      concentracionXDosisUtil: values.concentracionXDosisUtil,
      idTipoDosis: values.tipoDosis ? values.tipoDosis.value : null,
      idTipoDiluyente: values.tipoDiluyente ? values.tipoDiluyente.value : null,
      volumenDiluyente: values.volumenDiluyente,
      eyaculadoEliminado: values.eyaculadoEliminado,
      dosisTeoricas: values.dosisTeoricas,
      dosisTeoricasPredeterminadas: values.dosisTeoricasPredeterminadas,
      dosisReales: values.dosisReales,
      dosisRealesPredeterminadas: values.dosisRealesPredeterminadas,
      mediaUltimaDosis: values.mediaUltimaDosis,
      observaciones: values.observaciones,
      dilucion: values.dilucion || null,
      trazabilidad: values.trazabilidad || null,
      observacionesEyaculadoEliminado: values.observacionesEyaculadoEliminado ? values.observacionesEyaculadoEliminado.value : null,
      t_dtv_final: state.analisisExtracciones.extraccion.t_dtv_final
    }

    if (!preparedValues.horaFinProcesado) {
      let currentTime = new Date()
      let hours, minutes
      hours = currentTime.getHours()
      minutes = currentTime.getMinutes()
      if (hours < 10) hours = '0' + hours
      if (minutes < 10) minutes = '0' + minutes
      currentTime = hours + ':' + minutes
      preparedValues.horaFinProcesado = currentTime
    }

    // En caso de colorimetro no hay un campo contados, por lo que se completara sumando los valores introducidos por el usuario.
    if (preparedValues.idTipoAnalisis === tipoAnalisisExtraccionConstants.COLORIMETRO) {
      preparedValues.contados = preparedValues.buenos + preparedValues.faCabezas + preparedValues.faColas + preparedValues.faGotaProximal + preparedValues.faGotaDistal
    }

    // Añadir el parámetro que permitirá actualizar el estado activo si el usuario acepto el popup correspondiente.
    if (state.analisisExtracciones.extraccion.cambioEstado) preparedValues.cambioEstado = state.analisisExtracciones.extraccion.cambioEstado

    // Si se recibe el idAnalisisExtraccion se hara una actualización de dicho análisis, sino se creara uno nuevo.
    let estadoPeticion
    if (idAnalisisExtraccion) {
      preparedValues.seguimiento = values.seguimiento
      preparedValues.idAnalisis = idAnalisisExtraccion
      estadoPeticion = yield call(submitEditarAnalisisExtraccionService, preparedValues, state.auth.token)
    } else {
      estadoPeticion = yield call(submitNuevoAnalisisExtraccionService, preparedValues, state.auth.token)
    }
    estadoPeticion = estadoPeticion && (estadoPeticion.insertado || estadoPeticion.actualizado)

    if (estadoPeticion === true) {
      yield put(openMensajeAlerta(idAnalisisExtraccion ? 'modificacion' : 'insercion'))
      if (history) {
        yield history.push({pathname: '/AnalisisExtracciones', state: { from: history.location.pathname }})
      } else {
        yield put(reset('formAnalisisExtraccion'))

        let tipoAnalisisPredeterminado = state.combos.comboTipoAnalisisExtraccion.find((tipoAnalisisExtraccionItem) => {
          return tipoAnalisisExtraccionItem.value === state.panelControl.tipoAnalisisPredeterminado
        })
        tipoAnalisisPredeterminado = {value: tipoAnalisisPredeterminado.value, label: i18n.t(`COMUN.COMBOS.${tipoAnalisisPredeterminado.label}`)}
        yield put(change('formAnalisisExtraccion', 'tipoAnalisis', tipoAnalisisPredeterminado))

        let operarioProcesado = state.combos.comboOperarioActivo.find((procesado) => {
          if (procesado.procesado === true) {
            return procesado
          }
        })
        yield put(change('formAnalisisExtraccion', 'operarioProcesado', operarioProcesado))

        yield call(initializeFormAnalisisExtraccion)
        yield call(fetchCodigoAnalisisExtracciones)
      }
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta(idAnalisisExtraccion ? 'no-modificacion' : 'no-insercion'))
      if (onErrorCallback) yield onErrorCallback()
    }

    const listNotificaciones = yield call(getNotificaciones, null)
    yield put(obtenerNotificaconesUsuarioSuccess(listNotificaciones))
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
  }
}

export function * watchSubmitFormAnalisisExtraccion () {
  yield takeLatest(actionTypes.SUBMIT_FORM_ANALISIS_EXTRACCION, submitFormAnalisisExtraccion)
}

export function * recuperarDatosFormAnalisisExtraccion ({idAnalisisExtraccion}) {
  try {
    yield call(initializeFormAnalisisExtraccion)

    let state = yield select(state => state)
    let registroModificar = yield call(getAnalisisExtraccionService, idAnalisisExtraccion)

    registroModificar.codigoAnalisis = registroModificar.codigo
    registroModificar.verraco = {orden: registroModificar.orden}
    registroModificar.totalAcrosomas = parseInt(registroModificar.totalAcrosomas || 0 , 10)
    registroModificar.buenos = parseInt(registroModificar.buenos || 0 , 10)
    registroModificar.faCabezas = parseInt(registroModificar.faCabezas || 0, 10)
    registroModificar.faColas = parseInt(registroModificar.faColas || 0, 10)
    registroModificar.faGotaProximal = parseInt(registroModificar.faGotaProximal || 0, 10)
    registroModificar.faGotaDistal = parseInt(registroModificar.faGotaDistal || 0, 10)
    registroModificar.faTotal = parseFloat(registroModificar.faTotal || 0)
    registroModificar.faTotalPercent = parseFloat(registroModificar.faTotalPercent || 0)
    registroModificar.espermatozoidesContados = registroModificar.contados

    registroModificar.camposCalculados = {
      buenos: registroModificar.buenos,
      totalAcrosomas: registroModificar.totalAcrosomas,
      percentAcrosomas: registroModificar.totalAcrosomas !== 0 ? (100 / state.panelControl.espermatozoidesContar * registroModificar.totalAcrosomas) : 0,
      faCabezas: registroModificar.faCabezas,
      faCabezasPercent: registroModificar.faCabezas !== 0 ? ((registroModificar.faCabezas * 100 / registroModificar.faTotal) * registroModificar.faTotalPercent / 100) : 0,
      faColas: registroModificar.faColas,
      faColasPercent: registroModificar.faColas !== 0 ? ((registroModificar.faColas * 100 / registroModificar.faTotal) * registroModificar.faTotalPercent / 100) : 0,
      faGotaProximal: registroModificar.faGotaProximal,
      faGotaProximalPercent: registroModificar.faGotaProximal !== 0 ? ((registroModificar.faGotaProximal * 100 / registroModificar.faTotal) * registroModificar.faTotalPercent / 100) : 0,
      faGotaDistal: registroModificar.faGotaDistal,
      faGotaDistalPercent: registroModificar.faGotaDistal !== 0 ? ((registroModificar.faGotaDistal * 100 / registroModificar.faTotal) * registroModificar.faTotalPercent / 100) : 0,
      faTotal: registroModificar.faTotal,
      faTotalPercent: registroModificar.faTotalPercent
    }

    registroModificar.volumen = registroModificar.volumen && parseFloat(registroModificar.volumen)
    registroModificar.concentracionTotal = registroModificar.concentracionTotal && parseFloat(registroModificar.concentracionTotal)
    registroModificar.concentracionUtil = registroModificar.concentracionUtil && parseFloat(registroModificar.concentracionUtil)
    registroModificar.concentracionXDosisAplicada = registroModificar.concentracionxdosisaplicada && parseFloat(registroModificar.concentracionxdosisaplicada)
    registroModificar.concentracionXDosisUtil = registroModificar.concentracionxdosisutil && parseFloat(registroModificar.concentracionxdosisutil)
    registroModificar.dosisTeoricas = registroModificar.dosisTeoricas && parseInt(registroModificar.dosisTeoricas, 10)
    registroModificar.dosisReales = registroModificar.dosisReales && parseInt(registroModificar.dosisReales, 10)
    registroModificar.volumenDiluyente = registroModificar.volumenDiluyente && parseFloat(registroModificar.volumenDiluyente)
    registroModificar.dilucion = registroModificar.dilucion ? registroModificar.dilucion : {}

    // Asignar la hora de inicio procesado automáticamente a los analisis creados desde foso.
    if (registroModificar.creadoDesdeFoso) {
      let currentTime = new Date()
      let hours, minutes
      hours = currentTime.getHours()
      minutes = currentTime.getMinutes()
      if (hours < 10) hours = '0' + hours
      if (minutes < 10) minutes = '0' + minutes
      registroModificar.horaInicioProcesado = hours + ':' + minutes
    }

    // Obtener informacion extra de los combos.
    state = yield select(state => state)
    if (registroModificar.idTipoAnalisis) {
      registroModificar.tipoAnalisis = state.combos.comboTipoAnalisisExtraccion.find((element) => {
        return element.value === registroModificar.idTipoAnalisis
      })
      registroModificar.tipoAnalisis = {
        value: registroModificar.tipoAnalisis.value,
        label: i18n.t(`COMUN.COMBOS.${registroModificar.tipoAnalisis.label}`)
      }
    }
    if (registroModificar.idOperarioColecta) {
      registroModificar.operarioColecta = state.combos.comboOperarioPredeterminado.find((element) => {
        return element.value === registroModificar.idOperarioColecta
      })
    }
    if (registroModificar.idOperarioProcesado) {
      registroModificar.operarioProcesado = state.combos.comboOperarioActivo.find((element) => {
        return element.value === registroModificar.idOperarioProcesado
      })
    }
    if (registroModificar.idRecta) {
      registroModificar.rectaCalibrado = state.combos.comboRectaCalibrado.find((element) => {
        return element.value === registroModificar.idRecta
      })
    }
    if (registroModificar.idTipoDosis) {
      registroModificar.tipoDosis = state.combos.comboTipoDosis.find((element) => {
        return element.value === registroModificar.idTipoDosis
      })
    } else if (registroModificar.creadoDesdeFoso) {
      registroModificar.tipoDosis = state.combos.comboTipoDosis.find((element) => {
        return element.predeterminado
      })
    }
    if (registroModificar.idTipoDiluyente) {
      registroModificar.tipoDiluyente = state.combos.comboTipoDiluyente.find((element) => {
        return element.value === registroModificar.idTipoDiluyente
      })
    }
    if (registroModificar.observacionesEyaculadoEliminado) {
      registroModificar.observacionesEyaculadoEliminado = state.combos.comboObservacionesEyaculadoEliminado.find((element) => {
        return element.value === registroModificar.observacionesEyaculadoEliminado
      })
      registroModificar.observacionesEyaculadoEliminado = {
        value: registroModificar.observacionesEyaculadoEliminado.value,
        label: i18n.t(`COMUN.COMBOS.${registroModificar.observacionesEyaculadoEliminado.label}`)
      }
    }

    // Obtener los datos de verraco una vez recuperado los datos de fecha analisis y  analisisExtraccion.
    yield* comboVerracoSaga({values: {analisisExtraccion: registroModificar.analisisExtraccion || state.combos.comboAnalisisExtraccion[0].value, fechaExtraccion: registroModificar.fechaExtraccion}})
    state = yield select(state => state)
    if (registroModificar.idVerraco) {
      registroModificar.idVerraco = state.combos.comboVerraco.find((element) => {
        return element.value === registroModificar.idVerraco
      })
    }

    if (registroModificar.idTipoAnalisis !== tipoAnalisisExtraccionConstants.COLORIMETRO) {
      registroModificar.contados = (registroModificar.espermatozoidesContados || 0)
    }

    yield put(initialize('formAnalisisExtraccion', registroModificar))
    yield delay(300) // Requerido para evitar que se pierdan las dosis reales debido a que el tipo de dosis la machaca debido a los recalculos
    yield put(recuperarDatosFormAnalisisExtraccionSuccess(registroModificar))

    if (state.auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD)) {
      if (registroModificar.creadoDesdeFoso) {
        yield put(comprobarStockTrazabilidad(categoriasEscandallos.ANALISIS, registroModificar.tipoDosis.value))
        yield put(openModalTrazabilidadMaterial({
          idTipoDosis: registroModificar.tipoDosis.value,
          idCategoria: categoriasEscandallos.ANALISIS,
          idAnalisis: idAnalisisExtraccion,
          disabled: registroModificar.diluido,
          showStockColumns: !(registroModificar.trazabilidad),
          creadoDesdeFoso: true,
          openModal: false
        }))
      } else {
        yield put(comprobarStockTrazabilidadSuccess({[categoriasEscandallos.ANALISIS]: registroModificar.stockInsuficiente}))
      }
    }

    if (registroModificar.idVerraco) {
      yield* fetchDatosVerracoExtracciones({idVerraco: registroModificar.idVerraco.value, existsAnalisis: (registroModificar.creadoDesdeFoso ? false : true)})
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchRecuperarDatosFormAnalisisExtraccion () {
  yield takeLatest(actionTypes.RECUPERAR_DATOS_FORM_ANALISIS_EXTRACCION, recuperarDatosFormAnalisisExtraccion)
}

export function * initializeFormAnalisisExtraccion () {
  yield all([
    fetchPanelControlSaga(),
    comboOperarioPredeterminadoSaga(),
    fetchComboRectaCalibradoSaga(),
    comboTipoDosisSaga(),
    fetchComboTipoDiluyenteSaga()
  ])
  yield put(initializeFormAnalisisExtraccionSuccess())
  yield call(fetchTokenMagavision)
}

export function * watchInitializeFormAnalisisExtraccion () {
  yield takeLatest(actionTypes.INITIALIZE_FORM_ANALISIS_EXTRACCION, initializeFormAnalisisExtraccion)
}

export function * fetchCodigoAnalisisExtracciones () {
  try {
    let {codigoAnalisis} = yield call(getCodigoAnalisisExtraccionesService)
    yield put(change('formAnalisisExtraccion', 'codigoAnalisis', codigoAnalisis))
    yield put(fetchCodigoAnalisisExtraccionesSuccess(codigoAnalisis))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchFetchCodigoAnalisisExtracciones () {
  yield takeLatest(actionTypes.FETCH_CODIGO_ANALISIS_EXTRACCIONES, fetchCodigoAnalisisExtracciones)
}

export function * fetchTokenMagavision () {
  try {
    const state = yield select(state => state)
    let {token, t_anl} = yield call(getTokenMagavisionService)
    let t_dtv = (state.analisisExtracciones.extraccion && state.analisisExtracciones.extraccion.verraco && state.analisisExtracciones.extraccion.verraco.codVerraco) ? state.analisisExtracciones.extraccion.verraco.codVerraco : null
    yield put(fetchTokenMagavisionSuccess(token, t_anl, t_dtv))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchFetchTokenMagavision () {
  yield takeLatest(actionTypes.FETCH_TOKEN_MAGAVISION, fetchTokenMagavision)
}

export function * updateComboVerraco () {
  try {
    const state = yield select(state => state)
    const {analisisExtraccion, fechaExtraccion} = yield getFormValues('formAnalisisExtraccion')(state)
    let data = {analisisExtraccion: analisisExtraccion.value}

    if (analisisExtraccion && analisisExtraccion.value === analisisExtraccionConstants.PREVISTO) {
      data.fechaExtraccion = fechaExtraccion
    }

    yield put(comboVerraco(data, true))
    yield put(change('formAnalisisExtraccion', 'idVerraco', null))
    yield put(change('formAnalisisExtraccion', 'verraco', {
      verraco: null, nombreRaza: null, nombreLinea: null, indexVerraco: null, propiedad: null, estadoverraco: null, ubicacionverraco: null
    }))

    // Obtener la recta de calibrado predeterminada para asignarla cuando el verraco no tiene ninguna asignada y no se esta editando el análisis.
    let rectaCalibrado = state.combos.comboRectaCalibrado.filter((recta) => recta.predeterminado)
    if (rectaCalibrado.length) {
      yield put(change('formAnalisisExtraccion', 'rectaCalibrado', rectaCalibrado[0]))
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchUpdateComboVerraco () {
  yield takeLatest(actionTypes.UPDATE_COMBO_VERRACO, updateComboVerraco)
}

export function * fetchDatosVerracoExtracciones ({idVerraco, existsAnalisis = false}) {
  try {
    const state = yield select(state => state)
    let {idAnalisis, fechaExtraccion, verraco} = yield getFormValues('formAnalisisExtraccion')(state)
    let ordenExtraccion = verraco ? verraco.orden : '' // Variable auxiliar debido a que el campo orden se llaman igual en el formulario como en los datos del verraco.

    // Obtener la recta de calibrado predeterminada para asignarla cuando el verraco no tiene ninguna asignada y no se esta editando el análisis.
    let rectaCalibrado
    if (!existsAnalisis) {
      rectaCalibrado = state.combos.comboRectaCalibrado.filter((recta) => recta.predeterminado)
      if (rectaCalibrado.length) rectaCalibrado = rectaCalibrado[0]
    }

    let nuevoVerraco = {}
    if (idVerraco) {
      let {verraco, index, analisis24h, estado, mostrarCambioEstado, vigilado, descripcionVigilado, cliente, recta, operarioColecta, mediaUltimaDosis, orden, ubicacion} = yield call(obtenerDatosVerracoService, {
        idAnalisis, idVerraco, fechaExtraccion
      }, state.auth.token)
      nuevoVerraco = {...verraco, index, analisis24h, mostrarCambioEstado, vigilado, propiedad: (cliente && cliente.razonSocialCliente ? cliente.razonSocialCliente : i18n.t('ANALISIS_EXTRACCIONES.FORM.FORM.PROPIO')), recta: recta, mediaUltimaDosis, orden, ubicacion}
      nuevoVerraco.estadoverraco = i18n.t('FICHA_VERRACO.NUEVO_VERRACO.GENERAL.ESTADO_VERRACO.' + translateEstadoVerraco[estado])
      if (idAnalisis) {
        nuevoVerraco.orden = ordenExtraccion
      }
      if (!existsAnalisis) {
        if (vigilado) {
          yield call(yesNoModalSaga, {
            modalType: 'verracoEnVigilancia',
            subMessage: descripcionVigilado || null
          })
        }
        if (analisis24h) {
          if (vigilado) yield delay(300)
          yield call(yesNoModalSaga, {
            modalType: 'verracoAnalizadoHaceMenosDe24h'
          })
        }
        if (mostrarCambioEstado) {
          if (analisis24h) yield delay(300)
          yield call(yesNoModalSaga, {
            modalType: 'verracoPreguntaCambioEstadoActivo',
            okAction: function * () {
              yield put(prepararVerracoParaCambiarAEstadoActivoSuccess(true))
            },
            cancelAction: function * () {
              yield put(prepararVerracoParaCambiarAEstadoActivoSuccess(false))
            }
          })
        }
      }

      // Si el verraco tiene recta predeterminada se asignara dicha recta.
      // Debido a que la recta del verraco viene con distintas propiedades que en el combo se hace un mapeo de las propiedades label y value.
      if (!existsAnalisis && recta) {
        rectaCalibrado = {...recta, value: recta.idRecta, label: recta.nombre}
      }

      if (operarioColecta) {
        operarioColecta = state.combos.comboOperarioPredeterminado.filter((operario) => operario.value === operarioColecta)
        yield put(change('formAnalisisExtraccion', 'operarioColecta', operarioColecta))
      }

      // Actualizar el token verraco utilizado por magavision.
      let t_dtv = (nuevoVerraco && nuevoVerraco.codVerraco) ? nuevoVerraco.codVerraco : ''
      yield put(fetchTokenVerracoMagavisionSuccess(t_dtv))
    }

    if (!existsAnalisis) yield put(change('formAnalisisExtraccion', 'rectaCalibrado', rectaCalibrado))
    yield put(change('formAnalisisExtraccion', 'verraco', {...nuevoVerraco}))
    yield put(fetchDatosVerracoExtraccionesSuccess(nuevoVerraco))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchFetchDatosVerracoExtracciones () {
  yield takeLatest(actionTypes.FETCH_DATOS_VERRACO_EXTRACCIONES, fetchDatosVerracoExtracciones)
}

export function * changeTipoAnalisisExtraccion ({tipoAnalisisSeleccionado}) {
  try {
    yield put(changeTipoAnalisisExtraccionSuccess({tipoAnalisisSeleccionado}))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchChangeTipoAnalisisExtraccion () {
  yield takeLatest(actionTypes.CHANGE_TIPO_ANALISIS_EXTRACCION, changeTipoAnalisisExtraccion)
}

export function * raceAbrirYComprobarEstadoAnalisisMagavision ({tipoAnalisis, isSeguimiento, isPool}) {
  const controller = new AbortController();
  const signal = controller.signal;

  const {finished, cancelled} = yield race({
    finished: call(abrirMagavision, {tipoAnalisis, isSeguimiento, isPool, signal}),
    cancelled: take(actionTypes.CERRAR_NUEVO_MAGAVISION)
  })

  if (!finished) {
    controller.abort();
  }
}

export function * watchAbrirMagavision () {
  yield takeLatest(actionTypes.ABRIR_MAGAVISION, raceAbrirYComprobarEstadoAnalisisMagavision)
}

export function * abrirMagavision ({tipoAnalisis, isSeguimiento, isPool, signal}) {
  try {
    const state = yield select(state => state)
    if (tipoAnalisis === tipoAnalisisExtraccionConstants.NUEVO_MAGAVISION) {
      yield put(abrirNuevoMagavisionSuccess(isSeguimiento))

      // Funcionamiento comprobación del estado del analisis automaticamente.
      if (tipoAnalisis === tipoAnalisisExtraccionConstants.NUEVO_MAGAVISION) {
        yield put(esperandoResultadoSistemaAnalisis({status: true}))
        yield delay(10000);

        let estaAnalisisCompletado = false
        let data = {}
        let resultMagavision = null
        //const LIMITE_INTENTOS = 145 // ((5 minutos max - 10s (tiempo espera minimo antes de empezar)) / 2s (espera entre peticiones).
        //const LIMITE_INTENTOS = 10 // ((30 segundos max - 10s (tiempo espera minimo antes de empezar)) / 2s (espera entre peticiones).

        let intentos = 0

        let baseUrlMagavision = yield select(state => state.panelControl.urlMagavision)
        let {tokenUsuarioMagavision, t_anl} = yield select(state => isPool ? state.poolExtracciones : state.analisisExtracciones)

        while (/*intentos < LIMITE_INTENTOS && */(!resultMagavision || (resultMagavision.code !== 403 && resultMagavision.code !== 200))) {
          resultMagavision = yield call(leerDatosServidorLecturaNewMagavisionService, baseUrlMagavision, tokenUsuarioMagavision, t_anl, signal)
          intentos ++
          yield delay(2000);
        }

        estaAnalisisCompletado = resultMagavision.code === 200

        // Funcionamiento obtención datos nuevo magavision automaticamente.
        // TODO Eliminar después de testear la nueva funcionalidad de obtener los datos al aceptar el modal de magavision.
        // if (estaAnalisisCompletado) {
        //   let buenos = resultMagavision.conteo - resultMagavision.anormales
        //   let faCabezas = (resultMagavision.anormales - resultMagavision.cola - resultMagavision.gproximal - resultMagavision.gdistal)
        //   let faColas = resultMagavision.cola
        //   let faGotaProximal = resultMagavision.gproximal
        //   let faGotaDistal = resultMagavision.gdistal
        //   let faTotalPercent = round_decimal(resultMagavision.anormales * 100 / resultMagavision.conteo)
        //   if (isNaN(faTotalPercent) || !Number.isFinite(faTotalPercent)) faTotalPercent = 0
        //
        //   data = {
        //     motilidadMasal: '',
        //     concentracionTotal: resultMagavision.concentracion,
        //     contados: resultMagavision.conteo,
        //     camposCalculados: {
        //       buenos,
        //       faCabezas,
        //       faCabezasPercent: faCabezas !== 0 ? ((faCabezas * 100 / resultMagavision.anormales) * faTotalPercent / 100) : 0,
        //       faColas,
        //       faColasPercent: faColas !== 0 ? ((faColas * 100 / resultMagavision.anormales) * faTotalPercent / 100) : 0,
        //       faGotaProximal,
        //       faGotaProximalPercent: faGotaProximal !== 0 ? ((faGotaProximal * 100 / resultMagavision.anormales) * faTotalPercent / 100) : 0,
        //       faGotaDistal,
        //       faGotaDistalPercent: faGotaDistal !== 0 ? ((faGotaDistal * 100 / resultMagavision.anormales) * faTotalPercent / 100) : 0,
        //       faTotal: resultMagavision.anormales,
        //       faTotalPercent
        //     },
        //     mt: resultMagavision.MT,
        //     mp: resultMagavision.MP
        //   }
        // }
        //
        // if (estaAnalisisCompletado) {
        //   yield call(guardarResultadoSistemaAnalisis, {data, tipoAnalisis, isSeguimiento})
        // } else {
        //   yield call(yesNoModalSaga, {
        //     modalType: 'servidorNoPreparado'
        //   })
        // }
        yield put(esperandoResultadoSistemaAnalisis({status: false}))
      }
    } else {
      yield call(abrirOldMagavisionService)
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * cerrarNuevoMagavision ({isPool}) {
  yield put(cerrarNuevoMagavisionSuccess())

  let baseUrlMagavision = yield select(state => state.panelControl.urlMagavision)
  let {tokenUsuarioMagavision} = yield select(state => isPool ? state.poolExtracciones : state.analisisExtracciones)
  yield call(logoutNewMagavisionService, baseUrlMagavision, tokenUsuarioMagavision)
}

export function * watchCerrarNuevoMagavision () {
  yield takeLatest(actionTypes.CERRAR_NUEVO_MAGAVISION, cerrarNuevoMagavision)
}

export function * obtenerYGuardarResultadoSistemaAnalisis ({tipoAnalisis, isSeguimiento, isPool, isPrimerSeguimientoDelPool, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    // Status codes:
    // 403 -> error licencia
    // 404 -> No existe el analisis.
    // 405 -> Pendiente de completar
    // 200 -> Completado
    let status = false
    let estaServicioPreparado = false
    let data = {}
    let baseUrlMagavision, tokenUsuarioMagavision, t_anl

    if (tipoAnalisis === tipoAnalisisExtraccionConstants.MAGAVISION_I || tipoAnalisis === tipoAnalisisExtraccionConstants.MAGAVISION_M) {
      yield call(iniciarServidorLecturaOldMagavisionService)

      let limitRequest = 10
      let countRequest = 0
      while(!estaServicioPreparado && countRequest < limitRequest) {
        try {
          estaServicioPreparado = yield call(comprobarServidorLecturaOldMagavisionService)
          estaServicioPreparado = estaServicioPreparado && estaServicioPreparado.Iniciado
        } catch (e) {
          console.log(e, 'servidor no iniciado ...')
          yield delay(300)
        } finally {
          countRequest++
        }
      }
      if (estaServicioPreparado) {
        let result = yield call(leerDatosServidorLecturaOldMagavisionService)

        if (tipoAnalisis === tipoAnalisisExtraccionConstants.MAGAVISION_I) {
          data = {
            motilidadMasal: result.Linea1 !== null ? result.Linea1 : 0,
            concentracionTotal: result.Linea2 !== null ? result.Linea2 : 0,
            contados: result.Linea3 !== null ? result.Linea3 : 0,
            camposCalculados: {
              faTotal: result.Linea4 !== null ? result.Linea4 : 0,
            },
            mt: result.Linea5 !== null ? result.Linea5.slice(0, result.Linea5.length - 1) : 0,
            mp: result.Linea6 !== null ? result.Linea6.slice(0, result.Linea6.length - 1) : 0
          }
        } else {
          data = {
            motilidadMasal: result.Linea1 !== null ? result.Linea1 : 0,
            concentracionTotal: result.Linea3 !== null ? result.Linea3 : 0,
            contados: result.Linea4 !== null ? result.Linea4 : 0,
            camposCalculados: {
              faTotal: result.Linea5 !== null ? result.Linea5 : 0,
            }
          }
        }

        // Calcular el % de formas anormales.
        let faTotalPercent = parseFloat(data.camposCalculados.faTotal) * 100 / parseInt(data.contados, 10)
        faTotalPercent = round_decimal(faTotalPercent, 2, auth.separadorDec, auth.separadorMil)
        faTotalPercent = isNaN(faTotalPercent) || !Number.isFinite(faTotalPercent) ? 0 : faTotalPercent
        data.camposCalculados.faTotalPercent = faTotalPercent
      }
    }
    else if (tipoAnalisis === tipoAnalisisExtraccionConstants.NUEVO_MAGAVISION) {
      baseUrlMagavision = yield select(state => state.panelControl.urlMagavision)
      let tokens = yield select(state => isPool ? state.poolExtracciones : state.analisisExtracciones)
      tokenUsuarioMagavision = tokens.tokenUsuarioMagavision
      t_anl = tokens.t_anl
      let result = yield call(leerDatosServidorLecturaNewMagavisionService, baseUrlMagavision, tokenUsuarioMagavision, t_anl)
      status = result.code
      estaServicioPreparado = status === 200
      if (estaServicioPreparado) {
        let buenos = result.conteo - result.anormales
        let faCabezas = (result.anormales - result.cola - result.gproximal - result.gdistal)
        let faColas = result.cola
        let faGotaProximal = result.gproximal
        let faGotaDistal = result.gdistal
        let faTotalPercent = round_decimal(result.anormales * 100 / result.conteo)
        if (isNaN(faTotalPercent) || !Number.isFinite(faTotalPercent)) faTotalPercent = 0

        data = {
          motilidadMasal: '',
          concentracionTotal: result.concentracion,
          contados: result.conteo,
          camposCalculados: {
            buenos,
            faCabezas,
            faCabezasPercent: faCabezas !== 0 ? ((faCabezas * 100 / result.anormales) * faTotalPercent / 100) : 0,
            faColas,
            faColasPercent: faColas !== 0 ? ((faColas * 100 / result.anormales) * faTotalPercent / 100) : 0,
            faGotaProximal,
            faGotaProximalPercent: faGotaProximal !== 0 ? ((faGotaProximal * 100 / result.anormales) * faTotalPercent / 100) : 0,
            faGotaDistal,
            faGotaDistalPercent: faGotaDistal !== 0 ? ((faGotaDistal * 100 / result.anormales) * faTotalPercent / 100) : 0,
            faTotal: result.anormales,
            faTotalPercent
          },
          mt: result.MT,
          mp: result.MP
        }

        // Actualizar token t_dtv_final en base al recibido de magavision.
        if (isPool) {
          yield put(updateTokenMagavisionPoolSuccess(result.t_dtv, isPrimerSeguimientoDelPool))
        } else if (!isSeguimiento) {
          yield put(updateTokenMagavisionSuccess(result.t_dtv))
        }
      }
    }

    if (estaServicioPreparado) {
      if (tipoAnalisis === tipoAnalisisExtraccionConstants.NUEVO_MAGAVISION) {
        yield call(cerrarNuevoMagavision, isSeguimiento, isPool)
      }
      yield call(guardarResultadoSistemaAnalisis, {data, tipoAnalisis, isSeguimiento})
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      if(status === 403) {
        yield call(yesNoModalSaga, {
          modalType: 'licenciaInvalida'
        })
      } else if(status === 404) {
        yield call(yesNoModalSaga, {
          modalType: 'analisisInexistente'
        })
      } else if(status === 405) {
        yield call(yesNoModalSaga, {
          modalType: 'analisisPendienteDeCompletar'
        })
      } else {
        yield call(yesNoModalSaga, {
          modalType: 'servidorNoPreparado'
        })
      }
      if (onErrorCallback) yield onErrorCallback()
    }
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
  }
}

export function * watchObtenerYGuardarResultadoSistemaAnalisis () {
  yield takeLatest(actionTypes.OBTENER_Y_GUARDAR_RESULTADO_SISTEMA_ANALISIS, obtenerYGuardarResultadoSistemaAnalisis)
}

export function * guardarResultadoSistemaAnalisis ({data, tipoAnalisis, isSeguimiento}) {
  try {
    let state = yield select(state => state)
    let tipoAnalisisSeleccionado = tipoAnalisis || state.analisisExtracciones.extraccion.tipoAnalisisSeleccionado || state.panelControl.tipoAnalisisPredeterminado

    let tKeyMensajeAlerta = 'SIMPLE_MODAL.SUPERIOR_AL_LIMITE'
    let tKeyNombreCampo = ''
    let dynamicMessage = null

    if (
      tipoAnalisisSeleccionado === tipoAnalisisExtraccionConstants.COLORIMETRO ||
      tipoAnalisisSeleccionado === tipoAnalisisExtraccionConstants.CAMARA_BURKER
    ) {
      let faTotal = data.espermatozoidesContados - data.buenos
      let camposCalculados = {
        totalAcrosomas: data.acrosomasDannados,
        percentAcrosomas: data.acrosomas,
        faCabezas: data.cabeza,
        faCabezasPercent: data.cabeza !== 0 ? ((data.cabeza * 100 / faTotal) * data.totalFormasAnormales / 100) : 0,
        faColas: data.cola,
        faColasPercent: data.cola !== 0 ? ((data.cola * 100 / faTotal) * data.totalFormasAnormales / 100) : 0,
        faGotaDistal: data.gotaDistal,
        faGotaDistalPercent: data.gotaDistal !== 0 ? ((data.gotaDistal * 100 / faTotal) * data.totalFormasAnormales / 100) : 0,
        faGotaProximal: data.gotaProximal,
        faGotaProximalPercent: data.gotaProximal !== 0 ? ((data.gotaProximal * 100 / faTotal) * data.totalFormasAnormales / 100) : 0,
        faTotal,
        faTotalPercent: data.totalFormasAnormales,
        buenos: data.buenos
      }

      if (isSeguimiento) {
        // TODO mostrar avisos cuando se sobrepase un 20% respecto al valor origen. (Pliego Memorandum, RQ08-021.01. Página 84)
        yield put(change('ModalFormSeguimiento', 'formasAnormales', camposCalculados.faTotalPercent))
      } else {
        if ((camposCalculados.faCabezasPercent || ''+camposCalculados.faCabezasPercent === '0') && parseFloat(camposCalculados.faCabezasPercent) > parseFloat(state.panelControl.AlertaFACabezaMaxima)) {
          tKeyMensajeAlerta = 'SIMPLE_MODAL.SUPERIOR_AL_LIMITE'
          tKeyNombreCampo = 'ANALISIS_EXTRACCIONES.FORM.FORM.FORMAS_ANORMALES_CABEZAS_PERCENT_FULL'
          dynamicMessage = i18n.t(tKeyMensajeAlerta, {nombreCampo: i18n.t(tKeyNombreCampo), value: state.panelControl.AlertaFACabezaMaxima})
          yield call(yesNoModalSaga, {
            modalType: 'fueraLimite',
            message: dynamicMessage,
          })
        }
        if ((camposCalculados.faColasPercent || ''+camposCalculados.faColasPercent === '0') && parseFloat(camposCalculados.faColasPercent) > parseFloat(state.panelControl.AlertaFaColaMaxima)) {
          if (dynamicMessage) yield delay(300)
          tKeyMensajeAlerta = 'SIMPLE_MODAL.SUPERIOR_AL_LIMITE'
          tKeyNombreCampo = 'ANALISIS_EXTRACCIONES.FORM.FORM.FORMAS_ANORMALES_COLAS_PERCENT_FULL'
          dynamicMessage = i18n.t(tKeyMensajeAlerta, {nombreCampo: i18n.t(tKeyNombreCampo), value: state.panelControl.AlertaFaColaMaxima})
          yield call(yesNoModalSaga, {
            modalType: 'fueraLimite',
            message: dynamicMessage,
          })
        }
        if ((camposCalculados.faGotaDistalPercent || ''+camposCalculados.faGotaDistalPercent === '0') && parseFloat(camposCalculados.faGotaDistalPercent) > parseFloat(state.panelControl.AlertaFAGDistalMaxima)) {
          if (dynamicMessage) yield delay(300)
          tKeyMensajeAlerta = 'SIMPLE_MODAL.SUPERIOR_AL_LIMITE'
          tKeyNombreCampo = 'ANALISIS_EXTRACCIONES.FORM.FORM.FORMAS_ANORMALES_GOTA_DISTAL_PERCENT_FULL'
          dynamicMessage = i18n.t(tKeyMensajeAlerta, {nombreCampo: i18n.t(tKeyNombreCampo), value: state.panelControl.AlertaFAGDistalMaxima})
          yield call(yesNoModalSaga, {
            modalType: 'fueraLimite',
            message: dynamicMessage,
          })
        }
        if ((camposCalculados.faGotaProximalPercent || ''+camposCalculados.faGotaProximalPercent === '0') && parseFloat(camposCalculados.faGotaProximalPercent) > parseFloat(state.panelControl.AlertaFAGProximalMaxima)) {
          if (dynamicMessage) yield delay(300)
          tKeyMensajeAlerta = 'SIMPLE_MODAL.SUPERIOR_AL_LIMITE'
          tKeyNombreCampo = 'ANALISIS_EXTRACCIONES.FORM.FORM.FORMAS_ANORMALES_GOTA_PROXIMAL_PERCENT_FULL'
          dynamicMessage = i18n.t(tKeyMensajeAlerta, {nombreCampo: i18n.t(tKeyNombreCampo), value: state.panelControl.AlertaFAGProximalMaxima})
          yield call(yesNoModalSaga, {
            modalType: 'fueraLimite',
            message: dynamicMessage,
          })
        }
        if ((camposCalculados.faTotalPercent || ''+camposCalculados.faTotalPercent === '0') && parseFloat(camposCalculados.faTotalPercent) > parseFloat(state.panelControl.AlertaFAGTotalMaxima)) {
          if (dynamicMessage) yield delay(300)
          tKeyMensajeAlerta = 'SIMPLE_MODAL.SUPERIOR_AL_LIMITE'
          tKeyNombreCampo = 'ANALISIS_EXTRACCIONES.FORM.FORM.FORMAS_ANORMALES_TOTAL_PERCENT_FULL'
          dynamicMessage = i18n.t(tKeyMensajeAlerta, {nombreCampo: i18n.t(tKeyNombreCampo), value: state.panelControl.AlertaFAGTotalMaxima})
          yield call(yesNoModalSaga, {
            modalType: 'fueraLimite',
            message: dynamicMessage,
          })
        }
        yield put(change('formAnalisisExtraccion', 'camposCalculados', camposCalculados))
        if (tipoAnalisisSeleccionado === tipoAnalisisExtraccionConstants.CAMARA_BURKER) {
          yield put(change('formAnalisisExtraccion', 'contados', data.espermatozoidesContados))
        }
      }
      yield call(calcConcentracionTotal, {tipoAnalisis, isSeguimiento, dataSeguimiento: {contados: data.espermatozoidesContados}})
    } else if (
      tipoAnalisisSeleccionado === tipoAnalisisExtraccionConstants.MAGAVISION_M ||
      tipoAnalisisSeleccionado === tipoAnalisisExtraccionConstants.MAGAVISION_I ||
      tipoAnalisisSeleccionado === tipoAnalisisExtraccionConstants.NUEVO_MAGAVISION
    ) {
      if (isSeguimiento) {
        // TODO mostrar avisos cuando se sobrepase un 20% respecto al valor origen. (Pliego Memorandum, RQ08-021.01. Página 84)
        yield put(change('ModalFormSeguimiento', 'porMotilidad', data.motilidadMasal))
        yield put(change('ModalFormSeguimiento', 'porMotilidadTotal', data.mt))
        yield put(change('ModalFormSeguimiento', 'porMotilidadProgresiva', data.mp))
        yield put(change('ModalFormSeguimiento', 'formasAnormales', data.camposCalculados.faTotalPercent))
        yield put(change('ModalFormSeguimiento', 'concentracion', data.concentracionTotal))
      } else {
        if ((data.concentracionTotal || ''+data.concentracionTotal === '0') && parseFloat(data.concentracionTotal) < parseFloat(state.panelControl.AlertaConcentracionTotalMinimo)) {
          yield delay(300)
          tKeyMensajeAlerta = 'SIMPLE_MODAL.INFERIOR_AL_LIMITE'
          tKeyNombreCampo = 'ANALISIS_EXTRACCIONES.FORM.FORM.CONCENTRACION'
          dynamicMessage = i18n.t(tKeyMensajeAlerta, {nombreCampo: i18n.t(tKeyNombreCampo), value: state.panelControl.AlertaConcentracionTotalMinimo})
          yield call(yesNoModalSaga, {
            modalType: 'fueraLimite',
            message: dynamicMessage,
          })
        } else if ((data.concentracionTotal || ''+data.concentracionTotal === '0') && parseFloat(data.concentracionTotal) > parseFloat(state.panelControl.AlertaConcentracionTotalMaximo)) {
          yield delay(300)
          tKeyMensajeAlerta = 'SIMPLE_MODAL.SUPERIOR_AL_LIMITE'
          tKeyNombreCampo = 'ANALISIS_EXTRACCIONES.FORM.FORM.CONCENTRACION'
          dynamicMessage = i18n.t(tKeyMensajeAlerta, {nombreCampo: i18n.t(tKeyNombreCampo), value: state.panelControl.AlertaConcentracionTotalMaximo})
          yield call(yesNoModalSaga, {
            modalType: 'fueraLimite',
            message: dynamicMessage,
          })
        }
        if ((data.camposCalculados.faTotalPercent || ''+data.camposCalculados.faTotalPercent === '0') && parseFloat(data.camposCalculados.faTotalPercent) > parseFloat(state.panelControl.AlertaFAGTotalMaxima)) {
          if (dynamicMessage) yield delay(300)
          tKeyMensajeAlerta = 'SIMPLE_MODAL.SUPERIOR_AL_LIMITE'
          tKeyNombreCampo = 'ANALISIS_EXTRACCIONES.FORM.FORM.FORMAS_ANORMALES_TOTAL_PERCENT_FULL'
          dynamicMessage = i18n.t(tKeyMensajeAlerta, {nombreCampo: i18n.t(tKeyNombreCampo), value: state.panelControl.AlertaFAGTotalMaxima})
          yield call(yesNoModalSaga, {
            modalType: 'fueraLimite',
            message: dynamicMessage,
          })
        }

        yield put(change('formAnalisisExtraccion', 'contados', data.contados))
        yield put(change('formAnalisisExtraccion', 'camposCalculados', data.camposCalculados))
        yield put(change('formAnalisisExtraccion', 'concentracionTotal', data.concentracionTotal))

        // Gestionar la motilidad masal, solo en los analisis proporcionados por Magavisión M.
        if (tipoAnalisisSeleccionado === tipoAnalisisExtraccionConstants.MAGAVISION_M) {
          if ((data.motilidadMasal || ''+data.motilidadMasal === '0') && parseFloat(data.motilidadMasal) < parseFloat(state.panelControl.AlertaMotilidadMasalMinima)) {
            if (dynamicMessage) yield delay(300)
            tKeyMensajeAlerta = 'SIMPLE_MODAL.INFERIOR_AL_LIMITE'
            tKeyNombreCampo = 'ANALISIS_EXTRACCIONES.FORM.FORM.MOTILIDAD_MASAL_FULL'
            dynamicMessage = i18n.t(tKeyMensajeAlerta, {nombreCampo: i18n.t(tKeyNombreCampo), value: state.panelControl.AlertaMotilidadMasalMinima})
            yield call(yesNoModalSaga, {
              modalType: 'fueraLimite',
              message: dynamicMessage,
            })
          }else if ((data.motilidadMasal || ''+data.motilidadMasal === '0') && parseFloat(data.motilidadMasal) > parseFloat(state.panelControl.AlertaMotilidadMasalMaxima)) {
            if (dynamicMessage) yield delay(300)
            tKeyMensajeAlerta = 'SIMPLE_MODAL.SUPERIOR_AL_LIMITE'
            tKeyNombreCampo = 'ANALISIS_EXTRACCIONES.FORM.FORM.MOTILIDAD_MASAL_FULL'
            dynamicMessage = i18n.t(tKeyMensajeAlerta, {nombreCampo: i18n.t(tKeyNombreCampo), value: state.panelControl.AlertaMotilidadMasalMaxima})
            yield call(yesNoModalSaga, {
              modalType: 'fueraLimite',
              message: dynamicMessage,
            })
          }

          yield put(change('formAnalisisExtraccion', 'motilidadMasal', data.motilidadMasal))
        }else if (tipoAnalisisSeleccionado === tipoAnalisisExtraccionConstants.MAGAVISION_I || tipoAnalisisSeleccionado === tipoAnalisisExtraccionConstants.NUEVO_MAGAVISION) {
          if ((data.mt || ''+data.mt === '0') && parseFloat(data.mt) < parseFloat(state.panelControl.AlertaMotilidadTotalMinimo)) {
            if (dynamicMessage) yield delay(300)
            tKeyMensajeAlerta = 'SIMPLE_MODAL.INFERIOR_AL_LIMITE'
            tKeyNombreCampo = 'ANALISIS_EXTRACCIONES.FORM.FORM.MT_FULL'
            dynamicMessage = i18n.t(tKeyMensajeAlerta, {nombreCampo: i18n.t(tKeyNombreCampo), value: state.panelControl.AlertaMotilidadTotalMinimo})
            yield call(yesNoModalSaga, {
              modalType: 'fueraLimite',
              message: dynamicMessage,
            })
          }else if (tipoAnalisisSeleccionado === tipoAnalisisExtraccionConstants.MAGAVISION_I && (data.mt || ''+data.mt === '0') && parseFloat(data.mt) > parseFloat(state.panelControl.AlertaMotilidadTotalMaximo)) {
            if (dynamicMessage) yield delay(300)
            tKeyMensajeAlerta = 'SIMPLE_MODAL.SUPERIOR_AL_LIMITE'
            tKeyNombreCampo = 'ANALISIS_EXTRACCIONES.FORM.FORM.MT_FULL'
            dynamicMessage = i18n.t(tKeyMensajeAlerta, {nombreCampo: i18n.t(tKeyNombreCampo), value: state.panelControl.AlertaMotilidadTotalMaximo})
            yield call(yesNoModalSaga, {
              modalType: 'fueraLimite',
              message: dynamicMessage,
            })
          }
          if ((data.mp || ''+data.mp === '0') && parseFloat(data.mp) < parseFloat(state.panelControl.AlertaMotilidadProgresivaMinimo)) {
            if (dynamicMessage) yield delay(300)
            tKeyMensajeAlerta = 'SIMPLE_MODAL.INFERIOR_AL_LIMITE'
            tKeyNombreCampo = 'ANALISIS_EXTRACCIONES.FORM.FORM.MP_FULL'
            dynamicMessage = i18n.t(tKeyMensajeAlerta, {nombreCampo: i18n.t(tKeyNombreCampo), value: state.panelControl.AlertaMotilidadProgresivaMinimo})
            yield call(yesNoModalSaga, {
              modalType: 'fueraLimite',
              message: dynamicMessage,
            })
          }else if (tipoAnalisisSeleccionado === tipoAnalisisExtraccionConstants.MAGAVISION_I && (data.mp || ''+data.mp === '0') && parseFloat(data.mp) > parseFloat(state.panelControl.AlertaMotilidadProgresivaMaximo)) {
            if (dynamicMessage) yield delay(300)
            tKeyMensajeAlerta = 'SIMPLE_MODAL.SUPERIOR_AL_LIMITE'
            tKeyNombreCampo = 'ANALISIS_EXTRACCIONES.FORM.FORM.MP_FULL'
            dynamicMessage = i18n.t(tKeyMensajeAlerta, {nombreCampo: i18n.t(tKeyNombreCampo), value: state.panelControl.AlertaMotilidadProgresivaMaximo})
            yield call(yesNoModalSaga, {
              modalType: 'fueraLimite',
              message: dynamicMessage,
            })
          }

          if (tipoAnalisisSeleccionado === tipoAnalisisExtraccionConstants.NUEVO_MAGAVISION) {
            if ((data.camposCalculados.faColasPercent || ''+data.camposCalculados.faColasPercent === '0') && parseFloat(data.camposCalculados.faColasPercent) > parseFloat(state.panelControl.AlertaFaColaMaxima)) {
              if (dynamicMessage) yield delay(300)
              tKeyMensajeAlerta = 'SIMPLE_MODAL.SUPERIOR_AL_LIMITE'
              tKeyNombreCampo = 'ANALISIS_EXTRACCIONES.FORM.FORM.FORMAS_ANORMALES_COLAS_PERCENT_FULL'
              dynamicMessage = i18n.t(tKeyMensajeAlerta, {nombreCampo: i18n.t(tKeyNombreCampo), value: state.panelControl.AlertaFaColaMaxima})
              yield call(yesNoModalSaga, {
                modalType: 'fueraLimite',
                message: dynamicMessage,
              })
            }
            if ((data.camposCalculados.faGotaDistalPercent || ''+data.camposCalculados.faGotaDistalPercent === '0') && parseFloat(data.camposCalculados.faGotaDistalPercent) > parseFloat(state.panelControl.AlertaFAGDistalMaxima)) {
              if (dynamicMessage) yield delay(300)
              tKeyMensajeAlerta = 'SIMPLE_MODAL.SUPERIOR_AL_LIMITE'
              tKeyNombreCampo = 'ANALISIS_EXTRACCIONES.FORM.FORM.FORMAS_ANORMALES_GOTA_DISTAL_PERCENT_FULL'
              dynamicMessage = i18n.t(tKeyMensajeAlerta, {nombreCampo: i18n.t(tKeyNombreCampo), value: state.panelControl.AlertaFAGDistalMaxima})
              yield call(yesNoModalSaga, {
                modalType: 'fueraLimite',
                message: dynamicMessage,
              })
            }
            if ((data.camposCalculados.faGotaProximalPercent || ''+data.camposCalculados.faGotaProximalPercent === '0') && parseFloat(data.camposCalculados.faGotaProximalPercent) > parseFloat(state.panelControl.AlertaFAGProximalMaxima)) {
              if (dynamicMessage) yield delay(300)
              tKeyMensajeAlerta = 'SIMPLE_MODAL.SUPERIOR_AL_LIMITE'
              tKeyNombreCampo = 'ANALISIS_EXTRACCIONES.FORM.FORM.FORMAS_ANORMALES_GOTA_PROXIMAL_PERCENT_FULL'
              dynamicMessage = i18n.t(tKeyMensajeAlerta, {nombreCampo: i18n.t(tKeyNombreCampo), value: state.panelControl.AlertaFAGProximalMaxima})
              yield call(yesNoModalSaga, {
                modalType: 'fueraLimite',
                message: dynamicMessage,
              })
            }
          }

          // Gestionar la mt y mp, solo en los analisis proporcionados por Magavisión I y MagavisionWeb.
          yield put(change('formAnalisisExtraccion', 'mt', data.mt))
          yield put(change('formAnalisisExtraccion', 'mp', data.mp))
        }
      }
    }
    if (!isSeguimiento) {
      yield call(calcConcentracionUtil)
      yield call(calcConcentracionXDosis)
      yield call(calcNumeroDosis, {updateAllDosis: true})
      yield call(reCalcConcentracionXDosis)
      yield call(calcVolumenDiluyente)
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchGuardarResultadoSistemaAnalisis () {
  yield takeLatest(actionTypes.GUARDAR_RESULTADO_SISTEMA_ANALISIS, guardarResultadoSistemaAnalisis)
}

export function * handleAnalisisCalcFunctionsOrden ({calcFunctionList}) {
  for (let calcFunction of calcFunctionList) {
    switch(calcFunction.name) {
      case 'calcConcentracionTotal':
        yield call(calcConcentracionTotal)
        break
      case 'calcConcentracionUtil':
        yield call(calcConcentracionUtil)
        break
      case 'calcConcentracionXDosis':
        yield call(calcConcentracionXDosis)
        break
      case 'calcNumeroDosis':
        yield call(calcNumeroDosis, calcFunction.args)
        break
      case 'reCalcConcentracionXDosis':
        yield call(reCalcConcentracionXDosis)
        break
      case 'calcVolumenDiluyente':
        yield call(calcVolumenDiluyente)
        break
      case 'callback':
        yield call(calcFunction.fn)
        break
      default:
        yield console.log('unhandler function: ', calcFunction.name)
    }
  }
}

export function * watchHandleAnalisisCalcFunctionsOrden () {
  yield takeLatest(actionTypes.HANDLE_ANALISIS_CALC_FUNCTIONS_ORDEN, handleAnalisisCalcFunctionsOrden)
}

// 1º paso: Calcular la concentración total.
export function * calcConcentracionTotal (data) {
  let tipoAnalisis, isSeguimiento, dataSeguimiento
  if(data){
    tipoAnalisis = data.tipoAnalisis
    isSeguimiento = data.isSeguimiento
    dataSeguimiento = data.dataSeguimiento
  }
  try {
    let state = yield select(state => state)
    let {
      rectaCalibrado, lectura, contados
    } = !isSeguimiento ? yield getFormValues('formAnalisisExtraccion')(state) : dataSeguimiento

    let tipoAnalisisSeleccionado = tipoAnalisis || state.analisisExtracciones.extraccion.tipoAnalisisSeleccionado || state.panelControl.tipoAnalisisPredeterminado
    let concentracionTotal = 0
    if (tipoAnalisisSeleccionado === tipoAnalisisExtraccionConstants.COLORIMETRO && (lectura || lectura === 0)) {
      lectura = parseFloat(lectura)
      concentracionTotal = parseFloat(rectaCalibrado.Y) * parseFloat(lectura) + parseFloat(rectaCalibrado.X)
    } else if (tipoAnalisisSeleccionado === tipoAnalisisExtraccionConstants.CAMARA_BURKER) {
      concentracionTotal = (isSeguimiento ? contados : contados * 10)
    }
    concentracionTotal = round_decimal(concentracionTotal, 2, state.auth.separadorDec, state.auth.separadorMil)

    if (isSeguimiento) {
      // TODO mostrar avisos cuando se sobrepase un 20% respecto al valor origen. (Pliego Memorandum, RQ08-021.01. Página 84)
      yield put(change('ModalFormSeguimiento', 'concentracion', concentracionTotal))
    } else  {
      let tKeyMensajeAlerta = ''
      let tKeyNombreCampo = ''
      let dynamicMessage = null
      if ((concentracionTotal || ''+concentracionTotal === '0') && parseFloat(concentracionTotal) < parseFloat(state.panelControl.AlertaConcentracionTotalMinimo) &&
        (tipoAnalisisSeleccionado !== tipoAnalisisExtraccionConstants.COLORIMETRO || lectura || lectura === 0)
      ) {
        yield delay(300)
        tKeyMensajeAlerta = 'SIMPLE_MODAL.INFERIOR_AL_LIMITE'
        tKeyNombreCampo = 'ANALISIS_EXTRACCIONES.FORM.FORM.CONCENTRACION'
        dynamicMessage = i18n.t(tKeyMensajeAlerta, {nombreCampo: i18n.t(tKeyNombreCampo), value: state.panelControl.AlertaConcentracionTotalMinimo})
        yield call(yesNoModalSaga, {
          modalType: 'fueraLimite',
          message: dynamicMessage,
        })
      } else if ((concentracionTotal || ''+concentracionTotal === '0') && parseFloat(concentracionTotal) > parseFloat(state.panelControl.AlertaConcentracionTotalMaximo) &&
        (tipoAnalisisSeleccionado !== tipoAnalisisExtraccionConstants.COLORIMETRO || lectura || lectura === 0)) {
        yield delay(300)
        tKeyMensajeAlerta = 'SIMPLE_MODAL.SUPERIOR_AL_LIMITE'
        tKeyNombreCampo = 'ANALISIS_EXTRACCIONES.FORM.FORM.CONCENTRACION'
        dynamicMessage = i18n.t(tKeyMensajeAlerta, {nombreCampo: i18n.t(tKeyNombreCampo), value: state.panelControl.AlertaConcentracionTotalMaximo})
        yield call(yesNoModalSaga, {
          modalType: 'fueraLimite',
          message: dynamicMessage,
        })
      }
      yield put(change('formAnalisisExtraccion', 'concentracionTotal', concentracionTotal))
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchCalcConcentracionTotal () {
  yield takeLatest(actionTypes.CALC_CONCENTRACION_TOTAL, calcConcentracionTotal)
}

// 2º paso: Calcular la concentración util teniendo en cuenta los valores a descontar.
export function * calcConcentracionUtil () {
  try {
    let state = yield select(state => state)
    let {
      motilidadTotal, mp, mt, concentracionTotal, camposCalculados
    } = yield getFormValues('formAnalisisExtraccion')(state)

    let descontar = 0
    let concentracionUtil = 0
    if (state.panelControl.ConcentracionDosisFormasAnormales && camposCalculados && camposCalculados.faTotalPercent) {
      descontar += camposCalculados.faTotalPercent
    }
    if (state.panelControl.ConcentracionDosisMotilidad && motilidadTotal) {
      motilidadTotal = parseFloat(motilidadTotal)
      descontar += 100 - motilidadTotal // Motilidad (%)
    }
    if (state.panelControl.ConcentracionDosisMotilesProgresivos && mp) {
      descontar += 100 - mp
    }
    if (state.panelControl.ConcentracionDosisMotilesTotales && mt) {
      descontar += 100 - mt
    }
    if (concentracionTotal) {
      concentracionUtil = concentracionTotal * (100 - descontar) / 100
      concentracionUtil = round_decimal(concentracionUtil, 2, state.auth.separadorDec, state.auth.separadorMil)
    }

    yield put(change('formAnalisisExtraccion', 'concentracionUtil', concentracionUtil))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchCalcConcentracionUtil () {
  yield takeLatest(actionTypes.CALC_CONCENTRACION_UTIL, calcConcentracionUtil)
}

// 3º paso: Calcular la concentración x dosis, util o aplicada, dependiendo de la configuración del panel de control.
export function * calcConcentracionXDosis () {
  try {
    let state = yield select(state => state)
    let {
      concentracionTotal, concentracionUtil, tipoDosis
    } = yield getFormValues('formAnalisisExtraccion')(state)
    let tipoDosisPredeterminada = state.combos.comboTipoDosis.find(tipoDosis => tipoDosis.predeterminado)
    let concentracionXDosisAplicada = 0
    let concentracionXDosisUtil = 0
    let concentracionXDosisAplicadaPredeterminada = 0
    let concentracionXDosisUtilPredeterminada = 0

    if (tipoDosis) {
      concentracionXDosisAplicada = parseFloat(tipoDosis.concentracion)
      concentracionXDosisUtil = concentracionXDosisAplicada

      if (tipoDosisPredeterminada) {
      concentracionXDosisAplicadaPredeterminada = parseFloat(tipoDosisPredeterminada.concentracion)
      concentracionXDosisUtilPredeterminada = concentracionXDosisAplicadaPredeterminada
      }

      if (concentracionTotal && concentracionUtil) {
        if (state.panelControl.ConcentracionAplicada) {
          concentracionXDosisUtil = (concentracionXDosisAplicada * concentracionUtil) / concentracionTotal
          concentracionXDosisUtil = round_decimal(concentracionXDosisUtil, 4, state.auth.separadorDec, state.auth.separadorMil)
          concentracionXDosisUtilPredeterminada = (concentracionXDosisAplicadaPredeterminada * concentracionUtil) / concentracionTotal
          concentracionXDosisUtilPredeterminada = round_decimal(concentracionXDosisUtilPredeterminada, 4, state.auth.separadorDec, state.auth.separadorMil)
        } else {
          concentracionXDosisAplicada = (concentracionXDosisUtil * concentracionTotal) / concentracionUtil
          concentracionXDosisAplicada = round_decimal(concentracionXDosisAplicada, 4, state.auth.separadorDec, state.auth.separadorMil)
          concentracionXDosisAplicadaPredeterminada = (concentracionXDosisUtilPredeterminada * concentracionTotal) / concentracionUtil
          concentracionXDosisAplicadaPredeterminada = round_decimal(concentracionXDosisAplicadaPredeterminada, 4, state.auth.separadorDec, state.auth.separadorMil)
        }
      }
    }
    yield put(change('formAnalisisExtraccion', 'concentracionXDosisAplicada', concentracionXDosisAplicada))
    yield put(change('formAnalisisExtraccion', 'concentracionXDosisUtil', concentracionXDosisUtil))
    yield put(change('formAnalisisExtraccion', 'concentracionXDosisAplicadaPredeterminada', concentracionXDosisAplicadaPredeterminada))
    yield put(change('formAnalisisExtraccion', 'concentracionXDosisUtilPredeterminada', concentracionXDosisUtilPredeterminada))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchCalcConcentracionXDosis () {
  yield takeLatest(actionTypes.CALC_CONCENTRACION_X_DOSIS, calcConcentracionXDosis)
}

// 4º paso: Calcular el nº de dosis, dicho valor se asignara tanto a las teóricas como a las reales.
export function * calcNumeroDosis ({updateAllDosis = true, useTipoDosisConcentracion = false}) {
  try {
    let state = yield select(state => state)
    let {
      volumen, concentracionUtil, concentracionXDosisUtil, concentracionXDosisUtilPredeterminada, tipoDosis
    } = yield getFormValues('formAnalisisExtraccion')(state)
    let tipoDosisPredeterminada = state.combos.comboTipoDosis.find(tipoDosis => tipoDosis.predeterminado)

    let dosis = 0
    let dosisPredeterminadas = 0
    let dosisPredeterminadasTipoDosisActual = 0
    if (volumen && concentracionUtil && ((useTipoDosisConcentracion && tipoDosis.concentracion) || (!useTipoDosisConcentracion && concentracionXDosisUtil))) {
      volumen = parseFloat(volumen)
      concentracionXDosisUtil = parseFloat(concentracionXDosisUtil)
      dosis = Math.round(volumen * parseFloat(concentracionUtil) / (useTipoDosisConcentracion ? parseFloat(tipoDosis.concentracion) : concentracionXDosisUtil) / 1000)
      dosisPredeterminadasTipoDosisActual = Math.round(volumen * parseFloat(concentracionUtil) / parseFloat(tipoDosis.concentracion) / 1000)

      if ((useTipoDosisConcentracion && tipoDosisPredeterminada.concentracion) || (!useTipoDosisConcentracion && concentracionXDosisUtilPredeterminada)) {
        dosisPredeterminadas = Math.round(volumen * parseFloat(concentracionUtil) / (useTipoDosisConcentracion ? parseFloat(tipoDosisPredeterminada.concentracion) : concentracionXDosisUtilPredeterminada) / 1000)
      }
    }

    if (updateAllDosis) {
      yield put(change('formAnalisisExtraccion', 'dosisTeoricas', dosisPredeterminadasTipoDosisActual))
      yield put(change('formAnalisisExtraccion', 'dosisTeoricasPredeterminadas', dosisPredeterminadas))
    }
    yield put(change('formAnalisisExtraccion', 'dosisReales', dosis))
    yield put(change('formAnalisisExtraccion', 'dosisRealesPredeterminadas', dosisPredeterminadas))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchCalcNumeroDosis () {
  yield takeLatest(actionTypes.CALC_NUMERO_DOSIS, calcNumeroDosis)
}

// 5º paso: Recalcular la concentración x dosis en base a las dosis calculadas o modificadas pr el usuario.
export function * reCalcConcentracionXDosis () {
  try {
    let state = yield select(state => state)
    let {
      volumen, concentracionTotal, concentracionUtil, dosisReales
    } = yield getFormValues('formAnalisisExtraccion')(state)

    if (volumen && dosisReales && concentracionUtil) {
      volumen = parseFloat(volumen)
      concentracionTotal = parseFloat(concentracionTotal)
      concentracionUtil = parseFloat(concentracionUtil)
      dosisReales = parseInt(dosisReales, 10)
      let newConcentracionXDosisUtil = ((volumen * concentracionUtil) / dosisReales) / 1000
      newConcentracionXDosisUtil = round_decimal(newConcentracionXDosisUtil, 4, state.auth.separadorDec, state.auth.separadorMil)
      let newConcentracionXDosisAplicada = (newConcentracionXDosisUtil * concentracionTotal) / concentracionUtil
      newConcentracionXDosisAplicada = round_decimal(newConcentracionXDosisAplicada, 4, state.auth.separadorDec, state.auth.separadorMil)

      if (!isFinite(newConcentracionXDosisAplicada)) newConcentracionXDosisAplicada = 0
      if (!isFinite(newConcentracionXDosisUtil)) newConcentracionXDosisUtil = 0

      yield put(change('formAnalisisExtraccion', 'concentracionXDosisAplicada', newConcentracionXDosisAplicada))
      yield put(change('formAnalisisExtraccion', 'concentracionXDosisUtil', newConcentracionXDosisUtil))
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchReCalcConcentracionXDosis () {
  yield takeLatest(actionTypes.RE_CALC_CONCENTRACION_X_DOSIS, reCalcConcentracionXDosis)
}

// 6º paso: Calcular el volumen de diluyente.
export function * calcVolumenDiluyente () {
  try {
    let state = yield select(state => state)
    let {
      volumen, tipoDosis, dosisReales
    } = yield getFormValues('formAnalisisExtraccion')(state)

    let volumenDiluyente = 0
    if (dosisReales && tipoDosis && volumen) {
      dosisReales = parseInt(dosisReales, 10)
      volumen = parseFloat(volumen)
      volumenDiluyente = dosisReales * parseFloat(tipoDosis.volumen) - volumen
      volumenDiluyente = round_decimal(volumenDiluyente, 2, state.auth.separadorDec, state.auth.separadorMil)
    }

    yield put(change('formAnalisisExtraccion', 'volumenDiluyente', volumenDiluyente))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchCalcVolumenDiluyente () {
  yield takeLatest(actionTypes.CALC_VOLUMEN_DILUYENTE, calcVolumenDiluyente)
}

export function * abrirModalDilucionAnalisisExtraccion () {
  try {
    const state = yield select(state => state)
    let {dosisReales, volumen, tipoDosis, volumenDiluyente, tipoDiluyente} = yield getFormValues('formAnalisisExtraccion')(state)
    let idAnalisis, idMaquina, idOperario, diluido, dilucionEliminada, observacionesDilucion, observacionesDilucionEliminada, trazabilidad, stockInsuficiente, ultimaModificacion, lotesEscandallo
    if (state.analisisExtracciones.extraccion.data) {
      idAnalisis = state.analisisExtracciones.extraccion.data.idAnalisis
      idMaquina = state.analisisExtracciones.extraccion.data.idMaquina
      idOperario = state.analisisExtracciones.extraccion.data.idOperario
      diluido = state.analisisExtracciones.extraccion.data.diluido
      dilucionEliminada = state.analisisExtracciones.extraccion.data.dilucionEliminada
      observacionesDilucion = state.analisisExtracciones.extraccion.data.observacionesDilucion
      observacionesDilucionEliminada = state.analisisExtracciones.extraccion.data.observacionesDilucionEliminada
      trazabilidad = state.analisisExtracciones.extraccion.data.dilucion ? state.analisisExtracciones.extraccion.data.dilucion.trazabilidad : null
      stockInsuficiente = state.analisisExtracciones.extraccion.data.dilucion ? state.analisisExtracciones.extraccion.data.dilucion.stockInsuficiente : null
      ultimaModificacion = state.analisisExtracciones.extraccion.data.ultimaModificacion ? state.analisisExtracciones.extraccion.data.ultimaModificacion : null
    }

    if (state.auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && !ultimaModificacion) {
      const lotesEscandalloResult = yield call(getLotesEscandalloService,
        {
          diluido,
          idAnalisis: idAnalisis || null,
          volumenDiluyente,
          idTipoDosis: tipoDosis.value,
          idCategoria: categoriasEscandallos.DILUCION
        }, state.auth.token)
      lotesEscandallo = lotesEscandalloResult.lotesEscandallo

      trazabilidad = lotesEscandallo && lotesEscandallo.map((lote, index) => {
        let stock = lote.stock === null ? '0' : ''+ round_decimal(parseFloat(lote.stock), 2, state.auth.separadorDec, state.auth.separadorMil)
        let consumido = lote.consumido === null ? '0' : ''+ round_decimal(parseFloat(lote.consumido), 2, state.auth.separadorDec, state.auth.separadorMil)
        let stockFinal = lote.stockFinal === null ? '0' : ''+ round_decimal(parseFloat(lote.stockFinal), 2, state.auth.separadorDec, state.auth.separadorMil)
        return {...lote, idTrazabilidadFront: index, loteEscandallo: true, stock, consumido, stockFinal, cantidad: consumido}
      })
    }

    if (idMaquina) {
      idMaquina = state.combos.comboMaquina.find((element) => {
        return element.value === idMaquina
      })
    } else {
      let comboMaquina = yield select(state => state.combos.comboMaquina)
      if (comboMaquina[0] && comboMaquina.length === 1) {
        idMaquina = comboMaquina[0]
      }
    }
    if (idOperario) {
      idOperario = state.combos.comboOperarioPredeterminado.find((element) => {
        return element.value === idOperario
      })
    }

    yield put(initialize('ModalDilucionAnalisisExtraccion', {
      numeroDosis: dosisReales,
      volumenExtraccion: volumen,
      volumenDiluyente: volumenDiluyente,
      idTipoDiluyente: tipoDiluyente,
      gramosxLitro: tipoDiluyente ? tipoDiluyente['gr/l'] : '',
      idMaquina: idMaquina,
      idOperario: idOperario,
      diluido: diluido,
      dilucionEliminada: dilucionEliminada,
      observacionesDilucion: observacionesDilucion,
      observacionesDilucionEliminada: observacionesDilucionEliminada,
      trazabilidad: trazabilidad
    }))

    if (state.auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD)) {
      if (stockInsuficiente !== null) {
        yield put(comprobarStockTrazabilidadSuccess({[categoriasEscandallos.ANALISIS_DILUCION]: stockInsuficiente}))
      } else {
        yield put(comprobarStockTrazabilidad(categoriasEscandallos.ANALISIS_DILUCION, tipoDosis.value))
      }
    }

    yield put(abrirModalDilucionAnalisisExtraccionSuccess())
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchAbrirModalDilucionAnalisisExtraccion () {
  yield takeLatest(actionTypes.ABRIR_MODAL_DILUCION_ANALISIS_EXTRACCION, abrirModalDilucionAnalisisExtraccion)
}

export function * guardarModalDilucionAnalisisExtraccion ({data, idAnalisisExtraccion, history, onSuccessCallback, onErrorCallback}) {
  try {
    const state = yield select(state => state)
    let formAnalisisExtraccionData = yield getFormValues('formAnalisisExtraccion')(state)
    let preparedValues = {
        ...formAnalisisExtraccionData,
        tipoDiluyente: data.idTipoDiluyente ? data.idTipoDiluyente : formAnalisisExtraccionData.tipoDiluyente ? formAnalisisExtraccionData.tipoDiluyente : null,
        dilucion: {
          idMaquina: data.idMaquina ? data.idMaquina.value : null,
          idOperario: data.idOperario ? data.idOperario.value : null,
          diluido: data.diluido,
          dilucionEliminada: data.dilucionEliminada,
          observacionesDilucion: data.observacionesDilucion,
          dilucionObservacionesEliminada: data.observacionesDilucionEliminada,
          trazabilidad: data.trazabilidad,
          guardarUltimaModificacion: true
        }
    }
    yield call(submitFormAnalisisExtraccion, {values: preparedValues, idAnalisisExtraccion, history, onSuccessCallback, onErrorCallback})
    yield put(guardarModalDilucionAnalisisExtraccionSuccess())
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchGuardarModalDilucionAnalisisExtraccion () {
  yield takeLatest(actionTypes.GUARDAR_MODAL_DILUCION_ANALISIS_EXTRACCION, guardarModalDilucionAnalisisExtraccion)
}

export function * cerrarModalDilucionAnalisisExtraccion () {
  try {
    yield put(cerrarModalDilucionAnalisisExtraccionSuccess())
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchCerrarModalDilucionAnalisisExtraccion () {
  yield takeLatest(actionTypes.CERRAR_MODAL_DILUCION_ANALISIS_EXTRACCION, cerrarModalDilucionAnalisisExtraccion)
}

export function * racePrepararMaquinaDilucionAnalisisExtraccion ({data, idAnalisisExtraccion, history, onSuccessCallback, onErrorCallback}) {
  const controller = new AbortController();
  const signal = controller.signal;

  data.diluido = true
  const {idAnalisis} = yield race({
    idAnalisis: call(prepararMaquinaDilucionAnalisisExtraccion, {data, idAnalisisExtraccion, history, signal}),
    cancelled: take(actionTypes.CANCELAR_MAQUINA_DILUCION_ANALISIS_EXTRACCION)
  })

  if (!idAnalisis) {
    controller.abort();
    yield put(actualizarEstadoMaquinaDilucionAnalisisExtraccion(''))
    if (onErrorCallback) yield onErrorCallback()
  } else if (onSuccessCallback) {
    yield onSuccessCallback()

    // Si se ha creado un nuevo analisis se redirigira a la pagina de edicion.
    if (!idAnalisisExtraccion && idAnalisis && history) {
      let confirmed = yield call(yesNoModalSaga, {modalType: 'redireccionEdicionAnalisis'})
      if (confirmed) {
        yield put(cerrarModalDilucionAnalisisExtraccionSuccess())
        yield delay(300)
        yield history.push({pathname: 'EditarAnalisisExtraccion/' + idAnalisis, state: { from: history.location.pathname }})
      }
    }
  }
}
export function * watchPrepararMaquinaDilucionAnalisisExtraccion () {
  yield takeLatest(actionTypes.PREPARAR_MAQUINA_DILUCION_ANALISIS_EXTRACCION, racePrepararMaquinaDilucionAnalisisExtraccion)
}

export function * prepararMaquinaDilucionAnalisisExtraccion ({data, idAnalisisExtraccion, history, signal}) {
  try {
    let state = yield select(state => state)

    // Comprobar si es la primera dilucion del día y en caso afirmativo mostrar mensaje de advertencia sobre el 'modo agua'.
    let esPrimeraDilucionDelDia = yield call(comprobarPrimeraDilucionDelDiaService)
    if (esPrimeraDilucionDelDia) {
      yield call(yesNoModalSaga, {modalType: 'comprobarModoAguaDeshabilitado'})
    }

    yield put(actualizarEstadoMaquinaDilucionAnalisisExtraccion(estadoMaquinaDilucion['PROCESANDO_DILUCION']))

    // Preparar datos para la inserción/actualización del analisis.
    let formAnalisisExtraccionData = yield getFormValues('formAnalisisExtraccion')(state)
    let preparedValues = {
      codigoAnalisis: formAnalisisExtraccionData.codigoAnalisis,
      fechaExtraccion: formAnalisisExtraccionData.fechaExtraccion,
      idVerraco: formAnalisisExtraccionData.verraco ? formAnalisisExtraccionData.verraco.idVerraco : formAnalisisExtraccionData.idVerraco ? formAnalisisExtraccionData.idVerraco.value : null,
      idOperarioColecta: formAnalisisExtraccionData.operarioColecta ? formAnalisisExtraccionData.operarioColecta.value : null,
      idOperarioProcesado: formAnalisisExtraccionData.operarioProcesado ? formAnalisisExtraccionData.operarioProcesado.value : null,
      horaExtraccion: formAnalisisExtraccionData.horaExtraccion,
      horaEntradaLaboratorio: formAnalisisExtraccionData.horaEntradaLaboratorio,
      horaInicioProcesado: formAnalisisExtraccionData.horaInicioProcesado,
      horaFinProcesado: formAnalisisExtraccionData.horaFinProcesado,
      volumen: formAnalisisExtraccionData.volumen,
      motilidad: formAnalisisExtraccionData.motilidadTotal,
      calidad: formAnalisisExtraccionData.calidad,
      aglutinacion: formAnalisisExtraccionData.aglutinacion,
      idTipoAnalisis: formAnalisisExtraccionData.tipoAnalisis ? formAnalisisExtraccionData.tipoAnalisis.value : null,
      idRecta: formAnalisisExtraccionData.rectaCalibrado ? formAnalisisExtraccionData.rectaCalibrado.value : null,
      lectura: formAnalisisExtraccionData.lectura,
      motilidadMasal: formAnalisisExtraccionData.motilidadMasal,
      mt: formAnalisisExtraccionData.mt,
      mp: formAnalisisExtraccionData.mp,
      buenos: formAnalisisExtraccionData.camposCalculados ? formAnalisisExtraccionData.camposCalculados.buenos : null,
      totalAcrosomas: formAnalisisExtraccionData.camposCalculados ? formAnalisisExtraccionData.camposCalculados.totalAcrosomas : null,
      faCabezas: formAnalisisExtraccionData.camposCalculados ? formAnalisisExtraccionData.camposCalculados.faCabezas : null,
      faColas: formAnalisisExtraccionData.camposCalculados ? formAnalisisExtraccionData.camposCalculados.faColas : null,
      faGotaProximal: formAnalisisExtraccionData.camposCalculados ? formAnalisisExtraccionData.camposCalculados.faGotaProximal : null,
      faGotaDistal: formAnalisisExtraccionData.camposCalculados ? formAnalisisExtraccionData.camposCalculados.faGotaDistal : null,
      concentracionTotal: formAnalisisExtraccionData.concentracionTotal,
      concentracionUtil: formAnalisisExtraccionData.concentracionUtil,
      concentracionXDosisAplicada: formAnalisisExtraccionData.concentracionXDosisAplicada,
      concentracionXDosisUtil: formAnalisisExtraccionData.concentracionXDosisUtil,
      idTipoDosis: formAnalisisExtraccionData.tipoDosis ? formAnalisisExtraccionData.tipoDosis.value : null,
      idTipoDiluyente: data.idTipoDiluyente ? data.idTipoDiluyente.value : formAnalisisExtraccionData.tipoDiluyente ? formAnalisisExtraccionData.tipoDiluyente.value : null,
      volumenDiluyente: formAnalisisExtraccionData.volumenDiluyente,
      eyaculadoEliminado: formAnalisisExtraccionData.eyaculadoEliminado,
      dosisTeoricas: formAnalisisExtraccionData.dosisTeoricas,
      dosisReales: formAnalisisExtraccionData.dosisReales,
      mediaUltimaDosis: formAnalisisExtraccionData.mediaUltimaDosis,
      observaciones: formAnalisisExtraccionData.observaciones,
      dilucion: {
        idMaquina: data.idMaquina ? data.idMaquina.value : null,
        idOperario: data.idOperario ? data.idOperario.value : null,
        diluido: data.diluido,
        dilucionEliminada: data.dilucionEliminada,
        observaciones: data.observaciones,
        trazabilidad: data.trazabilidad,
        guardarUltimaModificacion: true
      },
      trazabilidad: formAnalisisExtraccionData.trazabilidad || null,
      observacionesEyaculadoEliminado: formAnalisisExtraccionData.observacionesEyaculadoEliminado ? formAnalisisExtraccionData.observacionesEyaculadoEliminado.value : null,
    }

    // Añadir el parámetro que permitirá actualizar el estado activo si el usuario acepto el popup correspondiente.
    if (state.analisisExtracciones.extraccion.cambioEstado) preparedValues.cambioEstado = state.analisisExtracciones.extraccion.cambioEstado

    if (idAnalisisExtraccion) {
      preparedValues.seguimiento = formAnalisisExtraccionData.seguimiento
      preparedValues.idAnalisis = idAnalisisExtraccion
    }

    let {maquinaPreparada, idAnalisis, conexionVPN, error, maquinaEnUso} = yield call(prepararMaquinaDilucionAnalisisExtraccionService, preparedValues, state.auth.token, signal)
    let codigoEstadoMaquina = maquinaPreparada ? estadoMaquinaDilucion['PREPARADA'] : conexionVPN === false ? estadoMaquinaDilucion['CONEXION_VPN_NO_ESTABLECIDA'] : maquinaEnUso ? estadoMaquinaDilucion['EN_USO'] : estadoMaquinaDilucion['NO_PREPARADA']
    let mensajeEstadoMaquina
    let confirmed = false

    if (codigoEstadoMaquina === estadoMaquinaDilucion['NO_PREPARADA'] || codigoEstadoMaquina === estadoMaquinaDilucion['EN_USO'] || codigoEstadoMaquina === estadoMaquinaDilucion['CONEXION_VPN_NO_ESTABLECIDA']) {
      if (error && error.codigo) {
        codigoEstadoMaquina = error.codigo
      }
      yield put(actualizarEstadoMaquinaDilucionAnalisisExtraccion(codigoEstadoMaquina))
      mensajeEstadoMaquina = i18n.t('COMUN.ESTADO_MAQUINA.' + translateEstadoMaquinaDilucion[codigoEstadoMaquina])
      confirmed = yield call(yesNoModalSaga, {modalType: 'dynamicMessage', message: mensajeEstadoMaquina})
      yield put(change('ModalDilucionAnalisisExtraccion', 'diluido', false))
    } else {
      codigoEstadoMaquina = null
      yield put(change('ModalDilucionAnalisisExtraccion', 'diluido', true))
      yield put(actualizarEstadoMaquinaDilucionAnalisisExtraccion(estadoMaquinaDilucion['DILUYENDO']))

      // while ((!codigoEstadoMaquina || codigoEstadoMaquina === estadoMaquinaDilucion['DILUYENDO'] || codigoEstadoMaquina === estadoMaquinaDilucion['PREPARADA_PARA_DILUIR'])) {
      //   codigoEstadoMaquina = yield call(comprobarEstadoMaquinaDilucionAnalisisExtraccionService, data.idMaquina.value, idAnalisis, signal)
      //   codigoEstadoMaquina = codigoEstadoMaquina.codigo ? codigoEstadoMaquina.codigo : ''
      //   yield put(actualizarEstadoMaquinaDilucionAnalisisExtraccion(codigoEstadoMaquina))
      //
      //   mensajeEstadoMaquina = i18n.t('COMUN.ESTADO_MAQUINA.' + translateEstadoMaquinaDilucion[codigoEstadoMaquina])
      //   if (codigoEstadoMaquina === estadoMaquinaDilucion['EN_ESPERA_RETIRAR_RECIPIENTE']) {
      //     yield call(yesNoModalSaga, {
      //       modalType: 'dynamicMessage',
      //       message: i18n.t('COMUN.ESTADO_MAQUINA.DILUCION_COMPLETADA'),
      //       subMessage: mensajeEstadoMaquina
      //     })
      //
      //     // Una vez completado el proceso sin errores se cerrara el formulario y se mostrar el listado de análisis.
      //     if (history) {
      //       yield history.push('/AnalisisExtracciones')
      //     }
      //     yield put(guardarModalDilucionAnalisisExtraccionSuccess())
      //   } else if (codigoEstadoMaquina !== estadoMaquinaDilucion['DILUYENDO'] && codigoEstadoMaquina !== estadoMaquinaDilucion['PREPARADA_PARA_DILUIR']) {
      //     confirmed = yield call(yesNoModalSaga, {
      //       modalType: 'dynamicMessage',
      //       message: i18n.t('COMUN.ESTADO_MAQUINA.DILUCION_NO_COMPLETADA'),
      //       subMessage: mensajeEstadoMaquina
      //     })
      //
      //     if (confirmed && !idAnalisisExtraccion && idAnalisis && history) {
      //       yield history.push('EditarAnalisisExtraccion/' + idAnalisis)
      //     }
      //   }
      //   yield delay(1000);
      // }
      //
      // if (codigoEstadoMaquina !== estadoMaquinaDilucion['EN_ESPERA_RETIRAR_RECIPIENTE']) {
      //   yield put(actualizarEstadoMaquinaDilucionAnalisisExtraccion(estadoMaquinaDilucion['FINALIZADO']))
      // }
    }
    return idAnalisis
  } catch (error) {
  } finally {
    // console.log('finally prepararMaquinaDilucion...')
  }
}

export function * pdfEtiquetaExtraccion ({idAnalisisExtraccion}) {
  try {
    const state = yield select(state => state)
    let formAnalisisExtraccionData = yield getFormValues('formAnalisisExtraccion')(state)
    let dosisReales, volumenTipoDosis, codVerraco, nombreRaza, fechaExtraccion, volumenDiluyente

    dosisReales = formAnalisisExtraccionData.dosisReales || 0
    volumenTipoDosis = (formAnalisisExtraccionData.tipoDosis && formAnalisisExtraccionData.tipoDosis.volumen) || 0
    codVerraco = (state.analisisExtracciones.extraccion.verraco && state.analisisExtracciones.extraccion.verraco.codVerraco) || ''
    nombreRaza = (state.analisisExtracciones.extraccion.verraco && state.analisisExtracciones.extraccion.verraco.nombreRaza) || ''
    fechaExtraccion = formAnalisisExtraccionData.fechaExtraccion || ''
    volumenDiluyente = (formAnalisisExtraccionData.volumenDiluyente && formAnalisisExtraccionData.volumenDiluyente) || 0

    const dataEtiquetaPdf = yield call(getPDFEtiqueta, {dosisReales, volumenTipoDosis, codVerraco, nombreRaza, fechaExtraccion, volumenDiluyente, idAnalisisExtraccion})
    let href = 'data:application/pdf;base64,' + encodeURIComponent(dataEtiquetaPdf.pdf)
    let name = i18n.t('ANALISIS_EXTRACCIONES.FORM.BUTTONS.PDF_ETIQUETA') + ".pdf"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchPdfEtiquetaExtraccion () {
  yield takeLatest(actionTypes.PDF_ETIQUETA_EXTRACCION, pdfEtiquetaExtraccion)
}

export function * imprimirEtiquetaPDF ({idAnalisisExtraccion}) {
  try {
    const state = yield select(state => state)
    let formAnalisisExtraccionData = yield getFormValues('formAnalisisExtraccion')(state)
    let dosisReales, volumenTipoDosis, codVerraco, nombreRaza, fechaExtraccion, volumenDiluyente

    dosisReales = formAnalisisExtraccionData.dosisReales || 0
    volumenTipoDosis = (formAnalisisExtraccionData.tipoDosis && formAnalisisExtraccionData.tipoDosis.volumen) || 0
    codVerraco = (state.analisisExtracciones.extraccion.verraco && state.analisisExtracciones.extraccion.verraco.codVerraco) || ''
    nombreRaza = (state.analisisExtracciones.extraccion.verraco && state.analisisExtracciones.extraccion.verraco.nombreRaza) || ''
    fechaExtraccion = formAnalisisExtraccionData.fechaExtraccion || ''
    volumenDiluyente = (formAnalisisExtraccionData.volumenDiluyente && formAnalisisExtraccionData.volumenDiluyente) || 0

    const etiquetaPdf = yield call(getImprimirEtiquetaPDF, {dosisReales, volumenTipoDosis, codVerraco, nombreRaza, fechaExtraccion, volumenDiluyente, idAnalisisExtraccion})
    let selected_device;
    let devices = [];
    window.BrowserPrint.getDefaultDevice("printer", function(device){
      selected_device = device;
      devices.push(device);
      window.BrowserPrint.getLocalDevices(function(device_list){
        for(let i = 0; i < device_list.length; i++){
          let device = device_list[i];
          if(!selected_device || device.uid != selected_device.uid){
            devices.push(device);
          }
        }
        selected_device.send(etiquetaPdf.pdf, undefined, undefined)
      },
      function(){console.log("Error getting local devices")},"printer");
    }, function(error){
      console.log(error);
    })
   }  catch (error) {
    console.log(error)
  }
  finally {
  }
}

export function * watchImprimirEtiquetaPDF () {
  yield takeLatest(actionTypes.PDF_ETIQUETA_PDF, imprimirEtiquetaPDF)
}
