import React, { Component } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { required } from '../../util/validationFunctions'
import Button from '../comun/button/Button'
import ButtonChangeRecord from '../comun/button/ButtonChangeRecord'
import ButtonLockable from '../comun/button/ButtonLockable'
import InputText from '../comun/form/InputText'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputSelect from '../comun/form/InputSelect'
import InputNumerical from '../comun/form/InputNumerical'
import InputTextArea from '../comun/form/InputTextArea'
import './Conectividad.scss'
import {permisos as permisosConstant} from '../../constants/permisos'

class ModalConectividad extends Component {
  submitNuevaConectividad (values) {
    return new Promise((resolve, reject) => {
      values.IP = values.ipParte1 + '.' + values.ipParte2 + '.' + values.ipParte3 + '.' + values.ipParte4
      if (Object.keys(this.props.data).length > 0){
        this.props.actions.editarConectividad(values, resolve, reject)
      } else {
        this.props.actions.submitNuevaConectividad(values, resolve, reject)
      }
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  componentDidMount () {
    let valors = {activo: true}
    this.props.initialize(valors)
  }

  guardarYnuevoConectividad(values){
    return new Promise((resolve, reject) => {
      values.IP = values.ipParte1 + '.' + values.ipParte2 + '.' + values.ipParte3 + '.' + values.ipParte4
      this.props.actions.guardarYnuevoConectividad(values, resolve, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  duplicar(values){
    return new Promise((resolve, reject) => {
      this.props.duplicar(values, resolve, reject)
    })
      .then(() => { console.log('completado') })
      .catch(() => { console.log('error') })
  }

  closeModalConectividad () {
    this.props.actions.closeModalConectividad()
    let valors = {activo: true}
    this.props.initialize(valors)
  }

  render () {
    const {
      t, handleSubmit, submitting, listConectividad, showModalConectividad, data, actions: {recuperarDatosModalConectividad}
      } = this.props

    let comboTipoPerifericoConectividad = this.props.combos.comboTipoPerifericoConectividad
    comboTipoPerifericoConectividad = comboTipoPerifericoConectividad.map((tipoPeriferico) => {
      return {...tipoPeriferico, label: t(tipoPeriferico.label)}
    })

    return (
      <Modal show={showModalConectividad} onHide={() => this.closeModalConectividad()} aria-labelledby="contained-modal-title-lg" backdrop="static">
      <form className="form-conectividad">
        <Modal.Header closeButton={!submitting}>
          <Modal.Title>
          {
            Object.keys(data).length > 0 ? (
              t('CONECTIVIDAD.MODAL.TITULO_EDITAR')
            ) : (
              t('CONECTIVIDAD.MODAL.TITULO_NUEVO')
            )
          }
          </Modal.Title>
          {!submitting && data && data.idMaquina && (
            <ButtonChangeRecord list={listConectividad} idElemento="idMaquina" currentId={data.idMaquina} getNextRecord={recuperarDatosModalConectividad} />
          )}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12}><h3>{t('CONECTIVIDAD.MODAL.DATOS_PERIFERICO')}</h3></Col>
            <Field
              id="idTipoMaquina"
              name="idTipoMaquina"
              colSm={4}
              component={InputSelect}
              options={comboTipoPerifericoConectividad}
              valueKey="value"
              labelKey="label"
              controlLabel={t('CONECTIVIDAD.MODAL.TIPO_PERIFERICO') + '*'}
              customClass="nombre-conectividad"
              validate={required}
              isClearable={false}
            />
            <Field
              id="nombre"
              name="nombre"
              colSm={4}
              component={InputText}
              controlLabel={t('CONECTIVIDAD.MODAL.NOMBRE') + '*'}
              claseActivo="nombre-conectividad"
              validate={required}
              maxLength={true}
              valorMaxLength={100}
            />
            <Field
              id="activoConectividad"
              name="activo"
              colSm={4}
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('CONECTIVIDAD.MODAL.ACTIVO')}
              customClass="check-activo-conectividad"
            />
            <Col sm={12}>
              <h3>{t('CONECTIVIDAD.MODAL.DATOS_CONEXION')}</h3>
              <p style={{fontWeight: 'bold'}}>{t('CONECTIVIDAD.MODAL.DIRECCION_IP')}*</p>
            </Col>
            <Field
              id="ipParte1"
              name="ipParte1"
              colSm={3}
              component={InputNumerical}
              numDecimales={0}
              validate={required}
            />
            <Field
              id="ipParte2"
              name="ipParte2"
              colSm={3}
              component={InputNumerical}
              numDecimales={0}
              validate={required}
            />
            <Field
              id="ipParte3"
              name="ipParte3"
              colSm={3}
              component={InputNumerical}
              numDecimales={0}
              validate={required}
            />
            <Field
              id="ipParte4"
              name="ipParte4"
              colSm={3}
              component={InputNumerical}
              numDecimales={0}
              validate={required}
            />
            <Field
              colSm={12}
              id="observaciones"
              name="observaciones"
              component={InputTextArea}
              componentClass="textarea"
              controlLabel={t('CONECTIVIDAD.MODAL.OBSERVACIONES')}
              customClass='observaciones'
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {
            Object.keys(data).length > 0 && (
              <Button
                type="button"
                className="btn btn-white btn-sm"
                disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.CONECTIVIDAD] < 2) || submitting}
                onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.CONECTIVIDAD] > 1) && !submitting ? handleSubmit(this.duplicar.bind(this)) : undefined}
              >
                {t('CONECTIVIDAD.MODAL.DUPLICAR')}
              </Button>
            )
          }
          {
            Object.keys(data).length === 0 && (
              <ButtonLockable
                processing={submitting}
                hasIcon={true}
                showLoaderOnClick={true}
                type="button"
                className="btn btn-primary btn-sm"
                disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.CONECTIVIDAD] < 2) || submitting}
                onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.CONECTIVIDAD] > 1) && !submitting) ? handleSubmit(this.guardarYnuevoConectividad.bind(this)) : () => {}}
              >
                {t('CONECTIVIDAD.MODAL.GUARDAR_Y_NUEVO')}
              </ButtonLockable>
            )
          }
          <ButtonLockable
            processing={submitting}
            hasIcon={true}
            showLoaderOnClick={true}
            type="button"
            className="btn btn-primary btn-sm"
            disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.CONECTIVIDAD] < 2) || submitting}
            onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.CONECTIVIDAD] > 1) && !submitting) ? handleSubmit(this.submitNuevaConectividad.bind(this)) : () => {}}
          >
            {t('CONECTIVIDAD.MODAL.GUARDAR')}
          </ButtonLockable>
          <Button
            type="button"
            className={"btn btn-white btn-sm" + (submitting ? ' disabled' : '')}
            onClick={() => !submitting && this.closeModalConectividad()}
          >
            {t('CONECTIVIDAD.MODAL.CANCELAR')}
          </Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalConectividad',
})(ModalConectividad))