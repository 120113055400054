import actionTypes from '../../constants/actions/grupoVerraco/GrupoVerraco'

export function fetchGrupoVerraco () {
  return {
    type: actionTypes.FETCH_GRUPO_VERRACO
  }
}

export function fetchGrupoVerracoSuccess (list) {
  return {
    type: actionTypes.FETCH_GRUPO_VERRACO_SUCCESS,
    list
  }
}

export function submitNuevoGrupoVerraco (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.SUBMIT_NUEVO_GRUPO_VERRACO,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function submitNuevoGrupoVerracoSuccess (insercionGrupoVerraco) {
  return {
    type: actionTypes.SUBMIT_NUEVO_GRUPO_VERRACO_SUCCESS,
    insercionGrupoVerraco
  }
}

export function recuperarDatosModalGrupoVerraco (idGrupoVerraco) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_GRUPO_VERRACO,
    idGrupoVerraco
  }
}

export function recuperarDatosModalGrupoVerracoSuccess (data) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_GRUPO_VERRACO_SUCCESS,
    data
  }
}

export function abrirModalVacioGrupoVerraco () {
  return {
    type: actionTypes.ABRIR_MODAL_VACIO_GRUPO_VERRACO
  }
}

export function editarGrupoVerraco (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.EDITAR_GRUPO_VERRACO,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function editarGrupoVerracoSuccess (edicionGrupoVerraco) {
  return {
    type: actionTypes.EDITAR_GRUPO_VERRACO_SUCCESS,
    edicionGrupoVerraco
  }
}

export function duplicarDatosModalGrupoVerraco (idGrupoVerraco, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_GRUPO_VERRACO,
    idGrupoVerraco,
    onSuccessCallback,
    onErrorCallback
  }
}

export function duplicarDatosModalGrupoVerracoSuccess (dataDuplicar) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_GRUPO_VERRACO_SUCCESS,
    dataDuplicar
  }
}

export function cambiarEstadoGrupoVerraco (idGrupoVerraco) {
  return {
    type: actionTypes.CAMBIAR_ESTADO_GRUPO_VERRACO,
    idGrupoVerraco
  }
}

export function cambiarEstadoGrupoVerracoSuccess (dataEstado) {
  return {
    type: actionTypes.CAMBIAR_ESTADO_GRUPO_VERRACO_SUCCESS,
    dataEstado
  }
}

export function onDeleteGrupoVerraco (idGrupoVerraco) {
  return {
    type: actionTypes.ON_DELETE_GRUPO_VERRACO,
    idGrupoVerraco
  }
}

export function onDeleteGrupoVerracoSuccess (dataDelete) {
  return {
    type: actionTypes.ON_DELETE_GRUPO_VERRACO_SUCCESS,
    dataDelete
  }
}

export function fetchFiltrarGrupoVerraco (values) {
  return {
    type: actionTypes.FETCH_FILTRAR_GRUPO_VERRACO,
    values
  }
}

export function fetchFiltrarGrupoVerracoSuccess (filtros) {
  return {
    type: actionTypes.FETCH_FILTRAR_GRUPO_VERRACO_SUCCESS,
    filtros
  }
}

export function seleccionarTodosGrupoVerraco(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_GRUPO_VERRACO
  }
}

export function deSeleccionarTodosGrupoVerraco(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_GRUPO_VERRACO
  }
}

export function cambiarEstadoSeleccionGrupoVerraco(list){
  return {
    type: actionTypes.CAMBIAR_ESTADO_SELECCION_GRUPO_VERRACO,
    list
  }
}

export function deleteSeleccionGrupoVerraco(list){
  return {
    type: actionTypes.DELETE_SELECCION_GRUPO_VERRACO,
    list
  }
}

export function crearCsvGrupoVerraco(list){
  return {
    type: actionTypes.CREATE_CSV_GRUPO_VERRACO,
    list
  }
}

export function crearCsvGrupoVerracoSuccess(datosGrupoVerracoExcel){
  return {
    type: actionTypes.CREATE_CSV_GRUPO_VERRACO_SUCCESS,
    datosGrupoVerracoExcel
  }
}

export function imprimirPdfGrupoVerraco(list){
  return {
    type: actionTypes.IMPRIMIR_PDF_GRUPO_VERRACO,
    list
  }
}

export function imprimirPdfGrupoVerracoSuccess(datosGrupoVerracoPdf){
  return {
    type: actionTypes.IMPRIMIR_PDF_GRUPO_VERRACO_SUCCESS,
    datosGrupoVerracoPdf
  }
}


export function guardarYnuevoGrupoVerraco(values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.GUARDAR_Y_NUEVO_GRUPO_VERRACO,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function seleccionarTodosModalGrupoVerraco(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_MODAL_GRUPO_VERRACO
  }
}

export function deSeleccionarTodosModalGrupoVerraco(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_MODAL_GRUPO_VERRACO
  }
}

export function abrirModalSeleccionarVerraco () {
  return {
    type: actionTypes.ABRIR_MODAL_SELECCIONAR_VERRACO 
  }
}

export function cerrarModalSeleccionarVerraco () {
  return {
    type: actionTypes.CERRAR_MODAL_SELECCIONAR_VERRACO
  }
}

export function fetchVerracosGrupoVerraco (verracosAnadidos){
  return {
    type: actionTypes.FETCH_VERRACOS_GRUPO_VERRACO,
    verracosAnadidos
  }
}

export function fetchVerracosGrupoVerracoSuccess (listVerracosEnGrupo){
  return {
    type: actionTypes.FETCH_VERRACOS_GRUPO_VERRACO_SUCCESS,
    listVerracosEnGrupo
  }
}

export function seleccionarTodosModalVerracoGrupoVerraco(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_MODAL_VERRACO_GRUPO_VERRACO
  }
}

export function deSeleccionarTodosModalVerracoGrupoVerraco(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_MODAL_VERRACO_GRUPO_VERRACO
  }
}

export function anadirVerraco (listadoAnadir) {
  return {
    type: actionTypes.ANADIR_VERRACO,
    listadoAnadir
  }
}

export function anadirVerracoSuccess (verracosAnadidos) {
  return {
    type: actionTypes.ANADIR_VERRACO_SUCCESS,
    verracosAnadidos
  }
}

export function onDeleteVerracoAnadido (idVerraco) {
  return {
    type: actionTypes.ON_DELETE_VERRACO_ANADIDO,
    idVerraco
  }
}

export function onDeleteVerracoAnadidoSuccess (verracosAnadidos) {
  return {
    type: actionTypes.ON_DELETE_VERRACO_ANADIDO_SUCCESS,
    verracosAnadidos
  }
}

export function deleteSeleccionVerracosGrupoVerraco (listVerracosQuitar) {
  return {
    type: actionTypes.DELETE_SELECCION_VERRACOS_GRUPO_VERRACO,
    listVerracosQuitar
  }
}

export function fetchFiltrarVerracoModalVerraco (values) {
  return {
    type: actionTypes.FETCH_FILTRAR_VERRACO_MODAL_VERRACO,
    values
  }
}

export function fetchFiltrarVerracoModalVerracoSuccess (filtrosModal) {
  return {
    type: actionTypes.FETCH_FILTRAR_VERRACO_MODAL_VERRACO_SUCCESS,
    filtrosModal
  }
}

export default{
  fetchGrupoVerraco,
  fetchGrupoVerracoSuccess,
  submitNuevoGrupoVerraco,
  submitNuevoGrupoVerracoSuccess,
  recuperarDatosModalGrupoVerraco,
  recuperarDatosModalGrupoVerracoSuccess,
  abrirModalVacioGrupoVerraco,
  editarGrupoVerraco,
  editarGrupoVerracoSuccess,
  duplicarDatosModalGrupoVerraco,
  duplicarDatosModalGrupoVerracoSuccess,
  cambiarEstadoGrupoVerraco,
  cambiarEstadoGrupoVerracoSuccess,
  onDeleteGrupoVerraco,
  onDeleteGrupoVerracoSuccess,
  fetchFiltrarGrupoVerraco,
  seleccionarTodosGrupoVerraco,
  deSeleccionarTodosGrupoVerraco,
  cambiarEstadoSeleccionGrupoVerraco,
  deleteSeleccionGrupoVerraco,
  crearCsvGrupoVerraco,
  crearCsvGrupoVerracoSuccess,
  guardarYnuevoGrupoVerraco,
  seleccionarTodosModalGrupoVerraco,
  deSeleccionarTodosModalGrupoVerraco,
  abrirModalSeleccionarVerraco,
  cerrarModalSeleccionarVerraco,
  fetchVerracosGrupoVerraco,
  fetchVerracosGrupoVerracoSuccess,
  seleccionarTodosModalVerracoGrupoVerraco,
  deSeleccionarTodosModalVerracoGrupoVerraco,
  anadirVerraco,
  anadirVerracoSuccess,
  onDeleteVerracoAnadido,
  onDeleteVerracoAnadidoSuccess,
  deleteSeleccionVerracosGrupoVerraco,
  fetchFiltrarVerracoModalVerraco,
  fetchFiltrarVerracoModalVerracoSuccess,
  imprimirPdfGrupoVerraco,
  imprimirPdfGrupoVerracoSuccess
}