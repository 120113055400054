export const causaEliminado = {
  GENETICA: 1,
  PRODUCTIVIDAD: 2,
  CALIDAD_SEMINAL: 3,
  COJERAS: 4,
  MUERTE_SUBITA: 5,
  ENFERMEDAD: 6,
  OTRAS: 7
}

export const translateCausaEliminado = {
  1: 'GENETICA',
  2: 'PRODUCTIVIDAD',
  3: 'CALIDAD_SEMINAL',
  4: 'COJERAS',
  5: 'MUERTE_SUBITA',
  6: 'ENFERMEDAD',
  7: 'OTRAS'
}