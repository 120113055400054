import React, { Component } from 'react'
import {reduxForm, Field, Fields} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import InputSelect from '../comun/form/InputSelect'
import InputRangeDatePickerDynamicNames from '../comun/form/InputRangeDatePickerDynamicNames'
import { Launch, Print, FilterList } from '@material-ui/icons'
import {date_formatter, date_parser, dateTimeZones} from '../../util/formatFunctions'

class FiltrosEnvasado extends Component {
   constructor () {
    super()
    this.state = {
      openFiltros: false,
      fitrosSeleccionados: []
    }
  }

  fetchFiltrarEnvasado(values){
    values.desde = values.fechaEntrada
    values.hasta = values.fechaSalida
    this.props.actions.fetchFiltrarEnvasado(values)
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  componentDidMount () {
    let fechaActual = date_parser(dateTimeZones(this.props.auth.zonaHorariaString), this.props.auth.formaFechaHora)
    let estadoLabel = this.props.t(`COMUN.COMBOS.ESTADO_ENVASADO.${this.props.combos.comboEnvasado[1].label}`)
    this.props.initialize({
      estado: {...this.props.combos.comboEnvasado[1], label: estadoLabel},
      fechaEntrada: fechaActual
    })
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.datosExcelEnvasado !== prevProps.datosExcelEnvasado) {
      if (this.props.datosExcelEnvasado.excel && this.props.datosExcelEnvasado.excel !== null){
        let pdf = this.props.datosExcelEnvasado.excel
        let element = document.createElement('a')
        // element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('href', 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.EXTRACCIONES.ENVASADO') + ".xlsx")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
    if (this.props.datosPdfEnvasado !== prevProps.datosPdfEnvasado) {
      if (this.props.datosPdfEnvasado.pdf && this.props.datosPdfEnvasado.pdf !== null){
        let pdf = this.props.datosPdfEnvasado.pdf
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.EXTRACCIONES.ENVASADO') + ".pdf")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
  }

  crearCsvEnvasado(){
    this.props.actions.crearCsvEnvasado(this.props.filtros)
  }

  imprimirPdfEnvasado(){
    this.props.actions.imprimirPdfEnvasado(this.props.filtros)
  }

  quitarFiltros (value) {
    let values = this.props.filtros
    values[value] = null
    if (value === 'fechaEntrada') {
      values.desde = null
    }
    if (value === 'fechaSalida') {
      values.hasta = null
    }
    this.props.actions.fetchFiltrarEnvasado(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }

  render () {
  const {
    t, handleSubmit, filtros
  } = this.props
    const tKey = 'EXTRACCIONES.ENVASADO.'

  let comboEnvasado = this.props.combos.comboEnvasado
    comboEnvasado = comboEnvasado.map((envasado) => {
    return {...envasado, label: t(`COMUN.COMBOS.ESTADO_ENVASADO.${envasado.label}`)}
  })

  let date1
  let date2
  let partes1
  let partes2
  return (
      <div>
        <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <form className="custom-form">
            <Field
            colSm={2}
            id="estado"
            name="estado"
            controlLabel={t('EXTRACCIONES.ENVASADO.FILTROS.ESTADO_ENVASADO')}
            component={InputSelect}
            options={comboEnvasado}
            valueKey="value"
            labelKey="label"
            customClass='select-estado-envasado'
            onInputChange={handleSubmit(this.fetchFiltrarEnvasado.bind(this))}
          />
          <Fields
            names={['fechaEntrada', 'fechaSalida']}
            component={InputRangeDatePickerDynamicNames}
            colSm={4}
            nameFrom="fechaEntrada"
            nameTo="fechaSalida"
            controlLabel={t('EXTRACCIONES.ENVASADO.FILTROS.DESDE_HASTA')}
            onInputChange={handleSubmit(this.fetchFiltrarEnvasado.bind(this))}
          />
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(filtros).length > 0 && (
                    filtros.estado ? (
                      <span className="badge-dark">{t(tKey + 'FILTROS.ESTADO_ENVASADO') + ': ' + filtros.estado.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('estado')}> x </button></span>
                    ) : null
                  )
                }
                {
                  Object.keys(filtros).length > 0 && (
                    (filtros.fechaEntrada && filtros.fechaSalida && filtros.fechaEntrada !== null && filtros.fechaSalida !== null) ? (
                      <span className="badge-dark">{t(tKey + 'FILTROS.DESDE_HASTA') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtros.fechaEntrada, this.props.auth.formaFechaHora, false) + ' - ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtros.fechaSalida, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaSalida')}> x </button></span>
                    ) : (filtros.fechaEntrada && filtros.fechaEntrada !== null) ? (
                      <span className="badge-dark">{t(tKey + 'FILTROS.DESDE_HASTA') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtros.fechaEntrada, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaEntrada')}> x </button></span>
                    ) : (filtros.fechaSalida && filtros.fechaSalida !== null) ? (
                      <span className="badge-dark">{t(tKey + 'FILTROS.DESDE_HASTA') + ' ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtros.fechaSalida, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaSalida')}> x </button></span>
                    ) : null
                  )
                }
              </div>
            </div>
          </form>
        </div>
        <Row className="acciones-tabla-filtros">
          <Col sm={6}>
            <div className="buttons-flex">
              <div className="button-group buttons-min">
                <button type="button" className="btn btn-icono" onClick={(e) => this.crearCsvEnvasado()}>
                  <Launch></Launch> {t('TITULOS_BOTONES.EXPORTAR')}
                </button>
                <button type="button" className="btn btn-icono" onClick={(e) => this.imprimirPdfEnvasado()}>
                  <Print></Print> {t('TITULOS_BOTONES.IMPRIMIR')}
                </button>
              </div>
            </div>
          </Col>
          <Col sm={6} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'} onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t('TITULOS_BOTONES.FILTROS')}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosEnvasado',
  initialValues: {
    fechaEntrada: new Date()
  }
})(FiltrosEnvasado))
