import React, { Component } from 'react'
import { Modal, Row } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import { required } from '../../util/validationFunctions'
import Button from '../comun/button/Button'
import ButtonChangeRecord from '../comun/button/ButtonChangeRecord'
import ButtonLockable from '../comun/button/ButtonLockable'
import InputText from '../comun/form/InputText'
import InputSelect from '../comun/form/InputSelect'
import InputTextArea from '../comun/form/InputTextArea'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputNumerical from '../comun/form/InputNumerical'
import './Producto.scss'
import FormSubtitle from '../comun/form/FormSubtitle'
import {permisos as permisosConstant} from '../../constants/permisos'
import {modulos as modulosConstant} from '../../constants/modulos'

class ModalProducto extends Component {
  constructor () {
    super()
    this.state = {
      mostrarEnviarAviso: false,
    }
  }

  componentDidMount () {
    this.props.change('activo', true)
    this.props.actions.comboUsuariosCentro()
  }

  componentDidUpdate (prevProps) {
    if (Object.keys(this.props.dataProductos).length > 0) {
      if (this.props.dataProductos !== prevProps.dataProductos) {
        if (this.props.dataProductos.producto.avisoStockMinimo === true) {
          this.setState({mostrarEnviarAviso: true})
        } else {
          this.setState({mostrarEnviarAviso: false})
        }
      }
    }
  }

  closeModalProducto () {
    this.props.actions.closeModalProducto()
    this.setState({mostrarEnviarAviso: false})
  }

  submitNuevoProducto (values) {
    return new Promise((resolve, reject) => {
      if (Object.keys(this.props.dataProductos).length > 0){
        this.props.actions.editarProducto(values, resolve, reject)
      } else {
        this.props.actions.submitNuevoProducto(values, resolve, reject)
      }
      this.setState({mostrarEnviarAviso: false})
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  guardarYnuevoProducto(values){
    return new Promise((resolve, reject) => {
      this.props.actions.guardarYnuevoProducto(values, resolve, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  cambiarAviso (value) {
    if (value === true) {
      this.setState({mostrarEnviarAviso: true})
    } else {
      this.setState({mostrarEnviarAviso: false})
      this.props.change('usuarioAvisoStockMinimo','')
    }
  }

  render () {
    const comboUnidadesMedia = [
      { value: 1, label: this.props.t('COMUN.COMBOS.UNIDAD_MEDIDA.UNIDADES')},
      { value: 2, label: this.props.t('COMUN.COMBOS.UNIDAD_MEDIDA.LITROS')}
    ]

    const {
      t, handleSubmit, submitting, showModalProducto, dataProductos, listEntrada, listProducto,
      actions: {recuperarDatosModalProducto, closeModalProducto},
      combos: {comboUsuariosCentro}
    } = this.props

    const table = {
      id: 'entradaTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      columns: [
        {id: 'lote', name: t('ALMACEN.PRODUCTO.MODAL.TABLA.NUM_LOTE')},
        {id: 'cantidad', name: t('ALMACEN.PRODUCTO.MODAL.TABLA.CANTIDAD')},
        {id: 'fecha', name: t('ALMACEN.PRODUCTO.MODAL.TABLA.FECHA_ENTRADA'), type: 'fecha'},
        {id: 'fechaCaducidad', name: t('ALMACEN.PRODUCTO.MODAL.TABLA.FECHA_CADUCIDAD'), type: 'fecha'},
        {id: 'numeroAlbaran', name: t('ALMACEN.PRODUCTO.MODAL.TABLA.NUM_ALBARAN')},
        {id: 'proveedor', name: t('ALMACEN.PRODUCTO.MODAL.TABLA.PROVEEDOR')},
        {id: 'stock', name: t('ALMACEN.PRODUCTO.MODAL.TABLA.STOCK')},
        {id: 'acabado', name: t('ALMACEN.PRODUCTO.MODAL.TABLA.ACABADO')},
      ],
      rows: Object.keys(this.props.dataProductos).length > 0 ? (this.props.dataProductos.entradas) : ( Object.keys(this.props.dataDuplicarProductos).length > 0 ? this.props.dataDuplicarProductos.entradas : [])
    }

    const tableAjusteStock = {
      id: 'ajusteStockTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      columns: [
        {id: 'lote', name: t('ALMACEN.PRODUCTO.AJUSTE_STOCK.LOTE')},
        {id: 'fechaAjuste', name: t('ALMACEN.PRODUCTO.AJUSTE_STOCK.FECHA'), type: 'fecha'},
        {id: 'stockTeorico', name: t('ALMACEN.PRODUCTO.AJUSTE_STOCK.STOCK_INICIAL'), type: 'numero'},
        {id: 'stockRealAnterior', name: t('ALMACEN.PRODUCTO.AJUSTE_STOCK.STOCK_MODIFICAR'), type: 'numero'},
        {id: 'stock', name: t('ALMACEN.PRODUCTO.AJUSTE_STOCK.STOCK_DEFINITIVO'), type: 'numero'},
        {id: 'observaciones', name: t('ALMACEN.PRODUCTO.AJUSTE_STOCK.OBSERVACIONES')},
      ],
      rows: Object.keys(this.props.dataProductos).length > 0 ? (this.props.dataProductos.ajusteStock) : ( Object.keys(this.props.dataDuplicarProductos).length > 0 ? this.props.dataDuplicarProductos.ajusteStock : [])
    }

    return (
      <Modal show={showModalProducto} onHide={() => this.closeModalProducto()} bsSize="large" dialogClassName="xl" backdrop="static" aria-labelledby="contained-modal-title-lg">
      <form className="form-producto">
        <Modal.Header closeButton={!submitting}>
          <Modal.Title>
          {
            Object.keys(this.props.dataProductos).length > 0 ? (
              t('ALMACEN.PRODUCTO.MODAL.TITLE_EDITAR')
            ) : (
              Object.keys(this.props.dataDuplicarProductos).length > 0 ? (
                t('ALMACEN.PRODUCTO.MODAL.TITLE_DUPLICAR')
              ) : (
                t('ALMACEN.PRODUCTO.MODAL.TITLE_NUEVO')
              )
            )
          }
          </Modal.Title>
          {!submitting && dataProductos && dataProductos.producto && dataProductos.producto.idProducto && (
            <ButtonChangeRecord list={listProducto} idElemento="idProducto" currentId={dataProductos.producto.idProducto} getNextRecord={recuperarDatosModalProducto} />
          )}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Field
              colSm={2}
              id="referencia"
              name="referencia"
              component={InputText}
              controlLabel={t('ALMACEN.PRODUCTO.MODAL.REFERENCIA') + '*'}
              customClass='referencia'
              validate={required}
              maxLength={true}
              valorMaxLength={30}
            />
            <Field
              colSm={2}
              id="nombre"
              name="nombre"
              component={InputText}
              controlLabel={t('ALMACEN.PRODUCTO.MODAL.PRODUCTO') + '*'}
              customClass='nombre'
              validate={required}
              maxLength={true}
              valorMaxLength={120}
            />
            <Field
              colSm={2}
              id="unidadMedida"
              name="unidadMedida"
              component={InputSelect}
              options={comboUnidadesMedia}
              controlLabel={t('ALMACEN.PRODUCTO.MODAL.UNIDAD_MEDIDA')}
              customClass="unidadMedida"
            />
            <Field
              colSm={2}
              id="stockMinimo"
              name="stockMinimo"
              component={InputNumerical}
              controlLabel={t('ALMACEN.PRODUCTO.MODAL.STOCK_MINIMO')}
              customClass='stock-minimo'
            />
            <Field
              id="validacion-producto"
              name="validacion"
              colSm={2}
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('ALMACEN.PRODUCTO.MODAL.VALIDACION')}
              customClass="check-producto"
            />
            {this.props.auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && (
              <Field
                id="trazabilidad-producto"
                name="trazabilidad"
                colSm={2}
                inline
                component={InputCheckBox}
                claseActivo="onoffswitch-label"
                controlLabel={t('ALMACEN.PRODUCTO.MODAL.TRAZABILIDAD')}
                customClass="check-producto"
              />
            )}
            <div className="clearfix"></div>
            <Field
              colSm={8}
              id="descripcion"
              name="descripcion"
              component={InputTextArea}
              componentClass="textarea"
              controlLabel={t('ALMACEN.PRODUCTO.MODAL.DESCRIPCION')}
              customClass='descripcion-producto'
            />
            <Field
              id="fecha-caducidad"
              name="fechaCaducidad"
              colSm={2}
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('ALMACEN.PRODUCTO.MODAL.FECHA_CADUCIDAD')}
              customClass="check-producto-fecha-caducidad"
            />
            <Field
              id="producto-activo"
              name="activo"
              colSm={2}
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('ALMACEN.PRODUCTO.MODAL.ACTIVO')}
              customClass="check-producto"
            />
            
            <Field
              id="avisoStockMinimo"
              name="avisoStockMinimo"
              colSm={2}
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('ALMACEN.PRODUCTO.MODAL.AVISO')}
              customClass="check-producto"
              onInputChange={this.cambiarAviso.bind(this)}
            />
            <Field
              colSm={2}
              id="usuarioAvisoStockMinimo"
              name="usuarioAvisoStockMinimo"
              component={InputSelect}
              options={comboUsuariosCentro}
              controlLabel={t('ALMACEN.PRODUCTO.MODAL.ENVIAR_AVISO_A')}
              customClass={this.state.mostrarEnviarAviso === true ? 'mostrar-enviar-aviso' : 'no-mostrar-eviar-aviso'}
              validate={this.state.mostrarEnviarAviso === true ? required : null}
            />
            
            
          </Row>
          <div className="clearfix"></div>
          {
            Object.keys(this.props.dataProductos).length > 0 ? (
              <div>
                <FormSubtitle>{t('MENU.ALMACEN.ENTRADAS_MERCANCIAS')}</FormSubtitle>
                <SimpleTablePage {...table}>
                </SimpleTablePage>
              </div>
            ) : null
          }
           <div className="clearfix"></div>
          {
            Object.keys(this.props.dataProductos).length > 0 ? (
              <div>
                <FormSubtitle>{t('ALMACEN.PRODUCTO.AJUSTES_STOCK')}</FormSubtitle>
                <SimpleTablePage {...tableAjusteStock}>
                </SimpleTablePage>
              </div>
            ) : null
          }
        </Modal.Body>
        <Modal.Footer>
          {
          /*
            this.props.data && (
              <Button type="button" className="btn btn-white" onClick={handleSubmit(duplicar)}>{t('ALMACEN.PRODUCTO.MODAL.DUPLICAR')}</Button>
            )
            !this.props.data && (
              <Button type="button" className="btn btn-primary" onClick={handleSubmit(this.guardarYnuevoProducto.bind(this))}>{t('ALMACEN.PRODUCTO.MODAL.GUARDAR_Y_NUEVO')}</Button>
            )
          */
          }
          <ButtonLockable
            processing={submitting}
            hasIcon={true}
            showLoaderOnClick={true}
            type="button"
            className="btn btn-primary btn-sm"
            disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.PRODUCTOS] < 2) || submitting}
            onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.PRODUCTOS] > 1) && !submitting) ? handleSubmit(this.submitNuevoProducto.bind(this)) : () => {}}
          >
            {t('ALMACEN.PRODUCTO.MODAL.GUARDAR')}
          </ButtonLockable>
          <Button
            type="button"
            className={"btn btn-white btn-sm" + (submitting ? ' disabled' : '')}
            onClick={() => !submitting && closeModalProducto()}
          >
            {t('ALMACEN.PRODUCTO.MODAL.CANCELAR')}
          </Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalProducto',
})(ModalProducto))