import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import SimpleTable from '../../../components/comun/table/SimpleTable'
import {openModal, seleccionarPorPagina, cambiarPagina} from '../../../actions/common'
import {anadirFechaPlaning, eliminarFechaPlaning, obtenerDatosVerracoPlaning, fetchPlaningSuccess, fetchOrderPlaning, anadirVerracosPlaning, deleteSeleccionVerracoPlaning} from '../../../actions/planing/planing'
import {obtenerDatosVerraco, obtenerExtraccion} from '../../../actions/foso/foso'
import {obtenerInformeCliente, obtenerRegistrosSeguimientosExtraccionesPool} from '../../../actions/informes/informes'

export function mapStateToProps (state, ownProps) {
  return {
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    auth: state.auth,
    form: ownProps.form || 'simpleTable',
    common: state.common,
    formSimpleTable: state.form,
    foso: state.foso,
    planing: state.planing
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openModal,
      seleccionarPorPagina,
      cambiarPagina,
      anadirFechaPlaning,
      eliminarFechaPlaning,
      obtenerDatosVerraco,
      obtenerExtraccion,
      obtenerDatosVerracoPlaning,
      obtenerInformeCliente,
      obtenerRegistrosSeguimientosExtraccionesPool,
      fetchPlaningSuccess,
      fetchOrderPlaning,
      anadirVerracosPlaning,
      deleteSeleccionVerracoPlaning
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SimpleTable))