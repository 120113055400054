import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize } from 'redux-form'
import {openSimpleModal, openMensajeAlerta} from '../../actions/common'
import { yesNoModal } from '../modal/yesNoModal'
import actionTypes from '../../constants/actions/movimientoVerraco/movimientoVerraco'
import {cerrarModalUbicacion, abrirModalUbicacion, guardarDatosUbicacion} from '../../actions/fichaVerraco/fichaVerraco'
import { ubicacionenesVaciasSuccess, enviarFechaMovimientoVerracoSuccess, fetchMovimientoVerracoSuccess, recuperarDatosModalMovimientoVerracoSuccess, duplicarDatosModalMovimientoVerracoSuccess,
  deSeleccionarTodosMovimientoVerraco, fetchFiltrarMovimientoVerracoSuccess, numVerracosSinUbicacionSuccess, origenMovimientoSuccess, crearCsvMovimientoVerracoSuccess, quitarCheckVerracosSinUbicacion,
  imprimirPdfMovimientoVerracoSuccess} from '../../actions/movimientoVerraco/movimientoVerraco'
import getUbicacionesVacias from '../../services/movimientoVerraco/getUbicacionesVacias'
import addMovimientoVerraco from '../../services/movimientoVerraco/addMovimientoVerraco'
import checkUbicacionVerracoFecha from '../../services/movimientoVerraco/checkUbicacionVerracoFecha'
import getMovimientoVerracoList from '../../services/movimientoVerraco/getMovimientoVerracoList'
import getMovimientoVerraco from '../../services/movimientoVerraco/getMovimientoVerraco'
import editMovimientoVerraco from '../../services/movimientoVerraco/editMovimientoVerraco'
import deleteMovimientoVerraco from '../../services/movimientoVerraco/deleteMovimientoVerraco'
import deleteSeleccionMoviVerraco from '../../services/movimientoVerraco/deleteSeleccionMovimientoVerraco'
import getFiltrarMovimientoVerraco from '../../services/movimientoVerraco/getFiltrarMovimientoVerraco'
import getVerracosSinUbicacion from '../../services/movimientoVerraco/getVerracosSinUbicacion'
import getOrigenMovimientoVerraco from '../../services/movimientoVerraco/getOrigenMovimientoVerraco'
import comprobarUbicacionLibre from '../../services/movimientoVerraco/comprobarUbicacionLibre'
import getCrearCsvMovimientoVerraco from '../../services/movimientoVerraco/getCrearCsvMovimientoVerraco'
import getImprimirPdfMovimientoVerraco from '../../services/movimientoVerraco/getImprimirPdfMovimientoVerraco'
import {date_formatter} from '../../util/formatFunctions'

export function * submitNuevoMoviVerraco ({values, esInsertar, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    let filtros = yield select(state => state.movimientoVerraco.filtros)
    if (esInsertar === true){
      values.idInstalacion = values.idInstalacion.value
      const esLibre = yield call(comprobarUbicacionLibre, values, auth.token)
      if (esLibre.ubicacion_libre === true) {
        let instalacion = yield select(state => state.combos.comboUbicacion)
        instalacion = instalacion.filter((sitio) => {
          if (sitio.value === values.idInstalacion){
            return sitio
          }
        })
        values.instalacion = instalacion
        const insercionMovimiento = yield call(addMovimientoVerraco, values, auth.token)
        const list = yield call(getFiltrarMovimientoVerraco, filtros, auth.token)
        yield put(fetchMovimientoVerracoSuccess(list))
        yield put(fetchFiltrarMovimientoVerracoSuccess(filtros))
        if (insercionMovimiento.insertado === true ) {
          yield put(openMensajeAlerta('insercion'))
          if (onSuccessCallback) yield onSuccessCallback()
        } else {
          yield put(openMensajeAlerta('no-insercion'))
          if (onErrorCallback) yield onErrorCallback()
        }
        yield put(cerrarModalUbicacion())
        yield put(quitarCheckVerracosSinUbicacion())
        yield put(initialize('modalMovimientoVerraco'))
        const listado = yield call(getMovimientoVerracoList, null)
        yield put(fetchMovimientoVerracoSuccess(listado))
        const numVerracosSinUbicacion = yield call(getVerracosSinUbicacion, null)
        listado.numVerracosSinUbicacion = numVerracosSinUbicacion
        yield put(numVerracosSinUbicacionSuccess(numVerracosSinUbicacion.numVerracosSinUbicacion))
      } else {
        yield put(openSimpleModal('ubicacionLibre'))
        const confirmed = yield call(yesNoModal, {modalType: 'ubicacionLibre'})
        if (confirmed) {
          values.vaciarUbicacion = true
          let instalacion = yield select(state => state.combos.comboUbicacion)
          instalacion = instalacion.filter((sitio) => {
            if (sitio.value === values.idInstalacion){
              return sitio
            }
          })
          values.instalacion = instalacion
          const insercionMovimiento = yield call(addMovimientoVerraco, values, auth.token)
          const list = yield call(getFiltrarMovimientoVerraco, filtros, auth.token)
          yield put(fetchMovimientoVerracoSuccess(list))
          yield put(fetchFiltrarMovimientoVerracoSuccess(filtros))
          if (insercionMovimiento.insertado === true ) {
            yield put(openMensajeAlerta('insercion'))
            if (onSuccessCallback) yield onSuccessCallback()
          } else {
            yield put(openMensajeAlerta('no-insercion'))
            if (onErrorCallback) yield onErrorCallback()
          }
          yield put(cerrarModalUbicacion())
          yield put(quitarCheckVerracosSinUbicacion())
          yield put(initialize('modalMovimientoVerraco'))
        } else {
          yield put(cerrarModalUbicacion())
          yield put(quitarCheckVerracosSinUbicacion())
          yield put(initialize('modalMovimientoVerraco'))
          yield put(openMensajeAlerta('ubicacion-no-libre'))
        }
      }
    } else {
      values.idInstalacion = values.idInstalacion.value
      let instalacion = yield select(state => state.combos.comboUbicacion)
      instalacion = instalacion.filter((sitio) => {
        if (sitio.value === values.idInstalacion){
          return sitio
        }
      })
      values.instalacion = instalacion
      const esLibre = yield call(comprobarUbicacionLibre, values, auth.token)
      if (esLibre.ubicacion_libre === true) {
        yield put(guardarDatosUbicacion(values))
        const list = yield call(getFiltrarMovimientoVerraco, filtros, auth.token)
        yield put(fetchMovimientoVerracoSuccess(list))
        yield put(fetchFiltrarMovimientoVerracoSuccess(filtros))
        yield put(cerrarModalUbicacion())
        yield put(quitarCheckVerracosSinUbicacion())
        yield put(initialize('modalMovimientoVerraco'))
        if (onSuccessCallback) yield onSuccessCallback()
      } else {
        yield put(openSimpleModal('ubicacionLibre'))
        const confirmed = yield call(yesNoModal, {modalType: 'ubicacionLibre'})
        if (confirmed) {
          values.vaciarUbicacion = true
          yield put(guardarDatosUbicacion(values))
          const list = yield call(getFiltrarMovimientoVerraco, filtros, auth.token)
          yield put(fetchMovimientoVerracoSuccess(list))
          yield put(fetchFiltrarMovimientoVerracoSuccess(filtros))
          yield put(cerrarModalUbicacion())
          yield put(quitarCheckVerracosSinUbicacion())
          yield put(initialize('modalMovimientoVerraco'))
          if (onSuccessCallback) yield onSuccessCallback()
        } else {
          yield put(openMensajeAlerta('ubicacion-no-libre'))
          if (onErrorCallback) yield onErrorCallback()
        }
        yield put(cerrarModalUbicacion())
        yield put(quitarCheckVerracosSinUbicacion())
      }
    }
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchSubmitNuevoMoviVerraco () {
  yield takeLatest(actionTypes.SUBMIT_NUEVO_MOVI_VERRACO, submitNuevoMoviVerraco)
}

export function * ubicacionenesVacias ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const data = yield call(getUbicacionesVacias, values, auth.token)
    yield put(ubicacionenesVaciasSuccess(data))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchUbicacionenesVacias () {
  yield takeLatest(actionTypes.UBICACIONES_VACIAS, ubicacionenesVacias)
}

export function * enviarFechaMovimientoVerraco ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const verracoMovidoHoy = yield call(checkUbicacionVerracoFecha, values, auth.token)
    yield put(enviarFechaMovimientoVerracoSuccess(verracoMovidoHoy))

    const origenMovimiento = yield call(getOrigenMovimientoVerraco, values, auth.token)
    yield put(origenMovimientoSuccess(origenMovimiento))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchEnviarFechaMovimientoVerraco () {
  yield takeLatest(actionTypes.ENVIAR_FECHA_MOVIMIENTO_VERRACO, enviarFechaMovimientoVerraco)
}

export function * fetchMovimientoVerraco () {
  try {
    const list = yield call(getMovimientoVerracoList, null)
    yield put(fetchMovimientoVerracoSuccess(list))
    const numVerracosSinUbicacion = yield call(getVerracosSinUbicacion, null)
    list.numVerracosSinUbicacion = numVerracosSinUbicacion
     yield put(numVerracosSinUbicacionSuccess(numVerracosSinUbicacion.numVerracosSinUbicacion))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchFetchMovimientoVerraco () {
  yield takeLatest(actionTypes.FETCH_MOVIMIENTO_VERRACO, fetchMovimientoVerraco)
}

export function * recuperarDatosModalMovimientoVerraco ({idUbicacion}) {
  try {
    let auth = yield select(state => state.auth)
    yield put(duplicarDatosModalMovimientoVerracoSuccess([]))
    const registroModificar = yield call(getMovimientoVerraco, idUbicacion, null)
    yield put(recuperarDatosModalMovimientoVerracoSuccess(registroModificar))
    registroModificar.origenMovimiento = registroModificar.instalacionorigen
    registroModificar.idInstalacion = {label: registroModificar.instalaciondestino, value: registroModificar.iddestino}
    yield put(initialize('modalMovimientoVerraco', registroModificar))
    yield put(abrirModalUbicacion())
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchRecuperarDatosModalMovimientoVerraco () {
  yield takeLatest(actionTypes.RECUPERAR_DATOS_MODAL_MOVIMIENTO_VERRACO, recuperarDatosModalMovimientoVerraco)
}

export function * abrirModalVacioUbicacion () {
  try {
    yield put(recuperarDatosModalMovimientoVerracoSuccess([]))
    yield put(initialize('modalMovimientoVerraco', {}))
    yield put(abrirModalUbicacion())
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchAbrirModalVacioUbicacion () {
  yield takeLatest(actionTypes.ABRIR_MODAL_VACIO_UBICACION, abrirModalVacioUbicacion)
}

export function * editarMoviVerraco ({values, esInsertar, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    if (esInsertar === true){
      values.idInstalacion = values.idInstalacion.value
      const esLibre = yield call(comprobarUbicacionLibre, values, auth.token)
      if (esLibre.ubicacion_libre === true) {
        const edicionMovimiento = yield call(editMovimientoVerraco, values, auth.token)
        let filtros = yield select(state => state.movimientoVerraco.filtros)
        const list = yield call(getFiltrarMovimientoVerraco, filtros, auth.token)
        yield put(fetchMovimientoVerracoSuccess(list))
        yield put(fetchFiltrarMovimientoVerracoSuccess(filtros))
        if (edicionMovimiento.actualizado === true) {
          yield put(openMensajeAlerta('modificacion'))
          if (onSuccessCallback) yield onSuccessCallback()
        } else {
          yield put(openMensajeAlerta('no-modificacion'))
          if (onErrorCallback) yield onErrorCallback()
        }
      } else {
        yield put(openSimpleModal('ubicacionLibre'))
        const confirmed = yield call(yesNoModal, {modalType: 'ubicacionLibre'})
        if (confirmed) {
          values.vaciarUbicacion = true
          const edicionMovimiento = yield call(editMovimientoVerraco, values, auth.token)
          let filtros = yield select(state => state.movimientoVerraco.filtros)
          const list = yield call(getFiltrarMovimientoVerraco, filtros, auth.token)
          yield put(fetchMovimientoVerracoSuccess(list))
          yield put(fetchFiltrarMovimientoVerracoSuccess(filtros))
          if (edicionMovimiento.actualizado === true) {
            yield put(openMensajeAlerta('modificacion'))
            if (onSuccessCallback) yield onSuccessCallback()
          } else {
            yield put(openMensajeAlerta('no-modificacion'))
            if (onErrorCallback) yield onErrorCallback()
          }
        } else {
          yield put(openMensajeAlerta('ubicacion-no-libre'))
          if (onErrorCallback) yield onErrorCallback()
        }
      }
    }
    yield put(cerrarModalUbicacion())
    yield put(quitarCheckVerracosSinUbicacion())
    yield put(initialize('modalMovimientoVerraco'))
    const listado = yield call(getMovimientoVerracoList, null)
    yield put(fetchMovimientoVerracoSuccess(listado))
    const numVerracosSinUbicacion = yield call(getVerracosSinUbicacion, null)
    listado.numVerracosSinUbicacion = numVerracosSinUbicacion
    yield put(numVerracosSinUbicacionSuccess(numVerracosSinUbicacion.numVerracosSinUbicacion))
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchEditarMoviVerraco () {
  yield takeLatest(actionTypes.EDITAR_MOVI_VERRACO, editarMoviVerraco)
}

export function * duplicarDatosModalMovimientoVerraco ({idUbicacion}) {
  try {
    let auth = yield select(state => state.auth)
    yield put(recuperarDatosModalMovimientoVerracoSuccess([]))
    const registroDuplicar = yield call(getMovimientoVerraco, idUbicacion, null)
    yield put(duplicarDatosModalMovimientoVerracoSuccess(registroDuplicar))
    registroDuplicar.origenMovimiento = registroDuplicar.instalacionorigen
    registroDuplicar.idInstalacion = {label: registroDuplicar.instalaciondestino, value: registroDuplicar.iddestino}
    yield put(initialize('modalMovimientoVerraco', registroDuplicar))
    yield put(abrirModalUbicacion())
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchDuplicarDatosModalMovimientoVerraco () {
  yield takeLatest(actionTypes.DUPLICAR_DATOS_MODAL_MOVIMIENTO_VERRACO, duplicarDatosModalMovimientoVerraco)
}

export function * onDeleteMovimientoVerraco ({idUbicacion}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      const eliminarMovimiento = yield call(deleteMovimientoVerraco, idUbicacion, null)
      let filtros = yield select(state => state.movimientoVerraco.filtros)
      let auth = yield select(state => state.auth)
      const list = yield call(getFiltrarMovimientoVerraco, filtros, auth.token)
      yield put(fetchMovimientoVerracoSuccess(list))
      yield put(fetchFiltrarMovimientoVerracoSuccess(filtros))
      if (eliminarMovimiento.eliminado === true && eliminarMovimiento.asociado === false) {
        yield put(openMensajeAlerta('eliminacion'))
      } else if (eliminarMovimiento.eliminado === false && eliminarMovimiento.asociado === true) {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
    }
    const listado = yield call(getMovimientoVerracoList, null)
    yield put(fetchMovimientoVerracoSuccess(listado))
    const numVerracosSinUbicacion = yield call(getVerracosSinUbicacion, null)
    listado.numVerracosSinUbicacion = numVerracosSinUbicacion
    yield put(numVerracosSinUbicacionSuccess(numVerracosSinUbicacion.numVerracosSinUbicacion))
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}
export function * watchOnDeleteMovimientoVerraco () {
  yield takeLatest(actionTypes.ON_DELETE_MOVIMIENTO_VERRACO, onDeleteMovimientoVerraco)
}

export function * deleteSeleccionMovimientoVerraco ({list}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      let eliminarMovimientoSeleccion = yield call(deleteSeleccionMoviVerraco, list, auth.token)
      if (eliminarMovimientoSeleccion.eliminado === true) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(deSeleccionarTodosMovimientoVerraco())
      yield put(initialize('simpleTable'))
      let filtros = yield select(state => state.movimientoVerraco.filtros)
      const listFiltros = yield call(getFiltrarMovimientoVerraco, filtros, auth.token)
      yield put(fetchMovimientoVerracoSuccess(listFiltros))
      yield put(fetchFiltrarMovimientoVerracoSuccess(filtros))
    }
    const listado = yield call(getMovimientoVerracoList, null)
    yield put(fetchMovimientoVerracoSuccess(listado))
    const numVerracosSinUbicacion = yield call(getVerracosSinUbicacion, null)
    listado.numVerracosSinUbicacion = numVerracosSinUbicacion
    yield put(numVerracosSinUbicacionSuccess(numVerracosSinUbicacion.numVerracosSinUbicacion))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchDeleteSeleccionMovimientoVerraco () {
  yield takeLatest(actionTypes.DELETE_SELECCION_MOVIMIENTO_VERRACO, deleteSeleccionMovimientoVerraco)
}

export function * fetchFiltrarMovimientoVerraco ({values}) {
  try {
    let auth = yield select(state => state.auth)
    values.movimientoDesde = values.fechaEntrada
    values.movimientoHasta = values.fechaSalida
    const list = yield call(getFiltrarMovimientoVerraco, values, auth.token)
    yield put(fetchMovimientoVerracoSuccess(list))
    yield put(fetchFiltrarMovimientoVerracoSuccess(values))
    const numVerracosSinUbicacion = yield call(getVerracosSinUbicacion, null)
    list.numVerracosSinUbicacion = numVerracosSinUbicacion
     yield put(numVerracosSinUbicacionSuccess(numVerracosSinUbicacion.numVerracosSinUbicacion))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchFetchFiltrarMovimientoVerraco () {
  yield takeLatest(actionTypes.FETCH_FILTRAR_MOVIMIENTO_VERRACO, fetchFiltrarMovimientoVerraco)
}

export function * guardarYNuevoMovimiento ({values, esInsertar, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    if (esInsertar === true){
      values.idInstalacion = values.idInstalacion.value
      const insercionMovimiento = yield call(addMovimientoVerraco, values, auth.token)
      let filtros = yield select(state => state.movimientoVerraco.filtros)
      const list = yield call(getFiltrarMovimientoVerraco, filtros, auth.token)
      yield put(fetchMovimientoVerracoSuccess(list))
      yield put(fetchFiltrarMovimientoVerracoSuccess(filtros))
      if (insercionMovimiento.insertado === true) {
        yield put(openMensajeAlerta('insercion'))
        if (onSuccessCallback) yield onSuccessCallback()
      } else {
        yield put(openMensajeAlerta('no-insercion'))
        if (onErrorCallback) yield onErrorCallback()
      }
    } else if (onErrorCallback) {
      yield onErrorCallback()
    }
    yield put(initialize('modalMovimientoVerraco'))
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchGuardarYNuevoMovimiento () {
  yield takeLatest(actionTypes.GUARDAR_Y_NUEVO_MOVIMIENTO, guardarYNuevoMovimiento)
}

export function * crearCsvMovimientoVerraco ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosExcelMovimientoVerraco = yield call(getCrearCsvMovimientoVerraco, list, auth.token)
    yield put(crearCsvMovimientoVerracoSuccess(datosExcelMovimientoVerraco))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchCrearCsvMovimientoVerraco () {
  yield takeLatest(actionTypes.CREATE_CSV_MOVIMIENTO_VERRACO, crearCsvMovimientoVerraco)
}
export function * imprimirPdfMovimientoVerraco ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfMovimientoVerraco = yield call(getImprimirPdfMovimientoVerraco, list, auth.token)
    yield put(imprimirPdfMovimientoVerracoSuccess(datosPdfMovimientoVerraco))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirPdfMovimientoVerraco () {
  yield takeLatest(actionTypes.IMPRIMIR_PDF_MOVIMIENTO_VERRACO, imprimirPdfMovimientoVerraco)
}
