import actionTypes from '../constants/actions/common'

export function changeLocale (responseLocale) {
  return {
    type: actionTypes.CHANGE_LOCALE,
    responseLocale
  }
}

export function openModal (){
  return {
    type: actionTypes.OPEN_MODAL
  }
}

export function closeModal (){
  return {
    type: actionTypes.CLOSE_MODAL
  }
}

export function nOkResponse (value) {
  return {
    type: actionTypes.CUSTOM_MODAL_NOK,
    value
  }
}

export function okResponse (value) {
  return {
    type: actionTypes.CUSTOM_MODAL_OK,
    value
  }
}

export function openSimpleModal (typeModal, dynamicMessage, subDynamicMessage){
  return {
    type: actionTypes.OPEN_SIMPLE_MODAL,
    typeModal,
    dynamicMessage,
    subDynamicMessage
  }
}

export function yesNoModal (modalType, okAction, cancelAction, message, subMessage) {
  return {
    type: actionTypes.YES_NO_MODAL,
    modalType,
    okAction,
    cancelAction,
    message,
    subMessage
  }
}

export function seleccionarPorPagina(numeroRegistrosPorPagina){
  return {
    type: actionTypes.SELECCIONAR_POR_PAGINA,
    numeroRegistrosPorPagina
  }
}

export function cambiarPagina(numeroPagina){
  return {
    type: actionTypes.CAMBIAR_PAGINA,
    numeroPagina
  }
}

export function openMensajeAlerta (typeAlerta){
  return {
    type: actionTypes.OPEN_MENSAJE_ALERTA,
    typeAlerta
  }
}

export function openMensajeAlertaCustomText (text, error){
  return {
    type: actionTypes.OPEN_MENSAJE_ALERTA_CUSTOM_TEXT,
    text,
    error
  }
}

export function closeMensajeAlerta (){
  return {
    type: actionTypes.CLOSE_MENSAJE_ALERTA
  }
}

export default {
    changeLocale,
    openModal,
    closeModal,
    nOkResponse,
    okResponse,
    openSimpleModal,
    yesNoModal,
    seleccionarPorPagina,
    cambiarPagina,
    openMensajeAlerta,
    openMensajeAlertaCustomText,
    closeMensajeAlerta
}
