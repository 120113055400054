import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import Button from '../comun/button/Button'
import InputCheckBox from '../comun/form/InputCheckBox'
import { AddCircleOutline, Delete } from '@material-ui/icons'
import {permisos as permisosConstant} from '../../constants/permisos'

class FiltrosListadoSeguimiento extends Component {
   constructor () {
    super()
    this.state = {
      imputSelected: false,
    }
    this.openModalFormSeguimiento = this.openModalFormSeguimiento.bind(this)
    this.deleteSeleccionSeguimiento = this.deleteSeleccionSeguimiento.bind(this)
  }

  openModalFormSeguimiento () {
    this.props.actions.openModalFormSeguimiento(null, false, this.props.isPool)
  }

  intercambiarCheck = (value) => {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosModalSeguimiento()
    } else {
      this.props.actions.deSeleccionarTodosModalSeguimiento()
    }
  }

  deleteSeleccionSeguimiento(){
    const itemsSeleccion = this.props.list.map((row) => {
      return this.props[this.props.idForm].values['check' + this.props.idForm + row[this.props.idElemento]] === true && (
        {
          'check': this.props[this.props.idForm].values['check' + this.props.idForm + row[this.props.idElemento]],
          [this.props.idElemento]: row[this.props.idElemento]
        }
      )
    })
    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.deleteSeleccionSeguimiento(list)
  }

  componentDidUpdate (prevProps) {
    if (this.props.listadoSeguimientoTable && Object.keys(this.props.listadoSeguimientoTable).length > 0) {
      if (this.props.listadoSeguimientoTable !== prevProps.listadoSeguimientoTable) {
        let contActivos = 0
        if (this.props.listadoSeguimientoTable.values) {
          Object.values(this.props.listadoSeguimientoTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todos-modal-listar-seguimiento', false)
            this.setState({ imputSelected: false })
            this.props.actions.deSeleccionarTodosModalSeguimiento()
          }
        }
      }
    }
  }

  render () {
  const {
    t,
    auth: { permisos }
  } = this.props
  const tKey = 'SEGUIMIENTO.LISTADO.'

  return (
      <div>
        <Row className="acciones-tabla-filtros">
          <Col sm={6}>
            <div className="buttons-flex">
              {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.SEGUIMIENTO] > 1) && [
                <div className="custom-check-w" key="custom-check-w">
                  <Field
                    id="seleccionar-todos-modal-listar-seguimiento"
                    name="seleccionar-todos-modal-listar-seguimiento"
                    colSm={12}
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheck(value)}
                  />
                </div>,
                <div id="block-options1" key="block-options1" className={this.state.imputSelected === true ? 'button-group buttons-min block-options' : 'button-group buttons-min block-options oculta-action'}>
                  <button type="button" className="btn btn-icono" onClick={(e) => this.deleteSeleccionSeguimiento()}>
                    <Delete></Delete> {t(tKey + 'BUTTONS.ELIMINAR')}
                  </button>
                  <div className="barra-buttons-min"></div>
                </div>
              ]}
              <div className="button-group buttons-min">
                <Button
                  type="button"
                  className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.SEGUIMIENTO] < 2) ? ' disabled' : '')}
                  onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.SEGUIMIENTO] > 1) ? this.openModalFormSeguimiento : undefined}
                  style={{padding: '1px 6px', verticalAlign: 'inherit'}}
                >
                  <AddCircleOutline /> {t(tKey + 'BUTTONS.NUEVO')}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosListadoSeguimiento',
})(FiltrosListadoSeguimiento))
