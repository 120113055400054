import {call, put, takeLatest, select, delay} from 'redux-saga/effects'
import {initialize} from 'redux-form'
import i18n from '../../i18n';
import actionTypes from '../../constants/actions/perfil/perfil'
import { cerrarModalPerfil, fetchPerfilSuccess, cerrarCambiarPassPerfil, cambiarPasswordPerfilSuccess } from '../../actions/perfil/perfil'
import {refreshAuth} from '../../actions/auth/auth'
import {openMensajeAlerta} from '../../actions/common'
import editPerfil from '../../services/perfil/editPerfil'
import getPerfil from '../../services/perfil/getPerfil'
import updatePassword from '../../services/perfil/updatePassword'

export function * fetchPerfil () {
  try {
    let state = yield select(state => state)
    const dataPerfil = yield call(getPerfil, null)
    if (dataPerfil.idioma === 'es'){
      dataPerfil.idioma = {value: 'es', label: i18n.t('COMUN.COMBOS.IDIOMA.ESPANOL')}
    }
    else if (dataPerfil.idioma === 'en') {
      dataPerfil.idioma = {value: 'en', label: i18n.t('COMUN.COMBOS.IDIOMA.INGLES')}
    }
    else if (dataPerfil.idioma === 'ch') {
      dataPerfil.idioma = {value: 'ch', label: i18n.t('COMUN.COMBOS.IDIOMA.CHINO')}
    }
    dataPerfil.formaFechaHora = {value: dataPerfil.formaFechaHora, label: dataPerfil.formaFechaHora}
    dataPerfil.separadorDec = {value: dataPerfil.separadorDec, label: dataPerfil.separadorDec}
    dataPerfil.separadorMil = {value: dataPerfil.separadorMil, label: dataPerfil.separadorMil}
    let fotmatFecHor = dataPerfil.formaFechaHora.value.split('/')
    dataPerfil.formaFecha = {value: fotmatFecHor[0] + '/' + fotmatFecHor[1] + '/' + fotmatFecHor[2], label: fotmatFecHor[0] + '/' + fotmatFecHor[1] + '/' + fotmatFecHor[2]}
    dataPerfil.formaHora = {value: '/' + fotmatFecHor[3], label: fotmatFecHor[3] + 'h'}
    dataPerfil.zonaHoraria = dataPerfil.zonaHoraria !== null && {value: dataPerfil.zonaHoraria, label: dataPerfil.zonaHoraria}
    yield put(initialize('ModalPerfil', dataPerfil))
    yield put (fetchPerfilSuccess(dataPerfil))
  } catch (error) {
    console.log(error)
  } finally {
  }
}
export function * watchFetchPerfil () {
  yield takeLatest(actionTypes.FETCH_PERFIL, fetchPerfil)
}

export function * editarPerfil ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const edicionPerfil = yield call(editPerfil, values, auth.token)
    if (edicionPerfil.actualizado === true && edicionPerfil.existeUsuario === false) {
      yield put(openMensajeAlerta('modificacion'))
      yield delay(2000)
      yield put(cerrarModalPerfil())

      // AÑADIR IMAGEN AL AUTH
      if (values.idUsuario === auth.idUsuario) {
        yield put(refreshAuth())
      }
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-modificacion'))
      if (onErrorCallback) yield onErrorCallback()
    }
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
  }
}
export function * watchEditarPerfil () {
  yield takeLatest(actionTypes.EDITAR_PERFIL, editarPerfil)
}

export function * cambiarPasswordPerfil ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const edicionPass = yield call(updatePassword, values, auth.token)
    if (edicionPass.actualizado === true) {
      yield put(openMensajeAlerta('modificacion'))
      yield put(cerrarCambiarPassPerfil())
      yield put(cambiarPasswordPerfilSuccess(edicionPass.actualizado))
    } else {
      // yield put(openMensajeAlerta('no-modificacion'))
      yield put(cambiarPasswordPerfilSuccess(edicionPass.actualizado))
    }
    yield put(initialize('ModalCambiarPassPerfil', ''))
  } catch (error) {}
}
export function * watchCambiarPasswordPerfil () {
  yield takeLatest(actionTypes.CAMBIAR_PASSWORD_PERFIL, cambiarPasswordPerfil)
}
