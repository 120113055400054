import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import InputSelect from '../comun/form/InputSelect'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import ModalInicioPlaningPage from '../../containers/planing/ModalInicioPlaningPage'
import ModalPlaningPage from '../../containers/planing/ModalPlaningPage'
import ModalExportarImprimirPlaningPage from '../../containers/planing/ModalExportarImprimirPlaningPage'
import FiltrosPlaningPage from '../../containers/planing/FiltrosPlaningPage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import InputDatePicker from '../comun/form/InputDatePicker'
// import './Planing.scss'
import {date_formatter, date_parser, number_formatter, date_formatter_tabla, dateTimeZones} from '../../util/formatFunctions'
import {permisos as permisosConstant} from '../../constants/permisos'

class Planing extends Component {
  constructor (props) {
    super()
    this.state = {
      fechasListPlaning: [],
      metodoEvaluacion: '',
      idMotilidad: '',
      nameMotilidad: ''
    }

    let currentDate = new Date()
    currentDate = date_parser(currentDate, props.auth.formaFechaHora)
    let initialValues = {
      fechaPedidos: currentDate,
      fechaPlanning: currentDate
    }
    props.initialize(initialValues)
  }

  componentDidMount () {
    document.title = this.props.t('MENU.PLANING') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    this.props.actions.obtenerDatosPanelControl(null)

    //this.props.actions.totalesPlaning() // Es llamado en el componentDidMount del componente FiltrosPlaning...
    this.props.actions.totalesPlaningAlbaran()
  }

  elegirDiasPlaning (value) {
    if (value !== null){
      this.props.actions.elegirDiasPlaning(value)
    }
  }

  fetchTotalDosisPedidas(values, getTotalesAlbaran = false) {
    this.props.actions.totalesPlaning(values)
    if (getTotalesAlbaran) {
      this.props.actions.totalesPlaningAlbaran(values)
    }
  }

  componentDidUpdate (prevProps) {
    if (Object.keys(this.props.listPlaning).length > 0) {
      if (this.props.listPlaning !== prevProps.listPlaning) {
        const fechasListPlaning = this.props.listPlaning.map((fecha) => {
          fecha.fechas.idVerraco = fecha.idVerraco
          return fecha.fechas
        })
        this.setState({fechasListPlaning: fechasListPlaning})
      }
      if (this.props.order !== prevProps.order) {
        const fechasListPlaning = this.props.listPlaning.map((fecha) => {
          fecha.fechas.idVerraco = fecha.idVerraco
          return fecha.fechas
        })
        this.setState({fechasListPlaning: fechasListPlaning})
      }
    } else {
      if (this.props.listPlaning !== prevProps.listPlaning) {
        const fechasListPlaning = this.props.listPlaning.map((fecha) => {
          fecha.fechas.idVerraco = fecha.idVerraco
          return fecha.fechas
        })
        this.setState({fechasListPlaning: fechasListPlaning})
      }
    }

    if (this.props.panelControl !== prevProps.panelControl) {
      if (Object.keys(this.props.panelControl).length > 0){
        this.setState({metodoEvaluacion: this.props.panelControl.datosPanel.metodoEvaluacion})
        if (this.props.panelControl.datosPanel.metodoEvaluacion === 1) {
          this.setState({idMotilidad: 'motilidadTotal'})
          this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MOTILIDAD')})
        }
        if (this.props.panelControl.datosPanel.metodoEvaluacion === 2) {
          this.setState({idMotilidad: 'motilidadTotal'})
          this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MOTILIDAD')})
        }
        if (this.props.panelControl.datosPanel.metodoEvaluacion === 4) {
          this.setState({idMotilidad: 'motilidadMasal'})
          this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MOT_MASAL')})
        }
        if (this.props.panelControl.datosPanel.metodoEvaluacion === 3) {
          this.setState({idMotilidad: 'mt'})
          this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MT')})
        }
        if (this.props.panelControl.datosPanel.metodoEvaluacion === 5) {
          this.setState({idMotilidad: 'mt'})
          this.setState({nameMotilidad: this.props.t('INFORMES.EXTRACCIONES.TABLA.MT')})
        }
      }
    }
  }

  render () {
    const {
      t, handleSubmit, numeroRegistrosPorPagina, paginaActual, checkedFiltrosPlaning, diasPlaning, listPlaning, datosTotalesPlaning, checkedPlanningPrincipal, totalesAlbaranes, datosTotalesPlaningAlbaran, formPlaning,
      actions: {recuperarDatosModalPlaning, duplicarDatosModalPlaning, cambiarEstadoPlaning, onDeletePlaning, totalesPlaning},
      auth: {formaFechaHora, separadorMil, separadorDec},
    } = this.props

    const comboDiasPlaning = [
    {value: 15, label: this.props.t('PLANING.15_DIAS')},
    {value: 30, label: this.props.t('PLANING.30_DIAS')},
    {value: 45, label: this.props.t('PLANING.45_DIAS')}
    ]

    let fecha = dateTimeZones(this.props.auth.zonaHorariaString)
    let arrayAntes = []
    let arrayDespues = []
    for (var i=1; i<diasPlaning / 2; i++) {
      // dias antes de hoy
      let HaceUnaSemana=new Date(fecha.getTime() - (24*60*60*1000) * i)
      let fechaMontadaDespues = HaceUnaSemana.getFullYear()+"-"+parseInt(HaceUnaSemana.getMonth() + 1, 10) +"-"+HaceUnaSemana.getDate()
      arrayAntes.push(date_formatter_tabla(fechaMontadaDespues, this.props.auth.formaFechaHora, false))

      // dias despues de hoy
      let MasUnaSemana = new Date(fecha.getTime() + (24*60*60*1000) * i)
      let fechaMontadaAntes = MasUnaSemana.getFullYear()+"-"+parseInt(MasUnaSemana.getMonth() + 1, 10) +"-"+MasUnaSemana.getDate()
      arrayDespues.push(date_formatter_tabla(fechaMontadaAntes, this.props.auth.formaFechaHora, false))
    }
    let diasCalendario = arrayAntes.reverse() + ',' + date_formatter_tabla(fecha, this.props.auth.formaFechaHora, false) + ',' + arrayDespues
    let calendario = diasCalendario.split(',')
    const childrenCalendario = calendario.map((dia) => {
      return {id: dia, name: dia, anchoColum: diasPlaning === 15 ? '10%' : '40px', type: 'numeroPlaning'}
    })

    // TABLA VERRACO
    listPlaning.forEach(
      (row) => row.idElemento = row.idVerraco
    )
    const table = {
      id: 'planingTable1',
      multiHeader: false,
      hasCheckbox: true,
      noBlockOptions: false,
      hasOptions: false,
      mostrarModificar: false,
      hasPagination: false,
      paginar: false,
      planingSeleccionarfila: true,
      classTableAlto: 'alto-tablas-planing',
      columns: [
        {id: 'verraco', name: t('PLANING.TABLA.VERRACO'), anchoColum: '25%'},
        {id: 'raza', name: t('PLANING.TABLA.RAZA'), anchoColum: '11%'},
        {id: 'lineaGenetica', name: t('PLANING.TABLA.LINEA_GENETICA'), anchoColum: '11%'},
        {id: 'Espu3', name: t('PLANING.TABLA.ESPU3'), type: 'numero', anchoColum: '10%'},
        // {id: 'Motilidad', name: t('PLANING.TABLA.MOTILIDAD'), type: 'numero', anchoColum: '10%'},
        {id: 'Motilidad', name: this.state.nameMotilidad, type: 'numero', type: 'numero', anchoColum: '10%'},
        {id: 'Dosis', name: t('PLANING.TABLA.DOSIS'), type: 'numero', anchoColum: '11%'},
        {id: 'Fa', name: t('PLANING.TABLA.FA'), type: 'numero', anchoColum: '11%'},
        {id: 'dias', name: t('PLANING.TABLA.DIAS'), anchoColum: '11%'}
      ],
      rows: listPlaning,
      initialValues: {}
    }
    table.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          table.initialValues['check' + row.idElemento] = checkedPlanningPrincipal
        }
      }
    )

    // TABLA FECHAS
    const tableFechas = {
      id: 'planingTable2',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      hasPagination: false,
      paginar: false,
      classTableAlto: 'alto-tablas-planing tabla-fechas-planing',
      esPlaning: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.PLANNING] > 1),
      planingSeleccionarfila: true,
      planningFechas: true,
      columns: childrenCalendario,
      rows: this.state.fechasListPlaning,
      initialValues: {}
    }

    // RESUMEN ALBARANES
    const tableTotalesAlbaranes = {
      id: 'albaranesTotalesTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      paginarConScroll: true,
      hasPagination: false,
      columns: [
        {id: 'nombreRaza', name: t('ALBARANES.RAZA')},
        {id: 'nombreTipoDosis', name: t('ALBARANES.TIPO_DOSIS')},
        {id: 'totalDosis', name: t('ALBARANES.TOTAL_DOSIS')}
      ],
      rows: datosTotalesPlaningAlbaran && Object.keys(datosTotalesPlaningAlbaran).length > 0 ? datosTotalesPlaningAlbaran : [],
      initialValues: {}
    }

    return (
      <div>
        <ModalInicioPlaningPage />
        <ModalExportarImprimirPlaningPage />
        <SimpleModalPage/>
        <MensajeAlertaPage/>
        <ModalPlaningPage />
        <h2 className="page-title">{t('PLANING.TITLE')}</h2>
        <FiltrosPlaningPage/>
        <div className="clearfix"></div>
        <div className="col-sm-12"><div className="table-container simple-table">
        <div className="leyenda"><ul><li><div style={{backgroundColor: 'rgb(28, 140, 220)'}}></div>{t('PLANING.EXTRACCIONES_FUTURAS')}</li><li><div style={{backgroundColor: 'rgb(220, 28, 28)'}}></div>{t('PLANING.EXTRACCIONES_PASADAS')}</li><li><div style={{backgroundColor: 'rgb(230, 211, 73)'}}></div>{t('PLANING.DIA_HOY')}</li></ul></div>
        </div></div>
        <Row>
          <Col sm={5} className="tabla-planning-izq">
            <SimpleTablePage {...table}></SimpleTablePage>
          </Col>
          <Col sm={7} style={{paddingLeft: 0}} className="tabla-planning-der">
            <SimpleTablePage {...tableFechas}></SimpleTablePage>
          </Col>
          <Col sm={12}>
            <Row className="planning-filtro-fecha-pedidos">
              <Field
                id="fechaPedidos"
                name="fechaPedidos"
                controlLabel={t('PLANING.TOTAL_FECHA_REFERENCIA_PEDIDOS')}
                component={InputDatePicker}
                colSm={2}
                onInputChange={(value) => this.fetchTotalDosisPedidas({fechaPedidos: value}, true)}
              />
              <Field
                id="fechaPlanning"
                name="fechaPlanning"
                controlLabel={t('PLANING.TOTAL_FECHA_REFERENCIA_PLANNING')}
                component={InputDatePicker}
                colSm={2}
                colSmOffset={1}
                onInputChange={(value) => this.fetchTotalDosisPedidas({fechaPlanning: value})}
              />
            </Row>
          </Col>
          <Col sm={12}>
            <div className="totales-planing">
              <Col sm={3} className="planing-total-verraco planning-total-dosis-pedido">
                {t('PLANING.TOTAL_DOSIS_PERDIDAS')}: {datosTotalesPlaning.dosisPedidas !== undefined && number_formatter(datosTotalesPlaning.dosisPedidas, 0, separadorDec, separadorMil)}
              </Col>
              <Col sm={3} className="planing-total-verraco planning-total-dosis-planning">
                {t('PLANING.TOTAL_TOTAL_DOSIS')}: {datosTotalesPlaning.dosisTotales !== undefined && number_formatter(datosTotalesPlaning.dosisTotales, 0, separadorDec, separadorMil)}
              </Col>
              <Col sm={3} className="planing-total-verraco planning-total-dosis-planning">
                {t('PLANING.TOTAL_DOSIS_SIN_ASIGNAR')}: {datosTotalesPlaning.dosisSinAsignar !== undefined && number_formatter(datosTotalesPlaning.dosisSinAsignar, 0, separadorDec, separadorMil)}
              </Col>
              <Col sm={3} className="planing-total-verraco planning-total-dosis-planning" style={{border: 'none'}}>
                {t('PLANING.TOTAL_DOSIS_ASIGNADAS')}: {datosTotalesPlaning.dosisAsisgnadas !== undefined && number_formatter(datosTotalesPlaning.dosisAsisgnadas, 0, separadorDec, separadorMil)}
              </Col>
            </div>
          </Col>
        </Row>
        <hr/>
        <p><b>{t('PLANING.RESUMEN_PEDIDOS')+" "+(formPlaning !==undefined && formPlaning.fechaPedidos ? "("+date_formatter(formPlaning.fechaPedidos, formaFechaHora, false)+")" : "")}</b></p>
        <SimpleTablePage {...tableTotalesAlbaranes}>
        </SimpleTablePage>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'formPlaning',
})(Planing))