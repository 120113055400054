import {eventChannel} from 'redux-saga'
import {take, put, call, takeLatest, select} from 'redux-saga/effects'

import i18n from '../../i18n';
import {downloadDocument} from '../../util/util'

import actionTypes from '../../constants/actions/analisisExtracciones/camaraView'
import {comprobarCamaraSuccess, getImagenCamaraSuccess, apagarCamaraSuccess} from '../../actions/analisisExtracciones/camaraView'
import iniciarCamaraService from '../../services/analisisExtracciones/iniciarCamara'
import comprobarCamaraService from '../../services/analisisExtracciones/comprobarCamara'
import getImagenCamaraService from '../../services/analisisExtracciones/getImagenCamara'
//import getVideoCamaraService from '../../services/analisisExtracciones/getVideoCamara'
import apagarCamaraService from '../../services/analisisExtracciones/apagarCamara'

function startCamaraInterval (times = 3, delay = 3000) {
  let current = times
  return eventChannel(emitter => {
      const iv = setInterval(() => {
        current -= 1
        if (current > -1) {
          comprobarCamaraService().then((response) => {
            emitter(response)
          }).catch((err) => {
            emitter({err, current})
          })
        } else {
          emitter({camara_no_encontrada: true})
        }
      }, delay);
      // The subscriber must return an unsubscribe function
      return () => {
        clearInterval(iv)
      }
    }
  )
}

export function * comprobarCamara () {
  let camaraIntervalChannel = null
  try {
    yield call(iniciarCamaraService)
    camaraIntervalChannel = yield call(startCamaraInterval)

    let response = {}
    while (response && (!response.camara_conectada && !response.camara_no_encontrada)) {
      response = yield take(camaraIntervalChannel)
    }
    camaraIntervalChannel.close()
    yield put(comprobarCamaraSuccess(response.camara_conectada))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchComprobarCamara () {
  yield takeLatest(actionTypes.COMPROBAR_CAMARA, comprobarCamara)
}

export function * getImagenCamara () {
  try {
    let base64Img = yield call(getImagenCamaraService)
    yield put(getImagenCamaraSuccess(base64Img))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchGetImagenCamara () {
  yield takeLatest(actionTypes.GET_IMAGEN_CAMARA, getImagenCamara)
}

/*export function * getVideoCamara () {
  try {
    let {result} = yield call(getVideoCamaraService)
    //yield put(getVideoCamaraSuccess(result))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchGetVideoCamara () {
  yield takeLatest(actionTypes.GET_VIDEO_CAMARA, getVideoCamara)
}*/

export function * descargarImagen () {
  try {
    yield call(getImagenCamara)

    const state = yield select(state => state)
    let base64Img = state.camaraView.base64Img
    let currentDate = new Date()

    let href = 'data:image/jpeg;base64,' + encodeURIComponent(base64Img)
    let name = currentDate.getTime() + ".jpg"
    downloadDocument(href, name)
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchDescargarImagen () {
  yield takeLatest(actionTypes.DESCARGAR_IMAGEN, descargarImagen)
}

export function * apagarCamara () {
  try {
    yield call(getImagenCamara)
    let {result} = yield call(apagarCamaraService)
    yield put(apagarCamaraSuccess(result))
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchApagarCamara () {
  yield takeLatest(actionTypes.APAGAR_CAMARA, apagarCamara)
}