export default {
  COMBO_COD_LINEA_GENETICA: 'COMBO_COD_LINEA_GENETICA',
  COMBO_COD_LINEA_GENETICA_SUCCESS: 'COMBO_COD_LINEA_GENETICA_SUCCESS',
  COMBO_LINEA_GENETICA: 'COMBO_LINEA_GENETICA',
  COMBO_LINEA_GENETICA_SUCCESS: 'COMBO_LINEA_GENETICA_SUCCESS',
  COMBO_COD_RAZA: 'COMBO_COD_RAZA',
  COMBO_COD_RAZA_SUCCESS: 'COMBO_COD_RAZA_SUCCESS',
  COMBO_RAZA: 'COMBO_RAZA',
  COMBO_RAZA_SUCCESS: 'COMBO_RAZA_SUCCESS',
  COMBO_NOMBRE_GRUPO_VERRACO: 'COMBO_NOMBRE_GRUPO_VERRACO',
  COMBO_NOMBRE_GRUPO_VERRACO_SUCCESS: 'COMBO_NOMBRE_GRUPO_VERRACO_SUCCESS',
  COMBO_VERRACO: 'COMBO_VERRACO',
  COMBO_VERRACO_SUCCESS: 'COMBO_VERRACO_SUCCESS',
  COMBO_TIPO_INSTALACION_PADRE: 'COMBO_TIPO_INSTALACION_PADRE',
  COMBO_TIPO_INSTALACION_PADRE_SUCCESS: 'COMBO_TIPO_INSTALACION_PADRE_SUCCESS',
  COMBO_TIPO_INSTALACION: 'COMBO_TIPO_INSTALACION',
  COMBO_TIPO_INSTALACION_SUCCESS: 'COMBO_TIPO_INSTALACION_SUCCESS',
  COMBO_INSTALACION_PADRE: 'COMBO_INSTALACION_PADRE',
  COMBO_INSTALACION_PADRE_SUCCESS: 'COMBO_INSTALACION_PADRE_SUCCESS',
  COMBO_COD_INSTALACION: 'COMBO_COD_INSTALACION',
  COMBO_COD_INSTALACION_SUCCESS: 'COMBO_COD_INSTALACION_SUCCESS',
  COMBO_NOMBRE_INSTALACION: 'COMBO_NOMBRE_INSTALACION',
  COMBO_NOMBRE_INSTALACION_SUCCESS: 'COMBO_NOMBRE_INSTALACION_SUCCESS',
  COMBO_OPERARIO: 'COMBO_OPERARIO',
  COMBO_OPERARIO_SUCCESS: 'COMBO_OPERARIO_SUCCESS',
  COMBO_SALA_EXTRACCION: 'COMBO_SALA_EXTRACCION',
  COMBO_SALA_EXTRACCION_SUCCESS: 'COMBO_SALA_EXTRACCION_SUCCESS',
  COMBO_UBICACION: 'COMBO_UBICACION',
  COMBO_UBICACION_SUCCESS: 'COMBO_UBICACION_SUCCESS',
  COMBO_TIPO_DOSIS: 'COMBO_TIPO_DOSIS',
  COMBO_TIPO_DOSIS_SUCCESS: 'COMBO_TIPO_DOSIS_SUCCESS',
  COMBO_OBTENER_CENTRO_USUARIO: 'COMBO_OBTENER_CENTRO_USUARIO',
  COMBO_OBTENER_CENTRO_USUARIO_SUCCESS: 'COMBO_OBTENER_CENTRO_USUARIO_SUCCESS',
  COMBO_LISTADO_VERRACO: 'COMBO_LISTADO_VERRACO',
  COMBO_LISTADO_VERRACO_SUCCESS: 'COMBO_LISTADO_VERRACO_SUCCESS',
  FETCH_COMBO_POOL: 'FETCH_COMBO_POOL',
  FETCH_COMBO_POOL_SUCCESS: 'FETCH_COMBO_POOL_SUCCESS',
  FETCH_COMBO_PRODUCTO: 'FETCH_COMBO_PRODUCTO',
  FETCH_COMBO_PRODUCTO_SUCCESS: 'FETCH_COMBO_PRODUCTO_SUCCESS',
  FETCH_COMBO_TIPO_DILUYENTE: 'FETCH_COMBO_TIPO_DILUYENTE',
  FETCH_COMBO_TIPO_DILUYENTE_SUCCESS: 'FETCH_COMBO_TIPO_DILUYENTE_SUCCESS',
  COMBO_TIPO_ALIMENTACION: 'COMBO_TIPO_ALIMENTACION',
  COMBO_TIPO_ALIMENTACION_SUCCESS: 'COMBO_TIPO_ALIMENTACION_SUCCESS',
  COMBO_TIPO_INTERVENCION_SANITARIA: 'COMBO_TIPO_INTERVENCION_SANITARIA',
  COMBO_TIPO_INTERVENCION_SANITARIA_SUCCESS: 'COMBO_TIPO_INTERVENCION_SANITARIA_SUCCESS',
  COMBO_VERRACO_SIN_UBICACION: 'COMBO_VERRACO_SIN_UBICACION',
  COMBO_VERRACO_SIN_UBICACION_SUCCESS: 'COMBO_VERRACO_SIN_UBICACION_SUCCESS',
  COMBO_MAQUINA: 'COMBO_MAQUINA',
  COMBO_MAQUINA_SUCCESS: 'COMBO_MAQUINA_SUCCESS',
  COMBO_RUTA: 'COMBO_RUTA',
  COMBO_RUTA_SUCCESS: 'COMBO_RUTA_SUCCESS',
  COMBO_CLIENTE: 'COMBO_CLIENTE',
  COMBO_CLIENTE_SUCCESS: 'COMBO_CLIENTE_SUCCESS',
  COMBO_CIF_CLIENTE: 'COMBO_CIF_CLIENTE',
  COMBO_CIF_CLIENTE_SUCCESS: 'COMBO_CIF_CLIENTE_SUCCESS',
  COMBO_DIRECCION_CLIENTE: 'COMBO_DIRECCION_CLIENTE',
  COMBO_DIRECCION_CLIENTE_SUCCESS: 'COMBO_DIRECCION_CLIENTE_SUCCESS',
  COMBO_LOCALIDAD_CLIENTE: 'COMBO_LOCALIDAD_CLIENTE',
  COMBO_LOCALIDAD_CLIENTE_SUCCESS: 'COMBO_LOCALIDAD_CLIENTE_SUCCESS',
  COMBO_INSTALACIONES_PADRE: 'COMBO_INSTALACIONES_PADRE',
  COMBO_INSTALACIONES_PADRE_SUCCESS: 'COMBO_INSTALACIONES_PADRE_SUCCESS',
  COMBO_NOMBRES_OPERARIOS: 'COMBO_NOMBRES_OPERARIOS',
  COMBO_NOMBRES_OPERARIOS_SUCCESS: '  COMBO_NOMBRES_OPERARIOS_SUCCESS',
  COMBO_APELLIDOS_OPERARIOS: '  COMBO_APELLIDOS_OPERARIOS',
  COMBO_APELLIDOS_OPERARIOS_SUCCESS: '  COMBO_APELLIDOS_OPERARIOS_SUCCESS',
  COMBO_LOCALIDAD_OPERARIOS: '  COMBO_LOCALIDAD_OPERARIOS',
  COMBO_LOCALIDAD_OPERARIOS_SUCCESS: '  COMBO_LOCALIDAD_OPERARIOS_SUCCESS',
  COMBO_EMPRESA_VISITAS: '  COMBO_EMPRESA_VISITAS',
  COMBO_EMPRESA_VISITAS_SUCCESS: '  COMBO_EMPRESA_VISITAS_SUCCESS',
  COMBO_CONTACTO_VISITAS: '  COMBO_CONTACTO_VISITAS',
  COMBO_CONTACTO_VISITAS_SUCCESS: '  COMBO_CONTACTO_VISITAS_SUCCESS',
  COMBO_LINEA_ACTIVA_GENETICA: 'COMBO_LINEA_ACTIVA_GENETICA',
  COMBO_LINEA_GENETICA_ACTIVA_SUCCESS: 'COMBO_LINEA_GENETICA_ACTIVA_SUCCESS',
  COMBO_ACTIVA_RAZA: 'COMBO_ACTIVA_RAZA',
  COMBO_RAZA_ACTIVA_SUCCESS: 'COMBO_RAZA_ACTIVA_SUCCESS',
  COMBO_OPERARIO_ACTIVO: 'COMBO_OPERARIO_ACTIVO',
  COMBO_OPERARIO_ACTIVO_SUCCESS: 'COMBO_OPERARIO_ACTIVO_SUCCESS',
  COMBO_SALA_EXTRACCION_ACTIVO: 'COMBO_SALA_EXTRACCION_ACTIVO',
  COMBO_SALA_EXTRACCION_ACTIVO_SUCCESS: 'COMBO_SALA_EXTRACCION_ACTIVO_SUCCESS',
  FETCH_COMBO_RECTA_CALIBRADO: 'FETCH_COMBO_RECTA_CALIBRADO',
  FETCH_COMBO_RECTA_CALIBRADO_SUCCESS: 'FETCH_COMBO_RECTA_CALIBRADO_SUCCESS',
  COMBO_EMPRESA_TRANSPORTISTA: 'COMBO_EMPRESA_TRANSPORTISTA',
  COMBO_EMPRESA_TRANSPORTISTA_SUCCESS: 'COMBO_EMPRESA_TRANSPORTISTA_SUCCESS',
  COMBO_CONDUCTORES_TRANSPORTISTA: 'COMBO_CONDUCTORES_TRANSPORTISTA',
  COMBO_CONDUCTORES_TRANSPORTISTA_SUCCESS: 'COMBO_CONDUCTORES_TRANSPORTISTA_SUCCESS',
  COMBO_VEHICULO_TRANSPORTISTA: 'COMBO_VEHICULO_TRANSPORTISTA',
  COMBO_VEHICULO_TRANSPORTISTA_SUCCESS: 'COMBO_VEHICULO_TRANSPORTISTA_SUCCESS',
  COMBO_USUARIOS_CENTRO: 'COMBO_USUARIOS_CENTRO',
  COMBO_USUARIOS_CENTRO_SUCCESS: 'COMBO_USUARIOS_CENTRO_SUCCESS',
  COMBO_GRUPO_USUARIOS_CENTRO: 'COMBO_GRUPO_USUARIOS_CENTRO',
  COMBO_GRUPO_USUARIOS_CENTRO_SUCCESS: 'COMBO_GRUPO_USUARIOS_CENTRO_SUCCESS',
  COMBO_NOMBRE_PRODUCTO: 'COMBO_NOMBRE_PRODUCTO',
  COMBO_NOMBRE_PRODUCTO_SUCCESS: 'COMBO_NOMBRE_PRODUCTO_SUCCESS',
  COMBO_REFERENCIA_PRODUCTO: 'COMBO_REFERENCIA_PRODUCTO',
  COMBO_REFERENCIA_PRODUCTO_SUCCESS: 'COMBO_REFERENCIA_PRODUCTO_SUCCESS',
  COMBO_NOMBRE_ESCANDALLO: 'COMBO_NOMBRE_ESCANDALLO',
  COMBO_NOMBRE_ESCANDALLO_SUCCESS: 'COMBO_NOMBRE_ESCANDALLO_SUCCESS',
  COMBO_PRODUCTO_ESCANDALLO: 'COMBO_PRODUCTO_ESCANDALLO',
  COMBO_PRODUCTO_ESCANDALLO_SUCCESS: 'COMBO_PRODUCTO_ESCANDALLO_SUCCESS' ,
  COMBO_PROVEEDORES_ENTRADA_MERCANCIA: 'COMBO_PROVEEDORES_ENTRADA_MERCANCIA',
  COMBO_PROVEEDORES_ENTRADA_MERCANCIA_SUCCESS: 'COMBO_PROVEEDORES_ENTRADA_MERCANCIA_SUCCESS',
  COMBO_ALBARANES_ENTRADA_MERCANCIA: 'COMBO_ALBARANES_ENTRADA_MERCANCIA',
  COMBO_ALBARANES_ENTRADA_MERCANCIA_SUCCESS: 'COMBO_ALBARANES_ENTRADA_MERCANCIA_SUCCESS',
  COMBO_ORIGENES_ENTRADA_MERCANCIA: 'COMBO_ORIGENES_ENTRADA_MERCANCIA',
  COMBO_ORIGENES_ENTRADA_MERCANCIA_SUCCESS: 'COMBO_ORIGENES_ENTRADA_MERCANCIA_SUCCESS',
  COMBO_LOTES: 'COMBO_LOTES',
  COMBO_LOTES_SUCCESS: 'COMBO_LOTES_SUCCESS',
  COMBO_LOTES_ACTIVOS: 'COMBO_LOTES_ACTIVOS',
  COMBO_LOTES_ACTIVOS_SUCCESS: 'COMBO_LOTES_ACTIVOS_SUCCESS',
  COMBO_PRODUCTOS_ACTIVOS: 'COMBO_PRODUCTOS_ACTIVOS',
  COMBO_PRODUCTOS_ACTIVOS_SUCCESS: 'COMBO_PRODUCTOS_ACTIVOS_SUCCESS',
  COMBO_PRODUCTOS_ACTIVOS_TRAZABILIDAD: 'COMBO_PRODUCTOS_ACTIVOS_TRAZABILIDAD',
  COMBO_PRODUCTOS_ACTIVOS_TRAZABILIDAD_SUCCESS: 'COMBO_PRODUCTOS_ACTIVOS_TRAZABILIDAD_SUCCESS',
  COMBO_LOTES_PRODUCTO_ESPECIFICO_ACTIVO: 'COMBO_LOTES_PRODUCTO_ESPECIFICO_ACTIVO',
  COMBO_LOTES_PRODUCTO_ESPECIFICO_ACTIVO_SUCCESS: 'COMBO_LOTES_PRODUCTO_ESPECIFICO_ACTIVO_SUCCESS',
  COMBO_LOTES_PRODUCTO_ESPECIFICO_ACTIVO_TRAZABILIDAD: 'COMBO_LOTES_PRODUCTO_ESPECIFICO_ACTIVO_TRAZABILIDAD',
  COMBO_LOTES_PRODUCTO_ESPECIFICO_ACTIVO_TRAZABILIDAD_SUCCESS: 'COMBO_LOTES_PRODUCTO_ESPECIFICO_ACTIVO_TRAZABILIDAD_SUCCESS',
  COMBO_RAZON_SOCIAL_CLIENTES_ACTIVOS: 'COMBO_RAZON_SOCIAL_CLIENTES_ACTIVOS',
  COMBO_RAZON_SOCIAL_CLIENTES_ACTIVOS_SUCCESS: 'COMBO_RAZON_SOCIAL_CLIENTES_ACTIVOS_SUCCESS',
  COMBO_ALIAS_CLIENTES_ACTIVOS: 'COMBO_ALIAS_CLIENTES_ACTIVOS',
  COMBO_ALIAS_CLIENTES_ACTIVOS_SUCCESS: 'COMBO_ALIAS_CLIENTES_ACTIVOS_SUCCESS',
  COMBO_INDEX: 'COMBO_INDEX',
  COMBO_INDEX_SUCCESS: 'COMBO_INDEX_SUCCESS',
  COMBO_UBICACION_PADRE: 'COMBO_UBICACION_PADRE',
  COMBO_UBICACION_PADRE_SUCCESS: 'COMBO_UBICACION_PADRE_SUCCESS',
  COMBO_VERRACOS_PRESENTES_ACTIVOS: 'COMBO_VERRACOS_PRESENTES_ACTIVOS',
  COMBO_VERRACOS_PRESENTES_ACTIVOS_SUCCESS: 'COMBO_VERRACOS_PRESENTES_ACTIVOS_SUCCESS',
  COMBO_VERRACOS_EXTRACCION_ALBARAN: "COMBO_VERRACOS_EXTRACCION_ALBARAN",
  COMBO_VERRACOS_EXTRACCION_ALBARAN_SUCCESS: "COMBO_VERRACOS_EXTRACCION_ALBARAN_SUCCESS",
  COMBO_POOLS_EXTRACCION_ALBARAN: "COMBO_POOLS_EXTRACCION_ALBARAN",
  COMBO_POOLS_EXTRACCION_ALBARAN_SUCCESS: "COMBO_POOLS_EXTRACCION_ALBARAN_SUCCESS",
  COMBO_CLIENTE_ACTIVO: 'COMBO_CLIENTE_ACTIVO',
  COMBO_CLIENTE_ACTIVO_SUCCESS: 'COMBO_CLIENTE_ACTIVO_SUCCESS',
  COMBO_CLIENTE_CON_VERRACOS_EN_PROPIEDAD: 'COMBO_CLIENTE_CON_VERRACOS_EN_PROPIEDAD',
  COMBO_CLIENTE_CON_VERRACOS_EN_PROPIEDAD_SUCCESS: 'COMBO_CLIENTE_CON_VERRACOS_EN_PROPIEDAD_SUCCESS',
  COMBO_NUMERO_PEDIDO: 'COMBO_NUMERO_PEDIDO',
  COMBO_NUMERO_PEDIDO_SUCCESS: 'COMBO_NUMERO_PEDIDO_SUCCESS',
  COMBO_TRANSPORTISTAS_CLIENTE: 'COMBO_TRANSPORTISTAS_CLIENTE',
  COMBO_TRANSPORTISTAS_CLIENTE_SUCCESS: 'COMBO_TRANSPORTISTAS_CLIENTE_SUCCESS',
  COMBO_RUTA_TRANSPORTISTA_CLIENTE: 'COMBO_RUTA_TRANSPORTISTA_CLIENTE',
  COMBO_RUTA_TRANSPORTISTA_CLIENTE_SUCCESS: 'COMBO_RUTA_TRANSPORTISTA_CLIENTE_SUCCESS',
  COMBO_VEHICULOS_TRANSPORTISTA_DETERMINADO: 'COMBO_VEHICULOS_TRANSPORTISTA_DETERMINADO',
  COMBO_VEHICULOS_TRANSPORTISTA_DETERMINADO_SUCCESS: 'COMBO_VEHICULOS_TRANSPORTISTA_DETERMINADO_SUCCESS',
  COMBO_CONDUCTORES_TRANSPORTISTA_DETERMINADO: 'COMBO_CONDUCTORES_TRANSPORTISTA_DETERMINADO',
  COMBO_CONDUCTORES_TRANSPORTISTA_DETERMINADO_SUCCESS: 'COMBO_CONDUCTORES_TRANSPORTISTA_DETERMINADO_SUCCESS',
  COMBO_NUMEROS_ALBARAN: 'COMBO_NUMEROS_ALBARAN',
  COMBO_NUMEROS_ALBARAN_SUCCESS: 'COMBO_NUMEROS_ALBARAN_SUCCESS',
  COMBO_EMPRESA_TRANSPORTISTA_ACTIVOS: 'COMBO_EMPRESA_TRANSPORTISTA_ACTIVOS',
  COMBO_EMPRESA_TRANSPORTISTA_ACTIVOS_SUCCESS: 'COMBO_EMPRESA_TRANSPORTISTA_ACTIVOS_SUCCESS',
  COMBO_INSTALACION_PADRE_ACTIVA_SUCCESS: 'COMBO_INSTALACION_PADRE_ACTIVA_SUCCESS',
  COMBO_INSTALACION_PADRE_ACTIVA: 'COMBO_INSTALACION_PADRE_ACTIVA',
  COMBO_LOCALIDAD_CLIENTES: 'COMBO_LOCALIDAD_CLIENTES',
  COMBO_LOCALIDAD_CLIENTES_SUCCESS: 'COMBO_LOCALIDAD_CLIENTES_SUCCESS',
  COMBO_PROVINCIAS_CLIENTES: 'COMBO_PROVINCIAS_CLIENTES',
  COMBO_PROVINCIAS_CLIENTES_SUCCESS: 'COMBO_PROVINCIAS_CLIENTES_SUCCESS',
  COMBO_USUARIOS_NO_ASIGNADOS_OPERARIO: 'COMBO_USUARIOS_NO_ASIGNADOS_OPERARIO',
  COMBO_USUARIOS_NO_ASIGNADOS_OPERARIO_SUCCESS: 'COMBO_USUARIOS_NO_ASIGNADOS_OPERARIO_SUCCESS',
  COMBO_OPERARIO_PREDETERMINADO: 'COMBO_OPERARIO_PREDETERMINADO',
  COMBO_OPERARIO_PREDETERMINADO_SUCCESS: 'COMBO_OPERARIO_PREDETERMINADO_SUCCESS',
  COMBO_PRODUCTOS_VALIDACION_ACTIVOS: 'COMBO_PRODUCTOS_VALIDACION_ACTIVOS',
  COMBO_PRODUCTOS_VALIDACION_ACTIVOS_SUCCESS: 'COMBO_PRODUCTOS_VALIDACION_ACTIVOS_SUCCESS',
  COMBO_UBICACION_VACIA: 'COMBO_UBICACION_VACIA',
  COMBO_NOMBRE_GRUPO_VERRACO_ACTIVA: 'COMBO_NOMBRE_GRUPO_VERRACO_ACTIVA',
  COMBO_NOMBRE_GRUPO_VERRACO_ACTIVA_SUCCESS: 'COMBO_NOMBRE_GRUPO_VERRACO_ACTIVA_SUCCESS',
  COMBO_TODAS_UBICACIONES: 'COMBO_TODAS_UBICACIONES',
  COMBO_TODAS_UBICACIONES_SUCCESS: 'COMBO_TODAS_UBICACIONES_SUCCESS',
  COMBO_RUTAS_ACTIVAS: 'COMBO_RUTAS_ACTIVAS',
  COMBO_RUTAS_ACTIVAS_SUCCESS: 'COMBO_RUTAS_ACTIVAS_SUCCESS',
  COMBO_INSTALACIONES_PADRE_O_SIN_HIJOS: 'COMBO_INSTALACIONES_PADRE_O_SIN_HIJOS',
  COMBO_INSTALACIONES_PADRE_O_SIN_HIJOS_SUCCESS: 'COMBO_INSTALACIONES_PADRE_O_SIN_HIJOS_SUCCESS',
  COMBO_VERRACOS_CON_SEGUIMIENTO: 'COMBO_VERRACOS_CON_SEGUIMIENTO',
  COMBO_VERRACOS_CON_SEGUIMIENTO_SUCCESS: 'COMBO_VERRACOS_CON_SEGUIMIENTO_SUCCESS',
  COMBO_DIRECCION_CLIENTE_SIN_PERSONALIZADO: 'COMBO_DIRECCION_CLIENTE_SIN_PERSONALIZADO',
  COMBO_DIRECCION_CLIENTE_SIN_PERSONALIZADO_SUCCESS: 'COMBO_DIRECCION_CLIENTE_SIN_PERSONALIZADO_SUCCESS',
  COMBO_ZONA_HORARIA: 'COMBO_ZONA_HORARIA',
  COMBO_ZONA_HORARIA_SUCCESS: 'COMBO_ZONA_HORARIA_SUCCESS'
}