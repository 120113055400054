import React, {Component} from 'react'
import {Col, FormGroup, Checkbox} from 'react-bootstrap'
import './InputCheckBox.scss'

class InputCheckbox extends Component {

  handleChange (e) {
    let checked = e.target.checked
    this.props.input.onChange(checked)
    if (this.props.onInputChange) {
      setTimeout((() => {
        this.props.onInputChange(checked)
      }).bind(this), 200)
    }
  }

  UNSAFE_componentWillMount () {
    if (!this.props.input.value) {
      this.props.input.onChange(false)
    }
  }

  render () {
    const {
      id, placeholder, disabled = false, inline = false, input: {value}, colSm = 6, customClass , componentClass = 'styled-checkbox select-all',
      claseActivo, controlLabel, labelInput, customStyle
    } = this.props
    return (
      <Col sm={colSm} className={customClass} style={customStyle}>
        <FormGroup>
          {controlLabel && <label className="control-label">{controlLabel}</label>}
          <Checkbox
            id={id}
            title={controlLabel}
            inline={inline}
            disabled={disabled}
            checked={value}
            onChange={this.handleChange.bind(this)}
            className={claseActivo ? 'styled-checkbox select-all checkbox-inline-activo' : componentClass}
          >
            {placeholder}
            <label className={claseActivo ? ( disabled === true ? claseActivo + ' checkDisabled' : claseActivo) : 'checkmark'} htmlFor={id}></label>
            {labelInput ? <span className="labelInput">{labelInput}</span> : null}
          </Checkbox>
        </FormGroup>
      </Col>
    )
  }
}

export default InputCheckbox