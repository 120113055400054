export default {
  FETCH_ENVASADO: 'FETCH_ENVASADO',
  FETCH_FILTRAR_ENVASADO: 'FETCH_FILTRAR_ENVASADO',
  FETCH_FILTRAR_ENVASADO_SUCCESS: 'FETCH_FILTRAR_ENVASADO_SUCCESS',
  CREATE_CSV_ENVASADO: 'CREATE_CSV_ENVASADO',
  CREATE_CSV_ENVASADO_SUCCESS: 'CREATE_CSV_ENVASADO_SUCCESS',
  IMPRIMIR_PDF_ENVASADO: 'IMPRIMIR_PDF_ENVASADO',
  IMPRIMIR_PDF_ENVASADO_SUCCESS: 'IMPRIMIR_PDF_ENVASADO_SUCCESS',
  FETCH_ENVASADO_SUCCESS: 'FETCH_ENVASADO_SUCCESS',
  UPDATE_TOTAL_UTILIZADO_DETALLE_ENVASADO: 'UPDATE_TOTAL_UTILIZADO_DETALLE_ENVASADO',
  ABRIR_DETALLES_ENVASADO: 'ABRIR_DETALLES_ENVASADO',
  ABRIR_DETALLES_ENVASADO_SUCCESS: 'ABRIR_DETALLES_ENVASADO_SUCCESS',
  CERRAR_DETALLES_ENVASADO: 'CERRAR_DETALLES_ENVASADO',
  CERRAR_DETALLES_ENVASADO_SUCCESS: 'CERRAR_DETALLES_ENVASADO_SUCCESS',
  EDITAR_ENVASADO: 'EDITAR_ENVASADO',
  SELECCIONAR_TODOS_ENVASADO_MULTI_DOSIS: "SELECCIONAR_TODOS_ENVASADO_MULTI_DOSIS",
  DESELECCIONAR_TODOS_ENVASADO_MULTI_DOSIS: "DESELECCIONAR_TODOS_ENVASADO_MULTI_DOSIS",
  DELETE_ENVASADO_MULTI_DOSIS: "DELETE_ENVASADO_MULTI_DOSIS",
  DELETE_SELECCION_ENVASADO_MULTI_DOSIS: "DELETE_SELECCION_ENVASADO_MULTI_DOSIS",
  DELETE_ENVASADO_MULTI_DOSIS_SUCCESS: "DELETE_ENVASADO_MULTI_DOSIS_SUCCESS",
  ABRIR_MODAL_ENVASADO_MULTI_DOSIS: "ABRIR_MODAL_ENVASADO_MULTI_DOSIS",
  ABRIR_MODAL_ENVASADO_MULTI_DOSIS_SUCCESS: "ABRIR_MODAL_ENVASADO_MULTI_DOSIS_SUCCESS",
  ABRIR_MODAL_ENVASADO_MULTI_DOSIS_DESDE_LISTADO_PRINCIPAL: "ABRIR_MODAL_ENVASADO_MULTI_DOSIS_DESDE_LISTADO_PRINCIPAL",
  CERRAR_MODAL_ENVASADO_MULTI_DOSIS: "CERRAR_MODAL_ENVASADO_MULTI_DOSIS",
  CERRAR_MODAL_ENVASADO_MULTI_DOSIS_SUCCESS: "CERRAR_MODAL_ENVASADO_MULTI_DOSIS_SUCCESS",
  GUARDAR_MODAL_ENVASADO_MULTI_DOSIS: "GUARDAR_MODAL_ENVASADO_MULTI_DOSIS",
  GUARDAR_MODAL_ENVASADO_MULTI_DOSIS_SUCCESS: "GUARDAR_MODAL_ENVASADO_MULTI_DOSIS_SUCCESS",
  UPDATE_TOTAL_UTILIZADO_DETALLE_ENVASADO_SUCCESS: 'UPDATE_TOTAL_UTILIZADO_DETALLE_ENVASADO_SUCCESS',
  PREPARAR_MAQUINA_ENVASADO: 'PREPARAR_MAQUINA_ENVASADO',
  CANCELAR_MAQUINA_ENVASADO: 'CANCELAR_MAQUINA_ENVASADO',
  ACTUALIZAR_ENVASADO_MULTI_DOSIS_SUCCESS: 'ACTUALIZAR_ENVASADO_MULTI_DOSIS_SUCCESS',
  ACTUALIZAR_ESTADO_MAQUINA_ENVASADO: 'ACTUALIZAR_ESTADO_MAQUINA_ENVASADO',
}