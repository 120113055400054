import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosPoolExtracciones from '../../components/poolExtracciones/FiltrosPoolExtracciones'
import {
  fetchPoolExtracciones, seleccionarTodosPoolExtracciones, deseleccionarTodosPoolExtracciones,
  deleteSeleccionPoolExtracciones, crearCsvPoolExtracciones, imprimirPdfPoolExtracciones
} from '../../actions/poolExtracciones/poolExtracciones'
import { fetchComboPool, comboNombreGrupoVerraco, comboRaza, comboLineaGenetica, comboVerraco } from '../../actions/combos/combos'

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    poolExtracciones: state.poolExtracciones,
    formSimpleTable: state.form.simpleTable,
    combos: {
      comboPool: state.combos.comboPool,
      comboNombreGrupoVerraco: state.combos.comboNombreGrupoVerraco,
      comboRaza: state.combos.comboRaza,
      comboLineaGenetica: state.combos.comboLineaGenetica,
      comboCliente: state.combos.comboCliente,
      comboVerraco: state.combos.comboVerraco
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchPoolExtracciones,
      seleccionarTodosPoolExtracciones,
      deseleccionarTodosPoolExtracciones,
      deleteSeleccionPoolExtracciones,
      fetchComboPool,
      comboNombreGrupoVerraco,
      comboRaza,
      comboLineaGenetica,
      crearCsvPoolExtracciones,
      imprimirPdfPoolExtracciones,
      comboVerraco
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosPoolExtracciones))