import actionTypes from '../../constants/actions/cambioEstadoVerraco/cambioEstadoVerraco'

export function enviarFechaCambioEstado(values){
  return {
    type: actionTypes.ENVIAR_FECHA_CAMBIO_ESTADO,
    values
  }
}

export function enviarFechaCambioEstadoSuccess(verracoMovidoHoy){
  return {
    type: actionTypes.ENVIAR_FECHA_CAMBIO_ESTADO_SUCCESS,
    verracoMovidoHoy
  }
}

export function submitNuevoCambioEstadoVerraco(values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.SUBMIT_NUEVO_CAMBIO_ESTADO_VERRACO,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function submitNuevoCambioEstadoVerracoSuccess(ultimoCambioEstado){
  return {
    type: actionTypes.SUBMIT_NUEVO_CAMBIO_ESTADO_VERRACO_SUCCESS,
    ultimoCambioEstado
  }
}

export function restartCambioEstadoVerraco () {
  return {
    type: actionTypes.RESTART_CAMBIO_ESTADO_VERRACO
  }
}

export function fetchCambioEstadoVerraco (){
  return {
    type: actionTypes.FETCH_CAMBIO_ESTADO_VERRACO
  }
}

export function fetchCambioEstadoVerracoSuccess (list){
  return {
    type: actionTypes.FETCH_CAMBIO_ESTADO_VERRACO_SUCCESS,
    list
  }
}

export function abrirModalVacioCambioEstado (){
  return {
    type: actionTypes.ABRIR_MODAL_VACIO_CAMBIO_ESTADO
  }
}

export function recuperarDatosModalCambioEstadoVerraco (idCambioEstadoVerraco){
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_CAMBIO_ESTADO_VERRACO,
    idCambioEstadoVerraco
  }
}

export function recuperarDatosModalCambioEstadoVerracoSuccess (registroModificar){
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_CAMBIO_ESTADO_VERRACO_SUCCESS,
    registroModificar
  }
}

export function editarCambioEstadoVerraco (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.EDITAR_CAMBIO_ESTADO_VERRACO,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function duplicarDatosModalCambioEstadoVerraco (idCambioEstadoVerraco){
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_CAMBIO_ESTADO_VERRACO,
    idCambioEstadoVerraco
  }
}

export function duplicarDatosModalCambioEstadoVerracoSuccess (registroDuplicar){
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_CAMBIO_ESTADO_VERRACO_SUCCESS,
    registroDuplicar
  }
}

export function onDeleteCambioEstadoVerraco (idCambioEstadoVerraco){
  return {
    type: actionTypes.ON_DELETE_CAMBIO_ESTADO_VERRACO,
    idCambioEstadoVerraco
  }
}

export function seleccionarTodosCambioEstadoVerraco (){
  return {
    type: actionTypes.SELECCIONAR_TODOS_CAMBIO_ESTADO_VERRACOS
  }
}

export function deSeleccionarTodosCambioEstadoVerraco (){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_CAMBIO_ESTADO_VERRACOS
  }
}

export function deleteSeleccionCambioEstadoVerraco (list) {
  return {
    type: actionTypes.DELETE_SELECCION_CAMBIO_ESTADO_VERRACO,
    list
  }
}

export function fetchFiltrarCambioEstadoVerraco (values) {
  return {
    type: actionTypes.FETCH_FILTRAR_CAMBIO_ESTADO_VERRACO,
    values
  }
}

export function fetchFiltrarCambioEstadoVerracoSuccess (filtros) {
  return {
    type: actionTypes.FETCH_FILTRAR_CAMBIO_ESTADO_VERRACO_SUCCESS,
    filtros
  }
}

export function crearCsvCambioEstado(list){
  return {
    type: actionTypes.CREATE_CSV_CAMBIO_ESTADO,
    list
  }
}

export function crearCsvCambioEstadoSuccess(datosExcelCambioEstado){
  return {
    type: actionTypes.CREATE_CSV_CAMBIO_ESTADO_SUCCESS,
    datosExcelCambioEstado
  }
}
export function imprimirPdfCambioEstado(list){
  return {
    type: actionTypes.IMPRIMIR_PDF_CAMBIO_ESTADO,
    list
  }
}

export function imprimirPdfCambioEstadoSuccess(datosPdfCambioEstado){
  return {
    type: actionTypes.IMPRIMIR_PDF_CAMBIO_ESTADO_SUCCESS,
    datosPdfCambioEstado
  }
}

export default {
  enviarFechaCambioEstado,
  enviarFechaCambioEstadoSuccess,
  submitNuevoCambioEstadoVerraco,
  submitNuevoCambioEstadoVerracoSuccess,
  restartCambioEstadoVerraco,
  fetchCambioEstadoVerraco,
  fetchCambioEstadoVerracoSuccess,
  abrirModalVacioCambioEstado,
  recuperarDatosModalCambioEstadoVerraco,
  recuperarDatosModalCambioEstadoVerracoSuccess,
  editarCambioEstadoVerraco,
  duplicarDatosModalCambioEstadoVerraco,
  duplicarDatosModalCambioEstadoVerracoSuccess,
  onDeleteCambioEstadoVerraco,
  seleccionarTodosCambioEstadoVerraco,
  deSeleccionarTodosCambioEstadoVerraco,
  deleteSeleccionCambioEstadoVerraco,
  fetchFiltrarCambioEstadoVerraco,
  fetchFiltrarCambioEstadoVerracoSuccess,
  crearCsvCambioEstado,
  crearCsvCambioEstadoSuccess,
  imprimirPdfCambioEstado,
  imprimirPdfCambioEstadoSuccess
}
