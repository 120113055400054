import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import './ModalRegistroAlimentacion.scss'
import ListadoPage from '../../../containers/page/ListadoPage'
import InputCheckBox from '../../comun/form/InputCheckBox'
import InputSelect from '../../comun/form/InputSelect'
import { DoneAll } from '@material-ui/icons'
import {estadoVerraco as estadoVerracoConstants} from '../../../constants/estadoVerraco'
import {estadoVerraco as estadoVerracoStilyzer} from '../../comun/table/Stilyzers'

class ModalVerracoRegistroAlimentacion extends Component {
  constructor () {
    super()
    this.state = {
      fitrosSeleccionados: []
    }
  }

  anadirVerracoModalRegistroAlimentacion (values) {
    const itemsSeleccion = this.props.registroAlimentacion.listVerracosEnRegistroAlimentacion.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idVerraco': row.idVerraco,
          'idElemento': row.idElemento
        }
      )
    })
    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    if (Object.keys(list).length > 0) {
      this.props.actions.anadirVerracoModalRegistroAlimentacion(list)
      this.props.change('seleccionar-todos-modal-verraco-registro-alimentacion', false)
    }
  }

  guardarYnuevoGrupoVerraco(values){
    this.props.actions.guardarYnuevoGrupoVerraco(values)
  }

  intercambiarCheckModalVerracosRegistroAlimentacion (value) {
    if (value === true) {
      this.props.actions.seleccionarTodosModalVerracoRegistroAlimentacion()
    } else {
      this.props.actions.deSeleccionarTodosModalVerracoRegistroAlimentacion()
    }
  }

  componentDidMount () {
    this.props.actions.deSeleccionarTodosModalVerracoRegistroAlimentacion()
    this.props.actions.comboVerraco()
    this.props.actions.comboRaza()
    this.props.actions.comboLineaGenetica()
    this.props.actions.comboUbicacionPadre()
    this.props.actions.comboNombreGrupoVerracoActiva()
    this.props.change('estado', {value: 3, label: this.props.t('COMUN.COMBOS.COMBO_ESTADO_VERRACO.ACTIVO')})
    let valores = []
    valores.estado = {value: 3, label: this.props.t('COMUN.COMBOS.COMBO_ESTADO_VERRACO.ACTIVO')}
    valores.list = Object.values(this.props.registroAlimentacion.verracosAnadidos)
    this.props.actions.fetchFiltrarVerracoModalRegistroAlimentacion(valores)
  }

  componentDidUpdate (prevProps) {
    if (this.props.registroAlimentacion.verracosAnadidos !== prevProps.registroAlimentacion.verracosAnadidos) {
      this.props.change('idVerraco', null)
      this.props.change('idRaza', null)
      this.props.change('idLinea', null)
      this.props.change('estado', {value: 3, label: this.props.t('COMUN.COMBOS.COMBO_ESTADO_VERRACO.ACTIVO')})
      this.props.change('idUbicacion', null)
      this.props.change('grupoVerraco', null)
      let valores = []
      valores.estado = {value: 3, label: this.props.t('COMUN.COMBOS.COMBO_ESTADO_VERRACO.ACTIVO')}
      valores.list = Object.values(this.props.registroAlimentacion.verracosAnadidos)
      this.props.actions.fetchFiltrarVerracoModalRegistroAlimentacion(valores)
      this.props.actions.deSeleccionarTodosModalVerracoRegistroAlimentacion()
    }
  }

  fetchFiltrarVerracoModalRegistroAlimentacion (values) {
    values.list = Object.values(this.props.registroAlimentacion.verracosAnadidos)
    this.props.actions.fetchFiltrarVerracoModalRegistroAlimentacion(values)
  }

  closeModalAnadirVerraco(){
    this.props.actions.cerrarModalVerracoRegistroAlimentacion()
    this.props.change('idVerraco', null)
    this.props.change('idRaza', null)
    this.props.change('idLinea', null)
    this.props.change('estado', {value: 3, label: this.props.t('COMUN.COMBOS.COMBO_ESTADO_VERRACO.ACTIVO')})
    this.props.change('idUbicacion', null)
    this.props.change('grupoVerraco', null)
    this.props.actions.deSeleccionarTodosModalVerracoRegistroAlimentacion()
    this.props.change('seleccionar-todos-modal-verraco-registro-alimentacion', false)
    if (Object.keys(this.props.registroAlimentacion.filtrosModalVerracoRegistroAlimentacion).length > 0) {
      this.props.registroAlimentacion.filtrosModalVerracoRegistroAlimentacion.idVerraco = null
      this.props.registroAlimentacion.filtrosModalVerracoRegistroAlimentacion.idRaza = null
      this.props.registroAlimentacion.filtrosModalVerracoRegistroAlimentacion.idLinea = null
      this.props.registroAlimentacion.filtrosModalVerracoRegistroAlimentacion.estado = {value: 3, label: this.props.t('COMUN.COMBOS.COMBO_ESTADO_VERRACO.ACTIVO')}
      this.props.registroAlimentacion.filtrosModalVerracoRegistroAlimentacion.idUbicacion = null
      this.props.registroAlimentacion.filtrosModalVerracoRegistroAlimentacion.grupoVerraco = null
    }
    let valores = []
    valores.estado = {value: 3, label: this.props.t('COMUN.COMBOS.COMBO_ESTADO_VERRACO.ACTIVO')}
    valores.list = Object.values(this.props.registroAlimentacion.verracosAnadidos)
    this.props.actions.fetchFiltrarVerracoModalRegistroAlimentacion(valores)
  }

  quitarFiltros (value) {
    let values = this.props.registroAlimentacion.filtrosModalVerracoRegistroAlimentacion
    values[value] = null
    this.props.actions.fetchFiltrarVerracoModalRegistroAlimentacion(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }

  render () {
    const {
      t, handleSubmit, numeroRegistrosPorPagina, paginaActual,
      actions: { cerrarModalVerracoRegistroAlimentacion },
      combos: {comboRaza, comboLineaGenetica, comboUbicacionPadre, comboVerraco, comboNombreGrupoVerracoActiva},
      registroAlimentacion: {showModalVerracoRegistroAlimentacion, listVerracosEnRegistroAlimentacion, checkedVerracosRegistroAlimentacion, filtrosModalVerracoRegistroAlimentacion, verracosAnadidos}
    } = this.props

    let comboEstadoVerraco = this.props.combos.comboEstadoVerraco
  comboEstadoVerraco = comboEstadoVerraco.map((estadoVerraco) => {
    return {...estadoVerraco, label: t(estadoVerraco.label)}
  })

    listVerracosEnRegistroAlimentacion.forEach(
      (row) => row.idElemento = row.idVerraco + 'verracoAlimentacion'
    )

    const tableVerracos = {
      id: 'verracosTable',
      multiHeader: false,
      hasCheckbox: true,
      hasOptions: false,
      mostrarModificar: false,
      columns: [
        {id: 'codVerraco', name: t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.TABLA.NOMBRE')},
        {id: 'nombreRaza', name: t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.TABLA.RAZA')},
        {id: 'nombreLinea', name: t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.TABLA.LINEA_GENETICA')},
        {id: 'estadoverraco', name: t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.TABLA.ESTADO'), stilyzer: estadoVerracoStilyzer}
      ],
      rows: listVerracosEnRegistroAlimentacion,
      rowsVerraco: true,
      initialValues: {},
      colorearRow: (row) => {
        switch(row.estadoverraco) {
          case estadoVerracoConstants.CUARENTENA:
            return 'verraco-en-cuarentena';
          case estadoVerracoConstants.PRESENTADO:
            return 'verraco-en-presente';
          case estadoVerracoConstants.ACTIVO:
            return 'verraco-en-activo';
          case estadoVerracoConstants.BAJA:
            return 'verraco-en-baja';
          case estadoVerracoConstants.ELIMINADO:
            return 'verraco-en-eliminado';
          default:
            return 'verraco-en-ningun-estado';
        }
      }
    }

    tableVerracos.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableVerracos.initialValues['check' + row.idElemento] = checkedVerracosRegistroAlimentacion
        }
      }
    )

    return (
      <Modal show={showModalVerracoRegistroAlimentacion} onHide={() => this.closeModalAnadirVerraco()} aria-labelledby="contained-modal-title-lg" size="lg" className="modal-grupo-verraco" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            {t('GRUPOS_VERRACO.MODAL.VERRACOS_ASIGNAR')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <div className="tabla-filtros-modal-grupo-verracos">
                <form className="custom-form">
                  <Field
                    colSm={2}
                    id="grupoVerraco"
                    name="grupoVerraco"
                    controlLabel={t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.FILTROS.GRUPO_VERRACO')}
                    component={InputSelect}
                    options={comboNombreGrupoVerracoActiva}
                    valueKey="value"
                    labelKey="label"
                    customClass='select-ubicacion'
                    onInputChange={handleSubmit(this.fetchFiltrarVerracoModalRegistroAlimentacion.bind(this))}
                  />
                  <Field
                    colSm={2}
                    id="idVerraco"
                    name="idVerraco"
                    controlLabel={t('FICHA_VERRACO.FILTROS.VERRACO')}
                    component={InputSelect}
                    options={comboVerraco}
                    valueKey="value"
                    labelKey="label"
                    customClass='select-verraco'
                    onInputChange={handleSubmit(this.fetchFiltrarVerracoModalRegistroAlimentacion.bind(this))}
                  />
                  <Field
                    colSm={2}
                    id="idRaza"
                    name="idRaza"
                    controlLabel={t('FICHA_VERRACO.FILTROS.RAZA')}
                    component={InputSelect}
                    options={comboRaza}
                    valueKey="value"
                    labelKey="label"
                    customClass='select-raza'
                    onInputChange={handleSubmit(this.fetchFiltrarVerracoModalRegistroAlimentacion.bind(this))}
                  />
                  <Field
                    colSm={2}
                    id="idLinea"
                    name="idLinea"
                    controlLabel={t('FICHA_VERRACO.FILTROS.LIN_GENETICA')}
                    component={InputSelect}
                    options={comboLineaGenetica}
                    valueKey="value"
                    labelKey="label"
                    customClass='select-lin-genetica'
                    onInputChange={handleSubmit(this.fetchFiltrarVerracoModalRegistroAlimentacion.bind(this))}
                  />
                  <Field
                    colSm={2}
                    id="estado"
                    name="estado"
                    controlLabel={t('FICHA_VERRACO.FILTROS.ESTADO_VERRACO')}
                    component={InputSelect}
                    options={comboEstadoVerraco}
                    valueKey="value"
                    labelKey="label"
                    customClass='select-estado-verraco'
                    onInputChange={handleSubmit(this.fetchFiltrarVerracoModalRegistroAlimentacion.bind(this))}
                  />
                  <Field
                    colSm={2}
                    id="idUbicacion"
                    name="idUbicacion"
                    controlLabel={t('CAMBIO_ESTADO_VERRACOS.FILTROS.UBICACION')}
                    component={InputSelect}
                    options={comboUbicacionPadre}
                    valueKey="value"
                    labelKey="label"
                    customClass='select-ubicacion'
                    onInputChange={handleSubmit(this.fetchFiltrarVerracoModalRegistroAlimentacion.bind(this))}
                  />
                  <div className="clearfix"></div>
                  <div className="aplicar-tabla-filtros">
                    <div className="filtros-anadidos">
                      {
                        Object.keys(filtrosModalVerracoRegistroAlimentacion).length > 0 && (
                          filtrosModalVerracoRegistroAlimentacion.grupoVerraco && filtrosModalVerracoRegistroAlimentacion.grupoVerraco !== null ? (
                            <span className="badge-dark">{t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.FILTROS.GRUPO_VERRACO') + ': ' + filtrosModalVerracoRegistroAlimentacion.grupoVerraco.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('grupoVerraco')}> x </button></span>
                          ) : null
                        )
                      }
                      {
                        Object.keys(filtrosModalVerracoRegistroAlimentacion).length > 0 && (
                          (filtrosModalVerracoRegistroAlimentacion.idVerraco && filtrosModalVerracoRegistroAlimentacion.idVerraco !== null && filtrosModalVerracoRegistroAlimentacion.idVerraco !== '') ? (
                            <span className="badge-dark">{t('FICHA_VERRACO.FILTROS.VERRACO') + ': ' + filtrosModalVerracoRegistroAlimentacion.idVerraco.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('idVerraco')}> x </button></span>
                          ) : null
                        )
                      }
                      {
                        Object.keys(filtrosModalVerracoRegistroAlimentacion).length > 0 && (
                          filtrosModalVerracoRegistroAlimentacion.idRaza && filtrosModalVerracoRegistroAlimentacion.idRaza !== null ? (
                            <span className="badge-dark">{t('FICHA_VERRACO.FILTROS.RAZA') + ': ' + filtrosModalVerracoRegistroAlimentacion.idRaza.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('idRaza')}> x </button></span>
                          ) : null
                        )
                      }
                      {
                        Object.keys(filtrosModalVerracoRegistroAlimentacion).length > 0 && (
                          filtrosModalVerracoRegistroAlimentacion.idLinea && filtrosModalVerracoRegistroAlimentacion.idLinea !== null ? (
                            <span className="badge-dark">{t('FICHA_VERRACO.FILTROS.LIN_GENETICA') + ': ' + filtrosModalVerracoRegistroAlimentacion.idLinea.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('idLinea')}> x </button></span>
                          ) : null
                        )
                      }
                      {
                        Object.keys(filtrosModalVerracoRegistroAlimentacion).length > 0 && (
                          filtrosModalVerracoRegistroAlimentacion.estado && filtrosModalVerracoRegistroAlimentacion.estado !== null ? (
                            <span className="badge-dark">{t('FICHA_VERRACO.FILTROS.ESTADO_VERRACO') + ': ' + filtrosModalVerracoRegistroAlimentacion.estado.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('estado')}> x </button></span>
                          ) : null
                        )
                      }
                      {
                        Object.keys(filtrosModalVerracoRegistroAlimentacion).length > 0 && (
                          filtrosModalVerracoRegistroAlimentacion.idUbicacion && filtrosModalVerracoRegistroAlimentacion.idUbicacion !== null ? (
                            <span className="badge-dark">{t('CAMBIO_ESTADO_VERRACOS.FILTROS.UBICACION') + ': ' + filtrosModalVerracoRegistroAlimentacion.idUbicacion.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('idUbicacion')}> x </button></span>
                          ) : null
                        )
                      }
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </form>
              </div>
            </Col>
            <div className="tabla-verracos-en-grupo-verracos">
              <div className="filtros-tabla-modal-grupo-verracos">
                <div className="custom-check-w">
                  <Field
                    id="seleccionar-todos-modal-verraco-registro-alimentacion"
                    name="seleccionar-todos-modal-verraco-registro-alimentacion"
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheckModalVerracosRegistroAlimentacion(value)}
                  />
                </div>
              </div>
              <ListadoPage t_key="GRUPOS_VERRACO." noTitle={true} table={tableVerracos}>
              </ListadoPage>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" className="btn btn-primary" onClick={handleSubmit(this.anadirVerracoModalRegistroAlimentacion.bind(this))}>{t('GRUPOS_VERRACO.MODAL.ANADIR')}</Button>
          <Button type="button" className="btn btn-white" onClick={() => this.closeModalAnadirVerraco()}>{t('GRUPOS_VERRACO.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalVerracoRegistroAlimentacion',
  enableReinitialize: true
})(ModalVerracoRegistroAlimentacion))