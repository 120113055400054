export const modulos = {
  BASICO: "1",
  INFORMES: "2",
  PEDIDOS_CLIENTES: "3",
  TRAZABILIDAD_BIOSEGURIDAD: "4"
}

export const translateModulos = {
  "1": 'COMUN.COMBOS.LICENCIAS.BASICO',
  "2": 'COMUN.COMBOS.LICENCIAS.INFORMES',
  "3": 'COMUN.COMBOS.LICENCIAS.PEDIDOS_CLIENTES',
  "4": 'COMUN.COMBOS.LICENCIAS.TRAZABILIDAD_BIOSEGURIDAD',
}