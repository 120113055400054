import React, { Component } from 'react'
import ListadoPage from '../../containers/page/ListadoPage'
import ModalEnvasadoPage from '../../containers/envasado/ModalEnvasadoPage'
import FiltrosEnvasadoPage from '../../containers/envasado/FiltrosEnvasadoPage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import { date_parser, dateTimeZones } from '../../util/formatFunctions'
import {permisos as permisosConstant} from '../../constants/permisos'

class Envasado extends Component {
  componentDidMount () {
    document.title = this.props.t('MENU.EXTRACCIONES.ENVASADO') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    let fechaActual = date_parser(dateTimeZones(this.props.auth.zonaHorariaString), this.props.auth.formaFechaHora)
    let estadoLabel = this.props.t(`COMUN.COMBOS.ESTADO_ENVASADO.${this.props.combos.comboEnvasado[1].label}`)
    this.props.actions.fetchFiltrarEnvasado({estado: {...this.props.combos.comboEnvasado[1], label: estadoLabel}, fechaEntrada: fechaActual, desde: fechaActual})
  }

  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual,
      envasado: { list, checkedFiltrosEnvasado },
      actions: {abrirDetallesEnvasado, abrirModalEnvasadoMultiDosisDesdeListadoPrincipal}
    } = this.props

    list.forEach(
      (row) => row.idElemento = row.idEnvasado
    )

    const table = {
      id: 'envasadoTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: true,
      columns: [
        {id: 'codVerraco', name: t('EXTRACCIONES.ENVASADO.TABLA.COD_VERRACO')},
        {id: 'codigo', name: t('EXTRACCIONES.ENVASADO.TABLA.CODIGO'), type: 'numero', numDecimales: 0},
        {id: 'nombrePool', name: t('EXTRACCIONES.ENVASADO.TABLA.POOL')},
        {id: 'fecha', name: t('EXTRACCIONES.ENVASADO.TABLA.FECHA'), type: 'fecha'},
        {id: 'numeroDosis', name: t('EXTRACCIONES.ENVASADO.TABLA.NUMERO_DOSIS')},
        {id: 'nombreRaza', name: t('EXTRACCIONES.ENVASADO.TABLA.RAZA')},
        {id: 'nombreLinea', name: t('EXTRACCIONES.ENVASADO.TABLA.LINEA_GENETICA')},
        {id: 'nombreTipoDosis', name: t('EXTRACCIONES.ENVASADO.TABLA.TIPO_DOSIS')}
      ],
      rows: list,
      filtros: <FiltrosEnvasadoPage />,
      mostrarVerDetalle: false,
      mostrarModificar: true,
      mostrarDuplicar: false,
      mostrarCambioEstado: false,
      mostrarEliminar: false,
      onEdit: (row) => abrirDetallesEnvasado(row.idEnvasado),
      mostrarEnvasar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ENVASADO] > 1),
      envasar: (row) => abrirModalEnvasadoMultiDosisDesdeListadoPrincipal(row.idEnvasado, row.idEnvasadoMultiDosis),
      leyenda: [
        {name: t('EXTRACCIONES.ENVASADO.LEYENDA.ENVASADO_ELIMINADO'), color: '#c4415d'},
        {name: t('EXTRACCIONES.ENVASADO.LEYENDA.ENVASADO'), color: '#aacfdc'}
      ],
      initialValues: {},
      colorearRow: (row) => {
        let classRow = ''
        if (row.envasadoEliminado){
          classRow = 'envasado-eliminado'
        } else if (row.envasado) {
          classRow = 'envasado'
        }
        return classRow
      }
    }
    table.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          table.initialValues['check' + row.idElemento] = checkedFiltrosEnvasado
        }
      }
    )
    return (
      <div>
        <SimpleModalPage/>
        <MensajeAlertaPage/>
        <ModalEnvasadoPage/>
        <ListadoPage t_key="EXTRACCIONES.ENVASADO." table={table}>
        </ListadoPage>
      </div>
    )
  }
}

export default Envasado