import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalIndex from '../../components/fichaVerraco/ModalIndex'
import {submitNuevaIndex, editarIndex, guardarYnuevoIndex, closeModalIndex} from '../../actions/fichaVerraco/fichaVerraco'

export function mapStateToProps (state) {
  return {
    ...state.fichaVerraco,
    auth: state.auth,
    fichaVerraco: {...state.fichaVerraco}
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeModalIndex,
      submitNuevaIndex,
      editarIndex,
      guardarYnuevoIndex
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalIndex))