import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import auth from './auth/auth'
import common from './common'
import combos from './combos/combos'
import panelControl from './panelControl/panelControl'
import raza from './raza/raza'
import lineaGenetica from './lineaGenetica/lineaGenetica'
import grupoVerraco from './grupoVerraco/grupoVerraco'
import operario from './operario/operario'
import tipoInstalacion from './tipoInstalacion/tipoInstalacion'
import instalacion from './instalacion/instalacion'
import fichaVerraco from './fichaVerraco/fichaVerraco'
import cambioEstadoVerraco from './cambioEstadoVerraco/cambioEstadoVerraco'
import movimientoVerraco from './movimientoVerraco/movimientoVerraco'
import tipoAlimentacion from './planSanitario/tipoAlimentacion/tipoAlimentacion'
import visita from './planSanitario/visita/visita'
import registroAlimentacion from './planSanitario/registroAlimentacion/registroAlimentacion'
import analisisExtracciones from './analisisExtracciones/analisisExtracciones'
import contajeMaquinas from './analisisExtracciones/contajeMaquinas'
import camaraView from './analisisExtracciones/camaraView'
import poolExtracciones from './poolExtracciones/poolExtracciones'
import tipoIntervencionSanitaria from './planSanitario/tipoIntervencionSanitaria/tipoIntervencionSanitaria'
import registroIntervencionSanitaria from './planSanitario/registroIntervencionSanitaria/registroIntervencionSanitaria'
import bioseguridadHigiene from './planSanitario/bioseguridadHigiene/bioseguridadHigiene'
import dilucion from './dilucion/dilucion'
import ruta from './ruta/ruta'
import transporte from './transporte/transporte'
import producto from './producto/producto'
import escandallo from './escandallo/escandallo'
import entradaMercancia from './entradaMercancia/entradaMercancia'
import validacionMateriaPrima from './validacionMateriaPrima/validacionMateriaPrima'
import trazabilidadMaterial from './trazabilidadMaterial/trazabilidadMaterial'
import materialDisponible from './trazabilidadMaterial/materialDisponible'
import ajusteStock from './ajusteStock/ajusteStock'
import envasado from './envasado/envasado'
import seguimiento from './seguimiento/seguimiento'
import cliente from './cliente/cliente'
import planing from './planing/planing'
import albaran from './albaran/albaran'
import pedidosVenta from './pedidosVenta/pedidosVenta'
import foso from './foso/foso'
import conectividad from './conectividad/conectividad'
import agenda from './agenda/agenda'
import informes from './informes/informes'
import empresa from './empresa/empresa'
import usuarios from './usuarios/usuarios'
import grupoUsuarios from './grupoUsuarios/grupoUsuarios'
import perfil from './perfil/perfil'
import plano from './plano/plano'
import copiaSeguridad from './copiaSeguridad/copiaSeguridad'
import licencias from './admin/licencias/licencias'
import dashboard from './dashboard/dashboard'
import cargaIndex from './cargaIndex/cargaIndex'
import importarDatos from './importarDatos/importarDatos'
import notificaciones from './notificaciones/notificaciones'

const reducers = {
  auth,
  common,
  combos,
  panelControl,
  raza,
  lineaGenetica,
  grupoVerraco,
  operario,
  tipoInstalacion,
  instalacion,
  fichaVerraco,
  cambioEstadoVerraco,
  movimientoVerraco,
  tipoAlimentacion,
  visita,
  registroAlimentacion,
  analisisExtracciones,
  contajeMaquinas,
  camaraView,
  poolExtracciones,
  tipoIntervencionSanitaria,
  registroIntervencionSanitaria,
  bioseguridadHigiene,
  dilucion,
  ruta,
  transporte,
  producto,
  escandallo,
  entradaMercancia,
  validacionMateriaPrima,
  trazabilidadMaterial,
  materialDisponible,
  ajusteStock,
  envasado,
  seguimiento,
  cliente,
  planing,
  albaran,
  pedidosVenta,
  foso,
  conectividad,
  agenda,
  informes,
  empresa,
  usuarios,
  grupoUsuarios,
  perfil,
  plano,
  copiaSeguridad,
  licencias,
  dashboard,
  cargaIndex,
  importarDatos,
  notificaciones,
  form: formReducer,
  // routing: routerReducer
}

const appReducer = combineReducers(reducers)

const rootReducer = (state, action) => {
  if (action.type === 'CAMBIAR_CENTRO_SUCCESS' || action.type === 'LOGOUT_SUCCESS') {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer
