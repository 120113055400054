import React, { Component } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import {reduxForm, Field, setSubmitFailed} from 'redux-form'
import { Warning } from '@material-ui/icons'
import { required } from '../../util/validationFunctions'
import Button from '../comun/button/Button'
import ButtonChangeRecord from '../comun/button/ButtonChangeRecord'
import ButtonLockable from '../comun/button/ButtonLockable'
import FormSubtitle from '../comun/form/FormSubtitle'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import InputText from '../comun/form/InputText'
import InputSelect from '../comun/form/InputSelect'
import InputTextArea from '../comun/form/InputTextArea'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputNumerical from '../comun/form/InputNumerical'
import './Escandallo.scss'
import FiltrosEscandalloAnalisisPage from '../../containers/escandallo/FiltrosEscandalloAnalisisPage'
import ModalAnadirEscandalloPage from '../../containers/escandallo/ModalAnadirEscandalloPage'
import {permisos as permisosConstant} from '../../constants/permisos'

class ModalEscandallo extends Component {
  constructor () {
    super()
    this.state = {
      mostrarEnviarAviso: false,
      marcarFiltrosAnalisis: false,
      marcarFiltrosDilucion: false,
      marcarFiltrosEnvasado: false
    }
  }

  componentDidMount () {
    this.props.change('activo', true)
    this.props.actions.comboTipoDosis()
  }

  componentDidUpdate (prevProps) {
    if (this.props.submitSucceeded !== prevProps.submitSucceeded && this.state.mostrarEnviarAviso === true) {
      this.props.dispatch(setSubmitFailed('ModalEscandallo'))
    }
  }

  closeModalEscandallo () {
    this.props.actions.closeModalEscandallo()
    this.setState({mostrarEnviarAviso: false})
  }

  submitNuevoEscandallo (values) {
    return new Promise((resolve, reject) => {
      values.escandalloAnalisis = this.props.escandalloAnalisis
      values.escandalloDilucion = this.props.escandalloDilucion
      values.escandalloEnvasado = this.props.escandalloEnvasado

      if (Object.keys(this.props.escandalloAnalisis).length === 0 && Object.keys(this.props.escandalloDilucion).length === 0 && Object.keys(this.props.escandalloEnvasado).length === 0){
        this.setState({mostrarEnviarAviso: true})
        window.scrollTo(0, 0)
        reject()
      } else {
        if (Object.keys(this.props.data).length > 0){
          this.props.actions.editarEscandallo(values, resolve, reject)
        } else {
          this.props.actions.submitNuevoEscandallo(values, resolve, reject)
        }
        this.setState({mostrarEnviarAviso: false})
      }
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  guardarYnuevoEscandallo (values) {
    return new Promise((resolve, reject) => {
      values.escandalloAnalisis = this.props.escandalloAnalisis
      values.escandalloDilucion = this.props.escandalloDilucion
      values.escandalloEnvasado = this.props.escandalloEnvasado
      if (Object.keys(this.props.escandalloAnalisis).length === 0 && Object.keys(this.props.escandalloDilucion).length === 0 && Object.keys(this.props.escandalloEnvasado).length === 0){
        this.setState({mostrarEnviarAviso: true})
        window.scrollTo(0, 0)
        reject()
      } else {
        this.props.actions.guardarYnuevoEscandallo(values, resolve, reject)
        this.setState({mostrarEnviarAviso: false})
      }
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  duplicar(values){
    return new Promise((resolve, reject) => {
      this.props.duplicar(values, resolve, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  render () {
    const {
      t, handleSubmit, submitting, showModalEscandallo, data, listEscandallo, escandalloAnalisis, numeroRegistrosPorPagina, paginaActual, checkedFiltrosEscandalloAnalisis, checkedFiltrosEscandalloDilucion,
      escandalloDilucion, checkedFiltrosEscandalloEnvasado, escandalloEnvasado, dataEscandallo, duplicar, dataDuplicar,
      actions: {recuperarDatosModalEscandallo, abrirModalAnadirEscandallo, recuperarDatosModalEscandalloAnadido, onDeleteEscandalloAnadido, duplicarDatosModalEscandalloAnadido}
    } = this.props

    // Filtrar los tipos de dosis ya utilizados en otros escandallos.
    let comboTipoDosis = this.props.combos.comboTipoDosis
    let idTipoDosis = this.props.formModalEscandallo && this.props.formModalEscandallo.idTipoDosis && this.props.formModalEscandallo.idTipoDosis.value
    comboTipoDosis = comboTipoDosis.filter(tipoDosis => {
      let estaUtilizadaEnOtroEscandallo = listEscandallo.some(escandallo => {
        return escandallo.idTipoDosis === tipoDosis.value
      })
      return !estaUtilizadaEnOtroEscandallo || (tipoDosis.value === idTipoDosis && (!dataDuplicar || dataDuplicar.length === 0))
    })

    // TABLA ANALISIS
    escandalloAnalisis.forEach(
      (row, idRow) => row.idElemento = idRow + 1 + 'analisis'
    )

    const tableEscandalloAnalisis = {
      id: 'escandalloAnalisisTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ESCANDALLOS] > 1) && !submitting,
      hasOptions: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ESCANDALLOS] > 1) && !submitting,
      mostrarCambioEstado: false,
      tablaModal: true,
      idBlockOptions: 1,
      columns: [
        {id: 'nombreProducto', name: t('ALMACEN.ESCANDALLO.SUBTABLA.PRODUCTO')},
        {id: 'cantidadEscandallo', name: t('ALMACEN.ESCANDALLO.SUBTABLA.CANTIDAD'), type: 'numero'}
      ],
      rows: escandalloAnalisis,
      filtros: !submitting && <FiltrosEscandalloAnalisisPage proceso={1} />,
      mostrarModificar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ESCANDALLOS] > 1),
      onEdit: (row) => !submitting && recuperarDatosModalEscandalloAnadido(row.idElemento, row.proceso.value),
      data: dataEscandallo,
      mostrarDuplicar: false,
      mostrarEliminar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ESCANDALLOS] > 1),
      onDelete: (row) => onDeleteEscandalloAnadido(row.idElemento, row.proceso.value),
      checkedTabla1: checkedFiltrosEscandalloAnalisis,
      initialValues: {}
    }
    tableEscandalloAnalisis.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableEscandalloAnalisis.initialValues['check' + row.idElemento] = checkedFiltrosEscandalloAnalisis
        }
      }
    )

    // TABLA DILUCION
    escandalloDilucion.forEach(
      (row, idRow) => row.idElemento = idRow + 1 + 'dilucion'
    )

    const tableEscandalloDilucion = {
      id: 'escandalloDilucionTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ESCANDALLOS] > 1) && !submitting,
      hasOptions: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ESCANDALLOS] > 1) && !submitting,
      mostrarCambioEstado: false,
      tablaModal: true,
      idBlockOptions: 2,
      columns: [
        {id: 'nombreProducto', name: t('ALMACEN.ESCANDALLO.SUBTABLA.PRODUCTO')},
        {id: 'cantidadEscandallo', name: t('ALMACEN.ESCANDALLO.SUBTABLA.CANTIDAD'), type: 'numero'}
      ],
      rows: escandalloDilucion,
      filtros: !submitting && <FiltrosEscandalloAnalisisPage proceso={2} />,
      mostrarModificar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ESCANDALLOS] > 1),
      onEdit: (row) => !submitting && recuperarDatosModalEscandalloAnadido(row.idElemento, row.proceso.value),
      data: dataEscandallo,
      mostrarDuplicar: false,
      mostrarEliminar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ESCANDALLOS] > 1),
      onDelete: (row) => onDeleteEscandalloAnadido(row.idElemento, row.proceso.value),
      checkedTabla2: checkedFiltrosEscandalloDilucion,
      initialValues: {}
    }
    tableEscandalloDilucion.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableEscandalloDilucion.initialValues['check' + row.idElemento] = checkedFiltrosEscandalloDilucion
        }
      }
    )

    // TABLA ENVASADO
    escandalloEnvasado.forEach(
      (row, idRow) => row.idElemento = idRow + 1 + 'envasado'
    )

    const tableEscandalloEnvasado = {
      id: 'escandalloEnvasadoTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ESCANDALLOS] > 1) && !submitting,
      hasOptions: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ESCANDALLOS] > 1) && !submitting,
      mostrarCambioEstado: false,
      tablaModal: true,
      idBlockOptions: 3,
      columns: [
        {id: 'nombreProducto', name: t('ALMACEN.ESCANDALLO.SUBTABLA.PRODUCTO')},
        {id: 'cantidadEscandallo', name: t('ALMACEN.ESCANDALLO.SUBTABLA.CANTIDAD'), type: 'numero'}
      ],
      rows: escandalloEnvasado,
      filtros: !submitting && <FiltrosEscandalloAnalisisPage proceso={3} />,
      mostrarModificar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ESCANDALLOS] > 1),
      onEdit: (row) => !submitting && recuperarDatosModalEscandalloAnadido(row.idElemento, row.proceso.value),
      data: dataEscandallo,
      mostrarDuplicar: false,
      mostrarEliminar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ESCANDALLOS] > 1),
      onDelete: (row) => onDeleteEscandalloAnadido(row.idElemento, row.proceso.value),
      checkedTabla3: checkedFiltrosEscandalloEnvasado,
      initialValues: {}
    }
    tableEscandalloEnvasado.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableEscandalloEnvasado.initialValues['check' + row.idElemento] = checkedFiltrosEscandalloEnvasado
        }
      }
    )

    return (
      <div>
        <ModalAnadirEscandalloPage
          duplicar={(row) =>duplicarDatosModalEscandalloAnadido(row.idElemento, row.proceso.value)}
        />
        <Modal show={showModalEscandallo} onHide={() => this.closeModalEscandallo()} bsSize="large" dialogClassName="xl" backdrop="static" aria-labelledby="contained-modal-title-lg">
        <form className="form-escandallo">
          <Modal.Header closeButton={!submitting}>
            <Modal.Title>
            {
              Object.keys(this.props.data).length > 0 ? (
                t('ALMACEN.ESCANDALLO.MODAL.TITLE_EDITAR')
              ) : (
                Object.keys(this.props.dataDuplicar).length > 0 ? (
                  t('ALMACEN.ESCANDALLO.MODAL.TITLE_DUPLICAR')
                ) : (
                  t('ALMACEN.ESCANDALLO.MODAL.TITLE_NUEVO')
                )
              )
            }
            </Modal.Title>
            {!submitting && data && data.escandallo && data.escandallo.idEscandallo && (
              <ButtonChangeRecord list={listEscandallo} idElemento="idEscandallo" currentId={data.escandallo.idEscandallo} getNextRecord={recuperarDatosModalEscandallo} />
            )}
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Field
                colSm={3}
                id="nombre"
                name="nombre"
                component={InputText}
                controlLabel={t('ALMACEN.ESCANDALLO.MODAL.NOMBRE') + '*'}
                customClass="nombre"
                validate={required}
                maxLength={true}
                valorMaxLength={80}
              />
              <Field
                colSm={3}
                id="idTipoDosis"
                name="idTipoDosis"
                component={InputSelect}
                options={comboTipoDosis}
                controlLabel={t('ALMACEN.ESCANDALLO.MODAL.TIPO_DOSIS') + '*'}
                customClass='tipo-dosis'
                validate={required}
                isClearable={false}
              />
              <Field
                id="escandallo-activo"
                name="activo"
                colSm={5}
                inline
                component={InputCheckBox}
                claseActivo="onoffswitch-label"
                controlLabel={t('ALMACEN.ESCANDALLO.MODAL.ACTIVO')}
                customClass="check-escandallo"
              />
              <div className="clearfix"></div>
              {
                this.state.mostrarEnviarAviso === true && (
                  <Col sm={12}>
                    <span className='help-block'>
                      <Warning /><span className="texto-alerta-escandallo">{t('ALMACEN.ESCANDALLO.MODAL.VALIDACION_TABLAS')}</span>
                    </span>
                  </Col>
                )
              }
              <Col sm={12}>
                <FormSubtitle>{t('ALMACEN.ESCANDALLO.SUBTABLA.TITLE_ANALISIS')}</FormSubtitle>
                <SimpleTablePage {...tableEscandalloAnalisis}>
                </SimpleTablePage>
              </Col>
              <Col sm={12}>
                <FormSubtitle>{t('ALMACEN.ESCANDALLO.SUBTABLA.TITLE_DILUCION')}</FormSubtitle>
                <SimpleTablePage {...tableEscandalloDilucion}>
                </SimpleTablePage>
              </Col>
              <Col sm={12}>
                <FormSubtitle>{t('ALMACEN.ESCANDALLO.SUBTABLA.TITLE_ENVASADO')}</FormSubtitle>
                <SimpleTablePage {...tableEscandalloEnvasado}>
                </SimpleTablePage>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            {
              Object.keys(this.props.data).length > 0 ? (
                <Button
                  type="button"
                  className="btn btn-white btn-sm"
                  disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.ESCANDALLOS] < 2) || submitting}
                  onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ESCANDALLOS] > 1) && !submitting ? handleSubmit(this.duplicar.bind(this)) : undefined}
                >
                  {t('ALMACEN.ESCANDALLO.MODAL.DUPLICAR')}
                </Button>
              ) : (
                <ButtonLockable
                  processing={submitting}
                  hasIcon={true}
                  showLoaderOnClick={true}
                  type="button"
                  className="btn btn-primary btn-sm"
                  disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.ESCANDALLOS] < 2) || submitting}
                  onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ESCANDALLOS] > 1) && !submitting) ? handleSubmit(this.guardarYnuevoEscandallo.bind(this)) : () => {}}
                  >
                    {t('ALMACEN.ESCANDALLO.MODAL.GUARDAR_Y_NUEVO')}
                  </ButtonLockable>
              )
            }
            <ButtonLockable
              processing={submitting}
              hasIcon={true}
              showLoaderOnClick={true}
              type="button"
              className="btn btn-primary btn-sm"
              disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.ESCANDALLOS] < 2) || submitting}
              onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ESCANDALLOS] > 1) && !submitting) ? handleSubmit(this.submitNuevoEscandallo.bind(this)) : () => {}}
            >
              {t('ALMACEN.ESCANDALLO.MODAL.GUARDAR')}
            </ButtonLockable>
            <Button
              type="button"
              className={"btn btn-white btn-sm" + (submitting ? ' disabled' : '')}
              onClick={() => !submitting && this.closeModalEscandallo()}
            >
              {t('ALMACEN.ESCANDALLO.MODAL.CANCELAR')}
            </Button>
          </Modal.Footer>
          </form>
        </Modal>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'ModalEscandallo',
})(ModalEscandallo))