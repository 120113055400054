import actionTypes from '../../constants/actions/planing/planing'

export function elegirDiasPlaning (value) {
  return {
    type: actionTypes.ELEGIR_DIAS_PLANING,
    value
  }
}

export function abrirModalPlaning () {
  return {
    type: actionTypes.ABRIR_MODAL_PLANING
  }
}

export function abrirModalPlaningSuccess () {
  return {
    type: actionTypes.ABRIR_MODAL_PLANING_SUCCESS
  }
}

export function closeModalPlaning () {
  return {
    type: actionTypes.CLOSE_MODAL_PLANING
  }
}

export function abrirModalSeleccionarVerracoPlaning () {
  return {
    type: actionTypes.ABRIR_MODAL_SELECCIONAR_VERRACO_PLANING
  }
}

export function cerrarModalSeleccionarVerracoPlaning () {
  return {
    type: actionTypes.CERRAR_MODAL_SELECCIONAR_VERRACO_PLANING
  }
}

export function fetchFiltrarPlaning (values) {
  return {
    type: actionTypes.FETCH_FILTRAR_PLANING,
    values
  }
}

export function fetchFiltrarPlaningSuccess (filtros) {
  return {
    type: actionTypes.FETCH_FILTRAR_PLANING_SUCCESS,
    filtros
  }
}

export function fetchPlaningSuccess (listPlaning) {
  return {
    type: actionTypes.FETCH_PLANING_SUCCESS,
    listPlaning
  }
}

export function anadirFechaPlaning (idVerraco, fecha) {
  return {
    type: actionTypes.ANADIR_FECHA_PLANING,
    idVerraco,
    fecha
  }
}

export function eliminarFechaPlaning (idVerraco, fecha) {
  return {
    type: actionTypes.ELIMINAR_FECHA_PLANING,
    idVerraco,
    fecha
  }
}

export function asignarVerracos (verracos) {
  return {
    type: actionTypes.ASIGNAR_VERRACOS,
    verracos
  }
}

export function asignarVerracosSuccess (listVerracos) {
  return {
    type: actionTypes.ASIGNAR_VERRACOS_SUCCCESS,
    listVerracos
  }
}

export function seleccionarTodosVerracosPlaning () {
  return {
    type: actionTypes.SELECCIONAR_TODOS_VERRACOS_PLANING
  }
}

export function deSeleccionarTodosVerracosPlaning () {
  return {
    type: actionTypes.DESELECCIONAR_TODOS_VERRACOS_PLANING
  }
}

export function anadirVerracosPlaning (listVerracosAsignados, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.ANADIR_VERRACOS_PLANING,
    listVerracosAsignados,
    onSuccessCallback,
    onErrorCallback
  }
}

export function anadirVerracosPlaningSuccess (listVerracosAsignados) {
  return {
    type: actionTypes.ANADIR_VERRACOS_PLANING_SUCCESS,
    listVerracosAsignados
  }
}

export function anadirPlaning (values) {
  return {
    type: actionTypes.ANADIR_PLANING,
    values
  }
}

export function cargarVerracosPlanificados (fecha) {
  return {
    type: actionTypes.CARGAR_VERRACOS_PLANIFICADOS,
    fecha
  }
}

export function onDeleteVerracoPlaning (idElemento) {
  return {
    type: actionTypes.ON_DELETE_VERRACO_PLANING,
    idElemento
  }
}

export function onDeleteVerracoPlaningSuccess (listVerracosAsignados) {
  return {
    type: actionTypes.ON_DELETE_VERRACO_PLANING_SUCCESS,
    listVerracosAsignados
  }
}

export function seleccionarTodosModalPlaning () {
  return {
    type: actionTypes.SELECCIONAR_TODOS_MODAL_PLANING
  }
}

export function deSeleccionarTodosModalPlaning () {
  return {
    type: actionTypes.DESELECCIONAR_TODOS_MODAL_PLANING
  }
}

export function deleteSeleccionVerracoPlaning (list, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.DELETE_SELECCION_VERRACO_PLANING,
    list,
    onSuccessCallback,
    onErrorCallback
  }
}

export function abrirModalInicioPlaning () {
  return {
    type: actionTypes.ABRIR_MODAL_INICIO_PLANING
  }
}

export function closeModalInicioPlaning () {
  return {
    type: actionTypes.CLOSE_MODAL_INICIO_PLANING
  }
}

export function obtenerVerracosSinExtraer () {
  return {
    type: actionTypes.OBTENER_VERRACOS_SIN_EXTRAER
  }
}

export function obtenerVerracosSinExtraerSuccess (listVerracosSinExtraer) {
  return {
    type: actionTypes.OBTENER_VERRACOS_SIN_EXTRAER_SUCCESS,
    listVerracosSinExtraer
  }
}

export function imprimirModalInicioPlaning () {
  return {
    type: actionTypes.IMPRIMIR_MODAL_INICIO_PLANING
  }
}

export function imprimirModalInicioPlaningSuccess (datosModalInicioPlaning) {
  return {
    type: actionTypes.IMPRIMIR_MODAL_INICIO_PLANING_SUCCESS,
    datosModalInicioPlaning
  }
}

export function exportarModalInicioPlaning () {
  return {
    type: actionTypes.EXPORTAR_MODAL_INICIO_PLANING
  }
}

export function exportarModalInicioPlaningSuccess (datosExcelModalInicioPlaning) {
  return {
    type: actionTypes.EXPORTAR_MODAL_INICIO_PLANING_SUCCESS,
    datosExcelModalInicioPlaning
  }
}

export function totalesPlaning (values, desdeSugerenciasExtraccion = false) {
  return {
    type: actionTypes.TOTALES_PLANING,
    values,
    desdeSugerenciasExtraccion
  }
}

export function totalesPlaningSuccess (datosTotalesPlaning) {
  return {
    type: actionTypes.TOTALES_PLANING_SUCCESS,
    datosTotalesPlaning
  }
}

export function totalesModalPlaningSuccess (datosTotalesModalPlaning) {
  return {
    type: actionTypes.TOTALES_MODAL_PLANING_SUCCESS,
    datosTotalesModalPlaning
  }
}

export function totalesPlaningAlbaran () {
  return {
    type: actionTypes.TOTALES_PLANING_ALBARAN
  }
}

export function totalesPlaningAlbaranSuccess (datosTotalesPlaningAlbaran) {
  return {
    type: actionTypes.TOTALES_PLANING_ALBARAN_SUCCESS,
    datosTotalesPlaningAlbaran
  }
}

export function abrirModalExportarImprimirPlaning (valor) {
  return {
    type: actionTypes.ABRIR_MODAL_EXPORTAR_IMPRIMIR_PLANING,
    valor
  }
}

export function cerrarModalExportarImprimirPlaning (valor) {
  return {
    type: actionTypes.CERRAR_MODAL_EXPORTAR_IMPRIMIR_PLANING,
    valor
  }
}

export function exportarPlaning (values) {
  return {
    type: actionTypes.EXPORTAR_PLANING,
    values
  }
}

export function exportarPlaningSuccess (datosExportarPlaning) {
  return {
    type: actionTypes.EXPORTAR_PLANING_SUCCESS,
    datosExportarPlaning
  }
}

export function imprimirPlaning (values) {
  return {
    type: actionTypes.IMPRIMIR_PLANING,
    values
  }
}

export function imprimirPlaningSuccess (datosImprimirPlaning) {
  return {
    type: actionTypes.IMPRIMIR_PLANING_SUCCESS,
    datosImprimirPlaning
  }
}

export function filtrosModalSugerenciasPlaning (filtrosSugerenciasPlaning) {
  return {
    type: actionTypes.FILTROS_MODAL_SUGERENCIAS_PLANING,
    filtrosSugerenciasPlaning
  }
}

export function obtenerDatosVerracoPlaning (datosVerracoPlaning) {
  return {
    type: actionTypes.OBTENER_DATOS_VERRACO_PLANING,
    datosVerracoPlaning
  }
}

export function fetchOrderPlaning (order) {
  return {
    type: actionTypes.FETCH_ORDER_PLANING,
    order
  }
}

export function exportarSugerenciaExtracciones (values) {
  return {
    type: actionTypes.EXPORTAR_SUGERENCIA_EXTRACCIONES,
    values
  }
}
export function exportarSugerenciaExtraccionesSuccess (dataExperotarSugerenciasExtracciones) {
  return {
    type: actionTypes.EXPORTAR_SUGERENCIA_EXTRACCIONES_SUCCESS,
    dataExperotarSugerenciasExtracciones
  }
}
export function imprimirSugerenciaExtracciones (values) {
  return {
    type: actionTypes.IMPRIMIR_SUGERENCIA_EXTRACCIONES,
    values
  }
}
export function imprimirSugerenciaExtraccionesSuccess (dataImprimirSugerenciasExtracciones) {
  return {
    type: actionTypes.IMPRIMIR_SUGERENCIA_EXTRACCIONES_SUCCESS,
    dataImprimirSugerenciasExtracciones
  }
}

export function seleccionarTodosPlanning () {
  return {
    type: actionTypes.SELECCIONAR_TODOS_PLANNING
  }
}

export function deSeleccionarTodosPlanning () {
  return {
    type: actionTypes.DESELECCIONAR_TODOS_PLANNING
  }
}

export function anadirSeleccionPlaning (list) {
  return {
    type: actionTypes.ANADIR_SELECCION_PLANING,
    list
  }
}

export function abrirDuplicarPlaning () {
  return {
    type: actionTypes.ABRIR_DUPLICAR_PLANING
  }
}

export function cerrarDuplicarPlaning () {
  return {
    type: actionTypes.CERRAR_DUPLICAR_PLANING
  }
}

export function enviarFechaDuplicarPlaning (fecha) {
  return {
    type: actionTypes.ENVIAR_FECHA_DUPLICAR_PLANING,
    fecha
  }
}

export function enviarFechaDuplicarPlaningSuccess (datosDuplicar) {
  return {
    type: actionTypes.ENVIAR_FECHA_DUPLICAR_PLANING_SUCCESS,
    datosDuplicar
  }
}

export function submitDuplicarPlaning (values) {
  return {
    type: actionTypes.SUBMIT_DUPLICAR_PLANING,
    values
  }
}

export function seleccionarTodosPlanningPrincipal () {
  return {
    type: actionTypes.SELECCIONAR_TODOS_PLANNING_PRINCIPAL
  }
}

export function deSeleccionarTodosPlanningPrincipal () {
  return {
    type: actionTypes.DESELECCIONAR_TODOS_PLANNING_PRINCIPAL
  }
}

export function anadirSeleccionPlaningPrincipal (list, fecha) {
  return {
    type: actionTypes.ANADIR_SELECCION_PLANING_PRINCIPAL,
    list,
    fecha
  }
}

export function seleccionarTodosSugerenciasPlanning () {
  return {
    type: actionTypes.SELECCIONAR_TODOS_SUGERENCIAS_PLANNING
  }
}

export function deSeleccionarTodosSugerenciasPlanning () {
  return {
    type: actionTypes.DESELECCIONAR_TODOS_SUGERENCIAS_PLANNING
  }
}

export function seleccionarTodosQuitarSugerenciasPlanning () {
  return {
    type: actionTypes.SELECCIONAR_TODOS_QUITAR_SUGERENCIAS_PLANNING
  }
}

export function deSeleccionarTodosQuitarSugerenciasPlanning () {
  return {
    type: actionTypes.DESELECCIONAR_TODOS_QUITAR_SUGERENCIAS_PLANNING
  }
}

export default {
  elegirDiasPlaning,
  abrirModalPlaning,
  abrirModalPlaningSuccess,
  closeModalPlaning,
  abrirModalSeleccionarVerracoPlaning,
  cerrarModalSeleccionarVerracoPlaning,
  fetchFiltrarPlaning,
  fetchFiltrarPlaningSuccess,
  fetchPlaningSuccess,
  anadirFechaPlaning,
  eliminarFechaPlaning,
  asignarVerracos,
  asignarVerracosSuccess,
  seleccionarTodosVerracosPlaning,
  deSeleccionarTodosVerracosPlaning,
  anadirVerracosPlaning,
  anadirVerracosPlaningSuccess,
  anadirPlaning,
  cargarVerracosPlanificados,
  onDeleteVerracoPlaning,
  onDeleteVerracoPlaningSuccess,
  seleccionarTodosModalPlaning,
  deSeleccionarTodosModalPlaning,
  deleteSeleccionVerracoPlaning,
  abrirModalInicioPlaning,
  closeModalInicioPlaning,
  obtenerVerracosSinExtraer,
  obtenerVerracosSinExtraerSuccess,
  imprimirModalInicioPlaning,
  imprimirModalInicioPlaningSuccess,
  exportarModalInicioPlaning,
  exportarModalInicioPlaningSuccess,
  totalesPlaning,
  totalesPlaningSuccess,
  totalesModalPlaningSuccess,
  abrirModalExportarImprimirPlaning,
  cerrarModalExportarImprimirPlaning,
  exportarPlaning,
  exportarPlaningSuccess,
  imprimirPlaning,
  imprimirPlaningSuccess,
  filtrosModalSugerenciasPlaning,
  obtenerDatosVerracoPlaning,
  fetchOrderPlaning,
  exportarSugerenciaExtracciones,
  exportarSugerenciaExtraccionesSuccess,
  imprimirSugerenciaExtracciones,
  imprimirSugerenciaExtraccionesSuccess,
  seleccionarTodosPlanning,
  deSeleccionarTodosPlanning,
  anadirSeleccionPlaning,
  abrirDuplicarPlaning,
  cerrarDuplicarPlaning,
  enviarFechaDuplicarPlaning,
  enviarFechaDuplicarPlaningSuccess,
  submitDuplicarPlaning,
  seleccionarTodosPlanningPrincipal,
  deSeleccionarTodosPlanningPrincipal,
  anadirSeleccionPlaningPrincipal,
  seleccionarTodosSugerenciasPlanning,
  deSeleccionarTodosSugerenciasPlanning,
  seleccionarTodosQuitarSugerenciasPlanning,
  deSeleccionarTodosQuitarSugerenciasPlanning,
  totalesPlaningAlbaran,
  totalesPlaningAlbaranSuccess
}
