import React, { Component } from 'react'
import SimplePage from '../../containers/page/SimplePage'
import {Doughnut, Scatter} from 'react-chartjs-2';
import 'chartjs-plugin-labels'
import { Link } from 'react-router-dom'
import ReactDOM from 'react-dom'
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import './Dashboard.scss'
import ListadoPage from '../../containers/page/ListadoPage'
import {number_formatter, round_decimal, date_formatter} from '../../util/formatFunctions'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'

class Dashboard extends Component {
  constructor () {
    super()
    this.state = {
      test: '',
      razaVerraco: [],
      totalRazas: 0,
      bloque1: false,
      bloque2: false,
      bloque3: false,
      bloque4: false,
      bloque5: false,
      bloque6: false,
      bloque7: false,
      bloque8: false,
      bloque9: false,
      bloque10: false
    }
  }

  aleatorio(inferior,superior){
    let numPosibilidades = superior - inferior
    let aleat = Math.random() * numPosibilidades
    aleat = Math.floor(aleat)
    return parseInt(inferior, 10) + aleat
  }

  dame_color_aleatorio(){
    let hexadecimal = new Array("0","1","2","3","4","5","6","7","8","9","A","B","C","D","E","F")
    let color_aleatorio = "#";
    for (let i=0;i<6;i++){
       let posarray = this.aleatorio(0,hexadecimal.length)
       color_aleatorio += hexadecimal[posarray]
    }
    return color_aleatorio
  }

  componentDidMount () {
    document.title = this.props.t('MENU.DASHBOARD') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    this.props.actions.fetchDashboard()
  }

  componentDidUpdate (prevProps) {
    if (this.props.auth.centro !== prevProps.auth.centro) {
      this.setState({bloque1: false})
      this.setState({bloque2: false})
      this.setState({bloque3: false})
      this.setState({bloque4: false})
      this.setState({bloque5: false})
      this.setState({bloque6: false})
      this.setState({bloque7: false})
      this.setState({bloque8: false})
      this.setState({bloque9: false})
      this.setState({bloque10: false})
      this.props.actions.fetchDashboard()
    }
    if (this.props.razaVerraco !== prevProps.razaVerraco) {
      if (Object.keys(this.props.razaVerraco).length > 0) {
        this.props.razaVerraco.map((raza) => {
          raza.porcentajeRazaVerraco = round_decimal(raza.numVerracos * 100 / this.props.totalVerracosRaza, 2, this.props.separadorDec, this.props.separadorMil)
          raza.color = this.dame_color_aleatorio()
        })
        this.setState({razaVerraco: this.props.razaVerraco})
      }
    }
    if (this.props.totalRazas !== prevProps.totalRazas) {
      this.setState({totalRazas: this.props.totalRazas})
    }
    if (this.props.tiposDosisProducidas !== prevProps.tiposDosisProducidas) {
      if (Object.keys(this.props.tiposDosisProducidas).length > 0) {
        this.props.tiposDosisProducidas.map((tipoDosis) => {
          tipoDosis.porcentajeTipoDosis = round_decimal(tipoDosis.numerodosis * 100 / this.props.totalTipoDosis, 2, this.props.separadorDec, this.props.separadorMil)
          tipoDosis.color = this.dame_color_aleatorio()
        })
      }
    }

    if (this.props.graficosMostrar !== prevProps.graficosMostrar) {
      this.props.graficosMostrar.forEach((mostrar) => {
        if (mostrar === '1') {
          this.setState({bloque1: true})
        }
        if (mostrar === '2') {
          this.setState({bloque2: true})
        }
        if (mostrar === '3') {
          this.setState({bloque3: true})
        }
        if (mostrar === '4') {
          this.setState({bloque4: true})
        }
        if (mostrar === '5') {
          this.setState({bloque5: true})
        }
        if (mostrar === '6') {
          this.setState({bloque6: true})
        }
        if (mostrar === '7') {
          this.setState({bloque7: true})
        }
        if (mostrar === '8') {
          this.setState({bloque8: true})
        }
        if (mostrar === '9') {
          this.setState({bloque9: true})
        }
        if (mostrar === '10') {
          this.setState({bloque10: true})
        }
      })
    }
  }

  render () {
    const {
      t, listTareasCalendario = [],
      porcentajeEyaculados, numEyaculados, porcentajeNoEyaculados, numNoEyaculados,

      porcentajeMuertos, numMuertos, porcentajelMotilidad, numlMotilidad, porcentajeFA, numFA, porcentajeConcentracion, numConcentracion,
      porcentajeAglutinacion, numAglutinacion, porcentajeConcentracionOtros, numConcentracionOtros, porcentajeNoInformado, numNoInformado,

      razaVerraco, totalVerracosRaza, totalRazas,

      porcentajeCuarentena, numCuarentena, porcentajePresentado, numPresentado, porcentajeActivo, numActivo,
      porcentajeBaja, numBaja, /*porcentajeEliminado, numEliminado,*/

      porcentaje06, num06, porcentaje6a9, num6a9, porcentaje9a12, num9a12, porcentaje12a18, num12a18, porcentaje18a24, num18a24,
      porcentaje24a36, num24a36, porcentajeMas36, numMas36, porcentajeSinFechaNacimiento, numSinFechaNacimiento, mediaEdad,

      tiposDosisProducidas, numeroRazas,

      numeroVerracos, numeroLineasGeneticas, mediaDosisVerracos, dosisProducidas, verracosVigilados, numeroVerracosSinExtraer, verracosEnTratamiento, numeroPedios, dosisPedidas,
      extraccionesPlaning, sumaLitrosDiluyente, tareasParaHoy, stockMateriales, numDosisDescartadasEdad,
      auth: { nombreUsuario, idioma, separadorDec, separadorMil }
    } = this.props

    const tKey = 'AGENDA.'

    // TABLA TAREAS PARA HOY
    const tableTareasDiariasAgenda = {
      id: 'tareasDiariasAgendaTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      paginarConScroll: true,
      hasPagination: false,
      columns: [
        {id: 'nombreOperario', name: t(tKey + 'TABLA_TAREAS_DIARIAS.COLUMNS.OPERARIO')},
        {id: 'nombre', name: t(tKey + 'TABLA_TAREAS_DIARIAS.COLUMNS.NOMBRE_TAREA')}
      ],
      rows: Object.keys(tareasParaHoy).length > 0 ? tareasParaHoy : [],
      initialValues: {}
    }

    // TABLA TAREAS PARA HOY
    const tableStockMateriales = {
      id: 'stockMaterialesTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      paginarConScroll: true,
      hasPagination: false,
      columns: [
        {id: 'nombre', name: t('ALMACEN.PRODUCTO.PRODUCTO')},
        {id: 'stockReal', name: t('ALMACEN.PRODUCTO.STOCK_REAL'), type: 'numero'},
        {id: 'unidadMedida', name: t('ALMACEN.PRODUCTO.MEDIDA')},
        {id: 'stockProduccion', name: t('ALMACEN.PRODUCTO.STOCK_PRODUCCION'), type: 'numero'}
      ],
      rows: Object.keys(stockMateriales).length > 0 ? stockMateriales : [],
      initialValues: {}
    }

    const eyaculados = porcentajeEyaculados !== '' && porcentajeEyaculados !== null ? round_decimal(porcentajeEyaculados, 2, separadorDec, separadorMil) : ''
    const numEyaculadosAux = numEyaculados !== '' && numEyaculados !== null ? round_decimal(numEyaculados, 2, separadorDec, separadorMil) : ''
    const noEyaculados = porcentajeNoEyaculados !== '' && porcentajeNoEyaculados !== null ? round_decimal(porcentajeNoEyaculados, 2, separadorDec, separadorMil) : ''
    const numNoEyaculadosAux = numNoEyaculados !== '' && numNoEyaculados !== null ? round_decimal(numNoEyaculados, 2, separadorDec, separadorMil) : ''

    const porMuertos = porcentajeMuertos !== '' && porcentajeMuertos !== null ? round_decimal(porcentajeMuertos, 2, separadorDec, separadorMil) : ''
    const numMuertosAux = numMuertos !== '' && numMuertos !== null ? round_decimal(numMuertos, 2, separadorDec, separadorMil) : ''
    const porlMotilidad = porcentajelMotilidad !== '' && porcentajelMotilidad !== null ? round_decimal(porcentajelMotilidad, 2, separadorDec, separadorMil) : ''
    const numlMotilidadAux = numlMotilidad !== '' && numlMotilidad !== null ? round_decimal(numlMotilidad, 2, separadorDec, separadorMil) : ''
    const porFA = porcentajeFA !== '' && porcentajeFA !== null ? round_decimal(porcentajeFA, 2, separadorDec, separadorMil) : ''
    const numFAAux = numFA !== '' && numFA !== null ? round_decimal(numFA, 2, separadorDec, separadorMil) : ''
    const porConcentracion = porcentajeConcentracion !== '' && porcentajeConcentracion !== null ? round_decimal(porcentajeConcentracion, 2, separadorDec, separadorMil) : ''
    const numConcentracionAux = numConcentracion !== '' && numConcentracion !== null ? round_decimal(numConcentracion, 2, separadorDec, separadorMil) : ''
    const porAglutinacion = porcentajeAglutinacion !== '' && porcentajeAglutinacion !== null ? round_decimal(porcentajeAglutinacion, 2, separadorDec, separadorMil) : ''
    const numAglutinacionAux = numAglutinacion !== '' && numAglutinacion !== null ? round_decimal(numAglutinacion, 2, separadorDec, separadorMil) : ''
    const porConcentracionOtros = porcentajeConcentracionOtros !== '' && porcentajeConcentracionOtros !== null ? round_decimal(porcentajeConcentracionOtros, 2, separadorDec, separadorMil) : ''
    const numConcentracionOtrosAux = numConcentracionOtros !== '' && numConcentracionOtros !== null ? round_decimal(numConcentracionOtros, 2, separadorDec, separadorMil) : ''
    const porNoInformado = porcentajeNoInformado !== '' && porcentajeNoInformado !== null ? round_decimal(porcentajeNoInformado, 2, separadorDec, separadorMil) : ''
    const numNoInformadoAux = numNoInformado !== '' && numNoInformado !== null ? round_decimal(numNoInformado, 2, separadorDec, separadorMil) : ''

    const porCuarentena = porcentajeCuarentena !== '' && porcentajeCuarentena !== null ? round_decimal(porcentajeCuarentena, 2, separadorDec, separadorMil) : ''
    const numCuarentenaAux = porcentajeCuarentena !== '' && porcentajeCuarentena !== null ? round_decimal(numCuarentena, 2, separadorDec, separadorMil) : ''
    const porPresentado = porcentajePresentado !== '' && porcentajePresentado !== null ? round_decimal(porcentajePresentado, 2, separadorDec, separadorMil) : ''
    const numPresentadoAux = porcentajePresentado !== '' && porcentajePresentado !== null ? round_decimal(numPresentado, 2, separadorDec, separadorMil) : ''
    const porActivo = porcentajeActivo !== '' && porcentajeActivo !== null ? round_decimal(porcentajeActivo, 2, separadorDec, separadorMil) : ''
    const numActivoAux = porcentajeActivo !== '' && porcentajeActivo !== null ? round_decimal(numActivo, 2, separadorDec, separadorMil) : ''
    const porBaja = porcentajeBaja !== '' && porcentajeBaja !== null ? round_decimal(porcentajeBaja, 2, separadorDec, separadorMil) : ''
    const numBajaAux = porcentajeBaja !== '' && porcentajeBaja !== null ? round_decimal(numBaja, 2, separadorDec, separadorMil) : ''
    //const porEliminado = porcentajeEliminado !== '' && porcentajeEliminado !== null ? round_decimal(porcentajeEliminado, 2, separadorDec, separadorMil) : ''
    //const numEliminadoAux = porcentajeEliminado !== '' && porcentajeEliminado !== null ? round_decimal(numEliminado, 2, separadorDec, separadorMil) : ''

    const por0a6 = porcentaje06 !== '' && porcentaje06 !== null ? round_decimal(porcentaje06, 2, separadorDec, separadorMil) : ''
    const num06Aux = num06 !== '' && num06 !== null ? round_decimal(num06, 2, separadorDec, separadorMil) : ''
    const por6a9 = porcentaje6a9 !== '' && porcentaje6a9 !== null ? round_decimal(porcentaje6a9, 2, separadorDec, separadorMil) : ''
    const num6a9Aux = num6a9 !== '' && num6a9 !== null ? round_decimal(num6a9, 2, separadorDec, separadorMil) : ''
    const por9a12 = porcentaje9a12 !== '' && porcentaje9a12 !== null ? round_decimal(porcentaje9a12, 2, separadorDec, separadorMil) : ''
    const num9a12Aux = num9a12 !== '' && num9a12 !== null ? round_decimal(num9a12, 2, separadorDec, separadorMil) : ''
    const por12a18 = porcentaje12a18 !== '' && porcentaje12a18 !== null ? round_decimal(porcentaje12a18, 2, separadorDec, separadorMil) : ''
    const num12a18Aux = num12a18 !== '' && num12a18 !== null ? round_decimal(num12a18, 2, separadorDec, separadorMil) : ''
    const por18a24 = porcentaje18a24 !== '' && porcentaje18a24 !== null ? round_decimal(porcentaje18a24, 2, separadorDec, separadorMil) : ''
    const num18a24Aux = num18a24 !== '' && num18a24 !== null ? round_decimal(num18a24, 2, separadorDec, separadorMil) : ''
    const por24a36 = porcentaje24a36 !== '' && porcentaje24a36 !== null ? round_decimal(porcentaje24a36, 2, separadorDec, separadorMil) : ''
    const num24a36Aux = num24a36 !== '' && num24a36 !== null ? round_decimal(num24a36, 2, separadorDec, separadorMil) : ''
    const porMas36 = porcentajeMas36 !== '' && porcentajeMas36 !== null ? round_decimal(porcentajeMas36, 2, separadorDec, separadorMil) : ''
    const numMas36Aux = numMas36 !== '' && numMas36 !== null ? round_decimal(numMas36, 2, separadorDec, separadorMil) : ''
    const porSinFechaNacimiento = porcentajeSinFechaNacimiento !== '' && porcentajeSinFechaNacimiento !== null ? round_decimal(porcentajeSinFechaNacimiento, 2, separadorDec, separadorMil) : ''
    const numSinFechaNacimientoAux = numSinFechaNacimiento !== '' && numSinFechaNacimiento !== null ? round_decimal(numSinFechaNacimiento, 2, separadorDec, separadorMil) : ''
    const mediaEdadAux = mediaEdad !== '' && mediaEdad !== null ? round_decimal(mediaEdad, 2, separadorDec, separadorMil) : ''

    const piecelabelDoughnut = {
      render: 'value',
      precision: 0,
      showZero: true,
      fontSize: 10,
      fontStyle: 'normal',
      fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      textShadow: true,
      position: 'outside',
      overlap: true,
      showActualPercentages: true,
      outsidePadding: 4,
      textMargin: 4
    }

    const data = {
        labels: [
          t('DASHBOARD.EYACULADO_UTILIZADO') + ' %',
          t('DASHBOARD.EYACULADO_ELIMINADO') + ' %'
        ],
        datasets: [{
          data: [eyaculados, noEyaculados],
          dataNum: [numEyaculadosAux, numNoEyaculadosAux],
          backgroundColor: [
          '#1e9fab',
          '#ec0000'
          ],
          hoverBackgroundColor: [
          '#1e9fab',
          '#ec0000'
          ]
        }],
        text: '23%'
      }
      const dataRazonNoUso = {
        labels: [
          t('COMUN.COMBOS.OBSERVACIONES_EYACULADO_ELIMINADO.MUERTO') + ' %',
          t('COMUN.COMBOS.OBSERVACIONES_EYACULADO_ELIMINADO.MOTILIDAD') + ' %',
          t('COMUN.COMBOS.OBSERVACIONES_EYACULADO_ELIMINADO.FA') + ' %',
          t('COMUN.COMBOS.OBSERVACIONES_EYACULADO_ELIMINADO.CONCENTRACION') + ' %',
          t('COMUN.COMBOS.OBSERVACIONES_EYACULADO_ELIMINADO.AGLUTINACION') + ' %',
          t('COMUN.COMBOS.OBSERVACIONES_EYACULADO_ELIMINADO.CONTAMINACION_U_OTROS') + ' %',
          t('COMUN.COMBOS.OBSERVACIONES_EYACULADO_ELIMINADO.NO_INFORMADO') + ' %'
        ],
        datasets: [{
          data: [porMuertos, porlMotilidad, porFA, porConcentracion, porAglutinacion, porConcentracionOtros, porNoInformado],
          dataNum: [numMuertosAux, numlMotilidadAux, numFAAux, numConcentracionAux, numAglutinacionAux, numConcentracionOtrosAux, numNoInformadoAux],
          backgroundColor: [
          '#21cd19',
          '#ec0000',
          '#eddb0f',
          '#8319cd',
          '#1950cd',
          '#cd8319',
          '#5be6c7'
          ],
          hoverBackgroundColor: [
          '#21cd19',
          '#ec0000',
          '#eddb0f',
          '#8319cd',
          '#1950cd',
          '#cd8319',
          '#5be6c7'
          ]
        }],
        text: '23%'
      }

      let datasetsRaza = []
      let datasetsRaza2 = []
      let datasetsRaza2Num = []
      let colorRaza = []
      if (Object.keys(this.props.razaVerraco).length > 0){
        for (let i = 0; i < Object.keys(this.props.razaVerraco).length; i++){
          datasetsRaza = datasetsRaza.concat(this.props.razaVerraco[i].nombreRaza + ' %')
          colorRaza = colorRaza.concat(this.props.razaVerraco[i].color)
        }
        for (let i = 0; i < Object.keys(this.props.razaVerraco).length; i++){
          datasetsRaza2 = datasetsRaza2.concat(this.props.razaVerraco[i].porcentajeRazaVerraco)
          datasetsRaza2Num = datasetsRaza2Num.concat(this.props.razaVerraco[i].numVerracos)
        }
      }

      const dataRazaVerraco ={
        labels: datasetsRaza,
        datasets: [{
          data: datasetsRaza2,
          dataNum: datasetsRaza2Num,
          backgroundColor:colorRaza,
        }],
        text: '23%'
      }

      const dataEstadoVerraco ={
        labels: [
          t('COMUN.COMBOS.COMBO_ESTADO_VERRACO.CUARENTENA') + ' %',
          t('COMUN.COMBOS.COMBO_ESTADO_VERRACO.PRESENTE') + ' %',
          t('COMUN.COMBOS.COMBO_ESTADO_VERRACO.ACTIVO') + ' %',
          t('COMUN.COMBOS.COMBO_ESTADO_VERRACO.BAJA') + ' %'
        ],
        datasets: [{
          data: [porCuarentena, porPresentado, porActivo, porBaja],
          dataNum: [numCuarentenaAux, numPresentadoAux, numActivoAux, numBajaAux],
          backgroundColor: [
          '#e89c08',
          '#1e9fab',
          '#7ebc12',
          '#ec45da'
          ],
          hoverBackgroundColor: [
          '#e89c08',
          '#1e9fab',
          '#7ebc12',
          '#ec45da'
          ]
        }],
        text: '23%'
      }

      const dataVerracoEdad ={
        labels: [
          t('DASHBOARD.0_6_MESES') + ' %',
          t('DASHBOARD.6_9_MESES') + ' %',
          t('DASHBOARD.9_12_MESES') + ' %',
          t('DASHBOARD.12_18_MESES') + ' %',
          t('DASHBOARD.18_24_MESES') + ' %',
          t('DASHBOARD.24_36_MESES') + ' %',
          t('DASHBOARD.MAS_36_MESES') + ' %',
          t('DASHBOARD.SIN_FECHA_NACIMIENTO') + ' %'
        ],
        datasets: [{
          data: [por0a6, por6a9, por9a12, por12a18, por18a24, por24a36, porMas36, porSinFechaNacimiento],
          dataNum: [num06Aux, num6a9Aux, num9a12Aux, num12a18Aux, num18a24Aux, num24a36Aux, numMas36Aux, numSinFechaNacimientoAux],
          backgroundColor: ['#21cd19', '#ec0000','#eddb0f', '#8319cd', '#1950cd', '#cd8319', '#5be6c7', '#d1d1d1'],
          hoverBackgroundColor: ['#21cd19', '#ec0000','#eddb0f', '#8319cd', '#1950cd', '#cd8319', '#5be6c7', '#d1d1d1']
        }],
        text: '23%'
      }

      // tipo dosis
      let datasetsTipoDosis = []
      let datasetsTipoDosis2 = []
      let datasetsTipoDosis2Num = []
      let colorTipoDosis = []
      if (Object.keys(this.props.tiposDosisProducidas).length > 0){
        for (let i = 0; i < Object.keys(this.props.tiposDosisProducidas).length; i++){
          datasetsTipoDosis = datasetsTipoDosis.concat(this.props.tiposDosisProducidas[i].nombreTipoDosis + ' %')
          colorTipoDosis = colorTipoDosis.concat(this.props.tiposDosisProducidas[i].color)
        }
        for (let i = 0; i < Object.keys(this.props.tiposDosisProducidas).length; i++){
          datasetsTipoDosis2 = datasetsTipoDosis2.concat(this.props.tiposDosisProducidas[i].porcentajeTipoDosis)
          datasetsTipoDosis2Num = datasetsTipoDosis2Num.concat(this.props.tiposDosisProducidas[i].numerodosis)
        }
      }
      const dataTipoDosis ={
        labels: datasetsTipoDosis,
        datasets: [{
          data: datasetsTipoDosis2,
          dataNum: datasetsTipoDosis2Num,
          backgroundColor: colorTipoDosis,
        }],
        text: '23%'
      }

    const t_key = 'DASHBOARD.'
    let contRecta = {
      minMeses: 0,
      maxMeses: 36,
      minDosis: -40,
      maxDosis: 40,
      data: []
    }
    contRecta.data = numDosisDescartadasEdad.map((dosis, idDosis) => {
      if (dosis.mesesEdad > contRecta.maxMeses) {
        contRecta.maxMeses = dosis.mesesEdad
      }
      if (dosis.dosis < contRecta.minDosis) {
        contRecta.minDosis = dosis.dosis
      }
      if (dosis.dosis > contRecta.maxDosis) {
        contRecta.maxDosis = dosis.dosis
      }

      return {x: parseFloat(dosis.dosis), y: parseFloat(dosis.mesesEdad)}
    })

    if ((contRecta.minDosis * -1) > contRecta.maxDosis) {
      contRecta.maxDosis = contRecta.minDosis * -1
    }

    return (
      <div className="page-dashboard">
        <SimplePage t_key={t_key} noTitle={true}>
          <Row>
          {
            this.state.bloque1 === true ? (
              <Col sm={8} className="cont-part-dashboard">
              <div className="part-dashboard" style={{marginTop: '12px'}}>
                <h3>{t('DASHBOARD.EYACULADOS_RAZON_NO_USO')}</h3>
                <Col sm={6} style={{height: '174px'}}>
                  <Doughnut
                    data={data}
                    options={{
                      legend: {
                        display: true,
                        position: 'left',
                        width:"300",
                        height:"300",
                        labels: {
                          fontSize: 10.3
                        }
                      },
                      plugins:{
                        labels: {
                          render: function(options){
                            // Con la siguiente condición se evita mostrar 0%.
                            /*if (options.value === 0 || options.value === '') {
                              return 0
                            }*/
                            return number_formatter(options.value, 2, separadorDec, separadorMil) + (options.value !== '' ? '%' : '')
                          }, precision: 0, showZero: true, fontSize: 10, textShadow: true, position: 'outside', overlap: true, showActualPercentages: true, outsidePadding: 4, textMargin: 4
                        }
                      },
                      maintainAspectRatio: false,
                      responsive: true,
                      cutoutPercentage: 60,
                      tooltips: {
                        enabled: true,
                        displayColors: false,
                        bodyFontFamily: "'opens-sans', sans-serif",
                        callbacks: {
                          label: function(tooltipItem, data) {
                            let dataset = data.datasets[tooltipItem.datasetIndex]
                            let total = dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
                              return previousValue + currentValue
                            })
                            let currentValue = dataset.data[tooltipItem.index]
                            let currentValueNum = dataset.dataNum[tooltipItem.index]
                            return "Nº: " + number_formatter(currentValueNum, 0, separadorDec, separadorMil) + " (" + number_formatter(currentValue, 2, separadorDec, separadorMil) + "%)"
                          }
                        }
                      }
                    }}
                    width={438}
                    height={160}
                  />
                </Col>
                <Col sm={6} style={{height: '174px'}}>
                  <Doughnut
                    data={dataRazonNoUso}
                    options={{
                      legend: {
                        display: true,
                        position: 'left',
                        width:"100",
                        height:"100",
                        labels: {
                          fontSize: 10.3
                        }
                      },
                      plugins:{
                        labels: {
                          render: function(options){
                            return number_formatter(options.value, 2, separadorDec, separadorMil) + (options.value !== '' ? '%' : '')
                          }, precision: 0, showZero: true, fontSize: 10, textShadow: true, position: 'outside', overlap: true, showActualPercentages: true, outsidePadding: 4, textMargin: 4
                        }
                      },
                      maintainAspectRatio: false,
                      responsive: true,
                      cutoutPercentage: 60,
                      tooltips: {
                        enabled: true,
                        displayColors: false,
                        bodyFontFamily: "'opens-sans', sans-serif",
                        callbacks: {
                          label: function(tooltipItem, data) {
                            let dataset = data.datasets[tooltipItem.datasetIndex]
                            let total = dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
                              return previousValue + currentValue
                            })
                            let currentValue = dataset.data[tooltipItem.index]
                            let currentValueNum = dataset.dataNum[tooltipItem.index]
                            return "Nº: " + number_formatter(currentValueNum, 0, separadorDec, separadorMil) + " (" + number_formatter(currentValue, 2, separadorDec, separadorMil) + "%)"
                          }
                        }
                      }
                    }}
                    width={438}
                    height={160}
                  />
                  </Col>
              </div>
            </Col>
              ) : null
            }
            {
              this.state.bloque3 === true ? (
                <Col sm={4} className="cont-part-dashboard">
              <div className="part-dashboard">
                <h3>{t('DASHBOARD.VERRACOS_POR_ESTADO')}</h3>
                <Col sm={12} style={{height: '174px'}}>
                <Doughnut
                  data={dataEstadoVerraco}
                  options={{
                    legend: {
                      display: true,
                      position: 'left',
                      width:"300",
                      height:"300",
                      labels: {
                        fontSize: 10.3
                      }
                    },
                    plugins:{
                      labels: {
                        render: function(options){
                          return number_formatter(options.value, 2, separadorDec, separadorMil) + (options.value !== '' ? '%' : '')
                        }, precision: 0, showZero: true, fontSize: 10, textShadow: true, position: 'outside', overlap: true, showActualPercentages: true, outsidePadding: 4, textMargin: 4
                      }
                    },
                    maintainAspectRatio: false,
                    responsive: true,
                    cutoutPercentage: 60,
                    tooltips: {
                      enabled: true,
                      displayColors: false,
                      bodyFontFamily: "'opens-sans', sans-serif",
                      callbacks: {
                        label: function(tooltipItem, data) {
                          let dataset = data.datasets[tooltipItem.datasetIndex]
                          let total = dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
                            return previousValue + currentValue
                          })
                          let currentValue = dataset.data[tooltipItem.index]
                          let currentValueNum = dataset.dataNum[tooltipItem.index]
                          return "Nº: " + number_formatter(currentValueNum, 0, separadorDec, separadorMil) + " (" + number_formatter(currentValue, 2, separadorDec, separadorMil) + "%)"
                        }
                      }
                    }
                  }}
                  width={438}
                  height={160}
                />
                </Col>
              </div>
            </Col>
              ) : null
            }
            {
              this.state.bloque4 === true ? (
                <Col sm={4} className="cont-part-dashboard">
                  <div className="part-dashboard">
                    <h3>{t('DASHBOARD.VERRACOS_RANGO_EDAD')}</h3>
                    <Col sm={12} style={{height: '174px', marginBottom: '15px'}}>
                    <Doughnut
                      data={dataVerracoEdad}
                      options={{
                        legend: {
                          display: true,
                          position: 'left',
                          width:"300",
                          height:"300",
                          labels: {
                            fontSize: 10.3
                          }
                        },
                        plugins:{
                          labels: {
                            render: function(options){
                              return number_formatter(options.value, 2, separadorDec, separadorMil) + (options.value !== '' ? '%' : '')
                            }, precision: 0, showZero: true, fontSize: 10, textShadow: true, position: 'outside', overlap: true, showActualPercentages: true, outsidePadding: 4, textMargin: 4
                          }
                        },
                        maintainAspectRatio: false,
                        responsive: true,
                        cutoutPercentage: 60,
                        tooltips: {
                          enabled: true,
                          displayColors: false,
                          bodyFontFamily: "'opens-sans', sans-serif",
                          callbacks: {
                            label: function(tooltipItem, data) {
                              let dataset = data.datasets[tooltipItem.datasetIndex]
                              let total = dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
                                return previousValue + currentValue
                              })
                              let currentValue = dataset.data[tooltipItem.index]
                              let currentValueNum = dataset.dataNum[tooltipItem.index]
                              return "Nº: " + number_formatter(currentValueNum, 0, separadorDec, separadorMil) + " (" + number_formatter(currentValue, 2, separadorDec, separadorMil) + "%)"
                            }
                          }
                        }
                      }}
                      width={438}
                      height={180}
                    />
                    </Col>
                    {mediaEdadAux && (
                      <Col sm={12} className="text-center">
                        <strong>{t(t_key + 'EDAD_MEDIA_VERRACOS_DIAS', {numDias: number_formatter(mediaEdadAux, 2, separadorDec, separadorMil)})}</strong>
                      </Col>
                    )}
                  </div>
                </Col>
              ) : null
            }
            {
              this.state.bloque2 === true ? (
                <Col sm={4} className="cont-part-dashboard">
              <div className="part-dashboard" style={{marginTop: '12px'}}>
                <h3>{t('DASHBOARD.VERRACOS_POR_RAZA')}</h3>
                <Col sm={12} style={{height: '174px'}}>
                <Doughnut
                  data={dataRazaVerraco}
                  options={{
                    legend: {
                      display: true,
                      position: 'left',
                      labels: {
                        fontSize: 10.3
                      }
                    },
                    plugins:{
                      labels: {
                        render: function(options){
                          return number_formatter(options.value, 2, separadorDec, separadorMil) + '%'
                        }, precision: 0, showZero: true, fontSize: 10, textShadow: true, position: 'outside', overlap: true, showActualPercentages: true, outsidePadding: 4, textMargin: 4
                      }
                    },
                    maintainAspectRatio: false,
                    responsive: true,
                    cutoutPercentage: 60,
                    tooltips: {
                      enabled: true,
                      displayColors: false,
                      bodyFontFamily: "'opens-sans', sans-serif",
                      callbacks: {
                        label: function(tooltipItem, data) {
                          let dataset = data.datasets[tooltipItem.datasetIndex]
                          let total = dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
                            return previousValue + currentValue
                          })
                          let currentValue = dataset.data[tooltipItem.index]
                          let currentValueNum = dataset.dataNum[tooltipItem.index]
                          return "Nº: " + number_formatter(currentValueNum, 0, separadorDec, separadorMil) + " (" + number_formatter(currentValue, 2, separadorDec, separadorMil) + "%)"
                        }
                      }
                    }
                  }}
                  width={438}
                  height={180}
                />
                </Col>
              </div>
            </Col>
              ) : null
            }
            {
              this.state.bloque5 === true ? (
                <Col sm={4} className="cont-part-dashboard">
              <div className="part-dashboard">
                <h3>{t('DASHBOARD.TIPO_DOSIS_PRODUCIDAD')}</h3>
                <Col sm={12} style={{height: '174px'}}>
                <Doughnut
                  data={dataTipoDosis}
                  options={{
                    legend: {
                      display: true,
                      position: 'left',
                      labels: {
                        fontSize: 10.3
                      }
                    },
                    plugins:{
                      labels: {
                        render: function(options){
                          return number_formatter(options.value, 2, separadorDec, separadorMil) + '%'
                        }, precision: 0, showZero: true, fontSize: 10, textShadow: true, position: 'outside', overlap: true, showActualPercentages: true, outsidePadding: 4, textMargin: 4
                      }
                    },
                    maintainAspectRatio: false,
                    responsive: true,
                    cutoutPercentage: 60,
                    tooltips: {
                      enabled: true,
                      displayColors: false,
                      bodyFontFamily: "'opens-sans', sans-serif",
                      callbacks: {
                        label: function(tooltipItem, data) {
                          let dataset = data.datasets[tooltipItem.datasetIndex]
                          let total = dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
                            return previousValue + currentValue
                          })
                          let currentValue = dataset.data[tooltipItem.index]
                          let currentValueNum = dataset.dataNum[tooltipItem.index]
                          return "Nº: " + number_formatter(currentValueNum, 0, separadorDec, separadorMil) + " (" + number_formatter(currentValue, 2, separadorDec, separadorMil) + "%)"
                        }
                      }
                    }
                  }}
                  width={438}
                  height={150}
                />
                </Col>
              </div>
            </Col>
              ) : null
            }
            {
              this.state.bloque6 === true ? (
                <Col sm={4} className="cont-part-dashboard">
                  <div className="part-dashboard">
                    <h3>{t('DASHBOARD.INFORMACION_GENERAL_CENTRO')}</h3>
                    <Col sm={12} style={{padding: 0}}>
                      <Col sm={6} className="estados-total-verraco">
                        <span>{t('DASHBOARD.NUMERO_VERRACOS')}: {number_formatter(numeroVerracos, 0, separadorDec, separadorMil)}</span>
                      </Col>
                      <Col sm={6} className="estados-total-verraco">
                        <span>{t('DASHBOARD.NUMERO_RAZAS')}: {number_formatter(numeroRazas, 0, separadorDec, separadorMil)}</span>
                      </Col>
                    </Col>
                    <Col sm={12} style={{padding: 0}}>
                      <Col sm={6} className="estados-total-verraco">
                        <span>{t('DASHBOARD.NUMERO_LINEAS_GENETICAS')}: {number_formatter(numeroLineasGeneticas, 0, separadorDec, separadorMil)}</span>
                      </Col>
                      <Col sm={6} className="estados-total-verraco">
                        <span>{t('DASHBOARD.NUMERO_DOSIS_POR_VERRACO')}: {number_formatter(round_decimal(mediaDosisVerracos, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span>
                      </Col>
                    </Col>

                    <Col sm={12} style={{padding: 0}}>
                      <Col sm={6} className="estados-total-verraco">
                        <span>{t('DASHBOARD.NUMERO_DOSIS_ESTE_ANO')}: {dosisProducidas ? number_formatter(dosisProducidas, 0, separadorDec, separadorMil) : 0}</span>
                      </Col>
                      <Col sm={6} className="estados-total-verraco">
                        <span>{t('DASHBOARD.NUMERO_VERRACOS_VIGILADOS')}: {number_formatter(verracosVigilados, 0, separadorDec, separadorMil)}</span>
                      </Col>
                    </Col>
                    <Col sm={12} style={{padding: 0}}>
                      <Col sm={6} className="estados-total-verraco">
                        <span>{t('DASHBOARD.NUMERO_VERRACOS_SIN_EXTRAER')}: {number_formatter(numeroVerracosSinExtraer, 0, separadorDec, separadorMil)}</span>
                      </Col>
                      <Col sm={6} className="estados-total-verraco">
                        <span>{t('DASHBOARD.NUMERO_VERRACOS_EN_TRATAMIENTO')}: {number_formatter(verracosEnTratamiento, 0, separadorDec, separadorMil)}</span>
                      </Col>
                    </Col>
                  </div>
                </Col>
              ) : null
            }
            {
              this.state.bloque7 === true ? (
                <Col sm={4} className="cont-part-dashboard">
                  <div className="part-dashboard">
                    <h3>{t('DASHBOARD.PRODUCCION_DEL_DIA')}</h3>
                    <Col sm={12} style={{padding: 0}}>
                      <Col sm={6} className="estados-total-verraco">
                        <span>{t('DASHBOARD.NUM_PEDIDOS')}: {number_formatter(numeroPedios, 0, separadorDec, separadorMil)}</span>
                      </Col>
                      <Col sm={6} className="estados-total-verraco">
                        <span>{t('DASHBOARD.NUM_DOSIS_PEDIDAS')}: {dosisPedidas === null ? 0 : number_formatter(dosisPedidas, 0, separadorDec, separadorMil)}</span>
                      </Col>
                    </Col>
                    <Col sm={12} style={{padding: 0}}>
                      <Col sm={6} className="estados-total-verraco">
                        <span>{t('DASHBOARD.NUMERO_VERRACOS_EXTRAER')}: {number_formatter(extraccionesPlaning, 0, separadorDec, separadorMil)}</span>
                      </Col>
                      <Col sm={6} className="estados-total-verraco">
                        <span>{t('DASHBOARD.LITROS_DILUYENTE')}: {number_formatter(round_decimal(sumaLitrosDiluyente, 2, separadorDec, separadorMil), 2, separadorDec, separadorMil)}</span>
                      </Col>
                    </Col>
                  </div>
                </Col>
              ) : null
            }
            {
              this.state.bloque8 === true ? (
                <Col sm={4} className="cont-part-dashboard">
                  <div className="part-dashboard">
                    <h3  style={{width: '75%', display: 'inline-block'}}>{t('DASHBOARD.TAREAS_PARA_HOY')}</h3>
                    <Link to="/Agenda" className="btn btn-primary ir-agenda-dashboard">{t('DASHBOARD.IR_AGENDA')}</Link>
                    <div style={{overflow: 'auto', paddingBottom: '10px', height: '160px', marginTop: '10px', width: '100%'}}>
                      <SimpleTablePage {...tableTareasDiariasAgenda} />
                    </div>
                  </div>
                </Col>
              ) : null
            }
            {
              this.state.bloque9 === true ? (
                <Col sm={4} className="cont-part-dashboard">
                  <div className="part-dashboard">
                    <h3 style={{width: '75%', display: 'inline-block'}}>{t('DASHBOARD.STOCK_MATERIALES')}</h3>
                    <Link to="/Producto" className="btn btn-primary ir-agenda-dashboard">{t('DASHBOARD.IR_PRODUCTO')}</Link>
                    <div style={{overflow: 'auto', height: '170px', marginTop: '10px'}}>
                      <ListadoPage noTitle={true} table={tableStockMateriales}></ListadoPage>
                    </div>
                  </div>
                </Col>
              ) : null
            }
            {
              this.state.bloque10 === true ? (
                <Col sm={8} className="cont-part-dashboard">
                  <div className="part-dashboard m-a-0_to_canvas">
                    <div style={{height: '210px'}}>
                      <h3>{t('DASHBOARD.NUMERO_DOSIS_PRODUCIDAS_DESCARTADAS_EDAD')}</h3>
                      {
                        Object.keys(contRecta.data).length > 0 ? (
                          <Scatter
                            data={{
                              labels: contRecta.data.map(v => v.x),
                              datasets: [
                                {
                                  data: contRecta.data,
                                  borderColor: "#FF6D29",
                                  pointBackgroundColor: "#FF6D29",
                                  fill: false,
                                  pointRadius: 2,
                                  pointHoverRadius: 4
                                }
                              ]
                            }}
                            width={200}
                            height={300}
                            options={{
                              legend: {
                                display: false
                              },
                              maintainAspectRatio: false,
                              responsive: true,
                              scales: {
                                xAxes: [{
                                  type: 'linear',
                                  position: "bottom",
                                  display: true,
                                  gridLines: {
                                    display: true,
                                    drawOnChartArea: true,
                                    drawTicks: true,
                                    lineWidth: 1,
                                    zeroLineWidth: 1
                                  },
                                  scaleLabel: {
                                    display: true,
                                    labelString: t('DASHBOARD.DOSIS_PRODUCIDAD_EXTRACCION'),
                                    fontFamily:  "'opens-sans', sans-serif",
                                    fontSize: 10,
                                    lineHeight: 1,
                                    padding: {
                                      top: 0,
                                      bottom: 0
                                    }
                                  },
                                  ticks: {
                                    display: true,
                                    fontFamily: "'opens-sans', sans-serif",
                                    fontSize: 10,
                                    lineHeight: 1,
                                    minor: {enabled: false},
                                    major : {enabled: true},
                                    min: contRecta.minDosis,
                                    max: contRecta.maxDosis,
                                    beginAtZero: false,
                                    callback: function(value) {
                                      return number_formatter(value, 0, separadorDec, separadorMil)
                                    },
                                  }
                                }],
                                yAxes: [{
                                  type: 'linear',
                                  position: 'left',
                                  display: true,
                                  gridLines: {
                                    display: true,
                                    drawOnChartArea: true,
                                    drawTicks: true
                                  },
                                  scaleLabel: {
                                    labelString: t('DASHBOARD.EDAD_VERRACO'),
                                    fontFamily: "'opens-sans', sans-serif",
                                    display: true,
                                    fontSize: 10,
                                    lineHeight: 1,
                                    padding: {
                                      top: 0,
                                      bottom: 0
                                    }
                                  },
                                  ticks: {
                                    display: true,
                                    fontFamily: "'opens-sans', sans-serif",
                                    fontSize: 10,
                                    lineHeight: 1,
                                    minor: {enabled: false},
                                    major : {enabled: true},
                                    beginAtZero: true,
                                    min: 0,
                                    max: contRecta.maxMeses,
                                    stepSize: 3,
                                    autoSkip: false,
                                    callback: function(value, index, values) {
                                      if (value === 0) {
                                        values[index] = '0'
                                      } else if (value === 6) {
                                        values[index] = '6'
                                      } else if (value === 9) {
                                        values[index] = '9'
                                      } else if (value === 12) {
                                        values[index] = '12'
                                      } else if (value === 18) {
                                        values[index] = '18'
                                      } else if (value === 24) {
                                        values[index] = '24'
                                      } else if (value === 36) {
                                        values[index] = '36'
                                      } else {
                                        values[index] = ''
                                      }
                                      return values[index];
                                    }
                                  }
                                }],
                              },
                              tooltips: {
                                enabled: true,
                                displayColors: false,
                                bodyFontFamily: "'opens-sans', sans-serif",
                                callbacks: {
                                    label: function(tooltipItems, data) {
                                      if (tooltipItems.yLabel >= 0 && tooltipItems.yLabel < 6) {
                                        tooltipItems.yLabel = t('DASHBOARD.TOOLTIP_RECTA2') + number_formatter(tooltipItems.xLabel, 0, separadorDec, separadorMil)
                                      }
                                      if (tooltipItems.yLabel >= 6 && tooltipItems.yLabel < 9) {
                                        tooltipItems.yLabel = t('DASHBOARD.TOOLTIP_RECTA3') + number_formatter(tooltipItems.xLabel, 0, separadorDec, separadorMil)
                                      }
                                      if (tooltipItems.yLabel >= 9 && tooltipItems.yLabel < 12) {
                                        tooltipItems.yLabel = t('DASHBOARD.TOOLTIP_RECTA4') + number_formatter(tooltipItems.xLabel, 0, separadorDec, separadorMil)
                                      }
                                      if (tooltipItems.yLabel >= 12 && tooltipItems.yLabel < 18) {
                                        tooltipItems.yLabel = t('DASHBOARD.TOOLTIP_RECTA5') + number_formatter(tooltipItems.xLabel, 0, separadorDec, separadorMil)
                                      }
                                      if (tooltipItems.yLabel >= 18 && tooltipItems.yLabel < 24) {
                                        tooltipItems.yLabel = t('DASHBOARD.TOOLTIP_RECTA6') + number_formatter(tooltipItems.xLabel, 0, separadorDec, separadorMil)
                                      }
                                      if (tooltipItems.yLabel >= 24 && tooltipItems.yLabel < 36) {
                                        tooltipItems.yLabel = t('DASHBOARD.TOOLTIP_RECTA7') + number_formatter(tooltipItems.xLabel, 0, separadorDec, separadorMil)
                                      }
                                      if (tooltipItems.yLabel >= 36) {
                                        tooltipItems.yLabel = t('DASHBOARD.TOOLTIP_RECTA8') + number_formatter(tooltipItems.xLabel, 0, separadorDec, separadorMil)
                                      }
                                      return tooltipItems.yLabel.toString();
                                    }
                                }
                              }
                            }}
                          />) : null
                        }
                    </div>
                  </div>
                </Col>
              ) : null
            }
          </Row>
        </SimplePage>
      </div>
    )
  }
}

export default Dashboard