import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize} from 'redux-form'
import grupoVerracoActionTypes from '../../constants/actions/grupoVerraco/GrupoVerraco'
import {fetchGrupoVerracoSuccess, recuperarDatosModalGrupoVerracoSuccess, duplicarDatosModalGrupoVerracoSuccess, cambiarEstadoGrupoVerracoSuccess, onDeleteGrupoVerracoSuccess,
  fetchFiltrarGrupoVerracoSuccess, deSeleccionarTodosGrupoVerraco, fetchVerracosGrupoVerracoSuccess, anadirVerracoSuccess, cerrarModalSeleccionarVerraco, onDeleteVerracoAnadidoSuccess,
fetchFiltrarVerracoModalVerracoSuccess, submitNuevoGrupoVerracoSuccess, editarGrupoVerracoSuccess, crearCsvGrupoVerracoSuccess, imprimirPdfGrupoVerracoSuccess} from '../../actions/grupoVerraco/GrupoVerraco'
import {openModal, closeModal, openSimpleModal, openMensajeAlerta} from '../../actions/common'
import { yesNoModal } from '../modal/yesNoModal'
import getGrupoVerracoList from '../../services/grupoVerraco/getGrupoVerracoList'
import addGrupoVerraco from '../../services/grupoVerraco/addGrupoVerraco'
import getGrupoVerraco from '../../services/grupoVerraco/getGrupoVerraco'
import editGrupoVerraco from '../../services/grupoVerraco/editGrupoVerraco'
import getGrupoVerracoParaDuplicar from '../../services/grupoVerraco/getGrupoVerracoParaDuplicar'
import getGrupoVerracoCambioEstado from '../../services/grupoVerraco/getGrupoVerracoCambioEstado'
import deleteGrupoVerraco from '../../services/grupoVerraco/deleteGrupoVerraco'
import getFiltrarGrupoVerraco from '../../services/grupoVerraco/getFiltrarGrupoVerraco'
import getCambiarEstadoSeleccion from '../../services/grupoVerraco/getCambiarEstadoSeleccion'
import getDeleteSeleccion from '../../services/grupoVerraco/getDeleteSeleccion'
import getCrearCsv from '../../services/grupoVerraco/getCrearCsv'
import getVerracoGrupoVerracoList from '../../services/grupoVerraco/getVerracoGrupoVerracoList'
import getAnadirVerracos from '../../services/grupoVerraco/getAnadirVerracos'
import getFiltrarGrupoVerracoSinAsignar from '../../services/grupoVerraco/getFiltrarGrupoVerracoSinAsignar'
import getImprimirGrupoVerraco from '../../services/grupoVerraco/getImprimirGrupoVerraco'
import {date_formatter, dateTimeZones} from '../../util/formatFunctions'
import {comboNombreGrupoVerraco, comboVerraco} from '../../actions/combos/combos'

export function * fetchGrupoVerraco () {
  try {
    const list = yield call(getGrupoVerracoList, null)
    yield put(fetchGrupoVerracoSuccess(list))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchfetchGrupoVerraco () {
  yield takeLatest(grupoVerracoActionTypes.FETCH_GRUPO_VERRACO, fetchGrupoVerraco)
}

export function * submitNuevoGrupoVerraco ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const insercionGrupoVerraco = yield call(addGrupoVerraco, values, auth.token)
    yield put(submitNuevoGrupoVerracoSuccess(insercionGrupoVerraco))
    if (insercionGrupoVerraco.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-insercion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    yield put(closeModal())
    let val = yield select(state => state.grupoVerraco.filtros)
    const lista = yield call(getFiltrarGrupoVerraco, val, auth.token)
    yield put(fetchGrupoVerracoSuccess(lista))
    yield put(fetchFiltrarGrupoVerracoSuccess(val))
    yield put(comboNombreGrupoVerraco())
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchSubmitNuevoGrupoVerraco() {
  yield takeLatest(grupoVerracoActionTypes.SUBMIT_NUEVO_GRUPO_VERRACO, submitNuevoGrupoVerraco)
}

export function * recuperarDatosModalGrupoVerraco ({idGrupoVerraco}) {
  try {
    let auth = yield select(state => state.auth)
    const registroModificar = yield call(getGrupoVerraco, idGrupoVerraco)
    const data = registroModificar.grupoVerraco
    data.verraco = registroModificar.verraco
    yield put(recuperarDatosModalGrupoVerracoSuccess(data))
    data.fechaAsignacion = dateTimeZones(auth.zonaHorariaString)
    data.fechaDesasignacion = dateTimeZones(auth.zonaHorariaString)
    yield put(initialize('ModalGrupoVerraco', data))
    yield put(openModal())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}

export function * watchRecuperarDatosModalGrupoVerraco () {
  yield takeLatest(grupoVerracoActionTypes.RECUPERAR_DATOS_MODAL_GRUPO_VERRACO, recuperarDatosModalGrupoVerraco)
}

export function * abrirModalVacioGrupoVerraco () {
  try {
    let auth = yield select(state => state.auth)
    const valores = {activo: true, fechaCreacion: dateTimeZones(auth.zonaHorariaString), fechaAsignacion: dateTimeZones(auth.zonaHorariaString), fechaDesasignacion: dateTimeZones(auth.zonaHorariaString)}
    yield put(initialize('ModalGrupoVerraco', valores))
    yield put(openModal())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}

export function * watchAbrirModalVacioGrupoVerraco() {
  yield takeLatest(grupoVerracoActionTypes.ABRIR_MODAL_VACIO_GRUPO_VERRACO, abrirModalVacioGrupoVerraco)
}

export function * editarGrupoVerraco ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const edicionGrupoVerraco = yield call(editGrupoVerraco, values, auth.token)
    yield put(editarGrupoVerracoSuccess(edicionGrupoVerraco))
    if (edicionGrupoVerraco.actualizado === true) {
      yield put(openMensajeAlerta('modificacion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-modificacion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    yield put(closeModal())
    yield put(comboNombreGrupoVerraco())
    let val = yield select(state => state.grupoVerraco.filtros)
    if (val.nombreGrupoVerraco && values.nombreGrupoVerraco){
      val.nombreGrupoVerraco = {value: val.nombreGrupoVerraco.value, label: values.nombreGrupoVerraco}
    }
    const lista = yield call(getFiltrarGrupoVerraco, val, auth.token)
    yield put(fetchGrupoVerracoSuccess(lista))
    yield put(fetchFiltrarGrupoVerracoSuccess(val))
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /**/
  }
}

export function * watchEditarGrupoVerraco () {
  yield takeLatest(grupoVerracoActionTypes.EDITAR_GRUPO_VERRACO, editarGrupoVerraco)
}

export function * duplicarDatosModalGrupoVerraco ({idGrupoVerraco, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const duplicar = yield call(getGrupoVerracoParaDuplicar, idGrupoVerraco)
    const dataDuplicar = duplicar.grupoVerraco
    dataDuplicar.verraco = duplicar.verraco
    dataDuplicar.fechaAsignacion = dateTimeZones(auth.zonaHorariaString)
    yield put(duplicarDatosModalGrupoVerracoSuccess(dataDuplicar))
    yield put(initialize('ModalGrupoVerraco', dataDuplicar))
    yield put(openModal())
    if (onSuccessCallback) yield onSuccessCallback()
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /* */
  }
}

export function * watchDuplicarDatosModalGrupoVerraco () {
  yield takeLatest(grupoVerracoActionTypes.DUPLICAR_DATOS_MODAL_GRUPO_VERRACO, duplicarDatosModalGrupoVerraco)
}

export function * cambiarEstadoGrupoVerraco ({idGrupoVerraco}) {
  try {
    yield put(openSimpleModal('cambiarEstado'))
    const confirmed = yield call(yesNoModal, {modalType: 'cambiarEstado'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const dataEstado = yield call(getGrupoVerracoCambioEstado, idGrupoVerraco, auth.token)
      yield put(cambiarEstadoGrupoVerracoSuccess(dataEstado))
      if (dataEstado.actualizado === true) {
        yield put(openMensajeAlerta('cambio-estado'))
      } else {
        yield put(openMensajeAlerta('no-cambio-estado'))
      }
      let val = yield select(state => state.grupoVerraco.filtros)
      const lista = yield call(getFiltrarGrupoVerraco, val, auth.token)
      yield put(fetchGrupoVerracoSuccess(lista))
      yield put(fetchFiltrarGrupoVerracoSuccess(val))
    }

  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}

export function * watchCambiarEstadoGrupoVerraco () {
  yield takeLatest(grupoVerracoActionTypes.CAMBIAR_ESTADO_GRUPO_VERRACO, cambiarEstadoGrupoVerraco)
}

export function * onDeleteGrupoVerraco ({idGrupoVerraco}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const dataDelete = yield call(deleteGrupoVerraco, idGrupoVerraco, auth.token)
      yield put(onDeleteGrupoVerracoSuccess(dataDelete))
      if (dataDelete.eliminado === true && dataDelete.asociado === false) {
        yield put(openMensajeAlerta('eliminacion'))
      } else if (dataDelete.eliminado === false && dataDelete.asociado === true) {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(comboNombreGrupoVerraco())
      let val = yield select(state => state.grupoVerraco.filtros)
      if (val.nombreGrupoVerraco && val.nombreGrupoVerraco.value === idGrupoVerraco) {
        val.nombreGrupoVerraco = null
      }
      const lista = yield call(getFiltrarGrupoVerraco, val, auth.token)
      yield put(fetchGrupoVerracoSuccess(lista))
      yield put(fetchFiltrarGrupoVerracoSuccess(val))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}

export function * watchOnDeleteGrupoVerraco () {
  yield takeLatest(grupoVerracoActionTypes.ON_DELETE_GRUPO_VERRACO, onDeleteGrupoVerraco)
}

export function * fetchFiltrarGrupoVerraco ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const list = yield call(getFiltrarGrupoVerraco, values, auth.token)
    yield put(fetchGrupoVerracoSuccess(list))
    yield put(fetchFiltrarGrupoVerracoSuccess(values))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchFetchFiltrarGrupoVerraco () {
  yield takeLatest(grupoVerracoActionTypes.FETCH_FILTRAR_GRUPO_VERRACO, fetchFiltrarGrupoVerraco)
}

export function * cambiarEstadoSeleccionGrupoVerraco ({list}) {
  try {
    yield put(openSimpleModal('cambiarEstado'))
    const confirmed = yield call(yesNoModal, {modalType: 'cambiarEstado'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      let dataEstadoSeleccion = yield call(getCambiarEstadoSeleccion, list, auth.token)
      if (dataEstadoSeleccion.actualizado === true) {
        yield put(openMensajeAlerta('cambio-estado'))
      } else {
        yield put(openMensajeAlerta('no-cambio-estado'))
      }
      yield put(deSeleccionarTodosGrupoVerraco())
      yield put(initialize('FiltrosGrupoVerraco'))
      let val = yield select(state => state.grupoVerraco.filtros)
      const lista = yield call(getFiltrarGrupoVerraco, val, auth.token)
      yield put(fetchGrupoVerracoSuccess(lista))
      yield put(fetchFiltrarGrupoVerracoSuccess(val))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchCambiarEstadoSeleccionGrupoVerraco () {
  yield takeLatest(grupoVerracoActionTypes.CAMBIAR_ESTADO_SELECCION_GRUPO_VERRACO, cambiarEstadoSeleccionGrupoVerraco)
}

export function * deleteSeleccionGrupoVerraco ({list}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      let dataDeleteSeleccion = yield call(getDeleteSeleccion, list, auth.token)
      if (dataDeleteSeleccion.eliminado === true && dataDeleteSeleccion.asociado === false) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(deSeleccionarTodosGrupoVerraco())
      yield put(initialize('FiltrosGrupoVerraco'))
      yield put(comboNombreGrupoVerraco())
      let val = yield select(state => state.grupoVerraco.filtros)
      list.forEach((li) => {
        if (val.nombreGrupoVerraco && val.nombreGrupoVerraco.value === li.idGrupoVerraco) {
          val.nombreGrupoVerraco = null
        }
      })
      const lista = yield call(getFiltrarGrupoVerraco, val, auth.token)
      yield put(fetchGrupoVerracoSuccess(lista))
      yield put(fetchFiltrarGrupoVerracoSuccess(val))
      yield put(initialize('filtrosGrupoVerraco', val))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchDeleteSeleccionGrupoVerraco () {
  yield takeLatest(grupoVerracoActionTypes.DELETE_SELECCION_GRUPO_VERRACO, deleteSeleccionGrupoVerraco)
}

export function * crearCsvGrupoVerraco ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosGrupoVerracoExcel = yield call(getCrearCsv, list, auth.token)
    yield put(crearCsvGrupoVerracoSuccess(datosGrupoVerracoExcel))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchCrearCsvGrupoVerraco () {
  yield takeLatest(grupoVerracoActionTypes.CREATE_CSV_GRUPO_VERRACO, crearCsvGrupoVerraco)
}

export function * imprimirPdfGrupoVerraco ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosGrupoVerracoExcel = yield call(getImprimirGrupoVerraco, list, auth.token)
    yield put(imprimirPdfGrupoVerracoSuccess(datosGrupoVerracoExcel))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirPdfGrupoVerraco () {
  yield takeLatest(grupoVerracoActionTypes.IMPRIMIR_PDF_GRUPO_VERRACO, imprimirPdfGrupoVerraco)
}

export function * guardarYnuevoGrupoVerraco ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    yield call(addGrupoVerraco, values, auth.token)
    const valores = {activo: true, fechaCreacion: dateTimeZones(auth.zonaHorariaString), fechaAsignacion: dateTimeZones(auth.zonaHorariaString)}
    yield put(initialize('ModalGrupoVerraco', valores))
   
    let val = yield select(state => state.grupoVerraco.filtros)
    const lista = yield call(getFiltrarGrupoVerraco, val, auth.token)
    yield put(fetchGrupoVerracoSuccess(lista))
    yield put(fetchFiltrarGrupoVerracoSuccess(val))
    yield put(comboNombreGrupoVerraco())
    if (onSuccessCallback) yield onSuccessCallback()
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchGuardarYnuevoGrupoVerraco () {
  yield takeLatest(grupoVerracoActionTypes.GUARDAR_Y_NUEVO_GRUPO_VERRACO, guardarYnuevoGrupoVerraco)
}

export function * fetchVerracosGrupoVerraco ({verracosAnadidos}) {
  try {
    let auth = yield select(state => state.auth)
    const listVerracosEnGrupo = yield call(getVerracoGrupoVerracoList, verracosAnadidos, auth.token)
    yield put(fetchVerracosGrupoVerracoSuccess(listVerracosEnGrupo))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchFetchVerracosGrupoVerraco () {
  yield takeLatest(grupoVerracoActionTypes.FETCH_VERRACOS_GRUPO_VERRACO, fetchVerracosGrupoVerraco)
}

export function * anadirVerraco ({listadoAnadir}) {
  try {
    let auth = yield select(state => state.auth)
    const verracosAnadidos = yield call(getAnadirVerracos, listadoAnadir, auth.token)
    verracosAnadidos.filter((verraco) => {
      let newDate = dateTimeZones(auth.zonaHorariaString)
      let date = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
      let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
      let year = newDate.getFullYear()
      verraco.fechaAsignacion = year + '-' + month + '-' + date
      return verraco.fechaAsignacion
    })
    yield put(anadirVerracoSuccess(verracosAnadidos))
    // yield put(initialize('ModalVerracoGrupoVerraco', ''))
    yield put(cerrarModalSeleccionarVerraco())
    /*const verracosNoMostrar = yield select(state => state.grupoVerraco.verracosAnadidos)
    const listVerracosEnGrupo = yield call(getVerracoGrupoVerracoList, verracosNoMostrar, auth.token)
    yield put(fetchVerracosGrupoVerracoSuccess(listVerracosEnGrupo))*/
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchAnadirVerraco () {
  yield takeLatest(grupoVerracoActionTypes.ANADIR_VERRACO, anadirVerraco)
}

export function * onDeleteVerracoAnadido ({idVerraco}) {
  try {
    const verracosNoMostrar = yield select(state => state.grupoVerraco.verracosAnadidos)
    const verracosParaMostrar = verracosNoMostrar.filter((verraco) => {
      if (verraco.idVerraco !== idVerraco){
        return verraco
      }
    })
    let auth = yield select(state => state.auth)

    if (Object.keys(verracosParaMostrar).length > 0) {
      const verracosAnadidos = yield call(getAnadirVerracos, verracosParaMostrar, auth.token)
    yield put(onDeleteVerracoAnadidoSuccess(verracosAnadidos))
    } else {
      const verracosAnadidos = []
    yield put(onDeleteVerracoAnadidoSuccess(verracosAnadidos))
    }

    const listaMostrar = yield select(state => state.grupoVerraco.verracosAnadidos)
    const listVerracosEnGrupo = yield call(getVerracoGrupoVerracoList, listaMostrar, auth.token)
    yield put(fetchVerracosGrupoVerracoSuccess(listVerracosEnGrupo))

  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchOnDeleteVerracoAnadido () {
  yield takeLatest(grupoVerracoActionTypes.ON_DELETE_VERRACO_ANADIDO, onDeleteVerracoAnadido)
}

export function * deleteSeleccionVerracosGrupoVerraco ({listVerracosQuitar}) {
  try {
    let auth = yield select(state => state.auth)

    if (Object.keys(listVerracosQuitar).length > 0) {
      const verracosAnadidos = yield call(getAnadirVerracos, listVerracosQuitar, auth.token)
      yield put(onDeleteVerracoAnadidoSuccess(verracosAnadidos))
    } else {
      const verracosAnadidos = []
      yield put(onDeleteVerracoAnadidoSuccess(verracosAnadidos))
    }

    const listaMostrar = yield select(state => state.grupoVerraco.verracosAnadidos)
    const listVerracosEnGrupo = yield call(getVerracoGrupoVerracoList, listaMostrar, auth.token)
    yield put(fetchVerracosGrupoVerracoSuccess(listVerracosEnGrupo))

  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchDeleteSeleccionVerracosGrupoVerraco () {
  yield takeLatest(grupoVerracoActionTypes.DELETE_SELECCION_VERRACOS_GRUPO_VERRACO, deleteSeleccionVerracosGrupoVerraco)
}

export function * fetchFiltrarVerracoModalVerraco ({values}) {
  try {
    let auth = yield select(state => state.auth)
    let verracosAnadidos = yield select(state => state.grupoVerraco.verracosAnadidos)
    values.list = verracosAnadidos
    const list = yield call(getFiltrarGrupoVerracoSinAsignar, values, auth.token)
    yield put(fetchVerracosGrupoVerracoSuccess(list))
    yield put(fetchFiltrarVerracoModalVerracoSuccess(values))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchFetchFiltrarVerracoModalVerraco () {
  yield takeLatest(grupoVerracoActionTypes.FETCH_FILTRAR_VERRACO_MODAL_VERRACO, fetchFiltrarVerracoModalVerraco)
}
