import React, { Component } from 'react'
import { Modal, Row } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { required } from '../../util/validationFunctions'
import Button from '../comun/button/Button'
import ButtonChangeRecord from '../comun/button/ButtonChangeRecord'
import ButtonLockable from '../comun/button/ButtonLockable'
import InputText from '../comun/form/InputText'
import InputTextArea from '../comun/form/InputTextArea'
import InputCheckBox from '../comun/form/InputCheckBox'
import './LineaGenetica.scss'
import {permisos as permisosConstant} from '../../constants/permisos'

class ModalLineaGenetica extends Component {
  submitNuevaLineaGenetica (values) {
    return new Promise((resolve, reject) => {
      if (this.props.data){
        this.props.actions.editarLineaGenetica(values, resolve, reject)
      } else {
        this.props.actions.submitNuevaLineaGenetica(values, resolve, reject)
      }
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  guardarYnuevo(values){
    return new Promise((resolve, reject) => {
      this.props.actions.guardarYnuevo(values, resolve, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  duplicar(values){
    return new Promise((resolve, reject) => {
      this.props.duplicar(values, resolve, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  render () {
    const {
      t, handleSubmit, submitting, showModalLineaGenetica, list: {lineasGeneticas}, data, duplicar, actions: {recuperarDatosModal, closeModalLineaGenetica}
    } = this.props

    return (
      <Modal show={showModalLineaGenetica} onHide={closeModalLineaGenetica} aria-labelledby="contained-modal-title-lg" className="modal-linea-genetica" backdrop="static">
      <form className="form-linea-genetica">
        <Modal.Header closeButton={!submitting}>
          <Modal.Title>
          {
            data ? (
              t('LINEA_GENETICA.MODAL.TITULO_EDITAR')
            ) : (
              t('LINEA_GENETICA.MODAL.TITULO_NUEVO')
            )
          }
          </Modal.Title>
          {!submitting && data && data.idLinea && (
            <ButtonChangeRecord list={lineasGeneticas} idElemento="idLinea" currentId={data.idLinea} getNextRecord={recuperarDatosModal} />
          )}
        </Modal.Header>
        <Modal.Body>
          <Row>
              <Field
                colSm={6}
                id="nombre-linea-genetica"
                name="nombreLinea"
                component={InputText}
                controlLabel={t('LINEA_GENETICA.MODAL.NOMBRE_LINEA_GENETICA') + '*'}
                customClass='nombre-linea-genetica'
                validate={required}
                maxLength={true}
                valorMaxLength={100}
              />
              <Field
                colSm={6}
                id="abreviatura-linea-genetica"
                name="codLinea"
                component={InputText}
                controlLabel={t('LINEA_GENETICA.MODAL.ABREVIATURA') + '*'}
                customClass='nombre-linea-genetica'
                validate={required}
                maxLength={true}
                subTextoMax={true}
                valorMaxLength={4}
                t={t}
              />
              <Field
                colSm={12}
                id="descripcion-linea-genetica"
                name="descripcionLinea"
                component={InputTextArea}
                componentClass="textarea"
                controlLabel={t('LINEA_GENETICA.MODAL.DESCRIPCION')}
                customClass='descripcion-linea-genetica'
              />
              <Field
                id="activo"
                name="activo"
                colSm={12}
                inline
                component={InputCheckBox}
                claseActivo="onoffswitch-label"
                controlLabel={t('LINEA_GENETICA.MODAL.ACTIVO')}
              />
              <input type="hidden" name="cambiarEstado"/>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {
            this.props.data && (
              <Button
                type="button"
                className="btn btn-white btn-sm"
                disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.LINEAS_GENETICAS] < 2) || submitting}
                onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.LINEAS_GENETICAS] > 1) && !submitting ? handleSubmit(this.duplicar.bind(this)) : undefined}
              >
                {t('LINEA_GENETICA.MODAL.DUPLICAR')}
              </Button>
            )
          }
          {
            !this.props.data && (
              <ButtonLockable
                processing={submitting}
                hasIcon={true}
                showLoaderOnClick={true}
                type="button"
                className="btn btn-primary btn-sm"
                disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.LINEAS_GENETICAS] < 2) || submitting}
                onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.LINEAS_GENETICAS] > 1) && !submitting) ? handleSubmit(this.guardarYnuevo.bind(this)) : () => {}}
              >
                {t('LINEA_GENETICA.MODAL.GUARDAR_Y_NUEVO')}
              </ButtonLockable>
            )
          }
          <ButtonLockable
            processing={submitting}
            hasIcon={true}
            showLoaderOnClick={true}
            type="button"
            className="btn btn-primary btn-sm"
            disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.LINEAS_GENETICAS] < 2) || submitting}
            onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.LINEAS_GENETICAS] > 1) && !submitting) ? handleSubmit(this.submitNuevaLineaGenetica.bind(this)) : () => {}}
          >
            {t('LINEA_GENETICA.MODAL.GUARDAR')}
          </ButtonLockable>
          <Button
            type="button"
            className={"btn btn-white btn-sm" + (submitting ? ' disabled' : '')}
            onClick={() => !submitting && closeModalLineaGenetica()}
          >
            {t('LINEA_GENETICA.MODAL.CANCELAR')}
          </Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalLineaGenetica',
})(ModalLineaGenetica))
