import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosInformesExtracciones from '../../components/informes/FiltrosInformesExtracciones'
import {obtenerInformeExtracciones, imprimirPdfInformesExtracciones, crearCsvInformeExtracciones} from '../../actions/informes/informes'
import {comboVerraco, comboNombreGrupoVerraco, comboTipoDosis, comboIndex, comboRaza, comboLineaGenetica, comboOperario, comboTipoIntervencionSanitaria, comboClienteConVerracosEnPropiedad
  } from '../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
   ...state.informes,
   auth: state.auth,
   combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      obtenerInformeExtracciones,
      comboVerraco,
      comboNombreGrupoVerraco,
      comboTipoDosis,
      comboIndex,
      comboRaza,
      comboLineaGenetica,
      comboOperario,
      comboTipoIntervencionSanitaria,
      comboClienteConVerracosEnPropiedad,
      imprimirPdfInformesExtracciones,
      crearCsvInformeExtracciones
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosInformesExtracciones))