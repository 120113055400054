import React, { Component } from 'react'
import { Modal, Row } from 'react-bootstrap'
import {reduxForm} from 'redux-form'
import Button from '../comun/button/Button'
import ButtonLockable from '../comun/button/ButtonLockable'
import Dropzone from 'react-dropzone'
import { Unarchive } from '@material-ui/icons'
import './CargaIndex.scss'

class CargaIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      archivos: '',
      archivoCargado: false
    }
    this.descargarPlantillaCsv = this.descargarPlantillaCsv.bind(this)
    this.submitCargaIndex = this.submitCargaIndex.bind(this)
  }

  onDrop (archivos) {
    archivos.map((archivo, i) => {
      var reader = new FileReader();
      reader.onloadend = () => {
        archivo.content=reader.result
        this.props.actions.subirArchivosCargaIndex({name: archivo.name, file: reader.result})
        this.setState({archivos: {name: archivo.name, file: reader.result}})
        this.setState({archivoCargado: true})
      }
      reader.readAsDataURL(archivo)
    })
  }

  submitCargaIndex () {
    return new Promise((resolve, reject) => {
      this.props.actions.submitCargaIndex(this.state.archivos, resolve, reject)
      this.setState({archivoCargado: false})
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  cerrarModalCargaIndex () {
    this.props.actions.cerrarModalCargaIndex()
    this.setState({archivos: ''})
    this.setState({archivoCargado: false})
  }

  descargarPlantillaCsv () {
    return new Promise((resolve, reject) => {
      this.props.actions.descargarPlantillaCsv(resolve, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  render () {
    const {
      t, handleSubmit, submitting, showModalCargaIndex, archivo, listCargaIndex
      } = this.props

    if(!showModalCargaIndex) return null
    return (
      <Modal show={showModalCargaIndex} onHide={() => this.cerrarModalCargaIndex()} aria-labelledby="contained-modal-title-lg" backdrop="static" className="form-carga-index">
      <form>
        <Modal.Header closeButton={!submitting}>
          <Modal.Title>
          {t('CARGA_INDEX.TITLE')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className="archivos-carga-index">
              <Dropzone
                accept=".csv"
                onDrop={this.onDrop.bind(this)}
                uploadMultiple={true}
                maxFiles={null}
                // maxSize={200000}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}  className="custom-file-label">
                    <input {...getInputProps()} />
                    <label><span className="explorar-input-file">{t('COPIA_SEGURIDAD.SUBIR_ARCHIVO')}</span></label>
                  </div>
                )}
              </Dropzone>
            </div>
            {
              Object.keys(archivo).length > 0 ? (
                <p className="nombre-archivo-subido"><Unarchive />{archivo.name}</p>
              ) : null
            }
            {
              Object.keys(listCargaIndex).length > 0 ? (
                <div className="resultado-verracos-index">
                  {
                    listCargaIndex.map((list, i) => {
                      return <p key={i} className={list.isertado === true ? 'index-insertado' : 'index-no-insertado'}>
                        {
                          list.isertado === true ? (
                            '- ' + list.codVerraco + ' | ' + list.fecha + ' | ' + list.indexVerraco + '. ' + t('CARGA_INDEX.INSERTADO_CORRECTAMENTE')
                          ) : (
                            '- ' + list.codVerraco + ' | ' + list.fecha + ' | ' + list.indexVerraco + ' ' + t('CARGA_INDEX.FECHA_COINCIDENTE')
                          )
                        }
                      </p>
                    })
                  }
                </div>
                ) : (
                  listCargaIndex === false && this.state.archivoCargado === false ? (
                    <p className="index-fallido">{t('CARGA_INDEX.NO_SE_PUEDE_SUBIR_ARCHIVO')}</p>
                  ) : null
                )
            }
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            className={"btn btn-primary btn-sm" + (submitting ? ' disabled' : '')}
            onClick={!submitting ? handleSubmit(this.descargarPlantillaCsv) : () => {}}
          >
            {t('CARGA_INDEX.DESCARGAR_EJEMPLO_CSV')}
          </Button>
          {
            Object.keys(listCargaIndex).length === 0 ? (
              <ButtonLockable
                processing={submitting}
                hasIcon={true}
                showLoaderOnClick={true}
                type="button"
                className="btn btn-primary btn-sm"
                disabled={submitting}
                onClick={!submitting ? handleSubmit(this.submitCargaIndex) : () => {}}
              >
                {t('CARGA_INDEX.ACEPTAR')}
              </ButtonLockable>
            ) : null
          }
          <Button
            type="button"
            className={"btn btn-white btn-sm" + (submitting ? ' disabled' : '')}
            onClick={() => !submitting && this.cerrarModalCargaIndex()}
          >
            {Object.keys(listCargaIndex).length > 0 ? (t('CARGA_INDEX.CERRAR')) : (t('CARGA_INDEX.CANCELAR'))}
          </Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'CargaIndex',
})(CargaIndex))