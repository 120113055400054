import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputSelect from '../comun/form/InputSelect'
import InputDatePicker from '../comun/form/InputDatePicker'
import {date_formatter} from '../../util/formatFunctions'
import { required } from '../../util/validationFunctions'

class FiltrosAsignarAnalisisPool extends Component {
   constructor () {
    super()
     this.fetchAnalisisDisponibles = this.fetchAnalisisDisponibles.bind(this)
     this.deleteSeleccionDisponiblesAnalisisPool = this.deleteSeleccionDisponiblesAnalisisPool.bind(this)
     this.state = {
      fitrosSeleccionados: []
    }
  }

  fetchAnalisisDisponibles(values){
    this.props.actions.fetchAnalisisDisponibles(values, this.props.onUpdateList)
  }

  intercambiarCheckModalAsignarAnalisisPool (value) {
    if (value === true) {
      this.props.actions.seleccionarTodosDisponiblesExtracciones()
    } else {
      this.props.actions.deseleccionarTodosDisponiblesExtracciones()
    }
  }

  deleteSeleccionDisponiblesAnalisisPool(){
    const itemsSeleccion = this.props.pool.disponiblesAnalisisList.map((row) => {
      return this.props[this.props.form].values['check' + row[this.props.idElemento]] === true && (
        {
          'check': this.props[this.props.form].values['check' + row[this.props.idElemento]],
          [this.props.idElemento]: row[this.props.idElemento]
        }
      )
    })
    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.deleteSeleccionDisponiblesAnalisisPool(list)
    this.props.change('seleccionar-todos-modal-asignar-analisis-pool', false)
  }

  quitarFiltros (value) {
    let values = this.props.pool.disponiblesAnalisisFiltros
    values[value] = null
    this.props.actions.fetchAnalisisDisponibles(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }

  // Se deja comentado porque fuerza a utilizar la primera raza en vez de la del propio pool.
  // componentDidMount () {
  //   if (Object.keys(this.props.combos.comboRaza).length > 0 && this.props.razaSeleccionada === false) {
  //     this.props.change('idRaza', this.props.combos.comboRaza[0])
  //   }
  // }

  render () {
    const {
      t, handleSubmit, pool: {asignadosAnalisisList, disponiblesAnalisisFiltros},
      combos: {
        comboRaza, comboTipoDiluyente
      }
    } = this.props
    const tKey = 'POOL_EXTRACCIONES.FORM.FORM.ASIGNAR_ANALISIS_POOL.MODAL.TABLE.'

    return (
      <div>
        <div className="tabla-filtros open-filters">
          <form className="custom-form">
            <Field
              colSm={2}
              id="idRaza"
              name="idRaza"
              controlLabel={t(tKey + 'FILTROS.RAZA') + '*'}
              component={InputSelect}
              options={comboRaza}
              valueKey="value"
              labelKey="label"
              onInputChange={handleSubmit(this.fetchAnalisisDisponibles)}
              noPredeterminado={false}
              validate={required}
              disabled={asignadosAnalisisList && asignadosAnalisisList.length > 0}
              isClearable={false}
            />
            <Field
              colSm={2}
              id="idTipoDiluyente"
              name="idTipoDiluyente"
              controlLabel={t(tKey + 'FILTROS.TIPO_DILUYENTE')}
              component={InputSelect}
              options={comboTipoDiluyente}
              valueKey="value"
              labelKey="label"
              onInputChange={handleSubmit(this.fetchAnalisisDisponibles)}
              noPredeterminado={false}
            />
            <Field
              colSm={2}
              id="fechaExtraccion"
              name="fechaExtraccion"
              controlLabel={t(tKey + 'FILTROS.FECHA_EXTRACCION')}
              component={InputDatePicker}
              onInputChange={handleSubmit(this.fetchAnalisisDisponibles)}
              esFiltro={true}
            />
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(disponiblesAnalisisFiltros).length > 0 && [
                    (disponiblesAnalisisFiltros.idRaza && <span key="filtros.idRaza" className="badge-dark">{t(tKey + 'FILTROS.RAZA') + ': ' + disponiblesAnalisisFiltros.idRaza.label}</span>),
                    (disponiblesAnalisisFiltros.idTipoDiluyente && <span key="filtros.idTipoDiluyente" className="badge-dark">{t(tKey + 'FILTROS.TIPO_DILUYENTE') + ': ' + disponiblesAnalisisFiltros.idTipoDiluyente.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('idTipoDiluyente')}> x </button></span>),
                    (disponiblesAnalisisFiltros.fechaExtraccion && <span key="filtros.fechaExtraccion" className="badge-dark">{t(tKey + 'FILTROS.FECHA_EXTRACCION') + ': ' + date_formatter(disponiblesAnalisisFiltros.fechaExtraccion, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('fechaExtraccion')}> x </button></span>),
                  ]
                }
              </div>
            </div>
          </form>
        </div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              <div className="custom-check-w">
                <Field
                  id="seleccionar-todos-modal-asignar-analisis-pool"
                  name="seleccionar-todos-modal-asignar-analisis-pool"
                  colSm={12}
                  inline
                  component={InputCheckBox}
                  onInputChange={(value) => this.intercambiarCheckModalAsignarAnalisisPool(value)}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosDisponiblesAnalisisPool',
  destroyOnUnmount: false
})(FiltrosAsignarAnalisisPool))
