import React, { Component } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import {reduxForm, Field, setSubmitFailed} from 'redux-form'
import { required } from '../../util/validationFunctions'
import Button from '../comun/button/Button'
import ButtonChangeRecord from '../comun/button/ButtonChangeRecord'
import ButtonLockable from '../comun/button/ButtonLockable'
import InputText from '../comun/form/InputText'
import InputCheckBox from '../comun/form/InputCheckBox'
import './Empresa.scss'
import Dropzone from 'react-dropzone'
import { CloudUpload, Delete, Warning } from '@material-ui/icons'
import {permisos as permisosConstant} from '../../constants/permisos'

class ModalCentro extends Component {
  constructor (props) {
    super()
    this.state = {
      accepted: '',
      rejected: '',
      errorTipoCentro: false,
      imagen: ''
    }
  }

  componentDidMount () {
    this.props.change('activo', true)
  }

  componentDidUpdate (prevProps) {
    if (this.props.imagen !== prevProps.imagen){
      this.props.change({hidden: true})
      this.props.change({hidden: false})
    }

    if (this.props.formModalCentro !== prevProps.formModalCentro) {
      if (this.props.formModalCentro.values) {
        if (this.props.formModalCentro.values.principalCentro !== false || this.props.formModalCentro.values.procesadoCentro !== false || 
          this.props.formModalCentro.values.produccionCentro !== false) {
          this.setState({errorTipoCentro: false})
        } else {
          if (this.props.showModalCentro === true){
            this.props.dispatch(setSubmitFailed('ModalCentro'))
          }
        }
      }
    }

    if (this.props.dataCentro !== prevProps.dataCentro) {
      if (Object.keys(this.props.dataCentro).length > 0) {
        if (Object.keys(this.props.dataCentro.imagenCentro).length > 0) {
          if (this.props.dataCentro.imagenCentro.file !== null) {
            this.setState({accepted: this.props.dataCentro.imagenCentro.file.imagebase64})
            let file = {
              name: this.props.dataCentro.imagenCentro.name,
              file: {
                name: this.props.dataCentro.imagenCentro.name,
                path: this.props.dataCentro.imagenCentro.name,
                imagebase64: this.props.dataCentro.imagenCentro.file.imagebase64
              }
            }
            this.props.actions.uploadFileEmpresa(file.file)
          }
        }
      }
    }

    if (this.props.dataCentroDuplicar !== prevProps.dataCentroDuplicar) {
      if (Object.keys(this.props.dataCentroDuplicar).length > 0) {
        if (Object.keys(this.props.dataCentroDuplicar.imagenCentro).length > 0) {
          if (this.props.dataCentroDuplicar.imagenCentro.file !== null) {
            this.setState({accepted: this.props.dataCentroDuplicar.imagenCentro.file.imagebase64})
            let file = {
              name: this.props.dataCentroDuplicar.imagenCentro.name,
              file: {
                name: this.props.dataCentroDuplicar.imagenCentro.name,
                path: this.props.dataCentroDuplicar.imagenCentro.name,
                imagebase64: this.props.dataCentroDuplicar.imagenCentro.file.imagebase64
              }
            }
            this.props.actions.uploadFileEmpresa(file.file)
          }
        }
      }
    }
  }

  submitNuevoCentro (values) {
    return new Promise((resolve, reject) => {
      if (values.principalCentro === false && values.procesadoCentro === false && values.produccionCentro === false) {
        this.setState({errorTipoCentro: true})
        reject()
      } else {
        this.setState({errorTipoCentro: false})
        if (Object.keys(this.props.dataCentro).length > 0) {
          if (Object.keys(this.props.imagen).length === 0) {
            values.imagenCentro = this.props.dataCentro.imagenCentro
          } else {
            values.imagenCentro = this.props.imagen
          }
          this.props.actions.editarCentro(values, resolve, reject)
        } else if (Object.keys(this.props.dataCentroDuplicar).length > 0) {
          values.imagenCentro = this.props.dataCentroDuplicar.imagenCentro
          this.props.actions.submitNuevoCentro(values, resolve, reject)
        } else {
          values.imagenCentro = this.props.imagen
          this.props.actions.submitNuevoCentro(values, resolve, reject)
        }
      }
      this.setState({accepted: ''})
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  guardarYnuevoCentro(values){
    return new Promise((resolve, reject) => {
      this.props.actions.guardarYnuevoCentro(values, resolve, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  duplicar(values){
    return new Promise((resolve, reject) => {
      this.props.duplicar(values, resolve, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  onDrop (fileToUpload, rejectedFiles) {
    if (fileToUpload.length === 0 && rejectedFiles.length > 0) {
      this.setState({hasInvalidFile: true})
    } else {
      this.setState({accepted: fileToUpload.accepted[0]})
      if (fileToUpload.accepted.length > 0) {
        var reader = new FileReader();
        reader.onloadend = () => {
          var imagebase64=reader.result
          fileToUpload.accepted[0].imagebase64 = imagebase64
          this.setState({accepted: imagebase64})
        }
        this.props.actions.uploadFileEmpresa(fileToUpload.accepted[0])
        reader.readAsDataURL(fileToUpload.accepted[0])
      }
    }
  }

  eliminaImagen () {
    this.setState({accepted: ''})
  }

  cerrarModalCentro () {
    this.props.actions.cerrarModalCentro()
    this.setState({accepted: ''})
    let valors = {
      activo: true
    }
    this.props.initialize(valors)
  }

  render () {
    const {
      t, handleSubmit, submitting, listCentros, showModalCentro, dataCentro, urlRecursos, actions: {recuperarDatosCentro}
    } = this.props

    return (
      <Modal show={showModalCentro} onHide={() => this.cerrarModalCentro()} backdrop="static" aria-labelledby="contained-modal-title-lg" className="modal-empresa">
      <form className="form-empresa">
        <Modal.Header closeButton={!submitting}>
          <Modal.Title>
          {
            Object.keys(this.props.dataCentro).length > 0 ? 
            t('EMPRESA.MODAL.TITULO_EDITAR')
            :
            t('EMPRESA.MODAL.TITULO_NUEVO')
          }
          </Modal.Title>
          {!submitting && dataCentro && dataCentro.idCentro && (
            <ButtonChangeRecord list={listCentros} idElemento="idCentro" currentId={dataCentro.idCentro} getNextRecord={recuperarDatosCentro} />
          )}
        </Modal.Header>
        <Modal.Body>
          <Row>
              <Field
                colSm={4}
                id="nombreCentro"
                name="nombreCentro"
                component={InputText}
                controlLabel={t('EMPRESA.MODAL.NOMBRE') + '*'}
                customClass='campo-modal-centro'
                validate={required}
                maxLength={true}
                valorMaxLength={100}
              />
              <Field
                colSm={4}
                id="regaCentro"
                name="regaCentro"
                component={InputText}
                controlLabel={t('EMPRESA.MODAL.CODIGO_REGA')}
                customClass='campo-modal-centro'
                maxLength={true}
                valorMaxLength={30}
              />
              <Field
                colSm={4}
                id="cifCentro"
                name="cifCentro"
                component={InputText}
                controlLabel={t('EMPRESA.MODAL.CIF')}
                customClass='campo-modal-centro'
                maxLength={true}
                valorMaxLength={20}
              />
              <Field
                colSm={4}
                id="direccionCentro"
                name="direccionCentro"
                component={InputText}
                controlLabel={t('EMPRESA.MODAL.DIRECCION')}
                customClass='campo-modal-centro'
                maxLength={true}
                valorMaxLength={100}
              />
              <Field
                colSm={4}
                id="cpCentro"
                name="cpCentro"
                component={InputText}
                controlLabel={t('EMPRESA.MODAL.CP')}
                customClass='campo-modal-centro'
                maxLength={true}
                valorMaxLength={10}
              />
              <Field
                colSm={4}
                id="localidadCentro"
                name="localidadCentro"
                component={InputText}
                controlLabel={t('EMPRESA.MODAL.LOCALIDAD')}
                customClass='campo-modal-centro'
                maxLength={true}
                valorMaxLength={50}
              />
              <Field
                colSm={4}
                id="provincia"
                name="provincia"
                component={InputText}
                controlLabel={t('EMPRESA.MODAL.PROVINCIA')}
                customClass='campo-modal-centro'
                maxLength={true}
                valorMaxLength={50}
              />
              <Field
                colSm={4}
                id="paisCentro"
                name="paisCentro"
                component={InputText}
                controlLabel={t('EMPRESA.MODAL.PAIS')}
                customClass='campo-modal-centro'
                maxLength={true}
                valorMaxLength={50}
              />
              <Field
                colSm={4}
                id="telefonoCentro"
                name="telefonoCentro"
                component={InputText}
                controlLabel={t('EMPRESA.MODAL.TELEFONO')}
                customClass='campo-modal-centro'
                maxLength={true}
                valorMaxLength={50}
              />
              <Field
                colSm={4}
                id="faxCentro"
                name="faxCentro"
                component={InputText}
                controlLabel={t('EMPRESA.MODAL.FAX')}
                customClass='campo-modal-centro'
                maxLength={true}
                valorMaxLength={50}
              />
              <Field
                colSm={4}
                id="emailCentro"
                name="emailCentro"
                component={InputText}
                controlLabel={t('EMPRESA.MODAL.EMAIL')}
                customClass='campo-modal-centro'
                maxLength={true}
                valorMaxLength={100}
              />
              <Field
                colSm={4}
                id="webCentro"
                name="webCentro"
                component={InputText}
                controlLabel={t('EMPRESA.MODAL.WEB')}
                customClass='campo-modal-centro'
                maxLength={true}
                valorMaxLength={50}
              />
              <Field
                id="hidden"
                name="hidden"
                colSm={0}
                inline
                component={InputCheckBox}
                claseActivo="hidden"
              />
              <div className="clearfix"></div>
              <div className="tipo-centro">
                <p><b>{t('EMPRESA.MODAL.TIPO_CENTRO')}</b></p>
                <Field
                  id="produccionCentro"
                  name="produccionCentro"
                  colSm={4}
                  inline
                  component={InputCheckBox}
                  claseActivo="onoffswitch-label"
                  controlLabel={t('EMPRESA.MODAL.PRODUCCION')}
                />
                <Field
                  id="procesadoCentro"
                  name="procesadoCentro"
                  colSm={4}
                  inline
                  component={InputCheckBox}
                  claseActivo="onoffswitch-label"
                  controlLabel={t('EMPRESA.MODAL.PROCESADO')}
                />
                <Field
                  id="principalCentro"
                  name="principalCentro"
                  colSm={4}
                  inline
                  component={InputCheckBox}
                  claseActivo="onoffswitch-label"
                  controlLabel={t('EMPRESA.MODAL.PRINCIPAL')}
                />
                {
                  this.state.errorTipoCentro === true ? (
                    <span><Warning />{t('EMPRESA.MODAL.ERROR_DEBE_HABER_TIPO_CENTRO')}</span>
                  ) : null
                }
              </div>
              <div className="clearfix"></div>
              <Field
                id="activo"
                name="activo"
                colSm={8}
                inline
                component={InputCheckBox}
                claseActivo="onoffswitch-label"
                controlLabel={t('EMPRESA.MODAL.ACTIVO')}
              />
              <Col sm={4}>
               <div className="imagenEmpresa">
                <div className="content-dropzone">
                  <Dropzone
                    accept="image/jpeg, image/png"
                    onDrop={(accepted, rejected) => { this.onDrop({ accepted, rejected })}}
                    maxSize={2000000}
                    onDropRejected={this.onDropRejected}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()}  className="dropzone">
                        <input {...getInputProps()} />
                        <CloudUpload/>
                      </div>
                    )}
                  </Dropzone>
                  {
                    (this.state.accepted !== '' && this.state.accepted !== undefined) ? (
                      <div className="eliminar-imagen-empresa" onClick={this.eliminaImagen.bind(this)}>
                        <Delete/>
                      </div>
                    ) : null
                  }
                  <div className="imagen-perfil-empresa">
                  {
                    this.state.accepted !== '' && this.state.accepted !== undefined ? (
                      <img src={this.state.accepted} />
                    ) : (
                      <img src={urlRecursos + 'imagen-firma.jpg'} />
                    )
                  }
                  </div>
                  <p className={this.state.rejected === '' ? 'error-upload-img-oculto' : 'error-upload-img-visible'}>Imagen desamiado grande</p>
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {
          /*
            this.props.dataCentro && (
              <Button
                type="button"
                className="btn btn-white btn-sm"
                disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.EMPRESA_CENTROS] < 2) || submitting}
                onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.EMPRESA_CENTROS] > 1) && !submitting ? handleSubmit(this.duplicar.bind(this)) : undefined}
               >
                {t('EMPRESA.MODAL.DUPLICAR')}
              </Button>
            )
          */
          }
          {
          /*
            !this.props.dataCentro && (
              <ButtonLockable
                processing={submitting}
                hasIcon={true}
                showLoaderOnClick={true}
                type="button"
                className="btn btn-primary btn-sm"
                disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.EMPRESA_CENTROS] < 2) || submitting}
                onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.EMPRESA_CENTROS] > 1) && !submitting) ? handleSubmit(this.guardarYnuevoCentro.bind(this)) : () => {}}
              >
                {t('EMPRESA.MODAL.GUARDAR_Y_NUEVO')}
              </ButtonLockable>
            )
          */
          }
          <ButtonLockable
            processing={submitting}
            hasIcon={true}
            showLoaderOnClick={true}
            type="button"
            className="btn btn-primary btn-sm"
            disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.EMPRESA_CENTROS] < 2) || submitting}
            onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.EMPRESA_CENTROS] > 1) && !submitting) ? handleSubmit(this.submitNuevoCentro.bind(this)) : () => {}}
          >
            {t('EMPRESA.MODAL.GUARDAR')}
          </ButtonLockable>
          <Button
            type="button"
            className={"btn btn-white btn-sm" + (submitting ? ' disabled' : '')}
            onClick={() => !submitting && this.cerrarModalCentro()}
          >
            {t('EMPRESA.MODAL.CANCELAR')}
          </Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalCentro',
})(ModalCentro))