import actionTypes from '../../constants/actions/analisisExtracciones/camaraView'

export function comprobarCamara(){
  return {
    type: actionTypes.COMPROBAR_CAMARA
  }
}

export function comprobarCamaraSuccess(estado){
  return {
    type: actionTypes.COMPROBAR_CAMARA_SUCCESS,
    estado
  }
}

export function getImagenCamara(){
  return {
    type: actionTypes.GET_IMAGEN_CAMARA
  }
}

export function getImagenCamaraSuccess(base64Img){
  return {
    type: actionTypes.GET_IMAGEN_CAMARA_SUCCESS,
    base64Img
  }
}

export function getVideoCamara(){
  return {
    type: actionTypes.GET_VIDEO_CAMARA
  }
}

export function descargarImagen(){
  return {
    type: actionTypes.DESCARGAR_IMAGEN
  }
}

export function apagarCamara(){
  return {
    type: actionTypes.APAGAR_CAMARA
  }
}

export function apagarCamaraSuccess(){
  return {
    type: actionTypes.APAGAR_CAMARA_SUCCESS
  }
}

export default {
  comprobarCamara,
  comprobarCamaraSuccess,
  getImagenCamara,
  getImagenCamaraSuccess,
  getVideoCamara,
  descargarImagen,
  apagarCamara,
  apagarCamaraSuccess
}
