import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize} from 'redux-form'
import i18n from '../../../i18n'
import actionTypes from '../../../constants/actions/fichaVerraco/editarVerraco/editarVerraco'
import { fetchEditarVerracoSuccess, fetchHistoricoEstadosVerracoSuccess, fetchHistoricoGrupoVerracoSuccess, fetchHistoricoExtraccionesSuccess, fetchHistoricoIntervencionesSuccess, abrirModalHistoricoGruposSuccess, abrirModalHistoricoExtraccionesSuccess, abrirModalHistoricoEstadoSuccess, abrirModalHistoricoIntervencionesSuccess} from '../../../actions/fichaVerraco/editarVerraco/editarVerraco'
import { recuperarDatosModalAlimentacionSuccess } from '../../../actions/fichaVerraco/fichaVerraco' 
import getVerraco from '../../../services/fichaVerraco/editarVerraco/getVerraco'
import editVerraco from '../../../services/fichaVerraco/editarVerraco/editVerraco'
import getHistoricoEstadosVerraco from '../../../services/fichaVerraco/editarVerraco/getHistoricoEstadosVerraco'
import getHistoricoGrupoVerraco from '../../../services/fichaVerraco/editarVerraco/getHistoricoGrupoVerraco'
import {openMensajeAlerta} from '../../../actions/common'
import {date_formatter, dateTimeZones} from '../../../util/formatFunctions'
import getHistoricoExtracciones from '../../../services/fichaVerraco/editarVerraco/getHistoricoExtracciones'
import getHistoricoIntervenciones from '../../../services/fichaVerraco/editarVerraco/getHistoricoIntervenciones'
import getGrupoVerraco from '../../../services/grupoVerraco/getGrupoVerraco'
import getRegistroIntervencionSanitaria from '../../../services/planSanitario/registroIntervencionSanitaria/getRegistroIntervencionSanitaria'
import getCambioEstadoVerraco from '../../../services/cambioEstadoVerraco/getCambioEstadoVerraco'
import getAnalisisExtraccionService from '../../../services/analisisExtracciones/getAnalisisExtraccion'

export function * fetchEditarVerraco ({idVerraco, t}) {
  try {
    let auth = yield select(state => state.auth)
    const listVerraco = yield call(getVerraco, idVerraco.idVerraco, null)
    yield put(fetchEditarVerracoSuccess(listVerraco))
    /*let comboRaza = yield select(state => state.combos.comboRaza)
    comboRaza.map((raza) => {
      if (raza.value === listVerraco.verraco.idRaza){
        listVerraco.verraco.idRaza = raza
      }
      return listVerraco.verraco.idRaza
    })
    let comboLineaGenetica = yield select(state => state.combos.comboLineaGeneticaActiva)
    comboLineaGenetica.map((linea) => {
      if (linea.value === listVerraco.verraco.idLinea){
        listVerraco.verraco.idLinea = linea
      } else {
        listVerraco.verraco.idLinea = ''
      }
      return listVerraco.verraco.idLinea
    })*/
    listVerraco.verraco.idRaza = listVerraco.verraco.idRaza !== null ? {label: listVerraco.verraco.nombreRaza, value: listVerraco.verraco.idRaza} : null
    listVerraco.verraco.idLinea = listVerraco.verraco.idLinea !== null ? {label: listVerraco.verraco.nombreLinea, value: listVerraco.verraco.idLinea} : null
    if (listVerraco.verraco.propiedad === true){
      listVerraco.verraco.propiedad = {value: true, label: 'Propio' }
    } else if(listVerraco.verraco.propiedad === false) {
        listVerraco.verraco.propiedad = {value: false, label: 'Alquilado'}
    }
    let comboOperario = yield select(state => state.combos.comboOperarioActivo)
    comboOperario.map((operario) => {
      if (operario.value === listVerraco.verraco.idOperarioColecta){
        listVerraco.verraco.idOperarioColecta = operario
      }
      return listVerraco.verraco.idOperarioColecta
    })
    let comboTipoDosis = yield select(state => state.combos.comboTipoDosis)
    comboTipoDosis.map((tipoDosis) => {
      if (tipoDosis.value === listVerraco.verraco.idTipoDosis){
        listVerraco.verraco.idTipoDosis = tipoDosis
      }
      return listVerraco.verraco.idTipoDosis
    })
    let comboSalaExtraccion = yield select(state => state.combos.comboSalaExtraccionActivo)
    comboSalaExtraccion.map((salaExtraccion) => {
      if (salaExtraccion.value === listVerraco.verraco.idSalaExtraccion){
        listVerraco.verraco.idSalaExtraccion = salaExtraccion
      }
      return listVerraco.verraco.idSalaExtraccion
    })
    let comboTiempoMonta = yield select(state => state.combos.comboTiempoMonta)
    comboTiempoMonta.map((monta) => {
      if (monta.value === listVerraco.verraco.tiempoMonta){
        listVerraco.verraco.tiempoMonta = {value: monta.value, label: i18n.t(monta.label)}
      }
      return listVerraco.verraco.tiempoMonta
    })
    let comboConservacion = yield select(state => state.combos.comboConservacion)
    comboConservacion.map((con) => {
      if (con.value === listVerraco.verraco.conservacion){
        listVerraco.verraco.conservacion = con
      }
      return listVerraco.verraco.conservacion
    })
    let comboCliente = yield select(state => state.combos.comboCliente)
    comboCliente.map((cliente) => {
      if (cliente.value === listVerraco.verraco.idCliente){
        listVerraco.verraco.idCliente = cliente
      }
      return listVerraco.verraco.idCliente
    })
    if (listVerraco.verraco.idDireccionCliente !== null ) {
      listVerraco.verraco.idDireccionCliente = {value: listVerraco.verraco.idDireccionCliente, label: listVerraco.verraco.nombredireccioncliente}
    }
    if (listVerraco.verraco.genHalotano === 'NN'){
      listVerraco.verraco.genHalotano = {value: 'NN', label: 'NN' }
    } else if(listVerraco.verraco.genHalotano === 'NP') {
        listVerraco.verraco.genHalotano = {value: 'NP', label: 'NP'}
    } else if(listVerraco.verraco.genHalotano === 'PP') {
        listVerraco.verraco.genHalotano = {value: 'PP', label: 'PP'}
    }

    const alimentacionState = yield select(state => state.fichaVerraco.alimentacionAnadidas)
    const alimentacion = alimentacionState.filter((datos) => {
      datos.tipoAlimentacion = {value: datos.idTipoAlimentacion, label: datos.nombreTipoAlimentacion}
      datos.operario = {value: datos.idOperario, label: datos.nombreOperario}
      return datos
    })
    yield put(recuperarDatosModalAlimentacionSuccess(alimentacion))

    if (listVerraco.verraco.fechaFinIntervencionSanitaria !== null) {
      let fechaHoy = new Date()
      let fechaIntervencion = new Date(listVerraco.verraco.fechaFinIntervencionSanitaria)
      if (fechaIntervencion.getTime() >= fechaHoy.getTime()) {
        listVerraco.verraco.noMandarMatadero = true
      }
    }

    yield put(initialize('editarVerraco', listVerraco.verraco))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchFetchEditarVerraco () {
  yield takeLatest(actionTypes.FETCH_EDITAR_VERRACO, fetchEditarVerraco)
}

export function * submitEditarVerraco ({values, history, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const editarVerraco = yield call(editVerraco, values, auth.token)
    yield put(initialize('nuevoVerraco'))
    if (editarVerraco.actualizado === true) {
        yield put(openMensajeAlerta('modificacion'))
        if (onSuccessCallback) yield onSuccessCallback()
      } else {
        yield put(openMensajeAlerta('no-modificacion'))
        if (onErrorCallback) yield onErrorCallback()
      }
    history.push({pathname: '/FichaVerraco', state: { from: history.location.pathname }})
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchSubmitEditVerraco () {
  yield takeLatest(actionTypes.SUBMIT_EDITAR_VERRACO, submitEditarVerraco)
}

export function * fetchHistoricoEstadosVerraco ({idVerraco}) {
  try {
    const listHistoricoEstadosVerraco = yield call(getHistoricoEstadosVerraco, idVerraco.idVerraco, null)
    yield put(fetchHistoricoEstadosVerracoSuccess(listHistoricoEstadosVerraco))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchFetchHistoricoEstadosVerraco () {
  yield takeLatest(actionTypes.FETCH_HISTORICO_ESTADOS_VERRACO, fetchHistoricoEstadosVerraco)
}

export function * fetchHistoricoGrupoVerraco ({idVerraco}) {
  try {
    const listHistoricoGrupoVerraco = yield call(getHistoricoGrupoVerraco, idVerraco.idVerraco, null)
    yield put(fetchHistoricoGrupoVerracoSuccess(listHistoricoGrupoVerraco))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchFetchHistoricoGrupoVerraco () {
  yield takeLatest(actionTypes.FETCH_HISTORICO_GRUPO_VERRACO, fetchHistoricoGrupoVerraco)
}



export function * fetchHistoricoExtracciones ({idVerraco}) {
  try {
    const listHistoricoExtracciones= yield call(getHistoricoExtracciones, idVerraco.idVerraco, null)
    yield put(fetchHistoricoExtraccionesSuccess(listHistoricoExtracciones))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchFetchHistoricoExtracciones () {
  yield takeLatest(actionTypes.FETCH_HISTORICO_EXTRACCIONES, fetchHistoricoExtracciones)
}

export function * fetchHistoricoIntervenciones ({idVerraco}) {
  try {
    const listHistoricoIntervenciones = yield call(getHistoricoIntervenciones, idVerraco.idVerraco, null)
    yield put(fetchHistoricoIntervencionesSuccess(listHistoricoIntervenciones))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchFetchHistoricoIntervenciones () {
  yield takeLatest(actionTypes.FETCH_HISTORICO_INTERVENCIONES, fetchHistoricoIntervenciones)
}

export function * abrirModalHistoricoGrupos ({idGrupoVerraco}) {
  try {
    const datosHistoricoGrupoVerraco = yield call(getGrupoVerraco, idGrupoVerraco, null)
    yield put(abrirModalHistoricoGruposSuccess(datosHistoricoGrupoVerraco))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchAbrirModalHistoricoGrupos () {
  yield takeLatest(actionTypes.ABRIR_MODAL_HISTORICO_GRUPOS, abrirModalHistoricoGrupos)
}

export function * abrirModalHistoricoExtracciones ({idAnalisis}) {
  try {
    const listHistoricoExtracciones = yield select(state => state.fichaVerraco.listHistoricoExtracciones)
    // const datosHistoricoExtracciones = yield call(getAnalisisExtraccionService, idAnalisis, null)
    const datosHistoricoExtracciones = listHistoricoExtracciones.filter((extracciones) => {
      if (extracciones.idAnalisis === idAnalisis) {
        return extracciones
      }
    })
    yield put(abrirModalHistoricoExtraccionesSuccess(datosHistoricoExtracciones))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchAbrirModalHistoricoExtracciones () {
  yield takeLatest(actionTypes.ABRIR_MODAL_HISTORICO_EXTRACCIONES, abrirModalHistoricoExtracciones)
}

export function * abrirModalHistoricoEstado ({idEstado}) {
  try {
    const datosHistoricoEstado = yield call(getCambioEstadoVerraco, idEstado, null)
    let estadoanterior = yield select(state => state.combos.comboEstadoVerraco)
    if (datosHistoricoEstado.estadoanterior !== null) {
      estadoanterior = estadoanterior.filter((estadoVerraco) => {
        if (estadoVerraco.value === datosHistoricoEstado.estadoanterior){
          return estadoVerraco
        }
      })
    datosHistoricoEstado.estadoActual = estadoanterior[0].label
    }
    let estanoNuevo = yield select(state => state.combos.comboEstadoVerraco)
    estanoNuevo = estanoNuevo.filter((estadoVerraco) => {
      if (estadoVerraco.value === datosHistoricoEstado.estadoactual){
        return estadoVerraco
      }
    })
    datosHistoricoEstado.nuevoEstado = estanoNuevo[0].label
    yield put(abrirModalHistoricoEstadoSuccess(datosHistoricoEstado))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchAbrirModalHistoricoEstado () {
  yield takeLatest(actionTypes.ABRIR_MODAL_HISTORICO_ESTADO, abrirModalHistoricoEstado)
}

export function * abrirModalHistoricoIntervenciones ({idIntervencion}) {
  try {
    const datosHistoricoIntervenciones = yield call(getRegistroIntervencionSanitaria, idIntervencion, null)
    yield put(abrirModalHistoricoIntervencionesSuccess(datosHistoricoIntervenciones))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchAbrirModalHistoricoIntervenciones () {
  yield takeLatest(actionTypes.ABRIR_MODAL_HISTORICO_INTERVENCIONES, abrirModalHistoricoIntervenciones)
}
