import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { getFormValues } from 'redux-form'
import ModalLicencias from '../../../components/admin/licencias/ModalLicencias'
import { comprobarEntorno } from '../../../actions/auth/auth'
import { abrirModalLicencias, cerrarModalLicencias, submitLicencia } from '../../../actions/admin/licencias/licencias'

export function mapStateToProps (state) {
  return {
    ...state.licencias,
    auth: state.auth,
    combos: {comboModulos: state.combos.comboModulos}
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      abrirModalLicencias,
      cerrarModalLicencias,
      submitLicencia,
      comprobarEntorno
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalLicencias))