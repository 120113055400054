import actionTypes from '../../../constants/actions/planSanitario/tipoIntervencionSanitaria/tipoIntervencionSanitaria'

export function fetchTipoIntervencionSanitaria () {
  return {
    type: actionTypes.FETCH_TIPO_INTERVENCION_SANITARIA
  }
}

export function fetchTipoIntervencionSanitariaSuccess (list) {
  return {
    type: actionTypes.FETCH_TIPO_INTERVENCION_SANITARIA_SUCCESS,
    list
  }
}

export function abrirModalVacioTipoIntervencionSanitaria () {
  return {
    type: actionTypes.ABRIR_MODAL_VACIO_TIPO_INTERVENCION_SANITARIA
  }
}

export function seleccionarTodosTipoIntervencionSanitaria(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_TIPO_INTERVENCION_SANITARIA
  }
}

export function deSeleccionarTodosTipoIntervencionSanitaria(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_TIPO_INTERVENCION_SANITARIA
  }
}

export function submitNuevoTipoIntervencionSanitaria(values, onSuccessCallback, onErrorCallback){
  return{
    type: actionTypes.SUBMIT_NUEVO_TIPO_INTERVENCION_SANITARIA,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function recuperarDatosModalTipoIntervencionSanitaria (idTipoIntervencionSanitaria) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_TIPO_INTERVENCION_SANITARIA,
    idTipoIntervencionSanitaria
  }
}

export function recuperarDatosModalTipoIntervencionSanitariaSuccess (data) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_TIPO_INTERVENCION_SANITARIA_SUCCESS,
    data
  }
}

export function editarTipoIntervencionSanitaria (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.EDITAR_TIPO_INTERVENCION_SANITARIA,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function duplicarDatosModalTipoIntervencionSanitaria (idTipoIntervencionSanitaria, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_TIPO_INTERVENCION_SANITARIA,
    idTipoIntervencionSanitaria,
    onSuccessCallback,
    onErrorCallback
  }
}

export function duplicarDatosModalTipoIntervencionSanitariaSuccess (dataDuplicar) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_TIPO_INTERVENCION_SANITARIA_SUCCESS,
    dataDuplicar
  }
}

export function cambiarEstadoTipoIntervencionSanitaria (idTipoIntervencionSanitaria) {
  return {
    type: actionTypes.CAMBIAR_ESTADO_TIPO_INTERVENCION_SANITARIA,
    idTipoIntervencionSanitaria
  }
}

export function onDeleteTipoIntervencionSanitaria (idTipoIntervencionSanitaria) {
  return {
    type: actionTypes.ON_DELETE_TIPO_INTERVENCION_SANITARIA,
    idTipoIntervencionSanitaria
  }
}

export function cambiarEstadoSeleccionTipoIntervencionSanitaria(list){
  return {
    type: actionTypes.CAMBIAR_ESTADO_SELECCION_TIPO_INTERVENCION_SANITARIA,
    list
  }
}

export function deleteSeleccionTipoIntervencionSanitaria(list){
  return {
    type: actionTypes.DELETE_SELECCION_TIPO_INTERVENCION_SANITARIA,
    list
  }
}

export function guardarYnuevoTipoIntervencionSanitaria (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.GUARDAR_Y_NUEVO_TIPO_INTERVENCION_SANITARIA,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function crearCsvTipoIntervencion(list){
  return {
    type: actionTypes.CREATE_CSV_TIPO_INTERVENCION,
    list
  }
}

export function crearCsvTipoIntervencionSuccess(datosExcelTipoIntervencion){
  return {
    type: actionTypes.CREATE_CSV_TIPO_INTERVENCION_SUCCESS,
    datosExcelTipoIntervencion
  }
}
export function imprimirPdfTipoIntervencion(list){
  return {
    type: actionTypes.IMPRIMIR_PDF_TIPO_INTERVENCION,
    list
  }
}

export function imprimirPdfTipoIntervencionSuccess(datosPdfTipoIntervencion){
  return {
    type: actionTypes.IMPRIMIR_PDF_TIPO_INTERVENCION_SUCCESS,
    datosPdfTipoIntervencion
  }
}

export default {
  fetchTipoIntervencionSanitaria,
  fetchTipoIntervencionSanitariaSuccess,
  abrirModalVacioTipoIntervencionSanitaria,
  seleccionarTodosTipoIntervencionSanitaria,
  deSeleccionarTodosTipoIntervencionSanitaria,
  submitNuevoTipoIntervencionSanitaria,
  recuperarDatosModalTipoIntervencionSanitaria,
  recuperarDatosModalTipoIntervencionSanitariaSuccess,
  editarTipoIntervencionSanitaria,
  duplicarDatosModalTipoIntervencionSanitaria,
  duplicarDatosModalTipoIntervencionSanitariaSuccess,
  cambiarEstadoTipoIntervencionSanitaria,
  onDeleteTipoIntervencionSanitaria,
  cambiarEstadoSeleccionTipoIntervencionSanitaria,
  deleteSeleccionTipoIntervencionSanitaria,
  guardarYnuevoTipoIntervencionSanitaria,
  crearCsvTipoIntervencion,
  crearCsvTipoIntervencionSuccess,
  imprimirPdfTipoIntervencion,
  imprimirPdfTipoIntervencionSuccess
}
