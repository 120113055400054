import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { getFormValues } from 'redux-form'
import ModalEntradaMercanciaAnadirProducto from '../../components/entradaMercancia/ModalEntradaMercanciaAnadirProducto'
import {comboProductoEscandallo} from '../../actions/combos/combos'
import {
  cerrarModalEntradaMercanciaAnadirProducto, anadirProductoEntradaMercancia, editarEntradaMercanciaProducto,
  guardarYnuevoEntradaMercanciaProducto, fetchLoteExistente, fetchLoteExistenteSuccess
} from '../../actions/entradaMercancia/entradaMercancia'

export function mapStateToProps (state) {
  return {
    ...state.entradaMercancia,
    combos: state.combos,
    formSimpleTable: state.form.simpleTable,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    auth: state.auth,
    ModalEntradaMercanciaAnadirProducto: getFormValues('ModalEntradaMercanciaAnadirProducto')(state)
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      cerrarModalEntradaMercanciaAnadirProducto,
      comboProductoEscandallo,
      anadirProductoEntradaMercancia,
      editarEntradaMercanciaProducto,
      guardarYnuevoEntradaMercanciaProducto,
      fetchLoteExistente,
      fetchLoteExistenteSuccess
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalEntradaMercanciaAnadirProducto))