import actionTypes from '../../../constants/actions/planSanitario/visita/visita'

export function fetchVisita () {
  return {
    type: actionTypes.FETCH_VISITA
  }
}

export function fetchVisitaSuccess (list) {
  return {
    type: actionTypes.FETCH_VISITA_SUCCESS,
    list
  }
}

export function abrirModalVacioVisita () {
  return {
    type: actionTypes.ABRIR_MODAL_VACIO_VISITA
  }
}

export function seleccionarTodosVisita(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_VISITA
  }
}

export function deSeleccionarTodosVisita(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_VISITA
  }
}

export function submitNuevoVisita(values, onSuccessCallback, onErrorCallback) {
  return{
    type: actionTypes.SUBMIT_NUEVO_VISITA,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function submitNuevoVisitaSuccess(ultimaVisita){
  return{
    type: actionTypes.SUBMIT_NUEVO_VISITA_SUCCESS,
    ultimaVisita
  }
}

export function recuperarDatosModalVisita (idVisita) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_VISITA,
    idVisita
  }
}

export function recuperarDatosModalVisitaSuccess (data) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_VISITA_SUCCESS,
    data
  }
}

export function editarVisita (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.EDITAR_VISITA,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function duplicarDatosModalVisita (idVisita) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_VISITA,
    idVisita
  }
}

export function duplicarDatosModalVisitaSuccess (dataDuplicar) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_VISITA_SUCCESS,
    dataDuplicar
  }
}

export function cambiarEstadoVisita (idVisita) {
  return {
    type: actionTypes.CAMBIAR_ESTADO_VISITA,
    idVisita
  }
}

export function onDeleteVisita (idVisita, contacto, empresa) {
  return {
    type: actionTypes.ON_DELETE_VISITA,
    idVisita,
    contacto,
    empresa
  }
}

export function cambiarEstadoSeleccionVisita(list){
  return {
    type: actionTypes.CAMBIAR_ESTADO_SELECCION_VISITA,
    list
  }
}

export function deleteSeleccionVisita(list){
  return {
    type: actionTypes.DELETE_SELECCION_VISITA,
    list
  }
}

export function guardarYnuevoVisita (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.GUARDAR_Y_NUEVO_VISITA,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function fetchFiltrarVisita (values) {
  return {
    type: actionTypes.FETCH_FILTRAR_VISITA,
    values
  }
}

export function fetchFiltrarVisitaSuccess (filtros) {
  return {
    type: actionTypes.FETCH_FILTRAR_VISITA_SUCCESS,
    filtros
  }
}

export function imprimirInformeVisita (values, idVisita, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.IMPRIMIR_INFORME_VISITA,
    values,
    idVisita,
    onSuccessCallback,
    onErrorCallback
  }
}

export function imprimirInformeVisitaSuccess (ultimaVisita) {
  return {
    type: actionTypes.IMPRIMIR_INFORME_VISITA_SUCCESS,
    ultimaVisita
  }
}

export function crearCsvVisita(list){
  return {
    type: actionTypes.CREATE_CSV_VISITA,
    list
  }
}

export function crearCsvVisitaSuccess(datosExcelVisita){
  return {
    type: actionTypes.CREATE_CSV_VISITA_SUCCESS,
    datosExcelVisita
  }
}
export function imprimirPdfVisita(list){
  return {
    type: actionTypes.IMPRIMIR_PDF_VISITA,
    list
  }
}

export function imprimirPdfVisitaSuccess(datosPdfVisita){
  return {
    type: actionTypes.IMPRIMIR_PDF_VISITA_SUCCESS,
    datosPdfVisita
  }
}

export function restartVisitas(){
  return {
    type: actionTypes.RESTART_VISITAS
  }
}

export function cerrarModalVisitas(){
  return {
    type: actionTypes.CERRAR_MODAL_VISITAS
  }
}

export function abrirModalVisitas(){
  return {
    type: actionTypes.ABRIR_MODAL_VISITAS
  }
}

export default {
  fetchVisita,
  fetchVisitaSuccess,
  abrirModalVacioVisita,
  seleccionarTodosVisita,
  deSeleccionarTodosVisita,
  submitNuevoVisita,
  recuperarDatosModalVisita,
  recuperarDatosModalVisitaSuccess,
  editarVisita,
  duplicarDatosModalVisita,
  duplicarDatosModalVisitaSuccess,
  cambiarEstadoVisita,
  onDeleteVisita,
  cambiarEstadoSeleccionVisita,
  deleteSeleccionVisita,
  guardarYnuevoVisita,
  fetchFiltrarVisita,
  fetchFiltrarVisitaSuccess,
  submitNuevoVisitaSuccess,
  imprimirInformeVisita,
  imprimirInformeVisitaSuccess,
  crearCsvVisita,
  crearCsvVisitaSuccess,
  imprimirPdfVisita,
  imprimirPdfVisitaSuccess,
  restartVisitas,
  cerrarModalVisitas,
  abrirModalVisitas
}
