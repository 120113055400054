import React, { Component } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import './Usuarios.scss'
import ListadoPage from '../../containers/page/ListadoPage'
import Button from '../comun/button/Button'
import ButtonLockable from '../comun/button/ButtonLockable'
import InputPassword from '../comun/form/InputPassword'
import { DoneAll, Warning } from '@material-ui/icons'
import { required } from '../../util/validationFunctions'

class ModalCambiarPass extends Component {
  constructor () {
    super()
    this.state = {
      validarAnadirGrupo: false,
      dataCambiarPass: false
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.dataCambiarPass !== prevProps.dataCambiarPass) {
      if (this.props.dataCambiarPass === false) {
        this.setState({dataCambiarPass: true})
      } else {
        this.setState({dataCambiarPass: false})
      }
    }
  }

  cerrarModalCambiarPass () {
    this.props.actions.cerrarModalCambiarPass()
    this.props.initialize()
  }

  anadirCambiarPass (values) {
    return new Promise((resolve, reject) => {
      values.idUsuario = this.props.idUsuario
      this.props.actions.cambiarPasswordUsuario(values, resolve, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  render () {
    const {
      t, handleSubmit, submitting, showModalCambiarPass, idUsuario,
      actions: { cerrarModalCambiarPass }
    } = this.props

    return (
      <Modal show={showModalCambiarPass} onHide={() => this.cerrarModalCambiarPass()} aria-labelledby="contained-modal-title-lg" size="lg" className="modal-cambiar-pass" backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            {t('USUARIOS.MODAL.CAMBIAR_PASS')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {
              this.state.dataCambiarPass === true ? (
                <Col sm={12}><div role="alert" className="alert alert-danger">{t('LOGIN.FORM.CREDENCIALES_INCORRECTOS')}</div></Col>
              ) : null
            }
            <form autoComplete="off">
              <Field
                colSm={12}
                id="oldPassword"
                name="oldPassword"
                controlLabel={t('USUARIOS.MODAL.PASS_VIEJA') + '*'}
                component={InputPassword}
                validate={required}
              />
              <Field
                colSm={12}
                id="passwordUsuario"
                name="passwordUsuario"
                controlLabel={t('USUARIOS.MODAL.PASS_NUEVA') + '*'}
                component={InputPassword}
                validate={required}
              />
            </form>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <ButtonLockable
            processing={submitting}
            hasIcon={true}
            showLoaderOnClick={true}
            type="button"
            className="btn btn-primary btn-sm"
            disabled={submitting}
            onClick={!submitting ? handleSubmit(this.anadirCambiarPass.bind(this)) : () => {}}
          >
          {t('USUARIOS.MODAL.GUARDAR')}
          </ButtonLockable>
          <Button
            type="button"
            className={"btn btn-white btn-sm" + (submitting ? ' disabled' : '')}
            onClick={() => !submitting && this.cerrarModalCambiarPass()}
          >
          {t('USUARIOS.MODAL.CANCELAR')}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalCambiarPass',
  enableReinitialize: true
})(ModalCambiarPass))