import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import ListadoPage from '../../containers/page/ListadoPage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import './informes.scss'
import FiltrosInformesStockDosisPage from '../../containers/informes/FiltrosInformesStockDosisPage'
import {number_formatter} from '../../util/formatFunctions'

class InformesStockDosis extends Component {

  UNSAFE_componentWillMount () {
    // this.props.actions.obtenerInformeStockDosis({})
  }

  componentDidMount () {
    document.title = this.props.t('MENU.INFORMES.INFORMES') + ' - ' + this.props.t('MENU.INFORMES.STOCK_DOSIS') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
  }

  render () {
    const {
      t, numeroRegistrosPorPagina, paginaActual, listInformeStockDosis, totalesStockDosis,
      auth: {separadorDec, separadorMil}
    } = this.props

    const tableInformeStockDosis = {
      id: 'informeStocDosisTable',
      multiHeader: false,
      hasCheckbox: false,
      hasOptions: false,
      mostrarModificar: false,
      columns: [
        {id: 'fechaEnvasado', name: t('INFORMES.STOCK_DOSIS.TABLA.FECHA'), type: 'fecha'},
        {id: 'nombreLinea', name: t('INFORMES.STOCK_DOSIS.TABLA.NOMBRE_LINEA')},
        {id: 'nombreRaza', name: t('INFORMES.STOCK_DOSIS.TABLA.NOMBRE_RAZA')},
        {id: 'nombreTipoDosis', name: t('INFORMES.STOCK_DOSIS.TABLA.TIPO_DOSIS')},
        {id: 'stock', name: t('INFORMES.STOCK_DOSIS.TABLA.STOCK'), type: 'numero'},
        {id: 'verracoPool', name: t('INFORMES.STOCK_DOSIS.TABLA.VERRACO_POLL')},
        {id: 'numeroDosisDisponibles', name: t('INFORMES.STOCK_DOSIS.TABLA.DOSIS_DISPONIBLES'), type: 'numero'},
        {id: 'numeroDosisReservadas', name: t('INFORMES.STOCK_DOSIS.TABLA.DOSIS_RESERVADAS'), type: 'numero'},
      ],
      rows: listInformeStockDosis,
      filtros: <FiltrosInformesStockDosisPage />,
      leyenda: [
        {name: t('INFORMES.STOCK_DOSIS.LEYENDA.VERRACO'), color: '#FF6D29'},
        {name: t('INFORMES.STOCK_DOSIS.LEYENDA.POOL'), color: 'rgb(37, 56, 170)'}
      ],
      initialValues: {},
      colorearRow: (row) => {
        let classRow = ''
        if (row.tipo === true){
          classRow = 'stock-dosis-verraco'
        } else if (row.tipo === false) {
          classRow = 'stock-dosis-pool'
        }
        return classRow
      }
    }
    return (
      <div>
        <h2 className="page-title">{t('INFORMES.STOCK_DOSIS.TITLE')}</h2>
        <SimpleTablePage {...tableInformeStockDosis}>
        </SimpleTablePage>
          <div className="totales-informes" style={{width: '30%'}}>
            <Col sm={6} className="titulo-totales-informes"><span>{t('INFORMES.STOCK_DOSIS.TOTALES.NUM_RAZAS')}</span></Col>
            <Col sm={6} className="titulo-totales-informes" style={{border: 'none'}}><span>{t('INFORMES.STOCK_DOSIS.TOTALES.TOTAL_DOSIS')}</span></Col>
            <div className="clearfix"></div>
            <Col sm={6} className="valores-totales-informes"><span>{totalesStockDosis.totalRazas ? number_formatter(totalesStockDosis.totalRazas, 0, separadorDec, separadorMil) : 0}</span></Col>
            <Col sm={6} className="valores-totales-informes" style={{border: 'none'}}><span>{totalesStockDosis.totalDosis ? number_formatter(totalesStockDosis.totalDosis, 0, separadorDec, separadorMil) : 0}</span></Col>
          </div>
      </div>
    )
  }
}

export default InformesStockDosis