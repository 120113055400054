import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import setting from '../../setting'
import {comprobarCamara, apagarCamara, descargarImagen} from '../../actions/analisisExtracciones/camaraView'
import CamaraView from '../../components/analisisExtracciones/CamaraView'

export function mapStateToProps (state) {
  return {
    ...state.camaraView,
    urlCamara: setting.camara.url
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      comprobarCamara,
      apagarCamara,
      descargarImagen
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CamaraView))