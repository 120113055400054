import React, { Component } from 'react'
import { Modal, Row } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { required } from '../../util/validationFunctions'
import Button from '../comun/button/Button'
import ButtonLockable from '../comun/button/ButtonLockable'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputSelect from '../comun/form/InputSelect'
import './CopiaSeguridad.scss'

class ModalCopiaSeguridad extends Component {
  constructor(props) {
    super(props);
    this.submitNuevaCopiaSeguridad = this.submitNuevaCopiaSeguridad.bind(this)
    this.cerrarModalCopiaSeguridad = this.cerrarModalCopiaSeguridad.bind(this)
  }

  componentDidMount () {
    this.props.actions.comboObtenerCentroUsuario()
  }

  submitNuevaCopiaSeguridad (values) {
    return new Promise((resolve, reject) => {
      this.props.actions.submitNuevaCopiaSeguridad(values, resolve, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  cerrarModalCopiaSeguridad () {
    this.props.actions.cerrarModalCopiaSeguridad()
    this.props.initialize()
  }

  render () {
    const {
      t, handleSubmit, submitting, showModalCopiaSeguridad,
      combos: {comboObtenerCentroUsuario}
      } = this.props

    return (
      <Modal show={showModalCopiaSeguridad} onHide={() => this.cerrarModalCopiaSeguridad()} aria-labelledby="contained-modal-title-lg" backdrop="static" className="form-copia-seguridad">
      <form>
        <Modal.Header closeButton={!submitting}>
          <Modal.Title>
          {t('COPIA_SEGURIDAD.MODAL.TITULO_NUEVO')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Field
              id="list"
              name="list"
              component={InputSelect}
              colSm={12}
              options={comboObtenerCentroUsuario}
              multi={true}
              valueKey="value"
              labelKey="label"
              controlLabel={t('COPIA_SEGURIDAD.MODAL.CENTRO') + '*'}
              customClass="centro"
              validate={required}
              isClearable={false}
            />
            <div className="clearfix"/>
            <Field
              id="incluirArchivos"
              name="incluirArchivos"
              colSm={12}
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('COPIA_SEGURIDAD.INCLUYE_ARCHIVOS')}
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <ButtonLockable
            processing={submitting}
            hasIcon={true}
            showLoaderOnClick={true}
            type="button"
            className="btn btn-primary btn-sm"
            disabled={submitting}
            onClick={!submitting ? handleSubmit(this.submitNuevaCopiaSeguridad) : () => {}}
          >
            {t('COPIA_SEGURIDAD.MODAL.GUARDAR')}
          </ButtonLockable>
          <Button
            type="button"
            className={"btn btn-white btn-sm" + (submitting ? ' disabled' : '')}
            onClick={() => !submitting && this.cerrarModalCopiaSeguridad()}
          >
            {t('COPIA_SEGURIDAD.MODAL.CANCELAR')}
          </Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalCopiaSeguridad',
})(ModalCopiaSeguridad))