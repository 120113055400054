import React, { Component } from 'react'
import ListadoPage from '../../containers/page/ListadoPage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import FiltrosSeguimientoPage from '../../containers/seguimiento/FiltrosSeguimientoPage'
import ModalNuevoMagavisionPage from '../../containers/analisisExtracciones/ModalNuevoMagavisionPage'
import ModalListadoSeguimientoPage from '../../containers/seguimiento/ModalListadoSeguimientoPage'
import ModalFormSeguimientoPage from '../../containers/seguimiento/ModalFormSeguimientoPage'
import ModalContajeMaquinasPage from '../../containers/analisisExtracciones/ModalContajeMaquinasPage'
import { date_parser, dateTimeZones } from '../../util/formatFunctions'
import {permisos as permisosConstant} from '../../constants/permisos'

class Seguimiento extends Component {
  componentDidMount () {
    document.title = this.props.t('MENU.EXTRACCIONES.SEGUIMIENTO') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    let fechaActual = date_parser(dateTimeZones(this.props.auth.zonaHorariaString), this.props.auth.formaFechaHora)
    this.props.actions.fetchSeguimientos({fechaDesde: fechaActual, 'seleccionar-todo': false, tipo: {value: 1, label: this.props.t('COMUN.COMBOS.VERRACO_POOL.VERRACO')}})
    this.props.actions.fetchPanelControl()
  }

  render () {
    const {
      mainList = [], checkedFiltrosSeguimiento, t, numeroRegistrosPorPagina, paginaActual, idPool,
      actions: {openModalFormSeguimiento, openModalListadoSeguimiento, deleteSeguimiento}
    } = this.props

    const tKey = 'SEGUIMIENTO.'
    const idElemento = 'idSeguimiento'
    const idForm = 'seguimientoTable'

    const tableSeguimiento = {
      id: 'seguimientoTable',
      form: idForm,
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.SEGUIMIENTO] > 1),
      idElemento,
      hasOptions: true,
      mostrarModificar: true,
      onEdit: (row) => openModalFormSeguimiento(row.idSeguimiento, false, !!row.idPool),
      mostrarDuplicar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.SEGUIMIENTO] > 1),
      duplicar: (row) => openModalFormSeguimiento(row.idSeguimiento, true, !!row.idPool),
      verDetalle: (row) => openModalListadoSeguimiento(row.idAnalisis, row.idPool),
      mostrarEliminar: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.SEGUIMIENTO] > 1),
      onDelete: (row) => deleteSeguimiento(row),
      vigilado: false,
      mostrarCambiarEyaculadoEliminado: false,
      mostrarCambioEstado: false,
      columns: [
        {id: 'codigo', name: t(tKey + 'COLUMNS.CODIGO'), type: 'numero', numDecimales: 0},
        {id: 'nombreVerracoPool', name: t(tKey + 'COLUMNS.VERRACO_POOL')},
        {id: 'indexVerraco', name: t(tKey + 'COLUMNS.INDEX')},
        {id: 'fechaExtraccion', name: t(tKey + 'COLUMNS.FECHA_EXTRACCION'), type: 'fecha'},
        {id: 'fecha', name: t(tKey + 'COLUMNS.FECHA_SEGUIMIENTO'), type: 'fecha'},
        {id: 'nombreRaza', name: t(tKey + 'COLUMNS.RAZA')},
        {id: 'nombreLinea', name: t(tKey + 'COLUMNS.LINEA_GENETICA')},
        {id: 'tipoControl', name: t(tKey + 'COLUMNS.TIPO_CONTROL')}
      ],
      rows: mainList,
      leyenda: [
        {name: t("ANALISIS_EXTRACCIONES.LEYENDA.PERTENECE_POOL"), color: '#2538aa'}
      ],
      colorearRow: (row) => {
        let classRow = ''
        if (row.idPool){
          classRow = 'analisis-pertenece-pool'
        }
        return classRow
      },
      filtros: <FiltrosSeguimientoPage idElemento={idElemento} idForm={idForm} />,
      initialValues: {}
    }
    tableSeguimiento.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableSeguimiento.initialValues['check' + idForm + row[idElemento]] = checkedFiltrosSeguimiento
        }
      }
    )
    return (
      <div>
        <ModalNuevoMagavisionPage isPool={!!idPool} isSeguimiento={true} />
        <ModalListadoSeguimientoPage isPool={!!idPool} renderForm={false} />
        <ModalFormSeguimientoPage isPool={!!idPool} />
        <ModalContajeMaquinasPage />
        <SimpleModalPage/>
        <MensajeAlertaPage/>
        <ListadoPage t_key={tKey} table={tableSeguimiento}>
        </ListadoPage>
      </div>
    )
  }
}

export default Seguimiento