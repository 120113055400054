import React, { Component } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import {reduxForm, Field, setSubmitFailed} from 'redux-form'
import './ModalGrupoVerraco.scss'
import {date_formatter} from '../../util/formatFunctions'
import FormSubtitle from '../comun/form/FormSubtitle'
import { Delete, AddCircleOutline, Warning } from '@material-ui/icons'
import { required } from '../../util/validationFunctions'
import Button from '../comun/button/Button'
import ButtonChangeRecord from '../comun/button/ButtonChangeRecord'
import ButtonLockable from '../comun/button/ButtonLockable'
import InputText from '../comun/form/InputText'
import InputTextArea from '../comun/form/InputTextArea'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputDatePicker from '../comun/form/InputDatePicker'
import ListadoPage from '../../containers/page/ListadoPage'
import ModalVerracoGrupoVerracoPage from '../../containers/grupoVerraco/ModalVerracoGrupoVerracoPage'
import {estadoVerraco as estadoVerracoConstants} from '../../constants/estadoVerraco'
import {estadoVerraco as estadoVerracoStilyzer} from '../comun/table/Stilyzers'
import {permisos as permisosConstant} from '../../constants/permisos'
import {modulos as modulosConstant} from '../../constants/modulos'

class ModalGrupoVerraco extends Component {
  constructor () {
    super()
    this.state = {
      imputSelected: false,
      mensajeErrorVerracos: '',
      classErrorVerracos: false,
      mostrarFechaAsignacion: false,
      mostrarFechaDesasignacion: false,
    }
  }

  close(){
    this.props.actions.closeModal()
    this.setState({classErrorVerracos: false})
    this.setState({mensajeErrorVerracos: ''})
    this.setState({mostrarFechaDesasignacion: false})
    this.setState({mostrarFechaAsignacion: false})
  }

  componentDidUpdate(prevProps, prevState){
    if (Object.keys(this.props.verracosAnadidos).length > 0) {
      if (this.props.verracosAnadidos !== prevProps.verracosAnadidos) {
        this.setState({classErrorVerracos: false})
        this.setState({mensajeErrorVerracos: ''})
      }
    } else {
      if (this.props.verracosAnadidos !== prevProps.verracosAnadidos) {
        this.setState({mensajeErrorVerracos: this.props.t('GRUPOS_VERRACO.MODAL.ERROR_VERRACO_REQUERIDO')})
      }
    }

    if (Object.keys(this.props.verracosAnadidos).length > 0 && Object.keys(this.props.data).length === 0) {
      if (this.props.verracosAnadidos !== prevProps.verracosAnadidos) {
        this.setState({mostrarFechaAsignacion: true})
      }
    }

    if ((Object.keys(this.props.verracosAnadidos).length > 0 || Object.keys(prevProps.verracosAnadidos).length > 0) && Object.keys(this.props.data).length > 0) {
      if (this.props.verracosAnadidos !== prevProps.verracosAnadidos) {
        let desasignacion = false
        let asignacion = false
        for(let i =0; i < Object.keys(this.props.data.verraco).length;i++){
          desasignacion = false
          for(let j =0; j < Object.keys(this.props.verracosAnadidos).length;j++){
            if(this.props.data.verraco[i].idVerraco === this.props.verracosAnadidos[j].idVerraco){
              desasignacion = true
              break
            }
          }
          if (desasignacion === false){
            this.setState({mostrarFechaDesasignacion: true})
            break
          } else {
            this.setState({mostrarFechaDesasignacion: false})
          }
        }

        for(let i =0; i < Object.keys(this.props.verracosAnadidos).length;i++){
          asignacion = false
          for(let j =0; j < Object.keys(this.props.data.verraco).length;j++){
            if(this.props.verracosAnadidos[i].idVerraco === this.props.data.verraco[j].idVerraco){
              asignacion = true
              break
            }
          }
          if (asignacion === false){
            this.setState({mostrarFechaAsignacion: true})
            break
          } else {
            this.setState({mostrarFechaAsignacion: false})
          }
        }
      }
    }

    if (this.props.submitSucceeded !== prevProps.submitSucceeded && this.state.classErrorVerracos === true) {
      this.props.dispatch(setSubmitFailed('ModalGrupoVerraco'))
    }
  }

  submitNuevoGrupoVerraco (values) {
    return new Promise((resolve, reject) => {
      values.verraco = this.props.verracosAnadidos
      if (Object.keys(this.props.verracosAnadidos).length > 0) {
        this.setState({classErrorVerracos: false})
        if (Object.keys(this.props.data).length > 0){
          this.props.actions.editarGrupoVerraco(values, resolve, reject)
        } else {
          this.props.actions.submitNuevoGrupoVerraco(values, resolve, reject)
        }
      } else {
        this.setState({classErrorVerracos: true})
        this.setState({mensajeErrorVerracos: this.props.t('GRUPOS_VERRACO.MODAL.ERROR_VERRACO_REQUERIDO')})
        reject()
      }
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  guardarYnuevoGrupoVerraco(values){
    return new Promise((resolve, reject) => {
      values.verraco = this.props.verracosAnadidos
      if (Object.keys(this.props.verracosAnadidos).length > 0) {
        this.setState({classErrorVerracos: false})
        this.props.actions.guardarYnuevoGrupoVerraco(values, resolve, reject)
        this.props.actions.fetchFiltrarVerracoModalVerraco({})
      } else {
        this.setState({classErrorVerracos: true})
        this.setState({mensajeErrorVerracos: this.props.t('GRUPOS_VERRACO.MODAL.ERROR_VERRACO_REQUERIDO')})
        reject()
      }
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  duplicar(values){
    return new Promise((resolve, reject) => {
      this.props.duplicar(values, resolve, reject)
    })
      .then(() => { console.log('completado') })
      .catch(() => { console.log('error') })
  }

  intercambiarCheckModalGrupoVerraco (value) {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosModalGrupoVerraco()
    } else {
      this.props.actions.deSeleccionarTodosModalGrupoVerraco()
    }
  }

  deleteSeleccionVerracosGrupoVerraco () {
    const itemsSeleccion = this.props.verracosAnadidos.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] !== true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idVerraco': row.idVerraco
        }
      )
    })
    let list = itemsSeleccion.filter(function (item) {
      if (item !== false) {
        return item
      }
    });
    this.props.actions.deleteSeleccionVerracosGrupoVerraco(list)
    this.props.change('seleccionar-todo-modal-grupo-verraco', false)
    this.setState({imputSelected: false})
  }

  render () {
    const {
      t, handleSubmit, submitting, showModal, list, data, verracosAnadidos, checkedGrupoVerraco, numeroRegistrosPorPagina, formModalGrupoVerraco, paginaActual,
      actions: {recuperarDatosModalGrupoVerraco, abrirModalSeleccionarVerraco, onDeleteVerracoAnadido}, auth: {formaFechaHora}
    } = this.props

    verracosAnadidos.forEach(
      (row) => row.idElemento = row.idVerraco + 'grupo-verraco'
    )

    const tableVerracosAnadidos = {
      id: 'verracoAnadidoTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.GRUPOS_VERRACOS] > 1) && !submitting,
      hasOptions: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.GRUPOS_VERRACOS] > 1) && !submitting,
      columns: [
        {id: 'codVerraco', name: t('GRUPOS_VERRACO.TABLA_VERRACO.NOMBRE')},
        {id: 'fechaAsignacion', name: t('GRUPOS_VERRACO.TABLA_VERRACO.FECHA_ASIGNACION'), type: 'fecha'},
        {id: 'nombreRaza', name: t('GRUPOS_VERRACO.TABLA_VERRACO.RAZA')},
        {id: 'nombreLinea', name: t('GRUPOS_VERRACO.TABLA_VERRACO.LINEA_GENETICA')},
        {id: 'estadoverraco', name: t('GRUPOS_VERRACO.TABLA_VERRACO.ESTADO'), stilyzer: estadoVerracoStilyzer},
        (this.props.auth.modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && {id: 'ubicacionverraco', name: t('GRUPOS_VERRACO.TABLA_VERRACO.UBICACION')}),
        {id: 'index', name: t('GRUPOS_VERRACO.TABLA_VERRACO.INDEX')}
      ],
      rows: verracosAnadidos,
      rowsVerraco: true,
      onDelete: (row) => onDeleteVerracoAnadido(row.idVerraco),
      mostrarCambioEstado: false,
      mostrarModificar: false,
      mostrarDuplicar: false,
      tablaModal: true,
      idBlockOptions: 1,
      showModal: this.props.showModalSeleccionarVerraco,
      initialValues: {},
    }

    tableVerracosAnadidos.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableVerracosAnadidos.initialValues['check' + row.idElemento] = checkedGrupoVerraco
        }
      }
    )

    return (
      <Modal show={showModal} onHide={() => this.close()} aria-labelledby="contained-modal-title-lg" size="lg" className="modal-grupo-verraco" backdrop="static">
      <form className="form-grupo-verraco">
        <Modal.Header closeButton={!submitting}>
          <Modal.Title>
          {
            Object.keys(data).length > 0 ? (
              t('GRUPOS_VERRACO.MODAL.TITULO_EDITAR')
            ) : (
              t('GRUPOS_VERRACO.MODAL.TITULO_NUEVO')
            )
          }
          </Modal.Title>
          {!submitting && data && data.idGrupoVerraco && (
            <ButtonChangeRecord list={list} idElemento="idGrupoVerraco" currentId={data.idGrupoVerraco} getNextRecord={recuperarDatosModalGrupoVerraco} />
          )}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={4} style={{padding: '0px'}}>
              <Field
                colSm={12}
                id="nombre-grupo-verraco"
                name="nombreGrupoVerraco"
                component={InputText}
                controlLabel={t('GRUPOS_VERRACO.MODAL.NOMBRE_GRUPO_VERRACO') + '*'}
                customClass='nombre-grupo-verraco'
                validate={required}
                maxLength={true}
                valorMaxLength={50}
              />
              <Field
                id="fechaCreacion"
                name="fechaCreacion"
                component={InputDatePicker}
                colSm={12}
                controlLabel={t('GRUPOS_VERRACO.MODAL.FECHA_CREACION') + '*'}
                validate={required}
              />
            </Col>
            <Col sm={8} style={{padding: '0px'}}>
              <Field
                colSm={12}
                id="descripcion-grupo-verraco"
                name="descripcionGrupoVerraco"
                component={InputTextArea}
                componentClass="textarea"
                controlLabel={t('GRUPOS_VERRACO.MODAL.DESCRIPCION')}
                customClass='descripcion-grupo-verraco'
              />
            </Col>
            <div className="clearfix"></div>
              <div className="tabla-verracos-en-grupo-verracos">
              <FormSubtitle>{t('GRUPOS_VERRACO.MODAL.VERRACOS_ASIGNADOS')}</FormSubtitle>
                <div id="block-options1" className={"filtros-tabla-modal-grupo-verracos col-sm-12" + (this.state.imputSelected === true ? '' : ' oculta-action')}>
                  <div className="custom-check-w">
                    {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.GRUPOS_VERRACOS] > 1) && [
                      <Field
                        id="seleccionar-todo-modal-grupo-verraco"
                        name="seleccionar-todo-modal-grupo-verraco"
                        key="seleccionar-todo-modal-grupo-verraco"
                        inline
                        component={InputCheckBox}
                        onInputChange={(value) => this.intercambiarCheckModalGrupoVerraco(value)}
                      />,
                      <button key="eliminar_key" type="button" data-toggle="modal" data-target="#delete-modal" className="btn-icono" onClick={(e) => this.deleteSeleccionVerracosGrupoVerraco()}>
                        <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                      </button>
                    ]}
                    <ModalVerracoGrupoVerracoPage />
                    <button
                      type="button"
                      data-toggle="modal"
                      data-target="#new-edit-modal"
                      className="btn btn-primary nuevo btn-sm"
                      disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.GRUPOS_VERRACOS] < 2) || submitting}
                      onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.GRUPOS_VERRACOS] > 1) && !submitting ? () => abrirModalSeleccionarVerraco() : undefined}
                    >
                      <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                    </button>
                  </div>
                </div>
                <ListadoPage t_key="GRUPOS_VERRACO." noTitle={true} table={tableVerracosAnadidos}>
                </ListadoPage>
                <p className={this.state.classErrorVerracos === true ? 'error-verracos' : 'noerror-verracos'}><Warning/>{this.state.mensajeErrorVerracos}</p>
              </div>
              {
                this.state.mostrarFechaAsignacion === true && (
                  <Field
                    id="fechaAsignacion"
                    name="fechaAsignacion"
                    component={InputDatePicker}
                    colSm={4}
                    controlLabel={t('GRUPOS_VERRACO.MODAL.FECHA_ASIGNACION')}
                    validate={required}
                  />
                )
              }
              {
                this.state.mostrarFechaDesasignacion === true && (
                  <Field
                    id="fechaDesasignacion"
                    name="fechaDesasignacion"
                    component={InputDatePicker}
                    colSm={4}
                    controlLabel={t('GRUPOS_VERRACO.MODAL.FECHA_DESASIGNACION')}
                    validate={required}
                  />
                )
              }
              <Field
                id="activo"
                name="activo"
                colSm={12}
                inline
                component={InputCheckBox}
                claseActivo="onoffswitch-label"
                controlLabel={t('GRUPOS_VERRACO.MODAL.ACTIVO')}
              />
              <div className="clearfix"></div>
              {
                formModalGrupoVerraco && formModalGrupoVerraco.values.activo === false ? (
                  <span style={{marginLeft: '15px'}}>{t('GRUPOS_VERRACO.MODAL.FECHA_DESACTIVACION') + ': ' + date_formatter(new Date, formaFechaHora, false)}</span>
                ) : null
              }
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {
            Object.keys(this.props.data).length > 0 && (
              <Button
                type="button"
                className="btn btn-white btn-sm"
                disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.GRUPOS_VERRACOS] < 2) || submitting}
                onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.GRUPOS_VERRACOS] > 1) && !submitting ? handleSubmit(this.duplicar.bind(this)) : undefined}
              >
                {t('GRUPOS_VERRACO.MODAL.DUPLICAR')}
              </Button>
            )
          }
          {
            Object.keys(this.props.data).length === 0 && (
              <ButtonLockable
                processing={submitting}
                hasIcon={true}
                showLoaderOnClick={true}
                type="button"
                className="btn btn-primary btn-sm"
                disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.GRUPOS_VERRACOS] < 2) || submitting}
                onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.GRUPOS_VERRACOS] > 1) && !submitting) ? handleSubmit(this.guardarYnuevoGrupoVerraco.bind(this)) : () => {}}
              >
                {t('GRUPOS_VERRACO.MODAL.GUARDAR_Y_NUEVO')}
              </ButtonLockable>
            )
          }
          <ButtonLockable
            processing={submitting}
            hasIcon={true}
            showLoaderOnClick={true}
            type="button"
            className="btn btn-primary btn-sm"
            disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.GRUPOS_VERRACOS] < 2) || submitting}
            onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.GRUPOS_VERRACOS] > 1) && !submitting) ? handleSubmit(this.submitNuevoGrupoVerraco.bind(this)) : () => {}}
          >
            {t('GRUPOS_VERRACO.MODAL.GUARDAR')}
          </ButtonLockable>
          <Button
            type="button"
            className={"btn btn-white btn-sm" + (submitting ? ' disabled' : '')}
            onClick={() => !submitting && this.close()}
          >
            {t('GRUPOS_VERRACO.MODAL.CANCELAR')}
          </Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalGrupoVerraco',
  // enableReinitialize: true
})(ModalGrupoVerraco))
