import {createStore, applyMiddleware} from 'redux'
import { composeWithDevTools } from 'remote-redux-devtools'
import rootReducer from '../reducers'
import createSagaMiddleware from 'redux-saga'
import rootSagas from '../sagas/index'

const sagaMiddleware = createSagaMiddleware()

const composeEnhancers =
  //process.env.NODE_ENV !== 'production' &&
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify here name, actionsBlacklist, actionsCreators and other options
    }) : composeWithDevTools({
      hostname: 'localhost',
      port: 8000,
      maxAge: 50,
      actionsBlacklist: [],
      actionSanitizer: (action) => {
        console.log(action.type)
        return action
      }
    })

const enhancer = composeEnhancers(
  applyMiddleware(sagaMiddleware)
)

const configureStore = (initialState) => {
  const store = createStore(rootReducer, initialState, enhancer)
  if (module.hot) {
    module.hot.accept('../reducers', () =>
      store.replaceReducer(require('../reducers'))
    )
  }

  sagaMiddleware.run(rootSagas)

  return store
}

export default configureStore
