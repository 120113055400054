import actionTypes from '../../../constants/actions/planSanitario/bioseguridadHigiene/bioseguridadHigiene'

export function abrirModalVacioBioseguridadHigiene () {
  return {
    type: actionTypes.ABRIR_MODAL_VACIO_BIOSEGURIDAD_HIGIENE
  }
}

export function openModalBioseguridadHigiene () {
  return {
    type: actionTypes.OPEN_MODAL_BIOSEGURIDAD_HIGIENE
  }
}

export function closeModalBioseguridadHigiene () {
  return {
    type: actionTypes.CLOSE_MODAL_BIOSEGURIDAD_HIGIENE
  }
}

export function fetchBioseguridadHigiene (){
  return {
    type: actionTypes.FETCH_BIOSEGURIDAD_HIGIENE
  }
}

export function fetchBioseguridadHigieneSuccess (list){
  return {
    type: actionTypes.FETCH_BIOSEGURIDAD_HIGIENE_SUCCESS,
    list
  }
}

export function abrirModalLimpiezaSilos (listSiloAnadidos){
  return {
    type: actionTypes.ABRIR_MODAL_LIMPIEZA_SILOS,
    listSiloAnadidos
  }
}

export function abrirModalLimpiezaSilosSuccess (listLimpiezaSilos){
  return {
    type: actionTypes.ABRIR_MODAL_LIMPIEZA_SILOS_SUCCESS,
    listLimpiezaSilos
  }
}


export function cerrarModalLimpiezaSilos (){
  return {
    type: actionTypes.CERRAR_MODAL_LIMPIEZA_SILOS
  }
}

export function fetchLimpiezaSilos (limpiezaSilosAnadidas){
  return {
    type: actionTypes.FETCH_LIMPIEZA_SILOS,
    limpiezaSilosAnadidas
  }
}

export function fetchLimpiezaSilosSuccess (listLimpiezaSilos){
  return {
    type: actionTypes.FETCH_LIMPIEZA_SILOS_SUCCESS,
    listLimpiezaSilos
  }
}

export function seleccionarTodosModalLimpiezaSilos (){
  return {
    type: actionTypes.SELCCIONAR_TODOS_MODAL_LIMPIEZA_SILOS,
  }
}

export function deSeleccionarTodosModalLimpiezaSilos (){
  return {
    type: actionTypes.DESELCCIONAR_TODOS_MODAL_LIMPIEZA_SILOS,
  }
}

export function anadirSilo (listSiloAnadidos){
  return {
    type: actionTypes.ANADIR_SILO,
    listSiloAnadidos
  }
}

export function onDeleteSiloAnadido (idInstalacion){
  return {
    type: actionTypes.ON_DELETE_SILO_ANADIDO,
    idInstalacion
  }
}

export function onDeleteSiloAnadidoSuccess (listSiloAnadidos){
  return {
    type: actionTypes.ON_DELETE_SILO_ANADIDO_SUCCESS,
    listSiloAnadidos
  }
}

export function seleccionarTodosModalBioseguridad (){
  return {
    type: actionTypes.SELECCIONAR_TODOS_MODAL_BIOSEGURIDAD
  }
}

export function deSeleccionarTodosModalBioseguridad (){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_MODAL_BIOSEGURIDAD
  }
}

export function deleteSeleccionModalSilos (list) {
  return {
    type: actionTypes.DELETE_SELECCION_MODAL_SILOS,
    list
  }
}

export function abrirModalLimpiezaCorral (listCorralAnadidos){
  return {
    type: actionTypes.ABRIR_MODAL_LIMPIEZA_CORRAL,
    listCorralAnadidos
  }
}

export function abrirModalLimpiezaCorralSuccess (listLimpiezaCorral){
  return {
    type: actionTypes.ABRIR_MODAL_LIMPIEZA_CORRAL_SUCCESS,
    listLimpiezaCorral
  }
}


export function cerrarModalLimpiezaCorral (){
  return {
    type: actionTypes.CERRAR_MODAL_LIMPIEZA_CORRAL
  }
}

export function fetchLimpiezaCorral (list, instalacionPadre){
  return {
    type: actionTypes.FETCH_LIMPIEZA_CORRAL,
    list,
    instalacionPadre
  }
}

export function deleteSeleccionModalCorral (list) {
  return {
    type: actionTypes.DELETE_SELECCION_MODAL_CORRAL,
    list
  }
}

export function fetchLimpiezaCorralSuccess (listLimpiezaCorral){
  return {
    type: actionTypes.FETCH_LIMPIEZA_CORRAL_SUCCESS,
    listLimpiezaCorral
  }
}

export function seleccionarTodosModalLimpiezaCorral (){
  return {
    type: actionTypes.SELCCIONAR_TODOS_MODAL_LIMPIEZA_CORRAL,
  }
}

export function deSeleccionarTodosModalLimpiezaCorral (){
  return {
    type: actionTypes.DESELCCIONAR_TODOS_MODAL_LIMPIEZA_CORRAL,
  }
}

export function onDeleteCorralAnadido (idInstalacion){
  return {
    type: actionTypes.ON_DELETE_CORRAL_ANADIDO,
    idInstalacion
  }
}

export function onDeleteCorralAnadidoSuccess (listCorralAnadidos){
  return {
    type: actionTypes.ON_DELETE_CORRAL_ANADIDO_SUCCESS,
    listCorralAnadidos
  }
}

export function anadirCorral (listCorralAnadidos){
  return {
    type: actionTypes.ANADIR_CORRAL,
    listCorralAnadidos
  }
}

export function abrirModalUbicacion (listUbicacionAnadidos){
  return {
    type: actionTypes.ABRIR_MODAL_UBICACION,
    listUbicacionAnadidos
  }
}

export function abrirModalUbicacionSuccess (listUbicacion){
  return {
    type: actionTypes.ABRIR_MODAL_UBICACION_SUCCESS,
    listUbicacion
  }
}


export function cerrarModalUbicacion (){
  return {
    type: actionTypes.CERRAR_MODAL_UBICACION
  }
}

export function fetchUbicacion (ubicacionAnadidas){
  return {
    type: actionTypes.FETCH_UBICACION,
    ubicacionAnadidas
  }
}

export function fetchUbicacionSuccess (listUbicacion){
  return {
    type: actionTypes.FETCH_UBICACION_SUCCESS,
    listUbicacion
  }
}

export function seleccionarTodosModalUbicacion (){
  return {
    type: actionTypes.SELCCIONAR_TODOS_MODAL_UBICACION,
  }
}

export function deSeleccionarTodosModalUbicacion (){
  return {
    type: actionTypes.DESELCCIONAR_TODOS_MODAL_UBICACION,
  }
}

export function onDeleteUbicacionAnadido (idInstalacion){
  return {
    type: actionTypes.ON_DELETE_UBICACION_ANADIDO,
    idInstalacion
  }
}

export function onDeleteUbicacionAnadidoSuccess (listUbicacionAnadidos){
  return {
    type: actionTypes.ON_DELETE_UBICACION_ANADIDO_SUCCESS,
    listUbicacionAnadidos
  }
}

export function anadirUbicacion (listUbicacionAnadidos){
  return {
    type: actionTypes.ANADIR_UBICACION,
    listUbicacionAnadidos
  }
}

export function deleteSeleccionModalUbicacion (list) {
  return {
    type: actionTypes.DELETE_SELECCION_MODAL_UBICACION,
    list
  }
}

export function abrirModalVerraco (listVerracoAnadidos){
  return {
    type: actionTypes.ABRIR_MODAL_VERRACO,
    listVerracoAnadidos
  }
}

export function abrirModalVerracoSuccess (listVerraco){
  return {
    type: actionTypes.ABRIR_MODAL_VERRACO_SUCCESS,
    listVerraco
  }
}


export function cerrarModalVerraco (){
  return {
    type: actionTypes.CERRAR_MODAL_VERRACO
  }
}

export function fetchVerraco (verracoAnadidas){
  return {
    type: actionTypes.FETCH_VERRACO,
    verracoAnadidas
  }
}

export function fetchVerracoSuccess (listVerraco){
  return {
    type: actionTypes.FETCH_VERRACO_SUCCESS,
    listVerraco
  }
}

export function seleccionarTodosModalVerraco (){
  return {
    type: actionTypes.SELCCIONAR_TODOS_MODAL_VERRACO,
  }
}

export function deSeleccionarTodosModalVerraco (){
  return {
    type: actionTypes.DESELCCIONAR_TODOS_MODAL_VERRACO,
  }
}

export function onDeleteVerracoAnadidoBioseguridad (idVerraco){
  return {
    type: actionTypes.ON_DELETE_VERRACO_ANADIDO_BIOSEGURIDAD,
    idVerraco
  }
}

export function onDeleteVerracoAnadidoBioseguridadSuccess (listVerracoAnadidos){
  return {
    type: actionTypes.ON_DELETE_VERRACO_ANADIDO_BIOSEGURIDAD_SUCCESS,
    listVerracoAnadidos
  }
}

export function anadirVerracoBioseguridad (listVerracoAnadidos){
  return {
    type: actionTypes.ANADIR_VERRACO_BIOSEGURIDAD,
    listVerracoAnadidos
  }
}

export function deleteSeleccionModalVerraco (list) {
  return {
    type: actionTypes.DELETE_SELECCION_MODAL_VERRACO,
    list
  }
}

export function abrirModalGrupoVerraco (listGrupoVerracoAnadidos){
  return {
    type: actionTypes.ABRIR_MODAL_GRUPO_VERRACO,
    listGrupoVerracoAnadidos
  }
}

export function abrirModalGrupoVerracoSuccess (listGrupoVerraco){
  return {
    type: actionTypes.ABRIR_MODAL_GRUPO_VERRACO_SUCCESS,
    listGrupoVerraco
  }
}

export function cerrarModalGrupoVerraco (){
  return {
    type: actionTypes.CERRAR_MODAL_GRUPO_VERRACO
  }
}

export function fetchGrupoVerracoBioseguridad (grupoVerracoAnadidas){
  return {
    type: actionTypes.FETCH_GRUPO_VERRACO_BIOSEGURIDAD,
    grupoVerracoAnadidas
  }
}

export function fetchGrupoVerracoBioseguridadSuccess (listGrupoVerraco){
  return {
    type: actionTypes.FETCH_GRUPO_VERRACO_BIOSEGURIDAD_SUCCESS,
    listGrupoVerraco
  }
}

export function seleccionarTodosModalGrupoVerraco (){
  return {
    type: actionTypes.SELCCIONAR_TODOS_MODAL_GRUPO_VERRACO,
  }
}

export function deSeleccionarTodosModalGrupoVerraco (){
  return {
    type: actionTypes.DESELCCIONAR_TODOS_MODAL_GRUPO_VERRACO,
  }
}

export function onDeleteGrupoVerracoAnadido (idGrupoVerraco){
  return {
    type: actionTypes.ON_DELETE_GRUPO_VERRACO_ANADIDO_BIOSEGURIDAD,
    idGrupoVerraco
  }
}

export function onDeleteGrupoVerracoAnadidoSuccess (listGrupoVerracoAnadidos){
  return {
    type: actionTypes.ON_DELETE_GRUPO_VERRACO_ANADIDO_BIOSEGURIDAD_SUCCESS,
    listGrupoVerracoAnadidos
  }
}

export function anadirGrupoVerracoBioseguridad (listGrupoVerracoAnadidos){
  return {
    type: actionTypes.ANADIR_GRUPO_VERRACO_BIOSEGURIDAD,
    listGrupoVerracoAnadidos
  }
}

export function deleteSeleccionModalGrupoVerraco (list) {
  return {
    type: actionTypes.DELETE_SELECCION_MODAL_GRUPO_VERRACO,
    list
  }
}

export function submitAnaliticaAgua (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.SUBMIT_ANALITICA_AGUA,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function submitAnaliticaAguaSuccess (insercionBioseguridad) {
  return {
    type: actionTypes.SUBMIT_ANALITICA_AGUA_SUCCESS,
    insercionBioseguridad
  }
}

export function submitLimpiezaSilos (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.SUBMIT_LIMPIEZA_SILOS,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function submitLimpiezaSilosSuccess (insercionBioseguridad) {
  return {
    type: actionTypes.SUBMIT_LIMPIEZA_SILOS_SUCCESS,
    insercionBioseguridad
  }
}

export function submitLimpiezaCorrales (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.SUBMIT_LIMPIEZA_CORRALES,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function submitLimpiezaCorralesSuccess (insercionBioseguridad) {
  return {
    type: actionTypes.SUBMIT_LIMPIEZA_CORRALES_SUCCESS,
    insercionBioseguridad
  }
}

export function submitOtros (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.SUBMIT_OTROS,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function submitOtrosSuccess (insercionBioseguridad) {
  return {
    type: actionTypes.SUBMIT_OTROS_SUCCESS,
    insercionBioseguridad
  }
}

export function recuperarDatosModalBioseguridadHigiene (idBioseguridad, idTipoAccion){
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_BIOSEGURIDAD_HIGIENE,
    idBioseguridad,
    idTipoAccion
  }
}

export function recuperarDatosModalBioseguridadHigieneSuccess (registroModificar){
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_BIOSEGURIDAD_HIGIENE_SUCCESS,
    registroModificar
  }
}

export function modificarAnaliticaAgua (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.MODIFICAR_ANALITICA_AGUA,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function modificarLimpiezaSilos (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.MODIFICAR_LIMPIEZA_SILOS,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function modificarLimpiezaCorral (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.MODIFICAR_LIMPIEZA_CORRAL,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function modificarOtros (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.MODIFICAR_OTROS,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function duplicarDatosModalBioseguridadHigiene (idBioseguridad, idTipoAccion){
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_BIOSEGURIDAD_HIGIENE,
    idBioseguridad,
    idTipoAccion
  }
}

export function duplicarDatosModalBioseguridadHigieneSuccess (){
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_BIOSEGURIDAD_HIGIENE_SUCCESS
  }
}

export function seleccionarTodosBioseguridadHigiene (){
  return {
    type: actionTypes.SELCCIONAR_TODOS_BIOSEGURIDAD_HIGIENE,
  }
}

export function deSeleccionarTodosBioseguridadHigiene (){
  return {
    type: actionTypes.DESELCCIONAR_TODOS_BIOSEGURIDAD_HIGIENE,
  }
}

export function onDeleteBioseguridadHigiene (idBioseguridad, idTipoAccion){
  return {
    type: actionTypes.ON_DELETE_BIOSEGURIDAD_HIGIENE,
    idBioseguridad,
    idTipoAccion
  }
}

export function guardarNuevoAnaliticaAgua (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.GUARDAR_NUEVO_ANALITICA_AGUA,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function guardarNuevoLimpiezaSilos (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.GUARDAR_NUEVO_LIMPIEZA_SILOS,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function guardarNuevoLimpiezaCorrales (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.GUARDAR_NUEVO_LIMPIEZA_CORRALES,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function guardarNuevoOtros (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.GUARDAR_NUEVO_OTROS,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function vaciarTablas(){
  return {
    type: actionTypes.VACIAR_TABLAS
  }
}

export function deleteSeleccionBioseguridadHigiene(list){
  return {
    type: actionTypes.DELETE_SELECCION_BIOSEGURIDAD_HIGIENE,
    list
  }
}

export function fetchFiltrarBioseguridadHigiene (values) {
  return {
    type: actionTypes.FETCH_FILTRAR_BIOSEGURIDAD_HIGIENE,
    values
  }
}

export function fetchFiltrarBioseguridadHigieneSuccess (values) {
  return {
    type: actionTypes.FETCH_FILTRAR_BIOSEGURIDAD_HIGIENE_SUCCESS,
    values
  }
}

export function crearCsvBioseguridad(list){
  return {
    type: actionTypes.CREATE_CSV_BIOSEGURIDAD,
    list
  }
}

export function crearCsvBioseguridadSuccess(datosExcelBioseguridad){
  return {
    type: actionTypes.CREATE_CSV_BIOSEGURIDAD_SUCCESS,
    datosExcelBioseguridad
  }
}
export function imprimirPdfBioseguridad(list){
  return {
    type: actionTypes.IMPRIMIR_PDF_BIOSEGURIDAD,
    list
  }
}

export function imprimirPdfBioseguridadSuccess(datosPdfBioseguridad){
  return {
    type: actionTypes.IMPRIMIR_PDF_BIOSEGURIDAD_SUCCESS,
    datosPdfBioseguridad
  }
}

export function abrirModalAgendaBioseguridad(){
  return {
    type: actionTypes.ABRIR_MODAL_AGENDA_BIOSEGURIDAD,
  }
}

export function abrirModalAgendaBioseguridadSuccess({tipoAccion}){
  return {
    type: actionTypes.ABRIR_MODAL_AGENDA_BIOSEGURIDAD_SUCCESS,
    tipoAccion
  }
}

export function guardarModalAgendaBioseguridad(values){
  return {
    type: actionTypes.GUARDAR_MODAL_AGENDA_BIOSEGURIDAD,
    values
  }
}

export function guardarModalAgendaBioseguridadSuccess(){
  return {
    type: actionTypes.GUARDAR_MODAL_AGENDA_BIOSEGURIDAD_SUCCESS,
  }
}

export function cerrarModalAgendaBioseguridad(){
  return {
    type: actionTypes.CERRAR_MODAL_AGENDA_BIOSEGURIDAD,
  }
}

export function cerrarModalAgendaBioseguridadSuccess(){
  return {
    type: actionTypes.CERRAR_MODAL_AGENDA_BIOSEGURIDAD_SUCCESS
  }
}

export function filtrosUbicacionSuccess(filtrosUbicacion){
  return {
    type: actionTypes.FILTROS_UBICACION_SUCCESS,
    filtrosUbicacion
  }
}

export function filtrosVerracosSuccess(filtrosVerracos){
  return {
    type: actionTypes.FILTROS_VERRACOS_SUCCESS,
    filtrosVerracos
  }
}

export function subirArchivosBioseguridad (archivos) {
  return {
    type: actionTypes.SUBIR_ARCHIVOS_BIOSEGURIDAD,
    archivos
  }
}

export function eliminarArchvioBioseguridad (archivo) {
  return {
    type: actionTypes.ELIMINAR_ARCHIVOS_BIOSEGURIDAD,
    archivo
  }
}

export function obtenerArchivoContentBioseguridad(archivo){
  return {
    type: actionTypes.OBTENER_ARCHIVO_CONTENT_BIOSEGURIDAD,
    archivo
  }
}

export function obtenerArchivoBioseguridadSuccess(archivoDescarga){
  return {
    type: actionTypes.OBTENER_ARCHIVO_BIOSEGURIDAD_SUCCESS,
    archivoDescarga
  }
}

export function imprimirInformeBioseguridad(values, id, tipoAccion, onSuccessCallback, onErrorCallback){
  return {
    type: actionTypes.IMPRIMIR_INFORME_BIOSEGURIDAD,
    values,
    id,
    tipoAccion,
    onSuccessCallback,
    onErrorCallback
  }
}

export default {
  abrirModalVacioBioseguridadHigiene,
  closeModalBioseguridadHigiene,
  fetchBioseguridadHigiene,
  abrirModalLimpiezaSilos,
  abrirModalLimpiezaSilosSuccess,
  cerrarModalLimpiezaSilos,
  fetchLimpiezaSilos,
  fetchLimpiezaSilosSuccess,
  seleccionarTodosModalLimpiezaSilos,
  deSeleccionarTodosModalLimpiezaSilos,
  anadirSilo,
  onDeleteSiloAnadido,
  onDeleteSiloAnadidoSuccess,
  seleccionarTodosModalBioseguridad,
  deSeleccionarTodosModalBioseguridad,
  deleteSeleccionModalSilos,
  abrirModalLimpiezaCorral,
  abrirModalLimpiezaCorralSuccess,
  cerrarModalLimpiezaCorral,
  fetchLimpiezaCorral,
  fetchLimpiezaCorralSuccess,
  seleccionarTodosModalLimpiezaCorral,
  deSeleccionarTodosModalLimpiezaCorral,
  onDeleteCorralAnadido,
  onDeleteCorralAnadidoSuccess,
  anadirCorral,
  abrirModalUbicacion,
  abrirModalUbicacionSuccess,
  cerrarModalUbicacion,
  fetchUbicacion,
  fetchUbicacionSuccess,
  seleccionarTodosModalUbicacion,
  deSeleccionarTodosModalUbicacion,
  onDeleteUbicacionAnadido,
  onDeleteUbicacionAnadidoSuccess,
  anadirUbicacion,
  deleteSeleccionModalCorral,
  deleteSeleccionModalUbicacion,
  abrirModalVerraco,
  abrirModalVerracoSuccess,
  cerrarModalVerraco,
  fetchVerraco,
  fetchVerracoSuccess,
  seleccionarTodosModalVerraco,
  deSeleccionarTodosModalVerraco,
  onDeleteVerracoAnadidoBioseguridad,
  onDeleteVerracoAnadidoBioseguridadSuccess,
  anadirVerracoBioseguridad,
  deleteSeleccionModalVerraco,
  abrirModalGrupoVerraco,
  abrirModalGrupoVerracoSuccess,
  cerrarModalGrupoVerraco,
  fetchGrupoVerracoBioseguridad,
  fetchGrupoVerracoBioseguridadSuccess,
  seleccionarTodosModalGrupoVerraco,
  deSeleccionarTodosModalGrupoVerraco,
  onDeleteGrupoVerracoAnadido,
  onDeleteGrupoVerracoAnadidoSuccess,
  anadirGrupoVerracoBioseguridad,
  deleteSeleccionModalGrupoVerraco,
  submitAnaliticaAgua,
  submitAnaliticaAguaSuccess,
  submitLimpiezaSilos,
  submitLimpiezaSilosSuccess,
  submitLimpiezaCorrales,
  submitLimpiezaCorralesSuccess,
  submitOtros,
  submitOtrosSuccess,
  recuperarDatosModalBioseguridadHigiene,
  recuperarDatosModalBioseguridadHigieneSuccess,
  modificarAnaliticaAgua,
  modificarLimpiezaSilos,
  modificarLimpiezaCorral,
  modificarOtros,
  duplicarDatosModalBioseguridadHigiene,
  duplicarDatosModalBioseguridadHigieneSuccess,
  seleccionarTodosBioseguridadHigiene,
  deSeleccionarTodosBioseguridadHigiene,
  onDeleteBioseguridadHigiene,
  guardarNuevoAnaliticaAgua,
  guardarNuevoLimpiezaSilos,
  guardarNuevoLimpiezaCorrales,
  guardarNuevoOtros,
  vaciarTablas,
  deleteSeleccionBioseguridadHigiene,
  fetchFiltrarBioseguridadHigiene,
  fetchFiltrarBioseguridadHigieneSuccess,
  crearCsvBioseguridad,
  crearCsvBioseguridadSuccess,
  imprimirPdfBioseguridad,
  imprimirPdfBioseguridadSuccess,
  abrirModalAgendaBioseguridad,
  abrirModalAgendaBioseguridadSuccess,
  guardarModalAgendaBioseguridad,
  guardarModalAgendaBioseguridadSuccess,
  cerrarModalAgendaBioseguridad,
  cerrarModalAgendaBioseguridadSuccess,
  filtrosUbicacionSuccess,
  filtrosVerracosSuccess,
  subirArchivosBioseguridad,
  obtenerArchivoContentBioseguridad,
  obtenerArchivoBioseguridadSuccess,
  imprimirInformeBioseguridad
}
