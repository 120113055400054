import actionTypes from '../../constants/actions/entradaMercancia/entradaMercancia'

export function initialState () {
  return {
    listEntradaMercancia: [],
    checkedFiltrosEntradaMercancia: false,
    showModalEntradaMercancia: false,
    filtros: {},
    datosExcelEntradaMercancia: [],
    datosPdfEntradaMercancia: [],
    selectorProceso: '',
    data: [],
    dataDuplicar: [],
    entradaMercanciaProductos: [],
    showModalEntradaMercanciaProducto: false,
    checkedFiltrosEntradaMercanciaProducto: false,
    dataProducto: [],
    dataProductoDuplicar: [],
    datosLoteExistente: null
  }
}

export function abrirModalEntradaMercancia (state) {
  return {
    ...state,
    showModalEntradaMercancia: true,
    data: [],
    dataDuplicar: [],
    entradaMercanciaProductos: []
  }
}

export function closeModalEntradaMercancia (state) {
  return {
    ...state,
    showModalEntradaMercancia: false,
    data: [],
    dataDuplicar: [],
    entradaMercanciaProductos: []
  }
}

export function filtrarEntradaMercanciaSuccess (state, {listEntradaMercancia}) {
  return {
    ...state,
    listEntradaMercancia: listEntradaMercancia
  }
}

export function filtrosEntradaMercanciaSuccess (state, {filtros}) {
  return {
    ...state,
    filtros: filtros
  }
}

export function recuperarDatosModalEntradaMercanciaSuccess (state, {data}) {
  return {
    ...state,
    data: data,
    showModalEntradaMercancia: true,
    dataDuplicar: []
  }
}

export function duplicarDatosModalEntradaMercanciaSuccess (state, {dataDuplicar}) {
  return {
    ...state,
    dataDuplicar: dataDuplicar,
    showModalEntradaMercancia: true,
    data: []
  }
}

export function seleccionarTodosEntradaMercancia (state) {
  return {
    ...state,
    checkedFiltrosEntradaMercancia: true
  }
}

export function deSeleccionarTodosEntradaMercancia (state) {
  return {
    ...state,
    checkedFiltrosEntradaMercancia: false
  }
}

export function crearCsvEntradaMercanciaSuccess (state, {datosExcelEntradaMercancia}) {
  return {
    ...state,
    datosExcelEntradaMercancia
  }
}

export function imprimirPdfEntradaMercanciaSuccess (state, {datosPdfEntradaMercancia}) {
  return {
    ...state,
    datosPdfEntradaMercancia
  }
}

export function guardarYnuevoEntradaMercanciaSuccess (state) {
  return {
    ...state,
    data: [],
    dataDuplicar: [],
    entradaMercanciaProductos: []
  }
}

export function abrirModalEntradaMercanciaAnadirProducto (state) {
  return {
    ...state,
    showModalEntradaMercanciaProducto: true,
    dataProducto: [],
    dataProductoDuplicar: [],
    datosLoteExistente: null
  }
}

export function cerrarModalEntradaMercanciaAnadirProducto (state) {
  return {
    ...state,
    showModalEntradaMercanciaProducto: false,
    dataProducto: [],
    dataProductoDuplicar: []
  }
}
export function seleccionarTodosEntradaMercanciaProducto (state) {
  return {
    ...state,
    checkedFiltrosEntradaMercanciaProducto: true
  }
}

export function deSeleccionarTodosEntradaMercanciaProducto (state) {
  return {
    ...state,
    checkedFiltrosEntradaMercanciaProducto: false
  }
}

export function anadirProductoEntradaMercancia (state, {entradaMercanciaProductos}) {
  return {
    ...state,
    entradaMercanciaProductos: state.entradaMercanciaProductos.concat(entradaMercanciaProductos),
    showModalEntradaMercanciaProducto: false
  }
}

export function guardarYnuevoEntradaMercanciaProducto (state, {entradaMercanciaProductos}) {
  return {
    ...state,
    entradaMercanciaProductos: state.entradaMercanciaProductos.concat(entradaMercanciaProductos)
  }
}

export function recuperarModalEntradaMercanciaProductoAnadidoSuccess (state, {dataProducto}) {
  return {
    ...state,
    dataProducto: dataProducto,
    showModalEntradaMercanciaProducto: true,
    dataProductoDuplicar: [],
    datosLoteExistente: null
  }
}

export function editarEntradaMercanciaProductoSuccess (state, {entradaMercanciaProductos}) {
  return {
    ...state,
    entradaMercanciaProductos: entradaMercanciaProductos,
    showModalEntradaMercanciaProducto: false
  }
}

export function duplicarDatosModalEntradaMercanciaAnadidoSuccess (state, {dataProductoDuplicar}) {
  return {
    ...state,
    dataProducto: [],
    dataProductoDuplicar: dataProductoDuplicar,
    showModalEntradaMercanciaProducto: true,
    datosLoteExistente: null
  }
}

export function resetDatosEntradaMercanciaProducto (state) {
  return {
    ...state,
    dataProducto: [],
    dataProductoDuplicar: []
  }
}

export function deleteSeleccionEntradaMercanciaProductoAnadidoSuccess (state, {datosAnadidos}) {
  return {
    ...state,
    entradaMercanciaProductos: datosAnadidos
  }
}

export function fetchLoteExistenteSuccess (state, {datosLoteExistente}) {
  return {
    ...state,
    datosLoteExistente
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.ABRIR_MODAL_ENTRADA_MERCANCIA:
      return abrirModalEntradaMercancia(state, action)
    case actionTypes.CLOSE_MODAL_ENTRADA_MERCANCIA:
      return closeModalEntradaMercancia(state, action)
    case actionTypes.FILTRAR_ENTRADA_MERCANCIA_SUCCESS:
      return filtrarEntradaMercanciaSuccess(state, action)
    case actionTypes.FILTROS_ENTRADA_MERCANCIA_SUCCESS:
      return filtrosEntradaMercanciaSuccess(state, action)
    case actionTypes.RECUPERAR_DATOS_MODAL_ENTRADA_MERCANCIA_SUCCESS:
      return recuperarDatosModalEntradaMercanciaSuccess(state, action)
    case actionTypes.DUPLICAR_DATOS_MODAL_ENTRADA_MERCANCIA_SUCCESS:
      return duplicarDatosModalEntradaMercanciaSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_ENTRADA_MERCANCIA:
      return seleccionarTodosEntradaMercancia(state, action)
    case actionTypes.DESELECCIONAR_TODOS_ENTRADA_MERCANCIA:
      return deSeleccionarTodosEntradaMercancia(state, action)
    case actionTypes.CREATE_CSV_ENTRADA_MERCANCIA_SUCCESS:
      return crearCsvEntradaMercanciaSuccess(state, action)
    case actionTypes.IMPRIMIR_PDF_ENTRADA_MERCANCIA_SUCCESS:
      return imprimirPdfEntradaMercanciaSuccess(state, action)
    case actionTypes.GUARDAR_Y_NUEVO_ENTRADA_MERCANCIA_SUCCESS:
      return guardarYnuevoEntradaMercanciaSuccess(state, action)
    case actionTypes.ABRIR_MODAL_ENTRADA_MERCANCIA_ANADIR_PRODUCTO:
      return abrirModalEntradaMercanciaAnadirProducto(state, action)
    case actionTypes.CERRAR_MODAL_ENTRADA_MERCANCIA_ANADIR_PRODUCTO:
      return cerrarModalEntradaMercanciaAnadirProducto(state, action)
    case actionTypes.SELECCIONAR_TODOS_ENTRADA_MERCANCIA_PRODUCTO:
      return seleccionarTodosEntradaMercanciaProducto(state, action)
    case actionTypes.DESELECCIONAR_TODOS_ENTRADA_MERCANCIA_PRODUCTO:
      return deSeleccionarTodosEntradaMercanciaProducto(state, action)
    case actionTypes.ANADIR_PRODUCTO_ENTRADA_MERCANCIA:
      return anadirProductoEntradaMercancia(state, action)
    case actionTypes.RECUPERAR_MODAL_ENTRADA_MERCANCIA_PRODUCTO_ANADIDO_SUCCESS:
      return recuperarModalEntradaMercanciaProductoAnadidoSuccess(state, action)
    case actionTypes.EDITAR_ENTRADA_MERCANCIA_PRODUCTO_SUCCESS:
      return editarEntradaMercanciaProductoSuccess(state, action)
    case actionTypes.DUPLICAR_MODAL_ENTRADA_MERCANCIA_PRODUCTO_ANADIDO_SUCCESS:
      return duplicarDatosModalEntradaMercanciaAnadidoSuccess(state, action)
    case actionTypes.GUARDAR_Y_NUEVO_ENTRADA_MERCANCIA_PRODUCTO:
      return guardarYnuevoEntradaMercanciaProducto(state, action)
    case actionTypes.RESET_DATOS_ENTRADA_MERCANCIA_PRODUCTO:
      return resetDatosEntradaMercanciaProducto(state, action)
    case actionTypes.DELETE_SELECCION_ENTRADA_MERCANCIA_PRODUCTO_ANADIDO_SUCCESS:
      return deleteSeleccionEntradaMercanciaProductoAnadidoSuccess(state, action)
    case actionTypes.FETCH_LOTE_EXISTENTE_SUCCESS:
      return fetchLoteExistenteSuccess(state, action)
    default:
      return state
  }
}