import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalDireccionesCliente from '../../components/cliente/ModalDireccionesCliente'
import { comboUsuariosCentro, comboTipoDosis } from '../../actions/combos/combos'
import { closeModalDireccionesCliente, editarDireccionCliente, submitDireccionCliente, onDeleteRutaCliente} from '../../actions/cliente/cliente'

export function mapStateToProps (state) {
  return {
    ...state.cliente,
    combos: state.combos,
    formSimpleTable: state.form.simpleTable,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeModalDireccionesCliente,
      editarDireccionCliente,
      submitDireccionCliente,
      onDeleteRutaCliente
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalDireccionesCliente))