import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize} from 'redux-form'
import i18n from '../../i18n'
import actionTypes from '../../constants/actions/copiaSeguridad/copiaSeguridad'
import {fetchCopiasSeguridadSuccess, cerrarModalCopiaSeguridad, descargarCopiaSeguridadSuccess, obtenerProgramacionCopiaSeguridadSuccess, cerrarModalCopiaSeguridadProgramada, cerrarModalRestaurarConArchivo, cerrarModalRestaurarMigracion
  } from '../../actions/copiaSeguridad/copiaSeguridad'
import {openModal, closeModal, openSimpleModal, openMensajeAlerta} from '../../actions/common'
import { yesNoModal } from '../modal/yesNoModal'
import getCopiasSeguridad from '../../services/copiaSeguridad/getCopiasSeguridad'
import addCopiaSeguridad from '../../services/copiaSeguridad/addCopiaSeguridad'
import obtenerArchivoCopiaSeguridad from '../../services/copiaSeguridad/obtenerArchivoCopiaSeguridad'
import deleteCopiaSeguridad from '../../services/copiaSeguridad/deleteCopiaSeguridad'
import onDeleteSeleccionCopiaSeguridad from '../../services/copiaSeguridad/onDeleteSeleccionCopiaSeguridad'
import getRestaurarCopiaSeguridad from '../../services/copiaSeguridad/getRestaurarCopiaSeguridad'
import getProgramacionCopiaSeguridad from '../../services/copiaSeguridad/getProgramacionCopiaSeguridad'
import addCopiaSeguridadProgramada from '../../services/copiaSeguridad/addCopiaSeguridadProgramada'
import uploadCopiaSeguridad from '../../services/copiaSeguridad/uploadCopiaSeguridad'
import uploadCopiaSeguridadMigracion from '../../services/copiaSeguridad/uploadCopiaSeguridadMigracion'

export function * fetchCopiasSeguridad () {
  try {
    const listCopiaSeguridad = yield call(getCopiasSeguridad, null)
    yield put(fetchCopiasSeguridadSuccess(listCopiaSeguridad))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchFetchCopiasSeguridad () {
  yield takeLatest(actionTypes.FETCH_COPIAS_SEGURIDAD, fetchCopiasSeguridad)
}

export function * submitNuevaCopiaSeguridad ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const insertarCopia = yield call(addCopiaSeguridad, values, auth.token)
    if (insertarCopia.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
      const listCopiaSeguridad = yield call(getCopiasSeguridad, null)
      yield put(fetchCopiasSeguridadSuccess(listCopiaSeguridad))
      if (onSuccessCallback) yield onSuccessCallback()
      yield put(cerrarModalCopiaSeguridad())
      yield put(initialize('ModalCopiaSeguridad', {}))
    } else {
      yield put(openMensajeAlerta('no-insercion'))
      if (onErrorCallback) yield onErrorCallback()
    }
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchSubmitNuevaCopiaSeguridad () {
  yield takeLatest(actionTypes.SUBMIT_NUEVA_COPIA_SEGURIDAD, submitNuevaCopiaSeguridad)
}

export function * descargarCopiaSeguridad ({idCopiaSeguridad}) {
  try {
    const archivoCopiaSeguridad = yield call(obtenerArchivoCopiaSeguridad, idCopiaSeguridad, null)
    yield put(descargarCopiaSeguridadSuccess(archivoCopiaSeguridad))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchDescargarCopiaSeguridad () {
  yield takeLatest(actionTypes.DESCARGAR_COPIA_SEGURIDAD, descargarCopiaSeguridad)
}

export function * onDeleteCopiaSeguridad ({idCopiaSeguridad}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      const eliminacionCopiaSeguridad = yield call(deleteCopiaSeguridad, idCopiaSeguridad, null)
      if (eliminacionCopiaSeguridad.eliminado === true && eliminacionCopiaSeguridad.asociado === false) {
        yield put(openMensajeAlerta('eliminacion'))
        const listCopiaSeguridad = yield call(getCopiasSeguridad, null)
        yield put(fetchCopiasSeguridadSuccess(listCopiaSeguridad))
      } else if (eliminacionCopiaSeguridad.eliminado === false && eliminacionCopiaSeguridad.asociado === true) {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchOnDeleteCopiaSeguridad () {
  yield takeLatest(actionTypes.ON_DELETE_COPIA_SEGURIDAD, onDeleteCopiaSeguridad)
}

export function * deleteSeleccionCopiaSeguridad ({list}) {
  try {
    let auth = yield select(state => state.auth)
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      const eliminacionCopiaSeguridad = yield call(onDeleteSeleccionCopiaSeguridad, list, auth.token)
      if (eliminacionCopiaSeguridad.eliminado === true && eliminacionCopiaSeguridad.asociado === false) {
        yield put(openMensajeAlerta('eliminacion'))
        const listCopiaSeguridad = yield call(getCopiasSeguridad, null)
        yield put(fetchCopiasSeguridadSuccess(listCopiaSeguridad))
      } else if (eliminacionCopiaSeguridad.eliminado === false && eliminacionCopiaSeguridad.asociado === true) {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(initialize('simpleTable', {}))
      yield put(initialize('FiltrosCopiaSeguridad', {}))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchDeleteSeleccionCopiaSeguridad () {
  yield takeLatest(actionTypes.DELETE_SELECCION_COPIA_SEGURIDAD, deleteSeleccionCopiaSeguridad)
}

export function * restaurarCopiaSeguridad ({idCopiaSeguridad}) {
  try {
    yield put(openSimpleModal('copiaSeguridad'))
    const confirmed = yield call(yesNoModal, {modalType: 'copiaSeguridad'})
    if (confirmed) {
      const restaurarCopia = yield call(getRestaurarCopiaSeguridad, idCopiaSeguridad, null)
      if (restaurarCopia.actualizado === true) {
        yield put(openMensajeAlerta('modificacion'))
      } else {
        yield put(openMensajeAlerta('no-modificacion'))
      }
      const listCopiaSeguridad = yield call(getCopiasSeguridad, null)
      yield put(fetchCopiasSeguridadSuccess(listCopiaSeguridad))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchRestaurarCopiaSeguridad () {
  yield takeLatest(actionTypes.RESTAURAR_COPIA_SEGURIDAD, restaurarCopiaSeguridad)
}

export function * obtenerProgramacionCopiaSeguridad () {
  try {
    const copiaProgramada = yield call(getProgramacionCopiaSeguridad , null)
    yield put(obtenerProgramacionCopiaSeguridadSuccess(copiaProgramada))
    if (copiaProgramada.diasSemana !== null) {
      copiaProgramada.diasSemana.filter((dia) => {
        if (dia === 1){
          copiaProgramada.lunes = true
        }
        if (dia === 2){
          copiaProgramada.martes = true
        }
        if (dia === 3){
          copiaProgramada.miercoles = true
        }
        if (dia === 4){
          copiaProgramada.jueves = true
        }
        if (dia === 5){
          copiaProgramada.viernes = true
        }
        if (dia === 6){
          copiaProgramada.sabado = true
        }
        if (dia === 0){
          copiaProgramada.domingo = true
        }
      })
    }
    yield put(initialize('ModalCopiaSeguridadProgramada', copiaProgramada))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchObtenerProgramacionCopiaSeguridad () {
  yield takeLatest(actionTypes.OBTENER_PROGRAMACION_COPIA_SEGURIDAD, obtenerProgramacionCopiaSeguridad)
}

export function * submitNuevaCopiaSeguridadProgramada ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const insertarCopiaProgramada = yield call(addCopiaSeguridadProgramada , values, auth.token)
    if (insertarCopiaProgramada.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
      const listCopiaSeguridad = yield call(getCopiasSeguridad, null)
      yield put(fetchCopiasSeguridadSuccess(listCopiaSeguridad))
      if (onSuccessCallback) yield onSuccessCallback()
      yield put(cerrarModalCopiaSeguridadProgramada())
      yield put(initialize('ModalCopiaSeguridad', {}))
    } else {
      yield put(openMensajeAlerta('no-insercion'))
      if (onErrorCallback) yield onErrorCallback()
    }
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchObtenerProgramacionCopiaSeguridadProgramada () {
  yield takeLatest(actionTypes.SUBMIT_NUEVA_COPIA_SEGURIDAD_PROGRAMADA, submitNuevaCopiaSeguridadProgramada)
}

export function * submitCopiaSeguridadDesdeArchivo ({archivos}) {
  try {
    let auth = yield select(state => state.auth)
    yield put(openSimpleModal('copiaSeguridad'))
    const confirmed = yield call(yesNoModal, {modalType: 'copiaSeguridad'})
    if (confirmed) {
      const insertarCopiaProgramada = yield call(uploadCopiaSeguridad , {copiaSeguridad: archivos}, auth.token)
      yield put(cerrarModalRestaurarConArchivo())
      if (insertarCopiaProgramada.actualizado === true) {
        yield put(openMensajeAlerta('modificacion'))
      } else {
        yield put(openMensajeAlerta('no-modificacion'))
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchSubmitCopiaSeguridadDesdeArchivo () {
  yield takeLatest(actionTypes.SUBMIT_COPIA_SEGURIDAD_DESDE_ARCHIVO, submitCopiaSeguridadDesdeArchivo)
}

export function * submitRestaurarMigracion ({archivos}) {
  try {
    let auth = yield select(state => state.auth)
    yield put(openSimpleModal('copiaSeguridad'))
    const confirmed = yield call(yesNoModal, {modalType: 'copiaSeguridad'})
    if (confirmed) {
      const insertarCopiaMigracion = yield call(uploadCopiaSeguridadMigracion , {copiaSeguridad: archivos}, auth.token)
      yield put(cerrarModalRestaurarMigracion())
      if (insertarCopiaMigracion.actualizado === true) {
        yield put(openMensajeAlerta('modificacion'))
      } else {
        yield put(openMensajeAlerta('no-modificacion'))
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchSubmitSubmitRestaurarMigracion () {
  yield takeLatest(actionTypes.SUBMIT_RESTAURAR_MIGRACION, submitRestaurarMigracion)
}
