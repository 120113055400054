import actionTypes from '../../constants/actions/pedidosVenta/pedidosVenta'

export function initialState () {
  return {
    showModalPedidosVenta: false,
    listPedidosVenta: [],
    filtros: [],
    checkedPedidosVenta: false,
    listPedidosAnadidos: [],
    showModalDatosDosis: false,
    dataPedidosVenta: [],
    dataPedidosVentaDuplicar: [],
    checkedModalPedidos: false,
    comboDireccionesCliente: [],
    data: [],
    dataDuplicar: [],
    comboVerracoPedido: [],
    showModalDetallePedido: false,
    numeroPedido: '',
    comboDireccionesCliente: [],
    comboRutaEnPedido: []
  }
}

export function abrirModalPedidosVenta (state) {
  return {
    ...state,
    showModalPedidosVenta: true
  }
}

export function cerrarModalPedidosVenta (state) {
  return {
    ...state,
    showModalPedidosVenta: false,
    listPedidosAnadidos: [],
    data: [],
    dataDuplicar: [],
    comboDireccionesCliente: []
  }
}

export function abrirModalDatosDosis (state) {
  return {
    ...state,
    showModalDatosDosis: true
  }
}

export function cerrarModalDatosDosis (state) {
  return {
    ...state,
    showModalDatosDosis: false,
    dataPedidosVenta: [],
    dataPedidosVentaDuplicar: []
  }
}

export function anadirDatosDosis (state, {listPedidosAnadidos}) {
  return {
    ...state,
    listPedidosAnadidos: state.listPedidosAnadidos.concat(listPedidosAnadidos),
    dataPedidosVenta: [],
    dataPedidosVentaDuplicar: []
  }
}

export function recuperarDatosModalDatosDosisSuccess (state, {dataPedidosVenta}) {
  return {
    ...state,
    dataPedidosVenta: dataPedidosVenta,
    dataPedidosVentaDuplicar: []
  }
}

export function editarDatosDosisSuccess (state, {listPedidosAnadidos}) {
  return {
    ...state,
    listPedidosAnadidos: listPedidosAnadidos,
    dataPedidosVenta: [],
    dataPedidosVentaDuplicar: [],
    checkedModalPedidos: false
  }
}

export function duplicarDatosModalDatosDosisSuccess (state, {dataPedidosVentaDuplicar}) {
  return {
    ...state,
    dataPedidosVentaDuplicar: dataPedidosVentaDuplicar,
    dataPedidosVenta: []
  }
}

export function seleccionarTodosModalPedidos (state) {
  return {
    ...state,
    checkedModalPedidos: true
  }
}

export function deSeleccionarTodosModalPedidos (state) {
  return {
    ...state,
    checkedModalPedidos: false
  }
}

export function fetchPedidosVentaSuccess (state, {listPedidosVenta}) {
  return {
    ...state,
    listPedidosVenta: listPedidosVenta
  }
}

export function fetchFiltrarPedidosVentaSuccess (state, {filtros}) {
  return {
    ...state,
    filtros: filtros
  }
}

export function elegirClientePedidoVentaSuccess (state, {comboDireccionesCliente}) {
  return {
    ...state,
    comboDireccionesCliente: comboDireccionesCliente
  }
}

export function recuperarDatosModalPedidosVentaSuccess (state, {data}) {
  return {
    ...state,
    data: data,
    dataDuplicar: []
  }
}

export function duplicarDatosModalPedidosVentaSuccess (state, {dataDuplicar}) {
  return {
    ...state,
    dataDuplicar: dataDuplicar,
    data: []
  }
}

export function seleccionarTodosPedidosVenta (state) {
  return {
    ...state,
    checkedPedidosVenta: true
  }
}

export function deseleccionarTodosPedidosVenta (state) {
  return {
    ...state,
    checkedPedidosVenta: false
  }
}

export function exportarPedidosVentaSuccess (state, {datosExcelPedidoVenta}) {
  return {
    ...state,
    datosExcelPedidoVenta
  }
}

export function imprimirPedidosVentaSuccess (state, {datosPdfPedidoVenta}) {
  return {
    ...state,
    datosPdfPedidoVenta
  }
}

export function verracosPedidoSuccess (state, {comboVerracoPedido}) {
  return {
    ...state,
    comboVerracoPedido
  }
}

export function abrirDetallesPedido (state) {
  return {
    ...state,
    showModalDetallePedido: true
  }
}

export function cerrarDetallesPedido (state) {
  return {
    ...state,
    showModalDetallePedido: false,
    data: [],
    dataDuplicar: [],
    listPedidosAnadidos: []
  }
}

export function obtenerNumeroPedidoSuccess (state, {numeroPedido}) {
  return {
    ...state,
    numeroPedido: numeroPedido.numeroPedido
  }
}

export function elegirDireccionClientePedidoVentaSuccess (state, {comboRutaEnPedido}) {
  return {
    ...state,
    comboRutaEnPedido: comboRutaEnPedido
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.ABRIR_MODAL_PEDIDOS_VENTA:
      return abrirModalPedidosVenta(state, action)
    case actionTypes.CERRAR_MODAL_PEDIDOS_VENTA:
      return cerrarModalPedidosVenta(state, action)
    case actionTypes.ABRIR_MODAL_DATOS_DOSIS:
      return abrirModalDatosDosis(state, action)
    case actionTypes.CERRAR_MODAL_DATOS_DOSIS:
      return cerrarModalDatosDosis(state, action)
    case actionTypes.ANADIR_DATOS_DOSIS:
      return anadirDatosDosis(state, action)
    case actionTypes.RECUPERAR_DATOS_MODAL_DATOS_DOSIS_SUCCESS:
      return recuperarDatosModalDatosDosisSuccess(state, action)
    case actionTypes.EDITAR_DATOS_DOSIS_SUCCESS:
      return editarDatosDosisSuccess(state, action)
    case actionTypes.DUPLICAR_DATOS_MODAL_DATOS_DOSIS_SUCCESS:
      return duplicarDatosModalDatosDosisSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_MODAL_PEDIDOS:
      return seleccionarTodosModalPedidos(state, action)
    case actionTypes.DESELECCIONAR_TODOS_MODAL_PEDIDOS:
      return deSeleccionarTodosModalPedidos(state, action)
    case actionTypes.FETCH_FILTRAR_PEDIDOS_VENTA_SUCCESS:
      return fetchFiltrarPedidosVentaSuccess(state, action)
    case actionTypes.FETCH_PEDIDOS_VENTA_SUCCESS:
      return fetchPedidosVentaSuccess(state, action)
    case actionTypes.ELEGIR_CLIENTE_PEDIDOS_VENTA_SUCCESS:
      return elegirClientePedidoVentaSuccess(state, action)
    case actionTypes.RECUPERAR_DATOS_MODAL_PEDIDOS_VENTA_SUCCESS:
      return recuperarDatosModalPedidosVentaSuccess(state, action)
    case actionTypes.DUPLICAR_DATOS_MODAL_PEDIDOS_VENTA_SUCCESS:
      return duplicarDatosModalPedidosVentaSuccess(state, action)
    case actionTypes.SELECCIONAR_TODOS_PEDIDOS_VENTA:
      return seleccionarTodosPedidosVenta(state, action)
    case actionTypes.DESELECCIONAR_TODOS_PEDIDOS_VENTA:
      return deseleccionarTodosPedidosVenta(state, action)
    case actionTypes.EXPORTAR_PEDIDOS_VENTA_SUCCESS:
      return exportarPedidosVentaSuccess(state, action)
    case actionTypes.IMPRIMIR_PEDIDOS_VENTA_SUCCESS:
      return imprimirPedidosVentaSuccess(state, action)
    case actionTypes.VERRACOS_PEDIDO_SUCCESS:
      return verracosPedidoSuccess(state, action)
    case actionTypes.ABRIR_DETALLES_PEDIDO_SUCCESS:
      return abrirDetallesPedido(state, action)
    case actionTypes.CERRAR_DETALLES_PEDIDO_SUCCESS:
      return cerrarDetallesPedido(state, action)
    case actionTypes.OBTENER_NUMERO_PEDIDO_SUCCESS:
      return obtenerNumeroPedidoSuccess(state, action)
    case actionTypes.ELEGIR_DIRECCION_CLIENTE_PEDIDOS_VENTA_SUCCESS:
      return elegirDireccionClientePedidoVentaSuccess(state, action)
    default:
      return state
  }
}