import React, { Component } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { required } from '../../util/validationFunctions'
import Button from '../comun/button/Button'
import ButtonLockable from '../comun/button/ButtonLockable'
import InputText from '../comun/form/InputText'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputSelect from '../comun/form/InputSelect'
import InputNumerical from '../comun/form/InputNumerical'
import './PanelControl.scss'
import ListadoPage from '../../containers/page/ListadoPage'
import { Delete, AddCircleOutline } from '@material-ui/icons'
import ModalAnadirMuestrasPage from '../../containers/panelControl/ModalAnadirMuestrasPage'

class ModalAnadirMuestras extends Component {

  anadirMuestra (values) {
    return new Promise((resolve, reject) => {
      if (Object.keys(this.props.muestrasRecuperar).length > 0){
        this.props.actions.editMuestrasAnadidas(values, resolve, reject)
      } else {
        this.props.actions.anadirMuestra(values, resolve, reject)
      }
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  guardarYnuevoMuestras (values) {
    return new Promise((resolve, reject) => {
      this.props.actions.guardarYnuevoMuestras(values, resolve, reject)
      this.props.initialize('ModalAnadirMuestras')
      reject()
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  render () {
    const {
      t, handleSubmit, submitting, showModalAnadirMuestra,
      actions: {cerrarModalAnadirMuestras}
    } = this.props

    return (
      <Modal show={showModalAnadirMuestra} onHide={cerrarModalAnadirMuestras} aria-labelledby="contained-modal-title-lg" className="modal-anadir-muestras" backdrop="static">
      <form className="form-anadir-colorimetro">
        <Modal.Header closeButton={!submitting}>
          <Modal.Title>
          {
            Object.keys(this.props.muestrasRecuperar).length > 0 ? (
              t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.TITLE_EDITAR')
            ) : (
              Object.keys(this.props.muestrasDuplicar).length > 0 ? (
                t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.TITLE_DUPLICAR')
              ) : (
                t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.TITLE_NUEVO')
              )
            )
          }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Field
              colSm={6}
              id="absorbancia"
              name="absorbancia"
              controlLabel={t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.ABSORBANCIA')}
              component={InputNumerical}
              numDecimales={3}
              customClass='absorbancia'
              validate={required}
            />
            <Field
              colSm={6}
              id="concentracionEyaculado"
              name="concentracionEyaculado"
              controlLabel={t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.MODAL_ANADIR_COLORIMETRO.CONCENTRACION_EYACULADO')}
              component={InputNumerical}
              numDecimales={4}
              customClass='concentracion-eyaculado'
              validate={required}
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {
            Object.keys(this.props.muestrasRecuperar).length === 0 ? (
              <ButtonLockable
                processing={submitting}
                hasIcon={true}
                showLoaderOnClick={true}
                type="button"
                className="btn btn-primary btn-sm"
                disabled={submitting}
                onClick={!submitting ? handleSubmit(this.guardarYnuevoMuestras.bind(this)) : () => {}}
              >
                {t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.GUARDAR_Y_NUEVO')}
              </ButtonLockable>
            ) : null
          }
          <ButtonLockable
            processing={submitting}
            hasIcon={true}
            showLoaderOnClick={true}
            type="button"
            className="btn btn-primary btn-sm"
            disabled={submitting}
            onClick={!submitting ? handleSubmit(this.anadirMuestra.bind(this)) : () => {}}
          >
            {t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.GUARDAR')}
          </ButtonLockable>
          <Button
            type="button"
            className={"btn btn-white btn-sm" + (submitting ? ' disabled' : '')}
            onClick={() => !submitting && cerrarModalAnadirMuestras()}
          >
            {t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.CERRAR')}
          </Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalAnadirMuestras',
})(ModalAnadirMuestras))