import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Modal, Row, Col } from 'react-bootstrap'
import { Warning } from '@material-ui/icons'
import Button from '../comun/button/Button'
import ButtonLockable from '../comun/button/ButtonLockable'
import ButtonChangeRecord from '../comun/button/ButtonChangeRecord'
import Loader from '../comun/loader/Loader'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputNumerical from '../comun/form/InputNumerical'
import InputSelect from '../comun/form/InputSelect'
import InputTextArea from '../comun/form/InputTextArea'
import ModalTrazabilidadMaterialPage from '../../containers/trazabilidadMaterial/ModalTrazabilidadMaterialPage'
import { date_formatter } from '../../util/formatFunctions'
import {categorias as categoriasEscandallos} from '../../constants/escandallo'
import {estadoMaquinaDilucion, translateEstadoMaquinaDilucion} from '../../constants/maquinas'
import {permisos as permisosConstant} from '../../constants/permisos'
import {modulos as modulosConstant} from '../../constants/modulos'

class ModalDilucion extends Component {
  constructor (props) {
    super()
    this.state = {
      diluido: false,
      dilucionEliminada: false
    }
    this.intercambiarCheckDiluido = this.intercambiarCheckDiluido.bind(this)
    this.handleOpenModalTrazabilidadMaterial = this.handleOpenModalTrazabilidadMaterial.bind(this)
    this.prepararMaquinaDilucion = this.prepararMaquinaDilucion.bind(this)
    this.cancelarMaquinaDilucion = this.cancelarMaquinaDilucion.bind(this)
    this.editarDilucion = this.editarDilucion.bind(this)
  }

  componentDidMount () {
    this.props.actions.comboMaquina(2)
    this.props.actions.comboOperarioPredeterminado()
    this.props.actions.fetchComboTipoDiluyente()

    if (this.props.data && this.props.data.diluido) {
      this.setState({diluido: this.props.data.diluido})
    }
    if (this.props.data && this.props.data.dilucionEliminada) {
      this.setState({dilucionEliminada: this.props.data.dilucionEliminada})
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.formModalDilucion && this.props.formModalDilucion.diluido !== this.state.diluido) {
      this.setState({diluido: this.props.formModalDilucion.diluido})
    }
    if (this.props.showModalDilucion && this.props.showModalDilucion !== prevProps.showModalDilucion) {
      if (this.props.data && this.props.data.diluido !== this.state.diluido) {
        this.setState({diluido: this.props.data.diluido})
      }
      if (this.props.data && this.props.data.dilucionEliminada !== this.state.dilucionEliminada) {
        this.setState({dilucionEliminada: this.props.data.dilucionEliminada})
      }
    } else if (!this.props.showModalDilucion && this.props.showModalDilucion !== prevProps.showModalDilucion) {
      this.setState({diluido: false, dilucionEliminada: false})
    }
  }

  editarDilucion (values) {
    values.nombreDiluyente = values.idTipoDiluyente !== null ? values.idTipoDiluyente.label : null
    values.nombreMaquina = values.idOperario !== null ? values.idOperario.label : null
    values.nombreOperario = values.idMaquina !== null ? values.idMaquina.label : null

    return new Promise((resolve, reject) => {
      this.props.actions.editarDilucion(values, resolve, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  intercambiarCheckDilucionEliminada (value) {
    let newState = {
      dilucionEliminada: value,
      diluido: value
    }
    this.setState(newState)

    if (value === true) {
      this.props.change('diluido', true)
    } else {
      this.props.change('diluido', false)
    }
  }

  intercambiarCheckDiluido (value) {
    const hasDiluyente = (!!this.props.data.volumenDiluyente && !isNaN(parseFloat(this.props.data.volumenDiluyente)) &&
      isFinite(parseFloat(this.props.data.volumenDiluyente)) && parseFloat(this.props.data.volumenDiluyente) > 0)

    if (!hasDiluyente) {
      this.props.change('diluido', false)
      this.props.actions.yesNoModal('volumenDiluyenteRequerido')
    } else {
      this.setState({diluido: value})
    }
  }

  handleOpenModalTrazabilidadMaterial () {
    if (this.props.data.idTipoDosis) {
      this.props.actions.openModalTrazabilidadMaterial({
        idTipoDosis: this.props.data.idTipoDosis,
        idCategoria: categoriasEscandallos.DILUCION,
        idAnalisis: this.props.data.idAnalisis,
        ultimaModificacion: this.props.data.ultimaModificacion,
        disabled: this.props.data.envasado,
        showStockColumns: !this.props.data.diluido,
        utilizarLotesPreferidosDelUsuario: this.props.utilizarLotesPreferidosDelUsuario
      })
    }
  }

  prepararMaquinaDilucion (values) {
    const hasDiluyente = (!!this.props.data.volumenDiluyente && !isNaN(parseFloat(this.props.data.volumenDiluyente)) &&
      isFinite(parseFloat(this.props.data.volumenDiluyente)) && parseFloat(this.props.data.volumenDiluyente) > 0)

    values.nombreDiluyente = values.idTipoDiluyente !== null ? values.idTipoDiluyente.label : null
    values.nombreMaquina = values.idMaquina !== null ? values.idMaquina.label : null
    values.nombreOperario = values.idOperario !== null ? values.idOperario.label : null

    return new Promise((resolve, reject) => {
      if (!hasDiluyente) {
        this.props.actions.yesNoModal('volumenDiluyenteRequerido')
        reject()
      } else if (!values.idTipoDiluyente || !values.idTipoDiluyente.value) {
        this.props.actions.yesNoModal('diluyenteRequerido')
        reject()
      } else if (!values.idMaquina || !values.idMaquina.value) {
        this.props.actions.yesNoModal('maquinaRequerida')
        reject()
      } else {
        this.props.actions.prepararMaquinaDilucion(values, resolve, reject)
      }
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  cancelarMaquinaDilucion () {
    this.props.actions.cancelarMaquinaDilucion()
  }

  render () {
    const {
      t, handleSubmit, submitting, showModalDilucion, list, data: {envasado, stockInsuficiente, idDilucion, codigo, codVerraco, fechaExtraccion}, codigoEstadoMaquinaDilucion,
      actions: {abrirDetalles, cerrarDetalles}, combos: {comboMaquina, comboOperarioPredeterminado, comboTipoDiluyente}, auth: {modulos},
      auth: {formaFechaHora}
    } = this.props

    const diluyendo = false
    // const diluyendo = ( codigoEstadoMaquinaDilucion !== '' &&
    //   codigoEstadoMaquinaDilucion !== estadoMaquinaDilucion['EN_ESPERA_RETIRAR_RECIPIENTE'] &&
    //   codigoEstadoMaquinaDilucion !== estadoMaquinaDilucion['FINALIZADO'] &&
    //   codigoEstadoMaquinaDilucion !== estadoMaquinaDilucion['NO_PREPARADA']
    // )

    return (
      <Modal show={showModalDilucion} onHide={cerrarDetalles} aria-labelledby="contained-modal-title-lg" backdrop="static">
      <form className="form-dilucion">
        <Modal.Header closeButton={!diluyendo && !submitting}>
          <Modal.Title>
            {t('EXTRACCIONES.DILUCION.MODAL.REALIZAR_DILUCION')}
          </Modal.Title>
          {idDilucion && !diluyendo && !submitting && (
            <ButtonChangeRecord list={list} idElemento="idDilucion" currentId={idDilucion} getNextRecord={abrirDetalles} />
          )}
        </Modal.Header>
        <Modal.Body>
          <ModalTrazabilidadMaterialPage />
          <Row>
          <Col sm={12}><p><b>{t('EXTRACCIONES.DILUCION.MODAL.EXTRACCION') + ': ' + codigo + ' - ' + date_formatter(fechaExtraccion, formaFechaHora, false) + ' - ' + codVerraco}</b></p></Col>
          <div className="clearfix"></div>
          <Field
            colSm={4}
            id="numeroDosis"
            name="numeroDosis"
            controlLabel={t('EXTRACCIONES.DILUCION.MODAL.NUMERO_DOSIS')}
            component={InputNumerical}
            numDecimales={0}
            disabled={true}
          />
          <Field
            colSm={4}
            id="volumenExtraccion"
            name="volumenExtraccion"
            controlLabel={t('EXTRACCIONES.DILUCION.MODAL.VOL_EYACULADO')}
            component={InputNumerical}
            numDecimales={2}
            disabled={true}
          />
          <Field
            colSm={4}
            id="volumenDiluyente"
            name="volumenDiluyente"
            controlLabel={t('EXTRACCIONES.DILUCION.MODAL.VOL_DILUYENTE')}
            component={InputNumerical}
            numDecimales={2}
            disabled={true}
          />
          <Field
            colSm={8}
            id="idTipoDiluyente"
            name="idTipoDiluyente"
            controlLabel={t('EXTRACCIONES.DILUCION.MODAL.TIPO_DILUYENTE')}
            component={InputSelect}
            options={comboTipoDiluyente}
            valueKey="value"
            labelKey="label"
            customClass='select-tipo-diluyente'
            isClearable={true}
            disabled={diluyendo || envasado || this.state.dilucionEliminada}
          />
          <div className="clearfix"></div>
          <Col sm={8}>
            <p>{t('EXTRACCIONES.DILUCION.MODAL.AVISO_DILUYENTE')}</p>
          </Col>
          <Field
            colSm={6}
            id="idMaquina"
            name="idMaquina"
            controlLabel={t('EXTRACCIONES.DILUCION.MODAL.MAQUINA')}
            component={InputSelect}
            options={comboMaquina}
            valueKey="value"
            labelKey="label"
            customClass='select-maquina'
            isClearable={true}
            disabled={diluyendo || envasado || this.state.dilucionEliminada}
          />
          <Field
            colSm={6}
            id="idOperario"
            name="idOperario"
            controlLabel={t('EXTRACCIONES.DILUCION.MODAL.OPERARIO')}
            component={InputSelect}
            options={comboOperarioPredeterminado}
            valueKey="value"
            labelKey="label"
            customClass='select-operario'
            isClearable={false}
            disabled={diluyendo || envasado || this.state.dilucionEliminada}
          />
          <Field
            id="diluido"
            name="diluido"
            colSm={12}
            inline
            component={InputCheckBox}
            claseActivo="onoffswitch-label"
            controlLabel={t('EXTRACCIONES.DILUCION.MODAL.DILUIDO')}
            disabled={diluyendo || envasado || this.state.dilucionEliminada}
            onInputChange={(value) => this.intercambiarCheckDiluido(value)}
          />
          <Field
            id="dilucionEliminada"
            name="dilucionEliminada"
            colSm={12}
            inline
            component={InputCheckBox}
            claseActivo="onoffswitch-label"
            controlLabel={t('EXTRACCIONES.DILUCION.MODAL.DILUCION_ELIMINADA')}
            disabled={diluyendo || envasado}
            onInputChange={(value) => this.intercambiarCheckDilucionEliminada(value)}
          />
          <div className="clearfix"></div>
          <Field
            colSm={12}
            id="observacionesDilucion"
            name="observacionesDilucion"
            controlLabel={t('EXTRACCIONES.DILUCION.MODAL.OBSERVACIONES')}
            component={InputTextArea}
            disabled={diluyendo}
            />
          <div className="clearfix"></div>
          {this.state.dilucionEliminada && (
            <Field
              colSm={12}
              id="observacionesDilucionEliminada"
              name="observacionesDilucionEliminada"
              controlLabel={t('EXTRACCIONES.DILUCION.MODAL.OBSERVACIONES_ELIMINADO')}
              component={InputTextArea}
              disabled={diluyendo || envasado}
            />
          )}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className="row-flex">
            <div className="col-flex text-left bold">
              {diluyendo && (<Loader />)}
              {codigoEstadoMaquinaDilucion ? t('COMUN.ESTADO_MAQUINA.' + translateEstadoMaquinaDilucion[codigoEstadoMaquinaDilucion]) : ''}
            </div>
            <Col sm={diluyendo ? 8 : 7}>
              {modulos.includes(modulosConstant.TRAZABILIDAD_BIOSEGURIDAD) && (
                <Button
                  type="button"
                  className={'btn btn-primary' + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.DILUCION] < 2) || diluyendo || this.state.dilucionEliminada || submitting ? ' disabled' : '')}
                  onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.DILUCION] > 1) && !diluyendo && !this.state.dilucionEliminada && !submitting ? this.handleOpenModalTrazabilidadMaterial : null}
                >
                  {stockInsuficiente && (<span className='help-block white'><Warning /></span>)} {t('EXTRACCIONES.DILUCION.MODAL.TRAZABILIDAD')}
                </Button>
              )}
              <Button
                type="button"
                className={'btn btn-primary' + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.DILUCION] < 2) || this.state.diluido || this.state.dilucionEliminada || envasado || submitting ? ' disabled' : '')}
                onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.DILUCION] > 1) && !this.state.diluido && !this.state.dilucionEliminada && !envasado && !submitting ? (!diluyendo ? handleSubmit(this.prepararMaquinaDilucion) : this.cancelarMaquinaDilucion) : null}
              >
                {diluyendo ? t('EXTRACCIONES.DILUCION.MODAL.PARAR_DILUCION') : t('EXTRACCIONES.DILUCION.MODAL.DILUIR')}
              </Button>
              <ButtonLockable
                processing={submitting}
                hasIcon={true}
                showLoaderOnClick={true}
                type="button"
                className="btn btn-primary"
                disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.DILUCION] < 2) || diluyendo || envasado || submitting}
                onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.DILUCION] > 1) && !diluyendo && !envasado && !submitting) ? handleSubmit(this.editarDilucion) : null}
              >{t('EXTRACCIONES.DILUCION.MODAL.GUARDAR')}</ButtonLockable>
              <Button type="button" className="btn btn-white" disabled={diluyendo || submitting} onClick={cerrarDetalles}>{t('EXTRACCIONES.DILUCION.MODAL.CANCELAR')}</Button>
            </Col>
          </Row>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalDilucion'
})(ModalDilucion))