import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import ListadoPage from '../../containers/page/ListadoPage'
import ModalCentroPage from '../../containers/empresa/ModalCentroPage'
import SimpleModalPage from '../../containers/comun/modal/SimpleModalPage'
import MensajeAlertaPage from '../../containers/comun/mensajeAlerta/MensajeAlertaPage'
import './Empresa.scss'
import { date_parser } from '../../util/formatFunctions'
import Button from '../comun/button/Button'
import ButtonLockable from '../comun/button/ButtonLockable'
import InputText from '../comun/form/InputText'
import InputCheckBox from '../comun/form/InputCheckBox'
import {Delete, AddCircleOutline, Refresh, Launch, Print} from '@material-ui/icons'
import {permisos as permisosConstant} from '../../constants/permisos'

class Empresa extends Component {
  constructor () {
    super()
    this.state = {
      imputSelected: false
    }
  }

  intercambiarCheck = (value) => {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosEmpresa()
    } else {
      this.props.actions.deSeleccionarTodosEmpresa()
    }
  }

  componentDidMount () {
    document.title = this.props.t('MENU.SISTEMA.EMPRESA_CENTROS') + ' - ' + this.props.t('HEADER.TITLE_GESIPOR')
    this.props.actions.fetchEmpresa()
    this.props.actions.comprobarEntorno()
  }

  componentDidUpdate (prevProps) {
    if (this.props.datosExcelEmpresa !== prevProps.datosExcelEmpresa) {
      if (this.props.datosExcelEmpresa.excel && this.props.datosExcelEmpresa.excel !== null){
        let pdf = this.props.datosExcelEmpresa.excel
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/vnd.ms-excel;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.SISTEMA.EMPRESA_CENTROS') + ".xlsx")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
    if (this.props.datosPdfEmpresa !== prevProps.datosPdfEmpresa) {
      if (this.props.datosPdfEmpresa.pdf && this.props.datosPdfEmpresa.pdf !== null){
        let pdf = this.props.datosPdfEmpresa.pdf
        let element = document.createElement('a')
        element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(pdf))
        element.setAttribute('download', this.props.t('MENU.SISTEMA.EMPRESA_CENTROS') + ".pdf")
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable !== prevProps.formSimpleTable) {
        let contActivos = 0
        if (this.props.formSimpleTable.values) {
          Object.values(this.props.formSimpleTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todos-empresa', false)
            this.setState({ imputSelected: false })
            this.props.actions.deSeleccionarTodosEmpresa()
          }
        }
      }
    }
  }

  editarEmpresa (values) {
    return new Promise((resolve, reject) => {
      this.props.actions.editarEmpresa(values, resolve, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  deleteSeleccionCentros () {
    const itemsSeleccion = this.props.listCentros.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idCentro': row.idCentro,
          'idElemento': row.idElemento
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    })
    this.props.actions.deleteSeleccionCentros(list)
    this.props.actions.deSeleccionarTodosEmpresa()
  }

  cambioEstadoSeleccionCentros () {
    const itemsSeleccion = this.props.listCentros.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idCentro': row.idCentro,
          'idElemento': row.idElemento
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    })
    this.props.actions.cambioEstadoSeleccionCentros(list)
    this.props.actions.deSeleccionarTodosEmpresa()
  }

  crearCsvEmpresa () {
    this.props.actions.crearCsvEmpresa()
  }

  imprimirPdfEmpresa () {
    this.props.actions.imprimirPdfEmpresa()
  }

  render () {
    const {
      t, handleSubmit, submitting, numeroRegistrosPorPagina, paginaActual, listEmpresa, listCentros, checkCentros, dataCentro, dataCentroDuplicar,
      auth: {isCloud},
      actions: {abrirModalCentro, recuperarDatosCentro, duplicarCentro, cambiarEstadoCentro, eliminarCentro}
    } = this.props

    listCentros.forEach(
      (row) => row.idElemento = row.idCentro
    )

    const table = {
      id: 'empresaTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.EMPRESA_CENTROS] > 1) && !submitting,
      hasOptions: !submitting,
      columns: [
        {id: 'idCentro', name: '#'},
        {id: 'nombreCentro', name: t('EMPRESA.TABLA.NOMBRE')},
        {id: 'regaCentro', name: t('EMPRESA.TABLA.REGA')},
        {id: 'telefonoCentro', name: t('EMPRESA.TABLA.TELEFONO')},
        {id: 'localidadCentro', name: t('EMPRESA.TABLA.LOCALIDAD')},
        {id: 'emailCentro', name: t('EMPRESA.TABLA.MAIL')}
      ],
      rows: listCentros,
      onEdit: (row) => !submitting && recuperarDatosCentro(row.idCentro),
      data: dataCentro,
      mostrarDuplicar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.EMPRESA_CENTROS] > 1,
      duplicar: (row) => duplicarCentro(row.idCentro),
      dataDuplicar: dataCentroDuplicar,
      mostrarCambioEstado: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.EMPRESA_CENTROS] > 1,
      activarDesactivar: true,
      cambiarEstado: (row) => cambiarEstadoCentro(row.idCentro),
      mostrarEliminar:  this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.EMPRESA_CENTROS] > 1,
      onDelete: (row) => eliminarCentro(row.idCentro),
      initialValues: {},
    }
    table.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          table.initialValues['check' + row.idElemento] = checkCentros
        }
      }
    )
    return (
      <div>
        <SimpleModalPage/>
        <MensajeAlertaPage/>
        {
          <ModalCentroPage/>
        }
        <h2 className="page-title">{t('EMPRESA.TITLE')}</h2>
        <h3><b>{t('EMPRESA.DATOS_EMPRESA')}</b></h3>
        <Row>
          <form>
            <Field
              id="nombreEmpresa"
              name="nombreEmpresa"
              colSm={2}
              component={InputText}
              controlLabel={t('EMPRESA.RAZON_SOCIAL')}
              claseActivo="apellidos-operario"
              maxLength={true}
              valorMaxLength={100}
            />
            <Field
              id="regaEmpresa"
              name="regaEmpresa"
              colSm={2}
              component={InputText}
              controlLabel={t('EMPRESA.CODIGO_REGA')}
              claseActivo="apellidos-operario"
              maxLength={true}
              valorMaxLength={30}
            />
            <Field
              id="cifEmpresa"
              name="cifEmpresa"
              colSm={2}
              component={InputText}
              controlLabel={t('EMPRESA.CIF')}
              claseActivo="apellidos-operario"
              maxLength={true}
              valorMaxLength={20}
            />
            <Field
              id="direccionEmpresa"
              name="direccionEmpresa"
              colSm={2}
              component={InputText}
              controlLabel={t('EMPRESA.DIRECCION')}
              claseActivo="apellidos-operario"
              maxLength={true}
              valorMaxLength={100}
            />
            <Field
              id="cpEmpresa"
              name="cpEmpresa"
              colSm={2}
              component={InputText}
              controlLabel={t('EMPRESA.CP')}
              claseActivo="apellidos-operario"
              maxLength={true}
              valorMaxLength={10}
            />
            <Field
              id="localidadEmpresa"
              name="localidadEmpresa"
              colSm={2}
              component={InputText}
              controlLabel={t('EMPRESA.LOCALIDAD')}
              claseActivo="apellidos-operario"
              maxLength={true}
              valorMaxLength={50}
            />
            <Field
              id="provinciaEmpresa"
              name="provinciaEmpresa"
              colSm={2}
              component={InputText}
              controlLabel={t('EMPRESA.PROVINCIA')}
              claseActivo="apellidos-operario"
              maxLength={true}
              valorMaxLength={50}
            />
            <Field
              id="paisEmpresa"
              name="paisEmpresa"
              colSm={2}
              component={InputText}
              controlLabel={t('EMPRESA.PAIS')}
              claseActivo="apellidos-operario"
              maxLength={true}
              valorMaxLength={50}
            />
            <Field
              id="telefonoEmpresa"
              name="telefonoEmpresa"
              colSm={2}
              component={InputText}
              controlLabel={t('EMPRESA.TELEFONO')}
              claseActivo="apellidos-operario"
              maxLength={true}
              valorMaxLength={50}
            />
            <Field
              id="faxEmpresa"
              name="faxEmpresa"
              colSm={2}
              component={InputText}
              controlLabel={t('EMPRESA.FAX')}
              claseActivo="apellidos-operario"
              maxLength={true}
              valorMaxLength={50}
            />
            <Field
              id="webEmpresa"
              name="webEmpresa"
              colSm={2}
              component={InputText}
              controlLabel={t('EMPRESA.WEB')}
              claseActivo="apellidos-operario"
              maxLength={true}
              valorMaxLength={100}
            />
            <Field
              id="emailEmpresa"
              name="emailEmpresa"
              colSm={2}
              component={InputText}
              controlLabel={t('EMPRESA.EMAIL')}
              claseActivo="apellidos-operario"
              maxLength={true}
              valorMaxLength={100}
            />
            <Col sm={12} style={{textAlign: 'right', marginBottom: '10px'}}>
              <ButtonLockable
                processing={submitting}
                hasIcon={true}
                showLoaderOnClick={true}
                type="button"
                className="btn btn-primary btn-sm"
                disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.EMPRESA_CENTROS] < 2) || submitting}
                onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.EMPRESA_CENTROS] > 1) && !submitting) ? handleSubmit(this.editarEmpresa.bind(this)) : () => {}}
              >
                {t('PEDIDOS.CLIENTE.MODAL.GUARDAR')}
              </ButtonLockable>
            </Col>
          </form>
        </Row>
        {!submitting && (
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.EMPRESA_CENTROS] > 1) && [
                <div className="custom-check-w" key="custom-check-w">
                  <Field
                    id="seleccionar-todos-empresa"
                    name="seleccionar-todos-empresa"
                    colSm={12}
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheck(value)}
                  />
                </div>,
                <div id="block-options" key="block-options" className={this.state.imputSelected === true ? 'button-group buttons-min block-options' : 'button-group buttons-min block-options oculta-action'}>
                  <button type="button" data-toggle="modal" data-target="#delete-modal" className="btn btn-icono" onClick={(e) => this.deleteSeleccionCentros()}>
                    <Delete></Delete> {t('TITULOS_BOTONES.ELIMINAR')}
                  </button>
                  <button type="button" data-toggle="modal" data-target="#cambio-estado" className="btn btn-icono" onClick={(e) => this.cambioEstadoSeleccionCentros()}>
                    <Refresh></Refresh> {t('TITULOS_BOTONES.ACTIVAR_DESACTIVAR')}
                  </button>
                  <div className="barra-buttons-min"></div>
                </div>
              ]}
              <div className="button-group buttons-min">
                <button type="button" className="btn btn-icono btn-sm" onClick={(e) => this.crearCsvEmpresa()}>
                  <Launch></Launch> {t('TITULOS_BOTONES.EXPORTAR')}
                </button>
                <button type="button" className="btn btn-icono btn-sm" onClick={(e) => this.imprimirPdfEmpresa()}>
                  <Print></Print> {t('TITULOS_BOTONES.IMPRIMIR')}
                </button>
                {isCloud && (
                  <button
                    type="button"
                    data-toggle="modal"
                    data-target="#new-edit-modal"
                    className="btn btn-primary nuevo btn-sm"
                    disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.EMPRESA_CENTROS] < 2)}
                    onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.EMPRESA_CENTROS] > 1) ? abrirModalCentro : undefined}
                  >
                    <AddCircleOutline></AddCircleOutline> {t('TITULOS_BOTONES.NUEVO')}
                  </button>
                )}
              </div>
            </div>
          </Col>
        </Row>
        )}
        <ListadoPage t_key="EMPRESA." noTitle={true} table={table}>
        </ListadoPage>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'formEmpresa'
})(Empresa))