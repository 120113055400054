import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalLimpiezaCorral from '../../../components/planSanitario/bioseguridadHigiene/ModalLimpiezaCorral'
import {closeModal} from '../../../actions/common'
import {fetchLimpiezaCorral, cerrarModalLimpiezaCorral, seleccionarTodosModalLimpiezaCorral, deSeleccionarTodosModalLimpiezaCorral, anadirCorral
  } from '../../../actions/planSanitario/bioseguridadHigiene/bioseguridadHigiene'
import {comboInstalacionPadreActiva} from '../../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.bioseguridadHigiene,
    showModal: state.common.showModal,
    numeroRegistrosPorPagina: state.common.numeroRegistrosPorPagina,
    paginaActual: state.common.paginaActual,
    formSimpleTable: state.form.simpleTable,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchLimpiezaCorral,
      cerrarModalLimpiezaCorral,
      seleccionarTodosModalLimpiezaCorral,
      deSeleccionarTodosModalLimpiezaCorral,
      anadirCorral,
      comboInstalacionPadreActiva
    }, dispatch)

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalLimpiezaCorral))
