import React, { Component } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import ListadoPage from '../../containers/page/ListadoPage'
import { required } from '../../util/validationFunctions'
import Button from '../comun/button/Button'
import ButtonLockable from '../comun/button/ButtonLockable'
import InputText from '../comun/form/InputText'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputNumerical from '../comun/form/InputNumerical'
import './PanelControl.scss'
import { Delete } from '@material-ui/icons'
import {permisos as permisosConstant} from '../../constants/permisos'

class ModalTipoDiluyentePanelControl extends Component {
  constructor () {
    super()
    this.state = {
      checkTodosSelected: false
    }
  }

  componentDidMount () {
    this.props.actions.obtenerTipoDiluyente()
  }

  componentDidUpdate (prevProps) {
    if (Object.keys(this.props.listTipoDiluyente).length > 0) {
      if (this.props.listTipoDiluyente !== prevProps.listTipoDiluyente) {
        this.props.actions.obtenerTipoDiluyente()
      }
    }
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable !== prevProps.formSimpleTable) {
        let contActivos = 0
        if (this.props.formSimpleTable.values) {
          Object.values(this.props.formSimpleTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todo-tipo-diluyente', false)
            this.setState({ checkTodosSelected: false })
            this.props.actions.deSeleccionarTodosTipoDiluyente()
          }
        }
      }
    }
  }

  anadirDiluyentePanelControl (values) {
    return new Promise((resolve, reject) => {
      this.props.actions.anadirDiluyentePanelControl(values, resolve, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  editarDiluyentePanelControl (values) {
    return new Promise((resolve, reject) => {
      values.idTipoDiluyente = this.props.dataTipoDiluyente.idTipoDiluyente
      this.props.actions.editarDiluyentePanelControl(values, resolve, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  intercambiarCheckTipoDiluyente (value) {
    this.setState({ checkTodosSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosTipoDiluyente()
    } else {
      this.props.actions.deSeleccionarTodosTipoDiluyente()
    }
  }

  deleteSeleccionTipoDiluyente(){
    const itemsSeleccion = this.props.listTipoDiluyente.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idTipoDiluyente': row.idTipoDiluyente
        }
      )
    })

    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.deleteSeleccionTipoDiluyente(list)
  }

  render () {
    const {
      t, handleSubmit, submitting, showModalTipoDiluyente, listTipoDiluyente, checkedTipoDiluyente,numeroRegistrosPorPagina, paginaActual, dataTipoDiluyente, dataDuplicarTipoDiluyente,
      actions: {cerrarModalTipoDiluyente, recuperarDatosTipoDiluyente, duplicarDatosModalTipoDiluyente, cambiaPredeterminadoDiluyente, onDeleteDiluyente}
    } = this.props

    listTipoDiluyente.forEach(
      (row) => row.idElemento = row.idTipoDiluyente
    )

    const table = {
      id: 'tipoDiluyenteTable',
      multiHeader: false,
      hasCheckbox: (this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPO_DILUYENTE] > 1) && !submitting,
      hasOptions: !submitting,
      mostrarCambioEstado: false,
      // noBlockOptions: false,
      tablaModal: true,
      idBlockOptions: 1,
      columns: [
        {id: 'idTipoDiluyente', name: t('PANEL_CONTROL.MODAL_TIPO_DILUYENTE.TABLA.CODIGO')},
        {id: 'nombre', name: t('PANEL_CONTROL.MODAL_TIPO_DILUYENTE.TABLA.NOMBRE')},
        {id: 'caducidad', name: t('PANEL_CONTROL.MODAL_TIPO_DILUYENTE.TABLA.CADUCIDAD')},
        {id: 'grl', name: t('PANEL_CONTROL.MODAL_TIPO_DILUYENTE.TABLA.GRL'), type: 'numero'},
        {id: 'predeterminado', name: t('PANEL_CONTROL.MODAL_TIPO_DILUYENTE.TABLA.PREDETERMINADO')}
      ],
      rows: listTipoDiluyente,
      onEdit: (row) => !submitting && recuperarDatosTipoDiluyente(row.idTipoDiluyente),
      data: dataTipoDiluyente,
      duplicar: (row) =>duplicarDatosModalTipoDiluyente(row.idTipoDiluyente),
      dataDuplicar: dataDuplicarTipoDiluyente,
      cambiarPorDefecto: (row) => cambiaPredeterminadoDiluyente(row.idTipoDiluyente),
      onDelete: (row) => onDeleteDiluyente(row.idTipoDiluyente),
      mostrarModificar: true,
      mostrarDuplicar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPO_DILUYENTE] > 1,
      mostrarEliminar: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPO_DILUYENTE] > 1,
      mostrarPredeterminado: this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPO_DILUYENTE] > 1,
      initialValues: {}
    }
    table.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          table.initialValues['check' + row.idElemento] = checkedTipoDiluyente
        }
      }
    )

    return (
      <Modal show={showModalTipoDiluyente} onHide={cerrarModalTipoDiluyente} aria-labelledby="contained-modal-title-lg" className="modal-tipo-diluyente" backdrop="static">
      <form className="form-teclas-contaje">
        <Modal.Header closeButton={!submitting}>
          <Modal.Title>
          {
            Object.keys(this.props.dataTipoDiluyente).length > 0 ? (
              t('PANEL_CONTROL.MODAL_TIPO_DILUYENTE.TITLE_MODIFICAR')
            ) : (
              Object.keys(this.props.dataDuplicarTipoDiluyente).length > 0 ? (
                t('PANEL_CONTROL.MODAL_TIPO_DILUYENTE.TITLE_DUPLICAR')
              ) : (
                t('PANEL_CONTROL.MODAL_TIPO_DILUYENTE.TITLE_NUEVO')
              )
            )
          }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Field
              colSm={2}
              id="codigo"
              name="codigo"
              controlLabel={t('PANEL_CONTROL.MODAL_TIPO_DILUYENTE.CODIGO')}
              component={InputText}
              customClass='codigo'
              disabled={true}
            />
            <Field
              colSm={3}
              id="nombre"
              name="nombre"
              component={InputText}
              controlLabel={t('PANEL_CONTROL.MODAL_TIPO_DILUYENTE.NOMBRE')}
              customClass='nombre'
              validate={required}
            />
            <Field
              colSm={3}
              id="caducidad"
              name="caducidad"
              component={InputNumerical}
              controlLabel={t('PANEL_CONTROL.MODAL_TIPO_DILUYENTE.CADUCIDAD')}
              customClass='caducidad'
              validate={required}
              numDecimales={0}
            />
            <Field
              colSm={2}
              id="grl"
              name="grl"
              component={InputNumerical}
              controlLabel={t('PANEL_CONTROL.MODAL_TIPO_DILUYENTE.GRL')}
              customClass='grl'
            />
            <Field
              colSm={2}
              id="predeterminado"
              name="predeterminado"
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('PANEL_CONTROL.MODAL_TIPO_DILUYENTE.PREDETERMINADO')}
            />
            <div className="clearfix"></div>
            <Col sm={12}>
            {
              Object.keys(this.props.dataTipoDiluyente).length > 0 ? (
                <div style={{textAlign: 'right'}}>
                  <Button
                    type="button"
                    style={{marginLeft: '5px'}}
                    className="btn btn-white btn-sm"
                    disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.TIPO_DILUYENTE] < 2) || submitting}
                    onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPO_DILUYENTE] > 1) && !submitting ? () => this.props.actions.duplicarDatosModalTipoDiluyente(dataTipoDiluyente.idTipoDiluyente) : undefined}
                  >
                    {t('PANEL_CONTROL.MODAL_TIPO_DILUYENTE.DUPLICAR')}
                  </Button>
                  <ButtonLockable
                    processing={submitting}
                    hasIcon={true}
                    showLoaderOnClick={true}
                    type="button"
                    style={{marginLeft: '5px'}}
                    className="btn btn-primary btn-sm"
                    disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.TIPO_DILUYENTE] < 2) || submitting}
                    onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPO_DILUYENTE] > 1) && !submitting) ? handleSubmit(this.editarDiluyentePanelControl.bind(this)) : () => {}}
                  >
                    {t('PANEL_CONTROL.MODAL_TIPO_DILUYENTE.GUARDAR')}
                  </ButtonLockable>
                </div>
              ) : (
                <div style={{textAlign: 'right'}}>
                  <ButtonLockable
                    processing={submitting}
                    hasIcon={true}
                    showLoaderOnClick={true}
                    type="button"
                    className="btn btn-primary btn-sm"
                    disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.TIPO_DILUYENTE] < 2) || submitting}
                    onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPO_DILUYENTE] > 1) && !submitting) ? handleSubmit(this.anadirDiluyentePanelControl.bind(this)) : () => {}}
                  >
                    {t('PANEL_CONTROL.MODAL_TIPO_DILUYENTE.GUARDAR')}
                  </ButtonLockable>
                </div>
              )
            }
            </Col>
            <div className="clearfix" />
            <hr/>
            <Col sm={12} className="container-tabla-tipo-diluyente">
              <div id="block-options1" className={"filtros-tabla-tipo-diluyente col-sm-12" + (this.state.checkTodosSelected === true ? '' : ' oculta-action')}>
                <div className="custom-check-w">
                  {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.TIPO_DILUYENTE] > 1) && ([
                    <Field
                      id="seleccionar-todo-tipo-diluyente"
                      name="seleccionar-todo-tipo-diluyente"
                      key="seleccionar-todo-tipo-diluyente"
                      inline
                      component={InputCheckBox}
                      onInputChange={(value) => this.intercambiarCheckTipoDiluyente(value)}
                      colSm={1}
                    />,
                    <button key="eliminar_key" type="button" data-toggle="modal" data-toggle="modal" data-target="#delete-modal" className="btn-icono" onClick={(e) => this.deleteSeleccionTipoDiluyente()}>
                      <Delete /> {t('TITULOS_BOTONES.ELIMINAR')}
                    </button>
                  ])}
                </div>
              </div>
              <div className="clearfix" />
              <ListadoPage noTitle={true} t_key="PANEL_CONTROL.MODAL_TIPO_DILUYENTE." table={table}>
              </ListadoPage>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className={"btn btn-white btn-sm" + (submitting ? ' disabled' : '')}
            onClick={() => !submitting && cerrarModalTipoDiluyente()}
          >
            {t('PANEL_CONTROL.MODAL_TECLAS_CONTAJE.CERRAR')}
          </Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalTipoDiluyentePanelControl',
})(ModalTipoDiluyentePanelControl))