import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalCambioEstadoVerraco from '../../components/cambioEstadoVerraco/ModalCambioEstadoVerraco'
import { comboVerraco} from '../../actions/combos/combos'
import {abrirModalCambioEstadoVerraco, cerrarModalCambioEstadoVerraco} from '../../actions/fichaVerraco/fichaVerraco'
import { recuperarDatosModalCambioEstadoVerraco, enviarFechaCambioEstado, submitNuevoCambioEstadoVerraco, restartCambioEstadoVerraco, editarCambioEstadoVerraco } from '../../actions/cambioEstadoVerraco/cambioEstadoVerraco'

export function mapStateToProps (state) {
  return {
    showModalCambioEstadoVerraco: state.fichaVerraco.showModalCambioEstadoVerraco,
    combos: {...state.combos},
    formModalCambioEstadoVerraco: state.form.ModalCambioEstadoVerraco,
    idVerraco: state.fichaVerraco.idVerraco,
    fichaVerraco: state.fichaVerraco,
    cambioEstadoVerraco: state.cambioEstadoVerraco,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      recuperarDatosModalCambioEstadoVerraco,
      abrirModalCambioEstadoVerraco,
      cerrarModalCambioEstadoVerraco,
      enviarFechaCambioEstado,
      submitNuevoCambioEstadoVerraco,
      restartCambioEstadoVerraco,
      comboVerraco,
      editarCambioEstadoVerraco
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalCambioEstadoVerraco))