import React, { Component } from 'react'
import {reduxForm, Field, Fields} from 'redux-form'
import { Link } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputSelect from '../comun/form/InputSelect'
import InputRangeDatePickerDynamicNames from '../comun/form/InputRangeDatePickerDynamicNames'
import InputText from '../comun/form/InputText'
import { Refresh, Delete, Launch, Print, AddCircleOutline, FilterList } from '@material-ui/icons'
import {permisos as permisosConstant} from '../../constants/permisos'
import {date_formatter, dateTimeZones, date_parser} from '../../util/formatFunctions'

class FiltrosAlbaran extends Component {
   constructor () {
    super()
    this.state = {
      openFiltros: false,
      imputSelected: false,
      fitrosSeleccionados: []
    }
    this.fetchAlbaranes = this.fetchAlbaranes.bind(this)
    this.crearCsvAlbaranes = this.crearCsvAlbaranes.bind(this)
    this.imprimirPdfAlbaranes = this.imprimirPdfAlbaranes.bind(this)
  }

  componentDidMount () {
    this.props.actions.comboNumerosAlbaran()
    this.props.actions.comboCliente()
    this.props.actions.comboRuta()
    this.props.actions.comboTipoDosis()
    this.props.initialize({
      desde: date_parser(dateTimeZones(this.props.auth.zonaHorariaString), this.props.auth.formaFechaHora)
    })
  }

  fetchAlbaranes(values){
    this.props.actions.fetchAlbaranes(values)
  }

  abrirFiltros = (event) => {
    this.setState({ openFiltros: true })
  }

  cerrarFiltros = (event) => {
    this.setState({ openFiltros: false })
  }

  intercambiarCheck = (value) => {
    this.setState({ imputSelected: value })
    if (value === true) {
      this.props.actions.seleccionarTodosAlbaranes()
    } else {
      this.props.actions.deseleccionarTodosAlbaranes()
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.formSimpleTable && Object.keys(this.props.formSimpleTable).length > 0) {
      if (this.props.formSimpleTable !== prevProps.formSimpleTable) {
        let contActivos = 0
        if (this.props.formSimpleTable.values) {
          Object.values(this.props.formSimpleTable.values).forEach((value) => {
            if (value === true) {
              contActivos++
            }
          })
          if (contActivos === 0) {
            this.props.change('seleccionar-todo', false)
            this.setState({ imputSelected: false })
            this.props.actions.deseleccionarTodosAlbaranes()
          }
        }
      }
    }
  }

  deleteSeleccionAlbaranes(){
    const itemsSeleccion = this.props.albaran.list.albaranes.map((row) => {
      return this.props.formSimpleTable.values['check' + row[this.props.idElemento]] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row[this.props.idElemento]],
          [this.props.idElemento]: row[this.props.idElemento]
        }
      )
    })
    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.deleteSeleccionAlbaranes(list)
  }

  crearCsvAlbaranes(){
    this.props.actions.crearCsvAlbaranes(this.props.albaran.filtros)
  }

  imprimirPdfAlbaranes(){
    this.props.actions.imprimirPdfAlbaranes(this.props.albaran.filtros)
  }

  quitarFiltros (value) {
    let values = this.props.albaran.filtros
    values[value] = null
    this.props.actions.fetchAlbaranes(values)
    this.props.change(value, null)
    this.setState({fitrosSeleccionados: values})
  }

  render () {
  const {
    t, handleSubmit, auth: { permisos }, albaran: {filtros = {}},
    actions: {abrirModalVacioAlbaran}, combos: {
      comboNumerosAlbaran, comboCliente, comboRuta, comboTipoDosis
    }
  } = this.props
  const tKey = 'ALBARANES.'

  let comboEstadoAlbaran = this.props.combos.comboEstadoAlbaran
    comboEstadoAlbaran = comboEstadoAlbaran.map((estadoAlbaranItem) => {
    return {...estadoAlbaranItem, label: t(`COMUN.COMBOS.${estadoAlbaranItem.label}`)}
  })

  return (
      <div>
        <div className={this.state.openFiltros === true ? 'tabla-filtros open-filters' : 'tabla-filtros'}>
          <form className="custom-form">
            <Field
              colSm={2}
              id="numeroAlbaran"
              name="numeroAlbaran"
              controlLabel={t(tKey + 'FILTROS.NUM_ALBARAN')}
              component={InputSelect}
              options={comboNumerosAlbaran}
              valueKey="value"
              labelKey="label"
              onInputChange={handleSubmit(this.fetchAlbaranes)}
            />
            <Field
              colSm={2}
              id="cliente"
              name="cliente"
              controlLabel={t(tKey + 'FILTROS.CLIENTE')}
              component={InputSelect}
              options={comboCliente}
              valueKey="value"
              labelKey="label"
              onInputChange={handleSubmit(this.fetchAlbaranes)}
            />
            <Field
              colSm={2}
              id="nombreDireccion"
              name="nombreDireccion"
              controlLabel={t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.GRANJA')}
              component={InputText}
              onInputChange={handleSubmit(this.fetchAlbaranes)}
            />
            <Field
              colSm={2}
              id="estado"
              name="estado"
              controlLabel={t(tKey + 'FILTROS.ESTADO')}
              component={InputSelect}
              options={comboEstadoAlbaran}
              valueKey="value"
              labelKey="label"
              onInputChange={handleSubmit(this.fetchAlbaranes)}
            />
            <Field
              colSm={2}
              id="ruta"
              name="ruta"
              controlLabel={t(tKey + 'FILTROS.RUTA')}
              component={InputSelect}
              options={comboRuta}
              valueKey="value"
              labelKey="label"
              onInputChange={handleSubmit(this.fetchAlbaranes)}
            />
            <Field
              colSm={2}
              id="tipoDosis"
              name="tipoDosis"
              controlLabel={t(tKey + 'FILTROS.TIPO_DOSIS')}
              component={InputSelect}
              options={comboTipoDosis}
              valueKey="value"
              labelKey="label"
              onInputChange={handleSubmit(this.fetchAlbaranes)}
              noPredeterminado={false}
            />
            <Fields
              names={['desde', 'hasta']}
              component={InputRangeDatePickerDynamicNames}
              colSm={4}
              nameFrom="desde"
              nameTo="hasta"
              controlLabel={t(tKey + 'FILTROS.PERIODO')}
              onInputChange={handleSubmit(this.fetchAlbaranes)}
            />
            <div className="aplicar-tabla-filtros">
              <div className="filtros-anadidos">
                {
                  Object.keys(filtros).length > 0 && [
                    (filtros.numeroAlbaran && <span key="filtros.numeroAlbaran" className="badge-dark">{t(tKey + 'FILTROS.NUM_ALBARAN') + ': ' + filtros.numeroAlbaran.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('numeroAlbaran')}> x </button></span>),
                    (filtros.cliente && <span key="filtros.cliente" className="badge-dark">{t(tKey + 'FILTROS.CLIENTE') + ': ' + filtros.cliente.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('cliente')}> x </button></span>),
                    (filtros.nombreDireccion && <span key="filtros.nombreDireccion" className="badge-dark">{t('PEDIDOS.CLIENTE.MODAL_DIRECCIONES.GRANJA') + ': ' + filtros.nombreDireccion}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('nombreDireccion')}> x </button></span>),
                    (filtros.estado && <span key="filtros.estado" className="badge-dark">{t(tKey + 'FILTROS.ESTADO') + ': ' + filtros.estado.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('estado')}> x </button></span>),
                    (filtros.ruta && <span key="filtros.ruta" className="badge-dark">{t(tKey + 'FILTROS.RUTA') + ': ' + filtros.ruta.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('ruta')}> x </button></span>),
                    (filtros.tipoDosis && <span key="filtros.tipoDosis" className="badge-dark">{t(tKey + 'FILTROS.TIPO_DOSIS') + ': ' + filtros.tipoDosis.label}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('tipoDosis')}> x </button></span>),

                    (filtros.desde && filtros.hasta && filtros.desde !== null && filtros.hasta !== null) ? (
                      <span key="filtros.desdeHasta" className="badge-dark">{t(tKey + 'FILTROS.PERIODO') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtros.desde, this.props.auth.formaFechaHora, false) + ' - ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtros.hasta, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('hasta')}> x </button></span>
                    ) : (filtros.desde && filtros.desde !== null) ? (
                      <span key="filtros.desde" className="badge-dark">{t(tKey + 'FILTROS.PERIODO') + ' ' + t('COMUN.DATE.DESDE') + ': ' + date_formatter(filtros.desde, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('desde')}> x </button></span>
                    ) : (filtros.hasta && filtros.hasta !== null) && (
                      <span key="filtros.hasta" className="badge-dark">{t(tKey + 'FILTROS.PERIODO') + ' ' + t('COMUN.DATE.HASTA') + ': ' + date_formatter(filtros.hasta, this.props.auth.formaFechaHora, false)}<button type="button" className="quitar-filtro" onClick={() => this.quitarFiltros('hasta')}> x </button></span>
                    )
                  ]
                }
              </div>
            </div>
          </form>
        </div>
        <Row className="acciones-tabla-filtros">
          <Col sm={10}>
            <div className="buttons-flex">
              {(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ALBARANES] > 1) && [
                <div key="custom-check-w" className="custom-check-w">
                  <Field
                    id="seleccionar-todo"
                    name="seleccionar-todo"
                    colSm={12}
                    inline
                    component={InputCheckBox}
                    onInputChange={(value) => this.intercambiarCheck(value)}
                  />
                </div>,
                <div key="block-options" id="block-options" className={this.state.imputSelected === true ? 'button-group buttons-min block-options' : 'button-group buttons-min block-options oculta-action'}>
                  <button type="button" className="btn btn-icono" onClick={(e) => this.deleteSeleccionAlbaranes()}>
                    <Delete></Delete> {t(tKey + 'FILTROS.ELIMINAR')}
                  </button>
                  <div className="barra-buttons-min"></div>
                </div>
              ]}
              <div className="button-group buttons-min">
                <button type="button" className="btn btn-icono" onClick={(e) => this.crearCsvAlbaranes()}>
                  <Launch></Launch> {t(tKey + 'FILTROS.EXPORTAR')}
                </button>
                <button type="button" className="btn btn-icono" onClick={(e) => this.imprimirPdfAlbaranes()}>
                  <Print></Print> {t(tKey + 'FILTROS.IMPRIMIR')}
                </button>
                <button
                  type="button"
                  className={"btn btn-primary nuevo" + ((this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.ALBARANES] < 2) ? ' disabled' : '')}
                  onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ALBARANES] > 1) ? abrirModalVacioAlbaran : undefined}
                >
                  <AddCircleOutline></AddCircleOutline> {t(tKey + 'FILTROS.NUEVO')}
                </button>
              </div>
            </div>
          </Col>
          <Col sm={2} className="content-button-filtro">
            <div className="button-group buttons-min button-filtro">
              <div className={this.state.openFiltros === true ? 'btn filters-desp opener-open' : 'btn filters-desp'} onClick={(e) => this.state.openFiltros === false ? this.abrirFiltros() : this.cerrarFiltros()}>
                <FilterList></FilterList> {t(tKey + 'FILTROS.ABRIR_FILTROS')}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'FiltrosAlbaran',
  initialValues: {
    desde: new Date()
  }
})(FiltrosAlbaran))
