import React, { Component } from 'react'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import { PowerSettingsNew } from '@material-ui/icons'
import ButtonLockable from '../comun/button/ButtonLockable'

class PopoverUser extends Component {
  constructor() {
    super()
    this.state = {
      processing: false
    }
    this.logout = this.logout.bind(this);
  }

  logout() {
    if (!this.state.processing) {
      new Promise((resolve, reject) => {
        this.setState({processing: true})
        this.props.actions.logout(false, false, resolve, reject)
      })
      .then(() => {})
      .catch(() => {})
      .finally(() => { if (this._mounted) this.setState({processing: false}) })
    }
  }

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  render () {
    const {
      t, auth: {nombreUsuario},
    } = this.props

    // Eliminada debido al aviso de propiedad no reconocida.
    let cleanedProps = {...this.props}
    delete cleanedProps.t

    return (
      <Popover {...cleanedProps}  id="popover-trigger-click-root-close" className="content-popover-user" onClick={() => document.body.click()}>
        <div className="user-popover">
          <div className="data-name">{nombreUsuario}</div>
        </div>
        <div className="mi-cuenta-popover">
          <ul>
            <li onClick={this.logout} >
              <ButtonLockable processing={this.state.processing} className="no-border no-bg full-size text-left" replaceChild="PowerSettingsNew" hasIcon={true}>
                <PowerSettingsNew name="PowerSettingsNew"/>
                <span className="acciones-mi-cuenta-popover">{t('HEADER.CERRAR_SESION')}</span>
              </ButtonLockable>
            </li>
          </ul>
        </div>
      </Popover>
    )
  }
}

class AdminSubHeader extends Component {
  render () {
    const { t, auth, actions } = this.props
    return (
      <div className="loged adminLogin">
        <OverlayTrigger trigger="click" placement="bottom" rootClose overlay={(<PopoverUser t={t} auth={auth} actions={actions} />)}>
          <div className="nav-link dropdown-toggle">
            <div className="user-data">
              <div className="data-name">{auth.nombreUsuario}</div>
            </div>
          </div>
        </OverlayTrigger>
      </div>
    )
  }
}

export default AdminSubHeader
