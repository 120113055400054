import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Row, Col, Modal} from 'react-bootstrap'
import { required } from '../../util/validationFunctions'
import InputNumerical from '../comun/form/InputNumerical'
import Button from '../comun/button/Button'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputDatePicker from '../comun/form/InputDatePicker'
import InputTextArea from '../comun/form/InputTextArea'
import InputSelect from '../comun/form/InputSelect'
import { DoneAll, Refresh, Delete, Launch, Print, AddCircleOutline, FilterList, Warning } from '@material-ui/icons'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import './PedidosVenta.scss'

class ModalDatosDosis extends Component {
   constructor () {
    super()
    this.state = {
      classErrorVerracos: false,
      imputSelected: false,
      idRaza: null,
      idLineaGenetica: null,
      propiedad: null
    }
  }

  componentDidMount () {
    this.props.actions.comboLineaGeneticaActiva()
    this.props.actions.comboRazaActiva()
    this.props.actions.comboTipoDosis()
  }

  componentDidUpdate (prevProps) {
    if (this.props.showModalDatosDosis !== prevProps.showModalDatosDosis) {
      this.props.actions.verracosPedido({idRaza: this.state.idRaza, idLineaGenetica: this.state.idLineaGenetica, idCliente: this.props.cliente, propiedad: this.state.propiedad})
    }
  }

  cerrarModalDatosDosis () {
    this.props.actions.cerrarModalDatosDosis()
    this.props.initialize()
    this.setState({idRaza: null})
    this.setState({idLineaGenetica: null})
    this.setState({propiedad: null})
  }

  anadirDatosDosis (values) {
    if (values.verraco) {values.idVerraco = values.verraco.value}
    if (values.raza) {values.idRaza = values.raza.value}
    if (values.lineaGenetica) {values.idLineaGenetica = values.lineaGenetica.value}
    if (values.tipoDosis) {values.idTipoDosis = values.tipoDosis.value}
    if (values.verraco) {values.verraco = values.verraco.label}
    if (values.raza) {values.raza = values.raza.label}
    if (values.lineaGenetica) {values.lineaGenetica = values.lineaGenetica.label}
    if (values.tipoDosis) {values.tipoDosis = values.tipoDosis.label}
    if (!values.numeroDosis) {values.numeroDosis = 0}

    if (Object.keys(this.props.dataPedidosVenta).length > 0) {
      this.props.actions.editarDatosDosis(values)
      this.cerrarModalDatosDosis()
    } else {
      this.props.actions.anadirDatosDosis(values)
      this.cerrarModalDatosDosis()
    }
  }

  guardarYnuevoDatosDosis (values) {
    if (values.verraco) {values.idVerraco = values.verraco.value}
    if (values.raza) {values.idRaza = values.raza.value}
    if (values.lineaGenetica) {values.idLineaGenetica = values.lineaGenetica.value}
    if (values.tipoDosis) {values.idTipoDosis = values.tipoDosis.value}
    if (values.verraco) {values.verraco = values.verraco.label}
    if (values.raza) {values.raza = values.raza.label}
    if (values.lineaGenetica) {values.lineaGenetica = values.lineaGenetica.label}
    if (values.tipoDosis) {values.tipoDosis = values.tipoDosis.label}
    if (!values.numeroDosis) {values.numeroDosis = 0}
    this.props.actions.anadirDatosDosis(values)
    this.props.initialize()
  }

  validarCantidadDosis (value, prevVal) {
    if (parseInt(prevVal, 10) <=0 || prevVal === ''){
      this.setState({textoValorTotalDosis: true})
    } else {
      this.setState({textoValorTotalDosis: false})
    }
  }

  asignarIdRaza (val) {
    this.setState({idRaza: val !== null ? val.value : null})
    this.verracosPedido()
    this.props.change('verraco', null)
  }

  asignarIdLineaGenetica (val) {
    this.setState({idLineaGenetica: val !== null ? val.value : null})
    this.verracosPedido()
    this.props.change('verraco', null)
  }

  asignarPropiedad (val) {
    this.setState({propiedad: val !== null ? val.value : null})
    this.verracosPedido()
  }

  verracosPedido () {
    this.props.actions.verracosPedido({idRaza: this.state.idRaza, idLineaGenetica: this.state.idLineaGenetica, idCliente: this.props.cliente, propiedad: this.state.propiedad})
  }

  asignarVerraco (val) {
    if (val !== null) {
      this.props.change('raza', {value: val.idRaza, label: val.nombreRaza})
      this.props.change('lineaGenetica', {value: val.idLinea, label: val.nombreLinea})
      this.setState({idRaza: val.idRaza})
      this.setState({idLineaGenetica: val.idLinea})
      this.props.actions.verracosPedido({idRaza: this.state.idRaza, idLineaGenetica: this.state.idLineaGenetica, idCliente: this.props.cliente, propiedad: this.state.propiedad})
    } else {
      this.props.change('raza', '')
      this.props.change('lineaGenetica', '')
      this.setState({idRaza: null})
      this.setState({idLineaGenetica: null})
      this.props.actions.verracosPedido({idRaza: this.state.idRaza, idLineaGenetica: this.state.idLineaGenetica, idCliente: this.props.cliente, propiedad: this.state.propiedad})
    }
  }

  render () {
    const {
      t, handleSubmit, showModalDatosDosis, checkedDatosDosis, dataPedidosVenta, dataPedidosVentaDuplicar, duplicar, comboVerracoPedido,
      combos:{ comboLineaGeneticaActiva, comboRazaActiva, comboTipoDosis }
    } = this.props

    const comboPropiedad = [
      { value: true, label: t('FICHA_VERRACO.NUEVO_VERRACO.COMBOS.PROPIO')},
      { value: false, label: t('FICHA_VERRACO.NUEVO_VERRACO.COMBOS.ALQUILADO')}
    ]

  return (
      <Modal show={showModalDatosDosis} onHide={() => this.cerrarModalDatosDosis()} bsSize="large" dialogClassName="xl" backdrop="static" aria-labelledby="contained-modal-title-lg">
        <form className="form-modal-planing">
          <Modal.Header closeButton>
            <Modal.Title>
            {
              Object.keys(dataPedidosVenta).length > 0 ? (
                t('PEDIDOS.PEDIDOS_VENTA.MODAL.DATOS_DOSIS_MODIFICAR')
              ) : (
                Object.keys(dataPedidosVentaDuplicar).length > 0 ? (
                  t('PEDIDOS.PEDIDOS_VENTA.MODAL.DATOS_DOSIS_DUPLICAR')
                ) : (
                  t('PEDIDOS.PEDIDOS_VENTA.MODAL.DATOS_DOSIS_NUEVO')
                )
              )
            }
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Field
              colSm={2}
              id="raza"
              name="raza"
              component={InputSelect}
              options={comboRazaActiva}
              valueKey="value"
              labelKey="label"
              controlLabel={t('PEDIDOS.PEDIDOS_VENTA.MODAL.RAZA') + '*'}
              customClass='raza'
              validate={required}
              onInputChange={(val) => this.asignarIdRaza(val)}
            />
            <Field
              colSm={2}
              id="lineaGenetica"
              name="lineaGenetica"
              component={InputSelect}
              options={comboLineaGeneticaActiva}
              valueKey="value"
              labelKey="label"
              controlLabel={t('PEDIDOS.PEDIDOS_VENTA.MODAL.LINEA_GENETICA')}
              customClass='lineaGenetica'
              onInputChange={(val) => this.asignarIdLineaGenetica(val)}
            />
            <Field
              colSm={2}
              id="propiedad"
              name="propiedad"
              component={InputSelect}
              options={comboPropiedad}
              valueKey="value"
              labelKey="label"
              controlLabel={t('PEDIDOS.PEDIDOS_VENTA.MODAL.PROPIEDAD')}
              customClass='propiedad'
              onInputChange={(val) => this.asignarPropiedad(val)}
            />
            <Field
              colSm={2}
              id="verraco"
              name="verraco"
              component={InputSelect}
              options={comboVerracoPedido.verracos}
              valueKey="value"
              labelKey="label"
              controlLabel={t('PEDIDOS.PEDIDOS_VENTA.MODAL.VERRACO')}
              customClass='verraco'
              onInputChange={(val) => this.asignarVerraco(val)}
            />
            <Field
              colSm={2}
              id="numeroDosis"
              name="numeroDosis"
              component={InputNumerical}
              controlLabel={t('PEDIDOS.PEDIDOS_VENTA.MODAL.DOSIS_PEDIDAS')}
              customClass='dosisPedidas'
            />
            <Field
              colSm={2}
              id="tipoDosis"
              name="tipoDosis"
              component={InputSelect}
              options={comboTipoDosis}
              valueKey="value"
              labelKey="label"
              controlLabel={t('PEDIDOS.PEDIDOS_VENTA.MODAL.TIPO_DOSIS') + '*'}
              customClass='tipoDosis'
              noPredeterminado={false}
              validate={required}
              isClearable={false}
            />
            <div className="clearfix"></div>
          </Modal.Body>
          <Modal.Footer>
            {
              Object.keys(dataPedidosVenta).length > 0 ? (
                <Button type="button" className="btn btn-white" onClick={handleSubmit(duplicar)}>{t('PEDIDOS.PEDIDOS_VENTA.MODAL.DUPLICAR')}</Button>
              ) : (
                <Button type="button" className="btn btn-primary" onClick={handleSubmit(this.guardarYnuevoDatosDosis.bind(this))}>{t('PEDIDOS.PEDIDOS_VENTA.MODAL.GUARDAR_Y_NUEVO')}</Button>
              )
            }
            <Button type="button" className="btn btn-primary" onClick={handleSubmit(this.anadirDatosDosis.bind(this))}>{t('PEDIDOS.PEDIDOS_VENTA.MODAL.GUARDAR')}</Button>
            <Button type="button" className="btn btn-white" onClick={() => this.cerrarModalDatosDosis()}>{t('PEDIDOS.PEDIDOS_VENTA.MODAL.CANCELAR')}</Button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalDatosDosis',
})(ModalDatosDosis))
