import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import FiltrosFichaVerraco from '../../components/fichaVerraco/FiltrosFichaVerraco'
import { seleccionarTodosFichaVerraco, deSeleccionarTodosFichaVerraco, cambiarVigiladoSeleccionFichaVerraco, deleteSeleccionFichaVerraco, fetchFiltrarFichaVerraco, crearCsvFichaVerraco, 
  imprimirPdfFichaVerraco} from '../../actions/fichaVerraco/fichaVerraco'
import { comboRaza, comboLineaGenetica, comboVerraco } from '../../actions/combos/combos'

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    fichaVerraco: state.fichaVerraco,
    formSimpleTable: state.form.simpleTable,
    formFiltrosFichaVerraco: state.form.FiltrosFichaVerraco,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      seleccionarTodosFichaVerraco,
      deSeleccionarTodosFichaVerraco,
      cambiarVigiladoSeleccionFichaVerraco,
      deleteSeleccionFichaVerraco,
      comboRaza,
      comboLineaGenetica,
      comboVerraco,
      fetchFiltrarFichaVerraco,
      crearCsvFichaVerraco,
      imprimirPdfFichaVerraco
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltrosFichaVerraco))