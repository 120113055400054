import React, { Component } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import Loader from '../comun/loader/Loader'
import Button from '../comun/button/Button'
import {
  tipoAnalisisExtraccion as tipoAnalisisExtraccionConstants
} from '../../constants/analisis'

class ModalNuevoMagavision extends Component {
  constructor (props) {
    super()
    this.obtenerYGuardarResultadoSistemaAnalisis = this.obtenerYGuardarResultadoSistemaAnalisis.bind(this)
  }

  obtenerYGuardarResultadoSistemaAnalisis () {
    this.props.actions.obtenerYGuardarResultadoSistemaAnalisis(
      tipoAnalisisExtraccionConstants.NUEVO_MAGAVISION,
      this.props.isPool ? true : this.props.extraccion.extraccion.isSeguimiento,
      this.props.isPool,
      (this.props.isPool && this.props.seguimiento.list && this.props.seguimiento.list.length === 0) ? true : false
    )
  }

  cerrarNuevoMagavision () {
    this.props.actions.cerrarNuevoMagavision(this.props.isPool)
  }

  render () {
    const {
      t, isPool = false, isSeguimiento = false, extraccion, pool, panelControl: {urlMagavision}, auth: {idioma}
    } = this.props
    const tKey = 'ANALISIS_EXTRACCIONES.MAGAVISION.'
    const esperandoResultadoSistemaAnalisis = extraccion.extraccion.esperandoResultadoSistemaAnalisis

    const tokenUsuarioMagavision = isPool ? pool.tokenUsuarioMagavision : extraccion.tokenUsuarioMagavision
    const t_anl = isPool ? pool.t_anl : extraccion.t_anl
    const t_dtv = isPool ? pool.t_dtv : extraccion.t_dtv
    const t_dtv_main = isPool ? pool.pool.t_dtv_main : extraccion.extraccion.t_dtv_main

    if (!extraccion.extraccion.showModalMagavision) return false

    return (
      <Modal show={extraccion.extraccion.showModalMagavision} onHide={() => this.cerrarNuevoMagavision() } bsSize="large" dialogClassName="full-screen" backdrop="static">
        <Modal.Body className="p-a-0">
          <iframe
            id="iframeMagavision"
            title="Magavision"
            style={{width: "100%", height: "100%", minHeight: "calc(100vh - 70px)"}}
            src={
              urlMagavision + (urlMagavision[urlMagavision.length - 1] !== '/' ? '/' : '') +
              "login/validatetoken?t_usr=" + tokenUsuarioMagavision +
              "&t_anl=" + t_anl +
              "&t_dtv=" + t_dtv +
              "&lang=" + (idioma === 'es' ? 'spanish' : 'english') +
              '&t_sample_type=' + (isPool ? 3 : isSeguimiento ? 2 : 1) +
              (t_dtv_main ? '&t_dtv_main=' + t_dtv_main : '')
            }
          >
          </iframe>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            className="btn btn-primary"
            onClick={() => this.obtenerYGuardarResultadoSistemaAnalisis() }
            disabled={esperandoResultadoSistemaAnalisis}
          >{esperandoResultadoSistemaAnalisis && (<Loader />)}{t(tKey + 'BUTTONS.ACEPTAR')}</Button>
          <Button
            type="button"
            className="btn btn-white"
            onClick={() => this.cerrarNuevoMagavision() }
          >{t(tKey + 'BUTTONS.CERRAR')}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default ModalNuevoMagavision