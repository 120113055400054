export const permisos = {
  DASHBOARD: 1,
  SISTEMA: 2,
  EMPRESA_CENTROS: 3,
  USUARIOS: 4,
  GRUPOS_USUARIOS: 5,
  IMPORTAR_DATOS_GESIPOR_2: 6,
  MANTENIMIENTO_DATOS: 7,
  RAZAS: 8,
  LINEAS_GENETICAS: 9,
  GRUPOS_VERRACOS: 10,
  OPERARIOS: 11,
  TRANSPORTE: 12,
  RUTAS: 13,
  TRANSPORTISTAS: 14,
  PLANO: 15,
  TIPOS: 16,
  INSTALACIONES: 17,
  VER_MAPA: 18,
  PLAN_SANITARIO: 19,
  BIOSEGURIDAD_HIGIENE: 20,
  VISITAS: 21,
  PS_ALIMENTACION: 22,
  TIPOS_ALIMENTACION: 23,
  REGISTRO_ALIMENTACIONES: 24,
  INTERVENCIONES_SANITARIAS: 25,
  TIPOS_INTERVENCION_SANITARIA: 26,
  REGISTRO_INTERVENCION_SANITARIA: 27,
  VERRACOS: 28,
  FICHA_VERRACOS: 29,
  EVOLUCION: 30,
  V_ALIMENTACION: 31,
  MOVIMIENTO_VERRACOS: 32,
  CAMBIOS_ESTADO_VERRACOS: 33,
  EXTRACCIONES: 34,
  ANALISIS: 35,
  DILUCION: 36,
  POOL: 37,
  ENVASADO: 38,
  SEGUIMIENTO: 39,
  PLANNING: 40,
  ALMACEN: 41,
  PRODUCTOS: 42,
  ESCANDALLOS: 43,
  ENTRADAS_MERCANCIAS: 44,
  VALIDACION_MATERIAS_PRIMAS: 45,
  PEDIDOS: 46,
  CLIENTES: 47,
  PEDIDO: 48,
  ALBARANES: 49,
  FOSO: 50,
  INFORMES: 51,
  HERRAMIENTAS: 52,
  PANEL_CONTROL: 53,
  TIPO_DOSIS: 54,
  TIPO_DILUYENTE: 55,
  TECLAS_CONTAJE: 56,
  RECTA_COLORIMETRO: 57,
  CONECTIVIDAD: 58,
  UTILIDADES: 59,
  ASISTENTE_INFORMES: 60,
  AGENDA: 61,
  COPIA_SEGURIDAD: 62,
  AYUDA: 63,
  NUEVO_VERRACO: 64,
  NUEVO_TRANSPORTISTA: 65,
  PLANING: 66,
  SECUENCIA: 67,
  AJUSTE_STOCK: 68,
  CARGA_INDEX: 69,
}