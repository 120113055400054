import React, { Component } from 'react'
import {reduxForm, Field} from 'redux-form'
import { Modal, Row } from 'react-bootstrap'
import Button from '../comun/button/Button'
import ButtonLockable from '../comun/button/ButtonLockable'
import InputNumerical from '../comun/form/InputNumerical'
import InputSelect from '../comun/form/InputSelect'
import InputTextArea from '../comun/form/InputTextArea'
import { required } from '../../util/validationFunctions'

class ModalAjusteStock extends Component {
  constructor () {
    super()
    this.handleChangeProducto = this.handleChangeProducto.bind(this)
    this.handleChangeLote = this.handleChangeLote.bind(this)
    this.guardarAjusteStock = this.guardarAjusteStock.bind(this)
    this.guardarYNuevoAjusteStock = this.guardarYNuevoAjusteStock.bind(this)
  }

  handleChangeProducto (values) {
    this.props.change('entradaAlmacenProducto', null)
    if (values && values.value) {
      this.props.actions.comboLotesProductoEspecificoActivoTrazabilidad(values.value)
    }
  }

  handleChangeLote (values) {
    if (values && values.stock) {
      this.props.change('stockReal', values.stock)
    }
  }

  guardarAjusteStock (values) {
    return new Promise((resolve, reject) => {
      this.props.actions.guardarAjusteStock(values, false, resolve, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  guardarYNuevoAjusteStock (values) {
    return new Promise((resolve, reject) => {
      this.props.actions.guardarAjusteStock(values, true, resolve, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  render () {
    const {
      t, handleSubmit, submitting, showModal,
      actions: {closeModalAjusteStock},
      combos: {comboProductosActivosTrazabilidad, comboLotesProductoEspecificoActivoTrazabilidad}
    } = this.props

    const tKey = 'ALMACEN.AJUSTE_STOCK.FORM.'

    return (
      <Modal show={showModal} onHide={closeModalAjusteStock} bsSize="large" backdrop="static">
        <form className="form-dilucion">
          <Modal.Header closeButton={!submitting}>
            <Modal.Title>{t(tKey + 'TITLE')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Field
                colSm={6}
                id="producto"
                name="producto"
                controlLabel={t(tKey + 'FORM.PRODUCTO') + '*'}
                component={InputSelect}
                options={comboProductosActivosTrazabilidad}
                valueKey="value"
                labelKey="label"
                isClearable={true}
                validate={required}
                onInputChange={(producto) => this.handleChangeProducto(producto)}
              />
              <Field
                colSm={6}
                id="entradaAlmacenProducto"
                name="entradaAlmacenProducto"
                controlLabel={t(tKey + 'FORM.LOTE') + '*'}
                component={InputSelect}
                options={comboLotesProductoEspecificoActivoTrazabilidad}
                valueKey="value"
                labelKey="label"
                isClearable={true}
                validate={required}
                onInputChange={(lote) => this.handleChangeLote(lote)}
              />
            </Row>
            <Row>
              <Field
                colSm={6}
                id="stockReal"
                name="stockReal"
                controlLabel={t(tKey + 'FORM.STOCK')}
                component={InputNumerical}
                numDecimales={0}
                disabled={true}
              />
              <Field
                colSm={6}
                id="stock"
                name="stock"
                controlLabel={t(tKey + 'FORM.AJUSTE_STOCK') + '*'}
                component={InputNumerical}
                numDecimales={0}
                minValue={0}
                validate={required}
              />
            </Row>
            <Row>
              <Field
                colSm={12}
                id="observaciones"
                name="observaciones"
                controlLabel={t(tKey + 'FORM.OBSERVACIONES')}
                component={InputTextArea}
              />
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <ButtonLockable
              processing={submitting}
              hasIcon={true}
              showLoaderOnClick={true}
              type="button"
              className="btn btn-primary btn-sm"
              disabled={submitting}
              onClick={!submitting ? handleSubmit(this.guardarYNuevoAjusteStock) : () => {}}
            >
              {t(tKey + 'BUTTONS.GUARDAR_Y_NUEVO')}
            </ButtonLockable>
            <ButtonLockable
              processing={submitting}
              hasIcon={true}
              showLoaderOnClick={true}
              type="button"
              className="btn btn-primary btn-sm"
              disabled={submitting}
              onClick={!submitting ? handleSubmit(this.guardarAjusteStock) : () => {}}
            >
              {t(tKey + 'BUTTONS.GUARDAR')}
            </ButtonLockable>
            <Button
              type="button"
              className={"btn btn-white btn-sm" + (submitting ? ' disabled' : '')}
              onClick={() => !submitting && closeModalAjusteStock()}
            >
              {t(tKey + 'BUTTONS.CANCELAR')}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}


export default (reduxForm({
  form: 'ModalAjusteStock',
})(ModalAjusteStock))