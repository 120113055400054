export default{
  ABRIR_MODAL_PEDIDOS_VENTA: 'ABRIR_MODAL_PEDIDOS_VENTA',
  CERRAR_MODAL_PEDIDOS_VENTA: 'CERRAR_MODAL_PEDIDOS_VENTA',
  ABRIR_MODAL_PEDIDOS: 'ABRIR_MODAL_PEDIDOS',
  CERRAR_MODAL_PEDIDOS: 'CERRAR_MODAL_PEDIDOS',
  ABRIR_MODAL_DATOS_DOSIS: 'ABRIR_MODAL_DATOS_DOSIS',
  CERRAR_MODAL_DATOS_DOSIS: 'CERRAR_MODAL_DATOS_DOSIS',
  ANADIR_DATOS_DOSIS: 'ANADIR_DATOS_DOSIS',
  RECUPERAR_DATOS_MODAL_DATOS_DOSIS: 'RECUPERAR_DATOS_MODAL_DATOS_DOSIS',
  RECUPERAR_DATOS_MODAL_DATOS_DOSIS_SUCCESS: 'RECUPERAR_DATOS_MODAL_DATOS_DOSIS_SUCCESS',
  EDITAR_DATOS_DOSIS: 'EDITAR_DATOS_DOSIS',
  EDITAR_DATOS_DOSIS_SUCCESS: 'EDITAR_DATOS_DOSIS_SUCCESS',
  DUPLICAR_DATOS_MODAL_DATOS_DOSIS: 'DUPLICAR_DATOS_MODAL_DATOS_DOSIS',
  DUPLICAR_DATOS_MODAL_DATOS_DOSIS_SUCCESS: 'DUPLICAR_DATOS_MODAL_DATOS_DOSIS_SUCCESS',
  ON_DELETE_DATOS_DOSIS: 'ON_DELETE_DATOS_DOSIS',
  SELECCIONAR_TODOS_MODAL_PEDIDOS: 'SELECCIONAR_TODOS_MODAL_PEDIDOS',
  DESELECCIONAR_TODOS_MODAL_PEDIDOS: 'DESELECCIONAR_TODOS_MODAL_PEDIDOS',
  ELEGIR_CLIENTE_PEDIDOS_VENTA: 'ELEGIR_CLIENTE_PEDIDOS_VENTA',
  ELEGIR_CLIENTE_PEDIDOS_VENTA_SUCCESS: 'ELEGIR_CLIENTE_PEDIDOS_VENTA_SUCCESS',
  ANADIR_PEDIDOS_VENTA: 'ANADIR_PEDIDOS_VENTA',
  FETCH_FILTRAR_PEDIDOS_VENTA: 'FETCH_FILTRAR_PEDIDOS_VENTA',
  FETCH_FILTRAR_PEDIDOS_VENTA_SUCCESS: 'FETCH_FILTRAR_PEDIDOS_VENTA_SUCCESS',
  FETCH_PEDIDOS_VENTA_SUCCESS: 'FETCH_PEDIDOS_VENTA_SUCCESS',
  RECUPERAR_DATOS_MODAL_PEDIDOS_VENTA: 'RECUPERAR_DATOS_MODAL_PEDIDOS_VENTA',
  RECUPERAR_DATOS_MODAL_PEDIDOS_VENTA_SUCCESS: 'RECUPERAR_DATOS_MODAL_PEDIDOS_VENTA_SUCCESS',
  EDITAR_PEDIDOS_VENTA: 'EDITAR_PEDIDOS_VENTA',
  DUPLICAR_DATOS_MODAL_PEDIDOS_VENTA: 'DUPLICAR_DATOS_MODAL_PEDIDOS_VENTA',
  DUPLICAR_DATOS_MODAL_PEDIDOS_VENTA_SUCCESS: 'DUPLICAR_DATOS_MODAL_PEDIDOS_VENTA_SUCCESS',
  ON_DELETE_PEDIDOS_VENTA: 'ON_DELETE_PEDIDOS_VENTA',
  SELECCIONAR_TODOS_PEDIDOS_VENTA: 'SELECCIONAR_TODOS_PEDIDOS_VENTA',
  DESELECCIONAR_TODOS_PEDIDOS_VENTA: 'DESELECCIONAR_TODOS_PEDIDOS_VENTA',
  DELETE_SELECCION_PEDIDOS_VENTA: 'DELETE_SELECCION_PEDIDOS_VENTA',
  EXPORTAR_PEDIDOS_VENTA: 'EXPORTAR_PEDIDOS_VENTA',
  EXPORTAR_PEDIDOS_VENTA_SUCCESS: 'EXPORTAR_PEDIDOS_VENTA_SUCCESS',
  IMPRIMIR_PEDIDOS_VENTA: 'IMPRIMIR_PEDIDOS_VENTA',
  IMPRIMIR_PEDIDOS_VENTA_SUCCESS: 'IMPRIMIR_PEDIDOS_VENTA_SUCCESS',
  VERRACOS_PEDIDO: 'VERRACOS_PEDIDO',
  VERRACOS_PEDIDO_SUCCESS: 'VERRACOS_PEDIDO_SUCCESS',
  CREAR_ALBARAN_DESDE_PEDIDO: 'CREAR_ALBARAN_DESDE_PEDIDO',
  ABRIR_DETALLES_PEDIDO_SUCCESS: 'ABRIR_DETALLES_PEDIDO_SUCCESS',
  CERRAR_DETALLES_PEDIDO_SUCCESS: 'CERRAR_DETALLES_PEDIDO_SUCCESS',
  DUPLICAR_PEDIDO_VENTA: 'DUPLICAR_PEDIDO_VENTA',
  OBTENER_NUMERO_PEDIDO: 'OBTENER_NUMERO_PEDIDO',
  OBTENER_NUMERO_PEDIDO_SUCCESS: 'OBTENER_NUMERO_PEDIDO_SUCCESS',
  ELEGIR_DIRECCION_CLIENTE_PEDIDOS_VENTA: 'ELEGIR_DIRECCION_CLIENTE_PEDIDOS_VENTA',
  ELEGIR_DIRECCION_CLIENTE_PEDIDOS_VENTA_SUCCESS: 'ELEGIR_DIRECCION_CLIENTE_PEDIDOS_VENTA_SUCCESS',
  CONFIRMAR_Y_NUEVO_PEDIDOS_VENTA: 'CONFIRMAR_Y_NUEVO_PEDIDOS_VENTA'
}