import React, { Component } from 'react'
import { Modal, Row } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { required } from '../../util/validationFunctions'
import Button from '../comun/button/Button'
import ButtonLockable from '../comun/button/ButtonLockable'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputTime from '../comun/form/InputTime'
import './CopiaSeguridad.scss'

class ModalCopiaSeguridadProgramada extends Component {
  constructor() {
    super()
    this.submitNuevaCopiaSeguridadProgramada = this.submitNuevaCopiaSeguridadProgramada.bind(this)
    this.cerrarModalCopiaSeguridadProgramada = this.cerrarModalCopiaSeguridadProgramada.bind(this)
  }

  componentDidUpdate (prevProps) {
    if (this.props.showModalCopiaSeguridadProgramada !== prevProps.showModalCopiaSeguridadProgramada){
      this.props.actions.obtenerProgramacionCopiaSeguridad ()
    }
  }

  submitNuevaCopiaSeguridadProgramada (values) {
    return new Promise((resolve, reject) => {
      const valoresEnviar = []
      const diasSemana = []
      if (values.lunes === true) {
        diasSemana.push(1)
      }
      if (values.martes === true) {
        diasSemana.push(2)
      }
      if (values.miercoles === true) {
        diasSemana.push(3)
      }
      if (values.jueves === true) {
        diasSemana.push(4)
      }
      if (values.viernes === true) {
        diasSemana.push(5)
      }
      if (values.sabado === true) {
        diasSemana.push(6)
      }
      if (values.domingo === true) {
        diasSemana.push(0)
      }
      valoresEnviar.hora = values.hora
      valoresEnviar.diasSemana = diasSemana
      this.props.actions.submitNuevaCopiaSeguridadProgramada(valoresEnviar, resolve, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  cerrarModalCopiaSeguridadProgramada () {
    this.props.actions.cerrarModalCopiaSeguridadProgramada()
  }

  render () {
    const {
      t, handleSubmit, submitting, showModalCopiaSeguridadProgramada,
      actions: {cerrarModalCopiaSeguridadProgramada},
      combos: {comboObtenerCentroUsuario}
      } = this.props

    return (
      <Modal
        show={showModalCopiaSeguridadProgramada} onHide={() => this.cerrarModalCopiaSeguridadProgramada()}
        aria-labelledby="contained-modal-title-lg" backdrop="static" className="modal-programar-copia"
      >
      <form>
        <Modal.Header closeButton={!submitting}>
          <Modal.Title>
          {t('COPIA_SEGURIDAD.MODAL.TITULO_NUEVO')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="row-flex">
            <Field
              id="lunes"
              name="lunes"
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('COMUN.DATE.WEEKDAYS_LONG.LUNES')}
              customClass="col-flex"
            />
            <Field
              id="martes"
              name="martes"
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('COMUN.DATE.WEEKDAYS_LONG.MARTES')}
              customClass="col-flex"
            />
            <Field
              id="miercoles"
              name="miercoles"
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('COMUN.DATE.WEEKDAYS_LONG.MIERCOLES')}
              customClass="col-flex"
            />
            <Field
              id="jueves"
              name="jueves"
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('COMUN.DATE.WEEKDAYS_LONG.JUEVES')}
              customClass="col-flex"
            />
            <Field
              id="viernes"
              name="viernes"
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('COMUN.DATE.WEEKDAYS_LONG.VIERNES')}
              customClass="col-flex"
            />
            <Field
              id="sabado"
              name="sabado"
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('COMUN.DATE.WEEKDAYS_LONG.SABADO')}
              customClass="col-flex"
            />
            <Field
              id="domingo"
              name="domingo"
              inline
              component={InputCheckBox}
              claseActivo="onoffswitch-label"
              controlLabel={t('COMUN.DATE.WEEKDAYS_LONG.DOMINGO')}
              customClass="col-flex"
            />
          </Row>
          <Row>
            <Field
              colSm={5}
              id="hora"
              name="hora"
              controlLabel={t('COPIA_SEGURIDAD.HORA') + '*'}
              component={InputTime}
              validate={required}
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <ButtonLockable
            processing={submitting}
            hasIcon={true}
            showLoaderOnClick={true}
            type="button"
            className="btn btn-primary btn-sm"
            disabled={submitting}
            onClick={!submitting ? handleSubmit(this.submitNuevaCopiaSeguridadProgramada) : () => {}}
          >
            {t('COPIA_SEGURIDAD.MODAL.GUARDAR')}
          </ButtonLockable>
          <Button
            type="button"
            className={"btn btn-white btn-sm" + (submitting ? ' disabled' : '')}
            onClick={() => !submitting && this.cerrarModalCopiaSeguridadProgramada()}
          >
            {t('COPIA_SEGURIDAD.MODAL.CANCELAR')}
          </Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalCopiaSeguridadProgramada',
})(ModalCopiaSeguridadProgramada))
