import actionTypes from '../../constants/actions/dashboard/dashboard'

export function initialState () {
  return {
    porcentajeEyaculados: '',
    numEyaculados: '',
    porcentajeNoEyaculados: '',
    numNoEyaculados: '',

    porcentajeMuertos: '',
    numMuertos: '',
    porcentajelMotilidad: '',
    numlMotilidad: '',
    porcentajeFA: '',
    numFA: '',
    porcentajeConcentracion: '',
    numConcentracion: '',
    porcentajeAglutinacion: '',
    numAglutinacion: '',
    porcentajeConcentracionOtros: '',
    numConcentracionOtros: '',
    porcentajeNoInformado: '',
    numNoInformado: '',

    razaVerraco: [],
    totalVerracosRaza: '',
    totalRazas: '',

    porcentajeCuarentena: '',
    numCuarentena: '',
    porcentajePresentado: '',
    numPresentado: '',
    porcentajeActivo: '',
    numActivo: '',
    porcentajeBaja: '',
    numBaja: '',
    //porcentajeEliminado: '',
    //numEliminado: '',

    porcentaje06: '',
    num06: '',
    porcentaje6a9: '',
    num6a9: '',
    porcentaje9a12: '',
    num9a12: '',
    porcentaje12a18: '',
    num12a18: '',
    porcentaje18a24: '',
    num18a24: '',
    porcentaje24a36: '',
    num24a36: '',
    porcentajeMas36: '',
    numMas36: '',
    porcentajeSinFechaNacimiento: '',
    numSinFechaNacimiento: '',
    mediaEdad: '',

    tiposDosisProducidas: [],
    totalTipoDosis: [],

    numeroRazas: '',
    numeroVerracos: '',
    numeroLineasGeneticas: '',
    mediaDosisVerracos: '',
    dosisProducidas: '',
    verracosVigilados: '',
    numeroVerracosSinExtraer: '',
    verracosEnTratamiento: '',
    numeroPedios: '',
    dosisPedidas: '',
    extraccionesPlaning: '',
    sumaLitrosDiluyente: '',
    tareasParaHoy: [],
    stockMateriales: [],
    numDosisDescartadasEdad: [],
    graficosMostrar: []
  }
}

export function fetchDashboardSuccess (state, {datosDashboard}) {
  return {
    ...state,
    porcentajeEyaculados: datosDashboard.porcentajeEyaculados !== null ? datosDashboard.porcentajeEyaculados.eyaculadosNoEliminados : null,
    numEyaculados: datosDashboard.porcentajeEyaculados !== null ? datosDashboard.porcentajeEyaculados.numEyaculadosNoEliminados : null,
    porcentajeNoEyaculados: datosDashboard.porcentajeEyaculados !== null ? datosDashboard.porcentajeEyaculados.eyaculadosEliminados : null,
    numNoEyaculados: datosDashboard.porcentajeEyaculados !== null ? datosDashboard.porcentajeEyaculados.numEyaculadosEliminados : null,

    porcentajeMuertos: datosDashboard.razonNoUso !== null ? datosDashboard.razonNoUso.porcentajeMuertos : null,
    numMuertos: datosDashboard.razonNoUso !== null ? datosDashboard.razonNoUso.numMuertos : null,
    porcentajelMotilidad: datosDashboard.razonNoUso !== null ? datosDashboard.razonNoUso.porcentajelMotilidad : null,
    numlMotilidad: datosDashboard.razonNoUso !== null ? datosDashboard.razonNoUso.numlMotilidad : null,
    porcentajeFA: datosDashboard.razonNoUso !== null ? datosDashboard.razonNoUso.porcentajeFA : null,
    numFA: datosDashboard.razonNoUso !== null ? datosDashboard.razonNoUso.numFA : null,
    porcentajeConcentracion: datosDashboard.razonNoUso !== null ? datosDashboard.razonNoUso.porcentajeConcentracion : null,
    numConcentracion: datosDashboard.razonNoUso !== null ? datosDashboard.razonNoUso.numConcentracion : null,
    porcentajeAglutinacion: datosDashboard.razonNoUso !== null ? datosDashboard.razonNoUso.porcentajeAglutinacion : null,
    numAglutinacion: datosDashboard.razonNoUso !== null ? datosDashboard.razonNoUso.numAglutinacion : null,
    porcentajeConcentracionOtros: datosDashboard.razonNoUso !== null ? datosDashboard.razonNoUso.porcentajeConcentracionOtros : null,
    numConcentracionOtros: datosDashboard.razonNoUso !== null ? datosDashboard.razonNoUso.numConcentracionOtros : null,
    porcentajeNoInformado: datosDashboard.razonNoUso !== null ? datosDashboard.razonNoUso.porcentajeNoInformado : null,
    numNoInformado: datosDashboard.razonNoUso !== null ? datosDashboard.razonNoUso.numNoInformado : null,

    razaVerraco: datosDashboard.verracosPorRaza !== null ? datosDashboard.verracosPorRaza.razaVerraco : [],
    totalVerracosRaza: datosDashboard.verracosPorRaza !== null ? datosDashboard.verracosPorRaza.totalVerracosRaza : null,
    totalRazas: datosDashboard.verracosPorRaza !== null ? datosDashboard.verracosPorRaza.totalRazas : null,

    porcentajeCuarentena: datosDashboard.verracosPorEstado !== null ? datosDashboard.verracosPorEstado.porcentajeCuarentena : null,
    numCuarentena: datosDashboard.verracosPorEstado !== null ? datosDashboard.verracosPorEstado.verracosCuarentena : null,
    porcentajePresentado: datosDashboard.verracosPorEstado !== null ? datosDashboard.verracosPorEstado.porcentajePresentado : null,
    numPresentado: datosDashboard.verracosPorEstado !== null ? datosDashboard.verracosPorEstado.verracosPresentado : null,
    porcentajeActivo: datosDashboard.verracosPorEstado !== null ? datosDashboard.verracosPorEstado.porcentajeActivo : null,
    numActivo: datosDashboard.verracosPorEstado !== null ? datosDashboard.verracosPorEstado.verracosActivo : null,
    porcentajeBaja: datosDashboard.verracosPorEstado !== null ? datosDashboard.verracosPorEstado.porcentajeBaja : null,
    numBaja: datosDashboard.verracosPorEstado !== null ? datosDashboard.verracosPorEstado.verracosBaja : null,
    //porcentajeEliminado: datosDashboard.verracosPorEstado !== null ? datosDashboard.verracosPorEstado.porcentajeEliminado : null,
    //numEliminado: datosDashboard.verracosPorEstado !== null ? datosDashboard.verracosPorEstado.verracosEliminado : null,

    porcentaje06: datosDashboard.verracosPorEdad !== null ? datosDashboard.verracosPorEdad.porcentaje06 : null,
    num06: datosDashboard.verracosPorEdad !== null ? datosDashboard.verracosPorEdad.num06 : null,
    porcentaje6a9: datosDashboard.verracosPorEdad !== null ? datosDashboard.verracosPorEdad.porcentaje6a9 : null,
    num6a9: datosDashboard.verracosPorEdad !== null ? datosDashboard.verracosPorEdad.num6a9 : null,
    porcentaje9a12: datosDashboard.verracosPorEdad !== null ? datosDashboard.verracosPorEdad.porcentaje9a12 : null,
    num9a12: datosDashboard.verracosPorEdad !== null ? datosDashboard.verracosPorEdad.num9a12 : null,
    porcentaje12a18: datosDashboard.verracosPorEdad !== null ? datosDashboard.verracosPorEdad.porcentaje12a18 : null,
    num12a18: datosDashboard.verracosPorEdad !== null ? datosDashboard.verracosPorEdad.num12a18 : null,
    porcentaje18a24: datosDashboard.verracosPorEdad !== null ? datosDashboard.verracosPorEdad.porcentaje18a24 : null,
    num18a24: datosDashboard.verracosPorEdad !== null ? datosDashboard.verracosPorEdad.num18a24 : null,
    porcentaje24a36: datosDashboard.verracosPorEdad !== null ? datosDashboard.verracosPorEdad.porcentaje24a36 : null,
    num24a36: datosDashboard.verracosPorEdad !== null ? datosDashboard.verracosPorEdad.num24a36 : null,
    porcentajeMas36: datosDashboard.verracosPorEdad !== null ? datosDashboard.verracosPorEdad.porcentajeMas36 : null,
    numMas36: datosDashboard.verracosPorEdad !== null ? datosDashboard.verracosPorEdad.numMas36 : null,
    porcentajeSinFechaNacimiento: datosDashboard.verracosPorEdad !== null ? datosDashboard.verracosPorEdad.porcentajeSinFechaNacimiento : null,
    numSinFechaNacimiento: datosDashboard.verracosPorEdad !== null ? datosDashboard.verracosPorEdad.numSinFechaNacimiento : null,
    mediaEdad: datosDashboard.verracosPorEdad !== null ? datosDashboard.verracosPorEdad.mediaEdad : null,

    tiposDosisProducidas: datosDashboard.tiposDosisProducidas !== null ? datosDashboard.tiposDosisProducidas.tiposDosis : [],
    totalTipoDosis: datosDashboard.tiposDosisProducidas !== null ? datosDashboard.tiposDosisProducidas.totalTipoDosis : [],

    numeroRazas: datosDashboard.informacionGeneralCentro !== null ? datosDashboard.informacionGeneralCentro.numeroRazas : null,
    numeroVerracos: datosDashboard.informacionGeneralCentro !== null ? datosDashboard.informacionGeneralCentro.numeroVerracos : null,
    numeroLineasGeneticas: datosDashboard.informacionGeneralCentro !== null ? datosDashboard.informacionGeneralCentro.numeroLineasGeneticas : null,
    mediaDosisVerracos: datosDashboard.informacionGeneralCentro !== null ? datosDashboard.informacionGeneralCentro.mediaDosisVerracos : null,
    dosisProducidas: datosDashboard.informacionGeneralCentro !== null ? datosDashboard.informacionGeneralCentro.dosisProducidas : null,
    verracosVigilados: datosDashboard.informacionGeneralCentro !== null ? datosDashboard.informacionGeneralCentro.verracosVigilados : null,
    numeroVerracosSinExtraer: datosDashboard.informacionGeneralCentro !== null ? datosDashboard.informacionGeneralCentro.numeroVerracosSinExtraer : null,
    verracosEnTratamiento: datosDashboard.informacionGeneralCentro !== null ? datosDashboard.informacionGeneralCentro.verracosEnTratamiento : null,
    numeroPedios: datosDashboard.produccion_dia !== null ? datosDashboard.produccion_dia.numeroPedios : null,
    dosisPedidas: datosDashboard.produccion_dia !== null ? datosDashboard.produccion_dia.dosisPedidas : null,
    extraccionesPlaning: datosDashboard.produccion_dia !== null ? datosDashboard.produccion_dia.extraccionesPlaning : null,
    sumaLitrosDiluyente: datosDashboard.produccion_dia !== null ? datosDashboard.produccion_dia.sumaLitrosDiluyente : null,
    tareasParaHoy: datosDashboard.tareasParaHoy !== null ? datosDashboard.tareasParaHoy : [],
    stockMateriales: datosDashboard.stockMateriales !== null ? datosDashboard.stockMateriales : [],
    numDosisDescartadasEdad: datosDashboard.numDosisDescartadasEdad !== null ? datosDashboard.numDosisDescartadasEdad : [],
    graficosMostrar: datosDashboard.graficosMostrar !== null ? datosDashboard.graficosMostrar : []
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_DASHBOARD_SUCCESS:
      return fetchDashboardSuccess(state, action)
    default:
      return state
  }
}