import {select, put, call, takeLatest} from 'redux-saga/effects'
import { getFormValues } from 'redux-form';

import actionTypes from '../../constants/actions/analisisExtracciones/contajeMaquinas'
import {guardarResultadoSistemaAnalisis} from '../../actions/analisisExtracciones/analisisExtracciones'
import {openModalContajeMaquinasSuccess, closeModalContajeMaquinasSuccess} from '../../actions/analisisExtracciones/contajeMaquinas'
import getTeclasContajeService from '../../services/analisisExtracciones/getTeclasContaje'
import {yesNoModal as yesNoModalSaga} from '../modal/yesNoModal'

export function * openModalContajeMaquinas ({tipoAnalisis, isSeguimiento}) {
  try {
    const state = yield select(state => state)
    let {
      volumen, idVerraco
    } = !isSeguimiento ? yield getFormValues('formAnalisisExtraccion')(state) : {}

    if (!isSeguimiento && !idVerraco) {
      yield call(yesNoModalSaga, {
        modalType: 'verracoRequerido'
      })
    } else if (!isSeguimiento && (!volumen || parseFloat(volumen) <= 0)) {
      yield call(yesNoModalSaga, {
        modalType: 'volumenRequerido'
      })
    } else {
      let teclasContaje = yield call(getTeclasContajeService)
      teclasContaje = {
        acrosomasDannados: {
          incrementarKey: teclasContaje.sumarAcrAnormales,
          disminuirKey: teclasContaje.restarAcrAnormales
        },
        cabeza: {
          incrementarKey: teclasContaje.sumarCabezas,
          disminuirKey: teclasContaje.restarCabezas,
        },
        cola: {
          incrementarKey: teclasContaje.sumarColas,
          disminuirKey: teclasContaje.restarColas,
        },
        gotaDistal: {
          incrementarKey: teclasContaje.sumarGotaDistal,
          disminuirKey: teclasContaje.restarGotaDistal,
        },
        gotaProximal: {
          incrementarKey: teclasContaje.sumarGotaProximal,
          disminuirKey: teclasContaje.restarGotaProximal,
        },
        buenos: {
          incrementarKey: teclasContaje.sumarBuenos,
          disminuirKey: teclasContaje.restarBuenos,
        },
        cuadrosContados: {
          incrementarKey: teclasContaje.sumarCuadros,
          disminuirKey: teclasContaje.restarCuadros,
        }
      }
      yield put(openModalContajeMaquinasSuccess({tipoAnalisis, teclasContaje, isSeguimiento}))
    }
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchOpenModalContajeMaquinas () {
  yield takeLatest(actionTypes.OPEN_MODAL_CONTAJE_MAQUINAS, openModalContajeMaquinas)
}

export function * closeModalContajeMaquinas ({values}) {
  try {
    const contajeMaquinas = yield select(state => state.contajeMaquinas)
    let data = {
        acrosomasDannados: values.acrosomasDannados,
        cabeza: values.cabeza,
        cola: values.cola,
        gotaDistal: values.gotaDistal,
        gotaProximal: values.gotaProximal,
        buenos: values.buenos,
        espermatozoidesContados: values.espermatozoidesContados,
        espermatozoidesContar: values.espermatozoidesContar,
        cuadrosContados: values.cuadrosContados,
        cuadrosAContar: values.cuadrosAContar,
        totalFormasAnormales: values.totalFormasAnormales,
        acrosomas: values.acrosomas
    }
    yield put(guardarResultadoSistemaAnalisis(data, contajeMaquinas.tipoAnalisis, contajeMaquinas.isSeguimiento))
    yield put(closeModalContajeMaquinasSuccess())
  } catch (error) {
    console.log(error)
  } finally {
  }
}

export function * watchCloseModalContajeMaquinas () {
  yield takeLatest(actionTypes.CLOSE_MODAL_CONTAJE_MAQUINAS, closeModalContajeMaquinas)
}
