import actionTypes from '../../constants/actions/tipoInstalacion/tipoInstalacion'

export function fetchTipoInstalacion () {
  return {
    type: actionTypes.FETCH_TIPO_INSTALACION
  }
}

export function fetchTipoInstalacionSuccess (list) {
  return {
    type: actionTypes.FETCH_TIPO_INSTALACION_SUCCESS,
    list
  }
}

export function submitNuevaTipoInstalacion (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.SUBMIT_NUEVA_TIPO_INSTALACION,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function submitNuevaTipoInstalacionSuccess (insercionTipoInstalacion) {
  return {
    type: actionTypes.SUBMIT_NUEVA_TIPO_INSTALACION_SUCCESS,
    insercionTipoInstalacion
  }
}

export function recuperarDatosModalTipoInstalacion (idTipoInstalacion) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_TIPO_INSTALACION,
    idTipoInstalacion
  }
}

export function recuperarDatosModalTipoInstalacionSuccess (data) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_TIPO_INSTALACION_SUCCESS,
    data
  }
}

export function abrirModalVacioTipoInstalacion () {
  return {
    type: actionTypes.ABRIR_MODAL_VACIO_TIPO_INSTALACION
  }
}

export function editarTipoInstalacion (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.EDITAR_TIPO_INSTALACION,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function editarTipoInstalacionSuccess (edicionTipoInstalacion) {
  return {
    type: actionTypes.EDITAR_TIPO_INSTALACION_SUCCESS,
    edicionTipoInstalacion
  }
}

export function duplicarDatosModalTipoInstalacion (idTipoInstalacion, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_TIPO_INSTALACION,
    idTipoInstalacion,
    onSuccessCallback,
    onErrorCallback
  }
}

export function duplicarDatosModalTipoInstalacionSuccess (dataDuplicar) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_TIPO_INSTALACION_SUCCESS,
    dataDuplicar
  }
}

export function cambiarEstadoTipoInstalacion (idTipoInstalacion) {
  return {
    type: actionTypes.CAMBIAR_ESTADO_TIPO_INSTALACION,
    idTipoInstalacion
  }
}

export function cambiarEstadoTipoInstalacionSuccess (dataEstado) {
  return {
    type: actionTypes.CAMBIAR_ESTADO_TIPO_INSTALACION_SUCCESS,
    dataEstado
  }
}

export function onDeleteTipoInstalacion (idTipoInstalacion) {
  return {
    type: actionTypes.ON_DELETE_TIPO_INSTALACION,
    idTipoInstalacion
  }
}

export function onDeleteTipoInstalacionSuccess (dataDelete) {
  return {
    type: actionTypes.ON_DELETE_TIPO_INSTALACION_SUCCESS,
    dataDelete
  }
}

export function fetchFiltrarTipoInstalacion (values) {
  return {
    type: actionTypes.FETCH_FILTRAR_TIPO_INSTALACION,
    values
  }
}

export function fetchFiltrarTipoInstalacionSuccess (filtros) {
  return {
    type: actionTypes.FETCH_FILTRAR_TIPO_INSTALACION_SUCCESS,
    filtros
  }
}

export function seleccionarTodosTipoInstalacion(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_TIPO_INSTALACION
  }
}

export function deSeleccionarTodosTipoInstalacion(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_TIPO_INSTALACION
  }
}

export function cambiarEstadoSeleccionTipoInstalacion(list){
  return {
    type: actionTypes.CAMBIAR_ESTADO_SELECCION_TIPO_INSTALACION,
    list
  }
}

export function deleteSeleccionTipoInstalacion(list){
  return {
    type: actionTypes.DELETE_SELECCION_TIPO_INSTALACION,
    list
  }
}

export function guardarYnuevoTipoInstalacion(values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.GUARDAR_Y_NUEVO_TIPO_INSTALACION,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function crearCsvTipoInstalacion(list){
  return {
    type: actionTypes.CREATE_CSV_TIPO_INSTALACION,
    list
  }
}

export function crearCsvTipoInstalacionSuccess(datosExcelTipoInstalacion){
  return {
    type: actionTypes.CREATE_CSV_TIPO_INSTALACION_SUCCESS,
    datosExcelTipoInstalacion
  }
}
export function imprimirPdfTipoInstalacion(list){
  return {
    type: actionTypes.IMPRIMIR_PDF_TIPO_INSTALACION,
    list
  }
}

export function imprimirPdfTipoInstalacionSuccess(datosPdfTipoInstalacion){
  return {
    type: actionTypes.IMPRIMIR_PDF_TIPO_INSTALACION_SUCCESS,
    datosPdfTipoInstalacion
  }
}

export default {
  fetchTipoInstalacion,
  fetchTipoInstalacionSuccess,
  submitNuevaTipoInstalacion,
  recuperarDatosModalTipoInstalacion,
  recuperarDatosModalTipoInstalacionSuccess,
  abrirModalVacioTipoInstalacion,
  editarTipoInstalacion,
  editarTipoInstalacionSuccess,
  duplicarDatosModalTipoInstalacion,
  duplicarDatosModalTipoInstalacionSuccess,
  cambiarEstadoTipoInstalacion,
  cambiarEstadoTipoInstalacionSuccess,
  onDeleteTipoInstalacion,
  onDeleteTipoInstalacionSuccess,
  fetchFiltrarTipoInstalacion,
  fetchFiltrarTipoInstalacionSuccess,
  seleccionarTodosTipoInstalacion,
  deSeleccionarTodosTipoInstalacion,
  cambiarEstadoSeleccionTipoInstalacion,
  deleteSeleccionTipoInstalacion,
  crearCsvTipoInstalacion,
  guardarYnuevoTipoInstalacion,
  crearCsvTipoInstalacion,
  crearCsvTipoInstalacionSuccess,
  imprimirPdfTipoInstalacion,
  imprimirPdfTipoInstalacionSuccess
}
