export default {
    CHANGE_LOCALE: 'CHANGE_LOCALE',
    OPEN_MODAL: 'OPEN_MODAL',
    CLOSE_MODAL: 'CLOSE_MODAL',
    CUSTOM_MODAL_NOK: 'CUSTOM_MODAL_NOK',
    CUSTOM_MODAL_OK: 'CUSTOM_MODAL_OK',
    OPEN_SIMPLE_MODAL: 'OPEN_SIMPLE_MODAL',
    YES_NO_MODAL: 'YES_NO_MODAL',
    SELECCIONAR_POR_PAGINA: 'SELECCIONAR_POR_PAGINA',
    CAMBIAR_PAGINA: 'CAMBIAR_PAGINA',
    OPEN_MENSAJE_ALERTA: 'OPEN_MENSAJE_ALERTA',
    OPEN_MENSAJE_ALERTA_CUSTOM_TEXT: 'OPEN_MENSAJE_ALERTA_CUSTOM_TEXT',
    CLOSE_MENSAJE_ALERTA: 'CLOSE_MENSAJE_ALERTA'
}
