import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import { required } from '../../util/validationFunctions'
import InputText from '../comun/form/InputText'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputSelect from '../comun/form/InputSelect'
import InputNumerical from '../comun/form/InputNumerical'
import './PanelControl.scss'
import ListadoPage from '../../containers/page/ListadoPage'
import { Delete, AddCircleOutline } from '@material-ui/icons'
import {estadoVerraco as estadoVerracoConstants} from '../../constants/estadoVerraco'
import {estadoVerraco as estadoVerracoStilyzer} from '../comun/table/Stilyzers'

class ModalAnadirVerracos extends Component {

  anadirVerracos (values) {
    const itemsSeleccion = this.props.listVerracos.map((row) => {
      return this.props.formSimpleTable.values['check' + row.idElemento] === true && (
        {
          'check': this.props.formSimpleTable.values['check' + row.idElemento],
          'idElemento': row.idElemento,
          'idVerraco': row.idVerraco,
          'codVerraco':row.codVerraco,
          'nombreRaza':row.nombreRaza,
          'nombreLinea':row.nombreLinea,
          'ubicacionverraco':row.ubicacionverraco,
          'index':row.index,
          'estadoverraco':row.estadoverraco
        }
      )
    })
    let list = itemsSeleccion.filter(function (item) {
      return item.check === true;
    });
    this.props.actions.anadirVerracos(list)
    this.props.change('seleccionar-todos-modal-verraco-grupo-verraco', false)
  }

  componentDidMount () {
    this.props.actions.fetchListadoVerracos(this.props.verracosAnadidos)
  }

  cerrarModalAnadirVerracos () {
    this.props.actions.cerrarModalAnadirVerracos()
    this.props.actions.fetchListadoVerracos({})
    this.props.actions.deSeleccionarTodosVerracos()
  }

  intercambiarCheckModalVerracosGrupoVerraco (value) {
    if (value === true) {
      this.props.actions.seleccionarTodosVerracos()
    } else {
      this.props.actions.deSeleccionarTodosVerracos()
    }
  }

  render () {
    const {
      t, handleSubmit, showModalAnadirVerracos, listVerracos, checkedVerracos,numeroRegistrosPorPagina, paginaActual,
      actions: {cerrarModalAnadirVerracos}
    } = this.props

    listVerracos.forEach(
      (row) => row.idElemento = row.idVerraco
    )

    const tableVerracos = {
      id: 'verracosTable',
      multiHeader: true,
      hasCheckbox: true,
      hasOptions: false,
      columns: [
        {id: 'codVerraco', name: t('GRUPOS_VERRACO.TABLA_VERRACO.NOMBRE')},
        {id: 'nombreRaza', name: t('GRUPOS_VERRACO.TABLA_VERRACO.RAZA')},
        {id: 'nombreLinea', name: t('GRUPOS_VERRACO.TABLA_VERRACO.LINEA_GENETICA')},
        {id: 'estadoverraco', name: t('GRUPOS_VERRACO.TABLA_VERRACO.ESTADO'), stilyzer: estadoVerracoStilyzer},
        {id: 'ubicacionverraco', name: t('GRUPOS_VERRACO.TABLA_VERRACO.UBICACION')},
        {id: 'index', name: t('GRUPOS_VERRACO.TABLA_VERRACO.INDEX')}
      ],
      rows: listVerracos,
      rowsVerraco: true,
      initialValues: {},
      mostrarModificar: false,
      colorearRow: (row) => {
        switch(row.estadoverraco) {
          case estadoVerracoConstants.CUARENTENA:
            return 'verraco-en-cuarentena';
          case estadoVerracoConstants.PRESENTADO:
            return 'verraco-en-presente';
          case estadoVerracoConstants.ACTIVO:
            return 'verraco-en-activo';
          case estadoVerracoConstants.BAJA:
            return 'verraco-en-baja';
          case estadoVerracoConstants.ELIMINADO:
            return 'verraco-en-eliminado';
          default:
            return 'verraco-en-ningun-estado';
        }
      }
    }

    tableVerracos.rows.forEach(
      (row, idRow) => {
        if (idRow >= numeroRegistrosPorPagina * (paginaActual - 1) && idRow < numeroRegistrosPorPagina * paginaActual) {
          tableVerracos.initialValues['check' + row.idElemento] = checkedVerracos
        }
      }
    )

    return (
      <Modal show={showModalAnadirVerracos} onHide={() => this.cerrarModalAnadirVerracos()} aria-labelledby="contained-modal-title-lg" className="modal-anadir-colorimetro" backdrop="static">
      <form className="form-anadir-colorimetro">
        <Modal.Header closeButton>
          <Modal.Title>
          {t('GRUPOS_VERRACO.MODAL.VERRACOS_ASIGNAR')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <div className="tabla-verracos-en-grupo-verracos">
                <div className="filtros-tabla-modal-grupo-verracos col-sm-12">
                  <div className="custom-check-w">
                    <Field
                      id="seleccionar-todos-modal-verraco-grupo-verraco"
                      name="seleccionar-todos-modal-verraco-grupo-verraco"
                      inline
                      component={InputCheckBox}
                      onInputChange={(value) => this.intercambiarCheckModalVerracosGrupoVerraco(value)}
                    />
                  </div>
                </div>
                <ListadoPage t_key="GRUPOS_VERRACO." noTitle={true} table={tableVerracos}>
                </ListadoPage>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" className="btn btn-primary" onClick={handleSubmit(this.anadirVerracos.bind(this))}>{t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.TABLA.ANADIR')}</Button>
          <Button type="button" className="btn btn-white" onClick={() => this.cerrarModalAnadirVerracos()}>{t('PANEL_CONTROL.MODAL_RECTA_COLORIMETRO.CERRAR')}</Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalAnadirVerracos',
})(ModalAnadirVerracos))