import React, { Component } from 'react'
import { Modal, Row, Col, FormGroup, ControlLabel } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Warning } from '@material-ui/icons'
import Button from '../comun/button/Button'
import ButtonChangeRecord from '../comun/button/ButtonChangeRecord'
import ButtonLockable from '../comun/button/ButtonLockable'
import InputCheckBox from '../comun/form/InputCheckBox'
import InputRadioButton from '../comun/form/InputRadioButton'
import InputText from '../comun/form/InputText'
import InputNumerical from '../comun/form/InputNumerical'
import InputDatePicker from '../comun/form/InputDatePicker'
import InputTime from '../comun/form/InputTime'
import InputTextArea from '../comun/form/InputTextArea'
import InputSelect from '../comun/form/InputSelect'
import { required } from '../../util/validationFunctions'
import {
  tipoFrecuencia as tipoFrecuenciaConstants, translateTipoFrecuencia as translateTipoFrecuenciaConstants,
  tipoFrecuenciaDiaria as tipoFrecuenciaDiariaConstants
} from '../../constants/agenda'
import {permisos as permisosConstant} from '../../constants/permisos'

class ModalAgenda extends Component {
  constructor () {
    super()
    this.state = {
      tieneAviso: false,
      tieneFrecuencia: false,
      tipoFrecuencia: null,
    }
    this.guardarAgenda = this.guardarAgenda.bind(this)
    this.onDeleteAgenda = this.onDeleteAgenda.bind(this)
    this.onDeleteAgendaSerie = this.onDeleteAgendaSerie.bind(this)
    this.handleChangeAviso = this.handleChangeAviso.bind(this)
    this.seleccionarDiasAntesRadio = this.seleccionarDiasAntesRadio.bind(this)
    this.handleChangeFrecuencia = this.handleChangeFrecuencia.bind(this)
    this.handleChangeFrecuenciaDiaria = this.handleChangeFrecuenciaDiaria.bind(this)
    this.handleChangeFrecuenciaMensual = this.handleChangeFrecuenciaMensual.bind(this)
    this.handleChangeFrecuenciaAnual = this.handleChangeFrecuenciaAnual.bind(this)
    this.seleccionarFrecuenciaDiariaCadaXDias = this.seleccionarFrecuenciaDiariaCadaXDias.bind(this)
    this.seleccionarFrecuenciaMensualDiaXCadaXMes = this.seleccionarFrecuenciaMensualDiaXCadaXMes.bind(this)
    this.seleccionarFrecuenciaAnualDiaXDeCadaMes = this.seleccionarFrecuenciaAnualDiaXDeCadaMes.bind(this)
    this.seleccionarFinalizarDespuesDeRepeticiones = this.seleccionarFinalizarDespuesDeRepeticiones.bind(this)
    this.seleccionarFinalizarEl = this.seleccionarFinalizarEl.bind(this)
  }

  guardarAgenda (values) {
    return new Promise((resolve, reject) => {
      if (this.props.idIntervencion) {
        values.idIntervencion = this.props.idIntervencion
      }
      if (this.props.idBioSeguridadAnalitica) {
        values.idBioSeguridadAnalitica = this.props.idBioSeguridadAnalitica
      }
      if (this.props.idBioSeguridadLimpiezaCorral) {
        values.idBioSeguridadLimpiezaCorral = this.props.idBioSeguridadLimpiezaCorral
      }
      if (this.props.idBioSeguridadLimpiezaSilo) {
        values.idBioSeguridadLimpiezaSilo = this.props.idBioSeguridadLimpiezaSilo
      }
      if (this.props.idBioSeguridadOtro) {
        values.idBioSeguridadOtro = this.props.idBioSeguridadOtro
      }
      this.props.actions.guardarModalAgenda(values, resolve, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  onDeleteAgenda () {
    return new Promise((resolve, reject) => {
      this.props.actions.onDeleteAgenda(this.props.data.idAgenda, resolve, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  onDeleteAgendaSerie () {
    return new Promise((resolve, reject) => {
      let idAgendaEliminar
      if(this.props.data.idAgendaPadre !== null){
        idAgendaEliminar = this.props.data.idAgendaPadre
      }
      else{
        idAgendaEliminar = this.props.data.idAgenda
      }
      this.props.actions.onDeleteAgendaSerie(idAgendaEliminar, resolve, reject)
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  handleChangeAviso (values) {
    this.setState({tieneAviso: values})
    if (values) {
      this.props.change('diasAviso', '0')
    }
  }

  seleccionarDiasAntesRadio () {
    this.props.change('diasAviso', '1')
  }

  handleChangeFrecuencia (values) {
    this.setState({tieneFrecuencia: values})
    if (values) {
      this.props.change('frecuenciaIntervalo', tipoFrecuenciaConstants.DIARIA)
      this.props.change('frecuenciaDiariaCada', tipoFrecuenciaDiariaConstants.CADA_X_DIAS)
      this.props.change('frecuenciaDiaria', 1)
      this.setState({tipoFrecuencia: tipoFrecuenciaConstants.DIARIA})
      this.props.change('intervaloRepeticion', '1')
    }
  }

  handleChangeFrecuenciaDiaria (value) {
    this.setState({tipoFrecuencia: value})
    this.props.change('frecuenciaDiariaCada', tipoFrecuenciaDiariaConstants.CADA_X_DIAS)
  }

  handleChangeFrecuenciaSemanal (value) {
    this.setState({tipoFrecuencia: value})
    this.props.change('frecuenciaSemanal', 1)
  }

  handleChangeFrecuenciaMensual (value) {
    this.setState({tipoFrecuencia: value})
    this.props.change('frecuenciaMensualDiaCadaMes', 'diaCadaMes')
    this.props.change('diaMes', 1)
    this.props.change('mes', 1)
  }

  handleChangeFrecuenciaAnual (value) {
    this.setState({tipoFrecuencia: value})
    this.props.change('frecuenciaAnualDiaCadaMes', 'diaCadaAnno')
    this.props.change('anno', 1)
    this.props.change('diaMesAnno', 1)
  }

  seleccionarFrecuenciaDiariaCadaXDias () {
    this.props.change('frecuenciaDiariaCada', tipoFrecuenciaDiariaConstants.CADA_X_DIAS)
  }

  seleccionarFrecuenciaMensualDiaXCadaXMes () {
    this.props.change('frecuenciaMensualDiaCadaMes', 'diaCadaMes')
  }

  seleccionarFrecuenciaAnualDiaXDeCadaMes () {
    this.props.change('frecuenciaAnualDiaCadaMes', 'diaCadaAnno')
  }

  seleccionarFinalizarDespuesDeRepeticiones () {
    this.props.change('intervaloRepeticion', '2')
    this.props.change('intervaloRepeticiones', 1)
  }

  seleccionarFinalizarEl () {
    this.props.change('intervaloRepeticion', '3')
  }

  componentDidUpdate (prevProps) {
    if (this.props.showModalAgenda && this.props.showModalAgenda !== prevProps.showModalAgenda) {
      this.props.actions.comboOperarioPredeterminado()

      if (this.props.data) {
        let newState = {
          tieneAviso: this.props.data.aviso ? this.props.data.aviso : false,
          tieneFrecuencia: !!this.props.data.frecuenciaIntervalo,
          tipoFrecuencia: this.props.data.frecuenciaIntervalo ? this.props.data.frecuenciaIntervalo : null,
        }
        this.setState(newState)
      }

      if (this.props.tipoTarea && this.props.tipoTarea === 3) {
        this.props.change('idTipoTarea', {value: 3, label: this.props.t('COMUN.COMBOS.TIPO_TAREAS_AGENDA.INTERVENCIONES_SANITARIAS')})
        this.setState({tieneFrecuencia: '1'})
        this.props.change('intervaloRepeticion', '3')
        this.props.change('frecuenciaIntervalo', '1')
        this.setState({tipoFrecuencia: tipoFrecuenciaConstants.DIARIA})
        this.props.change('frecuenciaDiariaCada', tipoFrecuenciaDiariaConstants.CADA_X_DIAS)

      }
      if (this.props.tipoTarea && this.props.tipoTarea === 2) {
        this.props.change('idTipoTarea', {value: 2, label: this.props.t('COMUN.COMBOS.TIPO_TAREAS_AGENDA.BIOSEGURIDAD_HIGIENE')})
      }
    }
  }

  render () {
    const {
      t, handleSubmit, submitting, listTareasCalendario, showModalAgenda, data, formModalAgenda, submitFailed,
      actions: {abrirModalAgenda, closeModalAgenda}, combos: {
        comboOperarioPredeterminado, comboDiasAntes
      }
    } = this.props
    const tKey = 'AGENDA.FORM.'

    let comboTipoTareasAgenda = this.props.combos.comboTipoTareasAgenda
    comboTipoTareasAgenda = comboTipoTareasAgenda.map((tipoTarea) => {
      return {...tipoTarea, label: t('COMUN.COMBOS.' + tipoTarea.label)}
    })

    let comboMeses = this.props.combos.comboMeses
    comboMeses = comboMeses.map((tipoTarea) => {
      return {...tipoTarea, label: t(tipoTarea.label)}
    })

    return (
      <Modal show={showModalAgenda} onHide={closeModalAgenda} bsSize="large" dialogClassName="xl" backdrop="static" className="modal-agenda" >
        <form>
          <Modal.Header closeButton={!submitting}>
            <Modal.Title>{t(tKey + ((data && data.idAgenda) ? 'TITLE_MODIFICAR' : 'TITLE_NUEVO'))}</Modal.Title>
            {!submitting && data && data.idAgenda && (
              <ButtonChangeRecord list={listTareasCalendario} idElemento="id" currentId={data.idAgenda} getNextRecord={abrirModalAgenda} />
            )}
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={12}>
                <Row>
                  <Field
                    colSm={4}
                    id="nombre"
                    name="nombre"
                    controlLabel={t(tKey + 'FORM.NOMBRE') + '*'}
                    component={InputText}
                    validate={required}
                    maxLength={true}
                    valorMaxLength={80}
                  />
                  <Field
                    colSm={4}
                    id="idOperario"
                    name="idOperario"
                    controlLabel={t(tKey + 'FORM.OPERARIO')}
                    component={InputSelect}
                    options={comboOperarioPredeterminado}
                    valueKey="value"
                    labelKey="label"
                    isClearable={false}
                  />
                  <Field
                    colSm={4}
                    id="idTipoTarea"
                    name="idTipoTarea"
                    controlLabel={t(tKey + 'FORM.TIPO_TAREA')}
                    component={InputSelect}
                    options={comboTipoTareasAgenda}
                    valueKey="value"
                    labelKey="label"
                    disabled={this.props.tipoTarea && (this.props.tipoTarea === 2 || this.props.tipoTarea === 3) ? true : false}
                  />
                </Row>
              </Col>
              <Col sm={12}>
                <Row>
                  <Field
                    colSm={4}
                    id="fecha"
                    name="fecha"
                    controlLabel={t(tKey + 'FORM.FECHA') + '*'}
                    component={InputDatePicker}
                    validate={required}
                  />
                  <Field
                    colSm={4}
                    id="horaInicio"
                    name="horaInicio"
                    controlLabel={t(tKey + 'FORM.HORA_INICIO')}
                    component={InputTime}
                  />
                  <Field
                    colSm={4}
                    id="horaFin"
                    name="horaFin"
                    controlLabel={t(tKey + 'FORM.HORA_FIN')}
                    component={InputTime}
                  />
                </Row>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col sm={12}>
                <Row>
                  <Col sm={4} className="border-right merge-right-border">
                    <Row>
                      <Field
                        colSm={12}
                        id="aviso"
                        name="aviso"
                        component={InputCheckBox}
                        claseActivo="onoffswitch-label"
                        controlLabel={t(tKey + 'FORM.CREAR_AVISO')}
                        inline={true}
                        onInputChange={(values) => { this.handleChangeAviso(values) }}
                      />
                      {this.state.tieneAviso && (
                        <Col sm={11} smOffset={1}>
                          <Row>
                            <Field
                              colSm={12}
                              id="horaAviso"
                              name="horaAviso"
                              controlLabel={t(tKey + 'FORM.HORA_ALARMA') + '*'}
                              component={InputTime}
                              validate={this.state.tieneAviso ? required : null}
                            />
                            <Col sm={12}>
                              <ul>
                                <li>
                                  <Field
                                    id="diasAviso0"
                                    name="diasAviso"
                                    component={InputRadioButton}
                                    inline={true}
                                    props={{ value: "0" }}
                                    onClick={() => this.props.change('diasAnterioresAviso', '')}
                                  >&nbsp;{t(tKey + 'FORM.MISMO_DIA')}</Field>
                                </li>
                                <li className={submitFailed && formModalAgenda && formModalAgenda.diasAviso === '1' && !formModalAgenda.diasAnterioresAviso ? 'has-error' : ''}>
                                  <Field
                                    id="diasAviso1"
                                    name="diasAviso"
                                    component={InputRadioButton}
                                    inline={true}
                                    props={{ value: "1" }}
                                  >&nbsp;</Field>
                                  <Field
                                    colSm={0}
                                    id="diasAnterioresAviso"
                                    name="diasAnterioresAviso"
                                    component={InputSelect}
                                    options={comboDiasAntes}
                                    valueKey="value"
                                    labelKey="label"
                                    inline={true}
                                    onClick={() => { this.seleccionarDiasAntesRadio() }}
                                    validate={formModalAgenda && formModalAgenda.diasAviso === '1' ? required : null}
                                  />
                                  <span className="m-l-5" onClick={() => { this.seleccionarDiasAntesRadio() }}>
                                    {t(tKey + 'FORM.DIAS_ANTES') + (formModalAgenda && formModalAgenda.diasAviso === '1' ? '*' : '')}
                                    {submitFailed && formModalAgenda && formModalAgenda.diasAviso === '1' && !formModalAgenda.diasAnterioresAviso && (<label className='help-block'><Warning /></label>)}
                                  </span>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </Col>
                      )}
                    </Row>
                  </Col>
                  <Col sm={8} className="border-left">
                    <Row>
                      <Field
                        colSm={12}
                        id="tieneFrecuencia"
                        name="tieneFrecuencia"
                        component={InputCheckBox}
                        claseActivo="onoffswitch-label"
                        controlLabel={t(tKey + 'FORM.FRECUENCIA')}
                        inline={true}
                        onInputChange={(values) => { this.handleChangeFrecuencia(values) }}
                      />
                      {this.state.tieneFrecuencia && [
                        <Col sm={2} className="border-right merge-right-border" key="tipo_frecuencia">
                          <ul>
                            <li>
                              <Field
                                id="frecuenciaIntervalo1"
                                name="frecuenciaIntervalo"
                                component={InputRadioButton}
                                inline={true}
                                props={{ value: tipoFrecuenciaConstants.DIARIA }}
                                onChange={(e, value) => { this.handleChangeFrecuenciaDiaria(value) }}
                              >{t("COMUN.COMBOS." + translateTipoFrecuenciaConstants[tipoFrecuenciaConstants.DIARIA])}</Field>
                            </li>
                            <li>
                              <Field
                                id="frecuenciaIntervalo2"
                                name="frecuenciaIntervalo"
                                component={InputRadioButton}
                                inline={true}
                                props={{ value: tipoFrecuenciaConstants.SEMANAL }}
                                onChange={(e, value) => { this.handleChangeFrecuenciaSemanal(value) }}
                              >{t("COMUN.COMBOS." + translateTipoFrecuenciaConstants[tipoFrecuenciaConstants.SEMANAL])}</Field>
                            </li>
                            <li>
                              <Field
                                id="frecuenciaIntervalo3"
                                name="frecuenciaIntervalo"
                                component={InputRadioButton}
                                inline={true}
                                props={{ value: tipoFrecuenciaConstants.MENSUAL }}
                                onChange={(e, value) => { this.handleChangeFrecuenciaMensual(value) }}
                              >{t("COMUN.COMBOS." + translateTipoFrecuenciaConstants[tipoFrecuenciaConstants.MENSUAL])}</Field>
                            </li>
                            <li>
                              <Field
                                id="frecuenciaIntervalo4"
                                name="frecuenciaIntervalo"
                                component={InputRadioButton}
                                inline={true}
                                props={{ value: tipoFrecuenciaConstants.ANUAL }}
                                onChange={(e, value) => { this.handleChangeFrecuenciaAnual(value) }}
                              >{t("COMUN.COMBOS." + translateTipoFrecuenciaConstants[tipoFrecuenciaConstants.ANUAL])}</Field>
                            </li>
                          </ul>
                        </Col>,
                        (this.state.tipoFrecuencia === tipoFrecuenciaConstants.DIARIA) && (
                          <Col sm={10} className="border-left" key="bloque_frecuencia_diaria">
                            <ul>
                              <li className={submitFailed && formModalAgenda && formModalAgenda.frecuenciaDiariaCada === tipoFrecuenciaDiariaConstants.CADA_X_DIAS && !formModalAgenda.frecuenciaDiaria ? 'has-error' : ''}>
                                <Field
                                  id="frecuenciaDiariaCadaX"
                                  name="frecuenciaDiariaCada"
                                  component={InputRadioButton}
                                  inline={true}
                                  props={{ value: tipoFrecuenciaDiariaConstants.CADA_X_DIAS }}
                                >
                                  <span className="m-r-5">{t(tKey + 'FORM.CADA')}</span>
                                </Field>
                                <Field
                                  colSm={0}
                                  id="frecuenciaDiaria"
                                  name="frecuenciaDiaria"
                                  component={InputNumerical}
                                  numDecimales={0}
                                  maxValue={999}
                                  minValue={1}
                                  showOnlyInput={true}
                                  inline={true}
                                  onClick={() => { this.seleccionarFrecuenciaDiariaCadaXDias() }}
                                  validate={formModalAgenda && formModalAgenda.frecuenciaDiariaCada === tipoFrecuenciaDiariaConstants.CADA_X_DIAS ? required : null}
                                />
                                <span className="m-l-5" onClick={() => { this.seleccionarFrecuenciaDiariaCadaXDias() }}>
                                  {t(tKey + 'FORM.DIAS') + (formModalAgenda && formModalAgenda.frecuenciaDiariaCada === tipoFrecuenciaDiariaConstants.CADA_X_DIAS ? '*' : '')}
                                  {submitFailed && formModalAgenda && formModalAgenda.frecuenciaDiariaCada === tipoFrecuenciaDiariaConstants.CADA_X_DIAS && !formModalAgenda.frecuenciaDiaria && (<label className='help-block'><Warning /></label>)}
                                </span>
                              </li>
                              <li>
                                <Field
                                  id="frecuenciaDiariaCadaTodos"
                                  name="frecuenciaDiariaCada"
                                  component={InputRadioButton}
                                  inline={true}
                                  props={{ value: tipoFrecuenciaDiariaConstants.TODOS_DIAS }}
                                >
                                  <span className="m-r-5">{t(tKey + 'FORM.TODOS_DIAS_SEMANA')}</span>
                                </Field>
                              </li>
                            </ul>
                          </Col>
                        ),
                        (this.state.tipoFrecuencia === tipoFrecuenciaConstants.SEMANAL) && (
                          <Col sm={10} className="border-left" key="bloque_frecuencia_semana">
                            <Row className={submitFailed && formModalAgenda && !formModalAgenda.frecuenciaSemanal ? 'has-error' : ''}>
                              <Col sm={12}>
                                <span className="m-r-5">{t(tKey + 'FORM.REPETIR_CADA')}</span>
                                <Field
                                  colSm={0}
                                  id="frecuenciaSemanal"
                                  name="frecuenciaSemanal"
                                  component={InputNumerical}
                                  numDecimales={0}
                                  maxValue={999}
                                  minValue={1}
                                  showOnlyInput={true}
                                  inline={true}
                                  validate={formModalAgenda && formModalAgenda.frecuenciaDiariaCada === tipoFrecuenciaDiariaConstants.CADA_X_DIAS ? required : null}
                                />
                                <span className="m-l-5">
                                  {t(tKey + 'FORM.REPETIR_SEMANAS')}*
                                  {submitFailed && formModalAgenda && !formModalAgenda.frecuenciaSemanal && (<label className='help-block'><Warning /></label>)}
                                </span>
                              </Col>
                            </Row>
                            <Row className="m-t-10">
                              <Col sm={12}>
                                <Row className="row-flex">
                                  <Col sm={0} className="col-flex">
                                    <Row>
                                      <Field
                                        colSm={12}
                                        id="diasSemanaLunes"
                                        name="diasSemanaLunes"
                                        component={InputCheckBox}
                                        claseActivo="onoffswitch-label"
                                        controlLabel={t('COMUN.DATE.WEEKDAYS_LONG.LUNES')}
                                        inline={true}
                                        validate={(value, allValues, props, name) => {
                                          console.log(value, allValues, props, name)
                                        }}
                                      />
                                      <Field
                                        colSm={12}
                                        id="diasSemanaViernes"
                                        name="diasSemanaViernes"
                                        component={InputCheckBox}
                                        claseActivo="onoffswitch-label"
                                        controlLabel={t('COMUN.DATE.WEEKDAYS_LONG.VIERNES')}
                                        inline={true}
                                        validate={(value, allValues, props, name) => {
                                          console.log(value, allValues, props, name)
                                        }}
                                      />
                                    </Row>
                                  </Col>
                                  <Col sm={0} className="col-flex">
                                    <Row>
                                      <Field
                                        colSm={12}
                                        id="diasSemanaMartes"
                                        name="diasSemanaMartes"
                                        component={InputCheckBox}
                                        claseActivo="onoffswitch-label"
                                        controlLabel={t('COMUN.DATE.WEEKDAYS_LONG.MARTES')}
                                        inline={true}
                                      />
                                      <Field
                                        colSm={12}
                                        id="diasSemanaSabado"
                                        name="diasSemanaSabado"
                                        component={InputCheckBox}
                                        claseActivo="onoffswitch-label"
                                        controlLabel={t('COMUN.DATE.WEEKDAYS_LONG.SABADO')}
                                        inline={true}
                                      />
                                    </Row>
                                  </Col>
                                  <Col sm={0} className="col-flex">
                                    <Row>
                                      <Field
                                        colSm={12}
                                        id="diasSemanaMiercoles"
                                        name="diasSemanaMiercoles"
                                        component={InputCheckBox}
                                        claseActivo="onoffswitch-label"
                                        controlLabel={t('COMUN.DATE.WEEKDAYS_LONG.MIERCOLES')}
                                        inline={true}
                                      />
                                      <Field
                                        colSm={12}
                                        id="diasSemanaDomingo"
                                        name="diasSemanaDomingo"
                                        component={InputCheckBox}
                                        claseActivo="onoffswitch-label"
                                        controlLabel={t('COMUN.DATE.WEEKDAYS_LONG.DOMINGO')}
                                        inline={true}
                                      />
                                    </Row>
                                  </Col>
                                  <Col sm={0} className="col-flex">
                                    <Row>
                                      <Field
                                        colSm={12}
                                        id="diasSemanaJueves"
                                        name="diasSemanaJueves"
                                        component={InputCheckBox}
                                        claseActivo="onoffswitch-label"
                                        controlLabel={t('COMUN.DATE.WEEKDAYS_LONG.JUEVES')}
                                        inline={true}
                                      />
                                    </Row>
                                  </Col>
                                </Row>
                                <Row className={submitFailed && formModalAgenda && !formModalAgenda.frecuenciaSemanal ? 'has-error' : ''}>
                                  <Col sm={12}>
                                    {
                                      submitFailed && formModalAgenda && !formModalAgenda.diasSemanaLunes && !formModalAgenda.diasSemanaMartes &&
                                      !formModalAgenda.diasSemanaMiercoles && !formModalAgenda.diasSemanaJueves && !formModalAgenda.diasSemanaViernes &&
                                      !formModalAgenda.diasSemanaSabado && !formModalAgenda.diasSemanaDomingo && (
                                        <span><label className='help-block'><Warning /></label> {t(tKey + 'FORM.SELECCIONE_UN_DIA')}</span>
                                      )
                                    }
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        ),
                        (this.state.tipoFrecuencia === tipoFrecuenciaConstants.MENSUAL) && (
                          <Col sm={10} className="border-left" key="bloque_frecuencia_mensual">
                            <ul>
                              <li className={submitFailed && formModalAgenda && formModalAgenda.frecuenciaMensualDiaCadaMes === 'diaCadaMes' && (!formModalAgenda.diaMes || !formModalAgenda.mes) ? 'has-error' : ''}>
                                <Field
                                  id="frecuenciaMensualDiaCadaMes"
                                  name="frecuenciaMensualDiaCadaMes"
                                  component={InputRadioButton}
                                  inline={true}
                                  props={{ value: 'diaCadaMes' }}
                                >
                                  <span className="m-r-5">{t(tKey + 'FORM.EL_DIA')}</span>
                                </Field>
                                <Field
                                  colSm={0}
                                  id="diaMes"
                                  name="diaMes"
                                  component={InputNumerical}
                                  numDecimales={0}
                                  maxValue={31}
                                  minValue={1}
                                  showOnlyInput={true}
                                  inline={true}
                                  onClick={() => { this.seleccionarFrecuenciaMensualDiaXCadaXMes() }}
                                  validate={formModalAgenda && formModalAgenda.frecuenciaMensualDiaCadaMes === 'diaCadaMes' ? required : null}
                                />
                                <span className="m-l-5 m-r-5" onClick={() => { this.seleccionarFrecuenciaMensualDiaXCadaXMes() }}>{t(tKey + 'FORM.DE_CADA')}</span>
                                <Field
                                  colSm={0}
                                  id="mes"
                                  name="mes"
                                  component={InputNumerical}
                                  numDecimales={0}
                                  maxValue={12}
                                  minValue={1}
                                  showOnlyInput={true}
                                  inline={true}
                                  onClick={() => { this.seleccionarFrecuenciaMensualDiaXCadaXMes() }}
                                  validate={formModalAgenda && formModalAgenda.frecuenciaMensualDiaCadaMes === 'diaCadaMes' ? required : null}
                                />
                                <span className="m-l-5" onClick={() => { this.seleccionarFrecuenciaMensualDiaXCadaXMes() }}>
                                  {t(tKey + 'FORM.MES')}*
                                  {submitFailed && formModalAgenda && formModalAgenda.frecuenciaMensualDiaCadaMes === 'diaCadaMes' && (!formModalAgenda.diaMes || !formModalAgenda.mes) && (<label className='help-block'><Warning /></label>)}
                                </span>
                              </li>
                            </ul>
                          </Col>
                        ),
                        (this.state.tipoFrecuencia === tipoFrecuenciaConstants.ANUAL) && (
                          <Col sm={10} className="border-left" key="bloque_frecuencia_anual">
                            <ul>
                              <li className={submitFailed && formModalAgenda && formModalAgenda.frecuenciaAnualDiaCadaMes === 'diaCadaAnno' && !formModalAgenda.anno ? 'has-error' : ''}>
                                <span className="m-l-5 m-r-5">{t(tKey + 'FORM.REPETIR_CADA')}</span>
                                <Field
                                  colSm={0}
                                  id="anno"
                                  name="anno"
                                  component={InputNumerical}
                                  numDecimales={0}
                                  maxValue={31}
                                  minValue={1}
                                  showOnlyInput={true}
                                  inline={true}
                                  validate={formModalAgenda && formModalAgenda.frecuenciaIntervalo === tipoFrecuenciaConstants.ANUAL ? required : null}
                                />
                                <span className="m-l-5 m-r-5">
                                  {t(tKey + 'FORM.ANNOS')}*
                                  {submitFailed && formModalAgenda && formModalAgenda.frecuenciaAnualDiaCadaMes === 'diaCadaAnno' && !formModalAgenda.anno && (<label className='help-block'><Warning /></label>)}
                                </span>
                              </li>
                              <li className={submitFailed && formModalAgenda && formModalAgenda.frecuenciaAnualDiaCadaMes === 'diaCadaAnno' && (!formModalAgenda.diaMesAnno || !formModalAgenda.mesAnno) ? 'has-error' : ''}>
                                <Field
                                  id="frecuenciaAnualDiaCadaMes"
                                  name="frecuenciaAnualDiaCadaMes"
                                  component={InputRadioButton}
                                  inline={true}
                                  props={{ value: 'diaCadaAnno' }}
                                >
                                  <span className="m-r-5">{t(tKey + 'FORM.EL')}</span>
                                </Field>
                                <Field
                                  colSm={0}
                                  id="diaMesAnno"
                                  name="diaMesAnno"
                                  component={InputNumerical}
                                  numDecimales={0}
                                  maxValue={31}
                                  minValue={1}
                                  showOnlyInput={true}
                                  inline={true}
                                  onClick={() => { this.seleccionarFrecuenciaAnualDiaXDeCadaMes() }}
                                  validate={formModalAgenda && formModalAgenda.frecuenciaIntervalo === tipoFrecuenciaConstants.ANUAL ? required : null}
                                />
                                <span className="m-l-5 m-r-5" onClick={() => { this.seleccionarFrecuenciaAnualDiaXDeCadaMes() }}>{t(tKey + 'FORM.DE_CADA')}</span>
                                <Field
                                  colSm={0}
                                  id="mesAnno"
                                  name="mesAnno"
                                  component={InputSelect}
                                  options={comboMeses}
                                  valueKey="value"
                                  labelKey="label"
                                  inline={true}
                                  onClick={() => { this.seleccionarFrecuenciaAnualDiaXDeCadaMes() }}
                                  validate={formModalAgenda && formModalAgenda.frecuenciaIntervalo === tipoFrecuenciaConstants.ANUAL ? required : null}
                                />
                                <span className="m-l-5" onClick={() => { this.seleccionarFrecuenciaAnualDiaXDeCadaMes() }}>
                                  {t(tKey + 'FORM.MES')}*
                                  {submitFailed && formModalAgenda && formModalAgenda.frecuenciaAnualDiaCadaMes === 'diaCadaAnno' && (!formModalAgenda.diaMesAnno || !formModalAgenda.mesAnno) && (<label className='help-block'><Warning /></label>)}
                                </span>
                              </li>
                            </ul>
                          </Col>
                        )
                      ]}
                    </Row>
                    {this.state.tieneFrecuencia && [
                      <hr key="separador_frecuencia_intervalo" />,
                      <Row key="intervalo_repeticion">
                        <Col sm={12}>
                          <FormGroup>
                            <ControlLabel>{t(tKey + 'FORM.INTERVALO_REPETICION')}</ControlLabel>
                          </FormGroup>
                        </Col>
                        <Col sm={12}>
                          <Row>
                            <Field
                              colSm={5}
                              id="fechaComienzo"
                              name="fechaComienzo"
                              controlLabel={t(tKey + 'FORM.COMIENZO') + (this.state.tieneFrecuencia ? '*' : '')}
                              component={InputDatePicker}
                              validate={this.state.tieneFrecuencia ? required : null}
                            />
                            <Col sm={6}>
                              <ul>
                                <li>
                                  <Field
                                    id="intervaloRepeticion1"
                                    name="intervaloRepeticion"
                                    component={InputRadioButton}
                                    inline={true}
                                    props={{ value: '1' }}
                                  >{t(tKey + 'FORM.SIN_FECHA_FINALIZACION')}</Field>
                                </li>
                                <li className={submitFailed && formModalAgenda && formModalAgenda.intervaloRepeticion === '2' && !formModalAgenda.intervaloRepeticiones ? 'has-error' : ''}>
                                  <Field
                                    id="intervaloRepeticion2"
                                    name="intervaloRepeticion"
                                    component={InputRadioButton}
                                    inline={true}
                                    props={{ value: '2' }}
                                    onClick={() => { this.seleccionarFinalizarDespuesDeRepeticiones() }}
                                  >
                                    <span className="m-r-5">{t(tKey + 'FORM.FINALIZAR_DESPUES_DE')}</span>
                                  </Field>
                                  <Field
                                    colSm={0}
                                    id="intervaloRepeticiones"
                                    name="intervaloRepeticiones"
                                    component={InputNumerical}
                                    numDecimales={0}
                                    maxValue={999}
                                    minValue={1}
                                    showOnlyInput={true}
                                    inline={true}
                                    onClick={() => { this.seleccionarFinalizarDespuesDeRepeticiones() }}
                                    validate={formModalAgenda && formModalAgenda.intervaloRepeticion === '2' ? required : null}
                                  />
                                  <span className="m-l-5" onClick={() => { this.seleccionarFinalizarDespuesDeRepeticiones() }}>
                                    {t(tKey + 'FORM.REPETICIONES')}*
                                    {submitFailed && formModalAgenda && formModalAgenda.intervaloRepeticion === '2' && !formModalAgenda.intervaloRepeticiones && (<label className='help-block'><Warning /></label>)}
                                  </span>
                                </li>
                                <li className={submitFailed && formModalAgenda && formModalAgenda.intervaloRepeticion === '3' && !formModalAgenda.fechaFinalizacion ? 'has-error' : ''}>
                                  <Field
                                    id="intervaloRepeticion3"
                                    name="intervaloRepeticion"
                                    component={InputRadioButton}
                                    inline={true}
                                    props={{ value: '3' }}
                                  >{t(tKey + 'FORM.FINALIZAR_EL')}</Field>
                                  <Field
                                    colSm={0}
                                    id="fechaFinalizacion"
                                    name="fechaFinalizacion"
                                    component={InputDatePicker}
                                    customClass='m-l-5'
                                    showOnlyInput={true}
                                    onClick={() => { this.seleccionarFinalizarEl() }}
                                    validate={formModalAgenda && formModalAgenda.intervaloRepeticion === '3' ? required : null}
                                  />
                                  <span className="m-l-5">
                                    {submitFailed && formModalAgenda && formModalAgenda.intervaloRepeticion === '3' && !formModalAgenda.fechaFinalizacion && (<label className='help-block'><Warning /></label>)}
                                  </span>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    ]}
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <Row>
              <Field
                colSm={12}
                id="observaciones"
                name="observaciones"
                controlLabel={t(tKey + 'FORM.OBSERVACIONES')}
                component={InputTextArea}
              />
            </Row>
            {data.serie && (
              <Row>
                <Col sm={12} className="info-albaran">
                  <span><b>{t(tKey + 'FORM.PERTENECE_SERIE')}</b></span>
                </Col>
              </Row>
            )}
          </Modal.Body>
          <Modal.Footer>
            {data.serie && (
              <ButtonLockable
                processing={submitting}
                hasIcon={true}
                showLoaderOnClick={true}
                type="button"
                className="btn btn-primary btn-sm"
                disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.AGENDA] < 2) || submitting}
                onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.AGENDA] > 1) && !submitting) ? handleSubmit(this.onDeleteAgendaSerie) : () => {}}
              >
                {t(tKey + 'BUTTONS.ELIMINAR_SERIE')}
              </ButtonLockable>
            )}
            {data && data.idAgenda && (
              <ButtonLockable
                processing={submitting}
                hasIcon={true}
                showLoaderOnClick={true}
                type="button"
                className="btn btn-primary btn-sm"
                disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.AGENDA] < 2) || submitting}
                onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.AGENDA] > 1) && !submitting) ? handleSubmit(this.onDeleteAgenda) : () => {}}
              >
                {t(tKey + 'BUTTONS.ELIMINAR')}
              </ButtonLockable>
            )}
            <ButtonLockable
              processing={submitting}
              hasIcon={true}
              showLoaderOnClick={true}
              type="button"
              className="btn btn-primary btn-sm"
              disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.AGENDA] < 2) || submitting}
              onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.AGENDA] > 1) && !submitting) ? handleSubmit(this.guardarAgenda.bind(this)) : () => {}}
            >
              {t(tKey + 'BUTTONS.GUARDAR')}
            </ButtonLockable>
            <Button
            type="button"
            className={"btn btn-white btn-sm" + (submitting ? ' disabled' : '')}
            onClick={() => !submitting && closeModalAgenda()}
            >
              {t(tKey + 'BUTTONS.CERRAR')}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: 'ModalAgenda'
})(ModalAgenda))