import React, { Component } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import './ModalCambioEstadoVerraco.scss'
import {estadoVerraco, translateEstadoVerraco} from '../../constants/estadoVerraco'
import { Warning } from '@material-ui/icons'
import { required } from '../../util/validationFunctions'
import Button from '../comun/button/Button'
import ButtonChangeRecord from '../comun/button/ButtonChangeRecord'
import ButtonLockable from '../comun/button/ButtonLockable'
import InputDatePicker from '../comun/form/InputDatePicker'
import InputText from '../comun/form/InputText'
import InputSelect from '../comun/form/InputSelect'
import InputTextArea from '../comun/form/InputTextArea'
import {permisos as permisosConstant} from '../../constants/permisos'
import { dateTimeZones } from '../../util/formatFunctions'

class ModalCambioEstadoVerraco extends Component {
  constructor (props) {
    super()
    this.state = {
      eliminado: false,
      comboEstadosPermitidos: [],
      idVerraco: null,
      fecha: '',
      cambioEstadoExistente: false
    }
  }

  componentDidMount () {
    this.props.actions.comboVerraco()
  }

  handleChangeEstado (value) {
    if(value !== null){
      if (value.value === estadoVerraco.ELIMINADO){
        this.setState({eliminado: true})
      } else {
        this.setState({eliminado: false})
      }
    } else {
      this.setState({eliminado: false})
    }
  }

  nuevoCambioEstadoVerraco (values) {
    return new Promise((resolve, reject) => {
      if ( Object.keys(this.props.cambioEstadoVerraco.registroModificar).length > 0) {
        this.props.actions.editarCambioEstadoVerraco(values, resolve, reject)
      } else {
        if (this.state.eliminado === false) {
          values.causaEliminacion = null
        }
        if (this.props.idVerraco){
          values.idVerraco = this.props.idVerraco
        } else {
          values.idVerraco = values.idVerraco.value
        }
        if (this.props.esFichaVerraco == true){
          values.esFichaVerraco = this.props.esFichaVerraco
        }
        this.props.actions.submitNuevoCambioEstadoVerraco(values, resolve, reject)
      }
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  enviarFecha (fecha) {
    this.setState({fecha: fecha})
    if ( this.state.idVerraco !== '') {
      const values = {fechaCambio: fecha, idVerraco: this.state.idVerraco, idCambioEstado: this.state.idCambioEstado}
      this.props.actions.enviarFechaCambioEstado(values)
    } else {
      const values = {fechaCambio: fecha, idVerraco: this.props.idVerraco, idCambioEstado: this.state.idCambioEstado}
      this.props.actions.enviarFechaCambioEstado(values)
    }
  }

  cerrarModalCambioEstadoVerraco () {
    this.props.actions.cerrarModalCambioEstadoVerraco()
    this.props.actions.restartCambioEstadoVerraco()
    this.setState({idVerraco: null})
    this.setState({fecha: ''})
    this.setState({cambioEstadoExistente: false})
  }

  componentDidUpdate(prevProps, prevState){
    if (this.props.showModalCambioEstadoVerraco !== prevProps.showModalCambioEstadoVerraco) {
      if (this.props.showModalCambioEstadoVerraco === true) {
        if (Object.keys(this.props.cambioEstadoVerraco.registroModificar).length > 0) {
          this.setState({idVerraco: this.props.cambioEstadoVerraco.registroModificar.idVerraco})
          const values = {fechaCambio: this.props.formModalCambioEstadoVerraco.values.fechaCambioEstado, idVerraco: this.props.cambioEstadoVerraco.registroModificar.idVerraco, idCambioEstado: this.props.cambioEstadoVerraco.registroModificar.idCambioEstadoVerraco}
          this.props.actions.enviarFechaCambioEstado(values)
          this.setState({idCambioEstado: this.props.cambioEstadoVerraco.registroModificar.idCambioEstadoVerraco})
        }
        if (Object.keys(this.props.cambioEstadoVerraco.registroDuplicar).length > 0) {
          this.setState({idVerraco: this.props.cambioEstadoVerraco.registroDuplicar.idVerraco})
          const values = {fechaCambio: this.props.formModalCambioEstadoVerraco.values.fechaCambioEstado, idVerraco: this.props.cambioEstadoVerraco.registroDuplicar.idVerraco, idCambioEstado: this.props.cambioEstadoVerraco.registroModificar.idCambioEstadoVerraco}
          this.props.actions.enviarFechaCambioEstado(values)
          this.setState({idCambioEstado: this.props.cambioEstadoVerraco.registroModificar.idCambioEstadoVerraco})
        } 
        if (Object.keys(this.props.cambioEstadoVerraco.registroModificar).length === 0 && Object.keys(this.props.cambioEstadoVerraco.registroDuplicar).length === 0) {
          let hoy = dateTimeZones(this.props.auth.zonaHorariaString)
          let idVerraco
          if (this.props.idVerraco) {
            idVerraco = this.props.idVerraco
          } else {
            idVerraco = null
          }
          const values = {fechaCambio: hoy, idVerraco: idVerraco, idCambioEstado: null}
          this.props.change('fechaCambioEstado', hoy)
          this.setState({idCambioEstado: null})
          this.props.actions.enviarFechaCambioEstado(values)
        }
      }
    }

    if (Object.keys(this.props.cambioEstadoVerraco).length > 0) {
      if (Object.keys(this.props.cambioEstadoVerraco.verracoMovidoHoy).length > 0) {
        const estadosPermitidos = this.props.cambioEstadoVerraco.verracoMovidoHoy.estadosPermitidos.map((estados) => estados )
        const comboEstadosPermitidos = []
        estadosPermitidos.forEach(function (idEstados){
          comboEstadosPermitidos.push(
          {
            value: idEstados,
            label: prevProps.t(`FICHA_VERRACO.NUEVO_VERRACO.GENERAL.ESTADO_VERRACO.${translateEstadoVerraco[idEstados]}`)
          }
          )
        })
        if (this.props.cambioEstadoVerraco.verracoMovidoHoy.estadosPermitidos !== prevProps.cambioEstadoVerraco.verracoMovidoHoy.estadosPermitidos){
          this.setState({comboEstadosPermitidos: comboEstadosPermitidos})
        }
        if (this.props.cambioEstadoVerraco.verracoMovidoHoy !== prevProps.cambioEstadoVerraco.verracoMovidoHoy) {
          this.setState({cambioEstadoExistente: this.props.cambioEstadoVerraco.verracoMovidoHoy.cambioEstadoExistente})
        }

      }
    }
    if (prevProps.cambioEstadoVerraco.verracoMovidoHoy && Object.keys(this.props.cambioEstadoVerraco.registroModificar).length === 0) {
      const idEstadoVerraco = prevProps.cambioEstadoVerraco.verracoMovidoHoy.ultimoEstado
      if (idEstadoVerraco === estadoVerraco.CUARENTENA) {
        prevProps.change('estadoActual', prevProps.t(`FICHA_VERRACO.NUEVO_VERRACO.GENERAL.ESTADO_VERRACO.${translateEstadoVerraco[idEstadoVerraco]}`))
      }
      else if (idEstadoVerraco === estadoVerraco.PRESENTADO) {
        prevProps.change('estadoActual', prevProps.t(`FICHA_VERRACO.NUEVO_VERRACO.GENERAL.ESTADO_VERRACO.${translateEstadoVerraco[idEstadoVerraco]}`))
      }
      else if (idEstadoVerraco === estadoVerraco.ACTIVO) {
        prevProps.change('estadoActual', prevProps.t(`FICHA_VERRACO.NUEVO_VERRACO.GENERAL.ESTADO_VERRACO.${translateEstadoVerraco[idEstadoVerraco]}`))
      }
      else if (idEstadoVerraco === estadoVerraco.BAJA) {
        prevProps.change('estadoActual', prevProps.t(`FICHA_VERRACO.NUEVO_VERRACO.GENERAL.ESTADO_VERRACO.${translateEstadoVerraco[idEstadoVerraco]}`))
      }
      else if (idEstadoVerraco === estadoVerraco.ELIMINADO) {
        prevProps.change('estadoActual', prevProps.t(`FICHA_VERRACO.NUEVO_VERRACO.GENERAL.ESTADO_VERRACO.${translateEstadoVerraco[idEstadoVerraco]}`))
      }
      else {
        prevProps.change('estadoActual', null)
      }
    }
  }

  handleChangeVerraco (id) {
    if (id !== null){
      this.setState({idVerraco: id.value})
      this.props.change('fechaCambioEstado', dateTimeZones(this.props.auth.zonaHorariaString))
      const values = {fechaCambio: dateTimeZones(this.props.auth.zonaHorariaString), idVerraco: id.value}
      this.props.actions.enviarFechaCambioEstado(values)
    } else {
      this.setState({idVerraco: null})
      const values = {fechaCambio: dateTimeZones(this.props.auth.zonaHorariaString), idVerraco: null}
      this.props.actions.enviarFechaCambioEstado(values)
    }
  }

  render () {
    const {
      t, showModalCambioEstadoVerraco, formModalCambioEstadoVerraco, mostrarComboVerraco, handleSubmit, submitting,
      actions:{recuperarDatosModalCambioEstadoVerraco, cerrarModalCambioEstadoVerraco},
      cambioEstadoVerraco: {list, verracoMovidoHoy, registroModificar, registroDuplicar},
      combos: {comboVerraco}
    } = this.props

    let comboCausaEliminacion = this.props.combos.comboCausaEliminacion
    comboCausaEliminacion = comboCausaEliminacion.map((causaEliminacion) => {
      return {...causaEliminacion, label: t(causaEliminacion.label)}
    })

    return (
      <Modal show={showModalCambioEstadoVerraco} onHide={() => this.cerrarModalCambioEstadoVerraco()} aria-labelledby="contained-modal-title-lg" backdrop="static">
      <form className="form-cambio-estado-verraco">
        <Modal.Header closeButton={!submitting}>
          <Modal.Title>
            {
              Object.keys(registroModificar).length > 0 ? (
                t(`CAMBIO_ESTADO_VERRACOS.MODAL.TITULO_EDITAR`)
              ) : (Object.keys(registroDuplicar).length > 0 ? (
                t(`CAMBIO_ESTADO_VERRACOS.MODAL.TITULO_DUPLICAR`)
                ) : ( t(`CAMBIO_ESTADO_VERRACOS.MODAL.TITULO_NUEVO`) )
              )
            }
          </Modal.Title>
          {!submitting && registroModificar && registroModificar.idCambioEstadoVerraco && (
            <ButtonChangeRecord list={list} idElemento="idCambioEstadoVerraco" currentId={registroModificar.idCambioEstadoVerraco} getNextRecord={recuperarDatosModalCambioEstadoVerraco} />
          )}
        </Modal.Header>
        <Modal.Body>
          <Row>
            {
              Object.keys(registroModificar).length > 0 ? (
                <Col sm={12}><p><b>{t('MOVIMIENTO_VERRACOS.VERRACO') + ': ' + registroModificar.codVerraco}</b></p></Col>
              ): null
            }
          </Row>
          <Row>
            {
              this.state.cambioEstadoExistente === true ? (
                <p className="existe-cambio-estado"><Warning/>{t('CAMBIO_ESTADO_VERRACOS.MODAL.YA_EXISTE_CAMBIO_ESTADO')}</p>
              ) : null
            }
            {
              Object.keys(this.props.cambioEstadoVerraco.registroModificar).length === 0 && Object.keys(this.props.cambioEstadoVerraco.registroDuplicar).length === 0 && mostrarComboVerraco !== false ? (
                <Field
                  colSm={3}
                  id="idVerraco"
                  name="idVerraco"
                  controlLabel={t('MOVIMIENTO_VERRACOS.MODAL.VERRACO') + '*'}
                  component={InputSelect}
                  options={comboVerraco}
                  valueKey="value"
                  labelKey="label"
                  customClass='select-idVerraco'
                  validate={required}
                  onInputChange={(id) => this.handleChangeVerraco(id)}
                />
              ) : null
            }
            <Field
              id="fechaCambioEstado"
              name="fechaCambioEstado"
              // component={InputDatePicker}
              component={InputDatePicker}
              colSm={3}
              controlLabel={t(`CAMBIO_ESTADO_VERRACOS.MODAL.FECHA`) + '*'}
              onInputChange={(value) => this.enviarFecha(value)}
              // fechaPrincipio={verracoMovidoHoy && verracoMovidoHoy.fechaCuarentena !== null ? verracoMovidoHoy.fechaCuarentena : undefined}
              // fechaFin={verracoMovidoHoy && verracoMovidoHoy.fechaEliminado !== null ? verracoMovidoHoy.fechaEliminado : undefined}
              validate={required}
              disabled={(this.state.idVerraco === '' || this.state.idVerraco === undefined || this.state.idVerraco === null) && mostrarComboVerraco !== false ? true : false}
            />
            <Field
              id="estadoActual"
              name="estadoActual"
              component={InputText}
              colSm={3}
              controlLabel={Object.keys(registroModificar).length > 0 ? (t('CAMBIO_ESTADO_VERRACOS.MODAL.ESTADO_ANTERIOR')) : t(`CAMBIO_ESTADO_VERRACOS.MODAL.ESTADO_ACTUAL`)}
              disabled={true}
            />
            <Field
              colSm={3}
              id="nuevoEstado"
              name="nuevoEstado"
              controlLabel={Object.keys(registroModificar).length > 0 ? (t('CAMBIO_ESTADO_VERRACOS.MODAL.ESTADO_ACTUAL') + '*') : t('CAMBIO_ESTADO_VERRACOS.MODAL.NUEVO_ESTADO') + '*'}
              component={InputSelect}
              options={this.state.comboEstadosPermitidos}
              valueKey="value"
              labelKey="label"
              customClass='select-estado-verraco'
              onInputChange={(value) => this.handleChangeEstado(value)}
              validate={required}
              disabled={Object.keys(this.state.comboEstadosPermitidos).length > 0 ? false : true}
            />
            <Field
              colSm={3}
              id="causaEliminacion"
              name="causaEliminacion"
              controlLabel={t('CAMBIO_ESTADO_VERRACOS.MODAL.CAUSA_ELIMINACION')}
              component={InputSelect}
              options={comboCausaEliminacion}
              valueKey="value"
              labelKey="label"
              customClass={this.state.eliminado === true ? 'select-causa-eliminado-visible' : 'select-causa-eliminado-oculto'}
            />
            <Field
              colSm={12}
              id="ObservacionesCambio"
              name="ObservacionesCambio"
              component={InputTextArea}
              componentClass="textarea"
              controlLabel={t('CAMBIO_ESTADO_VERRACOS.MODAL.OBSERVACIONES')}
              customClass='observaciones-verraco'
            />
            <Field
              id="sangriaInicial"
              name="sangriaInicial"
              component={InputText}
              colSm={3}
              controlLabel={t(`CAMBIO_ESTADO_VERRACOS.MODAL.SANGRIA_INICIAL`)}
              maxLength={true}
              valorMaxLength={50}
            />
            <Field
              id="sangriaFinal"
              name="sangriaFinal"
              component={InputText}
              colSm={3}
              controlLabel={t(`CAMBIO_ESTADO_VERRACOS.MODAL.SANGRIA_FINAL`)}
              maxLength={true}
              valorMaxLength={50}
            />
            <Field
              id="pesoInicial"
              name="pesoInicial"
              component={InputText}
              colSm={3}
              controlLabel={t(`CAMBIO_ESTADO_VERRACOS.MODAL.PESO_INICIAL`)}
              maxLength={true}
              valorMaxLength={50}
            />
            <Field
              id="pesoFinal"
              name="pesoFinal"
              component={InputText}
              colSm={3}
              controlLabel={t(`CAMBIO_ESTADO_VERRACOS.MODAL.PESO_FINAL`)}
              maxLength={true}
              valorMaxLength={50}
            />
            <Field
              colSm={12}
              id="incidenciasClinicas"
              name="incidenciasClinicas"
              component={InputTextArea}
              componentClass="textarea"
              controlLabel={t('CAMBIO_ESTADO_VERRACOS.MODAL.INCIDENCIAS_CLINICAS')}
              customClass='incidencias-clinicas-verraco'
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {/*
          <Button type="button" className="btn btn-primary">{t('CAMBIO_ESTADO_VERRACOS.MODAL.GUARDAR_Y_NUEVO')}</Button>
          */}
          <ButtonLockable
            processing={submitting}
            hasIcon={true}
            showLoaderOnClick={true}
            type="button"
            className="btn btn-primary btn-sm"
            disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.CAMBIOS_ESTADO_VERRACOS] < 2) || submitting}
            onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.CAMBIOS_ESTADO_VERRACOS] > 1) && !submitting) ? handleSubmit(this.nuevoCambioEstadoVerraco.bind(this)) : () => {}}
          >
            {t('CAMBIO_ESTADO_VERRACOS.MODAL.GUARDAR')}
          </ButtonLockable>
          <Button
            type="button"
            className={"btn btn-white btn-sm" + (submitting ? ' disabled' : '')}
            onClick={() => !submitting && this.cerrarModalCambioEstadoVerraco()}
          >
            {t('CAMBIO_ESTADO_VERRACOS.MODAL.CANCELAR')}
          </Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default (reduxForm({
  form: "ModalCambioEstadoVerraco",
})(ModalCambioEstadoVerraco))
