import React, { Component } from 'react'
import { Modal, Button, Row, Col } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { required } from '../../util/validationFunctions'
import InputSelect from '../comun/form/InputSelect'
import InputDatePicker from '../comun/form/InputDatePicker'
import InputNumerical from '../comun/form/InputNumerical'
import InputText from '../comun/form/InputText'
import InputTextArea from '../comun/form/InputTextArea'
import {date_formatter} from '../../util/formatFunctions'

class ModalAlimentacion extends Component {

  componentDidMount () {
    this.props.actions.comboTipoAlimentacion()
    this.props.actions.comboOperarioActivo()
  }

  render () {
    const {
      t, showModal, dataAlimentacion,
      actions: {closeModalAlimentacion},
      combos: {comboTipoAlimentacion, comboOperarioActivo},
      auth: {formaFechaHora}
    } = this.props

    return (
      <Modal show={showModal} onHide={closeModalAlimentacion} aria-labelledby="contained-modal-title-lg" backdrop="static" bsSize="large" dialogClassName="xl" >
        <Modal.Header closeButton>
          <Modal.Title>{t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.ALIMENTACION.MODAL.TITULO')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={3}>
              <p><b>{t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.CODIGO_ALIMENTACION')}: </b>{dataAlimentacion.numeroRegistroAlimentacion}</p>
            </Col>
            <Col sm={3}>
              <p><b>{t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.TIPO_ALIMENTACION')}: </b>{dataAlimentacion.nombreTipoAlimentacion}</p>
            </Col>
            <Col sm={3}>
              <p><b>{t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.PRODUCTO')}: </b>{dataAlimentacion.producto}</p>
            </Col>
            <Col sm={3}>
              <p><b>{t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.FECHA_CADUCIDAD')}: </b>{dataAlimentacion.fechaCaducidad ? date_formatter(dataAlimentacion.fechaCaducidad, formaFechaHora, false) : null}</p>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <p><b>{t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.LOTE')}: </b>{dataAlimentacion.lote}</p>
            </Col>
            <Col sm={3}>
              <p><b>{t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.CANTIDAD')}: </b>{dataAlimentacion.cantidad}</p>
            </Col>
            <Col sm={3}>
              <p><b>{t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.OPERARIO')}: </b>{dataAlimentacion.nombreOperario}</p>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <p><b>{t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.FECHA_INICIO')}: </b>{dataAlimentacion.fechaInicio ? date_formatter(dataAlimentacion.fechaInicio, formaFechaHora, false) : null}</p>
            </Col>
            <Col sm={3}>
              <p><b>{t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.FECHA_FINAL')}: </b>{dataAlimentacion.fechaFinal ? date_formatter(dataAlimentacion.fechaFinal, formaFechaHora, false) : null}</p>
            </Col>
            <Col sm={3}>
              <p><b>{t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.TERMINADO')}: </b>{dataAlimentacion.terminado}</p>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <p><b>{t('PLAN_SANITARIO.REGISTRO_ALIMENTACION.MODAL.OBSERVACIONES')}: </b>{dataAlimentacion.observaciones}</p>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" className="btn btn-white" onClick={closeModalAlimentacion}>{t('FICHA_VERRACO.NUEVO_VERRACO.PARAMETROS.ALIMENTACION.MODAL.CANCELAR')}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default ModalAlimentacion