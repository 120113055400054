import actionTypes from '../../constants/actions/pedidosVenta/pedidosVenta'

export function abrirModalPedidosVenta () {
  return {
    type: actionTypes.ABRIR_MODAL_PEDIDOS_VENTA
  }
}

export function cerrarModalPedidosVenta () {
  return {
    type: actionTypes.CERRAR_MODAL_PEDIDOS_VENTA
  }
}

export function abrirModalPedidos () {
  return {
    type: actionTypes.ABRIR_MODAL_PEDIDOS
  }
}

export function cerrarModalPedidos () {
  return {
    type: actionTypes.CERRAR_MODAL_PEDIDOS
  }
}

export function abrirModalDatosDosis () {
  return {
    type: actionTypes.ABRIR_MODAL_DATOS_DOSIS
  }
}

export function cerrarModalDatosDosis () {
  return {
    type: actionTypes.CERRAR_MODAL_DATOS_DOSIS
  }
}

export function anadirDatosDosis (listPedidosAnadidos) {
  return {
    type: actionTypes.ANADIR_DATOS_DOSIS,
    listPedidosAnadidos
  }
}

export function recuperarDatosModalDatosDosis (idElemento) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_DATOS_DOSIS,
    idElemento
  }
}

export function recuperarDatosModalDatosDosisSuccess (dataPedidosVenta) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_DATOS_DOSIS_SUCCESS,
    dataPedidosVenta
  }
}

export function editarDatosDosis (values) {
  return {
    type: actionTypes.EDITAR_DATOS_DOSIS,
    values
  }
}

export function editarDatosDosisSuccess (listPedidosAnadidos) {
  return {
    type: actionTypes.EDITAR_DATOS_DOSIS_SUCCESS,
    listPedidosAnadidos
  }
}

export function duplicarDatosModalDatosDosis (idElemento) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_DATOS_DOSIS,
    idElemento
  }
}

export function duplicarDatosModalDatosDosisSuccess (dataPedidosVentaDuplicar) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_DATOS_DOSIS_SUCCESS,
    dataPedidosVentaDuplicar
  }
}

export function onDeleteDatosDosis (idElemento) {
  return {
    type: actionTypes.ON_DELETE_DATOS_DOSIS,
    idElemento
  }
}

export function seleccionarTodosModalPedidos () {
  return {
    type: actionTypes.SELECCIONAR_TODOS_MODAL_PEDIDOS
  }
}

export function deSeleccionarTodosModalPedidos () {
  return {
    type: actionTypes.DESELECCIONAR_TODOS_MODAL_PEDIDOS
  }
}

export function elegirClientePedidoVenta (idCliente) {
  return {
    type: actionTypes.ELEGIR_CLIENTE_PEDIDOS_VENTA,
    idCliente
  }
}

export function elegirClientePedidoVentaSuccess (comboDireccionesCliente) {
  return {
    type: actionTypes.ELEGIR_CLIENTE_PEDIDOS_VENTA_SUCCESS,
    comboDireccionesCliente
  }
}

export function anadirPedidosVenta (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.ANADIR_PEDIDOS_VENTA,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function fetchFiltrarPedidosVenta (values) {
  return {
    type: actionTypes.FETCH_FILTRAR_PEDIDOS_VENTA,
    values
  }
}

export function fetchFiltrarPedidosVentaSuccess (filtros) {
  return {
    type: actionTypes.FETCH_FILTRAR_PEDIDOS_VENTA_SUCCESS,
    filtros
  }
}

export function fetchPedidosVentaSuccess (listPedidosVenta) {
  return {
    type: actionTypes.FETCH_PEDIDOS_VENTA_SUCCESS,
    listPedidosVenta
  }
}

export function recuperarDatosModalPedidosVenta (idPedido) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_PEDIDOS_VENTA,
    idPedido
  }
}

export function recuperarDatosModalPedidosVentaSuccess (data) {
  return {
    type: actionTypes.RECUPERAR_DATOS_MODAL_PEDIDOS_VENTA_SUCCESS,
    data
  }
}

export function editarPedidosVenta (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.EDITAR_PEDIDOS_VENTA,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function duplicarDatosModalPedidosVenta (idPedido) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_PEDIDOS_VENTA,
    idPedido
  }
}

export function duplicarDatosModalPedidosVentaSuccess (dataDuplicar) {
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_PEDIDOS_VENTA_SUCCESS,
    dataDuplicar
  }
}

export function onDeletePedidosVenta (idPedido) {
  return {
    type: actionTypes.ON_DELETE_PEDIDOS_VENTA,
    idPedido
  }
}

export function seleccionarTodosPedidosVenta (idPedido) {
  return {
    type: actionTypes.SELECCIONAR_TODOS_PEDIDOS_VENTA,
    idPedido
  }
}

export function deseleccionarTodosPedidosVenta (idPedido) {
  return {
    type: actionTypes.DESELECCIONAR_TODOS_PEDIDOS_VENTA,
    idPedido
  }
}

export function deleteSeleccionPedidosVenta (list) {
  return {
    type: actionTypes.DELETE_SELECCION_PEDIDOS_VENTA,
    list
  }
}

export function exportarPedidosVenta(list){
  return {
    type: actionTypes.EXPORTAR_PEDIDOS_VENTA,
    list
  }
}

export function exportarPedidosVentaSuccess(datosExcelPedidoVenta){
  return {
    type: actionTypes.EXPORTAR_PEDIDOS_VENTA_SUCCESS,
    datosExcelPedidoVenta
  }
}

export function imprimirPedidosVenta(list){
  return {
    type: actionTypes.IMPRIMIR_PEDIDOS_VENTA,
    list
  }
}

export function imprimirPedidosVentaSuccess(datosPdfPedidoVenta){
  return {
    type: actionTypes.IMPRIMIR_PEDIDOS_VENTA_SUCCESS,
    datosPdfPedidoVenta
  }
}

export function verracosPedido(idRazaLineaGenetica){
  return {
    type: actionTypes.VERRACOS_PEDIDO,
    idRazaLineaGenetica
  }
}

export function verracosPedidoSuccess(comboVerracoPedido){
  return {
    type: actionTypes.VERRACOS_PEDIDO_SUCCESS,
    comboVerracoPedido
  }
}

export function crearAlbaranDesdePedido(){
  return {
    type: actionTypes.CREAR_ALBARAN_DESDE_PEDIDO
  }
}

export function abrirDetallesPedido (idPedido) {
  return {
    type: actionTypes.ABRIR_DETALLES_PEDIDO_SUCCESS,
    idPedido
  }
}

export function cerrarDetallesPedido () {
  return {
    type: actionTypes.CERRAR_DETALLES_PEDIDO_SUCCESS
  }
}

export function duplicarPedidoVenta (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.DUPLICAR_PEDIDO_VENTA,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export function obtenerNumeroPedido () {
  return {
    type: actionTypes.OBTENER_NUMERO_PEDIDO
  }
}

export function obtenerNumeroPedidoSuccess (numeroPedido) {
  return {
    type: actionTypes.OBTENER_NUMERO_PEDIDO_SUCCESS,
    numeroPedido
  }
}

export function elegirDireccionClientePedidoVenta (idDireccion) {
  return {
    type: actionTypes.ELEGIR_DIRECCION_CLIENTE_PEDIDOS_VENTA,
    idDireccion
  }
}

export function elegirDireccionClientePedidoVentaSuccess (comboRutaEnPedido) {
  return {
    type: actionTypes.ELEGIR_DIRECCION_CLIENTE_PEDIDOS_VENTA_SUCCESS,
    comboRutaEnPedido
  }
}

export function confirmarYnuevoPedidosVenta (values, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.CONFIRMAR_Y_NUEVO_PEDIDOS_VENTA,
    values,
    onSuccessCallback,
    onErrorCallback
  }
}

export default {
  abrirModalPedidosVenta,
  cerrarModalPedidosVenta,
  abrirModalPedidos,
  cerrarModalPedidos,
  abrirModalDatosDosis,
  cerrarModalDatosDosis,
  anadirDatosDosis,
  recuperarDatosModalDatosDosis,
  recuperarDatosModalDatosDosisSuccess,
  editarDatosDosis,
  editarDatosDosisSuccess,
  duplicarDatosModalDatosDosis,
  duplicarDatosModalDatosDosisSuccess,
  onDeleteDatosDosis,
  seleccionarTodosModalPedidos,
  deSeleccionarTodosModalPedidos,
  elegirClientePedidoVenta,
  elegirClientePedidoVentaSuccess,
  anadirPedidosVenta,
  fetchFiltrarPedidosVenta,
  fetchFiltrarPedidosVentaSuccess,
  fetchPedidosVentaSuccess,
  recuperarDatosModalPedidosVenta,
  recuperarDatosModalPedidosVentaSuccess,
  editarPedidosVenta,
  duplicarDatosModalPedidosVenta,
  duplicarDatosModalPedidosVentaSuccess,
  onDeletePedidosVenta,
  seleccionarTodosPedidosVenta,
  deseleccionarTodosPedidosVenta,
  deleteSeleccionPedidosVenta,
  exportarPedidosVenta,
  exportarPedidosVentaSuccess,
  imprimirPedidosVenta,
  imprimirPedidosVentaSuccess,
  verracosPedido,
  verracosPedidoSuccess,
  crearAlbaranDesdePedido,
  abrirDetallesPedido,
  cerrarDetallesPedido,
  duplicarPedidoVenta,
  obtenerNumeroPedido,
  obtenerNumeroPedidoSuccess,
  elegirDireccionClientePedidoVenta,
  elegirDireccionClientePedidoVentaSuccess,
  confirmarYnuevoPedidosVenta
}
