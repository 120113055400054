import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize} from 'redux-form'
import operariosActionTypes from '../../constants/actions/operario/operario'
import {fetchOperarioSuccess, recuperarDatosModalOperarioSuccess, duplicarDatosModalOperarioSuccess, cambiarEstadoOperarioSuccess, onDeleteOperarioSuccess, deSeleccionarTodosOperario,
fetchFiltrarOperarioSuccess, submitNuevaOperarioSuccess, editarOperarioSuccess, crearCsvOperarioSuccess, imprimirPdfOperarioSuccess} from '../../actions/operario/operario'
import {openModal, closeModal, openSimpleModal, openMensajeAlerta} from '../../actions/common'
import { yesNoModal } from '../modal/yesNoModal'
import getOperarioList from '../../services/operario/getOperarioList'
import addOperario from '../../services/operario/addOperario'
import getOperario from '../../services/operario/getOperario'
import editOperario from '../../services/operario/editOperario'
import getOperarioParaDuplicar from '../../services/operario/getOperarioParaDuplicar'
import getOperarioCambioEstado from '../../services/operario/getOperarioCambioEstado'
import deleteOperario from '../../services/operario/deleteOperario'
import getFiltrarOperario from '../../services/operario/getFiltrarOperario'
import getCambiarEstadoSeleccion from '../../services/operario/getCambiarEstadoSeleccion'
import getDeleteSeleccion from '../../services/operario/getDeleteSeleccion'
import getCrearCsv from '../../services/operario/getCrearCsv'
import getCambiarOperarioProcesado from '../../services/operario/getCambiarOperarioProcesado'
import getCambiarOperarioAlbaran from '../../services/operario/getCambiarOperarioAlbaran'
import getImprimirPdfOperario from '../../services/operario/getImprimirPdfOperario'
import {comboNombresOperarios, comboApellidosOperarios, comboLocalidadOperarios} from '../../actions/combos/combos'

export function * fetchOperario () {
  try {
    const list = yield call(getOperarioList, null)
    yield put(fetchOperarioSuccess(list))
  } catch (error) {

  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchFetchOperario () {
  yield takeLatest(operariosActionTypes.FETCH_OPERARIO, fetchOperario)
}

export function * submitNuevaOperario ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const insercionOperario = yield call(addOperario, values, auth.token)
    yield put(submitNuevaOperarioSuccess(insercionOperario))
    if (insercionOperario.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-insercion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    yield put(closeModal())
    const val = yield select(state => state.operario.filtros)
    const list = yield call(getFiltrarOperario, val, auth.token)
    yield put(fetchOperarioSuccess(list))
    yield put(fetchFiltrarOperarioSuccess(val))
    let valoresFiltros = yield select(state => state.operario.filtros)
    yield put(initialize('FiltrosOperario', valoresFiltros))
    yield put(comboNombresOperarios())
    yield put(comboApellidosOperarios())
    yield put(comboLocalidadOperarios())
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /**/
  }
}

export function * watchSubmitNuevaOperario () {
  yield takeLatest(operariosActionTypes.SUBMIT_NUEVA_OPERARIO, submitNuevaOperario)
}

export function * recuperarDatosModalOperario ({idOperario}) {
  try {
    // let auth = yield select(state => state.auth)
    const registroModificar = yield call(getOperario, idOperario)
    yield put(recuperarDatosModalOperarioSuccess(registroModificar))
    if (registroModificar.nombreUsuario !== null) {
      registroModificar.usuario = {value: registroModificar.idUsuario, label: registroModificar.nombreUsuario}
    }
    yield put(initialize('ModalOperario', registroModificar))
    yield put(openModal())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}

export function * watchRecuperarDatosModalOperario () {
  yield takeLatest(operariosActionTypes.RECUPERAR_DATOS_MODAL_OPERARIO, recuperarDatosModalOperario)
}

export function * abrirModalVacioOperario () {
  try {
    yield put(recuperarDatosModalOperarioSuccess(null))
    const valores = {activo: true}
    yield put(initialize('ModalOperario', valores))
    yield put(openModal())
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}

export function * watchAbrirModalVacioOperario () {
  yield takeLatest(operariosActionTypes.ABRIR_MODAL_VACIO_OPERARIO, abrirModalVacioOperario)
}

export function * editarOperario ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const edicionOperario = yield call(editOperario, values, auth.token)
    yield put(editarOperarioSuccess(edicionOperario))
    if (edicionOperario.actualizado === true) {
      yield put(openMensajeAlerta('modificacion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-modificacion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    yield put(closeModal())
    yield put(comboNombresOperarios())
    yield put(comboApellidosOperarios())
    yield put(comboLocalidadOperarios())
    const val = yield select(state => state.operario.filtros)
    if (val.nombreOperario && values.nombreOperario){
      val.nombreOperario = {value: val.nombreOperario.value, label: values.nombreOperario}
    }
    if (val.apellidosOperario && values.apellidosOperario){
      val.apellidosOperario = {value: val.apellidosOperario.value, label: values.apellidosOperario}
    }
    if (val.localidadOperario && values.localidadOperario){
      val.localidadOperario = {value: val.localidadOperario.value, label: values.localidadOperario}
    }
    const list = yield call(getFiltrarOperario, val, auth.token)
    yield put(fetchOperarioSuccess(list))
    yield put(fetchFiltrarOperarioSuccess(val))
    let valoresFiltros = yield select(state => state.operario.filtros)
    yield put(initialize('FiltrosOperario', valoresFiltros))
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /**/
  }
}

export function * watchEditarOperario () {
  yield takeLatest(operariosActionTypes.EDITAR_OPERARIO, editarOperario)
}

export function * duplicarDatosModalOperario ({idOperario, onSuccessCallback, onErrorCallback}) {
  try {
    // let auth = yield select(state => state.auth)
    yield put(recuperarDatosModalOperarioSuccess(null))
    const duplicar = yield call(getOperarioParaDuplicar, idOperario)
    yield put(duplicarDatosModalOperarioSuccess(duplicar))
    yield put(initialize('ModalOperario', duplicar))
    yield put(openModal())
    if (onSuccessCallback) yield onSuccessCallback()
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /* */
  }
}

export function * watchDuplicarDatosModalOperario () {
  yield takeLatest(operariosActionTypes.DUPLICAR_DATOS_MODAL_OPERARIO, duplicarDatosModalOperario)
}

export function * cambiarEstadoOperario ({idOperario}) {
  try {
    yield put(openSimpleModal('cambiarEstado'))
    const confirmed = yield call(yesNoModal, {modalType: 'cambiarEstado'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const dataEstado = yield call(getOperarioCambioEstado, idOperario, auth.token)
      yield put(cambiarEstadoOperarioSuccess(dataEstado))
      if (dataEstado.actualizado === true) {
        yield put(openMensajeAlerta('cambio-estado'))
      } else {
        yield put(openMensajeAlerta('no-cambio-estado'))
      }
      const val = yield select(state => state.operario.filtros)
      const list = yield call(getFiltrarOperario, val, auth.token)
      yield put(fetchOperarioSuccess(list))
      yield put(fetchFiltrarOperarioSuccess(val))
      let valoresFiltros = yield select(state => state.operario.filtros)
      yield put(initialize('FiltrosOperario', valoresFiltros))
    }

  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}

export function * watchCambiarEstadoOperario () {
  yield takeLatest(operariosActionTypes.CAMBIAR_ESTADO_OPERARIO, cambiarEstadoOperario)
}

export function * onDeleteOperario ({idOperario, nombreOperario, apellidosOperario, localidadOperario}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const dataDelete = yield call(deleteOperario, idOperario, auth.token)
      yield put(onDeleteOperarioSuccess(dataDelete))
      if (dataDelete.eliminado === true && dataDelete.asociado === false) {
        yield put(openMensajeAlerta('eliminacion'))
      } else if (dataDelete.eliminado === false && dataDelete.asociado === true) {
        yield put(openMensajeAlerta('no-eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(comboNombresOperarios())
      yield put(comboApellidosOperarios())
      yield put(comboLocalidadOperarios())
      const val = yield select(state => state.operario.filtros)
      if (val.nombreOperario && val.nombreOperario.value === nombreOperario) {
        val.nombreOperario = null
      }
      if (val.apellidosOperario && val.apellidosOperario.value === apellidosOperario) {
        val.apellidosOperario = null
      }
      if (val.localidadOperario && val.localidadOperario.value === localidadOperario) {
        val.localidadOperario = null
      }
      const lista = yield call(getFiltrarOperario, val, auth.token)
      yield put(fetchOperarioSuccess(lista))
      yield put(fetchFiltrarOperarioSuccess(val))
      let valoresFiltros = yield select(state => state.operario.filtros)
      yield put(initialize('FiltrosOperario', valoresFiltros))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}

export function * watchOnDeleteOperario () {
  yield takeLatest(operariosActionTypes.ON_DELETE_OPERARIO, onDeleteOperario)
}

export function * fetchFiltrarOperario ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const list = yield call(getFiltrarOperario, values, auth.token)
    yield put(fetchOperarioSuccess(list))
    yield put(fetchFiltrarOperarioSuccess(values))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchFetchFiltrarOperario () {
  yield takeLatest(operariosActionTypes.FETCH_FILTRAR_OPERARIO, fetchFiltrarOperario)
}

export function * cambiarEstadoSeleccionOperario ({list}) {
  try {
    yield put(openSimpleModal('cambiarEstado'))
    const confirmed = yield call(yesNoModal, {modalType: 'cambiarEstado'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      let dataEstadoSeleccion = yield call(getCambiarEstadoSeleccion, list, auth.token)
      if (dataEstadoSeleccion.actualizado === true) {
        yield put(openMensajeAlerta('cambio-estado'))
      } else {
        yield put(openMensajeAlerta('no-cambio-estado'))
      }
      yield put(deSeleccionarTodosOperario())
      yield put(initialize('FiltrosOperario'))
      yield put(initialize('simpleTable'))
      /*const inicializaTabla = yield call(fetchOperario)
      yield put(initialize('simpleTable', inicializaTabla))*/
      const val = yield select(state => state.operario.filtros)
      const lista = yield call(getFiltrarOperario, val, auth.token)
      yield put(fetchOperarioSuccess(lista))
      yield put(fetchFiltrarOperarioSuccess(val))
      let valoresFiltros = yield select(state => state.operario.filtros)
      yield put(initialize('FiltrosOperario', valoresFiltros))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchCambiarEstadoSeleccionOperario () {
  yield takeLatest(operariosActionTypes.CAMBIAR_ESTADO_SELECCION_OPERARIO, cambiarEstadoSeleccionOperario)
}

export function * deleteSeleccionOperario ({list}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      let dataDelete = yield call(getDeleteSeleccion, list, auth.token)
      if (dataDelete.eliminado === true && dataDelete.asociado === false) {
        yield put(openMensajeAlerta('eliminacion'))
      } else if (dataDelete.eliminado === false && dataDelete.asociado === true) {
        yield put(openMensajeAlerta('no-eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(initialize('simpleTable'))
      yield put(deSeleccionarTodosOperario())
      yield put(initialize('FiltrosOperario'))
      yield put(comboNombresOperarios())
      yield put(comboApellidosOperarios())
      yield put(comboLocalidadOperarios())
      const val = yield select(state => state.operario.filtros)
      list.forEach((li) => {
        if (val.nombreOperario && val.nombreOperario.value === li.nombreOperario) {
          val.nombreOperario = null
        }
        if (val.apellidosOperario && val.apellidosOperario.value === li.apellidosOperario) {
          val.apellidosOperario = null
        }
        if (val.localidadOperario && val.localidadOperario.value === li.localidadOperario) {
          val.localidadOperario = null
        }
      })
      const lista = yield call(getFiltrarOperario, val, auth.token)
      yield put(fetchOperarioSuccess(lista))
      yield put(fetchFiltrarOperarioSuccess(val))
      let valoresFiltros = yield select(state => state.operario.filtros)
      yield put(initialize('FiltrosOperario', valoresFiltros))
    }
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchDeleteSeleccionOperario () {
  yield takeLatest(operariosActionTypes.DELETE_SELECCION_OPERARIO, deleteSeleccionOperario)
}

export function * crearCsvOperario ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosExcelOperario = yield call(getCrearCsv, list, auth.token)
    yield put(crearCsvOperarioSuccess(datosExcelOperario))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchCrearCsvOperario () {
  yield takeLatest(operariosActionTypes.CREATE_CSV_OPERARIO, crearCsvOperario)
}

export function * imprimirPdfOperario ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosExcelOperario = yield call(getImprimirPdfOperario, list, auth.token)
    yield put(imprimirPdfOperarioSuccess(datosExcelOperario))
  } catch (error) {
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}
export function * watchImprimirPdfOperario () {
  yield takeLatest(operariosActionTypes.IMPRIMIR_PDF_OPERARIO, imprimirPdfOperario)
}

export function * guardarYnuevoOperario ({values, onSuccessCallback, onErrorCallback}) {
  console.log(values)
  try {
    let auth = yield select(state => state.auth)
    const insercionOperario = yield call(addOperario, values, auth.token)
    const valores = {activo: true}
    yield put(initialize('ModalOperario', valores))
    if (insercionOperario.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-insercion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    const val = yield select(state => state.operario.filtros)
    const lista = yield call(getFiltrarOperario, val, auth.token)
    yield put(fetchOperarioSuccess(lista))
    yield put(fetchFiltrarOperarioSuccess(val))
    yield put(comboNombresOperarios())
    yield put(comboApellidosOperarios())
    yield put(comboLocalidadOperarios())
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
    console.log(error)
  } finally {
    /*yield put(commonActions.hideLoader())*/
  }
}

export function * watchGuardarYnuevoOperario () {
  yield takeLatest(operariosActionTypes.GUARDAR_Y_NUEVO_OPERARIO, guardarYnuevoOperario)
}

export function * cambiarOperarioProcesado ({idOperario}) {
  try {
    yield put(openSimpleModal('cambiarOperarioProcesado'))
    const confirmed = yield call(yesNoModal, {modalType: 'cambiarOperarioProcesado'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const operarioProcesado = yield call(getCambiarOperarioProcesado, idOperario, auth.token)
      if (operarioProcesado.actualizado === true) {
        yield put(openMensajeAlerta('operario-procesado'))
      } else {
        yield put(openMensajeAlerta('no-operario-procesado'))
      }
      const val = yield select(state => state.operario.filtros)
      const lista = yield call(getFiltrarOperario, val, auth.token)
      yield put(fetchOperarioSuccess(lista))
      yield put(fetchFiltrarOperarioSuccess(val))
      let valoresFiltros = yield select(state => state.operario.filtros)
      yield put(initialize('FiltrosOperario', valoresFiltros))
    }

  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}

export function * watchCambiarOperarioProcesado () {
  yield takeLatest(operariosActionTypes.CAMBIAR_OPERARIO_PROCESADO, cambiarOperarioProcesado)
}

export function * cambiarOperarioAlbaran ({idOperario}) {
  try {
    yield put(openSimpleModal('cambiarOperarioAlbaran'))
    const confirmed = yield call(yesNoModal, {modalType: 'cambiarOperarioAlbaran'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const operarioAlbaran = yield call(getCambiarOperarioAlbaran, idOperario, auth.token)
      if (operarioAlbaran.actualizado === true) {
        yield put(openMensajeAlerta('operario-albaran'))
      } else {
        yield put(openMensajeAlerta('no-operario-albaran'))
      }
      const val = yield select(state => state.operario.filtros)
      const lista = yield call(getFiltrarOperario, val, auth.token)
      yield put(fetchOperarioSuccess(lista))
      yield put(fetchFiltrarOperarioSuccess(val))
      let valoresFiltros = yield select(state => state.operario.filtros)
      yield put(initialize('FiltrosOperario', valoresFiltros))
    }

  } catch (error) {
    console.log(error)
  } finally {
    /* */
  }
}

export function * watchCambiarOperarioAlbaran () {
  yield takeLatest(operariosActionTypes.CAMBIAR_OPERARIO_ALBARAN, cambiarOperarioAlbaran)
}
