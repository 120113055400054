import actionTypes from '../../constants/actions/dashboard/dashboard'

export function fetchDashboard () {
  return {
    type: actionTypes.FETCH_DASHBOARD
  }
}

export function fetchDashboardSuccess (datosDashboard) {
  return {
    type: actionTypes.FETCH_DASHBOARD_SUCCESS,
    datosDashboard
  }
}

export default {
  fetchDashboard,
  fetchDashboardSuccess
}