import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { Col } from 'react-bootstrap'

class Footer extends PureComponent {
  render () {
    const { t, sm } = this.props
    return (
      <Col sm={sm} componentClass="footer" >
        <p>
          <a className="footer-link" href="https://magapor.com/" target="_blank" rel="noopener noreferrer">
            {t('FOOTER.TEXTO_PIE')}
          </a>
        </p>
      </Col>
    )
  }
}

export default Footer
