import actionTypes from '../../constants/actions/albaran/albaran'

export function fetchAlbaranes(filtros){
  return {
    type: actionTypes.FETCH_ALBARANES,
    filtros
  }
}

export function fetchAlbaranesSuccess(list, filtros){
  return {
    type: actionTypes.FETCH_ALBARANES_SUCCESS,
    list,
    filtros
  }
}

export function seleccionarTodosAlbaranes(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_ALBARANES
  }
}

export function deseleccionarTodosAlbaranes(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_ALBARANES
  }
}

export function onDeleteAlbaran(idAlbaran) {
  return {
    type: actionTypes.ON_DELETE_ALBARAN,
    idAlbaran
  }
}

export function deleteSeleccionAlbaranes(list){
  return {
    type: actionTypes.DELETE_SELECCION_ALBARANES,
    list
  }
}

export function crearCsvAlbaranes(list){
  return {
    type: actionTypes.CREATE_CSV_ALBARANES,
    list
  }
}

export function imprimirPdfAlbaranes(list){
  return {
    type: actionTypes.IMPRIMIR_PDF_ALBARANES,
    list
  }
}

export function abrirModalVacioAlbaran () {
  return {
    type: actionTypes.ABRIR_MODAL_VACIO_ALBARAN
  }
}

export function abrirModalVacioAlbaranSuccess () {
  return {
    type: actionTypes.ABRIR_MODAL_VACIO_ALBARAN_SUCCESS
  }
}

export function abrirDetallesAlbaran (idAlbaran) {
  return {
    type: actionTypes.ABRIR_DETALLES_ALBARAN,
    idAlbaran
  }
}

export function abrirDetallesAlbaranSuccess (data) {
  return {
    type: actionTypes.ABRIR_DETALLES_ALBARAN_SUCCESS,
    data
  }
}

export function duplicarDatosModalAlbaran(idAlbaran){
  return {
    type: actionTypes.DUPLICAR_DATOS_MODAL_ALBARAN,
    idAlbaran
  }
}

export function cerrarDetallesAlbaran () {
  return {
    type: actionTypes.CERRAR_DETALLES_ALBARAN
  }
}

export function cerrarDetallesAlbaranSuccess () {
  return {
    type: actionTypes.CERRAR_DETALLES_ALBARAN_SUCCESS
  }
}

export function editarAlbaran (values, mostrarMensaje, callback, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.EDITAR_ALBARAN,
    values,
    mostrarMensaje,
    callback,
    onSuccessCallback,
    onErrorCallback
  }
}

export function seleccionarTodosModalAlbaranDosis(){
  return {
    type: actionTypes.SELECCIONAR_TODOS_MODAL_ALBARAN_DOSIS
  }
}

export function deseleccionarTodosModalAlbaranDosis(){
  return {
    type: actionTypes.DESELECCIONAR_TODOS_MODAL_ALBARAN_DOSIS
  }
}

export function deleteModalAlbaranDosis(idAlbaranDosis) {
  return {
    type: actionTypes.DELETE_MODAL_ALBARAN_DOSIS,
    idAlbaranDosis
  }
}

export function deleteSeleccionModalAlbaranDosis(list){
  return {
    type: actionTypes.DELETE_SELECCION_MODAL_ALBARAN_DOSIS,
    list
  }
}

export function abrirModalVacioAlbaranDosis(){
  return {
    type: actionTypes.ABRIR_MODAL_VACIO_ALBARAN_DOSIS
  }
}

export function abrirModalVacioAlbaranDosisSuccess(){
  return {
    type: actionTypes.ABRIR_MODAL_VACIO_ALBARAN_DOSIS_SUCCESS
  }
}

export function abrirDetallesAlbaranDosis (row) {
  return {
    type: actionTypes.ABRIR_DETALLES_ALBARAN_DOSIS,
    row
  }
}

export function abrirDetallesAlbaranDosisSuccess (data) {
  return {
    type: actionTypes.ABRIR_DETALLES_ALBARAN_DOSIS_SUCCESS,
    data
  }
}

export function cerrarDetallesAlbaranDosis () {
  return {
    type: actionTypes.CERRAR_DETALLES_ALBARAN_DOSIS
  }
}

export function cerrarDetallesAlbaranDosisSuccess () {
  return {
    type: actionTypes.CERRAR_DETALLES_ALBARAN_DOSIS_SUCCESS
  }
}

export function editarAlbaranDosis (values, guardarYnuevo, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.EDITAR_ALBARAN_DOSIS,
    values,
    guardarYnuevo,
    onSuccessCallback,
    onErrorCallback
  }
}

export function editarAlbaranDosisSuccess (list) {
  return {
    type: actionTypes.EDITAR_ALBARAN_DOSIS_SUCCESS,
    list
  }
}

export function calcTotalesAlbaranDosis () {
  return {
    type: actionTypes.CALC_TOTALES_ALBARAN_DOSIS
  }
}

export function calcTotalesAlbaranDosisSuccess (totalesList) {
  return {
    type: actionTypes.CALC_TOTALES_ALBARAN_DOSIS_SUCCESS,
    totalesList
  }
}

export function crearAlbaranDesdePedidoSuccess (data) {
  return {
    type: actionTypes.CREAR_ALBARANES_DESDE_PEDIDO_SUCCESS,
    data
  }
}
export function editarAlbaranEImprimirDatosAlbaran(values, idAlbaran, numeroAlbaran, mostrarMensaje, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.EDITAR_ALBARAN_E_IMPRIMIR_DATOS_ALBARAN,
    values,
    idAlbaran,
    numeroAlbaran,
    mostrarMensaje,
    onSuccessCallback,
    onErrorCallback
  }
}

export function imprimirDatosAlbaran(values, idAlbaran, numeroAlbaran, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.IMPRIMIR_DATOS_ALBARAN,
    values,
    idAlbaran,
    numeroAlbaran,
    onSuccessCallback,
    onErrorCallback
  }
}

export function imprimirDatosAlbaranCliente(values, idAlbaran, numeroAlbaran, onSuccessCallback, onErrorCallback) {
  return {
    type: actionTypes.IMPRIMIR_DATOS_ALBARAN_CLIENTE,
    values,
    idAlbaran,
    numeroAlbaran,
    onSuccessCallback,
    onErrorCallback
  }
}

export default {
  fetchAlbaranes,
  fetchAlbaranesSuccess,
  seleccionarTodosAlbaranes,
  deseleccionarTodosAlbaranes,
  onDeleteAlbaran,
  deleteSeleccionAlbaranes,
  crearCsvAlbaranes,
  imprimirPdfAlbaranes,
  abrirModalVacioAlbaran,
  abrirModalVacioAlbaranSuccess,
  abrirDetallesAlbaran,
  abrirDetallesAlbaranSuccess,
  duplicarDatosModalAlbaran,
  cerrarDetallesAlbaran,
  cerrarDetallesAlbaranSuccess,
  editarAlbaran,
  seleccionarTodosModalAlbaranDosis,
  deseleccionarTodosModalAlbaranDosis,
  deleteModalAlbaranDosis,
  deleteSeleccionModalAlbaranDosis,
  abrirModalVacioAlbaranDosis,
  abrirModalVacioAlbaranDosisSuccess,
  abrirDetallesAlbaranDosis,
  abrirDetallesAlbaranDosisSuccess,
  cerrarDetallesAlbaranDosis,
  cerrarDetallesAlbaranDosisSuccess,
  editarAlbaranDosis,
  editarAlbaranDosisSuccess,
  calcTotalesAlbaranDosis,
  calcTotalesAlbaranDosisSuccess,
  crearAlbaranDesdePedidoSuccess,
  editarAlbaranEImprimirDatosAlbaran,
  imprimirDatosAlbaran,
  imprimirDatosAlbaranCliente
}
