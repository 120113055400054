export default {
  ABRIR_MODAL_ENTRADA_MERCANCIA: 'ABRIR_MODAL_ENTRADA_MERCANCIA',
  CLOSE_MODAL_ENTRADA_MERCANCIA: 'CLOSE_MODAL_ENTRADA_MERCANCIA',
  SUBMIT_NUEVO_ENTRADA_MERCANCIA: 'SUBMIT_NUEVO_ENTRADA_MERCANCIA',
  SUBMIT_NUEVO_ENTRADA_MERCANCIA_SUCCESS: 'SUBMIT_NUEVO_ENTRADA_MERCANCIA_SUCCESS',
  OBTENER_ENTRADA_MERCANCIAS_MERCANCIA: 'OBTENER_ENTRADA_MERCANCIAS_MERCANCIA',
  FILTRAR_ENTRADA_MERCANCIA: 'FILTRAR_ENTRADA_MERCANCIA',
  FILTRAR_ENTRADA_MERCANCIA_SUCCESS: 'FILTRAR_ENTRADA_MERCANCIA_SUCCESS',
  FILTROS_ENTRADA_MERCANCIA_SUCCESS: 'FILTROS_ENTRADA_MERCANCIA_SUCCESS',
  RECUPERAR_DATOS_MODAL_ENTRADA_MERCANCIA: 'RECUPERAR_DATOS_MODAL_ENTRADA_MERCANCIA',
  RECUPERAR_DATOS_MODAL_ENTRADA_MERCANCIA_SUCCESS: 'RECUPERAR_DATOS_MODAL_ENTRADA_MERCANCIA_SUCCESS',
  EDITAR_ENTRADA_MERCANCIA: 'EDITAR_ENTRADA_MERCANCIA',
  DUPLICAR_DATOS_MODAL_ENTRADA_MERCANCIA: 'DUPLICAR_DATOS_MODAL_ENTRADA_MERCANCIA',
  DUPLICAR_DATOS_MODAL_ENTRADA_MERCANCIA_SUCCESS: 'DUPLICAR_DATOS_MODAL_ENTRADA_MERCANCIA_SUCCESS',
  CAMBIAR_ESTADO_ENTRADA_MERCANCIA: 'CAMBIAR_ESTADO_ENTRADA_MERCANCIA',
  ON_DELETE_ENTRADA_MERCANCIA: 'ON_DELETE_ENTRADA_MERCANCIA',
  SELECCIONAR_TODOS_ENTRADA_MERCANCIA: 'SELECCIONAR_TODOS_ENTRADA_MERCANCIA',
  DESELECCIONAR_TODOS_ENTRADA_MERCANCIA: 'DESELECCIONAR_TODOS_ENTRADA_MERCANCIA',
  CAMBIAR_ESTADO_SELECCION_ENTRADA_MERCANCIA: 'CAMBIAR_ESTADO_SELECCION_ENTRADA_MERCANCIA',
  DELETE_SELECCION_ENTRADA_MERCANCIA: 'DELETE_SELECCION_ENTRADA_MERCANCIA',
  CREATE_CSV_ENTRADA_MERCANCIA: 'CREATE_CSV_ENTRADA_MERCANCIA',
  CREATE_CSV_ENTRADA_MERCANCIA_SUCCESS: 'CREATE_CSV_ENTRADA_MERCANCIA_SUCCESS',
  IMPRIMIR_PDF_ENTRADA_MERCANCIA: 'IMPRIMIR_PDF_ENTRADA_MERCANCIA',
  IMPRIMIR_PDF_ENTRADA_MERCANCIA_SUCCESS: 'IMPRIMIR_PDF_ENTRADA_MERCANCIA_SUCCESS',
  ABRIR_MODAL_ENTRADA_MERCANCIA_ANADIR_PRODUCTO: 'ABRIR_MODAL_ENTRADA_MERCANCIA_ANADIR_PRODUCTO',
  CERRAR_MODAL_ENTRADA_MERCANCIA_ANADIR_PRODUCTO: 'CERRAR_MODAL_ENTRADA_MERCANCIA_ANADIR_PRODUCTO',
  SELECCIONAR_TODOS_ENTRADA_MERCANCIA_PRODUCTO: 'SELECCIONAR_TODOS_ENTRADA_MERCANCIA_PRODUCTO',
  DESELECCIONAR_TODOS_ENTRADA_MERCANCIA_PRODUCTO: 'DESELECCIONAR_TODOS_ENTRADA_MERCANCIA_PRODUCTO',
  ANADIR_PRODUCTO_ENTRADA_MERCANCIA: 'ANADIR_PRODUCTO_ENTRADA_MERCANCIA',
  RECUPERAR_MODAL_ENTRADA_MERCANCIA_PRODUCTO_ANADIDO: 'RECUPERAR_MODAL_ENTRADA_MERCANCIA_PRODUCTO_ANADIDO',
  RECUPERAR_MODAL_ENTRADA_MERCANCIA_PRODUCTO_ANADIDO_SUCCESS: 'RECUPERAR_MODAL_ENTRADA_MERCANCIA_PRODUCTO_ANADIDO_SUCCESS',
  EDITAR_ENTRADA_MERCANCIA_PRODUCTO: 'EDITAR_ENTRADA_MERCANCIA_PRODUCTO',
  EDITAR_ENTRADA_MERCANCIA_PRODUCTO_SUCCESS: 'EDITAR_ENTRADA_MERCANCIA_PRODUCTO_SUCCESS',
  DUPLICAR_MODAL_ENTRADA_MERCANCIA_PRODUCTO_ANADIDO: 'DUPLICAR_MODAL_ENTRADA_MERCANCIA_PRODUCTO_ANADIDO',
  DUPLICAR_MODAL_ENTRADA_MERCANCIA_PRODUCTO_ANADIDO_SUCCESS: 'DUPLICAR_MODAL_ENTRADA_MERCANCIA_PRODUCTO_ANADIDO_SUCCESS',
  ON_DELETE_ENTRADA_MERCANCIA_PRODUCTO: 'ON_DELETE_ENTRADA_MERCANCIA_PRODUCTO',
  GUARDAR_Y_NUEVO_ENTRADA_MERCANCIA_PRODUCTO: 'GUARDAR_Y_NUEVO_ENTRADA_MERCANCIA_PRODUCTO',
  GUARDAR_Y_NUEVO_ENTRADA_MERCANCIA: 'GUARDAR_Y_NUEVO_ENTRADA_MERCANCIA',
  GUARDAR_Y_NUEVO_ENTRADA_MERCANCIA_SUCCESS: 'GUARDAR_Y_NUEVO_ENTRADA_MERCANCIA_SUCCESS',
  RESET_DATOS_ENTRADA_MERCANCIA_PRODUCTO: 'RESET_DATOS_ENTRADA_MERCANCIA_PRODUCTO',
  DELETE_SELECCION_ENTRADA_MERCANCIA_PRODUCTO_ANADIDO: 'DELETE_SELECCION_ENTRADA_MERCANCIA_PRODUCTO_ANADIDO',
  DELETE_SELECCION_ENTRADA_MERCANCIA_PRODUCTO_ANADIDO_SUCCESS: 'DELETE_SELECCION_ENTRADA_MERCANCIA_PRODUCTO_ANADIDO_SUCCESS',
  FETCH_LOTE_EXISTENTE: 'FETCH_LOTE_EXISTENTE',
  FETCH_LOTE_EXISTENTE_SUCCESS: 'FETCH_LOTE_EXISTENTE_SUCCESS'
}