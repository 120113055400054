import React, { Component } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import {reduxForm, Field} from 'redux-form'
import { Info } from '@material-ui/icons'
import { required } from '../../util/validationFunctions'
import Button from '../comun/button/Button'
import ButtonLockable from '../comun/button/ButtonLockable'
import FormSubtitle from '../comun/form/FormSubtitle'
import SimpleTablePage from '../../containers/comun/table/SimpleTablePage'
import InputText from '../comun/form/InputText'
import InputNumerical from '../comun/form/InputNumerical'
import InputSelect from '../comun/form/InputSelect'
import InputDatePicker from '../comun/form/InputDatePicker'
import './EntradaMercancia.scss'
import FiltrosEntradaMercanciaProductoPage from '../../containers/entradaMercancia/FiltrosEntradaMercanciaProductoPage'
import {permisos as permisosConstant} from '../../constants/permisos'

class ModalEntradaMercanciaAnadirProducto extends Component {
  constructor () {
    super()
    this.state = {
      mostrarEnviarAviso: false,
      fechaObligatoria: false,
      loteObligatorio: false
    }
    this.handleChangeProducto = this.handleChangeProducto.bind(this)
    this.handleChangeLote = this.handleChangeLote.bind(this)
  }

  componentDidMount () {
    this.props.actions.comboProductoEscandallo()
  }

  componentDidUpdate (prevProps) {
    if (this.props.showModalEntradaMercanciaProducto !== prevProps.showModalEntradaMercanciaProducto) {
      if (Object.keys(this.props.dataProducto).length > 0){
        this.props.change('nombre', {value: this.props.dataProducto.idProducto, label: this.props.dataProducto.nombre})
      }
      if (Object.keys(this.props.dataProductoDuplicar).length > 0){
        this.props.change('nombre', {value: this.props.dataProductoDuplicar.idProducto, label: this.props.dataProductoDuplicar.nombre})
      }
      let producto
      if (Object.keys(this.props.combos.comboProductoEscandallo).length > 0 && Object.keys(this.props.dataProducto).length > 0) {
        producto = this.props.combos.comboProductoEscandallo.filter((productoEscandallo) => {
          if (productoEscandallo.value === this.props.dataProducto.idProducto) {
            return productoEscandallo
          }
        })
        if (producto.length > 0) {
          if (producto[0].trazabilidad === true) {
            this.setState({loteObligatorio: true})
          } else {
            this.setState({loteObligatorio: false})
          }
          if (producto[0].caducidad === true) {
            this.setState({fechaObligatoria: true})
          } else {
            this.setState({fechaObligatoria: false})
          }
        }
      }
    }
    if (this.props.dataProductoDuplicar !== prevProps.dataProductoDuplicar) {
      if (Object.keys(this.props.dataProductoDuplicar).length > 0){
        this.props.change('nombre', {value: this.props.dataProductoDuplicar.idProducto, label: this.props.dataProductoDuplicar.nombre})
      }
    }
    if (this.props.dataProducto !== prevProps.dataProducto) {
      if (Object.keys(this.props.dataProducto).length > 0){
        this.props.change('nombre', {value: this.props.dataProducto.idProducto, label: this.props.dataProducto.nombre})
      }
    }
  }

  cerrarModalEntradaMercanciaAnadirProducto () {
    this.props.actions.cerrarModalEntradaMercanciaAnadirProducto()
    this.setState({mostrarEnviarAviso: false})
    this.setState({loteObligatorio: false})
    this.setState({fechaObligatoria: false})
  }

  anadirProductoEntradaMercancia (values) {
    return new Promise((resolve, reject) => {
      values.idProducto = values.nombre.value
      values.nombre = values.nombre.label
      // values.caducidad = values.caducidad
      if (Object.keys(this.props.dataProducto).length > 0){
        this.props.actions.editarEntradaMercanciaProducto(values, resolve, reject)
      } else {
        this.props.actions.anadirProductoEntradaMercancia(values, resolve, reject)
      }
      this.setState({mostrarEnviarAviso: false})
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  guardarYnuevoEntradaMercanciaProducto (values) {
    return new Promise((resolve, reject) => {
      values.idProducto = values.nombre.value
      values.nombre = values.nombre.label
      values.caducidad = values.caducidad
      if (Object.keys(this.props.dataProducto).length > 0){
        this.props.actions.editarEntradaMercanciaProducto(values, resolve, reject)
      } else {
        this.props.actions.guardarYnuevoEntradaMercanciaProducto(values, resolve, reject)
      }
      this.setState({mostrarEnviarAviso: false})
    })
    .then(() => { console.log('completado') })
    .catch(() => { console.log('error') })
  }

  duplicar(values){
    return new Promise((resolve, reject) => {
      this.props.duplicar(values, resolve, reject)
    })
      .then(() => { console.log('completado') })
      .catch(() => { console.log('error') })
  }

  handleChangeProducto (value) {
    if (value.caducidad === true) {
      this.setState({fechaObligatoria: true})
    } else {
      this.setState({fechaObligatoria: false})
    }

    if (value.trazabilidad === true) {
      this.setState({loteObligatorio: true})
      if (Object.keys(this.props.dataProducto).length === 0 && Object.keys(this.props.dataProductoDuplicar).length === 0) {
        let date = new Date()
        let lote = 'REF' + date.getFullYear() + (date.getMonth()+1 < 10 ? '0' : '') + (date.getMonth()+1) + (date.getDate() < 10 ? '0' : '') + date.getDate() + (date.getHours() < 10 ? '0' : '') + date.getHours() + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() + (date.getSeconds() < 10 ? '0' : '') + date.getSeconds()
        this.props.change('lote', lote)
      }
    } else {
      if (Object.keys(this.props.dataProducto).length === 0 && Object.keys(this.props.dataProductoDuplicar).length === 0) {
        this.props.change('lote', '')
      }
      this.setState({loteObligatorio: false})
    }
    this.props.actions.fetchLoteExistenteSuccess(null)
  }

  handleChangeLote (val) {
    if (val && this.props.ModalEntradaMercanciaAnadirProducto.nombre && this.props.ModalEntradaMercanciaAnadirProducto.nombre.value) {
      this.props.actions.fetchLoteExistente(this.props.ModalEntradaMercanciaAnadirProducto.nombre.value, val)
    }
  }

  render () {
    const {
      t, handleSubmit, submitting, showModalEntradaMercanciaProducto, dataProducto, numeroRegistrosPorPagina, paginaActual,
      duplicar, datosLoteExistente, checkedFiltrosEntradaMercanciaProducto,
      combos: {comboProductoEscandallo}
    } = this.props

    return (
      <div>
        <Modal show={showModalEntradaMercanciaProducto} onHide={() => this.cerrarModalEntradaMercanciaAnadirProducto()} bsSize="large" backdrop="static" aria-labelledby="contained-modal-title-lg" className="modal-producto-entrada-mercancia">
        <form className="form-entradaMercancia">
          <Modal.Header closeButton>
            <Modal.Title>
            {
              Object.keys(this.props.dataProducto).length > 0 ? (
                t('ALMACEN.ENTRADAS_MERCANCIA.MODAL.TITLE_PRODUCTO_EDITAR')
              ) : (
                Object.keys(this.props.dataProductoDuplicar).length > 0 ? (
                  t('ALMACEN.ENTRADAS_MERCANCIA.MODAL.TITLE_PRODUCTO_DUPLICAR')
                ) : (
                  t('ALMACEN.ENTRADAS_MERCANCIA.MODAL.TITLE_PRODUCTO_NUEVO')
                )
              )
            }
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Field
                colSm={3}
                id="nombre"
                name="nombre"
                component={InputSelect}
                options={comboProductoEscandallo}
                controlLabel={t('ALMACEN.ENTRADAS_MERCANCIA.MODAL.PRODUCTO') + '*'}
                customClass="producto"
                validate={required}
                onInputChange={(value) => this.handleChangeProducto(value)}
                isClearable={false}
                valueKey="value"
                labelKey="label"
                disabled={Object.keys(dataProducto).length > 0 && dataProducto.editable === false ? true : false}
              />
              <Field
                colSm={2}
                id="cantidad"
                name="cantidad"
                component={InputNumerical}
                controlLabel={t('ALMACEN.ENTRADAS_MERCANCIA.MODAL.CANTIDAD') + '*'}
                customClass="cantidad"
                validate={required}
                disabled={Object.keys(dataProducto).length > 0 && dataProducto.editable === false ? true : false}
              />
              {/*
              <Field
                colSm={2}
                id="stockProduccion"
                name="stockProduccion"
                component={InputText}
                controlLabel={t('ALMACEN.ENTRADAS_MERCANCIA.MODAL.STOCK_PRODUCCION') + '*'}
                customClass="stockProduccion"
                validate={required}
                disabled={Object.keys(dataProducto).length > 0 && dataProducto.editable === false ? true : false}
              />*/}
              <Field
                colSm={3}
                id="lote"
                name="lote"
                component={InputText}
                controlLabel={this.state.loteObligatorio === true ? (t('ALMACEN.ENTRADAS_MERCANCIA.MODAL.LOTE') + '*') : (t('ALMACEN.ENTRADAS_MERCANCIA.MODAL.LOTE'))}
                customClass="lote"
                onInputChange={(val) => this.handleChangeLote(val)}
                onInputChangeDelay={true}
                validate={this.state.loteObligatorio === true ? required : null}
                disabled={Object.keys(dataProducto).length > 0 && dataProducto.editable === false ? true : false}
                maxLength={true}
                valorMaxLength={50}
              />
              <Field
                colSm={2}
                id="fechaCaducidad"
                name="fechaCaducidad"
                component={InputDatePicker}
                controlLabel={this.state.fechaObligatoria === true ? (t('ALMACEN.ENTRADAS_MERCANCIA.MODAL.CADUCIDAD') + '*') : (t('ALMACEN.ENTRADAS_MERCANCIA.MODAL.CADUCIDAD'))}
                customClass="fecha"
                validate={this.state.fechaObligatoria === true ? required : null}
                disabled={Object.keys(dataProducto).length > 0 && dataProducto.editable === false ? true : false}
              />
              <div className="clearfix"></div>
            </Row>
            {datosLoteExistente && (
              <Row>
                <Col sm={12}>
                  <span className="text-info bold">
                    <Info /> {t('ALMACEN.ENTRADAS_MERCANCIA.MODAL.MENSAJE_LOTE_EXISTENTE')}
                  </span>
                </Col>
              </Row>
            )}
          </Modal.Body>
          <Modal.Footer>
            {
              Object.keys(this.props.dataProducto).length > 0 ? (
                <Button
                  type="button"
                  className="btn btn-white btn-sm"
                  disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.ENTRADAS_MERCANCIAS] < 2) || submitting}
                  onClick={(this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ENTRADAS_MERCANCIAS] > 1) && !submitting ? handleSubmit(this.duplicar.bind(this)) : undefined}
                >
                  {t('ALMACEN.ENTRADAS_MERCANCIA.MODAL.DUPLICAR')}
                </Button>
              ) : (
                <ButtonLockable
                  processing={submitting}
                  hasIcon={true}
                  showLoaderOnClick={true}
                  type="button"
                  className="btn btn-primary btn-sm"
                  disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.ENTRADAS_MERCANCIAS] < 2) || submitting}
                  onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ENTRADAS_MERCANCIAS] > 1) && !submitting) ? handleSubmit(this.guardarYnuevoEntradaMercanciaProducto.bind(this)) : () => {}}
                >
                  {t('ALMACEN.ENTRADAS_MERCANCIA.MODAL.ACEPTAR_Y_NUEVO')}
                </ButtonLockable>
              )
            }
            <ButtonLockable
              processing={submitting}
              hasIcon={true}
              showLoaderOnClick={true}
              type="button"
              className="btn btn-primary btn-sm"
              disabled={(this.props.auth.permisos !== '-1' && this.props.auth.permisos[permisosConstant.ENTRADAS_MERCANCIAS] < 2) || submitting}
              onClick={((this.props.auth.permisos === '-1' || this.props.auth.permisos[permisosConstant.ENTRADAS_MERCANCIAS] > 1) && !submitting) ? handleSubmit(this.anadirProductoEntradaMercancia.bind(this)) : () => {}}
            >
              {t('ALMACEN.ENTRADAS_MERCANCIA.MODAL.ACEPTAR')}
            </ButtonLockable>
            <Button
              type="button"
              className={"btn btn-white btn-sm" + (submitting ? ' disabled' : '')}
              onClick={() => !submitting && this.cerrarModalEntradaMercanciaAnadirProducto()}
            >
              {t('ALMACEN.ENTRADAS_MERCANCIA.MODAL.CANCELAR')}
            </Button>
          </Modal.Footer>
          </form>
        </Modal>
      </div>
    )
  }
}

export default (reduxForm({
  form: 'ModalEntradaMercanciaAnadirProducto',
})(ModalEntradaMercanciaAnadirProducto))