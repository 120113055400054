import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import ModalOperario from '../../components/operario/ModalOperario'
import {recuperarDatosModalOperario, submitNuevaOperario, editarOperario, guardarYnuevoOperario} from '../../actions/operario/operario'
import {closeModal} from '../../actions/common'
import {comboProvinciasClientes, comboUsuariosNoAsignadosOperario} from '../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.operario,
    showModal: state.common.showModal,
    combos: state.combos,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      recuperarDatosModalOperario,
      submitNuevaOperario,
      editarOperario,
      closeModal,
      guardarYnuevoOperario,
      comboProvinciasClientes,
      comboUsuariosNoAsignadosOperario
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalOperario))