import {call, put, takeLatest, select} from 'redux-saga/effects'
import {initialize} from 'redux-form'
import i18n from '../../i18n'
import actionTypes from '../../constants/actions/pedidosVenta/pedidosVenta'
import { abrirModalDatosDosis, recuperarDatosModalDatosDosisSuccess, editarDatosDosisSuccess, duplicarDatosModalDatosDosisSuccess, fetchPedidosVentaSuccess, fetchFiltrarPedidosVentaSuccess,
  elegirClientePedidoVentaSuccess, cerrarModalPedidosVenta, recuperarDatosModalPedidosVentaSuccess, abrirModalPedidosVenta, duplicarDatosModalPedidosVentaSuccess,
  exportarPedidosVentaSuccess, imprimirPedidosVentaSuccess, verracosPedidoSuccess, obtenerNumeroPedidoSuccess, elegirDireccionClientePedidoVentaSuccess
} from '../../actions/pedidosVenta/pedidosVenta'
import {dateTimeZones} from '../../util/formatFunctions'
import {crearAlbaranDesdePedidoSuccess, abrirModalVacioAlbaranSuccess} from '../../actions/albaran/albaran'
import {openSimpleModal, openMensajeAlerta} from '../../actions/common'
import { yesNoModal } from '../modal/yesNoModal'
import addPedidoVenta from '../../services/pedidosVenta/addPedidoVenta'
import getFiltrarPedidosVenta from '../../services/pedidosVenta/getFiltrarPedidosVenta'
import getDireccionesCliente from '../../services/pedidosVenta/getDireccionesCliente'
import getPedidoVenta from '../../services/pedidosVenta/getPedidoVenta'
import editarPedido from '../../services/pedidosVenta/editarPedido'
import eliminarPedido from '../../services/pedidosVenta/eliminarPedido'
import eliminarPedidoSeleccion from '../../services/pedidosVenta/eliminarPedidoSeleccion'
import getExportarPedidosVenta from '../../services/pedidosVenta/getExportarPedidosVenta'
import getImprimirPedidoVenta from '../../services/pedidosVenta/getImprimirPedidoVenta'
import getVerracosPedido from '../../services/pedidosVenta/verracosPedido'
import fetchNumeroAlbaranService from '../../services/albaran/fetchNumeroAlbaran'
import duplicarPedido from '../../services/pedidosVenta/duplicarPedido'
import getObtenerNumeroPedido from '../../services/pedidosVenta/getObtenerNumeroPedido'
import getRutasEnPedido from '../../services/pedidosVenta/getRutasEnPedido'

export function * recuperarDatosModalDatosDosis ({idElemento}) {
  try {
    const datosDosisState = yield select(state => state.pedidosVenta.listPedidosAnadidos)
    const datosDosis = datosDosisState.filter((datos) => {
      if (datos.idElemento === idElemento) {
        return datos
      }
    })
    const dataPedidosVenta = {
      verraco: {label: datosDosis[0].verraco, value: datosDosis[0].idVerraco},
      raza: {label: datosDosis[0].raza, value: datosDosis[0].idRaza},
      lineaGenetica: {label: datosDosis[0].lineaGenetica, value: datosDosis[0].idLineaGenetica},
      tipoDosis: {label: datosDosis[0].tipoDosis, value: datosDosis[0].idTipoDosis},
      numeroDosis: datosDosis[0].numeroDosis,
      idElemento: datosDosis[0].idElemento
    }
    yield put(initialize('ModalDatosDosis', dataPedidosVenta))
    yield put(recuperarDatosModalDatosDosisSuccess(dataPedidosVenta))
    yield put(abrirModalDatosDosis())
  } catch (error) {

  } finally {
    
  }
}
export function * watchRecuperarDatosModalDatosDosis () {
  yield takeLatest(actionTypes.RECUPERAR_DATOS_MODAL_DATOS_DOSIS, recuperarDatosModalDatosDosis)
}

export function * editarDatosDosis ({values}) {
  try {
    const datosDosisState = yield select(state => state.pedidosVenta.listPedidosAnadidos)
    const datosDosis = datosDosisState.map((datos) => {
      if (datos.idElemento === values.idElemento) {
        datos = values
      }
      return datos
    })
    yield put(editarDatosDosisSuccess(datosDosis))
  } catch (error) {

  } finally {
    
  }
}
export function * watchEditarDatosModalDatosDosis () {
  yield takeLatest(actionTypes.EDITAR_DATOS_DOSIS, editarDatosDosis)
}

export function * duplicarDatosModalDatosDosis ({idElemento}) {
  try {
    const datosDosisState = yield select(state => state.pedidosVenta.listPedidosAnadidos)
    const datosDosis = datosDosisState.filter((datos) => {
      if (datos.idElemento === idElemento) {
        return datos
      }
    })
    const dataPedidosVentaDuplicar = {
      verraco: {label: datosDosis[0].verraco, value: datosDosis[0].idVerraco},
      raza: {label: datosDosis[0].raza, value: datosDosis[0].idRaza},
      lineaGenetica: {label: datosDosis[0].lineaGenetica, value: datosDosis[0].idLineaGenetica},
      tipoDosis: {label: datosDosis[0].tipoDosis, value: datosDosis[0].idTipoDosis},
      numeroDosis: datosDosis[0].numeroDosis,
      idElemento: datosDosis[0].idElemento
    }
    yield put(initialize('ModalDatosDosis', dataPedidosVentaDuplicar))
    yield put(duplicarDatosModalDatosDosisSuccess(dataPedidosVentaDuplicar))
    yield put(abrirModalDatosDosis())
  } catch (error) {

  } finally {
    
  }
}
export function * watchDuplicarDatosModalDatosDosis () {
  yield takeLatest(actionTypes.DUPLICAR_DATOS_MODAL_DATOS_DOSIS, duplicarDatosModalDatosDosis)
}

export function * onDeleteDatosDosis ({idElemento}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      const datosDosisState = yield select(state => state.pedidosVenta.listPedidosAnadidos)
      const datosDosis = datosDosisState.filter((datos) => {
        if (datos.idElemento !== idElemento) {
          return datos
        }
      })
      yield put(editarDatosDosisSuccess(datosDosis))
    }
  } catch (error) {

  } finally {
    
  }
}
export function * watchOnDeleteDatosDosis () {
  yield takeLatest(actionTypes.ON_DELETE_DATOS_DOSIS, onDeleteDatosDosis)
}

export function * elegirClientePedidoVenta ({idCliente}) {
  try {
    const comboDireccionesCliente = yield call(getDireccionesCliente, idCliente, null)
    yield put(elegirClientePedidoVentaSuccess(comboDireccionesCliente))
  } catch (error) {

  } finally {
    
  }
}
export function * watchElegirClientePedidoVenta () {
  yield takeLatest(actionTypes.ELEGIR_CLIENTE_PEDIDOS_VENTA, elegirClientePedidoVenta)
}

export function * anadirPedidosVenta ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const insercionPedidoVenta = yield call(addPedidoVenta, values, auth.token)
    if (insercionPedidoVenta.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-insercion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    const val = yield select(state => state.pedidosVenta.filtros)
    const listPedidosVenta = yield call(getFiltrarPedidosVenta, val, auth.token)
    yield put(fetchPedidosVentaSuccess(listPedidosVenta))
    yield put(fetchFiltrarPedidosVentaSuccess(val))
    yield put(cerrarModalPedidosVenta())
    yield put(elegirDireccionClientePedidoVentaSuccess([]))
    yield put(initialize('ModalPedidosVenta', {fecha: new Date()}))
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
  } finally {
    
  }
}
export function * watchAnadirPedidosVenta () {
  yield takeLatest(actionTypes.ANADIR_PEDIDOS_VENTA, anadirPedidosVenta)
}

export function * fetchFiltrarPedidosVenta ({values}) {
  try {
    let auth = yield select(state => state.auth)
    const listPedidosVenta = yield call(getFiltrarPedidosVenta, values, auth.token)
    yield put(fetchPedidosVentaSuccess(listPedidosVenta))
    yield put(fetchFiltrarPedidosVentaSuccess(values))
  } catch (error) {

  } finally {
    
  }
}
export function * watchFetchFiltrarPedidosVenta () {
  yield takeLatest(actionTypes.FETCH_FILTRAR_PEDIDOS_VENTA, fetchFiltrarPedidosVenta)
}

export function * recuperarDatosModalPedidosVenta ({idPedido}) {
  try {
    const data = yield call(getPedidoVenta, idPedido, null)
    yield put(recuperarDatosModalPedidosVentaSuccess(data))
    data.pedido.cliente = {value: data.pedido.idCliente, label: data.pedido.cliente}
    data.pedido.idDireccionCliente = {value: data.pedido.idDireccionCliente, label: data.pedido.direccionCliente}
    data.pedido.operario = {value: data.pedido.idOperario, label: data.pedido.nombreOperario}
    data.pedido.ruta = {value: data.pedido.idRuta, label: data.pedido.nombreRuta}
    data.pedido.numeroPedido = data.pedido.numeroPedido
    yield put(initialize('ModalPedidosVenta', data.pedido))
    yield put(editarDatosDosisSuccess(data.lineasPedido))
    yield put(abrirModalPedidosVenta())
  } catch (error) {

  } finally {
    
  }
}
export function * watchRecuperarDatosModalPedidosVenta () {
  yield takeLatest(actionTypes.RECUPERAR_DATOS_MODAL_PEDIDOS_VENTA, recuperarDatosModalPedidosVenta)
}

export function * editarPedidosVenta ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const modificacionPedidoVenta = yield call(editarPedido, values, auth.token)
    if (modificacionPedidoVenta.actualizado === true) {
      yield put(openMensajeAlerta('modificacion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-modificacion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    const val = yield select(state => state.pedidosVenta.filtros)
    const listPedidosVenta = yield call(getFiltrarPedidosVenta, val, auth.token)
    yield put(fetchPedidosVentaSuccess(listPedidosVenta))
    yield put(fetchFiltrarPedidosVentaSuccess(val))
    yield put(cerrarModalPedidosVenta())
    yield put(elegirDireccionClientePedidoVentaSuccess([]))
    yield put(initialize('ModalPedidosVenta', {fecha: new Date()}))
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
  } finally {
    
  }
}
export function * watchEditarPedidosVenta () {
  yield takeLatest(actionTypes.EDITAR_PEDIDOS_VENTA, editarPedidosVenta)
}

export function * duplicarDatosModalPedidosVenta ({idPedido}) {
  try {
    const dataDuplicar = yield call(getPedidoVenta, idPedido, null)
    yield put(duplicarDatosModalPedidosVentaSuccess(dataDuplicar))
    dataDuplicar.pedido.cliente = {value: dataDuplicar.pedido.idCliente, label: dataDuplicar.pedido.cliente}
    dataDuplicar.pedido.idDireccionCliente = {value: dataDuplicar.pedido.idDireccionCliente, label: dataDuplicar.pedido.direccionCliente}
    dataDuplicar.pedido.operario = {value: dataDuplicar.pedido.idOperario, label: dataDuplicar.pedido.nombreOperario}
    dataDuplicar.pedido.ruta = {value: dataDuplicar.pedido.idRuta, label: dataDuplicar.pedido.nombreRuta}
    dataDuplicar.pedido.numeroPedido = dataDuplicar.pedido.numeroPedido
    yield put(initialize('ModalPedidosVenta', dataDuplicar.pedido))
    yield put(editarDatosDosisSuccess(dataDuplicar.lineasPedido))
    yield put(abrirModalPedidosVenta())
  } catch (error) {

  } finally {
    
  }
}
export function * watchDuplicarDatosModalPedidosVenta () {
  yield takeLatest(actionTypes.DUPLICAR_DATOS_MODAL_PEDIDOS_VENTA, duplicarDatosModalPedidosVenta)
}

export function * onDeletePedidosVenta ({idPedido}) {
  try {
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const eliminacionPedidoVenta = yield call(eliminarPedido, idPedido, null)
      if (eliminacionPedidoVenta.eliminado === true && eliminacionPedidoVenta.asociado === false) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      const val = yield select(state => state.pedidosVenta.filtros)
      const listPedidosVenta = yield call(getFiltrarPedidosVenta, val, auth.token)
      yield put(fetchPedidosVentaSuccess(listPedidosVenta))
      yield put(fetchFiltrarPedidosVentaSuccess(val))
      yield put(cerrarModalPedidosVenta())
    }
  } catch (error) {

  } finally {
    
  }
}
export function * watchOnDeletePedidosVenta () {
  yield takeLatest(actionTypes.ON_DELETE_PEDIDOS_VENTA, onDeletePedidosVenta)
}

export function * deleteSeleccionPedidosVenta ({list}) {
  try {
    let auth = yield select(state => state.auth)
    yield put(openSimpleModal('onDelete'))
    const confirmed = yield call(yesNoModal, {modalType: 'onDelete'})
    if (confirmed) {
      let auth = yield select(state => state.auth)
      const eliminacionPedidoVenta = yield call(eliminarPedidoSeleccion, list, auth.token)
      if (eliminacionPedidoVenta.eliminado === true && eliminacionPedidoVenta.asociado === false) {
        yield put(openMensajeAlerta('eliminacion'))
      } else {
        yield put(openMensajeAlerta('no-eliminacion'))
      }
      yield put(initialize('simpleTable', {}))
      yield put(initialize('FiltrosPedidosVenta'))
      const val = yield select(state => state.pedidosVenta.filtros)
      const listPedidosVenta = yield call(getFiltrarPedidosVenta, val, auth.token)
      yield put(fetchPedidosVentaSuccess(listPedidosVenta))
      yield put(fetchFiltrarPedidosVentaSuccess(val))
      yield put(cerrarModalPedidosVenta())
    }
  } catch (error) {

  } finally {
    
  }
}
export function * watchDeleteSeleccionPedidosVenta () {
  yield takeLatest(actionTypes.DELETE_SELECCION_PEDIDOS_VENTA, deleteSeleccionPedidosVenta)
}

export function * exportarPedidosVenta ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosExcelPedidoVenta = yield call(getExportarPedidosVenta, list, auth.token)
    yield put(exportarPedidosVentaSuccess(datosExcelPedidoVenta))
  } catch (error) {

  } finally {
    
  }
}
export function * watchExportarPedidosVenta () {
  yield takeLatest(actionTypes.EXPORTAR_PEDIDOS_VENTA, exportarPedidosVenta)
}

export function * imprimirPedidosVenta ({list}) {
  try {
    let auth = yield select(state => state.auth)
    const datosPdfPedidoVenta = yield call(getImprimirPedidoVenta, list, auth.token)
    yield put(imprimirPedidosVentaSuccess(datosPdfPedidoVenta))
  } catch (error) {

  } finally {
    
  }
}
export function * watchImprimirPedidosVenta () {
  yield takeLatest(actionTypes.IMPRIMIR_PEDIDOS_VENTA, imprimirPedidosVenta)
}

export function * verracosPedido ({idRazaLineaGenetica}) {
  try {
    let auth = yield select(state => state.auth)
    const comboVerracoPedido = yield call(getVerracosPedido, idRazaLineaGenetica, auth.token)
    yield put(verracosPedidoSuccess(comboVerracoPedido))
  } catch (error) {

  } finally {
    
  }
}
export function * watchVerracosPedido () {
  yield takeLatest(actionTypes.VERRACOS_PEDIDO, verracosPedido)
}

export function * crearAlbaranDesdePedido () {
  try {
    let auth = yield select(state => state.auth)
    let listPedidosAnadidos = yield select(state => state.pedidosVenta.listPedidosAnadidos)
    let datosPedido = yield select(state => state.pedidosVenta.data.pedido)

    const list = listPedidosAnadidos.map((lista, i) => {
      return (
        {
          idAlbaranDosis: 'new_' + i,
          verracoPool: lista.verraco,
          idVerracoPool: '',
          nombreRaza: lista.raza,
          nombreLinea: lista.lineaGenetica,
          nombreTipoDosis: lista.tipoDosis,
          diasHastaExtraccion: '',
          dosisPedidas: lista.numeroDosis,
          dosisEnviadas: '',
          idVerraco: lista.idVerraco,
          idRaza: lista.idRaza,
          idLinea: lista.idLineaGenetica,
          idTipoDosis: lista.idTipoDosis
        }
      )
    })

    let numeroAlbaran = yield call(fetchNumeroAlbaranService)
    const data = {}
    data.dosisAlbaran = list
    data.fechaCreacion = datosPedido.fecha
    data.cliente = datosPedido.cliente
    data.direccionEnvio = datosPedido.idDireccionCliente
    data.numeroAlbaran = numeroAlbaran.numeroAlbaran
    data.origen = datosPedido.numeroPedido
    data.idEstado = 1
    data.idPedido = datosPedido.idPedido
    data.observaciones = datosPedido.observaciones
    data.estado = i18n.t('COMUN.COMBOS.COMBO_ESTADO_PEDIDO.BORRADOR')
    yield put(crearAlbaranDesdePedidoSuccess(data))
    yield put(initialize('ModalAlbaran', data))
    yield put(abrirModalVacioAlbaranSuccess())
  } catch (error) {

  } finally {
    
  }
}
export function * watchCrearAlbaranDesdePedido () {
  yield takeLatest(actionTypes.CREAR_ALBARAN_DESDE_PEDIDO, crearAlbaranDesdePedido)
}

export function * abrirDetallesPedido ({idPedido}) {
  try {
    const data = yield call(getPedidoVenta, idPedido, null)
    yield put(recuperarDatosModalPedidosVentaSuccess(data))
    data.pedido.cliente = {value: data.pedido.idCliente, label: data.pedido.cliente}
    data.pedido.idDireccionCliente = {value: data.pedido.idDireccionCliente, label: data.pedido.direccionCliente}
    data.pedido.numeroPedido = data.pedido.numeroPedido

    let totalDosis = 0
    if (data.lineasPedido && data.lineasPedido.length > 0) {
      data.lineasPedido.forEach(row => {
        if (row.numeroDosis) {
          totalDosis += parseFloat(row.numeroDosis)
        }
      })
    }
    yield put(initialize('ModalDetallePedido', {...data.pedido, numeroDosis: totalDosis}))
    yield put(editarDatosDosisSuccess(data.lineasPedido))
  } catch (error) {

  } finally {
    
  }
}
export function * watchAbrirDetallesPedido () {
  yield takeLatest(actionTypes.ABRIR_DETALLES_PEDIDO_SUCCESS, abrirDetallesPedido)
}

export function * duplicarPedidoVenta ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const insercionPedidoVenta = yield call(duplicarPedido, values, auth.token)
    if (insercionPedidoVenta.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-insercion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    const val = yield select(state => state.pedidosVenta.filtros)
    const listPedidosVenta = yield call(getFiltrarPedidosVenta, val, auth.token)
    yield put(fetchPedidosVentaSuccess(listPedidosVenta))
    yield put(fetchFiltrarPedidosVentaSuccess(val))
    yield put(cerrarModalPedidosVenta())
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
  } finally {
    
  }
}
export function * watchDuplicarPedidoVenta () {
  yield takeLatest(actionTypes.DUPLICAR_PEDIDO_VENTA, duplicarPedidoVenta)
}

export function * obtenerNumeroPedido () {
  try {
    const numeroPedido = yield call(getObtenerNumeroPedido, null)
    yield put(obtenerNumeroPedidoSuccess(numeroPedido))
  } catch (error) {

  } finally {
    
  }
}
export function * watchObtenerNumeroPedido () {
  yield takeLatest(actionTypes.OBTENER_NUMERO_PEDIDO, obtenerNumeroPedido)
}

export function * elegirDireccionClientePedidoVenta ({idDireccion}) {
  try {
    const comboRutaEnPedido = yield call(getRutasEnPedido, idDireccion, null)
    yield put(elegirDireccionClientePedidoVentaSuccess(comboRutaEnPedido))
  } catch (error) {

  } finally {
    
  }
}
export function * watchElegirDireccionClientePedidoVenta () {
  yield takeLatest(actionTypes.ELEGIR_DIRECCION_CLIENTE_PEDIDOS_VENTA, elegirDireccionClientePedidoVenta)
}

export function * confirmarYnuevoPedidosVenta ({values, onSuccessCallback, onErrorCallback}) {
  try {
    let auth = yield select(state => state.auth)
    const insercionPedidoVenta = yield call(addPedidoVenta, values, auth.token)
    if (insercionPedidoVenta.insertado === true) {
      yield put(openMensajeAlerta('insercion'))
      if (onSuccessCallback) yield onSuccessCallback()
    } else {
      yield put(openMensajeAlerta('no-insercion'))
      if (onErrorCallback) yield onErrorCallback()
    }
    const val = yield select(state => state.pedidosVenta.filtros)
    const listPedidosVenta = yield call(getFiltrarPedidosVenta, val, auth.token)
    yield put(fetchPedidosVentaSuccess(listPedidosVenta))
    yield put(fetchFiltrarPedidosVentaSuccess(val))

    yield put(editarDatosDosisSuccess([]))
    yield put(elegirClientePedidoVentaSuccess([]))
    yield put(elegirDireccionClientePedidoVentaSuccess([]))
    const numeroPedido = yield call(getObtenerNumeroPedido, null)
    yield put(obtenerNumeroPedidoSuccess(numeroPedido))
    let valores = {
      fecha: new Date(),
      estado: i18n.t('COMUN.COMBOS.COMBO_ESTADO_PEDIDO.BORRADOR'),
      numeroPedido: numeroPedido.numeroPedido,
      cliente: null,
      idDireccionCliente: null,
      ruta: null
    }
    yield put(initialize('ModalPedidosVenta', valores))
  } catch (error) {
    if (onErrorCallback) yield onErrorCallback()
  } finally {
    
  }
}
export function * watchConfirmarYnuevoPedidosVenta () {
  yield takeLatest(actionTypes.CONFIRMAR_Y_NUEVO_PEDIDOS_VENTA, confirmarYnuevoPedidosVenta)
}
